import React from "react";

import { getToken } from "../../../../service/authService";
import { deleteFileList, downloadFile } from "../../../../service/bizStat/com/bizFileSupportService";

const BizExpenseFile = ({
  conn,
  styles,
  bizStyles,
  search,
  selected,
  dataList,
  getData,
  setBizExpenseWindowVisible,
  bizFileWindowVisible,
  setBizFileWindowVisible,
  setPayOrderWindowVisible,
  setBizExpendWindowVisible,
  IntlProvider,
  LocalizationProvider,
  kendo,
  useState,
  Upload,
  Window,
}) => {
  // console.log("docuCode", dataList.expenseFile[0].fileName);
  // 파일
  const [fileState, setFileState] = useState({
    fileList: [],
    currentFiles: [],
    docuCodeNo: "",
  });

  // const [file, setFile] = useState({});

  // 파일 업로드 후 문제 없다면 리스트 변수
  // const [uploadDataList, setUploadDataList] = useState([]);

  // // 파일 업로드
  // const [uploadState, setUploadState] = useState({
  //   files: [],
  //   events: [],
  //   filePreviews: {},
  //   afterStateChange: [],
  // });

  // 엑셀 업로드 취소 , 닫기 누를 시 초기화
  const initialExcelFile = () => {
    // setUploadState({
    //   files: [],
    // });
    // setUploadDataList([]);
  };

  const onAddFile = e => {
    // console.log("onAdd e ", e, fileState);
    setFileState(state => {
      const item = { ...state };
      // e.affectedFiles.forEach(element => {
      //   element.bizYy = search.bizYyNo;
      //   element.rgnCode = search.rgnCode;
      //   element.bizDocuCodeNo = 38008;
      //   element.docuCodeNo = docuCode;
      // });
      item.currentFiles = e.affectedFiles;
      return item;
    });
  };

  const onRemoveFile = e => {
    // console.log("onRemove e", e, fileState);    
    let removeFileIds = [];
    let updateFiles = [];
    fileState.fileList.forEach(f => {
      e.affectedFiles.forEach(v => {
        if (v.uid === f.uid && v.name === f.name)
          removeFileIds.push({
            bizYy: f.bizYy,
            rgnCode: f.rgnCode,
            bizDocuCodeNo: 38008,
            docuCodeNo: dataList.docuCode,
          });
      });
      e.newState.forEach(n => {
        if (f.uid === n.uid && f.name === n.name) updateFiles.push(f);
      });
    });

    if (removeFileIds.length !== 0) {
      // setConvDeleteFileList(conn, removeFileIds, fileTabName)
      // .then( r => {
      //   kendo.alert("삭제되었습니다.");
      //   setFileState((state) => {
      //     const item = { ...state };
      //     item.fileList = updateFiles;
      //     return item;
      //   });
      // }).catch(e => {
      //   kendo.alert("삭제되지 않았습니다.");
      // });
    }
  };

  const onStatusChangeFile = e => {
    // console.log("onStatusChange e", e, fileState);
    if (e.response?.response) {
      let list = [...fileState.fileList, ...e.response.response.data];
      setFileState(state => {
        const item = { ...state };
        item.fileList = list;
        item.currentFiles = e.response.response;
        return item;
      });

      if (e.response.response.status === 200) {
        setBizFileWindowVisible(!bizFileWindowVisible);
        kendo.alert("업로드되었습니다.");
        getData();
      }
    }
  };

  // 파일 업로드 윈도우 창 제어
  const uploadFileEvt = e => {
    // console.log('엑셀 업로드');
    initialExcelFile();

    // setFileTabName(e.target.name);
    if (search.rgnCode !== "" && search.bizYyNo.toString() !== "") {
      setFileState(state => {
        const item = { ...state };
        item.docuCodeNo = dataList.docuCode;
        return item;
      });

      setBizFileWindowVisible(!bizFileWindowVisible);
      setBizExpenseWindowVisible(false);
      setPayOrderWindowVisible(false);
      setBizExpendWindowVisible(false);
      // getData();
    } else {
      kendo.alert("사업연도와 시군구를 선택해주세요");
    }
  };

  /**
   * 파일 삭제 이벤트
   * @param {*} e
   */
  const deleteFileEvt = e => {
    // console.log('삭제');

    let removeFileIds = [
      {
        bizYy: search.bizYy,
        rgnCode: search.rgnCode,
        bizDocuCodeNo: 38008,
        docuCodeNo: dataList.docuCode,
        bizSctCode: search.bizSctCode,
      },
    ];

    // console.log("removeFileIds", removeFileIds);

    kendo
      .confirm("해당 파일을 삭제 하시겠습니까?")
      .then(result => {
        deleteFileList(conn, removeFileIds, e.target.name)
          .then(r => {
            kendo.alert("삭제되었습니다.");

            getData();
          })
          .catch(e => {
            kendo.alert("삭제되지 않았습니다.");
          });
      })
      .catch(e => {
        kendo.alert("삭제되지 않았습니다.");
      });
  };

  /**
   * 다운로드 파일 이벤트
   * @param {*} e
   */
  function downloadFileEvt(e) {
    const fileInfo = {
      bizYy: search.bizYy,
      rgnCode: search.rgnCode,
      bizDocuCodeNo: 38008,
      docuCodeNo: dataList.docuCode,
      bizSctCode: search.bizSctCode,
    };
    // downloadFileConv(fileInfo, e.target.name, e.target.outerText);
    const fileName = e.target.outerText;
    downloadFile(fileInfo, fileName);
  }

  const saveFileUrl =
    `${process.env.REACT_APP_REST_API_VERSION}/biz/file/upload?fileInfo=` +
    encodeURI(JSON.stringify(fileState.currentFiles)) +
    "&bizYy=" +
    search.bizYy +
    "&rgnCode=" +
    search.rgnCode +
    "&cityProvCode=" +
    search.cityProvCode +
    "&bizDocuCodeNo=38008&docuCodeNo=" +
    dataList.docuCode +
    "&convName=bizExpnse" +
    "&bizSctCode=" +
    search.bizSctCode;
  const removeFileUrl = `${process.env.REACT_APP_REST_API_VERSION}/biz/file/delete`;

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <table class="remcBB-Table eer-Table-striped">
          <colgroup>
            <col width="250" />
            <col width="550" />
            <col width="250" />
          </colgroup>
          <thead>
            <tr>
              <th>산출내역자료</th>
              <th>첨부파일</th>
              <th>선택</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>산출내역 엑셀파일</td>
              <td>
                <button 
                  style={{color:'#1e88e5', outline:'none', textDecoration:'none', background:'transparent', border:'none'}}
                  name="bizExpnse" id={dataList.docuCode} class="gree_cl" onClick={downloadFileEvt}>
                  {dataList.expenseFile[0]?.fileName ? dataList.expenseFile[0].fileName : null}
                </button>
              </td>
              <td class="center" style={{ textAlign: "center" }}>
                <button
                  className={dataList.expenseFile[0]?.fileId ? "redBg" : "btn_pls"}
                  name="bizExpnse"
                  id={dataList.docuCode}
                  onClick={dataList.expenseFile[0]?.fileId ? deleteFileEvt : uploadFileEvt}>
                  {dataList.expenseFile[0]?.fileId ? "삭제" : "등록"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {bizFileWindowVisible && (
        <Window
          title={"업로드"}
          onClose={uploadFileEvt}
          top={420}
          left={725}
          initialHeight={350}
          initialWidth={600}
          modal={true}
          resizable={false}
          doubleClickStageChange={false}
          draggable={false}>
          <div>
            {/* 윈도우 창 */}
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Upload
                  autoUpload={false}
                  batch={true}
                  multiple={false}
                  files={fileState.fileList}
                  defaultFiles={[]}
                  onAdd={onAddFile}
                  onRemove={onRemoveFile}
                  onStatusChange={onStatusChangeFile}
                  withCredentials={true}
                  removeVerb={"DELETE"}
                  restrictions={{
                    allowedExtensions: [".xls", ".xlsx", ".csv"],
                    maxFileSize: 51200000,
                  }}
                  saveMethod={"post"}
                  saveHeaders={{
                    "Content-Type": "multipart/form-data",
                    "X-AUTH-TOKEN": getToken(),
                  }}
                  saveUrl={saveFileUrl}
                  removeUrl={removeFileUrl}
                />
              </IntlProvider>
            </LocalizationProvider>
            {/* <div>
              <Button>저장</Button>
              <Button>취소</Button>
            </div> */}
          </div>
        </Window>
      )}
    </div>
  );
};

export default BizExpenseFile;
