import MakrCharts from "./makrCharts";
import MakrEventCntCharts from "./makrEventCntCharts";
import React from "react";

// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { orderBy } from "@progress/kendo-data-query";
import kendo from "@progress/kendo-ui";
// service
import { numFormat } from "../../../../com/dataUtil";

// Css
// import './makr.css';

export const MakrDashboard = ({ styles, dataSource, searchClick, paging, sort, onPageChange, onSortChange, downloadExcel, loading }) => {
  // const history = useHistory();
  // 이벤트-이력조회 이동 (전체) : jhchoi_20220222 : 사용 안함 현재 일단위로 가져오기에 이벤트 이력 조회로 넘어가는 기능은 제외 추후에 변경 필요
  // const goLogHistory = e => {
  //   history.push(
  //     "/analy/event/log?ensoTypeCodeNo=" +
  //       searchClick.ensoTypeCodeNo +
  //       "&cityProvCode=" +
  //       searchClick.cityProvCode +
  //       "&rgnCode=" +
  //       searchClick.rgnCode +
  //       "&start=" +
  //       searchClick.start +
  //       "&end=" +
  //       searchClick.end,
  //   );
  // };

  // 이벤트-이력조회 이동 (경고) : jhchoi_20220222 : 사용 안함 현재 일단위로 가져오기에 이벤트 이력 조회로 넘어가는 기능은 제외 추후에 변경 필요
  // const goLogWarnHistory = e => {
  //   history.push(
  //     "/analy/event/log?ensoTypeCodeNo=" +
  //       searchClick.ensoTypeCodeNo +
  //       "&cityProvCode=" +
  //       searchClick.cityProvCode +
  //       "&rgnCode=" +
  //       searchClick.rgnCode +
  //       "&start=" +
  //       searchClick.start +
  //       "&end=" +
  //       searchClick.end +
  //       "&facilityState=7006",
  //   );
  // };

  // 이벤트-이력조회 이동 (고장): jhchoi_20220222 : 사용 안함 현재 일단위로 가져오기에 이벤트 이력 조회로 넘어가는 기능은 제외 추후에 변경 필요
  // const goLogErrHistory = e => {
  //   history.push(
  //     "/analy/event/log?ensoTypeCodeNo=" +
  //       searchClick.ensoTypeCodeNo +
  //       "&cityProvCode=" +
  //       searchClick.cityProvCode +
  //       "&rgnCode=" +
  //       searchClick.rgnCode +
  //       "&start=" +
  //       searchClick.start +
  //       "&end=" +
  //       searchClick.end +
  //       "&facilityState=7007",
  //   );
  // };

  const warnCell = data => {
    return <td>{numFormat(data.dataItem.warn)}</td>;
  };
  const errCell = data => {
    return <td>{numFormat(data.dataItem.err)}</td>;
  };
  // const manaTmCell = data => {
  //   return <td>{numFormat(data.dataItem.manaTm)}</td>;
  // };

  const gathDtmCell = data => {
    return <td>{ kendo.toString( new Date( data.dataItem.gathDtm ) , 'yyyy-MM-dd' ) }</td>
  }

  return (
    <>
      <div
        style={
          loading
            ? {
                opacity: 0.6,
                pointerEvents: "none",
              }
            : null
        }>
        <div className="makrBoard">
          <div title="클릭시 상세페이지로 이동합니다." id="test" className="makrBoardSub">
            <div>
              <span>총 설비 경보</span>
              <br />
              <span className="makrBoardContent">
                <b>{dataSource.dashboard ? dataSource.dashboard.ttl >= 0 ? numFormat(dataSource.dashboard.ttl) : 0 : 0}</b>

                <span>개소</span>
              </span>
            </div>
          </div>
          <div title="클릭시 상세페이지로 이동합니다." className="makrBoardSub">
            <div>
              <span>설비 경고</span>
              <br />
              <span className="makrBoardContent">
                <b>{dataSource.dashboard ? dataSource.dashboard.warn >= 0 ? numFormat(dataSource.dashboard.warn) : 0 : 0}</b>

                <span>개소</span>
              </span>
            </div>
          </div>
          <div title="클릭시 상세페이지로 이동합니다." className="makrBoardSub">
            <div>
              <span>설비 고장</span>
              <br />
              <span className="makrBoardContent">
                <b>{dataSource.dashboard ? dataSource.dashboard.err >= 0 ? numFormat(dataSource.dashboard.err) : 0 : 0}</b>

                <span>개소</span>
              </span>
            </div>
          </div>
          {/* <div className="makrBoardSubManaTm">
            <div>
              <span>수동 조치시간</span>
              <br />
              <span className="makrBoardContent">
                <b>
                  {numFormat(dataSource.dashboard.manaTm)}
                  <span>분</span>
                </b>
              </span>
            </div>
          </div> */}
        </div>
        <div className="makrArticle">
          <div className="makrArticleSub">
            <div className="makrArticleSubTitle">
              <h3>제조사별 이벤트</h3>
            </div>
            <div className="makrArticleEngCharts">
                <MakrEventCntCharts dataSource={dataSource} />
              {/* <div>
                <MakrEventTimeCharts dataSource={dataSource} />
              </div> */}
              {/* <div><EnsoPieCharts chartTitle='조치소요시간' data={dataSource.eventContent} /></div> */}
            </div>
          </div>
          <div className="makrArticleSub">
            <div className="makrArticleSubTitle">
              <h3>시간대별 경고/고장 상세정보</h3>

              <Button className={styles.makrExcelBtnClass} onClick={downloadExcel} icon="excel">
                엑셀저장
              </Button>
            </div>
            <div className="makrArticleTimeCharts">
              <MakrCharts xCategories={dataSource.xCategories} warnData={dataSource.warnData} errData={dataSource.errData} />
            </div>
            <div className="makrArticleTimeGrid">
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Grid
                    className={styles.makrGrid}
                    style={{ height: "385px" }}
                    data={orderBy(dataSource.list, sort)}
                    total={dataSource.listTotal}
                    skip={paging.skip}
                    take={paging.take}
                    page={paging}
                    onPageChange={onPageChange}
                    sort={sort}
                    onSortChange={onSortChange}
                    sortable
                    pageable={{
                      type: "numeric",
                      pageSizes: [5, 10, 20, 50, 100],
                      previousNext: true,
                      buttonCount: 5,
                    }}>
                    <GridColumn field="makrTerm" title="제조사" />
                    <GridColumn field="meainTypeTerm" title="모델" />
                    <GridColumn field="gathDtm" title="수집 날짜"  cell={gathDtmCell} />
                    <GridColumn field="warn" title="경고" cell={warnCell} />
                    <GridColumn field="err" title="고장" cell={errCell} />
                    {/* <GridColumn field="manaTm" title="조치시간(분)" cell={manaTmCell} /> */}
                  </Grid>
                </IntlProvider>
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MakrDashboard;
