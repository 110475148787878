import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

const GathRawDataLogGridFuelcell = ({ search, paging, setOperHistData , cellKeyFormat , cellFormat }) => {
  
  // 페이징 이벤트
  const onPageChange = e => {
    let { take, skip } = e.page;
    // 검색 안되도록 수정 _ 20220216 _ jhchoi
    if(search.cid === "" && search.userId === ""){
      return;
    }
    paging.take = take;
    paging.skip = skip;
    setOperHistData(search, paging);
  };

  // 정렬 이벤트
  const onSortChange = e => {
    const { sort } = e;
    // 검색 안되도록 수정 _ 20220216 _ jhchoi
    if(search.cid === "" && search.userId === ""){
      return;
    }
    paging.sort = sort;
    setOperHistData(search, paging);
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              id="fuelCellGrid"
              key={15006}
              className="operHistGrid"
              data={paging.list}
              resizable={true}
              pageable={{
                type: "numeric",
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true,
                buttonCount: 5,
                refresh: true,
              }}
              total={paging.count}
              skip={paging.skip}
              take={paging.take}
              pageSize={paging.take}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              sort={paging.sort}
              sortable>
              <GridNoRecords>{"  "}</GridNoRecords>
              <GridColumn title="CID" field="cid" width="130px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="설치자" field="userTerm" width="200px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="수집일자" field="gathDtm" width="170px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="dataStatSctCodeTerm" title="데이터상태" width="140px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="accumGelecQty" title="누적발전량(Wh)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="accumPrdctHeatQty" title="누적생산열량(kWh)" width="125px" cell={cellFormat}></GridColumn>
              <GridColumn field="prdctHeatQty" title="생산열량(W)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="accumUseHeatQty" title="누적사용열량(kWh)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="ystrAccumUseHeatQty" title="전일누적사용열량(kWh)" width="150px" cell={cellFormat}></GridColumn>
              <GridColumn field="inptVotgFigr" title="(전)전압(V)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="inptEcurFigr" title="(전)전류(A)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="inptElcpFigr" title="(전)출력(W)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="outpVotgFigr" title="(후)전압(V)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="outpEcurFigr" title="(후)전류(A)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="outpElcpFigr" title="(후)출력(W)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="wasupTemp" title="급수온도(℃)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="olutwtrTemp" title="출수온도(℃)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="pwrftRate" title="역률(%)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="freqFigr" title="주파수(Hz)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="errCode" title="에러코드" width="150px" cell={cellFormat}></GridColumn>
            <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default GathRawDataLogGridFuelcell;
