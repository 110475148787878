import React , {memo} from "react";
// 공용
import TimePower from './time_power';
import AreaEfic from './area_efic';
// 태양광
import InverterEfic from './inverter_efic';
import ModuleEfic from './module_efic';
import ModuleCrystal from './module_crystal';
import InstType from './inst_type';
import AngleData from './angle_data';
//Kendo Tab TabStrip , TabStripTab
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import SocoEfic from "./soco_efic";

const EnsoTabStrip = memo(({ conn, search, setLoading , tabStat , selectTabItem ,LocalizationProvider ,IntlProvider}) => {

    // 에너지원 코드
    const ensoTypeCodeNo = search.ensoTypeCodeNo;

    /**
     * 탭 이름 설정
     */
    let tabName = '';
    if( search.unit === "DD" ){
      tabName = '일별 그래프';
    } else if ( search.unit === "MM" ){
      tabName = '월별 그래프';
    } else if ( search.unit === "HH" ){
      tabName = '시간대별 그래프';
    }else{
      tabName = '일별 그래프';
    }

    // 조건부로 랜더링 하자 
    return(
        <div className="uk-grid">
            <div className="uk-width-medium-1-1">
                <TabStrip selected={tabStat.state} onSelect={selectTabItem} className = "cmpEnsoTab" >
                    <TabStripTab title={tabName} >
                    <dd style = {{padding:"20px"}}>
                    <TimePower conn={conn} search={search} setLoading={setLoading} LocalizationProvider = {LocalizationProvider} IntlProvider = {IntlProvider} ></TimePower>
                    </dd>
                    </TabStripTab> 
                    <TabStripTab title="지역별 그래프" >
                    <dd style = {{padding:"20px"}}>
                        <AreaEfic conn = {conn}   search = {search} setLoading={setLoading} LocalizationProvider = {LocalizationProvider} IntlProvider = {IntlProvider} ></AreaEfic>
                    </dd>
                    </TabStripTab>
                    { ensoTypeCodeNo === 15001 &&
                        <TabStripTab title="인버터 발전효율">
                        <dd style = {{padding:"20px"}}>
                            <InverterEfic conn = {conn}   search = {search} setLoading={setLoading} LocalizationProvider = {LocalizationProvider} IntlProvider = {IntlProvider} ></InverterEfic>  
                        </dd>
                        </TabStripTab>
                    }
                    { ensoTypeCodeNo === 15001 &&
                        <TabStripTab title="모듈 효율" >
                        <dd style = {{padding:"20px"}}>
                            <ModuleEfic conn = {conn}   search = {search} setLoading={setLoading} LocalizationProvider = {LocalizationProvider} IntlProvider = {IntlProvider} ></ModuleEfic>
                        </dd>
                        </TabStripTab>
                    }

                    { ensoTypeCodeNo === 15001 &&
                        <TabStripTab title="결정분류"  >
                        <dd style = {{padding:"20px"}}>
                            <ModuleCrystal conn = {conn}   search = {search} setLoading={setLoading}></ModuleCrystal>
                        </dd>
                        </TabStripTab>
                    }
                    { ensoTypeCodeNo === 15001 &&
                        <TabStripTab title="설치유형"  >
                        <dd style = {{padding:"20px"}}>
                            <InstType  conn = {conn}   search = {search} setLoading={setLoading}></InstType>
                        </dd>
                        </TabStripTab>
                    }
                    { ensoTypeCodeNo === 15001 &&
                        <TabStripTab title="방위각/경사각"  >
                        <dd style = {{padding:"20px"}}>
                            <AngleData conn = {conn}   search = {search} setLoading={setLoading}></AngleData>
                        </dd>
                        </TabStripTab>
                    }
                    { ensoTypeCodeNo === 15002 &&
                        <TabStripTab title="집열기 효율" >
                        <dd style = {{padding:"20px"}}>
                          <SocoEfic conn = {conn}   search = {search} setLoading={setLoading} LocalizationProvider = {LocalizationProvider} IntlProvider = {IntlProvider} ></SocoEfic>
                        </dd>
                        </TabStripTab>
                    }

                </TabStrip>
            </div>
        </div>
    )
});

export default EnsoTabStrip;