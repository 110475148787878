import { Grid , GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { getHmpgAccsLog } from "../../../service/mngt/hmpgAccsLog/hmpgAccsLogService";
import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";
import './hmpgAccsLog.css';
import styles from './hmpgAccsLog.module.css';
import kendo from "@progress/kendo-ui";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

// 로딩 스피너 
import { BeatLoader } from 'react-spinners'

const HmpgAccsLog = ({conn}) => {

  // 로딩 스피너
  const [loading , setLoading] = useState(false);

  // 검색
  const [search , /*setSearch*/] = useState({
    userId : '',
    accsIp : '',
    accsUrl : '',
    msg : '',
    sort : "",
    gridSort : "",
    size : 20,
    skip : 0,
    take : 20
  })

  // 데이터 리스트
  const [userHistList , setUserHistList] = useState({
    dataList : [],
    total : 0
  });

  // 사용자 접속 이력 데이터 불러오기
  const getHmpgAccsLogList = () => {
    setLoading(true);
    getHmpgAccsLog(conn , getHmpgAccsLogListResult , getRejection , search);
  }

  // 사용자 접속 이력 데이터 불러오기 Callback
  const getHmpgAccsLogListResult = (result) => {
    console.log("result = ", result);
    setLoading(false);
    setUserHistList(userHistList => {
      const item = {...userHistList};
      item.dataList = result.results;
      item.total = result.total;
      return item;
    });
  }

  // Rejection 함수
  const getRejection = (error) => {
    console.log("error = ", error);
    if(error.errCode === "T002"){
      kendo.alert("다시 로그인을 해주세요.");
      return;
    }else {
      kendo.alert("시스템에 문제가 생겼습니다. 다시 접속 해주세요.");
      return;
    }
  }

  // 페이징 이벤트
  const onPageChange = (e) =>{
    const {take, skip} = e.page;
    search.take = take; search.skip = skip; search.size = take;
    getHmpgAccsLogList();
  }

  // 정렬 이벤트
  const onSortChange = (e) =>{
    const {sort} = e; 
    search.gridSort = sort;
    console.log("onSortChange sort = ", sort);
    if( sort.length > 0 ){
      search.sort = sort[0].field+","+sort[0].dir;
    }else{
      search.sort = "logSn,DESC";
    }
    getHmpgAccsLogList();
  }

  // 필터 함수
  const onFilterChange = (e) => {
    console.log("onFilterChange e = ", e);
    if( e.filter.filters[0].field === "userId" ){
      search.userId = e.filter.filters[0].value;
    } else if( e.filter.filters[0].field === "accsIp" ){
      search.accsIp = e.filter.filters[0].value;
    } else if( e.filter.filters[0].field === "accsUrl" ){
      search.accsUrl = e.filter.filters[0].value;
    } else if( e.filter.filters[0].field === "msg" ){
      search.msg = e.filter.filters[0].value;
    }
    getHmpgAccsLogList();
  }

  useEffect(() => {
    getHmpgAccsLogList();
    // eslint-disable-next-line
  },[]);

  return(
    <div>
      <div className="md-card"> 
        <div className="md-card-content">
          <div className={ loading ? styles.hmpgAccsLog : ""}>
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
            <Grid className="hmpgAccsLogGrid" filterable={true} sortable={false} 
              style={{ height: "800px" }} 
              total = {userHistList.total} skip = {search.skip} take={search.take} pageSize = {search.take}
              data={userHistList.dataList}
              onFilterChange = {onFilterChange}
              sort = {search.gridSort}
              onSortChange={onSortChange}
              onPageChange = {onPageChange}
              pageable={ {  type: 'numeric', pageSizes: [5, 10, 20, 50, 100], previousNext: true, buttonCount: 5, refresh: true } }
              >
              <Column width="100px" field="logSn" title="순번" filterable = {false} />
              <Column width="250px" field="regiDtm" title="시간" filterable = {false} />
              <Column width="250px" field="userId" title="ID" filterCell={GridColumnInputFilter}/>
              <Column width="250px" field="accsIp" title="IP주소" filterCell={GridColumnInputFilter}/>
              <Column width="250px" field="accsUrl" title="경로" filterCell={GridColumnInputFilter}/>
              <Column field="msg" title="메세지"  filterCell={GridColumnInputFilter}/>
              <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
            </Grid>
            </IntlProvider>
          </LocalizationProvider>
          </div>
          { loading && <p className={styles.hmpgAccsLogLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p> }
        </div>
      </div>
    </div>
  )
}

export default HmpgAccsLog;