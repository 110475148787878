import { useCreateAxiosInstance } from "conInstance";
import AsanEnergy from "./components/AsanEnergy/AsanEnergy";
import Contribution from "./components/Contribution/Contribution";
import ConvertedAmount from "./components/ConvertedAmount/ConvertedAmount";
import DevelopmentState from "./components/DevelopmentState/DevelopmentState";
import ShortCut from "./components/Shortcut/ShortCut";
import Supply from "./components/Supply/Supply";
import Today from "./components/Today/Today";
import Top from "./components/Top/Top";
import "./index.css";
import { ASAN_CODE } from "./components/constant/rgnCode";
import { useEffect, useState } from "react";

export default function Asan({isMapView}) {
  const { conn } = useCreateAxiosInstance();
  const [data, setData] = useState();

  const getPowerGeneration = async () => {
    const response = await conn.get(`monitor/installer/munic/energyData?rgnCode=${ASAN_CODE}`);
    setData(response);
    console.log(response);
  };

  useEffect(() => {
    getPowerGeneration();
    //eslint-disable-next-line
  }, []);

  if (!data) return <div />;

  return (
    <div className="asan-container">
      {!isMapView ? <Top /> : <div style={{marginTop:'50px'}}></div>}
      <div className="asan-layout">
        <div className="asan-left-content">
          <div className="asan-left-top">
            <Today />
            <ConvertedAmount totalPrice={data.totalPrice} smpPrice={data.smpPrice} recPrice={data.recPrice} />
          </div>
          <Contribution
            totalCo2={data.totalCo2}
            totalTree={data.totalTree}
            totalPlasticCup={data.totalPlasticCup}
            totalPaperCup={data.totalPaperCup}
            totalCarDistance={data.totalCarDistance}
          />
        </div>
        <div className="asan-right-content">
          <div className="asan-left-top">
            <DevelopmentState
              solaDayPower={data.solaDayPower}
              heatDayPower={data.heatDayPower}
              geoDayPower={data.geoDayPower}
              heatCo2={data.heatCo2}
              geoCo2={data.geoCo2}
              solaCo2={data.solaCo2}
            />
            <Supply />
          </div>
          <div className="asan-right-bottom">
            <AsanEnergy />
            <ShortCut />
          </div>
        </div>
      </div>
    </div>
  );
}
