import { Window } from "@progress/kendo-react-dialogs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { getToken } from '../../service/authService';
import { Upload } from "@progress/kendo-react-upload";
// import { Button } from "bootstrap";
const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  // Indent,
  // Outdent,
  InsertImage,
  OrderedList,
  CleanFormatting,
  UnorderedList,
  // Undo,
  // Redo,
  // Link,
  // Unlink,
  FontSize,
  // FontName,
  ForeColor,
  // FormatBlock,
} = EditorTools;

const QnaAdd = ({
  windowWrite,
  writebbs,
  styles,
  companyChange,
  regiChange,
  emailChange,
  emailReply,
  secretQna,
  sjChange,
  ctntChange,
  onAddFile,
  onRemoveFile,
  onStatusChangeFile,
  fileState,
  saveQna,
  cancelWrite,
  qnaState,
}) => {
  const emailRequiredOption = () => {
    alert("이메일을 입력해주세요.")
  }
  const subjectRequiredOption = () => {
    alert("질문 제목을 입력해주세요.")
  }

  const CSS = `p {color: white;}`;
  const onMount = (event) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(CSS));
    iframeDocument.head.appendChild(style);
  };
  
  return (
    <>
      {" "}
      <Window
        className="qna-info"
        title={"질문작성"}
        left={windowWrite.left}
        top={windowWrite.top}
        width={windowWrite.width}
        height={windowWrite.heiht}
        onClose={writebbs}
        initialHeight={720}
        initialWidth={1100}
      >
        <form className="uk-form-stacked">
          <div className="k-form">
            <table className="registerbox">
              <tr>
                <th>* 업체명</th>
                <td>
                  <input
                    className={styles.wrterNm}
                    onChange={companyChange}
                    value={qnaState.userId}
                    readOnly
                  ></input>
                </td>
              </tr>
              <tr>
                <th>* 작성자명</th>
                <td>
                  <input
                    className={styles.wrterNm}
                    onChange={regiChange}
                  ></input>
                </td>
              </tr>
              <tr>
                <th>* 이메일</th>
                <td>
                  <input
                    className={styles.wrterNm}
                    type="text"
                    onChange={emailChange}
                  ></input>
                  이메일 답변등록 알림 여부
                  <Checkbox
                    className="qnaCheckBox"
                    label={""}
                    onChange={emailReply}
                  />
                </td>
              </tr>
              <tr>
                <th>* 비밀글여부</th>
                <td>
                  비밀글
                  <Checkbox
                    className="qnaCheckBox"
                    label={""}
                    onChange={secretQna}
                  />
                </td>
              </tr>
              <tr>
                <th>* 질문제목</th>
                <td>
                  <input className={styles.wrterNm} onChange={sjChange}></input>
                </td>
              </tr>
              <tr>
                <th>* 질문내용</th>
                <td>
                  <div>
                    <LocalizationProvider language="ko-KR">
                      <IntlProvider locale="ko">
                        <Editor
                          type="text"
                          name="ctnt"
                          onChange={ctntChange}
                          contentStyle={{ width: 690, height: 270 }}
                          tools={[
                            [Bold, Italic, Underline, CleanFormatting],
                            [FontSize],
                            [ForeColor],
                            [OrderedList, UnorderedList],
                            [AlignLeft, AlignCenter, AlignRight],
                            [InsertImage],
                          ]}
                          onMount={onMount}
                        />
                      </IntlProvider>
                    </LocalizationProvider>
                  </div>
                </td>
              </tr>
              <tr>
                <th>* 첨부파일</th>
                <td>
                  <div className="demo-hint">
                    최대 업로드 용량 : <strong>10MB</strong>
                    <LocalizationProvider language="ko-KR">
                      <IntlProvider locale="ko">
                        <Upload
                          autoUpload={false}
                          batch={true}
                          multiple={true}
                          files={fileState.fileList}
                          defaultFiles=
                          {[]}
                          onAdd={onAddFile}
                          onRemove={onRemoveFile}
                          onStatusChange={onStatusChangeFile}
                          withCredentials={true}
                          removeVerb={"DELETE"}
                          restrictions={{
                            allowedExtensions: [
                              ".gif",
                              ".jpg",
                              ".png",
                              ".xls",
                              ".xlsx",
                              ".hwp",
                              ".doc",
                              ".pdf",
                              ".exe",
                              ".zip",
                            ],
                            maxFileSize: 10240000,
                          }}
                          saveMethod={"post"}
                          saveHeaders={{
                            "Content-Type": "multipart/form-data",
                            "X-AUTH-TOKEN": getToken()
                          }}
                          saveUrl={"/file/upload?fileInfo=" + encodeURI(JSON.stringify(fileState.currentFiles))}
                          removeUrl={"/file/delete"}
                        />
                      </IntlProvider>
                    </LocalizationProvider>
                  </div>
                </td>
              </tr>
            </table>

            <br></br>

            <div className="btnbox">
              <button
                style={{background:'transparent'}}                
                className="k-button k-button-icontext k-grid-add"
                onClick={cancelWrite}
              >
                취소
              </button>


              {qnaState.qestnSj !== "" ?
                    qnaState.emailAnswerYn === "N" ?
                    <button
                    style={{background:'transparent'}}                    
                    className="k-button k-button-icontext 22k-grid-add"
                    onClick={saveQna}
                  >등록</button> : qnaState.email !== "" ? <button
                                                    style={{background:'transparent'}}                                                    
                                                    className="k-button k-button-icontext 22k-grid-add"
                                                    onClick={saveQna}
                                                  >등록</button>:<button
                                                    style={{background:'transparent'}}                                                    
                                                    className="k-button k-button-icontext 22k-grid-add"
                                                    onClick={emailRequiredOption}
                                                 >등록</button>
                :
               qnaState.emailAnswerYn === "N" ? <button
                style={{background:'transparent'}}                
                className="k-button k-button-icontext 22k-grid-add"
                onClick={subjectRequiredOption}
              >
                등록</button> :<button
                style={{background:'transparent'}}                
                className="k-button k-button-icontext 22k-grid-add"
                onClick={subjectRequiredOption}
              >
                등록
              </button>
              }


            </div>
          </div>
        </form>
      </Window>
    </>
  );
};
export default QnaAdd;
