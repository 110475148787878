import { ReactComponent as GongjuTouristLogo } from "../../../../../assets/svg/gongju/gongjuTouristSpotLogo.svg";
import { ReactComponent as Spot1 } from "../../../../../assets/svg/gongju/touristSpot1.svg";
import { ReactComponent as Spot2 } from "../../../../../assets/svg/gongju/touristSpot2.svg";
import { ReactComponent as Spot3 } from "../../../../../assets/svg/gongju/touristSpot3.svg";
import { ReactComponent as Spot4 } from "../../../../../assets/svg/gongju/touristSpot4.svg";
import { ReactComponent as Spot5 } from "../../../../../assets/svg/gongju/touristSpot5.svg";
import { ReactComponent as Spot6 } from "../../../../../assets/svg/gongju/touristSpot6.svg";
import { ReactComponent as Spot7 } from "../../../../../assets/svg/gongju/touristSpot7.svg";
import { ReactComponent as Spot8 } from "../../../../../assets/svg/gongju/touristSpot8.svg";
import { ReactComponent as Spot9 } from "../../../../../assets/svg/gongju/touristSpot9.svg";
import { ReactComponent as Spot10 } from "../../../../../assets/svg/gongju/touristSpot10.svg";

const areaInfo = {
  사곡면: {
    svg: Spot7,
    title: "마곡사",
    address: "충청남도 공주시 사곡면 마곡사로 966",
  },
  반포면: [
    {
      svg: Spot1,
      title: "계룡산",
      address: "충청남도 공주시 반포면 동학사1로 327-6",
    },
    {
      svg: Spot8,
      title: "동학사 은선폭포",
      address: "충청남도 공주시 반포면 동학사1로 327-6",
    },
  ],
  월송동: {
    svg: Spot9,
    title: "석장리 풍경",
    address: "충청남도 공주시 금벽로 990",
  },
  계룡면: {
    svg: Spot2,
    title: "갑사",
    address: "충청남도 공주시 계룡면 갑사로 567-3",
  },
  금학동: {
    svg: Spot10,
    title: "금학생태공원",
    address: "충청남도 공주시 수원지공원길 74",
  },
  신관동: {
    svg: Spot3,
    title: "금강",
    address: "충청남도 공주시 금벽로 551",
  },
  웅진동: [
    {
      svg: Spot4,
      title: "공산성",
      address: "충청남도 공주시 웅진로 280",
    },
    {
      svg: Spot5,
      title: "고마나루",
      address: "충청남도 공주시 백제큰길 2045",
    },
    {
      svg: Spot6,
      title: "무령왕릉과 왕릉원",
      address: "충청남도 공주시 왕릉로 37",
    },
  ],
};

const GongjuTouristTooltip = ({ area }) => {
  const infos = areaInfo[area];
  if (!infos) return null;
  return (
    <>
      {Array.isArray(infos) ? (
        infos.map((info, index) => (
          <div className="gongju-tourist-container" key={index} style={{ top: `${101 + index * 41}%` }}>
            <div className="gongju-tourist-wrapper">
              <div className="gongju-tourist-left-wrapper">
                <GongjuTouristLogo />
                <info.svg />
              </div>
              <div className="gongju-tourist-right-wrapper">
                <div className="gongju-tourist-spot-title">{info.title}</div>
                <div className="gongju-tourist-spot-address">{info.address}</div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="gongju-tourist-container" style={{ top: "101%" }}>
          <div className="gongju-tourist-wrapper">
            <div className="gongju-tourist-left-wrapper">
              <GongjuTouristLogo />
              <infos.svg />
            </div>
            <div className="gongju-tourist-right-wrapper">
              <div className="gongju-tourist-spot-title">{infos.title}</div>
              <div className="gongju-tourist-spot-address">{infos.address}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default GongjuTouristTooltip;
