export const asanAreaName = [
  {
    name: "염치읍",
    x: "50%",
    y: "40%",
  },
  {
    name: "배방읍",
    x: "75%",
    y: "65%",
  },
  {
    name: "송악면",
    x: "50%",
    y: "80%",
  },
  {
    name: "탕정면",
    x: "80%",
    y: "45%",
  },
  {
    name: "음봉면",
    x: "75%",
    y: "30%",
  },
  {
    name: "둔포면",
    x: "70%",
    y: "10%",
  },
  {
    name: "영인면",
    x: "45%",
    y: "18%",
  },
  {
    name: "인주면",
    x: "18%",
    y: "25%",
  },
  {
    name: "신창면",
    x: "30%",
    y: "50%",
  },
  {
    name: "선장면",
    x: "13%",
    y: "52%",
  },
  {
    name: "도고면",
    x: "23%",
    y: "65%",
  },
  {
    name: "온양 1동",
    x: "55%",
    y: "51%",
  },
  {
    name: "온양 2동",
    x: "55%",
    y: "55%",
  },
  {
    name: "온양 3동",
    x: "63%",
    y: "53%",
  },
  {
    name: "온양 4동",
    x: "46%",
    y: "53%",
  },
  {
    name: "온양 5동",
    x: "46%",
    y: "65%",
  },
  {
    name: "온양 6동",
    x: "62%",
    y: "62%",
  },
];
