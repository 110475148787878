import "./nonOperHistGrid.css";
import React, { useState } from "react";
import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";

import { updateEtc } from "../../../service/stats/nonOperHist/nonOperHist";
import { getExcelDownload } from "../../../service/stats/nonOperHist/nonOperHist";
import { numFormat } from "../../../com/dataUtil";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import NonOperHistEtcUpdateWindow from "./nonOperHistEtcUpdateWindow";

const NonOperHistGrid = ({ conn, styles, dataSource, paging, sort, onPageChange, onSortChange, search, setLoading }) => {
  // 엑셀 저장
  const downloadExcel = () => {
    getExcelDownload(search, paging, setLoading);
  };

  const noCell = props => {
    let dataIndex = props.dataIndex + 1;
    if (props.rowType === "groupHeader") {
      return null;
    }
    // let no = props.dataIndex.rnnum;
    return <td>{dataIndex}</td>;
  };

  const accDateFormatCell = data => {
    const acceDtm = data.dataItem.finalAcceDtm ? kendo.toString(new Date(data.dataItem.finalAcceDtm), "yyyy-MM-dd 오후 HH:mm") : "-";

    if (data.rowType === "groupHeader") {
      return null;
    }
    return <td>{acceDtm}</td>;
  };

  const rcpmsgDateFormatCell = data => {
    const rcpmsgDtm = data.dataItem.finalRcpmsgDtm ? kendo.toString(new Date(data.dataItem.finalRcpmsgDtm), "yyyy-MM-dd 오후 HH:mm") : "-";

    if (data.rowType === "groupHeader") {
      return null;
    }
    return <td>{rcpmsgDtm}</td>;
  };

  const accumPowerCell = data => {
    // const field = data.field;
    let value = "-";
    if (data.dataItem.accumPower >= 0) {
      // value = kendo.format('{0:n0}', data.dataItem[field]);
      value = numFormat(data.dataItem.accumPower);
    }
    return <td style={{ textAlign: "right" }}>{value}</td>;
  };

  // const serverDiffCell = data => {
  //   var value = "-";
  //   if (data.dataItem.serverDiff >= 0) {
  //     value = data.dataItem.serverDiff;
  //   }
  //   return <td>{value}</td>;
  // };
  //
  // const rcpmsgDiffCell = data => {
  //   var value = "-";
  //   if (data.dataItem.rcpmsgDiff >= 0) {
  //     value = data.dataItem.rcpmsgDiff;
  //   }
  //   return <td>{value}</td>;
  // };

  const rgnTermCell = data => {
    var value = data.dataItem.rgnTerm;
    return <td>{value}</td>;
  };

  const expctCell = data => {
    var value = data.dataItem;
    if (value.meainStatCodeNo === 7007) {
      return (
        <td style={{ color: "red" }}>
          해당 설비 상태는 고장이며, 즉각적인 점검이 필요합니다.
        </td>
      );
    }
    if (value.precPrbbl > 40 && value.ensoTypeCode === 15001) {
      return <td>강수확률 {value.precPrbbl}%</td>;
    }
    if ((value.serverDiff < 7 && value.ensoTypeCode === 15003) || value.ensoTypeCode === 15002) {
      return <td>사용자에 의한 전원 OFF 의심</td>;
    }
    if (value.serverDiff <= 1) {
      return <td>재부팅으로 인한 일시적인 문제일 수 있음</td>;
    }
    if (value.serverDiff > 6) {
      return <td>서버접속시간 {value.serverDiff}일 경과 점검 요망</td>;
    }
    if (value.serverDiff === 0 && (value.rcpmsgDiff > 1 || value.rcpmsgDiff == null)) {
      return <td>485 통신에러 의심</td>;
    }
    return <td>서버접속이력없음</td>;
  };

  // 윈도우
  const [windowState, setWindowState] = useState({
    visible: false,
    etc: "",
    cid: "",
    ensoTypeCode: "",
  });

  // 윈도우 폼
  const update = data => {
    console.log("ETC = ", data.dataItem.etc);
    setWindowState(visible => {
      const item = { ...visible };
      item.visible = !windowState.visible;
      item.etc = data.dataItem.etc;
      item.cid = data.dataItem.cid;
      item.ensoTypeCode = data.dataItem.ensoTypeCode;
      return item;
    });
  };

  // 윈도우폼 취소
  const cancel = () => {
    setWindowState({
      visible: !windowState.visible,
    });
  };

  const updateCell = data => {
    return (
      <td>
        <button className="k-button k-button-icontext 22k-grid-add" onClick={() => update(data)}>
          수정
        </button>
      </td>
    );
  };

  const etcChange = e => {
    setWindowState(etc => {
      const item = { ...etc };
      item.etc = e.target.value;
      return item;
    });
  };

  const updateEtcSave = () => {
    const data = {
      cid: windowState.cid,
      ensoTypeCode: windowState.ensoTypeCode,
      etc: windowState.etc,
    };
    console.log("data = ", data);
    if (window.confirm("저장하시겠습니까?")) {
      updateEtc(conn, data)
        .then(result => {
          console.log("updateEtc result = ", result);
          kendo.alert("저장되었습니다.");
        })
        .catch(error => {
          kendo.alert("실패하였습니다.");
        });
    } else {
      kendo.alert("취소");
    }
  };

  return (
    <div className="md-card-content">
      <div className="md-card-toolbar">
        {/* <h3 className="md-card-toolbar-heading-text">미작동 이력</h3> */}
        <h3 className="md-card-toolbar-heading-text">미작동/고장 이력</h3>
        <div className="md-card-toolbar-actions">
          <Button className="nonOperHistExcelBtnClass" icon="excel" onClick={downloadExcel}>
            엑셀저장
          </Button>
        </div>
      </div>
      {windowState.visible && (
        <NonOperHistEtcUpdateWindow
          styles={styles}
          windowState={windowState}
          update={update}
          updateEtcSave={updateEtcSave}
          cancel={cancel}
          etcChange={etcChange}
        />
      )}
      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="ko">
          <Grid
            className="nonOperHistGrid"
            style={{ height: "320px" }}
            data={orderBy(dataSource.nonOperHistData, sort)}
            // data = {dataSource.nonOperHistData}
            total={dataSource.total}
            skip={paging.skip}
            take={paging.take}
            page={paging}
            onPageChange={onPageChange}
            sort={sort}
            onSortChange={onSortChange}
            sortable
            pageable={{
              type: "numeric",
              pageSizes: [5, 10, 20, 50, 100],
              previousNext: true,
              buttonCount: 5,
            }}
            // reorderable={true}
          >
            <Column title="No" width="60px" cell={noCell} editable={false}></Column>
            <Column
              title="지역"
              width="120px"
              field="rgnTerm"
              cell={rgnTermCell}
              // editable={false}
            ></Column>
            <Column
              title="CID"
              width="120px"
              field="cid"
              // editable={false}
            ></Column>
            <Column
              title="설비명"
              width="300px"
              field="alias"
              // editable={false}
            ></Column>
            <Column
              title="에너지원"
              width="90px"
              field="ensoTypeTerm"
              // editable={false}
            ></Column>
            <Column
              title="설비모델"
              width="130px"
              field="meainTypeTerm"
              // editable={false}
            ></Column>
            <Column title="인버터제조사" width="160px" field="makrTerm" editable={false}></Column>
            <Column title="최종서버접속일시" width="170px" field="finalAcceDtm" cell={accDateFormatCell} editable={false}></Column>
            <Column title="최종데이터수신일시" width="170px" field="finalRcpmsgDtm" cell={rcpmsgDateFormatCell} editable={false}></Column>
            {/* <Column title="서버미접속 일수" width="150px" field="serverDiff" cell={serverDiffCell} editable={false}></Column> */}
            {/* <Column title="데이터미수신 일수" width="150px" field="rcpmsgDiff" cell={rcpmsgDiffCell} editable={false}></Column> */}
            <Column title="누적발전량(kWh)" width="120px" field="accumPower" cell={accumPowerCell} editable={false}></Column>
            <Column title="통신방식" width="110px" field="communicationMthd" editable={false}></Column>
            <Column title="LORA ID" width="150px" field="loraDevid" editable={false}></Column>
            <Column title="예상고장내역" width="350px" field="exptc" cell={expctCell} editable={false}></Column>
            <Column title="비고" width="350px" field="etc"></Column>
            <Column title="" width="110px" cell={updateCell}></Column>
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
};
export default NonOperHistGrid;
