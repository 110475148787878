import React from "react";

const GeoJecheonThree = props => {
  const { mapClickEvt, mapOutEvt, mapOverEvt, /*goDepth, depthMapOverEvt, depthMapOutEvt*/ } = props;
  return (
    <svg
      version="1.1"
      id="레이어_1"
      x="0px"
      y="0px"
      width="1030px"
      height="950px"
      viewBox="0 0 1030 950"
      enable-background="new 0 0 1030 950">
      <g>
        <polygon
          fill="#999999"
          points="762.412,752.496 748.932,752.496 762.44,765.996 775.912,765.996 789.42,752.496 775.912,752.496 	
     "
        />
        <path
          fill="#999999"
          d="M856.918,253.069v13.496h-13.482v-13.496h-13.5v-13.495h-13.504h-13.486V226.07h-13.48h-13.514v-13.486
     l-13.49-13.504h-13.514v-13.486l-13.486-13.504l0.004-0.026h-13.477l-13.512,13.504l-13.492,26.999v13.486H681.5h-13.504h-13.488
     l-27.012,80.996v13.495h-0.021v-13.46l27.012-80.996l-13.512-13.504h-13.49V199.08v-13.486h13.49l-13.49-13.504v-13.486V145.1
     h-13.488h-13.502h-13.486h-13.492v-13.486h-13.512V145.1h-13.482h-0.041l0.002-0.004h-13.504v0.031h0.031V158.6h-0.031v-13.473
     h-0.039h-13.482h-13.498h-13.504h-13.487h-13.504h-13.485h-13.491h-13.517v13.504h-13.487v13.486l13.487,13.504h-13.487h-13.481
     v13.486v13.504H384.49v13.495v13.496l-13.482-13.496v13.496v13.495l-13.53,13.496H344v-0.026h-13.486h-13.512v26.999v13.496v13.495
     v13.486l-13.483,13.512l-13.499,13.491v13.5l-13.504,13.491v13.48v13.496v13.517l-13.486,13.495v13.487v26.998v13.517v26.982
     L249.526,536.5l-13.486,26.986h13.486h13.504h13.474v0.066h-13.487h-13.503h-13.487v26.998v13.516l-13.494,13.482l-13.508,13.5
     l-13.486,13.498l-13.482,13.492l-13.512,13.5v26.998l-13.492,13.494l-13.516,13.496v13.5l-13.486,13.494h13.486h13.516v13.494
     h13.492v13.496l26.998,13.494h13.486l13.513,13.5h13.491h13.486h13.504h13.486h13.503h13.499h13.482h13.513h13.485L357.473,820
     v13.494h13.53v13.494h13.483h13.512l13.481-13.494h13.487h27.016L492.469,820h13.504h13.498l13.486-13.494h13.504h13.486
     L573.451,820l13.498-13.494h13.482l13.514-13.5h13.48v-13.494h-13.48l13.48-13.496h-13.48h-13.514h-13.482v-13.494h-13.498h-13.504
     v-13.494h-13.486l-13.504-13.5h-13.486l-13.498-13.496h-13.504v-13.494h-13.488v-13.5v-13.49h-13.504h-13.485H438.5v-13.5h-13.517
     h-13.487l40.495-121.48l-13.513,0.004h-13.517h-13.487h-13.482h-13.511h-13.483h-13.529l-13.482-13.5h-13.473l0.018-0.018h-13.504
     v13.479l-0.039,0.039h-13.47v-0.063h13.483v-13.494h13.512v-13.512H344v-13.47h13.482h13.529v-13.517v-13.49h13.482v-13.5v-13.482
     l13.513-13.5v-13.516h13.481v-13.49v-13.486v-13.487h13.486v-13.502v-13.487l13.516-13.515l40.486,27.01h27.004v-13.487v-13.517
     l-13.508-13.482l27.012-67.501h13.486l0.014,0.004h-13.467l-27.012,67.501l13.512,13.479l-0.004-0.002v13.517v13.487l13.492,13.504
     l-13.492,26.981v27.016l-13.512,26.981v13.499l5.631-5.625l-5.631,5.63h-0.021v-13.491l13.514-26.98v-27.018l13.49-26.98
     l-13.49-13.503l0.004,0.025h-26.99l-40.499-27.016l-13.517,13.517v13.486V374.6h-13.481v13.49v13.486v13.499h-13.487v13.509
     l-13.508,13.5v13.48v13.5H371.03v13.5v13.508h-13.53h-13.481v13.477h-13.486v13.504h13.486l13.481,13.5h13.53h13.486h13.508h13.487
     h13.481h13.517H452l13.485-13.5v-13.504l13.513-40.484h0.02l-13.506,40.477v13.504l-13.482,13.5l-40.493,121.48h13.481h13.517v13.5
     h13.495h13.482h13.509v13.49v13.498h13.481v13.5h13.512l13.492,13.492h13.486l13.508,13.498h13.482v13.492h13.508h13.496v13.5
     h13.484h13.508h13.488l-13.488,13.494h13.488h27.012h13.486l13.508-13.494h13.486l13.492-13.5h13.488v-13.492v-13.498h0.049v13.473
     v13.494h13.486h13.482h13.512h13.492h13.512h26.973h27.012l13.486-13.494v-13.496v-13.494h-13.486v-13.494l-13.49-27
     l-13.514-13.498v-13.492v-13.498v-13.5v-13.482v-13.516v-13.492l13.514-13.5v-13.486v-13.49l13.49-13.498v-13.505v-13.478h13.486
     v-13.508v-13.5v-13.499V442.07v-13.5l-13.486-13.508v-13.5h-13.49h-13.514V388.08v-13.49h-0.021v-0.053v-13.503h13.504v-13.49h13.5
     h13.482v-13.513l13.512-13.486v-13.495v-13.495v-13.496v-13.495v-13.496H856.918z M303.506,550.07h-13.5h-13.49v-0.066h13.504
     h13.486V550.07z M613.989,320.588h0.023v13.442h13.484v13.513h13.496H654.5v13.49h13.486v13.503h13.504h13.486h13.49l13.514,13.487
     h13.48h13.486l13.504,13.476v0.063L748.94,388.08h-13.486h-13.482l-13.512-13.494v13.494v13.482h-13.49v13.492v13.508v13.498
     h-13.488v13.482v13.496h-0.008v-13.5v-13.482h13.486v-13.498v-13.518V401.55h13.492v-13.487v-13.484h-13.492h-13.486h-13.508
     v-13.504h-13.486v-13.487h-13.512h-13.492V334.07h-13.486V320.588z M560.004,172.09l13.512,13.504l26.986,13.486l-26.986,67.501
     l-0.039-0.013l26.99-67.493l-26.994-13.486l-13.5-13.5H560.004z M586.998,374.582v13.48v13.487h-0.008v-13.482v-13.485H586.998z
      M573.508,401.563v13.479h-0.01v-13.479H573.508z M559.994,415.063v13.496h-0.008v-13.496H559.994z M546.483,158.604h0.039v13.482
     h-0.039V158.604z M532.992,428.57h0.014v13.486h-0.014V428.57z M519.506,442.07h0.012v13.469h-0.012V442.07z M600.487,536.512
     l-0.041,0.041v-13.5h-13.482v-13.504h-13.498h-13.504v-13.477h-13.486h-13.504v-13.508h-13.486l-13.49-13.5v-13.493l0.02-0.02
     h0.014v13.487l13.49,13.489h13.488v13.517h13.504h13.484v13.475h13.514h13.49v13.508h13.488V536.512z M600.487,374.578h-0.012
     v-13.5l0.008-0.008l0.004,0.003v-0.004v-0.003l13.492-13.475l0.003-0.004h0.007l-13.502,13.487V374.578z M627.442,523.053h-13.479
     l0.025-0.025h13.453V523.053z M627.442,496.072h-13.453v-0.027h13.486h13.492l13.512,13.475h13.486h13.508v-13.475v-13.517h13.486
     v-13.485h0.01v13.495h-13.488v13.513v13.477h-13.502v13.504v13.5h-0.053v-13.479h-13.482l13.482-13.504h-13.482l-13.514-13.477
     H627.442z M748.957,725.482h-0.049v-13.469h0.049V725.482z M762.469,698.494v13.494h-0.057v-13.475l13.5-13.498h-13.5v-13.49v-13.5
     h-13.504l-13.486-13.49H721.94l26.975-67.498l-53.994-13.5l-40.477-26.984h0.081l40.452,26.963l53.984,13.5l-26.973,67.498h13.486
     l13.482,13.49h13.512v13.498v13.492h13.492L762.469,698.494z M802.938,401.563h-13.482l-13.514-13.482h-13.48v-0.056h13.49
     l13.514,13.485h13.473V401.563z M816.424,388.08h-13.479v-0.056h13.479V388.08z"
        />
        <polygon fill="#999999" points="667.965,523.027 667.965,509.52 654.479,523.027 	" />
      </g>
      <g>
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315010800"
          className="land land_A"
          fill="#E1E1E1"
          points="422.97,504.951 435.577,492.33 435.577,479.203 449.509,437.463 449.529,437.463 449.532,437.454 
     450.613,437.454 450.613,437.449 462.572,437.449 462.572,425.108 476.085,398.127 476.085,371.111 489.251,344.781 
     476.99,332.506 450.146,332.506 410.292,305.921 398.083,318.129 398.083,345.998 384.603,345.998 384.603,386.474 
     371.116,386.474 371.116,399.104 357.608,412.604 357.608,440.463 344.121,440.463 344.121,467.471 317.109,467.471 
     317.109,480.947 303.623,480.947 303.623,491.451 316.232,491.451 327.562,502.796 327.562,492.969 327.591,492.969 
     327.591,492.951 329.091,492.951 330.591,492.951 330.591,504.951 	"
        />
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315010600"
          className="land land_B"
          fill="#E1E1E1"
          points="489.597,423.937 489.597,410.469 489.611,410.469 489.611,410.455 503.083,410.455 
     503.083,396.969 503.097,396.969 503.097,396.957 530.077,396.957 530.077,383.461 530.087,383.461 530.087,383.439 
     543.591,383.439 543.591,369.961 543.599,369.961 543.599,369.948 557.081,369.948 557.081,342.98 557.089,342.98 557.089,342.976 
     570.568,342.976 570.568,330.354 574.087,326.838 581.935,318.985 573.091,318.985 569.466,328.016 558.708,319.489 
     545.101,333.092 530.966,318.985 504.243,318.985 479.107,306.395 479.107,330.347 492.923,344.176 479.107,371.807 
     479.107,398.824 465.593,425.805 465.593,435.331 468.382,432.545 476.118,424.809 476.118,423.937 476.99,423.937 
     479.085,421.842 479.085,423.937 	"
        />
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315010700"
          className="land land_C"
          fill="#E1E1E1"
          points="519.573,127.002 519.615,127.002 519.615,140.484 532.181,140.484 532.185,140.488 
     532.216,140.488 545.995,154.26 573.999,168.254 545.976,238.35 545.935,238.337 545.931,238.346 504.331,224.485 492.126,224.485 
     465.859,290.125 478.497,302.733 504.948,315.981 532.206,315.981 545.099,328.848 558.474,315.476 559.583,316.354 568.3,322.783 
     570.568,317.216 571.064,315.981 584.937,315.981 595.443,305.468 584.079,305.468 584.079,288.987 584.103,288.987 
     584.103,288.943 597.566,288.943 597.566,276.739 624.361,196.392 611.945,183.982 597.575,183.982 597.575,153.993 
     608.948,153.993 597.575,142.609 597.575,116.499 543.607,116.499 543.607,103.012 533.095,103.012 533.095,116.499 
     518.056,116.499 518.056,116.525 506.101,116.525 506.101,126.998 519.573,126.998 	"
        />
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315011000"
          className="land land_D"
          fill="#E1E1E1"
          points="595.4,737.414 557.042,737.414 557.042,723.92 530.038,723.92 530.038,710.426 517.431,710.426 
     503.927,696.926 490.441,696.926 476.943,683.43 462.56,683.43 462.56,669.936 449.073,669.936 449.073,642.945 408.592,642.945 
     408.592,629.445 381.007,629.445 421.501,507.966 330.591,507.969 330.591,510.076 330.562,510.046 330.562,510.094 
     328.439,507.969 327.562,507.969 327.562,507.09 314.956,494.469 302.727,494.469 290.118,507.072 290.118,507.951 
     289.218,507.951 289.2,507.969 276.611,507.969 276.611,521.402 276.598,521.402 276.598,521.469 249.608,521.469 249.608,534.885 
     249.595,534.885 249.595,534.951 209.118,534.951 209.118,574.587 141.634,642.06 141.634,669.057 114.626,696.048 
     114.626,709.547 103.26,720.92 128.144,720.92 128.144,734.414 141.634,734.414 141.634,748.483 167.488,761.404 181.24,761.404 
     194.753,774.904 316.204,774.904 330.566,789.277 330.566,801.893 344.095,801.893 344.095,815.387 368.968,815.387 
     382.45,801.893 423.331,801.893 463.818,788.398 490.441,788.398 503.927,774.904 532.159,774.904 545.042,787.777 
     557.921,774.904 571.404,774.904 584.915,761.404 597.517,761.404 597.517,750.91 581.919,750.91 	"
        />
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315010100"
          className="land land_E"
          fill="#E1E1E1"
          points="330.573,248.484 330.573,261.979 357.581,261.979 357.586,261.979 357.586,288.969 
     398.063,288.969 398.067,288.969 398.067,302.456 409.515,302.456 409.909,302.06 410.503,302.456 410.534,302.456 
     413.704,302.456 412.436,303.725 451.023,329.468 476.072,329.468 476.072,304.586 462.304,290.845 490.06,221.481 
     504.806,221.481 504.82,221.485 504.841,221.485 544.2,234.597 570.154,169.699 544.175,156.721 530.943,143.488 516.615,143.488 
     516.615,143.484 516.573,143.484 516.573,130.002 503.101,130.002 503.101,129.998 503.07,129.998 503.07,116.525 398.063,116.525 
     398.063,130.029 384.575,130.029 384.575,141.394 400.181,157.019 371.095,157.019 371.095,184.009 357.581,184.009 
     357.581,213.123 344.099,199.627 344.099,223.616 329.69,237.989 317.092,237.989 317.092,248.484 330.57,248.484 	"
        />
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315010500"
          className="land land_E"
          fill="#E1E1E1"
          points="830.009,224.467 830.009,237.963 813.527,237.963 813.527,224.467 800.027,224.467 
     800.027,210.973 773.036,210.973 773.036,197.469 746.042,197.469 746.042,183.104 733.431,170.479 719.038,170.479 
     719.038,156.113 706.404,143.462 694.2,143.462 681.3,156.355 668.075,182.819 668.075,197.452 627.181,197.452 600.587,277.191 
     600.587,291.943 600.566,291.943 600.566,291.987 587.103,291.987 587.103,302.429 598.48,302.429 600.566,300.341 
     600.566,302.429 600.587,302.429 600.587,302.468 602.681,302.468 600.587,304.567 600.587,315.942 627.591,315.942 
     627.591,329.432 641.077,329.432 641.077,342.936 678.55,342.936 678.55,340.867 680.622,342.936 680.679,342.936 680.718,342.976 
     681.542,342.976 681.542,343.799 694.193,356.423 721.159,356.423 732.542,367.783 732.542,356.479 732.552,356.479 
     732.552,356.423 748.163,356.423 759.546,367.783 759.546,357.979 759.556,357.979 759.556,357.923 761.056,357.923 
     762.556,357.923 762.556,369.909 773.029,369.909 773.029,356.479 773.036,356.479 773.036,356.423 786.515,356.423 
     786.515,344.489 786.523,344.489 786.523,329.432 800.027,329.432 800.027,315.942 827.009,315.942 827.009,303.307 
     840.521,289.821 840.521,224.467 	"
        />
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315010200"
          className="land land_E"
          fill="#E1E1E1"
          points="246.595,518.469 246.608,518.469 246.608,518.402 273.598,518.402 273.598,504.969 
     273.611,504.969 273.611,504.906 287.093,504.906 287.093,491.412 300.605,491.412 300.605,477.9 314.092,477.9 314.092,464.431 
     341.104,464.431 341.104,437.424 354.586,437.424 354.586,411.32 368.099,397.82 368.099,383.425 381.579,383.425 381.579,342.962 
     395.067,342.962 395.067,319.007 383.103,319.007 383.103,318.973 383.079,318.973 383.079,317.473 383.079,315.973 
     395.945,315.973 406.462,305.456 395.067,305.456 395.063,305.456 395.063,291.969 354.586,291.969 354.581,291.969 
     354.581,264.979 327.573,264.979 327.57,264.979 327.57,251.484 314.092,251.484 314.087,251.484 314.087,237.963 290.093,237.963 
     290.093,304.559 263.113,331.563 263.113,345.062 249.608,358.553 249.608,399.046 236.122,412.542 236.122,493.526 
     222.351,507.287 210.058,531.885 246.595,531.885 	"
        />
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315010400"
          className="land land_E"
          fill="#E1E1E1"
          points="597.54,307.621 586.202,318.985 586.197,318.985 577.097,328.072 573.577,331.594 573.577,345.976 
     573.568,345.976 573.568,345.98 560.089,345.98 560.089,372.948 560.081,372.948 560.081,372.961 546.599,372.961 546.599,386.439 
     546.591,386.439 546.591,386.461 533.087,386.461 533.087,399.957 533.077,399.957 533.077,399.969 506.097,399.969 
     506.097,413.455 506.083,413.455 506.083,413.469 492.611,413.469 492.611,426.937 492.597,426.937 492.597,426.95 479.118,426.95 
     479.118,438.316 491.732,450.927 506.097,450.927 506.097,464.443 533.087,464.443 533.087,477.918 560.089,477.918 
     560.089,491.426 573.577,491.426 573.577,502.791 584.925,491.451 584.933,491.451 584.958,491.426 597.532,491.426 
     597.532,480.54 589.204,477.859 588.816,477.736 588.814,477.734 583.779,476.113 571.052,478.486 570.814,464.326 
     590.948,464.326 590.948,464.443 613.38,464.443 613.845,465.174 626.779,478.071 628.991,478.537 630.595,478.665 
     630.554,478.866 630.556,478.867 630.251,480.31 627.917,491.426 638.058,491.426 638.07,479.848 638.07,477.926 638.072,477.926 
     638.072,477.918 651.564,477.918 651.564,450.927 665.05,450.927 665.05,440.441 651.572,440.441 651.572,440.438 651.564,440.438 
     651.564,410.455 665.05,410.455 665.05,369.948 678.542,369.948 678.542,345.976 638.056,345.976 638.056,332.472 624.57,332.472 
     624.57,318.985 597.566,318.985 597.566,307.595 	"
        />
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315010300"
          className="land land_E"
          fill="#E1E1E1"
          points="813.548,372.961 786.544,372.961 786.544,359.479 776.036,359.479 776.036,372.909 
     776.029,372.909 776.029,372.961 762.556,372.961 762.556,375.025 762.546,375.015 762.546,375.077 760.427,372.961 
     759.546,372.961 759.546,372.083 746.913,359.479 735.552,359.479 735.552,375.025 735.542,375.015 735.542,375.077 
     719.911,359.479 692.941,359.479 681.55,348.103 681.55,372.961 668.06,372.961 668.06,413.459 654.572,413.459 654.572,437.438 
     680.042,437.438 680.042,438.938 680.042,440.438 668.06,440.438 668.06,453.937 654.572,453.937 654.572,480.926 641.07,480.926 
     641.07,507.93 641.017,507.93 641.017,507.951 631.072,507.951 667.185,532.021 722.583,545.876 695.794,612.912 707.687,612.912 
     721.171,626.402 735.56,626.402 735.56,653.393 751.173,653.393 735.56,669.014 735.56,683.387 735.503,683.387 735.503,683.412 
     722.048,683.412 722.048,696.881 721.999,696.881 721.999,696.904 695.079,696.904 695.079,720.871 785.111,720.871 
     814.427,720.871 827.034,708.256 827.034,683.387 813.548,683.387 813.548,668.746 800.325,642.281 786.544,628.517 
     786.544,546.292 800.058,532.792 800.058,505.816 813.548,492.318 813.548,464.457 827.034,464.457 827.034,399.09 
     813.548,385.582 	"
        />
        <polygon
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4315010900"
          className="land land_E"
          fill="#E1E1E1"
          points="692.031,723.893 692.031,693.904 692.079,693.904 692.079,693.881 718.999,693.881 718.999,680.412 
 719.048,680.412 719.048,680.387 732.503,680.387 732.503,667.791 732.56,667.734 732.56,665.867 742.876,656.295 732.503,656.295 
 732.503,629.424 719.878,629.424 706.392,615.934 691.316,615.934 718.476,547.974 665.896,534.828 665.679,534.684 
 621.081,504.951 621.161,504.951 621.128,504.93 638.017,504.93 638.017,494.451 624.183,494.451 626.954,481.386 620.284,480.854 
 611.753,467.471 587.948,467.471 587.948,467.443 586.84,467.443 586.842,467.431 574.168,467.431 574.168,475.603 584.921,473.046 
 590.46,475.111 600.566,478.32 600.566,494.426 600.532,494.426 600.532,494.451 586.175,494.451 570.577,510.047 570.577,510.03 
 570.536,510.071 570.536,494.451 557.054,494.451 557.054,480.947 530.052,480.947 530.052,467.471 503.062,467.471 
 503.062,453.963 490.454,453.963 476.085,439.584 476.085,429.089 470.261,434.908 465.593,439.575 465.593,440.449 
 465.572,440.449 465.572,440.463 451.691,440.463 438.605,479.684 438.605,493.564 424.933,507.253 385.209,626.424 
 411.626,626.424 411.626,639.924 452.113,639.924 452.113,666.912 465.593,666.912 465.593,680.412 478.228,680.412 
 491.718,693.904 505.204,693.904 518.714,707.402 533.073,707.402 533.073,720.895 560.077,720.895 560.077,734.395 
 602.679,734.395 589.191,747.889 638.935,747.889 652.443,734.395 665.929,734.395 675.632,724.688 705.564,726.38 722.509,731.748 
 763.745,731.748 778.95,723.872 "
        />
      </g>
      <g>
        <text
          className="land_E"
          transform="matrix(1 0 0 1 410.4509 215.9302)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          의림동
        </text>
        <text
          className="land_E"
          transform="matrix(1 0 0 1 693.7073 279.0869)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          교동
        </text>
        <text
          className="land_C"
          transform="matrix(1 0 0 1 502.0002 278.0869)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          중앙로2가
        </text>
        <text
          className="land_B"
          transform="matrix(1 0 0 1 489.2512 360.0869)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          중앙로1가
        </text>
        <text
          className="land_E"
          transform="matrix(1 0 0 1 286.2512 382.0869)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          서부동
        </text>
        <text
          className="land_A"
          transform="matrix(1 0 0 1 394.2395 415.6143)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          명동
        </text>
        <text
          className="land_D"
          transform="matrix(1 0 0 1 286.2512 667.9277)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          영천동
        </text>
        <text
          className="land_E"
          transform="matrix(1 0 0 1 545.1096 609.9277)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          화산동
        </text>
        <text
          className="land_E"
          transform="matrix(1 0 0 1 566.1536 415.6143)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          남천동
        </text>
        <text
          className="land_E"
          transform="matrix(1 0 0 1 713.8118 489.3008)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          동현동
        </text>
      </g>
    </svg>
  );
};

export default GeoJecheonThree;
