// 설치 유형 15001 (설치유형별 분석 타입)
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat, roundNum } from '../../../../com/dataUtil';

const InstTypeChart = ({instTypeDs}) => {
    // 차트 옵션
    const chartConfig = {
        chart: {
            marginTop: 50,
            type: 'column'
        },
        credits: { enabled: false },
        title: { text: null },
        xAxis: { categories: ['최저효율','평균효율','최고효율'], crosshair: true ,labels : { style: { color: '#000000' } }  },
        yAxis: {
            min: 0,
            labels: {
              style: { color: '#000000' }
            },
            title: {
                text: '발전효율 (%)',
                rotation: 0,
                y: -20,
                offset: 0,
                align: 'high',
                style: { color: '#000000' }
            }
        },
        legend :{backgroundColor:
            (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
            "#FFFFFF",
        },
        tooltip: {
          formatter: function () {
            return(
              `
                <span style="color:#000000"><b>${this.x}</b></span><br>
                <span style="color:${this.points[0].point.color}">\u25CF</span>
                <span style="color:#000000">${this.points[0].series.name}</span> : <b>${numFormat(roundNum(this.points[0].y , 2 ))} %</b><br>
                <span style="color:${this.points[1].point.color}">\u25CF</span>
                <span style="color:#000000">${this.points[1].series.name}</span> : <b>${numFormat(roundNum(this.points[1].y , 2 ))} %</b><br>
                <span style="color:${this.points[2].point.color}">\u25CF</span>
                <span style="color:#000000">${this.points[2].series.name}</span> : <b>${numFormat(roundNum(this.points[2].y , 2 ))} %</b><br>
                <span style="color:${this.points[3].point.color}">\u25CF</span>
                <span style="color:#000000">${this.points[3].series.name}</span> : <b>${numFormat(roundNum(this.points[3].y , 2 ))} %</b><br>
                <span style="color:${this.points[4].point.color}">\u25CF</span>
                <span style="color:#000000">${this.points[4].series.name}</span> : <b>${numFormat(roundNum(this.points[4].y , 2 ))} %</b><br>
                <span style="color:${this.points[5].point.color}">\u25CF</span>
                <span style="color:#000000">${this.points[5].series.name}</span> : <b>${numFormat(roundNum(this.points[5].y , 2 ))} %</b><br>
              `
            );
          },
          shared: true,
          useHTML: true
        },
        plotOptions: {
            column: { pointPadding: 0.2, borderWidth: 0
              , dataLabels: { 
                enabled: true ,
                color : '#000000',
                style: { textShadow: false },
                formatter: function() { 
                  return '<span><b>' + numFormat(roundNum(this.y , 2)) + '</b></span>' 
                }
              } 
            }
        },
        series: [
              { name: '지상형', data: instTypeDs.groundEfic , color : '#0082ff'}
            , { name: '경사지붕형', data: instTypeDs.pitchedEfic , color : '#DC143C'}
            , { name: '평슬라브형', data: instTypeDs.slabEfic , color : '#32CD32'}
            , { name: '곡면형', data: instTypeDs.curvedEfic , color : '#DAA520'}
            , { name: 'BIPV', data: instTypeDs.biEfic , color : '#6A5ACD'}
            , { name: '미분류', data: instTypeDs.nullEfic , color : '#FF69B4'}
        ]
    };

    return(
        <div>
            <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
        </div>
    )
}

export default InstTypeChart;