import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

const GathRawDataLogGridHeat = ({ search, paging, setOperHistData , cellKeyFormat , cellFormat }) => {

  // 페이징 이벤트
  const onPageChange = e => {
    let { take, skip } = e.page;
    // 검색 안되도록 수정 _ 20220216 _ jhchoi
    if(search.cid === "" && search.userId === ""){
      return;
    }
    paging.take = take;
    paging.skip = skip;
    setOperHistData(search, paging);
  };

  // 정렬 이벤트
  const onSortChange = e => {
    const { sort } = e;
    // 검색 안되도록 수정 _ 20220216 _ jhchoi
    if(search.cid === "" && search.userId === ""){
      return;
    }
    paging.sort = sort;
    setOperHistData(search, paging);
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              id="heatGrid"
              key={15002}
              className="operHistGrid"
              data={paging.list}
              resizable={true}
              pageable={{
                type: "numeric",
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true,
                buttonCount: 5,
                refresh: true,
              }}
              total={paging.count}
              skip={paging.skip}
              take={paging.take}
              pageSize={paging.take}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              sort={paging.sort}
              sortable>
              <GridNoRecords>{"  "}</GridNoRecords>
              <GridColumn title="CID" field="cid" width="130px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="설치자" field="userTerm" width="200px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="수집일자" field="gathDtm" width="170px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="dataStatSctCodeTerm" title="데이터상태" width="140px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="accumConhQty" title="누적생산량(kcal)" width="120px" cell={cellFormat}></GridColumn>
              <GridColumn field="accumUseHeatQty" title="누적사용열량(kcal)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="socoInwtrTemp" title="집열기 입구온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="socoOlutwtrTemp" title="집열기 출구온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="hstUppartTemp" title="축열조 상부온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="hstDnpartTemp" title="축열조 하부온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="wasupPlaTemp" title="급수배관온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="waterheaterPlaTemp" title="급탕배관온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="oilQty" title="유량(LPM)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="cnsmOilQtyVal" title="소비유량(LPM)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="errCode" title="에러코드" width="150px" cell={cellFormat}></GridColumn>
            <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default GathRawDataLogGridHeat;
