export const get = async (conn, qnaNo) => {
  const response = await conn.get("bbs/qnaReply/" + qnaNo, {
    params: {
      qnaNo: qnaNo,
    },
  });
  return response;
};

export const setAddQna = async (conn, obj) => {
  const response = await conn.post("bbs/qnaReply/addQestn", obj, {
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export const setAddQnaReply = async (conn, obj) => {
  const response = await conn.post("bbs/qnaReply/addQestnReply", obj, {
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};
