import { Button } from "@progress/kendo-react-buttons";

const NotiSearch = ({ styles, search, appChange, appKeyPress, searchHandler, isMobile }) => {
  return (
    <>
      {isMobile ? (
        <div className={styles.mobileSearchSubTitleD}>
          <input
            type="text"
            name="title"
            value={search.title}
            className={styles.searchTerm}
            placeholder="제목을 입력해주세요"
            onChange={appChange}
            onKeyPress={appKeyPress}></input>
          <Button className={styles.notiBtn} onClick={searchHandler} icon="search"></Button>
          &nbsp;&nbsp;
        </div>
      ) : (
        <div className={styles.searchSubTitleD}>
          제목검색&nbsp;&nbsp;
          <input
            type="text"
            name="title"
            value={search.title}
            className={styles.searchTerm}
            placeholder="제목을 입력해주세요"
            onChange={appChange}
            onKeyPress={appKeyPress}></input>
          <Button className={styles.notiBtn} onClick={searchHandler} icon="search"></Button>
          &nbsp;&nbsp;
        </div>
      )}
    </>
  );
};
export default NotiSearch;
