import axios from "axios";
import { authGuest, setToken, getToken, setDelToken, getUser } from "./service/authService";
import { useState } from "react";

export const useCreateAxiosInstance = () => {
  const [isError, setIsError] = useState(false);
  const conn = axios.create({
    baseURL: `${process.env.REACT_APP_REST_API_VERSION}`,
    config: {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  });

  conn.interceptors.request.use(
    config => {
      const token = getToken();
      if (token) config.headers.common["X-AUTH-TOKEN"] = token;
      return config;
    },
    error => {
      console.log("token request failed", error);
      return Promise.reject(error);
    },
  );

  conn.interceptors.response.use(
    response => {
      const result = response.data;
      if (result.code === "T000" || result.code === "T001") {
        console.log("ERROR", result);
        // 사용자 정보
        const user = getUser();
        authGuest(conn, "INVALIDTOKEN", user).then(result => {
          setToken(result.token);
          // if(kendo.confirm("데이터를 조회하지 못하였습니다. 새로고침 하시겠습니까?"))
          window.location.href = "/"; // 요거 확인 필요 jhchoi : 요거 주석으로 때문에 "로그인중..." 이 안넘어가는 문제 발생
        });
      } else if (result.code === "T002") {
        window.location.href = "/"; // 요거 확인 필요 jhchoi : 요거 주석으로 때문에 "로그인중..." 이 안넘어가는 문제 발생
        // alert("로그인 후 이용가능합니다.");
      }

      if (result.status !== 200) {
        // throw { errCode: result.code };
        throw Object.assign(new Error(), { errCode: result.code });
      }

      return result.data;
    },
    async function (error) {
      console.log("response error", error);
      setDelToken();
      setIsError(true);
      return Promise.reject(error);
    },
  );

  return { conn, isError, setIsError };
};
