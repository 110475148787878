// 설비 정보 풍력
import React , {useState , useEffect} from "react";
import kendo from '@progress/kendo-ui';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import styles from './meain.module.css';
import DateTimePickerKo from '../../com/dateInputs/dateTimePickerKo';
import DatePickerKo from '../../com/dateInputs/datePickerKo';
import { Input } from '@progress/kendo-react-inputs';

// 풍력 설비 정보
function MeainInfoVelo({ selectMeain , setMeainInfo  , codeList , meainModlList, inputValidMsg , itemRender }){
    // console.log("MeainInfoVelo selectMeain = ", selectMeain);

  // const selectMeain = paging.selectMeain;
  const [generMakr , setGenerMakr] = useState( selectMeain.generMakrNo ? { makrSeriNo : selectMeain.generMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '' } );
  const [meainTypeSeri , setMeainTypeSeri] = useState( selectMeain.meainTypeSeriNo ? { meainTypeSeriNo : selectMeain.meainTypeSeriNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [invtMakr , setInvtMakr] = useState( selectMeain.invtMakrNo ? { makrSeriNo : selectMeain.invtMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '' } );
  const [invtType , setInvtType] = useState( selectMeain.invtTypeNo ? { meainTypeSeriNo : selectMeain.invtTypeNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [bldSrvCodeNo , setBldSrvCodeNo] = useState( selectMeain.bldSrvCodeNo ? { codeNo : selectMeain.bldSrvCodeNo , codeVal : "" } : { codeNo : '' , codeVal : '' } );
  const bldSrvDs = codeList.filter( c => c.grpCodeNo === 31 );

  // 풍력 발전기 제조사
  const generMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter(c => Number(c.makrClsfNo) === 30007) : [];
  // 풍력 발전기 모델 
  const meainTypeSeriList = meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter(c=> c.ensoTypeCodeNo === 15004 && c.makrSeriNo === selectMeain.generMakrNo) : []; 
  // 풍력 인버터 제조사
  const invtMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter(c => c.makrClsfNo === 30008) : [];
  // 풍력 인버터 모델 
  const invtTypeList = meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter(c=> c.ensoTypeCodeNo === 15004 && c.makrSeriNo === selectMeain.invtMakrNo) : []; 

  // Date 데이터 값 변경 이벤트
  const onchageDate = (v , name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const obj = {
        ...selectMeain
        ,  [name] : kendo.toString(v , 'yyyy-MM-dd') 
    };
    setMeainInfo(obj);
  }

  // Input 데이터 값 변경 이벤트
  const onchageInput = (e) => {
    let obj = {};
    const objName = e.target.name;
    obj = {
      ...selectMeain
      ,  [objName] : e.target.value
    };
    setMeainInfo(obj);
 }

  // DateTimeOnChangeDate
  const onChangeDateTiem = (v , name) =>{
    const obj = {
      ...selectMeain
      ,  [name] : v
      // ,  [name] : kendo.toString(v , 'yyyy-MM-dd HH:mm:00')
    };
    setMeainInfo(obj);
  }

  // 날짜 포맷 지정
  const dateFormat = (date) =>{
    if( typeof date !== "undefined" ){
        if( date !== "" ){ date = new Date(date); }
        else{ date = ""; } }
    else{ date = ""; }
    return date;
  }

  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(selectMeain).length !== 0 ){
      if( selectMeain.generMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        generMakrList.forEach(element => {
            if( element.makrSeriNo === selectMeain.generMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setGenerMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      if( selectMeain.invtMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        invtMakrList.forEach(element => {
            if( element.makrSeriNo === selectMeain.invtMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setInvtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
    }else{
      setGenerMakr({ makrSeriNo: '' , makrTerm: '' });
      setInvtMakr({ makrSeriNo: '', makrTerm: '' });
    }
  }

  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(selectMeain).length !== 0 ){
      if( selectMeain.meainTypeSeriNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        meainTypeSeriList.forEach(element => {
            if( element.meainTypeSeriNo === selectMeain.meainTypeSeriNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setMeainTypeSeri({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setMeainTypeSeri({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( selectMeain.invtTypeNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        invtTypeList.forEach(element => {
            if( element.meainTypeSeriNo === selectMeain.invtTypeNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setInvtType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
    }else{
      setMeainTypeSeri({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
    }
  }

  // Converter 건물용도별
  const  bldSrvConverter = () =>{
    if( Object.keys(selectMeain).length !== 0 ){
        let codeNo = "";
        let codeVal = "";
        bldSrvDs.forEach(element => {
            if( element.codeNo === selectMeain.bldSrvCodeNo ){
                codeNo = element.codeNo;
                codeVal = element.codeVal;
            }
        });
        setBldSrvCodeNo({ codeNo: codeNo, codeVal: codeVal });
    }else{
        setBldSrvCodeNo({ codeNo: '', codeVal: '-' });
    }
  }

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    bldSrvConverter();
    // eslint-disable-next-line
  }, [selectMeain]);

  return(
    <table className="Ch-TableN">
        <tr>
            <th>발전기용량</th>
            <td>
                <Input 
                  id = "instCapa" name = "instCapa"  type = "number" className={styles.searchTermTBC}
                  value = { selectMeain.instCapa ? selectMeain.instCapa : "" } onChange = {onchageInput}  
                  required = {true} validationMessage = {inputValidMsg.number2Fix}  step="0.01" min = {0}
                  />
            </td>
            <th>발전기 허브높이(m)</th>
            <td>
                <Input 
                  id = "generHubHeit" name = "generHubHeit"  type = "number" className={styles.searchTermTBC}
                  value = { selectMeain.generHubHeit ? selectMeain.generHubHeit : ""} onChange = {onchageInput}  
                  required = {true} validationMessage = {inputValidMsg.number2Fix}  step="0.01" min = {0}
                  />
            </td>
            <th>발전기 설치대수</th>
            <td>
                <Input 
                  id = "generInstQuan" name = "generInstQuan"  type = "number" className={styles.searchTermTBC}
                  value = { selectMeain.generInstQuan ? selectMeain.generInstQuan : "" } onChange = {onchageInput}  
                  required = {true} validationMessage = {inputValidMsg.number0Fix} min = {0}
                  />
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>발전기 제조사</th>
            <td>
                <DropDownList 
                id = "generMakrNo" name = "generMakrNo"  className="meainInfoDropDown"
                dataItemKey="makrSeriNo" textField = "makrTerm" data = {generMakrList}
                value = { generMakr } 
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.makrSeriNo }; 
                setGenerMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } }  
                required = {true} validationMessage = {inputValidMsg.normalText}
                valid = {selectMeain.generMakrNo !== "" && selectMeain.generMakrNo !== null && typeof selectMeain.generMakrNo !== "undefined" }
                itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th>발전기 모델</th>
            <td>
                <DropDownList 
                id = "meainTypeSeriNo" name = "meainTypeSeriNo"  className="meainInfoDropDown"
                dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" data = {meainTypeSeriList}
                value = { meainTypeSeri } disabled = { selectMeain.generMakrNo ? false : true }
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.meainTypeSeriNo }; 
                setMeainTypeSeri({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } }   
                required = {true} validationMessage = {inputValidMsg.normalText}
                valid = {selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined" }
                itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>인버터 제조사</th>
            <td>
                <DropDownList 
                id = "invtMakrNo" name = "invtMakrNo" className="meainInfoDropDown"
                dataItemKey="makrSeriNo" textField = "makrTerm" data = {invtMakrList}
                value = { invtMakr } 
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.makrSeriNo }; 
                setInvtMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
                required = {true} validationMessage = {inputValidMsg.normalText}
                valid = {selectMeain.invtMakrNo !== "" && selectMeain.invtMakrNo !== null && typeof selectMeain.invtMakrNo !== "undefined" }
                itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th>인버터 모델</th>
            <td>
                <DropDownList 
                id = "invtTypeNo" name = "invtTypeNo"  className="meainInfoDropDown"
                dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" data = {invtTypeList}
                value = { invtType } disabled = { selectMeain.invtMakrNo ? false : true }
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.meainTypeSeriNo }; 
                setInvtType({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } }  
                required = {true} validationMessage = {inputValidMsg.normalText}
                valid = {selectMeain.invtTypeNo !== "" && selectMeain.invtTypeNo !== null && typeof selectMeain.invtTypeNo !== "undefined" }
                itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th>인버터 용량</th>
            <td>
              <Input 
                id = "invtCapa" name = "invtCapa"  type = "number" className={styles.searchTermTBC}  step="0.01" min = {0}
                value = { selectMeain.invtCapa ? selectMeain.invtCapa : "" } onChange = {onchageInput}  
              />
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>건물용도</th>
            <td>
              <DropDownList className = "bldSrvCodeNo" id = "bldSrvCodeNo" name = "bldSrvCodeNo" 
                data = { bldSrvDs } required = {true} validationMessage = {inputValidMsg.normalText}
                value = { bldSrvCodeNo } 
                dataItemKey="codeNo" textField = "codeVal" 
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.codeNo }; setBldSrvCodeNo({codeNo : e.target.value.codeNo , codeVal : e.target.value.codeVal }); setMeainInfo(obj); } } 
                valid = {selectMeain.bldSrvCodeNo !== "" && selectMeain.bldSrvCodeNo !== null && typeof selectMeain.bldSrvCodeNo !== "undefined" }
                itemRender = {itemRender}
              />
            </td>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>시공업체</th>
            <td>
                <Input 
                id = "consEnte" name = "consEnte"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.consEnte ? selectMeain.consEnte : "" } onChange = {onchageInput} 
                required = {true} validationMessage = {inputValidMsg.normalText}
                />
            </td>
            <th>시공업체 연락처</th>
            <td>
                <Input 
                id = "consEnteCntct" name = "consEnteCntct"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.consEnteCntct ? selectMeain.consEnteCntct : "" } onChange = {onchageInput}  
                placeholder = "하이픈(-) 없이 입력해주세요."
                />
            </td>
            <th>시공업체 핸드폰 연락처</th>
            <td>
                <Input 
                id = "consPhoneCntct" name = "consPhoneCntct"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.consPhoneCntct ? selectMeain.consPhoneCntct : "" } onChange = {onchageInput}  
                placeholder = "하이픈(-) 없이 입력해주세요."
                />
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>시공일시</th>
            <td>
                <DateTimePickerKo 
                id = "meainInstDtm" name = "meainInstDtm" className = "meainDatePicker" 
                value = {dateFormat(selectMeain.meainInstDtm)} format = {"yyyy-MM-dd HH:mm"}
                formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                setDtm = {(v) => { onChangeDateTiem(v , "meainInstDtm"); }}
                required = {true} validationMessage = {inputValidMsg.normalText}
                  ></DateTimePickerKo>
            </td>
            <th>AS만료일</th>
            <td>
                <DatePickerKo 
                id = "asExpiDtm" name = "asExpiDtm" className = "meainDatePicker"    
                value = {dateFormat(selectMeain.asExpiDtm)} 
                format="yyyy-MM-dd"
                formatPlaceholder={{ year: '년', month: '월', day: '일' }}
                setDtm = {(v) => { onchageDate(v , "asExpiDtm"); }}
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></DatePickerKo>
            </td>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
          <th>사용전검사일</th>
          <td>
            <DatePickerKo id = "useBforInspDtm"  name = "useBforInspDtm" className = "meainDatePicker"
            format="yyyy-MM-dd" value = {dateFormat(selectMeain.useBforInspDtm)} 
            formatPlaceholder={{ year: '년', month: '월', day: '일' }}
            setDtm = { (v) => { onchageDate(v , "useBforInspDtm"); } }
            required = {true} validationMessage = {inputValidMsg.normalText}
              >
            </DatePickerKo> 
          </td>
          <th>모니터링시작일시</th>
          <td>
              <DateTimePickerKo 
                id = "mntrStrtDtm" name = "mntrStrtDtm"  className = "meainDatePicker"
                value = {dateFormat(selectMeain.mntrStrtDtm)} format = {"yyyy-MM-dd HH:mm"}
                formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                setDtm = {(v) => { onChangeDateTiem(v , "mntrStrtDtm"); }}
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></DateTimePickerKo>
          </td>
          <th>설치확인일</th>
          <td>
              <DatePickerKo id = "instCnfmDtm"  name = "instCnfmDtm" className = "meainDatePicker"
              format="yyyy-MM-dd" value = {dateFormat(selectMeain.instCnfmDtm)} 
              formatPlaceholder={{ year: '년', month: '월', day: '일' }}
              setDtm = { (v) => { onchageDate(v , "instCnfmDtm"); } }
              required = {true} validationMessage = {inputValidMsg.normalText}
                  >
              </DatePickerKo> 
          </td>
      </tr>
      <tr><th colSpan="6"><div></div></th></tr>
      <tr>
          <th>특이사항</th>
          <td colSpan = "4">
              <Input  
              id = "etc" name = "etc"  type = "text" className={styles.searchTermTBC}
              value = { selectMeain.etc ? selectMeain.etc : "" } onChange = {onchageInput}  
              />
          </td>
      </tr>
      <tr><th colSpan="6"><div></div></th></tr>
    </table>
  );
}


export default MeainInfoVelo;