//React
import React from "react";
import SolarPredPrzt from "./solarPredPrzt";
import { useState } from "react";
import { getMeainTermList } from "../../../service/kier/solaMeainService";
import { getMessage } from "./../../../com/messageUtil";

//kendo
import kendo from "@progress/kendo-ui";
import { useEffect } from "react";
import { getSolaPredList, getSolaPredPage } from "./../../../service/kier/solaPredService";

import { BeatLoader } from "react-spinners";
import { downloadExcel } from "./../../../com/downloadExcel";
import { parseDate } from "./../../../com/dateUtil";
import { numFormat, roundNum } from "./../../../com/dataUtil";
const SolarPred = props => {
  const { conn } = props;
  const [gridData, setGridData] = useState([]);
  const [today, setToday] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0, 0);
  const thirdDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 3, 0, 0, 0, 0);
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0, 0);
  const halfMonth = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15, 0, 0, 0, 0);
  const lastMonth = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30, 0, 0, 0, 0);
  const lastYear = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 365, 0, 0, 0, 0);

  const [gridState, setGridState] = useState({
    take: 15,
    skip: 0,
    sort: [],
    filter: {
      cid: "",
      dtmOpt: 1,
      startDtm: thirdDay,
      endDtm: today,
    },
  });

  const [cidList, setCidList] = useState([]);

  const [prdCht, setPrdCht] = useState({
    data: [],
    visible: false,
  });  

  const dtmOptList = [
    { opt: 1, optTerm: "실시간 조회" },
    { opt: 2, optTerm: "하루 전" },
    { opt: 3, optTerm: "일주일 전" },
    { opt: 4, optTerm: "보름 전" },
    { opt: 5, optTerm: "한달 전" },
    { opt: 6, optTerm: "일년 전" },
    { opt: 7, optTerm: "직접 조회" },
  ];

  const cidListChngEvt = e => {
    const { value } = e;
    setGridState({ ...gridState, filter: { ...gridState.filter, cid: value } });
  };

  const dtmOptListChngEvt = e => {
    const { value } = e;

    switch (value.opt) {
      case 1:
        setGridState({ ...gridState, filter: { ...gridState.filter, startDtm: thirdDay, endDtm: new Date(), dtmOpt: value } });
        break;
      case 2:
        setGridState({ ...gridState, filter: { ...gridState.filter, startDtm: yesterday, endDtm: new Date(), dtmOpt: value } });
        break;
      case 3:
        setGridState({ ...gridState, filter: { ...gridState.filter, startDtm: lastWeek, endDtm: new Date(), dtmOpt: value } });
        break;
      case 4:
        setGridState({ ...gridState, filter: { ...gridState.filter, startDtm: halfMonth, endDtm: new Date(), dtmOpt: value } });
        break;
      case 5:
        setGridState({ ...gridState, filter: { ...gridState.filter, startDtm: lastMonth, endDtm: new Date(), dtmOpt: value } });
        break;
      case 6:
        setGridState({ ...gridState, filter: { ...gridState.filter, startDtm: lastYear, endDtm: new Date(), dtmOpt: value } });
        break;
      case 7:
        setGridState({ ...gridState, filter: { ...gridState.filter, dtmOpt: value } });
        break;
      default:
        break;
    }
  };

  const prdChtVsblEvt = () => {
    setLoading(true);
    getSolaPredList(conn, gridState)
      .then(result => {
        setLoading(false);
        setPrdCht({ ...prdCht, data: result, visible: !prdCht.visible });
      })
      .catch(e => {
        kendo.alert(getMessage(e.errCode));
        setLoading(false);
      });
  };

  const prdChtUnVsblEvt = () => {
    setPrdCht({ ...prdCht, visible: false });
  };

  const getMeainTermFunc = () => {
    setLoading(true);
    getMeainTermList(conn)
      .then(result => {
        gridState.filter.cid = result[0];
        setCidList(result);
        getGridData();
      })
      .catch(e => kendo.alert(getMessage(e.errCode)));
  };

  const getGridData = () => {
    setLoading(true);
    getSolaPredPage(conn, gridState)
      .then(result => {
        setLoading(false);
        setGridData(result);
      })
      .catch(e => {
        kendo.alert(getMessage(e.errCode));
        setLoading(false);
      });
  };

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const { take, skip } = e.page;
    setGridState({ ...gridState, skip: skip, take: take });
  };

  // 그리드 필터링 처리
  const handleFilterChange = e => {
    // 필터 값 설정
    const isFilter = e.filter.filters;
    if (isFilter.length !== 0) {
      if (isFilter[0].field === "modSeqNo") {
        gridState.filter.modSeqNo = isFilter[0].value;
      } else if (isFilter[0].field === "modTerm") {
        gridState.filter.modTerm = isFilter[0].value;
      }
    }
    getGridData();
  };

  const startDtmPickerChngEvt = e => {
    setGridState({ ...gridState, filter: { ...gridState.filter, dtmOpt: 7, startDtm: e } });
  };

  const endDtmPickerChngEvt = e => {
    setGridState({ ...gridState, filter: { ...gridState.filter, dtmOpt: 7, endDtm: e } });
  };

  const xlDownEvt = e => {
    setLoading(true);
    // const excelTitle = "운전이력_및_예측" + "_" + gridState.filter.cid.meainTerm + "_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";    
    const excelTitle = `운전이력_및_예측_${gridState.filter.cid.meainTerm}_${kendo.toString(new Date(), "yyyyMMdd")}.xlsx`;
    const url = "/api/v2.2/kier/pred";

    const param = {
      cid: gridState.filter.cid.cid,
      startDtm: parseDate("yyyy-mm-dd hh:mm:ss", gridState.filter.startDtm),
      endDtm: parseDate("yyyy-mm-dd hh:mm:ss", gridState.filter.endDtm),
    };
    downloadExcel(param, excelTitle, url, setLoading);
  };

  const pvCell = e => {
    const { dataItem, field } = e;
    const data = dataItem[field];
    const result = data === null ? "-" : numFormat(roundNum(data, 3));
    return <td>{result}</td>;
  };

  useEffect(() => {
    if (gridState.filter.cid) getGridData();
    /* eslint-disable-next-line */
  }, [gridState.skip, gridState.take]);

  useEffect(() => {
    getMeainTermFunc();
    /* eslint-disable-next-line */
  }, []);

  //1분마다 데이터 새로고침
  const setInterval = () => {
    setTimeout(() => {
      setToday(new Date());
    }, 1 * 60 * 1000);
  };

  useEffect(() => {
    setInterval();
    if (gridState.filter.cid && gridState.filter.dtmOpt === 1 && !prdCht.visible) {
      setGridState({ ...gridState, filter: { ...gridState.filter, startDtm: thirdDay, endDtm: today } });
      // 비동기 때문에 state 직접변경함.
      gridState.filter.startDtm = thirdDay;
      gridState.filter.endDtm = today;
      getGridData();
    }
    /* eslint-disable-next-line */
  }, [today]);
  return (
    <div className={loading ? "loadingState" : null}>
      {loading && (
        <p className="loadingSpinner">
          <BeatLoader loading={true} size={24} color="#01579b" />
        </p>
      )}
      <SolarPredPrzt
        gridData={gridData}
        gridState={gridState}
        cidList={cidList}
        cidListChngEvt={cidListChngEvt}
        dtmOptList={dtmOptList}
        dtmOptListChngEvt={dtmOptListChngEvt}
        prdCht={prdCht}
        setPrdCht={setPrdCht}
        prdChtUnVsblEvt={prdChtUnVsblEvt}
        prdChtVsblEvt={prdChtVsblEvt}
        handlePageChange={handlePageChange}
        handleFilterChange={handleFilterChange}
        startDtmPickerChngEvt={startDtmPickerChngEvt}
        endDtmPickerChngEvt={endDtmPickerChngEvt}
        getGridData={getGridData}
        xlDownEvt={xlDownEvt}
        pvCell={pvCell}></SolarPredPrzt>
    </div>
  );
};

export default SolarPred;
