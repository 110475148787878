import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat , roundNum } from '../../../../com/dataUtil';

const TimePowerChart = ( {dataSource , ensoTypeCodeNo} ) => {
  
  // 에너지원별 생산량 , 발전량 분리
  const isUse = ensoTypeCodeNo === 15002 || ensoTypeCodeNo === 15003 ? true : false;

    // 차트 옵션
    const chartConfig = {
        chart: { zoomType: 'x' },
        title: { text: null },
        xAxis: [
          { 
            type: 'category', 
            crosshair: true ,
            labels : {
              style: { color: '#000000' }
            }
          }
        ],
        noData: {
            style:{ color: '#000000', fontSize: '14px' }
        },
        yAxis: [{ // 발전량 축
            labels: {
                format: '{value:,.0f}',
                style: { color: '#000000' },
                formatter: function() { 
                  return numFormat(roundNum(this.value , 2));
                }
            },
            title: {
                text: isUse === true ? '생산량(kWh)' : '발전량(kWh)',
                style: { color: '#000000' },
                rotation: 0,
                y: -20,
                offset: 0,
                align: 'high'
            }
        }],
        credits: { enabled: false },
        tooltip: {
            shared: true,
            formatter: function() {
              return(
                `
                  <span><b>${this.points[0].key}</b></span><br>
                  <span style="color:${this.points[0].point.color}">\u25CF</span>
                  <span>${this.points[0].series.name}</span> : <b>${numFormat(roundNum(this.points[0].y , 2 ))} (kwh)</b><br>
                `
              );
            }
        },
        legend: {
            layout: 'vertical',
            align: 'center',
            verticalAlign: 'top',
            floating: false
            ,backgroundColor:
        (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
        "#FFFFFF",
        },
        series: [{
            name: isUse === true ? '생산량(kWh)' : '발전량(kWh)',
            type: 'column',
            tooltip: { valueSuffix: ' kWh' },
            data : dataSource
        }]
    };

    return(
        <div>
            <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
        </div>
    )
}

export default TimePowerChart;



