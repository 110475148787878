import { useEffect } from "react";

import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";

import { CustomCalendar } from "../DatePicker/customCalender";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/ko/numbers.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import esMessages from "./ko.json";

load(likelySubtags, currencyData, weekData, numbers, caGregorian, dateFields, timeZoneNames);
loadMessages(esMessages, "ko");

// view 기능 : view = "month", "year", "decade" 중 하나를 인자로 넣어 일,월,년을 선택하는 캘린더로 변경할 수 있다.

const DatePickerKo = ({
  format,
  defaultValue,
  setDtm,
  max,
  min,
  value,
  readonly,
  name,
  required,
  validationMessage,
  disabled,
  className,
  view
}) => {
  // const [locale, setLocale] = useState("ko");
  const locale = 'ko';
  useEffect(() => {
    if (typeof readonly !== "undefined") {
      if (readonly === true) {
        document.getElementsByName(name)[0].setAttribute("readonly", readonly);
      }
    }
    /* eslint-disable-next-line */
  }, []);
  return (
    <LocalizationProvider language={locale}>
      <IntlProvider locale={locale}>
        <DatePicker
          format={format}
          defaultValue={defaultValue}
          name={name}
          onChange={e => setDtm(e.target.value)}
          max={max}
          min={min}
          value={value}
          required={required}
          validationMessage={validationMessage}
          disabled={disabled}
          className={className}
          calendar={view ? props => <CustomCalendar {...props} topView={view} bottomView={view} /> : null}
        />
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default DatePickerKo;
