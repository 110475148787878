import kendo from '@progress/kendo-ui';
import { downloadExcel } from "../../com/downloadExcel";
import { getMessage } from '../../com/messageUtil';

// SMP 이력 - SMP 이력 대시보드 불러오기
export const getSmpDashboard = async (conn, callback, reject, search) => {
  // 에너지원에 따른 URL 변경
  const url = "smp/hist/dashboard";
  // 검색 객체 생성
  await conn.get(url, {
      params: {
       
      },
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      reject(error);
    });
};

// SMP 이력 - SMP 이력 시간별, 일별, 월별 데이터 불러오기
export const getSmpHistList = async (conn, callback, reject, search) => {
  console.log("getSmpHistHour search = ", search);
  // 에너지원에 따른 URL 변경
  let url = "smp/hist/list/hour";
  console.log("search = ", search);
  if( search.radio === "MM" ){ url = "smp/hist/list/month"; }
  else if ( search.radio === "DD" ){ url = "smp/hist/list/day"; }
  console.log("url = ", url);
  // 검색 객체 생성
  await conn.get(url, {
      params: {
        areaCd : search.areaCd ,
        start : search.startSearchDtm,
        end : search.endSearchDtm,
        page: search.page , 
        size: search.size
      },
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      reject(error);
    });
};

// 시간별 데이터 저장
export const addSmpHour = async (conn, data) => {
  // console.log("data", data);

  // 헤더 옵션 변경
  const config = {
    headers: {'Content-Type': 'application/json'}
  };

  await conn.post('smp/hist/hour/save', JSON.stringify(data), config)
  .then(response => {
    return response;
  }).catch(error => {
    console.log("error",error)
    kendo.alert(getMessage(error.errCode));
  });
}

// 일별 데이터 저장
export const addSmpDay = async (conn, data) => {
  // console.log("data", data);

  // 헤더 옵션 변경
  const config = {
    headers: {'Content-Type': 'application/json'}
  };

  await conn.post('smp/hist/day/save', JSON.stringify(data), config)
  .then(response => {
    return response;
  }).catch(error => {
    console.log("error",error)
    kendo.alert(getMessage(error.errCode));
  });
}

// 월별 데이터 저장
export const addSmpMonth = async (conn, data) => {
  // console.log("data", data);

  // 헤더 옵션 변경
  const config = {
    headers: {'Content-Type': 'application/json'}
  };

  await conn.post('smp/hist/month/save', JSON.stringify(data), config)
  .then(response => {
    return response;
  }).catch(error => {
    console.log("error",error)
    kendo.alert(getMessage(error.errCode));
  });
}

// 엑셀 다운로드
export const getExcelDownload = async (search, setLoading) => {
  setLoading(true);
  let url = "/api/v2.2/smp/hist/hour";
  let excelTitle = "SMP 시간별 이력_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";

  if( search.radio === "MM" ){
    url = "/api/v2.2/smp/hist/month";
    excelTitle = "SMP 월별 이력_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
  } else if ( search.radio === "DD" ){
    url = "/api/v2.2/smp/hist/day";
    excelTitle = "SMP 일별 이력_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
  }
 
  try {
    downloadExcel( search , excelTitle , url, setLoading );
  } catch (error) {
    kendo.alert("엑셀 다운로드를 실패 하였습니다.");
    return;
  }
}