import styles from './locationDepth3.module.css';

const AreaDataItem = ({dataItem}) => { 
    return (
        <>
          <table className= {["chmapTable-B" , styles.mapLocation1D , styles.tableStyle].join(' ')}>
            <thead>
              <tr>
                <th colSpan="2">{dataItem.areaTerm}</th>
                <td colSpan="3">
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>금일발전</th>
                <td className={styles.energyTxtRight}><label className="textcoR">{dataItem.dayPower}</label> <span>kWh</span></td>
                <th>누적발전량</th>
                <td className={styles.energyTxtRight} colSpan="2"><label className="textcoR">{dataItem.accumPower}</label> <span>MWh</span></td>
              </tr>
              <tr>
                <th>금일생산</th>
                <td className={styles.energyTxtRight}><label className="textcoR">{dataItem.dayPrdct}</label> <span>kWh</span></td>
                <th>누적생산량</th>
                <td className={styles.energyTxtRight} colSpan="2"><label className="textcoR">{dataItem.accumPrdct}</label> <span>MWh</span></td>
              </tr>
              <tr>
                {/* 시도별 처리로 co2 저감량 공식은 발전량 , 소비량 에서 co2 각 구한 값의 합산값 */}
                <th>금일co2저감량</th>
                <td className={styles.energyTxtRight}><label className="textcoR">{dataItem.co2}</label> <span>CO<sup>2</sup></span></td>
                <th>설비용량</th>
                <td className={styles.energyTxtRight} colSpan="2"><label className="textcoR">{dataItem.instCapa}</label> <span>kW</span></td>
              </tr>
            </tbody>
          </table>
          <table className={["chmapTable-C" , styles.mapLocation1D ].join(' ')}>
            <tbody>
              <tr>
                <th rowSpan="4" className="linetitle">운영개소<br/>(개소)</th>
              </tr>
              <tr>
                <th>전체</th>
                <td>{dataItem.cntTotal}</td>
                <th>태양광</th>
                <td>{dataItem.cntSola}</td>
              </tr>
              <tr>
                <th>태양열</th>
                <td>{dataItem.cntHeat}</td>
                <th>지열</th>
                <td>{dataItem.cntHeath}</td>
              </tr>
              <tr>
                <th>풍력</th>
                <td>{dataItem.cntVelo}</td>
                <th>연료전지</th>
                <td>{dataItem.cntfuelcell}</td>
              </tr>
            </tbody>
          </table>
        </>
    )
}

export default AreaDataItem;