import log from '../../com/log';
import { parseDate } from '../../com/dateUtil';
import kendo from '@progress/kendo-ui';

const batchProcHistComUlr = 'sysBatchProcHist';

// 배치처리 그리드 데이터 조회
export const getBatchProcHist = async( conn, state, btchStats, data ) => {

  const {take, skip, sort} = state;
  const { trmeRsltCodeNo, startDate, endDate } = data;
  
  const convertStartDate = parseDate('yyyy-mm-dd', startDate);
  const convertEndDate = parseDate('yyyy-mm-dd', endDate);

  const config = {
    params: {
      page: (take + skip) / take
      , size: take
      , sort
      , trmeRsltCodeNo
      , startDate: convertStartDate
      , endDate: convertEndDate
    }
  }

  const result = await conn.get(batchProcHistComUlr + '/page', config);

  result.results.forEach(item => {
    item.reqDtm = parseDate('yyyy-mm-dd hh:mm:ss', item.reqDtm);
    item.batchDtm = parseDate('yyyy-mm-dd hh:mm:ss', item.batchDtm);
    item.trmeDtm = parseDate('yyyy-mm-dd hh:mm:ss', item.trmeDtm);
    item.trmeRsltCodeNo = btchStats
                          .filter(batchItem => batchItem.codeNo === item.trmeRsltCodeNo)[0].codeVal;
    
    item.batchTrmeDtm = Math.abs(new Date(item.trmeDtm) - new Date(item.batchDtm)) / 1000;
  });

  log(result, 'getBatchProcHist');
  return result;
};

// 배치 요청 
export const requestBatch = async(conn , callback , reject , parameter) => {
  // console.log("parameter = " , parameter);
  await conn.post(batchProcHistComUlr , {
    ensoTypeCode : parameter.engData,
    batchDtm : kendo.toString(parameter.searchDate , 'yyyy-MM-dd')
  }).then(response => {
    callback(response);
  }).catch(error => {
    reject(error);
  });
} 