import { useCreateAxiosInstance } from "conInstance";
import "./index.css";
import { useEffect, useRef, useState } from "react";
import TodayTaean from "../components/todayTaean";
import AmountOfPowerGenerationTaean from "../components/amountOfPowerGeneration";
import PowerGenerationTaean from "../components/powerGeneration";
import CurrentRenewableEnergyTaean from "../components/currentRenewableEnergy";
import ContributionOfEnvironmental from "../components/contributionOfEnvironmental";
import RenewableEnergyTaean from "../components/renewableEnergy";
import ReDirectTaean from "../components/reDirect";

const TaeanDashboard = () => {
  const { conn } = useCreateAxiosInstance();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await conn.get("/monitor/installer/munic/energyData", {
          params: {
            rgnCode: 44825,
          },
        });
        setData(res);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="taean-dashboard-container">
      <div className="taean-dashboard-left-container">
        <div className="taean-dashboard-left-top-container">
          <TodayTaean />
          <AmountOfPowerGenerationTaean data={data} />
        </div>
        <div className="taean-dashboard-left-bottom-container">
          <PowerGenerationTaean data={data} />
        </div>
      </div>
      <div className="taean-dashboard-middle-container">
        <CurrentRenewableEnergyTaean />
      </div>
      <div className="taean-dashboard-right-container">
        <ContributionOfEnvironmental data={data} />
        <div className="taean-dashboard-right-bottom-container">
          <RenewableEnergyTaean />
          <ReDirectTaean />
        </div>
      </div>
    </div>
  );
};

export default TaeanDashboard;
