import { Window } from "@progress/kendo-react-dialogs";
import { Button } from '@progress/kendo-react-buttons';

const NonOperHistEtcUpdateWindow = ({
  styles,
  windowState,
  update,
  updateEtcSave,
  cancel,
  etcChange
}) => {
  return (
    <>
      <Window
        title="비고 수정"
        left={700}
        top={100}
        initialHeight={220}
        initialWidth={500}
        onClose={cancel}
      >
        <form className="uk-form-stacked">
          <div className="k-form">
            <span>
              비고
              <input 
                className={styles.etcInput}
                style={{width:430}}
                value={windowState.etc}
                onChange={etcChange}
              ></input>
              <Button
                className='etcBtn'
                onClick={cancel}
              >
                닫기
              </Button>
              <Button
                className='etcBtn'
                onClick={updateEtcSave}
              >
                저장
              </Button>
            </span>
          </div>
        </form>
      </Window>
    </>
  )
}

export default NonOperHistEtcUpdateWindow;