import { useRef } from "react";
import { ReactComponent as RenewableEnergy } from "../../../../../assets/svg/taean/renewableEnergy.svg";
import { ReactComponent as Tooltip } from "../../../../../assets/svg/taean/tooltipLogo.svg";
import "./index.css";
const RenewableEnergyTaean = () => {
  const tooltipRef = useRef(null);

  const handleFocusTooltip = () => {
    tooltipRef.current.style.display = "flex";
  };

  const handleBlurTooltip = () => {
    tooltipRef.current.style.display = "none";
  };
  return (
    <div className="taean-flex-container">
      <div className="renewableEnergy-title-wrapper-taean">
        <span className="renewableEnergy-title-taean">신재생에너지 보급률</span>
        <Tooltip className="renewableEnergy-tooltip-taean" onMouseOver={handleFocusTooltip} onMouseOut={handleBlurTooltip} />
        <div className="renewableEnergy-tooltip-container-taean" ref={tooltipRef}>
          2023년 태안군 현황 자료 기준
          <br />
          (출처 : 한국에너지공단)
        </div>
      </div>
      <div className="renewableEnergy-wrapper-taean">
        <RenewableEnergy className="renewableEnergy-icon-taean" />
        <span className="powerGeneration-taean-text-5">23.44%</span>
      </div>
    </div>
  );
};

export default RenewableEnergyTaean;
