// React
import React from "react";
// Service
import { roundNum, numFormat } from "../../../com/dataUtil";

// Css
import "./munic.css";

const MunicProfit = props => {
  const { dataSource, className } = props;

  let totalSyncCount = 0;
  let totalAsyncCount = 0;
  let monthSmpProfSum = 0; //금월 smp 수익금합계
  let monthRecProfSum = 0; //금월 rec 수익금합계
  let monthSmpRecProfSum = 0; //금월 smp+rec 수익금합계

  if (dataSource.ensoMeainCntList) {
    dataSource.ensoMeainCntList.forEach(result => {
      totalSyncCount += result.syncCount;
      totalAsyncCount += result.asyncCount;
    });
    totalSyncCount = numFormat(roundNum(totalSyncCount, 0));
    totalAsyncCount = numFormat(roundNum(totalAsyncCount, 0));
  }

  if (dataSource.todayPowerData.syncInstCapa === 0) {
    monthSmpProfSum = dataSource.todayPowerData.asyncInstCapa * 3.5 * new Date().getDate() * dataSource.landSmp;
    monthRecProfSum = (dataSource.todayPowerData.asyncInstCapa * 3.5 * new Date().getDate() * dataSource.rec) / 1000;
    monthSmpRecProfSum = monthSmpProfSum + monthRecProfSum;
  } else {
    monthSmpProfSum =
      (dataSource.todayPowerData.asyncInstCapa * (dataSource.todayPowerData.syncMonthPower / dataSource.todayPowerData.syncInstCapa) +
        dataSource.todayPowerData.syncMonthPower) *
      dataSource.landSmp; //금월 smp 수익금합계
    monthRecProfSum =
      ((dataSource.todayPowerData.asyncInstCapa * (dataSource.todayPowerData.syncMonthPower / dataSource.todayPowerData.syncInstCapa) +
        dataSource.todayPowerData.syncMonthPower) *
        dataSource.rec) /
      1000; //금월 rec 수익금합계
    monthSmpRecProfSum = monthSmpProfSum + monthRecProfSum; //금월 smp+rec 수익금합계
  }

  // 금일 예상 발전량
  let todayExpePowerAmount = dataSource.todayPowerData.asyncInstCapa * dataSource.effi.todayExpeEffi;
  // 전일 예상 발전량
  let yesterdayExpePowerAmount = dataSource.prePowerData.asyncInstCapa * dataSource.effi.preExpeEffi;
  // 전일 대비 예상 발전량의 증감량 (금일 예상 발전량 - 전일 예상 발전량)
  let expeGapBetweenTodayYesterday = todayExpePowerAmount - yesterdayExpePowerAmount;
  // 전일 대비 예상 발전량의 증감율 {(금일 예상 발전량 - 전일 예상 발전량) / 전일 예상 발전량} * 100
  let expeGapBetweenTodayYesterdayRatio = numFormat(roundNum((expeGapBetweenTodayYesterday / yesterdayExpePowerAmount) * 100, 2));

  // 전일 대비 실제 발전량의 증감량 (금일 실제 발전량 - 전일 실제 발전량)
  let realGapBetweenTodayYesterday = dataSource.todayPowerData.syncDayPower - dataSource.effi.prePower;
  // 전일 대비 실제 발전량의 증감율 {(금일 실제 발전량 - 전일 실제 발전량) / 전일 실제 발전량} * 100
  let realGapBetweenTodayYesterdayRatio = numFormat(roundNum((realGapBetweenTodayYesterday / dataSource.effi.prePower) * 100, 2));

  // 금일 전체 발전량 (금일 예상 발전량 + 금일 실제 발전량)
  let todayTotalPowerAmount = todayExpePowerAmount + dataSource.todayPowerData.syncDayPower;
  // 전일 전체 발전량 (전일 예상 발전량 + 전일 실제 발전량)
  let yesterdayTotalPowerAmount = yesterdayExpePowerAmount + dataSource.effi.prePower;
  // 전일 대비 전체 발전량의 증감량 (금일 전체 발전량 - 전일 전체 발전량)
  let totalGapBetweenTodayYesterday = todayTotalPowerAmount - yesterdayTotalPowerAmount;
  // 전일 대비 전체 발전량의 증감율 {(금일 전체 발전량 - 전일 전체 발전량) / 전일 전체 발전량} * 100
  let totalGapBetweenTodayYesterdayRatio = numFormat(roundNum((totalGapBetweenTodayYesterday / yesterdayTotalPowerAmount) * 100, 2));

  // 전일 대비 SMP 가격 증감량 (금일 SMP 가격 - 전일 SMP 가격)
  let smpGapBetweenTodayYesterday = dataSource.landSmp - dataSource.landSmpYesterday;
  // 전일 대비 SMP 가격 증감율 {(금일 SMP 가격 - 전일 SMP 가격) / 전일 SMP 가격} * 100
  let smpGapBetweenTodayYesterdayRatio = (smpGapBetweenTodayYesterday / dataSource.landSmpYesterday) * 100;

  // 전일 대비 REC 가격 증감량 (금일 SMP 가격 - 전일 SMP 가격)
  let recGapBetweenTodayYesterday = dataSource.rec - dataSource.recYesterday;
  // 전일 대비 SMP 가격 증감율 {(금일 SMP 가격 - 전일 SMP 가격) / 전일 SMP 가격} * 100
  let recGapBetweenTodayYesterdayRatio = (recGapBetweenTodayYesterday / dataSource.recYesterday) * 100;

  // SMP 금일 예상 수익금
  let smpExpeTodayPrice = todayExpePowerAmount * dataSource.landSmp;
  // SMP 금일 실제 수익금
  let smpRealTodayPrice = dataSource.todayPowerData.syncDayPower * dataSource.landSmp;
  // SMP 금일 전체 수익금
  let smpTotalTodayPrice = todayTotalPowerAmount * dataSource.landSmp;

  // REC 금일 예상 수익금 (REC 는 1MW 당 1개가 발급이 됨)
  let recExpeTodayPrice = Math.floor(todayExpePowerAmount / 1000) * dataSource.rec;
  // REC 금일 실제 수익금
  let recRealTodayPrice = Math.floor(dataSource.todayPowerData.syncDayPower / 1000) * dataSource.rec;
  // REC 금일 전체 수익금
  let recTotalTodayPrice = Math.floor(todayTotalPowerAmount / 1000) * dataSource.rec;

  // 금일 전체 수익금
  let totalTodayPrice = smpTotalTodayPrice + recTotalTodayPrice;

  return (
    <div className={className ? className : null}>
      {/* {search.dongTypeCode != '' ? <h2 style={{ color: "#45a45f", textAlign: "center", marginTop: "0px" }}>{mapItem.fixbylawBundTermProfit}</h2> : ""} */}
      <div className="countSpace">
        <>
          <div className="asyncCount">미연동설비 {totalAsyncCount}개</div>
          <div className="syncCount">연동설비 {totalSyncCount}개</div>
        </>
      </div>
      <div className="con_box_w">
        <div className="tb-two">
          <table className="tb-oneThird">
            <tr>
              <th colSpan="2" className="tb-sm-t">
                금일예상발전량
              </th>
            </tr>
            <tr>
              <td colSpan="2" className="tb-rightA">
                {numFormat(roundNum(todayExpePowerAmount, 0))}
                <span>kWh</span>
              </td>
            </tr>
            <tr>
              {expeGapBetweenTodayYesterday > 0 ? (
                <>
                  <td class="tb-rightB upRed">
                    <i className="fa-solid fa-caret-up"></i>
                    <span>{numFormat(roundNum(expeGapBetweenTodayYesterday, 0))}</span>
                  </td>
                  <td class="tb-rightC upRed">
                    <i className="fa-solid fa-caret-up"></i>
                    <span>{isNaN(expeGapBetweenTodayYesterdayRatio) ? 0 : expeGapBetweenTodayYesterdayRatio}%</span>
                  </td>
                </>
              ) : expeGapBetweenTodayYesterday < 0 ? (
                <>
                  <td class="tb-rightB dwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                    <span>{numFormat(roundNum(-expeGapBetweenTodayYesterday, 0))}</span>
                  </td>
                  <td class="tb-rightC dwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                    <span>{isNaN(expeGapBetweenTodayYesterdayRatio) ? 0 : -expeGapBetweenTodayYesterdayRatio}%</span>
                  </td>
                </>
              ) : (
                <>
                  <td class="tb-rightB miBla">-</td>
                  <td class="tb-rightC miBla">0%</td>
                </>
              )}
            </tr>
          </table>
          <div className="Hline"></div>
          <table className="tb-oneThird">
            <tr>
              <th colSpan="2" className="tb-sm-t">
                금일실제발전량
              </th>
            </tr>
            <tr>
              <td colSpan="2" className="tb-rightA">
                {numFormat(roundNum(dataSource.todayPowerData.syncDayPower, 0))}
                <span>kWh</span>
              </td>
            </tr>
            <tr>
              {realGapBetweenTodayYesterday > 0 ? (
                <>
                  <td class="tb-rightB upRed">
                    <i className="fa-solid fa-caret-up"></i>
                    <span>{numFormat(roundNum(realGapBetweenTodayYesterday, 0))}</span>
                  </td>
                  <td class="tb-rightC upRed">
                    <i className="fa-solid fa-caret-up"></i>
                    <span>{isNaN(realGapBetweenTodayYesterdayRatio) ? 0 : realGapBetweenTodayYesterdayRatio}%</span>
                  </td>
                </>
              ) : realGapBetweenTodayYesterday < 0 ? (
                <>
                  <td class="tb-rightB dwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                    <span>{numFormat(roundNum(-realGapBetweenTodayYesterday, 0))}</span>
                  </td>
                  <td class="tb-rightC dwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                    <span>{isNaN(realGapBetweenTodayYesterdayRatio) ? -0 : -realGapBetweenTodayYesterdayRatio}%</span>
                  </td>
                </>
              ) : (
                <>
                  <td class="tb-rightB miBla">-</td>
                  <td class="tb-rightC miBla">0%</td>
                </>
              )}
            </tr>
          </table>
          <div className="Hline"></div>
          <table className="tb-oneThird">
            <tr>
              <th colSpan="2" className="tb-sm-t">
                금일전체발전량
              </th>
            </tr>
            <tr>
              <td colSpan="2" className="tb-rightA">
                {numFormat(roundNum(todayTotalPowerAmount, 0))}
                <span>kWh</span>
              </td>
            </tr>
            <tr>
              {totalGapBetweenTodayYesterday > 0 ? (
                <>
                  <td class="tb-rightB upRed">
                    <i className="fa-solid fa-caret-up"></i>
                    <span>{numFormat(roundNum(totalGapBetweenTodayYesterday, 0))}</span>
                  </td>
                  <td class="tb-rightC upRed">
                    <i className="fa-solid fa-caret-up"></i>
                    <span>{isNaN(totalGapBetweenTodayYesterdayRatio) ? 0 : totalGapBetweenTodayYesterdayRatio}%</span>
                  </td>
                </>
              ) : totalGapBetweenTodayYesterday < 0 ? (
                <>
                  <td class="tb-rightB dwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                    <span>{numFormat(roundNum(-totalGapBetweenTodayYesterday, 0))}</span>
                  </td>
                  <td class="tb-rightC dwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                    <span>{isNaN(totalGapBetweenTodayYesterdayRatio) ? -0 : -totalGapBetweenTodayYesterdayRatio}%</span>
                  </td>
                </>
              ) : (
                <>
                  <td class="tb-rightB miBla">-</td>
                  <td class="tb-rightC miBla">0%</td>
                </>
              )}
            </tr>
          </table>
        </div>
        <div className="Wline"></div>
        <div className="tb-two">
          <table>
            <tr>
              <th colSpan="2" className="tb-sm-t">
                SMP 가격
              </th>
            </tr>
            <tr>
              {smpGapBetweenTodayYesterday >= 0 ? (
                smpGapBetweenTodayYesterday === 0 ? (
                  <td colSpan="2" className="tb-rightA">
                    {numFormat(dataSource.landSmp)}
                    <span>원</span>
                  </td>
                ) : (
                  <td colSpan="2" className="tb-rightA">
                    {numFormat(dataSource.landSmp)}
                    <span>원</span>
                  </td>
                )
              ) : (
                <td colSpan="2" className="tb-rightA">
                  {numFormat(dataSource.landSmp)}
                  <span>원</span>
                </td>
              )}
            </tr>
            <tr>
              {smpGapBetweenTodayYesterday >= 0 ? (
                smpGapBetweenTodayYesterday === 0 ? (
                  <>
                    <td className="tb-rightB miBla">-</td>
                    <td className="tb-rightC miBla">0%</td>
                  </>
                ) : (
                  <>
                    <td className="tb-rightB upRed">
                      <i className="fa-solid fa-caret-up"></i>
                      <span>{numFormat(smpGapBetweenTodayYesterday)}</span>
                    </td>
                    <td className="tb-rightC upRed">
                      <i className="fa-solid fa-caret-up"></i>
                      <span>{numFormat(roundNum(smpGapBetweenTodayYesterdayRatio, 2))}%</span>
                    </td>
                  </>
                )
              ) : (
                <>
                  <td className="tb-rightB dwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                    <span>{numFormat(-smpGapBetweenTodayYesterday)}</span>
                  </td>
                  <td className="tb-rightC dwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                    <span>{numFormat(roundNum(-smpGapBetweenTodayYesterdayRatio, 2))}%</span>
                  </td>
                </>
              )}
            </tr>
          </table>
          <div className="Hline"></div>
          <table>
            <tbody>
              <tr>
                <th colSpan="2" className="tb-sm-t">
                  REC / RE100 가격
                </th>
              </tr>
              <tr>
                {recGapBetweenTodayYesterday >= 0 ? (
                  recGapBetweenTodayYesterday === 0 ? (
                    <td colSpan="2" className="tb-rightA">
                      {numFormat(dataSource.rec)}
                      <span>원</span>
                    </td>
                  ) : (
                    <td colSpan="2" className="tb-rightA">
                      {numFormat(dataSource.rec)}
                      <span>원</span>
                    </td>
                  )
                ) : (
                  <td colSpan="2" className="tb-rightA">
                    {numFormat(dataSource.rec)}
                    <span>원</span>
                  </td>
                )}
              </tr>
              <tr>
                {recGapBetweenTodayYesterday >= 0 ? (
                  recGapBetweenTodayYesterday === 0 ? (
                    <>
                      <td class="tb-rightB miBla">-</td>
                      <td class="tb-rightC miBla">0%</td>
                    </>
                  ) : (
                    <>
                      <td class="tb-rightB upRed">
                        <i className="fa-solid fa-caret-up"></i>
                        <span>{numFormat(recGapBetweenTodayYesterday)}</span>
                      </td>
                      <td class="tb-rightC upRed">
                        <i className="fa-solid fa-caret-up"></i>
                        <span>{numFormat(roundNum(recGapBetweenTodayYesterdayRatio, 2))}%</span>
                      </td>
                    </>
                  )
                ) : (
                  <>
                    <td class="tb-rightB dwBlu">
                      <i className="fa-solid fa-caret-down"></i>
                      <span>{numFormat(-recGapBetweenTodayYesterday)}</span>
                    </td>
                    <td class="tb-rightC dwBlu">
                      <i className="fa-solid fa-caret-down"></i>
                      <span>{numFormat(roundNum(-recGapBetweenTodayYesterdayRatio, 2))}%</span>
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <!-- box 4 --> */}
      <div className="con_box_w">
        <div className="tb-one">
          <table>
            <thead>
              <tr>
                <th>태양광에너지 환산수익금</th>
                {/* <th>
                  <Button className={styles.refreshBtn} onClick={clickRefresh}>전체보기</Button>
                </th> */}
              </tr>
            </thead>
          </table>
        </div>
        <div className="two-list-in">
          <div class="two-list-in-ti">
            <br />
            SMP
          </div>
          <div className="todayExpeProceeds">
            <label class="name">금일예상수익금</label>
            <div class="Print width-L">{numFormat(roundNum(smpExpeTodayPrice, 0))}</div>
            <span class="unit">원</span>
          </div>
          <div className="todayRealProceeds">
            <label class="name">금일실제수익금</label>
            <div class="Print width-R">{numFormat(roundNum(smpRealTodayPrice, 0))}</div>
            <span class="unit">원</span>
          </div>
        </div>
        <div className="two-list-in">
          <div class="two-list-in-ti">
            <img src="/images/dist/energy.png" alt="energy"/>
            <br />
            <br />
          </div>
          <div>
            <label class="name">금일수익금합계</label>
            <div class="Print width-L">{numFormat(roundNum(smpTotalTodayPrice, 0))}</div>
            <span class="unit">원</span>
          </div>
          <div>
            <label class="name">금월수익금합계</label>
            <div class="Print width-R">{numFormat(roundNum(monthSmpProfSum, 0))}</div>
            <span class="unit">원</span>
          </div>
        </div>
        <div class="Wline"></div>
        <div className="two-list-in">
          <div class="two-list-in-ti">
            <br />
            REC / RE100
          </div>
          <div className="todayExpeProceeds">
            <label class="name">금일예상수익금</label>
            <div class="Print width-L">{numFormat(roundNum(recExpeTodayPrice, 0))}</div>
            <span class="unit">원</span>
          </div>
          <div className="todayRealProceeds">
            <label class="name">금일실제수익금</label>
            <div class="Print width-R">{numFormat(roundNum(recRealTodayPrice, 0))}</div>
            <span class="unit">원</span>
          </div>
        </div>
        <div className="two-list-in">
          <div class="two-list-in-ti">
            <img src="/images/dist/save.png" alt="save"/>
            <br />
            <br />
          </div>
          <div>
            <label class="name">금일수익금합계</label>
            <div class="Print width-L">{numFormat(roundNum(recTotalTodayPrice, 0))}</div>
            <span class="unit">원</span>
          </div>
          <div>
            <label class="name">금월수익금합계</label>
            <div class="Print width-R">{numFormat(roundNum(monthRecProfSum, 0))}</div>
            <span class="unit">원</span>
          </div>
        </div>
      </div>
      {/* <!-- box 5 --> */}
      <div className="con_box_w flexBox">
        <div className="leftPaddingImg">
          <img src="/images/dist/icon-day.png" alt=""/>
        </div>
        <div className="borderTitle">
          금일수익합계
          <br />
          (SMP+REC)
        </div>
        {/* {
          search.dongTypeCode != '' ?
            <>
              <div className="BigM-title">금일 {mapItem.fixbylawBundTermProfit} 예상 수익<br /><small>(SMP+REC)</small></div>
            </>
            :
            <>
              <div className="BigM-title">금일 천안시 예상 수익<br /><small>(SMP+REC)</small></div>
            </>
        } */}
        <div className="BigM-Money">
          <div className="Big-Blk">{numFormat(roundNum(totalTodayPrice, 0))}</div>
          <small> 원</small>
          <br />
          <div>
            (<span>{numFormat(roundNum(smpTotalTodayPrice, 0))}원</span>
            &nbsp;+&nbsp;
            <span>{numFormat(roundNum(recTotalTodayPrice, 0))}원</span>)
          </div>
          {/* {
            ((dataSource.solaData.dayPower * dataSource.landSmp) + (dataSource.solaData.dayPower * dataSource.rec / 1000))
              - ((dataSource.solaData.prePower * dataSource.landSmpYesterday) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000)) >= 0 ?
              ((dataSource.solaData.dayPower * dataSource.landSmp) + (dataSource.solaData.dayPower * dataSource.rec / 1000))
                - ((dataSource.solaData.prePower * dataSource.landSmpYesterday) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000)) == 0 ?
                <>
                  <small className="MiBlack">-</small>
                  <small>전일대비</small>
                  <div className="Big-sm">{
                    numFormat(roundNum(((dataSource.solaData.dayPower * dataSource.landSmp) + (dataSource.solaData.dayPower * dataSource.rec / 1000))
                      - ((dataSource.solaData.prePower * dataSource.landSmpYesterday) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000)), 0))
                  }</div><small>원</small>
                </>
                :
                <>
                  <small className="UpRed"><i className="fa-solid fa-caret-up"></i></small>
                  <small>전일대비</small>
                  <div className="Big-sm">{
                    numFormat(roundNum(((dataSource.solaData.dayPower * dataSource.landSmp) + (dataSource.solaData.dayPower * dataSource.rec / 1000))
                      - ((dataSource.solaData.prePower * dataSource.landSmpYesterday) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000)), 0))
                  }</div><small>원</small>
                </>
              :
              <>
                <small className="DwBlu"><i className="fa-solid fa-caret-down"></i></small>
                <small>전일대비</small>
                <div className="Big-sm">{
                  numFormat(roundNum(-(((dataSource.solaData.dayPower * dataSource.landSmp) + (dataSource.solaData.dayPower * dataSource.rec / 1000))
                    - ((dataSource.solaData.prePower * dataSource.landSmpYesterday) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000))), 0))
                }</div><small>원</small>
              </>
          } */}
        </div>
      </div>
      {/* <!-- box 6 --> */}
      <div className="con_box_w flexBox">
        <div className="leftPaddingImg">
          <img src="/images/dist/icon-month.png" alt=""/>
        </div>
        <div className="borderTitle">
          금월수익합계
          <br />
          (SMP+REC)
        </div>
        {/* {
          search.dongTypeCode != '' ?
            <>
              <div className="BigM-title">금월 {mapItem.fixbylawBundTermProfit} 예상 수익<br /><small>(SMP+REC)</small></div>
            </>
            :
            <>
              <div className="BigM-title">금월 천안시 예상 수익<br /><small>(SMP+REC)</small></div>
            </>
        } */}

        <div className="BigM-Money">
          <div className="Big-Blk">{numFormat(roundNum(monthSmpRecProfSum, 0))}</div>
          <small> 원</small>
          <br />
          <div>
            (<span>{numFormat(roundNum(monthSmpProfSum, 0))}원</span>
            &nbsp;+&nbsp;
            <span>{numFormat(roundNum(monthRecProfSum, 0))}원</span>)
          </div>

          {/* {
            ((dataSource.solaData.dayPower * dataSource.landSmp * 30) + (dataSource.solaData.dayPower * dataSource.rec / 1000 * 30))
              - ((dataSource.solaData.prePower * dataSource.landSmpYesterday * 30) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000 * 30)) >= 0 ?
              ((dataSource.solaData.dayPower * dataSource.landSmp * 30) + (dataSource.solaData.dayPower * dataSource.rec / 1000 * 30))
                - ((dataSource.solaData.prePower * dataSource.landSmpYesterday * 30) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000 * 30)) == 0 ?
                <>
                  <small className="MiBlack">-</small>
                  <small>전월대비</small>
                  <div className="Big-sm">{
                    numFormat(roundNum(((dataSource.solaData.dayPower * dataSource.landSmp * 30) + (dataSource.solaData.dayPower * dataSource.rec / 1000 * 30))
                      - ((dataSource.solaData.prePower * dataSource.landSmpYesterday * 30) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000 * 30)), 0))
                  }</div><small>원</small>
                </>
                :
                <>
                  <small className="UpRed"><i className="fa-solid fa-caret-up"></i></small>
                  <small>전월대비</small>
                  <div className="Big-sm">{
                    numFormat(roundNum(((dataSource.solaData.dayPower * dataSource.landSmp * 30) + (dataSource.solaData.dayPower * dataSource.rec / 1000 * 30))
                      - ((dataSource.solaData.prePower * dataSource.landSmpYesterday * 30) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000 * 30)), 0))
                  }</div><small>원</small>
                </>
              :
              <>
                <small className="DwBlu"><i className="fa-solid fa-caret-down"></i></small>
                <small>전월대비</small>
                <div className="Big-sm">{
                  numFormat(roundNum(-(((dataSource.solaData.dayPower * dataSource.landSmp * 30) + (dataSource.solaData.dayPower * dataSource.rec / 1000 * 30))
                    - ((dataSource.solaData.prePower * dataSource.landSmpYesterday * 30) + (dataSource.solaData.prePower * dataSource.recYesterday / 1000 * 30))), 0))
                }</div><small>원</small>
              </>
          } */}
        </div>
      </div>
    </div>
  );
};

export default MunicProfit;
