import "./ContributionCard.css";

export default function ContributionCard({ title, unit, image, totalData }) {
  const { totalTree, totalPlasticCup, totalPaperCup, totalCarDistance } = totalData;

  return (
    <div className="contribution-card">
      <img alt={title} src={image} className="con-image" />
      <div className="asan-con-title">{title}</div>
      <div>
        <span className="con-amount">
          {title === "나무" && totalTree.toLocaleString()}
          {title === "플라스틱컵" && totalPlasticCup.toLocaleString()}
          {title === "종이컵" && totalPaperCup.toLocaleString()}
          {title === "주행거리" && totalCarDistance.toLocaleString()}
        </span>
        <span className="con-unit">{unit}</span>
      </div>
    </div>
  );
}
