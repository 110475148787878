import FilePreview from "react-preview-file";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Upload } from "@progress/kendo-react-upload";
import { CameraAlt } from "@material-ui/icons";

import stylesProgress from "./bizProgressEnergyImg.module.css";
import ProgressStepImgPopup from "./bizProgressStepImgPopup";

/**
 * 진척률 단계별 사진관리 사진 업로드 및 사진 보여주는 부분
 * @param {*} imgFile           // 이미지 파일 객체
 * @param {*} previewFile       // 이전 파일 객체
 * @param {*} filesState        // 파일 업로드 상태 객체
 * @param {*} onAdd             // 파일 추가 시 함수
 * @param {*} onRemove          // 파일 삭제 시 함수
 * @param {*} onProgress        // UPLOAD 진행 시 함수
 * @param {*} onStatusChange    // UPLOAD 컴포넌트 상태 변환 시 함수
 * @param {*} saveFileUrl       // 파일 저장 시 URL
 * @param {*} removeFileUrl     // 파일 삭제 시 URL
 * @param {*} isMobile          // 모바일 여부
 * @returns
 */
const ProgressEnergyImg = ({
  imgFile,
  previewFile,
  stepFileArray,
  filesState,
  onAdd,
  onRemove,
  onProgress,
  onStatusChange,
  saveFileUrl,
  removeFileUrl,
  isMobile,
  name,
  imgClickStatus,
  setImgClickStatus,
  closeImgWindow,
}) => {
  const imgSrc = Object.keys(stepFileArray).length !== 0 ? stepFileArray : "/images/defaultenso.png";

  return (
    <>
      <div className="imgBox">
        {/* 변경하기 시 미리보기 보여주고 변경하기 아닐때는 사진 보여줌 */}
        <div className="imgLnk">
          {Object.keys(previewFile).length === 0 && (
            <img
              src={imgSrc}
              alt="progressImg"
              onClick={e => {
                // 기본 이미지는 팝업 안띄우기
                if (imgSrc === "/images/defaultenso.png") {
                  return;
                }
                // 값 세팅
                setImgClickStatus({
                  state: true,
                  url: e.target.src,
                  width: e.target.width,
                  height: e.target.height,
                });
              }}
            />
          )}
          {previewFile.name && (
            <FilePreview file={previewFile.getRawFile()}>
              {preview => (
                <img
                  alt=""
                  src={preview}
                  onClick={() => {
                    // 값 세팅
                    setImgClickStatus({
                      state: true,
                      url: preview,
                      width: 0,
                      height: 0,
                    });
                  }}
                />
              )}
            </FilePreview>
          )}
        </div>
      </div>
      <div>
        <button
          className={stylesProgress.imageUploadBtn}
          onClick={() => {
            // document.getElementsByName('files')[0].click();
          }}>
          <CameraAlt className="material-icons" style={{ color: "black", fontSize: 20 }} />
        </button>
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Upload
              className={["step1", stylesProgress.fileUpload].join(" ")}
              name={name}
              showFileList={false}
              autoUpload={false}
              batch={true}
              multiple={false}
              files={filesState.files}
              defaultFiles={[]}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              withCredentials={true}
              removeVerb={"DELETE"}
              restrictions={{
                allowedExtensions: [".jpg", ".png"],
                maxFileSize: 10240000,
              }}
              saveMethod={"post"}
              saveHeaders={{
                "Content-Type": "multipart/form-data",
                "X-AUTH-TOKEN": localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
              }}
              saveUrl={saveFileUrl}
              removeUrl={removeFileUrl}
            />
          </IntlProvider>
        </LocalizationProvider>
      </div>
      {imgClickStatus.state && <ProgressStepImgPopup imgSrc={imgClickStatus.url} closeImgWindow={closeImgWindow} styled={stylesProgress} />}
    </>
  );
};

export default ProgressEnergyImg;
