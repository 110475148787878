import React from "react";

const BizProgressStatisticsRateModal = ({
  stylesProgress,
  useCallback,
  Dialog,
  Form,
  Field,
  FormElement,
  Input,
  Button,
  windowState,
  staticProssAdd,
  handleModalSaveSubmit,
}) => {
  // 저장 버튼
  const handleSubmit = useCallback(e => {
    handleModalSaveSubmit(e);
    /* eslint-disable-next-line */
  }, []);

  return (
    <Dialog title="협약개소 등록" onClose={staticProssAdd}>
      <div className={stylesProgress.rateModalForm}>
        <div className={stylesProgress.leftModalItem}>
          <div>태양광</div>
          <div>태양열</div>
          <div>소형풍력</div>
          <div>지열</div>
          <div>연료전지</div>
          <div>모니터링</div>
        </div>
        <div className="rightModalItem">
          <Form
            onSubmit={handleSubmit}
            initialValues={windowState.formItem}
            render={formRenderProps => (
              <FormElement>
                <div>
                  <Field name="solaCnt" component={Input} placeholder="0" />
                </div>
                <div>
                  <Field name="heatCnt" component={Input} placeholder="0" />
                </div>
                <div>
                  <Field name="veloCnt" component={Input} placeholder="0" />
                </div>
                <div>
                  <Field name="heathCnt" component={Input} placeholder="0" />
                </div>
                <div>
                  <Field name="fuelCnt" component={Input} placeholder="0" />
                </div>
                <div>
                  <Field name="moniCnt" component={Input} placeholder="0 " />
                </div>
                <div>
                  <Button onClick={staticProssAdd}>취소</Button>
                  <Button type="submit" disabled={!formRenderProps.allowSubmit}>
                    저장
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
        {/* <div>`
          ※ 최초 등록시 수정이 불가능하니, 
          신중히 입력해 주시기 바랍니다.
        </div> */}
      </div>
    </Dialog>
  );
};

export default BizProgressStatisticsRateModal;
