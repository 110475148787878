import React from 'react';
import { CODE_HEAT, CODE_HEATH, CODE_SOLA } from '../../../com/codeUtil';
import { numFormat } from '../../../com/dataUtil';

const LocalAreaMonitorStatInfoTable = ({ensoTypeTerm , dataItem , styles}) => {

  // 데이터 변수 정의
  let syncPower = dataItem.syncPower ; 
  let aSyncPower = dataItem.aSyncPower ; 
  let power = dataItem.power ; 
  let iconPath = '/images/localareaImg/42770/icon_50x45_01.png' ;

  if( dataItem.code === CODE_HEAT || dataItem.code === CODE_HEATH ){
    syncPower = dataItem.syncUse ; 
    aSyncPower = dataItem.aSyncUse ; 
    power = dataItem.use ; 
    // 아이콘 PATH 설정
    if(dataItem.code === CODE_HEAT){
      iconPath = '/images/localareaImg/42770/icon_50x45_02.png' ;
    }else if (dataItem.code === CODE_HEATH){
      iconPath = '/images/localareaImg/42770/icon_50x45_03.png' ;
    }
  }

  return (
    <div className={ dataItem.code !== CODE_SOLA && styles.ensoStatInfoArea}> 
    { dataItem.code === CODE_SOLA && 
      <div className={styles.ensoStatInfoMainTitle}>
        <table>
          <tr>
            <td><img src='/images/localareaImg/42770/info/info.png' alt='정보' /></td>
            <td>상세 현황</td>
          </tr>
        </table>
      </div> 
    }
      <table className={styles.ensoStatInfoTable}>
        { dataItem.code === CODE_SOLA ? 
          <thead>
            <tr>
              <th className={styles.ensoStatInfoSubTitle} >에너지원</th>
              <th className={styles.ensoStatInfoSubTitle} >연동 여부</th>
              <th className={styles.ensoStatInfoSubTitle} >개수</th>
              <th className={styles.ensoStatInfoSubTitle} >용량</th>
              <th className={styles.ensoStatInfoSubText} >발전량 & 사용량</th>
            </tr>
          </thead>
          : 
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
        }
        <tbody>
          <tr>
            <td rowSpan={3}><img src={iconPath} alt='태양광' /><div>{ensoTypeTerm}</div></td>
            <td className={styles.ensoStatInfoSyncText} >연동</td>
            <td className={styles.ensoStatInfoSyncText} >{numFormat(dataItem.syncCount)}</td>
            <td className={styles.ensoStatInfoSyncText} >{numFormat(dataItem.syncInstCapa)} MWh</td>
            <td className={styles.ensoStatInfoSyncText} >{numFormat(syncPower)} MWh</td>
          </tr>
          <tr>
            <td className={styles.ensoStatInfoASyncText} >미연동</td>
            <td className={styles.ensoStatInfoASyncText} >{numFormat(dataItem.asyncCount)}</td>
            <td className={styles.ensoStatInfoASyncText} >{numFormat(dataItem.asyncInstCapa)} MWh</td>
            <td className={styles.ensoStatInfoASyncText} >{numFormat(aSyncPower)} MWh</td>
          </tr>
          <tr>
            <td className={styles.ensoStatInfoTtlText} >총</td>
            <td className={styles.ensoStatInfoTtlText} >{numFormat(dataItem.cnt)}</td>
            <td className={styles.ensoStatInfoTtlText} >{numFormat(dataItem.instCapa)} MWh</td>
            <td className={styles.ensoStatInfoTtlText} >{numFormat(power)} MWh</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LocalAreaMonitorStatInfoTable;