
//작년대비 방문자 수
const LastYearVisitorCountChart = ({Highcharts ,HighchartsReact , thisYearChartData , lastYearChartData}) => {

// 카테고리
const category = ["1월" , "2월" , "3월" , "4월" , "5월" , "6월" , "7월" , "8월" , "9월" , "10월" , "11월" , "12월"];

  // 차트 옵션
  const chartConfig = {
    chart:{ 
      height: 370,
      type: 'column',
      backgroundColor: "#ffffff",
      className : "visitorChart"
    },
    title: { text: null },
    credits: { enabled: false },
    xAxis: { categories : category },
    yAxis: {
        title: {
            text: '방문자수',
            rotation: 0,
            y: -20,
            offset: 0,
            align: 'high',
        }
        , tickInterval : 2
        , labels : {
          format: '{value:,.0f}',
        }
    },
    legend: {  layout: 'horizontal', align: 'center', verticalAlign: 'top', floating: false 
    ,backgroundColor:
    (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
    "#FFFFFF",
},
    plotOptions: {
        series: { label: { connectorAllowed: false } }
    },
    series: [{
      name: '작년',
      data:lastYearChartData
    },{
      name: '금년',
      data:thisYearChartData  
    }],
    responsive: {
      rules: [{
          condition: { maxWidth: 500 },
          chartOptions: {
              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
                  ,backgroundColor:
                  (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
                  "#FFFFFF",
          
              }
          }
      }]
    }
  };


  return(
    <>
      <HighchartsReact Highcharts = {Highcharts} options = {chartConfig} />
    </>
  )
}


export default LastYearVisitorCountChart;