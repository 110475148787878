import "./index.css";
import { ReactComponent as Monitoring } from "../../../../../assets/svg/gongju/monitoring.svg";
import { ReactComponent as Symbol } from "../../../../../assets/svg/gongju/symbol.svg";
import { ReactComponent as CityLogo } from "../../../../../assets/svg/gongju/cityLogo.svg";
import { ReactComponent as AreaInfo } from "../../../../../assets/svg/gongju/areaInfo.svg";
import { useEffect, useState } from "react";
import GongjuArea from "./GongjuArea";
import { useCreateAxiosInstance } from "conInstance";
import GongjuRank from "./GongjuRank";

const CurrentRenewableEnergy = () => {
  const { conn } = useCreateAxiosInstance();
  const [data, setData] = useState(null);

  const handleRedirectMonitoring = () => {
    window.location.href = "https://kremc.kr/";
  };

  const handleRedirectGongju = () => {
    window.location.href = "https://www.gongju.go.kr/";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await conn.get("/monitor/installer/munic/dongEnergyData", {
          params: {
            rgnCode: 44150,
          },
        });
        setData(res);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="gongju-right-bottom-container">
      <div className="gongju-flex-container">
        <span className="gongju-dashboard-title">구역별 신재생에너지 현황</span>
        <div className="currentRenewableEnergy-wrapper">
          <GongjuArea className="gongju-area" data={data} />
          <AreaInfo className="gongju-area-info" />
          <GongjuRank data={data} />
        </div>
      </div>
      <div className="reDirect-container">
        <button className="reDirect-wrapper reDirect-monitoring" onClick={handleRedirectMonitoring} type="button">
          통합
          <br />
          모니터링
          <br />
          <Monitoring className="monitoring-icon" />
          바로가기
        </button>
        <button className="reDirect-wrapper reDirect-gongju" onClick={handleRedirectGongju} type="button">
          <Symbol className="gongju-symbol" />
          <CityLogo className="gongju-symbol" />
          <br />
          바로가기
        </button>
      </div>
    </div>
  );
};

export default CurrentRenewableEnergy;
