import React, { useEffect, useState } from 'react';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 설비 정보 - 통합 입력 부분
*/
const BizManagementMeainCom = ({
  Input, convMngtStyles, DropDownList, meainInfo , initialDs, search, setMeainInfo
}) => {
  // 설치유형 설정
  const instTypeDs = () => {
    let list = [];
    if(search.mngt.energyTypeCode === 39001){ list =  initialDs.instTypeSola; }
    else if (search.mngt.energyTypeCode === 39002){ list =  initialDs.instTypeHeat; }
    else if (search.mngt.energyTypeCode === 39003){ list =  initialDs.instTypeVelo; }
    else if (search.mngt.energyTypeCode === 39004){ list =  initialDs.instTypeHeath; }
    else if (search.mngt.energyTypeCode === 39005){ list =  initialDs.instTypeFuel; }
    return list ;
  }
  const instTypeList = instTypeDs();

  // 설치 유형 데이터 설정  
  const [instType , setInstType] = useState( meainInfo.instType ? { codeNo : meainInfo.instType , codeVal : "" } : { codeNo : '' , codeVal : '-' } );

  // 설치유형 Converter
  const instTypeConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.instType ){
        let instType = "";
        let instTypeTerm = "";
        instTypeList.forEach(element => {
            if( element.codeNo === Number(meainInfo.instType) ){
              instType = element.codeNo;
              instTypeTerm = element.codeVal;
            }
        });
        setInstType({ codeNo: instType, codeVal: instTypeTerm });
      }else{
        setInstType({ codeNo: '', codeVal: '-' });  
      }
    }else{
      setInstType({ codeNo: '', codeVal: '-' });
    }
  }

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    instTypeConverter();
    /* eslint-disable-next-line */
  }, [meainInfo]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  return (
    <>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;사업구분
        </th>
        <td>
          <Input id = "bizSct" name = "bizSct" type="text" 
                className={convMngtStyles.inputTerm} 
                value = {"융복합지원사업"} 
                readOnly = {true} disabled = {true}
          />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font13 }>설치계획서 신청번호</span>
        </th>
        <td>
          <Input id = "instlPlnnm" name = "instlPlnnm" type="text" 
                className={convMngtStyles.inputTerm} 
                value = {meainInfo.instlPlnnm ? meainInfo.instlPlnnm : ""}
                readOnly = {true} disabled = {true}
          />
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;신청자(건물)명
        </th>
        <td>
          <Input id = "instlTerm" name = "instlTerm" type="text" 
                className={convMngtStyles.inputTerm} 
                value = {meainInfo.instlTerm ? meainInfo.instlTerm : ""}
                readOnly = {true} disabled = {true}
          />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;신청자 연락처
        </th>
        <td>
          <Input id = "instlCelphon" name = "instlCelphon" type="text" 
                  className={convMngtStyles.inputTerm} 
                  value = {meainInfo.instlCelphon ? meainInfo.instlCelphon : ""}
                  readOnly = {true} disabled = {true}
          />
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;설치장소
        </th>
        <td colSpan={3}>
          <Input id = "instlAddr" name = "instlAddr" type="text" 
                className={convMngtStyles.inputTerm} 
                value = {meainInfo.instlAddr ? meainInfo.instlAddr : ""}
                readOnly = {true} disabled = {true}
          />
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;연동여부
        </th>
        <td>
          <Input id = "syncYnTerm" name = "syncYnTerm" type="text" 
                className={convMngtStyles.inputTerm} 
                value = {meainInfo.syncYn === 'Y' ? "연동" : "미연동"}
                readOnly = {true} 
          />
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        { meainInfo.energyTypeCode !== 39003 && meainInfo.energyTypeCode !== 39004  && meainInfo.energyTypeCode !== 39005 ?
          <>
            <th>
              <span className={ convMngtStyles.required }>*</span>&nbsp;설치유형
            </th>
            <td>
              <DropDownList id = "instType" name = "instType" type="text" 
                    className={convMngtStyles.inputTerm} 
                    data = {instTypeList}
                    defaultItem = { {codeVal : "-" , codeNo : ""} }
                    value = {instType}
                    dataItemKey="codeNo" textField = "codeVal" 
                    onChange = {(e) =>{ console.log("e", e); const obj = { ...meainInfo , [e.target.name] : e.value.codeNo }; setInstType(e.value); setMeainInfo(obj); }} 
              />
            </td>
          </>
          :
          <>
          </>
        }
        { meainInfo.energyTypeCode !== 39003 && meainInfo.energyTypeCode !== 39005 ?
          <>
            <th>
              <span className={ convMngtStyles.required }>*</span>&nbsp;설치용량 { meainInfo.unit ? " ( " + meainInfo.unit + " ) " : "" }  
            </th>
            <td>
              <Input id = "instCapa" name = "instCapa" type="text" 
                    className={convMngtStyles.inputTerm} 
                    value = {meainInfo.instCapa ? meainInfo.instCapa : ""}
                    readOnly = {true} disabled = {true}
              />
            </td>
          </>
          :
          <>
          </>
        }
      </tr>
    </>
  );
};

export default BizManagementMeainCom;