import React from "react";

import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

// import { getToken } from "../../../../service/authService";

import BizProgressStatistics from "./statistics/bizProgressStatistics";
import BizProgressEnergyImg from "./stepImg/bizProgressEnergyImg";

const BizProgressContents = ({
  conn,
  stylesProgress,
  search,
  setSearch,
  dataList,
  setSearchMonth,
  getProgressWeek,
  staticProssAdd,
  windowState,
  isMobile,
  useState,
  useCallback,
  kendo,
}) => {
  // const token = getToken();

  // 탭 선택
  const selectTabItem = e => {
    // 탭 상태 설정 부분
    setSearch(search => {
      const item = { ...search };
      item.progress.tabStatus = e.selected;
      return item;
    });

    // 만약 설치 계획서가 선택된 상태에서 탭 클릭 시 데이터 불러오도록 로직 적용
    // getSelectMeainInfoOrAsHistByTab();
  };

  return (
    <div class="uk-width-medium-2-5">
      <TabStrip selected={search.progress.tabStatus} onSelect={selectTabItem} className={stylesProgress.convProgessTab}>
        <TabStripTab title="사업추진 진행사항" key={"meainInfo"}>
          {/* <div className={convMngtStyles.tabsContents}> */}
          <BizProgressStatistics
            conn={conn}
            search={search}
            setSearch={setSearch}
            dataList={dataList}
            setSearchMonth={setSearchMonth}
            getProgressWeek={getProgressWeek}
            staticProssAdd={staticProssAdd}
            windowState={windowState}
          />
          {/* </div> */}
        </TabStripTab>
        <TabStripTab title="단계별 사진관리" key={"meainInfo"}>
          {/* <div className={convMngtStyles.tabsContents}>*/}
          <BizProgressEnergyImg
            conn={conn}
            search={search}
            setSearch={setSearch}
            dataList={dataList}
            stylesProgress={stylesProgress}
            isMobile={isMobile}
            useState={useState}
            useCallback={useCallback}
            kendo={kendo}
          />
          {/* </div> */}
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default BizProgressContents;
