import React from "react";

import { useEffect, useState } from "react";
import { getDaedeokWeathInfo } from "../../../../service/analy/weathCmpService";
import { roundNum } from "../../../../com/dataUtil";

import HeaderTimer from "../../../headerTimer";
import HeaderFullScr from "../../../headerFullSc";

import "./daedeokHeader.css";


const DaeDeokHeader = ({conn}) => {  
  const [weathInfo, setWeathInfo] = useState({
    presWeathVal: "",
    rgnCode: "",
    temp: "",
    img: "",
    rgnTerm: "",
  });


  useEffect(() => {      
    getDaedeokWeathInfo(conn)
      .then(response => {
        if (response) {
          setWeathInfo(weathInfo => {
            const item = { ...weathInfo };
            item.presWeathVal = response.presWeathVal;
            item.rgnCode = response.rgnCode;
            item.temp = roundNum(response.temp, 2);
            item.rgnTerm = response.rgnTerm;
            if (item.presWeathVal === "구름") {
              item.img = "/images/weather/weather_icon01.png";
            } else if (item.presWeathVal === "구름 많음") {
              item.img = "/images/weather/weather_icon02.png";
            } else if (item.presWeathVal === "구름 조금") {
              item.img = "/images/weather/weather_icon03.png";
            } else if (item.presWeathVal === "눈") {
              item.img = "/images/weather/weather_icon04.png";
            } else if (item.presWeathVal === "비/눈") {
              item.img = "/images/weather/weather_icon05.png";
            } else if (item.presWeathVal === "맑음") {
              item.img = "/images/weather/weather_icon06.png";
            } else if (item.presWeathVal === "비") {
              item.img = "/images/weather/weather_icon07.png";
            } else if (item.presWeathVal === "흐림") {
              item.img = "/images/weather/weather_icon08.png";
            } else if (item.presWeathVal === "소나기") {
              item.img = "/images/weather/weather_icon09.png";
            } else if (item.presWeathVal === "눈/비") {
              item.img = "/images/weather/weather_icon10.png";
            } else {
              item.img = "/images/weather/weather_icon06.png";
            }
            return item;
          });
        }
      })
      .catch(error => console.log(error, "getWeathInfo Error!"));
    /* eslint-disable-next-line */
  }, []);

  const btnClickEvt = () => {
    window.location.href = "https://kremc.kr/";
  };

  return (
    <header id="header_main">      
      <nav className="uk-navbar">  
        <div className="uk-navbar-center header-center">
          <h1 className="mainTitle">
            <div className="title">
              <img src="/images/localareaImg/30230/title/30230_title.png" alt="title" style={{marginTop: '-25px'}}/>
            </div>
          </h1>  
          <div className="uk-navbar-flip">
            <ul className="uk-navbar-nav user_actions">
              <li className="user_action_icon">
                <span
                  style={{                                                            
                    lineHeight:'60px',                                        
                  }}>                  
                  <HeaderTimer />
                  {weathInfo.rgnCode ? (
                    <span className="headata">
                      <span className="headRgnData">&nbsp;| {weathInfo.rgnTerm} |</span>{" "}
                      <span className="headTempData">
                        {weathInfo.temp}℃ &nbsp; <img alt="날씨" src={weathInfo.img} />
                      </span>
                    </span>
                  ) : null}                  
                </span>
              </li>
              <li className="loginIconLi">
                <button 
                  style={{color:'#333', background:'transparent', border:'none', lineHeight:'60px', height:'70px', padding:'0 15px', }}
                  onClick={btnClickEvt} className="user_action_icon">
                  <span className="headimgB">
                    <img src="/images/icon_login.png" alt="로그인" />
                  </span>
                </button>
              </li>
              <li className="headerFullLi">
                {" "}
                <HeaderFullScr />{" "}
              </li>
            </ul>
          </div>        
        </div>                
      </nav>                               
    </header>
  );
}

export default DaeDeokHeader;