import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../../com/dataUtil";

// 생산량 차트
const ReportDayGenerationChart = ({ compareChart, width, clsName, height }) => {
  // 차트 값 가공
  const heatChart = compareChart.length !== 0 ? compareChart.filter((c) => c.ensoTypeCode === 15002) : [];
  const heathChart = compareChart.length !== 0 ? compareChart.filter((c) => c.ensoTypeCode === 15003) : [];

  // 차트 옵션
  let chartConfig = {
    chart: {
      type: "column",
      zoomType: "xy",
      backgroundColor: "#f7f9fa",
      width: width !== null ? width : "100%",
      height: height !== null ? height : null,
    },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        // 발전량 축
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "생산열량(kWh)",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { 
      shared: true,
      formatter: function() {
        return(
          `
            <span style="color:${this.points[0].point.color}">\u25CF</span>
            <span>${this.points[0].series.name}</span> : <b>${numFormat(roundNum(this.points[0].y, 2))} kWh</b><br>
          `
        );
      }
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: { 
          enabled: true ,
          color : '#000000',
          style: { textShadow: false },
          formatter: function() { 
            return `<span><b${numFormat(roundNum(this.y , 0))} kWh</b></span>`
          }
        }
      },
    },
    series: [
      {
        name: "생산열량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#8fff94",
        data: [heatChart[0], heathChart[0]],
      },
    ],
  };

  return (
    <div className={clsName !== null ? clsName : "reportDayBackground"}>
      <HighchartsReact Highcharts={Highcharts} options={chartConfig}></HighchartsReact>
    </div>
  );
};

export default ReportDayGenerationChart;
