import { useEffect, useRef, useState } from "react";
import InstallerList from "./installerList";
import AreaDataList from "./areaDataList";
import MeainCount from "./meainCount";
import styles from "./locationDepth3.module.css";

// import { parseDate } from "../../../com/dateUtil";

import "./locationDepth3.css";
// import './locationDepth3.dk.css';
import {
  getCityDataList,
  getInstallAreaDataList,
  // getLocation,
  getRgnDataList,
  getDongDataList,
} from "../../../service/location/locationDepth3Service";

import { getInstallMeainStatData } from "../../../service/location/locationService";
import LocationSearch from "./locationSearch";
import { MeainInfo } from "./meainInfo";
import { useHistory, useLocation } from "react-router-dom";
import { getUser, isInstaller } from "../../../service/authService";
import kendo from "@progress/kendo-ui";

import { getDong } from "../../../service/areaService";
// 카카오 적용
const { kakao } = window;
const geocoder = new kakao.maps.services.Geocoder();
/***
 * 주의사항 : 카카오는 React 기반이 아닌 바닐라 자바스크립트 코드로 이루어져서 마커업과 클러스터 작업 시
 *           데이터 소스를 useState 가 아닌 useRef 로 제어 해야한다.
 *           맵은 데이터 소스가 랜더링 하기 전에 맵이 변경 되어 데이터 소스에 따라서 내가 원하는 마커와 클러스터를 그릴 수 없다.
 *           그래서 데이터 소스를 state 방식이 아닌 useRef 로 제어 하여 랜더링에 제안이 아닌 쉽게 제어가 되도록 해야한다.
 * */

// 계측 정보 확인 버튼 하나 추가 하기
const LocationDepth3 = ({ conn, codeList, cityList, rgnList, qs, setContentLayoutMargin }) => {
  // 맵 레벨 설정
  const DEFAULT_MAP_LEVEL = 12;
  const MAX_RGN_MAP_LEVEL = 10;
  const MAX_DONG_MAP_LEVEL = 8;
  const MAX_MARKER_MAP_LEVEL = 5;

  const MIN_CITY_MAP_LEVEL = 11;
  const MIN_RGN_MAP_LEVEL = 9;
  const MIN_DONG_MAP_LEVEL = 6;

  const AREA_CITY = "CITY";
  const AREA_RGN = "RGN";
  const AREA_DONG = "DONG";
  const AREA_MARKER = "";

  const DEFAULT_LATI = 35.753598294217575;
  const DEFAULT_LONGI = 127.19353543351394;
  // 히스토리 사용
  const history = useHistory();
  let location = useLocation();

  let { cityProvCode, rgnCode } = qs.parse(location.search);
  const rgnObj = rgnList.filter(c => c.rgnCode === rgnCode)[0];

  // 유저정보
  const userInfo = getUser();

  // 검색 조건
  const [search, setSearch] = useState({
    userTerm: isInstaller() ? userInfo.userTerm : "",
    cityProvCode: cityProvCode ? cityProvCode : "",
    rgnCode: rgnCode ? rgnCode : "",
    dongCode: "",
    bldSrvCodeNo: "",
    bizSct: "",
    bizYy: "",
    cityProvTerm: "",
    rgnTerm: "",
    dongTerm: "",
    lati: rgnObj ? rgnObj.lati : "",
    longi: rgnObj ? rgnObj.longi : "",
    mapLevel: DEFAULT_MAP_LEVEL,
    mapAddressCode: "",
    mapLevelTerm: "전체 시도별",
    type: AREA_CITY,
  });

  // const [dongList, setDongList] = useState();
  const [map, setMap] = useState("");
  // const [navigatorTerm, setNavigatorTerm] = useState("전국");

  // 지도에 표시된 마커 객체
  let markers = useRef([]);

  // 설치자 정보 리스트
  const [installerList, setInstallerList] = useState([]);

  const [areaDataList, setAreaDataList] = useState([]);
  // 맵 마커 문제로 useRef 로 변경 (마커 데이터 소스)
  const meainList = useRef([]);

  // 운영현황
  const [meainStatCnt, setMeainStatCnt] = useState({
    totalCnt: 0,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    warnCnt: 0,
    errorCnt: 0,
  });
  console.log("meainStatCnt", meainStatCnt);

  const bizYyRef = useRef(null);
  const bldSrvCodeNoRef = useRef(null);
  const bizSctRef = useRef(null);
  const userTermRef = useRef(null);
  // const navCityProv = useRef(null);
  // const navRgn = useRef(null);
  // const navDong = useRef(null);

  // 검색 조건 실행
  const getAreaInstallerList = (
    map,
    s = {
      cityProvCode: "",
      rgnCode: "",
      dongCode: "",
      mapLevel: DEFAULT_MAP_LEVEL,
      lati: DEFAULT_LATI,
      longi: DEFAULT_LONGI,
    },
    isClusterClick = false,
  ) => {
    setMap(map);

    s = {
      ...search,
      ...s,
      ...{ mapLevelTerm: setMapLevelInfoTerm(s.mapLevel) },
    };
    // setSearch(s);
    console.log("getAreaInstallerList s", s);

    // 위치 이동
    setMapPosition(s.lati, s.longi, map);
    if (isClusterClick) setNavigator(s);

    map.setLevel(s.mapLevel); // map 의 zoom_change 함수 실행 시킨다.

    if (isClusterClick) return false;

    if (s.mapLevel <= DEFAULT_MAP_LEVEL && s.mapLevel >= MIN_CITY_MAP_LEVEL) {
      s = { ...s, ...{ type: AREA_CITY, cityProvCode: "", rgnCode: "", dongCode: "" } };
      setNavCity(defaultNav);
      setNavRgn(defaultNav);
      setNavDong(defaultNav);
      getCityDataList(
        conn,
        s,
        result => {
          console.log("getCityDataList", result);
          setAreaDataList(result);
          setClusterList(result, map, AREA_CITY);
        },
        getRejection,
      );
    } else if (s.mapLevel < MIN_CITY_MAP_LEVEL && s.mapLevel >= MIN_RGN_MAP_LEVEL) {
      s = { ...s, ...{ type: AREA_RGN, rgnCode: "", dongCode: "" } };
      setNavRgn(defaultNav);
      setNavDong(defaultNav);
      getRgnDataList(
        conn,
        s,
        result => {
          console.log("getRgnDataList", result);
          setAreaDataList(result);
          setClusterList(result, map, AREA_RGN);
        },
        getRejection,
      );
    } else if (s.mapLevel < MIN_RGN_MAP_LEVEL && s.mapLevel >= MIN_DONG_MAP_LEVEL) {
      setNavDong(defaultNav);
      s = { ...s, ...{ type: AREA_DONG, dongCode: "" } };
      getDongDataList(
        conn,
        s,
        result => {
          console.log("getDongDataList", result);
          setAreaDataList(result);
          setClusterList(result, map, AREA_DONG);
        },
        getRejection,
      );
    } else if (s.mapLevel < MIN_DONG_MAP_LEVEL && s.mapLevel >= 0) {
      s = { ...s, ...{ type: AREA_MARKER, cityProvCode: "", rgnCode: "" } };
      getInstallAreaDataList(
        conn,
        s,
        result => {
          console.log("marker list", result);
          setInstallerList(result.installer);
          meainList.current = result.meainList;
          setMarkerList(meainList.current, map);
        },
        getRejection,
      );
    }

    getInstallMeainStatData(conn, s, result => setMeainStatCnt({ ...meainStatCnt, ...result }), getRejection);
    setSearch({ ...search, ...s });
  };

  // 리젝션 함수 제작
  const getRejection = error => {
    if (error.errCode === "F011") {
      kendo.alert("필요한 검색 파라미터가 선택 및 입력 되지 않았습니다. 다시 확인 해 주십시오.");
      return;
    }
  };

  // 맵 좌표 요청 (맵 이동)
  const setMapPosition = (lati, longi, map) => {
    if (map) {
      if (!lati) {
        lati = DEFAULT_LATI;
      }
      if (!longi) {
        longi = DEFAULT_LONGI;
      }
      map.setCenter(new kakao.maps.LatLng(lati, longi));
    }
  };

  // 네비게이터 설정
  const setNavigator = s => {
    console.log("navi s", s);
    // const cityTitle = s.cityProvCode ? "> " + s.cityProvTerm : " ";
    // const rgnTitle = s.rgnCode ? " > " + s.rgnTerm : " ";
    // const dongTitle = s.dongCode ? " > " + s.dongTerm : " ";
    // setNavigatorTerm("전국 " + cityTitle + rgnTitle + dongTitle);
  };

  // 맵 레벨 가이드 설정
  const setMapLevelInfoTerm = mapLevel => {
    let levelLabel = "";
    if (mapLevel === DEFAULT_MAP_LEVEL) levelLabel = "전체 시도별";
    else if (mapLevel === MIN_CITY_MAP_LEVEL) levelLabel = "해당 시도별의 전체 시군구";
    else if (mapLevel === MIN_RGN_MAP_LEVEL) levelLabel = "시군구";
    else if (mapLevel === MIN_DONG_MAP_LEVEL) levelLabel = "읍면동";
    return levelLabel;
  };

  // 맵 마크 업 작성
  const setMarkerList = (meainList, map) => {
    // 이전 마커 삭제
    removeMarkers();

    // 위치 정보 중복 처리 로직 추가 하기 (설비가 만약 천안시 중복으로 있을 경우 위치 정보 중복 제거로 마크를 중복으로 찍기 안하기 위해 작업)
    const meainLocationList = meainList.filter((item, i) => {
      return (
        meainList.findIndex((item2, j) => {
          return item.userId === item2.userId && item.lati === item2.lati && item.longi === item2.longi;
        }) === i
      );
    });

    meainLocationList.forEach(meain => {
      // 마커이미지의 주소입니다
      let imageSrc = "";
      if (meain.meainStatCodeNo === 7001) {
        imageSrc = "/images/mark/mark_ready.png";
      } else if (meain.meainStatCodeNo === 7002) {
        imageSrc = "/images/mark/mark_normal.png";
      } else if (meain.meainStatCodeNo === 7004) {
        imageSrc = "/images/mark/mark_unoper.png";
      } else if (meain.meainStatCodeNo === 7006) {
        imageSrc = "/images/mark/mark_warn.png";
      } else if (meain.meainStatCodeNo === 7007) {
        imageSrc = "/images/mark/mark_error.png";
      } else {
        imageSrc = "/images/mark/mark_error.png";
      }

      const marker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(meain.lati, meain.longi),
        image: new kakao.maps.MarkerImage(imageSrc, new kakao.maps.Size(27, 32), { offset: new kakao.maps.Point(16.5, 32) }), // 마커이미지 설정
        clickable: true,
      });

      // 마커가 지도 위에 표시되도록 설정합니다
      marker.setMap(map);
      markers.current.push(marker);

      const infoWindow = new kakao.maps.InfoWindow({
        position: new kakao.maps.LatLng(meain.lati, meain.longi),
        content: MeainInfo(meain),
      });
      // 마커에 클릭이벤트를 등록합니다
      kakao.maps.event.addListener(marker, "mouseover", () => infoWindow.open(map, marker));
      kakao.maps.event.addListener(marker, "mouseout", () => infoWindow.close());
      kakao.maps.event.addListener(marker, "click", () => {
        setContentLayoutMargin("/monitor/installerGath");
        history.push(
          "/monitor/installerGath?installerId=" + meain.userId + "&ensoType=" + meain.ensoTypeCode + "&rgnCode=" + meain.rgnCode,
        );
      });
    });
  };
  // 카카오 맵의 마커를 제어 (삭제)
  const removeMarkers = () => {
    for (let i = 0; i < markers.current.length; i++) {
      markers.current[i].setMap(null);
    }
    // 저장된 마커 전부 초기화
    if (search.dongCode === "") {
      markers.current = [];
    }
  };

  // 클러스터 마커 생성
  const setClusterList = (areaInfoList, map, type) => {
    // 이전 마커 삭제
    removeMarkers();
    areaInfoList.forEach(areaInfo => {      
      let clusterStyle = getClusterStyle(map.getLevel(), areaInfo.rate, areaInfo.instRate);
      let content =
        '<div class = "areaCluster ' +
        clusterStyle.className +
        ' " id ="' +
        areaInfo.areaCode +
        '">' +
        areaInfo.areaTerm +
        "<br>" +
        areaInfo.cntTotal +
        "" +
        "</div>";
      let position = new kakao.maps.LatLng(areaInfo.lati, areaInfo.longi);
      let clusterMarker = new kakao.maps.CustomOverlay({
        position: position,
        content: content,
      });
      markers.current.push(clusterMarker);
      clusterMarker.setMap(map);

      const cluster = document.getElementById(areaInfo.areaCode);
      if (cluster) {
        cluster.style.width = clusterStyle.len;
        cluster.style.height = clusterStyle.len;
        cluster.style.fontSize = clusterStyle.fontSize;
        if (cluster) {
          cluster.addEventListener("click", () => {
            const s = {
              ...search,
              ...{
                dongCode: type === AREA_DONG ? areaInfo.areaCode : "",
                dongTerm: type === AREA_DONG ? areaInfo.areaTerm : "",
                rgnCode: type === AREA_RGN ? areaInfo.areaCode : "", // result[0].code.substring(0,5),
                rgnTerm: type === AREA_RGN ? areaInfo.areaTerm : "",
                cityProvCode: type === AREA_CITY ? areaInfo.areaCode : "", // result[0].code.substring(0,2),
                cityProvTerm: type === AREA_CITY ? areaInfo.areaTerm : "",
                mapLevel: getMapLevel(type, map),
                lati: areaInfo.lati,
                longi: areaInfo.longi,
              },
            };
            getAreaInstallerList(map, s, true);
          });
        }
      }
    });
  };

  const getLocationInfo = (map, evtName) => {
    const latlng = map.getCenter();
    geocoder.coord2RegionCode(map.getCenter().getLng(), map.getCenter().getLat(), result => {
      console.log(
        evtName,
        ", 위도 : " + latlng.getLat() + ", 경도 " + latlng.getLng() + ", 레벨 : " + map.getLevel() + ", 코드 : " + result[0].code,
        result,
      );
      if (evtName !== "center_changed")
        getDong(conn, result[0].code.substring(0, 8) + "00")
          .then(r => {
            setNavCity({ areaCode: r.provinceCode, areaTerm: r.provinceTerm });
            setNavRgn({ areaCode: r.rgnCode, areaTerm: r.rgnTerm });
            setNavDong({ areaCode: r.fixbylawBundCode, areaTerm: r.fixbylawBundTermSimple });
            getAreaInstallerList(map, {
              dongCode: result[0].code.substring(0, 8) + "00",
              rgnCode: result[0].code.substring(0, 5),
              cityProvCode: result[0].code.substring(0, 2),
              mapLevel: map.getLevel(),
              lati: latlng.getLat(),
              longi: latlng.getLng(),
              // mapLevel: map.getLevel(),
              bizYy: bizYyRef.current.value === "사업연도" ? "" : bizYyRef.current.value,
              bldSrvCodeNo: bldSrvCodeNoRef.current.value.codeNo,
              bizSct: bizSctRef.current.value.codeNo,
              userTerm: userTermRef.current.value,
            });
          })
          .catch(e => console.log(e));
    });
  };

  const getMapLevel = (type, map) => {
    if (type === AREA_CITY) return MAX_RGN_MAP_LEVEL;
    else if (type === AREA_RGN) return MAX_DONG_MAP_LEVEL;
    else if (type === AREA_DONG) return MAX_MARKER_MAP_LEVEL;
    else return map.getLevel();
  };

  const getClusterStyle = (mapLevel, rate, installRate) => {
    const style = { len: "40px", fontSize: "12px", className: "areaClusterColor20" };
    let len = 40;
    let fontSize = 12;
    if (mapLevel === 13) {
      len = 40;
      fontSize = 12;
    } else if (mapLevel === 12) {
      len = 45;
      fontSize = 12;
    } else if (mapLevel === 11) {
      len = 80;
      fontSize = 16;
    } else if (mapLevel === 10) {
      len = 85;
      fontSize = 18;
    } else if (mapLevel === 9) {
      len = 60;
      fontSize = 12;
    } else if (mapLevel === 8) {
      len = 65;
      fontSize = 12;
    } else if (mapLevel === 7) {
      len = 70;
      fontSize = 12;
    } else if (mapLevel === 6) {
      len = 75;
      fontSize = 12;
    } else if (mapLevel === 5) {
      len = 80;
      fontSize = 12;
    } else if (mapLevel === 4) {
      len = 85;
    }

    let lenAlpha = 0;
    if (installRate > 20 && installRate < 41) lenAlpha = 20;
    else if (installRate > 40 && installRate < 61) lenAlpha = 30;
    else if (installRate > 60 && installRate < 81) lenAlpha = 40;
    else if (installRate > 80) lenAlpha = 60;

    len = len + lenAlpha;

    len = len + 10;
    fontSize = fontSize + 1;
    style.len = len + "px";
    style.fontSize = fontSize + "px";

    if (rate < 21) style.className = "areaClusterColor20";
    else if (rate > 20 && rate < 41) style.className = "areaClusterColor40";
    else if (rate > 40 && rate < 61) style.className = "areaClusterColor60";
    else if (rate > 60 && rate < 81) style.className = "areaClusterColor80";
    else if (rate > 80) style.className = "areaClusterColor100";

    return style;
  };

  // 처음 설비 마크업 설정
  useEffect(() => {
    const map = new kakao.maps.Map(document.getElementById("locaitonMap"), {
      center: new kakao.maps.LatLng(DEFAULT_LATI, DEFAULT_LONGI), //지도의 중심좌표.
      level: DEFAULT_MAP_LEVEL, //지도의 레벨(확대, 축소 정도)
      mapTypeId: 3,
    });
    setMap(map);
    kakao.maps.event.addListener(map, "zoom_changed", () => getLocationInfo(map, "zoom_changed"));
    kakao.maps.event.addListener(map, "dragend", () => getLocationInfo(map, "dragend"));
    getAreaInstallerList(map);
    /* eslint-disable-next-line */
  }, []);

  const navigatorClickEvt = type => {
    console.log("navigator type", type);
    if (type === "") getAreaInstallerList(map);
  };
  const defaultNav = { areaCode: "", areaTerm: "" };
  const [navCity, setNavCity] = useState(defaultNav);
  const [navRgn, setNavRgn] = useState(defaultNav);
  const [navDong, setNavDong] = useState(defaultNav);

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="md-card">
          <div className="md-card-content locationMap3">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">
                <span className={styles.navigatorTerm} onClick={() => navigatorClickEvt("")}>
                  {" "}
                  전국{" "}
                </span>
                {navCity.areaCode !== "" && (
                  <span className={styles.navigatorTerm} onClick={() => navigatorClickEvt(AREA_CITY)}>
                    {" "}
                    &gt; {navCity.areaTerm}{" "}
                  </span>
                )}
                {navRgn.areaCode !== "" && (
                  <span className={styles.navigatorTerm} onClick={() => navigatorClickEvt(AREA_RGN)}>
                    {" "}
                    &gt; {navRgn.areaTerm}{" "}
                  </span>
                )}
                {navDong.areaCode !== "" && (
                  <span className={styles.navigatorTerm} onClick={() => navigatorClickEvt(AREA_DONG)}>
                    {" "}
                    &gt; {navDong.areaTerm}{" "}
                  </span>
                )}
                {/* (지도 레벨 : { search.mapLevelTerm } ) */}
              </h3>
              {/* <input type="hidden" ref={navCityProv}/>
                <input type="hidden" ref={navRgn}/>
                <input type="hidden" ref={navDong}/> */}
              <div className="md-card-toolbar-actions">{/* <b className="textBl">{navigatorTerm}</b> */}</div>
            </div>
            <div id="locaitonMap" style={{ width: "100%", height: "950px" }}></div>
            <div className={styles.menuWrap}>
              <div className="chmapTBox">
                <div className="chmapTT"> 설치지역 검색 </div>
                <LocationSearch
                  codeList={codeList}
                  getAreaInstallerList={getAreaInstallerList}
                  map={map}
                  bizYyRef={bizYyRef}
                  bldSrvCodeNoRef={bldSrvCodeNoRef}
                  bizSctRef={bizSctRef}
                  userTermRef={userTermRef}
                />
                {search.type !== AREA_MARKER ? (
                  <AreaDataList search={search} areaDataList={areaDataList} map={map} setMapPosition={setMapPosition} />
                ) : (
                  <InstallerList search={search} installerList={installerList} map={map} setMapPosition={setMapPosition} />
                )}
              </div>
            </div>
            <MeainCount statCnt={meainStatCnt} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationDepth3;
