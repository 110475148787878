const menuUrl = "/menu";
const menuApiUrl = "/menu/api";
const menuRoleUrl = "/menu/role";
const menuRoleAreaUrl = "menu/roleArea";

/**
 * ### 메뉴 트리
 */
export const getMenuTree = async (conn, data) => {
  const res = await conn.get(menuUrl + "/menuTree");
  let obj = [];

  res.forEach(element => {
    // response.forEach(element => {
    if (typeof element.menuId != "undefined" || element.menuId != null) {
      let secondMenu = [];
      let menuText = element.menuTerm;
      let term = element.menuTerm;
      if (element.pageYn === "N") {
        menuText = element.menuTerm + " [메뉴]";
      }
      element.subMenus.forEach(seconds => {
        if (typeof seconds.menuId != "undefined" || seconds.menuId != null) {
          let thirdMenu = [];
          let menuTextSc = seconds.menuTerm;
          let termSecond = seconds.menuTerm;
          if (seconds.pageYn === "N") {
            menuTextSc = seconds.menuTerm + " [메뉴]";
          }
          seconds.subMenus.forEach(third => {
            if (typeof third.menuId != "undefined" || third.menuId != null) {
              let fourthMenu = [];
              third.subMenus.forEach(fourth => {
                if (typeof fourth.menuId != "undefined" || fourth.menuId != null) {
                  fourthMenu.push({
                    text: fourth.menuTerm,
                    term: fourth.menuTerm,
                    expanded: true,
                    id: fourth.menuId,
                    pageYn: fourth.pageYn,
                    menuUrl: fourth.menuUrl,
                    moblPageYn: fourth.moblPageYn,
                  });
                }
              });
              thirdMenu.push({
                text: third.menuTerm,
                term: third.menuTerm,
                expanded: true,
                id: third.menuId,
                pageYn: third.pageYn,
                menuUrl: third.menuUrl,
                items: fourthMenu,
                moblPageYn: third.moblPageYn,
              });
            }
          });
          secondMenu.push({
            text: menuTextSc,
            term: termSecond,
            expanded: true,
            id: seconds.menuId,
            menuUrl: seconds.menuUrl,
            pageYn: seconds.pageYn,
            items: thirdMenu,
            moblPageYn: seconds.moblPageYn,
          });
        }
      });
      obj.push({
        text: menuText,
        term: term,
        expanded: true,
        id: element.menuId,
        menuUrl: element.menuUrl,
        pageYn: element.pageYn,
        items: secondMenu,
        moblPageYn: element.moblPageYn,
      });
    }
  });

  // log(obj, 'menuService.getMenuTree');

  return obj;
};

/**
 * ### 메뉴 관리 - 그리드 전체 조회
 */
export const getMenu = async (conn, data) => {
  const response = await conn.get(menuUrl + "/list");
  return response;
};

/**
 * ### 메뉴 관리 - 메뉴 추가, 수정
 */
export const setUpdateMenu = async (conn, data) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  await conn.post(menuUrl, JSON.stringify(data), config);
};

/**
 * ### 메뉴 관리 - 데이터 1건 삭제
 */
export const setDeleteMenu = async (conn, data) => {
  const response = await conn.delete(menuUrl, {
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(new Array(data)),
  });
  return response;
};

/**
 * ### 메뉴 API 관리 - 그리드 전체 조회
 */
export const getMenuApi = async (conn, data, parm) => {
  const response = await conn.get(menuApiUrl + "/list?menuId=" + parm);
  // log(response, 'menuService.getMenu');
  return response;
};

/**
 * ### 메뉴 API 관리 - 추가, 수정
 */
export const setUpdateMenuApi = async (conn, data) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  await conn.post(menuApiUrl, JSON.stringify(data), config);
};

/**
 * ### 메뉴 API 관리 - 데이터 1건 삭제
 */
export const setDeleteMenuApi = async (conn, data) => {
  const response = await conn.delete(menuApiUrl, {
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(new Array(data)),
  });
  return response;
};

/**
 * ### 메뉴 Role 관리 - 그리드 전체 조회
 */
export const getMenuRoleTree = async (conn, data, userCode) => {
  const res = await conn.get(menuRoleUrl + "/list?menuRoleCodeNo=" + userCode);
  // const response = res;
  // console.log("role", response);

  // log(response, 'getMenuRoleTree.getMenu');
  data.forEach(element => {
    res.forEach(id => {
      if (element.id === id.menuId) {
        element.checked = true;
      } else {
        element.items.forEach(secondsId => {
          if (secondsId.id === id.menuId) {
            secondsId.checked = true;
            element.checked = true;
          } else {
            for (let i = 0; i < element.items.length; i++) {
              element.items[i].items.forEach(thirdId => {
                if (thirdId.id === id.menuId) {
                  thirdId.checked = true;
                  element.items[i].checked = true;
                } else {
                  for (let j = 0; j < element.items[i].items.length; j++) {
                    element.items[i].items[j].items.forEach(fourthId => {
                      if (fourthId.id === id.menuId) {
                        fourthId.checked = true;
                        element.items[i].items[j].checked = true;
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    });
  });

  // log(data, 'menuService.getMenuRoleTree');

  return data;
};

export const getMenuRole = async (conn, menuTree, userCode, userId) => {
  const res = await conn.get(menuRoleUrl + "/list?menuRoleCodeNo=" + userCode);
  const res2 = await conn.get(menuRoleAreaUrl + "/list?menuRoleUserId=" + userId);
  const resultRes = [...res, ...res2];

  // const roleList = res;
  // console.log("role", roleList);
  // let obj = []

  menuTree.forEach(element => {
    resultRes.forEach(id => {
      if (element.id === id.menuId) {
        element.checked = true;
      } else {
        element.items.forEach(secondsId => {
          if (secondsId.id === id.menuId) {
            secondsId.checked = true;
            element.checked = true;
          } else {
            for (let i = 0; i < element.items.length; i++) {
              element.items[i].items.forEach(thirdId => {
                if (thirdId.id === id.menuId) {
                  thirdId.checked = true;
                  element.items[i].checked = true;
                } else {
                  for (let j = 0; j < element.items[i].items.length; j++) {
                    element.items[i].items[j].items.forEach(fourthId => {
                      if (fourthId.id === id.menuId) {
                        fourthId.checked = true;
                        element.items[i].items[j].checked = true;
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    });
  });

  return menuTree;
};

/**
 * ### 메뉴 Role 관리 - 추가, 수정
 */
export const setUpdateMenuRole = async (conn, menuData) => {
  // console.log("menuData",menuData)
  let saveMenuList = [];
  menuData.forEach(element => {
    if (element.menuList.length !== 0) {
      if (element.menuList.data.checked) {
        // console.log("element",element)
        saveMenuList.push({ menuId: element.menuList.data.id, menuRoleCodeNo: element.userTypeCode });
      }
      for (let i = 0; i < element.menuList.data.length; i++) {
        element.menuList.data[i].items.forEach(seconds => {
          if (seconds.checked) {
            saveMenuList.push({ menuId: seconds.id, menuRoleCodeNo: element.userTypeCode });
          }
          if (seconds.items.length !== 0) {
            seconds.items.forEach(third => {
              if (third.checked) {
                saveMenuList.push({ menuId: third.id, menuRoleCodeNo: element.userTypeCode });
              }
              if (third.items.length !== 0) {
                third.items.forEach(fourth => {
                  if (fourth.checked) {
                    saveMenuList.push({ menuId: fourth.id, menuRoleCodeNo: element.userTypeCode });
                  }
                });
              }
            });
          }
        });
      }
    }
  });

  menuData.forEach(element => {
    if (element.menuData.length !== 0) {
      // console.log("element", element);
      element.menuData.forEach(data => {
        saveMenuList.push({ menuId: data, menuRoleCodeNo: element.userTypeCode });
      });
    }
  });

  const config = {
    headers: { "Content-Type": "application/json" },
  };
  // console.log("saveMenuList",saveMenuList)

  await conn.post(menuRoleUrl, JSON.stringify(saveMenuList), config);
};

/**
 * ### 메뉴 Role 관리 - 데이터 1건 삭제
 */
export const setDeleteMenuRole = async (conn, data) => {
  const response = await conn.delete(menuRoleUrl, {
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(new Array(data)),
  });
  return response;
};

/**
 * 지자체 계정을 가져오는 함수 (드롭다운에 지자체 계정을 표출해줘야하기 때문)
 * @param {*} conn 
 * @returns 지자체 계정 리스트
 */
export const getAreaUserList = async (conn) => {
  const response = await conn.get(menuRoleAreaUrl + "/userList");
  // console.log(response);
  return response;
}


/**
 * ### 지자체 추가메뉴관리 메뉴 트리
 */
export const getAreaAddlMenuTree = async (conn) => {
  const res = await conn.get(menuUrl + "/menuTree");
  // console.log("getMenuTree res", res);
  // const response = res;
  // console.log("getMenuTree", response);
  let obj = [];

  res.forEach(element => {
    // response.forEach(element => {
    if (typeof element.menuId != "undefined" || element.menuId != null) {
      let secondMenu = [];
      let menuText = element.menuTerm;
      let term = element.menuTerm;
      if (element.pageYn === "N") {
        menuText = element.menuTerm + " [메뉴]";
      }
      element.subMenus.forEach(seconds => {
        if (typeof seconds.menuId != "undefined" || seconds.menuId != null) {
          if (seconds.areaAddlPageYn === "Y") {
            let thirdMenu = [];
            let menuTextSc = seconds.menuTerm;
            let termSecond = seconds.menuTerm;
            if (seconds.pageYn === "N") {
              menuTextSc = seconds.menuTerm + " [메뉴]";
            }
            seconds.subMenus.forEach(third => {
              if (typeof third.menuId != "undefined" || third.menuId != null) {
                if (third.areaAddlPageYn === "Y") {
                  let fourthMenu = [];
                  third.subMenus.forEach(fourth => {
                    if (typeof fourth.menuId != "undefined" || fourth.menuId != null) {
                      if (fourth.areaAddlPageYn === "Y") {
                        fourthMenu.push({
                          text: fourth.menuTerm,
                          term: fourth.menuTerm,
                          expanded: true,
                          id: fourth.menuId,
                          pageYn: fourth.pageYn,
                          menuUrl: fourth.menuUrl,
                          moblPageYn: fourth.moblPageYn,
                        });
                      }
                    }
                  });
                  thirdMenu.push({
                    text: third.menuTerm,
                    term: third.menuTerm,
                    expanded: true,
                    id: third.menuId,
                    pageYn: third.pageYn,
                    menuUrl: third.menuUrl,
                    items: fourthMenu,
                    moblPageYn: third.moblPageYn,
                  });
                }
              }
            });
            secondMenu.push({
              text: menuTextSc,
              term: termSecond,
              expanded: true,
              id: seconds.menuId,
              menuUrl: seconds.menuUrl,
              pageYn: seconds.pageYn,
              items: thirdMenu,
              moblPageYn: seconds.moblPageYn,
            });
          }
        }
      });
      obj.push({
        text: menuText,
        term: term,
        expanded: true,
        id: element.menuId,
        menuUrl: element.menuUrl,
        pageYn: element.pageYn,
        items: secondMenu,
        moblPageYn: element.moblPageYn,
      });
    }
  });

  // log(obj, 'menuService.getMenuTree');

  return obj;
};


/**
 * ### 지자체 메뉴 관리 - 그리드 전체 조회
 */
export const getMenuRoleAreaTree = async (conn, data, userId) => {
  const res = await conn.get(menuRoleAreaUrl + "/list?menuRoleUserId=" + userId);
  // let obj = [];

  data.forEach(element => {
    res.forEach(id => {
      if (element.id === id.menuId) {
        element.checked = true;
      } else {
        element.items.forEach(secondsId => {
          if (secondsId.id === id.menuId) {
            secondsId.checked = true;
            element.checked = true;
          } else {
            for (let i = 0; i < element.items.length; i++) {
              element.items[i].items.forEach(thirdId => {
                if (thirdId.id === id.menuId) {
                  thirdId.checked = true;
                  element.items[i].checked = true;
                } else {
                  for (let j = 0; j < element.items[i].items.length; j++) {
                    element.items[i].items[j].items.forEach(fourthId => {
                      if (fourthId.id === id.menuId) {
                        fourthId.checked = true;
                        element.items[i].items[j].checked = true;
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    });
  });

  // log(data, 'menuService.getMenuRoleTree');

  return data;
};


/**
 * ### 지자제 추가 메뉴 관리 - 추가, 수정
 */
export const setUpdateMenuRoleArea = async (conn, menuData, isChecked, userId) => {
  // console.log("menuData",menuData)
  let saveMenuList = [];
  menuData.forEach(element => {
    if (element.menuList.length !== 0) {
      if (element.menuList.data.checked) {
        // console.log("element",element)
        saveMenuList.push({ menuId: element.menuList.data.id, menuRoleUserId: element.userId });
      }
      for (let i = 0; i < element.menuList.data.length; i++) {
        element.menuList.data[i].items.forEach(seconds => {
          if (seconds.checked) {
            saveMenuList.push({ menuId: seconds.id, menuRoleUserId: element.userId });
          }
          if (seconds.items.length !== 0) {
            seconds.items.forEach(third => {
              if (third.checked) {
                saveMenuList.push({ menuId: third.id, menuRoleUserId: element.userId });
              }
              if (third.items.length !== 0) {
                third.items.forEach(fourth => {
                  if (fourth.checked) {
                    saveMenuList.push({ menuId: fourth.id, menuRoleUserId: element.userId });
                  }
                });
              }
            });
          }
        });
      }
    }
  });

  menuData.forEach(element => {
    if (element.menuData.length !== 0) {
      // console.log("element", element);
      element.menuData.forEach(data => {
        saveMenuList.push({ menuId: data, menuRoleUserId: element.userId });
      });
    }
  });

  const config = {
    headers: { "Content-Type": "application/json" },
  };
  //
  if(isChecked && saveMenuList.length === 0){
    saveMenuList.push({menuId: "000000", menuRoleUserId: userId})
  }
  console.log(saveMenuList);
  await conn.post(menuRoleAreaUrl, JSON.stringify(saveMenuList), config);
};