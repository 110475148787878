import { useState, useEffect } from "react";

import { efficiencyRankingList } from "../../../service/ai/optomalAzimIncl/optomalAzimInclService";

import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

const RgnMakerEfficiencyTab = ({ conn, styles, selectedRgn, chungnamRgnList, setLoading, getRejection, tabStat }) => {
    // 엑셀 파일 이름
    let [fileName, setFileNameExcel] = useState("");

    const [effiRankList, setEffiRankList] = useState("");

    const today = kendo.toString(new Date(Date.parse(new Date())), 'yyyy-MM-dd');
    // const today = kendo.toString(new Date(Date.parse(new Date("2022-09-01"))), 'yyyy-MM-dd');

    function setFileName() {        
        let template = (selectedRgn?.selectedRgn) ? chungnamRgnList.filter(item => item.rgnCode === selectedRgn.selectedRgnCodeS)[0]?.rgnTermSimple +
            "_제조사별_이용률_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx" : " .xlsx";
        return template;
    }

    let _export;
    const downloadExcel = () => {
        if (!selectedRgn?.selectedRgn) {
            kendo.alert("지역을 선택해주세요");
        }
        else {
            setLoading(true);
            console.log("Download Excel");
            setFileNameExcel(setFileName());
            _export.save();
        }
    };

    const completeExcel = () => {
        setLoading(false);
    };

    const imgCell = (props) => {
        let rankingNum;
        if (typeof (props.dataItem.ranking) === 'string') {
            rankingNum = props.dataItem.ranking.slice(0, 1);
            rankingNum = parseInt(rankingNum);
        }

        return (
            <>
                {rankingNum === 1 ?
                    <td>
                        <img src={"/images/dist/medal_gold.png"} style={{ width: 30, height: 31 }} alt="" />
                    </td>
                    :
                    rankingNum === 2 ?
                        <td>
                            <img src={"/images/dist/medal_silver.png"} style={{ width: 30, height: 31 }} alt=""/>
                        </td>
                        :
                        rankingNum === 3 ?
                            <td>
                                <img src={"/images/dist/medal_copper.png"} style={{ width: 30, height: 31 }} alt=""/>
                            </td>
                            :
                            <td>
                                {props.dataItem?.ranking}
                            </td>
                }
            </>
        )
    }

    const getEfficiencyRankingList = (efficiencyRankingList) => {
        setEffiRankList(efficiencyRankingList);
        setLoading(false);
    }

    useEffect(() => {
        efficiencyRankingList(conn, getEfficiencyRankingList, getRejection, selectedRgn.selectedRgnCodeS, setLoading);
        /* eslint-disable-next-line */
    }, [selectedRgn, tabStat]);

    return (
        <dd className={styles.rgnMakerEfficiencyTab}>
            <div className={["uk-grid", styles.rgnMakerEfficiencyTabHead].join(' ')}>
                <div className="uk-width-medium-4-5">
                    {(!selectedRgn?.selectedRgn) ?
                        <h2>충남</h2>
                        :
                        <h2>충남 {chungnamRgnList.filter(item => item.rgnCode === selectedRgn.selectedRgnCodeS)[0]?.rgnTermSimple}</h2>
                    }
                </div>
                <div className={["uk-width-medium-1-5", styles.btnSpace].join(' ')}>
                    <Button className={styles.writeBtn} icon="excel" onClick={downloadExcel}>
                        엑셀저장
                    </Button>
                </div>
            </div>
            <div>
                <div className="uk-width-medium-5-5 noticeSpace">
                    ※순위는 이용률을 기준으로 매겨집니다.(기준일 : {today})&nbsp;&nbsp;&nbsp;
                </div>
                <ExcelExport
                    data={(effiRankList)}
                    ref={exporter => (_export = exporter)}
                    fileName={fileName}
                    onExportComplete={completeExcel}>
                    <ExcelExportColumn field="ranking" title="순위" width={150} />
                    <ExcelExportColumn field="makrTerm" title="모듈 제조사" width={300} />
                    <ExcelExportColumn field="powerEffic" title="이용률(%)" width={300} />
                    <ExcelExportColumn field="instCapa" title="설비 용량(kW)" width={300} />
                    <ExcelExportColumn field="dayPower" title="일일발전량(kWh)" width={300} />
                    <ExcelExportColumn field="dailySmpAmount" title="일일 SMP 환전액(원)" width={300} />
                </ExcelExport>
                <LocalizationProvider language="ko-KR">
                    <IntlProvider locale="ko">
                        <Grid
                            id="grid" className={styles.rgnMakerEfficiencyGrid}
                            data={effiRankList}
                            style={{ height: "800px" }}
                            scrollable="none"
                        >
                            <Column field="ranking" title="순위" width="65px" cell={imgCell}
                            />
                            <Column field="makrTerm" title="모듈 제조사" width="200px"
                            />
                            <Column field="powerEffic" title="이용률(%)"
                            />
                            <Column field="instCapa" title="설비 용량(kW)"
                            />
                            <Column field="dayPower" title="일일발전량(kWh)"
                            />
                            <Column field="dailySmpAmount" title="일일 SMP 환전액(원)"
                            />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </div>
        </dd >
    );
};

export default RgnMakerEfficiencyTab;