import { Window } from "@progress/kendo-react-dialogs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Upload } from "@progress/kendo-react-upload";
import { getToken } from "../../service/authService";

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  InsertImage,
  OrderedList,
  CleanFormatting,
  UnorderedList,  
  FontSize,  
  ForeColor,  
} = EditorTools;

const NotiAdd = ({
  windowState,
  styles,
  writebbs,
  data,
  titlChange,
  noticeChange,
  ctntChange,
  onAddFile,
  onRemoveFile,
  onStatusChangeFile,
  fileState,
  saveNoti,
  cancel,
  isMobile,
}) => {
  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;
  const requiredOption = () => {
    alert("제목을 입력해주세요");
  };

  const CSS = `p {color: black;}`;
  const onMount = event => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(CSS));
    iframeDocument.head.appendChild(style);
  };
  return (
    <>
      <Window
        className="noti-info"
        title={"글쓰기"}
        left={windowState.left}
        top={windowState.top}
        width={windowState.width}
        height={windowState.heiht}
        onClose={writebbs}
        initialHeight={isMobile ? innerHeight : 750}
        initialWidth={isMobile ? innerWidth : 1400}>
        <form className="uk-form-stacked">
          <div className="k-form">
            <span>
              제목 &nbsp;&nbsp;
              <input
                className={styles.noticeTitle}
                type="text"
                name="titl"
                value={data.titl ? data.titl : ""}
                onChange={titlChange}
                required={true}></input>
            </span>
            <br></br>

            <div>
              공지
              <Checkbox className="notiCheckBox" label={""} onChange={noticeChange} />
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Editor
                    type="text"
                    name="ctnt"
                    defaultContent={data.ctnt ? data.ctnt : ""}
                    onChange={ctntChange}
                    contentStyle={{
                      width: "100%",
                      height: "350px",
                    }}
                    onMount={onMount}
                    title="공지사항 에디터"
                    tools={
                      isMobile
                        ? [
                            [Bold, Italic, Underline, CleanFormatting, FontSize],
                            [ForeColor, OrderedList, UnorderedList],
                            [AlignLeft, AlignCenter, AlignRight],
                            [InsertImage],
                          ]
                        : [
                            [Bold, Italic, Underline, CleanFormatting],
                            [FontSize],
                            [ForeColor],
                            [OrderedList, UnorderedList],
                            [AlignLeft, AlignCenter, AlignRight],
                            [InsertImage],
                          ]
                    }
                  />
                </IntlProvider>
              </LocalizationProvider>
            </div>

            <br></br>
            <div className="demo-hint">
              최대 업로드 용량 : <strong>10MB</strong>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Upload
                    autoUpload={false}
                    batch={true}
                    multiple={true}
                    files={fileState.fileList}
                    // 함수를 정의해서 배열에다 파일 객체만큼
                    defaultFiles={fileState.fileList.length > 0 ? fileState.fileList : []}
                    onAdd={onAddFile}
                    onRemove={onRemoveFile}
                    onStatusChange={onStatusChangeFile}
                    withCredentials={true}
                    restrictions={{
                      allowedExtensions: [".gif", ".jpg", ".png", ".xls", ".xlsx", ".hwp", ".doc", ".pdf", ".exe", ".zip"],
                      maxFileSize: 10240000,
                    }}
                    saveMethod={"post"}
                    saveHeaders={{
                      "Content-Type": "multipart/form-data",
                      "X-AUTH-TOKEN": getToken(),
                    }}
                    saveUrl={"/file/upload?fileInfo=" + encodeURI(JSON.stringify(fileState.currentFiles))}
                    removeUrl={"/file/delete"}
                  />
                </IntlProvider>
              </LocalizationProvider>
            </div>

            <br></br>
            <div className="btnbox">
              {data.titl !== "" ? (
                data.titl !== undefined ? (
                  <button style={{background:'transparent'}} className="k-button k-button-icontext 22k-grid-add" onClick={saveNoti}>
                    저장
                  </button>
                ) : (
                  <button style={{background:'transparent'}} type="submit" className="k-button k-button-icontext 22k-grid-add" onClick={requiredOption}>
                    저장
                  </button>
                )
              ) : (
                <button style={{background:'transparent'}} type="submit"className="k-button k-button-icontext 22k-grid-add" onClick={requiredOption}>
                  저장
                </button>
              )}

              <button style={{background:'transparent'}} className="k-button k-button-icontext k-grid-add" onClick={cancel}>
                취소
              </button>
            </div>
          </div>
        </form>
      </Window>
    </>
  );
};

export default NotiAdd;
