// 설치 유형 15001 (설치유형별 분석 시간)
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat, roundNum } from '../../../../com/dataUtil';

const InstTypeTimeChart = ( {instTypeDs} ) => {
    // 차트 옵션
    const chartConfig = {
        chart:{ marginTop: 50 },
        title: { text: null },
        credits: { enabled: false },
        xAxis: { categories : instTypeDs.gathDtm ,labels : { style: { color: '#000000' } }  },
        yAxis: {
          labels: {
            style: { color: '#000000' }
          },
          title: {
              text: '발전효율(%)',
              rotation: 0,
              y: -20,
              offset: 0,
              align: 'high',
              style: { color: '#000000' }
          }
        },
        legend: { layout: 'vertical', align: 'right', verticalAlign: 'middle' ,backgroundColor:
        (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
        "#FFFFFF", },
        plotOptions: {
            series: { label: { connectorAllowed: false } }
        },
        tooltip: {
          shared: true,
          formatter: function() {
            let msg = `<span><b>${this.points[0].key}</b></span><br>`;

            for(const idx in this.points){
              msg += 
                `
                  <span style="color:${this.points[idx].point.color}">\u25CF</span>
                  <span>${this.points[idx].series.name}</span> : <b>${numFormat(roundNum(this.points[idx].y , 2 ))} (kwh)</b><br>
                `
            }

            return msg;
          }
        },
        series: [
            { name: '지상형', data: instTypeDs.outEficAnly , color : '#0082ff'}
            , { name: '경사지붕형', data: instTypeDs.pitchedEficAnly , color : '#DC143C'}
            , { name: '평슬라브형', data: instTypeDs.roofEficAnly , color : '#32CD32'}
            , { name: '곡면형', data: instTypeDs.groundEficAnly , color : '#DAA520'}
            , { name: 'BIPV', data: instTypeDs.biEficAnly , color : '#6A5ACD'}
            , { name: '미분류',data: instTypeDs.nullEficAnly , color : '#FF69B4'}
        ],
        responsive: {
            rules: [{
                condition: { maxWidth: 500 },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    };

    return(
        <div>
            <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
        </div>
    )
}

export default InstTypeTimeChart;