// 태양광 설비 리스트
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import React , {useCallback, useState } from "react";
// 셀 필터
import GridColumnInputFilter from '../../com/gridFilter/gridColumnInputFilter';
import Custom_filter_status_dropdown from '../../com/gridFilter/custom_filter_status_dropdown';
// 켄도 윈도우
import {Window} from '@progress/kendo-react-dialogs'
// 켄도 업로드
import { Upload } from '@progress/kendo-react-upload';
// 켄도 UI 관련 (ex : kendo.alert 등등)
import kendo from '@progress/kendo-ui';
// 켄도 버튼
import {Button} from '@progress/kendo-react-buttons'
// 서비스
import { getExcelMeainList } from '../../../service/meain/meain/meainService';
import { getToken } from '../../../service/authService';
// CSS
import styles from './meain.module.css';

import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import koMessage from "../../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

// 로컬라이즈
// import { IntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';

// 설비 그리드 (통합)
const MeainGrid = ( {
  conn , setMeainList , setSelectMeain , paging , ensoTypeCodeNo , sysMode , cityFilterDropDown , rgnFilterDropDown , getSaveResult , getRejection  , setMeain , valueValidator
  , meainListLoading , setSaveLoading , BeatLoader 
} ) =>{

  // --------- 파일 업로드 START
  // 파일 업로드 윈도우 창 제어
  let [windowVisible , setWindowVisible] = useState(false);
  // 엑셀 업로드 헤더 설정
  // eslint-disable-next-line
  const handleUploadHeaders = useCallback(e =>  {e.headers['X-AUTH-TOKEN'] = getToken();}); 

  // 파일 업로드 
  let [uploadState , setUploadState] = useState({
      files: [],
      events: [],
      filePreviews: {},
      afterStateChange : []
  });

  // 파일 업로드 후 문제 없다면 저장할 리스트 변수
  let [uploadDataList , setUploadDataList] = useState([]);
  // --------- 파일 업로드 END

  // 그리드 검색 필터 이벤트
  const searchFilter = (e) =>{
    paging.skip = 0;
    paging.take = 20;

      if( e.filter.filters[0].field === "meainStatTerm" ){
          if( e.filter.filters[0].value === 7000 ){ paging.filter.meainStatusCode = ""; }
          else{ paging.filter.meainStatusCode = e.filter.filters[0].value; }
      }
      else if ( e.filter.filters[0].field === "cityProvTerm" ){
        if( e.filter.filters[0].value.cityProvTerm === "" ){ paging.filter.cityProvTerm = ""; }
        else{ paging.filter.cityProvCode = e.filter.filters[0].value.cityProvCode; }
      }
      else if ( e.filter.filters[0].field === "rgnTerm" ){
          if( e.filter.filters[0].value.rgnCode === "" ){ paging.filter.rgnCode = ""; }
          else{ paging.filter.rgnCode = e.filter.filters[0].value.rgnCode; }
      }
      else if ( e.filter.filters[0].field === "cid" ){
          paging.filter.cid = e.filter.filters[0].value;
      }
      else if ( e.filter.filters[0].field === "userTerm" ){
          paging.filter.userTerm = e.filter.filters[0].value;
      }
      setMeainList(paging);
  }

  // 로우 선택
  const onRowClick = (e) =>{
      const {dataItem} = e;
      let meain = { selectMeain : dataItem, selectId : dataItem.cid }
      setSelectMeain(meain);
  }

  // 엑셀 업로드
    // 액셀 업로드 윈도우 창 제어
    const uploadExcel = () => {
      initialExcelFile();
      setWindowVisible(!windowVisible);
  }
  
  // 엑셀 업로드 관련 함수 모음 
  const onAdd = (event) => {
      // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.
      const afterStateChange = () => {
          event.affectedFiles
              .filter(file => !file.validationErrors)
              .forEach(file => {
                  const reader = new FileReader();
                  reader.onloadend = (ev) => {
                      setUploadState({
                          files: uploadDataList.files,
                          events: uploadDataList.events,
                          filePreviews: {
                                      [file.uid]: ev.target.result
                                  }
                      });
                  };
                  reader.readAsDataURL(file.getRawFile());
              });
      };
      // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.    
      setUploadState({
          files: event.newState,
          events: [
              uploadDataList.events
          ] ,
          filePreviews : uploadDataList.filePreviews,
          afterStateChange : afterStateChange
      });
  }
  const onRemove = (event) => {
      // console.log("onRemove event = ", event);
  }
  const onProgress = (event) => {
      // console.log("onProgress event = ", event);
  }
  const onStatusChange = (event) => {
      // 이걸 안해주면 파일 업로드 로딩이 안끝난다.
      setUploadState({
          files: event.newState
      });
      setUploadDataList(event.response.response);
  }

  // 엑셀 업로드 취소 , 닫기 누를 시 초기화
  const initialExcelFile = () => {
      setUploadState({
          files: []
      });
      setUploadDataList([]);
  }

  // 업로드 Save
  const uploadSave = () =>{
    const saveList = uploadDataList.data;
    if( ensoTypeCodeNo === 15002 ){
      saveList.forEach(element => {
        element.sysMode = sysMode;
      });
    }

    // 값 Validation
    const { boolean , message } = valueValidator(saveList);
    if( boolean === false ){
      kendo.alert(message);
      return;
    }

    setMeain( conn , excelUploadComplete , getRejection , saveList , ensoTypeCodeNo , sysMode );
  }

  // 엑셀 업로드 callback 
  const excelUploadComplete = () => {
    // kendo.alert("저장 하였습니다.");
    getSaveResult();
    uploadExcel();
  }

  // 엑셀 업로드 URL 설정
  const getExcelUploadUrl = () => {
    let url = "";
    if(ensoTypeCodeNo === 15001){ url = "/api/v2.2/meain/sola/xlsx"; } 
    else if(ensoTypeCodeNo === 15002){
      // 강제순환식
      if(sysMode === 29001){ url = "/api/v2.2/meain/heat/xlsx"; }
      // 자연순환식
      else{ url = "/api/v2.2/meain/heat/xlsx/na"; }
    } else if(ensoTypeCodeNo === 15003){ url = "/api/v2.2/meain/heath/xlsx"; } 
    else if(ensoTypeCodeNo === 15004){ url = "/api/v2.2/meain/velo/xlsx"; } 
    else if(ensoTypeCodeNo === 15006){ url = "/api/v2.2/meain/fuelcell/xlsx"; }
    else if(ensoTypeCodeNo === 15007){ url = "/api/v2.2/meain/ess/xlsx"; }
    return url;
  }

  // 엑셀 샘플 다운로드
  const downloadSample = () =>{
      const link = document.createElement('a');
      if( ensoTypeCodeNo === 15001 ){
        link.href = "/template/meain/설비관리_태양광_엑셀업로드샘플.xlsx";
      }else if( ensoTypeCodeNo === 15002 ){
        if( sysMode === 29001 ){
          link.href = "/template/meain/설비관리_태양열_엑셀업로드샘플.xlsx";
        }else{
          link.href = "/template/meain/설비관리_태양열(자연)_엑셀업로드샘플.xlsx";
        }
      }else if( ensoTypeCodeNo === 15003 ){
        link.href = "/template/meain/설비관리_지열_엑셀업로드샘플.xlsx";
      }else if( ensoTypeCodeNo === 15004 ){
        link.href = "/template/meain/설비관리_풍력_엑셀업로드샘플.xlsx";
      }else if( ensoTypeCodeNo === 15006 ){
        link.href = "/template/meain/설비관리_연료전지_엑셀업로드샘플.xlsx";
      }else if( ensoTypeCodeNo === 15007 ){
        link.href = "/template/meain/설비관리_ESS_엑셀업로드샘플.xlsx";
      }
      
      document.body.appendChild(link);
      link.click();
  }

  // 엑셀 저장
  const saveExcel = () =>{
    setSaveLoading(true);
    getExcelMeainList(conn , getDownloadExcel , paging , ensoTypeCodeNo , sysMode , setSaveLoading );
  }

  //엑셀 다운로드 callback
  const getDownloadExcel = (result) => {
  }

  // 페이징 이벤트
  const onPageChange = (e) =>{
      const {take, skip} = e.page;
      paging.take = take; paging.skip = skip;
      setMeainList(paging);
  }

  // 정렬 이벤트
  const onSortChange = (e) =>{
    const {sort} = e; 
    paging.sort = sort;
    setMeainList(paging);
  }

  return(
    <div className="uk-width-medium-1-2">
        <div className="md-card">
            <div className="md-card-content">
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                        설비리스트
                    </h3>
                    <div className="md-card-toolbar-actions">
                        <Button className={styles.meainBtn} onClick={uploadExcel} icon="upload">엑셀파일 가져오기</Button>
                        <Button className={styles.meainBtn} onClick={downloadSample} icon="download">엑셀샘플 다운로드</Button>
                        <Button className={styles.meainBtn} icon="excel" onClick={saveExcel}>엑셀저장</Button>
                    </div>
                   
                    {windowVisible &&
                         <Window 
                         title={"엑셀 업로드"} 
                         onClose={uploadExcel} 
                         initialHeight={240} 
                         initialWidth={600} 
                         initialLeft={600}
                         initialTop={200}
                         modal={true}
                         resizable={false}
                       >
                            <div>
                                <Upload    
                                    batch={false}
                                    multiple={false}
                                    onBeforeUpload={handleUploadHeaders}
                                    withCredentials={true}
                                    onAdd={onAdd}
                                    onRemove={onRemove}
                                    onProgress={onProgress}
                                    onStatusChange={onStatusChange}
                                    files={uploadState.files}
                                    saveUrl={getExcelUploadUrl()}
                                    // removeUrl={'http://localhost:8080/admin/admin/codeMngt/removeFile'}
                                ></Upload>
                                <div>
                                  <Button style={{ float: "right", marginLeft: 10, marginTop: 15,}} onClick={uploadExcel}>취소</Button>
                                  <Button style={{ float: "right", marginTop: 15,}} onClick={uploadSave}>저장</Button>
                                </div>
                            </div>
                        </Window>}
                      
                </div>
                <div className = { meainListLoading ? styles.meainListGridArea : ""}>
                  <Grid
                      id = "grid" className = "meainListGrid"
                      data = { paging.list.map( (item) => ({...item , selected : item.cid === paging.selectId}) ) }
                      filterable={true}
                      onFilterChange = {searchFilter}
                      style={{height:"680px"}}
                      onRowClick = {onRowClick}
                      selectedField="selected"
                      pageable={ {  type: 'numeric', pageSizes: [5, 10, 20, 50, 100], previousNext: true, buttonCount: 5, refresh: true } }
                      onSortChange={onSortChange}
                      sortable
                      onPageChange = {onPageChange}
                      total = {paging.count}
                      skip = {paging.skip}
                      take = {paging.take}
                      sort = {paging.sort}
                  >
                      <Column field="userTerm" title="수용가" 
                        filterCell = {GridColumnInputFilter}
                      />
                      <Column field="cid" title="CID" width = "150px"
                        filterCell = {GridColumnInputFilter}
                      />
                      <Column field="cityProvTerm" title="시/도" width = "150px"
                        filterCell = {cityFilterDropDown} 
                      />
                      <Column field="rgnTerm" title="시/군/구" width = "150px"
                        filterCell = {rgnFilterDropDown} 
                      />
                      <Column field="meainStatTerm" title="설비상태" width = "150px"
                        filterCell = {Custom_filter_status_dropdown}
                      />
                  </Grid>
                  { meainListLoading && <p className={styles.meainListGridAreaLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p> }
                </div>
                </IntlProvider>
              </LocalizationProvider>
            </div>
        </div>
    </div>
  );
}

export default MeainGrid;