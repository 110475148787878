import React from 'react';
import SmpDashBoardHourChart from './smpDashBoardHourChart';

// SMP 상단 대시보드 영역 - 시간별 육지, 제주 데이터 보여주는 부분
const SmpDashBoardHourData = ({ conn , styles, dashboardData }) => {
  return (
    <>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
            <div className="md-card-content">
              <div calssName="md-card-toolbar">
                <h3 className="md-card-toolbar-heading-text">금일 육지 시간별 데이터</h3>
              </div>
              <SmpDashBoardHourChart chartData = {dashboardData.smpHourTodayLand} styles = {styles} />
            </div>
          </div>
        </div>
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
            <div className="md-card-content">
            <div calssName="md-card-toolbar">
                <h3 className="md-card-toolbar-heading-text">금일 제주 시간별 데이터</h3>
              </div>
              <SmpDashBoardHourChart chartData = {dashboardData.smpHourTodayJeju} styles = {styles} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmpDashBoardHourData;