import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";
import { Input } from "@progress/kendo-react-inputs";
import styles from "./gathRawDataLog.module.css";
import "./GathRawDataLog.css";

// 엑셀 다운로드
import { getExcelDownload } from "../../../service/stats/raw/rawDataService";
import DateTimePickerKo from "../../com/dateInputs/dateTimePickerKo";
import { isInstaller } from "../../../service/authService";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ArrowRightAlt } from "@material-ui/icons";
import UserSearchList from "../../com/userSearch/userSearchList";

const GathRawDataLogSearch = ({
  search,
  setSearch,
  conn,
  codeList,
  cityList,
  rgnList,
  paging,
  setOperHistData,
  rtuList,
  makrList,
  meainModelList,
  setLoading,
  isMobile,
}) => {
  const [selected, setSelected] = useState(0);
  const handleSelect = e => {
    setSelected(e.selected);
  };
  // 에너지원 데이터 소스
  const ensoList = codeList.filter(c => c.grpCodeNo === 15 && c.codeNo !== 15007);
  const [defaultEnsoCode, setDefaultEnsoCode] = useState({ codeVal: "태양광", codeNo: "15001" });
  // 지역
  const rgns = rgnList.filter(e => e.cityProvCode === search.cityProvCode);
  // const [dongList, setDongList] = useState();

  // 에너지원별 설비타입기준 제조사 코드
  if (search.ensoTypeCode === 15001) {
    makrList = makrList.filter(e => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30002);
  } else if (search.ensoTypeCode === 15002) {
    makrList = makrList.filter(e => e.ensoTypeCodeNo === search.ensoTypeCode && (e.makrClsfNo === 30003 || e.makrClsfNo === 30013));
  } else if (search.ensoTypeCode === 15003) {
    makrList = makrList.filter(e => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30005);
  } else if (search.ensoTypeCode === 15004) {
    makrList = makrList.filter(e => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30007);
  } else if (search.ensoTypeCode === 15006) {
    makrList = makrList.filter(e => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30010);
  } else {
    makrList = [];
  }

  // optionLabel 검색 랜더링으로 인해서 무의식적인 랜더링을 방지하기 위한 라벨
  const [optionLabel, setOptionLabel] = useState({
    // dongValue : {fixbylawBundTermSimple : "읍/면/동" , fixbylawBundCode : ""} ,
    meainModl: { meainTypeTermSimple: "설비 모델", meainTypeSeriNo: "" },
    makr: { makrTermSimple: "제조사", makrSeriNo: "" },
    rtu: { rtuEnteTerm: "RTU 업체", rtuEnteCode: "" },
    cid: "",
    cityProvCode: "",
    rgnCode: "",
    makrSeriNo: "",
    rtuEnteCode: "",
  });

  // Rtu 업체 , 설비 모델 , 제조사 리스트
  // const [makrModlRtuList, setMakrModlRtuList] = useState({
  //   makrList: [],
  //   filterMakrList: [],
  //   meainModlList: [],
  //   rtuList: [],
  // });

  // 선택한 날의 마지막 날짜 구하는 로직
  const getMonthLastDay = (day) => {
    console.log("day = ", day);
    const year = day.getFullYear();
    const month = day.getMonth() + 1;
    const lastMonthDay = new Date( year , month , 0 )
    console.log( "lastMonthDay = ", lastMonthDay );

    console.log('year = ' , year);
    console.log('month = ' , month);
    return lastMonthDay;
  }

  // 날짜
  const startMax = new Date();
  const [endMin, setEndMin] = useState(new Date());
  const [endMax, setEndMax] = useState(getMonthLastDay(new Date()))

  // 드롭다운 체인지 이벤트
  const onchange = e => {
    if (e.target.name === "ensoTypeDropDown") {
      search.ensoTypeCode = e.value.codeNo;
      search.makrSeriNo = "";
      search.meainTypeSeriNo = "";
      search.rtuEnteCode = "";
      // search.cidData = "";
      // search.userTerm = "";
      // search.userId = "";
      optionLabel.makr = { makrTermSimple: "제조사", makrSeriNo: "" };
      optionLabel.meainModl = { meainTypeTermSimple: "설비 모델", meainTypeSeriNo: "" };
      optionLabel.rtu = { rtuEnteTerm: "RTU 업체", rtuEnteCode: "" };
      setDefaultEnsoCode({ codeNo: e.value.codeNo, codeVal: e.value.codeVal });
    } else if (e.target.name === "city") {
      search.city = e.target.value;
      search.cityProvCode = e.target.value.cityProvCode;
      search.cityProvTerm = e.target.value.cityProvTerm;
      search.rgnCode = "";
      search.rgnTerm = "";
      search.rgn = { rgnCode: "", rgnTermSimple: "시군구" };
      // search.fixbylawBundCode = "";
      // optionLabel.dongValue = {fixbylawBundTermSimple : "읍/면/동" , fixbylawBundCode : ""};
      setOptionLabel(optionLabel => {
        const item = { ...optionLabel };
        item.cityProvCode = e.value;
        return item;
      });
    } else if (e.target.name === "rgnDropDown") {
      search.rgnCode = e.value.rgnCode;
      search.rgn = e.value;
      // search.fixbylawBundCode = "";
      // optionLabel.dongValue = {fixbylawBundTermSimple : "읍/면/동" , fixbylawBundCode : ""};
      setOptionLabel(optionLabel => {
        const item = { ...optionLabel };
        item.rgnCode = e.value.rgnCode;
        return item;
      });
      // getDongs(conn, e.target.value.rgnCode)
      // .then(result => {
      //   setDongList(result);
      // })
    }
    // else if( e.target.name === "fixbylawBundDropDown" ){
    //   console.log("e",e);
    //   search.fixbylawBundCode = e.value.fixbylawBundCode;
    //   setOptionLabel( optionLabel => { const item = {...optionLabel}; item.dongValue = e.value; return item; });
    // }
    else if (e.target.name === "makrSeriNo") {
      // search.makrSeriNo = e.value.makrSeriNo;
      search.meainTypeSeriNo = "";
      search.makrSeriNo = e.value.makrSeriNo;
      optionLabel.meainModl = { meainTypeTermSimple: "설비 모델", meainTypeSeriNo: "" };
      setOptionLabel(optionLabel => {
        const item = { ...optionLabel };
        item.makrSeriNo = e.value.makrSeriNo;
        item.makr = e.value;
        return item;
      });
    } else if (e.target.name === "meainTypeSeriNo") {
      search.meainTypeSeriNo = e.value.meainTypeSeriNo;
      setOptionLabel(optionLabel => {
        const item = { ...optionLabel };
        item.meainModl = e.value;
        return item;
      });
    } else if (e.target.name === "rtuEnte") {
      search.rtuEnteCode = e.value.rtuEnteCode;
      setOptionLabel(optionLabel => {
        const item = { ...optionLabel };
        item.rtu = e.value;
        return item;
      });
    } else if (e.target.name === "cid") {
      search.cid = e.value;
      setOptionLabel(optionLabel => {
        const item = { ...optionLabel };
        item.cid = e.value;
        return item;
      });
    } else if (e.target.name === "userTerm") {
      search.userTerm = e.value;
      setOptionLabel(optionLabel => {
        const item = { ...optionLabel };
        item.userTerm = e.value;
        return item;
      });
    }
  };

  // 조회 버튼
  const searchData = e => {
    // 설치자명 또는 CID 를 입력 및 선택을 둘 중에 하나라도 해야한다.
    if(search.cid === "" && search.userId === ""){
      if( isMobile ){
        kendo.alert("CID 를 입력 해주세요.");
      }else{
        kendo.alert("검색 시 설치자 선택 또는 CID 를 입력 해주세요.");
      }
      return;
    }
    if (search.ensoTypeCode === "") {
      kendo.alert("에너지원을 선택 해주세요");
      return;
    } else if (search.ensoTypeCode === 15007) {
      kendo.alert("준비 중 입니다.");
      return;
    }
    // 페이징 초기화 로직 추가
    paging.skip = 0;
    paging.take = 20;
    paging.sort = [];
    search.search = true;
    // console.log("search = ", search);
    setOperHistData(search, paging);
  };

  // 엑셀 다운로드
  const excelDownload = () => {
    if (search.ensoTypeCode === "") {
      kendo.alert("에너지원을 선택 해주세요.");
      return;
    }
    if(search.cid === "" && search.userId === ""){
      kendo.alert("검색 시 설치자 선택 또는 CID 를 입력 해주세요.");
      return;
    }
    setLoading(true);
    getExcelDownload(search, paging, setLoading);
  };

  // RTU 업체 , 설비 모델 , 제조사 리스트 가져오기
  // const getRtuMakrModlList = () => {
  //   Promise.all([
  //     getRtu(conn),
  //     // getMakr(conn),
  //     getMeainModel(conn),
  //   ])
  //     .then(result => {
  //       // 필요한 제조사 데이터만 가져오도록 설비모델에서 가져오기
  //       const makrList = [];
  //       result[1].forEach(element => {
  //         makrList.push({
  //           makrSeriNo: element.makr.makrSeriNo,
  //           makrTerm: element.makr.makrTerm,
  //           useYn: element.makr.useYn,
  //           authYn: element.makr.authYn,
  //           ensoTypeCode: element.ensoTypeCodeNo,
  //         });
  //       });
  //
  //       // 중복 ID 제거 후 유니크 ID 추출 *객체의 속성이 1개만 있거나, 객체 값이 완전히 같은 것을 중복 제거 할 경우만 new Set사용
  //       const uniqMakrList = makrList.filter((item, i) => {
  //         return (
  //           makrList.findIndex((item2, j) => {
  //             return item.makrSeriNo === item2.makrSeriNo;
  //           }) === i
  //         );
  //       });
  //       setMakrModlRtuList(makrModlRtuList => {
  //         const item = { ...makrModlRtuList };
  //         item.rtuList = result[0];
  //         item.makrList = uniqMakrList;
  //         item.meainModlList = result[1];
  //         return item;
  //       });
  //     })
  //     .catch(error => {
  //       // 사용안함
  //     });
  // };

  // 에너지원 코드 확인 후 값 설정
  const setEnsoTypeCodeDropDown = () => {
    const ensoTypeCode = search.ensoTypeCode;
    if (ensoTypeCode) {
      ensoList.forEach(element => {
        if (element.codeNo === ensoTypeCode) {
          setDefaultEnsoCode({ codeNo: element.codeNo, codeVal: element.codeVal });
        }
      });
    } else {
      setDefaultEnsoCode({ codeVal: "태양광", codeNo: "15001" });
    }
  };

  // 윈도우 창 Visible
  let [userInfoVisible, setUserInfoVisible] = useState(false);

  // 설치자 검색 버튼 클릭 이벤트
  const searchUserId = () => {
    if (isInstaller() !== true) {
      setUserInfoVisible(!userInfoVisible);
    }
  };

  // 그리드에서 유저 선택 시 이벤트
  const selectUserId = e => {
    setSearch( search => {
      const item = {...search};
      item.userId = e.dataItem.userId;
      item.userTerm = e.dataItem.userTerm;
      return item;
    } );
    setUserInfoVisible(false);
  };

  useEffect(() => {
    // 에너지원 코드 확인 후 값 설정
    setEnsoTypeCodeDropDown();
    // RTU 업체 , 설비 모델 , 제조사 불러오기
    // getRtuMakrModlList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isMobile ? (
        <div>
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title="조회">
              <table className="gathRawTable">
                <tr>
                  <th>에너지원</th>
                  <td>
                    <DropDownList
                      data={ensoList}
                      name="ensoTypeDropDown"
                      textField="codeVal"
                      dataItemKey="codeNo"
                      onChange={onchange}
                      className="searchArea"
                      value={defaultEnsoCode}></DropDownList>
                  </td>
                  <td>
                    <Input
                      type="text"
                      className="searchTerm cidData"
                      name="cid"
                      id="cid"
                      placeholder="CID"
                      onChange={onchange}
                      value={search.cid}
                      disabled={isInstaller() === true ? true : false}
                    />
                  </td>
                </tr>
                <tr>
                  <th>지역검색</th>
                  <td>
                    <DropDownList
                      data={cityList}
                      textField="cityProvTerm"
                      dataItemKey="cityProvCode"
                      disabled={isInstaller() === true ? true : false}
                      name="city"
                      defaultItem={{ cityProvCode: "", cityProvTerm: "시/도" }}
                      onChange={onchange}
                      className="searchArea"></DropDownList>
                  </td>
                  <td>
                    <DropDownList
                      data={rgns}
                      defaultItem={{ rgnTermSimple: "시/군/구", rgnCode: "" }}
                      name="rgnDropDown"
                      disabled={search.cityProvCode === "" || isInstaller() === true ? true : false }
                      textField="rgnTermSimple"
                      dataItemKey="rgnCode"
                      onChange={onchange}
                      className="searchArea"
                      // disabled={isInstaller() === true ? true : false}
                      value={search.rgn}></DropDownList>
                  </td>
                </tr>
              </table>
              <table className="gathRawTable">
                <tr>
                  <th>수집기간</th>
                  <td className="gathRawSearchCalTd">
                    <DateTimePickerKo
                      className="gathRawSearchCal"
                      id="start"
                      name="start"
                      width={200}
                      defaultValue={search.startGathDtm}
                      format="yy-MM-dd HH:mm"
                      setDtm={v => {
                        // REMS의 검색 방식 적용
                        search.startGathDtm = v;
                        search.endGathDtm = v;
                        setEndMax(getMonthLastDay(v));
                        setEndMin(v);
                      }}
                      max={startMax}></DateTimePickerKo>
                    {/* &nbsp;~&nbsp; */}
                    </td>  
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <DateTimePickerKo
                      className="gathRawSearchCal"
                      id="end"
                      name="end"
                      width={200}
                      defaultValue={search.endGathDtm}
                      value = {search.endGathDtm}
                      format="yy-MM-dd HH:mm"
                      setDtm={v => {
                        setSearch(search => {
                          const item = {...search};
                          item.endGathDtm = v ;
                          return item;
                        })
                      }}
                      min={endMin}
                      max={endMax}></DateTimePickerKo>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <DropDownList
                      disabled={isInstaller() === true ? true : false}
                      data={rtuList}
                      defaultItem={{ rtuEnteTerm: "RTU 업체", rtuEnteCode: "" }}
                      name="rtuEnte"
                      textField="rtuEnteTerm"
                      dataItemKey="rtuEnteCode"
                      onChange={onchange}
                      className="searchArea"
                      value={optionLabel.rtu}></DropDownList>
                  </td>
                  <td>
                    <DropDownList
                      data={makrList}
                      defaultItem={{ makrTermSimple: "제조사", makrSeriNo: "" }}
                      name="makrSeriNo"
                      textField="makrTermSimple"
                      dataItemKey="makrSeriNo"
                      onChange={onchange}
                      className="searchArea"
                      value={optionLabel.makr}></DropDownList>
                  </td>
                  <td>
                    <DropDownList
                      disabled={search.makrSeriNo === ""}
                      value={optionLabel.meainModl}
                      data={meainModelList.filter(e => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrSeriNo === search.makrSeriNo)}
                      defaultItem={{ meainTypeTermSimple: "설비 모델", meainTypeSeriNo: "" }}
                      name="meainTypeSeriNo"
                      textField="meainTypeTermSimple"
                      dataItemKey="meainTypeSeriNo"
                      onChange={onchange}
                      className="searchArea"></DropDownList>
                  </td>
                </tr> */}
              </table>
              <div className="gathRawBtnDiv">
                <Button className={styles.mobileSearchBtn} icon="search" name="inqBtn" onClick={searchData}>
                  조회
                </Button>
              </div>
              <div className="mobileCmpUserSpan" style={{ textAlign: "right" }}>
                <ArrowRightAlt />
                &nbsp;표를 옆으로 드래그하여 확인
              </div>
            </TabStripTab>
          </TabStrip>
        </div>
      ) : (
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="md-card TBgA">
              <div className={["md-card-content", styles.searchPadding].join(" ")}>
                <span className="searchSubTitleA">에너지원</span>
                <DropDownList
                  data={ensoList}
                  name="ensoTypeDropDown"
                  textField="codeVal"
                  dataItemKey="codeNo"
                  onChange={onchange}
                  className="searchArea"
                  value={defaultEnsoCode}></DropDownList>
              <span class="searchSubTitleA">설치자</span>
              <Input
                type="text"
                className="searchTerm userTerm"
                name="userTerm"
                id="userTerm"
                onClick={searchUserId}
                placeholder="설치자"
                readOnly
                value={search.userTerm ? search.userTerm : ""}
                disabled={isInstaller() === true ? true : false}
              />
              <Button
                icon="search"
                // className={styles.searchBtn}
                onClick={searchUserId}
                disabled={isInstaller() === true ? true : false}></Button>
                <span class="searchSubTitleA">CID</span>
                <Input
                  type="text"
                  className="searchTerm cidData"
                  name="cid"
                  id="cid"
                  placeholder="CID"
                  onChange={onchange}
                  value={search.cid}
                  disabled={isInstaller() === true ? true : false}
                />
                <span className="searchSubTitleA">수집기간</span>
                <DateTimePickerKo
                  id="start"
                  name="start"
                  width={200}
                  defaultValue={search.startGathDtm}
                  format="yyyy-MM-dd HH:mm"
                  setDtm={v => {
                    // REMS의 검색 방식 적용
                    search.startGathDtm = v;
                    search.endGathDtm = v;
                    setEndMax(getMonthLastDay(v));
                    setEndMin(v);
                  }}
                  max={startMax}></DateTimePickerKo>
                <span className="textColorGr datePeriodIcon">~</span>
                <DateTimePickerKo
                  id="end"
                  name="end"
                  width={200}
                  defaultValue={search.endGathDtm}
                  value = {search.endGathDtm}
                  format="yyyy-MM-dd HH:mm"
                  setDtm={v => {
                    setSearch(search => {
                      const item = {...search};
                      item.endGathDtm = v ;
                      return item;
                    })
                  }}
                  min={endMin}
                  max={endMax}></DateTimePickerKo>
                  <span className="searchSubTitleC" style={{ paddingLeft: 5 }}>
                  <Button className="inqBtn" icon="search" name="inqBtn" onClick={searchData}></Button>
                </span>
                <span className="searchSubTitleC" style={{ float: "right", paddingRight: 10 }}>
                  <Button className="operHistExcelBtn" icon="excel" name="excelBtn" onClick={excelDownload}>
                    {" "}
                    엑셀저장
                  </Button>
                </span>
              </div>
              {userInfoVisible && (
                <UserSearchList conn={conn} setUserInfo={selectUserId} setVisible={setUserInfoVisible} initialTop = {50} initialLeft = {200} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GathRawDataLogSearch;
