

const rtuComUrl = 'rtu';
const rprsnComUrl = "rtu/mnglist/"

export const getRtu = async(conn) => {
    const result = await conn.get(rtuComUrl + "/list");
    // log(result, "rtuService.getRtuList");
    return result;
};

/**
 * ### RTU 업체관리 - 추가, 수정
 */
export const setUpdateRtu = async (conn, data) => {
  // testState codeVal => codeNo로 변경하는 코드
  data.testState = data.testStateList
    .filter((it) => it.codeVal === data.testState)
    .map((item) => item.codeNo)[0];
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const result = await conn.post(
    rtuComUrl,
    JSON.stringify(new Array(data)),
    config
  );
  
  return result;
};

/**
 * ### RTU 업체관리 - 데이터 1건 삭제
 */
export const setDeleteRtu = async (conn, data) => {
  
  // log(data, "rtuEnteCode");
  const deleteInfo = {
    rtuEnteCode : data.rtuEnteCode,
    rtuEnteId : data.rtuEnteId
  }
  const config = {
    headers: { "Content-Type": "application/json" },
    // data: deleteInfo,
    data: JSON.stringify(deleteInfo),
  };
  const result = await conn.delete(rtuComUrl, config);
  return result;
};

/**
 * ### RTU A/S 담당자 조회 - rtuEnteCode로 조회
 */
export const getRprsn = async (conn, data) => {
    const config = {
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(data),
    };
    const result = await conn.get(rprsnComUrl+ data, config);
    // log(result, "rtuService.getRtuMngByEnteCode");

    return result;
};

/**
 * ### RTU A/S 담당자 추가, 수정
 */
export const setUpdateRprsn = async (conn, data) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const result = await conn.post(
    rprsnComUrl,
    JSON.stringify(new Array(data)),
    config
  );
  return result;
};

/**
 * ### RTU A/S 담당자 삭제 - 데이터 1건 삭제
 */
export const setDeleteRprsn = async (conn, data) => {
  // log(data, "RprsnList");
  const config = {
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(new Array(data)),
  };
  conn.delete(rprsnComUrl, config);
};
