import React, { useState, useEffect } from "react";

import styles from "./menuMngt.module.css";

import { getMessage } from "../../com/messageUtil";

import kendo from "@progress/kendo-ui";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

import GridColumnInputFilter from "../com/gridFilter/gridColumnInputFilter";

import { getMenu, setUpdateMenu, setDeleteMenu } from "../../service/menu/menuService";

import "./menuMngt.css";

const MenuMngt = ({ conn }) => {
  /* 데이터 정의 */
  let [backDataSource, setBackDataSource] = useState([]);
  const [menu, setMenu] = useState({
    data: [],
    editField: undefined,
    changes: false,
  });

  /* GET API */
  const fetchMenu = (menu) => {
    getMenu(conn, menu)
      .then((menuList) => {
        // console.log("menu", menuList)
        setMenu({ data: menuList }); // 데이터는 response.data 안에 들어있습니다.
        setBackDataSource(menuList);
      })
      .catch((res) => {
        // console.error(res);
        alert(getMessage(res.errCode));
      });
  };

  useEffect(() => {
    fetchMenu(menu);
    // eslint-disable-next-line
  }, []);

  const itemChange = (event) => {
    event.dataItem.editFlag = true;
    event.dataItem[event.field] = event.value;
    setMenu({
      ...menu,
    });
  };

  /* 추가 */
  const addNew = () => {
    const newDataItem = { inEdit: true, menuSeqnNo: 0 };
    setMenu({
      data: [newDataItem, ...menu.data],
    });
  };

  /* 저장 버튼 */
  const saveChanges = () => {
    setUpdateMenu(conn, menu.data)
      .then((response) => {
        kendo.alert("저장되었습니다.");
        fetchMenu();
      })
      .catch(function (error) {
        // API 호출이 실패한 경우
        // kendo.alert("저장을 실패 하였습니다.");
        // console.error(error);  // 에러표시

        alert(getMessage(error.errCode));
      });
  };

  /* 취소버튼 */
  const cancelChanges = () => {
    setMenu({
      data: backDataSource,
      changes: false,
    });
  };

  /* 삭제버튼 */
  const CommandCell = (props) => {
    const { dataItem } = props;

    return (
      <td className="k-command-cell">
        <Button icon = "delete"  className="k-button" onClick={() => remove(dataItem)}>
        </Button>
      </td>
    );
  };

  // modify the data in the store, db etc
  const remove = (dataItem) => {
    setDeleteMenu(conn, dataItem)
      .then((response) => {
        kendo.alert("삭제되었습니다.");
        fetchMenu();
      })
      .catch((e) => {
        // API 호출이 실패한 경우
        // kendo.alert("삭제를 실패 하였습니다.");
        // console.error(e);  // 에러표시
        alert(getMessage(e.errCode));
      });
    //  window.location.replace(useEffect)
  };
  /* 삭제버튼 END */

  /* EDIT */
  // Cell 렌더링
  let cellRender = (tdElement, cellProps) => {
    const dataItem = cellProps.dataItem;
    const cellField = cellProps.field;
    const inEditField = dataItem["inEdit"];
    const additionalProps =
      cellField && cellField === inEditField
        ? {
            ref: (td) => {
              const input = td && td.querySelector("input");
              const activeElement = document.activeElement;
              if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
                return;
              }
              if (input.type === "dropdown") {
                input.focus();
              } else {
                input.select();
              }
            },
          }
        : {
            onClick: () => {
              console.log("onClick dataItem = ", dataItem);
              console.log("onClick cellField = ", cellField);
              enterEdit(dataItem, cellField);
            },
          };
    return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children);
  };

  const enterEdit = (dataItem, field) => {
    const data = menu.data.map((item) => ({
      ...item,
      inEdit: item.menuId === dataItem.menuId ? field : undefined,
    }));

    setMenu({
      data,
      editField: field,
    });
  };
  /* EDIT END */

  const filterChange = (event) => {
    setMenu({
      data: filterBy(backDataSource, event.filter),
      filter: event.filter,
    });
  };

  const DropDownCell = (props) => {
    const handleChange = (e) => {
      props.onChange({
        dataItem: props.dataItem,
        field: props.field,
        value: e.target.value,
      });
    };
    const value = props.dataItem[props.field];

    if (!props.dataItem.inEdit) {
      return <td> {value === null ? "" : props.dataItem[props.field].toString()}</td>;
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} onChange={handleChange} value={value} data={["Y", "N"]} />
      </td>
    );
  };

  const saveEvent = () => {
    console.log("dataSource.data = ", menu.data);
    let dataItem = [];
    menu.data.forEach((element) => {
      if (element.editFlag === true) {
        dataItem.push(element);
      }
    });
    console.log("save dataItem = ", dataItem);
    saveChanges(dataItem);
  };

  return (
    <div>
        <Button title="create" className={styles.menuMngtBtn} onClick={addNew} icon="add">
          추가
        </Button>
        <Button title="update" className={styles.menuMngtBtn} onClick={saveEvent} icon="save">
          저장
        </Button>
        <Button title="cancel" className={styles.menuMngtBtn} onClick={cancelChanges} icon="cancel">
          취소
        </Button>
      <Grid
        className={styles.menuMngtGrid}
        style={{ height: "1000px" }}
        data={menu.data}
        cellRender={cellRender}
        onItemChange={itemChange}
        editField="inEdit"
        filterable={true}
        filter={menu.filter}
        onFilterChange={filterChange}
      >
        <Column field="menuId" title="메뉴ID" width="90px" filterable={false} />
        <Column field="hgrnkMenuId" title="상위 메뉴ID" width="95px" filterable={false} />
        <Column field="menuTerm" title="메뉴명" width="270px" filterCell={GridColumnInputFilter} />
        <Column field="menuUrl" title="메뉴 URL" width="400px" filterCell={GridColumnInputFilter} />
        <Column field="menuExpl" title="메뉴 설명" width="350px" filterCell={GridColumnInputFilter} />
        <Column field="pageYn" title="페이지 여부" width="95px" filterable={false} cell={DropDownCell} />
        <Column field="menuSeqnNo" title="표시 순서" width="85px" filterable={false} editor="numeric" />
        <Column field="moblPageYn" title="모바일 페이지 여부" width="140px" filterable={false} cell={DropDownCell} />
        <Column field="areaAddlPageYn" title="지자체 추가메뉴 여부" width="150px" filterable={false} cell={DropDownCell} />
        <Column title="삭제" cell={CommandCell} width="80px" filterable={false} />
      </Grid>
    </div>
  );
};

export default MenuMngt;
