import styles from "./userSearchList.module.css";
import "./userSearchList.css";
import { BeatLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Window } from "@progress/kendo-react-dialogs";
import GridColumnInputFilter from "../gridFilter/gridColumnInputFilter";
import kendo from "@progress/kendo-ui";
import { getInstaller } from "../../../service/mngt/installer/installerService";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

/**
 * ## [유저 검색 그리드 컴포넌트]
 *
 * ### 매개변수
 * - conn
 * - 유저 그리드 row 선택 시 설정되는 값의 함수 (select 함수)
 * - 해당 검색 창 보여주는 여부(true , false) 함수
 * - 해당 창의 가로 길이
 * - 해당 창의 높이
 * - 해당 창의 왼쪽 위치 값
 * - 해당 창의 높이 위치 값
 */
const UserSearchList = ({ conn, setUserInfo, setVisible, initialWidth, initialHeight, initialLeft, initialTop, isMobile }) => {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 유저 리스트
  const [userList, setUserList] = useState({
    skip: 0,
    take: 10,
    filter: { userTerm: "", address: "", cityProvTerm: "", rgnTermSimple: "", fixbylawBundTermSimple: "" },
    userDataSource: [],
    total: 0,
    userId: "",
  });

  // 유저 다이어로그 닫기 (윈도우 창)
  const toggleDialog = () => {
    setVisible(false);
  };

  const getUserList = () => {
    setLoading(true);
    getInstaller(conn, userList)
      .then(response => {
        setLoading(false);
        setUserList(userList => {
          const item = { ...userList };
          item.userDataSource = response.results;
          item.total = response.total;
          return item;
        });
      })
      .catch(error => {
        // 현재 Installer에서 catch 코드 구현 안되어서 일단 임시로 작성
        // 추후에 코드 추가하면 Validaion 적용
        kendo.alert("설치자 목록을 불러오지 못하였습니다.");
        setLoading(false);
        return;
      });
  };

  // 페이징 이벤트
  const onPageChange = e => {
    let { take, skip } = e.page;
    userList.take = take;
    userList.skip = skip;
    getUserList();
  };

  // 필터 이벤트
  const filterChange = event => {
    userList.skip = 0;
    setUserList(userList => {
      const item = { ...userList };
      const filter = event.filter.filters[0];
      item.filter[filter.field] = filter.value;
      return item;
    });
    getUserList();
  };

  useEffect(() => {
    getUserList();
    /* eslint-disable-next-line */
  }, []);

  return (
    <div>
      <Window
        title={"유저 목록 리스트"}
        onClose={toggleDialog}
        initialHeight={initialHeight ? initialHeight : 650}
        initialWidth={initialWidth ? initialWidth : 1300}
        initialLeft={initialLeft && initialLeft}
        initialTop={initialTop && initialTop}
        modal={true}
        resizable={false}
        draggable={false}
        doubleClickStageChange={false}
        className="userListWindow">
        <div className={loading ? styles.meainUserListGirdArea : ""}>
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                style={{ height: initialHeight ? initialHeight * 0.85 : 550 }}
                className="meainUserListGird"
                data={userList.userDataSource}
                onRowClick={setUserInfo}
                total={userList.total}
                skip={userList.skip}
                take={userList.take}
                pageSize={userList.take}
                onPageChange={onPageChange}
                pageable={{
                  type: "numeric",
                  pageSizes: [5, 10],
                  previousNext: true,
                  buttonCount: 5,
                  refresh: true,
                }}
                filterable={true}
                onFilterChange={filterChange}>
                <GridNoRecords>{"조회 결과가 없습니다."}</GridNoRecords>
                <GridColumn field="userTerm" name="userTerm" title="설치자" filterCell={GridColumnInputFilter} width={isMobile ? "150px" : "250px"}></GridColumn>
                <GridColumn
                  field="cityProvTerm"
                  name="cityProvTerm"
                  title="시/도"
                  filterCell={GridColumnInputFilter}
                  width={isMobile ? "80px" : "250px"}></GridColumn>
                <GridColumn
                  field="rgnTermSimple"
                  name="rgnTermSimple"
                  title="시/군/구"
                  filterCell={GridColumnInputFilter}
                  width={isMobile ? "80px" : "250px"}></GridColumn>
                <GridColumn
                  field="fixbylawBundTermSimple"
                  name="fixbylawBundTermSimple"
                  title="읍/면/동"
                  filterCell={GridColumnInputFilter}
                  width={isMobile ? "80px" : "250px"}></GridColumn>
                <GridColumn field="userAddr" name="address" title="상세주소" filterCell={GridColumnInputFilter} width="250px"></GridColumn>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </div>
        {loading && (
          <p className={styles.meainUserListGirdLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </Window>
    </div>
  );
};

export default UserSearchList;
