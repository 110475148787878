import { memo, useEffect, useState } from "react";

const HeaderTimer = memo(() => {

  // 날짜 변수
  const [today , setToday] = useState("");

  // 시간 값 TimeDelay 설정
  const startTime = () => {
    setTimeout(() => {
      setToday(dateFormat());
    }, 1000);
  }

  // 시간 값 포맷값 가공 작업 진행
  const dateFormat = () =>{
    const today = new Date();
    const Y = today.getFullYear();
    let M = today.getMonth()+1;
    let d = today.getDate();
    let w = today.getDay();
    let h = today.getHours();
    let m = today.getMinutes();
    let s = today.getSeconds();
    M = checkTime(M);
    d = checkTime(d);
    h = checkTime(h);
    m = checkTime(m);
    s = checkTime(s);
    if(w === 0 ) w = "일";
    else if(w === 1) w = "월";
    else if(w === 2) w = "화";
    else if(w === 3) w = "수";
    else if(w === 4) w = "목";
    else if(w === 5) w = "금";
    else w = "토";
    // const formDate = Y + "-" + M + "-" + d + " " + w + "요일" + " " + h + ":" + m + ":" + s
    const formDate = `${Y}-${M}-${d} ${w}요일 ${h}:${m}:${s}`;
    return formDate;
  }
  
  // 시간 값 확인 
  const checkTime = (i) => {
    if (i < 10) {i = "0" + i};  // add zero in front of numbers < 10
    return i;
  }

  // 시간 타이머 마다 시작(처음 불러올 시 값이 없음 해당 1초 타임 지나고 값이 나옴)
  useEffect(() => {
    startTime();
    /* eslint-disable-next-line */
  },[today]);

  // 위의 1초 후 데이터 나오는걸 방지하기 위해 처음 한번 불러오는 부분으로 값을 가져오게 한다.
  useEffect(() => {
    setToday(dateFormat());
    /* eslint-disable-next-line */
  },[]);

  return(
    <span className="headata" id = "heaDateValue">{today}</span>
  );
});

export default HeaderTimer;