import React from 'react';
import BizManagementMeainHeatComp from './bizManagementMeainHeatComp';
import BizManagementMeainHeatNature from './bizManagementMeainHeatNature';
import { useEffect } from 'react';
import { useState } from 'react';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 태양열(공통) 정보
*/
const BizManagementMeainHeat = ({
  Input, convMngtStyles, DropDownList, meainInfo , initialDs, search, DatePickerKo, DateTimePickerKo, setMeainInfo
  ,onchageDate, onChangeDateTiem, dateFormat, onchageInput, onchageDropdown 
  , searchKeyWord ,makrMeainModlfilterChange
}) => {

  // 시스템 형식 데이터 소스
  const sysModeDs = [
    { codeNo : 29001 , codeVal : "강제순환식" },
    { codeNo : 29002 , codeVal : "자연순환식" }
  ];

  // 제조사 / 모델 데이터 소스
  const makrList = initialDs.makrList.length > 0 ? initialDs.makrList : [];
  const meainModelList = initialDs.meainModelList.length > 0 ? initialDs.meainModelList : []; 

  // 시스템 형식 데이터 설정
  const [sysMode , setSysMode] = useState( meainInfo.sysMode ? { codeNo : meainInfo.sysMode , codeVal : "" } : { codeNo : '' , codeVal : '' } );

  // 집열기 데이터 설정
  const [socoMakr , setSocoMakr] = useState( meainInfo.socoMakrNo ? { makrSeriNo : meainInfo.socoMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  const [socoType , setSocoType] = useState( meainInfo.socoTypeNo ? { meainTypeSeriNo : meainInfo.socoTypeNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );

  // 집열기 제조사 목록
  const socoMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30003 && c.makrTerm.includes(searchKeyWord.socoMakrKey) ) : [];
  // 집열기 모델 목록
  const socoTypeList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15002 && c.makrSeriNo === meainInfo.socoMakrNo && c.meainTypeTerm.includes(searchKeyWord.socoTypeKey) ) : [];

  // 컨버터
  const sysModeConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.sysMode ){
        let sysMode = "";
        let sysModeTerm = "";
        sysModeDs.forEach(element => {
            if( element.codeNo === Number(meainInfo.sysMode) ){
              sysMode = element.codeNo;
              sysModeTerm = element.codeVal;
            }
        });
        setSysMode({ codeNo: sysMode, codeVal: sysModeTerm });
      }else{
        setSysMode({ codeNo: '', codeVal: '' });  
      }
      
    }else{
      setSysMode({ codeNo: '', codeVal: '' });
    }
  }

  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      let makrSeriNo = "";
      let makrTerm = ""; 
      if( meainInfo.socoMakrNo ){
        socoMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.socoMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setSocoMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setSocoMakr({ makrSeriNo: '', makrTerm: '-' });
      }
    }else{
      setSocoMakr({ makrSeriNo: '', makrTerm: '-' });
    }
  }

  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.socoTypeNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        socoTypeList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.socoTypeNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setSocoType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setSocoType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
    }else{
      setSocoType({ meainTypeSeriNo: '', meainTypeTerm: '' });
    }
  }

  // 초기화 함수 추가 필요 (백업용)
  const initialHeatInfo = (data) => {
    const backupMeainInfo = {
      energyTypeCode: meainInfo.energyTypeCode,
      energyTypeTerm: meainInfo.energyTypeTerm,
      instCapa: meainInfo.instCapa,
      instlAddr: meainInfo.instlAddr,
      instlCelphon: meainInfo.instlCelphon,
      instlPlnnm: meainInfo.instlPlnnm,
      instlTerm: meainInfo.instlTerm,
      moniYn: meainInfo.moniYn,
      unit: meainInfo.unit ,
      instType : meainInfo.instType,
      meainInstDtm : meainInfo.meainInstDtm,
      asExpiDtm : meainInfo.asExpiDtm,
      // XXX: 집열기 제조사와 모델은 초기화를 시킨다. 왜냐하면 태양열(자연)은 설비타입번호가 집열기 모델이기에 태양열(강제)와는 다르다. 그래서 만약 초기화를 안시켜주면
      // XXX: 각 강제, 자연 컴포넌트에 집열기 입력을 각자 넣어줘야한다.
      // socoMakrNo : meainInfo.socoMakrNo,
      // socoTypeNo : meainInfo.socoTypeNo,
      presUseHeatSrc : meainInfo.presUseHeatSrc
    }
    const obj = { ...backupMeainInfo , [data.target.name] : data.value.codeNo }; 
    setSysMode(data.value); 
    setMeainInfo(obj); 
  }

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    sysModeConverter();
    makrConverter();
    modlConverter();
    /* eslint-disable-next-line */
  }, [meainInfo]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  return (
    <>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;설치일자
        </th>
        <td>
          <DateTimePickerKo 
                  id = "meainInstDtm" name = "meainInstDtm" className={convMngtStyles.inputTerm}
                  value = {dateFormat(meainInfo.meainInstDtm)} format = {"yyyy-MM-dd HH:mm"}
                  formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                  setDtm = {(v) => { onChangeDateTiem(v , "meainInstDtm"); }} 
                  // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;A/S 만료일자
        </th>
        <td>
          <DatePickerKo 
                id = "asExpiDtm" name = "asExpiDtm" className={convMngtStyles.inputTerm}
                value = {dateFormat(meainInfo.asExpiDtm)} 
                format="yyyy-MM-dd"
                formatPlaceholder={{ year: '년', month: '월', day: '일' }}
                setDtm = {(v) => { onchageDate(v , "asExpiDtm"); }}
                // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;시스템 형식
        </th>
        <td>
          <DropDownList 
                  id = "sysMode" name = "sysMode" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
                  data = {sysModeDs}  dataItemKey="codeNo" textField = "codeVal" 
                  value = {sysMode}
                  onChange = {(e) =>{ 
                    // 선택 시 초기화 함수 넣어야함
                    initialHeatInfo(e);
                  }}
          />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;현재사용열원</th>
        <td>
            <Input 
            id = "presUseHeatSrc" name = "presUseHeatSrc"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.presUseHeatSrc ? meainInfo.presUseHeatSrc : "" } onChange = {onchageInput} 
            // required = {true} validationMessage = {inputValidMsg.normalText}
              />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;집열기 제조사</th>
        <td>
            <DropDownList 
            id = "socoMakrNo" name = "socoMakrNo"  type = "text" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="makrSeriNo" textField = "makrTerm" 
            value = { socoMakr } data = {socoMakrList}
            defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setSocoMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            // required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
            ></DropDownList>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;집열기 모델명</th>
        <td>
            <DropDownList 
            id = "socoTypeNo" name = "socoTypeNo"  type = "text" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm"
            disabled = { meainInfo.socoMakrNo ? false : true } 
            value = { socoType } data = {socoTypeList}
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            onChange = { (e) =>{ 
              const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo }; 
              // 태양열 ( 자연 )은 집열기가 설비타입번호 이다.
              if( meainInfo.sysMode === 29002 ){
                obj.meainTypeSeriNo = e.value.meainTypeSeriNo ;
              }
              setSocoType({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); 
            }} 
            // required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
            ></DropDownList>
        </td>
      </tr>
      { meainInfo.sysMode === 29002 &&  
          <BizManagementMeainHeatNature 
            Input = {Input} convMngtStyles = {convMngtStyles} DropDownList = {DropDownList}
            meainInfo = {meainInfo} initialDs = {initialDs} search = {search} setMeainInfo = {setMeainInfo}
            DatePickerKo = {DatePickerKo} DateTimePickerKo = {DateTimePickerKo}
            onchageDate = {onchageDate} onChangeDateTiem = {onChangeDateTiem} dateFormat = {dateFormat}
            onchageInput = {onchageInput} onchageDropdown = {onchageDropdown}
            makrList = {makrList} meainModelList = {meainModelList}
            searchKeyWord = {searchKeyWord} makrMeainModlfilterChange = {makrMeainModlfilterChange}
          />
      }
      {
        meainInfo.sysMode === 29001 &&
          <BizManagementMeainHeatComp 
            Input = {Input} convMngtStyles = {convMngtStyles} DropDownList = {DropDownList}
            meainInfo = {meainInfo} initialDs = {initialDs} search = {search} setMeainInfo = {setMeainInfo}
            DatePickerKo = {DatePickerKo} DateTimePickerKo = {DateTimePickerKo}
            onchageDate = {onchageDate} onChangeDateTiem = {onChangeDateTiem} dateFormat = {dateFormat}
            onchageInput = {onchageInput} onchageDropdown = {onchageDropdown}
            makrList = {makrList} meainModelList = {meainModelList}
            searchKeyWord = {searchKeyWord} makrMeainModlfilterChange = {makrMeainModlfilterChange}
          />
      }
    </>
  );
};

export default BizManagementMeainHeat;