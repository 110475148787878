import DatePickerKo from "../../../com/dateInputs/datePickerKo";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { useRef } from 'react';
import styles from './userBetweenUser.module.css';

export const UserBetweenUserSearch = ({codeList , citys , rgns , search , setSearch , getUserData}) => {

  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate( today.getDate() - 1 );
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  console.log("search = ", search);

  // 에너지원 리스트
  const ensoList = codeList.filter( c => c.grpCodeNo === 15 && c.codeNo !== 15007 );

  // 드롭다운
  const onChangeEvt = (e) => {
    if( e.target.name === "ensoTypeCodeNo" ){ 
      search.ensoTypeCode = e.target.value.codeNo;
    }
    else if( e.target.name === "city" ){
      setSearch(search => {
        const item = {...search};
        item.cityProvCode = e.target.value.cityProvCode;
        item.city = e.target.value;
        item.rgnCode = "";
        item.rgn = {rgnTermSimple : "전체" , rgnCode : ""};
        return item;
      })
    }
    else if( e.target.name === "rgnCode" ){ 
      // search.rgnCode = e.target.value.rgnCode; 
      // search.rgn = e.target.value;
      setSearch(search => {
        const item = {...search};
        item.rgnCode = e.target.value.rgnCode; 
        item.rgn = e.target.value;
        return item;
      })
    }
  }

  const ensoType = useRef(null);
  const city = useRef(null);
  const rgn = useRef(null);
  const startDtm = useRef(null);

  // 조회 버튼
  const setSearchClickEvt = () => {
    // 유저 ID 초기화
    search.userId = "";
    search.makrSeriNo = "";

    const s = {
      ensoTypeCode: ensoType.current.value.codeNo,
      cityProvCode: city.current.value.cityProvCode,
      rgnCode: rgn.current.value.rgnCode,
      startGathDtm : search.startGathDtm,
      endGathDtm: search.endGathDtm,
      skip : 0,
      take : 10,
      size : 10
    }
    setSearch({...search, ...s});
    getUserData(s);
  }

  return(
    <>
      <span className="searchSubTitleA">에너지원</span>
      <DropDownList ref={ensoType}
          data = {ensoList} defaultValue = { {codeVal : "태양광" , codeNo : 15001} } name = "ensoTypeCodeNo"
          textField = "codeVal" dataItemKey="codeNo" onChange = {onChangeEvt} className = "searchArea"
      ></DropDownList>
      <span className="searchSubTitleB">지역검색</span>
      <DropDownList ref={city}
          data={citys} value = {search.city} defaultItem = {{ cityProvCode : "", cityProvTerm : "시/도" }} name = "city" 
          textField="cityProvTerm" dataItemKey="cityProvCode" onChange={onChangeEvt} className = "searchArea"
      ></DropDownList>
      <DropDownList ref={rgn} disabled={ !search.cityProvCode ? true : false}
          data = {rgns} value = {search.rgn} defaultItem = { {rgnTermSimple : "전체" , rgnCode : ""} } name = "rgnCode"
          textField = "rgnTermSimple" dataItemKey="rgnCode" onChange = {onChangeEvt} className = "searchArea"
      ></DropDownList>
      <DatePickerKo ref={startDtm} id = "start" name = "start" className = "datePicker" defaultValue={search.yesterday} format = "yyyy-MM-dd " 
          value={search.startGathDtm}
          readonly={true}
          max={search.startMax}
          setDtm={(v) => {
            setSearch(search => {
              const item = {...search};
              item.startGathDtm = v;
              item.endGathDtm = v;
              return item;
            });
          }}
        /> 
      <span className="searchSubTitleC">
          <Button className = {styles.inqBtn} onClick = {setSearchClickEvt} icon = "search"></Button>    
      </span>
    </>
  );
}

export default UserBetweenUserSearch;