import React from 'react';
import LocalAreaMonitorSmpRecDataInfo from './localAreaMonitorSmpRecDataInfo';

/**
 * 오른쪽 하단 "태양광 발전 환산수익금" 컴포넌트
 * @param {styles, energyPriceData , rgnTerm , CountUp} param0 
 * @returns 
 */
const LocalAreaMonitorSmpRecData = ({styles, energyPriceData , CountUp}) => {

  /**
   * 마우스 영역 진입 이벤트
   * @param {*} ensoTypeCode 
   */
  const onMouseOverEvt = () => {
    const localAreaEnsoStatArea = document.getElementById("localAreaMonitorSmpRecArea");
    const localAreaEnsoStatInfo = document.getElementById("localAreaMonitorSmpRecInfoArea");
    localAreaEnsoStatInfo.style.display = "block";
    localAreaEnsoStatInfo.style.left = localAreaEnsoStatArea.style.left + 900 + "px";
    localAreaEnsoStatInfo.style.top = localAreaEnsoStatArea.style.top + 500 +  "px";
  }

  /**
   * 마우스 영역 아웃 이벤트
   * @param {*} ensoTypeCode 
   */
  const mouseOutEvt = () => {
    const localAreaEnsoStatInfo = document.getElementById("localAreaMonitorSmpRecInfoArea");
    localAreaEnsoStatInfo.style.display = "none";
  }

  return (
    <div id = "localAreaMonitorSmpRecArea" className={styles.rightBox} onMouseOver={onMouseOverEvt} onMouseOut={mouseOutEvt} >
      <table className={styles.boxContents}>
          <caption>
              <span>태양광 발전 환산수익금</span>
          </caption>
          <colgroup>
              <col width="50px" />
              <col width="" />
              <col width="" />
              <col width="10px" />
              <col width="" />
              <col width="10px" />
          </colgroup>
          <thead>
              <tr>
                  <th></th>
                  <th>구분</th>
                  <th colspan="2">단가</th>
                  <th colspan="2">금일수익금</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <th><img src="/images/localareaImg/42770/icon_50x45_04.png" alt='SMP' /></th>
                  <th>SMP</th>
                  <td><CountUp end={energyPriceData.smp} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></td>
                  <td>원</td>
                  <td><CountUp end={energyPriceData.smpTotalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></td>
                  <td>원</td>
              </tr>
              <tr>
                  <th><img src="/images/localareaImg/42770/icon_50x45_05.png" alt='REC' /></th>
                  <th>REC/RE100</th>
                  <td><CountUp end={energyPriceData.rec} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></td>
                  <td>원</td>
                  <td><CountUp end={energyPriceData.recTotalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></td>
                  <td>원</td>
              </tr>
              <tr>
                  <th><img src="/images/localareaImg/42770/icon_50x45_06.png" alt='CER' /></th>
                  <th>탄소배출권<br/>(CER)</th>
                  <td><CountUp end={energyPriceData.cer} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></td>
                  <td>원</td>
                  <td><CountUp end={energyPriceData.cerTotalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></td>
                  <td>원</td>
              </tr>
              <tr>
                  <th><img src="/images/localareaImg/42770/icon_50x45_07.png" alt='금일수입합계' /></th>
                  <th>금일수익합계<br/>(SMP+REC+CER)</th>
                  <td colspan="4" class="center">
                  <CountUp end={energyPriceData.totalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /> <span>원</span><br/>
                      <small className={styles.totalSmall}>(
                          <CountUp end={energyPriceData.smpTotalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} />원 
                        + <CountUp end={energyPriceData.recTotalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} />원 
                        + <CountUp end={energyPriceData.cerTotalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} />원)
                      </small>
                  </td>
              </tr>
          </tbody>
      </table>
      <LocalAreaMonitorSmpRecDataInfo styles = {styles} energyPriceData = {energyPriceData} />
    </div>
  );
};

export default LocalAreaMonitorSmpRecData;