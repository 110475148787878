import log from '../../../com/log';
import { parseDate } from '../../../com/dateUtil';
import kendo from "@progress/kendo-ui";
import { getMessage } from '../../../com/messageUtil';
import { downloadExcel } from '../../../com/downloadExcel';

const installerComUrl = 'user/installer';
// 수용가 데이터 조회
export const getInstaller = async(conn, data) => {
  const {skip, take, sort, filter} = data;
  const config = {
    params: {
      page: (take + skip) / take
      , size: take
      , sort
      , useYn: 'Y'
      , userId : data.userId
    }
  };
  // filter 값 설정
  if(filter !== undefined) {
    if(filter.userId !== undefined)           config.params.userId = filter.userId;
    if(filter.userTerm !== undefined)         config.params.userTerm = filter.userTerm;
    if(filter.cityProvCode !== undefined)     config.params.cityProvCode = filter.cityProvCode;
    if(filter.cityProvTerm !== undefined)     config.params.cityProvTerm = filter.cityProvTerm;
    if(filter.rgnCode !== undefined)          config.params.rgnCode = filter.rgnCode;
    if(filter.rgnTermSimple !== undefined)          config.params.rgnTermSimple = filter.rgnTermSimple;
    if(filter.fixbylawBundCode !== undefined) config.params.fixbylawBundCode = filter.fixbylawBundCode;
    if(filter.fixbylawBundTermSimple !== undefined)          config.params.fixbylawBundTermSimple = filter.fixbylawBundTermSimple;
    if(filter.rasysCodeNo !== undefined)      config.params.rasysCodeNo = filter.rasysCodeNo;
    if(filter.userAddr !== undefined)          config.params.address = filter.userAddr;
  }
  let response = await conn.get(installerComUrl + '/page', config);
  // LocalDateTime -> yyyy-mm-dd hh:mm:ss 변환
  for(let item of response.results) {
    item.regiDtm = parseDate('yyyy-mm-dd hh:mm:ss', item.regiDtm);
  }
  // log(response, 'getInstaller');
  return response;
};


// 수용가 데이터 생성, 수정
export const setUpdateInstaller = async(conn, data) => {

    const dataArr = [];

    if(data.constructor === Object) {
      // yyyy-mm-dd hh:mm:ss -> LocalDateTime 변환
      data.regiDtm = parseDate('LocalDateTime', data.regiDtm);
      dataArr.push(data);
    } else if(data.constructor === Array) {
      data.forEach(item => dataArr.push(item));
    }

    const response = await conn.post(installerComUrl, JSON.stringify(dataArr), {
      headers: {'Content-Type': 'application/json'}
    });

    log(response, 'setUpdateInstaller');
    return response;

}


// 수용가 데이터 삭제
export const setDeleteInstaller = async(conn, data) => {
  try {

    const response = await conn.delete(installerComUrl, {
      headers: {'Content-Type': 'application/json'}
      , data: JSON.stringify(new Array(data))
    });
    
    log(response, 'setDeleteInstaller');
    return response;

  } catch(e) {
    kendo.alert(getMessage(e));
  }
}

// 엑셀 다운로드
export const downloadInstallerList = (dataState, setSaveLoading) => {
  // sort 값 확인
  let sort ="";
  let direction = "";
  if(dataState.sortStatus.length !== 0){
    sort = dataState.sortStatus[0].field;
    direction = dataState.sortStatus[0].dir;
  }
  // 검색 파라미터 설정
  const search = {
    userId : dataState.filter.userId,
    userTerm : dataState.filter.userTerm,
    fixbylawBundCode : dataState.filter.fixbylawBundCode,
    rasysCodeNo : dataState.filter.rasysCodeNo,
    sort : sort,
    direction : direction
  };
  const url = '/api/v2.2/user/installer';
  const data = search;
  const fileName = '수용가관리_' + parseDate('yyyymmddhhmmss') + '.xlsx';
  downloadExcel(data, fileName, url, setSaveLoading);
}