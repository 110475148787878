//react
import React from "react";
import { useState, useEffect } from "react";

//
import SolarModuleMngtPrzt from "./solarModuleMngtPrzt";
import kendo from "@progress/kendo-ui";
import { getSolaModulePage, saveSolaModule, setCurveFitData } from "./../../../service/kier/solaModuleService";
import { Button } from "@progress/kendo-react-buttons";
import { delSolaModule } from "./../../../service/kier/solaModuleService";
import { getMessage } from "./../../../com/messageUtil";

import { BeatLoader } from "react-spinners";
import { cellTypeList, moduleInit } from "./jsonFile/moduleInit";

import { ScatterPlot } from "@material-ui/icons";
const SolarModuleMngt = props => {
  const { conn } = props;
  /**
   * module 초기값
   */
  const [solModModal, setSolModModal] = useState(moduleInit);
  // 그리드 상태(페이징, 정렬, 필터)
  const [gridState, setGridState] = useState({
    take: 15,
    skip: 0,
    sort: [],
    filter: {},
  });

  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * 추가/수정 시 다이얼로그 표출 여부 함수
   */
  const setModalVisible = () => {
    setSolModModal({ ...solModModal, visible: !solModModal.visible });
  };
  const setModalInitial = () => {
    setSolModModal({ ...moduleInit, visible: true });
  };

  /**
   * 모듈 저장
   */
  const saveSolModFunc = e => {
    kendo.confirm("저장 하시겠습니까?").then(() => {
      validCheck();
    });
  };

  /**
   * 그리드 조회
   */
  const getGridData = () => {
    setLoading(true);
    getSolaModulePage(conn, gridState)
      .then(result => {
        result.results.forEach(item => {
          item.cellType = cellTypeList.find(e => e.id === item.cellType);
        });
        setGridData(result);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  };

  const solModChngEvt = e => {
    const elmntName = e.elmntName;
    const value = e.value;
    setSolModModal({ ...solModModal, [elmntName]: value });
  };

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const { take, skip } = e.page;
    setGridState({ ...gridState, skip: skip, take: take });
  };

  // 그리드 필터링 처리
  const handleFilterChange = e => {
    // 필터 값 설정
    const isFilter = e.filter.filters;
    if (isFilter.length !== 0) {
      if (isFilter[0].field === "modSeqNo") {
        gridState.filter.modSeqNo = isFilter[0].value;
      } else if (isFilter[0].field === "modTerm") {
        gridState.filter.modTerm = isFilter[0].value;
      }
    }
    getGridData();
  };

  const curveFitCell = e => {
    const curveFitBtnEvt = () => {
      const { dataItem } = e;
      setSolModModal({ ...dataItem, title: "피팅 보기", visible: true });
    };
    return (
      <td style={{ textAlign: "center" }}>
        <Button onClick={curveFitBtnEvt}>
          <ScatterPlot />
          피팅 보기
        </Button>
      </td>
    );
  };

  const cellTypeCell = e => {
    const { dataItem } = e;
    return <td>{dataItem.cellType.text}</td>;
  };
  const editCell = e => {
    const { dataItem } = e;
    const editBtnClickEvt = () => {
      setSolModModal({ ...dataItem, title: "수정", visible: true });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Button icon="edit" onClick={editBtnClickEvt}>
          수정
        </Button>
      </td>
    );
  };
  const delCell = e => {
    const { dataItem } = e;
    const delBtnEvt = () => {
      kendo
        .confirm(
          "<span style=color:red>* 주의 *</span><br/>" +
            "<b>코드 : " +
            dataItem.modSeqNo +
            "  모델명 :" +
            dataItem.modTerm +
            "</b> <br/>모듈을 삭제하시겠습니까?",
        )
        .then(() => {
          delSolaModule(conn, dataItem.modSeqNo)
            .then(() => {
              kendo.alert("삭제 처리되었습니다.");
              getGridData();
            })
            .catch(e => {
              kendo.alert(getMessage(e.errCode));
            });
        });
    };
    return (
      <td style={{ textAlign: "center" }}>
        <Button icon="delete" onClick={delBtnEvt}>
          삭제
        </Button>
      </td>
    );
  };

  const setCurveFitting = () => {
    kendo.confirm("커브 피팅을 시작하시겠습니까?").then(() => {
      setLoading(true);
      setCurveFitData(conn, solModModal)
        .then(result => {
          setLoading(false);
          setSolModModal({ ...solModModal, ...result });
        })
        .catch(e => {
          setLoading(false);
          kendo.alert(getMessage(e.errCode));
        });
    });
  };

  const validCheck = () => {
    let check = 0;
    if (!solModModal.modTerm) {
      check++;
      kendo.alert("모델명은 필수 파라미터입니다.");
      return;
    }
    if (!solModModal.vltMp) {
      check++;
      kendo.alert("최대전압은 필수 파라미터입니다.");
      return;
    }
    if (!solModModal.curMp) {
      check++;
      kendo.alert("최대전류는 필수 파라미터입니다.");
      return;
    }
    if (!solModModal.pwrMp) {
      check++;
      kendo.alert("최대출력은 필수 파라미터입니다.");
      return;
    }
    if (!solModModal.vltOc) {
      check++;
      kendo.alert("개방전압은 필수 파라미터입니다.");
      return;
    }
    if (!solModModal.curSc) {
      check++;
      kendo.alert("단락전류는 필수 파라미터입니다.");
      return;
    }
    if (!solModModal.cellType) {
      check++;
      kendo.alert("셀 종류는 필수 파라미터입니다.");
      return;
    }
    if (!solModModal.tempVltCof) {
      check++;
      kendo.alert("전압 온도계수는 필수 파라미터입니다.");
      return;
    }
    if (!solModModal.tempCurCof) {
      check++;
      kendo.alert("전류 온도 계수는 필수 파라미터입니다.");
      return;
    }
    if (check > 0) {
      kendo.alert("필수 파라미터를 모두 입력해 주시기 바랍니다.");
      return;
    } else {
      saveSolaModule(conn, solModModal)
        .then(result => {
          if (solModModal.title === "추가") setGridState({ ...gridState, skip: gridData.total + 1 / gridState.take });
          else getGridData();
          kendo.alert(solModModal.title + "에 성공했습니다.");
          setModalVisible();
        })
        .catch(e => {
          kendo.alert("저장에 실패했습니다.");
        });
    }
  };

  useEffect(() => {
    getGridData();
    /* eslint-disable-next-line */
  }, [gridState.skip, gridState.take]);

  return (
    <div className={loading ? "loadingState" : null}>
      {loading && (
        <p className="loadingSpinner">
          <BeatLoader loading={true} size={24} color="#01579b" />
        </p>
      )}
      <SolarModuleMngtPrzt
        gridData={gridData}
        gridState={gridState}
        solModModal={solModModal}
        setModalVisible={setModalVisible}
        saveSolModFunc={saveSolModFunc}
        getGridData={getGridData}
        solModChngEvt={solModChngEvt}
        handlePageChange={handlePageChange}
        handleFilterChange={handleFilterChange}
        cellTypeCell={cellTypeCell}
        curveFitCell={curveFitCell}
        editCell={editCell}
        delCell={delCell}
        setCurveFitting={setCurveFitting}
        setModalInitial={setModalInitial}></SolarModuleMngtPrzt>
    </div>
  );
};

export default SolarModuleMngt;
