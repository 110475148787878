import React, { useState, useEffect, useCallback, useRef } from "react";
import { Reply, Menu } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router";
import { authGuest, setDelToken, setToken, isLogin, getUser, getUserTypeCode } from "../service/authService";
import HeaderFullScr from "./headerFullSc";
import HeaderTimer from "./headerTimer";
import { DropDownList as DropDown } from "@progress/kendo-react-dropdowns";

import "./header.css";
import styles from "./header.module.css";
// import "./header.dk.css";
import { getWeathInfo } from "../service/analy/weathCmpService";
import { Sidebar } from "./sidebar";
import { roundNum } from "../com/dataUtil";

const Header = React.memo(props => {
  const {
    conn,
    setUpdateLoginStatus,
    roleMenu,
    // getRoleMenu,
    menuState,
    setSelectMenu,
    loginStatus,
    // rgnList,
    setContentLayoutMargin,
  } = props;

  const childRef = useRef();
  const history = useHistory();
  // const location = useLocation();
  const halfInnerWidth = window.innerWidth / 2;
  // 로그인
  // const loginClickEvt = () => {
  //   history.push("/login");
  //   setSelectMenu("", roleMenu);
  // };
  const [weathInfo, setWeathInfo] = useState({
    presWeathVal: "",
    rgnCode: "",
    temp: "",
    img: "",
    rgnTerm: "",
  });

  useEffect(() => {
    if (loginStatus) {
      getWeathInfo(conn)
        .then(response => {
          if (response) {
            setWeathInfo(weathInfo => {
              const item = { ...weathInfo };
              item.presWeathVal = response.presWeathVal;
              item.rgnCode = response.rgnCode;
              item.temp = roundNum(response.temp, 2);
              item.rgnTerm = response.rgnTerm;
              if (item.presWeathVal === "구름") {
                item.img = "/images/weather/weather_icon01.png";
              } else if (item.presWeathVal === "구름 많음") {
                item.img = "/images/weather/weather_icon02.png";
              } else if (item.presWeathVal === "구름 조금") {
                item.img = "/images/weather/weather_icon03.png";
              } else if (item.presWeathVal === "눈") {
                item.img = "/images/weather/weather_icon04.png";
              } else if (item.presWeathVal === "비/눈") {
                item.img = "/images/weather/weather_icon05.png";
              } else if (item.presWeathVal === "맑음") {
                item.img = "/images/weather/weather_icon06.png";
              } else if (item.presWeathVal === "비") {
                item.img = "/images/weather/weather_icon07.png";
              } else if (item.presWeathVal === "흐림") {
                item.img = "/images/weather/weather_icon08.png";
              } else if (item.presWeathVal === "소나기") {
                item.img = "/images/weather/weather_icon09.png";
              } else if (item.presWeathVal === "눈/비") {
                item.img = "/images/weather/weather_icon10.png";
              } else {
                item.img = "/images/weather/weather_icon06.png";
              }
              return item;
            });
          }
        })
        .catch(error => console.log(error, "getWeathInfo Error!"));
    } else {
      setWeathInfo({
        presWeathVal: "",
        rgnCode: "",
        temp: "",
      });
    }
    /* eslint-disable-next-line */
  }, [loginStatus]);

  // console.log("weathInfo = ", weathInfo);

  // 로그아웃
  const logoutClickEvt = () => {
    // 사용자 정보
    const user = getUser();
    setDelToken();
    authGuest(conn, "LOGOUT", user).then(result => {
      alert("로그아웃 되었습니다.");
      setToken(result.token);
      setUpdateLoginStatus(false);
      history.push("/");
    });
  };

  const goBack = () => {
    history.goBack();
  };

  // 상단 헤더의 메뉴 드롭다운 클릭 이벤트
  const go = useCallback(e => {
    if (e.target.value.checked) {
      setSelectMenu(e.target.value.menuUrl, roleMenu);
      // getRoleMenu(e.target.value.menuUrl);
      setContentLayoutMargin(e.target.value.menuUrl);
      history.push(e.target.value.menuUrl);
    } else {
      alert("로그인 후 이용가능합니다...");
      return false;
    }
    /* eslint-disable-next-line */
  }, []);

  const callChildFunc = useCallback(() => {
    childRef.current.menuClickEvt();
    /* eslint-disable-next-line */
  }, []);

  const pathName = window.location.pathname;

  // 로고를 클릭 시 이동 이벤트
  const clickLogoEvt = () => {
    if (getUserTypeCode() === 2001) {
      setSelectMenu("/monitor/installerMeain", roleMenu);
      // getRoleMenu("/monitor/areaEnergy");
      setContentLayoutMargin("/monitor/installerMeain");
      history.push("/monitor/installerMeain");
    } else if (getUserTypeCode() === 2002) {
      setSelectMenu("/monitor/munic", roleMenu);
      // getRoleMenu("/monitor/areaEnergy");
      setContentLayoutMargin("/monitor/munic");
      history.push("/monitor/munic");
    } else {
      setSelectMenu("/monitor/areaEnergy", roleMenu);
      // getRoleMenu("/monitor/areaEnergy");
      setContentLayoutMargin("/monitor/areaEnergy");
      history.push("/monitor/areaEnergy");
    }
  };

  const headerImg = () => {
    if(getUserTypeCode() === 2002 && getUser().rgnCode === "30230"){
      return(<img src="/images/localareaImg/30230/title/30230_title.png" alt="title" style={{marginTop: '-25px'}}/>)
    }
    else{
      return(<img src="/images/K_EMC_logo.svg" alt="title" style={{cursor:'pointer'}} onClick={() => history.push("/monitor/areaEnergy")}/>)
    }
  }

  return (
    <header className="App-header" id="header_main">
      <div className="header_main_content">
        <nav className="uk-navbar">
          <div className={["uk-float-left", "header-left", styles.leftMenuArea].join(" ")}>
            {/* 뒤로가기 버튼 주석처리 */}
            <button className="headerGobackBtn" onClick={goBack}>
              <Reply className="material-icons" style={{ fontSize: 30, color: "white" }} />
            </button>
            <button className="headerMenuBtn">
              <Menu className="material-icons" style={{ fontSize: 30, color: "white" }} onClick={callChildFunc} />
            </button>
            {/* {menuState.depth1MenuList.length > 0 && (
              <DropDown
                className="menuDropDown"
                data={menuState.depth1MenuList}
                textField="term"
                dataItemKey="menuUrl"
                value={menuState.depth1SelectedMenu}
                onChange={go}
              />
            )}
            {menuState.depth2MenuList.length > 0 && (
              <DropDown
                className="menuDropDown"
                data={menuState.depth2MenuList}
                textField="term"
                dataItemKey="menuUrl"
                value={menuState.depth2SelectedMenu}
                onChange={go}
              />
            )}
            {menuState.depth3MenuList.length > 0 && (
              <DropDown
                className="menuDropDown"
                data={menuState.depth3MenuList}
                textField="term"
                dataItemKey="menuUrl"
                value={menuState.depth3SelectedMenu}
                onChange={go}
              />
            )} */}
          </div>
          <h1 className="mobileMainTitle">
            <img src="/images/K_EMC_logo.svg" alt="logo" onClick={() => history.push("/monitor/areaEnergy")}></img>
            {/* <span className="mainTilteSpan" onClick={() => history.push("/monitor/installerMeain")}>
              EMS
            </span> */}
          </h1>
          <div className="uk-navbar-center header-center">
            <h1 className="mainTitle">
              {headerImg()}
            </h1>
          </div>
          <div className="uk-navbar-flip">
            <ul className="uk-navbar-nav user_actions">
              <li className="user_action_icon">
                <span
                  style={{                                                            
                    lineHeight:'60px',                                        
                  }}>                  
                  <HeaderTimer />
                  {weathInfo.rgnCode ? (
                    <span className="headata">
                      <span className="headRgnData">&nbsp;| {weathInfo.rgnTerm} |</span>{" "}
                      <span className="headTempData">
                        {weathInfo.temp}℃ &nbsp; <img alt="날씨" src={weathInfo.img} />
                      </span>
                    </span>
                  ) : null}                  
                </span>
              </li>
              <li className="loginIconLi">
                <button 
                  style={{color:'#333', background:'transparent', border:'none', lineHeight:'60px', height:'70px', padding:'0 15px', }}
                  onClick={logoutClickEvt} className="user_action_icon">
                  <span className="headimgB">
                    {isLogin() ? <img src="/images/icon_logout.png" alt="로그아웃" /> : <img src="/images/icon_login.png" alt="로그인" />}
                  </span>
                </button>
              </li>
              <li className="headerFullLi">
                {" "}
                <HeaderFullScr />{" "}
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <Sidebar width={halfInnerWidth} height={"100vh"} ref={childRef} roleMenu={roleMenu} />
    </header>
  );
});
export default Header;
