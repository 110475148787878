//React
import React, { useState, useEffect } from "react";
import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";
import { getMakr, setDeleteMakr, setUpdateMakr } from "../../../service/makrService";

//kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn as Column, GridToolbar, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { InEditCommandCell } from "../../mngt/inEditCommandCell";
import CustomFilter from "../../com/gridFilter/custom_filter";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
// 로딩 스피너
import { BeatLoader } from "react-spinners";

//css
import "./makrMngt.css";
const MakrMngt = ({ conn, codeList }) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [backupDataSource, setBackupDataSource] = useState([]);
  const [editFlag, setEditFlag] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMakr(conn)
      .then((result) => {
        if (codeList.length) {
          result.forEach((element) => {
            element.makrClsfNo = codeList.filter((it) => it.codeNo === element.makrClsfNo).map((item) => item.codeVal)[0];
          });
          setDataSource(result);
          setBackupDataSource(result);
          setLoading(false);
        }
      })
      .catch((e) => console.log("getMakr Error!", e));
    // eslint-disable-next-line
  }, [codeList]);

  // 그리드 상태(페이징, 정렬, 필터)
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    total: 0,
    sort: [],
    filter: {},
  });

  /* 인증여부 필터 */
  const authYnDropDownList = CustomFilter(["Y", "N"], "인증여부", "none", "none", false);

  /* 제조사 분류 필터 */
  const clsfNoList = codeList.filter((c) => c.grpCodeNo === 30);
  const clsfNoDropDownList = CustomFilter(
    clsfNoList.map((item) => item.codeVal),
    "제조사 분류",
    "none",
    "none",
    false
  );

  /* 페이징 이벤트 */
  const pageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  /* 정렬 이벤트 */
  const sortChange = (event) => {
    if (event.sort.length === 0) {
      setDataState({
        ...dataState,
        sort: [
          {
            field: "",
            dir: "",
          },
        ],
      });
    } else {
      setDataState({
        ...dataState,
        sort: [
          {
            field: event.sort[0].field,
            dir: event.sort[0].dir,
          },
        ],
      });
    }
  };

  /* 필터 이벤트 */
  const filterChange = (event) => {
    dataState.skip = 0;
    dataState.take = 10;
    setDataSource(filterBy(backupDataSource, event.filter));
    setDataState({
      ...dataState,
      filter: event.filter,
    });
  };

  /*inedit 추가, 수정 입력 이벤트*/
  const itemChange = (event) => {
    const temp = dataSource.map((item) => (item.makrSeriNo === event.dataItem.makrSeriNo ? { ...item, [event.field]: event.value } : item));
    setDataSource(temp);
  };

  const CommandCell = (props) => (
    <InEditCommandCell
      {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={"inEdit"}
      isNewItem={props.dataItem.makrSeriNo === undefined}
    />
  );

  const remove = (dataItem) => {
    kendo.confirm(dataItem.makrTerm + "의 데이터를 정말 삭제하시겠습니까?").then(() => {
      setDeleteMakr(conn, dataItem)
        .then(() => {
          const index = dataSource.findIndex((record) => record.makrSeriNo === dataItem.makrSeriNo);
          const temp = [...dataSource];
          temp.splice(index, 1);
          setDataSource(temp);
        })
        .catch((e) => console.log("deleteMakr Error!", e));
    });
  };

  const add = (dataItem) => {
    // Validation
    const isValid = validation(dataItem);
    if (isValid.isValid === false) {
      kendo.alert(isValid.message);
      return;
    }

    //makrSeriNo 생성 = 전체 seriNo의 최대값 +1
    const makrSeriNoList = dataSource.map((item) => (item.makrSeriNo !== undefined ? item.makrSeriNo : 0));
    dataItem.makrSeriNo = Math.max.apply(null, makrSeriNoList) + 1;
    dataItem.useYn = "Y";
    const data = { ...dataItem, clsfNoList };
    setUpdateMakr(conn, data)
      .then((response) => {
        kendo.alert("저장되었습니다.");
        //프론트엔드부분 setDataSource
        dataItem.regiDtm = new Date();
        dataItem.inEdit = false;
        setDataSource(dataSource.map((u) => (u.makrSeriNo === dataItem.makrSeriNo ? { ...u, ...dataItem } : u)));
      })
      .catch((e) => {
        // API 호출이 실패한 경우
        kendo.alert("저장을 실패 하였습니다.");
        console.log("setUpdateMakr Error!", e); // 에러표시
      });
  };

  const update = (dataItem) => {
    // Validation
    const isValid = validation(dataItem);
    if (isValid.isValid === false) {
      kendo.alert(isValid.message);
      return;
    }
    // 추가 버튼 사용가능
    setEditFlag(false);

    dataItem.inEdit = false;
    const data = { ...dataItem, clsfNoList };
    setUpdateMakr(conn, data)
      .then(() => {
        const temp = dataSource.map((item) =>
          item.makrSeriNo === dataItem.makrSeriNo
            ? {
                ...item,
                ...dataSource,
              }
            : item
        );
        setDataSource(temp);
      })
      .catch((e) => console.log("setUpdateMakr Error!", e));
  };

  const discard = (dataItem) => {
    setEditFlag(false);
    const temp = [...dataSource];
    temp.splice(0, 1);
    setDataSource(temp);
  };

  const cancel = (dataItem) => {
    setEditFlag(false);
    const originalItem = backupDataSource.find((p) => p.makrSeriNo === dataItem.makrSeriNo);
    const temp = dataSource.map((item) => (item.makrSeriNo === originalItem.makrSeriNo ? originalItem : item));

    dataItem.inEdit = false;
    setDataSource(temp);
  };

  const enterEdit = (dataItem) => {
    setEditFlag(true);
    const temp = dataSource.map((item) => (item.makrSeriNo === dataItem.makrSeriNo ? { ...item, inEdit: true } : item));
    setDataSource(temp);
  };

  const addNew = () => {
    setEditFlag(true);
    const newRecord = { inEdit: true };
    setDataSource([newRecord, ...dataSource]);
  };

  console.log("dataSource = ", dataSource);

  // Validation 설정
  const validation = (dataItem) => {
    let isValid = true;
    let message = "";

    if (!dataItem.makrTerm) {
      message = "제조사 명을 입력 해주세요.";
      isValid = false;
    }

    if (!dataItem.makrClsfNo) {
      message = "제조사 분류을 선택 해주세요.";
      isValid = false;
    }

    if (!dataItem.makrExpl) {
      message = "제조사 설명을 입력 해주세요.";
      isValid = false;
    }

    if (!dataItem.authYn) {
      message = "인증 여부를 선택 해주세요.";
      isValid = false;
    }

    if (dataItem.makrPhon) {
      const makrPhon = /^(02|01[016789]{1}|0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;
      const makrPhon2 = /^([0-9]{4}-?[0-9]{4})$/;
      if (!makrPhon.test(dataItem.makrPhon) && !makrPhon2.test(dataItem.makrPhon)) {
        message = "유효하지 않은 연락처입니다!";
        isValid = false;
      }
    }

    if (dataItem.makrEmail) {
      // eslint-disable-next-line
      const makrEmail = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      if (!makrEmail.test(dataItem.makrEmail)) {
        message = "유효하지 않은 이메일입니다!";
        isValid = false;
      }
    }

    return { isValid: isValid, message: message };
  };

  /* authYn 추가,수정시 드롭다운리스트 */
  const authYnDropDownCell = (props) => {
    const handleChange = (e) => {
      props.onChange({
        dataItem: props.dataItem,
        field: props.field,
        value: e.target.value,
      });
    };
    const value = props.dataItem[props.field];

    if (!props.dataItem.inEdit) {
      return <td> {value === null ? "" : props.dataItem[props.field].toString()}</td>;
    }

    return (
      <td>
        <DropDownList style={{ width: "100px" }} onChange={handleChange} value={value} data={["Y", "N"]} />
      </td>
    );

  };
  /* 제조사 분류 추가,수정시 드롭다운리스트 */
  const clsfNoDropDownCell = (props) => {
    const handleChange = (e) => {
      props.onChange({
        dataItem: props.dataItem,
        field: props.field,
        value: e.target.value,
      });
    };
    const value = props.dataItem[props.field];

    if (!props.dataItem.inEdit) {
      return <td> {value === null ? "" : props.dataItem[props.field].toString()}</td>;
    }
    return (
      <td>
        <DropDownList
          style={{ width: "200px" }}
          onChange={handleChange}
          value={value}
          data={codeList.filter((c) => c.grpCodeNo === 30).map((item) => item.codeVal)}
        />
      </td>
    );
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              className="makrMngtGrid"
              style={
                loading
                  ? {
                      opacity: 0.6,
                      pointerEvents: "none",
                      height: "740px",
                    }
                  : { height: "740px" }
              }
              data={orderBy(dataSource, dataState.sort).slice(dataState.skip, dataState.take + dataState.skip)}
              skip={dataState.skip}
              take={dataState.take}
              total={dataSource.length}
              pageable={{ buttonCount: 5, pageSizes: true }}
              onPageChange={pageChange}
              sortable
              sort={dataState.sort}
              onSortChange={sortChange}
              filterable={true}
              filter={dataState.filter}
              onFilterChange={filterChange}
              onItemChange={itemChange}
              editField={"inEdit"}
            >
              <GridToolbar>
                <Button
                  className="excelBtn"
                  icon="add"
                  // style={{ backgroundColor: 'white' }}
                  onClick={addNew}
                  disabled={editFlag === true ? true : false}
                >
                  추가
                </Button>
              </GridToolbar>
              <Column field="makrSeriNo" title="No" width="100px" editable={false} filterCell={GridColumnInputFilter} />
              <Column field="makrTerm" title="제조사명" filterCell={GridColumnInputFilter} />
              <Column field="makrAddr" title="제조사 주소" filterCell={GridColumnInputFilter} />
              <Column field="makrPhon" title="연락처" filterable={false} />
              <Column field="makrEmail" title="이메일" filterable={false} />
              <Column field="makrClsfNo" title="제조사 분류" filterCell={clsfNoDropDownList} cell={clsfNoDropDownCell} />
              <Column field="makrExpl" title="제조사 설명" filterCell={GridColumnInputFilter} />
              <Column field="authYn" title="인증여부" filterCell={authYnDropDownList} cell={authYnDropDownCell} />
              <Column title="수정 / 삭제" cell={CommandCell} width="160px" filterable={false} />
              <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
        {/* 엑셀로딩*/}
        {loading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </div>
  );
};

export default MakrMngt;
