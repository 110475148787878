export const saveSolaModule = async (conn, data) => {
  const param = {
    modSeqNo: data.modSeqNo,
    modTerm: data.modTerm,
    vltMp: data.vltMp,
    curMp: data.curMp,
    pwrMp: data.pwrMp,
    vltOc: data.vltOc,
    curSc: data.curSc,
    cellType: data.cellType.id,
    tempVltCof: data.tempVltCof,
    tempCurCof: data.tempCurCof,
    vltFita: data.vltFita,
    vltFitb: data.vltFitb,
    vltFitc: data.vltFitc,
    curFita: data.curFita,
    curFitb: data.curFitb,
    vltIrZero: data.vltIrZero,
    vltIrOne: data.vltIrOne,
    vltIrTwo: data.vltIrTwo,
    vltIrThree: data.vltIrThree,
    vltIrFour: data.vltIrFour,
    curIrZero: data.curIrZero,
    curIrOne: data.curIrOne,
    curIrTwo: data.curIrTwo,
    curIrThree: data.curIrThree,
    curIrFour: data.curIrFour,
  };
  console.log("param", param);
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const response = await conn.post("kier/module", param, config);

  return response;
};
export const getSolaModulePage = async (conn, data) => {
  const { take, skip, sort, filter } = data;
  const config = {
    params: {
      page: Math.floor((take + skip) / take),
      size: take,
      sort,
    },
  };
  if (filter !== undefined) {
    if (filter.modSeqNo !== undefined) config.params.modSeqNo = filter.modSeqNo;
    if (filter.modTerm !== undefined) config.params.modTerm = filter.modTerm;
  }
  const response = await conn.get("/kier/module/page", config);

  console.log(config);
  return response;
};

export const getSolaModuleList = async (conn, data) => {
  const response = await conn.get("/kier/module/list");
  return response;
};

export const delSolaModule = async (conn, data) => {
  const response = await conn.delete("/kier/module/" + data);

  return response;
};

export const setCurveFitData = async (conn, data) => {
  const param = {
    modSeqNo: data.modSeqNo,
    modTerm: data.modTerm,
    vltMp: data.vltMp,
    curMp: data.curMp,
    pwrMp: data.pwrMp,
    vltOc: data.vltOc,
    curSc: data.curSc,
    cellType: data.cellType.id,
    tempVltCof: data.tempVltCof,
    tempCurCof: data.tempCurCof,
  };
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const response = await conn.post("/kier/module/curvefit", param, config);

  return response;
};
