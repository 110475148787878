import { useEffect } from "react";
import { useLocation, withRouter } from "react-router";
import { getUser, setUserHistPage } from "./service/authService";


const PageInfo = withRouter(({conn}) => {

  // 위치 경로 확인
  const location = useLocation();
  
  useEffect(() => {
    const user = getUser();
    setUserHistPage( conn , user , location.pathname ).then(e => {
      
    });
  // eslint-disable-next-line
  },[location]);

  return (
    <></>
  );
});

export default PageInfo;