import kendo from "@progress/kendo-ui";
import latestGathColumn from "./latestGathColumn.json";
import latestGathMngtColumn from "./latestGathMngtColumn.json";

export const getSmpTodayData = async (conn, callback, reject) => {
  await conn
    .get("monitor/installer/gath/smp/day", {
      params: {
        start: kendo.toString(new Date(), "yyyy-MM-dd"),
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getSmpCurMonthData = async (conn, callback, reject) => {
  await conn
    .get("monitor/installer/gath/smp/month", {
      params: {
        start: kendo.toString(new Date(), "yyyy-MM-01"),
        end: kendo.toString(new Date(), "yyyy-MM-dd"),
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getRecDayData = async (conn, callback, reject) => {
  await conn
    .get("monitor/installer/munic/rec/day", {
      params: {
        num: 1,
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getRecMonthData = async (conn, callback, reject) => {
  await conn
    .get("monitor/installer/munic/rec/month", {
      params: {
        month: kendo.toString(new Date(), "yyyyMM"),
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getCerDayData = async (conn, callback, reject) => {
  await conn
    .get("monitor/installer/munic/cer/day", {
      params: {
        start: kendo.toString(new Date(), "yyyy-MM-dd"),
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getCerMonthData = async (conn, callback, reject) => {
  await conn
    .get("monitor/installer/munic/cer/month", {
      params: {
        month: kendo.toString(new Date(), "yyyyMM"),
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getLatestGathEnergy = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/latest", {
    params: { installerId, ensoTypeCode, cid },
  });
  const data = response;
  const gath = [...latestGathColumn[ensoTypeCode]];

  for (let i in gath) {
    for (let k in data) {
      if (k === gath[i].name) {
        gath[i].value = data[k];
        if (k === "outpElcpFigr") {
          gath[i].value = gath[i].value && gath[i].value / 1000;
        }
      }
    }
  }
  return { gath: gath, latestGathDtm: data.latestGathDtm };
};

export const getLatestGathMngtEnergy = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/latest", {
    params: { installerId, ensoTypeCode, cid },
  });
  const data = response;
  const gath = [...latestGathMngtColumn[ensoTypeCode]];

  for (let i in gath) {
    for (let k in data) {
      if (k === gath[i].name) {
        gath[i].value = data[k];
        
        if (k === "outpElcpFigr") {
          gath[i].value = gath[i].value && gath[i].value / 1000;
        }
        if (k === "errCode") {
          if (gath[i].value != null) {
            var errCodeSub = gath[i].value.substr(13, 3);
            if (errCodeSub === "010" || errCodeSub === "100" || errCodeSub === "110") {
              gath[i].value = "Fuse fault";
            } else if (errCodeSub === "001") {
              gath[i].value = "Earth Fault";
            } else if (errCodeSub === "011" || errCodeSub === "101" || errCodeSub === "111") {
              gath[i].value = "Fuse fault & Earth Fault";
            } else {
              gath[i].value = "정상";
            }
          } else {
            gath[i].value = "정상";
          }
        }
      }
    }
  }

  return { gath: gath, latestGathDtm: data.latestGathDtm };
};

export const getHourlyEnergyList = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/hourly/list", {
    params: { installerId, ensoTypeCode, cid },
  });
  return response;
};

export const getAreaEnergyList = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/area/list", {
    params: { installerId, ensoTypeCode, cid },
  });
  const list = response;
  // console.log("list", list);
  // const colors = ["#767E8D", "#767E8D", "#5A8DEE", "#1E88E5"];
  return list;
};

export const getLatestEventList = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/event/list", {
    params: { installerId, ensoTypeCode, cid },
  });
  return response;
};

export const getWeekEnergyList = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/week/list", {
    params: { installerId, ensoTypeCode, cid },
  });
  let list = response;
  return list.map(v => ({ term: v.gathDtm, gathDtm: v.gathDtm, energy: v.energy, use: v.use }));
};

export const getInstaller = async (conn, installerId) => {
  const response = await conn.get("user/installer/" + installerId, {
    params: {},
  });
  return {
    userTerm: response.userTerm,
    rgnCode: response.rgnCode,
    dongCode: response.fixbylawBundTermSimple,
  };
};

export const getInstallerMeainList = async (conn, installerId, ensoTypeCode) => {
  const response = await conn.get("monitor/installer/gath/meain/list", {
    params: { ensoTypeCode, installerId },
  });
  let list = response;
  return list.map(v => ({
    cid: v.cid,
    term: v.cid + "",
  }));
};

export const getInstallerEnsoList = async (conn, installerId) => {
  const response = await conn.get("monitor/installer/gath/enso/list", {
    params: { installerId },
  });
  return response;
};

export const getInstallerEnergy = async (conn, installerId, ensoTypeCode, cid) => {
  const response = await conn.get("monitor/installer/gath/energy", {
    params: { installerId, ensoTypeCode, cid },
  });
  return response;
};
