import React , {useState , useEffect} from "react";
//Kendo Tab TabStrip , TabStripTab
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { DropDownList } from "@progress/kendo-react-dropdowns";
// 탭
import MeainTab from './meainTab';
//CSS
import './meain.css';
// 서비스사용
import { getRtu } from '../../../service/mngt/rtuEnte/rtuService';

import { getUserMngRgnList, isManager } from "../../../service/authService";
import { getCalCityList, getCalRgnList } from "../../../com/dataUtil";
import { useLocation } from 'react-router-dom'
import { CODE_ESS, CODE_HEAT, CODE_SOLA, CODE_VELO } from "../../../com/codeUtil";
import { CODE_HEATH, CODE_FUELCELL } from './../../../com/codeUtil';

// 문제 사항 
// 현재 DateTimePicker 와 DropDownList 아이콘이 a 태그가 아닌 span 태그로 붙여서 화면에 이벤트 아이콘을 보여주지 않아서 좀 더 확인이 필요함 

const Meain = ( { conn ,  codeList , cityList , rgnList } ) => {

  // 사업현황에서 받아온 연동할 정보를 받아온다.
  const location = useLocation();
  // console.log(location.meainTransData);
  const bpmManagementInfo = {
    meainSync : "N" , 
    meainInfo : {} , 
    sysMode : '' , 
    ensoTypeCode : 15001
  }
  if( location.meainTransData ){
    const meainTransData = location.meainTransData;
    bpmManagementInfo.meainSync = meainTransData.meainSync;
    bpmManagementInfo.meainInfo = meainTransData.meainInfo;
    bpmManagementInfo.meainInfo.meainSync = meainTransData.meainSync;
    bpmManagementInfo.sysMode = meainTransData.sysMode;
    bpmManagementInfo.ensoTypeCode = meainTransData.ensoTypeCode;
  }

   // 필터 드롭다운 
  const [filterDropdown , setFilterDropdown] = useState({
    rgn : {rgnCode : "" , rgnTermSimple : "시/군/구" },
    city : {cityProvCode : "" , cityProvTerm : "시/도" }
  });

  let citys = getCalCityList(cityList);
  let rgns = getCalRgnList(rgnList.filter(e => e.cityProvCode === filterDropdown.city.cityProvCode));

  // 담당자 일 떄 시군구 리스트 설정
  if( isManager() === true){
    const userMngRgnList = getUserMngRgnList();
    const rgnListfilter = [];
    
    rgns.forEach(element => {
      if( userMngRgnList.length !== 0 ){
        userMngRgnList.forEach(rgnCode => {
          if( rgnCode === element.rgnCode ){               
            rgnListfilter.push(element);
          }
        });
      }else{
        rgnListfilter.push(element);
      }
    });
    rgns = rgnListfilter;
  }

  // 시/군/구 그리드 필터 드롭다운
  const cityFilterDropDown = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropdown( filterDropdown => {
        const item = {...filterDropdown};
        item.city = e.value;
        item.rgn = {rgnCode : "" , rgnTermSimple : "시/군/구" };
        return item;
      });
    }  

    return (
      <div className="k-filtercell">
          <DropDownList
            data={citys}
            onChange={filterOnchage}
            defaultItem= {{cityProvCode : "" , cityProvTerm : "시/도" }}
            className = "CustomFilterDropDown" name = "city"
            textField = "cityProvTerm" dataItemKey= "cityProvCode"
            value = {filterDropdown.city}
          />
      </div>
    );
  }

  // 시/군/구 그리드 필터 드롭다운
  const rgnFilterDropDown = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropdown( filterDropdown => {
        const item = {...filterDropdown};
        item.rgn = e.value;
        return item;
      });
    }  

    return (
      <div className="k-filtercell">
          <DropDownList
            data={rgns}
            onChange={filterOnchage}
            defaultItem= {{rgnCode : "" , rgnTermSimple : "시/군/구" }}
            className = "CustomFilterDropDown" name = "rgn"
            textField = "rgnTermSimple" dataItemKey= "rgnCode"
            value = {filterDropdown.rgn}
            disabled={filterDropdown.city.cityProvCode === ""}
          />
      </div>
    );
  }

  // RTU 업체 리스트
  const [meainDs , setMeainDs] = useState({
    rtuEnteList : []
    , meainModleMakrList : []
  })

  // 탭 상태
  const [tabStat, setTabStat] = useState({});

  // 탭 선택 시 발생하는 이벤트 
  const selectTabItem = (e) =>{
      let ensoTypeCode = 15001;
      if( e.selected === 0 ){ ensoTypeCode = 15001; }
      else if( e.selected === 1 ){ ensoTypeCode = 15002; }
      else if( e.selected === 2 ){ ensoTypeCode = 1500229002; }
      else if( e.selected === 3 ){ ensoTypeCode = 15003; }
      else if( e.selected === 4 ){ ensoTypeCode = 15004; }
      else if( e.selected === 5 ){ ensoTypeCode = 15006; }
      else if( e.selected === 6 ){ ensoTypeCode = 15007; }

      // 탭 선택에 대한 탭 값 설정
      setTabStat({
          state : e.selected
          , ensoTypeCode : ensoTypeCode
      });
  }

  

  // RTU 업체 목록 서비스
  const getRtuEnteList = () => {
    getRtu(conn ).then(response => {
      setMeainDs(meainDs => {
        const item = {...meainDs};
        item.rtuEnteList = response;
        return item;
      })
    }).catch( error => {
      getRejection(error);
    });
  }

  // 리젝션 함수
  const getRejection = (error) => {

  }



  useEffect(() => {
    if(bpmManagementInfo.meainSync === 'Y'){
      let tabNumber = 0;
      let ensoTypeCode = bpmManagementInfo.ensoTypeCode;
      const sysMode = bpmManagementInfo.sysMode;
      if( ensoTypeCode === CODE_SOLA ){ tabNumber = 0 }
      else if( ensoTypeCode === CODE_HEAT ){
        // console.log("sysMode = ", sysMode);
        tabNumber = 1
        if( sysMode === 29002 ){ tabNumber = 2; ensoTypeCode = 15002; }
      }
      else if( ensoTypeCode === CODE_HEATH ){ tabNumber = 3 }
      else if( ensoTypeCode === CODE_VELO ){ tabNumber = 4 }
      else if( ensoTypeCode === CODE_FUELCELL ){ tabNumber = 5 }
      else if( ensoTypeCode === CODE_ESS ){ tabNumber = 6 }
      // console.log("tabNumber = ", tabNumber);
      setTabStat({
        state : tabNumber
        , ensoTypeCode : bpmManagementInfo.ensoTypeCode
      });
    }else{
      setTabStat({
        state : 0
        , ensoTypeCode : 15001
      });
    }
    // 탭 상태 설정
    getRtuEnteList();
    // getMeainModlMakrList();
    // eslint-disable-next-line
  }, []);

  return(
      <div>
          <TabStrip selected={tabStat.state} onSelect={selectTabItem}>
              <TabStripTab title="태양광" key = {"tabSola"}>
                  <dd style = {{padding:"20px"}}>
                      <MeainTab conn = {conn} codeList = {codeList} cityList={cityList} rgnList = {rgnList} meainDs = {meainDs} ensoTypeCode = {15001} cityFilterDropDown = {cityFilterDropDown} rgnFilterDropDown = {rgnFilterDropDown} bpmManagementInfo = {bpmManagementInfo} 
                      setMeainDs = {setMeainDs}
                      />
                  </dd>
              </TabStripTab>
              <TabStripTab title="태양열" key = {"tabHeat"}>
                  <dd style = {{padding:"20px"}}>
                    <MeainTab conn = {conn} codeList = {codeList} cityList={cityList} rgnList = {rgnList} meainDs = {meainDs} ensoTypeCode = {15002} sysMode = {29001} cityFilterDropDown = {cityFilterDropDown} rgnFilterDropDown = {rgnFilterDropDown} bpmManagementInfo = {bpmManagementInfo} 
                    setMeainDs = {setMeainDs}
                    />
                  </dd>
              </TabStripTab>
              <TabStripTab title="태양열(자연)" key = {"tabHeatN"}>
                  <dd style = {{padding:"20px"}}>
                    <MeainTab conn = {conn} codeList = {codeList} cityList={cityList} rgnList = {rgnList} meainDs = {meainDs} ensoTypeCode = {15002} sysMode = {29002} cityFilterDropDown = {cityFilterDropDown} rgnFilterDropDown = {rgnFilterDropDown} bpmManagementInfo = {bpmManagementInfo} 
                    setMeainDs = {setMeainDs}
                    />
                  </dd>
              </TabStripTab>
              <TabStripTab title="지열" key = {"tabHeath"}>
                  <dd style = {{padding:"20px"}}>
                    <MeainTab conn = {conn} codeList = {codeList} cityList={cityList} rgnList = {rgnList} meainDs = {meainDs} ensoTypeCode = {15003} cityFilterDropDown = {cityFilterDropDown} rgnFilterDropDown = {rgnFilterDropDown} bpmManagementInfo = {bpmManagementInfo} 
                    setMeainDs = {setMeainDs}
                    />
                  </dd>
              </TabStripTab>
              <TabStripTab title="풍력" key = {"tabVelo"}>
                  <dd style = {{padding:"20px"}}>
                    <MeainTab conn = {conn} codeList = {codeList} cityList={cityList} rgnList = {rgnList} meainDs = {meainDs} ensoTypeCode = {15004} cityFilterDropDown = {cityFilterDropDown} rgnFilterDropDown = {rgnFilterDropDown} bpmManagementInfo = {bpmManagementInfo} 
                    setMeainDs = {setMeainDs}
                    />
                  </dd>
              </TabStripTab>
              <TabStripTab title="연료전지" key = {"tabFuelCell"}>
                  <dd style = {{padding:"20px"}}>
                    <MeainTab conn = {conn} codeList = {codeList} cityList={cityList} rgnList = {rgnList} meainDs = {meainDs} ensoTypeCode = {15006} cityFilterDropDown = {cityFilterDropDown} rgnFilterDropDown = {rgnFilterDropDown} bpmManagementInfo = {bpmManagementInfo} 
                    setMeainDs = {setMeainDs}
                    />
                  </dd>
              </TabStripTab>
              <TabStripTab title = "ESS">
                <dd style = {{padding:"20px"}}>
                  <MeainTab conn = {conn} codeList = {codeList} cityList={cityList} rgnList = {rgnList} meainDs = {meainDs} ensoTypeCode = {15007} cityFilterDropDown = {cityFilterDropDown} rgnFilterDropDown = {rgnFilterDropDown} bpmManagementInfo = {bpmManagementInfo} 
                  setMeainDs = {setMeainDs}
                  />
                </dd>
              </TabStripTab>
          </TabStrip>
      </div>
  );
}

export default Meain;