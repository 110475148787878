// 모듈 효율 15001
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import kendo from '@progress/kendo-ui';

const ModuleEficGrid = ({dataSource , unit , setExcelData , drilldown , makrTerm, completeExcel ,LocalizationProvider ,IntlProvider}) => {
    
  const [paging , setPaging] = useState({ take: 20, skip: 0 });
  const [sort , setSort] = useState([{field: '', dir: ''}]);

  // 페이징 이벤트
  const onPageChange = (e) =>{ const {page} = e; setPaging(page); }

  // 정렬 이벤트
  const onSortChange = (e) =>{ const {sort} = e; setSort(sort); }

  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  // 파일 이름
  const fileName = () =>{
    let name = "";
    if(unit === "HH") name += "시간별_";
    else if(unit === "DD") name += "일별_";
    else if(unit === "MM") name += "월별_";
    const tempName = drilldown === false ? "모듈 효율 데이터_" : makrTerm + " 효율 데이터_" ;
    const template = name + tempName + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
    return template;
  }

  useEffect(() => {
    // 엑셀 다운로드 데이터 설정
    setExcelData(_excelData)
    // eslint-disable-next-line
  }, []);

  return(
      <div>
        <ExcelExport className = "moduleGrid"
          data={orderBy ( dataSource, sort )}
          ref={exporter => _excelData = exporter }
        fileName={fileName()}
        onExportComplete={completeExcel}
          >
          <ExcelExportColumn  title='순위' field='no' width = {80}/>
          <ExcelExportColumn title={drilldown === false ? "제조사" : "모델"} field={drilldown === false ? "makrTerm" : "meainTypeTerm" } width = {185} ></ExcelExportColumn>
          <ExcelExportColumn title='운영개소' field='count' width = {185} ></ExcelExportColumn>
          <ExcelExportColumn title='평균효율' field='efic' width = {185} ></ExcelExportColumn>
          <ExcelExportColumn title='최저효율' field='minEfic' width = {185} ></ExcelExportColumn>
          <ExcelExportColumn title='최고효율' field='maxEfic' width = {185} ></ExcelExportColumn>
          <ExcelExportColumn title='설비용량' field='instCapa' width = {185} ></ExcelExportColumn>
          <ExcelExportColumn title='설비용량비율' field='ratio' width = {185} ></ExcelExportColumn>
          <ExcelExportColumn title='발전량' field='dayPower' width = {185} ></ExcelExportColumn>
        </ExcelExport>
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              style={{height:"400px"}} id = "moduleGrid" className = "ensoModuleGrid"
              data =  { orderBy ( dataSource, sort ).slice(paging.skip , paging.take + paging.skip)} 
              total = {dataSource.length}
              skip = {paging.skip}
              take = {paging.take}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              sort = {sort}
              page = {paging}
              sortable
              pageable
            >
                <GridNoRecords>
                    {"  "}
                </GridNoRecords>
                <GridColumn title='순위' field='no' width = "80px" ></GridColumn>
                <GridColumn title={drilldown === false ? "제조사" : "모델"} field={drilldown === false ? "makrTerm" : "meainTypeTerm" } ></GridColumn>
                <GridColumn title='운영개소' field='count' ></GridColumn>
                <GridColumn title='평균효율' field='efic' ></GridColumn>
                <GridColumn title='최저효율' field='minEfic' ></GridColumn>
                <GridColumn title='최고효율' field='maxEfic' ></GridColumn>
                <GridColumn title='설비용량' field='instCapa' ></GridColumn>
                <GridColumn title={<div>설비용량<br></br>비율</div>} field='ratio' ></GridColumn>
                <GridColumn title='발전량' field='dayPower' ></GridColumn>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
  )
}

export default ModuleEficGrid;