export const initMunicDataSource = {
  // 금일 SMP
  landSmp: "",
  jejuSmp: "",
  // 전일 SMP
  landSmpYesterday: "",
  jejuSmpYesterday: "",
  // 월별 SMP
  monthLandSmp: "",
  monthJejuSmp: "",
  // 금일 REC
  rec: "",
  // 전일 REC
  recYesterday: "",
  // 금일 CER
  cer: "",
  // 전일 CER
  cerYesterday: "",
  // 태양광 데이터
  solaData: {
    instCapa: 0,
    count: 0,
    dayPower: 0,
    minusPower: 0,
    rate: 0,
  },
  solaStatData: {
    instCapa: 0,
    count: 0,
  },
  // 왼쪽의 금일, 전일 데이터
  todayPowerData: "",
  prePowerData: "",
  effi: "",
  // 오른쪽 위 통계 데이터
  ensoMeainCntList: "",
  // 동별 태양광 데이터
  realSolaDongData: "",
};
export const initMunicDongData = {
  // 지역별 금일발전량
  dongPower1: 0,
  dongPowerTerm1: "",
  dongPower2: 0,
  dongPowerTerm2: "",
  dongPower3: 0,
  dongPowerTerm3: "",
  // 지역별 전일발전량
  dongYesterdayPower1: 0,
  dongYesterdayPower2: 0,
  dongYesterdayPower3: 0,
  // 지역별 전일 대비 이용률 차 값 추출
  dongPrePowerRank1: 0,
  dongPrePowerRank2: 0,
  dongPrePowerRank3: 0,
  // 지역별 금일이용율
  dongEfic1: 0,
  dongEficTerm1: "",
  dongEfic2: 0,
  dongEficTerm2: "",
  dongEfic3: 0,
  dongEficTerm3: "",
  // 지역별 전일이용율
  dongYesterdayEfic1: 0,
  dongYesterdayEfic2: 0,
  dongYesterdayEfic3: 0,
  // 지역별 전일 대비 이용률 차 값 추출
  dongPreEficRank1: 0,
  dongPreEficRank2: 0,
  dongPreEficRank3: 0,
};
export const initMunicUserData = {
  // 수용가별 금일발전량
  userPower1: 0,
  userTerm1: "",
  userPower2: 0,
  userTerm2: "",
  userPower3: 0,
  userTerm3: "",
  // 수용가별 전일발전량
  userYesterdayPower1: 0,
  userYesterdayPower2: 0,
  userYesterdayPower3: 0,
  // 수용가 전일 대비 발전량 차 값 추출
  userPrePowerRank1: 0,
  userPrePowerRank2: 0,
  userPrePowerRank3: 0,
};

export const initMapItem = {
  fixbylawBundTerm: "",
  syncCount: "",
  asyncCount: "",
  syncInstCapa: "",
  asyncInstCapa: "",
  syncDayPower: "",
  asyncDayPower: "",
  fixbylawBundTermProfit: "",
};
