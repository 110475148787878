import React, { useState, useEffect } from "react";
import { getCalCityList, getCalRgnList } from "../../../../com/dataUtil";
import BizCancelMngtGrid from "./bizCancelMngtGrid";
import BizCancelMngtSearch from "./bizCancelMngtSearch";

// 로딩스피너
import { BeatLoader } from "react-spinners";
import { makeNavigator } from "../commonFuc";
import { getCancel } from "../../../../service/bizStat/com/cancelApiService";

const BizCancelMngt = ({ conn, codeList, cityList, rgnList, styles, isMobile, search, setSearch }) => {
  // 시도 , 시군구 리스트 가공
  let citys = getCalCityList(cityList);
  let rgns = getCalRgnList(rgnList.filter(e => e.cityProvCode === search.cityProvCode && e.rgnCode !== "44131" && e.rgnCode !== "44133"));

  // 로딩스피너
  const [loading, setLoading] = useState(false);

  // 엑셀 로딩 스피너
  // const [excelLoading, setExcelLoading] = useState(false);

  const [dataList, setDataList] = useState({
    grid: {
      dataList: [],
      total: 0,
    },
    file: {},
  });

  // 데이터 불러오기 (검색 부분에서 찾을 시)
  const getData = () => {
    search.cancelPaging.take = 10;
    search.cancelPaging.skip = 0;
    // 네비게이션 설정
    makeNavigator(search);
    getDs();
  };

  // 데이터 불러오기
  const getDs = () => {
    setLoading(true);

    getCancel(conn, getResult, getRejection, setLoading, search);

    // getConvCancelInfo(
    //   conn,
    //   getResult,
    //   getRejection,
    //   setLoading,
    //   search.bizYyNo,
    //   search.rgnCode,
    //   search.instlTerm,
    //   search.energyTypeCode,
    //   search.cancelPaging,
    // );
  };

  const getResult = result => {
    // console.log('result', result);
    setDataList(dataList => {
      const item = { ...dataList };
      item.grid.dataList = result.results;
      item.grid.total = result.total;
      return item;
    });

    // setLoading(false);
  };

  // rejection 함수
  const getRejection = error => {
    console.log("error = ", error);
  };

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const { take, skip } = e.page;
    search.cancelPaging.skip = skip;
    search.cancelPaging.take = take;
    getDs();
  };

  useEffect(() => {
    getData();
    /* eslint-disable-next-line */
  }, []);

  return (
    <dd>
      <div className={styles.JinTabCon} id="JinTab_con">
        <BizCancelMngtSearch search={search} setSearch={setSearch} rgns={rgns} citys={citys} getData={getData} codeList={codeList} />
        <div className={loading ? [styles.convProject, styles.convProjectTable].join(" ") : styles.convProjectTable}>
          <div class="uk-grid">
            <BizCancelMngtGrid
              conn={conn}
              styles={styles}
              getDs={getDs}
              search={search}
              dataList={dataList}
              setDataList={setDataList}
              // setExcelLoading={setExcelLoading}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
        {loading && (
          <p className={styles.convProjectLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </dd>
  );
};

export default BizCancelMngt;
