import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import "./reportConsmPrintModal.css";
import { numFormat } from "../../../com/dataUtil";

function ReportConsmGrid({ selectMeain, chartMonthData, chartYearData, width,height, clsName, isPrint }) {
  // 차트 옵션
  let chartYear = {
    chart: {
      type: "line",
      zoomType: "xy",
      height: height?height:350,
      backgroundColor: "#f7f9fa",
      width: width !== null ? width : "100%",
    },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "(단위)kWh",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { shared: true },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        name: "금년발전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#87CEEB",
        data: chartYearData.energy,
      },
      {
        name: "작년발전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#F08080",
        data: chartYearData.preEnergy,
      },
    ],
  };

  // console.log("chartYearData.preEnergy",chartYearData.preEnergy);

  // 차트 옵션
  let chartMonth = {
    chart: { type: "line", zoomType: "xy", height: 350, backgroundColor: "#f7f9fa", width: width !== null ? width : "100%" },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "(단위)kWh",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { shared: true },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        name: "금월발전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#87CEEB",
        data: chartMonthData.energy,
      },
      {
        name: "전월발전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#F08080",
        data: chartMonthData.preEnergy,
      },
    ],
  };

  //세번째 자리마다 콤마 찍기
  const preYearPowerCell = (e) => {
    return <td>{numFormat(e.dataItem.preYearPower)}</td>;
  };
  const yearPowerCell = (e) => {
    return <td>{numFormat(e.dataItem.yearPower)}</td>;
  };
  const increaseYearRateCell = (e) => {
    return <td>{e.dataItem.increaseYearRate !== null ? numFormat(e.dataItem.increaseYearRate) + "%" : "-"}</td>;
  };
  const preMonthPowerCell = (e) => {
    return <td>{numFormat(e.dataItem.preMonthPower)}</td>;
  };
  const monthPowerCell = (e) => {
    return <td>{numFormat(e.dataItem.monthPower)}</td>;
  };
  const increaseMonthRateCell = (e) => {
    return <td>{e.dataItem.increaseMonthRate !== null ? numFormat(e.dataItem.increaseMonthRate) + "%" : "-"}</td>;
  };

  return (
    <div>
      <div className="uk-grid">
        <div className="uk-width-medium-1">
          <div className={isPrint?"reportConsmPrintSubheading":"reportDayBackground"}>
            <b> · &nbsp;전년대비 일별 발전현황 </b>
          </div>
          <div className={clsName !== null ? clsName : "reportDayBackground"}>
            <HighchartsReact Highcharts={Highcharts} options={chartYear} />
          </div>
        </div>
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium-1">
          <div style={{ paddingTop: 20 }}></div>
          <Grid className={isPrint ? "reportConsmPrintGrid" : "reportGrid"} data={selectMeain} sortable>
            <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
            <Column field="dtm" title="일" />
            <Column field="preYearPower" title="작년발전량(kWh)" cell={preYearPowerCell} />
            <Column field="yearPower" title="금년발전량(kWh)" cell={yearPowerCell} />
            <Column field="increaseYearRate" title="증감률" cell={increaseYearRateCell} />
          </Grid>
        </div>
      </div>
      <div className={isPrint?"spaceDiv":""}></div>
      <div className="uk-grid">
        <div className="uk-width-medium-1">
        <div className={isPrint?"reportConsmPrintSubheading":"reportDayBackground"}>
            <b> · &nbsp;전월대비 일별 발전현황 </b>
          </div>

          <div className={clsName !== null ? clsName : "reportDayBackground"}>
            <HighchartsReact options={chartMonth} />
          </div>
        </div>
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium-1">
          <Grid className={isPrint ? "reportConsmPrintGrid" : "reportGrid"} data={selectMeain} sortable>
            <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
            <Column field="dtm" title="일" />
            <Column field="preMonthPower" title="전월 발전량(kWh)" cell={preMonthPowerCell} />
            <Column field="monthPower" title="금월 발전량(kWh)" cell={monthPowerCell} />
            <Column field="increaseMonthRate" title="증감률" cell={increaseMonthRateCell} />
          </Grid>
        </div>
      </div>
      <div style={{ paddingBottom: 20 }}></div>
    </div>
  );
}

export default ReportConsmGrid;
