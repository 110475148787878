// 결정분류 15001 (결정분류별 분석 시간)
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat, roundNum } from '../../../../com/dataUtil';

const ModuleCrystalTimeChart = ({moduleCrystalDs}) => {
    
    // 차트 옵션
    const chartConfig = {
        chart:{ marginTop: 50 },
        title: { text: null },
        credits: { enabled: false },
        xAxis: { categories : moduleCrystalDs.gathDtm ,labels : { style: { color: '#000000' } } },
        yAxis: {
          labels: {
            style: { color: '#000000' }
          },
          title: {
              text: '발전효율(%)',
              rotation: 0,
              y: -20,
              offset: 0,
              align: 'high',
              style: { color: '#000000' }
          }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',backgroundColor:
            (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
            "#FFFFFF",
        },
        plotOptions: {
            series: { label: { connectorAllowed: false } }
        },
        tooltip: {
          shared: true,
          formatter: function() {
            let msg = `<span><b>${this.points[0].key}</b></span><br>`
            for(const idx in this.points){
              msg += 
                `
                  <span style="color:${this.points[idx].point.color}">\u25CF</span>
                  <span>${this.points[idx].series.name}</span> : <b>${numFormat(roundNum(this.points[idx].y , 2 ))} (kwh)</b><br>
                `
            }
            return msg;
          }
        },
        series: [
            { name: '단결정', data: moduleCrystalDs.singleEficAnly , color : '#0082ff' }
            , { name: '다결정', data: moduleCrystalDs.polyEficAnly , color : '#DC143C' }
            , { name: '미분류', data: moduleCrystalDs.nullEficAnly , color : '#32CD32' }
        ],
        responsive: {
            rules: [{
                condition: { maxWidth: 500 },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',backgroundColor:
                        (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
                        "#FFFFFF",
                    }
                }
            }]
        }
    };

    return(
        <div>
            <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
        </div>
    )
}

export default ModuleCrystalTimeChart;