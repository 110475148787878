import React, { useEffect, useState } from 'react';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 지열 정보
*/
const BizManagementMeainFuel = ({
  Input, convMngtStyles, DropDownList, meainInfo , initialDs,  setMeainInfo
  , DatePickerKo, DateTimePickerKo, onchageDate, onChangeDateTiem, dateFormat
  , onchageInput, onchageDropdown
  , searchKeyWord ,makrMeainModlfilterChange
}) => {

  // 제조사 / 모델 데이터 소스
  const makrList = initialDs.makrList.length > 0 ? initialDs.makrList : [];
  const meainModelList = initialDs.meainModelList.length > 0 ? initialDs.meainModelList : []; 

  // // 모듈 제조사 목록
  // const modulMakrDs = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30009 && c.makrTerm.includes(searchKeyWord.moduleMakrKey) ) : [];
  // // 모듈 타입 목록
  // const modulTypeDs = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15006 && c.makrSeriNo === meainInfo.modulMakrNo && c.meainTypeTerm.includes(searchKeyWord.moduleModlKey) ) : [];
  // 인버터 제조사 목록
  const invtMakrDs = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30010 && c.makrTerm.includes(searchKeyWord.invtMakrKey) ) : [];
  // 인버터 타입 목록
  const invtTypeDs = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15006 && c.makrSeriNo === meainInfo.fuelCellMakrNo && c.meainTypeTerm.includes(searchKeyWord.invtModlKey) ) : [];

  // const [modulMakr , setModulMakr] = useState( meainInfo.modulMakrNo ? { makrSeriNo : meainInfo.modulMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  // const [modulType , setModulType] = useState( meainInfo.modulTypeNo ? { meainTypeSeriNo : meainInfo.modulTypeNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [invtMakr , setInvtMakr] = useState( meainInfo.fuelCellMakrNo ? { makrSeriNo : meainInfo.fuelCellMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : "-" } );
  const [invtType , setInvtType] = useState( meainInfo.fuelCellModlNo ? { meainTypeSeriNo : meainInfo.fuelCellModlNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );

  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      // if( meainInfo.modulMakrNo ){
      //   let makrSeriNo = "";
      //   let makrTerm = "";
      //   modulMakrDs.forEach(element => {
      //       if( element.makrSeriNo === meainInfo.modulMakrNo ){
      //           makrSeriNo = element.makrSeriNo;
      //           makrTerm = element.makrTerm;
      //       }
      //   });
      //   setModulMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      // }else{
      //   setModulMakr({ makrSeriNo: '' , makrTerm: '-' });
      // }
      if( meainInfo.fuelCellMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        invtMakrDs.forEach(element => {
            if( element.makrSeriNo === meainInfo.fuelCellMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setInvtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setInvtMakr({ makrSeriNo: '', makrTerm: '-' });
      }
    }else{
      // setModulMakr({ makrSeriNo: '' , makrTerm: '-' });
      setInvtMakr({ makrSeriNo: '', makrTerm: '-' });
    }
  }

  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      // if( meainInfo.modulTypeNo ){
      //   let meainTypeSeriNo = "";
      //   let meainTypeTerm = "";
      //   modulTypeDs.forEach(element => {
      //       if( element.meainTypeSeriNo === meainInfo.modulTypeNo ){
      //           meainTypeSeriNo = element.meainTypeSeriNo;
      //           meainTypeTerm = element.meainTypeTerm;
      //       }
      //   });
      //   setModulType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      // }else{
      //   setModulType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      // }
      if( meainInfo.meainTypeSeriNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        invtTypeDs.forEach(element => {
            if( element.meainTypeSeriNo === meainInfo.fuelCellModlNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setInvtType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
    }else{
      // setModulType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
    }
  }

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    /* eslint-disable-next-line */
  }, [meainInfo]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  console.log(" meainInfo =  ", meainInfo);

  return (
    <>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;설치일자
        </th>
        <td>
          <DateTimePickerKo 
                  id = "meainInstDtm" name = "meainInstDtm" className={convMngtStyles.inputTerm}
                  value = {dateFormat(meainInfo.meainInstDtm)} format = {"yyyy-MM-dd HH:mm"}
                  formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                  setDtm = {(v) => { onChangeDateTiem(v , "meainInstDtm"); }} 
                  // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;A/S 만료일자
        </th>
        <td>
          <DatePickerKo 
                id = "asExpiDtm" name = "asExpiDtm" className={convMngtStyles.inputTerm}
                value = {dateFormat(meainInfo.asExpiDtm)} 
                format="yyyy-MM-dd"
                formatPlaceholder={{ year: '년', month: '월', day: '일' }}
                setDtm = {(v) => { onchageDate(v , "asExpiDtm"); }}
                // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;연료전지 제조사</th>
        <td>
            <DropDownList 
            id = "fuelCellMakrNo" name = "fuelCellMakrNo"  className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="makrSeriNo" textField = "makrTerm" 
            value = { invtMakr } data = {invtMakrDs}
            defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setInvtMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            // valid = {meainInfo.heatPumpMakrNo !== "" && meainInfo.heatPumpMakrNo !== null && typeof meainInfo.heatPumpMakrNo !== "undefined" }
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;연료전지 모델</th>
        <td>
            <DropDownList 
            id = "fuelCellModlNo" name = "fuelCellModlNo" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
            disabled = { meainInfo.fuelCellMakrNo ? false : true }
            value = { invtType } data = {invtTypeDs}
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo , "meainTypeSeriNo" : e.value.meainTypeSeriNo  }; // 설비타입번호 설정
            setInvtType({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            // valid = {meainInfo.meainTypeSeriNo !== "" && meainInfo.meainTypeSeriNo !== null && typeof meainInfo.meainTypeSeriNo !== "undefined" }
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;연료전지 용량(kW)</th>
        <td>
            <Input 
                id = "fuelCellCapa" name = "fuelCellCapa"  type = "number" className={convMngtStyles.inputTerm}
                value = { meainInfo.fuelCellCapa ? meainInfo.fuelCellCapa : "" } onChange = {onchageInput} 
                // required = {true} validationMessage = {inputValidMsg.normalText}
                />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;시공업체</th>
        <td>
            <Input 
                id = "consEnte" name = "consEnte"  type = "text" className={convMngtStyles.inputTerm}
                value = { meainInfo.consEnte ? meainInfo.consEnte : "" } onChange = {onchageInput} 
                // required = {true} validationMessage = {inputValidMsg.normalText}
                />
        </td>
        <th>시공업체 연락처</th>
        <td>
            <Input 
            id = "consEnteCntct" name = "consEnteCntct"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.consEnteCntct ? meainInfo.consEnteCntct : "" } onChange = {onchageInput}
            placeholder = "하이픈(-) 없이 입력해주세요."
            />
        </td>
      </tr>

      <tr>
      { meainInfo.moniYn === "Y" ?
          <>
            <th>
              <span className={ convMngtStyles.required }>*</span>&nbsp;설치확인관리번호
            </th>
            <td>
              <Input id = "instCnfrmCode" name = "instCnfrmCode" type="text" 
                    className={convMngtStyles.inputTerm} 
                    value = {meainInfo.instCnfrmCode ? meainInfo.instCnfrmCode : ""}
                    onChange = {onchageInput} />
            </td>
            <th></th>
            <td></td>
          </> :
          <>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
          </> 
        }
      </tr>
    </>
  );
};

export default BizManagementMeainFuel;