import kendo from "@progress/kendo-ui";
import { numFormat } from "../../com/dataUtil";

import { 
  getFirstDayOfMonth, getFirstDayOfYear, getMonday , getSunday , 
  getLastWeekSunday , getLastWeekSaturday  , 
  getFirstDayOfLastYear , getLastDayOfLastYear, getLastWeek
} from "../../com/dateUtil";

/**
 * 구글 Analytics 초기화 
 * 
 * @param  conn 
 * @return 초기화 결과
 */
 export const initialGoogleAnalytics = async(conn, callback, reject) =>{
  await conn.get('/sys/visitor/init', {
      params: {}
  }).then(response => {
    callback(response);
  }).catch(error => {
    reject(error);
  });   
}

/**
 * 쿼리 요청 
 * 
 * @param  conn 
 * @return 초기화 결과
 */

export const getQueryData = async(conn, callback, reject) =>{

  const today = new Date();
  let yesterday = new Date();
  yesterday.setDate( today.getDate() - 1 );

  await conn.get('/sys/visitor/total', {
      params: {
        today : "today",
        thisMonday : kendo.toString( getMonday( new Date() ) , 'yyyy-MM-dd'),
        thisMonthOneDay : kendo.toString( getFirstDayOfMonth( new Date() ) , 'yyyy-MM-dd'),
        thisYearOneDay : kendo.toString( getFirstDayOfYear( new Date() ) , 'yyyy-MM-dd'),
        thisSunday : kendo.toString( getSunday( new Date() ) , 'yyyy-MM-dd'),
        lastWeekSunday : kendo.toString( getLastWeekSunday( new Date() ) , 'yyyy-MM-dd'),
        lastWeekSaturDay : kendo.toString( getLastWeekSaturday( new Date() ) , 'yyyy-MM-dd'),
        lastYearOneDay : kendo.toString( getFirstDayOfLastYear( new Date() ) , 'yyyy-MM-dd'),
        lastYearLastDay : kendo.toString( getLastDayOfLastYear( new Date() ) , 'yyyy-MM-dd'),
        yesterday : kendo.toString( yesterday , 'yyyy-MM-dd' ),
        lastWeekYesterDay : kendo.toString(  getLastWeek(yesterday) , 'yyyy-MM-dd' ),
        lastWeekToday : kendo.toString(  getLastWeek( new Date() ) , 'yyyy-MM-dd' ),
        startGathDtm : kendo.toString(  getLastWeek( new Date() ) , 'yyyy-MM-dd' ) ,
        endGathDtm : kendo.toString( new Date() , 'yyyy-MM-dd')
      }
  }).then(response => {
    // 실시간 방문자 수 현황
    let realTimeUserCount = 0
    if( response.realTimeVisitUserCnt !== null ){
      const realTimeDataList = response.realTimeVisitUserCnt[0] ? response.realTimeVisitUserCnt[0] : null;
      realTimeUserCount = realTimeDataList[1] !== null ? realTimeDataList[1] : 0 ;
    }
    
    // 오늘 방문자 수 현황
    const todayUserCount = response.todayVisitUserCnt.rows ? response.todayVisitUserCnt.rows[0][0] : 0;
    // 주간 방문자 수 현황
    const weekUserCount = response.weekVisitUserCnt.rows ? response.weekVisitUserCnt.rows[0][0] : 0;
    // 이번달 방문자 수 현황
    const monthUserCount = response.monthVisitUserCnt.rows ? response.monthVisitUserCnt.rows[0][0] : 0;
    // 올해 방문자 수 현황
    const yearUserCount = response.yearVisitUserCnt.rows ? response.yearVisitUserCnt.rows[0][0] : 0;
    // 전체 방문자 수 현황
    const totalUserCount = response.totalVisitUserCnt.rows ? response.totalVisitUserCnt.rows[0][0] : 0;

    // 차트 가공 
    // (1) 지난주 대비 방문자 수 
    // 사용 객체 : lastWeekByVisitUserData , thisWeekByVisitUserData
    const lastWeekChartData = [];
    const thisWeekChartData = [];

    if( typeof response.lastWeekByVisitUserData.rows !== "undefined" ){
      response.lastWeekByVisitUserData.rows.forEach(element => {
        lastWeekChartData.push( Number(element[2]) )
      });
    }
    if( typeof response.thisWeekByVisitUserData.rows !== "undefined" ){
      response.thisWeekByVisitUserData.rows.forEach(element => {
        thisWeekChartData.push( Number(element[2]) )
      });
    }

    // (2) 작년 대비 방문자 수 
    // 사용 객체 : lastYearByVisitUserData , thisYearByVisitUserData
    const lastYearChartData = [];
    const thisYearChartData = [];

    if( typeof response.lastYearByVisitUserData.rows !== "undefined" ){
      response.lastYearByVisitUserData.rows.forEach(element => {
        lastYearChartData.push( Number(element[2]) )
      });
    }
    if( typeof response.thisYearByVisitUserData.rows !== "undefined" ){
      response.thisYearByVisitUserData.rows.forEach(element => {
        thisYearChartData.push( Number(element[2]) )
      });
    }

    // (3) TopBrowser 
    // 사용 객체 : topBrowsersData
    const topBrowserChartData = [];
    if( typeof response.topBrowsersData.rows !== "undefined" ){
      response.topBrowsersData.rows.forEach(element => {
        topBrowserChartData.push({name : element[0] , y : Number(element[1]) })
      });
    }

    // (4) TopDevice 
    // 사용 객체 : topDeviceData
    const topDeviceChartData = [];
    if( typeof response.topDeviceData.rows !== "undefined" ){
      response.topDeviceData.rows.forEach(element => {
        topDeviceChartData.push({name : element[0] , y : Number(element[1]) })
      });
    }

    // (5) 시도별 세션(sessions) _ 백분율 계산
    // 사용 객체 : rgnSessionData
    const rgnSessionChartData = [];
    if( typeof response.rgnSessionData.rows !== "undefined" ){
      let sumPercent = 0;
      response.rgnSessionData.rows.forEach(element => {
        sumPercent += Number(element[1]);
      });
      response.rgnSessionData.rows.forEach(element => {
        rgnSessionChartData.push({name : element[0] , y : (Number(element[1]) / sumPercent) * 100 })
      });
    }

    // (6) 모바일 기기 모델별 세션 _ 백분율 계산
    // 사용 객체 : mobileSessionData
    const mobileDeviceChartData = [];
    if( typeof response.mobileSessionData.rows !== "undefined" ){
      let sumPercent = 0;
      response.mobileSessionData.rows.forEach(element => {
        sumPercent += Number(element[1]);
      });
      response.mobileSessionData.rows.forEach(element => {
        mobileDeviceChartData.push({name : element[0] , y : (Number(element[1]) / sumPercent) * 100 })
      });
    }

    // (7) 운영체제별 세션 _ 백분율 계산
    // 사용 객체 : osSessionData
    const osSessionChartData = [];
    if( typeof response.osSessionData.rows !== "undefined" ){
      let sumPercent = 0;
      response.osSessionData.rows.forEach(element => {
        sumPercent += Number(element[1]);
      });
      response.osSessionData.rows.forEach(element => {
        osSessionChartData.push({name : element[0] , y : (Number(element[1]) / sumPercent) * 100  })
      });
    }

    // 그리드 가공
    // (8)  Browser 종류 (sessions)
    // 사용 객체 : browserKindsData
    const browserKindsData = [];
    // 브라우저 선택 값 
    let selectBrowserId = "";
    if( typeof response.browserKindsData.rows !== "undefined" ){
      response.browserKindsData.rows.forEach(element => {
        browserKindsData.push({name : element[0] , session : numFormat( Number(element[1])) });
      });

      // 타겟 브라우저 ID 설정
      if( browserKindsData.length > 0 ){
        selectBrowserId = browserKindsData[0].name;
      }
    }

    // 차트 가공
    // 선택한 브라우저에 대한 어제부터 1주일 전 데이터 리스트 차트 가공
    // 사용 객체 : targetBrowserDataList
    const targetBrowserDataChartData = [];
    const targetBrowserDataCategory = [];
    if( typeof response.targetBrowserDataList.rows !== "undefined" ){
      response.targetBrowserDataList.rows.forEach(element => {
        targetBrowserDataChartData.push({name : element[0] , y : Number(element[1]) })
        targetBrowserDataCategory.push(element[0]);
      });
    }

    // 일자별 세션 값 가공
    const dailySessionChartData = [];
    const dailySessionChartCategory = [];
    if( typeof response.dailySessionDataList.rows !== "undefined" ){
      response.dailySessionDataList.rows.forEach(element => {
        dailySessionChartData.push({name : element[0] , y : Number(element[1]) })
        dailySessionChartCategory.push(element[0]);
      });
    }

    // 결과 값 가공 객체
    const result = {
      realTimeUserCount : realTimeUserCount !== null ? realTimeUserCount : 0,
      todayUserCount : todayUserCount !== null ? todayUserCount : 0,
      weekUserCount : weekUserCount !== null ? weekUserCount : 0,
      monthUserCount : monthUserCount !== null ? monthUserCount : 0,
      yearUserCount : yearUserCount !== null ? yearUserCount : 0,
      totalUserCount : totalUserCount !== null ? totalUserCount : 0,
      lastWeekChartData : lastWeekChartData, thisWeekChartData : thisWeekChartData,
      lastYearChartData : lastYearChartData, thisYearChartData : thisYearChartData,
      topBrowserChartData : topBrowserChartData , topDeviceChartData : topDeviceChartData,
      rgnSessionChartData : rgnSessionChartData , mobileDeviceChartData : mobileDeviceChartData,
      osSessionChartData : osSessionChartData ,
      browserKindsData : browserKindsData, selectBrowserId : selectBrowserId,
      targetBrowserDataChartData : targetBrowserDataChartData , targetBrowserDataCategory : targetBrowserDataCategory,
      dailySessionChartData : dailySessionChartData , dailySessionChartCategory : dailySessionChartCategory
    }
    callback(result);
  }).catch(error => {
    reject(error);
  });   
}

/**
 * 실시간 유저 카운트 값 가져오기
 * 
 * @param  conn 
 * @return 실시간 유저 카운트 값
 */
 export const getRealTimeUserCount = async(conn, callback, reject) =>{
  await conn.get('/sys/visitor/realTime', {
    params: {}
  }).then(response => {

    // 실시간 방문자 수 현황
    let realTimeUserCount = 0
    if( response.realTimeVisitUserCnt !== null ){
      const realTimeDataList = response.realTimeVisitUserCnt[0] ? response.realTimeVisitUserCnt[0] : null;
      realTimeUserCount = realTimeDataList[1] !== null ? realTimeDataList[1] : 0 ;
    }

    // 결과 값 가공 객체
    const result = {
      realTimeUserCount : realTimeUserCount !== null ? realTimeUserCount : 0
    }
    callback(result);
  }).catch(error => {
    reject(error);
  });   
}

/**
 * Browser 클릭 시 해당 날짜 별로 세션 값 가져오기
 * 
 * @param  conn , browser name
 * @return 어제 기준으로 부터 1주일 전 세션 갯수 데이터 가져오기
 */
 export const getSessionDataListByBrowserName = async(conn, callback, reject , browserName) =>{
  const today = new Date();
  let yesterday = new Date();
  yesterday.setDate( today.getDate() - 1 );
  await conn.get('/sys/visitor/browserYesterday', {
    params: {
      yesterday : kendo.toString( yesterday , 'yyyy-MM-dd' ),
      lastWeekYesterDay : kendo.toString(  getLastWeek(yesterday) , 'yyyy-MM-dd' ),
      browserName : browserName
    }
  }).then(response => {
    // 차트 가공
    // 선택한 브라우저에 대한 어제부터 1주일 전 데이터 리스트 차트 가공
    // 사용 객체 : targetBrowserDataList
    const targetBrowserDataChartData = [];
    const targetBrowserDataCategory = [];
    if( typeof response.targetBrowserDataList.rows !== "undefined" ){
      response.targetBrowserDataList.rows.forEach(element => {
        targetBrowserDataChartData.push({name : element[0] , y : Number(element[1]) })
        targetBrowserDataCategory.push(element[0]);
      });
    }
     // 결과 값 가공 객체
    const result = {
      targetBrowserDataChartData : targetBrowserDataChartData , targetBrowserDataCategory : targetBrowserDataCategory
    }
    callback(result);
  }).catch(error => {
    reject(error);
  });   
}

/**
 * 일자별 세션 값 가져오기 (날짜 선택)
 * 
 * @param  conn , search (startGathDtm , endGathDtm)
 * @return 선택한 날짜 기준으로 일자별 세션 값 
 */
export const getDailySessionData = async( conn , callback , reject , search ) => {
  await conn.get('/sys/visitor/dailySession', {
    params: {
      startGathDtm : kendo.toString( search.startGathDtm , 'yyyy-MM-dd' ) ,
      endGathDtm : kendo.toString( search.endGathDtm , 'yyyy-MM-dd')
    }
  }).then(response => {
    // 일자별 세션 값 가공
    const dailySessionChartData = [];
    const dailySessionChartCategory = [];
    if( typeof response.dailySessionDataList.rows !== "undefined" ){
      response.dailySessionDataList.rows.forEach(element => {
        dailySessionChartData.push({name : element[0] , y : Number(element[1]) })
        dailySessionChartCategory.push(element[0]);
      });
    }

    // 값 전달 
    const result = {
      dailySessionChartData : dailySessionChartData,
      dailySessionChartCategory : dailySessionChartCategory
    }

    callback(result);
  }).catch(error => {
    reject(error);
  });   
}
