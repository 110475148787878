import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const SmpDashBoardHourChart = ({chartData , styles}) => {
  console.log("chartData = ", chartData);


  // 차트 옵션
  let chartOptions = {
    chart: {
      type: "line",
      // zoomType: "xy",
      height: 270
      // backgroundColor: "#f7f9fa",
      // width: width !== null ? width : "100%",
    },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "원/kWh",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { 
      shared: true ,
      formatter: function() {
        // class 적용 시 className이 아닌 class 로 적용 시켜야한다.
        let msg = "";
        msg += '<span style="color:' + this.points[0].point.color + '">\u25CF</span>';
        msg += '<span> <b> ' + this.points[0].key + ' SMP 값 : </span><span>' + this.points[0].y + ' 원/kWh </b> </span>';
        return msg;
      }
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    plotOptions: {
      line: {
        // stacking: "normal",
        dataLabels: { 
          enabled: true,
          color : '#000000',
          style: { textShadow: false }
        }
      },
    },
    series: [
      {
        name : "시간당 SMP 값 ", 
        tooltip: {
          valueSuffix: " 원/kWh",
        },
        color: "#F08080",
        data: chartData,
      }
    ],
  };

  return (
    <div>
      <HighchartsReact Highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default SmpDashBoardHourChart;