import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat, roundNum } from '../../../com/dataUtil';
import Qna from '../../bbs/qna';

const DaedeokMonitorPowerChart = ({ styles, dayPowerList}) => {

    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });

    const options = {
    chart:{type:'areaspline', height:300, backgroundColor: "rgba( 255, 255, 255, 0.0 )"}
    
    , title: { text: null }
    , credits: { enabled: false }
    , tooltip: {
      shared: true
      , useHTML: true
      , valueSuffix: ' kWh'
      // , formatter : function() {
      //   return (`
      //     <div><b>${this.points[0].point.category}</b></div>

      //     <span style="color: ${this.points[0].point.series.color}">\u25CF</span>
      //     <span>${this.points[0].point.series.name} : <b>${this.points[0].y} kWh</b></span></br>

      //     <span style="color: ${this.points[1].point.series.color}">\u25CF</span>
      //     <span>${this.points[1].point.series.name} : <b>${this.points[1].y} kWh</b></span></br>

      //     <span style="color: ${this.points[2].point.series.color}">\u25CF</span>
      //     <span>${this.points[2].point.series.name} : <b>${this.points[2].y} kWh</b></span></br>
      //   `)
      // }
    }
    , legend: {
      padding: 0
      , itemStyle: { color: '#ffffff' }
      , itemHoverStyle: { color: '#ffffff' }
    }
    , xAxis: [
      {
        categories: dayPowerList.map(e => e.name)
        , crosshair: true
        , labels: {
          style: {
            color: "#ffffff",
            textOutline: "none"
          }

        }
      }
    ]
    , yAxis : [
      {
        title: false
        , labels: {
          style: {
            color: "#ffffff",
          }
        }
      }
    ]
    , series: [
      {
        name: "전체 설비"
        , data: dayPowerList.map(e => roundNum(e.asyncDayPower + e.syncDayPower, 3))
        , color: "#24CE38"
        // , marker: {
        //   enabled: false
        //   , states: {
        //     hover: {
        //       enabled: false
        //     }
        //   }
        // }
        , zIndex: 1
      },
      {
        name: "미연동 설비"
        , data: dayPowerList.map(e => e.asyncDayPower)
        , color: "#FF6464"
        // , marker: {
        //   enabled: false
        //   , states: {
        //     hover: {
        //       enabled: false
        //     }
        //   }
        // }
        , zIndex: 2
      },
      {
        name: "연동 설비"
        , data: dayPowerList.map(e => e.syncDayPower)
        , color: "#1DDFFF"
        // , marker: {
        //   enabled: false
        //   , states: {
        //     hover: {
        //       enabled: false
        //     }
        //   }
        // }
        , zIndex: 3
      }
    ]
    
  }

  return (
    <div>
      <HighchartsReact options={options}/> 
    </div>
  );
};

export default DaedeokMonitorPowerChart;