
import { ListView } from '@progress/kendo-react-listview';
// import { Pager } from '@progress/kendo-react-data-tools';
import InstallerItem from './installerItem';
// import { useState } from 'react';
// import styles from './installerItem.module.css';
// import { loadMessages } from "@progress/kendo-react-intl";
// import koMessage from "../../../components/bbs/ko-language.json";
// import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
// loadMessages(koMessage, "ko-KR");
// 좌측 설치자별 리스트뷰 부분
const InstallerList = ({ installerList, map }) => {
  // 페이징 변수
  // const [page , setPage] = useState({skip : 0, take : 10 });
  console.log("installer list", installerList, map);
  const list = installerList.map(item => ({...item, ...{map:map}}));
  console.log("installer ch ", list);
  return(
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="md-card-content small-padding">
        { installerList.length !== 0 ? (
          <ListView
            data = {list}
            item={InstallerItem} 
            style={{width: "100%",height: 650,overflowY: "auto"}}
          />
          )
          : <span className = "none-data">데이터가 존재하지 않습니다.</span>
        }
        </div>
      </div>
    </div>
  );
}

export default InstallerList;