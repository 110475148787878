import {useState, useEffect, useCallback} from 'react';
import { BeatLoader } from 'react-spinners'
import log from '../../../com/log';
import RegiLoraRight from './regiLora_right';
import styles from './regiLora.module.css';
import {getLora} from '../../../service/mngt/loraService';
import {getRtu} from '../../../service/mngt/rtuEnte/rtuService';
import { getUser, isRtuUser } from '../../../service/authService'
import {getMessage} from '../../../com/messageUtil';
import { parseDate } from '../../../com/dateUtil';
import { downloadExcel } from '../../../com/downloadExcel';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {Button} from '@progress/kendo-react-buttons'
import {Grid, GridColumn as Column, GridToolbar, GridNoRecords} from "@progress/kendo-react-grid";
// import kendo from '@progress/kendo-ui';

import GridColumnInputFilter from '../../com/gridFilter/gridColumnInputFilter';
import {LocalizationProvider, loadMessages} from "@progress/kendo-react-intl";
import koMessage from "../../../com/ko-language.json"


import './regiLora.css';
// import './regiLora.dk.css';
loadMessages(koMessage, "ko-KR");

const RegiLora = ({conn, qs}) => {

  const userData = getUser();
  // 로딩 스피너
  const [loading, setLoading] = useState(true);
  // 엑셀 로딩 스피너
  const [excelLoading, setExcelLoading] = useState(false);

  // 테이블 데이터
  const [dataSource, setDataSource] = useState({
    dataList : []
    , total : 0
  });

  // 로라 정보 값
  const [inputs, setInputs] = useState({  
    rtuEnteCode: '',
    loraDevid: '',
    appEUI: '',
    uKey: '',
    loraUKey: '',
    rtuEnteTerm: ''
  })

  // 페이징
  const [pageState, setPageState] = useState({
    skip: 0, 
    take: 10, 
    filter: {loraDevid : "", loraStatus : "", rtuEnteCode : ""},
    total: 0
  });

  // 엑셀 파일 이름
  // let [fileName, setFileNameExcel] = useState("");

  let [rtu, setRtu] = useState([])
  let [rtuList, setRtuList] = useState([])

  const [filterDropValue, setFilterDropValue] = useState({
    rtuEnte : { rtuEnteCode : "" , rtuEnteTerm : "전체" }
    , loraStatusData : { loraStatus : "" , loraStatusTerm : "전체" }
  });

  const [selectValue, setSelectValue] = useState({
    rtuEnte : { rtuEnteCode : "" , rtuEnteTerm : "전체" }
  });

  // function setFileName() {
  //     let template = "LoRa등록목록_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
  //     return template;
  // }

  function getLoraList() {
    getLora(conn, pageState)
    .then(response => {
      const {results, total} = response;
      setDataSource(dataSource => {
        const item = {...dataSource};
        item.dataList = results;
        item.total = total;
        return item;
      });
      setLoading(false);
    })
    .catch(err => {
        // log(`lora RegiLora err : ${err}`);
        alert(getMessage(err.errCode));
    });
  }

  function getRtuDataList() {
    if(userData.userTypeCode !== 2007){
    getRtu(conn)
    .then(response => {
        response.forEach(element => {
          element.rtuEnteTerm = element.rtuEnteTerm + "(업체코드 : " + element.rtuEnteCode + ")";
        })
        setRtuList( response )
    })
    .catch(err => {
        log(`lora getRtuList error : ${err}`);
        alert(getMessage(err.errCode));
    });
  }
  }

  function getRtuList() {
    if(userData.userTypeCode !== 2007){
    getRtu(conn)
    .then(response => {
        setRtu(response)
    })
    .catch(err => {
        log(`lora getRtuList error : ${err}`);
        alert(getMessage(err.errCode));
    });
  }
  }

  function loginRtu() {
      if(userData.userTypeCode === 2007){ 
        setSelectValue({
        ...selectValue,
        rtuEnte : {
            rtuEnteCode : userData.userRtuEnteCode,
            rtuEnteTerm : userData.userTerm + "(업체코드 : " + userData.userRtuEnteCode + ")"
        }
      })

      setInputs({
        ...inputs,
        rtuEnteCode : userData.userRtuEnteCode
      })

      setFilterDropValue({
        ...filterDropValue,
        rtuEnte : {
          rtuEnteCode : userData.userRtuEnteCode,
          rtuEnteTerm : userData.userTerm
        }
      });
    }
  }

  // 데이터 불러오기
  useEffect(() => {
    getLoraList();
    getRtuDataList();
    getRtuList();

    loginRtu();
    /* eslint-disable-next-line */
  }, []);

  // 그리드 페이징 처리
  const handlePageChange = useCallback( e => {
    const {take, skip} = e.page;
    pageState.skip = skip;
    pageState.take = take;
    getLoraList();
    /* eslint-disable-next-line */
  }, [dataSource, pageState]);


  // 그리드 필터링 처리
  const handleFilterChange = useCallback( e => {
    const {filter} = e;
    // 필터 값 설정
    if(filter !== null) {
      if( filter.filters[0].field === "loraStatusTerm" ){
        pageState.filter.loraStatus = filter.filters[0].value.loraStatus;
      }
      else if( filter.filters[0].field === "rtuEnteTerm" ){
        pageState.filter.rtuEnteCode = filter.filters[0].value.rtuEnteCode;
      }
      else if( filter.filters[0].field === "loraDevid" ){
        pageState.filter.loraDevid = filter.filters[0].value;
      } 
    }
    pageState.skip = 0;
    pageState.take = 10;
    getLoraList();
    /* eslint-disable-next-line */
  }, [dataSource, pageState]);

  // 페이징 이벤트
  // const pageChange = (event) => {
  //   setPageState((prevState) => ({
  //       ...prevState,
  //       skip: event.page.skip,
  //       take: event.page.take
  //   }));
  // };

  // 엑셀다운
  const handleExcelDown = useCallback( e => {
    setExcelLoading(true);
    e.preventDefault();

    const search = {
      loraDevid : pageState.filter.loraDevid , 
      loraStatus : pageState.filter.loraStatus , 
      rtuEnteCode :  pageState.filter.rtuEnteCode
    };

    const url = '/api/v2.2/iot/sk';
    const data = search;
    const fileName = 'LoRa_' + parseDate('yyyymmddhhmmss') + '.xlsx';
    downloadExcel(data, fileName, url, setExcelLoading);
    /* eslint-disable-next-line */
  }, []);

   // RTU업체 필터CELL 드롭다운
  const handleIotRtuSearch = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });

      setFilterDropValue( filterDropValue => {
        const item = {...filterDropValue};
        item.rtuEnte = e.value;
        return item;
      });
    }  

    return (
      <div className="k-filtercell">
        <DropDownList
            data={rtu}
            onChange={filterOnchage}
            defaultItem= {{ rtuEnteCode : "" , rtuEnteTerm : "전체" }}
            disabled = { isRtuUser() === true ? true : false }
            className = "CustomFilterDropDown"
            textField = "rtuEnteTerm" dataItemKey= "rtuEnteCode" name = "rtuEnte"
            value = {filterDropValue.rtuEnte}
        />
      </div>
    );
  }

  // LoRa 상태 필터CELL 드롭다운
  const handleIotLoraStats = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });

      setFilterDropValue( filterDropValue => {
        const item = {...filterDropValue};
        item.loraStatusData = e.value;
        return item;
      });
    }  

    return (
      <div className="k-filtercell">
        <DropDownList
            data={[ { loraStatus : 201 , loraStatusTerm : '등록완료' } , { loraStatus : 409 , loraStatusTerm : '기등록' } , { loraStatus : 404 , loraStatusTerm : '미개통 또는 조인 미실시' } , { loraStatus : 400 , loraStatusTerm : '실패' }]}
            onChange={filterOnchage}
            defaultItem= {{ loraStatus : "" , loraStatusTerm : "전체" }}
            className = "CustomFilterDropDown"
            textField = "loraStatusTerm" dataItemKey= "loraStatus" name = "loraStatusTerm"
            value = {filterDropValue.loraStatusData}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="loraDiv">
        <div className={["uk-grid regiLoraGrid", excelLoading ? styles.saveLoading : ""].join(' ')}>
          <div className="uk-width-medium-1-2">
            <h3 className="md-card-toolbar-heading-text">
              LoRa 목록
            </h3>
            <div className="md-card">
              <div className={loading ? styles.regiLoraG : ""}>
             
                <LocalizationProvider language="ko-KR">
                  <Grid 
                    className="regiLoRaGrid"
                    data={dataSource.dataList}
                    total = {dataSource.total}
                    skip = {pageState.skip}
                    take = {pageState.take}
                    pageable={{buttonCount: 5}}
                    filterable
                    onPageChange={handlePageChange}
                    onFilterChange={handleFilterChange}
                    style={{
                      height: "550px"
                    }}
                    onRowClick={(e) => {
                      setInputs({
                        ...inputs, 
                        loraDevid : e.dataItem.loraDevid,
                        rtuEnteCode : e.dataItem.rtuEnteCode,
                        rtuEnteTerm :  e.dataItem.rtuEnteTerm
                      })

                      setSelectValue({
                        ...selectValue,
                        rtuEnte : {
                          rtuEnteCode : e.dataItem.rtuEnteCode,
                          rtuEnteTerm : e.dataItem.rtuEnteTerm + "(업체코드 : " + e.dataItem.rtuEnteCode + ")"
                        }
                      })
                    }}
                  >
                       <GridToolbar>
                  <Button
                    title="excelSave"
                    className="excelBtn"
                    onClick={loading ? loadMessages : handleExcelDown}
                    icon="excel">
                      엑셀저장
                  </Button>
                </GridToolbar>
                    <Column
                      field="loraDevid"
                      editable={false}
                      title="LoRa ID"
                      width="300px"
                      filterCell={GridColumnInputFilter}
                    />
                    <Column
                      field="rtuEnteTerm"
                      title="RTU 업체"
                      width="300px"
                      filterCell={handleIotRtuSearch}
                    />
                    <Column
                      field="loraStatusTerm"
                      title='LoRa 상태'
                      width="250px"
                      filterCell={handleIotLoraStats}
                    />
                    <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
                  </Grid>
                </LocalizationProvider>
              </div>
              { loading && <p className={styles.regiLoraLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
            </div>
          </div>
          <RegiLoraRight conn={conn} rtuList={rtuList} inputs = {inputs} setInputs = {setInputs} getLoraList = {getLoraList} selectValue = {selectValue} setSelectValue = {setSelectValue} styles={styles} setPageState = {setPageState}/>
        </div>
        { excelLoading && <p className={styles.saveLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p> }
      </div>
    </div>
  );
}

export default RegiLora