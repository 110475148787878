import * as React from 'react';
import DaumPostcode from 'react-daum-postcode';
import { Window } from "@progress/kendo-react-dialogs";
import './kakaoPostcodeSearch.css'

const KakaoPostcodeSearch = ({ setInfo, setVisible, initialWidth, initialHeight, initialLeft, initialTop, isMobile }) => {
    /**
     * useState
     */
    const toggleDialog = () => {
        setVisible(false);
    };

    return (
        <div>
            <Window
                title={"주소 검색"}
                onClose={toggleDialog}
                initialHeight={initialHeight ? initialHeight : 560}
                initialWidth={initialWidth ? initialWidth : 500}
                initialLeft={initialLeft && initialLeft}
                initialTop={initialTop && initialTop}
                modal={true}
                resizable={false}
                draggable={true}
                doubleClickStageChange={false}
                className="userListWindow">
                <DaumPostcode
                    onComplete={setInfo}  // 값을 선택할 경우 실행되는 이벤트
                    autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                    style={{height : 470}}
                />
            </Window >
        </div >
    )
}

export default KakaoPostcodeSearch;