import React, { useState } from "react";

import { Refresh, Delete, SaveAlt } from "@material-ui/icons";
import kendo from "@progress/kendo-ui";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Upload } from "@progress/kendo-react-upload";
import { Window } from "@progress/kendo-react-dialogs";

import { downloadExcel } from "../../../../com/downloadExcel";
import { getToken } from "../../../../service/authService";
import { CODE_BIZ_CANCEL_MNGT } from "../../../../com/codeUtil";
import { addressFormatFixFuc } from "../commonFuc";
import { cancelReapp, deleteBiz } from "../../../../service/bizStat/com/cancelApiService";

import { deleteFileList, downloadFile } from "../../../../service/bizStat/com/bizFileSupportService";

const BizCancelMngtGrid = ({ conn, styles, getDs, search, dataList, setDataList, setExcelLoading, handlePageChange }) => {
  console.log('BizCancelMngtGrid');
  // let instlPlnnm = '';
  // let energyTypeCode = '';

  // 파일 업로드 윈도우 창 제어
  const [windowVisible, setWindowVisible] = useState(false);

  // 파일
  const [fileState, setFileState] = useState({
    fileList: [],
    currentFiles: [],
    docuCodeNo: "CM",
    energyTypeCode: "",
    instlPlnnm: "",
  });

  // 엑셀다운
  const handleExcelDown = e => {
    setExcelLoading(true);
    e.preventDefault();
    const url = "/api/v2.2/biz/cancel";

    const data = {
      rgnCode: search.rgn.rgnCode,
      bizYy: search.bizYyNo,
      instlTerm: search.instlTerm,
      energyTypeCode: search.energyTypeCode,
      bizSctCode: search.bizSctCode,
    };

    console.log("data", data);

    const fileName = "취소관리목록_" + kendo.toString(new Date(), "yyyyMMdd_hh") + ".xlsx";

    downloadExcel(data, fileName, url, setExcelLoading);
  };

  // 설치계획서 순번
  const convPlanListNoCell = props => {
    var record = 0;
    record = dataList.grid.total - props.dataIndex;

    return <td>{record}</td>;
  };

  // 설치계획서 에너지원
  const energyDateFormatCell = props => {
    var result = "";
    var data = props.dataItem;

    if (data.moniYn === "Y") {
      result = "모니터링고도화";
    } else {
      if (data.energyTypeCodeVal !== null) {
        result = data.energyTypeCodeVal + "( " + data.instCapa + " " + data.unit + " )";
      }
    }

    return <td>{result}</td>;
  };

  const dtmFormatCell = props => {
    var date = kendo.toString(new Date(props.dataItem.cancelDtm), "yyyy-MM-dd");
    return <td>{date}</td>;
  };

  // 첨부파일한 다운로드
  const dataFileCell = props => {
    var result = "";
    var data = props.dataItem;

    result = (
      <button
        style={{ color: "#1e88e5", border:'none', background:'transparent' }}
        name="cancel"
        id="CM"
        class={styles.kendoA}
        onClick={e => {
          const fileInfo = {
            instlPlnnm: data.instlPlnnm,
            bizDocuCodeNo: 38007,
            docuCodeNo: e.target.id,
            energyTypeCode: data.energyTypeCode,
            bizSctCode: search.bizSctCode,
          };

          downloadFile(fileInfo, e.target.outerText);
        }}>
        {data.fileId ? data.fileName : null}
      </button>
    );

    return <td>{result}</td>;
  };

  const onAddFile = e => {
    // console.log("onAdd e ", e, fileState);
    setFileState(state => {
      const item = { ...state };
      item.currentFiles = e.affectedFiles;
      item.instlPlnnm = fileState.instlPlnnm;
      item.energyTypeCode = fileState.energyTypeCode;
      return item;
    });
  };

  const onRemoveFile = e => {
    console.log("onRemove e", e, fileState);
    let removeFileIds = [];
    let updateFiles = [];
    fileState.fileList.forEach(f => {
      e.affectedFiles.forEach(v => {
        if (v.uid === f.uid && v.name === f.name)
          removeFileIds.push({
            instlPlnnm: f.instlPlnnm,
            bizDocuCodeNo: 38007,
            docuCodeNo: "CM",
            energyTypeCode: fileState.energyTypeCode,
          });
      });
      e.newState.forEach(n => {
        if (f.uid === n.uid && f.name === n.name) updateFiles.push(f);
      });
    });

    if (removeFileIds.length !== 0) {
      deleteFileList(conn, removeFileIds)
        .then(r => {
          kendo.alert("삭제되었습니다.");
          setFileState(state => {
            const item = { ...state };
            item.fileList = updateFiles;
            return item;
          });
        })
        .catch(e => {
          kendo.alert("삭제되지 않았습니다.");
        });
    }
  };

  const onStatusChangeFile = e => {
    // console.log("onStatusChange e", e, fileState);
    if (e.response?.response) {
      let list = [...fileState.fileList, ...e.response.response.data];
      setFileState(state => {
        const item = { ...state };
        item.fileList = list;
        item.currentFiles = e.response.response;
        return item;
      });

      if (e.response.response.status === 200) {
        setWindowVisible(!windowVisible);
        kendo.alert("업로드되었습니다.");

        getDs();
        // getConvFileList();
      }
    }
  };

  // // 파일 업로드 윈도우 창 제어
  const uploadFile = e => {
    fileState.instlPlnnm = e.instlPlnnm;
    fileState.energyTypeCode = e.energyTypeCode;

    // let fileIds = {
    //   instlPlnnm: e.instlPlnnm,
    //   bizDocuCodeNo: CODE_BIZ_CANCEL_MNGT,
    //   energyTypeCode: e.energyTypeCode,
    // };

    setWindowVisible(!windowVisible);
    // getConvFileName(conn, fileIds, 'cancel', e.energyTypeCode, getFileListResult, getFileListRejection);
  };

  // const getFileListResult = (result) => {
  //   // console.log("result", result);
  //   // console.log("selected", selected);

  //   if(result.length > 0) {
  //     setDataList((dataList) => {
  //       const item = { ...dataList };
  //       item.file = {};
  //       return item;
  //     });

  //     result.forEach((element) => {
  //       setDataList((dataList) => {
  //         const item = { ...dataList };
  //         item.file = element;
  //         return item;
  //       })
  //     })
  //   } else {
  //     setDataList((dataList) => {
  //       const item = { ...dataList };
  //       item.file = {};
  //       return item;
  //     });
  //   }

  //   getDs();
  // }

  // // rejection 함수
  // const getFileListRejection = (error) => {
  //   console.log("error = ", error);
  // }

  function deleteFile(e) {
    // console.log("e", e);
    // console.log('삭제');

    let removeFileIds = [
      {
        instlPlnnm: e.instlPlnnm,
        bizDocuCodeNo: CODE_BIZ_CANCEL_MNGT,
        docuCodeNo: "CM",
        energyTypeCode: e.energyTypeCode,
        bizSctCode: search.bizSctCode,
      },
    ];

    // console.log("removeFileIds", removeFileIds);

    kendo
      .confirm("해당 파일을 삭제 하시겠습니까?")
      .then(result => {
        deleteFileList(conn, removeFileIds)
          .then(r => {
            kendo.alert("삭제되었습니다.");

            getDs();

            // getConvFileList();
          })
          .catch(e => {
            kendo.alert("삭제되지 않았습니다.");
          });
      })
      .catch(e => {
        kendo.alert("삭제되지 않았습니다.");
      });
  }

  // 첨부파일 등록
  const dataFileSaveCell = props => {
    let result = "";
    const data = props.dataItem;

    // fileState.instlPlnnm = data.instlPlnnm;
    // fileState.energyTypeCode = data.energyTypeCode;

    if (data.fileId) {
      result = (
        <button
          className="redBg"
          name="cancel"
          id="CM"
          onClick={() => {
            deleteFile(data);
          }}>
          삭제
        </button>
      );
    } else {
      result = (
        <button
          className="btn_pls"
          name="cancel"
          id="CM"
          onClick={() => {
            uploadFile(data);
          }}>
          등록
        </button>
      );
    }

    // result =
    //   <button className= {data.fileId ?  "redBg" : "btn_pls"} name = "cancel" id="CM" onclick={data.fileId ?  deleteFile : uploadFile}>
    //     {data.fileId?  "삭제" : "등록"}
    //   </button>

    return <td>{result}</td>;
  };

  // 재신청, 삭제 버튼 정의
  const dataCell = props => {
    var result = "";
    var data = props.dataItem;

    // fileState.instlPlnnm = data.instlPlnnm;
    // fileState.energyTypeCode = data.energyTypeCode;
    result = (
      <td>
        <button
          className="rebtn_icon"
          onClick={() => {
            kendo
              .confirm(data.instlTerm + "의 재신청을 진행하시겠습니까?<br/> 재신청시 첨부파일 및 사유내용은 자동삭제 됩니다.<br/>")
              .then(e => {
                cancelReapp(conn, data, search).then(results => {
                  kendo.alert("재신청을 완료 하였습니다.");
                  getDs();
                });
              });
            // .catch(e => {
            //   console.log('취소');
            // })
          }}>
          <Refresh className="material-icons" style={{ fontSize: 23, color: "#000" }} />
          <em>재신청</em>
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          className="del_icon"
          onClick={() => {
            kendo.confirm("정말 삭제하시겠습니까?").then(e => {
              // console.log("data",data);
              deleteBiz(conn, data, search).then(results => {
                kendo.alert("삭제를 완료 하였습니다.");
                getDs();
              });
            });
          }}>
          <Delete className="material-icons" style={{ fontSize: 23, color: "#fff" }} />
          <em>삭제</em>
        </button>
      </td>
    );

    return result;
  };

  const saveFileUrl =
    `${process.env.REACT_APP_REST_API_VERSION}/biz/file/upload?fileInfo=` +
    encodeURI(JSON.stringify(fileState.currentFiles)) +
    "&instlPlnnm=" +
    fileState.instlPlnnm +
    "&bizDocuCodeNo=38007" +
    "&docuCodeNo=" +
    fileState.docuCodeNo +
    "&energyTypeCode=" +
    fileState.energyTypeCode + 
    "&bizSctCode=" +
    search.bizSctCode;

  const removeFileUrl = `${process.env.REACT_APP_REST_API_VERSION}/biz/file/delete`;

  // 상세 주소 포맷 설정
  const getAddressFormatDataCell = data => {
    // console.log("data", data);
    return addressFormatFixFuc(data, search);
  };

  return (
    <>
      <div class="uk-width-medium-1-1">
        <div class="md-card">
          <div class="md-card-content">
            <div class="md-card-toolbar-conv">
              <h3 class="md-card-toolbar-heading-text-left">
                취소관리목록
                {
                  <>
                    {" "}
                    <span style={{ fontSize: "13px", marginLeft: "5px" }}> [{search.navi}] </span>{" "}
                  </>
                }
              </h3>
              <div className={styles.convMdCardToolbar}>
                <button className="btn_icon" onClick={handleExcelDown}>
                  <SaveAlt className="material-icons" style={{ fontSize: 23, color: "#000" }} />
                  <em>엑셀 저장</em>
                </button>
              </div>
            </div>
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid
                  className="convListG"
                  style={{ height: "603px" }}
                  data={dataList.grid.dataList}
                  total={dataList.grid.total}
                  filterable={false}
                  sortable={false}
                  skip={search.cancelPaging.skip}
                  take={search.cancelPaging.take}
                  pageable={{ buttonCount: 5, pageSizes: true }}
                  onPageChange={handlePageChange}>
                  <GridColumn title="순번" field="no" cell={convPlanListNoCell} width={50} />
                  <GridColumn title="설치계획서 신청번호" field="instlPlnnm" width={170} />
                  <GridColumn title="신청자(건물)명" field="instlTerm" />
                  <GridColumn title="설치장소" field="instlAddr" cell={getAddressFormatDataCell} />
                  <GridColumn title="에너지원 / 용량" field="energyTypeCodeVal" cell={energyDateFormatCell} width={150} />
                  <GridColumn width={100} title="취소일자" field="cancelDtm" cell={dtmFormatCell} />
                  <GridColumn title="취소사유" field="cancelReason" />
                  <GridColumn width={250} title="첨부파일" cell={dataFileCell} />
                  <GridColumn width={85} title="선택" cell={dataFileSaveCell} />
                  <GridColumn width={230} cell={dataCell} />
                  <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </div>
      </div>
      {windowVisible && (
        <Window
          title={"파일 등록"}
          onClose={uploadFile}
          top={345}
          left={685}
          initialHeight={350}
          initialWidth={600}
          modal={true}
          resizable={false}
          doubleClickStageChange={false}
          draggable={false}>
          <div>
            {/* 윈도우 창 */}
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Upload
                  autoUpload={false}
                  batch={true}
                  multiple={false}
                  files={fileState.fileList}
                  defaultFiles={[]}
                  onAdd={onAddFile}
                  onRemove={onRemoveFile}
                  onStatusChange={onStatusChangeFile}
                  withCredentials={true}
                  removeVerb={"DELETE"}
                  restrictions={{
                    allowedExtensions: [".gif", ".jpg", ".png", ".hwp", ".doc", ".pdf", ".zip"],
                    maxFileSize: 51200000,
                  }}
                  saveMethod={"post"}
                  saveHeaders={{
                    "Content-Type": "multipart/form-data",
                    "X-AUTH-TOKEN": getToken(),
                  }}
                  saveUrl={saveFileUrl}
                  removeUrl={removeFileUrl}
                />
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </Window>
      )}
    </>
  );
};

export default BizCancelMngtGrid;
