// import React, { useState } from "react";

// import kendo from "@progress/kendo-ui";
// 켄도 윈도우
// import { Window } from "@progress/kendo-react-dialogs";
// import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
// import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
// import { Button } from "@progress/kendo-react-buttons";

// import { TextArea } from "@progress/kendo-react-inputs";
// import { getMessage } from "../../../../com/messageUtil";
// import { ProseMirror } from "@progress/kendo-react-editor";
// import DatePickerKo from "../../../com/dateInputs/datePickerKo";
// import { progressStat } from "../../../../service/bizStat/com/progressApiService";

const BizProgressMobileGrid = ({
  conn,
  search,
  getData,
  paging,
  dataList,
  setDataList,
  grid,
  setSearch,
  stylesProgress,
  isMobile,
  Grid,
  GridColumn,
  GridNoRecords,
  IntlProvider,
  LocalizationProvider,
}) => {
  // const nowTime = new Date();

  // let instMoni = "";

  // const [moninstep, setMoninstep] = useState({
  //   dtm: new Date(),
  //   instMoni: "",
  // });
  // const [dtm, setDtm] = useState(new Date());
  // 포기 윈도우 창
  // const [cancelWindowVisible, setCancelWindowVisible] = useState(false);

  // 날짜 변경 윈도우 창
  // const [instWindowVisible, setInstWindowVisible] = useState(false);

  // 포기 윈도우 창 제어
  // const cancelStat = e => {
  //   console.log("e", e);
  //   console.log("dataList", dataList);
  //   kendo.confirm("설치계획서 목록의 추진현황에 표시된 설치 정보가 삭제 됩니다. 포기를 진행할까요?").then(() => {
  //     setDataList(dataList => {
  //       const item = { ...dataList };
  //       item.instl = e;
  //       return item;
  //     });
  //     setCancelWindowVisible(!cancelWindowVisible);
  //   });
  // };

  // const cancelWindowStat = e => {
  //   setCancelWindowVisible(!cancelWindowVisible);
  // };

  // const instWindowStat = e => {
  //   setInstWindowVisible(!instWindowVisible);
  // };

  // const cancelSave = () => {
  //   dataList.instl.cancelYn = "Y";
  //   dataList.instl.cancelDtm = nowTime;

  //   if (dataList.instl.cancelReason !== null) {
  //     progressCancel(conn, dataList.instl).then(() => {
  //       kendo
  //         .confirm("설치계획서 목록의 추진현황에 표시된 설치 정보가 삭제 됩니다. 포기를 진행할까요?")
  //         .then(() => {
  //           kendo.alert("포기 되었습니다.");
  //           setCancelWindowVisible(!cancelWindowVisible);
  //           getData();
  //           return;
  //         })
  //         .catch(error => {
  //           if (error.errCode === "L007") {
  //             kendo.alert("해당 기능을 사용할 수 있는 권한이 없습니다. 사용중인 계정의 권한을 확인해주세요.");
  //             return;
  //           } else {
  //             kendo.alert("알수 없는 오류가 발생하였습니다. 관리자에게 문의해주세요.");
  //             return;
  //           }
  //         });
  //     });
  //   } else {
  //     kendo.alert("포기사유를 입력 해주세요.");
  //   }
  // };

  // const instStepSave = e => {
  //   if (moninstep.instMoni === "inst") {
  //     dataList.instl.meainstDtm = moninstep.dtm;
  //     dataList.instl.meainstDtm4 = moninstep.dtm;
  //   } else if (moninstep.instMoni === "moni") {
  //     dataList.instl.monitorInstDtm = moninstep.dtm;
  //   }

  //   progressStat(conn, dataList.instl, getInstStatResult, getInstStatRejection);
  // };

  // 텍스트 사유 변경
  // const onTextareaChange = e => {
  //   setDataList(dataList => {
  //     const item = { ...dataList };
  //     item.instl.cancelReason = e.target.value;
  //     return item;
  //   });
  // };

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const { take, skip } = e.page;
    paging.skip = skip;
    paging.take = take;
    getData();
  };

  // 설치계획서 순번
  // const convPlanListNoCell = props => {
  //   var record = 0;
  //   record = dataList.grid.total - props.dataIndex;

  //   return <td>{record}</td>;
  // };

  // const energyDateFormatCell = props => {
  //   var result = "";
  //   var data = props.dataItem;

  //   if (data.moniYn === "Y") {
  //     result = "모니터링고도화";
  //   } else {
  //     if (data.energyTypeCodeVal != null) {
  //       result = data.energyTypeCodeVal + "( " + data.instCapa + " )";
  //     } else {
  //       result = "설비 미등록";
  //     }
  //   }

  //   return <td>{result}</td>;
  // };

  // const meainDataFormatCell = props => {
  //   var result = "";
  //   var data = props.dataItem;
  //   const yn = "meainstYn" + props.className.slice(0, 1);
  //   const dtm = "meainstDtm" + props.className.slice(0, 1);

  //   if (data[yn] !== "Y") {
  //     if (data.moniYn == "N" && data.energyTypeCode !== null) {
  //       result = (
  //         <button
  //           className="blBg"
  //           onClick={() => {
  //             if (props.className === "4 ") {
  //               if (search.bizYyNo === "" || search.cityProvCode === "" || search.rgnCode === "") {
  //                 kendo.alert("사업연도, 시도, 시군구 를 선택해야 사업추진 진행사항을 확인할 수 있습니다");
  //               } else {
  //                 kendo
  //                   .confirm(
  //                     data.instlTerm + "의 [ " + data.energyTypeCodeVal + " ] 설비 " + props.className + "단계를 설치완료 하시겠습니까?",
  //                   )
  //                   .done(function () {
  //                     convInstComplete(data, "meain", yn, dtm);
  //                   })
  //                   .fail(function () {
  //                     console.log("취소");
  //                   });
  //               }
  //             } else {
  //               kendo
  //                 .confirm(
  //                   data.instlTerm + "의 [ " + data.energyTypeCodeVal + " ] 설비 " + props.className + "단계를 설치완료 하시겠습니까?",
  //                 )
  //                 .done(function () {
  //                   convInstComplete(data, "meain", yn, dtm);
  //                 })
  //                 .fail(function () {
  //                   console.log("취소");
  //                 });
  //             }
  //           }}>
  //           <img src="/images/check_false.png" alt="" />
  //         </button>
  //       );
  //     } else if (data.moniYn === "Y" || (data.moniYn === "N" && data.energyTypeCode === null)) {
  //       result = (
  //         <button className="blBgDis" disabled>
  //           <img src="/images/check_disable.png" alt="" />
  //         </button>
  //       );
  //     }
  //   } else {
  //     if (props.className === "4 " && data.meainstYn1 === "Y" && data.meainstYn2 === "Y" && data.meainstYn3 === "Y" && data.meainstYn4 === "Y") {
  //       // setMoninstep(moninstep => {
  //       //   const item = {...moninstep};
  //       //   item.instMoni = "inst";
  //       //   return item;
  //       // });
  //       result = (
  //         <button
  //           className="blBg"
  //           onClick={() => {
  //             stepClick(data, "inst");
  //           }}>
  //           <img src="/images/check_true.png" alt="" />
  //         </button>
  //       );
  //     } else {
  //       result = (
  //         <span className="blBgText">
  //           <img src="/images/check_true.png" alt="" />
  //         </span>
  //       );
  //     }
  //   }

  //   return <td>{result}</td>;
  // };

  // const stepClick = (props, inmo) => {
  //   moninstep.instMoni = inmo;
  //   console.log("instMoni", moninstep.instMoni);
  //   setDataList(dataList => {
  //     const item = { ...dataList };
  //     item.instl = props;
  //     return item;
  //   });

  //   if (moninstep.instMoni === "inst") {
  //     moninstep.instMoni = "inst";
  //     setMoninstep(moninstep => {
  //       const item = { ...moninstep };
  //       item.dtm = new Date(props.meainstDtm4);
  //       return item;
  //     });
  //   } else {
  //     moninstep.instMoni = "moni";
  //     if (props.monitorInstDtm == null) {
  //       setMoninstep(moninstep => {
  //         const item = { ...moninstep };
  //         item.dtm = nowTime;
  //         return item;
  //       });
  //     } else {
  //       setMoninstep(moninstep => {
  //         const item = { ...moninstep };
  //         item.dtm = new Date(props.monitorInstDtm);
  //         return item;
  //       });
  //     }
  //   }
  //   setInstWindowVisible(!instWindowVisible);
  // };

  // const monitorDataFormatCell = props => {
  //   var result = "";
  //   var data = props.dataItem;

  //   if (data.monitorInstYn != "Y") {
  //     if (data.moniYn == "N" && data.energyTypeCode == null) {
  //       result = (
  //         <button className="blBgDis" disabled>
  //           <img src="/images/check_disable.png" alt="" />
  //         </button>
  //       );
  //     } else if (data.moniYn == "Y") {
  //       data.energyTypeCode = 39000;
  //       result = (
  //         <button
  //           className="blBg"
  //           onClick={() => {
  //             kendo
  //               .confirm(data.instlTerm + "의 모니터링을 설치완료 하시겠습니까?")
  //               .done(function () {
  //                 convInstComplete(data, "monitor");
  //               })
  //               .fail(function () {
  //                 console.log("취소");
  //               });
  //           }}>
  //           <img src="/images/check_false.png" alt="" />
  //         </button>
  //       );
  //     } else {
  //       result = (
  //         <button
  //           className="blBg"
  //           onClick={() => {
  //             kendo
  //               .confirm(data.instlTerm + "의 [ " + data.energyTypeCodeVal + " ] 모니터링을 설치완료 하시겠습니까?")
  //               .done(function () {
  //                 convInstComplete(data, "monitor");
  //               })
  //               .fail(function () {
  //                 console.log("취소");
  //               });
  //           }}>
  //           <img src="/images/check_false.png" alt="" />
  //         </button>
  //       );
  //     }
  //   } else {
  //     // setMoninstep(moninstep => {
  //     //   const item = {...moninstep};
  //     //   item.instMoni = "moni";
  //     //   return item;
  //     // });
  //     result = (
  //       <button
  //         className="blBg"
  //         onClick={() => {
  //           stepClick(data, "moni");
  //         }}>
  //         <img src="/images/check_true.png" alt="" />
  //       </button>
  //     );
  //   }

  //   return <td>{result}</td>;
  // };

  // const cancelDataFormatCell = props => {
  //   var result = "";
  //   var data = props.dataItem;

  //   if (data.moniYn == "N" && data.energyTypeCode == null) {
  //     result = (
  //       <button className="redBgDis ModalBtn_A" disabled>
  //         포기
  //       </button>
  //     );
  //   } else {
  //     result = (
  //       <button
  //         className="redBg ModalBtn_A"
  //         onClick={() => {
  //           cancelStat(data);
  //         }}>
  //         포기
  //       </button>
  //     );
  //   }

  //   return <td>{result}</td>;
  // };

  // const convInstComplete = (props, inst, yn, dtm) => {
  //   if (inst == "meain") {
  //     props[yn] = "Y";
  //     props[dtm] = nowTime;
  //   } else {
  //     props.monitorInstYn = "Y";
  //     props.monitorInstDtm = nowTime;
  //   }

  //   if (props.meainstYn1 == "Y" && props.meainstYn2 == "Y" && props.meainstYn3 == "Y" && props.meainstYn4 == "Y") {
  //     props.meainstYn = "Y";
  //     props.meainstDtm = nowTime;
  //   }

  //   progressStat(conn, props, search, getInstStatResult, getInstStatRejection);
  // };

  // const getInstStatResult = result => {
  //   if (instWindowVisible == false) {
  //     kendo.alert("설치완료 되었습니다.");
  //   } else {
  //     kendo.alert("설치 날짜가 변경 되었습니다.");
  //     setInstWindowVisible(!instWindowVisible);
  //   }

  //   getData();
  // };

  // const getInstStatRejection = error => {
  //   kendo.alert("설치 완료를 실패하였습니다. 관리자에게 문의해주세요.");
  //   console.log("error = ", error);
  // };

  // 로우 클릭 이벤트
  const onRowClick = item => {
    const data = item.dataItem;
    // console.log("item",item);
    search.instlPlnnm = data.instlPlnnm;
    search.convPro.energyTypeCode = data.energyTypeCode;

    // filterInitialList();
    setDataList(dataList => {
      const item = { ...dataList };
      item.instl = data;
      return item;
    });

    // // 탭별로 데이터 불러오기
    // getSelectMeainInfoOrAsHistByTab();

    // 설비 정보 불러오기
    // getMngtMeainInfoData();
  };

  // const getImgPross = () => {
  //   setDataList(dataList => {
  //     const item = { ...dataList };
  //     item.imgFile.step1 = [];
  //     item.imgFile.step2 = [];
  //     item.imgFile.step3 = [];
  //     item.imgFile.step4 = [];
  //     item.imgFile.moni = [];
  //     return item;
  //   });
  // };

  // const getImgResult = result => {
  //   console.log("result", result);
  //   result.forEach(element => {
  //     let name = element.instStep;
  //     let reader = new FileReader();
  //     console.log("reader", reader);
  //     reader.onload = function () {
  //       // setImageUrl({ result: reader.result });
  //       console.log("reader", reader);
  //     };
  //     // reader.readAsDataURL(element);

  //     setDataList(dataList => {
  //       const item = { ...dataList };
  //       item.imgFile[name] = element;
  //       return item;
  //     });
  //   });
  // };

  // const getImgRejection = error => {
  //   kendo.alert("단계별 사진 불러오기를 실패하였습니다. 관리자에게 문의해주세요.");
  //   console.log("error = ", error);
  // };

  return (
    <div class="uk-width-medium-3-5">
      <div class="md-card">
        <div class="md-card-content">
          <div class="md-card-toolbar-conv">
            <h3 class="md-card-toolbar-heading-text-left">설치계획서 목록</h3>
          </div>
          {/* <div class="eer-Table-scr" > */}
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className="convListG"
                style={{ height: "664px" }}
                // {loading ? styles.iotKtLogG : styles.iotktGrid}
                selectedField="selected"
                onRowClick={onRowClick}
                // data={grid.data}
                data={grid.data.map(item => ({
                  ...item,
                  selected: item.instlPlnnm === search.instlPlnnm && item.energyTypeCode === search.convPro.energyTypeCode,
                }))}
                total={grid.total}
                filterable={false}
                sortable={false}
                skip={paging.skip}
                take={paging.take}
                pageable
                onPageChange={handlePageChange}>
                <GridColumn title="신청자" field="instlTerm" />
                <GridColumn title="설치장소" field="instlAddr" width={210} />
                <GridColumn title="에너지원" field="energyTypeCodeVal" width={100} />
                <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default BizProgressMobileGrid;
