import { React, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Header from "./components/header";
import MenuNavigator from "./components/menuNavigator";
import Error from "./components/error";
import Ready from "./components/ready";
import MenuRole from "./components/menu/menuRole/menuRole";
import InstallerGath from "./components/monitor/installerGath/installerGath";
import InstallerGathMngt from "./components/monitor/installerGathMngt/installerGath/installerGath";
import InstallerMeain from "./components/monitor/installerMeain/installerMeain";
import AreaEnergy from "./components/monitor/area/areaEnergy";
import EventLog from "./components/mngt/eventLog/eventLog.jsx";
import CodeMngt from "./components/code/codeMngt.jsx";
import Manager from "./components/mngt/manager/manager.jsx";
import Meain from "./components/meain/meain/meain";
import MenuApi from "./components/menu/menuApi/menuApi";
import MenuMngt from "./components/menu/menuMngt";
import Noti from "./components/bbs/noti";
import OperationStat from "./components/monitor/operation/operationStat";
import GathRawDataLog from "./components/stats/operHist/gathRawDataLog";
import RegiLora from "./components/meain/LoRa/regiLora";
import RegiLoraLog from "./components/meain/LoRaLog/regiLoraLog";
import Qna from "./components/bbs/qna";
import CmpEnso from "./components/stats/type/enso/cmpEnso";
import Installer from "./components/mngt/installer/installer";
import Iotkt from "./components/meain/iotMakers/iotkt";
import IotktLog from "./components/meain/iotMakersLog/iotktLog";
import CmpArea from "./components/stats/type/area/cmpArea";
import GathStatus from "./components/analy/gathStatus/gathStatus";
import UserInfo from "./components/mngt/userInfo/userInfo";
import RtuMngt from "./components/mngt/rtuEnte/rtuMngt";
import ReportDay from "./components/report/total/reportDay";
import WeathCmp from "./components/analy/weathCmp/weathCmp";
import LocationDepth3 from "./components/location/depth3/locationDepth3";
import Sys from "./components/sys/sys/sys";
import SysServer from "./components/sys/sysServer/sysServer";
import BatchProcHist from "./components/sys/batchProcHist/batchProcHist";
import Enso from "./components/stats/event/enso/enso";
import Consm from "./components/stats/event/consm/consm";
import Makr from "./components/stats/event/makr/makr";
import ReportConsm from "./components/report/consm/reportConsm";
import CmpUserByUser from "./components/stats/type/userByUser/cmpUserByUser";
import UserBetweenUser from "./components/stats/type/userBetweenUser/userBetweenUser";
import NonOperHist from "./components/stats/nonOperHist/nonOperHist";
import VisitorStatistics from "./components/sys/visitorStatistics/visitorStatistics";
import StatsByConsm from "./components/stats/type/consm/statsByConsm";

import queryString from "query-string";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { isLogin } from "./service/authService";
import MakrMngt from "./components/meain/makr/makrMngt";
import MeainModel from "./components/meain/meainModel/meainModel";
import GroupInstaller from "./components/mngt/groupInstaller/groupInstaller";
import GroupManager from "./components/mngt/groupManager/groupManager";
import ExpctConsm from "./components/stats/expct/consm/expctConsm";
import ExpctBuildBsman from "./components/stats/expct/buildBsman/expctBuildBsman";
import HmpgAccsLog from "./components/mngt/hmpgAccsLog/hmpgAccsLog";
import PageInfo from "./pageInfo";
import ConvergenceInfo from "./components/business/convergenceInfo";
import HouseInfo from "./components/business/houseInfo";
import BuildingInfo from "./components/business/buildingInfo";
import AreaInfo from "./components/business/areaInfo";
import PowerStationInfo from "./components/business/powerStationInfo";
import EtcInfo from "./components/business/etcInfo";
import SmpHist from "./components/stats/smpHist/smpHist";
import PostPower from "./components/ai/postPower/postPower";
import OptomalAzimIncl from "./components/ai/optimalAzimIncl/optomalAzimIncl";
import RgnMakrEfficiency from "./components/ai/rgnMakrEfficiency/rgnMakrEfficiency";
import SolarPred from "./components/ai/troubleShooting/solarPred";
import SolarModuleMngt from "./components/ai/troubleShooting/solarModuleMngt";
import Munic from "./components/monitor/municipality/munic";
import LocationMunic from "./components/location/municipality/locationMunic";
import SttstMngt from "./components/meain/sttstMngt/sttstMngt";
import MenuRoleArea from "./components/menu/menuRoleArea/menuRoleArea";
import TroubleShooting from "./components/ai/troubleShooting/troubleShooting";
import DaedeokMainPage from "./components/localArea/daedeok/munic/daedeokMainPage";
import DaeDeokHeader from "./components/localArea/daedeok/munic/daedeokHeader";

function Contents({
  codeList,
  cityList,
  rgnList,
  dongList,
  conn,
  loginStatus,
  setUpdateLoginStatus,
  setError,
  getRoleMenu,
  menuState,
  setSelectMenu,
  roleMenu,
  isMobile,
}) {
  const location = useLocation();
  if (location.pathname === "/login")
    // document.body.style.backgroundColor = "#01579B";
    document.body.style.background = "linear-gradient(#1F93C8, #1054B1)";
  else document.body.style.background = "#f5f5f5";
  // if (location.pathname == "/login") document.body.style.backgroundColor = "#1E1F21";
  // else document.body.style.backgroundColor = "#1E1F21";

  const history = useHistory();
  if (location.pathname === "/") {
    history.push("/monitor/areaEnergy");
  }

  const setContentLayoutMargin = url => {
    const page_content = document.getElementById("page_content");
    const page_content_inner = document.getElementById("page_content_inner");
    if (isMobile) {
      page_content_inner.style.padding = "0px 0px 50px 0px";
    } else {
      if (url === "/location/thridDepth" || url === "/location/firstDepth" || url === "/location/munic" || url === "/monitor/munic" 
      || url === "/monitor/localAreaMonitor") {
        page_content.style.margin = "12px 0px 0px 0px";
        page_content_inner.style.padding = "10px 0px 0px 0px";
      } else {
        page_content.style.margin = "40px 0 0px 0"; // bottom 마진 40px 으로 인해서 하단이 위로 이동하여 하얀배경이 보이는 현상으로 margin 0으로 처리
        page_content_inner.style.padding = "10px 24px 24px 24px";
      }
    }
  };

  // history.action = 뒤로가기 시에도 setCotentLayoutMargin 사용하게 함
  useEffect(() => {
    setContentLayoutMargin(location.pathname);
    if (!isLogin()) {
      // 로그인 여부 확인
      setUpdateLoginStatus(false);
      history.push("/");
    }
  // eslint-disable-next-line
  }, [history.action]);
  // console.log("roleMenu", roleMenu);

  return (
    <div id="app">
      <Header
        conn={conn}
        loginStatus={loginStatus}
        roleMenu={roleMenu}
        menuState={menuState}
        setSelectMenu={setSelectMenu}
        getRoleMenu={getRoleMenu}
        setUpdateLoginStatus={setUpdateLoginStatus}
        rgnList={rgnList}
        setContentLayoutMargin={setContentLayoutMargin}
      />
      <MenuNavigator
        roleMenu={roleMenu}
        getRoleMenu={getRoleMenu}
        setSelectMenu={setSelectMenu}
        setContentLayoutMargin={setContentLayoutMargin}>
        <Switch>
          <Route exact path="/error" render={() => <Error />} />
          {/* <Route
            exact
            path="/login"
            render={() => <Login getRoleMenu={getRoleMenu} conn={conn} setUpdateLoginStatus={setUpdateLoginStatus} loginStatus={loginStatus} />}
          /> */}
          <Route
            exact
            path="/monitor/areaEnergy"
            render={() => <AreaEnergy conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} setError={setError} isMobile={isMobile} />}
          />
          <Route exact path="/monitor/operationStat" render={() => <OperationStat conn={conn} />} />
          <Route
            exact
            path="/monitor/installerMeain"
            render={() => (
              <InstallerMeain conn={conn} qs={queryString} codeList={codeList} cityList={cityList} rgnList={rgnList} isMobile={isMobile} />
            )}
          />
          <Route
            exact
            path="/monitor/installerGath"
            render={() => <InstallerGath conn={conn} codeList={codeList} rgnList={rgnList} isMobile={isMobile} />}
          />
          <Route
            exact
            path="/monitor/installerGath/mngt"
            render={() => <InstallerGathMngt conn={conn} codeList={codeList} rgnList={rgnList} isMobile={isMobile} />}
          />
          <Route
            exact
            path="/monitor/munic"
            render={() => <Munic conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} dongList={dongList} />}
          />
          {/* <Route exact path="/location/firstDepth" render={() => <CmpArea conn={conn}  codeList={codeList}  rgnList={rgnList} />} /> */}
          <Route
            exact
            path="/location/firstDepth"
            render={() => (
              <LocationDepth3
                conn={conn}
                codeList={codeList}
                rgnList={rgnList}
                dongList={dongList}
                qs={queryString}
                setContentLayoutMargin={setContentLayoutMargin}
              />
            )}
            // render={() => <LocationDepth1 conn={conn} codeList={codeList} rgnList={rgnList} dongList={dongList} />}
          />
          {/* <Route exact path="/location/secondDepth" render={() => <LocationDepth2 conn={conn}  codeList={codeList}/>} /> */}
          <Route
            exact
            path="/location/thridDepth"
            render={() => <LocationDepth3 conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} qs={queryString} />}
          />
          <Route
            exact
            path="/location/munic"
            render={() => (
              <LocationMunic
                conn={conn}
                codeList={codeList}
                cityList={cityList}
                rgnList={rgnList}
                qs={queryString}
                setContentLayoutMargin={setContentLayoutMargin}
              />
            )}
          />

          <Route
            exact
            path="/bizStat/convergenceInfo"
            render={() => <ConvergenceInfo conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} isMobile={isMobile} />}
          />
          <Route
            exact
            path="/bizStat/houseInfo"
            render={() => <HouseInfo conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />
          <Route
            exact
            path="/bizStat/buildingInfo"
            render={() => <BuildingInfo conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />
          <Route
            exact
            path="/bizStat/areaInfo"
            render={() => <AreaInfo conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />
          <Route
            exact
            path="/bizStat/powerStationInfo"
            render={() => <PowerStationInfo conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />
          <Route
            exact
            path="/bizStat/etcInfo"
            render={() => <EtcInfo conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />

          <Route exact path="/report" render={() => <ReportDay conn={conn} codeList={codeList} rgnList={rgnList} />} />
          <Route exact path="/report/consm" render={() => <ReportConsm conn={conn} cityList={cityList} rgnList={rgnList} />} />

          <Route
            exact
            path="/stats/compare/area"
            render={() => <CmpArea conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />
          <Route exact path="/stats/compare/enso" render={() => <CmpEnso conn={conn} codeList={codeList} />} />
          <Route
            exact
            path="/stats/compare/userbyuser"
            render={() => <CmpUserByUser conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} isMobile={isMobile} />}
          />
          <Route
            exact
            path="/stats/compare/userBetweenUser"
            render={() => <UserBetweenUser conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />
          <Route exact path="/stats/compare/event" render={() => <Ready />} />
          <Route
            exact
            path="/stats/compare/weather"
            render={() => <WeathCmp conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />
          <Route exact path="/stats/raw/gath" render={() => <GathStatus conn={conn} codeList={codeList} />} />

          <Route exact path="/stats/expct/consm" render={() => <ExpctConsm conn={conn} cityList={cityList} rgnList={rgnList} />} />
          <Route
            exact
            path="/stats/expct/buildBsman"
            render={() => <ExpctBuildBsman conn={conn} cityList={cityList} rgnList={rgnList} />}
          />

          <Route
            exact
            path="/stats/consm"
            render={() => <StatsByConsm conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />
          <Route
            exact
            path="/stats/nonOperHist/nonOperHist"
            render={() => <NonOperHist conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />

          <Route
            exact
            path="/stats/event/consm/consm"
            render={() => <Consm conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />}
          />
          <Route
            exact
            path="/stats/event/makr/makr"
            render={() => <Makr conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} qs={queryString} />}
          />

          <Route exact path="/stats/event/enso" render={() => <Enso conn={conn} codeList={codeList} />} />

          <Route exact path="/stats/smp/smpHist" render={() => <SmpHist conn={conn} codeList={codeList} />} />

          <Route
            exact
            path="/analy/raw/log"
            render={() => <GathRawDataLog conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} isMobile={isMobile} />}
          />

          <Route exact path="/board/notification" render={() => <Noti conn={conn} isMobile={isMobile} />} />
          <Route exact path="/board/qna" render={() => <Qna conn={conn} />} />
          <Route exact path="/board/archive" render={() => <Ready />} />

          <Route exact path="/monitor/server" render={() => <Ready />} />

          <Route exact path="/sys/sys" render={() => <Sys conn={conn} codeList={codeList} />} />
          <Route exact path="/sys/sysServer" render={() => <SysServer conn={conn} codeList={codeList} />} />
          <Route exact path="/sys/batchProcHist" render={() => <BatchProcHist conn={conn} codeList={codeList} />} />
          <Route exact path="/sys/visitor" render={() => <VisitorStatistics conn={conn} codeList={codeList} />} />

          <Route exact path="/mngt/code" render={() => <CodeMngt conn={conn} />} />
          <Route exact path="/mngt/user/installer" render={() => <Installer conn={conn} codeList={codeList} dongList={dongList} />} />
          <Route exact path="/user/manager" render={() => <Manager conn={conn} cityList={cityList} rgnList={rgnList} />} />
          <Route exact path="/mngt/user/groupManager" render={() => <GroupManager conn={conn} cityList={cityList} rgnList={rgnList} />} />
          <Route exact path="/mngt/rtu" render={() => <RtuMngt conn={conn} codeList={codeList} rgnList={rgnList} />} />

          <Route exact path="/mngt/meain" render={() => <Meain conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} />} />
          <Route exact path="/mngt/meain/iot/sk" render={() => <RegiLora conn={conn} qs={queryString} />} />
          <Route exact path="/mngt/meain/iot/sk/log" render={() => <RegiLoraLog conn={conn} />} />
          <Route exact path="/mngt/meain/iot/kt" render={() => <Iotkt conn={conn} />} />
          <Route exact path="/mngt/meain/iot/kt/log" render={() => <IotktLog conn={conn} />} />
          <Route exact path="/mngt/meain/makrMngt" render={() => <MakrMngt conn={conn} codeList={codeList} />} />
          <Route exact path="/mngt/meain/modelMngt" render={() => <MeainModel conn={conn} codeList={codeList} />} />
          <Route
            exact
            path="/mngt/meain/sttstMngt"
            render={() => <SttstMngt conn={conn} codeList={codeList} dongList={dongList} rgnList={rgnList} cityList={cityList} />}
          />

          <Route exact path="/mngt/menu" render={() => <MenuMngt conn={conn} />} />
          <Route exact path="/mngt/menu/role" render={() => <MenuRole conn={conn} codeList={codeList} />} />
          <Route exact path="/mngt/menu/api" render={() => <MenuApi conn={conn} />} />
          <Route exact path="/mngt/menu/roleArea" render={() => <MenuRoleArea conn={conn} />} />

          {/* // 로그인 시 loginInfo에 로그인한 ID 로 적용 */}
          <Route exact path="/mngt/user" render={() => <UserInfo conn={conn} />} />
          <Route
            exact
            path="/mngt/analy/event/log"
            render={() => (
              <EventLog conn={conn} codeList={codeList} cityList={cityList} rgnList={rgnList} qs={queryString} isMobile={isMobile} />
            )}
          />

          <Route exact path="/mngt/user/hmpgAccsLog" render={() => <HmpgAccsLog conn={conn} />} />

          {/* Ready 페이지 라우트 추가 */}
          <Route exact path="/ready" render={() => <Ready />} />

          <Route exact path="/mngt/group/installer" render={() => <GroupInstaller conn={conn} codeList={codeList} rgnList={rgnList} />} />
          {/* AI 메뉴 */}
          <Route exact path="/ai/troubleShooting" render={() => <SolarPred conn={conn} />} />
          <Route exact path="/ai/troubleShooting/curvefit" render={() => <SolarModuleMngt conn={conn} />} />
          <Route 
            exact path="/ai/troubleShootingJeongSeon" 
            render={() => <TroubleShooting conn={conn} dongList={dongList}/>} 
          />
          <Route exact path="/ai/postPower" render={() => <PostPower conn={conn} cityList={cityList} />} />
          <Route exact path="/ai/optomalAzimIncl" render={() => <OptomalAzimIncl conn={conn} codeList={codeList} rgnList={rgnList} />} />
          <Route exact path="/ai/rgnMakrEfficiency" render={() => <RgnMakrEfficiency conn={conn} />} />

        </Switch>
        <PageInfo conn={conn} />
      </MenuNavigator>
    </div>
  );
}

export default Contents;
