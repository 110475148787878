
import { Button } from '@progress/kendo-react-buttons';
import React from 'react';

const LocalAreaRightTitle = ({styles , clickBtnEvt}) => {
  return (
    <div class={styles.rightTitle}>
      <img className={styles.rightTitleImg} src="/images/localareaImg/42770/iconBar.png" alt="타이틀바"/><span class={styles.rightTitleText}>상세정보</span> 
      <span><Button className={styles.searchBtn} onClick={clickBtnEvt}>초기화</Button></span>
    </div>
  );
};

export default LocalAreaRightTitle;