import React, { useState, useRef, useEffect } from "react";

import { geoComponents } from "../../../monitor/municipality_v2/geoDir/geoDatas";

// import { initMapItem } from "../../../monitor/municipality/municData";
import JeongSeonMunicMapTable from "./jeongseonMunicMapTable";

import { getDongRankPer } from "../../../../service/location/locationMunicService";

// import { kendo } from "@progress/kendo-ui";
import kendo from "@progress/kendo-ui";

import { getMessage } from "../../../../com/messageUtil";
import LocalAreaMonitorMapTable from "../../../monitor/municipality_v2/localAreaMonitorMapTable";
import LocalAreaMonitorMapDepthTable from "../../../monitor/municipality_v2/localAreaMonitorMapDepthTable";
import LocalAreaGeoGradient from "../../../monitor/municipality_v2/localAreaGeoGradient";

const JeongSeonMunicMap = props => {
  const { dataSource, mapItem, search, setSearch, styles , CountUp , getData } = props;

  console.log("search = ", search);

  // const curDongData = dongList.filter(e => e.rgnCode.substring(0, 4) === (search.rgn.rgnCode + "").substring(0, 4));
  const [rgnDdlData, /*setRgnDdlData*/] = useState([
    {
      rgnCode: "42770",
      rgnTerm: "정선군",
    }
  ]);
  // 마우스 오버 시 보여주는 테이블(infoBo)
  const depthBoxRef = useRef(null);
  const [backupColor, setBackupColor] = useState("#DCDCDC");
  //고정된 테이블 정보들
  // const [fixMapItem, setFixMapItem] = useState(initMapItem);
  const [fixMapState, setFixMapState] = useState({
    interval: "",
  });
  const [loading, setLoading] = useState(false);

  //interval 스테이트
  const [intvlState, setIntvlState] = useState({ cnt: 0, currCode: "", visible: false });
  const [dongColor, setDongColor] = useState([]);

  // 동 데이터 리스트
  const dongDataList = dataSource.dongDataList;

  /**
   * mapItem 세팅 함수
   * @param {*} setFunc
   * @param {*} targetAreaId
   */
  const chngMapItem = (setFunc, targetAreaId) => {
    if (dataSource.realSolaDongData.length !== 0) {
      const data = dataSource.realSolaDongData.find(e => e.fixbylawBundCode === targetAreaId);
      if (data) {
        setFunc(dataSource => {
          const item = { ...dataSource };
          item.fixbylawBundTerm = data.fixbylawBundTermS;
          item.syncCount = data.syncCount;
          item.asyncCount = data.asyncCount;
          item.syncInstCapa = data.syncInstCapa;
          item.asyncInstCapa = data.asyncInstCapa;
          item.syncDayPower = data.syncDayPower;
          item.asyncDayPower = data.asyncDayPower;
          return item;
        });
      }
    }
  };


  /*  지도에 마우스 오버 이벤트 -> 테이블 보여주기, 오버 맵 색상 변경  */
  const mapOverEvt = e => {
    const { target } = e;
    // 읍면동 코드 추출
    const dongCode = target.id.replace( "dong_" , '' );
    // 테이블 오버 이벤트 연결
    overEvt(dongCode);
  };

  /** 테이블 오버 했을 떄 이벤트 */
  const boxOverEvt = (dongCode) => {
    overEvt(dongCode);
  }

  /**
   * 지도, 테이블 마우스 오버 시 이벤트 처리
   * @param {읍면동 코드} dongCode 
   */
  const overEvt = (dongCode) => {
    // 테이블 색상 칠하기
    const targetTable = document.getElementById("tb_" + dongCode);
    targetTable.classList.remove('infoBoxLocalArea');
    targetTable.classList.add('infoBoxLocalAreaOver');

    // 테이블 글씨 설정
    const targetTextCnt = document.getElementById("info_text_cnt_" + dongCode);
    const targetTextPower = document.getElementById("info_text_power_" + dongCode);
    const targetTextTitle = document.getElementById("info_title_" + dongCode);
    targetTextCnt.style.color = "#ffffff";
    targetTextPower.style.color = "#ffffff";
    targetTextTitle.style.color = "#6D4812";


    // 지도 색상 칠하기
    const targetMap = document.getElementById("dong_" + dongCode);
    // 원래 색칠한 색상을 백업한다.
    setBackupColor(targetMap.style.fill);
    // 지도에 오버 색상을 칠한다.
    targetMap.style.fill = "url(#overGra)";

    // 지도 글씨 설정
    const mapText = document.getElementById("mapText_" + dongCode);
    mapText.style.fill = "#e1e1e1";

    // 동코드 저장
    // setSearch( search => {
    //   const item = {...search};
    //   item.dongCode = dongCode;
    //   return item;
    // } )
    search.dongCode = dongCode;
    // 데이터 불러오기
    getData();

  }

  /**
   * 
   * @param {*} dongCode 
   */
  const outEvt = (dongCode) => {
    // 테이블 색상 칠하기
    const targetTable = document.getElementById("tb_" + dongCode);
    targetTable.classList.remove('infoBoxLocalAreaOver');
    targetTable.classList.add('infoBoxLocalArea');

    // 테이블 글씨 설정
    const targetTextCnt = document.getElementById("info_text_cnt_" + dongCode);
    const targetTextPower = document.getElementById("info_text_power_" + dongCode);
    const targetTextTitle = document.getElementById("info_title_" + dongCode);
    targetTextCnt.style.color = "#3e3e3e";
    targetTextPower.style.color = "#3e3e3e";
    targetTextTitle.style.color = "#3e3e3e";

    // 지도 색상 칠하기
    const targetMap = document.getElementById("dong_" + dongCode);
    // 지도의 원래 색상으로 다시 원복한다.
    targetMap.style.fill = backupColor;

    // 지도 글씨 설정
    const mapText = document.getElementById("mapText_" + dongCode);
    mapText.style.fill = "#3e3e3e";

    // 동코드 초기화
     search.dongCode = dongCode;
  
  }

  /* 지도에서 마우스 클릭했을 때 이벤트 */
  const mapClickEvt = e => {
    const { target } = e;
    triggerClickEvt(target.id.substring(5));
  };

  // 테이블에서 마우스 클릭 했을 떄 이벤트
  const boxClickEvt = (dongCode) => {
    triggerClickEvt(dongCode);
  }


  // 마우스 클릭 시 박스와 지도 색상 변경 함수
  const triggerClickEvt = (dongCode) => {
    if( search.dongCode ){
      outEvt(search.dongCode);
    }
    // console.log("dongCode = ", dongCode);
    // 색깔 칠하기
    overEvt(dongCode);
    
  }

  /**
   * 동적 컴포넌트(맵) 조회 함수
   * @param {*} rgnCode
   * @returns 동적 컴포넌트
   */
  const getGeo = (rgnCode, depth) => {
    let Geo;
    if (depth) Geo = geoComponents[rgnCode + "_" + depth];
    else Geo = geoComponents[rgnCode];
    return (
      <Geo
        // mapOverEvt={mapOverEvt}
        // mapOutEvt={mapOutEvt}
        mapClickEvt={mapClickEvt}
        >
      </Geo>
    );
  };

  /**
   * rejection 함수
   * @param {} e
   */
  const getRejection = e => {
    kendo.alert(getMessage(e.errCode));
  };

  // 헤더 변경 데이터
  const chngHeader = () => {
    const rgn = rgnDdlData.find(e => e.rgnCode === search.rgn.rgnCode);
    const headerLogo = document.getElementById("headerLogo");
    const headerImg = document.getElementById("headerImg");
    if(headerLogo) {
      if (rgn) {
        headerLogo.src = "/images/localareaImg/42770/title/" + rgn.rgnCode + "_logo_img.png";
        headerImg.style.display = "block";
        headerImg.src = '/images/localareaImg/42770/title/42770_logo_text.png';
        document.getElementById("subHeaderTerm").style.display = 'none';
        headerLogo.style.width = "145px";
        document.getElementById("headerTerm").style.display = 'none';
        headerLogo.style.marginLeft = "-175px";
        document.getElementById("title").style.marginTop = '5px';
      }
    }
  };

  /**
   * 맵의 색상 설정하기 ( 현재 설비 설치 갯수 기준으로 진행 )
   */
  const drawMap = () => {
    const getColorByPer = e => {
      if (e < 20) return "url(#scale0025)";
      else if (e >= 20 && e < 40) return "url(#scale2650)";
      else if (e >= 40 && e < 80) return "url(#scale5175)";
      else if (e >= 80 && e <= 100) return "url(#scale76100)";
      return "url(#scale0025)";
    };
    let colorArr = [];
    dongDataList.forEach(e => {
      const dongCode = e.dongCode;
      const rateByCnt = e.rateByCnt;
      const dongMap = document.getElementById("dong_" + dongCode);    
      if (!dongMap) return;
      if( search.dongCode ){
        if( search.dongCode === dongCode ) return;
      }
      const color = getColorByPer(rateByCnt);
      dongMap.style.fill = color;
      colorArr.push({ fixbylawBundCode: dongCode, color: color });
    });
    setDongColor(colorArr);
  }

  useEffect(() => {
    if( !search.dongCode ){
      drawMap();
    }
    chngHeader();
    // if (dataSource.realSolaDongData.length !== 0) intervalFixMapItem(curDongData);
  }, [dongDataList]);

  return (
    <>
      <LocalAreaGeoGradient />
      <div
        className="con_center"
        style={
          loading
            ? {
                opacity: 0.6,
                pointerEvents: "none",
              }
            : null
        }>
        <div className="map-box ">
          {
            dongDataList.map((item) => (
              <JeongSeonMunicMapTable 
                dongData={item} 
                boxClickEvt = {boxClickEvt} 
                styles = {styles}
                CountUp = {CountUp} 
              />
            ))
          }
          {/* <LocalAreaMonitorMapTable mapItem={mapItem} infoBoxRef={infoBoxRef} mapVisible={false} styles = {styles} />
          <LocalAreaMonitorMapTable mapItem={fixMapItem} mapVisible={intvlState.visible} styles = {styles} /> */}
          <LocalAreaMonitorMapDepthTable mapItem={mapItem} depthBoxRef={depthBoxRef} mapVisible={false} />
          {/* { isEditDepth && <LocalAreaMonitorMapDepthTable mapItem={mapItem} depthBoxRef={depthBoxRef} mapVisible={false} /> } */}
          {getGeo(search.rgn.rgnCode, search.geoDepth)}
        </div>
      </div>
    </>
  );
};

export default JeongSeonMunicMap;
