import React from "react";

const DaedeokGeoGradient = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ position: "fixed" }}>
      <linearGradient id="overGra" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stopColor="#FF9A57" stopOpacity="1" />
        <stop offset="100%" stopColor="#E8613B" stopOpacity="1" />
      </linearGradient>
      <linearGradient id="scale0025" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stopColor="#bedff9"></stop>
        <stop offset="100%" stopColor="#ffffff"></stop>
      </linearGradient>
      <linearGradient id="scale2650" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stopColor="#6a99c9"></stop>
        <stop offset="100%" stopColor="#b3cee3"></stop>
      </linearGradient>
      <linearGradient id="scale5175" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stopColor="#397aad"></stop>
        <stop offset="100%" stopColor="#75a3c7"></stop>
      </linearGradient>
      <linearGradient id="scale76100" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stopColor="#075390"></stop>
        <stop offset="100%" stopColor="#3c79aa"></stop>
      </linearGradient>
    </svg>
  );
};
export default DaedeokGeoGradient;
