import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import "./reportConsmPrintModal.css";

function ReportConsmWarnChart({ chartWarn, width, clsName, height,isPrint }) {
  // 차트 옵션
  let chartDayWarn = {
    chart: {
      type: "line",
      zoomType: "xy",
      height: height ? height : 350,
      backgroundColor: "#f7f9fa",
      width: width !== null ? width : "100%",
    },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "(단위)건",
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { shared: true },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    plotOptions: {
      line: {
        // stacking: "normal",
        dataLabels: { 
          enabled: true,
          color : '#000000',
          style: { textShadow: false }
        }
      },
    },
    series: [
      {
        name: "미작동",
        tooltip: {
          valueSuffix: " 건",
        },
        color: "#9090a7",
        data: chartWarn.unOperCnt,
      },
      {
        name: "경고",
        tooltip: {
          valueSuffix: " 건",
        },
        color: "#f9cc13",
        data: chartWarn.waringCnt,
      },
      {
        name: "고장",
        tooltip: {
          valueSuffix: " 건",
        },
        color: "#ff3b3b",
        data: chartWarn.errorCnt,
      },
    ],
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className={isPrint?"reportConsmPrintSubheading":"reportDayBackground"}>
          <b> · &nbsp;일별 경보 발생 현황 </b>
        </div>
        <div className={clsName !== null ? clsName : "reportDayBackground"}>
          <HighchartsReact Highcharts={Highcharts} options={chartDayWarn} />
        </div>
      </div>
    </div>
  );
}

export default ReportConsmWarnChart;
