import { ListView } from '@progress/kendo-react-listview';
import EnsoTypeItem from './ensoTypeItem';
import styles from './installerItem.module.css';

// 카카오 적용
const { kakao } = window;

// 좌측 설치자별 목록
const InstallerItem = ({dataItem}) =>{
  const ensoList = dataItem.installer;
  
  const movePostEvt = () =>{
    const moveLocation = new kakao.maps.LatLng(dataItem.lati, dataItem.longi);
    dataItem.map.setCenter(moveLocation);
  }

  return(
    <>
    <table className={styles.installerEnsoListTable}>
      <thead>
        <tr>
          <th colSpan="2"> <button className = {styles.movePosTerm} onClick = {movePostEvt}>{dataItem.userTerm}</button> </th>
          <td>{dataItem.sumCount}개소</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="3">
      {  ensoList.length !== 0 ? (
        <ListView data={ensoList} item={EnsoTypeItem} onChange = {movePostEvt}/>
        ): <span className = "none-data">데이터가 존재하지 않습니다.</span>
      }
          </td>
        </tr>
      </tbody>
    </table>
    </>
  );
  
}

export default InstallerItem;