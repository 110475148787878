 // 자동 가운데 정렬 (윈도우 창 가운데 정렬)
export const setCenterCalc = (width, height) => {
    let left = (document.body.offsetWidth / 2) - (width / 2);
    let top = (document.body.offsetHeight / 2) - (height / 2);
    
    let posCalc = {
      left : left , 
      top : top 
    }
    
    return posCalc;
}