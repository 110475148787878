import React from "react";
import { numFormat, roundNum } from "./../../../com/dataUtil";

const MunicMapTable = props => {
  const { mapItem, infoBoxRef, mapVisible } = props;
  return (
    <div className="info-box-A info-table municMapTable" ref={infoBoxRef} style={{ display: mapVisible ? "block" : "none" }}>
      <table className="M-over">
        <thead>
          <tr>
            <th colspan="3" className="th-th">
              {mapItem.fixbylawBundTerm}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="tb-th">설치개수</th>
            <td className="tb-td">{mapItem.asyncCount}개</td>
            <td className="tb-td">{mapItem.syncCount}개</td>
          </tr>
          <tr>
            <th className="tb-th">설비용량</th>
            <td className="tb-td">{numFormat(roundNum(mapItem.asyncInstCapa, 0))}kW</td>
            <td className="tb-td">{numFormat(roundNum(mapItem.syncInstCapa, 0))}kW</td>
          </tr>
          <tr>
            <th className="tb-th">금일발전</th>
            <td className="tb-td">{numFormat(roundNum(mapItem.asyncDayPower, 0))}kW</td>
            <td className="tb-td">{numFormat(roundNum(mapItem.syncDayPower, 0))}kW</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MunicMapTable;
