// Mui
import { DesktopWindows, QueryStats, BarChart, ChatBubble, Settings, Explore, BrowserNotSupported, Public } from "@material-ui/icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import SolarPowerIcon from '@mui/icons-material/SolarPower';

const MenuNavDaedeok = menu => {
	let menuHtml;
	let iconClass = "material-icons";
	let location = useLocation();

	switch (menu.menu.id) {
		/* 지자체 모니터링 */
		case "010000":
			if (location.pathname.includes("/munic", 0)) {
				iconClass += " material-icons-active";
			}
			menuHtml = (
				<span className="menu_icon">
					<Public className={iconClass} />
				</span>
			);
			break;
		/* 지역별 현황 */
		case "020000":
			if (location.pathname.includes("/areaEnergy", 0)) {
				iconClass += " material-icons-active";
			}
			menuHtml = (
				<span className="menu_icon">
					<DesktopWindows className={iconClass} />
				</span>
			);
			break;
		/* 수용가별 현황 */
		case "030000":
			if (location.pathname.includes("/installerMeain", 0)) {
				iconClass += " material-icons-active";
			}
			menuHtml = (
				<span className="menu_icon">
					<SolarPowerIcon className={iconClass} />
				</span>
			);
			break;
		/* 통계 분석 */
		case "040000":
			if (location.pathname.includes("/stats", 0)) {
				iconClass += " material-icons-active ";
			}
			menuHtml = (
				<span className="menu_icon">
					<BarChart className={iconClass} />
				</span>
			);
			break;
		/* 사업 현황 */
		case "050000":
			if (location.pathname.includes("/bizStat", 0) || location.pathname.includes("/analy/raw", 0)) {
				iconClass += " material-icons-active";
			}
			menuHtml = (
				<span className="menu_icon">
					<QueryStats className={iconClass} />
				</span>
			);
			break;
		/* 게시판 */
		case "060000":
			if (location.pathname.includes("/board", 0)) {
				iconClass += " material-icons-active";
			}
			menuHtml = (
				<span className="menu_icon">
					<ChatBubble className={iconClass} />
				</span>
			);
			break;
		/* 관리 */
		case "070000":
			if (location.pathname.includes("/mngt", 0)) {
				iconClass += " material-icons-active";
			}
			menuHtml = (
				<span className="menu_icon">
					<Settings className={iconClass} />
				</span>
			);
			break;

			/* 매칭되는 아이콘이 없을경우 */
		default:
			menuHtml = (
				<span className="menu_icon">
					<BrowserNotSupported className={iconClass} />
				</span>
			);
			break;
	}
	return menuHtml;
};


export default MenuNavDaedeok;