import styles from './userInfo.module.css';
import {Button} from '@progress/kendo-react-buttons';
// import './userInfo.css';
import './userInfo.dk.css';


const UserInfoSave = ({ userInfo , saveUserInfo }) => {

  // 저장 버튼 이벤트
  const saveClickEvt = () =>{
    saveUserInfo(userInfo);
  }

  return(
    <div className="uk-width-medium-1-2">
        <table className= {styles.produceTb}>
          <tbody>
            <tr>
                <td>
                  <Button className = {styles.saveBtn} icon="save" onClick = {saveClickEvt} >저장</Button>
                </td>
            </tr>
          </tbody>
        </table>
    </div>
  );
}

export default UserInfoSave;