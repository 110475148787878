// React
import React, { useState, useEffect, useCallback } from 'react';
import { getInstaller, setUpdateInstaller, setDeleteInstaller, downloadInstallerList } from '../../../service/mngt/installer/installerService';
import log from '../../../com/log';
import { parseDate } from '../../../com/dateUtil';
import InstallerModal from './installerModal';
import installerExcelSample from '../../../files/sample_installer.xlsx';
import { BeatLoader } from 'react-spinners'

// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Upload } from '@progress/kendo-react-upload';
import { Window } from '@progress/kendo-react-dialogs'

// Css
import './installer.css';
import styles from './installer.module.css';
import GridColumnInputFilter from '../../com/gridFilter/gridColumnInputFilter';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Create, Delete } from "@material-ui/icons";
import { getToken } from '../../../service/authService';

const Installer = ({ conn, codeList, dongList }) => {

  // 로딩 스피너
  const [loading, setLoading] = useState(true);
  // 엑셀 로딩 스피너
  const [saveLoading, setSaveLoading] = useState(false);

  // // dataList 제거
  // dataList.forEach(element => {
  //   element.fixbylawBundTermFix = element.fixbylawBundTerm.substring(5);
  // });

  // 요금제 유형
  const rasys = codeList.filter(item => item.grpCodeNo === 14);
  console.log(rasys);
  // 드롭다운 필터 상태
  const [filterDropValue, setFilterDropValue] = useState({
    rgn: { fixbylawBundCode: "", fixbylawBundTerm: "주소검색" },
    rasys: { codeNo: "", codeVal: "요금제 검색" }
  });

  // 그리드 데이터
  const [dataSource, setDataSource] = useState({
    total: 0,
    dataList: []
  });
  const [backupDataSource, setBackupDataSource] = useState();

  // 그리드 데이터 상태
  const [dataState, /*setDataState*/] = useState({
    take: 15
    , skip: 0
    , sort: []
    , sortStatus: []
    , filter: { userId: "", userTerm: "", fixbylawBundCode: "", rasysCodeNo: "" }
  });

  // 추가, 수정 모달창
  const [modalForm, setModalForm] = useState({
    visible: false
    , title: ''
    , formItem: []
    , isNew: false
  });

  // 엑셀 업로드 정보 상태
  const [uploadState, setUploadState] = useState({
    files: []
    , events: []
    , filePreviews: {}
    , afterStateChange: []
    , response: []
    , visible: false
  });

  // 데이터 불러오기
  const getGridData = () => {
    getInstaller(conn, dataState).then(response => {
      setLoading(false);
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.total = response.total;
        item.dataList = response.results;
        return item;
      });
    }).catch(error => {
      setLoading(false);
      log(`getGridData: ${error}`);
    });
  }

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const { take, skip } = e.page;
    dataState.skip = skip;
    dataState.take = take;
    getGridData();
  };

  // 그리드 소팅 처리
  const handleSortChange = e => {
    const { sort } = e;
    dataState.sort = sort;
    dataState.sortStatus = sort;
    if (sort.length !== 0) {
      if (sort[0].field === 'fixbylawBundTerm') {
        dataState.sort = 'fixbylawBundCode.' + sort[0].field + ',' + sort[0].dir;
      } else if (sort[0].field === 'rasysCodeTerm') {
        dataState.sort = 'rasysCodeNo.codeVal,' + sort[0].dir;
      } else {
        dataState.sort = sort[0].field + ',' + sort[0].dir;
      }
    }
    getGridData();
  };

  // 그리드 필터링 처리
  const handleFilterChange = e => {
    dataState.skip = 0;
    dataState.take = 15;

    // 필터 값 설정
    const isFilter = e.filter.filters;
    console.log("e.filter", e.filter);
    if (isFilter.length !== 0) {
      if (isFilter[0].field === "userId") { dataState.filter.userId = isFilter[0].value; }
      else if (isFilter[0].field === "userTerm") { dataState.filter.userTerm = isFilter[0].value; }
      else if (isFilter[0].field === "fixbylawBundTerm") { dataState.filter.fixbylawBundCode = isFilter[0].value.fixbylawBundCode; }
      else if (isFilter[0].field === "rasysCodeTerm") { dataState.filter.rasysCodeNo = isFilter[0].value.codeNo; }
    }
    getGridData();
  };

  // 지역 필터CELL 드롭다운
  const handleFlxbylawFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropValue(filterDropValue => {
        const item = { ...filterDropValue };
        item.rgn = e.value;
        return item;
      });
    }

    return (
      <div className="k-filtercell">
        <DropDownList
          data={backupDataSource}
          onChange={filterOnchage}
          defaultItem={{ fixbylawBundCode: "", fixbylawBundTerm: "주소검색" }}
          className="CustomFilterDropDown" name="fixbylawBund"
          textField="fixbylawBundTerm" dataItemKey="fixbylawBundCode"
          value={filterDropValue.rgn}
        />
      </div>
    );
  }

  // 요금 필터CELL 드롭다운
  const handleRasysFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropValue(filterDropValue => {
        const item = { ...filterDropValue };
        item.rasys = e.value;
        return item;
      });
    }

    return (
      <div className="k-filtercell">
        <DropDownList
          data={rasys}
          onChange={filterOnchage}
          defaultItem={{ codeNo: "", codeVal: "요금제 검색" }}
          className="CustomFilterDropDown"
          textField="codeVal" dataItemKey="codeNo" name="raySys"
          value={filterDropValue.rasys}
        />
      </div>
    );
  }

  // 추가 모달창 저장/수정 처리
  const handleModalSaveSubmit = useCallback(e => {
    console.log("e", e);
    // Vlidation Check
    const isValid = validationCheck(e);
    if (isValid.isValid === false) {
      kendo.alert(isValid.message);
      return;
    }

    // 데이터 저장
    setModalForm({ ...modalForm, visible: !modalForm.visible, title: '추가', formItem: [], isNew: modalForm.isNew });
    // if(e.smsNotiUseYn === undefined) e.smsNotiUseYn = 'Y';
    // if(e.emailNotiUseYn === undefined) e.emailNotiUseYn = 'Y';
    e.smsNotiUseYn = e.smsNotiUseYn ? e.smsNotiUseYn : 'N';
    e.emailNotiUseYn = e.emailNotiUseYn ? e.emailNotiUseYn : 'N';
    e.userAddr = e.fixbylawBund.fixbylawBundTerm;
    e.fixbylawBundCode = e.fixbylawBund.fixbylawBundCode;
    e.rgnCode = e.fixbylawBund.rgnCode;
    e.cityProvCode = e.fixbylawBund.provinceCode;
    e.rasysCodeNo = e.rasysCode.codeNo;
    e.regiDtm = parseDate('LocalDateTime');
    e.useYn = e.useYn ? e.useYn : 'Y';
    e.createUpdateYn = e.isNew;
    setUpdateInstaller(conn, e)
      .then(response => {
        setSaveLoading(false);
        if (!response) {
          kendo.alert("저장을 실패 하였습니다.");
          return;
        }
        setModalForm({ ...modalForm, visible: !modalForm.visible, title: '', formItem: [] });
        kendo.alert("저장을 완료 하였습니다.");
        getGridData();
      })
      .catch(err => {
        setSaveLoading(false);
        kendo.alert("저장을 실패 하였습니다.");
        getRejection(err);
        // log(`installer.handleModalSaveSubmit error: ${err}`);
      });
      // eslint-disable-next-line
  }, [modalForm, dataSource, dataState]);

  // 엑셀다운
  const handleExcelDown = useCallback(e => {
    setSaveLoading(true);
    e.preventDefault();
    downloadInstallerList(dataState, setSaveLoading);
    // eslint-disable-next-line
  }, []);


  // 엑셀 업로드 모달폼
  const handleExcelFormControl = useCallback(e => {
    setUploadState({
      ...uploadState
      , files: []
      , events: []
      , filePreviews: {}
      , afterStateChange: []
      , response: []
      , visible: !uploadState.visible
    });
  }, [uploadState]);


  // 엑셀 업로드 헤더 설정
  // eslint-disable-next-line
  const handleUploadHeaders = useCallback(e => e.headers['X-AUTH-TOKEN'] = getToken());

  // 엑셀 파일 추가 부분
  const onAdd = useCallback(({ target, newState, affectedFiles }) => {
    console.log("target = ", target);
    console.log("newState = ", newState);
    console.log("affectedFiles = ", affectedFiles);
    const afterStateChange = () => {
      affectedFiles
        .filter(file => !file.validationErrors)
        .forEach(file => {
          const reader = new FileReader();
          reader.onloadend = ev => {
            setUploadState({
              ...uploadState
              , filePreviews: {
                ...uploadState.filePreviews
                , [file.uid]: ev.target.result
              }
            });
          };
          reader.readAsDataURL(file.getRawFile());
        });
    };

    setUploadState({
      ...uploadState
      , files: newState
      , afterStateChange
    });
  }, [uploadState]);


  // 엑셀 파일 추가 후 데이터 추출 부분
  const onStatusChange = useCallback(({ target, newState, affectedFiles, response }) => {
    // 여기서 BizException 처리 확인 
    const result = response.response;
    if (result) {
      if (result.status !== 200) {
        const isValid = ValidBizException(result.code);
        kendo.alert(isValid.message);
        return;
      }
    }
    setUploadState({ ...uploadState, files: newState, response });
  }, [uploadState]);

  // validationCheck()

  // 엑셀 업로드 버튼
  const handleExcelUpload = useCallback((e) => {
    const uploadExcelData = uploadState.response.response.data;

    console.log("dongList = ", dongList);

    // 엑셀 업로드 전 Validation 체크
    let breakFlag = false;
    uploadExcelData.some(item => {
      console.log("item = ", item);
      // 읍면동 코드 추가 
      if (item.fixbylawBundCode) {
        item.fixbylawBund = { fixbylawBundCode: '' };
        item.fixbylawBund.fixbylawBundCode = item.fixbylawBundCode;
      }
      if (item.rasysCodeNo) {
        item.rasysCode = { codeNo: '' };
        item.rasysCode.codeNo = item.rasysCodeNo;
      }
      // Validation 시작
      const isValid = validationCheck(item);
      // const isValid = {isValid : true , message : ""};
      // if( !item.userId ){ isValid.isValid = false; isValid.message = "ID를 입력하지 않았습니다. ID를 입력 해주세요."; }
      // else if ( !item.userTerm ) { isValid.isValid = false; isValid.message = "사용자(설치자) 이름을 입력하지 않았습니다. 사용자(설치자) 이름을 입력 해주세요."; }
      // else if ( !item.loginPwd ) { isValid.isValid = false; isValid.message = "암호를 입력하지 않았습니다. 암호를 입력 해주세요."; }
      // else if( !item.fixbylawBundCode ){ isValid.isValid = false; isValid.message = "읍면동 코드를 입력 하였는지 확인 해주세요."; }
      // else  if( !item.rasysCodeNo ){ isValid.isValid = false; isValid.message = "요금제 코드를 입력 하였는지 확인 해주세요."; }
      // 실패 시 alert 창 보여주고 false 처리
      if (isValid.isValid === false) {
        kendo.alert(isValid.message);
        e.preventDefault();
        breakFlag = true;
        return true;
      }
      return false;
    });
    // some 에서 return 걸어도 멈추지 않아서 플래그로 제어
    if (breakFlag === true) {
      return;
    }

    uploadExcelData.forEach(item => {
      const dongData = dongList.filter(dongItem => dongItem.fixbylawBundCode === item.fixbylawBundCode)[0];
      item.cityProvCode = dongData.provinceCode;
      item.rgnCode = dongData.rgnCode;
      item.userAddr = dongData.fixbylawBundTerm;
      item.fixbylawBundTermSimple = dongData.fixbylawBundTermSimple;
      item.rasysCodeTerm = rasys.filter(rasysItem => rasysItem.codeNo === Number(item.rasysCodeNo))[0].codeVal;
      item.regiDtm = parseDate('LocalDateTime');
      item.useYn = 'Y';
      item.createUpdateYn = true;
      item.smsNotiUseYn = item.smsNotiUseYn ? item.smsNotiUseYn : 'N';
      item.emailNotiUseYn = item.emailNotiUseYn ? item.emailNotiUseYn : 'N';
    });
    setUpdateInstaller(conn, uploadExcelData)
      .then(() => {
        kendo.alert('엑셀 저장을 완료하였습니다.');
        setUploadState({ ...uploadState, visible: !uploadState.visible });
        getGridData();
      }).catch(error => {
        kendo.alert('엑셀 저장을 실패 하였습니다.');
        // console.log("error = ", error);
      });
      // eslint-disable-next-line
  }, [uploadState, dataSource, dataState]);

  // 리젝션 코드
  const getRejection = (errorCode) => {
    // 현재 BizException 발생 시 app.jsx 에서 이미 catch 하고 정상으로 response 로 처리하며, 마지막 response 는 undefined 로 오기에 확인이 필요
    const isValid = ValidBizException(errorCode);
    if (!isValid) {
      kendo.alert(isValid.message);
      return;
    }
  }

  // 엑셀 업로드 시 response 값 확인 후 BizException 시 안내 창 
  const ValidBizException = (errorCode) => {
    let isValid = true;
    let message = "";
    if (errorCode === "L006") {
      isValid = false;
      message = "ID가 중복 입니다.";
    }
    else if (errorCode === "L001") {
      isValid = false;
      message = "ID가 입력 되지 않았습니다.";
    }
    else if (errorCode === "L003") {
      isValid = false;
      message = "패스워드 형식이 맞지 않습니다. 8자리 이상 영문,숫자,특수 문자 조합으로 다시 입력 해주세요.";
    }
    else if (errorCode === "L004") {
      isValid = false;
      message = "연락처 형식이 맞지 않습니다. 연락처를 다시 입력 해주세요.";
    }
    else if (errorCode === "L005") {
      isValid = false;
      message = "이메일 형식이 맞지 않습니다. 이메일를 다시 입력 해주세요.";
    }
    else if (errorCode === 'L009') {
      isValid = false;
      message = "비밀번호를 입력 해주세요.";
    } else if (errorCode === 'L006') {
      isValid = false;
      message = "입력하신 ID가 중복 입니다."
    } else if (errorCode === 'L003') {
      isValid = false;
      message = "비밀번호 형식이 다릅니다. 다시 입력 해주세요.";
    } else if (errorCode === 'L001') {
      isValid = false;
      message = "입력하신 ID를 찾을 수 없습니다.";
    } else if (errorCode === 'L005') {
      isValid = false;
      message = "이메일 형식이 다릅니다. 다시 입력 해주세요.";
    } else if (errorCode === 'L004') {
      isValid = false;
      message = "연락처 형식이 다릅니다. 다시 입력 해주세요.";
    }

    return { isValid: isValid, message: message };
  }

  // Validation 처리 예정
  const validationCheck = (item) => {
    console.log("item = ", item);
    let isValid = true;
    let message = "";
    if (!item.userId) {
      isValid = false;
      message = "ID를 입력하지 않았습니다. ID를 입력 해주세요.";
    }
    else if (!item.userTerm) {
      isValid = false;
      message = "사용자(설치자) 이름을 입력하지 않았습니다. 사용자(설치자) 이름을 입력 해주세요.";
    }
    // else if ( !item.loginPwd ) {
    //   isValid = false;
    //   message = "암호를 입력하지 않았습니다. 암호를 입력 해주세요.";
    // }
    else if (!item.fixbylawBund.fixbylawBundCode) {
      isValid = false;
      message = "읍면동을 선택 또는 입력하지 않았습니다. 읍면동을 선택 하거나 엑셀 업로드라면 읍면동 코드를 다시 확인 해주세요.";
    }
    else if (!item.rasysCode.codeNo) {
      isValid = false;
      message = "요금제를 선택 또는 입력하지 않았습니다. 요금제를 선택 하거나 엑셀 업로드라면 요금제 코드를 다시 확인 해주세요.";
    }

    // 암호 체크
    if (item.isNew) {
      if (!item.loginPwd) {
        isValid = false;
        message = "암호를 입력하지 않았습니다. 암호를 입력 해주세요.";
      }
    }

    // 연락처 입력 하면 정규식 적용
    if (item.userCelphon) {
      const regPhone = /^\d{2,3}-\d{3,4}-\d{4}$/; // 현재 정규식 ( 02-xxx, xxxx-xxxx , 041-xxx , xxxx-xxxx )
      if (!regPhone.test(item.userCelphon)) {
        message = "유효하지 않은 내선 번호입니다!"; isValid = false;
      }
    }

    // 이메일 입력 하면 정규식 적용
    if (item.userEmail) {
      // eslint-disable-next-line
      const regEmail = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      if (!regEmail.test(item.userEmail)) {
        message = "유효하지 않은 A/S담당자 이메일입니다!"; isValid = false;
      }
    }

    // if( item. ){

    // }


    return { isValid: isValid, message: message };
  }


  useEffect(() => {
    getInstaller(conn, dataState).then(response => {
      setLoading(false);
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.total = response.total;
        item.dataList = response.results;
        return item;
      });
      setBackupDataSource(response.results)
    }).catch(error => {
      setLoading(false);
      log(`getGridData: ${error}`);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={["installerBody", saveLoading ? styles.installG : ""].join(' ')}>
        <div className={loading ? styles.installG : ""}>
          <div className="installerCate">
            <Button className={styles.writeBtn} onClick={e => {
              setModalForm({ ...modalForm, visible: !modalForm.visible, title: '추가', createUpdateYn: true, formItem: [], isNew: true });
            }}
              icon="add">
              추가
            </Button>
            <Button className={styles.writeBtn} onClick={handleExcelFormControl} icon="upload">
              엑셀파일 가져오기
            </Button>
            <Button icon="download" className={styles.writeBtn}>
              <a className='installerExcelTxt' href={installerExcelSample} download='sample_installer.xlsx'>엑셀샘플 다운로드</a>
            </Button>
            <Button className={styles.writeBtn} onClick={handleExcelDown} icon="excel">
              엑셀저장
            </Button>
          </div>
          <div className="installerGrid">
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid
                  className={styles.InstallerGrid}
                  data={dataSource.dataList}
                  skip={dataState.skip}
                  take={dataState.take}
                  total={dataSource.total}
                  sort={dataState.sortStatus}
                  pageable
                  sortable
                  filterable
                  onPageChange={handlePageChange}
                  onSortChange={handleSortChange}
                  onFilterChange={handleFilterChange}
                >
                  <GridColumn field='userId' title='ID' filterCell={GridColumnInputFilter} />
                  <GridColumn field='userTerm' title='설치자' filterCell={GridColumnInputFilter} />
                  <GridColumn field='fixbylawBundTerm' title='읍/면/동' filterCell={handleFlxbylawFilterList} />
                  <GridColumn field='userCelphon' title='연락처' filterable={false} />
                  <GridColumn field='userEmail' title='이메일' filterable={false} />
                  <GridColumn field='rasysCodeTerm' title='요금제' filterCell={handleRasysFilterList} />
                  <GridColumn field='regiDtm' title='등록일시' filterable={false} />
                  <GridColumn field='smsNotiUseYn' title='SMS' filterable={false} width={60} />
                  <GridColumn field='emailNotiUseYn' title='EMAIL' filterable={false} width={80} />
                  <GridColumn
                    title='수정'
                    filterable={false}
                    sortable={false}
                    width={60}
                    cell={
                      props => (
                        <td>
                          <button id="installerEditBtn"
                            onClick={e => {
                              e.preventDefault();
                              setModalForm({
                                ...modalForm
                                , visible: !modalForm.visible
                                , title: '수정'
                                , createUpdateYn: false
                                , formItem: props.dataItem
                                , isNew: false
                              });
                            }}
                          >
                            <i>
                              <Create />
                            </i>
                          </button>
                        </td>
                      )}
                  />
                  <GridColumn
                    title='삭제'
                    filterable={false}
                    sortable={false}
                    width={60}
                    cell={
                      props => (
                        <td>
                          <button id="installerDelBtn"
                            onClick={e => {
                              e.preventDefault();
                              const { userId } = props.dataItem;
                              // 삭제 여부 확인 
                              kendo.confirm("해당 사용자(설치자)를 삭제 하시겠습니까?").then(e => {
                                setDeleteInstaller(conn, userId)
                                  .then(response => {
                                    kendo.alert("삭제가 완료 되었습니다.");
                                    getGridData(conn, dataState);
                                  })
                                  .catch(err => {
                                    kendo.alert("삭제를 실패 하였습니다.");
                                    log(`installerGrid.setDeleteInstaller error: ${err}`);
                                  });
                              }).then(e => {

                              });
                            }}
                          >
                            <i>
                              <Delete />
                            </i>
                          </button>
                        </td>
                      )}
                  />
                  <GridNoRecords>
                    데이터가 존재하지 않습니다.
                  </GridNoRecords>
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
            {
              modalForm.visible &&
              <InstallerModal
                modalForm={modalForm}
                setModalForm={setModalForm}
                fixbylaw={dongList}
                rasys={rasys}
                handleModalSaveSubmit={handleModalSaveSubmit}
                handleModalUpdateSubmit={handleModalSaveSubmit}
              />
            }
            {
              uploadState.visible &&
              <Window
                title={"엑셀 업로드"}
                onClose={handleExcelFormControl}
                initialHeight={240}
                initialWidth={600}
                modal={true}
                resizable={false}
              >
                <div></div>
                <LocalizationProvider language="ko-KR">
                  <IntlProvider locale="ko">
                    <Upload
                      className={styles.installerUpload}
                      onBeforeUpload={handleUploadHeaders}
                      batch={false}
                      multiple={false}
                      files={uploadState.files}
                      restrictions={{ allowedExtensions: ['.xlsx'] }}
                      withCredentials={true}
                      saveUrl={'/api/v2.2/user/installer/xlsx'}
                      onAdd={onAdd}
                      onStatusChange={onStatusChange}
                    />
                  </IntlProvider>
                </LocalizationProvider>
                <div>
                  <Button style={{ float: "right", marginLeft: 10, marginTop: 15, }} onClick={handleExcelFormControl}>취소</Button>
                  <Button style={{ float: "right", marginTop: 15, }} onClick={handleExcelUpload}>저장</Button>
                </div>
              </Window>
            }
          </div>
        </div>
        {loading && <p className={styles.installLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
      </div>
      {saveLoading && <p className={styles.installLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
    </>
  );
}

export default Installer;
