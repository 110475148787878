import "./index.css";
import { ReactComponent as Electric } from "../../../../../assets/svg/gongju/electric.svg";
import { ReactComponent as Heat } from "../../../../../assets/svg/gongju/heat.svg";
import { ReactComponent as Greengas } from "../../../../../assets/svg/gongju/greengas.svg";
import { roundNum, numFormat } from "com/dataUtil";

const TodayRenewableEnergy = ({ data }) => {
  const formatEnergy = value => {
    if (value >= 1000) {
      return {
        value: numFormat(roundNum(value / 1000, 1)),
        unit: "MWh",
      };
    }
    return {
      value: numFormat(roundNum(value, 1)),
      unit: "kWh",
    };
  };

  const formatCo2 = value => {
    if (value >= 1000) {
      return {
        value: numFormat(roundNum(value / 1000, 1)),
        unit: "tCO₂",
      };
    }
    return {
      value: numFormat(roundNum(value, 1)),
      unit: "CO₂",
    };
  };

  const electricEnergy = formatEnergy(Number(data.solaDayPower) + Number(data.veloDayPower));
  const heatEnergy = formatEnergy(Number(data.heatDayPower) + Number(data.geoDayPower));

  return (
    <div className="gongju-content-wrapper">
      <span className="gongju-dashboard-title">오늘의 신재생에너지</span>
      <div className="gongju-left-bottom-container">
        <div className="renewableEnergy-top-wrapper">
          <Electric className="renewable-icon-top" />
          <span className="today-renewableEnergy-gongju-text-1">전기에너지</span>
          <div>
            <span className="today-renewableEnergy-gongju-text-2">{electricEnergy.value}</span>
            <span className="today-renewableEnergy-gongju-text-3"> {electricEnergy.unit}</span>
          </div>
        </div>
        <div className="renewableEnergy-top-wrapper">
          <Heat className="renewable-icon-top" />
          <span className="today-renewableEnergy-gongju-text-1">열에너지</span>
          <div>
            <span className="today-renewableEnergy-gongju-text-2">
              {heatEnergy.value}
              <span className="today-renewableEnergy-gongju-text-3"> {heatEnergy.unit}</span>
            </span>
          </div>
        </div>
        <div className="renewableEnergy-bottom-wrapper">
          <Greengas className="renewable-icon-bottom" />
          <span className="today-renewableEnergy-gongju-text-4">온실가스 감소량</span>
          <div>
            <span className="today-renewableEnergy-gongju-text-5">{formatCo2(data.totalCo2).value}</span>{" "}
            <span className="today-renewableEnergy-gongju-text-3">{formatCo2(data.totalCo2).unit}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodayRenewableEnergy;
