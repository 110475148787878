import { useCreateAxiosInstance } from "conInstance";
import AmountOfPowerGeneration from "../components/amountOfPowerGeneration";
import CurrentRenewableEnergy from "../components/currentRenewableEnergy";
import PowerGeneration from "../components/powerGeneration";
import TodayGongju from "../components/todayGongju";
import TodayRenewableEnergy from "../components/todayRenewableEnergy";
import "./index.css";
import { useEffect, useState } from "react";

const GongjuDashboard = props => {
  const { conn } = useCreateAxiosInstance();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await conn.get("/monitor/installer/munic/energyData", {
          params: {
            rgnCode: 44150,
          },
        });
        setData(res);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="gongju-dashboard-container">
      <div className="gongju-dashboard-left-container">
        <div className="gongju-dashboard-left-top-container">
          <TodayGongju />
          <AmountOfPowerGeneration data={data} />
        </div>
        <TodayRenewableEnergy data={data} />
      </div>
      <div className="gongju-dashboard-right-container">
        <PowerGeneration data={data} />
        <CurrentRenewableEnergy />
      </div>
    </div>
  );
};

export default GongjuDashboard;
