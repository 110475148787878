import React, { memo, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import kendo from "@progress/kendo-ui";
import "./cmpEnso.css";
// import "./cmpEnso.dk.css";
// 화면
import EnsoSearch from "./enso_search";
import EnsoDashBoard from "./enso_dashboard";
import EnsoTabStrip from "./enso_tabStrip";
// 켄도 언어 컨버터 (한글)
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

const CmpEnso = memo(({ conn, codeList }) => {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 어제 날짜 정의
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // 조회 조건
  const [search, setSearch] = useState({
    bizSct: "",
    // bizYy: kendo.toString(new Date() , 'yyyy'),
    bldSrvCodeNo: "",
    end: yesterday,
    ensoTypeCodeNo: 15001,
    start: new Date(yesterday.getFullYear(), yesterday.getMonth(), 1),
    unit: "DD",
    timeTabText : '시간대별 그래프'
  });

  // 탭 상태
  let [tabStat, setTabStat] = useState({ state: 0 });

  // 탭 선택 시 발생하는 이벤트
  const selectTabItem = e => {
    setLoading(true);
    // 탭 선택에 대한 탭 값 설정
    setTabStat({
      state: e.selected,
    });
  };

  useEffect(() => {});

  return (
    <>
      <div className={loading ? "cmpEnso" : ""}>
        <div>
          <EnsoSearch setSearch={setSearch} codeList={codeList} tabStat={tabStat}></EnsoSearch>
          <EnsoDashBoard search={search} conn={conn} setLoading={setLoading}></EnsoDashBoard>
          <EnsoTabStrip
            search={search}
            setSearch={setSearch}
            conn={conn}
            tabStat={tabStat}
            selectTabItem={selectTabItem}
            setLoading={setLoading}
            LocalizationProvider={LocalizationProvider}
            IntlProvider={IntlProvider}
          />
        </div>
      </div>
      {loading && (
        <p className="cmpEnsoLoading">
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
});

export default CmpEnso;
