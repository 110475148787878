import React, { Fragment } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat } from "../../../com/dataUtil";

function WeathCmpChart({ search, setSearch, dataState }) {
  const chartOption = {
    chart: {
      zoomType: "xy",
      events: {
        addSeries: function () {
          //chart.series[0].hide();
        },
      },
    },
    noData: {
      style: {
        color: "#FFFFFF",
        fontSize: "14px",
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [
      {
        type: "category",
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // 온도 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "tomato",
          },
        },
        title: {
          text: "온도(°C)",
          style: {
            color: "tomato",
          },
        },
        opposite: false,
      },
      {
        // 풍속 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#7FDBFF",
          },
        },
        title: {
          text: "풍속(m/s)",
          style: {
            color: "#7FDBFF",
          },
        },
        opposite: false,
      },
      {
        // 강수량 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#0074D9",
          },
        },
        title: {
          text: "강수량(mm)",
          style: {
            color: "#0074D9",
          },
        },
        opposite: false,
      },
      {
        // 습도 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "orange",
          },
        },
        title: {
          text: "습도(%)",
          style: {
            color: "orange",
          },
        },
        opposite: true,
      },
      {
        // 강수확률 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "mediumPurple",
          },
        },
        max: 100,
        title: {
          text: "강수확률(%)",
          style: {
            color: "mediumPurple",
          },
        },
        opposite: true,
      },
      {
        // 일사량 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#9ACD32",
          },
        },
        title: {
          text: "일사량(MJ/m²)",
          style: {
            color: "#9ACD32",
          },
        },
        opposite: true,
      },
      {
        // 발전량 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#ffc81E",
          },
        },
        title: {
          text: search.ensoTypeCode === 15002 || search.ensoTypeCode === 15003 ? "생산량(kWh)" : "발전량(kWh)",
          style: {
            color: "#ffc81E",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = "";
        if (this.points[0]) {
          msg +=
            '<span style="color:' +
            this.points[0].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[0].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[0].y) +
            "kWh</b>" +
            "<br>";
        }
        if (this.points[1]) {
          msg +=
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[1].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[1].y) +
            "kWh</b>" +
            "<br>";
        }
        if (this.points[2]) {
          msg +=
            '<span style="color:' +
            this.points[2].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[2].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[2].y) +
            "kWh</b>" +
            "<br>";
        }
        if (this.points[3]) {
          msg +=
            '<span style="color:' +
            this.points[3].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[3].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[3].y) +
            "kWh</b>" +
            "<br>";
        }
        if (this.points[4]) {
          msg +=
            '<span style="color:' +
            this.points[4].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[4].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[4].y) +
            "kWh</b>" +
            "<br>";
        }
        if (this.points[5]) {
          msg +=
            '<span style="color:' +
            this.points[5].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[5].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[5].y) +
            "kWh</b>" +
            "<br>";
        }
        if (this.points[6]) {
          msg +=
            '<span style="color:' +
            this.points[6].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[6].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[6].y) +
            "kWh</b>" +
            "<br>";
        }

        return msg;
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        events: {
          legendItemClick: function(e) {
            search.isTempChartVisible = !this.visible;
          }
        },
        visible: search.isTempChartVisible,
        color: "tomato",
        name: "온도",
        type: "spline",
        yAxis: 0,
        data: dataState.temp,
        tooltip: {
          valueSuffix: " °C",
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: function(e) {
            search.isWspdChartVisible = !this.visible;
          }
        },
        visible: search.isWspdChartVisible,
        color: "#7FDBFF",
        name: "풍속",
        type: "spline",
        yAxis: 1,
        data: dataState.wspd,
        dashStyle: "shortdot",
        tooltip: {
          valueSuffix: " m/s",
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: function(e) {
            search.isPrecQtyChartVisible = !this.visible;
          }
        },
        visible: search.isPrecQtyChartVisible,
        color: "#0074D9",
        name: "강수량",
        type: "spline",
        yAxis: 2,
        data: dataState.precQty,
        tooltip: {
          valueSuffix: " mm",
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: function(e) {
            search.isHumiChartVisible = !this.visible;
          }
        },
        visible: search.isHumiChartVisible,
        color: "orange",
        name: "습도",
        type: "spline",
        yAxis: 3,
        data: dataState.humi,
        tooltip: {
          valueSuffix: " %",
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: function(e) {
            search.isPrecPrbblChartVisible = !this.visible;
          }
        },
        color: "mediumPurple",
        visible: search.isPrecPrbblChartVisible,
        name: "강수확률",
        type: "spline",
        data: dataState.precPrbbl,
        yAxis: 4,
        tooltip: {
          valueSuffix: " %", 
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: function(e) {
            search.isPrecPrbblChartVisible = !this.visible;
          }
        },
        color: "#9ACD32",
        name: "일사량",
        type: "spline",
        yAxis: 5,
        data: dataState.soraQty,
        tooltip: {
          valueSuffix: " MJ/m²",
        },
        zIndex: 10,
      },
      {
        events: {
          legendItemClick: function(e) {
            search.isPrecPrbblChartVisible = !this.visible;
          }
        },
        color: "#ffc81E",
        name: search.ensoTypeCode === 15002 || search.ensoTypeCode === 15003 ? "생산량" : "발전량",
        type: "column",
        data: dataState.power,
        yAxis: 6,
        tooltip: {
          valueSuffix: " kWh",
        },
        zIndex: 1,
      },
    ],
  };

  return (
    <Fragment>
      <HighchartsReact highcharts={Highcharts} options={chartOption} />
    </Fragment>
  );
}

export default WeathCmpChart;
