import React from 'react';

const LocalAreaMonitorTitle = () => {
  return (
    <div className="sub_title">
      <img alt='타이틀' src='/images/localareaImg/42770/sunImg.png' />
    </div>
  );
};

export default LocalAreaMonitorTitle;