import { ReactComponent as AsanIcon } from "dist/svg/asan-icon.svg";
import { ReactComponent as AsanTitle } from "dist/svg/asan-title.svg";
import { ReactComponent as LoginButton } from "dist/svg/login-button.svg";

export default function Top() {
  return (
    <div className="asan-top">
      <div className="asan-top-title">
        <AsanIcon className="asan-icon" />
        <div>
          <AsanTitle className="asan-title" />
          <h3 className="asan-center">신재생에너지 관리 센터</h3>
        </div>
      </div>
      <a className="asan-login-container" href="https://kremc.kr/">
        <LoginButton className="asan-login-icon" />
        <p className="asan-login">로그인</p>
      </a>
    </div>
  );
}
