import React from 'react';

const DaedeokScaleComp = ({styles}) => {
  return (
    <>
      <div className={styles.percentInfoDiv}>
        <img alt='갯수 범위' src='/images/localareaImg/30230/scaleComp_black.png' />
      </div>
    </>
  );
};

export default DaedeokScaleComp;