import React, { useEffect, useState } from "react";

// Service
import { downloadAsFile } from "../../../../../service/bizStat/com/managementApiService";

// Kendo
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../../../../com/ko-language.json";
import BizManagementRepairAdd from "./bizManagementRepairAdd";

loadMessages(koMessage, "ko-KR");

/**
 * 융복합지원사업 - 관리현황 - A/S보수이력 및 관리이력 - AS 보수이력
 */
const BizManagementRepairHist = ({
  Grid,
  Column,
  GridNoRecords,
  Button,
  Input,
  search,
  kendo,
  asHistList,
  convMngtStyles,
  saveMeainAsInfoData,
  getMeainAsList,
  meainInfo,
  conn,
  getMngtMeainAsList,
  token,
  mngtFileList,
  DatePickerKo
}) => {
  // 모니터링 고도화인지 확인
  const moniYn = meainInfo.moniYn;
  // 그리드 높이 조절
  const gridHeight = moniYn === "Y" ? "330px" : "230px";

  // AS 보수이력 추가 입력 창 제어
  const [addStatus, setAddStatus] = useState(false);
  // AS 보수이력 추가 입력 데이터
  const [asData, setAsData] = useState({
    instlPlnnm: search.instlPlnnm,
    regiDtm: kendo.toString(new Date(), "yyyy-MM-dd"),
    asReason: "",
    fileId: "",
    fileExt: "",
    fileName: "",
    saveName: "",
    savePath: "",
    fileSize: "",
    createYn: "Y",
  });

  // 파일 정보
  const [fileInfo, setFileInfo] = useState({
    MA: {},
    selectId: "",
  });

  // 파일 세팅 (배열 데이터를 각 객체마다 데이터 잘 넣을 수 있도록 설정)
  const setFileInfoData = () => {
    setFileInfo(fileInfo => {
      const item = { ...fileInfo };
      // 초기화
      item.MA = {};
      // 파일 리스트 만큼 반복 진행
      if (mngtFileList.length > 0) {
        mngtFileList.forEach(element => {
          element.docuCodeNo ? (item[element.docuCodeNo] = element) : (item[element.docuCodeNo] = {});
        });
      }
      return item;
    });
  };

  // AS 보수 이력 리스트 클릭 시
  const onRowClick = e => {
    const item = e.dataItem;
    asData.instlPlnnm = item.instlPlnnm;
    asData.regiDtm = item.regiDtm;
    asData.asReason = item.asReason;
    asData.createYn = "N";
    setAddStatus(!addStatus);
  };

  // AS 보수 이력 추가 클릭 시
  const addAsHistClickEvt = e => {
    asData.createYn = "Y";
    setAddStatus(!addStatus);
  };

  // AS 보수이력 입력 정보 초기화
  const initialAsData = () => {
    asData.instlPlnnm = search.instlPlnnm;
    asData.regiDtm = kendo.toString(new Date(), "yyyy-MM-dd");
    asData.asReason = "";
  };

  // 파일 다운로드
  const downloadFile = e => {
    const downloadInfo = {
      instlPlnnm: e.instlPlnnm,
      energyTypeCode: search.mngt.energyTypeCode,
      regiDtm: e.regiDtm,
      bizTabName: 38,
      bizSctCode: search.bizSctCode,
    };
    downloadAsFile(downloadInfo, e.fileName, search);
  };

  // 그리드 에너지원 / 용량 표시 포맷
  const dateFormatCell = e => {
    return (
      <td className="left">
        <a
          name="MA"
          onClick={() => {
            downloadFile(e.dataItem);
          }}
          href="#!">
          {e.dataItem.fileName}
        </a>
      </td>
    );
  };

  useEffect(() => {
    // 파일 리스트 설정
    setFileInfoData();
    /* eslint-disable-next-line */
  }, [mngtFileList]);

  return (
    <>
      <div class="tbTitle">
        <span class="sel-title">[ A/S보수이력 ]</span>
        <span className={convMngtStyles.asHistAddArea}>
          <Button icon="add" onClick={addAsHistClickEvt} className={convMngtStyles.asHistAddBtn}>
            이력추가
          </Button>
        </span>
      </div>
      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="ko">
          {/* <div className = { loading ? convMngtStyles.meainListArea : ""}> */}
          <div>
            <Grid id="grid" data={asHistList} style={{ height: gridHeight }} selectedField="selected" onRowClick={onRowClick}>
              <Column field="regiDtm" title="접수 일자" width={250} />
              <Column field="asReason" title="처리 내용" />
              <Column cell={dateFormatCell} />
              <GridNoRecords>해당 설비는 등록된 AS 보수 이력이 존재하지 않습니다.</GridNoRecords>
            </Grid>
          </div>
        </IntlProvider>
      </LocalizationProvider>
      {addStatus === true && (
        <div>
          <BizManagementRepairAdd
            Button={Button}
            Input={Input}
            setAddStatus={setAddStatus}
            asData={asData}
            setAsData={setAsData}
            convMngtStyles={convMngtStyles}
            initialAsData={initialAsData}
            saveMeainAsInfoData={saveMeainAsInfoData}
            getMeainAsList={getMeainAsList}
            kendo={kendo}
            search={search}
            conn={conn}
            getMngtMeainAsList={getMngtMeainAsList}
            token={token}
            fileInfo={fileInfo}
            asHistList={asHistList}
            DatePickerKo = {DatePickerKo}
          />
        </div>
      )}
    </>
  );
};

export default BizManagementRepairHist;
