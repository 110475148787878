import { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { orderBy } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";
import { numFormat } from "../../../../com/dataUtil";

const ExpctBuildBsmanGrid = ({ search, dataSourceGrid, dataSourceChart, dataSourceExcel, setDataSource, userInfo, loading }) => {

  console.log(dataSourceGrid);
  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  const sort = { field: "", dir: "" };

  // 엑셀 다운로드 이벤트
  const onClickEvt = e => {
    dataSourceExcel.save();
  };

  // 정렬 이벤트
  // const onSortChange = e => {
  //   console.log("e", e);
  //   const { sort } = e;
  //   setSort(sort);
  // };

  // 차트 옵션
  let chartExpctConsm = {
    chart: {
      type: "column",
      zoomType: "xy",
      height: 294,
      backgroundColor: "#f7f9fa",
    },
    title: { text: null },
    xAxis: {
      categories: ["5일전", "4일전", "3일전", "2일전", "어제", "오늘", "내일"],
      crosshair: true,
      labels: {
        style: {
          fontSize: "18px",
        },
      },
    },
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
        },
        title: {
          text: "발전량(kWh)",
        },
      },
    ],
    credits: { enabled: false },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    colors: ["#b77d08", "#6f8835", "#1f624d", "#542871", "#85275c", "#32768e", "#33427a", "#818385"],
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = `<span><b>${this.x}</b></span><br>`;
        if (this.points[0]) {
          msg +=
            '<span style="color:' +
            this.points[0].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[0].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[0].y) +
            " kWh" +
            "</b>" +
            "<br>";
        }
        if (this.points[1]) {
          msg +=
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[1].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[1].y) +
            " kWh" +
            "</b>" +
            "<br>";
        }
        if (this.points[2]) {
          msg +=
            '<span style="color:' +
            this.points[2].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[2].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[2].y) +
            " kWh" +
            "</b>" +
            "<br>";
        }
        if (this.points[3]) {
          msg +=
            '<span style="color:' +
            this.points[3].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[3].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[3].y) +
            " kWh" +
            "</b>" +
            "<br>";
        }
        if (this.points[4]) {
          msg +=
            '<span style="color:' +
            this.points[4].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[4].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[4].y) +
            " kWh" +
            "</b>" +
            "<br>";
        }
        return msg;
      },
    },
    series: dataSourceChart,
  };

  // 엑셀 변수 상태 설정
  const setExcelData = _excelData => {
    setDataSource(dataSource => {
      const item = { ...dataSource };
      item.excelData = _excelData;
      return item;
    });
  };

  // 파일 이름
  const fileName = () => {
    let name = "";
    if (search.cityProvCode) {
      name += search.city.cityProvTerm + "_";
    }
    if (search.rgnCode) {
      name += search.rgn.rgnTermSimple + "_";
    }
    const template = name + "구축사업자_예측_통계" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  };

  useEffect(() => {
    // 엑셀 다운로드 데이터 설정
    setExcelData(_excelData);
  // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="uk-grid">
        <div className="uk-width-medium-1">
          <div className="expctSubTitleDiv">
            {" "}
            <b>· &nbsp;예측 그래프 </b>
          </div>
          <div className="expctConsmBackground">
            <HighchartsReact className="expctConsmChart" Highcharts={Highcharts} options={chartExpctConsm} />
          </div>
        </div>
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium-1">
          <div className="expctSubTitleDiv">
            <b>· &nbsp;예측 통계 </b>
            <Button
              className="expctConsmExcelBtn"
              // style={{ cursor: "pointer", position: "absolute", right: 55 }}
              onClick={onClickEvt}
              icon="excel">
              엑셀저장
            </Button>
          </div>
          <ExcelExport
            className="expctBuildGrid"
            data={orderBy(dataSourceGrid, sort)}
            ref={exporter => (_excelData = exporter)}
            fileName={fileName()}>
            <ExcelExportColumn field="ensoTypeTerm" title="에너지원" width={100} />
            <ExcelExportColumn field="dayAgo5" title="5일전" width={100} />
            <ExcelExportColumn field="dayAgo4" title="4일전" width={100} />
            <ExcelExportColumn field="dayAgo3" title="3일전" width={100} />
            <ExcelExportColumn field="dayAgo2" title="2일전" width={100} />
            <ExcelExportColumn field="dayAgo1" title="1일전" width={100} />
            <ExcelExportColumn field="dayAgo0" title="오늘" width={100} />
            <ExcelExportColumn field="tomorrow" title="내일" width={100} />
          </ExcelExport>
          <Grid
            className="expctBuildGrid"
            data={dataSourceGrid}
            style={{ height: "290px" }}
            // onSortChange={onSortChange}
            // sort = {sort}
            // sortable
          >
            <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
            <Column field="ensoTypeTerm" title="에너지원" />
            <Column field="dayAgo5" title="5일전" />
            <Column field="dayAgo4" title="4일전" />
            <Column field="dayAgo3" title="3일전" />
            <Column field="dayAgo2" title="2일전" />
            <Column field="dayAgo1" title="어제" />
            <Column field="dayAgo0" title="오늘" />
            <Column field="tomorrow" title="내일" />
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ExpctBuildBsmanGrid;
