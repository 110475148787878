import "./index.css";

import { ReactComponent as Earth } from "../../../../../assets/svg/taean/earth.svg";
import { ReactComponent as Tree } from "../../../../../assets/svg/taean/tree.svg";
import { ReactComponent as PlasticCup } from "../../../../../assets/svg/taean/plasticCup.svg";
import { ReactComponent as PaperCup } from "../../../../../assets/svg/taean/paperCup.svg";
import { ReactComponent as Car } from "../../../../../assets/svg/taean/car.svg";

import { numFormat, roundNum } from "com/dataUtil";

const ContributionOfEnvironmental = ({ data }) => {
  const formatCo2 = value => {
    if (value >= 1000) {
      return {
        value: numFormat(roundNum(value / 1000, 1)),
        unit: "tCO₂",
      };
    }
    return {
      value: numFormat(roundNum(value, 1)),
      unit: "CO₂",
    };
  };

  return (
    <div className="taean-flex-container">
      <span className="taean-dashboard-title">오늘의 환경공헌도</span>
      <div className="contribution-container">
        <div className="contribution-top-wrapper">
          <Earth className="earth-icon" />
          <div>
            <span className="taean-contribution-text1">온실가스 감소량 </span>
            {data && (
              <>
                <span className="taean-contribution-text2">{formatCo2(data.totalCo2).value}</span>
                <span className="taean-contribution-text3"> {formatCo2(data.totalCo2).unit}</span>
              </>
            )}
          </div>
        </div>
        <div className="contribution-bottom-wrapper">
          <Tree className="contribution-icon" />
          <span className="taean-contribution-text4">나무</span>
          <div>
            <span className="taean-contribution-text5">{numFormat(roundNum(data.totalTree, 1))}</span>
            <span className="taean-contribution-text6"> 그루</span>
          </div>
        </div>
        <div className="contribution-bottom-wrapper">
          <PlasticCup className="contribution-icon" />
          <span className="taean-contribution-text4">플라스틱컵</span>
          <div>
            <span className="taean-contribution-text5">{numFormat(roundNum(data.totalPlasticCup, 1))}</span>
            <span className="taean-contribution-text6"> 컵</span>
          </div>
        </div>
        <div className="contribution-bottom-wrapper">
          <PaperCup className="contribution-icon" />
          <span className="taean-contribution-text4">종이컵</span>
          <div>
            <span className="taean-contribution-text5">{numFormat(roundNum(data.totalPaperCup, 1))}</span>
            <span className="taean-contribution-text6"> 컵</span>
          </div>
        </div>
        <div className="contribution-bottom-wrapper">
          <Car className="contribution-icon" />
          <span className="taean-contribution-text4">주행거리</span>
          <div>
            <span className="taean-contribution-text5">{numFormat(roundNum(data.totalCarDistance, 1))}</span>
            <span className="taean-contribution-text6"> km</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributionOfEnvironmental;
