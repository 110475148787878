
import { useState, useEffect, memo } from 'react';
import { BeatLoader } from 'react-spinners'

import styles from './userBetweenUser.module.css';

import './userBetweenUser.css';
import UserBetweenUserSearch from './userBetweenUserSearch';
import { getUserRankList, getUserRankPage, getUserMakrRankList, getUserListExcelDownload, getUserBizYyRankList
} from '../../../../service/stats/type/userBetweenUserDataService';
import { Button , ButtonGroup } from '@progress/kendo-react-buttons';
import UserBetweenUserListChart from './userBetweenUserListChart';
import UserBetweenUserListGrid from './userBetweenUserListGrid';
import UserBetweenUserMakerChart from './userBetweenUserMakerChart';
import UserBetweenUserMakerGrid from './userBetweenUserMakerGrid';
import UserBetweenUserBizYyChart from './userBetweenUserBizYyChart';
import UserBetweenUserBizYyGrid from './userBetweenUserBizYyGrid';
import { getCalCityList, getCalRgnList } from '../../../../com/dataUtil';
// 켄도 언어 컨버터 (한글)


const UserBetweenUser = memo(({ conn, codeList, cityList, rgnList }) => {
  
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 엑셀 데이터 래퍼런스
  let [/*userExcelData*/ , setUserExcelData] = useState(null);
  let [makrExcelData , setMakrExcelData] = useState(null);

  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  yesterday.setDate( today.getDate() - 1 );

  // 검색 조건
  const [search , setSearch] = useState({
    ensoTypeCode : 15001,
    startGathDtm : yesterday,
    endGathDtm : yesterday,
    cityProvCode : "",
    city : { cityProvCode : "", cityProvTerm : "시/도" } ,
    rgnCode : "",
    rgn : {rgnTermSimple : "전체" , rgnCode : ""} ,
    userId : "",
    makrSeriNo : null,
    startMax : yesterday,
    endMin : yesterday,
    yesterday : yesterday,
    // makrDrillDown : false,
    skip : 0,
    take : 10,
    size : 10 ,
    modeType : 'makr'
  });

  // 발전량 , 생산량 조건부 렌더링
  const isCal = search.ensoTypeCode === 15002 || search.ensoTypeCode === 15003 ? true : false;

  const citys = getCalCityList(cityList);
  const rgns = getCalRgnList(rgnList.filter( e=> e.cityProvCode === search.cityProvCode ));

  // reject 함수
  // const getRejection = (error) => {
  //   setLoading(false)
  // }
  
  const [userList, setUserList] =  useState([]);
  const [userPage, setUserPage] = useState({ results : [] , total : 0 });
  const [userMakrList, setUserMakrList] = useState([]);
  const [userBizYyList , setUserBizYyList] = useState([]);
  const [makrModelList, /*setMakrModelList*/] = useState([]);

  const getUserData = (s) =>{
    setLoading(true);
    Promise.all([
      getUserRankList(conn, s), 
      getUserRankPage(conn, s), 
      getUserMakrRankList(conn, s),
      getUserBizYyRankList(conn , s)
    ])
    .then((results) => {
      setUserList(results[0]);
      setUserPage(results[1]);
      setUserMakrList(results[2]);
      setUserBizYyList(results[3]);
    })
    .catch((err) => console.log("data load Fail", err))
    .finally(() => setLoading(false));
  }

  const getUserPage = (s) =>{
    getUserRankPage(conn, s)
    .then((res) => {console.log("dd",res); setUserPage(res);}) 
    .catch((err) => console.log("data load Fail", err))
    .finally(() => setLoading(false));
  }

  const getMeainModelChartData = (s) => {
    setLoading(true); 
    getUserMakrRankList(conn, s).then((results) => {
      setUserMakrList(results);
    })
    .catch((err) => console.log("data load Fail", err))
    .finally(() => setLoading(false));
  }

  // const getMeainBizYyData = (s) => {
  //   setLoading(true);
  //   getUserBizYyRankList(conn, s).then((results) => {
  //     setUserBizYyList(results);
  //   })
  //   .catch((err) => console.log("data load Fail", err))
  //   .finally(() => setLoading(false));
  // }

  const getUserRankExcelDownload = (e) => {
    getUserListExcelDownload(search , setLoading);
  }

  // 버튼 클릭
  const clickButtonGroup = (e) => {
    setSearch( search => {
      const item = {...search};
      item.modeType = e.nativeEvent.target.name
      return item;
    });
  }

  useEffect(() => {
    getUserData(search);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={loading ? "cmpUserBetweenUser" : ""}>
          <div className="uk-grid">
              <div className="uk-width-medium-1-1">
                  <div className="md-card TBgA">
                      <div className="md-card-content small-padding">
                          <UserBetweenUserSearch codeList = {codeList} citys = {citys} rgns = {rgns} search = {search} setSearch = {setSearch} getUserData = {getUserData} />
                      </div>
                  </div>
              </div>
          </div>
          <div className="uk-grid">
              <div className="uk-width-medium-1-2">
                  <div className="md-card">
                    <div className="md-card-content">
                      <div className="md-card-toolbar">
                        <h3 className="md-card-toolbar-heading-text">TOP { isCal === false ? <> 발전량 </> : <> 생산량 </> } 분포 | 설치자 { isCal === false ? <> 발전량 </> : <> 생산량 </> } 목록</h3>
                        <div className={["md-card-toolbar-actions" , styles.buttonArea].join(' ')}>
                          <Button className="userBetweenUserExcelBtnClass" icon="excel" onClick={(e) => {
                            setLoading(true);
                            getUserRankExcelDownload();
                          }}>엑셀저장</Button>
                        </div>
                      </div>
                      <UserBetweenUserListChart userList={userList} search = {search}/>
                      <UserBetweenUserListGrid  userPage={userPage} search = {search} setUserExcelData = {setUserExcelData} setLoading={setLoading} getUserPage={getUserPage} />
                    </div> 
                  </div>
              </div>  
              <div className="uk-width-medium-1-2">
                  <div className="md-card">
                    <div className="md-card-content">
                      <div>
                        {/* <ButtonGroup selection="single" className='userBetweenUserGroupBtnClass'>
                          <>
                          <Button togglable={true} name = 'makr' selected={search.modeType === 'makr'} onClick={clickButtonGroup}>제조사</Button>
                          <Button togglable={true} name = 'bizYy' selected={search.modeType === 'bizYy'} onClick={clickButtonGroup}>사업연도</Button>
                          </>
                        </ButtonGroup> */}
                          <div className="md-card-toolbar">
                            <h3 className="md-card-toolbar-heading-text">
                              { search.modeType === 'makr' ?
                                <>
                                  { 
                                    !search.makrSeriNo ? <span>제조사간 { isCal === false ? <> 발전량 </> : <> 생산량 </> } 분포</span> : <span>설비 모델간 { isCal === false ? <> 발전량 </> : <> 생산량 </> } 분포</span>
                                  }
                                  {/* { search.makrSeriNo !== "" && <span>제조사 모델간 생산량 분포</span>}   */}
                                </>
                              :
                                <>
                                  <span>
                                    사업연도 { isCal === false ? <> 발전량 </> : <> 생산량 </> } 분포
                                  </span>
                                </>
                              }
                            </h3>
                            <div className='userBetweenUserGroupBtnClassArea'>
                              <ButtonGroup selection="single" className='userBetweenUserGroupBtnClass'>
                                <>
                                  <Button togglable={true} name = 'makr' selected={search.modeType === 'makr'} onClick={clickButtonGroup}>제조사</Button>
                                  <Button togglable={true} name = 'bizYy' selected={search.modeType === 'bizYy'} onClick={clickButtonGroup}>사업연도</Button>
                                </>
                              </ButtonGroup>
                            </div>
                            <div className={["md-card-toolbar-actions" , styles.buttonMeainBizYyArea].join(' ')}>
                              <Button className="userBetweenUserExcelBtnClassBizYyMeainExcel" icon="excel"
                              onClick={(e) => {
                                setLoading(true);
                                makrExcelData.save();
                              }}
                              >엑셀저장</Button>
                            </div>  
                          </div>
                        { search.modeType === 'makr' ?  
                        <>
                          <UserBetweenUserMakerChart userMakrList={userMakrList} search = {search} getMeainModelChartData = {getMeainModelChartData} />
                          <UserBetweenUserMakerGrid  userMakrList={userMakrList} makrModelList = {makrModelList} search = {search} setMakrExcelData = {setMakrExcelData} setLoading={setLoading}/>
                        </>
                        :    
                        <>
                          {/* <div className="md-card-toolbar">
                            <h3 className="md-card-toolbar-heading-text">
                              사업연도 생산량 분포
                            </h3>
                            <div className={["md-card-toolbar-actions" , styles.buttonArea].join(' ')}>
                              <Button className="userBetweenUserExcelBtnClass" icon="excel"
                              onClick={(e) => {
                                setLoading(true);
                                makrExcelData.save();
                              }}
                              >엑셀저장</Button>
                            </div>  
                          </div> */}
                          <UserBetweenUserBizYyChart userBizYyList={userBizYyList} search = {search} />
                          <UserBetweenUserBizYyGrid  userBizYyList={userBizYyList} search = {search} setMakrExcelData = {setMakrExcelData} setLoading={setLoading}/>
                        </>
                        }
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      {loading && <p className="cmpUserBetweenUserLoading"><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
    </>
  );
});

export default UserBetweenUser;