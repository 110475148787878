import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import ReportConsmGrid from "./reportConsmGrid";
import ConsmSearch from "./consmSearch";

import "./reportConsm.css";
import ReportConsmWarnChart from "./reportConsmWarnChart";
import styles from "./reportConsm.module.css";

function ReportConsm({ conn, cityList, rgnList }) {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  let today = new Date();
  const [selectMeain, setSelectMeain] = useState();
  const [energyData, setEnergyData] = useState([
    {
      totalPower: "-",
      accumPower: "-",
      powerTime: "-",
      powerCo2: "-",
      powerEfic: "-",
      totalCal: "-",
      accumCal: "-",
    },
  ]);

  const [energyCount, setEnergyCount] = useState([
    {
      totalCnt: "-",
      totalCapa: "-",
      solaCnt: "-",
      solaCapa: "-",
      heatCnt: "-",
      heatCapa: "-",
      heathCnt: "-",
      heathCapa: "-",
      veloCnt: "-",
      veloCapa: "-",
      fuelcellCnt: "-",
      fuelcellCapa: "-",
      essCnt: "-",
      essCapa: "-",
    },
  ]);

  const [chartWarn, setChartWarn] = useState({
    unOperCnt: [],
    waringCnt: [],
    errorCnt: [],
  });

  const [chartMonthData, setChartMonthData] = useState({
    energy: [],
    preEnergy: [],
  });

  const [chartYearData, setChartYearData] = useState({
    energy: [],
    preEnergy: [],
  });

  // 검색 조건
  const [search, setSearch] = useState({
    dtm: today,
    consmId: "",
    userTerm: "",
  });

  return (
    <>
      <div className="consmDiv">
        <div className={["consmDivIn", loading ? styles.reportConsmG : ""].join(" ")}>
          <ConsmSearch
            conn={conn}
            cityList={cityList}
            rgnList={rgnList}
            search={search}
            setSearch={setSearch}
            selectMeain={selectMeain}
            setSelectMeain={setSelectMeain}
            energyData={energyData}
            setEnergyData={setEnergyData}
            chartMonthData={chartMonthData}
            chartWarn={chartWarn}
            setChartWarn={setChartWarn}
            setChartMonthData={setChartMonthData}
            chartYearData={chartYearData}
            setChartYearData={setChartYearData}
            energyCount={energyCount}
            setEnergyCount={setEnergyCount}
            setLoading={setLoading}
          />
          <div className="uk-grid">
            <div className="uk-width-medium-4-10">
              <table className="reportConsmEnergyTable">
                <thead>
                  <th colSpan="3" style={{ color: "#87CEEB" }}>
                    전기 에너지
                  </th>
                </thead>
                <tbody>
                  <table>
                    <tr>
                      <th scope="col"> 월간 발전량 </th>
                      <th scope="col"> 누적 발전량</th>
                    </tr>
                    <tr>
                      <td><span className = {styles.dataLabel}>{energyData[0].totalPower}</span>&nbsp;kWh</td>
                      <td><span className = {styles.dataLabel}>{energyData[0].accumPower}</span>&nbsp;kWh</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th scope="col">발전시간</th>
                      <th scope="col">누적 CO2 저감량</th>
                      <th scope="col">발전효율</th>
                    </tr>
                    <tr>
                      <td><span className = {styles.dataLabel}>{energyData[0].powerTime}</span>&nbsp;시간</td>
                      <td><span className = {styles.dataLabel}>{energyData[0].powerCo2}</span>&nbsp;t</td>
                      <td><span className = {styles.dataLabel}>{energyData[0].powerEfic}</span>&nbsp;%</td>
                    </tr>
                  </table>
                </tbody>
              </table>
            </div>
            <div className="uk-width-medium-3-10">
              <table className="reportConsmEnergyTable">
                <thead>
                  <th colSpan="3" style={{ color: "#9ACD32" }}>
                    열 에너지
                  </th>
                </thead>
                <tbody>
                  <table>
                    <tr>
                      <th scope="col"> 월일 생산열량 </th>
                    </tr>
                    <tr>
                      <td><span className = {styles.dataLabel}>{energyData[0].totalCal}</span>&nbsp;kWh</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th scope="col">누적생산열량</th>
                    </tr>
                    <tr>
                      <td><span className = {styles.dataLabel}>{energyData[0].accumCal}</span>&nbsp;kWh</td>
                    </tr>
                  </table>
                </tbody>
              </table>
            </div>
            <div className="uk-width-medium-3-10">
              <table className="reportConsmEnergyTable">
                <thead>
                  <th colSpan="3" style={{ color: "#F08080" }}>
                    ESS
                  </th>
                </thead>
                <tbody>
                  <table>
                    <tr>
                      <th scope="colgroup">충전시간</th>
                    </tr>
                    <tr>
                      <td> - &nbsp;kWh</td>
                      <td> - &nbsp;시간</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th scope="col">누적충전량</th>
                      <th scope="col">충전효율</th>
                    </tr>
                    <tr>
                      <td> - &nbsp;kWh</td>
                      <td> - &nbsp;%</td>
                    </tr>
                  </table>
                </tbody>
              </table>
            </div>
          </div>
          <div className="uk-grid">
            <div className="uk-width-medium-1">
              <table className="reportConsmEnergyTable">
                <thead>
                  <th colSpan="3" style={{ color: "#369" }}></th>
                </thead>
                <tbody>
                  <table>
                    <tr>
                      <th scope="col">전체&nbsp;{energyCount[0].totalCnt}&nbsp;개소</th>
                      <th scope="col">태양광&nbsp;{energyCount[0].solaCnt}&nbsp;개소</th>
                      <th scope="col">태양열&nbsp;{energyCount[0].heatCnt}&nbsp;개소</th>
                      <th scope="col">지열&nbsp;{energyCount[0].heathCnt}&nbsp;개소</th>
                      <th scope="col">풍력&nbsp;{energyCount[0].veloCnt}&nbsp;개소</th>
                      <th scope="col">연료전지&nbsp;{energyCount[0].fuelcellCnt}&nbsp;개소</th>
                      <th scope="col">ESS&nbsp;{energyCount[0].essCnt}&nbsp;개소</th>
                    </tr>
                    <tr>
                      <td>설치용량&nbsp;&nbsp;{energyCount[0].totalCapa}&nbsp;kW</td>
                      <td>{energyCount[0].solaCapa}&nbsp;kW</td>
                      <td>{energyCount[0].heatCapa}&nbsp;㎡</td>
                      <td>{energyCount[0].heathCapa}&nbsp;kW</td>
                      <td>{energyCount[0].veloCapa}&nbsp;kW</td>
                      <td>{energyCount[0].fuelcellCapa}&nbsp;kW</td>
                      <td>{energyCount[0].essCapa}&nbsp;kW</td>
                    </tr>
                  </table>
                </tbody>
              </table>
            </div>
          </div>
          <ReportConsmWarnChart chartWarn={chartWarn} />
          <ReportConsmGrid selectMeain={selectMeain} chartMonthData={chartMonthData} chartYearData={chartYearData} />
        </div>
      </div>
      {loading && (
        <p className={styles.reportConsmLoading}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
}

export default ReportConsm;
