
// import React, { useState } from 'react';

// Kendo
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import koMessage from "../../../../com/ko-language.json";
import { addressFormatFixFuc } from '../commonFuc';
loadMessages(koMessage, "ko-KR");

/**
 * 융복합지원사업 - 설치계획서 목록
*/
const BizManagementContentGrid = ({ 
  Grid, Column, GridNoRecords, convMngtStyles, search, meainList, getMngtMeainDataList, setDataList, getMngtMeainInfoData 
  , getSelectMeainInfoOrAsHistByTab ,filterInitialList
}) => {

  // 로딩스피너
  // const [loading, setLoading] = useState(false);
  const loading = false;
  

  // 로우 클릭 이벤트
  const onRowClick = (item) => {
    const data = item.dataItem;
    console.log("data = ", data);
    search.instlPlnnm = data.instlPlnnm;
    search.mngt.energyTypeCode = data.energyTypeCode;
    filterInitialList();
    setDataList( dataList => {
      const item = {...dataList};
      item.meainInfo = data;
      return item;
    });

    // 탭별로 데이터 불러오기 
    getSelectMeainInfoOrAsHistByTab();

    // 설비 정보 불러오기
    // getMngtMeainInfoData();
  }

  // 페이징 이벤트
  const onPageChange = (e) => {
    let { take, skip } = e.page;
    search.mngt.paging.take = take;
    search.mngt.paging.skip = skip;
    getMngtMeainDataList();
  }

  // 그리드 에너지원 / 용량 표시 포맷
  const dateFormatCell = (props) => {
    var result = '';
    var data = props.dataItem;
    if( data.energyTypeTerm != null ) {
      if( data.moniYn === 'Y' ){
        result = "모니터링 고도화 ( "  + data.energyTypeTerm + '( ' + data.instCapa + ' ' + data.unit + ' ) )';
      }else{
        result = data.energyTypeTerm + '( ' + data.instCapa + ' ' + data.unit + ' )';
      }
    }
    return <td>{result}</td>
  }

  // 상세 주소 포맷 설정
  const getAddressFormatDataCell = (data) => {
    return addressFormatFixFuc( data, search );
  } 

  // 랜더링
  return (
    <div className="uk-width-medium-1-2">
      <div className="md-card">
        <div className="md-card-content">
          <div className="md-card-toolbar-conv">
            <h3 className="md-card-toolbar-heading-text-left">
              설치계획서 목록
              { <> <span style={{fontSize : "13px",  marginLeft : "5px" }}> [{search.navi}] </span> </> }
            </h3>
            </div> 
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <div className = { loading ? convMngtStyles.meainListArea : ""}>
                    <Grid
                      id = "grid" 
                      data = { meainList.list.map( (item) => ( 
                        {...item , selected : item.instlPlnnm === search.instlPlnnm && item.energyTypeCode === search.mngt.energyTypeCode}
                      ))}
                      style={{height:"535px"}}
                      selectedField="selected"
                      onRowClick = {onRowClick}
                      pageable={ {  type: 'numeric', pageSizes: [5, 10, 20, 50, 100], previousNext: true, buttonCount: 5, refresh: true } }
                      onPageChange = {onPageChange}
                      total = {meainList.total}
                      skip = {search.mngt.paging.skip}
                      take = {search.mngt.paging.take}
                  >
                      <Column field="instlTerm" title="신청자(건물)명" width = {250} />
                      <Column field="instlAddr" title="설치장소" cell = {getAddressFormatDataCell} />
                      <Column field="ensoTypeTermInstCapa" title="에너지원 / 용량" width = {150} cell = {dateFormatCell} />
                      <GridNoRecords>
                        데이터가 존재하지 않습니다.
                      </GridNoRecords>
                  </Grid>
              </div>
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </div>
    </div>

  );
};

export default BizManagementContentGrid;