// 설비 버튼 리스트
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";

const CmpUserByUserMeainList = ({ meainList, search, getDataListByMeain }) => {
  // 버튼 클릭 이벤트
  const onClickEvt = e => {
    const meainInfo = JSON.parse(e.target.value);
    search.cid = meainInfo.cid;
    search.ensoTypeCode = meainInfo.ensoTypeCode;
    getDataListByMeain();
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="md-card TBgA">
          <div className="md-card-content small-padding cmpBtnGrp">
            <ButtonGroup>
              {meainList.map(e => {
                return (
                  <Button
                    className="meainItemBtn"
                    onClick={onClickEvt}
                    value={JSON.stringify({ cid: e.cid, ensoTypeCode: e.ensoTypeCode })}
                    togglable={true}
                    selected={Number(search.cid) === e.cid}>
                    {e.cid}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmpUserByUserMeainList;
