import NonsanTitle from "../NonsanTitle";
import { ReactComponent as Bunpo } from "dist/svg/asan/bunpo.svg";
import "./index.css";
import { nonsanMapPath } from "../constant/nonsanMapPath";
import { LocalArea } from "components/localArea/asan/components/AsanEnergy/AsanEnergy";
import { useState, useEffect } from "react";
import { useCreateAxiosInstance } from "conInstance";
import { NONSAN_CODE } from "..";
import NonsanTooltip from "./NonsanTooltip";

export default function NonsanEnergy() {
  const [mouseLocation, setMouseLocation] = useState({ x: 0, y: 0, info: {} });
  const [isOver, setIsOver] = useState(false);
  const [data, setData] = useState();
  const { conn } = useCreateAxiosInstance();

  const getMouseLocation = e => {
    const findDong = data.filter(item => Number(item.admnsBundCode) === Number(e.target.id));
    setMouseLocation({
      x: e.clientX,
      y: e.clientY,
      info: data
        ? {
            dongElecPower: findDong[0].dongElecPower,
            dongUsePower: findDong[0].dongUsePower,
            meainCount: findDong[0].meainCount,
            name: findDong[0].admnsBundTermS,
          }
        : {},
    });
    setIsOver(true);
  };

  const removeTooltip = () => {
    setIsOver(false);
  };

  const getGenerateAmount = async () => {
    const response = await conn.get(`monitor/installer/munic/dongEnergyData?rgnCode=${NONSAN_CODE}`);
    setData(response);
  };

  useEffect(() => {
    getGenerateAmount();
    // eslint-disable-next-line
  }, []);

  if (!data) return <div />;

  const nonsanDongList = nonsanMapPath.map(path => {
    const find = data.find(item => Number(item.admnsBundCode) === Number(path.fixbylawBundCode));
    const isZero = find ? find.dongUsePower + find.dongElecPower === 0 : true; // 만약 두 에너지의 합이 0이면 최하 색상 표시
    return find ? { ...path, ratio: isZero ? 1 : find.dongRanking / data.length, name: find.admnsBundTermS } : { ...path };
  });

  console.log(isOver);

  return (
    <div className="asan-energy-container">
      <NonsanTitle title="구역별 신재생에너지 현황" />
      {isOver && <NonsanTooltip x={mouseLocation.x} y={mouseLocation.y} info={mouseLocation.info} />}
      <div className="nonsan-map">
        <svg width="680" height="450" viewBox="0 0 878 685" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseOut={removeTooltip}>
          {nonsanDongList.map(area => (
            <LocalArea
              filter={area.filter}
              d={area.d}
              onMouseOver={getMouseLocation}
              ratio={area.ratio}
              fixbylawBundCode={area.fixbylawBundCode}
            />
          ))}
          <text x='49%' y='17%' fontSize="24px">
            상월면
          </text>
          <text x='32%' y='18%' fontSize="24px">
            노성면
          </text>
          <text x='25%' y='36%' fontSize="24px">
            광석면
          </text>
          <text x='24%' y='54%' fontSize="24px">
            부창동
          </text>
          <text x='31%' y='52%' fontSize="24px">
            취암동
          </text>
          <text x='43%' y='48%' fontSize="24px">
            부적면
          </text>
          <text x='58%' y='40%' fontSize="24px">
            연산면
          </text>
          <text x='10%' y='50%' fontSize="24px">
            성동면
          </text>
          <text x='83%' y='54%' fontSize="24px">
            벌곡면
          </text>
          <text x='68%' y='72%' fontSize="24px">
            양촌면
          </text>
          <text x='50%' y='70%' fontSize="24px">
            가야곡면
          </text>
          <text x='31%' y='82%' fontSize="24px">
            연무읍
          </text>
          <text x='17%' y='62%' fontSize="24px">
            채운면
          </text>
          <text x='31%' y='65%' fontSize="24px">
            은진면
          </text>
          <text x='5%' y='68%' fontSize="24px">
            강경읍
          </text>
          <defs>
            <filter
              id="filter0_d_509_13259"
              x="203.575"
              y="0"
              width="197.873"
              height="254.291"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter1_d_509_13259"
              x="336.326"
              y="12.2788"
              width="222.252"
              height="249.657"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter2_d_509_13259"
              x="124.562"
              y="154.994"
              width="256.778"
              height="170.836"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter3_d_509_13259"
              x="0"
              y="256.525"
              width="241.12"
              height="182.782"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter4_d_509_13259"
              x="382.416"
              y="153.735"
              width="268.697"
              height="244.341"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter5_d_509_13259"
              x="627.453"
              y="234.717"
              width="250.547"
              height="313.107"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter6_d_509_13259"
              x="476.729"
              y="329.742"
              width="322.438"
              height="305.889"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter7_d_509_13259"
              x="359.994"
              y="384.017"
              width="197.161"
              height="251.08"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter8_d_509_13259"
              x="263.9"
              y="256.605"
              width="247.663"
              height="147.157"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter9_d_509_13259"
              x="241.123"
              y="305.719"
              width="119.753"
              height="111.389"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter10_d_509_13259"
              x="172.078"
              y="308.033"
              width="117.262"
              height="98.9795"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter11_d_509_13259"
              x="227.776"
              y="349.673"
              width="183.104"
              height="169.935"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter12_d_509_13259"
              x="156.943"
              y="463.348"
              width="273.867"
              height="221.576"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter13_d_509_13259"
              x="75.8066"
              y="383.512"
              width="192.891"
              height="119.191"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <filter
              id="filter14_d_509_13259"
              x="33.8105"
              y="422.455"
              width="111.033"
              height="82.5613"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_509_13259" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_509_13259" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_509_13259" x1="302.512" y1="0" x2="302.512" y2="246.291" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient id="paint1_linear_509_13259" x1="447.452" y1="12.2788" x2="447.452" y2="253.936" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EDFFD7" />
              <stop offset="1" stop-color="#D6F2B3" />
            </linearGradient>
            <linearGradient id="paint2_linear_509_13259" x1="252.951" y1="154.994" x2="252.951" y2="317.83" gradientUnits="userSpaceOnUse">
              <stop stop-color="#4EAB15" />
              <stop offset="1" stop-color="#327907" />
            </linearGradient>
            <linearGradient id="paint3_linear_509_13259" x1="120.56" y1="256.525" x2="120.56" y2="431.307" gradientUnits="userSpaceOnUse">
              <stop stop-color="#94CD4B" />
              <stop offset="1" stop-color="#6CAA1C" />
            </linearGradient>
            <linearGradient id="paint4_linear_509_13259" x1="516.765" y1="153.735" x2="516.765" y2="390.076" gradientUnits="userSpaceOnUse">
              <stop stop-color="#94CD4B" />
              <stop offset="1" stop-color="#6CAA1C" />
            </linearGradient>
            <linearGradient id="paint5_linear_509_13259" x1="752.726" y1="234.717" x2="752.726" y2="539.824" gradientUnits="userSpaceOnUse">
              <stop stop-color="#4EAB15" />
              <stop offset="1" stop-color="#327907" />
            </linearGradient>
            <linearGradient id="paint6_linear_509_13259" x1="637.949" y1="329.742" x2="637.949" y2="627.631" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient id="paint7_linear_509_13259" x1="458.575" y1="384.017" x2="458.575" y2="627.097" gradientUnits="userSpaceOnUse">
              <stop stop-color="#4EAB15" />
              <stop offset="1" stop-color="#327907" />
            </linearGradient>
            <linearGradient id="paint8_linear_509_13259" x1="387.732" y1="256.605" x2="387.732" y2="395.762" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient id="paint9_linear_509_13259" x1="301" y1="305.719" x2="301" y2="409.109" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EDFFD7" />
              <stop offset="1" stop-color="#D6F2B3" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_509_13259"
              x1="230.709"
              y1="308.033"
              x2="230.709"
              y2="399.012"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#94CD4B" />
              <stop offset="1" stop-color="#6CAA1C" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_509_13259"
              x1="319.328"
              y1="349.673"
              x2="319.328"
              y2="511.608"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_509_13259"
              x1="293.877"
              y1="463.348"
              x2="293.877"
              y2="676.924"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#94CD4B" />
              <stop offset="1" stop-color="#6CAA1C" />
            </linearGradient>
            <linearGradient
              id="paint13_linear_509_13259"
              x1="172.252"
              y1="383.512"
              x2="172.252"
              y2="494.703"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient
              id="paint14_linear_509_13259"
              x1="89.3272"
              y1="422.455"
              x2="89.3272"
              y2="497.016"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#EDFFD7" />
              <stop offset="1" stop-color="#D6F2B3" />
            </linearGradient>
          </defs>
        </svg>

        <div className="bunpo">
          <div>발전량 분포</div>
          <div>
            <Bunpo id="bunpo-degree" />
            <div className="bunpo-explain">
              <span>낮음</span>
              <span>높음</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
