import React from "react";
import { numFormat, roundNum } from "./../../../com/dataUtil";

const LocationMunicTable = props => {
  const { gridData } = props;
  // 추후 리팩토링할 예정임 (2022-11-17 권영서)
  let data = {
    sola: {
      syncCount: 0,
      syncCapa: 0,
      asyncCount: 0,
      asyncCapa: 0,
    },
    heat: {
      syncCount: 0,
      syncCapa: 0,
      asyncCount: 0,
      asyncCapa: 0,
    },
    heath: {
      syncCount: 0,
      syncCapa: 0,
      asyncCount: 0,
      asyncCapa: 0,
    },
    etc: {
      syncCount: 0,
      syncCapa: 0,
      asyncCount: 0,
      asyncCapa: 0,
    },
    subSum: {
      syncCount: 0,
      asyncCount: 0,
      syncKwCapa: 0,
      asyncKwCapa: 0,
      syncM2Capa: 0,
      asyncM2Capa: 0,
    },
    totalSum: {
      count: 0,
      kwCapa: 0,
      m2Capa: 0,
    },
  };
  if (gridData.length) {
    data.subSum.syncKwCapa += gridData[0].syncInstCapa + gridData[1].syncInstCapa + gridData[3].syncInstCapa;
    data.subSum.asyncKwCapa += gridData[0].asyncInstCapa + gridData[1].asyncInstCapa + gridData[3].asyncInstCapa;
    data.subSum.syncM2Capa += gridData[2].syncInstCapa;
    data.subSum.asyncM2Capa += gridData[2].asyncInstCapa;
    data.totalSum.kwCapa += data.subSum.syncKwCapa + data.subSum.asyncKwCapa;
    data.totalSum.m2Capa += data.subSum.syncM2Capa + data.subSum.asyncM2Capa;
  }

  const getTotalSum = (item, type, sync) => {
    let syncRes = 0;
    let asyncRes = 0;
    if (type === "count") {
      item.forEach(e => (syncRes += e.syncCount));
      item.forEach(e => (asyncRes += e.asyncCount));
    } else if (type === "capa") {
      item.forEach(e => (syncRes += e.syncInstCapa));
      item.forEach(e => (asyncRes += e.asyncInstCapa));
    }
    if (sync === "Y") return numFormat(roundNum(syncRes, 1));
    else if (sync === "N") return numFormat(roundNum(asyncRes, 1));
    else if (sync === "ALL") return numFormat(roundNum(syncRes + asyncRes, 1));
    else return 0;
  };
  return (
    <div>
      <table className="locMunicRghtTbl">
        <thead>
          <tr>
            <th className="tg-0pky" rowspan="2">
              에너지원
            </th>
            <th className="tg-0lax" colspan="2">
              개수
            </th>
            <th className="tg-0lax" colspan="2">
              용량
            </th>
          </tr>
          <tr>
            <th className="tg-0lax">미연동</th>
            <th className="tg-0lax">연동</th>
            <th className="tg-0lax">미연동</th>
            <th className="tg-0lax">연동</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tg-0lax">태양광</td>
            <td className="tg-0lax">{gridData.length ? numFormat(gridData[1].asyncCount) : 0}</td>
            <td className="tg-0lax">{gridData.length ? numFormat(gridData[1].syncCount) : 0}</td>
            <td className="tg-0lax">{gridData.length ? numFormat(roundNum(gridData[1].asyncInstCapa, 1)) : 0}&nbsp;kW</td>
            <td className="tg-0lax">{gridData.length ? numFormat(roundNum(gridData[1].syncInstCapa, 1)) : 0}&nbsp;kW</td>
          </tr>
          <tr>
            <td className="tg-0lax">태양열</td>
            <td className="tg-0lax">{gridData.length ? numFormat(gridData[2].asyncCount) : 0}</td>
            <td className="tg-0lax">{gridData.length ? numFormat(gridData[2].syncCount) : 0}</td>
            <td className="tg-0lax">{gridData.length ? numFormat(roundNum(gridData[2].asyncInstCapa, 1)) : 0}&nbsp;m²</td>
            <td className="tg-0lax">{gridData.length ? numFormat(roundNum(gridData[2].syncInstCapa, 1)) : 0}&nbsp;m²</td>
          </tr>
          <tr>
            <td className="tg-0lax">지열</td>
            <td className="tg-0lax">{gridData.length ? numFormat(gridData[3].asyncCount) : 0}</td>
            <td className="tg-0lax">{gridData.length ? numFormat(gridData[3].syncCount) : 0}</td>
            <td className="tg-0lax">{gridData.length ? numFormat(roundNum(gridData[3].asyncInstCapa, 1)) : 0}&nbsp;kW</td>
            <td className="tg-0lax">{gridData.length ? numFormat(roundNum(gridData[3].syncInstCapa, 1)) : 0}&nbsp;kW</td>
          </tr>
          <tr>
            <td className="tg-0lax">기타</td>
            <td className="tg-0lax">{gridData.length ? numFormat(gridData[0].asyncCount) : 0}</td>
            <td className="tg-0lax">{gridData.length ? numFormat(gridData[0].syncCount) : 0}</td>
            <td className="tg-0lax">{gridData.length ? numFormat(roundNum(gridData[0].asyncInstCapa, 1)) : 0}&nbsp;kW</td>
            <td className="tg-0lax">{gridData.length ? numFormat(roundNum(gridData[0].syncInstCapa, 1)) : 0}&nbsp;kW</td>
          </tr>
          <tr>
            <td className="tg-0lax">소계</td>
            <td className="tg-0lax">{getTotalSum(gridData, "count", "N")}</td>
            <td className="tg-0lax">{getTotalSum(gridData, "count", "Y")}</td>
            <td className="tg-0lax">
              {numFormat(roundNum(data.subSum.asyncKwCapa, 1))}&nbsp;kW
              <br />
              {numFormat(roundNum(data.subSum.asyncM2Capa, 1))}&nbsp;m²
            </td>
            <td className="tg-0lax">
              {numFormat(roundNum(data.subSum.syncKwCapa, 1))}&nbsp;kW
              <br />
              {numFormat(roundNum(data.subSum.syncM2Capa, 1))}&nbsp;m²
            </td>
          </tr>
          <tr>
            <td className="tg-0lax">총계</td>
            <td className="tg-0lax" colspan="2">
              {getTotalSum(gridData, "count", "ALL")}
            </td>
            <td className="tg-0lax" colspan="2">
              {numFormat(roundNum(data.totalSum.kwCapa, 1))}&nbsp;kW
              <br />
              {numFormat(roundNum(data.totalSum.m2Capa, 1))}&nbsp;m²
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LocationMunicTable;
