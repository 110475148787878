import { ReactComponent as NonsanTitle } from "../svg/nonsan-shortcut-icon.svg";
import { ReactComponent as Monitor } from "dist/svg/monitor.svg";
import "./index.css";

export default function ShortCut() {
  return (
    <div>
      <div className="divide" />
      <div className="shortcut nonsan-monitoring">
        <a className="monitoring-container" href="https://kremc.kr/">
          <div className="all-monitoring">
            <div>통합</div>
            <div>모니터링</div>
          </div>
          <div>
            <Monitor className="monitor" />
          </div>
          <div className="nonsansi-shortcut">바로가기</div>
        </a>
      </div>
      <a className="shortcut nonsansi" href="https://nonsan.go.kr/">
        <NonsanTitle className="nonsan-title-white" />
        <div className="nonsansi-shortcut">바로가기</div>
      </a>
    </div>
  );
}
