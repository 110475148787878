import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
// import { deleteFileList } from "../../../../../service/bizStat/com/bizFileSupportService";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Upload } from "@progress/kendo-react-upload";

/**
 * 융복합지원사업 - 관리현황 - 관리이력 - AS 보수 이력 추가
 */
const BizManagementRepairAdd = ({
  Button,
  Input,
  setAddStatus,
  asData,
  setAsData,
  convMngtStyles,
  initialAsData,
  saveMeainAsInfoData,
  getMeainAsList,
  kendo,
  search,
  conn,
  getMngtMeainAsList,
  token,
  fileInfo,
  asHistList,
  DatePickerKo
}) => {
  // const fileInputs = useRef(null);
  // let fileInputName = "";

  // API 주소 값
  const ev = `${process.env.REACT_APP_REST_API_VERSION}`;

  // 업로드 파일 창 제어
  // const [uploadStat, setUploadStat] = useState(false);

  // 파일
  const [fileState, setFileState] = useState({
    fileList: [],
    currentFiles: [],
    docuCodeNo: "",
    energyTypeCode: "",
  });

  // 파일 경로
  const saveFileUrl =
    ev +
    "/biz/management/file/as?fileInfo=" +
    encodeURI(JSON.stringify(fileState.currentFiles)) +
    "&instlPlnnm=" +
    asData.instlPlnnm +
    "&energyTypeCode=" +
    search.mngt.energyTypeCode +
    "&regiDtm=" +
    asData.regiDtm +
    "&asReason=" +
    asData.asReason +
    "&bizSctCode=" +
    search.bizSctCode;
  const removeFileUrl = ev + "/biz/file/delete";

  // 파일 추가
  const onAddFile = e => {
    setFileState(state => {
      const item = { ...state };
      item.currentFiles = e.affectedFiles;
      return item;
    });
  };

  // 업로드 한 파일 삭제
  const onRemoveFile = e => {
    let removeFileIds = [];
    let updateFiles = [];
    fileState.fileList.forEach(f => {
      e.affectedFiles.forEach(v => {
        if (v.uid === f.uid && v.name === f.name)
          removeFileIds.push({
            instlPlnnm: f.instlPlnnm,
            bizDocuCodeNo: f.bizDocuCodeNo,
            docuCodeNo: f.docuCodeNo,
            energyTypeCode: f.energyTypeCode,
          });
      });

      e.newState.forEach(n => {
        if (f.uid === n.uid && f.name === n.name) updateFiles.push(f);
      });
    });
  };

  // 파일 업로드
  const onStatusChangeFile = e => {
    if (e.response?.response) {
      let list = [...fileState.fileList, ...e.response.response.data];
      setFileState(state => {
        const item = { ...state };
        item.fileList = list;
        item.currentFiles = e.response.response;
        return item;
      });

      // INFO 업로드 버튼 클릭 시 파일 정보 설정
      if (e.response.response.status === 200) {
        asData.fileId = e.response.response.data[0].fileId;
        asData.fileName = e.response.response.data[0].fileName;
        asData.savePath = e.response.response.data[0].savePath;
        asData.saveName = e.response.response.data[0].saveName;
        asData.fileExt = e.response.response.data[0].extension;
        asData.fileSize = e.response.response.data[0].size;
        uploadCloseWindow();
        kendo.alert("업로드되었습니다.");
        getMeainAsList();
      }
    }
  };

  // 업로드 취소 버튼 클릭 시 창 닫기
  const uploadCloseWindow = () => {
    fileInfo.selectId = "";
    // setUploadStat(false);
  };

  // 파일 삭제
  // const deleteFile = id => {
  //   kendo
  //     .confirm("해당 파일을 삭제 하시겠습니까?")
  //     .then(e => {
  //       let removeFileIds = [
  //         {
  //           instlPlnnm: search.instlPlnnm,
  //           docuCodeNo: id,
  //           bizDocuCodeNo: 38006,
  //           energyTypeCode: search.mngt.energyTypeCode,
  //         },
  //       ];
  //       deleteFileList(conn, removeFileIds)
  //         .then(r => {
  //           kendo.alert("삭제되었습니다.");
  //           getMngtMeainAsList();
  //         })
  //         .catch(e => {
  //           kendo.alert("삭제되지 않았습니다.");
  //         });
  //     })
  //     .catch(e => {
  //       kendo.alert("삭제를 취소 하였습니다.");
  //       return;
  //     });
  // };

  // 취소 버튼 이벤트
  const cancelClickEvt = () => {
    initialAsData();
    setAddStatus(false);
  };

  // 입력 이벤트
  const inputChange = e => {
    setAsData(asData => {
      const item = { ...asData };
      item.asReason = e.value;
      return item;
    });
  };

  // 추가 버튼 이벤트
  const saveClickEvt = e => {
    saveMeainAsInfoData(asData);
    initialAsData();
    setAddStatus(false);
  };

  // 날짜 포맷 지정
  const dateFormat = date => {
    if (typeof date !== "undefined") {
      if (date !== "") {
        date = new Date(date);
      } else {
        date = "";
      }
    } else {
      date = "";
    }
    return date;
  };

  return (
    <>
      <Dialog width={600} height={270}>
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar-conv">
              <h3 className="md-card-toolbar-heading-text">{asData.createYn === "Y" ? "AS 보수 이력 추가" : "AS 보수 이력 수정"}</h3>
            </div>
            <div className={convMngtStyles.asHistInputArea}>
              <table className={convMngtStyles.asHistTableArea}>
                <thead>
                  <col width="25%" />
                  <col />
                </thead>
                <tbody>
                  <tr>
                    <th> 접수 일자 : </th>
                    <td> 
                      {" "}
                      {/* {asData.regiDtm}  */}
                      <DatePickerKo 
                            id = "asExpiDtm" name = "asExpiDtm" className={convMngtStyles.asHistInput}
                            value = {dateFormat(asData.regiDtm)} 
                            format="yyyy-MM-dd"
                            formatPlaceholder={{ year: '년', month: '월', day: '일' }}
                            setDtm = {(v) => { 
                                setAsData(asData => {
                                      const item = { ...asData };
                                      item.regiDtm = v;
                                      return item;
                                });
                            }}
                            disabled = {asData.createYn === "Y" ? false : true}
                            // required = {true} validationMessage = {inputValidMsg.normalText}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th> 처리 내용 : </th>
                    <td>
                      {" "}
                      <Input className={convMngtStyles.asHistInput} value={asData.asReason} onChange={inputChange} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <th> 파일 업로드 : </th>
                    <td>
                      <LocalizationProvider language="ko-KR">
                        <IntlProvider locale="ko">
                          <Upload
                            className={convMngtStyles.uploadMeainMngt}
                            autoUpload={false}
                            batch={true}
                            multiple={false}
                            files={fileState.fileList}
                            defaultFiles={[]}
                            onAdd={onAddFile}
                            onRemove={onRemoveFile}
                            onStatusChange={onStatusChangeFile}
                            withCredentials={true}
                            removeVerb={"DELETE"}
                            restrictions={{
                              allowedExtensions: [".gif", ".jpg", ".png", ".zip"],
                              maxFileSize: 51200000,
                            }}
                            saveMethod={"post"}
                            saveHeaders={{
                              "Content-Type": "multipart/form-data",
                              "X-AUTH-TOKEN": token,
                            }}
                            saveUrl={saveFileUrl}
                            removeUrl={removeFileUrl}
                          />
                        </IntlProvider>
                      </LocalizationProvider>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={convMngtStyles.asHistAddBtnArea}>
              {/* <Button className={convMngtStyles.addFileBtn} onClick = {() => { uploadSetClickEvt("MA") }} style ={{marginRight: "10px"}}>사진업로드</Button> */}
              <Button className={convMngtStyles.asHistSaveBtn} onClick={saveClickEvt}>
                저장
              </Button>
              <Button className={convMngtStyles.asHistCancelBtn} onClick={cancelClickEvt}>
                취소
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default BizManagementRepairAdd;
