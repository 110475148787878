// React
import React, { useState, useEffect, useCallback } from "react";
import log from "../../../com/log";
import { parseDate } from "../../../com/dateUtil";
import { getBatchProcHist, requestBatch } from "../../../service/sys/sysBatchProcHistService";

// Kendo
import kendo from "@progress/kendo-ui";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";

// Css
import "./batchProcHist.css";
import styles from "./batchProcHist.module.css";
import DatePickerKo from "../../com/dateInputs/datePickerKo";

// 로딩 스피너
import { BeatLoader } from "react-spinners";

function BatchProcHist({ conn, codeList }) {
  const [loading, setLoading] = useState(false);
  // 배치상태 데이터
  const btchStats = codeList.filter((codeItem) => codeItem.grpCodeNo === 13);

  // 에너지원 데이터
  const engList = codeList.filter((codeItem) => codeItem.grpCodeNo === 15);

  const [dataSource, setDataSource] = useState([]);
  const [dataState, setDataState] = useState({
    take: 20,
    skip: 0,
    total: 0,
    sort: [],
  });
  const [cateState, setCateState] = useState({
    trmeRsltCodeNo: 13003,
    startDate: new Date(),
    endDate: new Date(),
  });
  const [modalForm, setModalForm] = useState({
    visible: false,
    engData: "",
    searchDate: new Date(),
  });

  useEffect(() => {
    setLoading(true);
    getBatchProcHist(conn, dataState, btchStats, cateState)
      .then((response) => {
        const { results, total } = response;
        setDataSource(results);
        setDataState({ ...dataState, total });
        setLoading(false);
      })
      .catch((err) => {
        log(`useEffect.getBatchProcHist error: ${err}`);
      });
      // eslint-disable-next-line
  }, []);

  // 배치상태 변경 이벤트
  const handleBtchStats = useCallback(
    (e) => {
      const { value } = e.target;
      const btchCode = btchStats.filter((btchItem) => btchItem.codeVal === value)[0].codeNo;
      setCateState({ ...cateState, trmeRsltCodeNo: btchCode });
    },
    // eslint-disable-next-line
    [cateState]
  );

  // 요청일시 시작일 변경 이벤트
  const handleStartDateChange = useCallback(
    (e) => {
      const { value } = e.target;
      setCateState({ ...cateState, startDate: parseDate("yyyy-mm-dd", value) });
    },
    [cateState]
  );

  // 요청일시 끝일 변경 이벤트
  const handleEndDateChange = useCallback(
    (e) => {
      const { value } = e.target;
      if (new Date(cateState.startDate).getTime() > new Date(value).getTime()) {
        setCateState({
          ...cateState,
          startDate: parseDate("yyyy-mm-dd", value),
          endDate: parseDate("yyyy-mm-dd", value),
        });
      } else {
        setCateState({ ...cateState, endDate: parseDate("yyyy-mm-dd", value) });
      }
    },
    [cateState]
  );

  // 조회버튼
  const handleSubmitBtn = useCallback(
    (e) => {
      dataState.take = 20;
      dataState.skip = 0;

      getBatchProcHist(conn, dataState, btchStats, cateState)
        .then((response) => {
          const { results, total } = response;
          setDataSource(results);
          setDataState({ ...dataState, total });
        })
        .catch((err) => {
          log(`batchProcHist.handleSubmitBtn error: ${err}`);
        });
    },
    // eslint-disable-next-line
    [cateState]
  );

  // 그리드 페이징 처리
  const handlePageChange = useCallback(
    (e) => {
      const { take, skip } = e.page;
      const { sort } = dataState;

      const stateObj = {
        take : take,
        skip : skip
      };

      if (sort[0] !== undefined) {
        stateObj.sort = sort[0].field + "," + sort[0].dir;
      } else {
        delete stateObj.sort;
      }

      getBatchProcHist(conn, stateObj, btchStats, cateState)
        .then((response) => {
          const { results, total } = response;
          setDataSource(results);
          setDataState({ ...dataState, take, skip, total });
        })
        .catch((err) => {
          log(`batchProcHist.handlePageChange error: ${err}`);
        });
    },
    // eslint-disable-next-line
    [dataSource, dataState]
  );

  // 그리드 소팅 처리
  const handleSortChange = useCallback(
    (e) => {
      const { sort } = e;

      const stateObj = {};

      if (sort[0] !== undefined) {
        let field = sort[0].field;
        if(sort[0].field === "batchTrmeDtm") field = "trmeDtm";
        stateObj.sort = field + "," + sort[0].dir;
      } else {
        delete stateObj.sort;
      }

      console.log("stateObj = ", stateObj);

      getBatchProcHist(conn, stateObj, btchStats, cateState)
        .then((response) => {
          const { results, total } = response;
          setDataSource(results);
          setDataState({ ...dataState, sort, total });
        })
        .catch((err) => {
          log(`batchProcHist.handleSortChange error: ${err}`);
        });
    },
    // eslint-disable-next-line
    [dataSource, dataState]
  );

  // 배치재수행 - 재수행 버튼
  const handleBatchRestartBtn = useCallback(
    (e) => {
      e.preventDefault();
      setModalForm({ ...modalForm, visible: !modalForm.visible });
    },
    [modalForm]
  );

  // 배치재수행 - 취소버튼
  const handleBatchCancelBtn = useCallback(
    (e) => {
      setModalForm({ ...modalForm, visible: !modalForm.visible });
    },
    [modalForm]
  );

  // 배치 재수행 - 에너지원 이벤트
  const handleEngList = useCallback(
    (e) => {
      const { value } = e.target;
      // console.log("e = ", e.target)
      setModalForm({ ...modalForm, engData: value.codeNo });
    },
    [modalForm]
  );

  // 배치 재수행 - 배치일자
  // const handleSearchData = useCallback(
  //   (e) => {
  //     const { value } = e.target;
  //     setModalForm({ ...modalForm, searchDate: parseDate("yyyy-mm-dd", value) });
  //   },
  //   [modalForm]
  // );

  // 배치재수행 - 재수행 요청 버튼
  const handleReStartBtn = useCallback(
    (e) => {
      e.preventDefault();

      if (modalForm.engData === "") {
        kendo.alert("에너지원을 선택 해주세요.");
        return;
      }

      kendo.confirm("배치프로세스를 재요청하시겠습니까?").then(() => {
        log(modalForm, "modalForm");
        // alert('재요청');
        requestBatch(conn, requestBatchResult, requestBatchReject, modalForm);
      });
    },
    // eslint-disable-next-line
    [modalForm]
  );

  // 배치 리퀘스트 callback
  const requestBatchResult = (results) => {
    // console.log("results = ", results);
    handleBatchCancelBtn();
    handleSubmitBtn();
  };

  // 배치 리퀘스트 reject 함수
  const requestBatchReject = (error) => {};

  return (
    <>
      <div className="batchProcHistBody">
        <div className="batchProcHistCate">
          <div>
            <span className = {styles.textArea}>배치상태</span>
            <DropDownList
              data={btchStats
                .filter((btchItem) => btchItem.codeNo !== 13001)
                .filter((btchItem) => btchItem.codeNo !== 13002)
                .map((item) => item.codeVal)}
              defaultValue={btchStats.map((item) => item.codeVal)[2]}
              onChange={handleBtchStats}
            />
          </div>
          <div>
            <span className = {styles.textArea}>요청일시</span>
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <DatePicker
                  format={"yyyy-MM-dd"}
                  defaultValue={new Date()}
                  value={new Date(cateState.startDate)}
                  max={new Date(cateState.endDate)}
                  onChange={handleStartDateChange}
                />
              </IntlProvider>
            </LocalizationProvider>
            <span className={styles.simbolArea}> ~ </span>
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <DatePicker
                  format={"yyyy-MM-dd"}
                  defaultValue={new Date()}
                  value={new Date(cateState.endDate)}
                  max={new Date()}
                  onChange={handleEndDateChange}
                />
              </IntlProvider>
            </LocalizationProvider>
          </div>
          <div>
            <Button className={styles.batchReStart} onClick={handleBatchRestartBtn}>배치 재수행</Button>
            <Button className={styles.inqBtn} onClick={handleSubmitBtn} icon="search" ></Button>
          </div>
        </div>
        <div className="batchProcHistGrid">
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className={styles.batchProcHistGrid}
                style={
                  loading
                    ? {
                        opacity: 0.6,
                        pointerEvents: "none",
                      }
                    : null
                }
                data={dataSource}
                {...dataState}
                pageable={{ buttonCount: 5, pageSizes: true }}
                sortable
                onPageChange={handlePageChange}
                onSortChange={handleSortChange}
              >
                <GridColumn title="No" field="batchReqSeriNo" />
                <GridColumn title="요청일시" field="reqDtm" />
                <GridColumn title="배치일시" field="batchDtm" />
                <GridColumn title="처리일시" field="trmeDtm" />
                <GridColumn title="처리결과" field="trmeRsltCodeNo" />
                <GridColumn title="배치처리시간(초)" field="batchTrmeDtm" />
                <GridColumn title="내용" field="batchCtnt" />
                <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
          {loading && (
            <p
              style={{
                position: "absolute",
                top: "55%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </div>
      </div>
      {modalForm.visible && (
        <Dialog title="배치 재수행" className={styles.batchProcHistDialog} onClose={handleBatchCancelBtn}>
          <div className="batchProcHistModalDiv">
            <div>
              <span>에너지원</span>
              <DropDownList
                data={engList.filter((e) => e.codeNo !== 15007)}
                defaultItem={{ codeNo: "", codeVal: "에너지원 선택" }}
                dataItemKey="codeNo"
                textField="codeVal"
                // defaultValue={engList[0]}
                onChange={handleEngList}
              />
            </div>
            <div>
              <span>배치일자</span>
              <DatePickerKo
                format={"yyyy-MM-dd"}
                defaultValue={new Date()}
                max={new Date()}
                setDtm={(v) => {
                  setModalForm({ ...modalForm, searchDate: v });
                }}
              />
            </div>
            <div>
              <Button onClick={handleReStartBtn}>재수행</Button>
              <Button onClick={handleBatchCancelBtn}>취소</Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default BatchProcHist;
