// 그래프
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const UserBetweenUserListChart = ({userList, search }) => {

  const chartList = userList.map(item => ({
    name : item.userTerm , 
    y : item.instCapaPerEnergy , 
    userId : item.userId , 
    color : "#69bcc8"} )
 );

  // 에너지원 코드 번호
  const ensoTypeCodeNo = search.ensoTypeCode; 

  // 시/군 생사량 목록
  const chartConfig = {
    chart: { zoomType: 'xy' },
    title: { text: null },
    credits : { enabled: false },
    noData: { style:{ color: '#000000', fontSize: '14px' } },
    legend: { enabled: false },
    tooltip: {
        shared: true,
        positioner: function (labelWidth, labelHeight, point) {
          // 툴팁 위치 조정 
          const tooltipX = point.plotX;
          const tooltipY = 150;
          return {
              x: tooltipX,
              y: tooltipY
          };
        },
        formatter: function () {
          return(
            `
              <b>${this.points[0].point.name}</b><br/>
              <span style="color:${this.points[0].point.color}">\u25CF</span>
              ${this.points[0].series.name} : ${Math.round(this.points[0].y*100)/100} kWh/${ensoTypeCodeNo===15002?'㎡':'kW'}.day
            `
          );
        }
    },
    plotOptions: {
        series: {
            cursor: 'pointer'
        }
    },
    xAxis: [{ 
      type: 'category', crosshair: true ,
      labels: {
        style: {
          color: '#000000'
        }
      }
    }],
    yAxis: [{ // Primary yAxis
        labels: {
            format: '{value:,.2f}',
            style: {
                color: "#69bcc8"
            }
        },
        title: {
            text: `용량대비생산량(kWh/${ensoTypeCodeNo === 15002?'㎡':'kW'}.day)`,
            style: {
                color: "#69bcc8"
            }
        }
    }],
    // legend: {
    //     layout: 'horizontal',
    //     align: 'center',
    //     verticalAlign: 'top',
    //     backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
    // },
    series: [{
        name: '용량대비생산량',
        type: 'column',
        color: '#69bcc8',
        dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}'
        },
        data: chartList,
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} kWh/'+(search.ensoTypeCode===15002?'㎡':'kW')+'.day</b><br/>'		            
        }

    }]
  }

  return(
    <div style = {{height : "400px"}}>
        <HighchartsReact Highcharts = {Highcharts} options = {chartConfig} />
    </div>
  );
}

export default UserBetweenUserListChart;
