// React
import React from "react";

// Service
import { roundNum, numFormat } from "../../../com/dataUtil";

// Css
import "./munic.css";
import styles from "./munic.module.css";

const MunicRank = ({ dongData, userData }) => {
  return (
    <div className="R_box">
      <div className="R_title">태양광 모니터링 발전순위 정보</div>
      <div className="R_con">
        <table className="tb-ranking">
          <caption>지역별 금일발전량 순위 TOP 3</caption>
          <colgroup>
            <col width="25px" />
            <col width="120px" />
            <col width="50px" />
            <col width="35px" />
            <col width="45px" />
            <col width="" />
            <col width="" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <img src="/images/dist/medal_gold.png" alt="medal_gold"/>
              </th>
              <td>{dongData.dongPowerTerm1}</td>
              <td>{numFormat(roundNum(dongData.dongPower1, 0))}</td>
              <td>kWh</td>
              {dongData.dongPrePowerRank1 >= 0 ? (
                dongData.dongPrePowerRank1 === 0 ? (
                  <>
                    <td className="MiBla">-</td>
                    <td className="MiBla"></td>
                    <td className="MiBla">{numFormat(roundNum(dongData.dongPrePowerRank1, 0))}</td>
                    <td className="MiBla">kWh</td>
                  </>
                ) : (
                  <>
                    <td className="UpRed">
                      <i className="fa-solid fa-caret-up"></i>
                    </td>
                    <td className="UpRed"></td>
                    <td className="UpRed">{numFormat(roundNum(dongData.dongPrePowerRank1, 0))}</td>
                    <td className="UpRed">kWh</td>
                  </>
                )
              ) : (
                <>
                  <td className="DwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                  </td>
                  <td className="DwBlu"></td>
                  <td className="DwBlu">{numFormat(-roundNum(dongData.dongPrePowerRank1, 0))}</td>
                  <td className="DwBlu">kWh</td>
                </>
              )}
            </tr>
            <tr>
              <th>
                <img src="/images/dist/medal_silver.png" alt=""/>
              </th>
              <td>{dongData.dongPowerTerm2}</td>
              <td>{numFormat(roundNum(dongData.dongPower2, 0))}</td>
              <td>kWh</td>
              {dongData.dongPrePowerRank2 >= 0 ? (
                dongData.dongPrePowerRank2 === 0 ? (
                  <>
                    <td className="MiBla">-</td>
                    <td className="MiBla"></td>
                    <td className="MiBla">{numFormat(roundNum(dongData.dongPrePowerRank2, 0))}</td>
                    <td className="MiBla">kWh</td>
                  </>
                ) : (
                  <>
                    <td className="UpRed">
                      <i className="fa-solid fa-caret-up"></i>
                    </td>
                    <td className="UpRed"></td>
                    <td className="UpRed">{numFormat(roundNum(dongData.dongPrePowerRank2, 0))}</td>
                    <td className="UpRed">kWh</td>
                  </>
                )
              ) : (
                <>
                  <td className="DwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                  </td>
                  <td className="DwBlu"></td>
                  <td className="DwBlu">{numFormat(-roundNum(dongData.dongPrePowerRank2, 0))}</td>
                  <td className="DwBlu">kWh</td>
                </>
              )}
            </tr>
            <tr>
              <th>
                <img src="/images/dist/medal_copper.png" alt=""/>
              </th>
              <td>{dongData.dongPowerTerm3}</td>
              <td>{numFormat(roundNum(dongData.dongPower3, 0))}</td>
              <td>kWh</td>
              {dongData.dongPrePowerRank3 >= 0 ? (
                dongData.dongPrePowerRank3 === 0 ? (
                  <>
                    <td className="MiBla">-</td>
                    <td className="MiBla"></td>
                    <td className="MiBla">{numFormat(roundNum(dongData.dongPrePowerRank3, 0))}</td>
                    <td className="MiBla">kWh</td>
                  </>
                ) : (
                  <>
                    <td className="UpRed">
                      <i className="fa-solid fa-caret-up"></i>
                    </td>
                    <td className="UpRed"></td>
                    <td className="UpRed">{numFormat(roundNum(dongData.dongPrePowerRank3, 0))}</td>
                    <td className="UpRed">kWh</td>
                  </>
                )
              ) : (
                <>
                  <td className="DwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                  </td>
                  <td className="DwBlu"></td>
                  <td className="DwBlu">{numFormat(-roundNum(dongData.dongPrePowerRank3, 0))}</td>
                  <td className="DwBlu">kWh</td>
                </>
              )}
            </tr>
          </tbody>
        </table>
        <table className="tb-ranking">
          <caption>지역별 금일이용률 순위 TOP 3</caption>
          <colgroup>
            <col width="25px" />
            <col width="120px" />
            <col width="50px" />
            <col width="35px" />
            <col width="45px" />
            <col width="" />
            <col width="" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <img src="/images/dist/medal_gold.png" alt=""/>
              </th>
              <td>{dongData.dongEficTerm1}</td>
              <td>{numFormat(roundNum(dongData.dongEfic1, 2))}</td>
              <td>%</td>
              {dongData.dongPreEficRank1 >= 0 ? (
                dongData.dongPreEficRank1 === 0 ? (
                  <>
                    <td className="MiBla">-</td>
                    <td className="MiBla"></td>
                    <td className="MiBla">{numFormat(roundNum(dongData.dongPreEficRank1, 2))}</td>
                    <td className="MiBla">%</td>
                  </>
                ) : (
                  <>
                    <td className="UpRed">
                      <i className="fa-solid fa-caret-up"></i>
                    </td>
                    <td className="UpRed"></td>
                    <td className="UpRed">{numFormat(roundNum(dongData.dongPreEficRank1, 2))}</td>
                    <td className="UpRed">%</td>
                  </>
                )
              ) : (
                <>
                  <td className="DwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                  </td>
                  <td className="DwBlu"></td>
                  <td className="DwBlu">{numFormat(-roundNum(dongData.dongPreEficRank1, 2))}</td>
                  <td className="DwBlu">%</td>
                </>
              )}
            </tr>
            <tr>
              <th>
                <img src="/images/dist/medal_silver.png" alt=""/>
              </th>
              <td>{dongData.dongEficTerm2}</td>
              <td>{numFormat(roundNum(dongData.dongEfic2, 2))}</td>
              <td>%</td>
              {dongData.dongPreEficRank2 >= 0 ? (
                dongData.dongPreEficRank2 === 0 ? (
                  <>
                    <td className="MiBla">-</td>
                    <td className="MiBla"></td>
                    <td className="MiBla">{numFormat(roundNum(dongData.dongPreEficRank2, 2))}</td>
                    <td className="MiBla">%</td>
                  </>
                ) : (
                  <>
                    <td className="UpRed">
                      <i className="fa-solid fa-caret-up"></i>
                    </td>
                    <td className="UpRed"></td>
                    <td className="UpRed">{numFormat(roundNum(dongData.dongPreEficRank2, 2))}</td>
                    <td className="UpRed">%</td>
                  </>
                )
              ) : (
                <>
                  <td className="DwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                  </td>
                  <td className="DwBlu"></td>
                  <td className="DwBlu">{numFormat(-roundNum(dongData.dongPreEficRank2, 2))}</td>
                  <td className="DwBlu">%</td>
                </>
              )}
            </tr>
            <tr>
              <th>
                <img src="/images/dist/medal_copper.png" alt=""/>
              </th>
              <td>{dongData.dongEficTerm3}</td>
              <td>{numFormat(roundNum(dongData.dongEfic3, 2))}</td>
              <td>%</td>
              {dongData.dongPreEficRank3 >= 0 ? (
                dongData.dongPreEficRank3 === 0 ? (
                  <>
                    <td className="MiBla">-</td>
                    <td className="MiBla"></td>
                    <td className="MiBla">{numFormat(roundNum(dongData.dongPreEficRank3, 2))}</td>
                    <td className="MiBla">%</td>
                  </>
                ) : (
                  <>
                    <td className="UpRed">
                      <i className="fa-solid fa-caret-up"></i>
                    </td>
                    <td className="UpRed"></td>
                    <td className="UpRed">{numFormat(roundNum(dongData.dongPreEficRank3, 2))}</td>
                    <td className="UpRed">%</td>
                  </>
                )
              ) : (
                <>
                  <td className="DwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                  </td>
                  <td className="DwBlu"></td>
                  <td className="DwBlu">{numFormat(-roundNum(dongData.dongPreEficRank3, 2))}</td>
                  <td className="DwBlu">%</td>
                </>
              )}
            </tr>
          </tbody>
        </table>
        <table className="tb-ranking">
          <caption>수용가별 금일발전량 순위 TOP 3</caption>
          <colgroup>
            <col width="25px" />
            <col width="120px" />
            <col width="50px" />
            <col width="35px" />
            <col width="45px" />
            <col width="" />
            <col width="" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <img src="/images/dist/medal_gold.png" alt=""/>
              </th>
              <td className={styles.userRankTd1}>{userData.userTerm1}</td>
              <td>{numFormat(roundNum(userData.userPower1, 0))}</td>
              <td>kWh</td>
              {userData.userPrePowerRank1 >= 0 ? (
                userData.userPrePowerRank1 === 0 ? (
                  <>
                    <td className="MiBla">-</td>
                    <td className="MiBla"></td>
                    <td className="MiBla">{numFormat(roundNum(userData.userPrePowerRank1, 0))}</td>
                    <td className="MiBla">kWh</td>
                  </>
                ) : (
                  <>
                    <td className="UpRed">
                      <i className="fa-solid fa-caret-up"></i>
                    </td>
                    <td className="UpRed"></td>
                    <td className="UpRed">{numFormat(roundNum(userData.userPrePowerRank1, 0))}</td>
                    <td className="UpRed">kWh</td>
                  </>
                )
              ) : (
                <>
                  <td className="DwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                  </td>
                  <td className="DwBlu"></td>
                  <td className="DwBlu">{numFormat(-roundNum(userData.userPrePowerRank1, 0))}</td>
                  <td className="DwBlu">kWh</td>
                </>
              )}
            </tr>
            <tr>
              <th>
                <img src="/images/dist/medal_silver.png" alt=""/>
              </th>
              <td className={styles.userRankTd1}>{userData.userTerm2}</td>
              <td>{numFormat(roundNum(userData.userPower2, 0))}</td>
              <td>kWh</td>
              {userData.userPrePowerRank2 >= 0 ? (
                userData.userPrePowerRank2 === 0 ? (
                  <>
                    <td className="MiBla">-</td>
                    <td className="MiBla"></td>
                    <td className="MiBla">{numFormat(roundNum(userData.userPrePowerRank2, 0))}</td>
                    <td className="MiBla">kWh</td>
                  </>
                ) : (
                  <>
                    <td className="UpRed">
                      <i className="fa-solid fa-caret-up"></i>
                    </td>
                    <td className="UpRed"></td>
                    <td className="UpRed">{numFormat(roundNum(userData.userPrePowerRank2, 0))}</td>
                    <td className="UpRed">kWh</td>
                  </>
                )
              ) : (
                <>
                  <td className="DwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                  </td>
                  <td className="DwBlu"></td>
                  <td className="DwBlu">{numFormat(-roundNum(userData.userPrePowerRank2, 0))}</td>
                  <td className="DwBlu">kWh</td>
                </>
              )}
            </tr>
            <tr>
              <th>
                <img src="/images/dist/medal_copper.png" alt=""/>
              </th>
              <td className={styles.userRankTd1}>{userData.userTerm3}</td>
              <td>{numFormat(roundNum(userData.userPower3, 0))}</td>
              <td>kWh</td>
              {userData.userPrePowerRank3 >= 0 ? (
                userData.userPrePowerRank3 === 0 ? (
                  <>
                    <td className="MiBla">-</td>
                    <td className="MiBla"></td>
                    <td className="MiBla">{numFormat(roundNum(userData.userPrePowerRank3, 0))}</td>
                    <td className="MiBla">kWh</td>
                  </>
                ) : (
                  <>
                    <td className="UpRed">
                      <i className="fa-solid fa-caret-up"></i>
                    </td>
                    <td className="UpRed"></td>
                    <td className="UpRed">{numFormat(roundNum(userData.userPrePowerRank3, 0))}</td>
                    <td className="UpRed">kWh</td>
                  </>
                )
              ) : (
                <>
                  <td className="DwBlu">
                    <i className="fa-solid fa-caret-down"></i>
                  </td>
                  <td className="DwBlu"></td>
                  <td className="DwBlu">{numFormat(-roundNum(userData.userPrePowerRank3, 0))}</td>
                  <td className="DwBlu">kWh</td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MunicRank;
