import React  from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { Search } from "@material-ui/icons";

const WeathCmpSearch = ({
  ensoList,
  search,
  setSearch,
  citys,
  rgns,
  dateList,
  styles,
  RadioButton,
  radioChange,
  yesterday,
  setSearchClickEvt,
  searchData , 
  ensoSearch
}) => {
  return (
    <>
      <span className="searchSubTitleA">에너지원</span>
      <DropDownList
        data={ensoList.filter(e => e.codeNo !== 15007)}
        name="ensoTypeCodeNo"
        textField="codeVal"
        dataItemKey="codeNo"
        onChange={searchData}
        className="searchArea"
        value = {ensoSearch.ensoType}
      ></DropDownList>
      <span className="searchSubTitleB">지역검색</span>
      <DropDownList 
        data={citys}
        defaultItem={{ cityProvTerm: "시/도", cityProvCode: "" }}
        name="city"
        textField="cityProvTerm"
        dataItemKey="cityProvCode"
        onChange={searchData}
        value = {search.city}
      />
      &nbsp;&nbsp;
      <DropDownList
        data={rgns}
        textField="rgnTermSimple"
        name="rgn"
        dataItemKey="rgnCode"
        defaultItem={{ rgnTermSimple: "전체", rgnCode: "" }}
        onChange={searchData}
        disabled = { search.cityProvCode === "" }
        value = {search.rgn}
      />
      {/* <span className="searchSubTitleB">날짜검색</span>
      <div className="Searchdropdown">
        <DropDownList
          id="date"
          name="date"
          data={dateList}
          className="searchArea"
          dataItemKey="codeNo"
          textField="codeVal"
          defaultValue={{ codeNo: 23006, codeVal: "직접조회" }}
          onChange={searchData}
          value={search.dateVal}
        />
      </div>
      <span className={styles}>
        &nbsp;&nbsp;&nbsp;
        <RadioButton
          name="group1"
          className="radioBtn"
          value="HH"
          checked={search.selectedValue === "HH"}
          label="시간별"
          onChange={radioChange}
        />
        &nbsp;&nbsp;&nbsp;
        <RadioButton
          name="group1"
          className="radioBtn"
          value="DD"
          checked={search.selectedValue === "DD"}
          label="일별"
          onChange={radioChange}
        />
        &nbsp;&nbsp;&nbsp;
        <RadioButton
          name="group1"
          className="radioBtn"
          value="MM"
          checked={search.selectedValue === "MM"}
          label="월별"
          onChange={radioChange}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
      </span> */}
      <span className="searchSubTitleB">조회기간</span>
      <DatePickerKo
        id="start"
        name="start"
        className="datePicker"
        format={search.selectedValue === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
        setDtm={(v) => {
          setSearch( search => {
            const item = {...search};
            item.dateVal = { codeNo: 23006, codeVal: "직접조회" }
            item.endMin = v;
            item.startGathDtm = v;
            return item;
          })
        }}
        value={search.startGathDtm}
        readonly={true}
        max={search.startMax}
      />
      {search.selectedValue !== "HH" && <span className="textColorGr"> ~ </span>}
      {search.selectedValue !== "HH" && (
        <DatePickerKo
          id="end"
          name="end"
          className="datePicker"
          format={search.selectedValue === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
          setDtm={(v) => {
            setSearch( search => {
              const item = {...search};
              item.dateVal = { codeNo: 23006, codeVal: "직접조회" }
              item.startMax = v;
              item.endGathDtm = v;
              return item;
            });
          }}
          min={search.endMin}
          max={yesterday}
          value = {search.endGathDtm}
          readonly={true}
        />
      )}
      <span className={styles.searchSubTitleC}>
        <Button
          className="searchBtn"
          onClick={setSearchClickEvt}
        ><Search className="material-icons" /></Button>
        &nbsp;&nbsp;
      </span>
    </>
  );
};

export default WeathCmpSearch;
