import { numFormat } from '../../../../com/dataUtil';
import styles from './cmpUserByUser.module.css';
// import styles from './cmpUserByUser.dk.module.css';

// 시간별 데이터 차트


const CmpUserByUserTimeChart = ({ Highcharts , HighchartsReact , ensoTypeCode , timeSeries , timeCategory , search }) => {

  // 태양열, 지열인지 판별
  const isHeat = ensoTypeCode === 15002 || ensoTypeCode === 15003 ? true : false;

  // 차트 옵션
  const chartConfig = {
    chart: {
      zoomType: 'xy',
    },
    title: {
        text: null
    },
    xAxis: [{
      type: 'category',
      crosshair: true,
      categories : timeCategory,
      labels: {
        style: {
          color: '#000000'
        }
      }
    }],
  noData: {
  },
    yAxis: [{ // 발전량 축
        labels: {
          format: '{value:,.1f}',
            style: {
                color: '#000000'
            }
        },
        title: {
            text: '발전량(kWh)',
            style: {
                color: '#000000'
            },
            rotation: 0,
            y: -10,
            offset: 0,
            align: 'high'
        }

    }],
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        let label = '<b>'+this.key+'</b><br>'+
        '<table>'+
          '<tr><td class="tool-td">'+(isHeat===false ? '발&nbsp; &nbsp; &nbsp; 전' : '생&nbsp; &nbsp; &nbsp; 산')+'&nbsp; &nbsp; &nbsp; 량 : </td><td><b>'+ numFormat( this.y ) +'</b> kWh</td></tr>'+
          '<tr><td class="tool-td">효 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 율 : </td><td><b>'+ numFormat( this.point.efic ) +'</b> %</td></tr>'+
          '<tr><td class="tool-td">용량대비'+(isHeat===false ? '발전량' : '생산량')+' : </td><td><b>'+ numFormat( this.point.instCapaPerPower ) +'</b> kWh/kW.day</td></tr>';
          if(isHeat === false){
            label += '<tr><td class="tool-td">CO<sub>2 </sub>&nbsp;절&nbsp; 감&nbsp; 량 : </td><td><b>'+ numFormat( this.point.co2 ) +'</b> kgCO<sub>2</sub></td></tr>';
          }
          label += '</table>';
        return label;
      }
    },
    legend: {
        layout: 'vertical',
        align: 'center',
        verticalAlign: 'top',
        floating: false ,
        itemStyle: {
          color: '#000000'
        }
    },
    series: [{
      name:  isHeat === true ? '생산량' : '발전량',
      type: 'spline',
      data : timeSeries ,
      tooltip: {
          valueSuffix: ' kWh'
      }
  }]
  }

  return(
      <div className="uk-width-medium-1-2">
        <div className="md-card TBgA">
          <div className = {styles.titleName}> ⋅ {search.titleName} 그래프</div>
          <div className="md-card-content small-padding">
            <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
          </div>
        </div>
      </div>
  );
}

export default CmpUserByUserTimeChart;