
const meainModelComUrl = "meain/model";

/**
 * ### 설비 모델 관리 - 조회
 */
export const getMeainModel = async (conn, dataState) => {
  const result = await conn.get(meainModelComUrl + "/list", {
    params: {
      ensoTypeCodeNo: dataState.ensoTypeCodeNo,
      meainTypeTerm: dataState.meainTypeTerm,
      makrClsfCode: dataState.makrClsfCode,
      makrTerm: dataState.makrTerm,
      ensoTypeCode: dataState.ensoTypeCode,
      meainTypeExpl: dataState.meainTypeExpl,
      authYn: dataState.authYn,
    },
  });
  return result;
};

/**
 * ### 설비 제조사 관리 - 추가, 수정
 */
export const setUpdateMeainModel = async (conn, data, findEnsoTypeCodeNo) => {
  //원래 데이터 수정되지 않게 스프레드연산자 사용
  const temp = { ...data };
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const result = await conn.post(meainModelComUrl, JSON.stringify(new Array(temp)), config);

  return result;
};

export const setDeleteMeainModel = async (conn, data) => {
  const config = {
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(data.meainTypeSeriNo),
  };
  const result = await conn.delete(meainModelComUrl, config);
  return result;
};

/**
 * meainTypeTerm 전체조회
 */
export const getMeainTypeTermList = async (conn) => {
  const result = await conn.get(meainModelComUrl + "/termlist");

  return result;
};
