import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { yearList } from "../../../com/dropDownDs";
import styles from "./searchCom.module.css";
import { Search } from "@material-ui/icons";
import { Autorenew } from "@material-ui/icons";
import kendo from "@progress/kendo-ui";
import { isManager, getUserMngRgnList } from "../../../service/authService";

// 사업현황의 검색 컴포넌트
const SearchCom = ({ citys, rgns, search, setSearch, getData, show, codeList, isMobile }) => {  
  // 에너지원 데이터 소스
  const energyList = codeList.filter(
    c =>
      c.grpCodeNo === 39 &&
      c.codeNo !== 39006 &&
      c.codeNo !== 39007 &&
      c.codeNo !== 39008 &&
      c.codeNo !== 39009 &&
      c.codeNo !== 39010 &&
      c.codeNo !== 39011 &&
      c.codeNo !== 39012 &&
      c.codeNo !== 39013 &&
      c.codeNo !== 39014 &&
      c.codeNo !== 39015 &&
      c.codeNo !== 39016,
  );

  const defualtVal = { codeVal: "전체", codeNo: "" };

  // 드롭다운 변경 이벤트
  const onItemChange = e => {
    // console.log(" onItemChange E = ", e);
    if (e.target.name === "bizYy") {
      if (isManager()) {
        if (e.target.value === "사업연도") {
          search.bizYyNo = "";
          search.bizYy = e.target.value;
        } else {
          search.bizYyNo = e.target.value;
          search.bizYy = e.target.value;
        }

        getSearchData();
      } else {
        if (e.target.value === "사업연도") {
          setSearch(search => {
            const item = { ...search };
            item.bizYyNo = "";
            item.bizYy = e.target.value;
            return item;
          });
        } else {
          setSearch(search => {
            const item = { ...search };
            item.bizYyNo = e.target.value;
            item.bizYy = e.target.value;
            return item;
          });
        }
      }
    } else if (e.target.name === "city") {
      setSearch(search => {
        const item = { ...search };
        item.city = e.target.value;
        item.cityProvCode = e.target.value.cityProvCode;
        item.rgn = { rgnTermSimple: "전체", rgnCode: "" };
        item.rgnCode = "";
        return item;
      });
    } else if (e.target.name === "rgn") {
      setSearch(search => {
        const item = { ...search };
        item.rgn = e.target.value;
        item.rgnCode = e.target.value.rgnCode;
        item.rgnTerm = e.target.value.rgnTermSimple;
        return item;
      });
    } else if (e.target.name === "energyTypeCode") {
      setSearch(state => {
        const item = { ...state };
        item.energyTypeCode = e.target.value.codeNo;
        item.energyType = e.target.value;
        return item;
      });
    }
  };

  // 텍스트 명 변경
  const onTextChange = e => {
    // console.log(" onTextChange E = ", e);
    setSearch(search => {
      const item = { ...search };
      item.instlTerm = e.target.value;
      return item;
    });
  };

  // 키 이벤트
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      getSearchData();
    }
  };

  // 검색 클릭 이벤트
  const clickBtnEvt = () => {
    getSearchData();
  };

  // 검색 시작
  const getSearchData = () => {
    // console.log("getSearchData search = ", search);
    // 지역코드와 사업연도는 필수
    const valid = validData(search);
    if (!valid.isValid) {
      kendo.alert(valid.message);
      return;
    } else {
      getData();
    }
  };

  const renewBtnEvt = () => {
    search.bizYyNo = "";
    search.bizYy = "사업연도";
    search.city = { cityProvTerm: "시/도", cityProvCode: "" };
    search.cityProvCode = isManager() ? getUserMngRgnList()[0].substring(0, 2) : "";
    search.rgn = { rgnTermSimple: "전체", rgnCode: "", rgnTerm: "" };
    search.rgnCode = isManager() ? getUserMngRgnList()[0] : "";
    search.rgnTerm = "";
    search.energyTypeCode = "";
    search.energyType = { codeNo: "", codeVal: "전체" };
    search.instlTerm = "";
    search.saveBizYyNo = "";
    search.saveCityProvTerm = "";
    search.saveRgnTerm = "";
    // setSearch( (search) => {
    //   const item = {...search};
    //   item.bizYyNo = "";
    //   item.bizYy = "사업연도";
    //   item.city = { cityProvTerm: "시/도", cityProvCode: "" };
    //   item.cityProvCode = isManager() ? getUserMngRgnList()[0].substring(0,2) : "";
    //   item.rgn = {rgnTermSimple : "전체" , rgnCode : ""};
    //   item.rgnCode = isManager() ? getUserMngRgnList()[0] : "";
    //   item.rgnTerm = "";
    //   item.energyTypeCode = "";
    //   item.energyType = { codeNo: "", codeVal: "전체" };
    //   item.instlTerm = "";

    //   return item;
    // });
    getSearchData();
  };

  // Validation 정보
  const validData = search => {
    // let isValid = true;
    // let message = "";
    // console.log("search = ", search);
    const valid = {
      isValid: true,
      message: "",
    };
    // if( search.bizYy === "사업연도" ){
    //   valid.isValid = false;
    //   valid.message = '사업연도를 선택해주세요.';
    // }
    // else if( search.bizYyNo === "" ){
    //   valid.isValid = false;
    //   valid.message = '사업연도를 선택해주세요.';
    // }
    // else if ( !search.rgnCode ) {
    //   valid.isValid = false;
    //   valid.message = '시군구를 선택해주세요';
    // }

    if (search.cityProvCode !== "") {
      if (!search.rgnCode) {
        valid.isValid = false;
        valid.message = "시군구를 선택해주세요";
      }
    }

    return valid;
  };

  // 랜더링 부분
  return (
    <>
      {isMobile ? (
        <>
          <div className="uk-grid">
            <div className="uk-width-medium-1-1">
              <div className="searchBox">
                <div>
                  <span className="sel-Name">사업연도</span>
                  <span class="selectbox">
                    <DropDownList
                      id="bizYy"
                      name="bizYy"
                      data={yearList()}
                      defaultItem={"사업연도"}
                      value={search.bizYy}
                      onChange={onItemChange}
                      className={styles.searchArea}
                    />
                  </span>
                </div>
                <div>
                  <span className="sel-Name">시도</span>
                  <span class="selectbox">
                    <DropDownList
                      data={citys}
                      value={search.city}
                      defaultItem={{ cityProvTerm: "시/도", cityProvCode: "" }}
                      name="city"
                      textField="cityProvTerm"
                      dataItemKey="cityProvCode"
                      onChange={onItemChange}
                      className={styles.searchArea}
                    />
                  </span>
                </div>
                <div>
                  <span className="sel-Name">시군구</span>
                  <span class="selectbox">
                    <DropDownList
                      data={rgns}
                      value={search.rgn}
                      defaultItem={{ rgnTermSimple: "전체", rgnCode: "" }}
                      name="rgn"
                      textField="rgnTermSimple"
                      dataItemKey="rgnCode"
                      onChange={onItemChange}
                      className={styles.searchArea}
                      disabled={search.cityCodeNo === ""}
                      // value = {search.rgn}
                    />
                  </span>
                </div>
                <div>
                  {show !== false && (
                    <>
                      <span className="sel-Name">에너지원</span>
                      <span class="selectbox">
                        <DropDownList
                          name="energyTypeCode"
                          data={energyList}
                          textField="codeVal"
                          dataItemKey="codeNo"
                          defaultItem={defualtVal}
                          value={search.energyType}
                          onChange={onItemChange}
                          className={styles.searchArea}
                        />
                      </span>
                    </>
                  )}
                </div>
                <div>
                  {show !== false && (
                    <>
                      <span class="sel-Name">신청자</span>
                      <input
                        type="text"
                        class="intext"
                        defaultValue={search.instlTerm}
                        onChange={onTextChange}
                        onKeyPress={handleKeyPress}
                        value={search.instlTerm}
                      />
                    </>
                  )}
                  <button className={styles.searchBtn} onClick={clickBtnEvt}>
                    <Search className={styles.searchMaterialIcons} style={{ fontSize: 25 }} />
                  </button>
                </div>
                {/* <button className={styles.renewBtn} onClick={renewBtnEvt}>
              <Autorenew className={styles.searchMaterialIcons} style={{ fontSize: 25 }} />
            </button> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="uk-grid">
            <div className="uk-width-medium-1-1">
              <div className="searchBox">
                <span className="sel-Name">사업연도</span>
                <span class="selectbox">
                  <DropDownList
                    id="bizYy"
                    name="bizYy"
                    data={yearList()}
                    defaultItem={"사업연도"}
                    value={search.bizYy}
                    onChange={onItemChange}
                    className={styles.searchArea}
                  />
                </span>
                <span className="sel-Name">시도</span>
                <span class="selectbox">
                  <DropDownList
                    data={citys}
                    value={search.city}
                    defaultItem={{ cityProvTerm: "시/도", cityProvCode: "" }}
                    name="city"
                    textField="cityProvTerm"
                    dataItemKey="cityProvCode"
                    onChange={onItemChange}
                    className={styles.searchArea}
                  />
                </span>
                <span className="sel-Name">시군구</span>
                <span class="selectbox">
                  <DropDownList
                    data={rgns}
                    value={search.rgn}
                    defaultItem={{ rgnTermSimple: "전체", rgnCode: "" }}
                    name="rgn"
                    textField="rgnTermSimple"
                    dataItemKey="rgnCode"
                    onChange={onItemChange}
                    className={styles.searchArea}
                    disabled={search.cityCodeNo === ""}
                    // value = {search.rgn}
                  />
                </span>
                {show !== false && (
                  <>
                    <span className="sel-Name">에너지원</span>
                    <span class="selectbox">
                      <DropDownList
                        name="energyTypeCode"
                        data={energyList}
                        textField="codeVal"
                        dataItemKey="codeNo"
                        defaultItem={defualtVal}
                        value={search.energyType}
                        onChange={onItemChange}
                        className={styles.searchArea}
                      />
                    </span>
                  </>
                )}
                {show !== false && (
                  <>
                    <span class="sel-Name">신청자(건물)명</span>
                    <input
                      type="text"
                      class="intext"
                      defaultValue={search.instlTerm}
                      onChange={onTextChange}
                      onKeyPress={handleKeyPress}
                      value={search.instlTerm}
                    />
                  </>
                )}
                <button className={styles.searchBtn} onClick={clickBtnEvt}>
                  <Search className={styles.searchMaterialIcons} style={{ fontSize: 25 }} />
                </button>
                <button className={styles.renewBtn} onClick={renewBtnEvt}>
                  <Autorenew className={styles.searchMaterialIcons} style={{ fontSize: 25 }} />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SearchCom;
