import { useState } from "react";

const HeaderFullScr = () => {

  // 전체 화면 변수
  const [fullScreen , setFullScreen] = useState(false);
  const [fullScreenImg , setFullScreenImg] = useState("/images/icon_fullsc.png");

  // 전체 화면 제어 (Full Screen Control)
  function controllFullScrren(){
      if( fullScreen === false){
          document.documentElement.requestFullscreen();
          setFullScreen(true);
          setFullScreenImg("/images/icon_fullscout.png");
      }else{
          document.exitFullscreen();
          setFullScreen(false);
          setFullScreenImg("/images/icon_fullsc.png");
      }
  }

  return(
    <>
    <a href="#!"  className="user_action_icon" onClick={controllFullScrren}><span className="headimgB"><img src={fullScreenImg} alt="전체화면"/></span></a>
    </>
  );
}

export default HeaderFullScr;