// import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import './domainError.css';
import styles from './error.module.css';

const DomainError = (props) => {
 
  document.body.style.background = "#ffffff";

  const moveHome = (e) => {
    window.location.href = "https://kremc.kr/";
  }

  return (
    <div className = { [ styles.pageArea , "uk-grid" ].join(' ') } >
      {/* <div className = "uk-width-medium-1-1"> */}
      <div id="notice-wrap">
        <div id="notice-container">
            <div class="container-ttl">
                <img style={{width: "60px"}} src="/images/srlogo.png" alt="logo"></img>
                <span class="container-color">    접속 주소가 변경</span>되어 다음과 같이 안내 드립니다.
            </div>
            <div class="contents">
                <div class="txt-box-wrap">
                    <div class="txt-box1">현재</div>
                    <div class="txt-box2">https://sre.kr</div>
                    <div class="txt-box3">변경 후</div>
                    <div class="txt-box4">https://kremc.kr</div>
                </div>
                <div className = "new-page" onClick = {moveHome}>
                    {/* <a class="new-page-link" href="https://kremc.kr">변경 후 페이지 바로가기</a> */}
                    {/* <Button className = "new-page" onClick = {moveHome} > */}
                      <a class="new-page-link" href="https://kremc.kr">KR-EMC 이동</a>
                       {/* </Button> */}
                </div>
                <dev class="service-list">
                    <p class="bold-list">(기존 접속 주소 (https://sre.kr) 로는 접속이 불가합니다.)</p>
                </dev>
            </div>
        </div>
    </div>
      </div>
    // </div>
  );
};

export default DomainError;