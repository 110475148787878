import kendo from "@progress/kendo-ui";
import { roundNum, numFormat, reduceTCo2 } from "../../com/dataUtil";
const reportConsmUrl = "/report/consm";

export const getEnergyMonth = async (conn, callback, reject, search) => {
  await conn
    .get(reportConsmUrl + "/list", {
      params: {
        dtm: kendo.toString(search.dtm, "yyyy-MM-dd"),
        dtmMonth: kendo.toString(search.dtm, "MM"),
        dtmYear: kendo.toString(search.dtm, "yyyy"),
        consmId: search.consmId,
      },
    })
    .then((response) => {
      const result = response;
      console.log("result = ", result);
      const powerMonth = [];
      let totalPower = 0;
      let totalCal = 0;

      // 발전량 생산열량 누적 값 구하기
      let accumPower = 0;
      let accumCal = 0;

      // 효율
      let dayAccumEfic = 0;
      let efic = 0;

      // 발전시간
      let powerTm = 0;

      result.forEach((element) => {
        let count = 1;
        if (element.ensoTypeCode === 15001 || element.ensoTypeCode === 15004 || element.ensoTypeCode === 15006) {
          totalPower += element.monthPower !== null ? element.monthPower : 0;
          accumPower += element.accumPower !== null ? element.accumPower : 0;
          // 발전시간
          powerTm += element.powerTm ? element.powerTm : 0;
          // 효율
          efic += element.dayAccumEfic ? element.dayAccumEfic : 0;
          dayAccumEfic = efic / count ;
          count ++ ;
        }
        if (element.ensoTypeCode === 15002 || element.ensoTypeCode === 15003) {
          totalCal += element.monthPower !== null ? element.monthPower : 0;
          accumCal += element.accumPower !== null ? element.accumPower : 0;
        }
      });

      powerMonth.push({
        totalPower: numFormat(roundNum(totalPower, 2)),
        accumPower: numFormat(roundNum(accumPower, 2)),
        powerTime: numFormat(roundNum(powerTm, 2)),
        powerCo2: accumPower !== null ? reduceTCo2(roundNum(accumPower, 2) * 0.46553) : 0,
        powerEfic: numFormat(roundNum(dayAccumEfic, 2)),
        totalCal: numFormat(roundNum(totalCal, 2)),
        accumCal: numFormat(roundNum(accumCal, 2)),
      });

      console.log("powerMonth = ", powerMonth);

      callback(powerMonth);
    })
    .catch((error) => {
      reject("수용가 보고서", error);
    });

  //  const powerMonth = [];
  // let totalPower = 0;
  // let totalCal = 0;

  // // 발전량 생산열량 누적 값 구하기
  // let accumPower = 0;
  // let accumCal = 0;

  //     powerMonth.push({
  //   totalPower : numFormat(roundNum(totalPower, 2)),
  //   accumPower : numFormat(roundNum(accumPower, 2)),
  //   powerTime : 0,
  //   powerCo2 : accumPower !== null ? reduceTCo2((roundNum(accumPower, 2))* 0.46553) : 0,
  //   powerEfic : 0,
  //   totalCal : numFormat(roundNum(totalCal, 2)),
  //   accumCal : numFormat(roundNum(accumCal, 2))
  // })

  // callback( powerMonth );
};

export const getEnergyCount = async (conn, callback, reject, search) => {
  await conn
    .get(reportConsmUrl + "/count", {
      params: {
        dtm: kendo.toString(search.dtm, "yyyy-MM-dd"),
        consmId: search.consmId,
      },
    })
    .then((response) => {
      const result = response;
      const powerCount = [];
      let solaCnt = 0;
      let solaCapa = 0;
      let heatCnt = 0;
      let heatCapa = 0;
      let heathCnt = 0;
      let heathCapa = 0;
      let veloCnt = 0;
      let veloCapa = 0;
      let fuelcellCnt = 0;
      let fuelcellCapa = 0;

      result.forEach((element) => {
        if (element.ensoTypeCode === 15001) {
          solaCnt = element.count !== null ? element.count : 0;
          solaCapa = element.instCapa !== null ? element.instCapa : 0;
        }
        if (element.ensoTypeCode === 15002) {
          heatCnt = element.count !== null ? element.count : 0;
          heatCapa = element.instCapa !== null ? element.instCapa : 0;
        }
        if (element.ensoTypeCode === 15003) {
          heathCnt = element.count !== null ? element.count : 0;
          heathCapa = element.instCapa !== null ? element.instCapa : 0;
        }
        if (element.ensoTypeCode === 15004) {
          veloCnt = element.count !== null ? element.count : 0;
          veloCapa = element.instCapa !== null ? element.instCapa : 0;
        }
        if (element.ensoTypeCode === 15006) {
          fuelcellCnt = element.count !== null ? element.count : 0;
          fuelcellCapa = element.instCapa !== null ? element.instCapa : 0;
        }
      });

      powerCount.push({
        totalCnt: numFormat(solaCnt + heatCnt + heathCnt + veloCnt + fuelcellCnt),
        totalCapa: numFormat(roundNum(solaCapa + heatCapa + heathCapa + veloCapa + fuelcellCapa, 2)),
        solaCnt: solaCnt,
        solaCapa: numFormat(roundNum(solaCapa, 2)),
        heatCnt: heatCnt,
        heatCapa: numFormat(roundNum(heatCapa, 2)),
        heathCnt: heathCnt,
        heathCapa: numFormat(roundNum(heathCapa, 2)),
        veloCnt: veloCnt,
        veloCapa: numFormat(roundNum(veloCapa, 2)),
        fuelcellCnt: fuelcellCnt,
        fuelcellCapa: numFormat(roundNum(fuelcellCapa, 2)),
        essCnt: 0,
        essCapa: 0,
      });

      callback(powerCount);
    })
    .catch((error) => {
      reject("수용가 보고서", error);
    });
};

export const getEnergyWarn = async (conn, callback, reject, search) => {
  // 마지막 날짜 설정
  const today = new Date();
  const year = kendo.toString(search.dtm, "yyyy");
  const month = kendo.toString(search.dtm.getMonth() + 1, "MM");
  const todayYear = kendo.toString(today.getFullYear(), "yyyy");
  const todayMonth = kendo.toString(today.getMonth() + 1, "MM");
  let date;

  if (year !== todayYear || month !== todayMonth) {
    date = new Date(year, month, 0);
  } else {
    date = today;
  }

  await conn
    .get(reportConsmUrl + "/warn", {
      params: {
        dtm: kendo.toString(search.dtm, "yyyy-MM-dd"),
        monthStart: kendo.toString(search.dtm, "yyyy-MM-01"),
        monthEnd: kendo.toString(date, "yyyy-MM-dd"),
        consmId: search.consmId,
      },
    })
    .then((response) => {
      const result = response;
      // console.log("result", result);
      const energyWarn = {
        unOperCnt: [],
        waringCnt: [],
        errorCnt: [],
      };

      result.forEach((element) => {
        energyWarn.unOperCnt.push({ name: element.dtm.substring(8, 10), y: element.unOperCnt });
        energyWarn.waringCnt.push({ name: element.dtm.substring(8, 10), y: element.waringCnt });
        energyWarn.errorCnt.push({ name: element.dtm.substring(8, 10), y: element.errorCnt });
      });

      callback(energyWarn);
    })
    .catch((error) => {
      reject("수용가 보고서", error);
    });
};

export const getPowerCompare = async (conn, callback, reject, search) => {
  const today = new Date();
  const todayYear = kendo.toString(today, "yyyy");
  const todayMonth = kendo.toString(today, "MM");
  let date;

  const searchDtm = kendo.toString(search.dtm, "yyyy-MM-dd");
  const searchDtmMonth = kendo.toString(search.dtm, "MM");
  const searchDtmYear = kendo.toString(search.dtm, "yyyy"); 
  
  if (searchDtmYear !== todayYear || searchDtmMonth !== todayMonth) {
    date = new Date(searchDtmYear, searchDtmMonth, 0);
  } else {
    date = today;
  }
  const searchDtmDay = kendo.toString(date, "dd");

  await conn
    .get(reportConsmUrl + "/chart", {
      params: {
        dtm: searchDtm,
        dtmMonth: searchDtmMonth,
        dtmYear: searchDtmYear,
        consmId: search.consmId,
      },
    })
    .then((response) => {
      // const result = response;
      const chartMonth = { energy: [], preEnergy: [] };
      const chartYearh = { energy: [], preEnergy: [] };
      const gridList = [];

      // const gridData = response;

      //금월 데이터
      const thisMonthData = [];
      //전월 데이터
      const lastMonthData = [];
      //작년 같은 월의 데이터
      const lastYearData = [];

      const temp = searchDtmMonth - 1;
      let lastDtmMonth;
      if (temp.toString().length === 1) lastDtmMonth = "0" + temp.toString();
      else lastDtmMonth = temp.toString();

      //데이터 날짜별로 나누기
      response.forEach((e) => {
        if (e.dtm.substring(0, 7) === searchDtmYear + "-" + searchDtmMonth) thisMonthData.push(e);
        else if (e.dtm.substring(0, 7) === searchDtmYear + "-" + lastDtmMonth) lastMonthData.push(e);
        else lastYearData.push(e);
      });

      const gridTemp = [];

      //데이터 가공 전월, 금월, 작년 데이터로 분류해서 비교
      for (let i = 1; i <= searchDtmDay; i++) {
        let day = "";
        if (i.toString().length === 1) day = "0" + i.toString();
        else day = i.toString();
        const _monthPower = thisMonthData.filter((item) => item.dtm.substring(8, 10) === day)[0];
        const _preMonthPower = lastMonthData.filter((item) => item.dtm.substring(8, 10) === day)[0];
        const _yearPower = thisMonthData.filter((item) => item.dtm.substring(8, 10) === day)[0];
        const _preYearPower = lastYearData.filter((item) => item.dtm.substring(8, 10) === day)[0];
        gridTemp.push({
          dtm: day,
          monthPower: _monthPower ? (_monthPower.dayPower !== null ? roundNum(_monthPower.dayPower, 2) : 0) : 0,
          preMonthPower: _preMonthPower ? (_preMonthPower.dayPower !== null ? roundNum(_preMonthPower.dayPower, 2) : 0) : 0,
          yearPower: _yearPower ? (_yearPower.dayPower !== null ? roundNum(_yearPower.dayPower, 2) : 0) : 0,
          preYearPower: _preYearPower ? (_preYearPower.dayPower !== null ? roundNum(_preYearPower.dayPower, 2) : 0) : 0,
        });
      }

      gridTemp.forEach((e) => {
        gridList.push({
          dtm: e.dtm + "일",
          monthPower: e.monthPower,
          preMonthPower: e.preMonthPower,
          yearPower: e.yearPower,
          preYearPower: e.preYearPower,
          increaseMonthRate:
            e.preMonthPower !== 0 && e.monthPower !== 0 ? parseFloat((((e.monthPower - e.preMonthPower) / e.preMonthPower) * 100).toFixed(2)) : null,
          increaseYearRate:
            e.preYearPower !== 0 && e.yearPower !== 0 ? parseFloat((((e.yearPower - e.preYearPower) / e.preYearPower) * 100).toFixed(2)) : null,
        });
      });

      /** 잘못된 데이터 수집 주석처리 */
      // // 그리드 데이터에 단위 넣기
      // gridData.forEach((element) => {
      //   gridList.push({
      //     dtm: element.dtm.substring(8, 10) + "일",
      //     monthPower: element.monthPower !== null ? numFormat(roundNum(element.monthPower, 2)) : 0,
      //     preMonthPower: element.preMonthPower !== null ? numFormat(roundNum(element.preMonthPower, 2)) : 0,
      //     increaseMonthRate:
      //       element.preMonthPower !== 0 && element.monthPower !== 0
      //         ? parseFloat((((element.monthPower - element.preMonthPower) / element.preMonthPower) * 100).toFixed(2))
      //         : 0,

      //     yearPower: element.yearPower !== null ? numFormat(roundNum(element.yearPower, 2)) : 0,
      //     preYearPower: element.preYearPower !== null ? numFormat(roundNum(element.preYearPower, 2)) : 0,
      //     increaseYearRate:
      //       element.preYearPower !== 0 && element.yearPower !== 0
      //         ? parseFloat((((element.yearPower - element.preYearPower) / element.preYearPower) * 100).toFixed(2))
      //         : 0,
      //   });
      // });

      gridList.forEach((element) => {
        // 전월 비교
        element.monthPower = element.monthPower !== null ? element.monthPower : 0;
        element.preMonthPower = element.preMonthPower !== null ? element.preMonthPower : 0;

        // 전년비교
        element.yearPower = element.yearPower !== null ? element.yearPower : 0;
        element.preYearPower = element.preYearPower !== null ? element.preYearPower : 0;

        // 전월 차트 데이터
        chartMonth.energy.push({ name: element.dtm, y: parseFloat(element.monthPower.toFixed(2)) });
        chartMonth.preEnergy.push({ name: element.dtm, y: parseFloat(element.preMonthPower.toFixed(2)) });

        // 전년 차트 데이터
        chartYearh.energy.push({ name: element.dtm, y: parseFloat(element.yearPower.toFixed(2)) });
        chartYearh.preEnergy.push({ name: element.dtm, y: parseFloat(element.preYearPower.toFixed(2)) });
      });

      callback(gridList, chartMonth, chartYearh);
    })
    .catch((error) => {
      reject("수용가 보고서", error);
    });

  // const chartMonth = { energy : [] , preEnergy : []};
  // const chartYearh = { energy : [] , preEnergy : []};
  // const gridList = [];

  // // 그리드 데이터에 단위 넣기
  //   gridList.push({
  //     dtm :  '1일',
  //     monthPower : numFormat(roundNum( 1000, 2 )),
  //     preMonthPower : numFormat(roundNum( 500, 2 )),
  //     increaseMonthRate : preMonthPower !== 0 && monthPower !== 0? parseFloat(((monthPower - preMonthPower) / preMonthPower * 100).toFixed(2)) : 0 ,

  //     yearPower : numFormat(roundNum( 10000, 2 )),
  //     preYearPower : numFormat(roundNum( 1000, 2 )),
  //     increaseYearRate : preYearPower !== 0 && yearPower !== 0? parseFloat(((yearPower - preYearPower)  / preYearPower * 100).toFixed(2)) : 0

  //   })

  //     const monthPower = 1000;
  //     const preMonthPower = 500;

  //   // 전년비교
  //     const yearPower = 10000 ;
  //     const preYearPower = 1000 ;

  //     // 전월 차트 데이터
  //     chartMonth.energy.push({name : "1일", y :  parseFloat(monthPower.toFixed(2))})
  //     chartMonth.preEnergy.push({name : "1일", y : parseFloat(preMonthPower.toFixed(2))})

  //     // 전년 차트 데이터
  //     chartYearh.energy.push({name :"1일", y :  parseFloat(yearPower.toFixed(2))})
  //     chartYearh.preEnergy.push({name : "1일", y : parseFloat(preYearPower.toFixed(2))})

  // callback( gridList, chartMonth, chartYearh );
};
