import React from "react";

const JeongSeonMunicMapTable = ({
  dongData , infoBoxRef, styles 
  , boxClickEvt 
  , CountUp
}) => {
  
   // 테이블 ID 설정
  const tableId = "tb_"+dongData.dongCode;

  return (
    <div className={ ["infoBoxLocalArea" , dongData.position ].join(' ') } 
      ref={infoBoxRef}  
      id = { tableId } 
      onClick={() => {boxClickEvt(dongData.dongCode)}} 
      // onMouseOver={() => { boxOverEvt(dongData.dongCode) }}
      // onMouseOut={() => {boxOutEvt(dongData.dongCode)}}
    >
      <table>
        <thead>
            <tr>
                <th colspan="3" id = { "info_title_" + dongData.dongCode } >{dongData.dongTerm}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th id = { "info_text_cnt_" + dongData.dongCode } >설비개소</th>
                <td><CountUp end={dongData.totalCnt} separator="," duration={1} decimals={0} delay={1} redraw={true} /></td>
                <td>개</td>
            </tr>
            <tr>
                <th id = { "info_text_power_" + dongData.dongCode } >생산량</th>
                <td className={styles.tablePowerData}><CountUp end={dongData.totalDayPower} separator="," duration={1} decimals={1} delay={1} redraw={true} /></td>
                <td>kW</td>
            </tr>
        </tbody>
      </table>
    </div>
  );
}

export default JeongSeonMunicMapTable;