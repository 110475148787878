import React from "react";
import NumberFormat from "react-number-format";
import { setInstData } from "../../../../service/bizStat/com/infoApiService";

const BizInstAdd = ({ conn, codeList, inputs, instInput, setDataSource, inst, search, DropDownList, Button, kendo, getMessage }) => {
  console.log("inst", inst);
  console.log("instInput", instInput);

  const solaFormCapa = codeList.filter(c => c.grpCodeNo === 42);
  const solaInstType = codeList.filter(c => c.grpCodeNo === 43);
  const heatFormCapa = codeList.filter(c => c.grpCodeNo === 44);
  const heatInstType = codeList.filter(c => c.grpCodeNo === 45);
  const heathFormCapa = codeList.filter(c => c.grpCodeNo === 46);
  const heathInstType = codeList.filter(c => c.grpCodeNo === 47);
  const spendItem = codeList.filter(c => c.grpCodeNo === 48);

  const veloFormCapa = codeList.filter(c => c.grpCodeNo === 54);
  const veloInstType = codeList.filter(c => c.grpCodeNo === 55);
  const fuelFormCapa = codeList.filter(c => c.grpCodeNo === 56);
  const fuelInstType = codeList.filter(c => c.grpCodeNo === 57);

  /**
   * 설비내역 열원 정보 있을 경우 아래 필터 작업 진행
   */
  if (inst) {
    if (inst.sola !== "") {
      if (inst.sola.formCapa) {
        const solaFormCapaData = solaFormCapa.filter(c => c.codeNo === parseInt(inst.sola.formCapa))[0];
        inst.sola.formCapaData = solaFormCapaData;
      }
      if (inst.sola.instType) {
        const solaInstTypeData = solaInstType.filter(c => c.codeNo === parseInt(inst.sola.instType))[0];
        inst.sola.instTypeData = solaInstTypeData;
      }
      if (inst.sola.spendItem) {
        const solaSpendItemData = spendItem.filter(c => c.codeNo === parseInt(inst.sola.spendItem))[0];
        inst.sola.spendItemData = solaSpendItemData;
      }
    }
    if (inst.heat !== "") {
      if (inst.heat.formCapa) {
        const heatFormCapaData = heatFormCapa.filter(c => c.codeNo === parseInt(inst.heat.formCapa))[0];
        inst.heat.formCapaData = heatFormCapaData;
      }
      if (inst.heat.instType) {
        const heatInstTypeData = heatInstType.filter(c => c.codeNo === parseInt(inst.heat.instType))[0];
        inst.heat.instTypeData = heatInstTypeData;
      }
      if (inst.heat.spendItem) {
        const heatSpendItemData = spendItem.filter(c => c.codeNo === parseInt(inst.heat.spendItem))[0];
        inst.heat.spendItemData = heatSpendItemData;
      }
    }
    if (inst.velo !== "") {
      if (inst.velo.formCapa) {
        const veloFormCapaData = veloFormCapa.filter(c => c.codeNo === parseInt(inst.velo.formCapa))[0];
        inst.velo.formCapaData = veloFormCapaData;
      }
      if (inst.velo.instType) {
        const veloInstTypeData = veloInstType.filter(c => c.codeNo === parseInt(inst.velo.instType))[0];
        inst.velo.instTypeData = veloInstTypeData;
      }
      if (inst.velo.spendItem) {
        const veloSpendItemData = spendItem.filter(c => c.codeNo === parseInt(inst.velo.spendItem))[0];
        inst.velo.spendItemData = veloSpendItemData;
      }
    }
    if (inst.geot !== "") {
      if (inst.geot.formCapa) {
        const heathFormCapaData = heathFormCapa.filter(c => c.codeNo === parseInt(inst.geot.formCapa))[0];
        inst.geot.formCapaData = heathFormCapaData;
      }
      if (inst.geot.instType) {
        const heathInstTypeData = heathInstType.filter(c => c.codeNo === parseInt(inst.geot.instType))[0];
        inst.geot.instTypeData = heathInstTypeData;
      }
      if (inst.geot.spendItem) {
        const heathSpendItemData = spendItem.filter(c => c.codeNo === parseInt(inst.geot.spendItem))[0];
        inst.geot.spendItemData = heathSpendItemData;
      }
    }
    if (inst.fuel !== "") {
      if (inst.fuel.formCapa) {
        const fuelFormCapaData = fuelFormCapa.filter(c => c.codeNo === parseInt(inst.fuel.formCapa))[0];
        inst.fuel.formCapaData = fuelFormCapaData;
      }
      if (inst.fuel.instType) {
        const fuelInstTypeData = fuelInstType.filter(c => c.codeNo === parseInt(inst.fuel.instType))[0];
        inst.fuel.instTypeData = fuelInstTypeData;
      }
      if (inst.fuel.spendItem) {
        const fuelSpendItemData = spendItem.filter(c => c.codeNo === parseInt(inst.fuel.spendItem))[0];
        inst.fuel.spendItemData = fuelSpendItemData;
      }
    }
  }

  const onDropDownChange = event => {
    const name = event.target.props.name;
    const energyName = [name.slice(0, 4)];
    const dataName = name.slice(4, 16);

    setDataSource(value => {
      const item = value;
      item.instInput[energyName].instlPlnnm = inputs.instlPlnnm;

      if (inst[energyName] === "") {
        item.instInput[energyName][dataName] = event.target.value.codeNo;
      } else {
        item.instInput[energyName][dataName] = event.target.value;
      }

      // console.log("item = ", item);
      return item;
    });
  };

  // 설비내역 데이터 저장 하기
  function saveInstDataSource(dataItem) {
    console.log("dataItem", dataItem);
    setInstData(conn, dataItem, search)
      .then(res => {
        kendo.alert("설비내역을 저장 하였습니다.");
      })
      .catch(res => {
        // console.log( "Failed SaveInstList", res);
        kendo.alert("설비내역 저장을 실패 하였습니다.");
        alert(getMessage(res.errCode));
      });
  }

  const deleteCommas = str => {
    return str.replace(/,/g, "");
  };

  const onInstChange = e => {
    //input에 name을 가진 요소의 value에 이벤트
    const { name, value } = e.target;
    const energyName = [name.slice(0, 4)];
    const dataName = name.slice(4, 16);

    setDataSource(data => {
      const item = data;
      item.instInput[energyName].instlPlnnm = inputs.instlPlnnm;
      item.instInput[energyName][dataName] = deleteCommas(value);
      return item;
    });
  };  

  // 설비내역 저장
  const saveInstChanges = e => {
    const instlList = [];
    /* eslint-disable-next-line */
    const regExpCost = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/g;

    // console.log("설비 내역 저장");
    if (instInput.sola.formCapa !== "" || instInput.sola.instType !== "" || instInput.sola.spendItem !== "") {
      if (instInput.sola.formCapa !== "" && instInput.sola.instType !== "" && instInput.sola.spendItem !== "") {
        if (inst.sola !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.sola.energyTypeCode = instInput.sola.energyTypeCode?.codeNo
              ? instInput.sola.energyTypeCode.codeNo
              : instInput.sola.energyTypeCode;
            item.instInput.sola.formCapa = instInput.sola.formCapa?.codeNo ? instInput.sola.formCapa.codeNo : instInput.sola.formCapa;
            item.instInput.sola.instType = instInput.sola.instType?.codeNo ? instInput.sola.instType.codeNo : instInput.sola.instType;
            item.instInput.sola.spendItem = instInput.sola.spendItem?.codeNo ? instInput.sola.spendItem.codeNo : instInput.sola.spendItem;
            return item;
          });
        }

        if (
          regExpCost.test(instInput.sola.totalExpense) ||
          regExpCost.test(instInput.sola.gvmSpt) ||
          regExpCost.test(instInput.sola.mupal) ||
          regExpCost.test(instInput.sola.priCompany)
        ) {
          kendo.alert("태양광 총사업비, 정부지원, 지자체, 민간은 소수점 단위 입력이 불가능합니다.");
          return;
        }

        instlList.push(instInput.sola);
      } else {
        kendo.alert("태양광 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.heat.formCapa !== "" || instInput.heat.instType !== "" || instInput.heat.spendItem !== "") {
      if (instInput.heat.formCapa !== "" && instInput.heat.instType !== "" && instInput.heat.spendItem !== "") {
        if (inst.heat !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.heat.energyTypeCode = instInput.heat.energyTypeCode?.codeNo
              ? instInput.heat.energyTypeCode.codeNo
              : instInput.heat.energyTypeCode;
            item.instInput.heat.formCapa = instInput.heat.formCapa?.codeNo ? instInput.heat.formCapa.codeNo : instInput.heat.formCapa;
            item.instInput.heat.instType = instInput.heat.instType?.codeNo ? instInput.heat.instType.codeNo : instInput.heat.instType;
            item.instInput.heat.spendItem = instInput.heat.spendItem?.codeNo ? instInput.heat.spendItem.codeNo : instInput.heat.spendItem;
            return item;
          });
        }

        if (
          regExpCost.test(instInput.heat.totalExpense) ||
          regExpCost.test(instInput.heat.gvmSpt) ||
          regExpCost.test(instInput.heat.mupal) ||
          regExpCost.test(instInput.heat.priCompany)
        ) {
          kendo.alert("태양열 총사업비, 정부지원, 지자체, 민간은 소수점 단위 입력이 불가능합니다.");
          return;
        }

        instlList.push(instInput.heat);
      } else {
        kendo.alert("태양열 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }
    // 풍력 설비 저장 로직
    if (instInput.velo.formCapa !== "" || instInput.velo.instType !== "" || instInput.velo.spendItem !== "") {
      if (instInput.velo.formCapa !== "" && instInput.velo.instType !== "" && instInput.velo.spendItem !== "") {
        if (inst.velo !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.velo.energyTypeCode = instInput.velo.energyTypeCode?.codeNo
              ? instInput.velo.energyTypeCode.codeNo
              : instInput.velo.energyTypeCode;
            item.instInput.velo.formCapa = instInput.velo.formCapa?.codeNo ? instInput.velo.formCapa.codeNo : instInput.velo.formCapa;
            item.instInput.velo.instType = instInput.velo.instType?.codeNo ? instInput.velo.instType.codeNo : instInput.velo.instType;
            item.instInput.velo.spendItem = instInput.velo.spendItem?.codeNo ? instInput.velo.spendItem.codeNo : instInput.velo.spendItem;
            return item;
          });
        }

        if (
          regExpCost.test(instInput.velo.totalExpense) ||
          regExpCost.test(instInput.velo.gvmSpt) ||
          regExpCost.test(instInput.velo.mupal) ||
          regExpCost.test(instInput.velo.priCompany)
        ) {
          kendo.alert("소형풍력 총사업비, 정부지원, 지자체, 민간은 소수점 단위 입력이 불가능합니다.");
          return;
        }

        instlList.push(instInput.velo);
      } else {
        kendo.alert("소형풍력 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.geot.formCapa !== "" || instInput.geot.instType !== "" || instInput.geot.spendItem !== "") {
      if (instInput.geot.formCapa !== "" && instInput.geot.instType !== "" && instInput.geot.spendItem !== "") {
        if (inst.geot !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.geot.energyTypeCode = instInput.geot.energyTypeCode?.codeNo
              ? instInput.geot.energyTypeCode.codeNo
              : instInput.geot.energyTypeCode;
            item.instInput.geot.formCapa = instInput.geot.formCapa?.codeNo ? instInput.geot.formCapa.codeNo : instInput.geot.formCapa;
            item.instInput.geot.instType = instInput.geot.instType?.codeNo ? instInput.geot.instType.codeNo : instInput.geot.instType;
            item.instInput.geot.spendItem = instInput.geot.spendItem?.codeNo ? instInput.geot.spendItem.codeNo : instInput.geot.spendItem;
            return item;
          });
        }

        if (
          regExpCost.test(instInput.geot.totalExpense) ||
          regExpCost.test(instInput.geot.gvmSpt) ||
          regExpCost.test(instInput.geot.mupal) ||
          regExpCost.test(instInput.geot.priCompany)
        ) {
          kendo.alert("지열 총사업비, 정부지원, 지자체, 민간은 소수점 단위 입력이 불가능합니다.");
          return;
        }

        instlList.push(instInput.geot);
      } else {
        kendo.alert("지열 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.fuel.formCapa !== "" || instInput.fuel.instType !== "" || instInput.fuel.spendItem !== "") {
      if (instInput.fuel.formCapa !== "" && instInput.fuel.instType !== "" && instInput.fuel.spendItem !== "") {
        if (inst.fuel !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.fuel.energyTypeCode = instInput.fuel.energyTypeCode?.codeNo
              ? instInput.fuel.energyTypeCode.codeNo
              : instInput.fuel.energyTypeCode;
            item.instInput.fuel.formCapa = instInput.fuel.formCapa?.codeNo ? instInput.fuel.formCapa.codeNo : instInput.fuel.formCapa;
            item.instInput.fuel.instType = instInput.fuel.instType?.codeNo ? instInput.fuel.instType.codeNo : instInput.fuel.instType;
            item.instInput.fuel.spendItem = instInput.fuel.spendItem?.codeNo ? instInput.fuel.spendItem.codeNo : instInput.fuel.spendItem;
            return item;
          });
        }

        instlList.push(instInput.fuel);
      } else {
        kendo.alert("연료전지 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.wast.formCapa !== "" || instInput.wast.instType !== "" || instInput.wast.spendItem !== "") {
      if (instInput.wast.formCapa !== "" && instInput.wast.instType !== "" && instInput.wast.spendItem !== "") {
        if (inst.wast !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.wast.energyTypeCode = instInput.wast.energyTypeCode?.codeNo
              ? instInput.wast.energyTypeCode.codeNo
              : instInput.wast.energyTypeCode;
            item.instInput.wast.formCapa = instInput.wast.formCapa?.codeNo ? instInput.wast.formCapa.codeNo : instInput.wast.formCapa;
            item.instInput.wast.instType = instInput.wast.instType?.codeNo ? instInput.wast.instType.codeNo : instInput.wast.instType;
            item.instInput.wast.spendItem = instInput.wast.spendItem?.codeNo ? instInput.wast.spendItem.codeNo : instInput.wast.spendItem;
            return item;
          });
        }

        instlList.push(instInput.wast);
      } else {
        kendo.alert("폐기물 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.baio.formCapa !== "" || instInput.baio.instType !== "" || instInput.baio.spendItem !== "") {
      if (instInput.baio.formCapa !== "" && instInput.baio.instType !== "" && instInput.baio.spendItem !== "") {
        if (inst.baio !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.baio.energyTypeCode = instInput.baio.energyTypeCode?.codeNo
              ? instInput.baio.energyTypeCode.codeNo
              : instInput.baio.energyTypeCode;
            item.instInput.baio.formCapa = instInput.baio.formCapa?.codeNo ? instInput.baio.formCapa.codeNo : instInput.baio.formCapa;
            item.instInput.baio.instType = instInput.baio.instType?.codeNo ? instInput.baio.instType.codeNo : instInput.baio.instType;
            item.instInput.baio.spendItem = instInput.baio.spendItem?.codeNo ? instInput.baio.spendItem.codeNo : instInput.baio.spendItem;
            return item;
          });
        }

        instlList.push(instInput.baio);
      } else {
        kendo.alert("바이오 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.coli.formCapa !== "" || instInput.coli.instType !== "" || instInput.coli.spendItem !== "") {
      if (instInput.coli.formCapa !== "" && instInput.coli.instType !== "" && instInput.coli.spendItem !== "") {
        if (inst.coli !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.coli.energyTypeCode = instInput.coli.energyTypeCode?.codeNo
              ? instInput.coli.energyTypeCode.codeNo
              : instInput.coli.energyTypeCode;
            item.instInput.coli.formCapa = instInput.coli.formCapa?.codeNo ? instInput.coli.formCapa.codeNo : instInput.coli.formCapa;
            item.instInput.coli.instType = instInput.coli.instType?.codeNo ? instInput.coli.instType.codeNo : instInput.coli.instType;
            item.instInput.coli.spendItem = instInput.coli.spendItem?.codeNo ? instInput.coli.spendItem.codeNo : instInput.coli.spendItem;
            return item;
          });
        }

        instlList.push(instInput.coli);
      } else {
        kendo.alert("집광채광 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.smpo.formCapa !== "" || instInput.smpo.instType !== "" || instInput.smpo.spendItem !== "") {
      if (instInput.smpo.formCapa !== "" && instInput.smpo.instType !== "" && instInput.smpo.spendItem !== "") {
        if (inst.smpo !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.smpo.energyTypeCode = instInput.smpo.energyTypeCode?.codeNo
              ? instInput.smpo.energyTypeCode.codeNo
              : instInput.smpo.energyTypeCode;
            item.instInput.smpo.formCapa = instInput.smpo.formCapa?.codeNo ? instInput.smpo.formCapa.codeNo : instInput.smpo.formCapa;
            item.instInput.smpo.instType = instInput.smpo.instType?.codeNo ? instInput.smpo.instType.codeNo : instInput.smpo.instType;
            item.instInput.smpo.spendItem = instInput.smpo.spendItem?.codeNo ? instInput.smpo.spendItem.codeNo : instInput.smpo.spendItem;
            return item;
          });
        }

        instlList.push(instInput.smpo);
      } else {
        kendo.alert("소수력 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.hydr.formCapa !== "" || instInput.hydr.instType !== "" || instInput.hydr.spendItem !== "") {
      if (instInput.hydr.formCapa !== "" && instInput.hydr.instType !== "" && instInput.hydr.spendItem !== "") {
        if (inst.hydr !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.hydr.energyTypeCode = instInput.hydr.energyTypeCode?.codeNo
              ? instInput.hydr.energyTypeCode.codeNo
              : instInput.hydr.energyTypeCode;
            item.instInput.hydr.formCapa = instInput.hydr.formCapa?.codeNo ? instInput.hydr.formCapa.codeNo : instInput.hydr.formCapa;
            item.instInput.hydr.instType = instInput.hydr.instType?.codeNo ? instInput.hydr.instType.codeNo : instInput.hydr.instType;
            item.instInput.hydr.spendItem = instInput.hydr.spendItem?.codeNo ? instInput.hydr.spendItem.codeNo : instInput.hydr.spendItem;
            return item;
          });
        }

        instlList.push(instInput.hydr);
      } else {
        kendo.alert("수열에너지 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.wope.formCapa !== "" || instInput.wope.instType !== "" || instInput.wope.spendItem !== "") {
      if (instInput.wope.formCapa !== "" && instInput.wope.instType !== "" && instInput.wope.spendItem !== "") {
        if (inst.wope !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.wope.energyTypeCode = instInput.wope.energyTypeCode?.codeNo
              ? instInput.wope.energyTypeCode.codeNo
              : instInput.wope.energyTypeCode;
            item.instInput.wope.formCapa = instInput.wope.formCapa?.codeNo ? instInput.wope.formCapa.codeNo : instInput.wope.formCapa;
            item.instInput.wope.instType = instInput.wope.instType?.codeNo ? instInput.wope.instType.codeNo : instInput.wope.instType;
            item.instInput.wope.spendItem = instInput.wope.spendItem?.codeNo ? instInput.wope.spendItem.codeNo : instInput.wope.spendItem;
            return item;
          });
        }

        instlList.push(instInput.wope);
      } else {
        kendo.alert("목재펠릿 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.psd1.formCapa !== "" || instInput.psd1.instType !== "" || instInput.psd1.spendItem !== "") {
      if (instInput.psd1.formCapa !== "" && instInput.psd1.instType !== "" && instInput.psd1.spendItem !== "") {
        if (inst.psd1 !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.psd1.energyTypeCode = instInput.psd1.energyTypeCode?.codeNo
              ? instInput.psd1.energyTypeCode.codeNo
              : instInput.psd1.energyTypeCode;
            item.instInput.psd1.formCapa = instInput.psd1.formCapa?.codeNo ? instInput.psd1.formCapa.codeNo : instInput.psd1.formCapa;
            item.instInput.psd1.instType = instInput.psd1.instType?.codeNo ? instInput.psd1.instType.codeNo : instInput.psd1.instType;
            item.instInput.psd1.spendItem = instInput.psd1.spendItem?.codeNo ? instInput.psd1.spendItem.codeNo : instInput.psd1.spendItem;
            return item;
          });
        }

        instlList.push(instInput.psd1);
      } else {
        kendo.alert("전력저장장치(1) 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.psd2.formCapa !== "" || instInput.psd2.instType !== "" || instInput.psd2.spendItem !== "") {
      if (instInput.psd2.formCapa !== "" && instInput.psd2.instType !== "" && instInput.psd2.spendItem !== "") {
        if (inst.psd2 !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.psd2.energyTypeCode = instInput.psd2.energyTypeCode?.codeNo
              ? instInput.psd2.energyTypeCode.codeNo
              : instInput.psd2.energyTypeCode;
            item.instInput.psd2.formCapa = instInput.psd2.formCapa?.codeNo ? instInput.psd2.formCapa.codeNo : instInput.psd2.formCapa;
            item.instInput.psd2.instType = instInput.psd2.instType?.codeNo ? instInput.psd2.instType.codeNo : instInput.psd2.instType;
            item.instInput.psd2.spendItem = instInput.psd2.spendItem?.codeNo ? instInput.psd2.spendItem.codeNo : instInput.psd2.spendItem;
            return item;
          });
        }

        instlList.push(instInput.psd2);
      } else {
        kendo.alert("전력저장장치(2) 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.psd3.formCapa !== "" || instInput.psd3.instType !== "" || instInput.psd3.spendItem !== "") {
      if (instInput.psd3.formCapa !== "" && instInput.psd3.instType !== "" && instInput.psd3.spendItem !== "") {
        if (inst.psd3 !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.psd3.energyTypeCode = instInput.psd3.energyTypeCode?.codeNo
              ? instInput.psd3.energyTypeCode.codeNo
              : instInput.psd3.energyTypeCode;
            item.instInput.psd3.formCapa = instInput.psd3.formCapa?.codeNo ? instInput.psd3.formCapa.codeNo : instInput.psd3.formCapa;
            item.instInput.psd3.instType = instInput.psd3.instType?.codeNo ? instInput.psd3.instType.codeNo : instInput.psd3.instType;
            item.instInput.psd3.spendItem = instInput.psd3.spendItem?.codeNo ? instInput.psd3.spendItem.codeNo : instInput.psd3.spendItem;
            return item;
          });
        }

        instlList.push(instInput.psd3);
      } else {
        kendo.alert("전력저장장치(3) 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    if (instInput.psd4.formCapa !== "" || instInput.psd4.instType !== "" || instInput.psd4.spendItem !== "") {
      if (instInput.psd4.formCapa !== "" && instInput.psd4.instType !== "" && instInput.psd4.spendItem !== "") {
        if (inst.psd4 !== "") {
          setDataSource(value => {
            const item = value;
            item.instInput.psd4.energyTypeCode = instInput.psd4.energyTypeCode?.codeNo
              ? instInput.psd4.energyTypeCode.codeNo
              : instInput.psd4.energyTypeCode;
            item.instInput.psd4.formCapa = instInput.psd4.formCapa?.codeNo ? instInput.psd4.formCapa.codeNo : instInput.psd4.formCapa;
            item.instInput.psd4.instType = instInput.psd4.instType?.codeNo ? instInput.psd4.instType.codeNo : instInput.psd4.instType;
            item.instInput.psd4.spendItem = instInput.psd4.spendItem?.codeNo ? instInput.psd4.spendItem.codeNo : instInput.psd4.spendItem;
            return item;
          });
        }

        instlList.push(instInput.psd4);
      } else {
        kendo.alert("전력저장장치(4) 형식(용량), 설비타입, 지출항목은 필수 입력 항목입니다. 다시 확인해주시기 바랍니다.");
        return;
      }
    }

    // 저장할 데이터 정리 : jhchoi : 20220311
    const saveList = [];
    let isSave = true;
    instlList.some((item) => {
      if (item.instType && item.formCapa && item.spendItem) {
        saveList.push(item);
      } else {
        isSave = false;
        kendo.alert(
          "형식(용량), 설비타입, 지출항목 을 다시 선택으로 선택하고 저장하면 저장이 안됩니다. 삭제는 진척률에서 해주시기 바랍니다.",
        );
        return true;
      }

      return false;
    });    

    // console.log("saveList = ", saveList);
    // console.log("isSave = ", isSave);

    // 저장 여부 ( isSave가 true가 아니면 저장 불가 )
    if (isSave) {
      // console.log("저장 확인");
      saveInstDataSource(saveList);
    }
  };

  return (
    <>
      <div class="SubM_titleB">[ 설비내역(조정전) ]</div>
      <div class="eer-Table-scr" style={{ height: "390px" }}>
        <table class="remcBB-Table eer-Table-striped k-grid-table">
          <thead>
            <tr>
              <th>구분</th>
              <th style={{ color: "red" }}>형식(용량)</th>
              <th style={{ color: "red" }}>설비타입</th>
              <th>설치용량</th>
              <th>단위</th>
              <th>총사업비(원)</th>
              <th>정부지원(원)</th>
              <th>지자체(원)</th>
              {/* <th>도비(원)</th> */}
              <th>민간(원)</th>
              <th style={{ color: "red" }}>지출항목</th>
              <th>시공기업</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>태양광</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="solaformCapa"
                    component={DropDownList}
                    data={solaFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.sola !== ""
                        ? {
                            codeNo: inst.sola.formCapa,
                            codeVal: inst.sola.formCapaData ? (inst.sola.formCapaData.codeVal ? inst.sola.formCapaData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="solainstType"
                    component={DropDownList}
                    data={solaInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.sola !== ""
                        ? {
                            codeNo: inst.sola.instType,
                            codeVal: inst.sola.instTypeData ? (inst.sola.instTypeData.codeVal ? inst.sola.instTypeData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="solainstCapa"
                  type="text"
                  class="tbText inwidth01"
                  value={inst.sola.instCapa !== "" ? inst.sola.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="solaunit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  value={inst.sola.unit ? inst.sola.unit : "kW"}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="solatotalExpense"
                  type="text"
                  class="tbText inwidth02"
                  value={inst.sola.totalExpense !== "" ? inst.sola.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="solagvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  value={inst.sola.gvmSpt !== "" ? inst.sola.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="solamupal"
                  type="text"
                  class="tbText inwidth02"
                  value={inst.sola.mupal !== "" ? inst.sola.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="solaprovince" type="text" class="tbText inwidth02" defaultValue={instInput.sola.province != '' ? instInput.sola.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="solapriCompany"
                  type="text"
                  class="tbText inwidth02"
                  value={inst.sola.priCompany !== "" ? inst.sola.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="solaspendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.sola !== ""
                        ? {
                            codeNo: inst.sola.spendItem,
                            codeVal: inst.sola.spendItemData
                              ? inst.sola.spendItemData.codeVal
                                ? inst.sola.spendItemData.codeVal
                                : "-"
                              : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="solaconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.sola.consEnte !== "" ? inst.sola.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr className="k-master-row k-alt">
              <td>태양열</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="heatformCapa"
                    component={DropDownList}
                    data={heatFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.heat !== ""
                        ? {
                            codeNo: inst.heat.formCapa,
                            codeVal: inst.heat.formCapaData ? (inst.heat.formCapaData.codeVal ? inst.heat.formCapaData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="heatinstType"
                    component={DropDownList}
                    data={heatInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.heat !== ""
                        ? {
                            codeNo: inst.heat.instType,
                            codeVal: inst.heat.instTypeData ? (inst.heat.instTypeData.codeVal ? inst.heat.instTypeData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="heatinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={inst.heat.instCapa !== "" ? inst.heat.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="heatunit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="㎡"
                  defaultValue={inst.heat.unit ? inst.heat.unit : "㎡"}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="heattotalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.heat.totalExpense !== "" ? inst.heat.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="heatgvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.heat.gvmSpt !== "" ? inst.heat.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="heatmupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.heat.mupal !== "" ? inst.heat.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="heatprovince" type="text" class="tbText inwidth02" defaultValue={instInput.heat.province != '' ? instInput.heat.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="heatpriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.heat.priCompany !== "" ? inst.heat.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="heatspendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.heat !== ""
                        ? {
                            codeNo: inst.heat.spendItem,
                            codeVal: inst.heat.spendItemData
                              ? inst.heat.spendItemData.codeVal
                                ? inst.heat.spendItemData.codeVal
                                : "-"
                              : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="heatconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.heat.consEnte !== "" ? inst.heat.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr>
              <td>소형풍력</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="veloformCapa"
                    component={DropDownList}
                    data={veloFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.velo !== ""
                        ? {
                            codeNo: inst.velo.formCapa,
                            codeVal: inst.velo.formCapaData ? (inst.velo.formCapaData.codeVal ? inst.velo.formCapaData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="veloinstType"
                    component={DropDownList}
                    data={veloInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.velo !== ""
                        ? {
                            codeNo: inst.velo.instType,
                            codeVal: inst.velo.instTypeData ? (inst.velo.instTypeData.codeVal ? inst.velo.instTypeData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="veloinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={inst.velo.instCapa !== "" ? inst.velo.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="velounit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={inst.velo.unit ? inst.velo.unit : "kW"}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="velototalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.velo.totalExpense !== "" ? inst.velo.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="velogvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.velo.gvmSpt !== "" ? inst.velo.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="velomupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.velo.mupal !== "" ? inst.velo.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="veloprovince" type="text" class="tbText inwidth02" defaultValue={instInput.velo.province != '' ? instInput.velo.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="velopriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.velo.priCompany !== "" ? inst.velo.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="velospendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.velo !== ""
                        ? {
                            codeNo: inst.velo.spendItem,
                            codeVal: inst.velo.spendItemData
                              ? inst.velo.spendItemData.codeVal
                                ? inst.velo.spendItemData.codeVal
                                : "-"
                              : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="veloconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.velo.consEnte !== "" ? inst.velo.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr className="k-master-row k-alt">
              <td>지열</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="geotformCapa"
                    component={DropDownList}
                    data={heathFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.geot !== ""
                        ? {
                            codeNo: inst.geot.formCapa,
                            codeVal: inst.geot.formCapaData ? (inst.geot.formCapaData.codeVal ? inst.geot.formCapaData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="geotinstType"
                    component={DropDownList}
                    data={heathInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.geot !== ""
                        ? {
                            codeNo: inst.geot.instType,
                            codeVal: inst.geot.instTypeData ? (inst.geot.instTypeData.codeVal ? inst.geot.instTypeData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="geotinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={inst.geot.instCapa !== "" ? inst.geot.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="geotunit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={inst.geot.unit ? inst.geot.unit : "kW"}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="geottotalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.geot.totalExpense !== "" ? inst.geot.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="geotgvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.geot.gvmSpt !== "" ? inst.geot.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="geotmupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.geot.mupal !== "" ? inst.geot.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="geotprovince" type="text" class="tbText inwidth02" defaultValue={instInput.geot.province != '' ? instInput.geot.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="geotpriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.geot.priCompany !== "" ? inst.geot.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="geotspendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.geot !== ""
                        ? {
                            codeNo: inst.geot.spendItem,
                            codeVal: inst.geot.spendItemData
                              ? inst.geot.spendItemData.codeVal
                                ? inst.geot.spendItemData.codeVal
                                : "-"
                              : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="geotconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={inst.geot.consEnte !== "" ? inst.geot.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr>
              <td>연료전지</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="fuelformCapa"
                    component={DropDownList}
                    data={fuelFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.fuel !== ""
                        ? {
                            codeNo: inst.fuel.formCapa,
                            codeVal: inst.fuel.formCapaData ? (inst.fuel.formCapaData.codeVal ? inst.fuel.formCapaData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="fuelinstType"
                    component={DropDownList}
                    data={fuelInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.fuel !== ""
                        ? {
                            codeNo: inst.fuel.instType,
                            codeVal: inst.fuel.instTypeData ? (inst.fuel.instTypeData.codeVal ? inst.fuel.instTypeData.codeVal : "-") : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="fuelinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.fuel.instCapa !== "" ? instInput.fuel.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="fuelunit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.fuel.unit !== "" ? instInput.fuel.unit : "kW"}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="fueltotalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.fuel.totalExpense !== "" ? instInput.fuel.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="fuelgvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.fuel.gvmSpt !== "" ? instInput.fuel.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="fuelmupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.fuel.mupal !== "" ? instInput.fuel.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="fuelprovince" type="text" class="tbText inwidth02" defaultValue={instInput.fuel.province != '' ? instInput.fuel.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="fuelpriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.fuel.priCompany !== "" ? instInput.fuel.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="fuelspendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.fuel !== ""
                        ? {
                            codeNo: inst.fuel.spendItem,
                            codeVal: inst.fuel.spendItemData
                              ? inst.fuel.spendItemData.codeVal
                                ? inst.fuel.spendItemData.codeVal
                                : "-"
                              : "-",
                          }
                        : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="fuelconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.fuel.consEnte !== "" ? instInput.fuel.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr className="k-master-row k-alt">
              <td>폐기물</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="wastformCapa"
                    component={DropDownList}
                    // data={wastFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.wast !== "" ? { codeNo: inst.wast.formCapa, codeVal: inst.wast.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="wastinstType"
                    component={DropDownList}
                    // data={wastInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.wast !== "" ? { codeNo: inst.wast.instType, codeVal: inst.wast.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wastinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.wast.instCapa !== "" ? instInput.wast.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="wastunit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.wast.unit !== "" ? instInput.wast.unit : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wasttotalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wast.totalExpense !== "" ? instInput.wast.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wastgvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wast.gvmSpt !== "" ? instInput.wast.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wastmupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wast.mupal !== "" ? instInput.wast.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="wastprovince" type="text" class="tbText inwidth02" defaultValue={instInput.wast.province != '' ? instInput.wast.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wastpriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wast.priCompany !== "" ? instInput.wast.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="wastspendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.wast !== "" ? { codeNo: inst.wast.spendItem, codeVal: inst.wast.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="wastconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wast.consEnte !== "" ? instInput.wast.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr>
              <td>바이오</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="baioformCapa"
                    component={DropDownList}
                    // data={baioFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.baio !== "" ? { codeNo: inst.baio.formCapa, codeVal: inst.baio.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="baioinstType"
                    component={DropDownList}
                    // data={baioInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.baio !== "" ? { codeNo: inst.baio.instType, codeVal: inst.baio.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="baioinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.baio.instCapa !== "" ? instInput.baio.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="baiounit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.baio.unit !== "" ? instInput.baio.unit : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="baiototalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.baio.totalExpense !== "" ? instInput.baio.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="baiogvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.baio.gvmSpt !== "" ? instInput.baio.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="baiomupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.baio.mupal !== "" ? instInput.baio.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="baioprovince" type="text" class="tbText inwidth02" defaultValue={instInput.baio.province != '' ? instInput.baio.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="baiopriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.baio.priCompany !== "" ? instInput.baio.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="baiospendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.baio !== "" ? { codeNo: inst.baio.spendItem, codeVal: inst.baio.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="baioconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.baio.consEnte !== "" ? instInput.baio.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr className="k-master-row k-alt">
              <td>집광채광</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="coliformCapa"
                    component={DropDownList}
                    // data={coliFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.coli !== "" ? { codeNo: inst.coli.formCapa, codeVal: inst.coli.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="coliinstType"
                    component={DropDownList}
                    // data={coliInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.coli !== "" ? { codeNo: inst.coli.instType, codeVal: inst.coli.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="coliinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.coli.instCapa !== "" ? instInput.coli.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="coliunit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.coli.unit !== "" ? instInput.coli.unit : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="colitotalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.coli.totalExpense !== "" ? instInput.coli.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="coligvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.coli.gvmSpt !== "" ? instInput.coli.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="colimupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.coli.mupal !== "" ? instInput.coli.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="coliprovince" type="text" class="tbText inwidth02" defaultValue={instInput.coli.province != '' ? instInput.coli.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="colipriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.coli.priCompany !== "" ? instInput.coli.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="colispendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.coli !== "" ? { codeNo: inst.coli.spendItem, codeVal: inst.coli.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="coliconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.coli.consEnte !== "" ? instInput.coli.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr>
              <td>소수력</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="smpoformCapa"
                    component={DropDownList}
                    // data={smpoFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.smpo !== "" ? { codeNo: inst.smpo.spendItem, codeVal: inst.smpo.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="smpoinstType"
                    component={DropDownList}
                    // data={smpoInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.smpo !== "" ? { codeNo: inst.smpo.instType, codeVal: inst.smpo.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="smpoinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.smpo.instCapa !== "" ? instInput.smpo.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="smpounit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.smpo.unit !== "" ? instInput.smpo.unit : "kW"}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="smpototalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.smpo.totalExpense !== "" ? instInput.smpo.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="smpogvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.smpo.gvmSpt !== "" ? instInput.smpo.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="smpomupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.smpo.mupal !== "" ? instInput.smpo.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="smpoprovince" type="text" class="tbText inwidth02" defaultValue={instInput.smpo.province != '' ? instInput.smpo.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="smpopriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.smpo.priCompany !== "" ? instInput.smpo.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="smpospendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.smpo !== "" ? { codeNo: inst.smpo.spendItem, codeVal: inst.smpo.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="smpoconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.smpo.consEnte !== "" ? instInput.smpo.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr className="k-master-row k-alt">
              <td>수열에너지</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="hydrformCapa"
                    component={DropDownList}
                    // data={hydrFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.hydr !== "" ? { codeNo: inst.hydr.formCapa, codeVal: inst.hydr.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="hydrinstType"
                    component={DropDownList}
                    // data={hydrInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.hydr !== "" ? { codeNo: inst.hydr.instType, codeVal: inst.hydr.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="hydrinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.hydr.instCapa !== "" ? instInput.hydr.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="hydrunit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.hydr.unit !== "" ? instInput.hydr.unit : "kW"}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="hydrtotalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.hydr.totalExpense !== "" ? instInput.hydr.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="hydrgvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.hydr.gvmSpt !== "" ? instInput.hydr.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="hydrmupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.hydr.mupal !== "" ? instInput.hydr.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="hydrprovince" type="text" class="tbText inwidth02" defaultValue={instInput.hydr.province != '' ? instInput.hydr.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="hydrpriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.hydr.priCompany !== "" ? instInput.hydr.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="hydrspendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.hydr !== "" ? { codeNo: inst.hydr.spendItem, codeVal: inst.hydr.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="hydrconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.hydr.consEnte !== "" ? instInput.hydr.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr>
              <td>목재펠릿</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="wopeformCapa"
                    component={DropDownList}
                    // data={wopeFormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.wope !== "" ? { codeNo: inst.wope.formCapa, codeVal: inst.wope.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="wopeinstType"
                    component={DropDownList}
                    // data={wopeInstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.wope !== "" ? { codeNo: inst.wope.instType, codeVal: inst.wope.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wopeinstCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.wope.instCapa !== "" ? instInput.wope.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="wopeunit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.wope.unit !== "" ? instInput.wope.unit : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wopetotalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wope.totalExpense !== "" ? instInput.wope.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wopegvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wope.gvmSpt !== "" ? instInput.wope.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wopemupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wope.mupal !== "" ? instInput.wope.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="wopeprovince" type="text" class="tbText inwidth02" defaultValue={instInput.wope.province != '' ? instInput.wope.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="wopepriCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wope.priCompany !== "" ? instInput.wope.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="wopespendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.wope !== "" ? { codeNo: inst.wope.spendItem, codeVal: inst.wope.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="wopeconsEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.wope.consEnte !== "" ? instInput.wope.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr className="k-master-row k-alt">
              <td>전력저장장치(1)</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd1formCapa"
                    component={DropDownList}
                    // data={psd1FormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd1 !== "" ? { codeNo: inst.psd1.formCapa, codeVal: inst.psd1.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd1instType"
                    component={DropDownList}
                    // data={psd1InstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd1 !== "" ? { codeNo: inst.psd1.instType, codeVal: inst.psd1.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd1instCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.psd1.instCapa !== "" ? instInput.psd1.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="psd1unit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.psd1.unit !== "" ? instInput.psd1.unit : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd1totalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd1.totalExpense !== "" ? instInput.psd1.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd1gvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd1.gvmSpt !== "" ? instInput.psd1.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd1mupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd1.mupal !== "" ? instInput.psd1.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="psd1province" type="text" class="tbText inwidth02" defaultValue={instInput.psd1.province != '' ? instInput.psd1.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd1priCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd1.priCompany !== "" ? instInput.psd1.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd1spendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd1 !== "" ? { codeNo: inst.psd1.spendItem, codeVal: inst.psd1.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="psd1consEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd1.consEnte !== "" ? instInput.psd1.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr>
              <td>전력저장장치(2)</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd2formCapa"
                    component={DropDownList}
                    // data={psd2FormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd2 !== "" ? { codeNo: inst.psd2.formCapa, codeVal: inst.psd2.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd2instType"
                    component={DropDownList}
                    // data={psd2InstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd2 !== "" ? { codeNo: inst.psd2.instType, codeVal: inst.psd2.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd2instCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.psd2.instCapa !== "" ? instInput.psd2.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="psd2unit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.psd2.unit !== "" ? instInput.psd2.unit : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd2totalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd2.totalExpense !== "" ? instInput.psd2.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd2gvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd2.gvmSpt !== "" ? instInput.psd2.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd2mupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd2.mupal !== "" ? instInput.psd2.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="psd2province" type="text" class="tbText inwidth02" defaultValue={instInput.psd2.province != '' ? instInput.psd2.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd2priCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd2.priCompany !== "" ? instInput.psd2.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd2spendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd2 !== "" ? { codeNo: inst.psd2.spendItem, codeVal: inst.psd2.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="psd2consEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd2.instCapa !== "" ? instInput.psd2.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr className="k-master-row k-alt">
              <td>전력저장장치(3)</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd3formCapa"
                    component={DropDownList}
                    // data={psd3FormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd3 !== "" ? { codeNo: inst.psd3.formCapa, codeVal: inst.psd3.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW02">
                  <DropDownList
                    name="psd3instType"
                    component={DropDownList}
                    // data={psd3InstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd3 !== "" ? { codeNo: inst.psd3.instType, codeVal: inst.psd3.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd3instCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.psd3.instCapa !== "" ? instInput.psd3.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="psd3unit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.psd3.unit !== "" ? instInput.psd3.unit : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd3totalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd3.totalExpense !== "" ? instInput.psd3.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd3gvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd3.gvmSpt !== "" ? instInput.psd3.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd3mupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd3.mupal !== "" ? instInput.psd3.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="psd3province" type="text" class="tbText inwidth02" defaultValue={instInput.psd3.province != '' ? instInput.psd3.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd3priCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd3.priCompany !== "" ? instInput.psd3.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd3spendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd3 !== "" ? { codeNo: inst.psd3.spendItem, codeVal: inst.psd3.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="psd3consEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd3.consEnte !== "" ? instInput.psd3.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
            <tr>
              <td>전력저장장치(4)</td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd4formCapa"
                    component={DropDownList}
                    // data={psd4FormCapa}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd4 !== "" ? { codeNo: inst.psd4.formCapa, codeVal: inst.psd4.formCapaTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <span class="selectboxHw selectW02">
                  <DropDownList
                    name="psd4instType"
                    component={DropDownList}
                    // data={psd4InstType}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd4 !== "" ? { codeNo: inst.psd4.instType, codeVal: inst.psd4.instTypeTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    // onChange = {onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd4instCapa"
                  type="text"
                  class="tbText inwidth01"
                  defaultValue={instInput.psd4.instCapa !== "" ? instInput.psd4.instCapa : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <input
                  name="psd4unit"
                  type="text"
                  class="tbText inwidth01"
                  placeholder="kW"
                  defaultValue={instInput.psd4.unit !== "" ? instInput.psd4.unit : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd4totalExpense"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd4.totalExpense !== "" ? instInput.psd4.totalExpense : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd4gvmSpt"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd4.gvmSpt !== "" ? instInput.psd4.gvmSpt : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd4mupal"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd4.mupal !== "" ? instInput.psd4.mupal : ""}
                  onChange={onInstChange}
                />
              </td>
              {/* <td><NumberFormat thousandSeparator={true} name="psd4province" type="text" class="tbText inwidth02" defaultValue={instInput.psd4.province != '' ? instInput.psd4.province : "" } onChange={onInstChange} /></td> */}
              <td>
                <NumberFormat
                  thousandSeparator={true}
                  name="psd4priCompany"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd4.priCompany !== "" ? instInput.psd4.priCompany : ""}
                  onChange={onInstChange}
                />
              </td>
              <td>
                <span class="selectboxHw selectW01">
                  <DropDownList
                    name="psd4spendItem"
                    component={DropDownList}
                    data={spendItem}
                    defaultItem={{ codeVal: "선택", codeNo: "" }}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultValue={
                      inst.psd4 !== "" ? { codeNo: inst.psd4.spendItem, codeVal: inst.psd4.spendItemTerm } : { codeNo: "", codeVal: "선택" }
                    }
                    onChange={onDropDownChange}
                  />
                </span>
              </td>
              <td>
                <input
                  name="psd4consEnte"
                  type="text"
                  class="tbText inwidth02"
                  defaultValue={instInput.psd4.consEnte !== "" ? instInput.psd4.consEnte : ""}
                  onChange={onInstChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Button title="instSave" className="saveButton" onClick={saveInstChanges}>
          저장
        </Button>
      </div>
    </>
  );
};

export default BizInstAdd;
