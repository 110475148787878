import { getMessage } from '../../../com/messageUtil';

import { useEffect, useState } from 'react'

import { getAreaUserList, getMenuRoleAreaTree, getAreaAddlMenuTree, setUpdateMenuRoleArea } from '../../../service/menu/menuService'

import kendo from '@progress/kendo-ui';

import styles from './menuRoleArea.module.css'
import './menuRoleArea.css'
import MenuRoleAreaTreeView from './menuRoleAreaTreeView';

const MenuRoleArea = ({ conn }) => {

  // 트리뷰 체크 여부 확인 저장용
  let [dataSource, setDataSource] = useState({})

  const [commonCheckData, setCommonCheckData] = useState({
    menuData: [],
    menuList: []
  })

  const [areaUserList, setAreaUserList] = useState({ userId: "", userTerm: "지자체계정명" });

  const [selectedUser, setSelectedUser] = useState({
    userId: "", userTerm: "-"
  });

  const [areaUserListData, setAreaUserListData] = useState([]);

  // 로딩 스피너 설정용
  const [loading, setLoading] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  // 메뉴 트리 데이터
  let [treeData, setTreeData] = useState({})

  // 메뉴 트리 데이터 불러오기
  const getTreeData = (treeData) => {
    getAreaAddlMenuTree(conn, treeData)
      .then(treeData => {
        setTreeData({
          data: treeData
        })
        getCheckData(treeData)
      })
      .catch(res => {
        // console.error(res);
        alert(getMessage(res.errCode));
      });
  }

  function getCheckData(obj) {
    getMenuRoleAreaTree(conn, obj, areaUserList.userId)
      .then(dataSource => {
        setDataSource({
          data: dataSource,
          checkChildren: true,
          checkParents: true,
          check: { idField: 'id', ids: [], applyCheckIndeterminate: true }
        });
        setLoading(false);
      })
      .catch(res => {
        // console.log( "error codeList res = ", res );
        // console.error(res);
        alert(getMessage(res.errCode));
        setLoading(false);
      });
  }

  /* 저장 버튼 */
  const saveChanges = () => {
    let menuData = [commonCheckData];
    if (selectedUser.userId === "") {
      kendo.alert("지자체 계정을 먼저 선택해주세요");
    }
    else if (selectedUser.userId !== areaUserList.userId){
      kendo.alert("조회하고 있는 지자체 계정과 현재 드롭다운에서 선택된 지자체 계정이 다릅니다");
    }
    else {
      setUpdateMenuRoleArea(conn, menuData, isChecked, selectedUser.userId)
        .then((response) => {
          kendo.alert('저장되었습니다.');
          setIsChecked(false);
          setSelectedUser({ userId: areaUserList.userId, userTerm: areaUserList.userTerm + "(" + areaUserList.userId + ")" });
          getTreeData();
        })
        .catch(function (error) {  // API 호출이 실패한 경우
          // kendo.alert("저장을 실패 하였습니다.");
          // console.error(error);  // 에러표시

          alert(getMessage(error.errCode));
          setIsChecked(false);
        })
    }
  };

  const setCheckData = (userId, menuData, menuList) => {
    setCommonCheckData({
      ...commonCheckData,
      menuData: menuData,
      menuList: menuList,
      userId: userId
    })
  }

  useEffect(() => {
    setLoading(true);
    getAreaUserList(conn)
      .then((result) => {
        setAreaUserListData(result);
        setLoading(false);
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      });
  // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div className="uk-grid" style={{ justifyContent: "center" }}>
        <MenuRoleAreaTreeView
          conn={conn}
          setCheckData={setCheckData}
          areaUserList={areaUserList}
          setAreaUserList={setAreaUserList}
          styles={styles}
          saveChanges={saveChanges}
          loading={loading}
          setLoading={setLoading}
          treeData={treeData}
          setTreeData={setTreeData}
          getTreeData={getTreeData}
          dataSource={dataSource}
          setDataSource={setDataSource}
          setCommonCheckData={setCommonCheckData}
          areaUserListData={areaUserListData}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setIsChecked={setIsChecked}
        />
      </div>
    </div>
  )
}

export default MenuRoleArea