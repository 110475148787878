import { parseDate } from "../../../com/dateUtil";

const statsByConsmUrl = "/stat/byconsm";
let energyTypeUrl = "";

const toCamelCase = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const getStatsByConsm = async (conn, dataState) => {
  const { take, skip, sort, filter } = dataState;
  const config = {
    params: {
      page: (take + skip) / take,
      size: take,
      sort,
    },
  };

  if (filter.ensoType.codeNo === 15001) energyTypeUrl = "/sola/page";
  else if (filter.ensoType.codeNo === 15002) energyTypeUrl = "/heat/page";
  else if (filter.ensoType.codeNo === 15003) energyTypeUrl = "/heath/page";
  else if (filter.ensoType.codeNo === 15004) energyTypeUrl = "/velo/page";
  else if (filter.ensoType.codeNo === 15006) energyTypeUrl = "/fuelcell/page";
  else if (filter.ensoType.codeNo === 15007) energyTypeUrl = "/ess/page";

  if (filter !== undefined) {
    if (filter.startDtm !== "") config.params.startDtm = parseDate("yyyy-mm-dd", filter.startDtm);
    if (filter.endDtm !== "") config.params.endDtm = parseDate("yyyy-mm-dd", filter.startDtm);
    if (filter.userTerm !== undefined) config.params.userTerm = filter.userTerm;
    if (filter.cid !== undefined) config.params.cid = filter.cid;
    if (filter.cityProvCode !== undefined) config.params.cityProvCode = filter.cityProvCode;
    if (filter.rgnCode !== undefined) config.params.rgnCode = filter.rgnCode;
    if (filter.fixbylawBundCode !== undefined) config.params.fixbylawBundCode = filter.fixbylawBundCode;
    if (filter.alias !== undefined) config.params.alias = filter.alias;
    if (filter.meainModel !== undefined) {
      if (filter.meainModel.meainTypeSeriNo) {
        config.params.meainModel = filter.meainModel.meainTypeSeriNo;
      }
    }
    if (filter.makr !== undefined) {
      if (filter.makr.makrSeriNo) {
        config.params.makr = filter.makr.makrSeriNo;
      }
    }
  }

  const response = await conn.get(statsByConsmUrl + energyTypeUrl, config);

  return response;
};

export const getDbInfo = async (conn, dataState) => {
  const { filter } = dataState;
  // const defaultColumnName = ["cid", "consmId", "alias", "instCapa", "meainStatCodeNo"];
  let tbName = "";

  if (filter.ensoType.codeNo === 15001) tbName = "tb_sola_meain";
  else if (filter.ensoType.codeNo === 15002) tbName = "tb_heat_meain";
  else if (filter.ensoType.codeNo === 15003) tbName = "tb_heath_meain";
  else if (filter.ensoType.codeNo === 15004) tbName = "tb_velo_meain";
  else if (filter.ensoType.codeNo === 15006) tbName = "tb_fuelcell_meain";
  else if (filter.ensoType.codeNo === 15007) tbName = "tb_ess_meain";

  const response = await conn.get(statsByConsmUrl + "/db/" + tbName);

  response.forEach((element) => {
    element.tableName = toCamelCase(element.tableName);
    element.columnName = toCamelCase(element.columnName);
    element.comment = element.comment.replace("번호", "");
    element.comment = element.comment.replace("코드", "");
  });
  return response;
};
