import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import "./error.css";
import styles from "./error.module.css";

const Error = props => {
  document.body.style.background = "#ffffff";

  const moveHome = e => {
    window.location.href = "/";
  };

  return (
    <div className={[styles.pageArea, "uk-grid"].join(" ")}>
      {/* <div className = "uk-width-medium-1-1"> */}
      <div className={styles.titleArea}>
        <table className={styles.titleTable}>
          <tr>
            <td className={styles.titleText}>
              <img alt="시스템 장애" className={styles.errorImg} src="/images/page/error/errorPageImg1.png"></img>
              시스템 장애 발생
            </td>
          </tr>
        </table>
      </div>
      {/* </div> */}
      {/* <div className = "uk-width-medium-1-1"> */}
      <div className={styles.contentsArea}>
        <div className={styles.contentsTextArea}>
          <div className={styles.contentsText}>일시적으로 이용에 불편을 드려 죄송합니다.</div>
          {/* <div className = {styles.contentsText}>가능한 빠르게 원활한 서비스를 제공할 수 있도록 최선을 다하고 있습니다.</div> */}
          <div className={styles.contentsText}>잠시 후에 다시 방문하여 주시기 바랍니다.</div>
        </div>
        <div>
          <Button className="errorPageConfirmBtn" onClick={moveHome}>
            확인
          </Button>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Error;
