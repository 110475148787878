// React
import React, {useState, useEffect, useCallback} from 'react';
import log from '../../../com/log';
import { parseDate } from '../../../com/dateUtil';
import { getGroupManager, setUpdateGroupManager, setDeleteGroupManager } from '../../../service/mngt/groupManager/groupManagerService';
import GroupManagerModal from './groupManagerModal';
import { BeatLoader } from 'react-spinners'

// Kendo
import kendo from "@progress/kendo-ui";
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

import GridColumnInputFilter from '../../com/gridFilter/gridColumnInputFilter';

// Css
import './groupManager.css';
import styles from './groupManager.module.css';
import { useHistory } from 'react-router';
import { Create, Delete } from "@material-ui/icons";

const GroupManager = ({ conn, cityList, rgnList }) => { 

  // 로딩 스피너
  const [loading, setLoading] = useState(true);
  // 엑셀 로딩 스피너
  const [saveLoading, setSaveLoading] = useState(false);

  // 히스토리 사용
  const history = useHistory();

  // 드롭다운 필터 상태
  // const [filterDropValue , setFilterDropValue] = useState({
  //   rgn : { rgnCode  : "" , rgnTermSimple : "주소검색" }
  // });

  const [dataSource, setDataSource] = useState({
    total : 0,
    dataList : []
  });
  const [dataState, /*setDataState*/] = useState({
    take: 20
    , skip: 0    
    , sort: []
    , sortStatus : []
    , filter: { userId : "" , userTerm : "" , rgnCode : "" }
  });
  const [modalState, setModalState] = useState({
    visible: false
    , title: ''
    , formItem: []
    , isNew : false
  });
  // const [uploadState, setUploadState] = useState({
  //   files: []
  //   , events: []
  //   , filePreviews: {}
  //   , afterStateChange: []
  //   , response: []
  //   , visible: false
  // });


  // 단순 그리드 데이터 조회
  const getGridData = () => {
    getGroupManager(conn, dataState, rgnList)
    .then( response => {
      console.log("response = ", response);
      setDataSource(dataSource => {
        const item = {...dataSource};
        item.total = response.total;
        item.dataList = response.results;
        return item;
      });
    }).catch(error => {   
      if( error.errCode === "L007" ){
        alert("로그인 한 ID는 해당 메뉴에 접근할 수 없습니다.");
        history.goBack();
        return;
      }
    });
    setLoading(false);
  };

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const {take, skip} = e.page;
    dataState.skip = skip;
    dataState.take = take;
    getGridData();
  };

  // 그리드 소팅 처리
  const handleSortChange = e => {
    const {sort} = e;
    dataState.sort = sort;
    dataState.sortStatus = sort;
    if(sort.length !== 0) {
      if(sort[0].field === 'rgnTermSimple') {
        dataState.sort = 'rgnCode.' + sort[0].field + ',' + sort[0].dir;
      } else {
        dataState.sort = sort[0].field + ',' + sort[0].dir;
      }
    }
    getGridData();
  };


  // 그리드 필터링 처리
  const handleFilterChange = e => {
    dataState.take = 20;
    dataState.skip = 0;

    // 필터 값 설정
    const isFilter = e.filter.filters;
    if( isFilter.length !== 0 ){
      if(isFilter[0].field === "userId"  ){ dataState.filter.userId = isFilter[0].value; }
      else if(isFilter[0].field === "userTerm"  ){ dataState.filter.userTerm = isFilter[0].value; }
      else if(isFilter[0].field === "rgnTermSimple"  ){ dataState.filter.rgnCode = isFilter[0].value.rgnCode; }
    }
    getGridData();
  };


  // 추가 모달창 저장 처리
  const handleModalSaveSubmit = useCallback( e => {
    console.log("e = ", e);
    if( !e.cityInfo.cityProvCode ){
      kendo.alert("시/도 를 선택하지 않았습니다.");
      return;
    }
    if( !e.rgnInfo.rgnCode ){
      kendo.alert("시/군/구 를 선택하지 않았습니다.");
      return;
    }
    setSaveLoading(true);

    setModalState({...modalState, visible: !modalState.visible, title: '추가', formItem: []});
    
    // 저장할 데이터 설정
    if(e.smsNotiUseYn === undefined) e.smsNotiUseYn = 'Y';
    if(e.emailNotiUseYn === undefined) e.emailNotiUseYn = 'Y';
    if(e.lisenNotiUseYn === undefined) e.lisenNotiUseYn = 'Y';
    if(e.ausenNotiUseYn === undefined) e.ausenNotiUseYn = 'Y';
    e.userAddr = e.rgnInfo ? e.rgnInfo.rgnTerm : null;
    e.rgnCode = e.rgnInfo ? e.rgnInfo.rgnCode : null;
    e.cityProvCode = e.rgnInfo ? e.rgnInfo.cityProvCode : null;
    e.cityProvTerm = e.cityInfo ? e.cityInfo.cityProvTerm : null;
    e.rgnTermSimple = e.rgnInfo ? e.rgnInfo.rgnTermSimple : null;
    e.regiDtm = parseDate('LocalDateTime');
    e.useYn = e.useYn ? e.useYn : 'Y';
    e.createUpdateYn = e.isNew;
    // e.isNew = modalState.isNew;

    // 배열로 저장
    const saveDataList = [e];

    setUpdateGroupManager(conn, saveDataList, rgnList)
    .then( response => {
      setSaveLoading(false);
      setModalState({ ...modalState, visible: !modalState.visible, title: '', formItem: []});
      getGridData();
    })
    .catch( err => {
      setSaveLoading(false);
      log(`manager.handleModalSaveSubmit error: ${err}`);
      if( err.errCode === 'L009' ){
        kendo.alert("비밀번호를 입력 해주세요.")
        return;
      } else if( err.errCode === 'L006' ){
        kendo.alert("입력하신 ID가 중복 입니다.")
        return;
      } else if( err.errCode === 'L003' ){
        kendo.alert("비밀번호 형식이 다릅니다. 다시 입력 해주세요.")
        return;
      } else if( err.errCode === 'L001' ){
        kendo.alert("입력하신 ID를 찾을 수 없습니다.")
        return;
      } else if( err.errCode === 'L005' ){
        kendo.alert("이메일 형식이 다릅니다. 다시 입력 해주세요.")
        return;
      } else if( err.errCode === 'L004' ){
        kendo.alert("연락처 형식이 다릅니다. 다시 입력 해주세요.")
        return;
      }
    });
    // eslint-disable-next-line
  }, [modalState, dataSource, dataState]);

  // 시/군 필터
  // const handleRgnFilterList = (props) => {
  //   const filterOnchage = (e) => {
  //     props.onChange({
  //       value: e.value,
  //       operator: e.value ? "eq" : "",
  //       syntheticEvent: e.syntheticEvent
  //     });
  //     setFilterDropValue( filterDropValue => {
  //       const item = {...filterDropValue};
  //       item.rgn = e.value;
  //       return item;
  //     });
  //   }
  //
  //   return (
  //     <div className="k-filtercell">
  //       <DropDownList
  //           data={rgnList}
  //           onChange={filterOnchage}
  //           defaultItem= {{ rgnCode  : "" , rgnTermSimple : "주소검색" }}
  //           className = "CustomFilterDropDown"
  //           textField = "rgnTermSimple" dataItemKey= "rgnCode" name = "rgnTermSimple"
  //           value = {filterDropValue.rgn}
  //       />
  //     </div>
  //   );
  // }

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line
  }, []);

  return(
    <>
      <div className={saveLoading ? styles.managerG : ""}>
        <div className={["managerBody",loading ? styles.managerG : ""].join(' ')}>
          <div className="managerCate">
            <Button className={styles.writeBtn} onClick={ useCallback( e => {
              setModalState({...modalState, visible: !modalState.visible, title: '추가', createUpdateYn : true , formItem: [] , isNew : true });
            }, [modalState])} icon="add">
              추가
            </Button>
          </div>
          <div className="managerGrid">
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className={styles.managerGrid}
                data={dataSource.dataList}
                skip = {dataState.skip}
                take = {dataState.take}
                total = {dataSource.total}
                sort = {dataState.sortStatus}
                pageable={{buttonCount: 5, pageSizes: true}}
                filterable
                sortable
                onPageChange={handlePageChange}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
              >
                <GridColumn title='담당자' field='userTerm' filterCell = {GridColumnInputFilter} />
                <GridColumn title='ID' field='userId' filterCell = {GridColumnInputFilter} />
                <GridColumn title='시/도' field='cityProvTerm' filterable={false}
                // filterCell={handleRgnFilterList} 
                />
                <GridColumn title='시/군/구' field='rgnTermSimple' filterable={false}
                // filterCell={handleRgnFilterList} 
                />
                <GridColumn title='연락처' field='userCelphon' filterable={false} />
                <GridColumn title='이메일' field='userEmail' filterable={false} />
                <GridColumn title='등록일시' field='regiDtm' filterable={false} />
                <GridColumn title='이벤트 알림여부' sortable={false} >
                  <GridColumn title='SMS' field='smsNotiUseYn' filterable={false} width={60} />
                  <GridColumn title='EMAIL' field='emailNotiUseYn' filterable={false} width={80} />
                </GridColumn>
                <GridColumn 
                  title='수정'
                  filterable={false}
                  sortable={false}
                  width={60}
                  cell={
                    props => (
                      <td>
                        <button id="grpManagerEditBtn"
                          onClick={ e => {
                            e.preventDefault();
                            setModalState({
                              ...modalState
                              , visible: !modalState.visible
                              , title: '수정'
                              , createUpdateYn : false
                              , formItem: props.dataItem
                              , isNew : false
                            });
                          }}
                        >
                          <i>
                            <Create />
                          </i>
                        </button>
                      </td>
                    )}
                />
                <GridColumn
                  title='삭제'
                  filterable={false}
                  sortable={false}
                  width={60}
                  cell={
                    props => (
                      <td>
                        <button id="grpManagerDelBtn"
                          onClick={ e => {
                            if (window.confirm("정말 삭제하시겠습니까?")) { 
                              e.preventDefault();
                              const {userId} = props.dataItem;
                              setDeleteGroupManager(conn, userId)
                              .then( response => {
                                getGridData(conn, dataState);
                              })
                              .catch( err => {
                                log(`managerGrid.setDeleteManager error: ${err}`);
                              });
                            } else {
                              console.log("취소");
                            }
                          }}
                        >
                          <i>
                            <Delete />
                          </i>
                        </button>
                      </td>
                    )}
                />
                <GridNoRecords>
                  데이터가 존재하지 않습니다.
                </GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
          {
            modalState.visible &&
              <GroupManagerModal
                modalState={modalState} 
                setModalState={setModalState} 
                cityList={cityList}
                rgnList={rgnList} 
                handleModalSaveSubmit={handleModalSaveSubmit}
                handleModalUpdateSubmit={handleModalSaveSubmit}
              />
          }
          </div>
        </div>
        { loading && <p className={styles.managerLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p> }  
      </div>
      { saveLoading && <p className={styles.managerLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p> }
    </>
  );
}

export default GroupManager;