import React from 'react';

const BizMngtMeainSampleDownArea = ({
  openSample, convMngtStyles , Button
}) => {

  const downloadSampleSola = () =>{
    const link = document.createElement('a');
    link.href = '/template/bizStat/conv/태양광 설비 양식파일.xlsx';
    document.body.appendChild(link);
    link.click();
  }

  const downloadSampleHeat = () =>{
    const link = document.createElement('a');
    link.href = '/template/bizStat/conv/태양열(강제) 설비 양식파일.xlsx';
    document.body.appendChild(link);
    link.click();
  }

  const downloadSampleHeatN = () =>{
    const link = document.createElement('a');
    link.href = '/template/bizStat/conv/태양열(자연) 설비 양식파일.xlsx';
    document.body.appendChild(link);
    link.click();
  }

  const downloadSampleVelo = () =>{
    const link = document.createElement('a');
    link.href = '/template/bizStat/conv/소형풍력 설비 양식파일.xlsx';
    document.body.appendChild(link);
    link.click();
  }

  const downloadSampleHeath = () =>{
    const link = document.createElement('a');
    link.href = '/template/bizStat/conv/지열 설비 양식파일.xlsx';
    document.body.appendChild(link);
    link.click();
  }

  const downloadSampleFuel = () =>{
    const link = document.createElement('a');
    link.href = '/template/bizStat/conv/연료전지 설비 양식파일.xlsx';
    document.body.appendChild(link);
    link.click();
  }


  return (
    <div className={openSample ? convMngtStyles.showMenuSample : convMngtStyles.hideMenuSample}>
      <ul className={convMngtStyles.ulSampleDownloadCss}>
        <li className={convMngtStyles.liSampleDownloadCss} >
          <Button icon = "download" onClick = {downloadSampleSola} className = {convMngtStyles.sampleBtnCss} > 태양광 설비 샘플 다운로드 </Button>
        </li>
        <li className={convMngtStyles.liSampleDownloadCss} > 
          <Button icon = "download" onClick = {downloadSampleHeat} className = {convMngtStyles.sampleBtnCss} > 태양열(강제) 설비 샘플 다운로드 </Button>
        </li>
        <li className={convMngtStyles.liSampleDownloadCss} > 
          <Button icon = "download" onClick = {downloadSampleHeatN} className = {convMngtStyles.sampleBtnCss} > 태양열(자연) 설비 샘플 다운로드 </Button>
        </li>
        <li className={convMngtStyles.liSampleDownloadCss} > 
          <Button icon = "download" onClick = {downloadSampleVelo} className = {convMngtStyles.sampleBtnCss} > 소형풍력 설비 샘플 다운로드 </Button>
        </li>
        <li className={convMngtStyles.liSampleDownloadCss}> 
          <Button icon = "download" onClick = {downloadSampleHeath} className = {convMngtStyles.sampleBtnCss} > 지열 설비 샘플 다운로드 </Button>
        </li>
        <li className={convMngtStyles.liSampleDownloadCss} > 
          <Button icon = "download" onClick = {downloadSampleFuel} className = {convMngtStyles.sampleBtnCss} > 연료전지 설비 샘플 다운로드 </Button>
        </li>
      </ul>
    </div>
  );
};

export default BizMngtMeainSampleDownArea;