import React, { memo } from "react";
import NonOperHistSearch from "./nonOperHistSearch";

const NonOperHist = memo(({ conn, codeList, cityList, rgnList }) => {
  return (
    <>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
              <NonOperHistSearch
            conn={conn}
            codeList={codeList}
            cityList={cityList}
            rgnList={rgnList}
              />
        </div>
      </div>
    </>
  )
});
export default NonOperHist;