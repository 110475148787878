import React from "react";
import BizManagementMeainCom from "./bizManagementMeainCom";
import BizManagementMeainSola from "./bizManagementMeainSola";
import BizMngtNoContents from "../bizMngtNoContents";
import BizManagementMeainHeat from "./bizManagementMeainHeat";
import BizManagementMeainHeath from "./bizManagementMeainHeath";
import BizManagementMeainVelo from "./bizManagementMeainVelo";
import BizManagementMeainFuel from "./bizManagementMeainFuel";

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 설비 정보 ( 컴포넌트 )
 */
const BizManagementMeain = ({
  convMngtStyles,
  Input,
  DropDownList,
  DatePickerKo,
  DateTimePickerKo,
  kendo,
  meainInfo,
  initialDs,
  search,
  setMeainInfo,
  searchKeyWord,
  makrMeainModlfilterChange,
}) => {
  // 입력한 값에 대한 이벤트
  // Date 데이터 값 변경 이벤트
  const onchageDate = (v, name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const meain = {
      ...meainInfo,
      [name]: kendo.toString(v, "yyyy-MM-dd"),
    };
    setMeainInfo(meain);
  };

  // Date 데이터 값 변경 DateTimeOnChangeDate
  const onChangeDateTiem = (v, name) => {
    const meain = {
      ...meainInfo,
      [name]: kendo.toString(v, "yyyy-MM-dd HH:mm:00"),
    };
    setMeainInfo(meain);
  };

  // 날짜 포맷 지정
  const dateFormat = date => {
    if (typeof date !== "undefined") {
      if (date !== "") {
        date = new Date(date);
      } else {
        date = "";
      }
    } else {
      date = "";
    }
    return date;
  };

  // Input 데이터 값 변경 이벤트
  const onchageInput = e => {
    const objName = e.target.name;
    const meain = {
      ...meainInfo,
      [objName]: e.target.value,
    };
    setMeainInfo(meain);
  };

  // 드롭다운 값 변경 이벤트 (Single 데이터 버전)
  const onchageDropdown = e => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const objName = e.target.name;
    const meain = {
      ...meainInfo,
      [objName]: e.value,
    };
    setMeainInfo(meain);
  };

  return (
    <div>
      {search.instlPlnnm &&
      (search.mngt.energyTypeCode === 39001 ||
        search.mngt.energyTypeCode === 39002 ||
        search.mngt.energyTypeCode === 39003 ||
        search.mngt.energyTypeCode === 39004 ||
        search.mngt.energyTypeCode === 39005) ? (
        <div class="JinTabB_con" id="JinTabBB_con">
          <dl>
            <table className={convMngtStyles.meainTable}>
              <colgroup>
                <col width="16%" />
                <col width="34%" />
                <col width="16%" />
                <col width="34%" />
              </colgroup>
              <tbody>
                <BizManagementMeainCom
                  Input={Input}
                  convMngtStyles={convMngtStyles}
                  DropDownList={DropDownList}
                  meainInfo={meainInfo}
                  initialDs={initialDs}
                  search={search}
                  setMeainInfo={setMeainInfo}
                />
                {search.mngt.energyTypeCode === 39001 && (
                  <BizManagementMeainSola
                    Input={Input}
                    convMngtStyles={convMngtStyles}
                    DropDownList={DropDownList}
                    meainInfo={meainInfo}
                    initialDs={initialDs}
                    setMeainInfo={setMeainInfo}
                    DatePickerKo={DatePickerKo}
                    DateTimePickerKo={DateTimePickerKo}
                    onchageDate={onchageDate}
                    onChangeDateTiem={onChangeDateTiem}
                    dateFormat={dateFormat}
                    onchageInput={onchageInput}
                    searchKeyWord={searchKeyWord}
                    makrMeainModlfilterChange={makrMeainModlfilterChange}
                  />
                )}
                {search.mngt.energyTypeCode === 39002 && (
                  <BizManagementMeainHeat
                    Input={Input}
                    convMngtStyles={convMngtStyles}
                    DropDownList={DropDownList}
                    meainInfo={meainInfo}
                    initialDs={initialDs}
                    search={search}
                    setMeainInfo={setMeainInfo}
                    DatePickerKo={DatePickerKo}
                    DateTimePickerKo={DateTimePickerKo}
                    onchageDate={onchageDate}
                    onChangeDateTiem={onChangeDateTiem}
                    dateFormat={dateFormat}
                    onchageInput={onchageInput}
                    onchageDropdown={onchageDropdown}
                    searchKeyWord={searchKeyWord}
                    makrMeainModlfilterChange={makrMeainModlfilterChange}
                  />
                )}
                {search.mngt.energyTypeCode === 39003 && (
                  <BizManagementMeainVelo
                    Input={Input}
                    convMngtStyles={convMngtStyles}
                    DropDownList={DropDownList}
                    meainInfo={meainInfo}
                    initialDs={initialDs}
                    setMeainInfo={setMeainInfo}
                    DatePickerKo={DatePickerKo}
                    DateTimePickerKo={DateTimePickerKo}
                    onchageDate={onchageDate}
                    onChangeDateTiem={onChangeDateTiem}
                    dateFormat={dateFormat}
                    onchageInput={onchageInput}
                    onchageDropdown={onchageDropdown}
                    searchKeyWord={searchKeyWord}
                    makrMeainModlfilterChange={makrMeainModlfilterChange}
                  />
                )}
                {search.mngt.energyTypeCode === 39004 && (
                  <BizManagementMeainHeath
                    Input={Input}
                    convMngtStyles={convMngtStyles}
                    DropDownList={DropDownList}
                    meainInfo={meainInfo}
                    initialDs={initialDs}
                    setMeainInfo={setMeainInfo}
                    DatePickerKo={DatePickerKo}
                    DateTimePickerKo={DateTimePickerKo}
                    onchageDate={onchageDate}
                    onChangeDateTiem={onChangeDateTiem}
                    dateFormat={dateFormat}
                    onchageInput={onchageInput}
                    onchageDropdown={onchageDropdown}
                    searchKeyWord={searchKeyWord}
                    makrMeainModlfilterChange={makrMeainModlfilterChange}
                  />
                )}
                {search.mngt.energyTypeCode === 39005 && (
                  <BizManagementMeainFuel
                    Input={Input}
                    convMngtStyles={convMngtStyles}
                    DropDownList={DropDownList}
                    meainInfo={meainInfo}
                    initialDs={initialDs}
                    setMeainInfo={setMeainInfo}
                    DatePickerKo={DatePickerKo}
                    DateTimePickerKo={DateTimePickerKo}
                    onchageDate={onchageDate}
                    onChangeDateTiem={onChangeDateTiem}
                    dateFormat={dateFormat}
                    onchageInput={onchageInput}
                    onchageDropdown={onchageDropdown}
                    searchKeyWord={searchKeyWord}
                    makrMeainModlfilterChange={makrMeainModlfilterChange}
                  />
                )}
              </tbody>
            </table>
          </dl>
        </div>
      ) : (
        <div>
          <BizMngtNoContents convMngtStyles={convMngtStyles} />
        </div>
      )}
    </div>
  );
};

export default BizManagementMeain;
