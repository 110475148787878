import React from 'react';

const BizMngtNoContents = ({convMngtStyles}) => {
  return (
    <div className={convMngtStyles.convMngtNoDataArea}>
      <img src='/images/conv/convMngtNoData.png' alt='clickNavigator' />
    </div>
  );
};

export default BizMngtNoContents;