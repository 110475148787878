import React, { useEffect, useState } from 'react';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 태양열(강제순환식) 정보
*/
const BizManagementMeainHeatComp = ({
  Input, convMngtStyles, DropDownList, meainInfo , initialDs, search, DatePickerKo, DateTimePickerKo, setMeainInfo
  ,onchageDate, onChangeDateTiem, dateFormat, onchageInput, onchageDropdown
  , makrList, meainModelList
  , searchKeyWord ,makrMeainModlfilterChange
}) => {

  // 드롭다운 변수 설정
  const [coldCtrlerMakr , setColdCtrlerMakr] = useState( meainInfo.coldCtrlerMakrNo ? { makrSeriNo : meainInfo.coldCtrlerMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  const [coldCtrlerModl , setColdCtrlerModl] = useState( meainInfo.coldCtrlerModlNo ? { meainTypeSeriNo : meainInfo.coldCtrlerModlNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [loadClrmtMakr , setLoadClrmtMakr] = useState( meainInfo.bldSrvCodeNo ? { makrSeriNo : meainInfo.loadClrmtMakrNo , makrTerm : "-" } : { makrSeriNo : '' , makrTerm : '' } );
  const [loadClrmtModl , setLoadClrmtModl] = useState( meainInfo.modulTypeNo ? { meainTypeSeriNo : meainInfo.invtMakr , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  
  // 컨트롤러 차온제어 제조사
  const coldCtrlerMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30004 && c.makrTerm.includes(searchKeyWord.coldCtrlerMakrKey)) : [];
  // 컨트롤러 차온제어 모델 
  const coldCtrlerModlList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15002 && c.makrSeriNo === meainInfo.coldCtrlerMakrNo 
    && c.meainTypeTerm.includes(searchKeyWord.coldCtrlerModlKey)) : []; 
  // 부하측 열량계 제조사
  const loadClrmtMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30013 && c.makrTerm.includes(searchKeyWord.loadClrmtMakrKey)) : [];
  // 부하측 열량계 모델
  const loadClrmtModlNoList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15002 && c.makrSeriNo === meainInfo.loadClrmtMakrNo 
    && c.meainTypeTerm.includes(searchKeyWord.loadClrmtModlKey)) : []; 


  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      let makrSeriNo = "";
      let makrTerm = "";
      
      if( meainInfo.coldCtrlerMakrNo ){
        coldCtrlerMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.coldCtrlerMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setColdCtrlerMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setColdCtrlerMakr({ makrSeriNo: '', makrTerm: '-' });
      }
      if( meainInfo.loadClrmtMakrNo ){
        loadClrmtMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.loadClrmtMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setLoadClrmtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setLoadClrmtMakr({ makrSeriNo: '', makrTerm: '-' });
      }
    }else{
      setColdCtrlerMakr({ makrSeriNo: '', makrTerm: '-' });
      setLoadClrmtMakr({ makrSeriNo: '', makrTerm: '-' });
    }
  }
  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.coldCtrlerModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        coldCtrlerModlList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.coldCtrlerModlNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setColdCtrlerModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setColdCtrlerModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( meainInfo.loadClrmtModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        loadClrmtModlNoList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.loadClrmtModlNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setLoadClrmtModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setLoadClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
    }else{
      setColdCtrlerModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setLoadClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
    }
  }

  useEffect(() => {
    makrConverter();
    modlConverter();
    /* eslint-disable-next-line */
  }, [meainInfo]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다. 

  return (
    <>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;집열기 종류</th>
        <td>
            <DropDownList 
            id = "socoKind" name = "socoKind"  type = "text" className={convMngtStyles.inputTerm} 
            data = { [ "-", "단일진공관" , "이중진공관" , "평판형"] }
            value = { meainInfo.socoKind ? meainInfo.socoKind : "-" } onChange = {onchageDropdown} 
            // required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
            />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;집열기 면적(㎡)</th>
        <td>
            <Input 
            id = "socoArea" name = "socoArea"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.socoArea ? meainInfo.socoArea : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix} 
            step="0.01" min = {0}
            ></Input>
        </td>
      </tr>
      
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;집열기 직렬개수</th>
        <td>
            <Input 
            id = "socoSeriCnt" name = "socoSeriCnt"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.socoSeriCnt ? meainInfo.socoSeriCnt : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.normalText} 
            min = {0}
            ></Input>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;집열기 병렬개수</th>
        <td>
            <Input 
            id = "socoArowCnt" name = "socoArowCnt"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.socoArowCnt ? meainInfo.socoArowCnt : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.normalText} 
            min = {0}
            ></Input>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font10 }>컨트롤러(차온제어) 제조사</span></th>
        <td>
          <DropDownList 
          id = "coldCtrlerMakrNo" name = "coldCtrlerMakrNo"  type = "text" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
          value = { coldCtrlerMakr } data = {coldCtrlerMakrList} 
          defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
          filterable={true}
          onFilterChange={makrMeainModlfilterChange}
          dataItemKey="makrSeriNo" textField = "makrTerm"
          onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
          setColdCtrlerMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
          // required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
          ></DropDownList>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font11 }>컨트롤러(차온제어) 모델</span></th>
        <td>
          <DropDownList 
          id = "coldCtrlerModlNo" name = "coldCtrlerModlNo"  type = "text" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
          value = { coldCtrlerModl } data = {coldCtrlerModlList} 
          filterable={true}
          onFilterChange={makrMeainModlfilterChange}
          disabled = { meainInfo.coldCtrlerMakrNo ? false : true }
          dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
          onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo , "meainTypeSeriNo" : e.value.meainTypeSeriNo }; // meainTypeSeriNo 임 
          setColdCtrlerModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
          // required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
          ></DropDownList>
        </td> 
      </tr>
      <tr>
        <th><span className={ convMngtStyles.font13 }>부하측 열량계 제조사</span></th>
        <td>
            <DropDownList 
            id = "loadClrmtMakrNo" name = "loadClrmtMakrNo"  type = "text" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            value = { loadClrmtMakr } data = {loadClrmtMakrList} 
            defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
            dataItemKey="makrSeriNo" textField = "makrTerm" 
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setLoadClrmtMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
            ></DropDownList>
        </td>
        <th>부하측 열량계 모델</th>
        <td>
            <DropDownList 
            id = "loadClrmtModlNo" name = "loadClrmtModlNo"  type = "text" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            value = { loadClrmtModl } data = {loadClrmtModlNoList}
            disabled = { meainInfo.loadClrmtMakrNo ? false : true }
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo }; 
            setLoadClrmtModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
            ></DropDownList>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;방위각</th>
        <td>
            <Input 
            id = "azimuth" name = "azimuth"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.azimuth ? meainInfo.azimuth : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix} 
            step="0.01" min = {0}
            ></Input>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;경사각</th>
        <td>
            <Input 
            id = "incliAngle" name = "incliAngle"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.incliAngle ? meainInfo.incliAngle : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix} 
            step="0.01" min = {0}
            ></Input>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;열매체 펌프 유형</th>
        <td>
            <Input 
            id = "hmediPumpType" name = "hmediPumpType"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.hmediPumpType ? meainInfo.hmediPumpType : "" } onChange = {onchageInput} 
            // required = {true} validationMessage = {inputValidMsg.normalText}
            ></Input>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font11 }>열매체 펌프 유량(LPM)</span></th>
        <td>
            <Input 
            id = "hmediPumpOilQty" name = "hmediPumpOilQty"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.hmediPumpOilQty ? meainInfo.hmediPumpOilQty : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix} 
            step="0.01"
            ></Input>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font13 }>열매체 펌프 양정(m)</span></th>
        <td>
            <Input 
            id = "hmediPumpHead" name = "hmediPumpHead"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.hmediPumpHead ? meainInfo.hmediPumpHead : "" } onChange = {onchageInput} 
            // required = {true} validationMessage = {inputValidMsg.number2Fix} 
            step="0.01"
            ></Input>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font13 }>열매체 보충펌프 유형</span></th>
        <td>
            <Input 
            id = "hmediSubPumpType" name = "hmediSubPumpType"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.hmediSubPumpType ? meainInfo.hmediSubPumpType : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            ></Input>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font10 }>열매체 보충펌프 유량(LPM)</span></th>
        <td>
            <Input 
            id = "hmediSubPumpOilQty" name = "hmediSubPumpOilQty"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.hmediSubPumpOilQty ? meainInfo.hmediSubPumpOilQty : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix} 
            step="0.01" min = {0}
            ></Input>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font11 }>열매체 보충펌프 양정(m)</span></th>
        <td>
            <Input 
            id = "hmediSubPumpHead" name = "hmediSubPumpHead"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.hmediSubPumpHead ? meainInfo.hmediSubPumpHead : "" } onChange = {onchageInput} 
            // required = {true} validationMessage = {inputValidMsg.number2Fix} 
            step="0.01" min = {0}
            ></Input>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;온수 순환펌프 유형</th>
        <td>
            <Input 
            id = "hotwaterCirclPumpType" name = "hotwaterCirclPumpType"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.hotwaterCirclPumpType ? meainInfo.hotwaterCirclPumpType : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            ></Input>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font11 }>온수 순환펌프 유량(LPM)</span></th>
        <td>
            <Input 
            id = "hotwaterCirclPumpOilQty" name = "hotwaterCirclPumpOilQty"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.hotwaterCirclPumpOilQty ? meainInfo.hotwaterCirclPumpOilQty : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
            ></Input>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;<span className={ convMngtStyles.font12 }>온수 순환펌프 양정(m)</span></th>
        <td>
            <Input 
            id = "hotwaterCirclPumpHead" name = "hotwaterCirclPumpHead"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.hotwaterCirclPumpHead ? meainInfo.hotwaterCirclPumpHead : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
            ></Input>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;유속(m/s)</th>
        <td>
            <Input 
            id = "woilSpeed" name = "woilSpeed"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.woilSpeed ? meainInfo.woilSpeed : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
              ></Input>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;운전압력(bar)</th>
        <td>
            <Input 
            id = "drvePres" name = "drvePres"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.drvePres ? meainInfo.drvePres : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number0Fix} 
            min = {0}
            ></Input>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;배관관경(A)</th>
        <td>
            <Input 
            id = "plaDiamtr" name = "plaDiamtr"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.plaDiamtr ? meainInfo.plaDiamtr : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number0Fix} 
            min = {0}
            ></Input>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;축열탱크용량(L)</th>
        <td>
            <Input 
            id = "rthrmTankCapa" name = "rthrmTankCapa"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.rthrmTankCapa ? meainInfo.rthrmTankCapa : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
            ></Input>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;축열탱크재질</th>
        <td>
            <Input 
            id = "rthrmTankQual" name = "rthrmTankQual"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.rthrmTankQual ? meainInfo.rthrmTankQual : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            ></Input>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;시공업체</th>
        <td>
            <Input 
            id = "consEnte" name = "consEnte"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.consEnte ? meainInfo.consEnte : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            ></Input>
        </td>
        <th>시공업체 연락처</th>
        <td>
            <Input 
              id = "consEnteCntct" name = "consEnteCntct"  type = "text" className={convMngtStyles.inputTerm}
              value = { meainInfo.consEnteCntct ? meainInfo.consEnteCntct : "" } onChange = {onchageInput}
              placeholder = "하이픈(-) 없이 입력해주세요."
              ></Input>
        </td>
      </tr>
      <tr>
        { meainInfo.moniYn === "Y" ?
          <>
            <th>
              <span className={ convMngtStyles.required }>*</span>&nbsp;설치확인관리번호
            </th>
            <td>
              <Input id = "instCnfrmCode" name = "instCnfrmCode" type="text" 
                    className={convMngtStyles.inputTerm} 
                    value = {meainInfo.instCnfrmCode ? meainInfo.instCnfrmCode : ""}
                    onChange = {onchageInput} />
            </td>
            <th></th>
            <td></td>
          </> :
          <>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
          </> 
        }
      </tr>
    </>
  );
};

export default BizManagementMeainHeatComp;