import styles from "./areaEnergy.module.css";
import "./areaEnergy.css";
// import styles from "./areaEnergy.dk.module.css";
// import "./areaEnergy.dk.css";
import { getAreaEnergyList, getMeainStatCntList } from "../../../service/monitor/areaEnergyService";
import { useHistory } from "react-router-dom";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useState, useEffect } from "react";
import kendo from "@progress/kendo-ui";
import CountUp from "react-countup";
import { getUserTypeCode } from "../../../service/authService";
// 로딩 스피너
import { BeatLoader } from "react-spinners";
import { roundNum } from "../../../com/dataUtil";
import { CODE_SOLA, CODE_HEAT, CODE_HEATH, CODE_VELO, CODE_FUELCELL, CODE_ESS } from "../../../com/codeUtil";
import { useRef } from "react";
import { getUser } from "../../../service/authService";

const AreaEnergy = ({ conn, codeList, cityList , rgnList, setError, isMobile }) => {
  const [selected, setSelected] = useState(0);
  
  let ensoTypeCodeNo = useRef(CODE_SOLA);

  const handleSelect = e => {
    if(e.selected === 1) {
      ensoTypeCodeNo.current = CODE_HEAT;
    } else if (e.selected === 2) {
      ensoTypeCodeNo.current = CODE_HEATH;
    } else if (e.selected === 3) {
      ensoTypeCodeNo.current = CODE_VELO;
    } else if (e.selected === 4) {
      ensoTypeCodeNo.current = CODE_FUELCELL;
    } else if (e.selected === 5) {
      ensoTypeCodeNo.current = CODE_ESS;
    } else if (e.selected === 0) {
      ensoTypeCodeNo = CODE_SOLA;
    }
    setSelected(e.selected);
  };

  // 로딩 스피너
  const [areaGridLoading, setAreaGridLoading] = useState(false);

  // 상태 확인
  const history = useHistory();
  const loginUserTypeCode = getUserTypeCode();

  // console.log("loginUserTypeCode = " , loginUserTypeCode);

  const areaTerm = "전국";
  const AREA_TYPE_CITY = "CITY";
  const AREA_TYPE_RGN = "RGN";
  const AREA_TYPE_DONG = "DONG";

  let userInfo = getUser();
  // console.log("userInfo = ", userInfo);

  const [state, setState] = useState({
    areaType: AREA_TYPE_CITY, //CITY:시도, RGN:시군구, DONG:읍면동
    cityProvCode: "",
    cityProvTerm: "",
    rgnCode: "",
    rgnTerm: "",
    dongCode: "",
    dongTerm: "읍면동",
    ensoTypeCode: "",
    areaEnergyList: [],
    totalEnergy: {
      dayPower: 0,
      dayPowerCo2: 0,
      powerCapa: 0,
      accumPower: 0,
      dayUse: 0,
      dayUseCo2: 0,
      heatCapa: 0,
      heathCapa: 0,
      accumUse: 0,
    },
    meatinStatCnt: [],
  });

  useEffect(() => {
    // 데이터 설정 처음 보여주는 그리드에 대한 지역 단계 설정
    setUserData();
    // 타임 인터벌 이벤트
    setInterval();
  }, []);

  // 유저 정보와 처음 보여주는 데이터 설정
  const setUserData = () => {
    if( loginUserTypeCode === 2002 ){
      const rgns = rgnList.filter(e => e.rgnCode === userInfo.rgnCode)[0];
      console.log("rgns = ", rgns);
      const citys = cityList.filter(e => e.cityProvCode === rgns.cityProvCode)[0];
      console.log("citys = ", citys);
      // userInfo
      setAreaEnergy({
        areaType: AREA_TYPE_DONG,
        cityProvCode: citys.cityProvCode,
        cityProvTerm: citys.cityProvTerm,
        rgnCode: rgns.rgnCode,
        rgnTerm: rgns.rgnTerm,
        dongCode : "" , 
        dongTerm : "읍면동"
      });
    }else{
      setAreaEnergy({
        areaType: AREA_TYPE_CITY,
        cityProvCode: "",
        cityProvTerm: "",
        rgnCode: "",
        rgnTerm: "",
      });
    }
  }

  const setAreaEnergy = selectedArea => {
    // 스피너
    setAreaGridLoading(true);
    Promise.all([getAreaEnergyList(conn, selectedArea), getMeainStatCntList(conn, selectedArea)]) //
      .then(results => {
        // 스피너
        setAreaGridLoading(false);
        // 데이터 세팅
        setState(state => {
          const item = { ...state };
          item.areaType = selectedArea.areaType;
          item.cityProvCode = selectedArea.cityProvCode;
          item.cityProvTerm = selectedArea.cityProvTerm;
          item.rgnCode = selectedArea.rgnCode;
          item.rgnTerm = selectedArea.rgnTerm;
          item.areaEnergyList = results[0].areaEnergyList;
          item.totalEnergy = results[0].areaEnergyTotal;
          item.meatinStatCnt = results[1];
          return item;
        });
      });
  };

  const setAreaChangeEvt = e => {
    e.preventDefault();
    setAreaEnergy({
      areaType: AREA_TYPE_CITY,
      cityProvCode: "",
      cityProvTerm: "",
      rgnCode: "",
      rgnTerm: "",
    });
  };

  const setCityChangeEvt = e => {
    e.preventDefault();
    setAreaEnergy({
      areaType: AREA_TYPE_RGN,
      cityProvCode: state.cityProvCode,
      cityProvTerm: state.cityProvTerm,
      rgnCode: "",
      rgnTerm: "",
    });
  };

  const setRgnClickEvt = e => {
    let rgn = e.dataItem;
    setAreaEnergy({
      areaType: AREA_TYPE_RGN,
      cityProvCode: rgn.cityProvCode,
      cityProvTerm: rgn.cityProvTerm,
      rgnCode: "",
      rgnTerm: "",
    });
  };

  const setDongClickEvt = e => {
    let dong = e.dataItem;
    setAreaEnergy({
      areaType: AREA_TYPE_DONG,
      cityProvCode: dong.cityProvCode,
      cityProvTerm: dong.cityProvTerm,
      rgnCode: dong.rgnCode,
      rgnTerm: dong.rgnTerm,
      dongCode: dong.dongCode,
      dongTerm: dong.dongTerm,
    });

    if (loginUserTypeCode === 2003 || loginUserTypeCode === 2005 || loginUserTypeCode === 2002) {
      if (dong.dongCode != null) {
        if(isMobile === true) {
          history.push(
            // "/monitor/installerMeain?&ensoTypeCode="+ ensoTypeCodeNo+ 
            // "&cityProvCode=" +
            "/monitor/installerMeain?&cityProvCode=" +
              dong.cityProvCode +
              "&rgnCode=" +
              dong.rgnCode +
              "&dongCode=" +
              dong.dongCode +
              "&dongTerm=" +
              dong.dongTerm +
              "&ensoTypeCode=" +
              ensoTypeCodeNo.current
              ,
          );
        } else {
          history.push(
            "/monitor/installerMeain?cityProvCode=" +
              dong.cityProvCode +
              "&rgnCode=" +
              dong.rgnCode +
              "&dongCode=" +
              dong.dongCode +
              "&dongTerm=" +
              dong.dongTerm,
          );
        }
      }
    } else {
      if (state.areaType === AREA_TYPE_DONG) return;
    }
  };

  //FIXME common 으로 이동할 것
  const setColumnSum = props => {
    const sum = state.areaEnergyList.reduce((acc, current) => acc + current[props.field], 0);
    let style = { textAlign: "right", paddingRight: "7px" };

    const field = props.field;
    if (
      field === "solaCnt" ||
      field === "heatCnt" ||
      field === "heathCnt" ||
      field === "veloCnt" ||
      field === "fuelcellCnt" ||
      field === "essCnt"
    ) {
      style.color = "#82b440";
    } else if (field === "solaDayPower" || field === "veloDayPower" || field === "fuelcellDayPower") {
      style.color = "#ee7951";
    } else if (field === "heatDayUse" || field === "heathDayUse") {
      style.color = "#3c9ac9";
    }

    return (
      <td colSpan={props.colSpan} style={style}>
        <CountUp end={sum} separator="," duration={1.5} />
      </td>
    );
  };

  //FIXME common 으로 이동할 것
  // 숫자 표현 cell template
  const setColumnNumberFormat = data => {
    const field = data.field;
    let value = "-";
    let style = { textAlign: "right", paddingRight: "10px" };

    // 동적으로 객체 존재를 확인 하고 있다면 포맷 적용 (undefined 와 null 체크 )
    if (data.dataItem.hasOwnProperty(field) && data.dataItem[field] != null) {
      if (field === "errCode") {
        value = data.dataItem[field];
      } else {
        value = kendo.format("{0:n0}", data.dataItem[field]);
      }
    }
    if (value === 0) {
      value = "-";
    }

    // 자리수 자동 fontsize 적용 알고리즘_20220208_jhchoi
    if(value){
      if( value.toString().length > 9 ){
        let fontOffset = 3;
        let fontSize = 14;
        let length = value.toString().length;
        fontSize = length + ( (( length % 10 ) * -2 ) + fontOffset );
        style = { ...style , fontSize : fontSize };
      }
    }
    
    // 형식 적용
    return <td style={style}>{value}</td>;
  };

  // 지역 표현 cell template
  const setColumnRgnFormat = data => {
    const field = data.field;
    let style = { textAlign: "center"};
    let value = data.dataItem[field];

    // 자리수 자동 fontsize 적용 알고리즘_20220208_jhchoi
    if(value){
      if( value.toString().length > 7 ){
        let fontSize = 13;
        style = { ...style , fontSize : fontSize };
      }
    }
    
    // 형식 적용
    return <td style={style}>{value}</td>;
  };

  const getEnsoTerm = ensoTypeCode => {
    let code = codeList.find(code => code.codeNo === ensoTypeCode);
    return code ? code.codeVal : "전체";
  };

  const setInterval = () => {
    setTimeout(() => {
      setUserData();
    }, 5000);
  };

  const setOperCntClickEvt = e => {
    console.log("e.target.classList = ", e.target.classList);
    console.log("e.target.classList[0] = ", e.target.classList[0]);
    // setEnsoTypeCode 값이 설정할 때 이벤트 값에서 클래스 배열 순으로 처리하는데 문제는 에너지원 값 설정 시 "cnt-header-" 값이 같이 넘어와서 필터링을 해줘야함_jhchoi(20220208)
    let setEnsoTypeCode = e.target.classList[0];
    if( e.target.classList[0] && setEnsoTypeCode.includes("cnt-header-") ){
      console.log("상태 = ", e.target.classList[1]);
      setEnsoTypeCode = e.target.classList[0].replace("cnt-header-" , '');
    }else if( e.target.classList[0] && setEnsoTypeCode.includes("cnt-table-") ){
      console.log("상태 = ", e.target.classList[1]);
      setEnsoTypeCode = e.target.classList[0].replace("cnt-table-" , '');
    }else if( e.target.classList[0] && setEnsoTypeCode.includes("cnt-data-") ){
      console.log("상태 = ", e.target.classList[1]);
      setEnsoTypeCode = e.target.classList[0].replace("cnt-data-" , '');
    }
    const setStateCode = e.target.classList[1];
    let statNormal = false;
    let statUnOper = false;
    let statReady = false;
    let statWarn = false;
    let statError = false;

    if (setStateCode === 7002) {
      statNormal = true;
    } else if (setStateCode === 7001) {
      statReady = true;
    } else if (setStateCode === 7006) {
      statWarn = true;
    } else if (setStateCode === 7004) {
      statUnOper = true;
    } else if (setStateCode === 7007) {
      statError = true;
    } else if (setStateCode === 7000) {
      statNormal = true;
      statReady = true;
      statWarn = true;
      statUnOper = true;
      statError = true;
    }

    console.log("setEnsoTypeCode = ", setEnsoTypeCode);
    console.log("setStateCode = ", setStateCode);

    history.push(
      "/monitor/installerMeain?cityProvCode=" +
        state.cityProvCode +
        "&rgnCode=" +
        state.rgnCode +
        "&ensoTypeCode=" +
        setEnsoTypeCode +
        "&statNormal=" +
        statNormal +
        "&statUnOper=" +
        statUnOper +
        "&statReady=" +
        statReady +
        "&statWarn=" +
        statWarn +
        "&statError=" +
        statError,
    );
  };


  // 전기에너지, 열에너지 자리수 표현에 따른 설정
  const setEnergyValueLength = ( value ) =>{
    const length = roundNum(value , 0).toString().length;
    let fontSize = 18; 
    const offset = 4;
    if( length > 10){
      fontSize = length + ( (( length % 10 ) * -2 ) + offset );
    }
    return <span style={{fontSize : fontSize}}><CountUp end={value} separator="," duration={1.5} /></span>;
  }

  // 운영현황 값 길이에 따른 설정
  const setOperValueLength = ( value ,ensoTypeCode , meainStatCode ) =>{

    //setOperValueLength(ensoCnt.errorCnt, ensoCnt.ensoTypeCode, 7007 )}

    const length = value.toString().length;
    let fontSize = 18; 
    if( length > 6){
      fontSize = (length * 2) + (( length - 7 ) * -3 )-1
    }
    return <span style={{fontSize : fontSize}}>
      <CountUp className={ `cnt-data-${ensoTypeCode} ${meainStatCode}` } end={value} separator="," duration={1.5} /></span>;
  }

  // console.log("state.areaEnergyList",state.areaEnergyList);
  return (
    <>
      {isMobile ? (
        <div className={areaGridLoading ? styles.areaTable : ""}>
          <div className="TitleHistory">
            <button onClick={setAreaChangeEvt} className={styles.cityTerm} disabled = { loginUserTypeCode === 2002 ? true : false } >
              {areaTerm}
            </button>

            {state.cityProvTerm === "" ? (
              ""
            ) : (
              <button onClick={setCityChangeEvt} className={styles.cityTerm} disabled = { loginUserTypeCode === 2002 ? true : false } >
                <span id="rgnTerm" className={styles.rgnTerm}>
                  &gt; {state.cityProvTerm}
                </span>
              </button>
            )}

            {state.rgnTerm === "" ? (
              ""
            ) : (
              <span id="dongTerm" className={styles.dongTerm}>
                &gt; {state.rgnTerm}
              </span>
            )}
          </div>
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title="태양광">
              <div className="uk-grid">
                <div className="uk-width-medium-1-1">
                  <div className="Table-scr">
                    <Grid
                      filterable={false}
                      sortable={false}
                      pageable={false}
                      data={state.areaEnergyList}
                      className="areaEnergyGrid genarationGrid"
                      style={{ height: "615px" }}
                      onRowClick={state.cityProvCode !== "" ? setDongClickEvt : setRgnClickEvt}>
                      <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />
                      <Column title="태 양 광">
                        <Column field="solaCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="solaCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="solaDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      </Column>
                    </Grid>
                  </div>
                </div>
              </div>
            </TabStripTab>
            <TabStripTab title="태양열">
              <div className="uk-grid">
                <div className="uk-width-medium-1-1">
                  <div className="Table-scr">
                    <Grid
                      filterable={false}
                      sortable={false}
                      pageable={false}
                      data={state.areaEnergyList}
                      className="areaEnergyGrid productionGrid"
                      style={{ height: "615px" }}
                      onRowClick={state.cityProvCode !== "" ? setDongClickEvt : setRgnClickEvt}>
                      <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />
                      <Column title="태 양 열">
                        <Column field="heatCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="heatCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="heatDayUse" title="사용량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      </Column>
                    </Grid>
                  </div>
                </div>
              </div>
            </TabStripTab>
            <TabStripTab title="지열">
              <div className="uk-grid">
                <div className="uk-width-medium-1-1">
                  <div className="Table-scr">
                    <Grid
                      filterable={false}
                      sortable={false}
                      pageable={false}
                      data={state.areaEnergyList}
                      className="areaEnergyGrid productionGrid"
                      style={{ height: "615px" }}
                      onRowClick={state.cityProvCode !== "" ? setDongClickEvt : setRgnClickEvt}>
                      <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />

                      <Column title="지 열">
                        <Column field="heathCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="heathCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="heathDayUse" title="사용량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      </Column>
                    </Grid>
                  </div>
                </div>
              </div>
            </TabStripTab>
            <TabStripTab title="풍력">
              <div className="uk-grid">
                <div className="uk-width-medium-1-1">
                  <div className="Table-scr">
                    <Grid
                      filterable={false}
                      sortable={false}
                      pageable={false}
                      data={state.areaEnergyList}
                      className="areaEnergyGrid genarationGrid"
                      style={{ height: "615px" }}
                      onRowClick={state.cityProvCode !== "" ? setDongClickEvt : setRgnClickEvt}>
                      <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />

                      <Column title="풍 력">
                        <Column field="veloCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="veloCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="veloDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      </Column>
                    </Grid>
                  </div>
                </div>
              </div>
            </TabStripTab>
            <TabStripTab title="연료전지">
              <div className="uk-grid">
                <div className="uk-width-medium-1-1">
                  <div className="Table-scr">
                    <Grid
                      filterable={false}
                      sortable={false}
                      pageable={false}
                      data={state.areaEnergyList}
                      className="areaEnergyGrid genarationGrid"
                      style={{ height: "615px" }}
                      onRowClick={state.cityProvCode !== "" ? setDongClickEvt : setRgnClickEvt}>
                      <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />

                      <Column title="연료전지">
                        <Column field="fuelcellCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="fuelcellCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="fuelcellDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      </Column>
                    </Grid>
                  </div>
                </div>
              </div>
            </TabStripTab>
            <TabStripTab title="ESS">
              <div className="uk-grid">
                <div className="uk-width-medium-1-1">
                  <div className="Table-scr">
                    <Grid
                      filterable={false}
                      sortable={false}
                      pageable={false}
                      data={state.areaEnergyList}
                      className="areaEnergyGrid essGrid"
                      style={{ height: "615px" }}
                      onRowClick={state.cityProvCode !== "" ? setDongClickEvt : setRgnClickEvt}>
                      <Column className={styles.areaTerm} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />

                      <Column title="ESS">
                        <Column field="essCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="essCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="essCharge" title="충전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                        <Column field="essDecahrge" title="방전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                      </Column>
                    </Grid>
                  </div>
                </div>
              </div>
            </TabStripTab>
          </TabStrip>

          <div className="uk-grid">
            <div className="uk-width-medium-1-6">
              <div className="md-card">
                <div className="md-card-content">
                  <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                      <span className={styles.rgnTerm}>
                        {state.cityProvTerm === "" ? areaTerm : state.rgnTerm === "" ? state.cityProvTerm : state.rgnTerm}
                      </span>
                      전기에너지
                    </h3>
                  </div>
                  <table className="Ch-TableB">
                    <tbody>
                      <tr>
                        <th>금일발전량</th>
                        <td className={styles.totPowerNum}>
                          <CountUp end={state.totalEnergy.dayPower} separator="," duration={1.5} />
                        </td>
                        <td>kWh</td>
                      </tr>
                      <tr>
                        <th>
                          금일CO<sub>2</sub> 저감량
                        </th>
                        <td className={styles.totPowerNum}>
                          <CountUp
                            end={
                              state.totalEnergy.dayPowerCo2 / 1000 < 0
                                ? state.totalEnergy.dayPowerCo2
                                : state.totalEnergy.dayPowerCo2 / 1000
                            }
                            separator=","
                            duration={1.5}
                          />
                        </td>
                        <td>
                          {state.totalEnergy.dayPowerCo2 / 1000 < 0 ? "" : "t"}CO<sub>2</sub>
                        </td>
                      </tr>
                      <tr>
                        <th>설비용량</th>
                        <td className={styles.totPowerNum}>
                          <CountUp end={state.totalEnergy.powerCapa} separator="," duration={1.5} />
                        </td>
                        <td>kW</td>
                      </tr>
                      <tr>
                        <th>누적발전량</th>
                        <td className={styles.totPowerNum}>
                          <CountUp end={state.totalEnergy.accumPower / 1000} separator="," duration={1.5} />
                        </td>
                        <td>MWh</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="uk-width-medium-1-6">
              <div className="md-card">
                <div className="md-card-content">
                  <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                      <span className={styles.rgnTerm}>
                        {state.cityProvTerm === "" ? areaTerm : state.rgnTerm === "" ? state.cityProvTerm : state.rgnTerm}
                      </span>
                      열에너지
                    </h3>
                  </div>
                  <table className="Ch-TableC">
                    <tbody>
                      <tr>
                        <th>금일사용량</th>
                        <td className="textcoB">
                          <CountUp end={state.totalEnergy.dayUse} separator="," duration={1.5} />
                        </td>
                        <td>kWh</td>
                      </tr>
                      <tr>
                        <th>
                          금일CO<sub>2</sub> 저감량
                        </th>
                        <td className="textcoB">
                          <CountUp
                            end={state.totalEnergy.dayUseCo2 / 1000 < 0 ? state.totalEnergy.dayUseCo2 : state.totalEnergy.dayUseCo2 / 1000}
                            separator=","
                            duration={1.5}
                          />
                        </td>
                        <td>
                          {state.totalEnergy.dayUseCo2 / 1000 < 0 ? "" : "t"}CO<sub>2</sub>
                        </td>
                      </tr>
                      <tr>
                        <th>설비용량 태양열</th>
                        <td className="textcoB">
                          <CountUp end={state.totalEnergy.heatCapa} separator="," duration={1.5} />
                        </td>
                        <td>
                          km<sup>2</sup>
                        </td>
                      </tr>
                      <tr>
                        <th>설비용량 지열</th>
                        <td className="textcoB">
                          <CountUp end={state.totalEnergy.heathCapa} separator="," duration={1.5} />
                        </td>
                        <td>kW</td>
                      </tr>
                      <tr>
                        <th>누적사용량</th>
                        <td className="textcoB">
                          <CountUp end={state.totalEnergy.accumUse / 1000} separator="," duration={1.5} />
                        </td>
                        <td>MWh</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="uk-width-medium-2-3">
              <div className="md-card">
                <div className="md-card-content">
                  <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                      {/* <a onClick={setOperClickEvt}> */}
                      <span className={styles.rgnTerm}>
                        {state.cityProvTerm === "" ? areaTerm : state.rgnTerm === "" ? state.cityProvTerm : state.rgnTerm}
                      </span>
                      운영현황
                      {/* </a> */}
                    </h3>
                  </div>
                  <div className={styles.meainStatCntBox} onClick={setOperCntClickEvt}>
                    {state.meatinStatCnt.map((ensoCnt, index) => {
                      const totalCnt = ensoCnt.normalCnt + ensoCnt.unOperCnt + ensoCnt.readyCnt + ensoCnt.waringCnt + ensoCnt.errorCnt;
                      return (
                        <table className={`${styles.meainStatCnt} cnt-table-${ensoCnt.ensoTypeCode}`} key={index}>
                          <thead>
                            <tr>
                              <th colSpan="2" className={`cnt-header-${ensoCnt.ensoTypeCode} ${styles.meainStatCntEnso}`}>
                                {getEnsoTerm(ensoCnt.ensoTypeCode)}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7002 ${styles.meainStatCntType}`}>정&nbsp;&nbsp;&nbsp;&nbsp;상</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7002 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.normalCnt === 0 ? "-" : <CountUp end={ensoCnt.normalCnt} separator="," duration={1.5} />}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7001 ${styles.meainStatCntType}`}>준비중</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7001 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.readyCnt === 0 ? "-" : <CountUp end={ensoCnt.readyCnt} separator="," duration={1.5} />}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7006 ${styles.meainStatCntType}`}>경&nbsp;&nbsp;&nbsp;&nbsp;고</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7006 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.waringCnt === 0 ? "-" : <CountUp end={ensoCnt.waringCnt} separator="," duration={1.5} />}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7004 ${styles.meainStatCntType}`}>미작동</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7004 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.unOperCnt === 0 ? "-" : <CountUp end={ensoCnt.unOperCnt} separator="," duration={1.5} />}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7007 ${styles.meainStatCntType}`}>고&nbsp;&nbsp;&nbsp;&nbsp;장</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7007 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.errorCnt === 0 ? "-" : <CountUp end={ensoCnt.errorCnt} separator="," duration={1.5} />}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} ${styles.meainStatCntType}`}>총개소</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {totalCnt === 0 ? "-" : <CountUp end={totalCnt} separator="," duration={1.5} />}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {areaGridLoading && (
            <p className={styles.areaTableLoading}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </div>
      ) : (
        <div className={areaGridLoading ? styles.areaTable : ""}>
          <div className="TitleHistory">
            { loginUserTypeCode === 2002 ? 
              <span className={styles.cityTerm}> {areaTerm} </span>  
              :
              <button onClick={setAreaChangeEvt} className={styles.cityTerm}> {areaTerm} </button>
            }
            {state.cityProvTerm === "" ? (
              ""
            ) : (
                loginUserTypeCode === 2002 ? 
                <span id="rgnTerm" className={styles.cityTerm}>
                  &gt; {state.cityProvTerm}
                </span>
                :
                <button onClick={setCityChangeEvt} className={styles.cityTerm} disabled = { loginUserTypeCode === 2002 ? true : false } >
                <span id="rgnTerm" className={styles.rgnTerm}>
                  &gt; {state.cityProvTerm}
                </span>
                </button>
            )}
            {state.rgnTerm === "" ? (
              ""
            ) : (
              <span id="dongTerm" className={styles.dongTerm}>
                &gt; {state.rgnTerm}
              </span>
            )}
          </div>
          <div className="uk-grid">
            <div className="uk-width-medium-1-1">
              <div className="Table-scr">
                <Grid
                  filterable={false}
                  sortable={false}
                  pageable={false}
                  data={state.areaEnergyList}
                  className="areaEnergyGrid"
                  style={{ height: "615px" }}
                  onRowClick={state.cityProvCode !== "" ? setDongClickEvt : setRgnClickEvt}>
                  <Column className={styles.areaTerm} cell = {setColumnRgnFormat} field="areaTerm" title="지 역" footerCell={() => <td>총계</td>} />
                  <Column title="태 양 광">
                    <Column field="solaCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="solaCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="solaDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  </Column>
                  <Column title="태 양 열">
                    <Column field="heatCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="heatCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="heatDayUse" title="사용량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  </Column>
                  <Column title="지 열">
                    <Column field="heathCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="heathCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="heathDayUse" title="사용량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  </Column>
                  <Column title="풍 력">
                    <Column field="veloCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="veloCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="veloDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  </Column>
                  <Column title="연료전지">
                    <Column field="fuelcellCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="fuelcellCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="fuelcellDayPower" title="발전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  </Column>
                  <Column title="ESS">
                    <Column field="essCnt" title="개소" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="essCapa" title="설비용량(kW)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="essCharge" title="충전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                    <Column field="essDecahrge" title="방전량(kWh)" cell={setColumnNumberFormat} footerCell={setColumnSum} />
                  </Column>
                </Grid>
              </div>
            </div>
          </div>
          <div className="uk-grid">
            <div className="uk-width-medium-1-6">
              <div className="md-card">
                <div className="md-card-content">
                  <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                      <span className={styles.rgnTerm}>
                        {state.cityProvTerm === "" ? areaTerm : state.rgnTerm === "" ? state.cityProvTerm : state.rgnTerm}
                      </span>
                      전기에너지
                    </h3>
                  </div>
                  <table className="Ch-TableB">
                    <tbody>
                      <tr>
                        <th>금일발전량</th>
                        <td className={styles.totPowerNum}>
                          {setEnergyValueLength(state.totalEnergy.dayPower)}
                        </td>
                        <td>kWh</td>
                      </tr>
                      <tr>
                        <th>
                          금일CO<sub>2</sub> 저감량
                        </th>
                        <td className={styles.totPowerNum}>
                          {setEnergyValueLength(state.totalEnergy.dayPowerCo2 / 1000 < 0 ? state.totalEnergy.dayPowerCo2 : state.totalEnergy.dayPowerCo2 / 1000)}
                        </td>
                        <td>
                          {state.totalEnergy.dayPowerCo2 / 1000 < 0 ? "" : "t"}CO<sub>2</sub>
                        </td>
                      </tr>
                      <tr>
                        <th>설비용량</th>
                        <td className={styles.totPowerNum}>
                          {setEnergyValueLength(state.totalEnergy.powerCapa)}
                        </td>
                        <td>kW</td>
                      </tr>
                      <tr>
                        <th>누적발전량</th>
                        <td className={styles.totPowerNum}>
                          {setEnergyValueLength(state.totalEnergy.accumPower / 1000)}
                        </td>
                        <td>MWh</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="uk-width-medium-1-6">
              <div className="md-card">
                <div className="md-card-content">
                  <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                      <span className={styles.rgnTerm}>
                        {state.cityProvTerm === "" ? areaTerm : state.rgnTerm === "" ? state.cityProvTerm : state.rgnTerm}
                      </span>
                      열에너지
                    </h3>
                  </div>
                  <table className="Ch-TableC">
                    <tbody>
                      <tr>
                        <th>금일사용량</th>
                        <td className="textcoB">
                          {setEnergyValueLength(state.totalEnergy.dayUse)}
                        </td>
                        <td>kWh</td>
                      </tr>
                      <tr>
                        <th>
                          금일CO<sub>2</sub> 저감량
                        </th>
                        <td className="textcoB">
                          {setEnergyValueLength(state.totalEnergy.dayUseCo2 / 1000 < 0 ? state.totalEnergy.dayUseCo2 : state.totalEnergy.dayUseCo2 / 1000)}
                        </td>
                        <td>
                          {state.totalEnergy.dayUseCo2 / 1000 < 0 ? "" : "t"}CO<sub>2</sub>
                        </td>
                      </tr>
                      <tr>
                        <th>설비용량 태양열</th>
                        <td className="textcoB">
                          {setEnergyValueLength(state.totalEnergy.heatCapa)}
                        </td>
                        <td>
                          km<sup>2</sup>
                        </td>
                      </tr>
                      <tr>
                        <th>설비용량 지열</th>
                        <td className="textcoB">
                          {setEnergyValueLength(state.totalEnergy.heathCapa)}
                        </td>
                        <td>kW</td>
                      </tr>
                      <tr>
                        <th>누적사용량</th>
                        <td className="textcoB">
                          {setEnergyValueLength(state.totalEnergy.accumUse / 1000)}
                        </td>
                        <td>MWh</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="uk-width-medium-2-3">
              <div className="md-card">
                <div className="md-card-content">
                  <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                      {/* <a onClick={setOperClickEvt}> */}
                      <span className={styles.rgnTerm}>
                        {state.cityProvTerm === "" ? areaTerm : state.rgnTerm === "" ? state.cityProvTerm : state.rgnTerm}
                      </span>
                      운영현황
                      {/* </a> */}
                    </h3>
                  </div>
                  <div className={styles.meainStatCntBox} onClick={setOperCntClickEvt}>
                    {state.meatinStatCnt.map((ensoCnt, index) => {
                      // console.log("ensoCnt = ", ensoCnt);
                      const totalCnt = ensoCnt.normalCnt + ensoCnt.unOperCnt + ensoCnt.readyCnt + ensoCnt.waringCnt + ensoCnt.errorCnt;
                      // console.log("totalCnt = ", totalCnt);
                      return (
                        <table className={`${styles.meainStatCnt} cnt-table-${ensoCnt.ensoTypeCode}`} key={index}>
                          <thead>
                            <tr>
                              <th colSpan="2" className={`cnt-header-${ensoCnt.ensoTypeCode} ${styles.meainStatCntEnso}`}>
                                {getEnsoTerm(ensoCnt.ensoTypeCode)}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7002 ${styles.meainStatCntType}`}>정&nbsp;&nbsp;&nbsp;&nbsp;상</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7002 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.normalCnt === 0 ? "-" : setOperValueLength(ensoCnt.normalCnt, ensoCnt.ensoTypeCode, 7002)}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7001 ${styles.meainStatCntType}`}>준비중</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7001 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.readyCnt === 0 ? "-" : setOperValueLength(ensoCnt.readyCnt , ensoCnt.ensoTypeCode, 7001)}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7006 ${styles.meainStatCntType}`}>경&nbsp;&nbsp;&nbsp;&nbsp;고</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7006 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.waringCnt === 0 ? "-" : setOperValueLength(ensoCnt.waringCnt , ensoCnt.ensoTypeCode, 7006)}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7004 ${styles.meainStatCntType}`}>미작동</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7004 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.unOperCnt === 0 ? "-" : setOperValueLength(ensoCnt.unOperCnt , ensoCnt.ensoTypeCode, 7004)}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} 7007 ${styles.meainStatCntType}`}>고&nbsp;&nbsp;&nbsp;&nbsp;장</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} 7007 ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.errorCnt === 0 ? "-" : setOperValueLength(ensoCnt.errorCnt, ensoCnt.ensoTypeCode, 7007 )}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                            <tr>
                              <th className={` cnt-data-${ensoCnt.ensoTypeCode} ${styles.meainStatCntType}`}>총개소</th>
                              <td className={` cnt-data-${ensoCnt.ensoTypeCode} ${styles.colorG} ${styles.meainStatCntVal}`}>
                                {ensoCnt.totalCnt === 0 ? "-" : setOperValueLength(totalCnt , ensoCnt.ensoTypeCode)}
                                <b className={`${styles.meainStatCntTerm}`}>개소</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {areaGridLoading && (
            <p className={styles.areaTableLoading}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default AreaEnergy;
