import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

const GathRawDataLogGridHeath = ({ search, paging, setOperHistData , cellKeyFormat ,cellFormat }) => {
  
  // 페이징 이벤트
  const onPageChange = e => {
    let { take, skip } = e.page;
    // 검색 안되도록 수정 _ 20220216 _ jhchoi
    if(search.cid === "" && search.userId === ""){
      return;
    }
    paging.take = take;
    paging.skip = skip;
    setOperHistData(search, paging);
  };

  // 정렬 이벤트
  const onSortChange = e => {
    const { sort } = e;
    // 검색 안되도록 수정 _ 20220216 _ jhchoi
    if(search.cid === "" && search.userId === ""){
      return;
    }
    paging.sort = sort;
    setOperHistData(search, paging);
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              id="heathGrid"
              key={15003}
              className="operHistGrid"
              data={paging.list}
              resizable={true}
              pageable={{
                type: "numeric",
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true,
                buttonCount: 5,
                refresh: true,
              }}
              total={paging.count}
              skip={paging.skip}
              take={paging.take}
              pageSize={paging.take}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              sort={paging.sort}
              sortable>
              <GridNoRecords>{"  "}</GridNoRecords>
              <GridColumn title="CID" field="cid" width="130px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="설치자" field="userTerm" width="200px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="수집일자" field="gathDtm" width="170px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="dataStatSctCodeTerm" title="데이터상태" width="140px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="accumPrdctHeatQty" title="누적생산열량(kWh)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="prdctHeatQty" title="생산열량(W)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="accumElcpUseQty" title="누적전력소비량(kWh)" width="140px" cell={cellFormat}></GridColumn>
              <GridColumn field="heathInwtrTemp" title="지열수입구온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="heathOlutwtrTemp" title="지열수출구온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="hotwaterInwtrTemp" title="온수 입구온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="hotwaterOlutwtrTemp" title="온수출구온도(℃)" width="130px" cell={cellFormat}></GridColumn>
              <GridColumn field="loadEntrTemp" title="냉난방측-입구온도(℃)" width="140px" cell={cellFormat}></GridColumn>
              <GridColumn field="loadExitTemp" title="냉난방측-출구온도(℃)" width="140px" cell={cellFormat}></GridColumn>
              <GridColumn field="hstWasupTemp" title="급탕측-급수온도(℃)" width="140px" cell={cellFormat}></GridColumn>
              <GridColumn field="hstWaterheaterTemp" title="급탕측-급탕온도(℃)" width="140px" cell={cellFormat}></GridColumn>
              <GridColumn field="votgFigr" title="전압(V)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="ecurFigr" title="전류(A)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="elcpFigr" title="전력(W)" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="heathwtrOilQty" title="지열수유량(LPM)" width="120px" cell={cellFormat}></GridColumn>
              <GridColumn field="loadSideFlow" title="냉난방측-사용유량(LPM)" width="150px" cell={cellFormat}></GridColumn>
              <GridColumn field="loadSideAccCal" title="냉난방측-누적사용열량(kWh)" width="180px" cell={cellFormat}></GridColumn>
              <GridColumn field="tankSideFlow" title="급탕측-사용유량(LPM)" width="150px" cell={cellFormat}></GridColumn>
              <GridColumn field="tankSideAccCal" title="급탕측-누적사용열량(kWh)" width="170px" cell={cellFormat}></GridColumn>
              <GridColumn field="heatPumpStatCodeNo" title="펌프상태" width="90px" cell={cellFormat}></GridColumn>
              <GridColumn field="errCode" title="에러코드" width="150px" cell={cellFormat}></GridColumn>
            <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default GathRawDataLogGridHeath;
