import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {isPowerEnsoType, numFormat, roundNum} from '../../../../com/dataUtil';

const AreaEnergyChart = ({areaEnergyList, ensoTypeCode , energy}) => {

  const setChartData = (data, isEnergy) =>(
    data.map( v => (
        {   name: v.term, 
            y: !isPowerEnsoType(ensoTypeCode) ? energy.sysMode === 29002 ? !isEnergy ? v.use : v.rate : !isEnergy ? v.energy : v.rate : !isEnergy ? v.energy : v.rate, 
            color: !isEnergy ? v.color || '#EE7951' : '#6DB6EF'
        }
        ))
  );

  const areaEnergyChartOptions = {
    chart: {
      // marginTop: 50,
        type: 'column',
        // backgroundColor: "#2c2c2c"
        height:'305px',
        backgroundColor:'#f5f5f5'
    },
    title: {
        text: null
    },
    legend: {
              enabled: false
          },
          credits: {
            enabled: false
          },
          exporting: {
      enabled: false
    },
    xAxis: {
        categories: areaEnergyList.map(v => v.term),
        crosshair: true
    },
    yAxis: {
        title: {
            text: !isPowerEnsoType(ensoTypeCode) ? energy.sysMode === 29002 ?  '누적소비량(kWh)' : '누적생산량(kWh)' : '누적발전량(kWh)',
        },
        labels: {
          formatter: function() { 
            return numFormat(roundNum(this.value , 2));
          }
        }
    },
    plotOptions: {
          series: {
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              style:{
                fontSize: '14px'
              },
              formatter: function() { 
                return `<span><b>${numFormat(roundNum(this.y , 2))}</b></span>`
              }
            }
          }
    },
    tooltip: { 
      shared: true ,
      formatter: function() {
        return(
          `
            <span style="color:${this.points[0].point.color}">\u25CF</span>
            <span>${this.points[0].series.name}</span> : <b>${numFormat(roundNum(this.points[0].y , 2 ))} (kWh)</b><br>
          ` 
        );
      }
    },
    series: [{
      name : !isPowerEnsoType(ensoTypeCode) ? energy.sysMode === 29002 ?  '누적소비량(kWh)' : '누적생산량(kWh)' : '누적발전량(kWh)',
        data: setChartData(areaEnergyList)
    }]
  }

  return (
    <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={areaEnergyChartOptions}/>
  );
}

export default AreaEnergyChart;