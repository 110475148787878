import { roundNum } from "com/dataUtil";
import "./DevelopmentState.css";

// 단위 변환, kWh와 MWh만 고려
export const translateUnit = power => {
  const digit = Number(power)
  if (digit < 1000 ) {
    return `${Number(power).toFixed(1).toLocaleString()} kWh`;
  } else {
    return `${Number(power / 10 ** 3)
      .toFixed(1)
      .toLocaleString()} MWh`;
  }
};

// CO2와 tCO2만 고려
const CO2 = ({co2}) => {
  const currentCo2 = Number(co2)
  if (currentCo2 < 1000) {
    return (
      <>
      온실가스 감소량 {roundNum(Number(co2), 1).toLocaleString()} CO<small>2</small>
      </>
    )
  }
  return (
    <>
      온실가스 감소량 {roundNum(Number(co2) / 1000, 1).toLocaleString()} tCO<small>2</small>
    </>
  )
}

export default function DevelopmentState({ solaDayPower, heatDayPower, geoDayPower, solaCo2, heatCo2, geoCo2 }) {
  const stateList = [
    {
      title: "태양광",
      power: solaDayPower,
      co2: solaCo2,
    },
    {
      title: "태양열",
      power: heatDayPower,
      co2: heatCo2,
    },
    {
      title: "지열",
      power: geoDayPower,
      co2: geoCo2,
    },
  ];

  return (
    <div className="asan-devlop-state">
      <h3 className="asan-card-title">에너지원별 발전현황</h3>
      <div className="asan-develop-card">
        {stateList.map(state => (
          <div className="asan-develop-container">
            <h3>{state.title}</h3>
            <div>
              <span className="asan-develop-amount">{translateUnit(state.power).split(" ")[0]}</span>{" "}
              <span className="amount-won">{translateUnit(state.power).split(" ")[1]}</span>
            </div>
            <div>
              <CO2 co2={state.co2}/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
