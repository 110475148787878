import React from "react";

const BizFileBpm1 = ({
  onAddFile,
  onRemoveFile,
  onStatusChangeFile,
  fileState,
  windowVisible,
  uploadFileEvt,
  deleteFileEvt,
  file,
  downloadFileEvt,
  saveFileUrl,
  removeFileUrl,
  IntlProvider,
  LocalizationProvider,
  getToken,
  Upload,
  Window,
}) => {
  // console.log("file",file);

  return (
    <>
      <div class="JinTabMo_con" id="JinTabModalBB_con">
        <div class="SubM_title">[ 첨부서류 ]</div>
        <div class="info_text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; · <span className="red_cl">50MB이상의 파일은 첨부하실 수 없습니다.</span>(이미지파일은
          JPEG,JPG,GIF로 변환하여 첨부하셔야 합니다.)
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; · 첨부파일을 수정하시려면 반드시 삭제후 재등록 하셔야 합니다.
          <br />
        </div>
        <div class="eer-Table-scr" style={{ height: "330px" }}>
          <table class="remcBB-Table eer-Table-striped">
            <colgroup>
              <col width="550" />
              <col width="250" />
              <col width="" />
              <col width="250" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>필수</th>
                <th>첨부파일</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>본인설치대상시설(건물) 등기부등본 또는 건축물대장 1부(신축건물은 건축허가서)</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="B11" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.B11 ? file.B11.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.B11 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="B11"
                    onClick={file?.B11 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B11 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>본인서명사실확인서(개인인경우)또는 사업자등록증(기관)및 법인인감증명서(기관)[법인인경우]</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="B12" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.B12 ? file.B12.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.B12 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="B12"
                    onClick={file?.B12 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B12 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>설비 설치계획서[에너지원]</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="B13" class="gree_cl" onClick={downloadFileEvt}>
                    {file.B13 ? file.B13.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.B13 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="B13"
                    onClick={file?.B13 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B13 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>설비 설치계획서[모니터링]</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="B14" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.B14 ? file.B14.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.B14 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="B14"
                    onClick={file?.B14 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B14 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>소유자 설치 동의서(소유자 서명포함) 및 공동소유 동의서(필요시 첨부)</td>
                <td class="center" style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="B15" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.B15 ? file.B15.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.B15 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="B15"
                    onClick={file?.B15 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B15 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>설치예정 장소 현장사진1매(4*6) 및 약도 1부</td>
                <td class="center" style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="B16" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.B16 ? file.B16.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.B16 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="B16"
                    onClick={file?.B16 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B16 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>개인정보 수집·이용 동의서</td>
                <td class="center" style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="B17" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.B17 ? file.B17.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.B17 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="B17"
                    onClick={file?.B17 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B17 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>한전 고객종합정보내역</td>
                <td class="center" style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="B18" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.B18 ? file.B18.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.B18 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="B18"
                    onClick={file?.B18 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B18 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>기타</td>
                <td class="center" style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="B19" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.B19 ? file.B19.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.B19 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="B19"
                    onClick={file?.B19 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B19 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {windowVisible && (
        <Window
          title={"파일 등록"}
          onClose={uploadFileEvt}
          top={186}
          left={483}
          initialHeight={350}
          initialWidth={600}
          modal={true}
          resizable={false}
          doubleClickStageChange={false}
          draggable={false}>
          <div>
            {/* 윈도우 창 */}
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Upload
                  autoUpload={false}
                  batch={true}
                  multiple={false}
                  files={fileState.fileList}
                  defaultFiles={[]}
                  onAdd={onAddFile}
                  onRemove={onRemoveFile}
                  onStatusChange={onStatusChangeFile}
                  withCredentials={true}
                  removeVerb={"DELETE"}
                  restrictions={{
                    allowedExtensions: [
                      ".gif",
                      ".jpg",
                      ".png",
                      // ".xls",
                      // ".xlsx",
                      ".hwp",
                      ".doc",
                      ".pdf",
                      ".exe",
                      ".zip",
                    ],
                    maxFileSize: 51200000,
                  }}
                  saveMethod={"post"}
                  saveHeaders={{
                    "Content-Type": "multipart/form-data",
                    "X-AUTH-TOKEN": getToken(),
                  }}
                  saveUrl={saveFileUrl}
                  removeUrl={removeFileUrl}
                />
              </IntlProvider>
            </LocalizationProvider>
            {/* <div>
              <Button>저장</Button>
              <Button>취소</Button>
            </div> */}
          </div>
        </Window>
      )}
    </>
  );
};

export default BizFileBpm1;
