import React, { useEffect, useState } from 'react';
import { deleteFileList, downloadFile } from '../../../../../service/bizStat/com/bizFileSupportService';
import BizManagementMngtHistUpload from './bizManagementMngtHistUpload';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - A/S보수이력 및 관리이력 - 관리이력
*/
const BizManagementMngtHist = ({
  token, convMngtStyles, mngtFileList, meainInfo, conn
  , Button , getMeainMngtFileList
  , kendo , search
}) => {

  console.log("search = ", search);
  
  // 업로드 파일 창 제어
  const [ uploadStat , setUploadStat ] = useState(false);

  // 파일 정보
  const [fileInfo , setFileInfo] = useState({
    MH1 : {} ,
    MH2 : {} ,
    MH3 : {} , 
    selectId : ""
  });

  // 파일 세팅 (배열 데이터를 각 객체마다 데이터 잘 넣을 수 있도록 설정) 
  const setFileInfoData = () => {
    setFileInfo( fileInfo => {
      const item = {...fileInfo};
      // 초기화
      item.MH1 = {};
      item.MH2 = {};
      item.MH3 = {};
      // 파일 리스트 만큼 반복 진행
      if( mngtFileList.length > 0 ){
        mngtFileList.forEach(element => {
          element.docuCodeNo ? item[element.docuCodeNo] = element : item[element.docuCodeNo] = {};
        });
      }
      return item;
    } )
  }

  // 등록 버튼 클릭 시 윈도우 창 띄우기
  const uploadSetClickEvt = (id) => {
    fileInfo.selectId = id;
    setUploadStat(!uploadStat);
  }

  // 업로드 취소 버튼 클릭 시 창 닫기
  const uploadCloseWindow = () =>{
    fileInfo.selectId = "";
    setUploadStat(false);
  }

  // 파일 다운로드
  const downloadFileEvt = (e) => {
    const downloadInfo = {
      instlPlnnm : search.instlPlnnm, 
      docuCodeNo : e.target.name,
      bizDocuCodeNo : 38006,
      energyTypeCode : search.mngt.energyTypeCode ,
      bizSctCode : search.bizSctCode
    } 
    const fileName = e.target.outerText;
    downloadFile(downloadInfo, fileName);
  }

  // 파일 삭제 
  const deleteFileEvt = (id) => {
    kendo.confirm("해당 파일을 삭제 하시겠습니까?")
    .then(e => {
      let removeFileIds = [{
        instlPlnnm : search.instlPlnnm, 
        docuCodeNo : id,
        bizDocuCodeNo : 38006,
        energyTypeCode : search.mngt.energyTypeCode , 
        bizSctCode : search.bizSctCode
      }];
      deleteFileList(conn, removeFileIds, id)
      .then( r => {
        kendo.alert("삭제되었습니다.");
        getMeainMngtFileList();
      }).catch(e => {
        kendo.alert("삭제되지 않았습니다.");
      });
    }).catch( e => {
      kendo.alert("삭제를 취소 하였습니다.");
      return;
    });
  }

  useEffect(() => {
    // 파일 리스트 설정 
    setFileInfoData();
    /* eslint-disable-next-line */
  }, [mngtFileList]);

  return (
    <>
    {/* <div className={ meainInfo.moniYn === 'Y' && convMngtStyles.moniYesTableCss } > */}
      <div className="tbTitle">
          <span className="sel-title">[ 관리이력 ]</span>
      </div>
      <table className= {["eer-Table","eer-Table-striped", convMngtStyles.uploadTable].join(' ')} style={{height: "40%"}}>
          <colgroup>
              <col width="25%"/>
              <col />
              <col width="15%"/>
          </colgroup>
          <thead>
              <tr>
                  <th>공정자료</th>
                  <th>첨부파일</th>
                  <th>선택</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td className="center">설치공정사진</td>
                  <td className="left"><a name = "MH1" onClick={downloadFileEvt} href = "#!" >{ fileInfo.MH1.fileName ? fileInfo.MH1.fileName : ' - ' }</a></td>
                  <td>
                    {  fileInfo.MH1.docuCodeNo ?
                        <Button className={convMngtStyles.delFileBtn} onClick = {() => { deleteFileEvt("MH1") }}>삭제</Button>
                        :
                        <Button className={convMngtStyles.addFileBtn} onClick = {() => { uploadSetClickEvt("MH1") }}>등록</Button>
                    }   
                  </td>
              </tr>
              { meainInfo.moniYn === "N" ? 
                <>
                  <tr>
                      <td className="center">모듈/인버터인증서 시험성적서</td>
                      <td className="left"><a name = "MH2" onClick={downloadFileEvt} href = "#!" >{ fileInfo.MH2.fileName ? fileInfo.MH2.fileName : ' - ' }</a></td>
                      <td>
                        {  fileInfo.MH2.docuCodeNo ?
                            <Button className={convMngtStyles.delFileBtn} onClick = {() => { deleteFileEvt("MH2") }}>삭제</Button>
                            :
                            <Button className={convMngtStyles.addFileBtn} onClick = {() => { uploadSetClickEvt("MH2") }}>등록</Button>
                        }   
                      </td>
                  </tr>
                  {/* <tr>
                      <td className="center">인버터인증서 시험성적서</td>
                      <td className="left"><a name = "MH3" onClick={downloadFile} href = "#!" >{ fileInfo.MH3.fileName ? fileInfo.MH3.fileName : ' - ' }</a></td>
                      <td>
                        {  fileInfo.MH3.docuCodeNo ?
                            <Button className={convMngtStyles.delFileBtn} onClick = {() => { deleteFile("MH3") }}>삭제</Button>
                            :
                            <Button className={convMngtStyles.addFileBtn} onClick = {() => { uploadSetClickEvt("MH3") }}>등록</Button>
                        }   
                      </td>
                  </tr> */}
                </>
                :
                <>
                </>
              }
          </tbody>
      </table>
      { uploadStat === true &&
        <BizManagementMngtHistUpload 
          uploadCloseWindow = {uploadCloseWindow} token = {token} convMngtStyles = {convMngtStyles} 
          getMeainMngtFileList = {getMeainMngtFileList} fileInfo = {fileInfo}
          kendo = {kendo} search = {search}
        />
      }
    {/* </div> */}
    </>
  );
};

export default BizManagementMngtHist;