import kendo from "@progress/kendo-ui";

// 일별 데이터 가져오기
export const getGathStatusDay = async (conn, callback, reject, ensoTypeCodeNo, day, bizYy , setSearch) => {
  // 검색 객체 생성
  await conn
    .get("analy/gathdata/day", {
      params: {
        ensoTypeCodeNo: ensoTypeCodeNo,
        day: kendo.toString(day, "yyyy-MM-dd"),
        bizYy : bizYy
      },
    })
    .then(response => {
      setSearch(search => {
        const item = {...search};
        item.dayLoading = false;
        return item;
      });
      callback(response);
    })
    .catch(error => {
      setSearch(search => {
        const item = {...search};
        item.dayLoading = false;
        return item;
      });
      reject(error);
    });
};

// 월별 데이터 가져오기
export const getGathStatusMonth = async (conn, callback, reject, ensoTypeCodeNo, day, bizYy , setSearch) => {
  // 마지막 날짜 설정
  const year = kendo.toString(day, "yyyy");
  const month = kendo.toString(day, "MM");
  const date = new Date(year, month, 0);
  // 검색 객체 생성
  await conn
    .get("analy/gathdata/month", {
      params: {
        ensoTypeCodeNo: ensoTypeCodeNo,
        monthStart: kendo.toString(day, "yyyy-MM-01"),
        monthEnd: kendo.toString(date, "yyyy-MM-dd"),
        bizYy : bizYy
      },
    })
    .then(response => {
      setSearch(search => {
        const item = {...search};
        item.monthLoading = false;
        return item;
      });
      callback(response);
    })
    .catch(error => {
      setSearch(search => {
        const item = {...search};
        item.monthLoading = false;
        return item;
      });
      reject(error);
    });
};
