
import { Button } from "@progress/kendo-react-buttons";
import { BeatLoader } from "react-spinners";

import DatePickerKo from "../../com/dateInputs/datePickerKo";

import styles from "./detailChart.module.css";
import TroubleShootingDetailChart from "./troubleShootingDetailChart";

const troubleShootingChartSpace = ({search, setSearch, onClickEvt, selectedUser, detailChartData, detailChartLoading}) => {
    const today = new Date();
    return (
        <>
            <div className='uk-grid troubleShootingWarnTextSpace'>
                ※ 기간별 일사량 대비 발전효율에 저하(5% 이상 - 취약/5% 미만 - 미흡)가 발생한 경우 설비를 미흡 및 취약 상태로 진단합니다.
            </div>
            <div className='uk-grid troubleShootingChartSpace'>
                <div className="md-card troubleShootingChart">
                    <div className="md-card-content">
                        <div className="md-card-toolbar">
                            <h3 className="md-card-toolbar-heading-text">대상자별 기상요소 및 발전효율 상세 조회</h3>
                            <div className="gathDetailDate">
                                <DatePickerKo
                                    id="start"
                                    name="start"
                                    className="datePicker"
                                    defaultValue={today}
                                    format="yyyy-MM "
                                    setDtm={v => {
                                        search.day = v;
                                        // setSearch((search) => {
                                        //     const item = {...search}
                                        //     item.day
                                        // })
                                    }}
                                    max={today}
                                    view="year"
                                />
                                <Button className="gathStatusExcelBtn" icon="search" onClick={onClickEvt}>
                                </Button>
                            </div>
                            <div className="gathDetailInfo">
                                <span className={['k-state-default', styles.statusViewHelp].join(' ')}>
                                    <img src="/images/info.png" alt="" />
                                    <div className={styles.statusHelpImg}>
                                        <span className="helpTxtLine">
                                            개별수용가 선택시 월간 발전효율 추이가 표시됩니다.
                                        </span>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <TroubleShootingDetailChart search={search} selectedUser={selectedUser} detailChartData={detailChartData}/>
                    </div>
                    {detailChartLoading && (
                        <p
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                            }}>
                            <BeatLoader loading={true} size={24} color="#1e88e5" />
                        </p>
                    )}
                </div>
            </div>
        </>
    )
};

export default troubleShootingChartSpace;