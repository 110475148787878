import "./uikit.almost-flat.css";
import "./kendo.css";
import "./app.css";

import React from "react";
import { useState, useEffect } from "react";
import { authGuest, setToken, isAuthenticated, getUser, isLogin, getUserTypeCode, getUserId } from "./service/authService";

import { getCodes } from "./service/codeService";
import { getRgns, getDongs, getCitys } from "./service/areaService";
import { BrowserRouter } from "react-router-dom";
import Contents from "./contents";
import Error from "./components/error";

import Login from "./components/login/login";
import { getMenuTree, getMenuRole } from "./service/menu/menuService";
import DomainError from "./components/domainError";
import JeongseonMainPage from "./components/localArea/jeongseon/jeongseonMainPage";
import DaedeokMainPage from "./components/localArea/daedeok/munic/daedeokMainPage";
import { useCreateAxiosInstance } from "./conInstance";
import GongjuMainPage from "./components/localArea/gongju/index";
import Asan from "./components/localArea/asan";
import Nonsan from "components/localArea/nonsan";
import TaeanMainPage from "components/localArea/taean";

function App() {
  const { conn, isError, setIsError } = useCreateAxiosInstance();

  const [isMobile, setIsMobile] = useState(false);
  const [state, setState] = useState({
    codeList: [],
    cityList: [],
    rgnList: [],
    dongList: [],
  });

  const setDefaultData = () => {
    let errList = [];
    // console.log("setDefaultData calling getUser() = ", getUser());
    Promise.all([getCodes(conn), getCitys(conn), getRgns(conn), getDongs(conn)]) //
      .then(results => {
        if (errList.length > 0) {
          const set = new Set(errList);
          const uniqueErrList = [...set];
          console.log("err", errList, uniqueErrList);
        }
        setState(state => {
          const item = { ...state };
          item.codeList = results[0];
          item.cityList = results[1];
          item.rgnList = results[2];
          item.dongList = results[3];
          return item;
        });
      })
      .catch(err => console.log("initial data load Fail", err));
  };

  const [loginStatus, setLoginStatus] = useState(isLogin());
  const setUpdateLoginStatus = status => {
    setLoginStatus(status);
  };

  const [isDomainError, setIsDomainError] = useState(false);
  const [isdaedeokSub, setIsdaedeokSub] = useState(false);
  const [isJeongSeonSub, setIsJeongSeonSub] = useState(false);
  const [isGongJuSub, setIsGongJuSub] = useState(false);
  const [isAsanSub, setIsAsanSub] = useState(false);
  const [isNonsanSub, setIsNonsanSub] = useState(false);
  const [isTaeanSub, setIsTaeanSub] = useState(false);

  useEffect(() => {
    // 20220310 jhchoi : sre.kr 로 접속한 인원에 대해서 kremc.kr 로 리다이렉트 되도록 하는 코드
    const hostname = window.location.hostname;
    // console.log("hostname = ", hostname);
    // console.log("window.location = ", window.location);
    // if (hostname === "localhost" || hostname === "sre.kr" || hostname === "https://sre.kr/" || hostname === "http://sre.kr/") {
    if (hostname === "sre.kr" || hostname === "https://sre.kr/" || hostname === "http://sre.kr/") {
      //   window.location.href = "https://kremc.kr/";
      //   // window.location.href = "http://sre.kr/";
      setIsDomainError(true);
    }

    // console.log(hostname);
    if (
      hostname === "jeongseon.kremc.kr" ||
      hostname === "https://jeongseon.kremc.kr/" ||
      hostname === "http://jeongseon.kremc.kr/"
      // || hostname === "localhost"
    ) {
      setIsJeongSeonSub(true);
    }

    if (hostname === "daedeok.kremc.kr") {
      setIsdaedeokSub(true);
    }

    if (hostname === "gongju.kremc.kr") {
      setIsGongJuSub(true);
    }

    if (hostname === "asan.kremc.kr") setIsAsanSub(true);
    // if (hostname === "localhost") setIsAsanSub(true);

    // if (hostname === "localhost") setIsNonsanSub(true);
    if (hostname === "nonsan.kremc.kr") setIsNonsanSub(true);
    if (hostname === "taean.kremc.kr") {
      setIsTaeanSub(true);
    }


    if (navigator.userAgent.indexOf("Mobile") === -1) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    if (isAuthenticated()) {
      // 로그인 여부 확인
      setDefaultData();
      // 로그인 상태에서 메뉴를 다시 불러와야하는 부분
      getRetryMenuList();
    } else {
      // 사용자 정보
      const user = getUser();
      authGuest(conn, "LOGIN", user) // 게스트 로그인 처리
        .then(result => {
          setToken(result.token);
          setDefaultData();
          // 로그인 상태 true 설정
          // 현재 loginstatus = false 로 isAuthenticated 가 false 라도 랜더링이 안되서 게스트 로그인 시 로그인 했다는 표시 설정
          // setLoginStatus(true);
        })
        .catch(err => {
          console.log("guest login access Fail ", err);
        });
    }
    /* eslint-disable-next-line */
  }, []);

  // 로그인 후 페이지 다시 접속하면 메뉴를 다시 불러오는 부분 _ 20220328 _ jhchoi
  const getRetryMenuList = () => {
    if (loginStatus) {
      const userType = getUserTypeCode();
      // 만약 여기서 유저 권한별로 설정하겠다면 아래 분기 처리 필요
      if (userType !== 2004) {
        if (userType === 2001) {
          getRoleMenu("/monitor/areaEnergy");
        } else if (userType === 2002) {
          getRoleMenu("/monitor/areaEnergy");
        } else if (userType === 2003) {
          getRoleMenu("/monitor/areaEnergy");
        } else if (userType === 2005) {
          getRoleMenu("/monitor/areaEnergy");
        } else if (userType === 2006) {
          getRoleMenu("/monitor/areaEnergy");
        } else if (userType === 2007) {
          getRoleMenu("/monitor/areaEnergy");
        } else {
          getRoleMenu("/monitor/areaEnergy");
        }
      }
    }
  };

  const { codeList, cityList, rgnList, dongList } = state;

  const setError = is => setIsError(is);
  const [roleMenu, setRoleMenu] = useState([]);
  const [menuState, setMenuState] = useState({
    depth1MenuList: [],
    depth2MenuList: [],
    depth3MenuList: [],
    depth1SelectedMenu: {},
    depth2SelectedMenu: {},
    depth3SelectedMenu: {},
  });

  const setSelectMenu = (selectMenuUrl, roleMenu) => {
    setMenuState(state => {
      const item = { ...state };
      if (selectMenuUrl === "") {
        return {
          depth1MenuList: [],
          depth2MenuList: [],
          depth3MenuList: [],
          depth1SelectedMenu: {},
          depth2SelectedMenu: {},
          depth3SelectedMenu: {},
          depth1Menu: {},
          depth2Menu: {},
          depth3Menu: {},
        };
      }
      roleMenu.forEach(md1 => {
        if (selectMenuUrl === md1.menuUrl) {
          item.depth3MenuList = [];
          item.depth2MenuList = [];
          item.depth1MenuList = roleMenu.filter(v => v.checked);
          item.depth1SelectedMenu = md1;
          item.depth2SelectedMenu = {};
          item.depth3SelectedMenu = {};
          // return false;
        }
        md1.items.forEach(md2 => {
          if (selectMenuUrl === md2.menuUrl) {
            item.depth3MenuList = [];
            item.depth2MenuList = md1.items.filter(v => v.checked);
            item.depth1MenuList = roleMenu.filter(v => v.checked);
            item.depth1SelectedMenu = md1;
            item.depth2SelectedMenu = md2;
            item.depth3SelectedMenu = {};
            // return false;
          }
          md2.items.forEach(md3 => {
            if (selectMenuUrl === md3.menuUrl) {
              item.depth3MenuList = md2.items.filter(v => v.checked);
              item.depth2MenuList = md1.items.filter(v => v.checked);
              item.depth1MenuList = roleMenu;
              item.depth1SelectedMenu = md1;
              item.depth2SelectedMenu = md2;
              item.depth3SelectedMenu = md3;
              // return false;
            }
          });
        });
      });
      return item;
    });
  };

  const getRoleMenu = changeMenuUrl => {
    getMenuTree(conn).then(menu => {
      /* 지자체 담당자는 독자적인 메뉴구조를 사용게끔 변경 */
      /* 기존 : tb_menu 에서 tb_menu_role 에 해당되는것만, checked = true 하는 방식 */
      if (getUser().userTypeCode === 2002 && getUser().rgnCode === "30230") {
        menu[0]?.items.forEach(element => {
          element.checked = true;
          element.items.forEach(secondsId => {
            secondsId.checked = true;
            secondsId.items.forEach(thirdId => {
              thirdId.checked = true;
            });
          });
        });

        setRoleMenu(menu[0]?.items);
      } else {
        getMenuRole(conn, menu, getUserTypeCode(), getUserId()).then(roleMenuResponse => {
          setRoleMenu(roleMenuResponse[0].items);
          let menuUrl = "";
          roleMenuResponse[0].items.forEach(md1 => {
            if (changeMenuUrl === md1.menuUrl) {
              menuUrl = md1.menuUrl;
            }
            md1.items.forEach(md2 => {
              if (changeMenuUrl === md2.menuUrl) {
                menuUrl = md2.menuUrl;
                return false;
              }
              md2.items.forEach(md3 => {
                if (changeMenuUrl === md3.menuUrl) {
                  menuUrl = md3.menuUrl;
                  return false;
                }
              });
            });
            setSelectMenu(menuUrl, roleMenuResponse[0].items);
          });
        });
      }
    });
  };

  return isDomainError ? (
    <DomainError />
  ) : isError ? (
    <Error />
  ) : isJeongSeonSub ? (
    <BrowserRouter>
      <JeongseonMainPage conn={conn} />
    </BrowserRouter>
  ) : isdaedeokSub ? (
    <BrowserRouter>
      <DaedeokMainPage conn={conn} />
    </BrowserRouter>
  ) : isGongJuSub ? (
    <BrowserRouter>
      <GongjuMainPage conn={conn} />
    </BrowserRouter>
  ) : isAsanSub ? (
    <BrowserRouter>
      <Asan />
    </BrowserRouter>
  ) : isNonsanSub ? (
    <BrowserRouter>
      <Nonsan />
    </BrowserRouter>
  ) : isTaeanSub ? (
    <BrowserRouter>
      <TaeanMainPage />
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      {loginStatus ? (
        codeList.length > 0 && cityList.length > 0 && rgnList.length > 0 && dongList.length > 0 ? (
          <Contents
            conn={conn}
            codeList={codeList}
            cityList={cityList}
            rgnList={rgnList}
            dongList={dongList}
            loginStatus={loginStatus}
            setUpdateLoginStatus={setUpdateLoginStatus}
            setError={setError}
            getRoleMenu={getRoleMenu}
            roleMenu={roleMenu}
            menuState={menuState}
            setSelectMenu={setSelectMenu}
            isMobile={isMobile}
          />
        ) : (
          <div>로딩중...</div>
        )
      ) : (
        <Login
          conn={conn}
          getRoleMenu={getRoleMenu}
          setUpdateLoginStatus={setUpdateLoginStatus}
          loginStatus={loginStatus}
          roleMenu={roleMenu}
          setSelectMenu={setSelectMenu}
          isMobile={isMobile}
        />
      )}
    </BrowserRouter>
  );
}

export default App;
