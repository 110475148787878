import React, { useState, useEffect, useCallback } from 'react';


// css import
import './codeMngt.css';
import styles from './codeMngt.module.css'

// 켄도 UI 관련 (ex : kendo.alert 등등)
import kendo from '@progress/kendo-ui';
// 켄도 버튼
import {Button} from '@progress/kendo-react-buttons'
// 켄도 그리드
import { Grid, GridColumn as Column} from '@progress/kendo-react-grid';
// 켄도 드롭다운
import { DropDownList } from '@progress/kendo-react-dropdowns';
// 켄도 윈도우
import { Window } from '@progress/kendo-react-dialogs'
// 켄도 업로드
import { Upload } from '@progress/kendo-react-upload';
// 켄도 엑셀 다운로드
import { ExcelExport } from '@progress/kendo-react-excel-export';
// 테이블 필터링
import { filterBy } from '@progress/kendo-data-query';

import GridColumnInputFilter from '../com/gridFilter/gridColumnInputFilter';
import { getCodes, setCodeList } from '../../service/codeService'
import {getMessage} from '../../com/messageUtil';
import CustomFilter from '../com/gridFilter/custom_filter';
import { getToken } from '../../service/authService';
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

const CodeMngt = ({conn}) => {

    // 데이터 소스 초기화
    const [dataSource, setDataSource] = useState({
        data : []
        , editField: undefined
        , changes: false
    });

    // 데이터 소스 백업 : 취소 버튼 클릭 시 백업 데이터 소스로 바꾸기 
    const [prevDataSource , setPrevDataSource] = useState([]);

    // 파일 업로드 윈도우 창 제어
    const [windowVisible , setWindowVisible] = useState(false);

    // 파일 업로드 
    const [uploadState , setUploadState] = useState({
        files: [],
        events: [],
        filePreviews: {},
        afterStateChange : []
    });

    // 파일 업로드 후 문제 없다면 리스트 변수
    const [uploadDataList , setUploadDataList] = useState([]);

    // 엑셀 파일 이름
    const [fileName , setFileNameExcel] = useState("");
    
    function setFileName(){
        let template = "코드관리_" + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
        return template;
    }

      // 엑셀 업로드 헤더 설정
    const handleUploadHeaders = useCallback( e => {
        e.headers['X-AUTH-TOKEN'] = getToken();
        /* eslint-disable-next-line */
    }, []);

    const setCodeDataList = (dataSource) =>{
        getCodes( conn , dataSource)
        .then(codeDataSource => {
            setDataSource({
                data : codeDataSource
                , editField: undefined
                , changes: false
            });

            setPrevDataSource(codeDataSource);
        })
        .catch(e => {
            alert(getMessage(e.errCode));
        });
    };

    // 데이터 한번 불러오기
    useEffect(() => {
        setCodeDataList(dataSource);
        /* eslint-disable-next-line */
    }, []);

    // 코드 저장 하기
    const saveDataSource = (dataItem) => {
        setCodeList(conn, dataItem)
        .then ( res => {
            kendo.alert("저장을 성공 하였습니다.");
            setCodeDataList()
        })
          .catch ( res => {
              // console.log( "Failed SaveCodeList");
              // kendo.alert("저장을 실패 하였습니다.");
              alert(getMessage(res.errCode));
        });
    }

    // 컬럼 헤더 부분
    // 코드 일련번호
    // const codeSeriNoHeader = () => {
    //     return(
    //         <div>
    //             <span>코드 <br/>일련번호</span>
    //         </div>
    //     );
    // }

    // Cell 렌더링
    let cellRender = (tdElement, cellProps) => {
        // console.log("tdElement = ", tdElement);
        const dataItem = cellProps.dataItem;
        const cellField = cellProps.field;
        // console.log("dataItem = ", dataItem);
        const inEditField = dataItem["inEdit"];
        // console.log("inEditField = ", inEditField);
        const additionalProps =
          cellField && cellField === inEditField
            ? {
                ref: td => {
                  const input = td && td.querySelector("input");
                  const activeElement = document.activeElement;

                  if (
                    !input ||
                    !activeElement ||
                    input === activeElement ||
                    !activeElement.contains(input)
                  ) {
                    return;
                  }
    
                  if (input.type === "checkbox") {
                    input.focus();
                  } else {
                    input.select();
                  }
                }
              }
            : {
                onClick: () => {
                    // Edit 진입
                    console.log("onClick dataItem = " , dataItem);
                    console.log("onClick cellField = " , cellField);
                    enterEdit(dataItem, cellField);
                }
              };
        
        return React.cloneElement(
            tdElement,
            { ...tdElement.props, ...additionalProps },
            tdElement.props.children
        )
    };

    // 이벤트
    const itemChange = (event) => {
        console.log("itemChange");
        event.dataItem.editFlag = true;
        event.dataItem[event.field] = event.value;

        setDataSource({
            data : dataSource.data
            , changes : true
            , editField : event.field
        })

    }

    // 셀 EDIT 진입 시
    const enterEdit = (dataItem, field) => {        
        const data = dataSource.data.map(item => ({            
            ...item
            ,inEdit: item.codeNo === dataItem.codeNo ? field : undefined
            })
        );

        setDataSource({
            data : data
            , editField: field
        });

    }

    // ROW 바깥 클릭 시 발생
    // const exitEdit = (event) => {
    //     console.log("exitEdit = " , event);
    //     const data = dataSource.data.map(item => (
    //         { ...item, inEdit: undefined }
    //     ));
    
    //     setDataSource({
    //         data : data
    //         , editField: undefined
    //     })
    // }

    // 수정 취소시 이벤트
    const cancelChanges = () => {
        console.log("dataSource.data = ", dataSource.data);
        setDataSource({
            data: prevDataSource
            , changes: false
            , editField: dataSource.editField
        });
    }

    // 저장
    const saveEvent = () => {
        console.log("dataSource.data = ", dataSource.data);
        let dataItem = [];
        dataSource.data.forEach(element => {
            if( element.editFlag === true ){
                dataItem.push(element);
            }
        });
        console.log("save dataItem = ", dataItem);
        saveDataSource(dataItem)
    }

    // 추가
    const addChange = () => {
        console.log("addChange");
        const newDataItem = { inEdit: true };
        setDataSource({
            data: [newDataItem , ...dataSource.data]
            , changes: dataSource.changes
            , editField: dataSource.editField
        });

    }

    /**
     * 셀 드롭다운 리스트 
     */
    // Y, N 데이터 소스 
    const useYnDropDownList = CustomFilter(["Y", "N"], "사용여부" , null , null , false);
    
    // Y, N 데이터 드롭다운
    function useYnCellDropDown(value){
        // 드롭다운 CELL 변경 시 이벤트
        const chageUseYn = (event) => {
            value.dataItem.editFlag = true;
            value.dataItem.useYn = event.value;
            setDataSource({
                data : dataSource.data
                , changes : true
                , editField : event.field
            })
        }

        // 랜더링  (기존 값도 가져와서 확인)
        let useYn = value.dataItem.useYn;
        
        // console.log("value.dataItem.inEdit = ", value.dataItem.inEdit);        

        // let inEdit = true;
        // if( typeof value.dataItem.inEdit != "undefined" ){
        //     inEdit = false;
        // }

        if (!value.dataItem.inEdit) {
            // console.log("value.dataItem.inEdit = ", value.dataItem.inEdit);
            return (
                <td  > 
                    {
                        (useYn === null) ? '' : value.dataItem[value.field]
                    }
                </td>
            );
        } else {
            return(
                <td>
                    <DropDownList
                    data = {["Y", "N"]}
                    defaultValue = {useYn}
                    onChange={chageUseYn.bind(this)}
                    />
                </td>
            )
        }
    }

    // function filteDropdown(data, defaultItem) {
    //     return class extends React.Component {
    //         render() {
    //             console.log("data = ", data);
    //             console.log("defaultItem = ", defaultItem);
    //             return (
    //                 <div className="k-filtercell">
    //                     <DropDownList
    //                         data={data}
    //                         onChange={this.onChange}
    //                         value={this.props.value || defaultItem}
    //                         defaultItem={defaultItem}
    //                     />
    //                 </div>
    //             );
    //         }
    //         hasValue = value => Boolean(value && value !== defaultItem);
    //         onChange = event => {
    //             const hasValue = this.hasValue(event.target.value);
    //             this.props.onChange({
    //                 value: hasValue ? event.target.value : '',
    //                 operator: hasValue ? 'eq' : '',
    //                 syntheticEvent: event.syntheticEvent
    //             });
    //         }
    //     };
    // }


    // 액셀 업로드 윈도우 창 제어
    const uploadExcel = () => {
        initialExcelFile();
        setWindowVisible(!windowVisible);
    }

    // 엑셀 업로드 관련 함수 모음 
    // files: [],
    // events: [],
    // filePreviews: {}
    const onAdd = (event) => {
        console.log("onAdd event = ", event);

        // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.
        const afterStateChange = () => {
            event.affectedFiles
                .filter(file => !file.validationErrors)
                .forEach(file => {
                    const reader = new FileReader();

                    reader.onloadend = (ev) => {
                        setUploadState({
                            files: uploadDataList.files,
                            events: uploadDataList.events,
                            filePreviews: {
                                        [file.uid]: ev.target.result
                                    }
                        });
                    };

                    reader.readAsDataURL(file.getRawFile());
                });
        };

        // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.    
        setUploadState({
            // files: uploadDataList.files,
            files: event.newState,
            events: [
                uploadDataList.events
            ] ,
            filePreviews : uploadDataList.filePreviews,
            afterStateChange : afterStateChange
        });

        console.log("uploadStatus = ", uploadState); 
    }
    const onRemove = (event) => {
        console.log("onRemove event = ", event);
    }
    const onProgress = (event) => {
        console.log("onProgress event = ", event);
    }
    const onStatusChange = (event) => {
        console.log("onStatusChange event = ", event);

        // 이걸 안해주면 파일 업로드 로딩이 안끝난다.
        setUploadState({
            files: event.newState
        });

        setUploadDataList(event.response.response);        
    }

    // 엑셀 업로드 취소 , 닫기 누를 시 초기화
    const initialExcelFile = () => {
        setUploadState({
            files: []
        });
        setUploadDataList([]);
    }


    // 업로드 Save
    const uploadSave = () =>{
        setCodeList(conn, uploadDataList.data)
        .then ( res => {
            setCodeDataList()
            uploadExcel();
        })
          .catch ( res => {
              // console.log( "Failed SaveCodeList");
              // kendo.alert("저장을 실패 하였습니다.");
              alert(getMessage(res.errCode));
        });
    }

    // 엑셀 샘플 다운로드
    const downloadSample = () =>{
        const link = document.createElement('a');
        link.href = "/template/meain/코드관리_엑셀업로드샘플.xlsx";
        document.body.appendChild(link);
        link.click();
    }

    // 엑셀 다운로드
    let _export;
    const downloadExcel = () => {
        console.log("Download Excel");
        setFileNameExcel(
            setFileName()
        )
        _export.save();
    }

    // 필터링 
    const filterChange = (event) => {
        setDataSource({
            data: filterBy( prevDataSource, event.filter),
            filter: event.filter
        });
    };

    // 렌더링
    return(
        <div>
            <Button className={styles.codeManageBtn} id='addBtn' onClick = {addChange} icon="add">추가</Button>
            <Button className={styles.codeManageBtn} id='saveBtn' onClick = {saveEvent} icon="save">저장</Button>
            <Button className={styles.codeManageBtn} id='cancelBtn' onClick = {cancelChanges} icon="cancel">취소</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button className={styles.codeManageBtn} onClick = {uploadExcel} icon="upload">엑셀파일 가져오기</Button>
            <Button className={styles.codeManageBtn} icon="download" onClick={downloadSample}>엑셀샘플 다운로드</Button>
            <Button className={styles.codeManageBtn} onClick = {downloadExcel} icon="excel">엑셀 저장</Button>
                <ExcelExport
                    data={dataSource.data}
                    ref={exporter => _export = exporter}
                    fileName = {fileName}
                >
                    <Grid 
                        className = "codeMngtGrid"
                        style={{width: "1800px", height:"740px"}}
                        data={dataSource}
                        onItemChange={itemChange}
                        cellRender={cellRender}
                        editField="inEdit"
                        filterable
                        filter={dataSource.filter}
                        onFilterChange={filterChange}
                    >
                        <Column field="codeNo" title="코드" width="220px" filterable={false}/>
                        <Column field="grpCodeNo" title="코드그룹" width="200px" editor = "numeric"
                        // editable= {false} 
                        filterable={false}/>
                        <Column field="grpCodeVal" title="그룹명" width="260px" editable={false} filterCell={GridColumnInputFilter}/>
                        <Column field="codeSeriNo" title='코드 일련번호' width="200px" editor = "numeric"
                        // editable={false} 
                        filterable={false}/>
                        <Column field="codeVal" title="값" width="260px" filterCell={GridColumnInputFilter}/>
                        <Column field="useYn" title="사용여부" width="200px" cell = {useYnCellDropDown} filterCell={useYnDropDownList}/>
                        <Column field="codeExpl" title="설명" width="450px" filterCell={GridColumnInputFilter}
                        />
                    </Grid>
                </ExcelExport>
            {windowVisible &&
                <Window 
                    title={"엑셀 업로드"} 
                    onClose={uploadExcel} 
                    initialHeight={240} 
                    initialWidth={600} 
                    modal={true}
                    resizable={false}
                >
                    <div>
                        <LocalizationProvider language="ko-KR">
                            <IntlProvider locale="ko">
                                <Upload
                                    batch={false}
                                    multiple={false}
                                    withCredentials={true}
                                    onBeforeUpload={handleUploadHeaders}
                                    onAdd={onAdd}
                                    onRemove={onRemove}
                                    onProgress={onProgress}
                                    onStatusChange={onStatusChange}
                                    restrictions={{
                                        allowedExtensions: [ '.xlsx' ]
                                    }}
                                    files={uploadState.files}
                                    saveUrl={`${process.env.REACT_APP_REST_API_VERSION}/code/xlsx`}
                                    // removeUrl={`${process.env.REACT_APP_REST_API_VERSION}` + '/code/removeFile'}
                                ></Upload>
                            </IntlProvider>
                        </LocalizationProvider>
                        <div>
                            <Button style={{ float: "right", marginLeft: 10, marginTop: 15,}} onClick={uploadExcel}>취소</Button>
                            <Button style={{ float: "right", marginTop: 15,}} onClick={uploadSave}>저장</Button>
                        </div>
                    </div>
            </Window>}
        </div>
    );
}

export default CodeMngt;