// 결정분류 15001 (결정분류별 분석 타입)
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat, roundNum } from '../../../../com/dataUtil';

const ModuleCrystalTypeChart = ( {moduleCrystalDs} ) => {
    
    // 차트 옵션
    const chartConfig = {
        chart: { marginTop: 50, type: 'column' },
        credits: { enabled: false },
        title: { text: null },
        xAxis: { categories: ['최저효율','평균효율','최고효율'], crosshair: true ,labels : { style: { color: '#000000' } } },
        yAxis: {
            min: 0,
            labels: {
              style: { color: '#000000' }
            },
            title: {
                text: '발전효율 (%)',
                rotation: 0,
                y: -20,
                offset: 0,
                align: 'high',
                style: { color: '#000000' }
            },
        },
        tooltip: {
          formatter: function () {
            let msg = `<span style="color:#000000"><b>${this.x}</b'</span><br>`
            
            for(const idx in this.points){
                msg += 
                  `
                    <span style="color:${this.points[idx].point.color}">\u25CF</span>
                    <span style="color:#000000">${this.points[idx].series.name}</span> : <b>${numFormat(roundNum(this.points[idx].y , 2 ))} %</b><br>
                  `
            }

            return msg;
          },
            shared: true,
            useHTML: true
        },
        legend :{backgroundColor:
            (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
            "#FFFFFF",
        },
        plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
              dataLabels: {
                  enabled: true,
                  color : '#000000',
                  style: { textShadow: false },
                  formatter: function() { 
                    return `<span><b>${numFormat(roundNum(this.y , 2))}</b></span>`
                  }
              }
            }
        },
        series: [
            { name: '단결정', data: moduleCrystalDs.singleEfic , color : '#0082ff' }
            , { name: '다결정', data: moduleCrystalDs.polyEfic , color : '#DC143C' }
            , { name: '미분류', data: moduleCrystalDs.nullEfic , color : '#32CD32' }
        ]
    };

    return(
        <div>
            <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
        </div>
    )
}

export default ModuleCrystalTypeChart;