import React from "react";

const GeoSeosanTwo = props => {
  const { mapClickEvt, mapOutEvt, mapOverEvt, /*goDepth, depthMapOverEvt, depthMapOutEvt*/ } = props;
  return (
    <svg
      version="1.1"
      id="레이어_1"
      x="0px"
      y="0px"
      width="1030px"
      height="950px"
      viewBox="0 0 1030 950"
      enable-background="new 0 0 1030 950">
      <polygon
        fill="#999999"
        points="848.785,633.49 845.385,633.49 841.985,630.089 838.569,630.089 835.167,630.089 831.766,626.675 
 824.95,626.675 824.95,623.269 821.541,619.86 818.137,616.46 811.322,609.64 807.916,606.235 804.515,602.833 804.515,599.426 
 801.103,596.03 797.702,592.625 794.289,589.208 790.89,585.8 787.485,582.394 787.485,578.996 784.069,578.996 780.669,578.996 
 777.261,575.592 773.851,572.175 770.451,568.769 767.043,565.365 763.641,565.365 767.043,561.963 767.043,558.563 767.043,555.14 
 770.451,555.14 773.851,548.336 777.261,544.934 780.669,541.525 784.069,538.115 784.069,534.711 787.485,531.301 787.485,527.9 
 787.485,524.5 787.485,521.095 787.485,517.676 787.485,514.271 784.069,514.271 780.669,510.87 780.669,507.466 777.261,507.466 
 777.261,504.061 777.261,500.645 780.669,497.24 784.069,490.436 790.89,483.616 790.89,480.211 790.89,476.806 790.89,473.401 
 787.485,470.001 787.485,466.596 787.485,463.177 787.485,459.776 787.485,456.371 787.485,452.967 787.485,449.562 790.89,449.562 
 790.89,446.15 790.89,442.745 797.702,439.337 797.702,435.937 794.289,435.937 790.89,432.536 790.89,429.116 794.289,425.712 
 794.289,422.31 794.289,418.906 794.289,415.502 790.89,412.097 790.89,408.681 790.89,405.277 790.89,401.872 790.89,398.472 
 790.89,395.067 794.289,391.651 794.289,388.246 794.289,384.842 797.702,381.442 797.702,378.037 794.289,374.617 794.289,371.217 
 794.289,367.812 801.103,367.812 807.916,367.812 807.916,364.411 811.322,364.411 814.733,364.411 814.733,361.003 
 814.733,357.586 818.137,357.586 818.137,354.186 818.137,350.778 818.137,343.976 821.541,343.976 821.541,340.571 824.95,337.153 
 828.35,333.751 828.35,330.346 828.35,326.943 824.95,326.943 824.95,323.538 824.95,320.122 824.95,316.717 821.541,309.912 
 821.541,306.504 824.95,303.092 824.95,299.687 824.95,296.279 828.35,292.878 828.35,289.478 824.95,292.878 821.541,292.878 
 818.137,296.279 811.322,296.279 807.916,296.279 807.916,299.687 804.515,299.687 794.289,299.687 790.89,299.687 787.485,299.687 
 777.261,299.687 773.851,303.092 770.451,303.092 763.641,303.092 760.235,303.092 753.425,306.504 750.009,306.504 
 746.604,309.912 743.203,309.912 739.792,309.912 739.792,313.313 736.39,313.313 732.987,313.313 729.573,313.313 726.173,313.313 
 722.761,316.717 719.358,316.717 715.955,316.717 712.544,316.717 712.544,313.313 715.955,309.912 719.358,309.912 
 719.358,306.504 722.761,303.092 726.173,299.687 726.173,296.279 729.573,296.279 729.573,292.878 729.573,289.478 
 732.987,289.478 732.987,286.073 736.39,282.654 732.987,279.252 736.39,275.847 736.39,272.444 739.792,272.444 739.792,269.039 
 739.792,265.627 739.792,262.218 743.203,258.814 739.792,258.814 739.792,255.414 739.792,252.012 739.792,248.593 
 743.203,248.593 746.604,248.593 746.604,245.188 750.009,245.188 750.009,241.787 746.604,238.379 743.203,238.379 
 739.792,238.379 736.39,238.379 732.987,238.379 729.573,234.979 726.173,234.979 722.761,234.979 719.358,234.979 715.955,234.979 
 712.544,231.574 709.142,228.158 705.739,228.158 705.739,224.754 702.326,224.754 698.926,224.754 698.926,221.349 
 698.926,217.948 695.517,217.948 695.517,214.544 695.517,211.128 695.517,207.723 695.517,204.319 695.517,200.918 
 692.105,197.513 692.105,194.094 692.105,190.693 692.105,187.288 692.105,183.88 692.105,180.48 688.709,177.079 688.709,173.663 
 685.304,170.255 681.892,170.255 678.488,166.853 675.074,166.853 671.679,163.453 668.273,160.045 664.862,160.045 
 658.052,156.629 651.24,153.224 644.427,149.82 644.427,146.42 644.427,143.015 644.427,139.599 644.427,136.194 641.022,132.79 
 641.022,129.389 641.022,125.981 637.61,122.564 637.61,119.164 637.61,112.355 637.61,108.954 634.21,105.55 630.801,102.131 
 630.801,95.324 634.21,91.92 634.21,88.519 630.801,88.519 627.396,85.1 623.991,81.696 623.991,78.294 620.58,78.294 
 613.771,71.485 613.771,68.07 610.363,64.665 610.363,61.26 606.962,64.665 603.549,61.26 600.144,61.26 589.932,68.07 
 589.932,71.485 586.522,78.294 583.115,85.1 583.115,88.519 579.71,91.92 576.298,98.729 576.298,102.131 576.298,105.55 
 572.897,108.954 566.084,108.954 562.681,112.355 559.276,112.355 555.864,112.355 555.864,115.756 549.05,119.164 545.649,119.164 
 545.649,122.564 545.649,125.981 542.246,136.194 538.834,143.015 535.43,146.42 532.028,149.82 528.616,153.224 521.804,156.629 
 518.398,160.045 518.398,156.629 514.994,156.629 511.582,153.224 508.185,149.82 508.185,146.42 504.781,143.015 504.781,139.599 
 504.781,136.194 501.368,132.79 497.963,132.79 497.963,129.389 494.552,129.389 491.151,129.389 487.75,125.981 480.933,122.564 
 477.528,119.164 470.716,125.981 467.305,125.981 463.903,125.981 460.499,129.389 457.086,129.389 450.278,129.389 
 446.869,125.981 446.869,122.564 443.469,125.981 433.248,136.194 429.84,136.194 426.437,136.194 419.621,139.599 416.218,136.194 
 409.408,132.79 409.408,136.194 405.999,132.79 402.592,129.389 402.592,125.981 402.592,122.564 399.187,115.756 395.776,112.355 
 392.37,112.355 388.97,112.355 385.56,112.355 385.56,119.164 382.157,122.564 378.752,122.564 375.341,122.564 375.341,115.756 
 371.935,112.355 371.935,108.954 371.935,105.55 368.528,105.55 365.122,105.55 361.723,102.131 358.309,102.131 354.906,102.131 
 354.906,98.729 351.504,98.729 348.093,91.92 344.692,91.92 341.281,91.92 337.875,91.92 327.655,88.519 317.439,88.519 
 314.028,88.519 310.623,88.519 307.226,88.519 303.815,85.1 300.406,81.696 297.006,81.696 293.594,81.696 293.594,78.294 
 286.781,81.696 283.377,81.696 279.974,85.1 279.974,88.519 276.563,88.519 276.563,91.92 273.158,91.92 269.755,95.324 
 269.755,98.729 269.755,102.131 266.343,105.55 262.946,108.954 262.946,112.355 259.533,115.756 252.724,122.564 249.312,122.564 
 245.911,122.564 239.098,125.981 232.282,122.564 225.475,125.981 225.475,129.389 222.069,129.389 218.664,132.79 215.259,136.194 
 211.847,136.194 208.447,139.599 208.447,143.015 208.447,146.42 208.447,149.82 205.034,153.224 205.034,156.629 201.634,163.453 
 201.634,166.853 205.034,173.663 208.447,173.663 211.847,177.079 218.664,187.288 222.069,190.693 225.475,197.513 
 239.098,200.918 256.129,207.723 256.129,211.128 259.533,211.128 259.533,214.544 262.946,214.544 262.946,217.948 
 262.946,224.754 262.946,231.574 266.343,234.979 262.946,234.979 262.946,241.787 266.343,241.787 266.343,245.188 
 266.343,248.593 269.755,248.593 269.755,252.012 269.755,255.414 273.158,258.814 273.158,262.218 273.158,265.627 
 273.158,269.039 273.158,272.444 279.974,272.444 283.377,279.252 283.377,282.654 286.781,286.073 286.781,289.478 
 290.194,289.478 290.194,292.878 290.194,296.279 290.194,299.687 290.194,303.092 286.781,306.504 286.781,309.912 
 290.194,309.912 290.194,306.504 293.594,306.504 293.594,303.092 297.006,299.687 297.006,296.279 300.406,296.279 
 303.815,296.279 303.815,292.878 307.226,292.878 310.623,292.878 314.028,292.878 314.028,289.478 317.439,289.478 
 320.845,289.478 324.258,289.478 324.258,286.073 327.655,286.073 327.655,282.654 331.058,282.654 331.058,279.252 
 334.471,279.252 334.471,275.847 337.875,275.847 337.875,272.444 341.281,272.444 341.281,269.039 344.692,269.039 
 348.093,269.039 351.504,269.039 354.906,269.039 358.309,269.039 361.723,272.444 365.122,272.444 368.528,272.444 
 371.935,272.444 375.341,272.444 375.341,275.847 378.752,275.847 382.157,275.847 385.56,279.252 388.97,279.252 392.37,282.654 
 395.776,282.654 399.187,286.073 395.776,286.073 392.37,286.073 392.37,289.478 388.97,292.878 385.56,296.279 385.56,299.687 
 382.157,296.279 378.752,296.279 375.341,299.687 375.341,303.092 371.935,306.504 368.528,306.504 368.528,309.912 
 368.528,313.313 368.528,316.717 368.528,323.538 365.122,323.538 361.723,323.538 358.309,326.943 354.906,330.346 
 351.504,333.751 348.093,337.153 348.093,340.571 348.093,343.976 348.093,347.377 348.093,350.778 348.093,354.186 
 344.692,361.003 344.692,364.411 344.692,367.812 341.281,378.037 337.875,378.037 337.875,381.442 334.471,381.442 
 331.058,384.842 331.058,388.246 334.471,391.651 334.471,395.067 337.875,395.067 341.281,401.872 337.875,405.277 
 334.471,405.277 331.058,405.277 331.058,408.681 327.655,408.681 324.258,405.277 320.845,405.277 317.439,405.277 
 320.845,408.681 320.845,412.097 324.258,412.097 324.258,415.502 324.258,418.906 324.258,422.31 320.845,422.31 317.439,422.31 
 317.439,425.712 314.028,425.712 310.623,425.712 310.623,429.116 310.623,432.536 310.623,435.937 314.028,435.937 
 314.028,439.337 317.439,439.337 320.845,442.745 320.845,446.15 324.258,446.15 324.258,449.562 327.655,452.967 327.655,456.371 
 331.058,456.371 334.471,459.776 334.471,463.177 337.875,463.177 337.875,466.596 337.875,470.001 341.281,470.001 
 341.281,473.401 344.692,473.401 344.692,476.806 344.692,480.211 348.093,480.211 348.093,483.616 351.504,483.616 
 351.504,487.035 351.504,490.436 354.906,493.836 358.309,497.24 358.309,493.836 361.723,493.836 365.122,493.836 368.528,493.836 
 368.528,490.436 371.935,490.436 371.935,493.836 375.341,493.836 375.341,497.24 375.341,500.645 375.341,504.061 375.341,507.466 
 375.341,510.87 378.752,510.87 378.752,514.271 382.157,514.271 385.56,517.676 388.97,521.095 388.97,524.5 388.97,527.9 
 392.37,531.301 395.776,534.711 399.187,538.115 399.187,541.525 399.187,544.934 402.592,548.336 402.592,551.74 405.999,551.74 
 402.592,555.14 402.592,558.563 402.592,561.963 399.187,561.963 399.187,565.365 399.187,568.769 395.776,568.769 395.776,572.175 
 395.776,575.592 392.37,578.996 392.37,582.394 392.37,585.8 392.37,589.208 392.37,592.625 392.37,596.03 392.37,599.426 
 388.97,599.426 388.97,602.833 388.97,606.235 392.37,606.235 392.37,609.64 388.97,609.64 385.56,609.64 385.56,613.059 
 382.157,613.059 382.157,616.46 378.752,616.46 378.752,619.86 378.752,623.269 382.157,623.269 382.157,626.675 385.56,623.269 
 388.97,623.269 388.97,626.675 388.97,630.089 388.97,633.49 388.97,636.894 392.37,636.894 392.37,640.3 392.37,643.7 
 392.37,647.123 392.37,650.522 388.97,650.522 392.37,653.925 392.37,657.329 392.37,660.733 388.97,660.733 388.97,664.138 
 388.97,670.96 388.97,674.358 392.37,681.168 392.37,684.589 392.37,687.985 392.37,694.797 392.37,698.2 395.776,701.623 
 395.776,705.022 395.776,708.425 395.776,715.232 399.187,722.053 399.187,725.454 399.187,728.857 399.187,732.263 
 399.187,739.087 402.592,742.484 402.592,745.892 402.592,749.293 402.592,752.698 402.592,756.114 405.999,759.521 
 405.999,762.918 405.999,766.323 405.999,769.73 405.999,773.147 405.999,776.552 409.408,779.954 409.408,783.355 409.408,786.759 
 409.408,790.164 409.408,793.583 409.408,796.982 412.808,803.793 412.808,807.198 412.808,810.612 412.808,814.021 
 412.808,817.418 416.218,824.223 416.218,831.047 416.218,837.852 419.621,844.662 419.621,848.081 419.621,858.291 
 423.033,865.112 450.278,854.882 450.278,858.291 453.686,861.69 453.686,865.112 457.086,871.916 457.086,875.321 460.499,878.723 
 460.499,882.146 463.903,885.547 463.903,892.352 463.903,895.757 467.305,899.162 467.305,902.58 470.716,905.984 470.716,912.787 
 474.117,916.19 474.117,919.609 477.528,929.815 480.933,923.01 528.616,885.547 535.43,878.723 542.246,861.69 552.459,834.446 
 562.681,803.793 566.084,796.982 579.71,790.164 586.522,783.355 596.74,766.323 617.175,756.114 623.991,756.114 644.427,769.73 
 658.052,769.73 661.457,769.73 675.074,766.323 698.926,773.147 702.326,773.147 709.142,773.147 712.544,756.114 715.955,742.484 
 719.358,742.484 732.987,735.668 753.425,749.293 767.043,745.892 770.451,745.892 773.851,745.892 777.261,742.484 
 780.669,735.668 787.485,728.857 790.89,728.857 790.89,725.454 794.289,725.454 794.289,722.053 797.702,722.053 801.103,722.053 
 801.103,718.633 801.103,715.232 804.515,715.232 804.515,711.825 804.515,708.425 807.916,708.425 811.322,705.022 
 811.322,701.623 814.733,698.2 814.733,694.797 818.137,691.392 818.137,687.985 821.541,687.985 821.541,684.589 824.95,681.168 
 824.95,677.763 828.35,674.358 828.35,670.96 828.35,667.557 828.35,664.138 831.766,664.138 831.766,660.733 835.167,660.733 
 835.167,657.329 838.569,653.925 841.985,653.925 841.985,650.522 841.985,647.123 845.385,647.123 845.385,643.7 848.785,643.7 
 848.785,640.3 852.198,636.894 852.198,633.49 "
      />
      <g>
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421011100"
          class="land land_A"
          fill="#E1E1E1"
          d="M546.142,613.117c-0.05-0.154-0.076-0.313-0.076-0.476v-6.184l-6.375-6.381
     c-0.279-0.281-0.438-0.662-0.438-1.062v-27.237c0-0.101,0.01-0.197,0.029-0.295l3.379-16.89v-35.82h-22.343
     c-0.828,0-1.5-0.672-1.5-1.5v-15.526h-22.347c-0.828,0-1.5-0.671-1.5-1.5v-5.322h-5.31c-0.397,0-0.778-0.158-1.061-0.438
     l-2.973-2.968h-2.776c-0.396,0-0.777-0.156-1.06-0.438l-2.967-2.962h-6.196c-0.397,0-0.778-0.158-1.061-0.439l-2.972-2.965h-9.582
     h-0.012c-0.001,0-0.002,0-0.004,0c-0.023-0.001-0.046-0.001-0.067-0.002c-0.015-0.001-0.026-0.008-0.04-0.009
     c-0.161-0.014-0.319-0.044-0.467-0.105c-0.002-0.001-0.004-0.003-0.006-0.004c-0.142-0.059-0.269-0.145-0.386-0.244
     c-0.011-0.009-0.025-0.014-0.037-0.022c-0.002-0.002-0.003-0.003-0.004-0.003c-0.001-0.002,0-0.004-0.004-0.004
     c-0.002-0.002-0.001-0.002-0.004-0.004c0,0-0.004-0.002-0.003-0.004c-0.002-0.001-0.002-0.002-0.004-0.003
     c0.001-0.001-0.003-0.002-0.003-0.004c-0.002,0,0.001,0-0.005-0.002c0.002,0,0-0.002-0.003-0.004
     c-0.002-0.001-0.004-0.002-0.003-0.003c-0.003-0.002-0.002-0.003-0.004-0.003c0-0.002-0.002-0.002-0.003-0.004
     c-0.002-0.001-0.002-0.002-0.004-0.003c0-0.001-0.005-0.001-0.003-0.003c-0.002-0.002-0.003-0.004-0.003-0.004
     c-0.001,0,0.001,0-0.005-0.003c0-0.001-0.002-0.003-0.003-0.003l-2.965-2.966h-8.097v8.709c0,0.398-0.158,0.781-0.44,1.063
     l-2.971,2.965v13.004c0,0.828-0.672,1.5-1.5,1.5h-1.901v1.908c0,0.828-0.672,1.5-1.5,1.5h-1.903v1.904
     c0,0.397-0.158,0.778-0.439,1.061l-2.974,2.973v6.188c0,0.398-0.157,0.779-0.438,1.061l-0.856,0.857
     c0.521,0.072,0.976,0.416,1.182,0.912c0.232,0.561,0.104,1.207-0.325,1.635l-2.96,2.961v15.795l2.963,2.979
     c0.279,0.278,0.438,0.66,0.438,1.057v29.146h1.913c0.828,0,1.5,0.672,1.5,1.5v17.032c0,0.828-0.672,1.5-1.5,1.5h-12.13v1.906
     c0,0.828-0.672,1.5-1.5,1.5h-13.621c-0.828,0-1.5-0.672-1.5-1.5v-1.906h-3.812v1.906c0,0.828-0.672,1.5-1.5,1.5h-2.782
     l-2.974,2.976c-0.28,0.28-0.662,0.438-1.061,0.438h-1.903v25.74c0,0.715-0.505,1.33-1.206,1.471l-17.03,3.404
     c-0.097,0.02-0.194,0.029-0.294,0.029h-6.816h-1.9v11.771l3.242,6.492c0.104,0.209,0.158,0.438,0.158,0.67v16.414l2.969,2.984
     c0.279,0.28,0.437,0.659,0.437,1.057v13.256l3.254,6.504c0.104,0.209,0.158,0.438,0.158,0.672v16.41l2.964,2.959
     c0.282,0.281,0.44,0.664,0.44,1.063v13.006l2.968,2.967c0.281,0.281,0.439,0.664,0.439,1.061v16.41l2.969,2.961
     c0.282,0.281,0.439,0.664,0.439,1.063v16.676l3.242,6.492c0.104,0.209,0.158,0.438,0.158,0.67v13.271l3.25,6.488
     c0.104,0.209,0.159,0.438,0.159,0.672v13.273l3.245,6.493c0.104,0.207,0.157,0.438,0.157,0.671v13.274l2.649,5.297l25.979-9.756
     c0.459-0.174,0.977-0.108,1.382,0.17c0.403,0.281,0.646,0.742,0.646,1.234v2.787l2.969,2.961c0.282,0.281,0.44,0.662,0.44,1.061
     v3.068l3.241,6.486c0.104,0.209,0.157,0.438,0.157,0.67v2.782l2.973,2.964c0.282,0.28,0.44,0.664,0.44,1.063v2.801l2.965,2.961
     c0.281,0.281,0.439,0.662,0.439,1.062v9.59l2.963,2.965c0.28,0.283,0.438,0.664,0.438,1.062v2.797l2.971,2.965
     c0.282,0.281,0.439,0.664,0.439,1.063v6.181l2.964,2.965c0.279,0.281,0.438,0.664,0.438,1.061v3.177l2.188,6.545l1.787-3.572
     c0.101-0.197,0.241-0.371,0.415-0.508l47.611-37.408l6.533-6.543l6.702-16.746l10.201-27.213l10.202-30.604
     c0.022-0.065,0.051-0.133,0.081-0.194l3.079-6.162l-23.53-50.435c-0.094-0.198-0.142-0.416-0.142-0.635v-30.661
     c0-0.271,0.074-0.539,0.214-0.771l10.213-17.033c0.063-0.104,0.14-0.203,0.227-0.291l10.222-10.213
     c0.281-0.281,0.663-0.439,1.062-0.439c0,0,0,0,0.001,0c0.396,0,0.778,0.158,1.061,0.439l0.842,0.844v-3.18
     c0-0.191,0.036-0.381,0.107-0.56l6.813-17.03c0.074-0.187,0.187-0.357,0.328-0.5l3.152-3.17l3.104-9.289l-3.133-6.244
     c-0.031-0.064-0.06-0.131-0.083-0.199L546.142,613.117z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421010900"
          class="land land_B"
          fill="#E1E1E1"
          d="M444.323,475.334l2.963-2.966v-13.003c0-0.829,0.672-1.5,1.5-1.5h5.315v-15.52
     c0-0.397,0.157-0.777,0.438-1.059l0.868-0.872c-0.736-0.095-1.307-0.725-1.307-1.488c0-0.829,0.672-1.5,1.5-1.5h1.904v-1.902
     c0-0.398,0.158-0.78,0.44-1.062l0.857-0.856c-0.732-0.099-1.299-0.727-1.299-1.487v-44.273c0-0.829,0.672-1.5,1.5-1.5h1.912v-3.821
     h-1.912c-0.828,0-1.5-0.671-1.5-1.5v-29.159h-1.904c-0.827,0-1.5-0.671-1.5-1.5s0.673-1.5,1.5-1.5h1.904v-10.614h-1.904
     c-0.827,0-1.5-0.671-1.5-1.5v-3.417v-1.9h-14.029v1.9c0,0.829-0.673,1.5-1.5,1.5h-5.317v1.917c0,0.829-0.672,1.5-1.5,1.5h-3.399
     c-0.827,0-1.5-0.671-1.5-1.5v-1.917h-1.909c-0.396,0-0.777-0.158-1.06-0.438l-2.969-2.962h-8.977l-2.966,2.961
     c-0.584,0.585-1.533,0.584-2.119,0l-6.64-6.637l-6.281-3.134h-16.676c-0.828,0-1.5-0.671-1.5-1.5v-5.879l-5.672-2.845h-6.45
     c-0.828,0-1.5-0.671-1.5-1.5V311h-1.909c-0.828,0-1.5-0.671-1.5-1.5v-2.784l-2.523-2.525h-6.195c-0.568,0-1.088-0.321-1.342-0.83
     l-3.297-6.602l-0.676-0.674v3.201c0,0.829-0.672,1.5-1.5,1.5h-6.185l-12.75,12.737v16.411c0,0.232-0.054,0.462-0.158,0.67
     l-3.243,6.5v6.455c0,0.161-0.025,0.322-0.077,0.475l-3.41,10.225c-0.204,0.612-0.777,1.025-1.424,1.025h-1.904v1.905
     c0,0.829-0.672,1.5-1.5,1.5h-2.784l-2.532,2.523v2.16l2.972,2.965c0.282,0.282,0.44,0.664,0.44,1.062v1.916h1.904
     c0.567,0,1.087,0.321,1.342,0.829l2.99,5.976h2.483c0.828,0,1.5,0.671,1.5,1.5c0,0.829-0.672,1.5-1.5,1.5h-2.79l-2.966,2.965
     c-0.281,0.281-0.663,0.439-1.061,0.439h-5.316v1.905c0,0.829-0.672,1.5-1.5,1.5h-3.404c-0.397,0-0.78-0.159-1.062-0.44
     l-2.957-2.964h-2.574l0.844,0.844c0.281,0.281,0.439,0.663,0.439,1.061v1.916h1.913c0.827,0,1.5,0.671,1.5,1.5v10.213
     c0,0.829-0.673,1.5-1.5,1.5h-5.318v1.901c0,0.829-0.672,1.5-1.5,1.5h-5.316v7.225h1.905c0.828,0,1.5,0.671,1.5,1.5v1.9h1.911
     c0.397,0,0.779,0.158,1.062,0.44l3.405,3.408c0.28,0.281,0.438,0.663,0.438,1.06v1.905h1.913c0.827,0,1.5,0.671,1.5,1.5v2.792
     l2.958,2.965c0.28,0.281,0.438,0.663,0.438,1.06v1.904h1.904c0.396,0,0.777,0.158,1.06,0.438l3.412,3.405
     c0.282,0.282,0.44,0.664,0.44,1.062v1.902h1.904c0.827,0,1.5,0.671,1.5,1.5v5.323h1.904c0.828,0,1.5,0.671,1.5,1.5v1.901h1.911
     c0.828,0,1.5,0.671,1.5,1.5v5.31h1.901c0.828,0,1.5,0.671,1.5,1.5v1.905h1.911c0.827,0,1.5,0.671,1.5,1.5v5.32h1.9
     c0.828,0,1.5,0.671,1.5,1.5c0,0.758-0.563,1.385-1.293,1.486l1.711,1.711c0.101-0.732,0.728-1.297,1.486-1.297h8.718v-1.9
     c0-0.829,0.672-1.5,1.5-1.5h3.408c0.828,0,1.5,0.671,1.5,1.5v1.9h1.905c0.828,0,1.5,0.671,1.5,1.5v12.13h3.815v-1.904
     c0-0.829,0.672-1.5,1.5-1.5h1.903v-1.916c0-0.829,0.672-1.5,1.5-1.5h3.055l6.489-3.247c0.208-0.104,0.438-0.159,0.671-0.159h3.06
     l5.665-2.832v-2.473c0-0.829,0.672-1.5,1.5-1.5h5.31v-1.902c0-0.606,0.365-1.153,0.925-1.386c0.563-0.23,1.205-0.104,1.636,0.324
     l3.408,3.402c0.166,0.165,0.291,0.366,0.363,0.587l3.063,9.184h29.574c0.828,0,1.5,0.671,1.5,1.5v1.916h17.438v-1.916
     C443.885,475.996,444.043,475.615,444.323,475.334z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421010700"
          class="land land_C"
          fill="#E1E1E1"
          d="M595.249,304.191h-1.905v1.904c0,0.829-0.672,1.5-1.5,1.5h-8.713v1.905c0,0.829-0.672,1.5-1.5,1.5h-13.008
     l-5.933,5.94v2.785c0,0.829-0.672,1.5-1.5,1.5h-6.813c-0.762,0-1.39-0.567-1.487-1.302l-0.865,0.864
     c-0.28,0.28-0.662,0.438-1.06,0.438h-9.593l-2.965,2.961c-0.586,0.585-1.533,0.585-2.119,0l-6.379-6.367h-6.192
     c-0.828,0-1.5-0.671-1.5-1.5V314.4h-11.497l-2.962,2.977c-0.282,0.283-0.664,0.442-1.063,0.442h-8.724v1.905
     c0,0.829-0.672,1.5-1.5,1.5h-6.188l-6.367,6.362c-0.281,0.282-0.663,0.44-1.063,0.44h-15.535v0.408h1.901
     c0.828,0,1.5,0.671,1.5,1.5v8.725h1.911c0.828,0,1.5,0.671,1.5,1.5v2.779l2.966,2.966c0.281,0.281,0.438,0.663,0.438,1.061v1.901
     h1.912c0.828,0,1.5,0.671,1.5,1.5v1.919h1.905c0.828,0,1.5,0.671,1.5,1.5v1.905h1.896c0.827,0,1.5,0.671,1.5,1.5v1.9h1.911
     c0.828,0,1.5,0.671,1.5,1.5v1.905h1.904c0.828,0,1.5,0.671,1.5,1.5v1.905h1.905c0.828,0,1.5,0.671,1.5,1.5v1.916h1.911
     c0.828,0,1.5,0.671,1.5,1.5v1.905h1.9c0.828,0,1.5,0.671,1.5,1.5v1.9h1.911c0.828,0,1.5,0.671,1.5,1.5v1.905h1.902
     c0.828,0,1.5,0.671,1.5,1.5v1.905h1.904c0.397,0,0.78,0.158,1.062,0.44l3.412,3.416c0.28,0.281,0.438,0.663,0.438,1.06v1.905h1.902
     c0.828,0,1.5,0.671,1.5,1.5v1.904h1.9c0.828,0,1.5,0.671,1.5,1.5v1.904h1.909c0.828,0,1.5,0.671,1.5,1.5v1.901h1.904
     c0.827,0,1.5,0.671,1.5,1.5v1.905h1.911c0.828,0,1.5,0.671,1.5,1.5v5.32h1.905c0.397,0,0.779,0.158,1.062,0.439l2.964,2.961h6.191
     c0.828,0,1.5,0.671,1.5,1.5v1.908h5.313c0.828,0,1.5,0.671,1.5,1.5v2.479l5.667,2.838h3.055c0.828,0,1.5,0.671,1.5,1.5v1.905h1.901
     c0.828,0,1.5,0.671,1.5,1.5v1.904h1.907c0.828,0,1.5,0.671,1.5,1.5v1.905h1.903c0.828,0,1.5,0.671,1.5,1.5v0.977l4.43-2.21
     l3.228-3.232c0.114-0.114,0.246-0.209,0.391-0.282l6.596-3.295l10.05-10.049c0.281-0.281,0.663-0.439,1.062-0.439h2.782l2.53-2.53
     v-29.413l-2.969-2.965c-0.282-0.282-0.44-0.664-0.44-1.062V360.59c0-0.398,0.158-0.781,0.44-1.062l2.969-2.96v-2.782
     c0-0.829,0.672-1.5,1.5-1.5h2.777l2.974-2.979c0.114-0.115,0.246-0.21,0.391-0.282l27.252-13.614
     c0.208-0.104,0.438-0.158,0.671-0.158h3.051l19.894-9.958l3.241-3.231c0.281-0.28,0.661-0.438,1.059-0.438h2.787l2.961-2.961
     c0.281-0.281,0.663-0.439,1.062-0.439h2.791l2.524-2.526v-16.413c0-0.829,0.673-1.5,1.5-1.5h1.901v-0.416h-7.218v1.916
     c0,0.829-0.672,1.5-1.5,1.5h-9.6l-2.957,2.964c-0.28,0.282-0.662,0.44-1.062,0.44h-2.785l-2.972,2.966
     c-0.281,0.28-0.662,0.438-1.061,0.438h-2.785l-2.974,2.967c-0.281,0.28-0.662,0.438-1.06,0.438h-17.022
     c-0.232,0-0.462-0.054-0.671-0.158l-13.309-6.65h-9.862c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-10.63v1.905
     C596.749,303.52,596.077,304.191,595.249,304.191z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421010200"
          class="land land_D"
          fill="#E1E1E1"
          d="M450.286,210.727c0,0.161-0.026,0.321-0.077,0.474l-3.108,9.331l6.158,6.169
     c0.113,0.114,0.208,0.245,0.279,0.388l3.405,6.801c0.104,0.208,0.158,0.438,0.158,0.671v3.405c0,0.397-0.157,0.779-0.438,1.06
     l-4.271,4.273c0.735,0.096,1.305,0.726,1.305,1.487v5.308h1.904c0.828,0,1.5,0.671,1.5,1.5v18.932h1.904c0.828,0,1.5,0.671,1.5,1.5
     s-0.672,1.5-1.5,1.5h-1.904v3.813h1.904c0.828,0,1.5,0.671,1.5,1.5v12.125h1.912c0.828,0,1.5,0.671,1.5,1.5v18.935h1.9
     c0.828,0,1.5,0.671,1.5,1.5v12.125h14.912l6.372-6.364c0.281-0.28,0.662-0.438,1.06-0.438h5.31v-1.905c0-0.829,0.672-1.5,1.5-1.5
     h9.602l2.962-2.977c0.281-0.283,0.664-0.442,1.063-0.442h13.62c0.828,0,1.5,0.671,1.5,1.5v1.919h5.313
     c0.396,0,0.777,0.158,1.06,0.438l5.758,5.747l2.344-2.341c0.28-0.281,0.663-0.439,1.061-0.439h9.594l2.974-2.967
     c0.106-0.107,0.229-0.195,0.357-0.264c0.39-0.206,0.855-0.234,1.276-0.06c0.56,0.232,0.925,0.779,0.925,1.386v1.905h3.813v-1.905
     c0-0.397,0.157-0.778,0.438-1.059l6.813-6.822c0.281-0.281,0.663-0.438,1.061-0.438h12.129v-1.905c0-0.829,0.672-1.5,1.5-1.5h8.713
     v-1.904c0-0.829,0.673-1.5,1.5-1.5h1.905v-0.405h-5.309c-0.828,0-1.5-0.671-1.5-1.5v-2.488l-5.664-2.832h-3.059
     c-0.397,0-0.779-0.158-1.062-0.439l-2.965-2.965h-2.783c-0.396,0-0.777-0.158-1.06-0.438l-2.968-2.962h-6.19
     c-0.828,0-1.5-0.671-1.5-1.5v-1.908h-1.908c-0.828,0-1.5-0.671-1.5-1.5s0.672-1.5,1.5-1.5h1.908v-0.412h-1.908
     c-0.828,0-1.5-0.671-1.5-1.5v-3.405c0-0.829,0.672-1.5,1.5-1.5h1.908v-1.908c0-0.829,0.672-1.5,1.5-1.5h1.909v-7.206h-1.909
     c-0.828,0-1.5-0.671-1.5-1.5v-10.226c0-0.398,0.158-0.78,0.44-1.062l2.969-2.964v-13.006v-8.72h-1.909c-0.828,0-1.5-0.671-1.5-1.5
     v-1.905h-1.908c-0.828,0-1.5-0.671-1.5-1.5v-5.309h-5.315c-0.828,0-1.5-0.671-1.5-1.5v-3.047l-3.135-6.285l-3.231-3.232
     c-0.281-0.281-0.439-0.663-0.439-1.061v-1.905h-1.908c-0.396,0-0.778-0.158-1.061-0.439l-6.81-6.806
     c-0.28-0.281-0.438-0.663-0.438-1.061v-12.125h-1.912c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-1.904c-0.827,0-1.5-0.671-1.5-1.5v-2.796
     l-2.969-2.962c-0.282-0.282-0.439-0.664-0.439-1.062v-2.905l-9.916-13.226c-0.055-0.072-0.103-0.149-0.143-0.229l-2.989-5.98
     h-2.476c-0.646,0-1.219-0.413-1.423-1.025l-3.411-10.224c-0.051-0.153-0.077-0.313-0.077-0.475V131.4l-4.423,2.21l-2.795,2.803
     v2.789c0,0.569-0.321,1.088-0.83,1.342l-6.598,3.291l-9.604,9.613v6.181c0,0.568-0.321,1.087-0.829,1.342l-5.986,2.994v1.855
     l2.971,2.962c0.282,0.281,0.439,0.664,0.439,1.062v10.224c0,0.829-0.672,1.5-1.5,1.5h-2.482l-2.829,5.664v3.062
     c0,0.829-0.672,1.5-1.5,1.5h-2.78l-9.781,9.771c-0.586,0.586-1.533,0.586-2.119,0.001l-0.853-0.85v10.011H450.286z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421011300"
          class="land land_E"
          fill="#E1E1E1"
          d="M754.278,555.806l-13.188-13.193h-2.159l-2.968,2.968c-0.114,0.112-0.246,0.207-0.391,0.278l-6.81,3.406
     c-0.208,0.104-0.438,0.158-0.671,0.158h-2.794l-6.366,6.381c-0.281,0.281-0.663,0.439-1.062,0.439h-6.192l-2.965,2.961
     c-0.281,0.28-0.662,0.438-1.061,0.438h-8.726v1.906c0,0.828-0.672,1.5-1.5,1.5h-5.306v1.908c0,0.828-0.672,1.5-1.5,1.5h-3.057
     l-12.474,6.248v2.471c0,0.828-0.672,1.5-1.5,1.5h-2.788l-2.532,2.527v2.78c0,0.828-0.672,1.5-1.5,1.5h-6.182l-2.531,2.527v2.797
     c0,0.828-0.672,1.5-1.5,1.5h-2.785l-5.929,5.922v2.787c0,0.398-0.158,0.779-0.439,1.062l-6.378,6.381v2.778
     c0,0.828-0.672,1.5-1.5,1.5h-2.782l-5.936,5.933v2.778c0,0.396-0.157,0.775-0.437,1.058l-6.38,6.389v2.779
     c0,0.828-0.672,1.5-1.5,1.5h-1.9v1.904c0,0.827-0.672,1.5-1.5,1.5h-6.817c-0.397,0-0.78-0.158-1.062-0.441l-2.96-2.963h-2.787
     c-0.397,0-0.779-0.158-1.062-0.439l-3.23-3.229l-6.282-3.135h-6.455c-0.828,0-1.5-0.673-1.5-1.5v-1.923h-3.81v1.923
     c0,0.827-0.672,1.5-1.5,1.5h-28.233l2.322,4.629c0.179,0.356,0.208,0.77,0.082,1.147l-3.412,10.215
     c-0.073,0.22-0.196,0.418-0.359,0.582l-3.185,3.201l-6.593,16.48v6.514c0,0.606-0.365,1.154-0.927,1.387
     c-0.073,0.031-0.152,0.039-0.229,0.059c-0.067,0.016-0.133,0.043-0.201,0.05c-0.438,0.042-0.882-0.111-1.204-0.435l-2.343-2.346
     l-9.029,9.023l-9.904,16.518v29.912l23.046,49.393l12.029-6.021l6.51-6.506l10.123-16.873c0.146-0.246,0.36-0.443,0.616-0.57
     l20.436-10.209c0.208-0.104,0.438-0.158,0.67-0.158h6.815c0.297,0,0.586,0.088,0.832,0.252l20.059,13.365h16.392l13.438-3.363
     c0.257-0.063,0.522-0.061,0.776,0.013l23.648,6.768h8.776l3.16-15.828l3.428-13.698c0.167-0.668,0.767-1.136,1.455-1.136h1.901
     v-4.696l-2.963-2.966c-0.281-0.28-0.438-0.661-0.438-1.061v-5.31h-5.313c-0.828,0-1.5-0.672-1.5-1.5v-1.899h-1.904
     c-0.828,0-1.5-0.672-1.5-1.5v-2.799l-2.971-2.959c-0.282-0.281-0.441-0.664-0.441-1.063v-3.408c0-0.828,0.673-1.5,1.5-1.5h1.912
     v-1.899c0-0.828,0.672-1.5,1.5-1.5h1.904v-1.902c0-0.828,0.672-1.5,1.5-1.5h2.781l2.973-2.963c0.28-0.279,0.661-0.438,1.06-0.438
     h1.901v-5.325c0-0.828,0.672-1.5,1.5-1.5h1.903v-0.404h-1.903c-0.828,0-1.5-0.672-1.5-1.5v-3.406c0-0.828,0.672-1.5,1.5-1.5h1.903
     v-8.723c0-0.828,0.672-1.5,1.5-1.5h1.912v-8.705c0-0.828,0.672-1.5,1.5-1.5h1.899v-5.324c0-0.828,0.672-1.5,1.5-1.5h1.913v-5.309
     c0-0.398,0.158-0.779,0.439-1.063l3.403-3.399c0.281-0.281,0.663-0.439,1.062-0.439h6.813c0.828,0,1.5,0.672,1.5,1.5v1.902h0.401
     v-1.902c0-0.828,0.672-1.5,1.5-1.5h1.904v-1.9c0-0.827,0.672-1.5,1.5-1.5h5.315v-5.321c0-0.828,0.672-1.5,1.5-1.5h1.909v-1.908
     c0-0.828,0.672-1.5,1.5-1.5h6.188l2.969-2.965c0.212-0.211,0.479-0.354,0.767-0.41c0.098-0.02,0.194-0.029,0.294-0.029h2.78
     l6.376-6.375c0.281-0.28,0.663-0.438,1.063-0.438h1.899v-1.906c0-0.828,0.672-1.5,1.5-1.5h1.916v-11.506l-2.975-2.965
     c-0.282-0.281-0.441-0.664-0.441-1.063v-2.782l-2.962-2.968c-0.585-0.586-0.585-1.534,0.001-2.121l2.34-2.336l-2.341-2.344
     c-0.28-0.281-0.438-0.662-0.438-1.061v-6.824c0-0.398,0.157-0.779,0.438-1.061l2.962-2.968v-1.276h-1.899
     C754.94,556.244,754.559,556.086,754.278,555.806z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421010300"
          class="land land_E"
          fill="#E1E1E1"
          d="M449.079,195.626c0.001,0,0.002,0.001,0.004,0.001c0.062,0.012,0.119,0.035,0.178,0.054
     c0.033,0.011,0.068,0.018,0.103,0.032c0.028,0.012,0.056,0.03,0.084,0.044c0.027,0.013,0.055,0.028,0.081,0.043
     c0.008,0.004,0.016,0.008,0.022,0.013c0.022,0.014,0.047,0.023,0.069,0.038c0.082,0.055,0.157,0.117,0.228,0.187l2.35,2.344
     l9.162-9.151c0.281-0.281,0.662-0.438,1.061-0.438h1.9v-1.916c0-0.233,0.054-0.462,0.157-0.67l3.401-6.81
     c0.254-0.508,0.773-0.83,1.342-0.83h1.911v-8.102l-2.971-2.962c-0.282-0.281-0.44-0.664-0.44-1.062v-3.405
     c0-0.568,0.321-1.087,0.829-1.342l5.987-2.994v-5.874c0-0.397,0.158-0.779,0.438-1.06l10.215-10.226
     c0.113-0.115,0.246-0.21,0.392-0.282l5.986-2.986v-2.48v-1.916h-1.904c-0.397,0-0.778-0.158-1.061-0.438l-6.811-6.812
     c-0.281-0.281-0.438-0.663-0.438-1.06v-2.778l-2.966-2.966c-0.28-0.281-0.438-0.663-0.438-1.061v-6.198l-2.532-2.527h-2.785
     c-0.828,0-1.5-0.671-1.5-1.5v-1.9h-5.313c-0.398,0-0.78-0.159-1.063-0.44l-3.229-3.236l-6.6-3.308
     c-0.144-0.072-0.273-0.166-0.388-0.279l-2.345-2.341l-5.753,5.756c-0.281,0.281-0.662,0.439-1.062,0.439h-6.189l-2.966,2.969
     c-0.28,0.282-0.662,0.44-1.062,0.44h-10.222c-0.397,0-0.779-0.158-1.062-0.439l-3.408-3.409c-0.232-0.233-0.382-0.535-0.426-0.857
     l-11.074,11.071c-0.28,0.281-0.663,0.439-1.061,0.439h-6.457l-6.5,3.247c-0.577,0.288-1.274,0.175-1.73-0.281l-3.23-3.232
     l-4.421-2.21v0.978c0,0.606-0.365,1.153-0.926,1.386c-0.56,0.231-1.207,0.104-1.635-0.324l-6.815-6.805
     c-0.282-0.281-0.44-0.663-0.44-1.062v-6.471l-3.137-6.273l-2.8-2.791h-8.096v5.31c0,0.398-0.157,0.78-0.438,1.061l-3.403,3.4
     c-0.28,0.281-0.663,0.439-1.061,0.439h-6.816c-0.828,0-1.5-0.671-1.5-1.5v-6.187l-2.965-2.961c-0.282-0.282-0.44-0.664-0.44-1.063
     v-5.305h-5.313c-0.399,0-0.782-0.159-1.063-0.442l-2.961-2.977h-6.192c-0.828,0-1.5-0.671-1.5-1.5v-1.901h-1.901
     c-0.566,0-1.087-0.321-1.341-0.828l-2.996-5.981h-9.292c-0.161,0-0.32-0.026-0.474-0.077l-9.99-3.325h-20.185
     c-0.398,0-0.78-0.158-1.063-0.44l-6.38-6.383h-6.191c-0.827,0-1.5-0.671-1.5-1.5v-0.976l-4.643,2.318
     c-0.208,0.104-0.438,0.158-0.67,0.158h-2.782l-2.523,2.526v2.797c0,0.829-0.673,1.5-1.5,1.5h-1.911v1.901
     c0,0.829-0.673,1.5-1.5,1.5h-2.784l-2.524,2.525v6.185c0,0.397-0.157,0.778-0.438,1.06l-6.371,6.385v2.78
     c0,0.398-0.159,0.781-0.441,1.063l-10.22,10.208c-0.281,0.281-0.663,0.439-1.062,0.439h-6.457l-6.497,3.257
     c-0.422,0.212-0.922,0.212-1.344,0l-6.144-3.079l-5.308,2.664v2.483c0,0.829-0.672,1.5-1.5,1.5h-2.785l-6.37,6.366
     c-0.281,0.281-0.663,0.439-1.062,0.439h-2.79l-2.521,2.525v9.6c0,0.398-0.158,0.78-0.44,1.062l-2.972,2.965v2.783
     c0,0.232-0.054,0.461-0.157,0.669l-3.243,6.508v2.693l2.828,5.663h2.484c0.398,0,0.78,0.159,1.063,0.442l3.4,3.416
     c0.067,0.069,0.13,0.144,0.184,0.225l6.735,10.086l3.3,3.3c0.114,0.114,0.209,0.246,0.281,0.39l3.099,6.203l12.952,3.238
     c0.066,0.016,0.131,0.037,0.193,0.062l17.03,6.805c0.569,0.228,0.943,0.779,0.943,1.393v1.905h1.904c0.828,0,1.5,0.671,1.5,1.5
     v1.916h1.912c0.828,0,1.5,0.671,1.5,1.5v16.41l2.959,2.966c0.429,0.429,0.557,1.074,0.323,1.634
     c-0.232,0.56-0.778,0.925-1.386,0.925h-1.896v3.809h1.896c0.828,0,1.5,0.671,1.5,1.5v5.306h1.912c0.828,0,1.5,0.671,1.5,1.5v6.199
     l2.964,2.96c0.281,0.281,0.439,0.663,0.439,1.061v12.13h5.315c0.568,0,1.088,0.321,1.342,0.83l3.402,6.808
     c0.104,0.208,0.158,0.438,0.158,0.67v2.782l2.967,2.981c0.28,0.281,0.438,0.662,0.438,1.058v1.905h1.911c0.828,0,1.5,0.671,1.5,1.5
     v13.614c0,0.398-0.157,0.779-0.438,1.061l-0.853,0.852h1.691v-1.912c0-0.398,0.158-0.78,0.44-1.062l2.972-2.965v-2.786
     c0-0.829,0.672-1.5,1.5-1.5h5.311v-1.901c0-0.829,0.672-1.5,1.5-1.5h8.713v-1.9c0-0.829,0.672-1.5,1.5-1.5h8.729v-1.905
     c0-0.829,0.672-1.5,1.5-1.5h1.896v-1.919c0-0.829,0.672-1.5,1.5-1.5h1.903v-1.901c0-0.829,0.673-1.5,1.5-1.5h1.912v-1.905
     c0-0.829,0.672-1.5,1.5-1.5h1.904v-1.903c0-0.829,0.672-1.5,1.5-1.5h1.905v-1.905c0-0.829,0.672-1.5,1.5-1.5h17.029
     c0.396,0,0.777,0.158,1.06,0.438l2.974,2.967h12.998c0.828,0,1.5,0.671,1.5,1.5v1.903h5.316c0.398,0,0.779,0.158,1.061,0.439
     l2.965,2.965h2.787c0.397,0,0.779,0.158,1.062,0.439l2.961,2.962h2.784c0.398,0,0.78,0.158,1.063,0.44l0.862,0.865
     c0.001-0.011,0.009-0.021,0.011-0.032c0.021-0.141,0.063-0.276,0.121-0.404c0.013-0.025,0.025-0.047,0.038-0.071
     c0.069-0.13,0.151-0.253,0.257-0.359l2.965-2.962v-5.565l-2.965-2.965c-0.28-0.281-0.438-0.663-0.438-1.061v-17.027
     c0-0.829,0.672-1.5,1.5-1.5h1.904v-1.919c0-0.398,0.157-0.78,0.439-1.062l6.816-6.806c0.28-0.281,0.662-0.438,1.06-0.438h1.9
     v-1.908c0-0.233,0.055-0.463,0.159-0.672l3.25-6.488V194.32l-2.97-2.965c-0.084-0.085-0.158-0.179-0.221-0.28
     c-0.123-0.201-0.195-0.429-0.215-0.665c-0.009-0.117-0.005-0.237,0.016-0.356c0.057-0.357,0.239-0.677,0.511-0.905
     c0.09-0.076,0.189-0.142,0.298-0.196l6.596-3.307l6.646-6.638c0.113-0.114,0.245-0.209,0.391-0.282l5.979-2.986v-2.477
     c0-0.52,0.271-1.003,0.713-1.276c0.439-0.274,0.992-0.298,1.459-0.066l6.491,3.247h9.866c0.828,0,1.5,0.671,1.5,1.5v2.474
     l5.979,2.99c0.144,0.072,0.273,0.167,0.389,0.28l3.242,3.234l6.59,3.307c0.289,0.145,0.522,0.379,0.668,0.668l2.996,5.978h2.476
     c0.096,0,0.19,0.01,0.283,0.028C449.073,195.626,449.076,195.626,449.079,195.626z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421010600"
          class="land land_E"
          fill="#E1E1E1"
          d="M687.213,300.786h-1.901v15.534c0,0.398-0.157,0.779-0.438,1.061l-3.404,3.405
     c-0.281,0.281-0.663,0.439-1.061,0.439h-2.791l-2.961,2.961c-0.281,0.281-0.663,0.439-1.062,0.439h-2.788l-2.974,2.964
     c-0.113,0.113-0.244,0.207-0.388,0.279l-20.427,10.225c-0.209,0.104-0.438,0.159-0.672,0.159h-3.052l-26.716,13.347l-3.24,3.247
     c-0.281,0.282-0.663,0.44-1.062,0.44h-1.899v1.905c0,0.398-0.158,0.781-0.44,1.062l-2.969,2.96v19.19l2.969,2.965
     c0.282,0.282,0.44,0.664,0.44,1.062v30.656c0,0.398-0.158,0.779-0.439,1.061l-3.409,3.408c-0.28,0.281-0.662,0.439-1.061,0.439
     h-2.783l-9.781,9.782c-0.114,0.114-0.246,0.209-0.391,0.281l-6.595,3.295l-3.229,3.232c-0.113,0.115-0.247,0.21-0.392,0.282
     l-5.987,2.988v0.99h1.904c0.828,0,1.5,0.671,1.5,1.5v1.905h1.913c0.828,0,1.5,0.671,1.5,1.5v1.901h8.713c0.828,0,1.5,0.671,1.5,1.5
     v1.905h8.722c0.828,0,1.5,0.671,1.5,1.5v1.905h8.714c0.827,0,1.5,0.671,1.5,1.5v1.905h5.316c0.828,0,1.5,0.671,1.5,1.5v1.918h5.313
     c0.396,0,0.778,0.158,1.061,0.439l6.372,6.363h2.785c0.396,0,0.779,0.158,1.061,0.439l3.404,3.404
     c0.281,0.281,0.439,0.663,0.439,1.062v1.904h1.912c0.397,0,0.78,0.158,1.063,0.44l2.966,2.976h2.772c0.828,0,1.5,0.671,1.5,1.5
     v1.904h5.317c0.828,0,1.5,0.671,1.5,1.5v1.904h5.316c0.231,0,0.462,0.054,0.67,0.157l13.302,6.648h6.462
     c0.399,0,0.782,0.158,1.063,0.441l3.15,3.166l9.879,3.293c0.22,0.071,0.42,0.195,0.584,0.358l2.974,2.965h2.78
     c0.828,0,1.5,0.671,1.5,1.5v1.9h1.913c0.827,0,1.5,0.671,1.5,1.5v1.908h5.306c0.398,0,0.779,0.158,1.061,0.438l6.373,6.377h2.783
     c0.828,0,1.5,0.672,1.5,1.5v1.908h1.916c0.828,0,1.5,0.672,1.5,1.5v1.9h1.9c0.396,0,0.777,0.157,1.06,0.438l3.409,3.404
     c0.282,0.281,0.44,0.662,0.44,1.061v1.9h1.907c0.398,0,0.781,0.16,1.063,0.443l0.851,0.854c0.099-0.731,0.728-1.299,1.486-1.299
     h2.48l2.985-5.975c0.072-0.146,0.168-0.277,0.282-0.392l9.777-9.779v-2.784c0-0.398,0.157-0.779,0.439-1.062l2.976-2.971v-14.908
     h-1.916c-0.396,0-0.778-0.158-1.061-0.438l-3.4-3.4c-0.28-0.281-0.438-0.663-0.438-1.062v-1.903h-1.908c-0.828,0-1.5-0.672-1.5-1.5
     v-6.82c0-0.397,0.158-0.78,0.439-1.062l3.235-3.232l3.291-6.586c0.072-0.145,0.167-0.276,0.281-0.39l6.381-6.381v-8.972
     l-2.965-2.961c-0.281-0.281-0.439-0.663-0.439-1.061v-20.438c0-0.829,0.673-1.5,1.5-1.5h1.904v-5.317
     c0-0.568,0.321-1.087,0.829-1.341l5.983-2.994v-0.974h-1.913c-0.397,0-0.779-0.158-1.062-0.439l-3.398-3.401
     c-0.281-0.281-0.439-0.663-0.439-1.061v-3.419c0-0.397,0.158-0.779,0.438-1.06l2.961-2.966v-8.968l-2.961-2.966
     c-0.28-0.281-0.438-0.662-0.438-1.06v-17.03c0-0.396,0.157-0.777,0.438-1.058l2.963-2.977v-6.19c0-0.399,0.159-0.781,0.44-1.063
     l2.973-2.96v-2.161l-2.976-2.98c-0.28-0.281-0.438-0.662-0.438-1.06v-6.806c0-0.829,0.672-1.5,1.5-1.5h12.127v-1.9
     c0-0.829,0.672-1.5,1.5-1.5h5.315v-5.325c0-0.829,0.673-1.5,1.5-1.5h1.904v-12.11c0-0.829,0.672-1.5,1.5-1.5h1.904v-1.905
     c0-0.397,0.157-0.778,0.438-1.059l6.372-6.382v-4.688h-1.899c-0.828,0-1.5-0.671-1.5-1.5v-9.871l-3.25-6.488
     c-0.104-0.208-0.159-0.438-0.159-0.672v-3.408c0-0.397,0.158-0.779,0.438-1.061l2.972-2.973v-6.192
     c0-0.398,0.157-0.779,0.438-1.061l0.841-0.841h-2.567l-2.966,2.962c-0.28,0.281-0.662,0.438-1.061,0.438h-8.721v1.908
     c0,0.829-0.672,1.5-1.5,1.5h-30.034l-2.971,2.966c-0.281,0.281-0.662,0.438-1.061,0.438h-13.263l-6.492,3.253
     c-0.208,0.104-0.438,0.159-0.672,0.159h-2.794l-2.965,2.968c-0.281,0.282-0.663,0.44-1.063,0.44h-5.313v1.9
     c0,0.829-0.672,1.5-1.5,1.5h-12.999l-2.973,2.967c-0.281,0.28-0.662,0.438-1.061,0.438h-8.717v1.905v3.416
     C688.713,300.115,688.041,300.786,687.213,300.786z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421011200"
          class="land land_E"
          fill="#E1E1E1"
          d="M569.062,446.81c-0.28,0.281-0.663,0.439-1.061,0.439H566.1v22.335c0,0.829-0.672,1.5-1.5,1.5h-5.316
     v46.189c0,0.828-0.672,1.5-1.5,1.5h-12.122v35.97c0,0.1-0.011,0.196-0.029,0.295l-3.379,16.889v26.469l6.375,6.381
     c0.28,0.281,0.438,0.662,0.438,1.062v6.563l2.983,8.972h28.081v-1.922c0-0.828,0.672-1.5,1.5-1.5h6.81c0.828,0,1.5,0.672,1.5,1.5
     v1.922h5.31c0.231,0,0.462,0.055,0.67,0.157l6.816,3.4c0.145,0.072,0.275,0.168,0.391,0.281l2.965,2.963h2.788
     c0.398,0,0.78,0.158,1.063,0.438l2.959,2.966h4.695v-1.904c0-0.828,0.672-1.5,1.5-1.5h1.901v-1.902c0-0.397,0.157-0.78,0.44-1.063
     l6.374-6.379v-2.781c0-0.398,0.158-0.779,0.439-1.063l6.813-6.809c0.281-0.281,0.663-0.439,1.062-0.439h1.904v-1.899
     c0-0.398,0.157-0.779,0.438-1.062l6.378-6.381v-2.787c0-0.396,0.157-0.776,0.438-1.059l6.812-6.805
     c0.281-0.281,0.662-0.438,1.06-0.438h1.904v-1.92c0-0.397,0.158-0.779,0.44-1.061l3.411-3.404c0.28-0.281,0.662-0.439,1.06-0.439
     h5.303v-1.901c0-0.398,0.157-0.781,0.439-1.063l3.412-3.405c0.281-0.281,0.662-0.438,1.06-0.438h1.908v-1.896
     c0-0.568,0.321-1.089,0.829-1.343l13.616-6.821c0.209-0.104,0.438-0.158,0.673-0.158h1.911v-1.908c0-0.828,0.672-1.5,1.5-1.5h5.306
     v-1.906c0-0.828,0.672-1.5,1.5-1.5h9.605l2.965-2.959c0.281-0.281,0.662-0.438,1.06-0.438h6.19l6.365-6.382
     c0.281-0.28,0.663-0.438,1.063-0.438h3.063l6.274-3.139l6.197-6.195v-2.159l-2.525-2.542h-2.782c-0.828,0-1.5-0.672-1.5-1.5v-2.777
     l-2.53-2.526h-2.779c-0.828,0-1.5-0.672-1.5-1.5v-1.9h-1.916c-0.828,0-1.5-0.672-1.5-1.5v-1.908h-1.904
     c-0.396,0-0.778-0.157-1.061-0.438l-6.373-6.377h-6.186c-0.828,0-1.5-0.672-1.5-1.5v-1.908h-1.913c-0.828,0-1.5-0.672-1.5-1.5v-1.9
     h-1.899c-0.397,0-0.777-0.157-1.06-0.438l-3.162-3.152l-9.883-3.293c-0.222-0.074-0.424-0.199-0.589-0.364l-2.962-2.978h-6.192
     c-0.231,0-0.463-0.055-0.671-0.158l-13.3-6.647h-6.463c-0.828,0-1.5-0.672-1.5-1.5v-1.904h-5.318c-0.828,0-1.5-0.672-1.5-1.5
     v-1.904h-1.896c-0.398,0-0.781-0.158-1.063-0.44l-2.966-2.976h-2.789c-0.828,0-1.5-0.672-1.5-1.5v-2.783l-2.524-2.525h-2.784
     c-0.397,0-0.778-0.158-1.061-0.438l-6.374-6.364h-6.19c-0.828,0-1.5-0.671-1.5-1.5v-1.918h-5.317c-0.828,0-1.5-0.671-1.5-1.5
     v-1.905h-8.713c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-8.722c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-8.714c-0.827,0-1.5-0.671-1.5-1.5
     v-1.901h-1.913c-0.827,0-1.5-0.671-1.5-1.5v-1.905h-1.903h-2.783L569.062,446.81z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421011000"
          class="land land_E"
          fill="#E1E1E1"
          d="M423.447,479.81v-1.916H394.29c-0.646,0-1.219-0.414-1.423-1.024l-3.291-9.874l-0.612-0.61
     c-0.098,0.733-0.726,1.299-1.486,1.299h-5.31v1.9c0,0.568-0.321,1.088-0.829,1.342l-6.812,3.404
     c-0.209,0.104-0.438,0.158-0.672,0.158h-3.058l-6.489,3.245c-0.208,0.104-0.438,0.159-0.671,0.159h-1.909v1.916
     c0,0.828-0.672,1.5-1.5,1.5h-1.902v1.904c0,0.828-0.672,1.5-1.5,1.5h-5.316v0.404h1.911c0.828,0,1.5,0.671,1.5,1.5v1.899h1.905
     c0.397,0,0.778,0.158,1.061,0.439l6.814,6.826c0.279,0.28,0.438,0.661,0.438,1.059v6.186l9.777,9.773
     c0.281,0.281,0.439,0.662,0.439,1.061v6.197l2.965,2.963c0.281,0.281,0.439,0.662,0.439,1.062v1.903h1.907
     c0.606,0,1.153,0.367,1.386,0.929c0.232,0.561,0.104,1.205-0.326,1.635l-2.967,2.961v6.199c0,0.828-0.672,1.5-1.5,1.5h-1.904v5.307
     c0,0.828-0.672,1.5-1.5,1.5h-1.912v5.322c0,0.398-0.158,0.779-0.438,1.061l-2.966,2.966v19.811c0,0.828-0.673,1.5-1.5,1.5h-1.9
     v3.809h1.9c0.827,0,1.5,0.673,1.5,1.5v3.404c0,0.828-0.673,1.5-1.5,1.5h-5.311v1.92c0,0.828-0.672,1.5-1.5,1.5h-1.903v1.9
     c0,0.828-0.672,1.5-1.5,1.5h-1.904v3.809h1.904c0.761,0,1.388,0.564,1.486,1.297l0.856-0.856c0.28-0.281,0.661-0.439,1.061-0.439
     h3.409c0.828,0,1.5,0.672,1.5,1.5v12.123h1.899c0.828,0,1.5,0.672,1.5,1.5v13.629c0,0.761-0.563,1.388-1.293,1.486l0.854,0.855
     c0.281,0.28,0.439,0.661,0.439,1.061v5.309h5.168l15.68-3.135v-26.01c0-0.828,0.672-1.5,1.5-1.5h2.781l2.974-2.975
     c0.281-0.281,0.662-0.439,1.061-0.439h1.904v-1.906c0-0.828,0.672-1.5,1.5-1.5h6.812c0.828,0,1.5,0.672,1.5,1.5v1.906h10.621
     v-1.906c0-0.828,0.672-1.5,1.5-1.5h12.13v-14.033h-1.913c-0.828,0-1.5-0.672-1.5-1.5v-30.024l-2.963-2.979
     c-0.279-0.278-0.438-0.66-0.438-1.057v-17.035c0-0.396,0.158-0.779,0.439-1.061l0.854-0.854c-0.521-0.072-0.973-0.416-1.179-0.912
     c-0.232-0.559-0.104-1.205,0.324-1.633l2.961-2.967v-6.188c0-0.397,0.157-0.778,0.438-1.061l2.975-2.973v-2.783
     c0-0.828,0.672-1.5,1.5-1.5h1.903v-1.908c0-0.829,0.673-1.5,1.5-1.5h1.901v-12.125c0-0.398,0.158-0.781,0.439-1.063l2.972-2.965
     v-8.088h-18.938C424.118,481.31,423.447,480.638,423.447,479.81z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421010500"
          class="land land_E"
          fill="#E1E1E1"
          d="M565.66,249.254l-2.969,2.964v8.104h1.908c0.828,0,1.5,0.671,1.5,1.5v10.206c0,0.829-0.672,1.5-1.5,1.5
     h-1.908v1.908c0,0.829-0.673,1.5-1.5,1.5h-1.908v0.405h1.908c0.827,0,1.5,0.671,1.5,1.5v5.32h5.311
     c0.396,0,0.778,0.158,1.061,0.438l2.968,2.962h2.784c0.396,0,0.778,0.158,1.061,0.439l2.965,2.965h2.792
     c0.232,0,0.463,0.054,0.671,0.158l6.811,3.405c0.508,0.254,0.829,0.773,0.829,1.342v1.916h5.309h13.63c0.828,0,1.5,0.671,1.5,1.5
     v1.905h8.717c0.232,0,0.463,0.054,0.672,0.158l13.308,6.65h16.049l2.974-2.967c0.281-0.28,0.662-0.438,1.061-0.438h2.785
     l2.972-2.966c0.281-0.28,0.662-0.438,1.061-0.438h2.782l2.957-2.964c0.281-0.282,0.663-0.44,1.063-0.44h8.721v-1.916
     c0-0.829,0.672-1.5,1.5-1.5h8.719v-1.905v-3.405c0-0.398,0.157-0.781,0.44-1.063l3.411-3.4c0.281-0.28,0.661-0.438,1.059-0.438
     h1.902v-1.908c0-0.397,0.157-0.778,0.438-1.059l6.379-6.38v-2.786c0-0.829,0.672-1.5,1.5-1.5h1.899v-5.301
     c0-0.829,0.672-1.5,1.5-1.5h1.913v-1.905c0-0.396,0.157-0.777,0.438-1.058l2.349-2.358l-2.346-2.343
     c-0.281-0.281-0.439-0.663-0.439-1.061c0-0.398,0.158-0.78,0.439-1.061l2.965-2.965v-2.782c0-0.829,0.672-1.5,1.5-1.5h1.901v-8.726
     c0-0.398,0.157-0.78,0.439-1.062l0.858-0.857c-0.733-0.098-1.3-0.726-1.3-1.486v-10.219c0-0.829,0.673-1.5,1.5-1.5h5.313v-1.905
     c0-0.829,0.672-1.5,1.5-1.5h1.904v-1.28l-2.526-2.529H707.66c-0.396,0-0.776-0.157-1.059-0.438l-2.975-2.963h-12.998
     c-0.397,0-0.778-0.158-1.061-0.438l-6.376-6.382h-2.781c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-5.313c-0.828,0-1.5-0.671-1.5-1.5
     v-5.305h-1.908c-0.828,0-1.5-0.671-1.5-1.5v-16.408l-2.972-2.965c-0.282-0.282-0.44-0.664-0.44-1.062v-16.413l-2.958-2.961
     c-0.28-0.281-0.438-0.663-0.438-1.06v-2.796l-2.527-2.529h-2.789c-0.396,0-0.777-0.158-1.06-0.439l-2.968-2.962h-2.792
     c-0.397,0-0.779-0.158-1.062-0.44l-6.361-6.369h-2.79c-0.232,0-0.464-0.055-0.673-0.159l-18.262-9.139v23.333h1.9
     c0.828,0,1.5,0.671,1.5,1.5v18.935h1.911c0.828,0,1.5,0.671,1.5,1.5v3.051l3.246,6.488c0.104,0.208,0.158,0.438,0.158,0.671v1.905
     h1.908c0.828,0,1.5,0.671,1.5,1.5v1.916h1.905c0.605,0,1.152,0.365,1.386,0.926c0.232,0.561,0.104,1.206-0.325,1.635l-2.966,2.966
     v6.184c0,0.829-0.672,1.5-1.5,1.5h-2.787l-2.965,2.965c-0.281,0.281-0.663,0.439-1.062,0.439H624v1.916c0,0.829-0.673,1.5-1.5,1.5
     h-1.901v1.905c0,0.829-0.672,1.5-1.5,1.5h-1.904v1.9c0,0.829-0.672,1.5-1.5,1.5h-1.913v1.908c0,0.829-0.672,1.5-1.5,1.5h-9.592
     L599.723,222c-0.281,0.281-0.662,0.438-1.061,0.438h-8.72v1.904c0,0.829-0.672,1.5-1.5,1.5h-5.31v1.919c0,0.829-0.673,1.5-1.5,1.5
     h-2.793l-2.965,2.962c-0.28,0.281-0.662,0.439-1.061,0.439h-2.784l-2.968,2.962c-0.281,0.28-0.662,0.438-1.061,0.438h-1.9v12.13
     C566.1,248.591,565.942,248.973,565.66,249.254z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421010400"
          class="land land_E"
          fill="#E1E1E1"
          d="M570.349,230.1c0.281-0.28,0.662-0.438,1.061-0.438h2.782l2.966-2.962c0.28-0.281,0.663-0.439,1.061-0.439
     h1.913v-1.919c0-0.829,0.672-1.5,1.5-1.5h5.311v-1.905c0-0.829,0.672-1.5,1.5-1.5h9.599l2.967-2.962
     c0.281-0.281,0.662-0.438,1.061-0.438h8.713v-1.908c0-0.829,0.672-1.5,1.5-1.5h1.913v-1.9c0-0.829,0.672-1.5,1.5-1.5h1.903v-1.905
     c0-0.829,0.673-1.5,1.5-1.5h1.901v-1.916c0-0.829,0.672-1.5,1.5-1.5h2.79l2.965-2.965c0.281-0.281,0.663-0.439,1.062-0.439h1.907
     v-5.305c0-0.398,0.158-0.779,0.439-1.061l0.856-0.858c-0.731-0.099-1.297-0.727-1.297-1.486v-1.916h-1.907
     c-0.828,0-1.5-0.671-1.5-1.5v-3.05l-3.246-6.489c-0.104-0.208-0.158-0.438-0.158-0.671v-1.905h-1.911c-0.828,0-1.5-0.671-1.5-1.5
     v-18.935h-1.9c-0.828,0-1.5-0.671-1.5-1.5v-27.26v-13.004l-2.966-2.965c-0.28-0.281-0.438-0.663-0.438-1.061v-6.188l-2.975-2.978
     c-0.28-0.281-0.438-0.663-0.438-1.061V85.323l-6.371-6.385c-0.28-0.281-0.438-0.662-0.438-1.059v-6.806
     c0-0.398,0.157-0.78,0.439-1.062l2.969-2.965v-1.279h-1.909c-0.398,0-0.78-0.159-1.063-0.441l-6.808-6.821
     c-0.281-0.281-0.439-0.663-0.439-1.061v-1.901h-1.911c-0.396,0-0.779-0.158-1.061-0.439l-6.81-6.809
     c-0.28-0.281-0.438-0.663-0.438-1.061v-2.793l-2.969-2.965c-0.233-0.232-0.383-0.534-0.428-0.856l-0.853,0.854
     c-0.586,0.586-1.535,0.586-2.121,0.002l-2.974-2.967h-2.33l-9.167,6.113v2.612c0,0.233-0.056,0.463-0.159,0.671l-6.658,13.298
     v3.064c0,0.398-0.158,0.78-0.438,1.061l-3.232,3.229l-3.146,6.275v6.465c0,0.397-0.157,0.779-0.438,1.06l-3.4,3.405
     c-0.28,0.282-0.662,0.44-1.062,0.44h-6.192l-2.964,2.961c-0.281,0.281-0.663,0.439-1.061,0.439h-5.317v1.901
     c0,0.568-0.321,1.087-0.829,1.341l-6.813,3.409c-0.208,0.104-0.438,0.159-0.671,0.159h-1.9v5.316c0,0.161-0.026,0.321-0.077,0.474
     l-3.403,10.213c-0.021,0.067-0.05,0.133-0.081,0.197l-3.411,6.821c-0.072,0.144-0.168,0.276-0.281,0.39l-9.778,9.771v2.539
     l2.992,8.968h2.321c0.567,0,1.088,0.321,1.342,0.829l3.344,6.689l10.135,13.517c0.194,0.26,0.301,0.575,0.301,0.9v2.782
     l2.969,2.962c0.282,0.282,0.439,0.664,0.439,1.062v1.919h1.904c0.828,0,1.5,0.671,1.5,1.5v1.905h1.912c0.828,0,1.5,0.671,1.5,1.5
     v13.004l5.93,5.927h2.787c0.828,0,1.5,0.671,1.5,1.5v2.783l2.966,2.966c0.114,0.114,0.21,0.247,0.282,0.391l3.4,6.82
     c0.104,0.208,0.156,0.437,0.156,0.669v1.9h5.316c0.828,0,1.5,0.671,1.5,1.5v5.309h1.908c0.828,0,1.5,0.671,1.5,1.5v1.905h1.909
     c0.828,0,1.5,0.671,1.5,1.5v8.721h1.28L570.349,230.1z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421010800"
          class="land land_E"
          fill="#E1E1E1"
          d="M556.283,469.585c0-0.829,0.672-1.5,1.5-1.5h5.316V445.75c0-0.829,0.672-1.5,1.5-1.5h2.78l2.968-2.966
     c0.281-0.281,0.663-0.439,1.062-0.439h1.903v-1.918v-1.902h-1.903c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-1.907
     c-0.828,0-1.5-0.671-1.5-1.5v-1.904h-1.901c-0.827,0-1.5-0.671-1.5-1.5v-1.905h-1.909c-0.232,0-0.463-0.054-0.672-0.159
     l-6.813-3.412c-0.508-0.254-0.828-0.773-0.828-1.341v-1.905h-5.313c-0.828,0-1.5-0.671-1.5-1.5v-1.908h-5.313
     c-0.397,0-0.779-0.158-1.062-0.439l-2.964-2.961h-2.784c-0.827,0-1.5-0.671-1.5-1.5v-5.32h-1.911c-0.828,0-1.5-0.671-1.5-1.5
     v-1.905h-1.904c-0.828,0-1.5-0.671-1.5-1.5v-1.901h-1.909c-0.828,0-1.5-0.671-1.5-1.5v-1.904h-1.9c-0.828,0-1.5-0.671-1.5-1.5
     v-1.904h-1.902c-0.828,0-1.5-0.671-1.5-1.5v-2.784l-2.534-2.537H510.1c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-1.902
     c-0.827,0-1.5-0.671-1.5-1.5v-1.905h-1.91c-0.828,0-1.5-0.671-1.5-1.5v-1.9h-1.901c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-1.911
     c-0.828,0-1.5-0.671-1.5-1.5V368.9h-1.905c-0.827,0-1.5-0.671-1.5-1.5v-1.905h-1.903c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-1.912
     c-0.828,0-1.5-0.671-1.5-1.5v-1.9h-1.896c-0.828,0-1.5-0.671-1.5-1.5v-1.905h-1.904c-0.828,0-1.5-0.671-1.5-1.5v-1.919h-1.912
     c-0.828,0-1.5-0.671-1.5-1.5v-2.779l-2.967-2.966c-0.28-0.281-0.438-0.663-0.438-1.061v-1.9h-1.911c-0.828,0-1.5-0.671-1.5-1.5
     v-8.725h-1.9h-5.313v1.9c0,0.829-0.672,1.5-1.5,1.5h-1.904v0.417h1.904c0.828,0,1.5,0.671,1.5,1.5v42.773h1.912
     c0.828,0,1.5,0.671,1.5,1.5v6.821c0,0.829-0.672,1.5-1.5,1.5h-1.912v41.273h1.912c0.606,0,1.154,0.366,1.386,0.927
     c0.232,0.561,0.104,1.207-0.326,1.635l-2.972,2.965v2.769v0.011v0.001c0,0.02,0,0.04-0.001,0.06
     c-0.001,0.013-0.008,0.024-0.009,0.038c-0.013,0.171-0.048,0.338-0.115,0.494c-0.006,0.014-0.015,0.026-0.021,0.04
     c-0.07,0.153-0.165,0.295-0.284,0.418c-0.002,0.001-0.002,0.003-0.004,0.004l0,0c-0.001,0-0.002,0.001-0.003,0.002l-2.967,2.979
     v16.4c0,0.829-0.672,1.5-1.5,1.5h-5.316v12.125c0,0.396-0.157,0.777-0.438,1.06l-2.963,2.967v1.295h8.718
     c0.396,0,0.779,0.158,1.061,0.439l0.857,0.856c0.1-0.731,0.728-1.297,1.486-1.297c0.828,0,1.5,0.671,1.5,1.5v1.904h8.714
     c0.396,0,0.778,0.157,1.06,0.438l2.973,2.967h6.197c0.1,0,0.197,0.01,0.293,0.028c0.288,0.058,0.556,0.198,0.768,0.409l2.966,2.962
     h2.775c0.396,0,0.777,0.158,1.06,0.439l2.973,2.967h6.189c0.828,0,1.5,0.671,1.5,1.5v5.322h22.346c0.828,0,1.5,0.672,1.5,1.5
     v15.526h22.344h12.121v-46.187H556.283z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421011400"
          class="land land_E"
          fill="#E1E1E1"
          d="M698.93,673.949c0,0.827-0.672,1.5-1.5,1.5h-1.902v1.922c0,0.828-0.672,1.5-1.5,1.5h-2.782l-2.974,2.963
     c-0.279,0.281-0.66,0.438-1.059,0.438h-1.9v1.902c0,0.828-0.673,1.5-1.5,1.5h-1.904v1.9c0,0.827-0.672,1.5-1.5,1.5h-1.912v1.284
     l2.971,2.959c0.282,0.281,0.441,0.664,0.441,1.063v1.922h1.904c0.827,0,1.5,0.672,1.5,1.5v1.9h5.313c0.828,0,1.5,0.672,1.5,1.5
     v6.188l2.963,2.965c0.281,0.281,0.439,0.664,0.439,1.062v4.391l11.458-5.731c0.212-0.105,0.44-0.158,0.671-0.158
     c0.291,0,0.581,0.086,0.832,0.252l19.887,13.258l12.975-3.24c0.119-0.028,0.241-0.045,0.363-0.045h6.188l2.798-2.795l3.299-6.6
     c0.072-0.146,0.167-0.277,0.281-0.392l6.815-6.811c0.281-0.279,0.663-0.438,1.062-0.438h1.904v-1.903c0-0.828,0.672-1.5,1.5-1.5
     h1.898v-1.9c0-0.828,0.672-1.5,1.5-1.5h5.314v-5.32c0-0.827,0.672-1.5,1.5-1.5h1.91v-5.309c0-0.828,0.673-1.5,1.5-1.5h2.78
     l2.526-2.523v-2.778c0-0.396,0.157-0.777,0.438-1.06l2.975-2.983v-2.783c0-0.396,0.158-0.779,0.438-1.062l2.965-2.965v-2.785
     c0-0.827,0.673-1.5,1.5-1.5h1.904v-1.896c0-0.396,0.157-0.777,0.438-1.06l2.972-2.981v-2.785c0-0.396,0.158-0.776,0.438-1.059
     l2.962-2.968v-9.6c0-0.828,0.672-1.5,1.5-1.5h1.916v-1.904c0-0.827,0.672-1.5,1.5-1.5h1.899v-1.903
     c0-0.398,0.158-0.779,0.438-1.062l3.4-3.403c0.282-0.281,0.663-0.439,1.063-0.439h1.916v-5.303c0-0.828,0.672-1.5,1.5-1.5h1.899
     v-1.922c0-0.828,0.673-1.5,1.5-1.5h1.9v-1.9c0-0.397,0.158-0.781,0.44-1.063l2.973-2.966v-1.282h-5.313
     c-0.396,0-0.778-0.158-1.061-0.439l-2.961-2.961h-6.196c-0.397,0-0.781-0.16-1.063-0.441l-2.962-2.973h-6.192
     c-0.828,0-1.5-0.672-1.5-1.5v-2.785l-19.996-19.996c-0.281-0.28-0.438-0.664-0.438-1.061v-2.783l-16.591-16.596
     c-0.28-0.281-0.438-0.662-0.438-1.062v-1.897h-0.416v1.897c0,0.398-0.158,0.779-0.438,1.062l-2.962,2.967v5.582l2.962,2.967
     c0.585,0.586,0.585,1.535-0.001,2.121l-2.34,2.338l2.341,2.344c0.281,0.281,0.438,0.662,0.438,1.062v2.779l2.975,2.965
     c0.282,0.28,0.441,0.664,0.441,1.063v13.629c0,0.828-0.672,1.5-1.5,1.5h-1.916v1.906c0,0.828-0.672,1.5-1.5,1.5h-2.778l-6.38,6.377
     c-0.281,0.281-0.662,0.438-1.06,0.438h-2.779l-2.969,2.967c-0.281,0.278-0.663,0.438-1.062,0.438h-5.309v1.906
     c0,0.828-0.672,1.5-1.5,1.5h-1.909v5.322c0,0.828-0.672,1.5-1.5,1.5h-5.316v1.899c0,0.828-0.672,1.5-1.5,1.5h-1.903v1.902
     c0,0.828-0.673,1.5-1.5,1.5h-3.401c-0.828,0-1.5-0.672-1.5-1.5v-1.902h-4.691l-2.524,2.523v6.188c0,0.828-0.672,1.5-1.5,1.5h-1.913
     v5.324c0,0.828-0.672,1.5-1.5,1.5h-1.9v8.705c0,0.828-0.672,1.5-1.5,1.5h-1.912v8.724c0,0.827-0.672,1.5-1.5,1.5h-1.902v0.405
     h1.902c0.828,0,1.5,0.672,1.5,1.5v3.404c0,0.828-0.672,1.5-1.5,1.5h-1.902v0.404h1.902
     C698.258,672.449,698.93,673.121,698.93,673.949z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          id="dong_4421010100"
          class="land land_E"
          fill="#E1E1E1"
          d="M457.507,329.935c0-0.829,0.672-1.5,1.5-1.5h5.313v-1.908v-12.125h-1.9c-0.828,0-1.5-0.671-1.5-1.5v-18.935
     h-1.912c-0.828,0-1.5-0.671-1.5-1.5v-12.125h-1.904c-0.828,0-1.5-0.671-1.5-1.5v-25.745h-1.904c-0.828,0-1.5-0.671-1.5-1.5v-5.308
     h-1.911c-0.606,0-1.153-0.365-1.387-0.926c-0.232-0.561-0.104-1.206,0.325-1.635l6.377-6.381v-2.429l-3.139-6.268l-6.642-6.652
     c-0.4-0.402-0.541-0.996-0.36-1.534l3.324-9.979v-11.887h-1.901c-0.567,0-1.087-0.32-1.341-0.828l-3.188-6.36l-6.359-3.192
     c-0.144-0.072-0.273-0.166-0.388-0.279l-3.24-3.233l-6.593-3.296c-0.508-0.254-0.829-0.773-0.829-1.342v-1.9h-8.721
     c-0.232,0-0.463-0.054-0.671-0.158l-4.638-2.319v0.978c0,0.568-0.321,1.088-0.83,1.342l-6.592,3.291l-6.646,6.639
     c-0.113,0.113-0.244,0.207-0.388,0.279l-4.952,2.483l1.938,1.935c0.281,0.282,0.439,0.664,0.439,1.062v13.625
     c0,0.233-0.055,0.463-0.159,0.672l-3.25,6.488v3.054c0,0.829-0.672,1.5-1.5,1.5h-2.778l-5.938,5.928v2.797
     c0,0.829-0.672,1.5-1.5,1.5h-1.904v14.906l2.965,2.965c0.281,0.281,0.439,0.663,0.439,1.061v6.808c0,0.398-0.158,0.78-0.439,1.061
     l-2.965,2.962v2.798c0,0.138-0.023,0.269-0.06,0.395c-0.008,0.03-0.018,0.059-0.027,0.089c-0.042,0.122-0.094,0.239-0.164,0.345
     c-0.001,0.002-0.002,0.004-0.003,0.006c-0.072,0.108-0.162,0.202-0.26,0.288c-0.023,0.021-0.047,0.04-0.071,0.059
     c-0.104,0.081-0.216,0.152-0.339,0.203c-0.001,0-0.002,0.001-0.003,0.002c-0.004,0.002-0.009,0.001-0.013,0.003
     c-0.116,0.046-0.24,0.075-0.368,0.091c-0.033,0.004-0.065,0.008-0.101,0.01c-0.03,0.002-0.061,0.009-0.093,0.009h-5.316v1.905
     c0,0.398-0.158,0.779-0.439,1.061l-6.37,6.363v2.786c0,0.607-0.365,1.154-0.927,1.386c-0.561,0.231-1.205,0.103-1.635-0.326
     l-2.965-2.968h-2.161l-2.532,2.53v2.783c0,0.397-0.157,0.778-0.438,1.06l-3.404,3.412c-0.281,0.282-0.663,0.44-1.063,0.44h-1.908
     v8.091l2.97,2.966c0.113,0.114,0.209,0.246,0.28,0.391l2.991,5.99h5.889c0.397,0,0.779,0.158,1.062,0.439l3.403,3.404
     c0.28,0.281,0.438,0.663,0.438,1.061v1.905h1.909c0.828,0,1.5,0.671,1.5,1.5v1.901h5.306c0.233,0,0.464,0.055,0.673,0.159
     l6.816,3.419c0.507,0.254,0.827,0.773,0.827,1.341v5.305h15.529c0.231,0,0.462,0.054,0.67,0.158l6.815,3.401
     c0.146,0.072,0.277,0.168,0.393,0.283l5.748,5.749l2.346-2.342c0.281-0.281,0.662-0.438,1.061-0.438h10.217
     c0.396,0,0.777,0.158,1.06,0.438l2.97,2.962h2.789c0.827,0,1.5,0.671,1.5,1.5v1.917h0.398v-1.917c0-0.829,0.672-1.5,1.5-1.5h5.317
     v-1.9c0-0.829,0.672-1.5,1.5-1.5h17.03c0.827,0,1.5,0.671,1.5,1.5v1.9h0.403V329.935L457.507,329.935z"
        />
      </g>
      <g>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 286.7903 163.4736)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          갈산동
        </text>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 547.7522 154.4736)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          온석동
        </text>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 617.7522 251.4736)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          잠홍동
        </text>
        <text
          class="land_D"
          transform="matrix(1 0 0 1 487.5676 252.4736)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          동문동
        </text>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 389.5676 275.1196)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          읍내동
        </text>
        <text
          class="land_B"
          transform="matrix(1 0 0 1 360.5676 393.1196)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          예천동
        </text>
        <text
          class="land_C"
          transform="matrix(1 0 0 1 537.2532 366.936)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          석림동
        </text>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 669.2532 408.936)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          수석동
        </text>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 593.7512 542.6133)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          오남동
        </text>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 596.7512 679.8477)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          장동
        </text>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 718.1829 662.8477)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          덕지천동
        </text>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 485.5676 443.8477)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          석남동
        </text>
        <text
          class="land_A"
          transform="matrix(1 0 0 1 435.5676 679.8477)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          양대동
        </text>
        <text
          class="land_E"
          transform="matrix(1 0 0 1 380.5676 512.7715)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          죽성동
        </text>
      </g>
    </svg>
  );
};

export default GeoSeosanTwo;
