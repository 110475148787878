
// Comp
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import React from 'react';
import BizManagementMeain from './meain/bizManagementMeain';
import BizManagementContentTab from './bizManagementContentTab';
import BizManagementAsHist from './manageHist/bizManagementAsHist';
import { getToken } from '../../../../service/authService';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 신청자 및 설비 관리정보 & A/S보수이력 및 관리이력 탭 부분 컴포넌트
*/
const BizManagementContents = ({
  search , setSearch, convMngtStyles, conn
  , Input , DropDownList, DatePickerKo, DateTimePickerKo, kendo, Button, Window, Upload
  , roundNum , numFormat
  , dataList , setDataList, setMeainInfo
  , saveMeainInfoData , saveMeainInfoDataList
  , Grid, Column, GridNoRecords
  , getMeainAsList
  , getSelectMeainInfoOrAsHistByTab, saveMeainAsInfoData
  , getMeainMngtFileList
  , searchKeyWord ,makrMeainModlfilterChange ,filterInitialList
  , codeList
}) => {

  const token = getToken();

  // 탭 정보
  const tabValue = search.mngt.tabStatus; 

  // 탭 선택
  const selectTabItem = (e) => {
    // 탭 상태 설정 부분
    setSearch( (search) => {
      const item = {...search};
      item.mngt.tabStatus = e.selected;
      return item;
    } );

    // 만약 설치 계획서가 선택된 상태에서 탭 클릭 시 데이터 불러오도록 로직 적용
    getSelectMeainInfoOrAsHistByTab();
  }

  return (
    <div class="uk-width-medium-1-2">
      <TabStrip selected={search.mngt.tabStatus} onSelect={selectTabItem} className={convMngtStyles.convMngtTab}>
        <TabStripTab title="신청자 및 설비 관리정보" key = {"meainInfo"}>
            <div className={convMngtStyles.tabsContents}>
              <BizManagementMeain convMngtStyles = {convMngtStyles} Input = {Input}  
                                  DropDownList = {DropDownList} DatePickerKo = {DatePickerKo} DateTimePickerKo = {DateTimePickerKo} kendo = {kendo}
                                  meainInfo = {dataList.meainInfo} setDataList = {setDataList} initialDs = {dataList.initialDs}
                                  search = {search} setMeainInfo = {setMeainInfo}
                                  searchKeyWord = {searchKeyWord} makrMeainModlfilterChange = {makrMeainModlfilterChange}
              />
            </div>
        </TabStripTab>
        <TabStripTab title="A/S 보수이력 및 관리이력" key = {"meainAsHist"}>
            <div className={convMngtStyles.tabsContents}>
              <BizManagementAsHist
                convMngtStyles = {convMngtStyles} Input = {Input} conn = {conn}
                kendo = {kendo} asHistList = {dataList.asHistList} mngtFileList = {dataList.mngtFileList}
                search = {search}
                Grid = {Grid} Column = {Column} GridNoRecords = {GridNoRecords} Button = {Button}
                getMeainAsList = {getMeainAsList} saveMeainAsInfoData = {saveMeainAsInfoData}
                token = {token}
                meainInfo = {dataList.meainInfo}
                getMeainMngtFileList = {getMeainMngtFileList} DatePickerKo = {DatePickerKo}
              />
            </div>
        </TabStripTab>
      </TabStrip>  
      { tabValue === 0 ?
        <>
          <BizManagementContentTab Button = {Button} convMngtStyles = {convMngtStyles} saveMeainInfoData={saveMeainInfoData} Window = {Window} Upload = {Upload} kendo = {kendo} 
                                token = {token}
                                saveMeainInfoDataList = {saveMeainInfoDataList}
                                filterInitialList = {filterInitialList}
                                meainInfo = {dataList.meainInfo} codeList = {codeList}
          />  
        </>
        :
        <></>
      }
    </div>
  );
};

export default BizManagementContents;