import GongjuDashboard from "./dashboard";
import GongjuHeader from "./header";
import "./index.css";

const GongjuMainPage = ({isMapView}) => {
  return (
    <div className="gongju-display">
      {!isMapView ? <GongjuHeader /> : <div style={{marginTop:'30px'}}></div>}
      <GongjuDashboard />
    </div>
  );
};

export default GongjuMainPage;
