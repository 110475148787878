import "./ConvertedAmount.css";

export default function ConvertedAmount({ recPrice, smpPrice, totalPrice }) {
  return (
    <div className="today-asan-container">
      <h3 className="asan-card-title">발전량 환산 금액</h3>
      <div className="asan-card">
        <h3 className="asan-detail-title">오늘 시세</h3>
        <div className="asan-amount">
          <div>SMP</div>
          <div>
            {smpPrice.toLocaleString()} <span className="won">원</span>
          </div>
        </div>
        <div className="asan-amount">
          <div>REC</div>
          <div>
            {recPrice.toLocaleString()} <span className="won">원</span>
          </div>
        </div>
        <div className="asan-amount-card">
          <h3 className="asan-detail-title">오늘 수익</h3>
          <div>
            {Number(totalPrice.toFixed()).toLocaleString()} <span className="amount-won">원</span>
          </div>
        </div>
      </div>
    </div>
  );
}
