import React, { useEffect, useState } from 'react';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 태양열(자연순환식) 정보
*/
const BizManagementMeainHeatNature = ({
  Input, convMngtStyles, DropDownList, meainInfo , initialDs, search, DatePickerKo, DateTimePickerKo, setMeainInfo
  ,onchageDate, onChangeDateTiem, dateFormat, onchageInput, onchageDropdown
  , makrList, meainModelList
  , searchKeyWord ,makrMeainModlfilterChange
}) => {

  // 자연 순환식은 clrmtModlNo(열량계 모델이 설비 번호이다.);
  const [wtrHtrMakr , setWtrHtrMakr] = useState( meainInfo.wtrHtrMakrNo ? { makrSeriNo : meainInfo.wtrHtrMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '' } );
  const [wtrHtrModl , setWtrHtrModl] = useState( meainInfo.wtrHtrModlNo ? { meainTypeSeriNo : meainInfo.wtrHtrModlNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [clrmtMakr , setClrmtMakr] = useState( meainInfo.clrmtMakrNo ? { makrSeriNo : meainInfo.clrmtMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  const [clrmtModl , setClrmtModl] = useState( meainInfo.clrmtModlNo ? { meainTypeSeriNo : meainInfo.clrmtModlNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );

  // 온수기 제조사 목록
  const wtrHtrMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30016 && c.makrTerm.includes(searchKeyWord.wtrHtrMakrKey)) : [];
  // 온수기 모델 목록
  const wtrHtrModlList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15002 && c.makrSeriNo === meainInfo.wtrHtrMakrNo 
    && c.meainTypeTerm.includes(searchKeyWord.wtrHtrModlKey) ) : [];
  // 열량계 제조사
  const clrmtMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30013 && c.makrTerm.includes(searchKeyWord.clrmtMakrKey)) : [];
  // 열량계 모델 
  const clrmtModlList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15002 && c.makrSeriNo === meainInfo.clrmtMakrNo 
    && c.meainTypeTerm.includes(searchKeyWord.clrmtModlKey) ) : [];     

  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.wtrHtrMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        wtrHtrMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.wtrHtrMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setWtrHtrMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setWtrHtrMakr({ makrSeriNo: '', makrTerm: '' });
      }
      if( meainInfo.clrmtMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        clrmtMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.clrmtMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setClrmtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setClrmtMakr({ makrSeriNo: '', makrTerm: '-' });
      }
    }else{
      setWtrHtrMakr({ makrSeriNo: '', makrTerm: '' });
      setClrmtMakr({ makrSeriNo: '', makrTerm: '-' });
    }
  }
  // 모델 Converter
  const modlConverter = () => {    
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.wtrHtrModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        wtrHtrModlList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.wtrHtrModlNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setWtrHtrModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setWtrHtrModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( meainInfo.clrmtModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        clrmtModlList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.clrmtModlNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setClrmtModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
    }else{
      setWtrHtrModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
    }
  }

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    /* eslint-disable-next-line */
  }, [meainInfo]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  return (
    <>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;집열기 매수</th>
        <td>
            <Input 
            id = "socoShtno" name = "socoShtno"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.socoShtno ? meainInfo.socoShtno : "" } onChange = {onchageInput} 
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            min = {0}
              />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;집열기 면적(㎡)</th>
        <td>
            <Input 
            id = "socoArea" name = "socoArea"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.socoArea ? meainInfo.socoArea : "" } onChange = {onchageInput} 
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
              />
        </td>
      </tr>
      <tr>
        <th>온수기 제조사</th>
        <td>
            <DropDownList 
            id = "wtrHtrMakrNo" name = "wtrHtrMakrNo"  className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            value = { wtrHtrMakr } data = {wtrHtrMakrList}
            dataItemKey="makrSeriNo" textField = "makrTerm"
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setWtrHtrMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
        <th>온수기 모델명</th>
        <td>
            <DropDownList 
            id = "wtrHtrModlNo" name = "wtrHtrModlNo"  className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            value = { wtrHtrModl } data = {wtrHtrModlList} 
            disabled = { meainInfo.wtrHtrMakrNo ? false : true }
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm"
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo };
            setWtrHtrModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
      </tr>
      <tr>
        <th>열량계 제조사</th>
        <td>
            <DropDownList 
            id = "clrmtMakrNo" name = "clrmtMakrNo" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            value = { clrmtMakr } data = {clrmtMakrList}
            defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
            dataItemKey="makrSeriNo" textField = "makrTerm"
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setClrmtMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
        <th>열량계 모델</th>
        <td>
            <DropDownList 
            id = "clrmtModlNo" name = "clrmtModlNo"  className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            value = { clrmtModl } data = {clrmtModlList} disabled = { meainInfo.clrmtMakrNo ? false : true }
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm"
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo , "meainTypeSeriNo" : e.value.meainTypeSeriNo }; 
            setClrmtModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } }
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender} 
              ></DropDownList>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;방위각</th>
        <td>
            <Input 
            id = "azimuth" name = "azimuth"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.azimuth ? meainInfo.azimuth : "" } onChange = {onchageInput} 
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
            />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;경사각</th>
        <td>
            <Input 
            id = "incliAngle" name = "incliAngle"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.incliAngle ? meainInfo.incliAngle : "" } onChange = {onchageInput} 
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
            />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;축열탱크용량(L)</th>
        <td>
            <Input 
            id = "rthrmTankCapa" name = "rthrmTankCapa"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.rthrmTankCapa ? meainInfo.rthrmTankCapa : "" } onChange = {onchageInput} 
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
              />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;축열탱크재질</th>
        <td>
            <Input 
            id = "rthrmTankQual" name = "rthrmTankQual"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.rthrmTankQual ? meainInfo.rthrmTankQual : "" } onChange = {onchageInput}  
            // required = {true} validationMessage = {inputValidMsg.normalText}
            />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;시공업체</th>
        <td>
            <Input 
            id = "consEnte" name = "consEnte"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.consEnte ? meainInfo.consEnte : "" } onChange = {onchageInput}   
            // required = {true} validationMessage = {inputValidMsg.normalText}
            />
        </td>
        <th>시공업체 연락처</th>
        <td>
            <Input 
            id = "consEnteCntct" name = "consEnteCntct"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.consEnteCntct ? meainInfo.consEnteCntct : "" } onChange = {onchageInput} 
            placeholder = "하이픈(-) 없이 입력해주세요."
              />
        </td>
      </tr>
      <tr>
        { meainInfo.moniYn === "Y" ?
          <>
            <th>
              <span className={ convMngtStyles.required }>*</span>&nbsp;설치확인관리번호
            </th>
            <td>
              <Input id = "instCnfrmCode" name = "instCnfrmCode" type="text" 
                    className={convMngtStyles.inputTerm} 
                    value = {meainInfo.instCnfrmCode ? meainInfo.instCnfrmCode : ""}
                    onChange = {onchageInput} />
            </td>
            <th></th>
            <td></td>
          </> :
          <>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
          </> 
        }
      </tr>
    </>
  );
};

export default BizManagementMeainHeatNature;