import { ConsmCharts } from "./consmCharts";

// CSS
import "./consm.css";
// import "./consm.dk.css";
import styles from "./consm.module.css";
import { Search } from "@material-ui/icons";

// Service
import { getConsmDashboard, getConsmList, getConsmCharts, getExcelDownload } from "../../../../service/stats/event/consmEventService";
import { numFormat } from "../../../../com/dataUtil";
import DatePickerKo from "../../../com/dateInputs/datePickerKo";

// React
import React, { useState } from "react";
import { BeatLoader } from "react-spinners";

// Kendo
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import kendo from "@progress/kendo-ui";
import UserSearchList from "../../../com/userSearch/userSearchList";

function Consm({ conn, codeList, cityList, rgnList }) {
  const [loading, setLoading] = useState(false);

  // 페이징
  const [paging, /*setPaging*/] = useState({ take: 10, skip: 0 });
  // 정렬
  const [sort, setSort] = useState([{ field: "", dir: "desc" }]);
  // 페이징 이벤트
  const onPageChange = e => {
    paging.skip = e.page.skip;
    paging.take = e.page.take;
    setConsmList();
  };
  // 정렬 이벤트
  const onSortChange = e => {
    const { sort } = e;
    setSort(sort);
  };

  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 검색 조건
  const [search, setSearch] = useState({
    userId: "",
    consmVisible: false,
    start: new Date(),
    end: new Date(),
    endMin: new Date(),
    startMax: new Date(),
    dateState: { codeNo: 23001, codeVal: "오늘" },
  });

  // 검색버튼 클릭 후 저장되는 데이터
  const [/*searchClick*/, setSearchClick] = useState({
    userId: "",
    start: new Date(),
    end: new Date(),
  });

  // 날짜 조회 드롭다운
  // const dateList = codeList.filter(e => e.grpCodeNo === 23);

  // 데이터 소스
  const [dataSource, setDataSource] = useState({
    dashboard: [],
    list: [],
    listTotal: 0,
    charts: [], //카테고리 ,고장 ,경고 배열 3개로
    xCategories: [],
    warnData: [],
    errData: [],
  });

  const downloadExcel = () => {
    const data = {
      start: search.start,
      end: search.end,
      userId: search.userId,
    };
    getExcelDownload(data, paging);
  };

  // 유저 ID 검색
  const searchUserId = e => {
    setSearch(search => {
      const item = { ...search };
      item.consmVisible = true;
      return item;
    });
  };

  // 상단 카테고리 검색버튼 클릭 이벤트
  const setSearchClickEvt = e => {
    paging.skip = 0;
    paging.take = 10;

    setSearchClick(state => {
      const item = { ...state };
      item.userId = search.userId;
      item.start = search.start;
      item.end = search.end;
      return item;
    });

    // e.preventDefault();

    setConsmDashboard();
    setConsmList();
    setConsmCharts();
  };

  // ocurDtmCell
  const ocurDtmCell = data => {
    var dtm = kendo.toString(new Date(data.dataItem.ocurDtm), "yyyy-MM-dd HH:mm");
    return <td>{dtm}</td>;
  };

  // Dashboard
  const setConsmDashboard = () => {
    setLoading(true);
    getConsmDashboard(conn, search)
      .then(response => {
        setDataSource(dataSource => {
          const item = { ...dataSource };
          item.dashboard = response;
          return item;
        });
        setLoading(false);
      })
      .catch(error => {
        console.log("error = ", error);
      });
  };

  // List
  const setConsmList = () => {
    setLoading(true);
    getConsmList(conn, search, paging)
      .then(response => {
        setDataSource(dataSource => {
          const item = { ...dataSource };
          item.list = response.results;
          item.listTotal = response.total;
          return item;
        });
        setLoading(false);
      })
      .catch(error => {
        console.log("error = ", error);
      });
  };

  // Charts
  const setConsmCharts = () => {
    setLoading(true);
    getConsmCharts(conn, search)
      .then(response => {
        const xCategories = [];
        const warnData = [];
        const errData = [];
        for (let i = 0; i < 24; i++) {
          let errCnt = 0;
          let warnCnt = 0;
          let name = "";
          response.forEach(element => {
            if (i < 10) {
              name = "0" + i;
            } else {
              name = String(i);
            }
            if (name === element.hh) {
              errCnt = element.err;
              warnCnt = element.warn;
            }
          });
          warnData.push({ name: name, y: warnCnt });
          errData.push({ name: name, y: errCnt });
          xCategories.push(name);
        }
        setDataSource(dataSource => {
          const item = { ...dataSource };
          item.xCategories = xCategories;
          item.warnData = warnData;
          item.errData = errData;
          return item;
        });
        setLoading(false);
      })
      .catch(error => {
        console.log("error = ", error);
      });
  };

  // UserId 선택
  const selectUserId = e => {
    // 수용가 선택 시 기존 데이터 초기화
    dataSource.dashboard = [];
    dataSource.list = [];
    dataSource.listTotal = [];
    dataSource.charts = [];
    dataSource.xCategories = [];
    dataSource.warnData = [];
    dataSource.errData = [];
    // 검색 조건 설정
    setSearch(search => {
      const item = { ...search };
      item.userId = e.dataItem.userId;
      item.userTerm = e.dataItem.userTerm;
      item.rgnCode = e.dataItem.rgnCode;
      item.dongCode = e.dataItem.fixbylawBundCode;
      item.consmVisible = false;
      return item;
    });
  };

  // 윈도우창 닫기
  const setUserInfoVisible = () => {
    setSearch(search => {
      const item = { ...search };
      item.consmVisible = false;
      return item;
    });
  };

  return (
    <LocalizationProvider language="ko-KR">
      <IntlProvider locale="ko">
        <>
          <div
            style={
              loading
                ? {
                    opacity: 0.6,
                    pointerEvents: "none",
                  }
                : null
            }></div>
          <div className="uk-grid">
            <div className="uk-width-medium-1-1">
              <div className="md-card TBgA">
                <div className="md-card-content small-padding">
                  <span className="searchSubTitleA">수용가</span>
                  <span className={styles.userInfoArea}>
                    <div className={styles.SetSearch}>
                      <Input
                        id="userTerm"
                        type="text"
                        className={styles.SetSearchTerm}
                        placeholder="설치자"
                        readOnly
                        value={search.userTerm}
                      />
                    </div>
                    <div className={styles.SetSearch}>
                    <Button icon="search" onClick={searchUserId}></Button>
                    </div>
                  </span>
                  <span className={styles.weatherSearchA}>날짜검색</span>
                  <span className={styles.weatherSearchB}>
                    <DatePickerKo
                      id="start"
                      name="start"
                      className="datePicker"
                      defaultValue={today}
                      format="yyyy-MM-dd"
                      setDtm={v => {
                        setSearch(search => {
                          const item = { ...search };
                          item.start = v;
                          item.end = v;
                          return item;
                        });
                      }}
                      max={search.startMax}
                      value={search.start}
                    />
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <Button className="inqBtn" onClick={setSearchClickEvt}>
                      <Search className="material-icons" />
                    </Button>
                  </span>
                  {search.consmVisible && (
                    <UserSearchList
                      conn={conn}
                      setUserInfo={selectUserId}
                      setVisible={setUserInfoVisible}
                      initialTop={"10%"}
                      initialLeft={200}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          {dataSource.list.length !== 0 ? (
            <div className="consmBoard">
              <div className="consmBoardSub">
                <div>
                  <span>총 설비 경보</span>
                  <br />
                  <span className="consmBoardContent">
                    <b>{dataSource.dashboard ? dataSource.dashboard.ttl >= 0 ? numFormat(dataSource.dashboard.ttl) : 0 : 0}</b>
                    <span>개소</span>
                  </span>
                </div>
              </div>
              <div className="consmBoardSub">
                <div>
                  <span>설비 경고</span>
                  <br />
                  <span className="consmBoardContent">
                    <b>{dataSource.dashboard ? dataSource.dashboard.warn >= 0 ? numFormat(dataSource.dashboard.warn) : 0 : 0}</b>
                    <span>개소</span>
                  </span>
                </div>
              </div>
              <div className="consmBoardSub">
                <div>
                  <span>설비 고장</span>
                  <br />
                  <span className="consmBoardContent">
                    <b>{dataSource.dashboard ? dataSource.dashboard.err >= 0 ? numFormat(dataSource.dashboard.err) : 0 : 0}</b>
                    <span>개소</span>
                  </span>
                </div>
              </div>
              {/* <div className="consmBoardSub">
                <div>
                  <span>수동 조치시간</span>
                  <br />
                  <span className="consmBoardContent">
                    <b>
                      {numFormat(dataSource.dashboard.manaTm)}
                      <span>분</span>
                    </b>
                  </span>
                </div>
              </div> */}
            </div>
          ) : (
            <div className={styles.noDataCommnet}>경보 데이터가 존재 하지 않습니다. 사용자를 선택해주세요.</div>
          )}
          {dataSource.list.length !== 0 ? (
            <div>
              <div className="uk-grid">
                <div className="uk-width-medium-1-1">
                  <div className="md-card">
                    <div className="md-card-content">
                      <div className="md-card-toolbar">
                        <h3 className="md-card-toolbar-heading-text">경보 발생 신호 시간대별 그래프</h3>
                      </div>
                      <ConsmCharts xCategories={dataSource.xCategories} warnData={dataSource.warnData} errData={dataSource.errData} />
                    </div>
                  </div>
                </div>
                <div className="uk-width-medium-1-1">
                  <div className="md-card">
                    <div className="md-card-content">
                      <div className="md-card-toolbar">
                        <h3 className="md-card-toolbar-heading-text">경보 발생 신호 상세정보</h3>
                        <div className="md-card-toolbar-actions">
                          <Button className={styles.consmExcelBtnClass} onClick={downloadExcel} icon="excel">
                            엑셀저장
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Grid
                      className="eventConsmGrid"
                      style={{ height: "460px" }}
                      data={orderBy(dataSource.list, sort)}
                      total={dataSource.listTotal}
                      skip={paging.skip}
                      take={paging.take}
                      page={paging}
                      onPageChange={onPageChange}
                      sort={sort}
                      onSortChange={onSortChange}
                      sortable
                      pageable={{
                        type: "numeric",
                        pageSizes: [5, 10, 20, 50, 100],
                        previousNext: true,
                        buttonCount: 5,
                      }}>
                      <GridColumn title="CID" field="cid" width="150px" />
                      <GridColumn title="설비명" field="alias" width="200px" />
                      <GridColumn title="발생시간" field="ocurDtm" width="200px" cell={ocurDtmCell} />
                      <GridColumn title="제조사" field="makrTerm" width="200px" />
                      <GridColumn title="모델" field="meainTypeTerm" width="200px" />
                      <GridColumn title="발생유형" field="codeVal" width="110px" />
                      <GridColumn field="eventCtnt" title="발생내역" />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {loading && (
            <p
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </>
      </IntlProvider>
    </LocalizationProvider>
  );
}
export default Consm;
