import HighchartsReact from 'highcharts-react-official';
import { numFormat } from '../../../com/dataUtil';

const TroubleShootingStateChart = ({ stateChartData }) => {
    
    let totalCnt = 0;
    stateChartData.map((item) => {
        totalCnt += item.y;
    })
    
    let operChart = {
        legend: {
            verticalAlign: "top",
            align: "center",
            itemStyle: {
              fontSize: "16px",
            },
        },
        series: [{
            innerSize: '80%',
            showInLegend: true,
            data: stateChartData,
            zIndex: 10,  
        }],
        chart: {
            type: 'pie',
            height: 308,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
        },
        credits: {
            enabled: false
        },
        title: {
            text: "전체<br>" + totalCnt,
            y: 50,
            verticalAlign: 'middle',
            className: "operTitle",
            style: { "color": "#333", "fontSize": "25px", "font-weight": "bold" }
        },
        tooltip: {
            shared: true,
            formatter: function () {
                let msg = "";
                msg +=
                    '<span style="color:' +
                    this.point.color +
                    '">\u25CF</span> ' +
                    this.point.name +
                    " : " +
                    "<b>" +
                    numFormat(this.point.y) +
                    "건</b>" +
                    "<br>";

                return msg;
            },
        },
        plotOptions: {
            pie: {
                borderWidth: 0,
                // allowPointSelect: true,
                size: 210,
                dataLabels: {
                    enabled: false,
                },
                stickyTracking: false,
            }
        },
    };

    return (
        <>
            <HighchartsReact options={operChart}></HighchartsReact>
        </>
    )
}

export default TroubleShootingStateChart;