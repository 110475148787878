import React from 'react';
import LocalAreaMonitorToTalStatInfo from './localAreaMonitorToTalStatInfo';

/**
 * 오른쪽 총 통계 데이터 컴포넌트
 * @param {styles , totalEnergyData ,CountUp} param0 
 * @returns 
 */
const LocalAreaMonitorToTalStat = ({
  styles , totalEnergyData ,CountUp
}) => {

  // 안내 팝업 마우스 엔터 이벤트
  const onMouseEnter = (e) => {
    const totalBoxInfo = document.getElementById("totalStatBoxInfo");
    const localAreaTotalStat = document.getElementById("localAreaTotalStat");
    totalBoxInfo.style.display = "block";
    totalBoxInfo.style.left = localAreaTotalStat.style.left + 100 + "px";
    totalBoxInfo.style.top = localAreaTotalStat.style.top + 20 +  "px";
  }

  // 안내 팝업 마우스 리브 이벤트 
  const onMouseLeave = () => {
    const totalBoxInfo = document.getElementById("totalStatBoxInfo");
    totalBoxInfo.style.display = "none";
  }


  return (
    <div className={styles.totalAreaCenter} onMouseEnter = {onMouseEnter} onMouseLeave = {onMouseLeave} >
      <div id = "localAreaTotalStat" className={styles.totalArea}>
        <table>
            <tr>
                <th>                 
                  <CountUp end={totalEnergyData.totalMeainCnt} separator="," duration={1.5} decimals={0} delay={2} redraw={true} />
                </th>
                <td>
                 개
                </td>
            </tr>
            <tr>
                <th>
                  <CountUp end= {totalEnergyData.totalPower } separator="," duration={1.5} decimals={1} delay={2} redraw={true} />
                </th>
                <td className = {styles.totalUnit}>
                    MWh
                </td>
            </tr>
            <tr>
                <th>
                <CountUp end= {totalEnergyData.totalUse} separator="," duration={1.5} decimals={1} delay={2} redraw={true} />
                </th>
                <td className = {styles.totalUnit}>
                    MWh
                </td>
            </tr>
        </table>
        <div>
          <LocalAreaMonitorToTalStatInfo styles = {styles} totalEnergyData = {totalEnergyData} />
        </div>
      </div>
    </div>
  );
};

export default LocalAreaMonitorToTalStat;