import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../com/dataUtil";

 const OperationStatAreaToday = ({state}) => {
  // 지역운영현황
  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });

  let areaChart = {
    chart: { type: "column", zoomType: "xy", height: 320 },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        // 발전효율 축
        labels: {
          format: "{value:,.1f}",
          style: {
            color: "#9ACD32",
          },
        },
        title: {
          text: "발전효율 (%)",
          style: {
            color: "#9ACD32",
          },
        },
        opposite: true,
      },
      {
        // 생산효율 축
        labels: {
          format: "{value:,.1f}",
          style: {
            color: "#FAED7D",
          },
        },
        title: {
          text: "생산효율 (%)",
          style: {
            color: "#FAED7D",
          },
        },
        opposite: true,
      },
      {
        // 설비용량 축
        labels: {
          format: "{value:,.2f}",
          style: {
            color: "#87CEEB",
          },
        },
        title: {
          text: "설비용량 (MW)",
          style: {
            color: "#87CEEB",
          },
        },
      },
      {
        // 개소 축
        labels: {
          format: "{value:,.0f}",
          style: {
            color: "#F08080",
          },
        },
        title: {
          text: "개소",
          style: {
            color: "#F08080",
          },
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { 
      shared: true ,
      formatter: function() {
        return(
          `
            <span><b>${this.points[0].key}</b></span><br>
            <span style="color:${this.points[0].point.color}">\u25CF</span>
            <span>${this.points[0].series.name}</span> : <b>${numFormat(this.points[0].y)} 개수</b><br>
            <span style="color:${this.points[1].point.color}">\u25CF</span>
            <span>${this.points[1].series.name}</span> : <b>${numFormat(this.points[1].y)} MW</b><br>
            <span style="color:${this.points[2].point.color}">\u25CF</span>
            <span>${this.points[2].series.name}</span> : <b>${numFormat(roundNum(this.points[2].y , 2))} %</b><br>
            <span style="color:${this.points[3].point.color}">\u25CF</span>
            <span>${this.points[3].series.name}</span> : <b>${numFormat(roundNum(this.points[3].y , 2))} %</b>
          `
        );
      }
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    plotOptions: {
      column: {
        dataLabels: { 
          enabled: true ,
          color : '#333',
          style: { textShadow: false },
          formatter: function() { 
            return `<span><b>${numFormat(roundNum(this.y , 2))}</b></span>`
          }
        }
      },
    },
    series: [
      {
        name: "개소",
        tooltip: {
          valueSuffix: " 개수",
        },
        yAxis: 3,
        color: "#F08080",
        data: state.chartCount,
        labels: {
          format: "{value:,.0f}",
          style: {
            color: "#F08080",
          },
        },
      },
      {
        name: "용량",
        tooltip: {
          valueSuffix: " MW",
        },
        color: "#87CEEB",
        yAxis: 2,
        data: state.chartInstCapa,
        labels: {
          format: "{value:,.0f}",
          style: {
            color: "#F08080",
          },
        },
      },
      {
        name: "발전효율",
        tooltip: {
          valueSuffix: "%",
        },
        color: "#9ACD32",
        data: state.chartPowerEfic,
      },
      {
        name: "생산효율",
        tooltip: {
          valueSuffix: "%",
        },
        yAxis: 1,
        color: "#FAED7D",
        data: state.chartCalEfic,
      }
    ]
  };

  return (
    <div className="uk-width-medium-1-1">
      <div className="md-card">
        <div className="md-card-content">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">지역운영현황</h3>
            {/* <div className="md-card-toolbar-actionsLeft">
                <ul id="select-period" data-role="buttongroup" className="km-widget km-buttongroup k-widget k-button-group">
                    <li className="k-button km-button k-state-active km-state-active"><span className="k-text km-text">금일</span></li>
                    <li className="k-button km-button"><span className="k-text km-text">어제</span></li>
                    <li className="k-button km-button"><span className="k-text km-text">3일</span></li>
                </ul>
            </div> */}
          </div>
          <div>
            <HighchartsReact options = {areaChart}/>         
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationStatAreaToday;
