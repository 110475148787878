import { downloadFile } from '../../service/fileService';

import { Window } from "@progress/kendo-react-dialogs";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import kendo from "@progress/kendo-ui";

import { Upload } from "@progress/kendo-react-upload";
import { getToken } from '../../service/authService';
const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignRight,
  AlignCenter,
  // Indent,
  // Outdent,
  InsertImage,
  OrderedList,
  CleanFormatting,
  UnorderedList,
  // Undo,
  // Redo,
  // Link,
  // Unlink,
  FontSize,
  // FontName,
  ForeColor,
  // FormatBlock,
} = EditorTools;

const QnaReply = ({
  windowReply,
  checkState,
  qnaState,
  qnaAddState,
  cancelReply,
  updateReply,
  resetButtonClick,
  deleteButtonClick,
  updateButtonClick,
  addQestnButtonClick,
  replyButtonClick,
  addQestnReplyButtonClick,
  formChange,
  styles,
  DropDownList,
  dropStatusKind,
  statusCondition,
  replyChange,
  sjChange,
  ctntChange,
  addSjChange,
  addCtntChange,
  regiChange,
  saveQna,
  setReply,
  saveAddQna,
  saveAddQnaReply,
  qnaAddReplyState,
  addReplyCtntChange,
  updateAddQestn,
  updateAddQestnReply,
  showFileState,
  onAddFile,
  onRemoveFile,
  onStatusChangeFile,
  fileState,
  loginUserTypeCode
}) => {
  const isUse = loginUserTypeCode === 2003 || loginUserTypeCode === 2005 ? true : false;
  
  const CSS = `p {color: white;}`;
  const onMount = (event) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(CSS));
    iframeDocument.head.appendChild(style);
  };

  console.log('qnaReply: ', qnaState);

  return (
    <>
      <Window
        className="qna-info"
        title={"내용"}
        left={windowReply.left}
        top={windowReply.top}
        width={windowReply.width}
        height={windowReply.heiht}
        initialHeight={checkState.answerCtnt != null ? 760 : 620}
        initialWidth={1000}
        onClose={cancelReply}
      >
        <div className="uk-form-stacked">
          <div className="k-form">
            <div className="btn_wrapper">
              <button
                style={{background:'transparent'}}
                className="k-button k-button-icontext 22k-grid-add"
                onClick={resetButtonClick}
              >
                목록
              </button>
              {qnaState.answerCtnt == null && (
                isUse &&
                <button
                  style={{background:'transparent'}}
                  className="k-button k-button-icontext 22k-grid-add"
                  onClick={replyButtonClick}
                >
                  답변
                </button>
              )}
              {isUse &&                
                <button
                  style={{background:'transparent'}}
                  className="k-button k-button-icontext 22k-grid-add"
                  onClick={deleteButtonClick}
                >
                  삭제
              </button>}
              {isUse &&
                checkState.answerCtnt == null &&
                <button
                  style={{background:'transparent'}}
                  className="k-button k-button-icontext 22k-grid-add"
                  onClick={updateButtonClick}
                >
                  수정
                </button>}
              {qnaState.answerCtnt != null ?
              checkState.addCtnt != null ? 
                  checkState.addReplyCtnt == null ?
                     isUse&&
                <button
                  style={{background:'transparent'}}
                  className="k-button k-button-icontext 22k-grid-add"
                  onClick={addQestnReplyButtonClick}
                >
                  추가답변
                </button>
              :null:null:null}
              {qnaState.answerCtnt != null ?
                checkState.addCtnt==null?
                  checkState.addAnswerCtnt == null ?
                    
                <button
                  style={{background:'transparent'}}
                  className="k-button k-button-icontext 22k-grid-add"
                  onClick={addQestnButtonClick}
                >
                  추가접수
                </button>:null
              :null:null}
            </div>
            {/* 답변폼 메인 */}
            
            {!formChange.boolState ? (
              <table className={styles.answerForm}>
                <tr>
                  <th>작성자명(업체)</th>
                  <td>
                    <input
                      className={styles.inputUnvisible}
                      value={qnaState.companyTerm}
                      readOnly
                    ></input>
                  </td>
                </tr>
                <tr>
                  <th>작성일자</th>
                  <td>
                    <input
                      className={styles.inputUnvisible}
                      value={kendo.toString(new Date(qnaState.regiDtm), "yyyy-MM-dd")}
                      readOnly
                    ></input>
                  </td>
                </tr>
              
                  <tr>
                    <th>조회횟수</th>
                    <td>
                      <input
                        className={styles.inputUnvisible}
                        value={qnaState.viewCnt}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                <tr>
                  <th className="test">질의응답처리상태</th>
                  <td>
                    {qnaState.procStatusCode === 0 ? (
                      <input
                        className={styles.inputUnvisible}
                        value="답변 진행중"
                        readOnly
                      ></input>
                    ) : (
                      <input
                        className={styles.inputUnvisible}
                        value="답변 완료"
                        readOnly
                      ></input>
                    )}
                  </td>
                </tr>
                  {!formChange.viewAddQestn ?
                <tr>
                  <th>질문제목</th>
                  <td>
                    <input
                      className={styles.inputUnvisible}
                      value={qnaState.qestnSj}
                      readOnly
                    ></input>
                  </td>
                </tr> : <tr>
                  <th>질문제목</th>
                  <td>
                    <input
                      className={styles.inputUnvisible}
                      value={qnaState.qestnSj2}
                      readOnly
                    ></input>
                  </td>
                </tr> }
          
                <tr>
                  <th>질문내용</th>
                  <td>
                    <>
                    <div className={styles.gridContents}>
                      <span
                        className={styles.fixSpan2}
                        dangerouslySetInnerHTML={{
                          __html: qnaState.qestnCtnt,
                        }}
                      >
                      </span>
                      {/* {showFileState.fileList.length > 0 ? showFileState.fileList.map((item,index) => (                        
                        <table className={styles.fileList} key={index}>
                          <thead>
                            <tr>
                              <th>파일이름</th>
                              <th>파일사이즈(KB)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.size}</td>
                              <td>   
                                <button style={{background:'transparent'}} className={styles.downBtn} onClick={() =>downloadFile(item)} >
                                  다운로드
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )): null} */}
                    </div>
                    </>                    
                  </td>
                </tr> 
                {showFileState.fileList.length > 0 ? showFileState.fileList.map((item, index) => (
                  <tr>
                    <th>파일</th>
                    <td>
                      <div className={styles.fileDiv}>
                        <table className={styles.fileTableStyle} key={index}>
                          <thead>                          
                            <tr>
                              <th>파일이름</th>
                              <th>파일사이즈(KB)</th>
                            </tr>                          
                          </thead>
                          <tbody>
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.size}</td>
                              <td>
                                <button className={styles.downBtn}                           
                                  onClick={() =>downloadFile(item)}>
                                  다운로드
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )) : null}                               
                <br></br>
                {/* 답변버튼 클릭 후 */}
                {formChange.replyState && (
                  <>
                    <tr>
                      <th>진행상태</th>
                      <td>
                        <span>
                          <DropDownList
                            data={dropStatusKind}
                            defaultValue="접수대기"
                            onChange={statusCondition}
                            className="CustomFilterDropDown1"
                          />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>답변내용</th>
                      <td>
                        <LocalizationProvider language="ko-KR">
                          <IntlProvider locale="ko">
                            <Editor
                              type="text"
                              name="reply"
                              onChange={replyChange}
                              tools={[
                                [Bold, Italic, Underline, CleanFormatting],
                                [FontSize],
                                [ForeColor],
                                [OrderedList, UnorderedList],
                                [AlignLeft, AlignCenter, AlignRight],
                                [InsertImage],
                              ]}
                              onMount={onMount}
                            />
                          </IntlProvider>
                        </LocalizationProvider>
                      </td>
                    </tr>
                  </>
                )}

                {/* 답변 있는 데이터에 답변내용 출력 */}
                {checkState.answerCtnt != null && (
                  <>
                    <hr></hr>
                    <tr>
                      <th>답변일자</th>
                      <td>
                        <input
                          className={styles.inputUnvisible}
                          value={kendo.toString(new Date(qnaState.replyDtm), "yyyy-MM-dd")}
                          readOnly
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <th>답변내용</th>
                      <td>
                        <span
                          className={styles.fixSpan}
                          dangerouslySetInnerHTML={{
                            __html: checkState.answerCtnt,
                          }}
                        ></span>
                        {isUse&&
                          <button
                            style={{background:'transparent'}}
                            className="k-button k-button-icontext k-grid-add"
                            onClick={updateReply}
                          >
                            답변수정
                        </button>}
                        <br></br>
                      </td>
                    </tr>
                  </>
                )}
                {/* 추가문의 있는 데이터에 추가문의내용 출력 */}
                {checkState.addCtnt != null && (
                  <>
                    <hr></hr>
                    <tr>
                      <th>추가접수일자</th>
                      <td>
                        <input
                          className={styles.inputUnvisible}
                          value={kendo.toString(new Date(qnaAddState.regiDtm), "yyyy-MM-dd")}
                          
                          readOnly
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <th>추가접수제목</th>
                      <td>
                        <input
                          className={styles.inputUnvisible}
                          value={qnaAddState.qestnSj}
                          readOnly
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <th>추가접수내용</th>
                      <td>
                        <span
                          className={styles.fixSpan}
                          dangerouslySetInnerHTML={{
                            __html: checkState.addCtnt,
                          }}
                        ></span>
                        {checkState.addReplyCtnt == null &&
                          <button
                            style={{background:'transparent'}}
                            className="k-button k-button-icontext k-grid-add"
                            onClick={updateAddQestn}
                          >
                            추가접수수정
                        </button>}
                      </td>
                    </tr>
                  </>
                )}
                {/* 추가답변 있는 데이터에 추가답변내용 출력 */}
                {checkState.addReplyCtnt != null && (
                  <>
                    <br></br>
                    <hr></hr>
                    <tr>
                      <th>추가답변일자</th>
                      <td>
                        <input
                          className={styles.inputUnvisible}
                          value={kendo.toString(new Date(qnaAddReplyState.regiDtm), "yyyy-MM-dd")}
                          
                          readOnly
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <th>추가답변내용</th>
                      <td>
                        <span
                          className={styles.fixSpan}
                          dangerouslySetInnerHTML={{
                            __html: checkState.addReplyCtnt,
                          }}
                        ></span>
                        {isUse&&
                          <button
                            style={{background:'transparent'}}
                            className="k-button k-button-icontext k-grid-add"
                            onClick={updateAddQestnReply}
                          >
                            추가답변수정
                        </button>}
                      </td>
                    </tr>
                  </>
                )}
                {/* ing 1*/}
                {formChange.replyUpdate && (
                  <>
                    <tr>
                      <th>진행상태</th>
                      <td>
                        <span>
                          <DropDownList
                            data={dropStatusKind}
                            defaultValue="진행상태"
                            onChange={statusCondition}
                            className="CustomFilterDropDown1"
                          />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>답변내용 수정</th>
                      <td>
                        <LocalizationProvider language="ko-KR">
                          <IntlProvider locale="ko">
                            <Editor
                              type="text"
                              name="replyCtnt"
                              defaultContent={qnaState.answerCtnt}
                              onChange={replyChange}
                              // contentStyle={{ width: 1030, height: 370 }}
                              tools={[
                                [Bold, Italic, Underline, CleanFormatting],
                                [FontSize],
                                [ForeColor],
                                [OrderedList, UnorderedList],
                                [AlignLeft, AlignCenter, AlignRight],
                                [InsertImage],
                              ]}
                              onMount={onMount}
                            />
                          </IntlProvider>
                        </LocalizationProvider>

                        <button
                          style={{background:'transparent'}}
                          // role="button"
                          className="k-button k-button-icontext k-grid-add"
                          onClick={setReply}
                        >
                          수정완료
                        </button>
                      </td>
                    </tr>
                  </>
                )}
                {formChange.addQestnUpdate && (
                  <>
                    <tr>
                      <th>추가접수 내용 수정</th>
                      <td>
                        <LocalizationProvider language="ko-KR">
                          <IntlProvider locale="ko">
                            <Editor
                              type="text"
                              name="replyCtnt"
                              defaultContent={qnaAddState.ctnt}
                              onChange={addCtntChange}
                              // contentStyle={{ width: 1030, height: 370 }}
                              tools={[
                                [Bold, Italic, Underline, CleanFormatting],
                                [FontSize],
                                [ForeColor],
                                [OrderedList, UnorderedList],
                                [AlignLeft, AlignCenter, AlignRight],
                                [InsertImage],
                              ]}
                              onMount={onMount}
                            />
                          </IntlProvider>
                        </LocalizationProvider>

                        <button
                          style={{background:'transparent'}}
                          className="k-button k-button-icontext k-grid-add"
                          onClick={saveAddQna}
                        >
                          수정완료
                        </button>
                      </td>
                    </tr>
                  </>
                )}
                {formChange.addQestnReplyUpdate && (
                  <>
                    <tr>
                      <th>추가답변 내용 수정</th>
                      <td>
                        <LocalizationProvider language="ko-KR">
                          <IntlProvider locale="ko">
                            <Editor
                              type="text"
                              name="addReplyCtnt"
                              defaultContent={qnaAddReplyState.ctnt}
                              onChange={addReplyCtntChange}
                              // contentStyle={{ width: 1030, height: 370 }}
                              tools={[
                                [Bold, Italic, Underline, CleanFormatting],
                                [FontSize],
                                [ForeColor],
                                [OrderedList, UnorderedList],
                                [AlignLeft, AlignCenter, AlignRight],
                                [InsertImage],
                              ]}
                              onMount={onMount}
                            />
                          </IntlProvider>
                        </LocalizationProvider>

                        <button
                          style={{background:'transparent'}}
                          className="k-button k-button-icontext k-grid-add"
                          onClick={saveAddQnaReply}
                        >
                          수정완료
                        </button>
                      </td>
                    </tr>
                  </>
                )}
                {/* 추가문의버튼 클릭 후 */}
                {formChange.addQestnBody && (
                  <>
                    <tr>
                      <th>추가제목</th>
                      <td>
                        <input
                          value={qnaAddState.qestnSj}
                          onChange={addSjChange}
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <th>추가내용</th>
                      <td>
                        <LocalizationProvider language="ko-KR">
                          <IntlProvider locale="ko">
                            <Editor
                              type="text"
                              name="addQestn"
                              onChange={addCtntChange}
                              // contentStyle={{ width: 1030, height: 370 }}
                              tools={[
                                [Bold, Italic, Underline, CleanFormatting],
                                [FontSize],
                                [ForeColor],
                                [OrderedList, UnorderedList],
                                [AlignLeft, AlignCenter, AlignRight],
                                [InsertImage],
                              ]}
                              onMount={onMount}
                            />
                          </IntlProvider>
                        </LocalizationProvider>
                      </td>
                    </tr>
                  </>
                )}
                {/* 추가답변버튼 클릭 후 */}
                {formChange.addQestnReplyBody && (
                  <>
                    <br></br>

                    <tr>
                      <th>추가답변내용</th>
                      <td>
                        <LocalizationProvider language="ko-KR">
                          <IntlProvider locale="ko">
                            <Editor
                              type="text"
                              name="addReplyQestn"
                              onChange={addReplyCtntChange}
                              // contentStyle={{ width: 1030, height: 370 }}
                              tools={[
                                [Bold, Italic, Underline, CleanFormatting],
                                [FontSize],
                                [ForeColor],
                                [OrderedList, UnorderedList],
                                [AlignLeft, AlignCenter, AlignRight],
                                [InsertImage],
                              ]}
                              onMount={onMount}
                            />
                          </IntlProvider>
                        </LocalizationProvider>
                      </td>
                    </tr>
                  </>
                )}
              </table>
            ) : null}
            {/* 수정버튼클릭 후 */}
            {formChange.boolState && (
              <table className="test2">
                <tr>
                  <th>작성자명</th>
                  <td>
                    <input
                      className={styles.inputVisible}
                      value={qnaState.regiTerm}
                      onChange={regiChange}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <th>작성일자</th>
                  <td>
                    <input
                      className={styles.inputUnvisible}
                      value={kendo.toString(new Date(qnaState.regiDtm), "yyyy-MM-dd")}
                      readOnly
                    ></input>
                  </td>
                </tr>
                <tr>
                  <th>조회횟수</th>
                  <td>
                    <input
                      className={styles.inputUnvisible}
                      value={qnaState.viewCnt}
                      readOnly
                    ></input>
                  </td>
                </tr>
                <tr>
                  <th>질의응답처리상태</th>
                  <td>
                    {qnaState.procStatusCode === 1 ? (
                      <input
                        className={styles.inputUnvisible}
                        value="답변 완료"
                        readOnly
                      ></input>
                    ) : (
                      <input
                        className={styles.inputUnvisible}
                        value="답변 진행중"
                        readOnly
                      ></input>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>질문제목</th>
                  <td>
                    <input
                      className={styles.inputVisible}
                      value={qnaState.qestnSj}
                      onChange={sjChange}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <th>질문내용</th>
                  <td>
                      <div className="demo-hint">
                      최대 업로드 용량 : <strong>10MB</strong>
                      <LocalizationProvider language="ko-KR">
                        <IntlProvider locale="ko">
                          <Upload
                            autoUpload={false}
                            batch={true}
                            multiple={true}
                            files={fileState.fileList}
                            defaultFiles={showFileState.fileList.length > 0 ?
                              (
                                // showFileState.fileList.map((item, index) => 
                                // { return item[index] })
                                showFileState.fileList
                              ):[]}
                            onAdd={onAddFile}
                            onRemove={onRemoveFile}
                            onStatusChange={onStatusChangeFile}
                            withCredentials={true}
                            restrictions={{
                              allowedExtensions: [".gif", ".jpg", ".png", ".xls", ".xlsx", ".hwp", ".doc", ".pdf", ".exe", ".zip"],
                              maxFileSize: 10240000,
                            }}
                            saveMethod={"post"}
                            saveHeaders={{
                              "Content-Type": "multipart/form-data",
                              "X-AUTH-TOKEN": getToken()
                            }}
                            saveUrl={"/file/upload?fileInfo=" + encodeURI(JSON.stringify(fileState.currentFiles))}
                            removeUrl={"/file/delete"}
                          />
                        </IntlProvider>
                      </LocalizationProvider>
                    </div>              
                    <LocalizationProvider language="ko-KR">
                      <IntlProvider locale="ko">
                        <Editor
                          type="text"
                          name="ctnt"
                          defaultContent={qnaState.qestnCtnt}
                          onChange={ctntChange}
                          onMount={onMount}
                          // contentStyle={{ width: 1030, height: 370 }}
                          tools={[
                            [Bold, Italic, Underline, CleanFormatting],
                            [FontSize],
                            [ForeColor],
                            [OrderedList, UnorderedList],
                            [AlignLeft, AlignCenter, AlignRight],
                            [InsertImage],
                          ]}
                        />
                      </IntlProvider>
                    </LocalizationProvider>
                  </td>
                </tr>
              </table>
            )}

            <br></br>
            <div className="btnbox">
              {/* 답변버튼클릭 전, 후 */}
              <button   
                style={{background:'transparent'}}       
                className="k-button k-button-icontext k-grid-add"
                onClick={cancelReply}
              >
                취소
              </button>
              {/*                   
                    <a
                      role="button"
                      className="k-button k-button-icontext 22k-grid-add"
                      onClick={!formChange.replyState ? saveQna : setReply}
                    >
                      저장
                    </a> */}

              {/* 추가접수버튼클릭 전, 후 */}
              {formChange.addQestnBody &&  <button
                    style={{background:'transparent'}}
                    className="k-button k-button-icontext 22k-grid-add"
                    onClick={saveAddQna}
                  >
                추가접수저장
                  </button>}
               {formChange.replyState &&  <button
                    style={{background:'transparent'}}                    
                    className="k-button k-button-icontext 22k-grid-add"
                    onClick={setReply}
                  >
                답변저장
                  </button>}
              {formChange.addQestnReplyBody && <button
                    style={{background:'transparent'}}
                    className="k-button k-button-icontext 22k-grid-add"
                    onClick={saveAddQnaReply}
                  >
                추가답변저장
                  </button>}
              {formChange.boolState ?
                !formChange.addQestnBody ? (
                !formChange.addQestnReplyBody ? (
                  <button
                  style={{background:'transparent'}}                    
                    className="k-button k-button-icontext 22k-grid-add"
                    onClick={!formChange.replyState ? saveQna : setReply}
                  >
                    수정저장
                  </button>
                ) : (
                  <button
                    style={{background:'transparent'}}                    
                    className="k-button k-button-icontext 22k-grid-add"
                    onClick={saveAddQnaReply}
                  >
                    추가답변 저장
                  </button>
                )
              ) : (
                <button
                  style={{background:'transparent'}}
                  className="k-button k-button-icontext 22k-grid-add"
                  onClick={saveAddQna}
                >
                  추가접수 저장
                </button>
              ):null}
            </div>
          </div>
        </div>
      </Window>
    </>
  );
};
export default QnaReply;
