import React from "react";
import { saveBizExpense } from "../../../../service/bizStat/com/expenseApiService";

const ExpenseModal = ({
  conn,
  styles,
  bizStyles,
  search,
  dataList,
  setDataList,
  getData,
  bizExpenseGridAdd,
  kendo,
  Form,
  FormElement,
  Button,
  NumberFormat,
}) => {
  const deleteCommas = str => {
    return str.replace(/,/g, "");
  };

  const onBizExpenseChange = e => {
    //input에 name을 가진 요소의 value에 이벤트
    const { name, value } = e.target;

    /* eslint-disable-next-line */
    var regExp = /[ \{\}\[\]\/?.;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;

    if (regExp.test(value)) {
      kendo.alert("소수점없이 입력해주세요");
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
    } else {
      // 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
      const nextInputs = dataSource => {
        // 스프레드 문법으로 기존의 객체를 복사한다.
        const item = { ...dataSource };
        item.bizExpenseInput[name] = deleteCommas(value);
        return item;
      };

      // 만든 변수를 seInput으로 변경해준다.
      setDataList(nextInputs);

      setValue();
    }
  };

  const setValue = () => {        
    setDataList(data => {
      const item = { ...data };      
      item.bizExpenseInput.bizYy = search.bizYyNo;
      item.bizExpenseInput.rgnCode = search.rgnCode;
      item.bizExpenseInput.cityProvCode = search.cityProvCode;
      item.bizExpenseInput.totalExpense =
        parseInt(item.bizExpenseInput.gvmSpt === "" ? 0 : item.bizExpenseInput.gvmSpt) +
        parseInt(item.bizExpenseInput.province === "" ? 0 : item.bizExpenseInput.province) +
        parseInt(item.bizExpenseInput.city === "" ? 0 : item.bizExpenseInput.city) +
        parseInt(item.bizExpenseInput.priCompany === "" ? 0 : item.bizExpenseInput.priCompany);
      item.bizExpenseInput.mupal =
        parseInt(item.bizExpenseInput.city === "" ? 0 : item.bizExpenseInput.city) +
        parseInt(item.bizExpenseInput.province === "" ? 0 : item.bizExpenseInput.province);
      return item;
    });
  };

  const saveBizExpenseEvent = e => {
    console.log("dataList", dataList.bizExpenseInput);
    dataList.bizExpenseInput.bizSctCode = search.bizSctCode;
    saveBizExpense(conn, dataList.bizExpenseInput).then(results => {
      // console.log("result",results)
      kendo.alert("사업비 입력을 완료 하였습니다.");
      bizExpenseGridAdd();
    });
  };

  const cancelBizExpense = e => {
    bizExpenseGridAdd();
  };

  return (
    <div className={bizStyles.bizExpenseModal}>
      <div className={bizStyles.bizExpenseModalTitle}>
        <h3 style={{ textAlign: "left" }}>사업비 입력</h3>
        <button class="btn_iconMM b-close">
          <i class="material-icons" style={{ color: "black", fontSize: "20px" }} onClick={cancelBizExpense}>
            X
          </i>
        </button>
      </div>
      <div>
        <Form
          render={() => (
            <FormElement>
              <table class={bizStyles.emcTable}>
                <colgroup>
                  <col width="10" />
                  <col width="40" />
                </colgroup>
                <tbody>
                  <tr style={{ border: "solid 1px #dedede" }}>
                    <th>정부(원)</th>
                    <td>
                      <NumberFormat
                        thousandSeparator={true}
                        name="gvmSpt"
                        type="text"
                        className={styles.borderE4}
                        defaultValue={dataList.bizExpenseInput.gvmSpt.toString() !== "" ? dataList.bizExpenseInput.gvmSpt : ""}
                        onChange={onBizExpenseChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>도비(원)</th>
                    <td>
                      <NumberFormat
                        thousandSeparator={true}
                        name="province"
                        type="text"
                        className={styles.borderE4}
                        defaultValue={dataList.bizExpenseInput.province.toString() !== "" ? dataList.bizExpenseInput.province : ""}
                        onChange={onBizExpenseChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>시/군비(원)</th>
                    <td>
                      <NumberFormat
                        thousandSeparator={true}
                        name="city"
                        type="text"
                        className={styles.borderE4}
                        defaultValue={dataList.bizExpenseInput.city.toString() !== "" ? dataList.bizExpenseInput.city : ""}
                        onChange={onBizExpenseChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>민간(원)</th>
                    <td>
                      <NumberFormat
                        thousandSeparator={true}
                        name="priCompany"
                        type="text"
                        className={styles.borderE4}
                        defaultValue={dataList.bizExpenseInput.priCompany.toString() !== "" ? dataList.bizExpenseInput.priCompany : ""}
                        onChange={onBizExpenseChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button title="saveBizExpense" className="saveButton" onClick={saveBizExpenseEvent}>
                저장
              </Button>
              <Button title="cancelBizExpense" className="cancelButton" onClick={cancelBizExpense}>
                취소
              </Button>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default ExpenseModal;
