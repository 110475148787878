import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons'
import { yearList } from '../../../com/dropDownDs';
import { isInstaller } from '../../../service/authService';
import styles from './locationSearch.module.css';

// 좌측 검색 목록
const LocationSearch = ({ 
  codeList,
  getAreaInstallerList,
  map,
  bizYyRef, bldSrvCodeNoRef, bizSctRef, userTermRef
}) => {
  const bizSctList = codeList.filter( c => c.grpCodeNo === 34 );
  const bldSrvList = codeList.filter( c => c.grpCodeNo === 31 );

  return(
    <div>
      <table className="chmapTable-A">
        <tbody>
          <tr>
            <td>
              <div className="Mapdropdown">
              <DropDownList data = {yearList()} className = "searchDropDown"
                disabled = {isInstaller()} defaultItem = "사업연도" ref={bizYyRef}/>
              </div>
            </td>
            <td>
              <div className="Mapdropdown">
              <DropDownList data = {bldSrvList} className = "searchDropDown" 
                disabled = {isInstaller()} defaultItem = {{ codeNo : "" ,  codeVal : "건물용도" }} 
                dataItemKey="codeNo" textField = "codeVal" ref={bldSrvCodeNoRef} />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="Mapdropdown">
              <DropDownList data = {bizSctList} className = "searchDropDown" 
                disabled = {isInstaller()} defaultItem =  {{ codeNo : "" ,  codeVal : "사업구분" }} 
                dataItemKey="codeNo" textField = "codeVal" ref={bizSctRef}/>
              </div>
            </td>  
            <td>
              <div className="MapSearch"> 
                <input type = "text" name = "userTerm" className={["MapSearchTerm", styles.searchUserTerm].join(' ')} placeholder = "설치자명" 
                   disabled = {isInstaller()} ref={userTermRef}
                />
              </div>
            </td>
            </tr>
            <tr>
              <td colSpan="2">
                <Button id = "inqBtn" className="MapSearchBtn"  name = "inqBtn" 
                  onClick={(e) => {
                    e.preventDefault();
                    getAreaInstallerList(map, {
                      bizYy: bizYyRef.current.value ==="사업연도" ?"":bizYyRef.current.value
                      , bldSrvCodeNo : bldSrvCodeNoRef.current.value.codeNo
                      , bizSct : bizSctRef.current.value.codeNo
                      , userTerm : userTermRef.current.value
                      }
                    );
                  }} >검 색</Button>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  );
}

export default LocationSearch;