//React
import React, { useState, useEffect } from "react";
import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";
import { getMeainModel, setDeleteMeainModel, setUpdateMeainModel } from "../../../service/meainModelService";

//CSS
import "./meainModel.css";
import { Create, Delete } from "@material-ui/icons";

//kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn as Column, GridToolbar, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import MeainModelModal from "./meainModelModal";
import { getMakr } from "../../../service/makrService";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

// 로딩 스피너
import { BeatLoader } from "react-spinners";

const MeainModel = ({ conn, codeList }) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [makrData, setMakrTermData] = useState([]);
  // 그리드 상태(페이징, 정렬, 필터)
  const [dataState, setDataState] = useState({
    take: 20,
    skip: 0,
    total: 0,
    sort: [],
    meainTypeTerm: "",
    makrClsfCode: "",
    makrTerm: "",
    ensoTypeCode: "",
    meainTypeExpl: "",
    authYn: "",
    filter: {
      filter: {
        logic: "and",
        filters: [],
      },
    },
  });
  /* 추가, 수정용 팝업창 State */
  const [modalItem, setModalItem] = useState({
    title: "",
    item: [],
    visible: false,
  });

  // 필터 드롭다운
  const [filterDropdown, setFilterDropdown] = useState({
    makr: { codeNo: "", codeVal: "제조사 분류" },
    ensoType: { codeNo: "", codeVal: "에너지원" },
    auth: { code: "", text: "인증여부" },
  });

  // 설비 모델 조회 리스트
  const getMeainModelList = () => {
    setLoading(true);
    getMeainModel(conn, dataState)
      .then((result) => {
        console.log("getMeainModel result = ", result);

        result.forEach((element) => {
          element.makrClsfTerm = codeList.filter((v) => v.grpCodeNo === 30 && v.codeNo === element.makrClsfNo)[0].codeVal;
        });
        setDataSource(result);
        setLoading(false);
      })
      .catch((e) => console.log("getMeainModel Error!", e));
  };

  useEffect(() => {
    getMeainModelList();
    getMakr(conn)
      .then((result) => {
        setMakrTermData(result);
      })
      .catch((e) => console.log("getMakr Error!", e));
  // eslint-disable-next-line
  }, [codeList]);

  /* 필터 드롭다운 */
  // 제조사 분류 필터 드롭다운
  const filterMakrClsf = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
      setFilterDropdown((filterDropdown) => {
        const item = { ...filterDropdown };
        item.makr = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <DropDownList
          data={codeList.filter((c) => c.grpCodeNo === 30)}
          onChange={filterOnchage}
          defaultItem={{ codeNo: "", codeVal: "제조사 분류" }}
          className="CustomFilterDropDown"
          name="makrClsfTerm"
          textField="codeVal"
          dataItemKey="codeNo"
          value={filterDropdown.makr}
        />
      </div>
    );
  };

  // 에너지원 드롭다운
  const filterEnsoTyp = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
      setFilterDropdown((filterDropdown) => {
        const item = { ...filterDropdown };
        item.ensoType = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <DropDownList
          data={codeList.filter((c) => c.grpCodeNo === 15)}
          onChange={filterOnchage}
          defaultItem={{ codeNo: "", codeVal: "에너지원" }}
          className="CustomFilterDropDown"
          name="ensoTypeTerm"
          textField="codeVal"
          dataItemKey="codeNo"
          value={filterDropdown.ensoType}
        />
      </div>
    );
  };

  // 인증여부 드롭다운
  const filterAuth = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
      setFilterDropdown((filterDropdown) => {
        const item = { ...filterDropdown };
        item.auth = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <DropDownList
          data={[
            { code: "Y", text: "Y" },
            { code: "N", text: "N" },
          ]}
          onChange={filterOnchage}
          defaultItem={{ code: "", text: "인증여부" }}
          className="CustomFilterDropDown"
          name="ensoTypeTerm"
          textField="text"
          dataItemKey="code"
          value={filterDropdown.auth}
        />
      </div>
    );
  };

  /* 페이징 이벤트 */
  const pageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  /* 정렬 이벤트 */
  const sortChange = (event) => {
    if (event.sort.length === 0) {
      setDataState({
        ...dataState,
        sort: [
          {
            field: "",
            dir: "",
          },
        ],
      });
    } else {
      setDataState({
        ...dataState,
        sort: [
          {
            field: event.sort[0].field,
            dir: event.sort[0].dir,
          },
        ],
      });
    }
  };

  /* 필터 이벤트 */
  const filterChange = (event) => {
    dataState.skip = 0;
    dataState.take = 20;
    console.log("event = ", event);
    dataState.filter = event.filter;
    if (event.filter) {
      console.log("event.filter = ", event.filter);
      const filterValue = event.filter;
      console.log("filterValue = ", filterValue);
      filterValue.filters.forEach((element) => {
        const field = element.field;
        if (field === "meainTypeTerm") {
          dataState.meainTypeTerm = element.value;
        } else if (field === "makrClsfTerm") {
          dataState.makrClsfCode = element.value.codeNo;
        } else if (field === "makrTerm") {
          dataState.makrTerm = element.value;
        } else if (field === "meainTypeExpl") {
          dataState.meainTypeExpl = element.value;
        } else if (field === "ensoTypeTerm") {
          dataState.ensoTypeCode = element.value.codeNo;
        } else if (field === "authYn") {
          dataState.authYn = element.value.code;
        }
      });
    }
    console.log("dataState = ", dataState);
    getMeainModelList();

    // setDataState(dataState => {
    //   const item = {...dataState};
    //   item.filter = event.filter;
    //   return item;
    // });
    // setDataSource(filterBy(backupDataSource, event.filter));
    // setDataState({
    //   ...dataState,
    //   filter: event.filter,
    // });
  };

  // 저장 시 값 설정
  const convertDsData = (dataItem) => {
    //에너지원
    if (dataItem.ensoTypeCode) {
      dataItem.ensoTypeCodeNo = dataItem.ensoTypeCode.codeNo;
      dataItem.ensoTypeTerm = dataItem.ensoTypeCode.codeVal;
    }
    // 분류 값
    if (dataItem.makrClsf) {
      dataItem.makrClsfNo = dataItem.makrClsf.codeNo;
      dataItem.makrClsfTerm = dataItem.makrClsf.codeVal;
    }
    // 제조사 값 convert
    if (dataItem.makr) {
      dataItem.makrSeriNo = dataItem.makr.makrSeriNo;
      dataItem.makrTerm = dataItem.makr.makrTerm;
    }
    return dataItem;
  };

  // RTU 업체 저장 시 Validation
  const rtuValidation = (dataItem) => {
    let isValid = true;
    let message = "";
    if (!dataItem.meainTypeTerm) {
      message = "설비 모델 명를 입력 해주세요.";
      isValid = false;
    } else if (!dataItem.ensoTypeCodeNo) {
      message = "에너지원을 선택 해주세요.";
      isValid = false;
    } else if (!dataItem.makrClsfNo) {
      message = "분류를 선택 해주세요.";
      isValid = false;
    } else if (!dataItem.makrSeriNo) {
      message = "제조사를 선택 해주세요.";
      isValid = false;
    }
    return { isValid: isValid, message: message };
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        {/* <h3 className="md-card-toolbar-heading-text">설비 모델 관리</h3> */}

        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              className="meainModelGrid"
              style={
                loading
                  ? {
                      opacity: 0.6,
                      pointerEvents: "none",
                      height: "80vh",
                    }
                  : { height: "80vh" }
              }
              data={orderBy(dataSource, dataState.sort).slice(dataState.skip, dataState.take + dataState.skip)}
              skip={dataState.skip}
              take={dataState.take}
              total={dataSource.length}
              pageable={{ buttonCount: 5, pageSizes: true }}
              onPageChange={pageChange}
              sortable
              sort={dataState.sort}
              onSortChange={sortChange}
              filterable={true}
              filter={dataState.filter}
              onFilterChange={filterChange}
              editField={"inEdit"}
            >
              <GridToolbar>
                <Button
                  className="excelBtn"
                  // style={{ backgroundColor: 'white' }}
                  icon="add"
                  onClick={() => {
                    const newRecord = {
                      meainTypeSeriNo: "",
                      ensoTypeCode: { codeNo: "", codeVal: "전체" },
                      makrClsf: { codeNo: "", codeVal: "전체" },
                      makr: { makrSeriNo: "", makrTerm: "제조사" },
                    };
                    setDataSource([newRecord, ...dataSource]);
                    setModalItem({
                      title: "추가",
                      item: {
                        meainTypeSeriNo: newRecord.meainTypeSeriNo,
                        ensoTypeCode: newRecord.ensoTypeCode,
                        makrClsf: newRecord.makrClsf,
                        makr: newRecord.makr,
                      },
                      visible: true,
                    });
                  }}
                >
                  추가
                </Button>
              </GridToolbar>
              <Column field="meainTypeTerm" title="모델명" width="300px" filterCell={GridColumnInputFilter} />
              <Column field="makrClsfTerm" title="분류" width="200px" filterCell={filterMakrClsf} />
              <Column field="makrTerm" title="제조사" width="450px" filterCell={GridColumnInputFilter} />
              <Column field="ensoTypeTerm" title="에너지원" width="150px" filterCell={filterEnsoTyp} />
              <Column field="meainTypeExpl" title="설명" filterCell={GridColumnInputFilter} />
              <Column field="authYn" title="인증여부" width={100} filterCell={filterAuth} />
              <Column
                title="수정"
                filterable={false}
                width="64"
                cell={(props) => (
                  <td>
                    <button id="modelEditBtn"
                      onClick={() => {
                        setModalItem({
                          title: "수정",
                          item: props.dataItem,
                          visible: !modalItem.visible,
                        });
                      }}
                    >
                    <i>
                      <Create />
                    </i>
                    </button>
                  </td>
                )}
              />
              <Column
                title="삭제"
                filterable={false}
                width="64"
                cell={(props) => (
                  <td>
                    <button id="modelDelBtn"
                      onClick={() => {
                        kendo.confirm(props.dataItem.meainTypeTerm + "의 데이터를 정말 삭제하시겠습니까?").then(() => {
                          setDeleteMeainModel(conn, props.dataItem)
                            .then(() => {
                              const index = dataSource.findIndex((record) => record.meainTypeSeriNo === props.dataItem.meainTypeSeriNo);
                              const result = [...dataSource];
                              result.splice(index, 1);
                              setDataSource(result);
                            })
                            .catch((e) => console.log("deleteMeainModel Error", e));
                        });
                      }}
                    >
                    <i>
                      <Delete />
                    </i>
                    </button>
                  </td>
                )}
              />
              <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
        {loading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
        {modalItem.visible && (
          <MeainModelModal
            title={modalItem.title}
            item={modalItem.item}
            codeList={codeList}
            makrData={makrData}
            cancelEdit={() => {
              if (modalItem.title === "추가") {
                setDataSource(dataSource.filter((item) => item.meainTypeSeriNo !== modalItem.item.meainTypeSeriNo));
              }
              setModalItem({
                title: "",
                item: [],
                visible: !modalItem.visible,
              });
            }}
            onSubmit={(dataItem) => {
              if (modalItem.title === "추가") {
                console.log("추가 dataItem = ", dataItem);
                // 값 설정
                dataItem = convertDsData(dataItem);
                // Validation
                const isValid = rtuValidation(dataItem);
                if (isValid.isValid === false) {
                  kendo.alert(isValid.message);
                  return;
                }
                if (dataItem.authYn === undefined) dataItem.authYn = "Y";
                dataItem.useYn = "Y";
                setUpdateMeainModel(conn, dataItem)
                  .then(() => {
                    kendo.alert("저장되었습니다.");
                    getMeainModelList();
                  })
                  .catch((e) => {
                    // API 호출이 실패한 경우
                    setDataSource(dataSource.filter((item) => item.meainTypeSeriNo !== ""));
                    kendo.alert("저장을 실패 하였습니다.");
                    console.log("updateMeainModel Error!", e); // 에러표시
                  })
              } else if (modalItem.title === "수정") {
                // Validation
                const isValid = rtuValidation(dataItem);
                if (isValid.isValid === false) {
                  kendo.alert(isValid.message);
                  return;
                }
                // 값 설정
                dataItem = convertDsData(dataItem);
                setUpdateMeainModel(conn, dataItem)
                  .then(() => {
                    kendo.alert("저장되었습니다.");
                    getMeainModelList();
                  })
                  .catch((e) => console.log("updateMeainModel Error!", e));
              }
              // window 창 닫기
              setModalItem({
                title: "",
                item: [],
                visible: !modalItem.visible,
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MeainModel;
