// Mui
import { DesktopWindows, Place, QueryStats, BarChart, InsertDriveFile, Dvr, ChatBubble, Settings } from "@material-ui/icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom";


const MenuNavDefault = menu => {
	let menuHtml;
	let iconClass = "material-icons";
	let location = useLocation();

	switch (menu.menu.id) {
      /**
       * 사이드 메뉴바 1
       */
      case "010000":
        if (location.pathname.includes("/monitor", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <DesktopWindows className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 2
       */
      case "020000":
        if (location.pathname.includes("/location", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <Place className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 3
       */
      case "030000":
        if (location.pathname.includes("/bizStat", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <QueryStats className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 4
       */
      case "040000":
        if (location.pathname.includes("/ai", 0)) {
          iconClass += " material-icons-active ";
        }
        menuHtml = (
          <span className="menu_icon">
            <img src="/images/AI.png" className={[iconClass, "aiActive"].join(" ")} alt="aiIcon" />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 5
       */
      // case "040000":
      case "050000":
        if (location.pathname.includes("/stats", 0) || location.pathname.includes("/analy/raw", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <BarChart className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 6
       */
      // case "050000":
      case "060000":
        if (location.pathname.includes("/report", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <InsertDriveFile className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 7
       */
      // case "060000":
      case "070000":
        if (location.pathname.includes("/sys", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <Dvr className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 8
       */
      // case "070000":
      case "080000":
        if (location.pathname.includes("/board", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <ChatBubble className={iconClass} />
          </span>
        );
        break;
      /**
       * 사이드 메뉴바 9
       */
      // case "080000":
      case "090000":
        if (location.pathname.includes("/mngt", 0)) {
          iconClass += " material-icons-active";
        }
        menuHtml = (
          <span className="menu_icon">
            <Settings className={iconClass} />
          </span>
        );
        break;

      default:
        break;
    }
    return menuHtml;
};


export default MenuNavDefault;