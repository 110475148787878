import { useCreateAxiosInstance } from "./../../../conInstance";
import { useState } from "react";
import { useEffect } from "react";
import Top from "./Top";
import "./index.css";
import Today from "./Today";
import ConvertedAmount from "./ConvertedAmount.jsx";
import Contribution from "./Contribution";
import DevelopmentState from "./DevelopmentState";
import { ReactComponent as EnergyCenter } from "./svg/nonsan-energy-center.svg";
import NonsanEnergy from "./NonsanEnergy";
import ShortCut from "./Shortcut";

export const NONSAN_CODE = 44230;

export default function Nonsan({isMapView}) {
  const { conn } = useCreateAxiosInstance();
  const [data, setData] = useState();

  const getPowerGeneration = async () => {
    const response = await conn.get(`monitor/installer/munic/energyData?rgnCode=${NONSAN_CODE}`);
    setData(response);
  };

  useEffect(() => {
    getPowerGeneration();
    //eslint-disable-next-line
  }, []);

  if (!data) return <div />;

  return (
    <div className="nonsan-container">
      {!isMapView ? <Top /> : <div style={{marginTop:'50px'}}></div>}
      <div className="nonsan-layout">
        <div className="nonsan-left-content">
          <div className="nonsan-left-top">
            <Today />
            <ConvertedAmount recPrice={data.recPrice} smpPrice={data.smpPrice} totalPrice={data.totalPrice} />
          </div>
          <Contribution
            totalCo2={data.totalCo2}
            totalTree={data.totalTree}
            totalPlasticCup={data.totalPlasticCup}
            totalPaperCup={data.totalPaperCup}
            totalCarDistance={data.totalCarDistance}
          />
        </div>
        <div className="nonsan-right-content">
          <div className="nonsan-right-top">
            <DevelopmentState solaDayPower={data.solaDayPower} heatDayPower={data.heatDayPower} geoDayPower={data.geoDayPower} />
          </div>
          <div className="nonsan-energy-center">
            <EnergyCenter />
          </div>
          <div className="nonsan-right-bottom">
            <NonsanEnergy />
            <ShortCut />
          </div>
        </div>
      </div>
    </div>
  );
}
