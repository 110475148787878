import React from "react";

import { DropDownList } from "@progress/kendo-react-dropdowns";
// import { Grid, GridColumn } from "@progress/kendo-react-grid";
// import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { monthList } from "../../../../../com/dropDownDs";

// import kendo from "@progress/kendo-ui";

const BizProgressStatisticsWeek = ({ search, setSearch, styles, dataList, setSearchMonth, getProgressWeek }) => {
  // 주별 데이터 묶음
  const weekData = dataList.progressWeekList;

  // 월 드롭다운 선택 시 이벤트
  const onItemChange = e => {
    const month = e.target.value.replace("월", "");
    // month = month;
    search.month = month.length === 1 ? "0" + month : month;
    setSearchMonth();
    getProgressWeek();
  };

  return (
    <>
      <div class="tbTitle">
        <span class="sel-title">[ 주별 진행률 ]</span>
        <span>월 선택</span>
        {/* <span class="selectboxHw"> */}
        <DropDownList
          id="month"
          name="month"
          value={search.month}
          data={monthList()}
          onChange={onItemChange}
          disabled={search.bizYy === "사업연도" && search.rgnCode === ""}
          className={styles.searchArea}
        />
        {/* </span> */}
        <span class="danright">단위 : 개소</span>
      </div>
      <table class="eer-Table eer-Table-striped">
        <colgroup>
          <col width="16.666%" />
          <col width="16.666%" />
          <col width="16.666%" />
          <col width="16.666%" />
          <col width="16.666%" />
          <col width="16.666%" />
        </colgroup>
        <thead>
          <tr>
            <td class="backslash left">
              <div>{search.month}월&nbsp;&nbsp;</div>&nbsp;&nbsp;에너지원
            </td>
            <th>1주차</th>
            <th>2주차</th>
            <th>3주차</th>
            <th>4주차</th>
            <th>5주차</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>태양광</td>
            <td>{weekData[39001].week1}</td>
            <td>{weekData[39001].week2}</td>
            <td>{weekData[39001].week3}</td>
            <td>{weekData[39001].week4}</td>
            <td>{weekData[39001].week5}</td>
          </tr>
          <tr>
            <td>태양열</td>
            <td>{weekData[39002].week1}</td>
            <td>{weekData[39002].week2}</td>
            <td>{weekData[39002].week3}</td>
            <td>{weekData[39002].week4}</td>
            <td>{weekData[39002].week5}</td>
          </tr>
          <tr>
            <td>소형풍력</td>
            <td>{weekData[39003].week1}</td>
            <td>{weekData[39003].week2}</td>
            <td>{weekData[39003].week3}</td>
            <td>{weekData[39003].week4}</td>
            <td>{weekData[39003].week5}</td>
          </tr>
          <tr>
            <td>지&nbsp;&nbsp;&nbsp;&nbsp;열</td>
            <td>{weekData[39004].week1}</td>
            <td>{weekData[39004].week2}</td>
            <td>{weekData[39004].week3}</td>
            <td>{weekData[39004].week4}</td>
            <td>{weekData[39004].week5}</td>
          </tr>
          <tr>
            <td>연료전지</td>
            <td>{weekData[39005].week1}</td>
            <td>{weekData[39005].week2}</td>
            <td>{weekData[39005].week3}</td>
            <td>{weekData[39005].week4}</td>
            <td>{weekData[39005].week5}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>완료[총계]</td>
            <td>{weekData.total.week1}</td>
            <td>{weekData.total.week2}</td>
            <td>{weekData.total.week3}</td>
            <td>{weekData.total.week4}</td>
            <td>{weekData.total.week5}</td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default BizProgressStatisticsWeek;
