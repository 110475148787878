export const saveSolaMeain = async (conn, data) => {
  const param = {
    cid: data.cid,
    modSeqNo: data.modSeqNo,
    meainDong: data.meainDong,
    meainTerm: data.meainTerm,
    modTerm: data.modTerm,
    pwrMp: data.pwrMp,
    vltMp: data.vltMp,
    curMp: data.curMp,
    vltOc: data.vltOc,
    curSc: data.curSc,
    tempVltCof: data.tempVltCof,
    tempCurCof: data.tempCurCof,
    cellType: data.cellType,
    operYear: data.operYear,
    vltSoil: data.vltSoil,
    curSoil: data.curSoil,
    vltAging: data.vltAging,
    curAging: data.curAging,
    arrSr: data.arrSr,
    arrPr: data.arrPr,
    dcLineLen: data.dcLineLen,
    dcLineThk: data.dcLineThk,
    invtTerm: data.invtTerm,
    invtCapa: data.invtCapa,
  };
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const response = await conn.post("kier/meain", param, config);

  return response;
};

export const getSolaMeainPage = async (conn, data) => {
  const { take, skip, sort, filter } = data;
  const config = {
    params: {
      page: Math.floor((take + skip) / take),
      size: take,
      sort,
    },
  };
  if (filter !== undefined) {
    if (filter.cid !== undefined) config.params.cid = filter.cid;
    if (filter.meainTerm !== undefined) config.params.meainTerm = filter.meainTerm;
  }

  const response = await conn.get("/kier/meain/page", config);
  return response;
};

export const delSolaMeain = async (conn, data) => {
  const response = await conn.delete("/kier/meain/" + data);

  return response;
};

export const getMeainTermList = async (conn) => {
  const response = await conn.get("/kier/meain/term/list");

  return response;
};
