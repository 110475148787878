import React, { useEffect, useState } from 'react';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 지열 정보
*/
const BizManagementMeainHeath = ({
  Input, convMngtStyles, DropDownList, meainInfo , initialDs,  setMeainInfo
  , DatePickerKo, DateTimePickerKo, onchageDate, onChangeDateTiem, dateFormat
  , onchageInput, onchageDropdown
  , searchKeyWord ,makrMeainModlfilterChange
}) => {

  // 변수 정의 (히트펌프 모델이 설비타입번호)
  const [heatPumpMakr , setHeatPumpMakr] = useState( meainInfo.heatPumpMakrNo ? { makrSeriNo : meainInfo.heatPumpMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  const [heatPumpModl , setHeatPumpModl] = useState( meainInfo.heatPumpModlNo ? { meainTypeSeriNo : meainInfo.heatPumpModlNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [hwClrmtMakr , setHwClrmtMakr] = useState( meainInfo.hwClrmtMakrNo ? { makrSeriNo : meainInfo.hwClrmtMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  const [hwClrmtModl , setHwClrmtModl] = useState( meainInfo.hwClrmtModlNo ? { modulTypeNo : meainInfo.hwClrmtModlNo , meainTypeTerm : "" } : { modulTypeNo : '' , meainTypeTerm : '' } );
  const [hcClrmtMakr , setHcClrmtMakr] = useState( meainInfo.hcClrmtMakrNo ? { makrSeriNo : meainInfo.hcClrmtMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  const [hcClrmtModl , setHcClrmtModl] = useState( meainInfo.hcClrmtModlNo ? { meainTypeSeriNo : meainInfo.hcClrmtModlNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [elctrMeterMakr , setElctrMeterMakr] = useState( meainInfo.elctrMeterMakrNo ? { makrSeriNo : meainInfo.elctrMeterMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '' } );
  const [elctrMeterModl , setElctrMeterModl] = useState( meainInfo.elctrMeterModlNo ? { modulTypeNo : meainInfo.elctrMeterModlNo , meainTypeTerm : "" } : { modulTypeNo : '' , meainTypeTerm : '' } );
  const [bntnMakr , setBntnMakr] = useState( meainInfo.bntnMakrNo ? { makrSeriNo : meainInfo.bntnMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  const [bntnType , setBntnType] = useState( meainInfo.bntnTypeNo ? { meainTypeSeriNo : meainInfo.bntnTypeNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );

  // 제조사 / 모델 데이터 소스
  const makrList = initialDs.makrList.length > 0 ? initialDs.makrList : [];
  const meainModelList = initialDs.meainModelList.length > 0 ? initialDs.meainModelList : []; 

  // 히트펌프 제조사 목록
  const heatPumpMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30005 && c.makrTerm.includes(searchKeyWord.heatPumpMakrKey) ) : [];
  // 히트펌프 모델 목록
  const heatPumpModlList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15003 && c.makrSeriNo === meainInfo.heatPumpMakrNo 
    && c.meainTypeTerm.includes(searchKeyWord.heatPumpModlKey) ) : [];
  // 급탕측 열량계 제조사 
  const hwClrmtMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30014 && c.makrTerm.includes(searchKeyWord.hwClrmtModlKey) ) : [];
  // 급탕측 열량계 모델 
  const hwClrmtModlList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15003 && c.makrSeriNo === meainInfo.hwClrmtMakrNo 
    && c.meainTypeTerm.includes(searchKeyWord.hcClrmtModlKey) ) : []; 
   // 냉난방측 열량계 제조사
  const hcClrmtMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30014 && c.makrTerm.includes(searchKeyWord.clrmtMakrHwKey) ) : [];
  // 냉난방측 열량계 모델 
  const hcClrmtModlList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15003 && c.makrSeriNo === meainInfo.hcClrmtMakrNo 
    && c.meainTypeTerm.includes(searchKeyWord.clrmtMakrHcKey) ) : []; 
  // 전력량계 제조사
  const elctrMeterMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30015 && c.makrTerm.includes(searchKeyWord.elctrMeterMakrKey) ) : [];
  // 전력량계 모델 
  const elctrMeterModlList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15003 && c.makrSeriNo === meainInfo.elctrMeterMakrNo 
    && c.meainTypeTerm.includes(searchKeyWord.elctrMeterModlKey) ) : []; 
  // 벤토나이트 
  const bntnMakrList = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30006 && c.makrTerm.includes(searchKeyWord.bntnMakrtKey) ) : [];
  // 벤토나이트 모델 
  const bntnTypeList = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15003 && c.makrSeriNo === meainInfo.bntnMakrNo 
    && c.meainTypeTerm.includes(searchKeyWord.bntnTypeKey) ) : []; 

  // 제조사 converter
  const makrConverter = () => {    
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.heatPumpMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        heatPumpMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.heatPumpMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setHeatPumpMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setHeatPumpMakr({ makrSeriNo: '', makrTerm: '-' });
      }
      if( meainInfo.hwClrmtMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        hwClrmtMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.hwClrmtMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setHwClrmtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setHwClrmtMakr({ makrSeriNo: '', makrTerm: '-' });
      }
      if( meainInfo.hcClrmtMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        hcClrmtMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.hcClrmtMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setHcClrmtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setHcClrmtMakr({ makrSeriNo: '', makrTerm: '-' });
      }
      if( meainInfo.elctrMeterMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        elctrMeterMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.elctrMeterMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setElctrMeterMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setElctrMeterMakr({ makrSeriNo: '', makrTerm: '-' });
      }
      if( meainInfo.bntnMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        bntnMakrList.forEach(element => {
          if( element.makrSeriNo === meainInfo.bntnMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setBntnMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setBntnMakr({ makrSeriNo: '', makrTerm: '-' });
      }
    }else{
      setHeatPumpMakr({ makrSeriNo: '', makrTerm: '-' });
      setHwClrmtMakr({ makrSeriNo: '', makrTerm: '-' });
      setHcClrmtMakr({ makrSeriNo: '', makrTerm: '-' });
      setElctrMeterMakr({ makrSeriNo: '', makrTerm: '-' });
      setBntnMakr({ makrSeriNo: '', makrTerm: '-' });
    }
  }
  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.heatPumpModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        heatPumpModlList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.heatPumpModlNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setHeatPumpModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setHeatPumpModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( meainInfo.hwClrmtModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        hwClrmtModlList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.hwClrmtModlNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setHwClrmtModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setHwClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( meainInfo.hcClrmtModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        hcClrmtModlList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.hcClrmtModlNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setHcClrmtModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setHcClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( meainInfo.elctrMeterModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        elctrMeterModlList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.elctrMeterModlNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setElctrMeterModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setElctrMeterModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( meainInfo.bntnTypeNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        bntnTypeList.forEach(element => {
          if( element.meainTypeSeriNo === meainInfo.bntnTypeNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setBntnType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setBntnType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
    }else{
      setHeatPumpModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setHwClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setHcClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setElctrMeterModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setBntnType({ meainTypeSeriNo: '', meainTypeTerm: '' });
    }
  }

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    /* eslint-disable-next-line */
  }, [meainInfo]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  console.log(" meainInfo =  ", meainInfo);

  return (
    <>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;설치일자
        </th>
        <td>
          <DateTimePickerKo 
                  id = "meainInstDtm" name = "meainInstDtm" className={convMngtStyles.inputTerm}
                  value = {dateFormat(meainInfo.meainInstDtm)} format = {"yyyy-MM-dd HH:mm"}
                  formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                  setDtm = {(v) => { onChangeDateTiem(v , "meainInstDtm"); }} 
                  // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;A/S 만료일자
        </th>
        <td>
          <DatePickerKo 
                id = "asExpiDtm" name = "asExpiDtm" className={convMngtStyles.inputTerm}
                value = {dateFormat(meainInfo.asExpiDtm)} 
                format="yyyy-MM-dd"
                formatPlaceholder={{ year: '년', month: '월', day: '일' }}
                setDtm = {(v) => { onchageDate(v , "asExpiDtm"); }}
                // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;시스템방식</th>
        <td>
          <DropDownList 
          id = "sysMthd" name = "sysMthd" className={convMngtStyles.inputTerm} data = { [ "-", "개방형" , "수직밀폐형" ] }
          value = { meainInfo.sysMthd ? meainInfo.sysMthd : "" } onChange = {onchageDropdown}
          // required = {true} validationMessage = {inputValidMsg.normalText}
          // itemRender = {itemRender}
            />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;시스템구분</th>
        <td>
            <DropDownList 
            id = "sysSct" name = "sysSct" className={convMngtStyles.inputTerm} data = { [ "-", "물-물" , "물-공기" , "물-공기멀티" ] }
            value = { meainInfo.sysSct ? meainInfo.sysSct : "" } onChange = {onchageDropdown}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            // itemRender = {itemRender}
              />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;히트펌프용량(kW)</th>
        <td>
          <Input 
          id = "heatPumpCapa" name = "heatPumpCapa"  type = "number" className={convMngtStyles.inputTerm}
          value = { meainInfo.heatPumpCapa ? meainInfo.heatPumpCapa : "" } onChange = {onchageInput}
          // required = {true} validationMessage = {inputValidMsg.number2Fix}  
          step="0.01" min = {0}
            />
        </td>
        <th></th>
        <td></td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;히트펌프 제조사</th>
        <td>
            <DropDownList 
            id = "heatPumpMakrNo" name = "heatPumpMakrNo"  className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="makrSeriNo" textField = "makrTerm" 
            value = { heatPumpMakr } data = {heatPumpMakrList}
            defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setHeatPumpMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            // valid = {meainInfo.heatPumpMakrNo !== "" && meainInfo.heatPumpMakrNo !== null && typeof meainInfo.heatPumpMakrNo !== "undefined" }
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;히트펌프 모델명</th>
        <td>
            <DropDownList 
            id = "heatPumpModlNo" name = "heatPumpModlNo" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
            disabled = { meainInfo.heatPumpMakrNo ? false : true }
            value = { heatPumpModl } data = {heatPumpModlList}
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo , "meainTypeSeriNo" : e.value.meainTypeSeriNo  }; // 설비타입번호 설정
            setHeatPumpModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            // valid = {meainInfo.meainTypeSeriNo !== "" && meainInfo.meainTypeSeriNo !== null && typeof meainInfo.meainTypeSeriNo !== "undefined" }
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.font13 }>급탕측 열량계 제조사</span></th>
        <td>
            <DropDownList 
            id = "hwClrmtMakrNo" name = "hwClrmtMakrNo"  type = "text" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="makrSeriNo" textField = "makrTerm" 
            value = { hwClrmtMakr } data = {hwClrmtMakrList}
            defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setHwClrmtMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
        <th><span className={ convMngtStyles.font13 }>급탕측 열량계 모델명</span></th>
        <td>
            <DropDownList 
            id = "hwClrmtModlNo" name = "hwClrmtModlNo"  type = "text" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
            disabled = { meainInfo.hwClrmtMakrNo ? false : true }
            value = { hwClrmtModl } data = {hwClrmtModlList}
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo }; 
            setHwClrmtModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.font12 }>냉난방측 열량계 제조사</span></th>
        <td>
            <DropDownList  
            id = "hcClrmtMakrNo" name = "hcClrmtMakrNo" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="makrSeriNo" textField = "makrTerm" 
            value = { hcClrmtMakr } data = {hcClrmtMakrList}
            defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setHcClrmtMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
        <th><span className={ convMngtStyles.font12 }>냉난방측 열량계 모델명</span></th>
        <td>
            <DropDownList 
            id = "hcClrmtModlNo" name = "hcClrmtModlNo" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
            disabled = { meainInfo.hcClrmtMakrNo ? false : true }
            value = { hcClrmtModl } data = {hcClrmtModlList} 
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo }; 
            setHcClrmtModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } }
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
      </tr>
      <tr>
        <th>전력량계 제조사</th>
        <td>
            <DropDownList 
            id = "elctrMeterMakrNo" name = "elctrMeterMakrNo"  className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="makrSeriNo" textField = "makrTerm" 
            value = { elctrMeterMakr } data = {elctrMeterMakrList}
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setElctrMeterMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
        <th>전력량계 모델명</th>
        <td>
            <DropDownList 
            id = "elctrMeterModlNo" name = "elctrMeterModlNo"  className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" disabled = { meainInfo.elctrMeterMakrNo ? false : true }
            value = { elctrMeterModl } data = {elctrMeterModlList} 
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo }; 
            setElctrMeterModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } }
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;냉온수 유량(LPM)</th>
        <td>
            <Input 
            id = "hotchOilQty" name = "hotchOilQty"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.hotchOilQty ? meainInfo.hotchOilQty : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
              />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;지열수 유량(LPM)</th>
        <td>
            <Input
            id = "heathwtrOilQty" name = "heathwtrOilQty"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.heathwtrOilQty ? meainInfo.heathwtrOilQty : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number2Fix}  
            step="0.01" min = {0}
              />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;천공깊이(m)</th>
        <td>
            <Input 
                id = "boringDeep" name = "boringDeep"  type = "number" className={convMngtStyles.inputTerm}
                value = { meainInfo.boringDeep ? meainInfo.boringDeep : "" } onChange = {onchageInput}
                // required = {true} validationMessage = {inputValidMsg.number2Fix}  
                step="0.01" min = {0}
                />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;천공개수</th>
        <td>
            <Input 
            id = "boringCnt" name = "boringCnt"  type = "number" className={convMngtStyles.inputTerm}
            value = { meainInfo.boringCnt ? meainInfo.boringCnt : "" } onChange = {onchageInput}
            // required = {true} validationMessage = {inputValidMsg.number0Fix} 
            min = {0}
            />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;벤토나이트 제조사</th>
        <td>
            <DropDownList 
            id = "bntnMakrNo" name = "bntnMakrNo" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="makrSeriNo" textField = "makrTerm" 
            value = { bntnMakr } data = {bntnMakrList}
            defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; 
            setBntnMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            // valid = {meainInfo.bntnMakrNo !== "" && meainInfo.bntnMakrNo !== null && typeof meainInfo.bntnMakrNo !== "undefined" }
            // itemRender = {itemRender}
              ></DropDownList>
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;벤토나이트 모델명</th>
        <td>
            <DropDownList 
            id = "bntnTypeNo" name = "bntnTypeNo" className={convMngtStyles.inputTerm} popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" disabled = { meainInfo.bntnMakrNo ? false : true }
            value = { bntnType } data = {bntnTypeList} 
            onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo }; 
            setBntnType({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } }
            filterable={true} onFilterChange={makrMeainModlfilterChange}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            // valid = {meainInfo.bntnTypeNo !== "" && meainInfo.bntnTypeNo !== null && typeof meainInfo.bntnTypeNo !== "undefined" }
            // itemRender = {itemRender}
            ></DropDownList>
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;시공업체</th>
        <td>
            <Input 
                id = "consEnte" name = "consEnte"  type = "text" className={convMngtStyles.inputTerm}
                value = { meainInfo.consEnte ? meainInfo.consEnte : "" } onChange = {onchageInput} 
                // required = {true} validationMessage = {inputValidMsg.normalText}
                />
        </td>
        <th>시공업체 연락처</th>
        <td>
            <Input 
            id = "consEnteCntct" name = "consEnteCntct"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.consEnteCntct ? meainInfo.consEnteCntct : "" } onChange = {onchageInput}
            placeholder = "하이픈(-) 없이 입력해주세요."
            />
        </td>
      </tr>
      <tr>
      { meainInfo.moniYn === "Y" ?
          <>
            <th>
              <span className={ convMngtStyles.required }>*</span>&nbsp;설치확인관리번호
            </th>
            <td>
              <Input id = "instCnfrmCode" name = "instCnfrmCode" type="text" 
                    className={convMngtStyles.inputTerm} 
                    value = {meainInfo.instCnfrmCode ? meainInfo.instCnfrmCode : ""}
                    onChange = {onchageInput} />
            </td>
            <th></th>
            <td></td>
          </> :
          <>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
          </> 
        }
      </tr>
    </>
  );
};

export default BizManagementMeainHeath;