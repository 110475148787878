// 그래프
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// 그리드
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import React, { useState } from "react";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import kendo from "@progress/kendo-ui";

// 버튼 이벤트
import { Button } from "@progress/kendo-react-buttons";

// 컴마표시
import { numFormat } from "../../../../com/dataUtil";

export const AreaRank = ({ dataSource, search, setMakrDataSource, setLoading, LocalizationProvider, IntlProvider, paging, setPaging }) => {
  // 설비용량 , 사용량 , 발전량 가공
  dataSource.rgnList.forEach(element => {
    element.dayPower = element.dayPower ? numFormat(element.dayPower) : 0;
    element.dayUse = element.dayUse ? numFormat(element.dayUse) : 0;
    element.instCapa = element.instCapa ? numFormat(element.instCapa) : 0;
  });

  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  // 생산량 , 사용량 조건부 렌더링
  const isUse = search.ensoTypeCodeNo === 15002 || search.ensoTypeCodeNo === 15003 ? true : false;

  // 정렬 상태
  let [sort, setSort] = useState([{ field: "", dir: "" }]);

  // 페이징 이벤트
  const onPageChange = e => {
    const { page } = e;
    setPaging(page);
  };

  // 정렬 이벤트
  const onSortChange = e => {
    const { sort } = e;
    setSort(sort);
  };

  // 시/군 생사량 목록
  const chartConfig = {
    chart: { zoomType: "xy" ,  height:'350px' },
    title: { text: null },
    credits: { enabled: false },
    noData: { style: { color: "#FFFFFF", fontSize: "14px" } },
    tooltip: {
      shared: true,
      positioner: function (labelWidth, labelHeight, point) {
        // 툴팁 위치 조정
        const tooltipX = point.plotX;
        const tooltipY = 150;
        return {
          x: tooltipX,
          y: tooltipY,
        };
      },
      formatter: function () {
        var s = "<b>" + this.points[0].point.name + "</b>";
        s +=
          "<br/>" +
          '<span style="color:' +
          this.points[0].point.color +
          '">\u25CF</span> ' +
          this.points[0].series.name +
          ": " +
          Math.round(this.points[0].y * 100) / 100 +
          " kWh/" +
          (search.ensoTypeCodeNo === 15002 ? "㎡" : "kW") +
          ".day";
        if (this.points[1]) {
          s +=
            "<br/>" +
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            this.points[1].series.name +
            ": " +
            Math.round(this.points[1].y) +
            " % (" +
            numFormat(this.points[1].point.instCapa) +
            (search.ensoTypeCodeNo === 15002 ? "㎡" : "kW") +
            ")";
        }
        return s;
      },
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        events: {
          click: function (event) {
            // 드릴다운 이벤트
            setMakrDataSource(event.point.rgnCode);
          },
        },
      },
    },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value:,.0f}",
          style: {
            color: "#69bcc8",
          },
        },
        title: {
          text: isUse === false ? "용량대비생산량(kWh/" : "용량대비생산량(kWh/" + (search.ensoTypeCodeNo === 15002 ? "㎡" : "kW") + ".day)",
          style: {
            color: "#69bcc8",
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: "설비용량비율(%)",
          style: {
            color: "#c1ce01",
          },
        },
        labels: {
          format: "{value:,.0f}",
          style: {
            color: "#c1ce01",
          },
        },
        opposite: true,
      },
    ],
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        name: "용량대비생산량",
        type: "column",
        color: "#69bcc8",
        dataLabels: {
          enabled: true,
          format: "{point.y:,.2f}",
        },
        data: dataSource.rgnRankList.efic,
        // data: dataSource.rgnRankList.efic,
        tooltip: {
          pointFormat:
            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} kWh/' +
            (search.ensoTypeCodeNo === 15002 ? "㎡" : "kW") +
            ".day</b><br/>",
        },
      },
      {
        name: "설비용량비율",
        type: "column",
        yAxis: 1,
        color: "#c1ce01",
        dataLabels: {
          enabled: true,
          format: "{point.y:,.2f}%",
        },
        data: dataSource.rgnRankList.ratio,
        tooltip: {
          pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} %</b><br/>',
        },
      },
    ],
  };

  // 파일 이름
  const fileName = () => {
    let template = "";
    template += "지역별_데이터_" + +kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  };

  const completeExcel = () => {
    setLoading(false);
  };

  return (
    <div className="md-card-content">
      <div className="md-card-toolbar">
        <h3 className="md-card-toolbar-heading-text">
          TOP 생산량 분포 | 시/군 생산량 목록
          <Button
            className="areaRankExcelBtn"
            icon="excel"
            onClick={e => {
              setLoading(true);
              _excelData.save();
            }}>
            엑셀저장
          </Button>
        </h3>
      </div>
      <div style={{ height: "350px", marginTop: "8px" }}>
        <HighchartsReact Highcharts={Highcharts} options={chartConfig}></HighchartsReact>
      </div>
      <div className="Table-scr" style={{ height: "400px" }}>
        <ExcelExport
          data={orderBy(dataSource.rgnList, sort)}
          ref={exporter => (_excelData = exporter)}
          fileName={fileName()}
          onExportComplete={completeExcel}>
          <ExcelExportColumn title="시/군별" field="rgnTerm" />
          <ExcelExportColumn title="생산량(kWh)" field="dayPower" />
          <ExcelExportColumn title="설비용량(kW)" field="instCapa" />
          <ExcelExportColumn title="설비용량비율(%)" field="ratio" />
          <ExcelExportColumn title="용량대비생산량(kWh/kW.day)" field="instVsPower" />
          <ExcelExportColumn title="운영개소" field="count" />
        </ExcelExport>
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              style={{ height: "400px" }}
              className="areaRankGrid"
              // data = {dataSource.rgnList}
              data={orderBy(dataSource.rgnList, sort).slice(paging.skip, paging.take + paging.skip)}
              total={dataSource.rgnList.length}
              skip={paging.skip}
              take={paging.take}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              sort={sort}
              page={paging}
              sortable
              pageable>
              <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
              <GridColumn title="순위" field="no" width="100px" />
              <GridColumn title="시/군별" field="rgnTerm" width="150px" />
              <GridColumn title="생산량(kWh)" field="dayPower" />
              <GridColumn title="설비용량(kW)" field="instCapa" />
              <GridColumn title="설비용량비율(%)" field="ratio" />
              <GridColumn
                title={
                  <div>
                    용량대비생산량<br></br>(kWh/kW.day)
                  </div>
                }
                field="instVsPower"
              />
              <GridColumn title="운영개소" field="count" />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default AreaRank;
