import React, { useState, useEffect } from 'react';

import kendo from "@progress/kendo-ui";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import AzimInclTab from "./azimInclTab";
import RgnMakerEfficiencyTab from "./rgnMakerEfficiencyTab";
import ChMap from "./optomalAzimInclChMap";
import { azimInclList, weathTempList } from "../../../service/ai/optomalAzimIncl/optomalAzimInclService";

import KakaoPostcodeSearch from './kakaoPostcodeSearch/kakaoPostcodeSearch';


// 로딩 스피너 
import { BeatLoader } from 'react-spinners'

import styles from "./optomalAzimIncl.module.css"
import './optomalAzimIncl.css'

// 최적의 방위각/경사각
const OptomalAzimIncl = ({ conn, rgnList, codeList }) => {
    // 로딩 스피너
    const [loading, setLoading] = useState(false);
    // 탭 상태
    const [tabStat, setTabStat] = useState({});

    const [selectedBtn, setSelectedBtn] = useState({ pageName: "azimIncl" });

    const [address, setAddress] = useState();

    const [selectedRgn, setSelectedRgn] = useState({ selectedRgn: "", selectedRgnCodeS: "" });

    const [selectedBundCode, setSelectedBundCode] = useState({});
    
    //천안시 동남구와 서북구를 제거하기 위한 함수
    const removeRgn = (item) => {        
        if (item.cityProvCode === '44' && item.rgnCode !== "44131" && item.rgnCode !== '44133') {
            return true;
        }
        return false;
    }

    const chungnamRgnList = rgnList.filter(removeRgn);

    const initialHeight = 560;
    const initialWidth = 500;


    // 윈도우 창 Visible
    let [userInfoVisible, setUserInfoVisible] = useState(false);

    const [areaAzimIncl, setAreaAzimIncl] = useState({
        data: [
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
            { areaAzim: 0, areaIncl: 0 },
        ]
    });

    const [areaWeather, setAreaWeather] = useState({
        data: [
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
            { weather: 0, temp: 0 },
        ]
    })

    // 탭 선택 시 발생하는 이벤트 
    const selectTabItem = (e) => {
        // 탭 선택에 대한 탭 값 설정
        setTabStat({
            state: e.selected
        });
    }

    // 버튼 클릭
    const clickButtonGroup = (e) => {
        setSelectedBtn(selectedBtn => {
            const item = { ...selectedBtn };
            item.pageName = e.nativeEvent.target.name
            return item;
        });
    }

    // 그리드에서 유저 선택 시 이벤트
    const selectUserAddress = e => {
        let sigunguCode = e.sigunguCode;
        let bundCode = e.bcode;
        bundCode = bundCode.slice(0, -2);
        bundCode += "00";
        
        //충남 외 지역을 선택할 경우
        if (sigunguCode.slice(0, 2) !== '44') {
            kendo.alert("충남 지역을 선택해주세요");
            return;
        }

        //천안시 동남구와 천안시 서북구를 천안시로 통일
        if (sigunguCode.slice(0, 4) === '4413') {
            sigunguCode = '44130';
        }
        const temp = "rgnCode_" + sigunguCode;
        setSelectedRgn({
            selectedRgn: temp, selectedRgnCodeS: sigunguCode
        })

        setAddress(
            e.address,
        )
        setSelectedBundCode(
            bundCode,
        )
        setUserInfoVisible(false);
    };

    // 결과 데이터
    const getAzimInclList = (azimInclList) => {
        setAreaAzimIncl(
            areaAzimIncl.data = azimInclList
        );
        setLoading(false);
    };

    const getWeathTempList = (weathTempList) => {
        setAreaWeather(
            weathTempList.data = weathTempList
        );
        setLoading(false);
    }

    // rejection
    const getRejection = error => {
        if (error.errCode === "F011") {
            kendo.alert("필수 입력 값이 입력되지 않았습니다. 다시 한번 입력 또는 선택 해주세요.");
            return;
        } else {
            console.log("error = ", error);
            kendo.alert("시스템에 문제가 발생 하였습니다.");
            return;
        }
    };

    useEffect(() => {
        // 탭 상태 설정
        setTabStat({
            // state: 0
            state: 1
        });
        setAddress("");
        azimInclList(conn, getAzimInclList, getRejection, setLoading);
        weathTempList(conn, getWeathTempList, getRejection, setLoading);
        /* eslint-disable-next-line */
    }, []);

    return (
        // <div className='background-border'>
        <div className={["uk-grid", "backgroundWhite", loading ? styles.optimalAzimInclG : ""].join(' ')}>
            <div className="uk-width-medium-1-2">
                <div className={["md-card", styles.mapSpace].join(' ')}>
                    <div className="md-card-content">
                        <div className="md-card-toolbar">
                            <h3 className="md-card-toolbar-heading-text">
                                충남 지역별 현황
                            </h3>
                        </div>
                        <div className="uk-grid topSpace">
                            <div className="uk-width-medium-2-5">
                                <ButtonGroup selection="single" rounded='full' className='weatherAzimInclChoice' width='100%' >
                                    <Button togglable={true} name='weather' selected={selectedBtn.pageName === 'weather'} onClick={clickButtonGroup}>날씨</Button>
                                    <Button togglable={true} name='azimIncl' selected={selectedBtn.pageName === 'azimIncl'} onClick={clickButtonGroup}>방위각/경사각</Button>
                                </ButtonGroup>
                            </div>
                            {selectedBtn?.pageName === 'azimIncl' ?
                                <div className="uk-width-medium-3-5 noticeSpace">
                                    ※방위각/경사각 : 지역별 평균값 기준
                                </div>
                                :
                                <div className="uk-width-medium-3-5 noticeSpace">
                                    ※날씨 : 현재 시간 기준
                                </div>
                            }
                        </div>
                        <div className='svgMapSpace'>
                            <ChMap areaAzimIncl={areaAzimIncl} styles={styles} selectedRgn={selectedRgn} setSelectedRgn={setSelectedRgn} selected={selectedBtn.pageName}
                                areaWeather={areaWeather} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-width-medium-1-2">
                <div className={["md-card"].join(' ')}>
                    <TabStrip className='JinTab JinTabAzimIncl' selected={tabStat.state} onSelect={selectTabItem}>
                        <TabStripTab title="지역 제조사별 이용률" key={"tabMakrEfficiency"}>
                            <RgnMakerEfficiencyTab
                                conn={conn}
                                styles={styles}
                                selectedRgn={selectedRgn}
                                chungnamRgnList={chungnamRgnList}
                                setLoading={setLoading}
                                getRejection={getRejection}
                                tabStat={tabStat}
                            />
                        </TabStripTab>
                        <TabStripTab title="최적의 방위각/경사각" key={"tabAzimIncl"}>
                            <AzimInclTab
                                conn={conn}
                                styles={styles}
                                address={address}
                                setAddress={setAddress}
                                bundCode={selectedBundCode}
                                chungnamRgnList={chungnamRgnList}
                                codeList={codeList}
                                selectedRgn={selectedRgn}
                                setSelectedRgn={setSelectedRgn}
                                userInfoVisible={userInfoVisible}
                                setUserInfoVisible={setUserInfoVisible}
                                setLoading={setLoading}
                                areaAzimIncl={areaAzimIncl}
                                getRejection={getRejection}
                            />
                        </TabStripTab>
                    </TabStrip>
                </div>
            </div>
            {userInfoVisible && (
                <KakaoPostcodeSearch setInfo={selectUserAddress} setVisible={setUserInfoVisible} initialTop={(window.screen.height - initialHeight)/2 - 50} initialLeft={(window.screen.width - initialWidth)/2}
                initialHeight = {initialHeight} initialWidth = {initialWidth} />
            )}
            {loading && <p className={styles.loading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
        </div>
    );
};

export default OptomalAzimIncl;