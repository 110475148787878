import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "./../../../com/dataUtil";

const SolarModuleCurveFitCht = (props) => {
  const { data, title } = props;
  const dots =
    title === "전압"
      ? [
          { x: 200, y: data.vltIrZero },
          { x: 400, y: data.vltIrOne },
          { x: 600, y: data.vltIrTwo },
          { x: 800, y: data.vltIrThree },
          { x: 1000, y: data.vltIrFour },
        ]
      : [
          { x: 200, y: data.curIrZero },
          { x: 400, y: data.curIrOne },
          { x: 600, y: data.curIrTwo },
          { x: 800, y: data.curIrThree },
          { x: 1000, y: data.curIrFour },
        ];
  let minVal = 0;
  let maxVal = 0;

  const getVltPerIrFunc = () => {
    let vltPerIr = [];
    for (let i = 200; i <= 1000; i++) {
      const vlt = data.vltFita * i ** data.vltFitb + data.vltFitc;
      vltPerIr.push({ x: i, y: vlt });
    }
    minVal = vltPerIr[0].y;
    maxVal = vltPerIr[800].y;
    return vltPerIr;
  };

  const getCurPerIrFunc = () => {
    let curPerIr = [];
    for (let i = 200; i <= 1000; i++) {
      const cur = data.curFita * i + data.curFitb;
      curPerIr.push({ x: i, y: cur });
    }
    minVal = curPerIr[0].y;
    maxVal = curPerIr[800].y;
    return curPerIr;
  };

  const chartData = title === "전압" ? getVltPerIrFunc() : getCurPerIrFunc();

  const chartOpt = {
    chart: {
      height: "245px",
    },
    title: {
      text: null,
    },
    xAxis: {
      title: {
        text: "일사량",
        style: {
          color: "#000000",
        },
        // ,
        rotation: 0,
        y: -20,
        x: -10,
        offset: 0,
        align: "high",
      },
      min: 200,
      max: 1000,
      tickInterval: 200,
      startOnTick: false,
      endOnTick: false,
    },
    yAxis: {
      title: "none",

      // title: {
      //   text: title,
      //   style: {
      //     color: "#000000",
      //   },
      //   // ,
      //   rotation: 0,
      //   // y: -20,
      //   x: -10,
      //   offset: 0,
      //   align: "high",
      // },
      min: minVal - (maxVal - minVal) * 0.05,
      max: maxVal + (maxVal - minVal) * 0.05,
      startOnTick: false,
      endOnTick: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        // let s = '<span style="color:' + this.color + '">\u25CF</span> ' + "<b>" + " " + title + "</b>";
        // s += "<br/>" + " 일사량: " + numFormat(roundNum(this.x, 2)) + " W/㎡" + "<br/>" + " 값: " + numFormat(roundNum(this.y, 2)) + (title === "전압" ? " V" : " A");

        let s2 = `<span style="color:${this.color}">\u25CF</span><b> ${title}</b>`;
        s2 += `<br/> 일사량: ${numFormat(roundNum(this.x, 2))} W/㎡<br/> 값: ${numFormat(roundNum(this.y, 2)) + (title === '전압' ? 'V' : ' A')}`; 

        return s2;
      },
    },
    series: [
      {
        type: "line",
        color: "#C2103F",
        data: chartData,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            lineWidth: 0,
          },
        },
        enableMouseTracking: false,
      },
      {
        name: title,
        type: "scatter",
        color: "#000099",
        data: dots,
        marker: {
          radius: 3,
        },
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOpt}></HighchartsReact>
    </div>
  );
};

export default SolarModuleCurveFitCht;
