import React from "react";
import BizExpenseGrid from "./bizExpenseGrid";
import BizExpendGrid from "./bizExpendGrid";
import BizPayOrderGrid from "./bizPayOrderGrid";
import BizExpenseFile from "./bizExpenseFile";

const BizExpenseContent = ({
  conn,
  styles,
  bizStyles,
  search,
  selected,
  dataList,
  setDataList,
  getData,
  spendItemListData,
  energyTypeCodeListData,
  bizExpenseWindowVisible,
  setBizExpenseWindowVisible,
  bizFileWindowVisible,
  setBizFileWindowVisible,
  payOrderWindowVisible,
  setPayOrderWindowVisible,
  bizExpendWindowVisible,
  setBizExpendWindowVisible,
  initalBizExpense,
  loadConvPayOrder,
  loadbizExpend,
  Grid,
  GridColumn,
  IntlProvider,
  LocalizationProvider,
  kendo,
  numFormat,
  Form,
  FormElement,
  Button,
  useState,
  useEffect,
  Upload,
  Window,
  NumberFormat,
  DropDownList,
  Input,
}) => {
  return (
    <>
      <BizExpenseGrid
        conn={conn}
        styles={styles}
        bizStyles={bizStyles}
        search={search}
        selected={selected}
        dataList={dataList}
        setDataList={setDataList}
        getData={getData}
        bizExpenseWindowVisible={bizExpenseWindowVisible}
        setBizExpenseWindowVisible={setBizExpenseWindowVisible}
        setBizFileWindowVisible={setBizFileWindowVisible}
        setPayOrderWindowVisible={setPayOrderWindowVisible}
        setBizExpendWindowVisible={setBizExpendWindowVisible}
        initalBizExpense={initalBizExpense}
        Grid={Grid}
        GridColumn={GridColumn}
        IntlProvider={IntlProvider}
        LocalizationProvider={LocalizationProvider}
        kendo={kendo}
        numFormat={numFormat}
        Form={Form}
        FormElement={FormElement}
        Button={Button}
        NumberFormat={NumberFormat}
        useState={useState}
        DropDownList={DropDownList}
        Input={Input}
      />
      <BizExpenseFile
        conn={conn}
        styles={styles}
        bizStyles={bizStyles}
        search={search}
        selected={selected}
        dataList={dataList}
        getData={getData}
        setBizExpenseWindowVisible={setBizExpenseWindowVisible}
        bizFileWindowVisible={bizFileWindowVisible}
        setBizFileWindowVisible={setBizFileWindowVisible}
        setPayOrderWindowVisible={setPayOrderWindowVisible}
        setBizExpendWindowVisible={setBizExpendWindowVisible}
        IntlProvider={IntlProvider}
        LocalizationProvider={LocalizationProvider}
        kendo={kendo}
        useState={useState}
        Upload={Upload}
        Window={Window}
      />
      <div class="uk-grid">
        <BizPayOrderGrid
          conn={conn}
          styles={styles}
          bizStyles={bizStyles}
          search={search}
          selected={selected}
          dataList={dataList}
          setDataList={setDataList}
          getData={getData}
          energyTypeCodeListData={energyTypeCodeListData}
          setBizExpenseWindowVisible={setBizExpenseWindowVisible}
          setBizFileWindowVisible={setBizFileWindowVisible}
          payOrderWindowVisible={payOrderWindowVisible}
          setPayOrderWindowVisible={setPayOrderWindowVisible}
          setBizExpendWindowVisible={setBizExpendWindowVisible}
          initalBizExpense={initalBizExpense}
          loadConvPayOrder={loadConvPayOrder}
          Grid={Grid}
          GridColumn={GridColumn}
          IntlProvider={IntlProvider}
          LocalizationProvider={LocalizationProvider}
          kendo={kendo}
          numFormat={numFormat}
          useState={useState}
          useEffect={useEffect}
          Form={Form}
          FormElement={FormElement}
          Button={Button}
          NumberFormat={NumberFormat}
          DropDownList={DropDownList}
          Input={Input}
        />
        <BizExpendGrid
          conn={conn}
          styles={styles}
          bizStyles={bizStyles}
          search={search}
          selected={selected}
          dataList={dataList}
          setDataList={setDataList}
          getData={getData}
          spendItemListData={spendItemListData}
          energyTypeCodeListData={energyTypeCodeListData}
          setBizExpenseWindowVisible={setBizExpenseWindowVisible}
          setBizFileWindowVisible={setBizFileWindowVisible}
          setPayOrderWindowVisible={setPayOrderWindowVisible}
          bizExpendWindowVisible={bizExpendWindowVisible}
          setBizExpendWindowVisible={setBizExpendWindowVisible}
          initalBizExpense={initalBizExpense}
          loadbizExpend={loadbizExpend}
          Grid={Grid}
          GridColumn={GridColumn}
          IntlProvider={IntlProvider}
          LocalizationProvider={LocalizationProvider}
          kendo={kendo}
          numFormat={numFormat}
          useState={useState}
          useEffect={useEffect}
          Form={Form}
          FormElement={FormElement}
          Button={Button}
          NumberFormat={NumberFormat}
          DropDownList={DropDownList}
          Input={Input}
        />
      </div>
    </>
  );
};

export default BizExpenseContent;
