// React
import React, { useState, useCallback } from 'react';
import { yearList } from "../../../com/dropDownDs";

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from '@progress/kendo-react-buttons';

// Css
import './sttstMngtModal.css';

const SttstMngtModal = ({ modalForm, setModalForm, fixbylaw, city, rgn, energyType, bizSct, handleModalUpdateSubmit }) => {
  // 수정 유무 설정
  modalForm.formItem.createUpdateYn = modalForm.createUpdateYn;

  const cityList = city.filter(e => e.cityProvCode === modalForm.formItem.cityCode);
  cityList.length !== 0 ? modalForm.formItem.cityList = cityList[0] : modalForm.formItem.cityList = { cityProvCode: "", cityProvTerm: "주소검색" };
  const rgnList = rgn.filter(e => e.rgnCode === modalForm.formItem.rgnCode);
  rgnList.length !== 0 ? modalForm.formItem.rgnList = rgnList[0] : modalForm.formItem.rgnList = { rgnCode: "", rgnTerm: "주소검색" };
  const fixbylawBund = fixbylaw.filter(e => e.fixbylawBundCode === modalForm.formItem.fixbylawBundCode);
  fixbylawBund.length !== 0 ? modalForm.formItem.fixbylawBund = fixbylawBund[0] : modalForm.formItem.fixbylawBund = { fixbylawBundCode: "", fixbylawBundTerm: "주소검색" };
  const ensoList = energyType.filter(e => e.codeNo === modalForm.formItem.ensoTypeCode);
  ensoList.length !== 0 ? modalForm.formItem.energyList = ensoList[0] : modalForm.formItem.energyList = { codeNo: "", codeVal: "에너지원 선택" };

  const [cityData, ] = useState(city.slice());
  const [rgnData, setRgnData] = useState(rgn.slice());
  const [fixbylawData, setFixbylawData] = useState(fixbylaw.slice());

  const filterRgnData = (filter) => {
    const rgnData = rgn.slice();
    return filterBy(rgnData, filter);
  };

  const filterfixbylawData = (filter) => {
    const fixbylawData = fixbylaw.slice();
    return filterBy(fixbylawData, filter);
  };

  const filterChange = (event) => {
    if (event.filter.field === "rgnTermSimple") {
      setRgnData(filterRgnData(event.filter));
    }
    else if (event.filter.field === "fixbylawTerm") {
      setFixbylawData(filterfixbylawData(event.filter));
    }
  }

  // 닫기, 취소 이벤트
  const handleClose = useCallback(() => {
    setModalForm({ ...modalForm, visible: !modalForm.visible, title: '', formItem: [] });
    // eslint-disable-next-line
  }, [modalForm]);


  // 저장 버튼
  // eslint-disable-next-line
  const handleSubmit = useCallback(e => {
    e.isNew = modalForm.isNew;
    handleModalUpdateSubmit(e)
  });



  return (
    <Dialog
      title={modalForm.title}
      onClose={handleClose}
    >
      <div className="sttstMngtModalForm">
        <div className="leftModalItem">
          <div>성함</div>
          <div>사업구분</div>
          <div>사업연도</div>
          <div>시/도</div>
          <div>시/군/구</div>
          <div>읍/면/동</div>
          <div>주소</div>
          <div>에너지원</div>
          <div>용량{modalForm.formItem.ensoTypeCode === 15002 ? "(㎡)" : "(kW)"}</div>
          <div>비고</div>
        </div>
        <div className="rightModalItem">
          <Form
            onSubmit={handleSubmit}
            initialValues={modalForm.formItem}
            render={formRenderProps => (
              <FormElement>
                <div>
                  <Field name='installerName' component={Input} placeholder="사용자 이름 입력" />
                </div>
                <div>
                  <Field
                    name='bizSct'
                    component={DropDownList}
                    data={bizSct}
                  />
                </div>
                <div>
                  <Field
                    name='bizYy'
                    component={DropDownList}
                    data={yearList()}
                  />
                </div>
                <div>
                  <Field
                    name='cityList'
                    component={DropDownList}
                    data={cityData}
                    textField="cityProvTerm" dataItemKey="cityProvCode"
                    defaultValue={{ cityProvCode: "", cityProvTerm: "시/도 검색" }}

                  />
                </div>
                <div>
                  <Field
                    name='rgnList'
                    component={DropDownList}
                    data={rgnData}
                    textField="rgnTermSimple" dataItemKey="rgnCode"
                    defaultValue={{ rgnCode: "", rgnTermSimple: "시/군/구 검색" }}
                    onFilterChange={filterChange}
                    filterable={true}
                  />
                </div>
                <div>
                  <Field
                    name='fixbylawBund'
                    component={DropDownList}
                    data={fixbylawData}
                    textField="fixbylawBundTermSimple" dataItemKey="fixbylawBundCode"
                    defaultValue={{ fixbylawBundCode: "", fixbylawBundTerm: "읍/면/동 검색" }}
                    onFilterChange={filterChange}
                    filterable={true}
                  />
                </div>
                <div>
                  <Field name='address' component={Input} placeholder="주소 입력" />
                </div>
                <div>
                  <Field name='ensoTerm' component={Input} readOnly = {true} />
                  {/* name='energyList'
                    readOnly = {true}
                    component={DropDownList}
                    data={energyData}
                    textField="codeVal" dataItemKey="codeNo"
                    defaultValue={{ codeNo: "", codeVal: "에너지원 선택" }}
                  /> */}
                </div>
                <div>
                  <Field name='instCapa' component={Input} placeholder="용량 입력" />
                </div>
                <div>
                  <Field name='remarks' component={Input} placeholder="비고 입력" />
                </div>
                <div>
                  <Button onClick={handleClose}>취소</Button>
                  <Button type='submit' disabled={!formRenderProps.allowSubmit}>저장</Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default SttstMngtModal;