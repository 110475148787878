import { orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { useState } from "react";
import { ArrowRightAlt } from "@material-ui/icons";

//설비별 데이터
const CmpUserByUserMeainInfoGrid = ({ meainCmpPowerList, search, kendo, setLoading, isMobile }) => {
  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  // 정렬
  const [sort, setSort] = useState([{ field: "", dir: "" }]);
  // 정렬 함수
  const onSortChange = e => {
    const { sort } = e;
    setSort(sort);
  };

  // 엑셀 다운로드
  const downloadExcel = e => {
    setLoading(true);
    _excelData.save();
  };

  const completeExcel = () => {
    setLoading(false);
  };
  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="md-card TBgA">
          <div className="md-card-content small-padding">
            <div className="md-card-toolbar">
              <span className="mobileCmpUserSpan">
                <ArrowRightAlt />
                &nbsp;옆으로 드래그하여 확인
              </span>
              <div className="cmpUserByUserExcelDownBtnDiv">
                <Button className="cmpUserByUserExcelDownBtn" onClick={downloadExcel} icon="excel">
                  엑셀저장
                </Button>
              </div>
            </div>
            <ExcelExport
              className="userByUserMeainListGrid"
              data={orderBy(meainCmpPowerList, sort)}
              ref={exporter => (_excelData = exporter)}
              fileName={search.userTerm + "_설비별 발전량_" + kendo.toString(new Date(), "yyyy-MM-dd")}
              onExportComplete={completeExcel}>
              <ExcelExportColumn title="No" field="no" width="100px" />
              <ExcelExportColumn title="CID" field="cid" width="210px" />
              <ExcelExportColumn title="설비명" field="alias" width="210px" />
              <ExcelExportColumn title="제조사" field="makrTerm" width="210px" />
              <ExcelExportColumn title="설비모델" field="meainTypeTerm" width="210px" />
              <ExcelExportColumn title="에너지원" field="ensoTypeTerm" width="210px" />
              <ExcelExportColumn title="설비용량" field="instCapa" width="210px" />
              <ExcelExportColumn title="발전량 & 생산량" field="dayEnergy" width="210px" />
              <ExcelExportColumn title="용량대비생산량" field="instCapaToEnergy" width="210px" />
            </ExcelExport>
            <Grid
              id="userByUserMeainListGrid"
              className="userByUserMeainListGrid"
              data={orderBy(meainCmpPowerList, sort)}
              onSortChange={onSortChange}
              sort={sort}
              sortable>
              <GridNoRecords>{"해당 수용가의 설비가 존재 하지 않습니다. 설비를 등록 해주세요."}</GridNoRecords>
              <GridColumn title="No" field="no" width={isMobile ? 50 : "100px"} />
              <GridColumn title="CID" field="cid" width={isMobile ? 115 : "auto"} />
              <GridColumn title="설비명" field="alias" width={isMobile ? 100 : "auto"} />
              <GridColumn title="제조사" field="makrTerm" width={isMobile ? 115 : "auto"} />
              <GridColumn title="설비모델" field="meainTypeTerm" width={isMobile ? 115 : "auto"} />
              <GridColumn title="에너지원" field="ensoTypeTerm" width={isMobile ? 115 : "auto"} />
              <GridColumn title="설비용량" field="instCapa" width={isMobile ? 115 : "auto"} />
              <GridColumn title="발전량 & 생산량" field="dayEnergy" width={isMobile ? 125 : "auto"} />
              <GridColumn title="용량대비생산량" field="instCapaToEnergy" width={isMobile ? 140 : "auto"} />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CmpUserByUserMeainInfoGrid;
