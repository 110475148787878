import BizProgressStatisticsMonth from "./bizProgressStatisticsMonth";
import BizProgressStatisticsRate from "./bizProgressStatisticsRate";
import BizProgressStatisticsWeek from "./bizProgressStatisticsWeek";

import styles from "../bizProgress.module.css";

const BizProgressStatistics = ({ conn, search, setSearch, dataList, setSearchMonth, getProgressWeek, staticProssAdd, windowState }) => {
  return (
    // <div class="uk-width-medium-2-5">
    //   <div className="md-card">
    //     <div className="md-card-content">
    //       <div className="md-card-toolbar-conv">
    //         <h3 className="md-card-toolbar-heading-text-left">
    //           사업추진 진행사항
    //         </h3>
    //       </div>

    <div class="JinTabB_con" id="JinTabBB_con">
      <div class="pd10">
        {/* <div class="pd10">         */}
        <BizProgressStatisticsWeek
          search={search}
          setSearch={setSearch}
          styles={styles}
          dataList={dataList}
          setSearchMonth={setSearchMonth}
          getProgressWeek={getProgressWeek}
        />
        <br />
        <BizProgressStatisticsMonth search={search} dataList={dataList} />
        <br />
        <BizProgressStatisticsRate
          styles={styles}
          search={search}
          dataList={dataList}
          staticProssAdd={staticProssAdd}
          windowState={windowState}
        />
      </div>
    </div>
    // </div>
    // </div>
  );
};

export default BizProgressStatistics;
