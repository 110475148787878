// React
import React, {useState, useEffect, useCallback} from 'react';
import { getIotKtLog, getLogUserIdList } from '../../../service/meain/iotMakers/iotKtService';
import log from '../../../com/log';
import { parseDate } from '../../../com/dateUtil';
import { downloadExcel } from '../../../com/downloadExcel';

// Kendo
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';

// Css
import './iotktLog.css';
import styles from './iotktLog.module.css';

function IotktLog({ conn }) {

  const [dataSource, setDataSource] = useState({
    dataList : [],
    total : 0
  });
  const [dataState, setDataState] = useState({
    take: 20
    , skip: 0
  });
  const [userIdList, setUserIdList] = useState({
    userIdArr: []
    , filterUserIdArr: []
    , selectUserId: '전체'
  });

  // 등록 ID 목록 리스트
  const getLogUserIdDropList = () => {
    getLogUserIdList(conn)
    .then( response => {
      setUserIdList({...userIdList, userIdArr: response, filterUserIdArr: response});
    })
    .catch( err => {
      log(`IotktLog.useEffect.getLogUserIdList error : ${err}`);
    });
  }

  // IotMakers 로그 리스트
  const getIotKtLogList = () => {
    getIotKtLog(conn, dataState)
    .then( response => {
      const {results, total} = response;
      setDataSource(dataSource => {
        const item = {...dataSource};
        item.dataList = results;
        item.total = total;
        return item;
      });
    })
    .catch( err => {
      log(`IotktLog.useEffect.getIotKtLog error : ${err}`);
    });
  }

  useEffect(() => {
    getLogUserIdDropList();
    getIotKtLogList();
    // eslint-disable-next-line
  }, []);


  // 그리드 페이징 처리
  const handlePageChange = useCallback( e => {
    const {take, skip} = e.page;
    dataState.take = take;
    dataState.skip = skip;
    getIotKtLogList();
    // eslint-disable-next-line
  }, [dataSource, dataState]);

  // 엑셀다운
  const handleExcelDown = useCallback( e => {
    e.preventDefault();
    const url = '/api/v2.2/iot/kt/log';
    const data = [];
    const fileName = 'IoTMakers로그_' + parseDate('yyyymmddhhmmss') + '.xlsx';
    console.log("url",url,data,fileName);
    downloadExcel(data, fileName, url);
  }, []);


  // 등록 ID 드롭다운리스트 변경 이벤트
  const handleDropDownChange = useCallback( e => {
    const {value} = e.target;

    const stateObj = {take: 20, skip: 0, sort: []};

    getIotKtLog(conn, stateObj, value)
    .then( response => {
      const {results, total} = response;
      setDataSource(results);
      setDataState({take: 20, skip: 0, total, sort: []});
      setUserIdList({...userIdList, selectUserId: value});
    })
    .catch( err => {
      log(`IotktLog.handleDropDownChange error : ${err}`);
    });
    // eslint-disable-next-line
  }, [dataSource, dataState]);


  // 등록 ID 드롭다운리스트 필터 이벤트
  const handleDropDownFilterChange = useCallback( e => {
    const {value} = e.filter;
    
    const filterArr = [];
    userIdList.userIdArr.forEach(userIdItem => {
      if(userIdItem.startsWith(value)) filterArr.push(userIdItem);
    });

    setUserIdList({ ...userIdList, filterUserIdArr: filterArr });
  }, [userIdList]);


  return (
    <>
      <div className="iotktLogBody">
        <h3>설비관리 - IoTMakers 로그</h3>
        <div className="iotktLogCate">
          <span>등록 ID : </span>
          <DropDownList
            data={userIdList.filterUserIdArr}
            defaultItem='전체'
            defaultValue='전체'
            filterable
            onChange={handleDropDownChange}
            onFilterChange={handleDropDownFilterChange}
          />
          <Button onClick={handleExcelDown} icon="excel">
            엑셀저장
          </Button>
        </div>
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              className={styles.iotktLogGrid}
              data={dataSource.dataList}
              total = {dataSource.total}
              skip = {dataState.skip}
              take = {dataState.take}
              pageable={{buttonCount: 5, pageSizes: [5, 10, 20, 50, 100]}}
              onPageChange={handlePageChange}
            >
              <GridColumn title='등록일시' field='regiDtm' />
              <GridColumn title='IMEI' field='nbDevid' />
              <GridColumn title='등록상태' field='stateCode' />
              <GridColumn title='응답코드' field='responseCode' />
              <GridColumn title='메시지' field='message' />
              <GridColumn title='등록ID' field='regiId' />
              <GridColumn title='등록유저명' field='userTerm' />
              <GridNoRecords>
                데이터가 존재하지 않습니다.
              </GridNoRecords>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </>
  );
}

export default IotktLog;
