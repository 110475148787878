// React
import React, { useState, useEffect, useCallback } from 'react';
import { getSttstData, setUpdateSttst, setDeleteSttst } from '../../../service/meain/sttstMngt/sttstMngtService';
import log from '../../../com/log';
import SttstMngtModal from './sttstMngtModal';
import { BeatLoader } from 'react-spinners'
import { getCalDongList, getCalRgnList } from "../../../com/dataUtil";
import { yearList } from "../../../com/dropDownDs";

// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';

// Css
import './sttstMngt.css';
import styles from './sttstMngt.module.css';
import GridColumnInputFilter from '../../com/gridFilter/gridColumnInputFilter';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Create, Delete } from "@material-ui/icons";

// 통계관리 페이지
const SttstDataMngt = ({ conn, codeList, dongList, rgnList, cityList }) => {

  // 로딩 스피너
  const [loading, setLoading] = useState(true);
  // 엑셀 로딩 스피너
  const [saveLoading, setSaveLoading] = useState(false);

  // 드롭다운 필터 상태
  const [filterDropValue, setFilterDropValue] = useState({
    city: { cityProvCode: "", cityProvTerm: "시/도" },
    rgn: { rgnCode: "", rgnTermSimple: "시/군/구" },
    dong: { fixbylawBundCode: "", fixbylawBundTermSimple: "읍/면/동" },
    energyType: { codeNo: "", codeVal: "에너지원" },
    bizSct: "사업구분",
    bizYy: "사업연도",
  });

  // 에너지원 유형
  const energyType = codeList.filter(item => item.grpCodeNo === 15);

  // 선택한 시/도 에 대한 시/군/구
  let rgns = getCalRgnList(rgnList.filter(e => e.cityProvCode === filterDropValue.city.cityProvCode)); // && e.rgnCode !== "44130"

  // 선택한 시/군/구 에 대한 읍/면/동
  let dongs = getCalDongList(dongList.filter(e => e.rgnCode === filterDropValue.rgn.rgnCode));
  if(filterDropValue.rgn.rgnCode === '44130'){
    dongs = getCalDongList(dongList.filter(e => e.rgnCode === "44131" || e.rgnCode === "44133"));
  }
  else {
    dongs = getCalDongList(dongList.filter(e => e.rgnCode === filterDropValue.rgn.rgnCode));
  }

  // 사업 구분 데이터
  const bizSctData = ["건물지원", "공공기관지원", "설치의무화", "시범보급", "융복합사업", "주택지원", "지역지원", "태양열주택"];

  // 그리드 데이터
  const [dataSource, setDataSource] = useState({
    total: 0,
    dataList: []
  });
  const [, setBackupDataSource] = useState();

  // 그리드 데이터 상태
  const [dataState, ] = useState({
    take: 15
    , skip: 0
    , sort: []
    , sortStatus: []
    , filter: { bizSct: "", bizYy: "", cityProvCode: "", rgnCode: "", fixbylawBundCode: "", address: "", ensoTypeCode: "" }
  });

  // 추가, 수정 모달창
  const [modalForm, setModalForm] = useState({
    visible: false
    , title: ''
    , formItem: []
    , isNew: false
  });

  // 데이터 불러오기
  const getSttstGridData = () => {
    getSttstData(conn, dataState).then(response => {
      setLoading(false);
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.total = response.total;
        item.dataList = response.results;
        return item;
      });
    }).catch(error => {
      setLoading(false);
      log(`getSttstGridData: ${error}`);
    });
  }

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const { take, skip } = e.page;
    dataState.skip = skip;
    dataState.take = take;
    getSttstGridData();
  };

  // 그리드 소팅 처리
  const handleSortChange = e => {
    const { sort } = e;
    dataState.sort = sort;
    dataState.sortStatus = sort;
    if (sort.length !== 0) {
      if (sort[0].field === 'fixbylawBundTermSimple') {
        dataState.sort = 'fixbylawBundCode.' + sort[0].field + ',' + sort[0].dir;
      } else if (sort[0].field === 'rgnTermSimple') {
        dataState.sort = 'rgnCode.' + sort[0].field + ',' + sort[0].dir;
      } else if (sort[0].field === 'cityProvTerm') {
        dataState.sort = 'cityProvCode.' + sort[0].field + ',' + sort[0].dir;
      } else {
        dataState.sort = sort[0].field + ',' + sort[0].dir;
      }
    }
    getSttstGridData();
  };

  // 그리드 필터링 처리
  const handleFilterChange = e => {
    dataState.skip = 0;
    dataState.take = 15;

    // 필터 값 설정
    const isFilter = e.filter.filters;
    if (isFilter.length !== 0) {
      if (isFilter[0].field === "bizSct") { if (isFilter[0].value === "사업구분") dataState.filter.bizSct = ""; else dataState.filter.bizSct = isFilter[0].value; }
      else if (isFilter[0].field === "bizYy") { if (isFilter[0].value === "사업연도") dataState.filter.bizYy = ""; else dataState.filter.bizYy = isFilter[0].value; }
      else if (isFilter[0].field === "cityProvTerm") { dataState.filter.cityProvCode = isFilter[0].value.cityProvCode; dataState.filter.rgnCode = ""; dataState.filter.fixbylawBundCode = ""; }
      else if (isFilter[0].field === "rgnTermSimple") { dataState.filter.rgnCode = isFilter[0].value.rgnCode; dataState.filter.fixbylawBundCode = ""; }
      else if (isFilter[0].field === "fixbylawBundTermSimple") { dataState.filter.fixbylawBundCode = isFilter[0].value.fixbylawBundCode; }
      else if (isFilter[0].field === "address") { dataState.filter.address = isFilter[0].value; }
      else if (isFilter[0].field === "ensoTypeCode") { dataState.filter.ensoTypeCode = isFilter[0].value.codeNo; }
    }
    getSttstGridData();
  };

  // 시도 종류 드롭다운
  const handleCityFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropValue(filterDropValue => {
        const item = { ...filterDropValue };
        item.city = e.value;
        item.rgn = { rgnCode: "", rgnTermSimple: "시/군/구" };
        item.dong = { fixbylawBundCode: "", fixbylawBundTermSimple: "읍/면/동" };
        return item;
      });
    }

    return (
      <div className="k-filtercell">
        <DropDownList
          data={cityList}
          onChange={filterOnchage}
          defaultItem={{ cityProvCode: "", cityProvTerm: "시/도" }}
          className="CustomFilterDropDown"
          textField="cityProvTerm" dataItemKey="cityProvCode" name="city"
          value={filterDropValue.city}
        />
      </div>
    );
  }

  // 시/군/구 그리드 필터 드롭다운
  const rgnFilterDropDown = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropValue(filterDropdown => {
        const item = { ...filterDropdown };
        item.rgn = e.value;
        item.dong = { fixbylawBundCode: "", fixbylawBundTermSimple: "읍/면/동" };
        return item;
      });
    }

    return (
      <div className="k-filtercell">
        <DropDownList
          data={rgns}
          onChange={filterOnchage}
          defaultItem={{ rgnCode: "", rgnTermSimple: "시/군/구" }}
          className="CustomFilterDropDown" name="rgn"
          textField="rgnTermSimple" dataItemKey="rgnCode"
          value={filterDropValue.rgn}
          disabled={filterDropValue.city.cityProvCode === ""}
        />
      </div>
    );
  }

  // 읍/면/동 그리드 필터 드롭다운
  const dongFilterDropDown = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropValue(filterDropdown => {
        const item = { ...filterDropdown };
        item.dong = e.value;
        return item;
      });
    }

    return (
      <div className="k-filtercell">
        <DropDownList
          data={dongs}
          onChange={filterOnchage}
          defaultItem={{ fixbylawBundCode: "", fixbylawBundTermSimple: "읍/면/동" }}
          className="CustomFilterDropDown" name="dong"
          textField="fixbylawBundTermSimple" dataItemKey="fixbylawBundCode"
          value={filterDropValue.dong}
          disabled={filterDropValue.rgn.rgnCode === "" || filterDropValue.city.cityProvCode === ""}
        />
      </div>
    );
  }

  // 에너지원 종류 드롭다운
  const handleEnergyTypeFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropValue(filterDropValue => {
        const item = { ...filterDropValue };
        item.energyType = e.value;
        return item;
      });
    }

    return (
      <div className="k-filtercell">
        <DropDownList
          data={energyType}
          onChange={filterOnchage}
          defaultItem={{ codeNo: "", codeVal: "에너지원" }}
          className="CustomFilterDropDown"
          textField="codeVal" dataItemKey="codeNo" name="energyType"
          value={filterDropValue.energyType}
        />
      </div>
    );
  }

  // 사업구분 드롭다운
  const handleBizSctFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropValue(filterDropValue => {
        const item = { ...filterDropValue };
        item.bizSct = e.value;
        return item;
      });
    }

    return (
      <div className="k-filtercell">
        <DropDownList
          data={bizSctData}
          onChange={filterOnchage}
          defaultItem="사업구분"
          className="CustomFilterDropDown"
          name="bizSct"
          value={filterDropValue.bizSct}
        />
      </div>
    );
  }

  // 사업연도 드롭다운
  const handleBizYearFilterList = (props) => {
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });
      setFilterDropValue(filterDropValue => {
        const item = { ...filterDropValue };
        item.bizYy = e.value;
        return item;
      });
    }

    return (
      <div className="k-filtercell">
        <DropDownList
          data={yearList()}
          onChange={filterOnchage}
          defaultItem="사업연도"
          className="CustomFilterDropDown"
          name="bizYy"
          value={filterDropValue.bizYy}
        />
      </div>
    );
  }

  // 추가 모달창 저장/수정 처리
  const handleModalSaveSubmit = useCallback(e => {
    // 데이터 저장
    setModalForm({ ...modalForm, visible: !modalForm.visible, title: '추가', formItem: [], isNew: modalForm.isNew });
    e.createUpdateYn = e.isNew;
    setUpdateSttst(conn, e)
      .then(response => {
        setSaveLoading(false);
        if (!response) {
          kendo.alert("저장을 실패 하였습니다.");
          return;
        }
        setModalForm({ ...modalForm, visible: !modalForm.visible, title: '', formItem: [] });
        kendo.alert("저장을 완료 하였습니다.");
        getSttstGridData();
      })
      .catch(err => {
        setSaveLoading(false);
        kendo.alert("저장을 실패 하였습니다.");
        // getRejection(err);
      });
      // eslint-disable-next-line
  }, [modalForm, dataSource, dataState]);

  // // 리젝션 코드
  // const getRejection = (errorCode) => {
  //   // 현재 BizException 발생 시 app.jsx 에서 이미 catch 하고 정상으로 response 로 처리하며, 마지막 response 는 undefined 로 오기에 확인이 필요
  //   const isValid = ValidBizException(errorCode);
  //   if (!isValid) {
  //     kendo.alert(isValid.message);
  //     return;
  //   }
  // }

  // // 엑셀 업로드 시 response 값 확인 후 BizException 시 안내 창 
  // const ValidBizException = (errorCode) => {
  //   let isValid = true;
  //   let message = "";
  //   if (errorCode === "L006") {
  //     isValid = false;
  //     message = "ID가 중복 입니다.";
  //   }

  //   return { isValid: isValid, message: message };
  // }


  //순번 부여 함수
  const sttstMngtListNoCell = (props) => {
    var record = 0
    record = dataSource.total - props.dataIndex;

    return <td>{record}</td>
  }

  //에너지원 코드 -> 이름 치환 함수
  const sttstMngtListEnergyCell = (props) => {
    if (props.dataItem.ensoTypeCode === "15001") {
      props.dataItem.ensoTerm = "태양광";
      return <td>태양광</td>
    }
    else if (props.dataItem.ensoTypeCode === "15002") {
      props.dataItem.ensoTerm = "태양열";
      return <td>태양열</td>
    }
    else if (props.dataItem.ensoTypeCode === "15003") {
      props.dataItem.ensoTerm = "지열";
      return <td>지열</td>
    }
    else if (props.dataItem.ensoTypeCode === "15004") {
      props.dataItem.ensoTerm = "풍력";
      return <td>풍력</td>
    }
    // else if (props.dataItem.ensoTypeCode == "15005")
    //   return <td></td>
    else if (props.dataItem.ensoTypeCode === "15006") {
      props.dataItem.ensoTerm = "연료전지";
      return <td>연료전지</td>
    }
    else if (props.dataItem.ensoTypeCode === "15007") {
      props.dataItem.ensoTerm = "ESS";
      return <td>ESS</td>
    }
    else
      return <td></td>
  }

  //용량 부여 함수
  const sttstMngtListUnitCell = (props) => {
    if (props.dataItem.ensoTypeCode === "15002")
      return <td>㎡</td>
    else
      return <td>kW</td>
  }

  useEffect(() => {
    getSttstData(conn, dataState).then(response => {
      setLoading(false);
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.total = response.total;
        item.dataList = response.results;
        return item;
      });
      setBackupDataSource(response.results)
    }).catch(error => {
      setLoading(false);
      log(`getSttstGridData: ${error}`);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={["sttstBody", saveLoading ? styles.sttstG : ""].join(' ')}>
        <div className={loading ? styles.sttstG : ""}>
          <div className="sttstMngtTitle">
            <h2>&nbsp;&nbsp;통계관리 페이지</h2>
          </div>
          <div className="sttstGrid">
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid
                  className={styles.SttstGrid}
                  data={dataSource.dataList}
                  skip={dataState.skip}
                  take={dataState.take}
                  total={dataSource.total}
                  sort={dataState.sortStatus}
                  pageable
                  filterable
                  sortable
                  onPageChange={handlePageChange}
                  onSortChange={handleSortChange}
                  onFilterChange={handleFilterChange}
                >
                  <GridColumn field='sttstNumber' title='순번' filterable={false} width={100} cell={sttstMngtListNoCell} />
                  <GridColumn field='installerName' title='성함' filterable={false} width={120} />
                  <GridColumn field='bizSct' title='사업구분' filterCell={handleBizSctFilterList} width={130} />
                  <GridColumn field='bizYy' title='사업연도' filterCell={handleBizYearFilterList} width={100} />
                  <GridColumn field='cityProvTerm' title='시/도' filterCell={handleCityFilterList} width={100} />
                  <GridColumn field='rgnTermSimple' title='시/군/구' filterCell={rgnFilterDropDown} width={140} />
                  <GridColumn field='fixbylawBundTermSimple' title='읍/면/동' filterCell={dongFilterDropDown} width={130} />
                  <GridColumn field='address' title='주소' filterCell={GridColumnInputFilter} width={400} />
                  <GridColumn field='ensoTypeCode' title='에너지원' filterCell={handleEnergyTypeFilterList} width={110} cell={sttstMngtListEnergyCell} />
                  <GridColumn field='instCapa' title='용량' filterable={false} width={100} />
                  <GridColumn field='unit' title='단위' filterable={false} width={90} cell={sttstMngtListUnitCell} />
                  <GridColumn field='remarks' title='비고' filterable={false} />
                  <GridColumn
                    title='수정'
                    filterable={false}
                    sortable={false}
                    width={60}
                    cell={
                      props => (
                        <td>
                          <button id="sttstEditBtn"
                            onClick={e => {
                              e.preventDefault();
                              setModalForm({
                                ...modalForm
                                , visible: !modalForm.visible
                                , title: '수정'
                                , createUpdateYn: false
                                , formItem: props.dataItem
                                , isNew: false
                              });
                            }}
                          >
                            <i>
                              <Create />
                            </i>
                          </button>
                        </td>
                      )}
                  />
                  <GridColumn
                    title='삭제'
                    filterable={false}
                    sortable={false}
                    width={60}
                    cell={
                      props => (
                        <td>
                          <button id="sttstDelBtn"
                            onClick={e => {
                              e.preventDefault();
                              const { cid, ensoTypeCode } = props.dataItem;
                              // 삭제 여부 확인 
                              kendo.confirm("해당 데이터가 DB에서 완전히 삭제됩니다. 진행하시겠습니까?").then(e => {
                                setDeleteSttst(conn, { cid, ensoTypeCode })
                                  .then(response => {
                                    kendo.alert("삭제가 완료 되었습니다.");
                                    getSttstGridData(conn, dataState);
                                  })
                                  .catch(err => {
                                    kendo.alert("삭제를 실패 하였습니다.");
                                    log(`sttstGrid.setDeleteSttst error: ${err}`);
                                  });
                              }).then(e => {
                                
                              });
                            }}
                          >
                            <i>
                              <Delete />
                            </i>
                          </button>
                        </td>
                      )}
                  />
                  <GridNoRecords>
                    데이터가 존재하지 않습니다.
                  </GridNoRecords>
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
            {
              modalForm.visible &&
              <SttstMngtModal
                modalForm={modalForm}
                setModalForm={setModalForm}
                city={cityList}
                rgn={rgnList}
                fixbylaw={dongList}
                energyType={energyType}
                bizSct={bizSctData}
                handleModalUpdateSubmit={handleModalSaveSubmit}
              />
            }
          </div>
        </div>
        {loading && <p className={styles.sttstLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
      </div>
      {saveLoading && <p className={styles.sttstLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
    </>
  );
};

export default SttstDataMngt;