
import posit from '../localAreaMonitorBoxPosition.module.css';

/**
 * 맵에 보여줄 위치 style 적용
 * @param {*} item 
 */
export const setBoxPositionCss = (item) =>{
  let position = "";
  const fixbylawBundCode = Number(item.fixbylawBundCode);
  // 정선
  if( fixbylawBundCode === 4277025000 ) { position = posit.area4277025000; }
  if( fixbylawBundCode === 4277025300 ) { position = posit.area4277025300; }
  if( fixbylawBundCode === 4277025600 ) { position = posit.area4277025600; }
  if( fixbylawBundCode === 4277025900 ) { position = posit.area4277025900; }
  if( fixbylawBundCode === 4277032000 ) { position = posit.area4277032000; }
  if( fixbylawBundCode === 4277034000 ) { position = posit.area4277034000; }
  if( fixbylawBundCode === 4277035000 ) { position = posit.area4277035000; }
  if( fixbylawBundCode === 4277036000 ) { position = posit.area4277036000; }
  if( fixbylawBundCode === 4277037000 ) { position = posit.area4277037000; }

  return position;
}