//React
import React from "react";
import "./reportDay.css";
import styles from './reportDay.module.css';

// 상단 대시보드 테이블
export const ReportDayEnergyTable = ({search , dashBoard}) => {
  // console.log(dashBoard)
  return (
    <div className="uk-grid">
      <div className="uk-width-medium-4-10">
        <table className="reportEnergyTable">
          <thead>
            <th colSpan="3" style={{color:"#87CEEB"}}>전기 에너지</th>
          </thead>
          <tbody>
              <tr>
                { search.dateType === "DD" && <th scope="col"> 금일 발전량 </th> }
                { search.dateType === "MM" && <th scope="col"> 월일 발전량 </th> }
                { search.dateType === "YYYY" && <th scope="col"> 년도 발전량 </th> }
                <th scope="col">누적발전량</th>
              </tr>
              <tr>
                <td><span className = {styles.dataLabel}>{dashBoard.power}</span>&nbsp;kWh</td>
                <td><span className = {styles.dataLabel}>{dashBoard.accumPower}</span>&nbsp;MWh</td>
              </tr>
              <tr>                
                <th scope="col">누적CO2저감량</th>
                <th scope="col">이용률</th>
              </tr>
              <tr>
                <td><span className = {styles.dataLabel}>{dashBoard.accumCo2}</span>&nbsp;tCO2</td>
                <td><span className = {styles.dataLabel}>{dashBoard.eficCalRate}</span>&nbsp;%</td>  
              </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-medium-3-10">
        <table className="reportEnergyTable">
          <thead>
            <th colSpan="3" style={{color:"#9ACD32"}}>열 에너지</th>
          </thead>
          <tbody>
              <tr>
                { search.dateType === "DD" && <th scope="col"> 금일 생산열량 </th> }
                { search.dateType === "MM" && <th scope="col"> 월일 생산열량 </th> }
                { search.dateType === "YYYY" && <th scope="col"> 년도 생산열량 </th> }
              </tr>
              <tr>
                <td><span className = {styles.dataLabel}>{dashBoard.cal}</span>&nbsp;kWh</td>
              </tr>
              <tr>
                <th scope="col">누적생산열량</th>
              </tr>
              <tr>
                <td><span className = {styles.dataLabel}>{dashBoard.accumCal}</span>&nbsp;MWh</td>
              </tr>
          </tbody>
        </table>
      </div>
      <div className="uk-width-medium-3-10">
        <table className="reportEnergyTable">
          <thead>
            <th colSpan="3" style={{color:"#F08080"}}>ESS</th>
          </thead>
          <tbody>
              <tr>
                { search.dateType === "DD" && <th scope="col"> 금일 충전량 </th> }
                { search.dateType === "MM" && <th scope="col"> 월일 충전량 </th> }
                { search.dateType === "YYYY" && <th scope="col"> 년도 충전량 </th> }
                <th scope="col">충전시간</th>
              </tr>
              <tr>
                <td> - &nbsp;kWh</td>
                <td> - &nbsp;시간</td>
              </tr>
              <tr>
                <th scope="col">누적충전량</th>
                <th scope="col">충전효율</th>
              </tr>
              <tr>
                <td> - &nbsp;kWh</td>
                <td> - &nbsp;%</td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportDayEnergyTable;
