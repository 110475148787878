import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { numFormat, roundNum } from "../../../com/dataUtil";

const PostPowerGrid = ({ search, dataSourceChart, dataSourceGrid, setDataSource, userInfo, loading, styles }) => {
    Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.area.prototype.drawLegendSymbol;
    Highcharts.setOptions({
        lang: {
            thousandsSep: ','
        }
    });

    const getWeaImg = weather => {
        let img;
        if (weather === "구름") {
            img = "/images/weather/weather_icon01.png";
        } else if (weather === "구름많음") {
            img = "/images/weather/weather_icon02.png";
        } else if (weather === "구름조금") {
            img = "/images/weather/weather_icon03.png";
        } else if (weather === "눈") {
            img = "/images/weather/weather_icon04.png";
        } else if (weather === "비/눈") {
            img = "/images/weather/weather_icon05.png";
        } else if (weather === "맑음") {
            img = "/images/weather/weather_icon06.png";
        } else if (weather === "비") {
            img = "/images/weather/weather_icon07.png";
        } else if (weather === "흐림") {
            img = "/images/weather/weather_icon08.png";
        } else if (weather === "소나기") {
            img = "/images/weather/weather_icon09.png";
        } else if (weather === "눈/비") {
            img = "/images/weather/weather_icon10.png";
        } else {
            img = "/images/weather/weather_icon06.png";
        }
        return img;
    }

    let areaChart = {
        chart: {
            zoomType: 'xy'
        },
        title: { text: null },
        xAxis: [{
            categories:
                ["5일전", "5일전", "5일전", "5일전", "5일전", "5일전", "5일전", "5일전",
                    "5일전", "5일전", "5일전", "5일전", "5일전", "5일전", "5일전", "5일전",
                    "5일전", "5일전", "5일전", "5일전", "5일전", "5일전", "5일전", "5일전",
                    "4일전", "4일전", "4일전", "4일전", "4일전", "4일전", "4일전", "4일전",
                    "4일전", "4일전", "4일전", "4일전", "4일전", "4일전", "4일전", "4일전",
                    "4일전", "4일전", "4일전", "4일전", "4일전", "4일전", "4일전", "4일전",
                    "3일전", "3일전", "3일전", "3일전", "3일전", "3일전", "3일전", "3일전",
                    "3일전", "3일전", "3일전", "3일전", "3일전", "3일전", "3일전", "3일전",
                    "3일전", "3일전", "3일전", "3일전", "3일전", "3일전", "3일전", "3일전",
                    "2일전", "2일전", "2일전", "2일전", "2일전", "2일전", "2일전", "2일전",
                    "2일전", "2일전", "2일전", "2일전", "2일전", "2일전", "2일전", "2일전",
                    "2일전", "2일전", "2일전", "2일전", "2일전", "2일전", "2일전", "2일전",
                    "1일전", "1일전", "1일전", "1일전", "1일전", "1일전", "1일전", "1일전",
                    "1일전", "1일전", "1일전", "1일전", "1일전", "1일전", "1일전", "1일전",
                    "1일전", "1일전", "1일전", "1일전", "1일전", "1일전", "1일전", "1일전",
                    "오늘", "오늘", "오늘", "오늘", "오늘", "오늘", "오늘", "오늘",
                    "오늘", "오늘", "오늘", "오늘", "오늘", "오늘", "오늘", "오늘",
                    "오늘", "오늘", "오늘", "오늘", "오늘", "오늘", "오늘", "오늘",
                    "내일", "내일", "내일", "내일", "내일", "내일", "내일", "내일",
                    "내일", "내일", "내일", "내일", "내일", "내일", "내일", "내일",
                    "내일", "내일", "내일", "내일", "내일", "내일", "내일", "내일",
                ],
            crosshair: true,
            tickInterval: 24,
            tickWidth: 1,
            // tickPositions: [11, 35, 59, 83, 107, 131, 155],
            labels: {
                enabled: false,
                align: 'center',
                style: {
                    fontSize: 15
                }
            }
        }],
        yAxis: [{ // Primary yAxis
            title: {
                text: '발전량',
            },
            labels: {
                format: '{value}kWh',
            },
            // min: 0,
            // // max: 1200,
            tickAmount: 5,
        }, { // Secondary yAxis
            title: {
                text: '발전오차율',
            },
            labels: {
                format: '{value}%',
            },
            min: 0,
            max: 100,
            tickAmount: 5,
            opposite: true
        }],
        credits: { enabled: false },
        tooltip: {
            backgroundColor: '#FFFFFF',
            borderColor: 'none',
            headerFormat: '<span style="font-size:13px; padding-bottom:8px">{point.key}</span><br/>',
            shared: true,
            useHTML: true,
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "top",
            floating: false,
        },
        series: [{
            name: '발전량',
            type: 'column',
            yAxis: 0,
            color: '#4a70c8',
            data: dataSourceChart[0]?.powerChartData,
            tooltip: {
                valueSuffix: " kWh",
            },
            // zoneAxis: 'x',
            // zones: [{
            //     value: 75
            // }, {
            //     color: '#f69725'
            // }]
        }, {
            name: '예상발전량',
            // type: 'line',
            type: 'column',
            yAxis: 0,
            color: '#f69725',
            data: dataSourceChart[0]?.expePowerChartData,
            tooltip: {
                valueSuffix: " kWh",
                color: '#fafafa',
            },
            marker: {
                enabled: false
            }
        },
        {
            name: '발전오차율',
            type: 'line',
            yAxis: 1,
            color: '#FF5B5C',
            data: dataSourceChart[0]?.errRateData,
            tooltip: {
                valueSuffix: " %",
            },
            marker: {
                enabled: false
            }
        }]
    };        

    return (
        <div>
            <br />
            <div className="uk-width-medium-1-1">
                <div className="md-card">
                    <div className="md-card-content">
                        <div className="md-card-toolbar">
                            <h3 className="md-card-toolbar-heading-text">
                                최근 7일 발전시간 비교
                            </h3>
                        </div>
                        {
                            dataSourceChart[0]?.expePowerChartData == null
                                ?
                                <div className="uk-width-medium-1-1 noticeNotSetCity">
                                    <br/><br/><br/><br/><br/><br/><br/>
                                    <h2>정보를 조회하시려면 지역을 선택하신 후에 검색 버튼을 눌러주세요.</h2>
                                </div>
                                :
                                <div>
                                    <HighchartsReact
                                        options={areaChart}
                                    />
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="uk-width-medium-1-1">
                <div className="md-card">
                    <div className="md-card-content">
                        {
                            dataSourceChart[0]?.expePowerChartData == null
                                ?
                                <div></div>
                                : <div>
                                    <div className={styles.weekDataBox}>
                                        <table className={`${styles.weekDataIndex} data-table-index`}>
                                            <thead>
                                                <tr>
                                                    <th colSpan="2" className={`week-header-index ${styles.weekDataHead}`}>
                                                        <p></p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr><td className={`${styles.weekDataIndexRowWeaT}`}>날씨<br />발전시간</td></tr>
                                                <tr><td className={`${styles.weekDataIndexRow}`}>발전량</td></tr>
                                                <tr><td className={`${styles.weekDataIndexRow}`}>예상발전량</td></tr>
                                                <tr><td className={`${styles.weekDataIndexRow}`}>발전오차율</td></tr>
                                                <tr><td className={`${styles.weekDataIndexRow}`}>발전인센티브</td></tr>
                                                <tr><td className={`${styles.weekDataIndexRow}`}>SMP 수익</td></tr>
                                            </tbody>
                                        </table>
                                        {dataSourceGrid.map((day, index) => {
                                            //오차율 계산
                                            const errRate = Math.abs((day[0].expeDayPower - day[0].dayPower) / day[0].todayInstCapa * 100);

                                            //오차율을 기반으로 인센티브 계산
                                            let powerIncen;
                                            if (errRate <= 6) {
                                                powerIncen = day[0].dayPower * 4;
                                            }
                                            else if (errRate <= 8) {
                                                powerIncen = day[0].dayPower * 3;
                                            }
                                            else if (errRate > 8) {
                                                powerIncen = 0;
                                            }

                                            //SMP 계산
                                            const smpAmount = day[0].dayPower * day[0].smpRate;

                                            return (
                                                <table className={`${styles.weekData} data-table-${day.day}`} key={index}>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="2" className={`week-header-${day.day} ${styles.weekDataHead}`}>
                                                                {day[0].day}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="2" className={`week-data-weather ${styles.weekDataWeaT}`}>
                                                                <img src={getWeaImg(day[0].weather)} alt="날씨"/>
                                                                <br />
                                                                {day[0].todayInstCapa === 0 ? "-" : day[0].powerTm + "시간"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={`week-data-genAmount ${styles.weekDataCont}`}>
                                                                {day[0].todayInstCapa === 0 ? "-" : numFormat(day[0].dayPower)}
                                                            </td>
                                                            <td className={`${styles.weekDataTerm}`}>
                                                                <b>kWh</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={`week-data-className ${styles.weekDataCont}`}>
                                                                {isNaN(day[0].expeDayPower) ? 0 : numFormat(day[0].expeDayPower)}
                                                            </td>
                                                            <td className={`${styles.weekDataTerm}`}>
                                                                <b>kWh</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={`week-data-errRate ${styles.weekDataCont}`}>
                                                                {day[0].todayInstCapa === 0 ? "-" : (numFormat(roundNum(errRate, 1)))}
                                                            </td>
                                                            <td className={`${styles.weekDataTerm}`}>
                                                                <b>%</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={`week-data-genIncen ${styles.weekDataCont}`}>
                                                                {day[0].todayInstCapa === 0 ? "-" : (numFormat(roundNum(powerIncen, 1)))}
                                                            </td>
                                                            <td className={`${styles.weekDataTerm}`}>
                                                                <b>원</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className={`week-data-smpAmount ${styles.weekDataCont}`}>
                                                                {day[0].todayInstCapa === 0 ? "-" : (numFormat(roundNum(smpAmount, 0)))}
                                                            </td>
                                                            <td className={`${styles.weekDataTerm}`}>
                                                                <b>원</b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            );
                                        })}
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostPowerGrid;
