// EMS 에너지 데이터의 포맷을 지정 dataFormat.js

import { getUserMngRgnList, isManager } from "../service/authService";

/**
 * 숫자 천단위마다 콤마추가 정규식
 *
 * @param x 변경전 숫자
 * @return 변경후 숫자
 */
export const numFormat = x => {
  if (x == null) return 0;
  return x.toLocaleString();
};

/**
 * 소수점 반올림 처리
 * */
export const roundNum = (num, scale) => {
  if (!("" + num).indexOf("e") >= 0) {
    return +(Math.round(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = "";
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
};

const _UNIT_KILO = "K";
const _UNIT_MEGA = "M";
const _UNIT_GIGA = "G";

/*
 * 십억(giga)  G 십억     1 000 000 000
 * 메가(mega)  M 백만     1 000 000
 * 킬로(kilo)  k 천       1000
 * 데시(deci)  d 십분의 일 0.1
 * 센티(centi) c 백분의 일 0.01
 * 밀리(milli) m 천분의 일 0.001
 */

/**
 * 숫자 포맷 지정 및 소수점 자리수 지정
 * @param num 숫자
 * @param unit 단위 (K,M,G)
 * 십억(giga)  G 십억     1 000 000 000
 * 메가(mega)  M 백만     1 000 000
 * 킬로(kilo)  K 천       1000
 * @param scale 소수점 자리수
 **/
export const numUnit = (num, unit, scale = 0) => {
  let divisionNum = 1;
  if (unit === _UNIT_KILO) divisionNum = 1000;
  else if (unit === _UNIT_MEGA) divisionNum = 1000 * 1000;
  else if (unit === _UNIT_GIGA) divisionNum = 1000 * 1000 * 1000;
  return numFormat(roundNum(num / divisionNum, scale));
};

/**
 * 숫자 단순 포맷 지정 및 소숫점 자리수 지정
 * @param num 숫자
 * @param scale 소수점 자리수
 */
export const num = (num, scale = 0) => numUnit(num, "", scale);

/**
 *  킬로 (1000) 단위 숫자 변환
 * @param num 숫자
 * @param scale 소수점 자리수
 */
export const kiloNum = (num, scale = 0) => numUnit(num, _UNIT_KILO, scale);

/**
 *  메가(1 000 000) 단위 숫자 변환
 * @param num 숫자
 * @param scale 소수점 자리수
 */
export const megaNum = (num, scale = 0) => numUnit(num, _UNIT_MEGA, scale);

/**
 *  기가(1 000 000 000) 단위 숫자 변환
 * @param num 숫자
 * @param scale 소수점 자리수
 */
export const gigaNum = (num, scale = 0) => numUnit(num, _UNIT_GIGA, scale);

export const _CO2_CAL_VAL = 0.45941; // 발전량,소비량 Co2 변환 계산 인자
export const _WOOD_CAL_VAL = 6.6;
/**
 * Co2 저감량
 *  @param power 발전량 또는 소비량 숫자값
 */
export const reduceCo2 = power => {
  function replaceAll(str, searchStr, replaceStr) {
    return str.split(searchStr).join(replaceStr);
  }
  if (isNaN(power)) {
    power = replaceAll(power, ",", "");
    power = parseFloat(power);
  }
  return numFormat((power * _CO2_CAL_VAL).toFixed(2));
};

/**
 * Co2 저감량 (ton 단위)
 *  @param power 발전량 또는 소비량 숫자값
 */
export const reduceTCo2 = power => numFormat(Math.ceil((power * _CO2_CAL_VAL) / 1000)); //소수점 반올림

/**
 * 식수(그루) 변환
 *  @param power 발전량 또는 소비량 숫자값
 */
export const wood = power => numFormat(Math.floor((power * _CO2_CAL_VAL) / _WOOD_CAL_VAL)); // 소수점 버림

export const isPowerEnsoType = ensoTypeCode => {
  if (ensoTypeCode === 15001 || ensoTypeCode === 15004 || ensoTypeCode === 15006) return true;
  else return false;
};

// 시/도 중복 처리 제거 된 리스트
export const getCalCityList = (cityList) => {
  if( isManager() ){
    if( cityList ){
      const rgnList = [];
      getUserMngRgnList().forEach(element => {
        if( !rgnList.includes(element.substring(0,2)) ){
          rgnList.push(element.substring(0,2));
        }
      });
  
      // 데이터 저장
      const rgnResultList = [];
      rgnList.forEach( cityCode => {
        cityList.forEach( cityData => {
          if( cityCode === cityData.cityProvCode ) rgnResultList.push(cityData);
        })
      });
      return rgnResultList;
    }else{
      return cityList;  
    }
  }else{
    return cityList;
  }
}

// 시/군/구 리스트
export const getCalRgnList = (rgnList) => {
  if( isManager() ){
    if( rgnList ){
      const rgnListfilter = [];
      getUserMngRgnList().forEach(element => {
        rgnList.forEach(rgns => { 
          if( rgns.rgnCode === element ){    
            rgnListfilter.push(rgns);
          }
        })
      });

      return rgnListfilter;
    } else {
      return rgnList;
    }
  } else {
    return rgnList;
  }
}

// 읍/면/동 리스트
export const getCalDongList = (dongList) => {
  if( isManager() ){
    if( dongList ){
      const dongListfilter = [];
      getUserMngRgnList().forEach(element => {
        dongList.forEach(dongs => { 
          if( dongs.fixbylawBundCode === element ){    
            dongListfilter.push(dongs);
          }
        })
      });
      return dongListfilter;
    } else {
      return dongList;
    }
  } else {
    return dongList;
  }
}