import { DropDownList } from '@progress/kendo-react-dropdowns';
import React from "react";

/**
 * 객체 그리드 필터
 * @param {Kendo Grid 객체 명} props 
 * @param {해당 필터 이름 설정} name 
 * @param {필터 객체} filter 
 * @param {필터 아이템 키} dataItemKey 
 * @param {필터 아이템 이름} textField 
 * @param {필터 데이터 리스트} dataList 
 * @param {기본 아이템 설정} defaultItem 
 * @param {필터 관리 객체} filterDropdown 
 * @param {필터 관리 객체 설정 함수} setFilterDropdown 
 * @param {활성화 조건 : 조건을 사용하면 조건 식 추가 사용 안한다면 false } disabled 
 * @returns 
 */
export const customFilterObj = ( props , name , filter, dataItemKey , textField , dataList , defaultItem, filterDropdown , setFilterDropdown , disabled ) => {
  // 시/군/구 그리드 필터 드롭다운
    const filterOnchage = (e) => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent
      });

      setFilterDropdown( filterDropdown => {
        const item = {...filterDropdown};
        item[filter] = e.value;
        return item;
      });
    }  
    
    return (
      <div className="k-filtercell">
        <DropDownList
        data={dataList}
        onChange={filterOnchage}
        defaultItem= {defaultItem}
        className = "CustomFilterDropDown" name = {name}
        textField = {textField} dataItemKey= {dataItemKey}
        value = {filterDropdown[filter]}
        disabled={disabled}
        />
      </div>
    );
    
}