import kendo from "@progress/kendo-ui";
import { roundNum, numFormat } from "../../../com/dataUtil";

const optomalAzimInclUrl = "ai/optomalAzimIncl";

const allChRgnId = ["44133", "44150", "44180", "44200", "44210", "44230", "44250", "44270", "44710", "44760", "44770", "44790", "44800", "44810", "44825",];

// 지도에 표시되는 방위각, 경사각
export const azimInclList = async (conn, callback, reject, setLoading) => {

    setLoading(true);

    await conn.get(optomalAzimInclUrl + "/map")
        .then(response => {
            const result = response;


            // 방위각/경사각 데이터 가공 -> 해당 지역의 설비가 없으면 값이 없기때문
            const azimInclList = { data: result };
            for (let i = 0; i < allChRgnId.length; i++) {
                if (azimInclList.data[i]?.rgnCode !== allChRgnId[i] || i > azimInclList.data.length) {
                    azimInclList.data.splice(i, 0, { areaIncl: "25º", rgnCode: allChRgnId[i], areaAzim: "180º"});
                }
                else {
                    azimInclList.data[i].areaIncl = roundNum(azimInclList.data[i].areaIncl, 1) + "º";
                    azimInclList.data[i].areaAzim = roundNum(azimInclList.data[i].areaAzim, 1) + "º";
                }
            }

            callback(azimInclList);
        }).catch(error => {
            reject(error);
        });
};

export const weathTempList = async (conn, callback, reject, setLoading) => {
    setLoading(true);

    const today = new Date();
    // const today = new Date("2022-09-01");
    const now = kendo.toString(new Date(Date.parse(today)), 'yyyy-MM-dd H');

    const getWeaImg = weather => {
        let img;
        if (weather === "구름") {
            img = "/images/weather/weather_icon01.png";
        } else if (weather === "구름많음") {
            img = "/images/weather/weather_icon02.png";
        } else if (weather === "구름조금") {
            img = "/images/weather/weather_icon03.png";
        } else if (weather === "눈") {
            img = "/images/weather/weather_icon04.png";
        } else if (weather === "비/눈") {
            img = "/images/weather/weather_icon05.png";
        } else if (weather === "맑음") {
            img = "/images/weather/weather_icon06.png";
        } else if (weather === "비") {
            img = "/images/weather/weather_icon07.png";
        } else if (weather === "흐림") {
            img = "/images/weather/weather_icon08.png";
        } else if (weather === "소나기") {
            img = "/images/weather/weather_icon09.png";
        } else if (weather === "눈/비") {
            img = "/images/weather/weather_icon10.png";
        } else {
            img = "/images/weather/weather_icon06.png";
        }
        return img;
    }


    const config = {
        params: {
            now: now
        },
    };


    await conn.get(optomalAzimInclUrl + "/weather", config)
        .then(response => {
            const weathTempList = { data: response };

            for (let i = 0; i < weathTempList.data.length; i++) {
                weathTempList.data[i].weather = getWeaImg(weathTempList.data[i].weather);
                weathTempList.data[i].temp = parseFloat(weathTempList.data[i].temp).toFixed(1);
            }

            callback(weathTempList.data);
        }).catch(error => {
            reject(error);
        });
}

export const optomalDegreeData = async (conn, callback, reject, search, setLoading) => {

    setLoading(true);

    // 날짜 설정
    const today = new Date();
    const now = kendo.toString(new Date(Date.parse(new Date())), 'yyyy-MM-dd');
    // const today = new Date("2022-09-01");
    const gathStart = kendo.toString(new Date(Date.parse(today) - 7 * 1000 * 60 * 60 * 24), 'yyyy-MM-dd');
    const gathFin = kendo.toString(new Date(Date.parse(today) - 1 * 1000 * 60 * 60 * 24), 'yyyy-MM-dd');

    const config = {
        params: {
            rgnCode: search.rgn.rgnCode
            , gathStart: gathStart
            , gathFin: gathFin
            , instCapa: search.instCapa
            , now: now
            , bundCode: search.bundCode
        },
    };

    await conn.get(optomalAzimInclUrl + "/opt", config)
        .then(response => {
            const result = response;

            let count = 0;
            let optomalAzim = 0;
            let optomalIncl = 0;
            result.forEach((element) => {
                //가장 큰 값이 0
                if (element.optomalAzim === 0 && element.optomalIncl === 0) {
                    return;
                }
                //가장 큰 값과의 차이가 5퍼 아래이면
                //yearPowerEffi : ( 해당 설비의 연간 발전량 / 해당 설비의 용량 * 24시간 * 365일 ) * 100 을 한 값 (실제론 1년이 지나지 않아 실제 효율보다 낮게 나옴)
                else if ((result[0].yearPowerEffi / element.yearPowerEffi) - 1 <= 0.05) {
                    count++;
                    optomalAzim += element.optomalAzim;
                    optomalIncl += element.optomalIncl;
                }
                else {
                    return;
                }
            })

            let optomalDegreeData;
            // 해당 지역의 설비가 없어서 값을 못가져왔다는 의미이므로 방위각 : 180, 경사각 : 25로 설정
            if (optomalAzim === 0 || optomalIncl === 0) {
                optomalDegreeData = { azim: 180 + "º", incl: 25 + "º" };
            }
            else {
                optomalDegreeData = { azim: roundNum(optomalAzim / count, 1) + "º", incl: roundNum(optomalIncl / count, 1) + "º" };
            }
            callback(optomalDegreeData);
        }).catch(error => {
            reject(error);
        });

};


export const efficiencyRankingList = async (conn, callback, reject, search, setLoading) => {

    setLoading(true);

    // 날짜 설정
    const today = kendo.toString(new Date(Date.parse(new Date())), 'yyyy-MM-dd');
    // const today = kendo.toString(new Date(Date.parse(new Date("2022-09-01"))), 'yyyy-MM-dd');

    if(search === ""){
        search = 0;
    }

    const config = {
        params: {
            rgnCode: search
            , today: today
        },
    };

    await conn.get(optomalAzimInclUrl + "/ranking", config)
        .then(response => {
            const result = response;

            const efficiencyRankingList = [];
            result.forEach((element, index) => {
                const item = { ...element };
                item.ranking = index + 1 + "위";    
                item.powerEffic = roundNum(element.calcPowerEffic, 2);
                item.instCapa = numFormat(roundNum(element.instCapa, 1));
                item.dayPower = numFormat((roundNum(element.dayPower, 2)));
                item.dailySmpAmount = numFormat((roundNum(element.dayPower * element.smp, 2)));
                efficiencyRankingList.push(item);
            })

            callback(efficiencyRankingList);
        }).catch(error => {
            reject(error);
        });


}
