// React
import React, { useState, useCallback } from 'react';

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from '@progress/kendo-react-buttons';
import { Error } from '@progress/kendo-react-labels';

// Css
import './groupManagerModal.css';
import styles from './groupManagerModal.module.css';

const GroupManagerModal = ({ modalState, setModalState, cityList, rgnList, handleModalSaveSubmit, handleModalUpdateSubmit }) => {

  // console.log("modalState = ", modalState);
  // console.log("rgnList = ", rgnList);

  const smsUseYn = ['Y', 'N'];
  const emailUseYn = ['Y', 'N'];
  const lisenUseYn = ['Y', 'N'];
  const ausenUseYn = ['Y', 'N'];

  // 지역 Converter
  const cityInfo = cityList.filter(e => e.cityProvCode === modalState.formItem.cityProvCode)
  cityInfo.length !== 0 ? modalState.formItem.cityInfo = cityInfo[0] : modalState.formItem.cityInfo = { cityProvCode  : "" , cityProvTerm : "주소검색" } ;  

  const rgnInfo = rgnList.filter(e => e.rgnCode === modalState.formItem.rgnCode)
  rgnInfo.length !== 0 ? modalState.formItem.rgnInfo = rgnInfo[0] : modalState.formItem.rgnInfo = { rgnCode  : "" , rgnTermSimple : "주소검색" } ;

  // servrRgnInfo Converter
  if( typeof modalState.formItem.servrRgnInfo !== "undefined" ){
    if( modalState.formItem.servrRgnInfo.length !== 0 ){
      const servrRgnInfoArray = [];
      const servrRgnInfo = modalState.formItem.servrRgnInfo;
      servrRgnInfo.forEach(element => {
        const rgnData = rgnList.filter( e => e.rgnTermSimple === element )[0];
        servrRgnInfoArray.push( { rgnCode : rgnData.rgnCode , rgnTermSimple : rgnData.rgnTermSimple , rgnTerm : rgnData.rgnTermSimple } );
      });
      modalState.formItem.servrRgnList = servrRgnInfoArray;
    }
  }

    // 시/도 값 
  const [cityData, setCityData] = useState(
    {
      cityProvCode : cityInfo.length !== 0 ? cityInfo[0].cityProvCode : "" , 
      cityProvTerm : cityInfo.length !== 0 ? cityInfo[0].cityProvTerm : "주소검색",
    });

    // 시/군/구 값 
  const [rgnData, setRgnData] = useState(
    {
      rgnCode : rgnInfo.length !== 0 ? rgnInfo[0].rgnCode : "" , 
      rgnTermSimple : rgnInfo.length !== 0 ? rgnInfo[0].rgnTermSimple : "주소검색",
    }
  );

  /* 시도 드롭다운 리스트 */
  const cityDDL = (props) => {
    // console.log("props",props);
    const handleChange = (e) => {
      setCityData(e.value);
      setRgnData({
        rgnCode : "",
        rgnTermSimple : "주소검색"
      })
      props.onChange({
        value: e.value
      });
    };
    return (
      <DropDownList
        style={{ width: '100%' }}
        name="cityInfo"
        // label="시도"
        data={cityList}
        textField = "cityProvTerm" dataItemKey= "cityProvCode"
        value={cityData}
        required={true}
        onChange={handleChange}
        validationMessage={'시/도를 선택하세요'}
      />
    );
  };

  /* 시군구 드롭다운 리스트 */
  const rgnDDL = (props) => {
    const handleChange = (e) => {
      setRgnData(e.value)
      props.onChange({
        value: e.value
      });
    };
    return (
      <DropDownList
        style={{ width: '100%' }}
        name="rgnInfo"
        // label="시도"
        data={rgnList.filter( e=> e.cityProvCode === cityData.cityProvCode )}
        textField = "rgnTermSimple" dataItemKey= "rgnCode"
        value={rgnData}
        required={true}
        onChange={handleChange}
        validationMessage={'시/군/구를 선택하세요'}
        disabled={cityData.cityProvCode === ""}
      />
    );
  };

  // password Validation
  const pwdRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/);
  // eslint-disable-next-line
  const pwdValidator = useCallback( value => (pwdRegex.test(value) ? '' : ' '));
  // eslint-disable-next-line
  const PwdInput = useCallback( fieldRenderProps => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {
          visited && validationMessage &&
            (<Error>{validationMessage}</Error>)
        }
      </div>
    )
  });


  // phon Validation
  const phoneRegex = new RegExp(/^\d{2,3}-\d{3,4}-\d{4}$/);
  // eslint-disable-next-line
  const phoneValidator = useCallback( value => (phoneRegex.test(value) ? '' : ' '));
  // eslint-disable-next-line
  const PhoneInput = useCallback( fieldRenderProps => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {
          visited && validationMessage &&
            (<Error>{validationMessage}</Error>)
        }
      </div>
    );
  });


  // Email Validation
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  // eslint-disable-next-line
  const emailValidator = useCallback( value => (emailRegex.test(value) ? '' : ' '));
  // eslint-disable-next-line
  const EmailInput = useCallback( fieldRenderProps => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {
          visited && validationMessage &&
            (<Error>{validationMessage}</Error>)
        }
      </div>
    );
  });


  // 닫기, 취소 이벤트
  const handleClose = useCallback( () => {
    setModalState({...modalState, visible: !modalState.visible, title: '', formItem: []});
    // eslint-disable-next-line
  }, [modalState]);


  // 저장 버튼
  // eslint-disable-next-line
  const handleSubmit = useCallback( e => { 
    e.isNew = modalState.isNew;
    modalState.title === '추가' ? handleModalSaveSubmit(e) : handleModalUpdateSubmit(e) ;
  });


  return(
    <>
      <Dialog
        title={modalState.title}
        onClose={handleClose}
      >
        <div className="managerModalForm">
          <div className="leftModalItem">
            <div><span className={styles.requriedItem}> * </span>ID</div>
            <div><span className={styles.requriedItem}> * </span>담당자</div>
            <div><span className={styles.requriedItem}> * </span>시/도</div>
            <div><span className={styles.requriedItem}> * </span>시/군/구</div>
            <div><span className={styles.requriedItem}> * </span>패스워드</div>
            <div><span className={styles.requriedItem}> * </span>연락처</div>
            <div><span className={styles.requriedItem}> * </span>이메일</div>
            <div>SMS 수신여부</div>
            <div>EMAIL 수신여부</div>
            <div>가시</div>
            <div>가청</div>
          </div>
          <div className="rightModalItem">
            <Form
              onSubmit={handleSubmit}
              initialValues={modalState.formItem}
              render={ formRenderProps => (
                <FormElement>
                  <div>
                    <Field name='userId' component={Input} placeholder = "아이디 입력" readOnly = {modalState.formItem.userId ? true : false }  required/> 
                  </div>
                  <div>
                    <Field name='userTerm' component={Input} placeholder = "담당자 이름 입력" />
                  </div>
                  <div>
                    <Field
                      name='cityInfo'
                      component={cityDDL}
                      required
                    />
                  </div>
                  <div>
                    <Field
                      name='rgnInfo'
                      component={rgnDDL}
                      required
                    />
                  </div>
                  <div>
                    <Field 
                      name='loginPwd'
                      type='password'
                      component={PwdInput}
                      placeholder='8자 이상 영문,숫자,특수문자 입력'
                      validator={pwdValidator}
                    />
                  </div>
                  <div>
                    <Field 
                      name='userCelphon'
                      type='tel'
                      component={PhoneInput}
                      placeholder='010-1234-5678'
                      validator={phoneValidator}
                    />
                  </div>
                  <div>
                    <Field 
                      name='userEmail' 
                      type='email' 
                      component={EmailInput} 
                      placeholder='myname@example.com' 
                      validator={emailValidator}
                    />
                  </div>
                  <div>
                    <Field
                      name='smsNotiUseYn'
                      component={DropDownList}
                      data={smsUseYn}
                      defaultValue={smsUseYn[0]}
                    />
                  </div>
                  <div>
                    <Field
                      name='emailNotiUseYn'
                      component={DropDownList}
                      data={emailUseYn}
                      defaultValue={emailUseYn[0]}
                    />
                  </div>
                  <div>
                    <Field
                      name='lisenNotiUseYn'
                      component={DropDownList}
                      data={lisenUseYn}
                      defaultValue={lisenUseYn[0]}
                    />
                  </div>                  <div>
                    <Field
                      name='ausenNotiUseYn'
                      component={DropDownList}
                      data={ausenUseYn}
                      defaultValue={ausenUseYn[0]}
                    />
                  </div>
                  <div>
                    <Button onClick={handleClose}>취소</Button>
                    <Button type='submit' disabled={!formRenderProps.allowSubmit}>저장</Button>
                  </div>
                </FormElement>
              )}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default GroupManagerModal;