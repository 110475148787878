
import { BeatLoader } from "react-spinners";

import TroubleShootingStateChart from "./troubleShootingStateChart";

const TroubleShootingStateChartSpace = ({ stateChartData, stateChartLoading }) => {
    const today = new Date();
    return (
        <>
            <div className='uk-grid'>
                <div className="md-card troubleShootingChart">
                    <div className="md-card-content">
                        <div className="md-card-toolbar">
                            <h3 className="md-card-toolbar-heading-text">진단 상태 현황</h3>
                        </div>
                        <TroubleShootingStateChart stateChartData={stateChartData}/>
                    </div>
                    {stateChartLoading && (
                        <p
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                            }}>
                            <BeatLoader loading={true} size={24} color="#1e88e5" />
                        </p>
                    )}
                </div>
            </div>
        </>
    )
};

export default TroubleShootingStateChartSpace;