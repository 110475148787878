import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {isPowerEnsoType, numFormat, roundNum} from '../../../../com/dataUtil';

const WeekEnergyChart = ({weekEnergyList, ensoTypeCode , energy}) => {

  const setChartData = (data) =>(
    data.map( v => (
        {   name: v.term, 
            y: ensoTypeCode === 15002 || ensoTypeCode === 15003 ? energy.sysMode === 29002 ? v.use : v.energy : v.energy, 
            color: '#EE7951'
        }
        ))
  );

  const weekEnergyChartOptions = {
    chart: {
        height:'167px',
        color:'#EE7951',
        backgroundColor:'#f5f5f5'
    },
    title: {
        text: null
    },
    legend: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          exporting: {
      enabled: false
    },
    xAxis: {
        categories: weekEnergyList.map(v => `${v.term?.substring(8,10)}일`),
        crosshair: true
    },
    yAxis: {
        title: {
            text: !isPowerEnsoType(ensoTypeCode) ? energy.sysMode === 29002 ? "소비량(kWh)" : '생산량(kWh)' : '발전량(kWh)',
        },
        labels: {
          formatter: function() { 
            return numFormat(roundNum(this.value , 2));
          }
        }
    },
    plotOptions: {
      series: {
        color:'#EE7951',
        dataLabels: {
          enabled: true,
          style:{
            fontSize: '14px'
          },
          formatter: function() { 
            return '<span><b>' + numFormat(roundNum(this.y , 2)) + '</b></span>' 
          }
        }
      }
    },
    tooltip: { 
      shared: true ,
      formatter: function() {
        let msg = '<span><b>' + this.points[0].key + '</b></span><br>';
        msg += '<span style="color:' + this.points[0].point.color + '">\u25CF</span><span>' + this.points[0].series.name + '</span> : <b>' + numFormat(roundNum(this.points[0].y , 2 )) + ' (kWh)</b><br>';
        return msg;
      }
    },
    series: [{
        type :'spline',
        name : !isPowerEnsoType(ensoTypeCode) ? energy.sysMode === 29002 ? "소비량(kWh)" : '생산량(kWh)' : '발전량(kWh)',
        data: setChartData(weekEnergyList), //setChartData(weekEnergyList),
        marker : {
            lineWidth: 2,
            lineColor: '#EE7951',
            fillColor: 'white'
        }
    }]
  }
  return (
    <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={weekEnergyChartOptions}/>
  )
  };

export default WeekEnergyChart;