import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";

const BrowserKindGrid = ({browserKindsData , selectBrowserId , setBrowserSelectId}) => {

  // onRowClick 이벤트
  const onRowClickEvt = (e) => {
    const dataItem = e.dataItem;
    setBrowserSelectId(dataItem.name);
  }

  return(
    <>
      <Grid
        style={{height:"350px"}} className = "browserKindGrid"
        data = {browserKindsData.map( browserKindsData => {
          const item = {...browserKindsData};
          if( item.name === selectBrowserId ){ item.selected = true; } 
          return item;
        })}
        selectedField="selected"
        selectable = "row"
        onRowClick = {onRowClickEvt}
      >
        <GridNoRecords>
            {"조회 결과 데이터가 없습니다."}
        </GridNoRecords>
        <GridColumn title='운영체제' field='name' />
        <GridColumn title='세션 갯수' field='session' />
      </Grid>
    </>
  );

}

export default BrowserKindGrid;