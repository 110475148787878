// React
import React from "react";

// HighCharts
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { numFormat } from "../../../../com/dataUtil";

HighchartsMore(Highcharts);

export const EnsoLineCharts = ({ data }) => {
  let cateData = [];
  let warnData = [];
  let errData = [];

  if (Object.keys(data).length !== 0) {
    cateData = data.map(item => item.gathDtm);
    warnData = data.map(item => item.warn);
    errData = data.map(item => item.err);
  }

  console.log("data = ", data);
  console.log("cateData = ", cateData);

  const options = {
    chart: { type: "line", height:  28 + '%' },
    title: null,
    credits: false,
    xAxis: {
      categories: cateData,
    },
    yAxis: { title: null },
    tooltip: {
      shared: true,
      crosshairs: true,
      // , headerFormat: '<b>{point.x}시</b><br />'
      formatter: function () {
        return(
          `
            <span><b>${this.points[0].x}</b></span><br>
            <span style='color:${this.points[0].point.color}'>\u25CF</span>
            <span>${this.points[0].series.name}</span> : <b>${numFormat(this.points[0].y)}</b><br>
            <span style='color:${this.points[1].point.color}'>\u25CF</span>
            <span>${this.points[1].series.name}</span> : <b>${numFormat(this.points[1].y)}</b><br>
          `
        );
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          color: "black",
          style: { textShadow: false },
          formatter: function () {
            return `<span><b>${numFormat(this.y)}</b></span>`;
          },
        },
      },
      series: {
        marker: {
          fillColor: "#FFFFFF",
          lineWidth: 2,
          lineColor: null,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        name: "경고",
        data: warnData,
        color: "#F9CC13",
      },
      {
        name: "고장",
        data: errData,
        color: "#FF3B3B",
      },
    ],
  };

  return (
    <>
      <HighchartsReact Highcharts={Highcharts} options={options} />
    </>
  );
};
