import React from "react";
import LocMunicWindow from "./locMunicWindow";
// import log from "./../../../com/log";
import MunicProfit from "./../../monitor/municipality/municProfit";
import LocationMunicTable from "./locationMunicTable";
import { BeatLoader } from "react-spinners";

const LocationMunicPrzt = props => {
  const { conn, navigatorTerm, gridData, rghtWindow, closeRghtWindow, profitData, loading } = props;
  return (
    <div id="locationMunic" style={loading ? { opacity: 0.7 } : null}>
      <div id="locationMunicHeader">{navigatorTerm}</div>
      <div id="locationMunicMap">
        <MunicProfit dataSource={profitData} className={"locationMunicProfit"}></MunicProfit>
        {rghtWindow.visible && <LocMunicWindow conn={conn} rghtWindow={rghtWindow} closeRghtWindow={closeRghtWindow}></LocMunicWindow>}
        <LocationMunicTable gridData={gridData}></LocationMunicTable>
      </div>
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: 99999,
          }}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
};

export default LocationMunicPrzt;
