const BizProgressStatisticsRate = ({ styles, search, dataList, staticProssAdd, windowState }) => {
  const progressList = dataList.convProgressRateList;

  console.log('search: ', search);

  return (
    <>
      <div class="tbTitle">
        <span class="sel-title">[ 추진현황 전체대비 진행률 ]</span>
        {search.bizYyNo === "" || search.rgnCode === "" ? (
          <></>
        ) : (
          <button class="btn_pls ModalBtn_A" onClick={staticProssAdd}>
            등록
          </button>
        )}
        &nbsp;&nbsp;
        <span class="danrightB">단위 : 개소</span>
      </div>
      <table class="eer-Table eer-Table-striped">
        <colgroup>
          <col width="14.222%" />
          <col width="14.222%" />
          <col width="14.222%" />
          <col width="14.222%" />
          <col width="14.222%" />
          <col width="14.222%" />
          <col width="14.222%" />
        </colgroup>
        <thead>
          <tr>
            <th>에너지원</th>
            <th>총 개소</th>
            <th>협약개소</th>
            <th>미설치</th>
            <th>설치</th>
            <th>취소</th>
            <th>
              전체대비
              <br />
              진행률(%)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>태양광</td>
            <td>{progressList.length > 0 ? progressList[1].total : 0}</td>
            <td>{progressList.length > 0 ? progressList[1].agressment : 0}</td>
            <td>{progressList.length > 0 ? progressList[1].meainUninstCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[1].meainCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[1].cancelCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[1].rate : 0}</td>
          </tr>
          <tr>
            <td>태양열</td>
            <td>{progressList.length > 0 ? progressList[2].total : 0}</td>
            <td>{progressList.length > 0 ? progressList[2].agressment : 0}</td>
            <td>{progressList.length > 0 ? progressList[2].meainUninstCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[2].meainCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[2].cancelCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[2].rate : 0}</td>
          </tr>
          <tr>
            <td>소형풍력</td>
            <td>{progressList.length > 0 ? progressList[3].total : 0}</td>
            <td>{progressList.length > 0 ? progressList[3].agressment : 0}</td>
            <td>{progressList.length > 0 ? progressList[3].meainUninstCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[3].meainCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[3].cancelCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[3].rate : 0}</td>
          </tr>
          <tr>
            <td>지&nbsp;&nbsp;&nbsp;열</td>
            <td>{progressList.length > 0 ? progressList[4].total : 0}</td>
            <td>{progressList.length > 0 ? progressList[4].agressment : 0}</td>
            <td>{progressList.length > 0 ? progressList[4].meainUninstCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[4].meainCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[4].cancelCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[4].rate : 0}</td>
          </tr>
          <tr>
            <td>연료전지</td>
            <td>{progressList.length > 0 ? progressList[5].total : 0}</td>
            <td>{progressList.length > 0 ? progressList[5].agressment : 0}</td>
            <td>{progressList.length > 0 ? progressList[5].meainUninstCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[5].meainCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[5].cancelCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[5].rate : 0}</td>
          </tr>
          <tr>
            <td>모니터링</td>
            <td>{progressList.length > 0 ? progressList[0].total : 0}</td>
            <td>{progressList.length > 0 ? progressList[0].agressment : 0}</td>
            <td>{progressList.length > 0 ? progressList[0].meainUninstCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[0].meainCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[0].cancelCount : 0}</td>
            <td>{progressList.length > 0 ? progressList[0].rate : 0}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default BizProgressStatisticsRate;
