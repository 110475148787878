// React
import React from "react";
import { useState, useEffect, useRef } from "react";

// Kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";

// Css
import "./munic.css";
import { getDongRankPer, getLtByDongCode } from "../../../service/location/locationMunicService";
import { geoComponents } from "./geoDir/geoDatas";
import MunicMapTable from "./municMapTable";
import { initMapItem } from "./municData";
import { kendo } from "@progress/kendo-ui";
import { getMessage } from "./../../../com/messageUtil";
import MunicMapDepthTable from "./municMapDepthTable";
import { isAdmin } from "./../../../service/authService";
import { Button } from "@progress/kendo-react-buttons";
import GeoGradient from "./geoGradient";

const MunicMap = props => {
  const INTERVAL_TIME = 5000;
  const { conn, dataSource, mapItem, setMapItem, search, setSearch, /*dongList,*/ history } = props;
  // const curDongData = dongList.filter(e => e.rgnCode.substring(0, 4) === (search.rgn.rgnCode + "").substring(0, 4));
  const [rgnDdlData, /*setRgnDdlData*/] = useState([
    {
      rgnCode: "44130",
      rgnTerm: "천안시",
    },
    {
      rgnCode: "44200",
      rgnTerm: "아산시",
    },
    {
      rgnCode: "43150",
      rgnTerm: "제천시",
    },
    {
      rgnCode: "44210",
      rgnTerm: "서산시",
    },
    {
      rgnCode: "44825",
      rgnTerm: "태안시",
    },
    {
      rgnCode: "42230",
      rgnTerm: "삼척시",
    },
    {
      rgnCode: "42770",
      rgnTerm: "정선군",
    },
    {
      rgnCode: "42810",
      rgnTerm: "인제군",
    },
    {
      rgnCode: "42110",
      rgnTerm: "춘천시",
    },
    {
      rgnCode: "30230",
      rgnTerm: "대덕구",
    },
  ]);
  // 마우스 오버 시 보여주는 테이블(infoBo)
  const infoBoxRef = useRef(null);
  const depthBoxRef = useRef(null);
  const [backupColor, setBackupColor] = useState("#DCDCDC");
  //고정된 테이블 정보들
  const [fixMapItem, setFixMapItem] = useState(initMapItem);
  const [fixMapState, setFixMapState] = useState({
    interval: "",
  });
  const [loading, setLoading] = useState(false);

  //setTimeOut 타이머
  // const [timer, setTimer] = useState("");

  //interval 스테이트
  const [intvlState, setIntvlState] = useState({ cnt: 0, currCode: "", visible: false });
  const [dongColor, setDongColor] = useState([]);

  /**
   * mapItem 세팅 함수
   * @param {*} setFunc
   * @param {*} targetAreaId
   */
  const chngMapItem = (setFunc, targetAreaId) => {
    if (dataSource.realSolaDongData.length !== 0) {
      const data = dataSource.realSolaDongData.find(e => e.fixbylawBundCode === targetAreaId);
      if (data) {
        setFunc(dataSource => {
          const item = { ...dataSource };
          item.fixbylawBundTerm = data.fixbylawBundTermS;
          item.syncCount = data.syncCount;
          item.asyncCount = data.asyncCount;
          item.syncInstCapa = data.syncInstCapa;
          item.asyncInstCapa = data.asyncInstCapa;
          item.syncDayPower = data.syncDayPower;
          item.asyncDayPower = data.asyncDayPower;
          return item;
        });
      }
    }
  };

  /*  지도에 마우스 오버 이벤트 -> 테이블 보여주기, 오버 맵 색상 변경  */
  const mapOverEvt = e => {
    // setFixMapVis(false);
    const { target } = e;
    let targetAreaId = target.id.substring(5);
    chngMapItem(setMapItem, targetAreaId);
    // 마우스 오버된 읍면동 데이터를 찾아서 업데이트
    setBackupColor(target.style.fill);
    target.style.fill = "url(#overGra)";
    infoBoxRef.current.style.display = "block";
    infoBoxRef.current.style.top = e.pageY + 15 + "px";
    infoBoxRef.current.style.left = e.pageX + 15 + "px";
  };
  /* 지도에서 마우스 벗어났을 때 이벤트 */
  const mapOutEvt = e => {
    // setFixMapVis(true);
    const { target } = e;
    target.style.fill = backupColor;
    infoBoxRef.current.style.display = "none";
  };

  /*  depth 지도에 마우스 오버 이벤트 -> 테이블 보여주기  */
  const depthMapOverEvt = e => {
    // setFixMapVis(false);
    depthBoxRef.current.style.display = "block";
    depthBoxRef.current.style.top = e.pageY + 15 + "px";
    depthBoxRef.current.style.left = e.pageX + 15 + "px";
  };
  /* depth 지도에서 마우스 벗어났을 때 이벤트 */
  const depthMapOutEvt = e => {
    // setFixMapVis(true);
    depthBoxRef.current.style.display = "none";
  };

  /* 지도에서 마우스 클릭했을 때 이벤트 */
  const mapClickEvt = e => {
    const { target } = e;
    getLtByDongCode(conn, target.id.substring(5)).then(response => {
      history.push({
        pathname: "/location/munic",
        state: { lati: response.lati, longi: response.longi, rgnCode: search.rgn.rgnCode },
      });
    });
  };

  /**
   * 확대 지도 클릭 시 이벤트
   * @param {*} e
   */
  const goDepth = e => {
    depthMapOutEvt();
    if (Number.isInteger(e)) setSearch({ ...search, geoDepth: search.geoDepth + e });
    else setSearch({ ...search, geoDepth: search.geoDepth + 1 });
  };

  /**
   * 동적 컴포넌트(맵) 조회 함수
   * @param {*} rgnCode
   * @returns 동적 컴포넌트
   */
  const getGeo = (rgnCode, depth) => {
    let Geo;
    if (depth) Geo = geoComponents[rgnCode + "_" + depth];
    else Geo = geoComponents[rgnCode];
    return (
      <Geo
        mapOverEvt={mapOverEvt}
        mapOutEvt={mapOutEvt}
        mapClickEvt={mapClickEvt}
        goDepth={goDepth}
        depthMapOverEvt={depthMapOverEvt}
        depthMapOutEvt={depthMapOutEvt}></Geo>
    );
  };

  const mapDdlEvt = e => {
    const { value } = e;
    history.replace = { state: value };
    setSearch({ ...search, rgn: value, geoDepth: 0 });
  };

  /**
   * rejection 함수
   * @param {} e
   */
  const getRejection = e => {
    kendo.alert(getMessage(e.errCode));
  };

  /**
   * 지도에 마우스 오버, 아웃 시 우측 하단 fixtable 상태 이벤트
   * @param {*} vis
   */
  // const setFixMapVis = vis => {
  //   if (vis) {
  //     //react에서는 setTimeOut이 상태 관리 때문에 이렇게 해줘야 제대로 작동함
  //     // clearTimeout(timer);
  //     // setTimer(
  //     //   setTimeout(() => {
  //     //     intervalFixMapItem(curDongData, intvlState.cnt);
  //     //   }, INTERVAL_TIME),
  //     // );
  //     intervalFixMapItem(curDongData, intvlState.cnt);
  //   } else {
  //     clearInterval(fixMapState.interval);
  //     setIntvlState({ ...intvlState, visible: vis });
  //
  //     const lastDongMap = document.getElementById("dong_" + intvlState.currCode);
  //     if (!lastDongMap) return;
  //     lastDongMap.style.webkitFilter = "drop-shadow( 0px 0px 0px black)";
  //     lastDongMap.style.fill = dongColor.find(e => e.fixbylawBundCode === intvlState.currCode).color;
  //   }
  // };

  /**
   * 각 동별로 맵의 색상 칠하기(발전량 비율별)
   * @param {*} result getDongRankPer조회 result
   */
  const fillColorDongRank = result => {
    setLoading(false);
    const getColorByPer = e => {
      if (e < 20) return "url(#top20)";
      else if (e >= 20 && e < 40) return "url(#top40)";
      else if (e >= 40 && e < 80) return "url(#top60)";
      else if (e >= 80 && e <= 100) return "url(#top80)";
      else return "#e1e1e1";
    };
    let colorArr = [];
    result.forEach(e => {
      const dongMap = document.getElementById("dong_" + e.fixbylawBundCode);      
      if (!dongMap) return;
      const color = getColorByPer(e.rankPer);
      dongMap.style.fill = color;
      colorArr.push({ fixbylawBundCode: e.fixbylawBundCode, color: color });
    });
    setDongColor(colorArr);
  };
  /**
   * 자동으로 맵 돌아가며 데이터 표출하기
   * @param {} e
   */
  // eslint-disable-next-line
  const intervalFixMapItem = (e, cnt) => {
    const fillFixMap = (lastCode, currCode, i) => {
      const lastDongMap = document.getElementById("dong_" + lastCode);
      const curDongMap = document.getElementById("dong_" + currCode);
      if (!curDongMap) return;
      curDongMap.style.webkitFilter = "drop-shadow( 3px 3px 2px black)";
      curDongMap.style.fill = "url(#overGra)";
      if (!lastDongMap) return;
      lastDongMap.style.webkitFilter = "drop-shadow( 0px 0px 0px black)";
      lastDongMap.style.fill = dongColor.find(e => e.fixbylawBundCode === lastCode).color;
      setIntvlState({ cnt: i, currCode: currCode, visible: true });
    };

    let i = cnt ? cnt : 0;
    const len = e.length - 1;
    clearInterval(fixMapState.interval);
    // setInterval 시간때문에 초기에 한개 세팅
    if (!cnt) {
      chngMapItem(setFixMapItem, e[len].fixbylawBundCode);
      fillFixMap(e[len - 1].fixbylawBundCode, e[len].fixbylawBundCode);
    }
    setFixMapState({
      ...fixMapState,
      interval: setInterval(() => {
        chngMapItem(setFixMapItem, e[i].fixbylawBundCode);
        if (i === 0) fillFixMap(e[len].fixbylawBundCode, e[i].fixbylawBundCode, i);
        else fillFixMap(e[i - 1].fixbylawBundCode, e[i].fixbylawBundCode, i);

        if (i === len) i = 0;
        else i++;
      }, INTERVAL_TIME),
    });
  };

  const chngHeader = () => {
    const rgn = rgnDdlData.find(e => e.rgnCode === search.rgn.rgnCode);
    if(document.getElementById("headerLogo")) {
        if (rgn) {
        document.getElementById("headerLogo").src = "/images/dist/" + rgn.rgnCode + "_logo.png";
        document.getElementById("headerTerm").textContent = rgn.rgnTerm + " 신재생에너지 관리센터";
      }
    }
  };

  /**
   * rgnCode 변경될 때 마다 맵 색상 변경
   */
  useEffect(() => {
    getDongRankPer(conn, search, fillColorDongRank, getRejection, setLoading);
    chngHeader();
    // eslint-disable-next-line
  }, [search.rgn.rgnCode, document.getElementById("headerLogo"), search.geoDepth]);
  /**
   * dataSource.realSolaDongData를 받아왔을 때 실행 - 인터벌 돌리면서 맵 보여주기
   * 버그 있어서 일단 주석처리
   */
  useEffect(() => {
    // if (dataSource.realSolaDongData.length !== 0) intervalFixMapItem(curDongData);
  }, [dataSource.realSolaDongData]);

  return (
    <>
      <GeoGradient />
      <div
        className="con_center"
        style={
          loading
            ? {
                opacity: 0.6,
                pointerEvents: "none",
              }
            : null
        }>
        <div className="energy-list">
          <img src="/images/dist/energy-list.svg" alt="energy-list"/>
          {/* <div id="percentInfoDiv">
            <div>금일발전량 비율 [단위 : %]</div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <img src="/images/percent/firstGra.png" alt="첫번째그라데이션" />
                  </td>
                  <td>
                    <span>0-20</span>
                  </td>
                  <td>
                    <img src="/images/percent/secondGra.png" alt="두번째그라데이션" />
                  </td>
                  <td>
                    <span>21-50</span>
                  </td>
                  <td>
                    <img src="/images/percent/thirdGra.png" alt="세번째그라데이션" />
                  </td>
                  <td>
                    <span>51-80</span>
                  </td>
                  <td>
                    <img src="/images/percent/fourthGra.png" alt="네번째그라데이션" />
                  </td>
                  <td>
                    <span>81-100</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>
        {search.geoDepth > 0 ? (
          <Button className="geoDepthBtn" onClick={() => setSearch({ ...search, geoDepth: search.geoDepth > 0 ? search.geoDepth - 1 : 0 })}>
            지도 축소
          </Button>
        ) : null}
        {isAdmin() ? (
          <DropDownList
            className="municMapDdl"
            value={search.rgn}
            data={rgnDdlData}
            textField="rgnTerm"
            dataItemKey="rgnCode"
            onChange={mapDdlEvt}
          />
        ) : null}
        <div className="map-box ">
          <MunicMapTable mapItem={mapItem} infoBoxRef={infoBoxRef} mapVisible={false} />
          <MunicMapTable mapItem={fixMapItem} mapVisible={intvlState.visible} />
          <MunicMapDepthTable mapItem={mapItem} depthBoxRef={depthBoxRef} mapVisible={false} />
          {getGeo(search.rgn.rgnCode, search.geoDepth)}
        </div>
      </div>
    </>
  );
};

export default MunicMap;
