import React from "react";
import BizMngtNoContents from "../bizMngtNoContents";
import BizManagementRepairHist from "./bizManagementRepairHist";
import BizManagementMngtHist from "./bizManagementMngtHist";

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - A/S보수이력 및 관리이력 ( 컴포넌트 )
 */
const BizManagementAsHist = ({
  convMngtStyles,
  kendo,
  Input,
  conn,
  token,
  asHistList,
  mngtFileList,
  search,
  Grid,
  Column,
  GridNoRecords,
  Button,
  getMeainAsList,
  saveMeainAsInfoData,
  meainInfo,
  getMeainMngtFileList,
  DatePickerKo
}) => {
  return (
    <>
      {search.instlPlnnm ? (
        <>
          <BizManagementRepairHist
            Grid={Grid}
            Column={Column}
            GridNoRecords={GridNoRecords}
            Button={Button}
            Input={Input}
            search={search}
            kendo={kendo}
            asHistList={asHistList}
            convMngtStyles={convMngtStyles}
            saveMeainAsInfoData={saveMeainAsInfoData}
            getMeainAsList={getMeainAsList}
            meainInfo={meainInfo}
            conn={conn}
            getMeainMngtFileList={getMeainMngtFileList}
            token={token}
            mngtFileList={mngtFileList}
            DatePickerKo = {DatePickerKo}
          />
          <BizManagementMngtHist
            mngtFileList={mngtFileList}
            token={token}
            convMngtStyles={convMngtStyles}
            Button={Button}
            conn={conn}
            meainInfo={meainInfo}
            getMeainMngtFileList={getMeainMngtFileList}
            kendo={kendo}
            search={search}
          />
        </>
      ) : (
        <BizMngtNoContents convMngtStyles={convMngtStyles} />
      )}
    </>
  );
};

export default BizManagementAsHist;
