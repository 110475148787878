import React, { useState, useCallback, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import styles from "./searchCom.module.css";
import { Search } from "@material-ui/icons";
import { filterBy } from "@progress/kendo-data-query";

// 사업현황의 검색 컴포넌트
const SearchCom = ({ dong, search, setSearch, getData }) => {

  const filterChange = (e) => {
    const { filter } = e;
    const ddlId = e.target.props.id;
    switch (ddlId) {
      case "dongList":
        setDdlData({ ...ddlData, dong: filterBy(dong, filter) });
        break;
      default:
        break;
    }
  };

  const [ddlData, setDdlData] = useState({
    dong: dong.slice(),
  });

  // 드롭다운 변경 이벤트
  const onItemChange = e => {
    // console.log(" onItemChange E = ", e);
    if (e.target.name === "dong") {
      setSearch(search => {
        const item = { ...search };
        item.dong = e.target.value;
        item.fixbylawBundCode = e.target.value.fixbylawBundCode;
        return item;
      });
    }
  };

  // 텍스트 명 변경
  const onTextChange = e => {
    // console.log(" onTextChange E = ", e);
    setSearch(search => {
      const item = { ...search };
      item.instlTerm = e.target.value;
      return item;
    });
  };

  // 키 이벤트
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      getSearchData();
    }
  };

  // 검색 클릭 이벤트
  const clickBtnEvt = () => {
    getSearchData();
  };

  // 검색 시작
  const getSearchData = () => {
    getData();
  };

  useEffect(() => {
    setDdlData({
      dong: dong.slice(),
    });
  }, []);

  // 랜더링 부분
  return (
    <>
      <>
        <div className="uk-grid searchSpace">
          <div className="uk-width-medium-1-1">
            <div className="troubleShootingSearchBox">
              <span className="sel-Name">읍면동</span>
              <span className="selectbox">
                <DropDownList
                  data={ddlData.dong}
                  id="dongList"
                  value={search.dong}
                  defaultItem={{ fixbylawBundCode: "", fixbylawBundTerm: "전체" }}
                  name="dong"
                  textField="fixbylawBundTerm"
                  dataItemKey="fixbylawBundCode"
                  onChange={onItemChange}
                  popupSettings={{ className: "autoFitDropDown" }}
                  filterable={true}
                  onFilterChange={filterChange}
                  className="troubleShootingSearchDropdown"
                />
              </span>
              <span className="sel-Name">신청자(건물)명</span>
              <input
                type="text"
                className="intext"
                defaultValue={search.instlTerm}
                onChange={onTextChange}
                onKeyPress={handleKeyPress}
                value={search.instlTerm}
              />
              <button className={styles.searchBtn} onClick={clickBtnEvt}>
                <Search className={styles.searchMaterialIcons} style={{ fontSize: 25 }} />
              </button>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SearchCom;
