import { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { orderBy } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";

import styles from "./expctConsm.module.css";
import { numFormat } from "../../../../com/dataUtil";

const ExpctConsmGrid = ({ search, dataSourceGrid, dataSourceChart, dataSourceExcel, setDataSource, loading }) => {
  // 엑셀 다운로드 변수 (레퍼런스 사용)
  let _excelData;

  const sort = [{ field: "", dir: "" }];

  // 엑셀 다운로드 이벤트
  const onClickEvt = e => {
    dataSourceExcel.save();
  };

  // 정렬 이벤트
  // const onSortChange = e => {
  //   const { sort } = e;
  //   setSort(sort);
  // };

  // 차트 옵션
  let chartExpctConsm = {
    chart: {
      type: "column",
      zoomType: "xy",
      height: 294,
      backgroundColor: "#f7f9fa",
    },
    title: { text: null },
    xAxis: {
      categories: ["5일전", "4일전", "3일전", "2일전", "어제", "오늘", "내일"],
      crosshair: true,
      labels: {
        style: {
          fontSize: "18px",
          color: "#444",
        },
      },
    },
    yAxis: [
      {
        labels: {
          format: "{value:,.0f}",
          style: {
            color: "#444",
          },
        },
        title: {
          text: "발전량(kWh)",
          style: {
            color: "#444",
          },
        },
      },
    ],
    credits: { enabled: false },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    colors: ["#b77d08", "#6f8835", "#1f624d", "#542871", "#85275c", "#32768e", "#33427a", "#818385"],
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = `<span><b>${this.x}</b></span><br>`;
        for (let i = 0; i < this.points.length; i++) {
          msg +=
            `
              <span style="color:${this.points[i].point.color}">\u25CF</span>
              <span>${this.points[i].series.name}</span> : 
              <b>${numFormat(this.points[i].y)} kWh</b><br>
            `
        }
        return msg;
      },
    },
    series: dataSourceChart,
  };

  // 엑셀 변수 상태 설정
  const setExcelData = _excelData => {
    setDataSource(dataSource => {
      const item = { ...dataSource };
      item.excelData = _excelData;
      return item;
    });
  };

  // 파일 이름
  const fileName = () => {
    let name = search.userTerm;
    const template = name + "_수용가_예측_통계" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    return template;
  };

  useEffect(() => {
    // 엑셀 다운로드 데이터 설정
    setExcelData(_excelData);
  // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="uk-grid">
        <div className="uk-width-medium-1">
          <div className="expctSubTitleDiv">
            {" "}
            <b>· &nbsp;예측 그래프 </b>
          </div>
          <div className="expctConsmBackground">
            <HighchartsReact Highcharts={Highcharts} options={chartExpctConsm} />
          </div>
        </div>
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium-1">
          <div className="expctSubTitleDiv">
            <b>· &nbsp;예측 통계 </b>
            <Button
              className="expctConsmExcelBtn"
              // style={{ cursor: "pointer", position: "absolute"}}
              onClick={onClickEvt}
              icon="excel">
              엑셀저장
            </Button>
          </div>
          <ExcelExport
            className="expctConsmGrid"
            data={orderBy(dataSourceGrid, sort)}
            ref={exporter => (_excelData = exporter)}
            fileName={fileName()}>
            <ExcelExportColumn field="cid" title="CID" width={150} />
            <ExcelExportColumn field="ensoTypeTerm" title="에너지원" width={100} />
            <ExcelExportColumn field="makrTerm" title="제조사" width={185} />
            <ExcelExportColumn field="meainTypeTerm" title="모델" width={200} />
            <ExcelExportColumn field="instCapa" title="설비용량" width={185} />
            <ExcelExportColumn field="dayAgo5" title="5일전" width={100} />
            <ExcelExportColumn field="dayAgo4" title="4일전" width={100} />
            <ExcelExportColumn field="dayAgo3" title="3일전" width={100} />
            <ExcelExportColumn field="dayAgo2" title="2일전" width={100} />
            <ExcelExportColumn field="dayAgo1" title="1일전" width={100} />
            <ExcelExportColumn field="dayAgo0" title="오늘" width={100} />
            <ExcelExportColumn field="tomorrow" title="내일" width={100} />
          </ExcelExport>
          <Grid
            className="expctConsmGrid"
            data={dataSourceGrid}
            style={{ height: "290px" }}
            // onSortChange={onSortChange}
            // sort = {sort}
            // sortable
          >
            <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
            <Column className={styles.expctConsmColumn} field="cid" title="CID" />
            <Column field="ensoTypeTerm" title="에너지원" />
            <Column field="makrTerm" title="제조사" />
            <Column field="meainTypeTerm" title="모델" />
            <Column field="instCapa" title="설비용량" />
            <Column field="dayAgo5" title="5일전" />
            <Column field="dayAgo4" title="4일전" />
            <Column field="dayAgo3" title="3일전" />
            <Column field="dayAgo2" title="2일전" />
            <Column field="dayAgo1" title="어제" />
            <Column field="dayAgo0" title="오늘" />
            <Column field="tomorrow" title="내일" />
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ExpctConsmGrid;
