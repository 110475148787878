import "./index.css";
import { ReactComponent as Monitoring } from "../../../../../assets/svg/taean/monitor.svg";
import { ReactComponent as Symbol } from "../../../../../assets/svg/taean/symbol.svg";
import { ReactComponent as CityLogo } from "../../../../../assets/svg/taean/taeanCityLogo.svg";

const ReDirectTaean = () => {
  const handleRedirectMonitoring = () => {
    window.location.href = "https://kremc.kr/";
  };

  const handleRedirectTaean = () => {
    window.location.href = "https://www.taean.go.kr/";
  };
  return (
    <div className="taean-flex-container">
      <span className="taean-dashboard-title">바로가기</span>
      <div className="reDirect-container-taean">
        <button className="reDirect-wrapper-taean reDirect-monitoring-taean" onClick={handleRedirectMonitoring} type="button">
          통합
          <br />
          모니터링
          <br />
          <Monitoring className="monitoring-icon-taean" />
          바로가기
        </button>
        <button className="reDirect-wrapper-taean reDirect-taean" onClick={handleRedirectTaean} type="button">
          <Symbol className="taean-symbol" />
          <CityLogo className="taean-symbol" />
          <br />
          바로가기
        </button>
      </div>
    </div>
  );
};

export default ReDirectTaean;
