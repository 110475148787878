// React
import React, { useState, useEffect } from "react";

// css
import "./bizContents.css";
import styles from "./bizContents.module.css";

// kendo
import kendo from "@progress/kendo-ui";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

// Service
import { CODE_BIZ_POWERSTATION } from "../../com/codeUtil";
import { isManager, getUserMngRgnList } from "../../service/authService";
import { getCalCityList, getCalRgnList } from "../../com/dataUtil";
import BizProjectInfo from "./com/bizInfo/bizProjectInfo";
// import BizProgress from "./com/progress/bizProgress";
// import BizExpense from "./com/bizExpense/bizExpense";
import BizManagement from "./com/management/bizManagement";
// import BizCancelMngt from "./com/cancelMngt/bizCancelMngt";

const PowerStationInfo = ({ conn, codeList, cityList, rgnList, isMobile }) => {
  // 탭 번호
  const [selected, setSelected] = useState();

  // 검색 정보
  const [search, setSearch] = useState({
    energyType: { codeNo: "", codeVal: "전체" },
    energyTypeCode: "",
    city: { cityProvTerm: "시/도", cityProvCode: "" },
    cityProvCode: isManager() ? getUserMngRgnList()[0].substring(0, 2) : "",
    rgn: { rgnTermSimple: "전체", rgnCode: "" },
    rgnCode: isManager() ? getUserMngRgnList()[0] : "",
    rgnTerm: "",
    instlPlnnm: "",
    instlTerm: "",
    bizYyNo: "",
    bizYy: "사업연도",
    month: kendo.toString(new Date(), "MM"),
    date: { startGathDtm: kendo.toString(new Date(), "yyyy-01-01"), endGathDtm: kendo.toString(new Date(), "yyyy-12-31") },
    monthDate: { startGathDtm: kendo.toString(new Date(), "yyyy-MM-01"), endGathDtm: kendo.toString(new Date(), "yyyy-MM-dd") },
    bizInfoPaging: {
      take: 10,
      skip: 0,
      filter: {
        classFaci: "",
        instlAddr: "",
        cityNewdeal: "",
      },
    },
    progress: {
      paging: { take: 10, skip: 0 },
      tabStatus: 0,
      energyTypeCode: "",
    },
    bizExpendPaging: {
      take: 10,
      skip: 0,
    },
    payOrderPaging: {
      take: 10,
      skip: 0,
    },
    mngt: {
      paging: { take: 10, skip: 0 },
      energyTypeCode: "",
      tabStatus: 0,
      heatSysMode: "",
    },
    cancelPaging: {
      take: 10,
      skip: 0,
    },
    sort: [],
    sortStatus: [],
    navi: "전체",
    bizSctCode: CODE_BIZ_POWERSTATION,
  });

  let citys = getCalCityList(cityList);
  let rgns = getCalRgnList(rgnList.filter(e => e.cityProvCode === search.cityProvCode));

  useEffect(() => {
    setSelected(0);

    if (isManager()) {
      setSearch(search => {
        const item = { ...search };
        item.city = citys[0];
        item.rgn = rgns[0];
        return item;
      });
    }
    /* eslint-disable-next-line */
  }, []);

  const handleSelect = e => {
    setSelected(e.selected);
  };

  return (
    // <>
    //   {isMobile ? (
    //     <>
    //       <div class="page_title">발전소</div>
    //       <BizProgress
    //         conn={conn}
    //         codeList={codeList}
    //         cityList={cityList}
    //         rgnList={rgnList}
    //         styles={styles}
    //         isMobile={isMobile}
    //         search={search}
    //         setSearch={setSearch}
    //         Mobile={isMobile}
    //       />
    //     </>
    //   ) : (
    <>
      <div class="page_title">발전소</div>
      <div>
        <TabStrip className="JinTab" id="JinTab" selected={selected} onSelect={handleSelect}>
          <TabStripTab title="사업대상지 정보" key={"tabPlan"}>
            <BizProjectInfo
              conn={conn}
              codeList={codeList}
              cityList={cityList}
              rgnList={rgnList}
              styles={styles}
              isMobile={isMobile}
              search={search}
              setSearch={setSearch}
              getCalCityList={getCalCityList}
              getCalRgnList={getCalRgnList}
            />
          </TabStripTab>
          {/*<TabStripTab title="진척률" key={'tabProgress'}>
                <BizProgress
                  conn={conn}
                  codeList={codeList}
                  cityList={cityList}
                  rgnList={rgnList}
                  styles={styles}
                  isMobile = {isMobile}
                  search={search}
                  setSearch={setSearch}
                />
              </TabStripTab>
              <TabStripTab title="사업비" key={'tabBisExpense'}>
                <BizExpense
                  conn={conn}
                  codeList={codeList}
                  cityList={cityList}
                  rgnList={rgnList}
                  styles={styles}
                  isMobile = {isMobile}
                  search={search}
                  setSearch={setSearch}
                />
                <img className='tabBisExpenseImg' src='/images/tabBisExpenseImg.png' /> 
              </TabStripTab>*/}
          {/* <img className='tabBisExpenseImg' src='/images/tabBisExpenseImg.png' /> */}
          <TabStripTab title="관리현황" key={"tabMngtStat"}>
            <BizManagement
              conn={conn}
              codeList={codeList}
              cityList={cityList}
              rgnList={rgnList}
              styles={styles}
              search={search}
              setSearch={setSearch}
            />
          </TabStripTab>
          {/*  
              <TabStripTab title="취소관리" key={'tabCancelMngt'}>
                <BizCancelMngt
                  conn={conn}
                  codeList={codeList}
                  cityList={cityList}
                  rgnList={rgnList}
                  styles={styles}
                  isMobile = {isMobile}
                  search={search}
                  setSearch={setSearch}
                />
                <img className='tabCancelMngtImg' src='/images/tabCancelMngtImg.png' /> 
              </TabStripTab>
              */}
        </TabStrip>
      </div>
    </>
    //   )}
    // </>
  );
};

export default PowerStationInfo;
