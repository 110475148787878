import TaeanDashboard from "./dashboard";
import TaeanHeader from "./header";
import "./index.css";

const TaeanMainPage = ({isMapView}) => {
  return (
    <div className="taean-display">
      {!isMapView ? <TaeanHeader /> : <div style={{marginTop:'50px'}}></div>}
      <TaeanDashboard />
    </div>
  );
};

export default TaeanMainPage;
