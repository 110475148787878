import { TreeView, processTreeViewItems, handleTreeViewCheckChange } from '@progress/kendo-react-treeview';

import kendo from '@progress/kendo-ui';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Search } from "@material-ui/icons";

// 로딩 스피너 
import { BeatLoader } from 'react-spinners'

const MenuRoleCommon = ({ conn, setCheckData, nowUserType, setNowUserType, userTypeList, styles, saveChanges, loading, setLoading, dataSource, setDataSource,
    getTreeData, setCommonCheckData, selectedUserType, setSelectedUserType }) => {
    const settings = { checkChildren: true, checkParents: true };

    // 드롭다운 이벤트
    const onChangeUserType = e => {
        setNowUserType(nowUserType => {
            const item = { ...nowUserType };
            item.codeNo = e.target.value.codeNo;
            item.codeVal = e.target.value.codeVal;
            return item;
        });
    };

    //조회버튼 클릭시
    const viewBtnOnclick = () => {
        if (nowUserType.codeNo === "") {
            kendo.alert("사용자유형을 먼저 선택해주세요");
        }
        else {
            setLoading(true);
            getTreeData();
            // setDataSource({});
            setCommonCheckData({ menuData: [], menuList: [] });
            setSelectedUserType({ codeNo: nowUserType.codeNo, codeVal: nowUserType.codeVal });
        }
    }
    const onCheckChange = (event) => {
        setDataSource({
            ...dataSource,
            check: handleTreeViewCheckChange(event, dataSource.check, dataSource.data, settings),
        });

        event.item.checked = !event.item.checked;

        const checkList = handleTreeViewCheckChange(event, dataSource.check, dataSource.data, settings)
        setCheckData(nowUserType.codeNo, checkList.ids, dataSource);
    }

    const onExpandChange = (event) => {
        event.item.expanded = !event.item.expanded;
    }

    return (
        <div className="uk-width-medium-1-4">
            <div className="md-card" style={{ border: "0px solid black" }}>
                <div className="md-card-content">
                    <div className="md-card-toolbar">
                        <h3 className="md-card-toolbar-heading-text" style={{ border: "1px solid #dddddd" }}>
                            <DropDownList
                                className={styles.UserTypeDropDown}
                                data={userTypeList}
                                textField="codeVal"
                                dataItemKey="codeNo"
                                name="userType"
                                // defaultItem={{ codeNo: "", codeVal: "사용자유형" }}
                                onChange={onChangeUserType}
                                value={nowUserType}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                className={styles.viewBtn}
                                onClick={viewBtnOnclick}
                            >
                                <Search />
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                title="update"
                                className={styles.saveBtn}
                                onClick={saveChanges}
                                icon="save"
                            >
                                저장
                            </Button>
                        </h3>
                        <h3 className="md-card-toolbar-heading-text" style={{ border: "1px solid #dddddd", marginTop: "10px" }}>
                            {selectedUserType.codeVal}
                        </h3>
                        {selectedUserType.codeNo === "" ? <></> :
                            <TreeView
                                className={["menuTree", loading ? styles.treeViewLoading : ""].join(' ')}
                                data={processTreeViewItems(dataSource.data, { check: dataSource.check })}
                                expandIcons={true}
                                checkboxes={true}
                                onCheckChange={onCheckChange}
                                onExpandChange={onExpandChange}
                                selectField={false}
                            />}
                        {loading && <p className={styles.loading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuRoleCommon;