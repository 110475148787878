
export const getMessage = (code) => {
  const list = [
    { code: 'F001', message: '시스템 오류로 관리자에 문의하십시오.' },
    { code: 'F002', message: '유효하지 않은 값이 존재합니다.' },
    { code: 'F010', message: '시스템 오류로 파일을 업로드하지 못하였습니다. ' },
    { code: 'F011', message: '필수 파라미터 값이 없습니다.' },
    { code: 'T000', message: '인증 토큰이 없습니다.' },
    { code: 'T001', message: '유효하지 않은 접속 토큰입니다.' },
    { code: 'T002', message: '유효하지 않은 이용자 토큰입니다.' },
    { code: 'L001', message: '존재하지 않는 아이디 입니다.' },
    { code: 'L002', message: '아이디 및 패스워드가 잘못되었습니다.' },
    { code: 'L003', message: '비밀번호 형식이 알맞지 않습니다.' },
    { code: 'L004', message: '핸드폰번호 형식이 알맞지 않습니다.' },
    { code: 'L005', message: '이메일 형식이 알맞지 않습니다.' },
    { code: 'IP001', message: '존재하지 않는 BPM설치계획서 신청번호 입니다.' },
    { code: 'IP002', message: '연락처 형식이 알맞지 않습니다.' },
    { code: 'IP003', message: '전화번호 형식이 알맞지 않습니다.' },
    { code: 'IP004', message: 'BPM설치계획서 신청번호가 중복되었습니다.' },
    { code: 'IP005', message: '에너지원 코드 가 입력 되지 않았습니다.' },
    { code: 'IP006', message: '사업파일코드 가 존재 하지 않습니다.' },
    { code: 'IP007', message: '사업파일세부코드 가 존재 하지 않습니다. ' },
    { code: 'IP008', message: 'BPM설치계획서 번호가 지수 형태 입니다.' },
  ];
  console.log("code", code);
  const msg = list.find(item => item.code === code);
  return msg?.message || '존재하지 않는 오류 코드입니다.';
}