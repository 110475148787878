// 인버터 효율 15001

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat, roundNum } from '../../../../com/dataUtil';

const InverterEficChart = ( {efic , ratio, clickDrillDown} ) => {
  // 차트 옵션
  const chartConfig = {
    chart: { zoomType: 'xy', marginTop: 50 },
    title: { text: null },
    noData: {
      style:{ color: '#FFFFFF', fontSize: '14px' }
    },
    tooltip: {
      shared: true,
      formatter: function () {
        return(
          `
            <b>${this.points[0].point.name}</b><br/>
            <span style="color:${this.points[0].color}">\u25CF</span>
            ${this.points[0].series.name}: ${roundNum(this.points[0].y,2)} %<br/>
            <span style="color:${this.points[1].color}">\u25CF</span>
            ${this.points[1].series.name}: ${Math.round(this.points[1].y)} % (${numFormat(roundNum(this.points[1].point.instCapa,2))} kW)
          `
        );
      }
    },
    xAxis: { type: 'category', crosshair: true },
    yAxis: [{ // Primary yAxis
        labels: { format: '{value:,.2f}', style: { color: "#69bcc8" } },
        title: {
            text: '평균효율(%)',
            style: {
                color: "#69bcc8"
            },
                rotation: 0,
                y: -10,
                offset: 0,
                align: 'high'
        },
        max:100 }
    , { // Secondary yAxis
      min: 0,
      title: {
        text: '설비용량비율(%)',
        style: { color: "#c1ce01" },
          rotation: 0,
          y: -10,
          offset: 0,
          align: 'high'
      },
      labels: {
        format: '{value:,.0f}', style: { color: "#c1ce01" }
      },
      max:100, opposite: true
    }],
    legend: {
        enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: { },
      column : {
        cursor: 'pointer',
        point: {
          events: {
            click: function(e){
              clickDrillDown(e.point.makrSeriNo , e.point.name);
            }
          }
        }
      }
    } ,
    colors: [ "#b77d08", "#6f8835", "#1f624d", "#542871", "#85275c", "#32768e", "#33427a", "#818385" ],
    series: [{
      name: '평균효율',
        type: 'column',
        data: efic,
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} %</b><br/>'
        }
    },
    {
      name: '설비용량비율',
        type: 'column',
        yAxis: 1,
        data: ratio,
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.1f} %</b><br/>'
        }
    }],
    drilldown: {
        series: []
    }
  };
  return(
      <div>
          <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
      </div>
  )
}

export default InverterEficChart;