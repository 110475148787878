import { downloadExcel } from "../../../com/downloadExcel";
import kendo from '@progress/kendo-ui';

// 설비 조회
export const getMeainList = async(conn, callback, reject , paging , ensoTypeCode) =>{

  // sort 값 convert
  let sort = [];
  if( paging.sort.length !== 0 ){
      let field = paging.sort[0].field;
      let dir = paging.sort[0].dir;
      if( paging.sort[0].field === "userTerm" ){ field = "installer.userId"; }
      else if( paging.sort[0].field === "rgnTerm" ){ field = "installer.rgnCode.rgnCode"; }
      else if( paging.sort[0].field === "rgnTerm" ){ field = "installer.rgnCode.rgnCode"; }
      else if ( paging.sort[0].field === "meainStatTerm"){ field = "meainStatCodeNo"; }
      sort = field+","+dir;
  }

  // url 설정
  let url = "";
  if(ensoTypeCode === 15001 ){ url = "/meain/sola/page"; }
  else if(ensoTypeCode === 15002 ){ url = "/meain/heat/page"; }
  else if(ensoTypeCode === 15003 ){ url = "/meain/heath/page"; }
  else if(ensoTypeCode === 15004 ){ url = "/meain/velo/page"; }
  else if(ensoTypeCode === 15006 ){ url = "/meain/fuelcell/page"; }
  else if(ensoTypeCode === 15007 ){ url = "/meain/ess/page"; }

  await conn.get(url, {
      params: {
        cid : paging.filter.cid
        , userTerm : paging.filter.userTerm
        , cityProvCode : paging.filter.cityProvCode
        , rgnCode : paging.filter.rgnCode
        , meainStatCodeNo : paging.filter.meainStatusCode
        , page : (paging.skip / paging.take) + 1
        , size : paging.take
        , sort : sort
        , sysMode : paging.sysMode ? paging.sysMode : null
    }
  }).then(response => {
    callback(response);
  }).catch(error => {
    reject(error)
  });
}

// 설비 추가
export const setMeain = async(conn, callback , reject , param , ensoTypeCode , sysMode) =>{
    // 에너지원 코드를 보고 url 정한다.
    let url = "";
    if( ensoTypeCode === 15001 ){ url = '/meain/sola'; }
    else if( ensoTypeCode === 15002 ){ url = '/meain/heat'; }
    else if( ensoTypeCode === 15003 ){ url = '/meain/heath'; }
    else if( ensoTypeCode === 15004 ){ url = '/meain/velo'; }
    else if( ensoTypeCode === 15006 ){ url = '/meain/fuelcell'; }
    else if( ensoTypeCode === 15007 ){ url = '/meain/ess'; }

    // 헤더 옵션 변경
    const config = {
      headers: {'Content-Type': 'application/json'}
    };

    await conn.post(url, JSON.stringify(param) , config)
    .then(response => {
      callback(response);
    }).catch(error => {
      reject(error);
    });
    
}

// 설비 삭제
export const deleteMeain = async(conn, callback , reject , param , ensoTypeCode) =>{
  let url = "";
  if( ensoTypeCode === 15001 ){ url = '/meain/sola'; }
  else if( ensoTypeCode === 15002 ){ url = '/meain/heat'; }
  else if( ensoTypeCode === 15003 ){ url = '/meain/heath'; }
  else if( ensoTypeCode === 15004 ){ url = '/meain/velo'; }
  else if( ensoTypeCode === 15006 ){ url = '/meain/fuelcell'; }
  else if( ensoTypeCode === 15007 ){ url = '/meain/ess'; }

  await conn.delete(url, {
    headers: {'Content-Type': 'application/json'}
    , data: JSON.stringify(param)
  } ).then(response => {
    callback(response);
  }).catch(error => {
    reject(error);
  });
}

// 엑셀 다운로드
export const getExcelMeainList = async(conn, callback, param , ensoTypeCode , sysMode , setSaveLoading) =>{

  let excelTitle = "";
  let url = "";
  if( ensoTypeCode === 15001 ){
    excelTitle = "태양광_설비_" + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
    url = "/api/v2.2/meain/sola";
  }else if( ensoTypeCode === 15002 ){
    if( sysMode === 29001 ){
      excelTitle = "태양열_설비_" + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
      url = "/api/v2.2/meain/heat";
    }else{
      excelTitle = "태양열(자연)_설비_" + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
      url = "/api/v2.2/meain/heat";
    }
  }else if( ensoTypeCode === 15003 ){
    excelTitle = "지열_설비_" + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
    url = "/api/v2.2/meain/heath";
  }else if( ensoTypeCode === 15004 ){
    excelTitle = "풍력_설비_" + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
    url = "/api/v2.2/meain/velo";
  }else if( ensoTypeCode === 15006 ){
    excelTitle = "연료전지_설비_" + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
    url = "/api/v2.2/meain/fuelcell";
  }else if( ensoTypeCode === 15007 ){
    excelTitle = "ESS_설비_" + kendo.toString(new Date() , "yyyyMMdd") + ".xlsx";
    url = "/api/v2.2/meain/ess";
  }
  
  // 정렬 필드 정의
  let field = "";
  if( param.sort.length !== 0 ){
    if( param.sort[0].field === "userTerm" ){ field = "userId"; }
    else if( param.sort[0].field === "cityProvTerm" ){ field = "cityProvCode"; }
    else if( param.sort[0].field === "rgnTerm" ){ field = "rgnCode"; }
    else if ( param.sort[0].field === "meainStatTerm"){ field = "meainStatCodeNo"; }
    else if ( param.sort[0].field === "cid" ) field = "cid";
  }

  // 조건 검색 객체
  const search = {
    cid : param.filter.cid , 
    meainStatCodeNo : param.filter.meainStatusCode , 
    cityProvCode : param.filter.cityProvCode ,
    rgnCode : param.filter.rgnCode , 
    userTerm : param.filter.userTerm , 
    sort : field , 
    direction : param.sort.length !== 0 ? param.sort[0].dir : "DESC" , 
    sysMode : sysMode
  };
  downloadExcel( search , excelTitle , url , setSaveLoading );
}

// 설비모델&제조사 서비스
export const getMeainModlMakr = async(conn, callback, reject , ensoTypeCode ) =>{
  await conn.get("/meain/model/modelMakr/list", {
    params: {
      ensoTypeCode : ensoTypeCode
    }
  }
  ).then(response => {
    callback(response);
  }).catch(error => {
    reject(error)
  });
}


// 설비 교체 
export const replaceMeain = async(conn , callback , reject , param , ensoTypeCode) => {

  // 에너지원 코드를 보고 url 정한다.
  let url = "";
  if( ensoTypeCode === 15001 ){ url = '/meain/sola/replace'; }
  else if( ensoTypeCode === 15002 ){ url = '/meain/heat/replace'; }
  else if( ensoTypeCode === 15003 ){ url = '/meain/heath/replace'; }
  else if( ensoTypeCode === 15004 ){ url = '/meain/velo/replace'; }
  else if( ensoTypeCode === 15006 ){ url = '/meain/fuelcell/replace'; }
  else if( ensoTypeCode === 15007 ){ url = '/meain/ess/replace'; }

  await conn.post(url, param)
    .then(response => {
      callback(response);
    }).catch(error => {
      reject(error);
    });

}

// BPM 설비 리스트 불러오기
export const getBpmManagementInfo = async(conn , callback , reject , param , ensoTypeCode) => {

  await conn.get('', {
      params: {
        // cid : paging.filter.cid
        // , userTerm : paging.filter.userTerm
        // , cityProvCode : paging.filter.cityProvCode
        // , rgnCode : paging.filter.rgnCode
        // , meainStatCodeNo : paging.filter.meainStatusCode
        // , page : (paging.skip / paging.take) + 1
        // , size : paging.take
        // , sort : sort
        // , sysMode : paging.sysMode ? paging.sysMode : null
    }
  }).then(response => {
    callback(response);
  }).catch(error => {
    reject(error)
  });
}