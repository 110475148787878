// React
import React from "react";
import { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";

// Service
import {
  getTodayDongEnergy,
  getYesterdayDongEnergy,
  getTodayUserEnergy,
  getYesterdayUserEnergy,
  getSmpRecCerDataResource,
  getSolaDongDataResource,
  getMeainCntDataResource,
  getEficPowerDataResource,
} from "./../../../service/monitor/municService";

// Css
import "./munic.css";

// Componenet
// import MunicProfit from "./municProfit";
// import MunicMap from "./municMap";
// import MunicStat from "./municStat";
// import MunicRank from "./municRank";
import { initMapItem, initMunicDataSource, initMunicDongData, initMunicUserData } from "./municData";
import { getUser, getUserId } from "../../../service/authService";
import { isAdmin } from "./../../../service/authService";
import { useHistory } from "react-router-dom";
// import { geoBackGroudImg } from "./geoDir/geoBackGroudImg";
// import JeongSeonMunicProfit from "../../localArea/jeongseon/munic/jeongseonMunicProfit";
// import { getReturnComponent } from "./commonLogic/MunicCommonComponent";
import { geoBackGroudImg } from "./geoDir/geoBackGroudImg";
import MunicProfit from "./municProfit";
import MunicMap from "./municMap";
import MunicStat from "./municStat";
import MunicRank from "./municRank";
import LocalAreaMonitor from "../municipality_v2/localAreaMonitor";
import DaedeokMonitor from "../municipality_daedeok/daedeokMonitor";
import Asan from "components/localArea/asan";
import GongjuMainPage from "components/localArea/gongju";
import Nonsan from "components/localArea/nonsan";
import TaeanMainPage from "components/localArea/taean";

const Munic = props => {
  const history = useHistory();
  const { conn, dongList } = props;
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const testDay = new Date();
  testDay.setDate(today.getDate() - 79);

  // 로딩스피너
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    rgn: history.replace.state
      ? history.replace.state
      : { rgnCode: !isAdmin() ? (getUser() ? getUser().rgnCode : null) : "44130", rgnTerm: "천안시" },
    geoDepth: 0,
  });
  let callbackData = {};
  const [dataSource, setDataSource] = useState(initMunicDataSource);
  const [dongData, setDongData] = useState(initMunicDongData);
  const [userData, setUserData] = useState(initMunicUserData);
  const [mapItem, setMapItem] = useState(initMapItem);

  // 시간별 계산
  const calHourData = resultObj => {
    // 00시 ~ 23시
    const today = new Date();
    let hour = today.getHours();
    let hourSumPower = 0;
    let hourAvgEfic = 0;

    for (let index = 0; index <= hour; index++) {
      let indexStr = index;
      if (index < 10) {
        indexStr = "0" + index;
      }
      let powerIndex = "p" + indexStr;
      let eifcIndex = "e" + indexStr;
      hourSumPower += resultObj[powerIndex];
      hourAvgEfic += resultObj[eifcIndex];
    }
    hourAvgEfic = hourAvgEfic / (hour + 1);

    return {
      fixbylawBundTermS: resultObj.fixbylawBundTermS,
      fixbylawBundCode: resultObj.fixbylawBundCode,
      userId: resultObj.userId,
      userTerm: resultObj.userTerm,
      hourSumPower: hourSumPower,
      hourAvgEfic: hourAvgEfic,
    };
  };

  // 오른쪽 하단 지역별 태양광 모니터링 발전순위 정보 데이터
  const getTodayDongEnergyResult = result => {
    const todayPowerData = [];
    const todayEficData = [];
    const yesterdayData = [];

    result.forEach(element => {
      todayPowerData.push(calHourData(element));
      todayEficData.push(calHourData(element));
    });

    const todayPowerRank = todayPowerData.sort(function (comp1, comp2) {
      const comp1UC = comp1.hourSumPower;
      const comp2UC = comp2.hourSumPower;
      if (comp1UC < comp2UC) {
        return 1;
      } else if (comp1UC > comp2UC) {
        return -1;
      }
      return 0;
    });

    const todayEficRank = todayEficData.sort(function (comp1, comp2) {
      const comp1UC = comp1.hourAvgEfic;
      const comp2UC = comp2.hourAvgEfic;
      if (comp1UC < comp2UC) {
        return 1;
      } else if (comp1UC > comp2UC) {
        return -1;
      }
      return 0;
    });

    let item = {};
    const len = todayPowerRank.length <= 3 ? todayPowerRank.length : 3;
    for (let i = 0; i < 3; i++) {
      item["dongPower" + (i + 1)] = i < len ? todayPowerRank[i].hourSumPower : 0;
      item["dongPowerTerm" + (i + 1)] = i < len ? todayPowerRank[i].fixbylawBundTermS : "-";
      item["dongEfic" + (i + 1)] = i < len ? todayEficRank[i].hourAvgEfic : 0;
      item["dongEficTerm" + (i + 1)] = i < len ? todayEficRank[i].fixbylawBundTermS : "-";
    }
    const param = {
      date: yesterday,
      rgnCode: search.rgn.rgnCode,
    };

    // 오른쪽 하단 지역별 태양광 모니터링 발전순위 정보 데이터 (전일)
    getYesterdayDongEnergy(conn, param)
      .then(result => {
        result.forEach(element => {
          yesterdayData.push(calHourData(element));
        });
        for (let i = 0; i < 3; i++) {
          let preData;
          preData = i < len ? yesterdayData.find(e => e.fixbylawBundCode === todayPowerRank[i].fixbylawBundCode) : null;
          item["dongPrePowerRank" + (i + 1)] = preData ? todayPowerRank[i].hourSumPower - preData.hourSumPower : 0;
          item["dongPreEficRank" + (i + 1)] = preData ? todayEficRank[i].hourAvgEfic - preData.hourAvgEfic : 0;
        }
        setDongData({ ...dongData, ...item });
      })
      .catch(e => console.log("Error", e));
  };

  // 오른쪽 하단 수용가별 태양광 모니터링 발전순위 정보 데이터
  const getTodayUserEnergyResult = result => {
    const todayData = [];
    const yesterdayData = [];
    result.forEach(element => {
      todayData.push(calHourData(element));
    });

    const todayRank = todayData.sort(function (comp1, comp2) {
      const comp1UC = comp1.hourSumPower;
      const comp2UC = comp2.hourSumPower;
      if (comp1UC < comp2UC) {
        return 1;
      } else if (comp1UC > comp2UC) {
        return -1;
      }
      return 0;
    });

    let item = {};
    const len = todayRank.length <= 3 ? todayRank.length : 3;
    for (let i = 0; i < 3; i++) {
      item["userPower" + (i + 1)] = i < len ? todayRank[i].hourSumPower : 0;
      item["userTerm" + (i + 1)] = i < len ? todayRank[i].userTerm : "-";
    }

    const param = {
      date: yesterday,
      rgnCode: search.rgn.rgnCode,
    };
    // 오른쪽 하단 수용가별 태양광 모니터링 발전순위 정보 데이터 (전일)
    getYesterdayUserEnergy(conn, param)
      .then(yesterdayResult => {
        yesterdayResult.forEach(element => {
          yesterdayData.push(calHourData(element));
        });
        for (let i = 0; i < 3; i++) {
          let preData;
          preData = i < len ? yesterdayData.find(e => e.userId === todayRank[i].userId) : null;
          item["userPrePowerRank" + (i + 1)] = preData ? todayRank[i].hourSumPower - preData.hourSumPower : 0;
        }
        setUserData({ ...userData, ...item });
      })
      .catch(e => console.log("Error", e));
  };

  const callBackFunc = result => {
    callbackData = { ...callbackData, ...result };
  };

  const getRejection = error => {
    console.log("error = ", error);
  };

  const getData = () => {
    setLoading(true);
    const param = {
      date: today,
      // date: testDay,
      rgnCode: search.rgn.rgnCode,
    };
    Promise.all([
      // 오른쪽 하단 지역별 태양광 모니터링 발전순위 정보 데이터
      getTodayDongEnergy(conn, param, getTodayDongEnergyResult, getRejection),
      // 오른쪽 하단 수용가별 태양광 모니터링 발전순위 정보 데이터
      getTodayUserEnergy(conn, param, getTodayUserEnergyResult, getRejection),
      //1.smpreccer
      getSmpRecCerDataResource(conn, param, callBackFunc, getRejection),
      //2. 오늘,어제 power,효율 eficdata
      getEficPowerDataResource(conn, param, callBackFunc, getRejection),
      //3. 설비개수,발전량,용량 soladongdata
      getSolaDongDataResource(conn, param, callBackFunc, getRejection),
      //4. 에너지원별 통계 meaincnt
      getMeainCntDataResource(conn, param, callBackFunc, getRejection),
    ]).then(results => {
      setLoading(false);
      setDataSource({ ...dataSource, ...callbackData });
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [search.rgn]); 

  // 반복 사이클 진행 ( 10분에 1번 조회 ) 사용안함
  // const setIntervalCycle = () => {
  //   setInterval(() => {
  //     // console.log("인터벌 함수 실행 실행 시간 = ", kendo.toString( new Date() , 'yyyy-MM-dd HH:mm:ss' ));
  //     getData();
  //   }, 600000);
  // };

  const getBackgorundImg = rgnCode => {
    const imgSrc = geoBackGroudImg[rgnCode]
    if( imgSrc ){ return `url(` + imgSrc + `)`; }
    else{ return `url(` + geoBackGroudImg[44130] + `)`; }
    // if (rgnCode === "44200" || rgnCode === "44130") return `url("/images/dist/mapBG_` + rgnCode + `.png")`;
    // else return `url("/images/dist/mapBG_` + 44130 + `.png")`;
  };


  // 컴포넌트 Result
  // const getReturnComponent = () => {
  //   return(
  //     <div
  //       style={
  //         loading
  //           ? {
  //               opacity: 0.6,
  //               pointerEvents: "none",
  //             }
  //           : null
  //       }>
  //       <div className="comBg">
  //         <section
  //           className="content"
  //           style={{
  //             backgroundImage: getBackgorundImg(search.rgn.rgnCode),
  //           }}>
  //           <div className="con_left">
  //             <div className="sub_title">
  //               <h1>신재생에너지 현황</h1>
  //             </div>
  //             {
  //               search.rgn.rgnCode === '42770' ? 
  //               <JeongSeonMunicProfit dataSource={dataSource} />
  //               :
  //               <MunicProfit dataSource={dataSource} />
  //             }
  //           </div>
  //           <MunicMap
  //             conn={conn}
  //             search={search}
  //             setSearch={setSearch}
  //             dataSource={dataSource}
  //             mapItem={mapItem}
  //             setMapItem={setMapItem}
  //             dongList={dongList}
  //             history={history}
  //           />
  //           <div className="con_right">
  //             <MunicStat dataSource={dataSource} />
  //             <MunicRank dongData={dongData} userData={userData} />
  //           </div>
  //         </section>
  //       </div>
  //       {loading && (
  //         <p
  //           style={{
  //             position: "absolute",
  //             top: "50%",
  //             left: "50%",
  //             transform: "translate(-50%,-50%)",
  //           }}>
  //           <BeatLoader loading={true} size={24} color="#1e88e5" />
  //         </p>
  //       )}
  //     </div>
  //   )
  // }


  // return getReturnComponent( loading , search.rgn.rgnCode
  //   , dataSource ,dongData , userData
  //   , conn ,search ,setSearch ,mapItem ,setMapItem ,dongList ,history 
  // );

  // return (
  //   <MunicMapComp 
  //     loading = {loading} rgnCode = { search.rgn.rgnCode } 
  //     dataSource = {dataSource} dongData = {dongData} userData = {userData} dongList = {dongList} history = {history}
  //     conn = {conn} search = {search} setSearch = {setSearch} mapItem = {mapItem} setMapItem = {setMapItem}
  //     />
  //   //   , dataSource ,dongData , userData
  //   //   , conn ,search ,setSearch ,mapItem ,setMapItem ,dongList ,history   />

  // );

  return (
    <>
      {
        search.rgn.rgnCode === '42770' ? 
          <LocalAreaMonitor conn = {conn} dongList = {dongList} />
        : 
         search.rgn.rgnCode === '30230' ? 
          <DaedeokMonitor conn = {conn} isMapView = {true} />
        : 
         search.rgn.rgnCode === '44200' ? 
          <Asan isMapView = {true} />
        : 
         search.rgn.rgnCode === '44150' ? 
          <GongjuMainPage isMapView = {true} />
        : 
         search.rgn.rgnCode === '44230' ? 
          <Nonsan isMapView = {true} />
        : 
         search.rgn.rgnCode === '44825' ? 
          <TaeanMainPage isMapView = {true} />
        : 
          <div
            style={
              loading
                ? {
                    opacity: 0.6,
                    pointerEvents: "none",
                  }
                : null
            }>
            <div className="comBg">
              <section
                className="content"
                style={{
                  backgroundImage: getBackgorundImg(search.rgn.rgnCode),
                }}>
                <div className="con_left">
                  <div className="sub_title">
                    <h1>신재생에너지 현황</h1>
                  </div>
                  {
                    <MunicProfit dataSource={dataSource} />
                  }
                </div>
                <MunicMap
                  conn={conn}
                  search={search}
                  setSearch={setSearch}
                  dataSource={dataSource}
                  mapItem={mapItem}
                  setMapItem={setMapItem}
                  dongList={dongList}
                  history={history}
                />
                <div className="con_right">
                  <MunicStat dataSource={dataSource} />
                  <MunicRank dongData={dongData} userData={userData} />
                </div>
              </section>
            </div>
            {loading && (
              <p
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}>
                <BeatLoader loading={true} size={24} color="#1e88e5" />
              </p>
            )}
          </div>

      }
    </>
  );
};

export default Munic;
