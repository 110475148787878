

// 융복합지원 - 사업비 - 사업비 데이터 가져오기
export const getBizExpense = async (conn, search) => {
  const response = await conn.get('biz/expense/bizExpense', {
      params: {
        bizYy : search.bizYyNo,
        rgnCode : search.rgnCode,
        bizSctCode : search.bizSctCode
      }
  });
  
  return response;
}


// 융복합지원 - 사업비 - 지급 차수별 현황 데이터 가져오기
export const getPayOrder = async (conn, search) => {
  const {take, skip} = search.payOrderPaging;
  const response = await conn.get('biz/expense/payOrder', {
    params: {
      bizYy : search.bizYyNo,
      rgnCode : search.rgnCode,
      page: skip, 
      size: take,
      bizSctCode : search.bizSctCode
    }
});

return response;

}

// 융복합지원 - 사업비 - 사업비 지출현황 데이터 가져오기
export const getBizExpend = async (conn, search) => {
  const {take, skip} = search.bizExpendPaging;
  const response = await conn.get('biz/expense/bizExpend', {
    params: {
      bizYy : search.bizYyNo,
      rgnCode : search.rgnCode,
      page: skip, 
      size: take,
      bizSctCode : search.bizSctCode
    }
  });

  return response;
  
}

// 융복합지원 - 사업비 - 사업비 지출현황 컨소시엄 리스트 데이터 가져오기
export const getEnteList = async(conn, search, callback, reject) => {
  await conn.get('biz/expense/consEnte', {
    params: {
      bizYy: search.bizYy,
      rgnCode: search.rgnCode,
      bizSctCode : search.bizSctCode
    },
  })
  .then((response) => {
    callback(response);
  })
  .catch((error) => {
    reject(error);
  });
}

// 융복합지원사업 - 사업비 - 사업비 Grid 저장
export const saveBizExpense  = async (conn, data) => {
  console.log("data", data);
  // 헤더 옵션 변경
  const config = {
    headers: {'Content-Type': 'application/json'}
  };

  const result = await conn.post('biz/expense/bizExpense', JSON.stringify(data) ,config);

  return result;
}

// 융복합지원사업 - 사업비 - 지급 차수별 현황 Grid 저장
export const savePayOrder  = async (conn, data) => {
  // 헤더 옵션 변경
  const config = {
    headers: {'Content-Type': 'application/json'}
  };

  const result = await conn.post('biz/expense/payOrder', JSON.stringify(data) ,config);

  return result;
}

// 융복합지원사업 - 사업비 - 사업비 지출현황 Grid 저장
export const saveBizExpend  = async (conn, data) => {
  // 헤더 옵션 변경
  const config = {
    headers: {'Content-Type': 'application/json'}
  };

  const result = await conn.post('biz/expense/bizExpend', JSON.stringify(data) ,config);

  return result;
}
