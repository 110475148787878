import React from "react";
import { BeatLoader } from "react-spinners";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';

const TroubleShootingLeftGrid = ({ search, setSearch, getData, dongList, SearchCom, setSelectedUser, gridData, gridLoading }) => {

    //고장진단 셀
    const checkErrorCells = (e) => {
        const { dataItem } = e;

        switch (dataItem.checkError) {
            case '우수':
                return <td style={{color : "#237CCE"}}>{dataItem.checkError}</td>;
            case '양호':
                return <td style={{color : "#46BD80"}}>{dataItem.checkError}</td>;
            case '보통':
                return <td style={{color : "#E2D43B"}}>{dataItem.checkError}</td>;
            case '미흡':
                return <td style={{color : "#E4AE2A"}}>{dataItem.checkError} <img src="/images/caution-icon.png" style={{ width: "18px", height: "17px" }}/></td>;
            case '취약':
                return <td style={{color : "#E8574C"}}>{dataItem.checkError} <img src="/images/emergency-icon.png" style={{ width: "28px", height: "22px" }}/></td>;
        }
    }

    // 주간발전효율 - 변동값 셀 색상 추가 및 % 기호 추가
    const gapBetweenAvgNowCells = (e) => {
        const { dataItem } = e;

        if(dataItem.gapBetweenAvgNow.includes('+')){
            return <td style={{color : "#237CCE"}}>{dataItem.gapBetweenAvgNow}%</td>;
        }
        else if(dataItem.gapBetweenAvgNow > 7){
            return <td style={{color : "#E8574C"}}>{dataItem.gapBetweenAvgNow}%</td>;
        }
        else if(dataItem.gapBetweenAvgNow > 5){
            return <td style={{color : "#E4AE2A"}}>{dataItem.gapBetweenAvgNow}%</td>;
        }
        else if(dataItem.gapBetweenAvgNow > 3){
            return <td style={{color : "#E2D43B"}}>{dataItem.gapBetweenAvgNow}%</td>;
        }
        else if(dataItem.gapBetweenAvgNow > 1){
            return <td style={{color : "#46BD80"}}>{dataItem.gapBetweenAvgNow}%</td>;
        }
        else {
            return <td style={{color : "#237CCE"}}>{dataItem.gapBetweenAvgNow}%</td>;
        }
    }

    // 월간발전효율 - 변동값 셀 색상 추가 및 % 기호 추가
    const gapBetweenFirstNowCells = (e) => {
        const { dataItem } = e;

        if(dataItem.gapBetweenFirstNow.includes('+')){
            return <td style={{color : "#237CCE"}}>{dataItem.gapBetweenFirstNow}%</td>;
        }
        else if(dataItem.gapBetweenFirstNow > 7){
            return <td style={{color : "#E8574C"}}>{dataItem.gapBetweenFirstNow}%</td>;
        }
        else if(dataItem.gapBetweenFirstNow > 5){
            return <td style={{color : "#E4AE2A"}}>{dataItem.gapBetweenFirstNow}%</td>;
        }
        else if(dataItem.gapBetweenFirstNow > 3){
            return <td style={{color : "#E2D43B"}}>{dataItem.gapBetweenFirstNow}%</td>;
        }
        else if(dataItem.gapBetweenFirstNow > 1){
            return <td style={{color : "#46BD80"}}>{dataItem.gapBetweenFirstNow}%</td>;
        }
        else {
            return <td style={{color : "#237CCE"}}>{dataItem.gapBetweenFirstNow}%</td>;
        }
    }

    // 평균효율, 금주효율, 최초효율, 금월효율 % 기호 추가
    const monthAvgEffiCells = (e) => {
        const { dataItem } = e;
        return <td>{dataItem.monthAvgEffi}%</td>;
    }
    const weekNowEffiCells = (e) => {
        const { dataItem } = e;
        return <td>{dataItem.weekNowEffi}%</td>;
    }
    const monthNowEffiCells = (e) => {
        const { dataItem } = e;
        return <td>{dataItem.monthNowEffi}%</td>;
    }
    const yearFirstEffiCells = (e) => {
        const { dataItem } = e;
        return <td>{dataItem.yearFirstEffi}%</td>;
    }

    // 해당 로우를 클릭시 해당 로우의 userId 로 선택한 user Id 를 설정
    const rowClickEvt = (e) => {
        setSelectedUser(e.dataItem.userId);
    }


    const handleColumnMouseOver = (event) => {
        const tooltip = document.createElement('div');
        tooltip.textContent = '이 컬럼은 오늘로부터 1년 전까지의 데이터를 의미합니다';
        tooltip.style.position = 'absolute';
        tooltip.style.top = '0';
        tooltip.style.left = '0';
        tooltip.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltip.style.color = '#fff';
        tooltip.style.padding = '8px';
        tooltip.style.zIndex = '9999';
      
        const thElement = event.target;
        thElement.appendChild(tooltip);
      
        thElement.addEventListener('mouseout', () => {
          thElement.removeChild(tooltip);
        });
      };

    return (
        <div className="uk-width-medium-1-2 troubleShootingGridSpace">
            <SearchCom search={search} setSearch={setSearch} dong={dongList} getData={getData} />
            <Grid
                filterable={false}
                sortable={false}
                pageable={false}
                data={gridData}
                className="troubleShootingGrid"
                onRowClick={rowClickEvt}
            >
                <GridColumn title="순번" width={60} cell={(props) => (<td>{props.dataIndex + 1}</td>)} />
                <GridColumn field="userTerm" title="수용가명" />
                <GridColumn field="bldSrv" title="종류" width={80} />
                <GridColumn field="checkError" title={<>고장진단<br />(클릭)</>} cell={checkErrorCells} />
                <GridColumn title="주간발전효율">
                    <GridColumn field="monthAvgEffi" title="평균효율" cell={monthAvgEffiCells}/>
                    <GridColumn field="weekNowEffi" title="금주효율" cell={weekNowEffiCells}/>
                    <GridColumn field="gapBetweenAvgNow" title="변동값" cell={gapBetweenAvgNowCells}/>
                </GridColumn>
                <GridColumn title="월간발전효율">
                    <GridColumn field="yearFirstEffi" title="최초효율" cell={yearFirstEffiCells}/>
                    <GridColumn field="monthNowEffi" title="금월효율" cell={monthNowEffiCells}/>
                    <GridColumn field="gapBetweenFirstNow" title="변동값" cell={gapBetweenFirstNowCells}/>
                </GridColumn>
                <GridNoRecords>
                    데이터가 존재하지 않습니다.
                </GridNoRecords>
            </Grid>
            {gridLoading && (
                <p
                    style={{
                        position: "absolute",
                        top: "55%",
                        left: "26.5%",
                        transform: "translate(-50%,-50%)",
                    }}>
                    <BeatLoader loading={true} size={24} color="#1e88e5" />
                </p>
            )}
        </div>
    )

};

export default TroubleShootingLeftGrid;