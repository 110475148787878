import kendo from "@progress/kendo-ui";
import { getMessage } from "../../../com/messageUtil";

/**
 * 사업대상지 정보 - 설치계획서 목록 데이터 가져오기
 * @param {*} conn
 * @param {*} callback
 * @param {*} reject
 * @param {*} setLoading
 * @param {*} search
 */
export const getInfo = async (conn, callback, reject, setLoading, search) => {
  // 검색 객체 생성
  const { take, skip, filter } = search.bizInfoPaging;

  const config = {
    params: {
      bizYy: search.bizYyNo,
      rgnCode: search.rgnCode,
      instlTerm: search.instlTerm,
      energyTypeCode: search.energyTypeCode,
      row: skip,
      size: take,
      sort: search.sort,
      bizSctCode: search.bizSctCode,
    },
  };

  // filter 값 설정
  if (filter !== undefined) {
    if (filter.classFaci !== undefined) config.params.classFaci = filter.classFaci.codeNo;
    if (filter.instlAddr !== undefined) config.params.instlAddr = filter.instlAddr;
    if (filter.cityNewdeal !== undefined) config.params.cityNewdeal = filter.cityNewdeal;
  }
  await conn
    .get("biz/info/page", config)
    .then(response => {
      setLoading(false);
      callback(response);
    })
    .catch(error => {
      setLoading(false);
      reject(error);
    });
};

// 사업대상지 정보 - 기본정보 + 설비내역 - 신규사업 저장
export const setPlanInstData = async (conn, data, search, callback, reject) => {
  console.log("data = ", data);
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    // 사업 코드 부여
    data.forEach(element => {
      element.bizSctCode = search.bizSctCode;
    });

    await conn
      .post("biz/info/save", JSON.stringify(data), config)
      .then(response => {
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};

/**
 * 사업대상지 정보 - 기본정보 저장
 * @param {*} conn
 * @param {*} data
 * @param {*} search
 * @param {*} callback
 * @param {*} reject
 */
export const setPlanData = async (conn, data, search, callback, reject) => {
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    // 사업 코드 부여
    data.forEach(element => {
      element.bizSctCode = search.bizSctCode;
    });

    await conn
      .post("biz/info/save/plan", JSON.stringify(data), config)
      .then(response => {
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};

/**
 * 사업대상지 정보 - 설비내역 저장
 * @param {*} conn
 * @param {*} data
 * @param {*} search
 */
export const setInstData = async (conn, data, search) => {
  console.log("data", data);
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  // 사업 코드 부여
  data.forEach(element => {
    element.bizSctCode = search.bizSctCode;
  });

  await conn
    .post("biz/info/save/inst", JSON.stringify(data), config)
    .then(response => {
      return response;
    })
    .catch(error => {
      kendo.alert(getMessage(error.errCode));
    });
};

/**
 * 사업대상지 정보 - 모니터링 고도화 사업 저장
 * @param {*} conn
 * @param {*} data
 */
export const setMonitorData = async (conn, data, search, callback, reject) => {
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  // 사업 코드 부여
  data.forEach(element => {
    element.bizSctCode = search.bizSctCode;
  });

  console.log("data", data);

  await conn
    .post("biz/info/save/monitor", JSON.stringify(data), config)
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
      kendo.alert(getMessage(error.errCode));
    });
  // .then(response => {
  //   return response;
  // })
  // .catch(error => {
  //   console.log("error", error);
  //   kendo.alert(getMessage(error.errCode));
  // });
};

export const findPlan = async (conn, data, search, callback, reject) => {
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    await conn
      .get(
        "biz/info/find/plan",
        {
          params: {
            instlPlnnm: data,
            bizSctCode: search.bizSctCode,
          },
        },
        config,
      )
      .then(response => {
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};

export const findMonitor = async (conn, data, search, callback, reject) => {
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    await conn
      .get(
        "biz/info/find/monitor",
        {
          params: {
            instlPlnnm: data,
            bizSctCode: search.bizSctCode,
          },
        },
        config,
      )
      .then(response => {
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};

export const findInst = async (conn, data, search, callback, reject) => {
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    await conn
      .get(
        "biz/info/find/inst",
        {
          params: {
            instlPlnnm: data,
            bizSctCode: search.bizSctCode,
          },
        },
        config,
      )
      .then(response => {
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};
