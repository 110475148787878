import kendo from "@progress/kendo-ui";
import log from '../../com/log';
import { getMessage } from '../../com/messageUtil';

const sysServerComUrl = 'sysServer';


// CPU, MEM 임계치 조회
export const getCpuMem = async( conn, data, serverList ) => {

  const { take, skip, sort } = data;

  const config = {
    params: {
      page: (take + skip) / take
      , size: take
      , sort
    }
  };

  const result = await conn.get(sysServerComUrl + '/page/cpuMem', config);

  result.results.forEach(item => {
    item.sysCodeTerm = serverList.filter(serverItem => serverItem.codeNo === item.sysCodeNo)[0].codeVal;
  });

  log(result, 'sysServerService.getCpuMem');
  return result;
};


// CPU, MEM 임계치 수정
export const setCpuMem = async( conn, data ) => {
  try {
    await conn.post(sysServerComUrl + "/cpuMem", JSON.stringify(new Array(data)), {
      headers: {'Content-Type': 'application/json'}
    });
  } catch(e) {
    const {errCode} = e;
    kendo.alert(getMessage(errCode));
  }
};


// DISK 임계치 조회
export const getDisk = async( conn, data, serverList ) => {
  const { take, skip, sort } = data;
  const config = {
    params: {
      page: (take + skip) / take
      , size: take
      , sort
    }
  };

  const result = await conn.get(sysServerComUrl + '/page/disk', config);

  result.results.forEach(item => {
    item.sysCodeTerm = serverList.filter(serverItem => serverItem.codeNo === item.sysCodeNo)[0].codeVal;
  });

  log(result, 'sysServerService.getDisk');
  return result;
};

// DISK 임계치 수정
export const setDisk = async( conn, data ) => {
  try {
    await conn.post(sysServerComUrl + "/disk", JSON.stringify(new Array(data)), {
      headers: {'Content-Type': 'application/json'}
    });
  } catch(e) {
    const {errCode} = e;
    kendo.alert(getMessage(errCode));
  }
}