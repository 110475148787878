import React from "react";

import { savePayOrder } from "../../../../service/bizStat/com/expenseApiService";

const PayOrderModal = ({
  conn,
  styles,
  bizStyles,
  search,
  dataList,
  setDataList,
  energyTypeCodeListData,
  getData,
  payOrderGridAdd,
  cancelBtnEvt,
  kendo,
  Form,
  FormElement,
  Button,
  useState,
  useEffect,
  NumberFormat,
  DropDownList,
  Input,
}) => {
  const [energyTypeData, setEnergyTypeData] = useState({ codeNo: "", codeVal: "" });

  const deleteCommas = str => {
    return str.replace(/,/g, "");
  };

  const onPayOrderChange = e => {
    // console.log("e",e);
    //input에 name을 가진 요소의 value에 이벤트를 걸었다
    const { name, value } = e.target;
    // 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
    const nextInputs = dataSource => {
      //스프레드 문법으로 기존의 객체를 복사한다.
      const item = { ...dataSource };
      item.payOrderInput[name] = deleteCommas(value);
      return item;
    };
    //만든 변수를 seInput으로 변경해준다.
    setDataList(nextInputs);
  };

  // 드롭다운 변경 이벤트
  const onItemChange = e => {
    dataList.payOrderInput.energyTypeCode = e.target.value.codeNo;
    setEnergyTypeData({
      codeNo: e.target.value.codeNo,
      codeVal: e.target.value.codeVal,
    });
  };

  const savePayOrderEvent = e => {
    dataList.payOrderInput.bizYy = search.bizYyNo;
    dataList.payOrderInput.rgnCode = search.rgnCode;
    dataList.payOrderInput.cityProvCode = search.cityProvCode;
    dataList.payOrderInput.bizSctCode = search.bizSctCode;
    savePayOrder(conn, dataList.payOrderInput).then(results => {
      kendo.alert("지급 차수별 현황 입력을 완료 하였습니다.");
      payOrderGridAdd();
    });
  };

  const cancelPayOrder = e => {
    // payOrderGridAdd();
    cancelBtnEvt();
  };

  // converter
  const dataConverter = item => {
    // 에너지원
    if (item.energyTypeCode) {
      const ensoTypeValue = energyTypeCodeListData.filter(v => v.codeNo === item.energyTypeCode)[0];
      setEnergyTypeData({ codeNo: ensoTypeValue.codeNo, codeVal: ensoTypeValue.codeVal });
    }
  };

  useEffect(() => {
    const ensoData = dataList.payOrderInput;
    dataConverter(ensoData);
    /* eslint-disable-next-line */
  }, []);

  return (
    <div className={bizStyles.payOrderModal}>
      <div className={bizStyles.payOrderModalTitle}>
        <h3 style={{ textAlign: "left" }}>지급차수별 입력</h3>
        <button class="btn_iconMM b-close">
          <i class="material-icons" style={{ color: "black", fontSize: "20px" }} onClick={cancelPayOrder}>
            X
          </i>
        </button>
      </div>
      <div>
        <Form
          render={() => (
            <FormElement>
              <table class={bizStyles.emcTable}>
                <colgroup>
                  <col width="10" />
                  <col width="40" />
                </colgroup>
                <tbody>
                  <tr style={{ border: "solid 1px #dedede" }}>
                    <th>컨소시엄(업체)명</th>
                    <td>
                      <input
                        className={dataList.payOrderInput.createUpdateYn === false ? styles.readOnlyBorderE4 : styles.borderE4}
                        name="consEnte"
                        component={Input}
                        onChange={onPayOrderChange}
                        value={dataList.payOrderInput.consEnte !== "" ? dataList.payOrderInput.consEnte : ""}
                        disabled={dataList.payOrderInput.createUpdateYn === false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>에너지원</th>
                    <td>
                      <DropDownList
                        name="energyTypeCode"
                        data={energyTypeCodeListData}
                        textField="codeVal"
                        dataItemKey="codeNo"
                        value={energyTypeData}
                        disabled={dataList.payOrderInput.createUpdateYn === false}
                        onChange={onItemChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>협약금액</th>
                    <td>
                      <NumberFormat
                        thousandSeparator={true}
                        name="amount"
                        type="text"
                        className={styles.borderE4}
                        value={dataList.payOrderInput.amount.toString() !== "" ? dataList.payOrderInput.amount : ""}
                        onChange={onPayOrderChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>특이사항</th>
                    <td>
                      <textarea
                        className={styles.textBorderE4}
                        name="etc"
                        component={Input}
                        onChange={onPayOrderChange}
                        value={dataList.payOrderInput.etc !== "" ? dataList.payOrderInput.etc : ""}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button title="savePayOrder" className="saveButton" onClick={savePayOrderEvent}>
                저장
              </Button>
              <Button title="cancelPayOrder" className="cancelButton" onClick={cancelPayOrder}>
                취소
              </Button>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default PayOrderModal;
