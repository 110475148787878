import { roundNum , numFormat } from '../../../com/dataUtil';

// 맵의 마커를 마우스 오버 시 설비 정보 컴포넌트 (설비 정보)
export const MeainInfo = (meain) => {

  // 사용량 사용 유무(태양열, 지열)
  const isUse = meain.ensoTypeCode === 15002 || meain.ensoTypeCode === 15003 ? true : false;

  // 설치자명
  const userTerm = meain.userTerm ? meain.userTerm : "-";

  // 에너지원명
  let ensoTypeTerm = "-";
  if( meain.ensoTypeCode === 15001 ){ ensoTypeTerm = "태양광"; }
  else if( meain.ensoTypeCode === 15002 ){ ensoTypeTerm = "태양열"; }
  else if( meain.ensoTypeCode === 15003 ){ ensoTypeTerm = "지열"; }
  else if( meain.ensoTypeCode === 15004 ){ ensoTypeTerm = "풍력"; }
  else if( meain.ensoTypeCode === 15006 ){ ensoTypeTerm = "연료전지"; }
  else if( meain.ensoTypeCode === 15007 ){ ensoTypeTerm = "ESS"; }

  // 설비용량
  const instCapa = meain.instCapa ? meain.instCapa : '-';

  // 사용량 유무 에 따른 누적 발전량, 누적 생산량 값 결정
  const accumTerm = isUse === false ? "누적발전량" : "누적생산량";
  const accumValue = isUse === false ? meain.accumPower : meain.accumUse;

  // className 적용 시 해당 CSS 가 적용이 안되는 현상이 있고, react 방식이 아닌 기존 방식의 동적 태그 제작 이므로, class 사용
  const meainInfo = '<div class = "meainMarkerInfo" id="iw-'+meain.userId+'-'+meain.ensoTypeCode+'">' 
                  + '<div class ="meainMarkerInfoHeader">'
                  + userTerm
                  // + '<button class = "meainMarkerInfoGo" id="iw-go-'+meain.userId+'-'+meain.ensoTypeCode+'" ><img src="/images/icon_d.png"/></button>'
                  // + '<img src="/images/icon_close.png" class = "meainMarkerInfoClose" id="iw-close-'+meain.userId+'-'+meain.ensoTypeCode+'" />'
                  + '</div>'
                  + '<table class="installerMeainPopW ">'
                  + '<tbody>'
                  + '<tr>'
                  + '<th>에너지원</th>'
                  + '<td>'+ensoTypeTerm+'</td>'
                  + '</tr>'
                  + '<tr>'
                  + '<th>설비용량</th>'
                  + '<td>' + numFormat(roundNum(instCapa ,2 )) + '<span>kW</span></td>'
                  + '</tr>'
                  + '<tr>'
                  + '<th>'+ accumTerm +'</th>'
                  + '<td>'+ numFormat(roundNum( accumValue , 2 ))+'<span>kWh</span></td>'
                  + '</tr>'
                  + '</tbody>'
                  + '</table>'
                  + '</div>'
                  ;

  return meainInfo;
};


// 맵의 클러스터를 마우스 오버 시 지역 이름과 각 에너지원별 설비 갯수
export const ClusterInfo = (element , markerInfo) => {
  // 데이터 파싱
  const parseData = JSON.parse(markerInfo.Fb);
  // 타이틀 설정
  let title = '-';
  // 에너지원별 갯수
  const ensoTypeCnt = [ 
    { ensoTypeCode : 15001 , cnt : 0 }
    , { ensoTypeCode : 15002 , cnt : 0 }
    , { ensoTypeCode : 15003 , cnt : 0 }
    , { ensoTypeCode : 15004 , cnt : 0 } 
    , { ensoTypeCode : 15005 , cnt : 0 } 
    , { ensoTypeCode : 15006 , cnt : 0 }
    , { ensoTypeCode : 15007 , cnt : 0 }
  ]

  // 각 에너지원 별 갯수 설정
  element.forEach(item1 => {
    if(item1.dongCode === parseData.dongCode){
      ensoTypeCnt.forEach(item2 => {
        if( item1.ensoTypeCode === item2.ensoTypeCode ){
          item2.cnt += 1;
        }
      });
    }
  });

  // 타이틀 설정 (전부 순회를 하지 않고 같은 dongCode 일시만 stop)
  element.some( item1 => {
    if( item1.dongCode === parseData.dongCode ){
      title = item1.fixbylawBundTerm;
      return true;
    }
    return false;
  } );

  const clusterInfo = '<div className="plcNmWrapper">' +
    '<table className="no-margin">' +
    '<tr>' +
    '<td className="plcNm" style="text-align: center;">' + title + '</td>' +
    '</tr>' +
    '</table>' +
    '</div>' +
    '<div id="iWindow" style="width: 100%; padding:5px;">' +
    '<table style="width: 100%;">' +
    '<tr><th>태양광</th><td>' + numFormat(ensoTypeCnt[0].cnt) + '</td><td>개소</td></tr>' +
    '<tr><th>태양열</th><td>' + numFormat(ensoTypeCnt[1].cnt) + '</td><td>개소</td></tr>' +
    '<tr><th>지열</th><td>' + numFormat(ensoTypeCnt[2].cnt) + '</td><td>개소</td></tr>' +
    '<tr><th>풍력</th><td>' + numFormat(ensoTypeCnt[3].cnt) + '</td><td>개소</td></tr>' +
    '<tr><th>소수력</th><td>' + numFormat(ensoTypeCnt[4].cnt) + '</td><td>개소</td></tr>' +
    '<tr><th>연료전지</th><td>' + numFormat(ensoTypeCnt[5].cnt) + '</td><td>개소</td></tr>' +
    '<tr><th>ESS</th><td>' + numFormat(ensoTypeCnt[6].cnt) + '</td><td>개소</td></tr>' +
    '</table' +
    '</div>';

  return clusterInfo;
}