export const DaedeokUnderBar = (props) => {
	
	let barContainer = {
		margin: "5px 0px"
		, width: props.width
		, height: "5px"
	}
	
	let barImg = {
		width: "100%"
		, height: "100%"
		, display: "block"
		, objectFit: "fill"
	}

	return(
		<div style={barContainer}>
			<img style={barImg} src="/images/localareaImg/30230/underBar.png"/>
		</div>
	)
}