// 맵 레벨 설정
export const DEFAULT_MAP_LEVEL = 12;
export const MAX_RGN_MAP_LEVEL = 10;
export const MAX_DONG_MAP_LEVEL = 8;
export const MAX_MARKER_MAP_LEVEL = 5;
export const MIN_CITY_MAP_LEVEL = 11;
export const MIN_RGN_MAP_LEVEL = 9;
export const MIN_DONG_MAP_LEVEL = 6;
export const AREA_CITY = "CITY";
export const AREA_RGN = "RGN";
export const AREA_DONG = "DONG";
export const AREA_MARKER = "";
export const DEFAULT_LATI = 36.8151;
export const DEFAULT_LONGI = 127.1139;

// 맵 좌표 요청 (맵 이동)
/**
 *
 * @param {*} kakao kakao 객체
 * @param {*} lati  lat
 * @param {*} longi lng
 * @param {*} map kakao map 객체
 */
export const setMapPosition = (kakao, lati, longi, map) => {
  if (map) {
    if (!lati) {
      lati = DEFAULT_LATI;
    }
    if (!longi) {
      longi = DEFAULT_LONGI;
    }
    map.setCenter(new kakao.maps.LatLng(lati, longi));
  }
};

/**
 * 카카오 맵의 마커를 삭제
 * @param {*} markers 마커 useRef 사용
 * @param {*} search 검색조건
 */
export const removeMarkers = (markers, search) => {
  for (let i = 0; i < markers.current.length; i++) {
    markers.current[i].setMap(null);
  }
  // 저장된 마커 전부 초기화
  if (search.dongCode === "") {
    markers.current = [];
  }
};

/**
 * type으로 mapLevel 반환
 * @param {*} type AREA_CITY, AREA_RGN, AREA_DONG
 * @param {*} map kakao map 객체
 * @returns
 */
export const getMapLevel = (type, map) => {
  if (type === AREA_CITY) return MAX_RGN_MAP_LEVEL;
  else if (type === AREA_RGN) return MAX_DONG_MAP_LEVEL;
  else if (type === AREA_DONG) return MAX_MARKER_MAP_LEVEL;
  else return map.getLevel();
};

/**
 * cluster style 반환
 * @param {*} mapLevel 레벨
 * @param {*} rate 이용률
 * @param {*} installRate 설치율
 * @returns
 */
export const getClusterStyle = (mapLevel, rate, installRate) => {  
  const style = { len: "40px", fontSize: "12px", className: "areaClusterColor20" };
  let len = 40;
  let fontSize = 12;
  if (mapLevel === 13) {
    len = 40;
    fontSize = 12;
  } else if (mapLevel === 12) {
    len = 45;
    fontSize = 12;
  } else if (mapLevel === 11) {
    len = 80;
    fontSize = 16;
  } else if (mapLevel === 10) {
    len = 85;
    fontSize = 18;
  } else if (mapLevel === 9) {
    len = 60;
    fontSize = 12;
  } else if (mapLevel === 8) {
    len = 65;
    fontSize = 12;
  } else if (mapLevel === 7) {
    len = 70;
    fontSize = 12;
  } else if (mapLevel === 6) {
    len = 75;
    fontSize = 12;
  } else if (mapLevel === 5) {
    len = 80;
    fontSize = 12;
  } else if (mapLevel === 4) {
    len = 85;
  }

  let lenAlpha = 0;
  if (installRate > 20 && installRate < 41) lenAlpha = 20;
  else if (installRate > 40 && installRate < 61) lenAlpha = 30;
  else if (installRate > 60 && installRate < 81) lenAlpha = 40;
  else if (installRate > 80) lenAlpha = 60;

  len = len + lenAlpha;

  len = len + 10;
  fontSize = fontSize + 1;
  style.len = len + "px";
  style.fontSize = fontSize + "px";

  if (rate < 21) style.className = "areaClusterColor20";
  else if (rate > 20 && rate < 41) style.className = "areaClusterColor40";
  else if (rate > 40 && rate < 61) style.className = "areaClusterColor60";
  else if (rate > 60 && rate < 81) style.className = "areaClusterColor80";
  else if (rate > 80) style.className = "areaClusterColor100";

  return style;
};
