import { useCreateAxiosInstance } from "conInstance";
import Time from "./Time";
import "./Today.css";
import { useEffect, useRef, useState } from "react";
import { ASAN_CODE } from "../constant/rgnCode";
import { roundNum } from "com/dataUtil";
import WeatherIcon from './../../../gongju/components/todayGongju/weatherIcon';

export default function Today() {
  const { conn } = useCreateAxiosInstance();
  const timeId = useRef(null);
  const [data, setData] = useState();
  const getTodayInfo = async () => {
    const response = await conn.get(`/monitor/installer/munic/weather?rgnCode=${ASAN_CODE}`);
    setData(response);
  };

  useEffect(() => {
    getTodayInfo();
    if (timeId.current) clearInterval(timeId.current);
    timeId.current = setInterval(() => getTodayInfo(), 1000 * 60 * 60);
    // eslint-disable-next-line
  }, []); // 초기 렌더링 시에만 적용 및 interval 진행, 새로고침 시 interval 초기화

  if (!data) return <div />;

  return (
    <div className="today-asan-container">
      <h3 className="asan-card-title">오늘의 아산</h3>
      <div className="asan-card">
        <div className="asan-info">
          <div className="asan-info-detail">
            <div>{new Date().toISOString().slice(0, 10)}</div>
            <div>
              <div className="asan-weather">{data.presWeathVal}</div>
              <div className="asan-weather-box">
                <div className="asan-tem">{data.temp}</div>
                <div className="asan-degree">ºC</div>
              </div>
            </div>
          </div>
          <div>
            <Time />
            <WeatherIcon weather={data.presWeathVal}/>
          </div>
        </div>
        <div className="asan-info-etc">
          <div>습도: {data.humi}%</div>
          <div>강수량: {roundNum(data.precQty, 0)}mm</div>
          <div>일사량: {roundNum(data.solaQty, 0)} W/m<sup>2</sup></div>
        </div>
      </div>
    </div>
  );
}
