import React from "react";
import BizPlanAddMonitor from "./bizPlanAddMonitor";
import { setPlanData, setMonitorData } from "../../../../service/bizStat/com/infoApiService";

const BizPlanAdd = ({
  conn,
  styles,
  rgnCode,
  bizYy,
  instPlanListAdd,
  codeList,
  setPaging,
  inputs,
  setDataSource,
  plan,
  moniInfo,
  value,
  handleMoniYChange,
  handleMoniNChange,
  handleNewbulidYChange,
  handleNewbulidNChange,
  handleCityNewdealYChange,
  handleCityNewdealNChange,
  isNew,
  rowData,
  search,
  userInfoVisible,
  setUserInfoVisible,
  useEffect,
  Form,
  FormElement,
  Input,
  RadioButton,
  DropDownList,
  Button,
  kendo,
  getMessage,
}) => {
  useEffect(() => {
    if (plan != null) {
      //   convPlanList = convPlan;
      setDataSource(dataSource => {
        const item = dataSource;
        item.inputs.createUpdateYn = false;
        item.moniInfo.instlPlnnm = plan.instlPlnnm;
        return item;
      });
    }

    setDataSource(dataSource => {
      const item = dataSource;
      item.inputs.rgnCode = rgnCode.rgnCode;
      item.inputs.bizYy = bizYy;
      item.inputs.cityProvCode = rgnCode.cityProvCode;
      return item;
    });
    /* eslint-disable-next-line */
  }, []);

  const classFaci = codeList.filter(c => c.grpCodeNo === 41);

  /**
   * 기본정보의 시설구분 코드 값이 있을 경우 아래 필터 작업 진행
   */
  if (plan) {
    if (plan.classFaci) {
      const classFaciData = classFaci.filter(c => c.codeNo === parseInt(plan.classFaci))[0];
      plan.classFaciData = classFaciData;
    }
  }

  const searchAddress = () => {
    setUserInfoVisible(!userInfoVisible);
  };

  const onClassFaciChange = event => {
    setDataSource(dataSource => {
      const item = dataSource;
      item.inputs.classFaci = event.target.value;
      return item;
    });
  };

  const onPlanChange = e => {
    // console.log("e",e);
    //input에 name을 가진 요소의 value에 이벤트를 걸었다
    const { name, value } = e.target;

    // 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
    const nextInputs = dataSource => {
      //스프레드 문법으로 기존의 객체를 복사한다.
      const item = dataSource;
      item.inputs[name] = value;
      return item;
    };

    //만든 변수를 seInput으로 변경해준다.
    setDataSource(nextInputs);
  };
  
  // 기본정보 데이터 저장 하기
  function savePlanDataSource(dataItem) {
    const planList = [
      {
        bizYy: search.bizYy === "사업연도" ? dataItem.bizYy : search.bizYy,
        cityNewdeal: dataItem.cityNewdeal,
        cityProvCode: dataItem.cityProvCode,
        classFaci: dataItem.classFaci.codeNo ? dataItem.classFaci.codeNo : dataItem.classFaci,
        createUpdateYn: inputs.createUpdateYn,
        delYn: "N",
        etc: dataItem.etc,
        fixDtm: dataItem.fixDtm,
        instlAddr: dataItem.instlAddr,
        instlDetailAddr: dataItem.instlDetailAddr,
        instlCelphon: dataItem.instlCelphon,
        instlCntct: dataItem.instlCntct,
        instlPlnnm: dataItem.instlPlnnm,
        instlTerm: dataItem.instlTerm,
        moniYn: dataItem.moniYn,
        newbuild: dataItem.newbuild,
        regiDtm: dataItem.regiDtm,
        rgnCode: dataItem.rgnCode,
        fixbylawBundCode: dataItem.fixbylawBundCode,
        longi: dataItem.longi,
        lati: dataItem.lati,
      },
    ];

    setPlanData(conn, planList, search, getResult, getRejection);
  }

  function savePlanMoniDataSource(dataItem) {
    const planMoniList = [
      {
        bizYy: search.bizYy === "사업연도" ? inputs.bizYy : search.bizYy,
        cityNewdeal: inputs.cityNewdeal,
        cityProvCode: inputs.cityProvCode,
        classFaci: inputs.classFaci.codeNo ? inputs.classFaci.codeNo : inputs.classFaci,
        createUpdateYn: inputs.createUpdateYn,
        delYn: "N",
        etc: inputs.etc,
        fixDtm: inputs.fixDtm,
        instlAddr: inputs.instlAddr,
        instlDetailAddr: inputs.instlDetailAddr,
        instlCelphon: inputs.instlCelphon,
        instlCntct: inputs.instlCntct,
        instlPlnnm: inputs.instlPlnnm,
        instlTerm: inputs.instlTerm,
        moniYn: inputs.moniYn,
        newbuild: inputs.newbuild,
        regiDtm: inputs.regiDtm,
        rgnCode: inputs.rgnCode,
        fixbylawBundCode: inputs.fixbylawBundCode,
        longi: inputs.longi,
        lati: inputs.lati,
        relatBizCode: dataItem.relatBizCodeData.codeNo,
        relatBizYy: dataItem.relatBizYy,
        instCnfrmCode: dataItem.instCnfrmCode,
        bizno: dataItem.bizno,
        energyTypeCode: dataItem.energyTypeCodeData.codeNo,
        instCapa: dataItem.instCapa,
        unit: dataItem.unit,
        cid: dataItem.cid,
      },
    ];

    setMonitorData(conn, planMoniList, search, getMoniResult, getRejection);
  }

  const getResult = result => {
    kendo.alert("기본정보를 저장 하였습니다.");
    instPlanListAdd();

    // setLoading(false);
  };

  const getMoniResult = result => {
    kendo.alert("모니터링정보를 저장 하였습니다.");
    instPlanListAdd();

    // setLoading(false);
  };

  // rejection 함수
  const getRejection = error => {
    kendo.alert("저장을 실패 하였습니다.");
    alert(getMessage(error.errCode));
  };

  // 기본내역 저장
  const savePlanChanges = e => {
    // 전화번호 정규식
    const regPhone = /^\d{2,3}-(\d{3,4})-\d{4}$/;
    // 핸드폰 정규식
    // const regCellPhone = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
    const regCellPhone = /^(02|01[016789]{1}|0[3-9]{1}[0-9]{1})-([0-9]{3,4})-([0-9]{4})$/;
    const regCellPhone2 = /^([0-9]{4}-?[0-9]{4})$/;

    var regExpCid = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
    var regExpNum = /[0-9]/gi;

    if (
      inputs.instlTerm != null &&
      inputs.instlPlnnm != null &&
      inputs.instlAddr != null &&
      inputs.instlCelphon != null &&
      inputs.newbuild != null
    ) {
      if (!inputs.bizYy) {
        inputs.bizYy = rowData.bizYy;
      }
      if (!inputs.cityProvCode) {
        inputs.cityProvCode = rowData.cityProvCode;
      }
      if (!inputs.rgnCode) {
        inputs.rgnCode = rowData.rgnCode;
      }

      if (!inputs.instlTerm) {
        kendo.alert("신청자를 입력하지 않았습니다. 신청자를 입력해 주세요.");
        return;
      }
      if (!inputs.instlPlnnm) {
        kendo.alert("BPM 설치 계획서 신청 번호를 입력하지 않았습니다. BPM 설치 계획서 신청 번호를 입력해 주세요.");
        return;
      }
      if (!inputs.instlAddr) {
        kendo.alert("신청자 주소를 입력하지 않았습니다. 신청자 주소를 입력해 주세요.");
        return;
      }
      if (!inputs.instlCelphon) {
        kendo.alert("신청자 연락처를 입력하지 않았습니다. 휴대폰 번호를 입력해 주세요.");
        return;
      } else {
        if (!regCellPhone.test(inputs.instlCelphon.replace(" ", "")) && !regCellPhone2.test(inputs.instlCelphon.replace(" ", ""))) {
          kendo.alert("신청자 연락처 형식이 아닙니다. 다시 입력해 주시기 바랍니다.");
          return;
        }
      }

      if (inputs.instlCntct) {
        if (!regPhone.test(inputs.instlCntct)) {
          kendo.alert("전화번호 형식이 아닙니다. 다시 입력해 주시기 바랍니다.");
          return true;
        }
      }

      if (!inputs.newbuild) {
        kendo.alert("신축 여부를 선택하지 않았습니다. 신축 여부를 선택해 주세요.");
        return;
      }
      if (!inputs.classFaci) {
        kendo.alert("시설 구분을 선택하지 않았습니다. 시설 구분을 선택해 주세요.");
        return;
      }
      if (!inputs.cityNewdeal) {
        kendo.alert("도시재생뉴딜 사업 여부를 선택하지 않았습니다. 도시재생뉴딜 사업 여부를 선택해 주세요.");
        return;
      }
      if (inputs.moniYn) {
        if (inputs.moniYn === "Y") {
          if (
            moniInfo.relatBizCode !== "" &&
            moniInfo.relatBizYy !== "" &&
            moniInfo.energyTypeCode !== "" &&
            moniInfo.instCapa !== "" &&
            moniInfo.unit !== "" &&
            moniInfo.cid !== ""
          ) {
            if (!moniInfo.relatBizCode) {
              kendo.alert("관련사업을 선택하지 않았습니다. 관련사업을 선택해 주세요.");
              return;
            }
            if (!moniInfo.relatBizYy) {
              kendo.alert("관련사업 년도를 선택하지 않았습니다. 관련사업 년도를 선택해 주세요.");
              return;
            }
            if (!moniInfo.energyTypeCode) {
              kendo.alert("에너지원을 선택하지 않았습니다. 에너지원을 선택해 주세요.");
              return;
            }
            if (!moniInfo.instCapa) {
              kendo.alert("설치용량을 입력 하지 않았습니다. 설치용량을 입력해 주세요.");
              return;
            }
            if (!moniInfo.unit) {
              kendo.alert("용량 단위를 입력 하지 않았습니다. 용량 단위를 입력해 주세요.");
              return;
            } else {
              if (regExpNum.test(moniInfo.unit)) {
                kendo.alert("용량 단위 형식이 아닙니다. 다시 입력해 주시기 바랍니다.");
                return;
              }
            }
            if (!moniInfo.cid) {
              kendo.alert("CID를 입력 하지 않았습니다. CID를 입력해 주세요.");
              return;
            } else {
              if (regExpCid.test(moniInfo.cid)) {
                kendo.alert("CID 형식이 아닙니다. 다시 입력해 주시기 바랍니다.");
                return;
              }
            }
          } else {
            kendo.alert("모니터링고도화 기본정보와 설비내역의 필수 값들을 입력해주세요.");
            return;
          }
          savePlanMoniDataSource(moniInfo);
        } else {
          savePlanDataSource(inputs);
        }
      }

      setPaging(paging => {
        const item = { ...paging };
        item.bizInfoPaging.skip = 0;
        item.bizInfoPaging.take = 10;
        return item;
      });
    } else {
      kendo.alert("설치장소 기본정보를 입력해주세요.");
    }
  };  
  
  return (
    <>
      <div class="JinTabMo_con" id="JinTabModal_con">
        <div>
          <div class="SubM_title">[ 컨소시엄 정보 ]</div>
          {/* <div class="info_w">
              <span class="sel-Name">사업연도</span>
              <input type="text" class="infoIn" value={bizYy} style={ { width: "200px", height: "25px", border: "solid 1px #dedede", backgroundImage: "linear-gradient(to bottom, #f5f5f5, #f0f0f0)"}} />
              <span class="sel-Name">시군구</span>
              <input type="text" class="infoIn" value={rgnCode.rgnTermSimple} style={ { width: "200px", height: "25px", border: "solid 1px #dedede", backgroundImage: "linear-gradient(to bottom, #f5f5f5, #f0f0f0)"}} />          
            </div> */}
          <div class="info_w">
            <span class="sel-Name">사업연도</span>
            <input
              type="text"
              class="infoIn"
              value={rowData.bizYy}
              style={{
                width: "200px",
                height: "25px",
                border: "solid 1px #dedede",
                backgroundImage: "linear-gradient(to bottom, #f5f5f5, #f0f0f0)",
              }}
            />
            <span class="sel-Name">시군구</span>
            <input
              type="text"
              class="infoIn"
              value={rowData.rgnTerm}
              style={{
                width: "200px",
                height: "25px",
                border: "solid 1px #dedede",
                backgroundImage: "linear-gradient(to bottom, #f5f5f5, #f0f0f0)",
              }}
            />
          </div>
          <div class="SubM_title">[ 신청서 기본정보 ]</div>
          <div class="info_w_m">
            <RadioButton
              name="moniYn"
              className={styles.moniYnRadio}
              style={{ marginLeft: "29px" }}
              label="신규사업(신재생에너지설비 + 모니터링시스템)"
              value={value}
              checked={inputs.moniYn === "N"}
              onChange={handleMoniNChange}
              disabled={isNew}
            />
            <RadioButton
              name="moniYn"
              className={styles.moniYnRadio}
              label="모니터링고도화사업"
              value={value}
              checked={inputs.moniYn === "Y"}
              onChange={handleMoniYChange}
              disabled={isNew}
            />
          </div>
          {inputs.moniYn === "Y" && (
            <BizPlanAddMonitor
              codeList={codeList}
              inputs={inputs}
              setDataSource={setDataSource}
              plan={plan}
              styles={styles}
              moniInfo={moniInfo}
              DropDownList={DropDownList}
              Form={Form}
              FormElement={FormElement}
              kendo={kendo}
              Input={Input}
            />
          )}
          <div class="SubM_title">[ 설치장소 기본정보 ]</div>
          <Form
            render={() => (
              <FormElement>
                <table class={styles.emcTable}>
                  <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="20%" />
                    <col width="30%" />
                  </colgroup>
                  <tbody>
                    <tr style={{ border: "solid 1px #dedede" }}>
                      <th>
                        <span class="red_cl">*</span> 신청자(건물)명
                      </th>
                      <td>
                        <input
                          className={styles.borderE4}
                          name="instlTerm"
                          component={Input}
                          placeholder="신청자 이름 입력"
                          onChange={onPlanChange}
                          defaultValue={inputs.instlTerm ? inputs.instlTerm : ""}
                          style={{ width: "98%" }}
                        />
                      </td>
                      <th>
                        <span class="red_cl">*</span> BPM설치계획서 신청번호
                      </th>
                      <td>
                        <input
                          className={inputs.createUpdateYn === false ? styles.readOnlyBorderE4 : styles.borderE4}
                          name="instlPlnnm"
                          component={Input}
                          placeholder="설치계획서 신청번호 입력"
                          onChange={onPlanChange}
                          defaultValue={inputs.instlPlnnm ? inputs.instlPlnnm : ""}
                          style={{ width: "98%" }}
                          readOnly={inputs.createUpdateYn === false ? true : false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="red_cl">*</span> 설치장소
                      </th>
                      <td>
                        <input
                          className={styles.borderE4}
                          name="instlAddr"
                          component={Input}
                          placeholder="신청자 주소 입력"
                          onChange={onPlanChange}
                          value={inputs.instlAddr ? inputs.instlAddr : ""}
                          style={{ width: "98%" }}
                          onClick={searchAddress}
                          readOnly
                        />
                      </td>
                      <th>상세주소</th>
                      <td>
                        <input
                          className={styles.borderE4}
                          name="instlDetailAddr"
                          component={Input}
                          placeholder="상세주소 입력"
                          onChange={onPlanChange}
                          defaultValue={inputs.instlDetailAddr ? inputs.instlDetailAddr : ""}
                          style={{ width: "98%" }}
                        />
                      </td>
                      {/* <th>
                          <span class="red_cl">*</span> 설치장소
                        </th>
                        <td colSpan={3}>
                        <input className={styles.borderE4} name="instlAddr" component={Input} placeholder="신청자 주소 입력" onChange={onPlanChange} defaultValue ={inputs.instlAddr? inputs.instlAddr : "" } style={{width: '99%'}} onClick={searchAddress}/>
                        </td> */}
                    </tr>
                    <tr>
                      <th>
                        <span class="red_cl">*</span> 연락처
                      </th>
                      <td>
                        <input
                          className={styles.borderE4}
                          name="instlCelphon"
                          style={{ width: "98%" }}
                          component={Input}
                          placeholder="신청자 연락처 입력(하이픈 입력)"
                          onChange={onPlanChange}
                          defaultValue={inputs.instlCelphon ? inputs.instlCelphon : ""}
                        />
                      </td>
                      <th>전화번호</th>
                      <td>
                        <input
                          className={styles.borderE4}
                          name="instlCntct"
                          style={{ width: "98%" }}
                          component={Input}
                          placeholder="신청자 전화번호 입력(하이픈 입력)"
                          onChange={onPlanChange}
                          defaultValue={inputs.instlCntct ? inputs.instlCntct : ""}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="red_cl">*</span> 신축여부
                      </th>
                      <td>
                        <RadioButton
                          name="newbuild"
                          label="신축"
                          value={value}
                          checked={inputs.newbuild === "Y"}
                          onChange={handleNewbulidYChange}
                        />
                        <RadioButton
                          name="newbuild"
                          label="신축아님"
                          value={value}
                          checked={inputs.newbuild === "N"}
                          onChange={handleNewbulidNChange}
                        />
                      </td>
                      <th>
                        <span class="red_cl">*</span> 시설구분
                      </th>
                      <td>
                        <DropDownList
                          name="classFaci"
                          component={DropDownList}
                          data={classFaci}
                          textField="codeVal"
                          dataItemKey="codeNo"
                          defaultValue={
                            plan
                              ? {
                                  codeNo: plan.classFaci,
                                  codeVal: plan.classFaciData ? (plan.classFaciData.codeVal ? plan.classFaciData.codeVal : "-") : "-",
                                }
                              : { codeNo: "", codeVal: "선택" }
                          }
                          onChange={onClassFaciChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>설치계획서 번호</th>
                      <td>
                        <input
                          className={styles.borderE4}
                          name="etc"
                          component={Input}
                          onChange={onPlanChange}
                          defaultValue={inputs.etc ? inputs.etc : ""}
                          style={{ width: "97%" }}
                        />
                      </td>
                      <th>
                        <span class="red_cl">*</span> 도시재생뉴딜 사업여부
                      </th>
                      <td>
                        <RadioButton
                          name="newdealUrbanRegeneration"
                          label="예"
                          value={value}
                          checked={inputs.cityNewdeal === "Y"}
                          onChange={handleCityNewdealYChange}
                        />
                        <RadioButton
                          name="newdealUrbanRegeneration"
                          label="아니오"
                          value={value}
                          checked={inputs.cityNewdeal === "N"}
                          onChange={handleCityNewdealNChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button title="planSave" className="saveButton" onClick={savePlanChanges}>
                  저장
                </Button>
              </FormElement>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default BizPlanAdd;
