import { ReactComponent as Bunpo } from "dist/svg/asan/bunpo.svg";
import "./AsanEnergy.css";
import { useEffect, useState } from "react";
import AsanTooltip from "./AsanTooltip";
import { useCreateAxiosInstance } from "conInstance";
import { ASAN_CODE } from "../constant/rgnCode";
import { asanMapPath } from "../constant/asanMapPath";
import { asanAreaName } from "../constant/asanAreaName";

// 색상 보간 함수
const interpolateColor = ratio => {
  const startColor = { r: 50, g: 121, b: 7 };
  const endColor = { r: 237, g: 255, b: 215 };

  const r = Math.round(startColor.r + ratio * (endColor.r - startColor.r));
  const g = Math.round(startColor.g + ratio * (endColor.g - startColor.g));
  const b = Math.round(startColor.b + ratio * (endColor.b - startColor.b));

  const rHex = r.toString(16).padStart(2, "0");
  const gHex = g.toString(16).padStart(2, "0");
  const bHex = b.toString(16).padStart(2, "0");

  // 최종 색상 코드 반환
  return `#${rHex}${gHex}${bHex}`;
};

const TextLocalSvg = ({ title, x, y }) => {
  return (
    <text x={x} y={y} fontSize="20px">
      {title}
    </text>
  );
};

export const LocalArea = ({ d, filter, fixbylawBundCode, onMouseOver, onMouseOut, ratio }) => {
  return (
    <g filter={filter}>
      <path d={d} fill={interpolateColor(ratio)} id={fixbylawBundCode} onMouseOut={onMouseOut} onMouseOver={onMouseOver} />
    </g>
  );
};

export default function AsanEnergy() {
  const [mouseLocation, setMouseLocation] = useState({ x: 0, y: 0, info: {} });
  const [isOver, setIsOver] = useState(false);
  const [data, setData] = useState();
  const { conn } = useCreateAxiosInstance();
  const getMouseLocation = e => {
    const findDong = data.filter(item => item.admnsBundCode === Number(e.target.id));
    setMouseLocation({
      x: e.clientX,
      y: e.clientY,
      info: data
        ? {
            dongElecPower: findDong[0].dongElecPower,
            dongUsePower: findDong[0].dongUsePower,
            meainCount: findDong[0].meainCount,
            name: findDong[0].admnsBundTermS,
          }
        : {},
    });
    setIsOver(true);
  };

  const removeTooltip = () => {
    setIsOver(false);
  };

  const getGenerateAmount = async () => {
    const response = await conn.get(`monitor/installer/munic/dongEnergyData?rgnCode=${ASAN_CODE}`);
    setData(response);
  };

  useEffect(() => {
    getGenerateAmount();
    // eslint-disable-next-line
  }, []);

  if (!data) return <div />;

  const asanDongList = asanMapPath.map(path => {
    const find = data.find(item => item.admnsBundCode === Number(path.fixbylawBundCode));
    const isZero = find.dongUsePower + find.dongElecPower === 0; // 만약 두 에너지의 합이 0이면 최하 색상 표시
    return find ? { ...path, ratio: isZero ? 1 : find.dongRanking / data.length, name: find.admnsBundTermS } : { ...path };
  });

  return (
    <div className="asan-energy-container">
      <h3 className="asan-card-title">구역별 신재생에너지 현황</h3>
      {isOver && <AsanTooltip x={mouseLocation.x} y={mouseLocation.y} info={mouseLocation.info} />}
      <div className="asan-map">
        <svg width="600" height="440" viewBox="0 0 774 779" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseOut={removeTooltip}>
          {asanDongList.map(dong => (
            <LocalArea
              d={dong.d}
              filter={dong.filter}
              onMouseOver={getMouseLocation}
              fixbylawBundCode={dong.fixbylawBundCode}
              ratio={dong.ratio}
            />
          ))}
          {asanAreaName.map(title => (
            <TextLocalSvg title={title.name} x={title.x} y={title.y} />
          ))}

          <defs>
            <filter
              id="filter0_d_425_7206"
              x="205.369"
              y="220.582"
              width="375.867"
              height="175.338"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter1_d_425_7206"
              x="528.982"
              y="370.633"
              width="244.486"
              height="262.465"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter2_d_425_7206"
              x="289.039"
              y="515.844"
              width="297.039"
              height="263.156"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter3_d_425_7206"
              x="532.439"
              y="268.986"
              width="196.082"
              height="169.806"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter4_d_425_7206"
              x="365.102"
              y="91.9669"
              width="384.165"
              height="218.21"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter5_d_425_7206"
              x="412.122"
              y="0"
              width="294.964"
              height="180.87"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter6_d_425_7206"
              x="221.965"
              y="20.0527"
              width="274.911"
              height="256.933"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter7_d_425_7206"
              x="30.4248"
              y="91.9669"
              width="252.784"
              height="235.497"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter8_d_425_7206"
              x="0"
              y="266.22"
              width="262.465"
              height="267.305"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter9_d_425_7206"
              x="54.627"
              y="441.164"
              width="260.39"
              height="200.923"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter10_d_425_7206"
              x="132.073"
              y="299.411"
              width="265.922"
              height="244.486"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter11_d_425_7206"
              x="428.026"
              y="375.473"
              width="63.3184"
              height="56.4036"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter12_d_425_7206"
              x="449.462"
              y="372.016"
              width="109.647"
              height="77.8395"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter13_d_425_7206"
              x="333.984"
              y="361.644"
              width="116.562"
              height="120.711"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter14_d_425_7206"
              x="423.877"
              y="425.952"
              width="135.232"
              height="110.339"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter15_d_425_7206"
              x="331.91"
              y="423.877"
              width="163.583"
              height="133.158"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <filter
              id="filter16_d_425_7206"
              x="434.249"
              y="414.196"
              width="58.4775"
              height="30.8188"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_425_7206" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_425_7206" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_425_7206" x1="393.303" y1="220.582" x2="393.303" y2="387.92" gradientUnits="userSpaceOnUse">
              <stop stop-color="#4EAB15" />
              <stop offset="1" stop-color="#327907" />
            </linearGradient>
            <linearGradient id="paint1_linear_425_7206" x1="651.225" y1="370.633" x2="651.225" y2="625.098" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient id="paint2_linear_425_7206" x1="437.558" y1="515.844" x2="437.558" y2="771" gradientUnits="userSpaceOnUse">
              <stop stop-color="#4EAB15" />
              <stop offset="1" stop-color="#327907" />
            </linearGradient>
            <linearGradient id="paint3_linear_425_7206" x1="630.481" y1="268.986" x2="630.481" y2="430.792" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EDFFD7" />
              <stop offset="1" stop-color="#D6F2B3" />
            </linearGradient>
            <linearGradient id="paint4_linear_425_7206" x1="557.184" y1="91.9669" x2="557.184" y2="302.177" gradientUnits="userSpaceOnUse">
              <stop stop-color="#94CD4B" />
              <stop offset="1" stop-color="#6CAA1C" />
            </linearGradient>
            <linearGradient id="paint5_linear_425_7206" x1="559.604" y1="0" x2="559.604" y2="172.87" gradientUnits="userSpaceOnUse">
              <stop stop-color="#4EAB15" />
              <stop offset="1" stop-color="#327907" />
            </linearGradient>
            <linearGradient id="paint6_linear_425_7206" x1="359.42" y1="20.0527" x2="359.42" y2="268.985" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient id="paint7_linear_425_7206" x1="156.817" y1="91.9669" x2="156.817" y2="319.464" gradientUnits="userSpaceOnUse">
              <stop stop-color="#94CD4B" />
              <stop offset="1" stop-color="#6CAA1C" />
            </linearGradient>
            <linearGradient id="paint8_linear_425_7206" x1="131.232" y1="266.22" x2="131.232" y2="525.525" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EDFFD7" />
              <stop offset="1" stop-color="#D6F2B3" />
            </linearGradient>
            <linearGradient id="paint9_linear_425_7206" x1="184.822" y1="441.164" x2="184.822" y2="634.087" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient id="paint10_linear_425_7206" x1="265.034" y1="299.411" x2="265.034" y2="535.897" gradientUnits="userSpaceOnUse">
              <stop stop-color="#94CD4B" />
              <stop offset="1" stop-color="#6CAA1C" />
            </linearGradient>
            <linearGradient id="paint11_linear_425_7206" x1="459.686" y1="375.473" x2="459.686" y2="423.877" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EDFFD7" />
              <stop offset="1" stop-color="#D6F2B3" />
            </linearGradient>
            <linearGradient id="paint12_linear_425_7206" x1="504.286" y1="372.016" x2="504.286" y2="441.856" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient id="paint13_linear_425_7206" x1="392.266" y1="361.644" x2="392.266" y2="474.355" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C4EC91" />
              <stop offset="1" stop-color="#A4D863" />
            </linearGradient>
            <linearGradient id="paint14_linear_425_7206" x1="491.493" y1="425.952" x2="491.493" y2="528.291" gradientUnits="userSpaceOnUse">
              <stop stop-color="#94CD4B" />
              <stop offset="1" stop-color="#6CAA1C" />
            </linearGradient>
            <linearGradient id="paint15_linear_425_7206" x1="413.702" y1="423.877" x2="413.702" y2="549.035" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EDFFD7" />
              <stop offset="1" stop-color="#D6F2B3" />
            </linearGradient>
            <linearGradient id="paint16_linear_425_7206" x1="463.488" y1="414.196" x2="463.488" y2="437.015" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EDFFD7" />
              <stop offset="1" stop-color="#D6F2B3" />
            </linearGradient>
          </defs>
        </svg>
        <div className="bunpo">
          <div>발전량 분포</div>
          <div>
            <Bunpo id="bunpo-degree" />
            <div className="bunpo-explain">
              <span>낮음</span>
              <span>높음</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
