import React, { useState } from 'react';

// Kendo
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import koMessage from "../../../../../com/ko-language.json";
import { Upload } from '@progress/kendo-react-upload';
import { Window } from '@progress/kendo-react-dialogs';
import { setCenterCalc } from '../../../../../com/modalUtil';
loadMessages(koMessage, "ko-KR");

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 관리이력 - 관리이력 업로드
*/
const BizManagementMngtHistUpload = ({
  token, kendo, search, fileInfo, convMngtStyles
  , uploadCloseWindow ,getMeainMngtFileList
}) => {

  // API 주소 값
  const ev = `${process.env.REACT_APP_REST_API_VERSION}`;

  // 팝업창 가운데 정렬을 위한 변수
  let posCalc = {};

  // 파일
  const [fileState, setFileState] = useState({
    fileList: [],
    currentFiles: [],
    docuCodeNo: ''
  });

  // 파일 경로
  const saveFileUrl = ev + "/biz/file/upload?fileInfo=" + encodeURI(JSON.stringify(fileState.currentFiles)) + "&instlPlnnm=" + search.instlPlnnm 
  + "&bizDocuCodeNo=" + 38006 + "&docuCodeNo=" + fileInfo.selectId + "&energyTypeCode=" + search.mngt.energyTypeCode + "&convName=mngtEnergy" 
  + "&bizSctCode=" + search.bizSctCode
  ;
  const removeFileUrl = ev + "/biz/file/delete"

  // 파일 추가
  const onAddFile = (e) => {
    console.log("onAdd e ", e, fileState);
    setFileState((state) => {
      const item = { ...state };
      item.currentFiles = e.affectedFiles;
      return item;
    });
  };

  // 업로드 한 파일 삭제
  const onRemoveFile = (e) => {
    // console.log("onRemove e", e, fileState);
    let removeFileIds = [];
    let updateFiles = [];
    fileState.fileList.forEach( f => {
      e.affectedFiles.forEach(v => {
        if(v.uid === f.uid && v.name === f.name)
        removeFileIds.push({
          instlPlnnm : f.instlPlnnm,
          bizDocuCodeNo : f.bizDocuCodeNo,
          docuCodeNo : f.docuCodeNo,
          energyTypeCode : null
        });
      });
      e.newState.forEach(n => {
        if(f.uid === n.uid && f.name === n.name)
          updateFiles.push(f);
      });
    });
  }

  // 파일 업로드
  const onStatusChangeFile = (e) => {
    if(e.response?.response){
      let list = [...fileState.fileList, ...e.response.response.data];
      setFileState((state) => {
        const item = { ...state };
        item.fileList = list;
        item.currentFiles = e.response.response;
        return item;
      });
      
      if(e.response.response.status === 200) {
        uploadCloseWindow();
        kendo.alert("업로드되었습니다.");
        getMeainMngtFileList();
      }
    }
  };


  // useEffect(() => {
  //   posCalc = setCenterCalc(600 ,400);
  // }, []);

  posCalc = setCenterCalc(600 ,400);
  console.log("posCalc", posCalc);

  return (  
    <>
      <Window title={'파일 등록'} onClose={uploadCloseWindow} 
      top={posCalc.top} left={posCalc.left} 
      initialHeight={350} initialWidth={600} modal={true}>
        <div>
          {/* 윈도우 창 */}
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Upload
                className = {convMngtStyles.uploadMeainMngt}
                autoUpload={false}
                batch={true}
                multiple={false}
                files={fileState.fileList}
                defaultFiles={[]}
                onAdd={onAddFile}
                onRemove={onRemoveFile}
                onStatusChange={onStatusChangeFile}
                withCredentials={true}
                removeVerb={"DELETE"}
                restrictions={{
                  allowedExtensions: [
                    ".gif",
                    ".jpg",
                    ".png",
                    // ".xls",
                    // ".xlsx",
                    ".hwp",
                    ".doc",
                    ".pdf",
                    ".exe",
                    ".zip",
                  ],
                  maxFileSize: 51200000,
                }}
                saveMethod={"post"}
                saveHeaders={{
                  "Content-Type": "multipart/form-data",
                  "X-AUTH-TOKEN": token
                }}
                saveUrl={saveFileUrl}
                removeUrl={removeFileUrl}
              />
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </Window>
    </>
  );
};

export default BizManagementMngtHistUpload;