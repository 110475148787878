import GathStatusMonthChart from "./gathStatusMonthChart";
import GathStatusMonthGrid from "./gathStatusMonthGrid";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./gathStatus.module.css";
import { BeatLoader } from "react-spinners";

const GathStatusMonth = ({ getMonthDs , monthDataSource , setMonthDataSource , search }) => {
  // 오늘날
  const today = new Date();
 
  // 엑셀 다운로드 이벤트
  const onClickEvt = e => {
    monthDataSource.excelData.save();
  };

  // 엑셀 변수 상태 설정
  const setExcelData = _excelData => {
    setMonthDataSource(dataSource => {
      const item = { ...dataSource };
      item.excelData = _excelData;
      return item;
    });
  };

  return (
    <div
      className="uk-width-medium-1-2"
      style={
        search.monthLoading
          ? {
              opacity: 0.6,
              pointerEvents: "none",
            }
          : null
      }>
      <div className="md-card">
        <div className="md-card-content">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">월간 일별 수집률</h3>
            <div className={styles.gathStatusToolbar}>
              날짜검색&nbsp;&nbsp;
              <DatePickerKo
                id="start"
                name="start"
                className="datePicker"
                defaultValue={today}
                format="yyyy-MM "
                setDtm={v => {
                  search.day = v;
                  getMonthDs();
                }}
                max={today}
                view="year"
              />
              <Button className="gathStatusExcelBtn" icon="excel" onClick={onClickEvt}>
                엑셀저장
              </Button>
            </div>
          </div>
          <GathStatusMonthChart dataSource={monthDataSource.chart} x_categories={monthDataSource.x_categories} />
          <GathStatusMonthGrid dataSource={monthDataSource.grid} ensoTypeCodeNo={search.ensoTypeCodeNo} setExcelData={setExcelData} />
        </div>
        {search.monthLoading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </div>
  );
};

export default GathStatusMonth;
