// React
import React, { useState, useEffect } from 'react';
import { getNoneGroupInstallerPage, getGroupMangerList, getGroupInstallerPage 
, setGroupInstallerList, setDeleteGroupInstallerList} from '../../../service/mngt/groupInstaller/groupInstallerService';
import GridColumnInputFilter from '../../com/gridFilter/gridColumnInputFilter';
import { BeatLoader } from 'react-spinners'
// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn as Column, getSelectedState, GridNoRecords } from '@progress/kendo-react-grid';
import { getter } from "@progress/kendo-react-common";
import { DropDownList as DropDown} from '@progress/kendo-react-dropdowns';

import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';

// Css
import styles from './groupInstaller.module.css';
import './groupInstaller.css';

const GroupInstaller = ({ conn , codeList, rgnList}) => {

  // 로딩 스피너
  const [loading, setLoading] = useState(true);

  const DATA_ITEM_KEY = "installerId";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  // 그룹 담당자 목록
  const [managerState, setManagerState] = useState({
    managerList : [],
    defalutManger : {userTerm : '선택', groupManagerId : ''}
  });
  // 설치자 목록
  const [installerState, setInstallerState] = useState({list:[], count:0});
  // 그룹 설치자 목록
  const [groupInstallerState, setGroupInstallerState] = useState({list:[],count:0});

  const [groupManagerId, setGroupManagerId] = useState('');

  const [searchInstaller, setSearchInstaller] = useState({
    groupManagerId : '',
    installerId : '',
    userTerm : '',
    rgnCode : '',
    address : '',
    skip : 0,
    take : 15,
    size : 15
  });

  const [searchGroupInstaller, setSearchGroupInstaller] = useState({
    groupManagerId : '',
    installerId : '',
    userTerm : '',
    rgnCode : '',
    address : '',
    skip : 0,
    take : 15,
    size : 15
  });

  const [selectedState, setSelectedState] = useState({});

  const selectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  const headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const newSelectedState = {};
    installerState.list.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  };

  const [groupInstallerSelectedState, setGroupInstallerSelectedState] = useState({});

  const selectionChangeGroupInstaller = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: groupInstallerSelectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setGroupInstallerSelectedState(newSelectedState);
  };

  const headerSelectionChangeGroupInstaller = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const newSelectedState = {};
    groupInstallerState.list.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setGroupInstallerSelectedState(newSelectedState);
  };

  const getGroupManagerInfo = (selectGroupManagerId) =>{

    if(selectGroupManagerId === "") {
      setLoading(false);
    }
    // 체크박스 초기화
    setSelectedState({}); 
    setGroupInstallerSelectedState({}); 
    
    setGroupManagerId(selectGroupManagerId);

    const searchInstallerM = {
      groupManagerId : selectGroupManagerId,
      installerId : '',
      userTerm : '',
      rgnCode : '',
      address : '',
      skip : 0,
      take : 15,
      size : 15};
    setSearchInstaller(searchInstallerM);
    const searchGroupInstallerM =  {
      groupManagerId : selectGroupManagerId,
      installerId : '',
      userTerm : '',
      rgnCode : '',
      address : '',
      skip : 0,
      take : 15,
      size : 15};
    setSearchGroupInstaller(searchGroupInstallerM);

    if(selectGroupManagerId === ''){
      setInstallerState({list:[], count:0});
      setGroupInstallerState({list:[], count:0});
      return false;
    }

    getNoneGroupInstallerPage(conn, searchInstallerM)
    .then(result =>{
      setLoading(false);
      setInstallerState(state => {
        const item = {...state};
        item.list = result.results?.map(v => { return {...v, ...{selected : false}}});
        item.count = result.total;
        return item;
      });
    });

    getGroupInstallerPage(conn, searchGroupInstallerM)
    .then(result =>{
      setLoading(false);
      console.log("result", result);
      setGroupInstallerState(state => {
        const item = {...state};
        item.list = result.results;
        item.count = result.total;
        return item;
      });
    });
  }
  // 그룹 담당자 선택 이벤트
  const groupManagerChangeEvt = (event) => {
    setLoading(true);
    getGroupManagerInfo(event.value.groupManagerId);
  }

  const onClickAddEvt = (event) => {
    let selectInstallerList =[];
    Object.entries(selectedState).forEach(([key, value]) => {if(value) selectInstallerList.push(key)});

    if(selectInstallerList.length === 0 ){
      alert("설치자를 선택하십시오.");
      return false;
    }
    if(groupManagerId === ''){
      alert("그룹관리자를 선택하십시오.");
      return false;
    }
    
    setGroupInstallerList(conn, selectInstallerList, groupManagerId)
    .then(result => {
      alert("추가되었습니다.", result);
      getGroupManagerInfo(groupManagerId);
    });
  }

  const onClickDelEvt = (event) => {
    let selectGroupInstallerList =[];
    Object.entries(groupInstallerSelectedState).forEach(([key, value]) => {if(value) selectGroupInstallerList.push(key)});
    
    if(selectGroupInstallerList.length === 0 ){
      alert("그룹설치자를 선택하십시오.");
      return false;
    }
    
    setDeleteGroupInstallerList(conn, selectGroupInstallerList, groupManagerId)
    .then(result => {
      alert("삭제되었습니다.");
      getGroupManagerInfo(groupManagerId);
    });
  }

  useEffect(() => {
    getGroupMangerList(conn)
    .then(result =>{
      setLoading(false);
      setManagerState(state => {
        const item = {...state};
        item.managerList = [...[state.defalutManger], ...result];
        return item;
      });
    }).catch((e) => {
      if(e.errCode === "L007"){
        kendo.alert("해당 페이지에 권한이 없습니다.");
        return;
      }else{
        console.log("err = ", e);
        kendo.alert("시스템에 문제가 발생하였습니다.");
        return;
      }
    });
    // eslint-disable-next-line
  },[]);
  
  // const rgnDropDownInstallerFilter = CustomFilter(rgnList,{rgnCode:'',rgnTermSimple:'시/군/구'}, 'rgnCode', 'rgnTermSimple');
  // const rgnDropDownGroupManagerFilter = CustomFilter(rgnList,{rgnCode:'',rgnTermSimple:'시/군/구'}, 'rgnCode', 'rgnTermSimple');

  const setInstallerPageChange = (event) =>{
    console.log("page chanage", event);
    let search = {...searchInstaller, ...{skip:event.page.skip, take:event.page.take}};
    getSearchInstaller(search);
  }

  const getSearchInstaller = (search) => {
    setSearchInstaller(search);
    setSelectedState({}); 
    getNoneGroupInstallerPage(conn, search)
    .then(result =>{
      setInstallerState(state => {
        const item = {...state};
        item.list = result.results?.map(v => { return {...v, ...{selected : false}}});
        item.count = result.total;
        return item;
      });
    });
  };

  const getSearchGroupInstaller = (search) => {
    setSearchGroupInstaller(search);
    setGroupInstallerSelectedState({}); 
    
    getGroupInstallerPage(conn, search)
    .then(result =>{
      console.log("result", result);
      setGroupInstallerState(state => {
        const item = {...state};
        item.list = result.results;
        item.count = result.total;
        return item;
      });
    });
  }

  const setGroupInstallerPageChange = (event) =>{
    console.log("page chanage", event);
    let search = {...searchGroupInstaller, ...{skip:event.page.skip, take:event.page.take}};
    getSearchGroupInstaller(search);
  }

  const setInstallerFilterChange = (e, type) => {
    const search = {
      groupManagerId : groupManagerId,
      installerId : '',
      userTerm : '',
      rgnCode : '',
      address : '',
      skip : 0,
      take : 15,
      size : 15
    };
    
    if( e.filter.filters[0].field === "installerId" )
      search.installerId = e.filter.filters[0].value;
    else if ( e.filter.filters[0].field === "userTerm" )
      search.userTerm = e.filter.filters[0].value;
    else if ( e.filter.filters[0].field === "rgnTerm" )
      search.rgnCode = e.filter.filters[0].value.rgnCode; 
    else if ( e.filter.filters[0].field === "address" )
      search.address = e.filter.filters[0].value;
    console.log("filter search ", search);
    if(type === "I")
      getSearchInstaller(search);
    else if(type === "G")
      getSearchGroupInstaller(search);
  }

  return (
    <>
      <div className={loading ? styles.groupInstallerG : ""}>
        <div className={styles.left}>
        <div className="md-card"> 
          <div className="md-card-content">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">설치자 목록</h3>
              <div>검색</div>
            </div>  
            <div>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Grid className={styles.groupInstallerGrid} filterable={true} sortable={false} pageable={true}  
                  style={{ height: "750px" }} 
                  total = {installerState.count} skip = {searchInstaller.skip} take={searchInstaller.take} pageSize = {searchInstaller.take}
                  data={
                    installerState.list.map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))
                  }
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{enabled: true,drag: false,cell: false,mode:'multiple'}}
                  onSelectionChange={selectionChange}
                  onHeaderSelectionChange={headerSelectionChange}
                  onPageChange={setInstallerPageChange}
                  onFilterChange = {(e) => setInstallerFilterChange(e, 'I')}
                  // filterable={true}
                  >
                    <GridNoRecords>{"그룹 설치자 목록을 선택해주세요."}</GridNoRecords>
                    <Column field="selected" width="50px" filterable={false}
                      headerSelectionValue={installerState.list.findIndex(item => !selectedState[idGetter(item)]) === -1}
                    />
                    <Column width="150px" field="installerId" title="ID" filterCell={GridColumnInputFilter}/>
                    <Column width="200px" field="userTerm" title="설치자명" filterCell={GridColumnInputFilter}/>
                    {/* <Column width="120px" field="cityTerm" title="시도" /> */}
                    {/* <Column width="120px" field="rgnTerm" title="시군구" filterCell={rgnDropDownInstallerFilter}/> */}
                    {/* <Column width="120px" field="dongTerm" title="읍면동" /> */}
                    <Column field="address" title="주소"  filterCell={GridColumnInputFilter}/>
                  </Grid>
                </IntlProvider>
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.middle}>
        <Button className={styles.groupButton} onClick={onClickAddEvt}><span className={"k-icon k-i-arrow-60-right " + styles.fontLarge}></span></Button>
        <Button className={styles.groupButton} onClick={onClickDelEvt}><span className={"k-icon k-i-arrow-60-left " + styles.fontLarge}></span></Button>
        {/* <Button className={styles.groupButton} onClick={onClickAllAddEvt}><span className={"k-icon k-i-arrow-double-60-right " + styles.fontLarge}></span></Button>
        <Button className={styles.groupButton} onClick={onClickAllDelEvt}><span className={"k-icon k-i-arrow-double-60-left " + styles.fontLarge}></span></Button> */}
      </div>
      <div className={styles.right}>
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">그룹 설치자 목록</h3>
              <DropDown className={styles.groupMnagerDropDown} data={managerState.managerList} textField="userTerm" dataItemKey="groupManagerId" 
                  defaultValue={managerState.defalutManger}
                  onChange={groupManagerChangeEvt}
              />
            </div>  
            <div>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Grid className={styles.groupInstallerGrid} filterable={true} sortable={false} pageable={true} data={groupInstallerState.list}  style={{ height: "750px" }}
                  total = {groupInstallerState.count} skip = {searchGroupInstaller.skip} take = {searchGroupInstaller.take}  pageSize = {searchGroupInstaller.take}
                  // data={
                  //   groupInstallerState.list?.map((item) => ({
                  //     ...item,
                  //     [SELECTED_FIELD]: groupInstallerSelectedState[idGetter(item)],
                  //   }))
                  // }
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{enabled: true,drag: false,cell: false,mode:'multiple'}}
                  onSelectionChange={selectionChangeGroupInstaller}
                  onHeaderSelectionChange={headerSelectionChangeGroupInstaller}
                  onPageChange={setGroupInstallerPageChange}
                  onFilterChange = {(e) => setInstallerFilterChange(e, 'G')}
                  // filterable={true}
                  >
                     <GridNoRecords>{"그룹 설치자 목록을 선택해주세요."}</GridNoRecords>
                    <Column field="selected" width="50px" filterable={false}
                      headerSelectionValue={groupInstallerState.list.findIndex(item => !groupInstallerSelectedState[idGetter(item)]) === -1}
                    />
                    <Column width="150px" field="installerId" title="ID" filterCell={GridColumnInputFilter}/>
                    <Column width="200px" field="userTerm" title="설치자명" filterCell={GridColumnInputFilter}/>
                    {/* <Column width="120px" field="cityTerm" title="시도"/> */}
                    {/* <Column width="120px" field="rgnTerm" title="시군구"  filterCell={rgnDropDownGroupManagerFilter}/> */}
                    {/* <Column width="120px" field="dongTerm" title="읍면동"/> */}
                    <Column field="address" title="주소" filterCell={GridColumnInputFilter}/>
                  </Grid>
                </IntlProvider>
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
      </div>
      { loading && <p className={styles.groupInstallerLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}  
    </>
  );
}

export default GroupInstaller;