import kendo from "@progress/kendo-ui";
import NewWindow from "react-new-window";
import ReportConsmGrid from "./reportConsmGrid";
import ReportConsmWarnChart from "./reportConsmWarnChart";
import { useState } from "react";
// import "./reportConsmPrintModal.css";
import { BeatLoader } from "react-spinners";

const ReportConsmPrintModal = ({ search, energyData, energyCount, chartWarn, selectMeain, chartMonthData, chartYearData, setModalItem }) => {
  const [loading, setLoading] = useState(false);
  return (
    <NewWindow
      features={{ width: 900, height: 1080 }}
      title="월간 수용가별 운영 보고서"
      onUnload={() => setModalItem(false)}
      onOpen={(win) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        setTimeout(() => {
          win.print();
          win.close();
        }, 3000);
      }}
    >
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
      <div
        className="page"
        style={
          loading
            ? {
                opacity: 0.6,
                pointerEvents: "none",
                boxShadow: "none",
              }
            : {
                pointerEvents: "none",
              }
        }
      >
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="reportConsmPrintHeading"> 월간 종합 운영 보고서 </div>
            <div className="reportDayBackground" style={{textAlign:"center"}}>{search.userTerm}</div>
            <div className="reportDayBackground"> Monthly Operation Report </div>
            <div className="reportDayBackground">보고일 : &nbsp; {kendo.toString(search.dtm, "yyyy년 MM월")}</div>
            <div className="reportConsmPrintTableDiv">
              <table>
                <thead>
                  <th colSpan="3">전기 에너지</th>
                </thead>
                <tbody>
                  <tr>
                    <th>월간발전량</th>
                    <th colSpan="2">누적발전량</th>
                  </tr>
                  <tr>
                    <td>{energyData[0].totalPower}&nbsp;kWh</td>
                    <td colSpan="2">{energyData[0].accumPower}&nbsp;kWh</td>
                  </tr>
                  <tr>
                    <th>발전시간</th>
                    <th>누적CO2저감량</th>
                    <th>발전효율</th>
                  </tr>
                  <tr>
                    <td>{energyData[0].powerTime}&nbsp;분</td>
                    <td>{energyData[0].powerCo2}&nbsp;t</td>
                    <td>{energyData[0].powerEfic}&nbsp;%</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <th>열 에너지</th>
                </thead>
                <tbody>
                  <tr>
                    <th>월간생산열량</th>
                  </tr>
                  <tr>
                    <td>{energyData[0].totalCal}&nbsp;kWh</td>
                  </tr>
                  <tr>
                    <th>누적생산열량</th>
                  </tr>
                  <tr>
                    <td>{energyData[0].accumCal}&nbsp;kWh</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <th colSpan="2">ESS</th>
                </thead>
                <tbody>
                  <tr>
                    <th>월간충전량</th>
                    <th>충전시간</th>
                  </tr>
                  <tr>
                    <td>- &nbsp;kWh</td>
                    <td>- &nbsp;시간</td>
                  </tr>
                  <tr>
                    <th>누적충전량</th>
                    <th>충전효율</th>
                  </tr>
                  <tr>
                    <td>- &nbsp;kWh</td>
                    <td>- &nbsp;%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="reportConsmPrintSecondTableDiv">
              <table>
                <tbody>
                  <tr>
                    <th scope="col">전체&nbsp;{energyCount[0].totalCnt}&nbsp;개소</th>
                    <th scope="col">태양광&nbsp;{energyCount[0].solaCnt}&nbsp;개소</th>
                    <th scope="col">태양열&nbsp;{energyCount[0].heatCnt}&nbsp;개소</th>
                    <th scope="col">지열&nbsp;{energyCount[0].heathCnt}&nbsp;개소</th>
                    <th scope="col">풍력&nbsp;{energyCount[0].veloCnt}&nbsp;개소</th>
                    <th scope="col">연료전지&nbsp;{energyCount[0].fuelcellCnt}&nbsp;개소</th>
                    <th scope="col">ESS&nbsp;{energyCount[0].essCnt}&nbsp;개소</th>
                  </tr>
                  <tr>
                    <td>설치용량&nbsp;&nbsp;{energyCount[0].totalCapa}&nbsp;kW</td>
                    <td>{energyCount[0].solaCapa}&nbsp;kW</td>
                    <td>{energyCount[0].heatCapa}&nbsp;㎡</td>
                    <td>{energyCount[0].heathCapa}&nbsp;kW</td>
                    <td>{energyCount[0].veloCapa}&nbsp;kW</td>
                    <td>{energyCount[0].fuelcellCapa}&nbsp;kW</td>
                    <td>{energyCount[0].essCapa}&nbsp;kW</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="reportDayBackground"> · &nbsp;시간대별 경보 발생 현황 </div>
          <div className="reportDayBackground">
              
          </div>
        </div>
      </div> */}
        <ReportConsmWarnChart chartWarn={chartWarn} width={793} height={500} clsName={"printChart"} isPrint={true}/>
        <ReportConsmGrid
          selectMeain={selectMeain}
          chartMonthData={chartMonthData}
          chartYearData={chartYearData}
          width={793}
          heihgt={400}
          clsName="printChart"
          isPrint={true}
        />
      </div>
    </NewWindow>
  );
};

export default ReportConsmPrintModal;
