import tree from "dist/svg/tree.svg";
import plasticCup from "dist/svg/plastic-cup.svg";
import paperCup from "dist/svg/paper-cup.svg";
import car from "dist/svg/car.svg";

export const environmentList = [
  {
    image: tree,
    title: "나무",
    unit: "그루",
  },
  {
    image: plasticCup,
    title: "플라스틱컵",
    unit: "컵",
  },
  {
    image: paperCup,
    title: "종이컵",
    unit: "컵",
  },
  {
    image: car,
    title: "주행거리",
    unit: "km",
  },
];
