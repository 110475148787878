import React from "react";

const GeoSamcheokTwo = props => {
  const { mapClickEvt, mapOutEvt, mapOverEvt } = props;
  return (
    <svg
      version="1.1"
      id="레이어_1"
      x="0px"
      y="0px"
      width="1030px"
      height="950px"
      viewBox="0 0 1030 950"
      enable-background="new 0 0 1030 950">
      <g>
        <path
          fill="#808080"
          d="M944.93,529.945l-4.104,4.111l-4.109-4.111v-4.11v-4.101l-4.104-4.104v-4.102v-4.102v-4.11v-8.211h-4.102
     h-4.109l-4.102-4.104h-4.104h-4.111v-4.102h-4.1v-4.11l4.1-4.11v-4.102h-4.1v-4.103v-4.102h4.1l4.111-4.111v-4.105h-4.111v-4.104
     l-4.1-4.106l4.1-4.105l-4.1-8.208l-4.111-4.106v-8.213l-8.205-4.102v-4.105v-12.315h4.104h4.102h4.111v-4.106h-4.111h-4.102
     h-12.314v-12.315v-12.317h12.314v-8.209h-4.104h-4.109h-4.102l-8.213-4.106v-4.106h8.213h4.102h4.109l8.205,8.212l4.111-4.106
     l4.1,4.106v4.107l4.111,12.313l4.104,4.106l4.102,8.209v-4.105v-4.104v-4.106v-4.105l-4.102-4.106v-4.102v-12.32l-4.104-16.421
     v-4.101l-4.111-4.106V332.9l-4.1-4.105l-4.111-8.209v-4.105h-4.102v-4.106h-4.104v-4.104v-4.105h-4.109h-4.102v-4.106v-4.106
     l4.102-4.101l-4.102-4.108v-4.106v-4.105v-4.106v-8.207v-8.209v-8.212v-8.213h-4.102h-4.111h-4.102h-4.109v-4.102l-4.104,4.102
     v-4.102v-4.106v-4.105h4.104v-4.104h-4.104l4.104-4.106h-4.104v-4.105v-4.106v-4.106v-4.102h-4.102l-4.109-4.106v-4.107
     l-4.102,4.107h-4.104v-4.107v-4.102h-4.109h-4.104v-4.105v-4.107v-4.106h-4.109h-4.102v-4.105v-4.103v-4.106h-4.104h-4.109h-4.102
     h-4.102v-4.106h-4.109v-4.106h4.109v-4.103l-4.109-4.105v-4.106h-4.105v-4.106h-4.109h-4.102l-4.102-4.102h4.102v-4.106
     l-8.211,4.106h-4.104h-4.102v-4.106l-4.111-4.107v-4.105h-4.1v-4.103h-4.111l-4.102,4.103l-8.215-8.208h-4.1l-4.102-4.105
     l-4.111-4.107v-4.101h-4.104l-4.109-4.107h-4.102v-4.105l-4.102-4.106h-4.109v-4.103l-8.215-8.211V78.38l-4.1-4.101h-4.104v-8.213
     V61.96h4.104h4.1l-4.1-4.103h-4.104h-4.109h-4.104h-8.211v-4.105h-4.102l-4.104-4.107h-4.111v-4.106h-4.1l4.1-4.102h-4.1l4.1-4.106
     h-4.1h-4.111h-4.102h-4.102h-4.113v-4.106h-4.1v4.106l-4.111,4.106v4.102h-4.102v4.106h-4.1h-4.111v4.107h-4.104v4.105v4.103v4.105
     l-4.102,8.213h-4.109v4.101h4.109v4.107h-4.109v4.106l-4.102,4.105l-4.111,4.103l-4.102,8.211v4.107l-4.104,8.208v4.105v4.106
     l-4.109,4.103h-4.102h-4.104v4.105v4.107l-4.109-4.107h-4.102h-4.111l-4.102-4.105l-4.102-4.103v4.103l-4.111-4.103h-4.102h-4.102
     h-4.109h-4.104v-4.106h-4.107h-4.105v4.106l-4.1-4.106v4.106h-4.111v4.103h-4.102v4.105v4.107h-4.104h-4.111v4.106h-4.1v4.102
     l-4.111,8.212v4.105v4.103l-4.102,4.106l4.102,4.106v4.106v4.103v4.105h-4.102v4.106v4.107l-4.104,8.207l-4.11,8.213l-8.202,4.102
     v4.106l4.101,4.106v4.105l-4.101,8.21v4.105v4.106l-4.11,4.102v-4.102h-4.104l-4.109,4.102h-4.102l-4.102-8.208l-8.213-4.105
     h-4.103v-4.104v-4.106v-8.211l-8.211,8.211h-4.111v-4.105v-4.106l-4.101-4.106h-4.104H440l-8.212-4.102l-4.103,4.102l-8.212-4.102
     h-4.102l-4.109-4.106h-4.104v-4.107h-4.11v-4.102l-4.102-4.105h-4.101l-4.11-4.107l-4.104-4.106h-4.102l-4.11-4.105h-4.102h-8.214
     l-4.101,4.105h-4.11v4.106h-4.102v4.107l-4.104,4.105v4.102l-4.11,4.107v4.106l-4.102,4.102h-4.102h-4.109h-8.205h-4.111
     l-4.101,4.106v4.106h-4.11l-4.102,4.105h-4.104l-4.109-4.105v-4.106h-4.102v-4.106l-4.102-4.102h-4.11h-4.104v-4.106v-4.107h-4.11
     l-4.102-4.102l-8.211,4.102v-4.102l-4.104-4.105h-4.102l-4.11-4.107h-4.102v-4.106l-8.214-4.105v-4.103v-4.106v-4.106l-4.102-4.106
     h-4.11l-4.101-4.103h-8.212v-4.105l-4.104,4.105l-4.109,4.103l-8.203,4.106h-4.109h-4.104h-4.111l-4.101,4.106h-4.102h-4.11
     l-4.104,4.106l-8.212,4.103v4.105h-4.101h-4.111h-4.104l-4.101-4.105l-4.11,8.211l-8.205-4.106h-4.11h-4.101l-4.111,4.106
     l-4.101,4.107v4.105v4.102l-4.104,4.107l-8.211,4.106l-8.212,4.102l-8.212,8.212h-4.104h-4.101l4.101,12.315l4.104,8.211v4.102
     l4.111,4.107v4.106l4.101,4.106l8.212-4.106h8.211h4.104l4.101,12.313l4.111,4.108v4.105l4.101,8.208h-4.101v8.211v4.108h12.314
     v4.101v12.317h4.102l8.211,4.104l4.104-4.104h4.111l8.211,4.104l4.102,4.106l4.104,4.105h4.11v4.106h8.202l4.111,4.104v4.105
     l4.104,8.212v4.101h8.211l4.102,4.106h4.109l4.104,4.108l4.101,4.105l4.111,4.101l4.101,4.106h4.11v4.106l4.102,4.107l8.214,4.102
     l4.102,4.106l8.212,8.211h4.104v4.104l8.211,4.105h4.102l12.324,4.106v4.106h4.102v4.101v4.108v8.211l4.102,8.208h4.109h4.104
     h4.102v-4.106h8.211h4.111l8.205,4.106v4.107h4.109l4.102-4.107v4.107l4.102,4.106l4.11,4.106h4.104v-4.106h8.212h8.205h4.109
     l4.102,4.106l4.11,4.102l8.205,4.105v-4.105l4.11-4.102h4.101h4.102l4.11,4.102l4.104,4.105l8.211,8.21l8.212,4.105h4.103
     l4.103,4.111l4.109,8.205l4.102,4.102h4.11h4.104h4.101h4.111h4.102l8.212,4.11v4.11h4.103l-4.103,4.102h-4.103v4.104v4.101v8.221
     l-4.109,4.102v4.102v4.104l-4.102,4.101v4.11l4.102,8.212v4.104v4.102l-4.102,4.11v4.11l-4.111,4.102h4.111v4.103v8.212v4.11v4.102
     v4.104v4.101v4.111l-4.111,4.101v4.11v4.103h4.111v4.103v4.101l4.102,4.111v4.11l4.109,4.101v4.104l4.103,4.101v4.11l4.103,4.111
     v4.101v4.104l-4.103,4.102l4.103,12.322l12.313-8.212l4.109-4.11l16.417-4.102l4.11,12.321v8.205v4.102v4.102v4.11l4.104,4.109
     l8.213,16.418l-4.111,8.213v8.203l4.111,8.221l4.1,4.101h4.111l4.104,4.104l-4.104,8.203l8.205,16.425l-4.102,8.212v8.215v8.202
     l-8.215,20.526l4.111,8.214l4.104-4.104h4.102v-4.11l4.111-4.101h4.1v-4.103h4.105h8.211l4.109-4.103h4.102l4.102-4.11v-4.11
     l8.213,8.221l4.102,4.103l4.111,4.103h4.102v4.101h4.109h4.104v8.214l-4.104,4.11v4.102v4.102v4.101v8.221l-4.109,4.104
     l-4.102,4.103l-4.111,8.21v8.215v4.101l4.111,4.103l4.102,4.109v4.104l8.213,8.212h4.102v4.101l4.109-4.101l4.104,4.101h4.102
     l4.111,4.104l8.211,4.11v4.109h4.102v4.103h4.104l4.111,4.101h4.1l4.102-4.101h4.111v-4.103v-4.109h8.213h4.102v-4.11l4.102-4.104
     l4.111-8.201h4.1h4.111h4.104l4.102-4.111v-4.104h4.109v-4.109l4.102-4.103h4.104v-4.101l4.109-4.104l8.203-4.111l8.215-16.416
     v-4.109l4.109-4.111l4.102-4.101v-4.102v-4.102l4.102-4.11h4.109l4.104-4.104v-4.11v-4.101l-4.104-4.103v-4.103l-4.109-4.11v-4.11
     l4.109-8.202v-8.215h4.104v-4.11h4.111v-4.102l4.102-4.101l-4.102-4.104l-4.111-4.111v-4.109l4.111-4.102v-4.102l4.102-4.104
     L751.994,727v-4.111l-4.111-4.109l4.111-4.102v-8.205l4.102-8.221l4.1-4.102v-4.101l4.111-4.104l8.205,4.104l4.111-8.214v-4.11h4.1
     h12.316l4.109-4.102l4.102-4.102l4.102-4.103l4.109-4.103h4.105v-4.109v-4.11l8.211-4.102l4.102-4.104v-4.101h4.109v-4.111h8.205
     h4.109l4.104-4.11v-4.101l-4.104-4.104l4.104-4.101l8.213-4.11h4.102h4.109h4.102h4.104l4.109-4.111v-8.203v-4.103h4.102v-4.11
     l4.111-4.101h4.102h4.102l4.109,4.101h4.104l4.102,4.11h4.111v-8.211v-4.111v-4.101v-4.104h4.1v-4.102h4.111v-4.11h4.104
     l4.102-4.109v-4.103h4.109l4.102-4.103v-4.102h4.104l-4.104-4.11v-4.11v-4.102h4.104l4.109-4.104l4.104-4.101l4.109-4.111H944.93z
      M879.246,373.954h-4.102v-4.107h4.102V373.954z"
        />
      </g>
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223011700"
        fill="#E1E1E1"
        points="630.425,510.537 634.861,506.112 634.861,503.253 630.75,499.151 630.75,489.696 638.636,481.811 
 635.242,475.02 627.256,471.023 622.329,466.085 622.329,466.084 626.646,453.131 626.646,449.885 622.812,446.047 618.435,437.303 
 618.435,426.136 614.333,426.136 614.333,421.151 610.223,417.044 610.223,409.715 604.001,409.715 608.103,405.609 606.12,405.609 
 606.12,396.517 601.397,391.794 606.12,387.067 606.12,382.082 611.103,382.082 614.333,378.854 614.333,372.348 603.275,368.662 
 599.053,368.662 590.314,364.289 586.472,360.454 571.299,360.454 563.965,367.782 563.965,373.873 563.965,376.873 
 563.965,380.098 559.863,384.203 559.863,386.188 563.965,386.188 563.965,392.148 559.863,400.357 559.863,408.837 
 555.753,412.938 555.753,417.043 550.771,422.03 546.663,422.03 543.438,425.257 543.438,432.222 548.163,436.949 543.438,441.676 
 543.438,446.659 534.349,446.659 531.124,449.885 531.124,463.079 527.022,463.079 527.022,474.256 522.91,482.461 522.91,494.184 
 526.603,505.234 528.822,505.234 532.427,505.234 533.107,505.234 536.337,502.009 536.337,489.697 540.438,485.587 
 540.438,477.381 545.421,472.401 549.528,472.401 552.753,469.17 552.753,465.059 565.945,451.875 569.177,451.875 569.177,447.764 
 573.28,447.764 573.28,439.555 579.501,439.555 592.702,452.753 592.702,457.122 596.54,464.793 600.907,469.171 600.907,492.928 
 605.018,492.928 605.018,501.131 609.12,501.131 609.12,506.113 612.956,509.946 620.347,524.729 623.425,521.657 631.63,521.657 
 634.534,518.759 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223011500"
        fill="#E1E1E1"
        points="498.457,606.765 503.494,596.688 503.494,587.34 507.598,587.34 507.598,580.006 515.81,571.794 
 515.81,567.693 519.585,563.915 515.81,556.353 515.81,551.269 519.91,547.166 519.91,542.185 524.901,542.185 526.002,541.083 
 524.022,541.083 524.022,532.862 519.91,532.862 519.91,518.603 526.822,508.234 524.441,508.234 519.91,494.672 519.91,481.752 
 524.022,473.547 524.022,460.079 528.124,460.079 528.124,448.643 533.105,443.659 540.438,443.659 540.438,440.434 
 543.921,436.949 540.438,433.464 540.438,424.015 545.421,419.03 549.529,419.03 552.753,415.801 552.753,411.694 556.863,407.593 
 556.863,399.649 560.965,391.44 560.965,389.188 558.984,389.188 546.663,401.503 529.002,401.503 524.022,396.516 524.022,392.415 
 512.579,380.977 505.349,380.977 495.282,386.009 495.282,380.098 487.948,372.767 470.655,372.767 470.655,363.678 
 467.424,360.454 460.46,360.454 452.249,368.662 446.895,368.662 441.916,363.676 441.916,360.454 438.692,360.454 424.389,346.154 
 424.389,348.134 420.286,348.134 420.286,350.119 424.389,354.226 424.389,380.977 419.407,380.977 414.675,385.703 
 409.952,380.977 394.503,380.977 387.447,384.509 387.447,403.755 395.649,420.176 395.649,428.12 411.462,443.926 419.14,447.764 
 423.511,447.764 428.224,452.486 432.333,460.69 435.823,464.181 456.084,464.181 465.442,468.864 465.442,472.401 471.666,472.401 
 464.563,479.503 461.339,479.503 461.339,495.05 457.23,499.151 457.23,507.355 453.128,511.456 453.128,514.592 457.23,522.804 
 457.23,531.982 453.128,536.093 453.128,540.205 451.144,542.185 453.128,542.185 453.128,577.148 449.017,581.249 449.017,587.34 
 453.128,587.34 453.128,596.423 457.23,600.534 457.23,604.643 461.339,608.743 461.339,612.849 465.442,616.949 465.442,621.061 
 469.544,625.172 469.544,634.617 465.657,638.503 468.847,648.082 479.402,641.042 483.7,636.743 499.852,632.708 503.183,626.058 
 503.494,625.438 503.494,606.765 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_D"
        id="dong_4223011100"
        fill="#E1E1E1"
        points="473.655,369.767 484.948,369.767 489.193,369.767 491.182,369.767 491.182,365.662 496.161,365.662 
 500.263,361.556 522.91,361.556 522.91,365.662 529.003,365.662 533.105,361.556 538.459,361.556 543.438,366.541 543.438,370.34 
 550.503,373.873 560.965,373.873 560.965,366.541 569.177,358.332 569.177,353.347 573.28,353.347 573.28,349.24 577.381,349.24 
 577.381,345.134 581.491,345.134 581.491,331.713 577.381,331.713 577.381,322.933 569.177,318.827 569.177,311.191 
 565.066,311.191 565.066,306.206 560.965,302.101 560.965,289.786 556.241,285.059 560.965,280.332 560.965,272.123 
 565.066,268.017 565.066,266.032 562.222,266.032 549.906,261.926 537.216,261.926 533.728,258.445 530.857,261.314 
 527.022,268.992 527.022,274.244 522.032,274.244 514.699,281.575 514.699,290.665 510.598,290.665 510.598,297.995 505.616,302.98 
 498.282,302.98 498.282,305.585 496.782,305.585 495.282,305.585 495.282,302.98 490.072,302.98 490.072,304.085 490.072,307.085 
 490.072,322.934 480.712,327.612 477.756,327.612 477.756,330.834 473.655,334.94 473.655,339.05 469.544,343.155 469.544,349.24 
 478.682,349.24 473.082,360.454 471.671,360.454 473.655,362.434 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_C"
        id="dong_4223012400"
        fill="#E1E1E1"
        points="688.226,549.287 684.995,549.287 676.179,540.472 671.801,531.717 671.801,531.362 671.801,528.761 
 669.821,528.761 665.709,532.862 661.611,532.862 657.509,536.973 652.152,536.973 647.784,532.595 639.343,528.379 
 635.948,521.587 632.87,524.657 624.665,524.657 621.435,527.883 621.435,531.716 617.333,539.937 617.333,548.409 612.343,553.39 
 609.12,553.39 609.12,569.814 594.952,569.814 587.39,566.036 579.501,573.915 576.28,573.915 576.28,580.153 570.676,574.537 
 568.689,576.525 572.177,580.005 572.177,585.359 563.965,593.563 563.965,601.508 551.382,626.681 546.663,631.393 
 522.032,631.393 517.607,635.821 508.743,631.393 503.866,631.393 502.503,634.111 506.494,646.074 506.494,666.215 
 510.331,670.058 518.988,687.363 514.699,695.931 514.699,703.425 518.542,711.111 522.031,714.601 526.144,714.601 
 531.449,719.908 527.198,728.407 535.404,744.832 531.124,753.397 531.124,769.75 523.054,789.919 525.932,795.669 529.003,792.598 
 532.227,792.598 532.227,789.365 537.217,784.387 540.438,784.387 540.438,780.284 553.632,780.284 557.741,776.182 
 561.843,776.182 565.066,772.951 565.066,765.838 583.613,784.387 588.593,784.387 588.593,788.487 596.807,788.487 
 596.807,798.822 592.702,802.933 592.702,823.458 584.224,831.931 580.381,839.606 580.381,850.945 588.593,859.159 
 588.593,863.262 595.928,870.595 600.907,870.595 600.907,872.58 603.519,869.975 608.241,874.695 612.343,874.695 616.722,879.067 
 625.545,883.483 625.545,887.02 629.646,887.02 629.646,891.122 632.87,891.122 636.981,895.223 639.84,895.223 643.942,891.122 
 647.173,891.122 647.173,882.91 659.489,882.91 659.489,879.679 663.857,875.307 668.275,866.494 680.892,866.494 684.116,863.263 
 684.116,858.279 688.226,858.279 688.226,855.049 693.207,850.067 696.431,850.067 696.431,846.845 701.153,842.131 
 709.126,838.135 716.958,822.481 716.958,818.105 725.169,809.894 725.169,801.691 730.15,796.701 734.26,796.701 737.485,793.477 
 737.485,786.508 733.381,782.405 733.381,778.303 729.272,774.192 729.272,769.106 733.381,760.904 733.381,751.544 
 737.485,751.544 737.485,747.434 741.596,747.434 741.596,744.211 745.075,740.731 737.485,733.138 737.485,727.785 
 741.596,723.684 741.596,719.583 745.075,716.101 741.596,712.621 741.596,708.51 736.862,703.778 741.596,699.056 741.596,691.119 
 745.963,682.364 749.797,678.529 749.797,668.336 745.697,668.336 745.697,654.707 741.596,638.098 741.596,633.643 
 741.596,633.642 745.52,625.791 741.596,617.924 741.596,605.619 737.485,597.397 737.485,594.442 733.381,594.442 733.381,590.34 
 714.001,590.34 704.132,585.409 708.744,576.173 708.744,573.036 704.644,568.936 704.644,561.609 701.42,561.609 697.042,557.231 
 688.226,552.816 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_B"
        id="dong_4223011300"
        fill="#E1E1E1"
        points="444.916,357.454 444.916,362.435 448.139,365.662 451.007,365.662 459.218,357.454 464.417,357.454 
 468.665,357.454 471.227,357.454 473.83,352.24 466.544,352.24 466.544,341.911 470.655,337.806 470.655,333.699 470.655,333.698 
 472.705,331.646 474.756,329.592 474.756,324.612 480.003,324.612 487.072,321.079 487.072,307.085 474.756,307.085 474.756,302.98 
 459.218,302.98 455.731,299.496 451.63,303.601 437.813,289.786 437.813,277.473 433.702,273.365 433.702,269.259 431.1,266.653 
 426.99,270.759 417.286,261.047 417.286,246.611 421.389,246.611 421.389,242.51 429.601,242.51 429.601,226.963 433.97,222.594 
 437.813,214.917 437.813,210.547 442.794,205.562 446.017,205.562 446.017,199.471 442.794,196.244 430.747,196.244 
 423.185,192.467 419.083,196.569 410.22,192.143 407.973,192.143 407.973,194.744 406.473,194.744 404.973,194.744 404.973,191.264 
 401.742,188.036 396.759,188.036 396.759,183.929 392.649,183.929 392.649,178.949 389.425,175.722 385.325,175.722 
 377.111,167.509 373.011,167.509 368.9,163.404 357.827,163.404 353.727,167.509 350.494,167.509 350.494,171.615 346.393,171.615 
 346.393,174.843 342.289,178.949 342.289,183.051 338.179,187.158 338.179,191.264 333.199,196.244 312.673,196.244 
 309.449,199.471 309.449,204.457 306.267,204.457 309.449,210.813 309.449,215.893 309.449,215.894 305.665,219.673 
 308.876,226.084 312.671,226.084 316.782,230.192 334.077,230.192 334.077,239.55 337.606,246.611 344.411,246.611 344.412,246.611 
 338.179,252.841 338.179,258.926 342.289,258.926 342.289,272.123 349.615,279.453 354.605,279.453 354.605,284.438 361.931,291.77 
 375.131,291.77 375.131,295.874 379.233,295.874 379.233,300.859 383.336,304.964 383.336,308.191 387.447,308.191 387.447,312.295 
 391.547,312.295 391.547,320.506 395.649,320.506 395.649,324.612 398.88,324.612 402.985,328.713 407.973,328.713 407.973,332.819 
 420.286,332.819 420.286,336.928 424.389,336.928 424.389,341.912 439.934,357.454 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_A"
        id="dong_4223012500"
        fill="#E1E1E1"
        points="844.22,357.454 848.322,357.454 848.322,349.24 852.432,349.24 852.432,345.134 855.606,345.134 
 852.432,338.782 852.432,319.4 848.322,319.4 848.322,315.295 844.22,315.295 844.22,311.191 832.783,311.191 823.693,302.101 
 823.693,294.77 819.59,294.77 819.59,280.331 823.693,276.23 823.693,274.244 823.693,271.244 823.693,261.048 820.469,257.825 
 817.601,257.825 814.378,261.047 814.378,266.032 803.167,266.032 803.167,261.926 802.063,261.926 802.063,265.803 
 777.435,269.908 777.435,278.352 760.133,278.352 757.53,280.953 761.012,284.438 761.012,287.665 765.113,287.665 765.113,295.874 
 769.224,295.874 769.224,304.085 773.324,304.085 773.324,308.191 777.435,308.191 777.435,314.722 759.866,323.506 
 751.651,323.506 748.697,323.506 748.697,327.612 744.596,327.612 744.596,330.835 740.485,334.941 740.485,348.134 
 736.381,348.134 736.381,358.466 732.272,354.36 732.272,362.434 735.769,365.929 741.596,368.841 741.596,367.643 743.989,370.036 
 744.596,370.34 744.596,370.645 764.235,390.294 768.345,390.294 772.713,394.663 780.391,398.503 784.761,398.503 788.87,402.609 
 792.974,402.609 797.074,406.715 813.232,406.715 821.444,410.816 831.905,410.816 834.905,410.816 860.637,410.816 
 860.637,394.395 862.622,394.395 857.411,389.188 852.432,389.188 852.432,385.082 848.322,385.082 848.322,378.854 
 850.306,376.873 848.322,376.873 848.322,360.454 847.22,360.454 847.22,363.056 845.72,363.056 844.22,363.056 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223011000"
        fill="#E1E1E1"
        points="650.173,151.089 650.173,158.423 646.063,162.526 646.063,175.722 637.861,175.722 637.861,179.828 
 633.75,179.828 633.75,192.143 629.646,192.143 629.646,194.123 634.371,198.851 629.646,203.578 629.646,205.562 633.75,205.562 
 633.75,211.79 628.767,216.771 625.545,216.771 625.545,220.877 613.223,220.877 613.223,229.084 609.12,229.084 609.12,233.192 
 605.018,233.192 605.018,236.419 600.029,241.404 596.807,241.404 596.807,245.51 592.702,245.51 592.702,265.154 589.626,268.227 
 596.197,271.511 600.296,275.619 608.509,279.72 613.223,284.438 613.223,288.237 619.64,291.445 624.046,287.043 626.646,289.646 
 626.646,289.518 621.619,279.453 635.74,279.453 640.461,274.73 645.184,279.453 650.173,279.453 650.173,283.559 659.489,283.559 
 659.489,281.574 655.387,277.473 655.387,247.489 658.867,244.009 655.387,240.525 655.387,233.192 649.149,233.192 
 655.387,226.962 655.387,224.105 651.274,219.999 651.274,205.562 655.387,205.562 655.387,202.957 656.887,202.957 
 658.387,202.957 658.387,203.438 659.755,202.068 664.677,192.212 664.677,192.213 664.677,192.212 670.7,198.229 670.7,202.336 
 673.923,205.562 677.057,205.562 684.728,201.724 688.226,198.229 688.226,196.244 684.116,196.244 684.116,185.036 
 688.226,185.036 688.226,183.929 684.116,183.929 684.116,174.843 675.911,166.63 675.911,162.526 671.801,158.423 671.801,154.316 
 667.7,150.209 667.7,145.837 664.163,138.774 654.274,138.774 654.274,151.089 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223011400"
        fill="#E1E1E1"
        points="417.286,339.928 417.286,335.819 404.973,335.819 404.973,331.713 401.742,331.713 397.638,327.612 
 392.649,327.612 392.649,323.506 388.547,323.506 388.547,315.295 384.447,315.295 384.447,311.191 380.336,311.191 
 380.336,306.207 376.233,302.101 376.233,298.874 372.131,298.874 372.131,294.77 360.689,294.77 351.605,285.679 351.605,282.453 
 348.373,282.453 339.289,273.365 339.289,261.926 335.179,261.926 335.179,251.598 337.166,249.611 335.751,249.611 
 331.077,240.258 331.077,233.192 315.54,233.192 311.429,229.084 307.023,229.084 302.013,219.081 306.449,214.649 306.449,211.522 
 303.429,205.489 300.359,208.562 295.014,208.562 290.025,203.579 290.025,200.351 285.923,200.351 285.923,195.365 282.7,192.143 
 273.608,192.143 273.608,183.929 270.376,183.929 266.599,180.152 257.185,184.855 257.185,178.949 253.959,175.722 
 249.859,175.722 245.748,171.615 240.768,171.615 240.768,166.937 232.554,162.831 232.554,150.209 229.331,146.982 225.22,146.982 
 221.119,142.88 212.03,142.88 212.03,140.896 206.204,146.715 197.468,151.089 185.41,151.089 181.31,155.195 173.097,155.195 
 169.261,159.034 161.66,162.831 161.66,167.509 147.224,167.509 144.154,164.437 140.304,172.128 131.074,167.509 123.838,167.509 
 116.506,174.843 116.506,183.05 111.79,187.769 95.366,195.977 86.889,204.457 80.144,204.457 83.554,214.697 87.767,223.129 
 87.767,226.963 91.878,231.07 91.878,235.177 94.776,238.079 102.337,234.298 116.087,234.298 120.418,247.3 124.718,251.598 
 124.718,255.971 129.745,266.032 124.718,266.032 124.718,275.352 137.032,275.352 137.032,291.77 139.988,291.77 147.549,295.549 
 151.327,291.77 156.413,291.77 165.159,296.141 173.097,304.085 178.086,304.085 178.086,308.191 185.409,308.191 190.4,313.173 
 190.4,317.546 194.503,325.758 194.503,328.713 201.835,328.713 205.937,332.819 210.047,332.819 226.464,349.24 231.452,349.24 
 231.452,354.226 234.943,357.721 243.156,361.823 255.202,373.873 260.185,373.873 260.185,378.549 267.249,382.082 271.24,382.082 
 284.822,386.607 284.822,390.294 288.923,390.294 288.923,407.861 292.451,414.922 302.339,414.922 302.339,410.816 
 316.516,410.816 325.866,415.496 325.866,419.03 327.855,419.03 334.077,412.797 334.077,419.909 341.409,427.242 343.393,427.242 
 343.393,423.136 366.04,423.136 374.519,431.61 380.336,434.521 380.336,432.221 385.325,427.242 392.649,427.242 392.649,420.883 
 384.447,404.463 384.447,382.655 393.794,377.977 411.197,377.977 414.677,381.461 418.165,377.977 421.389,377.977 
 421.389,355.468 417.286,351.361 417.286,345.134 421.389,345.134 421.389,343.155 421.389,339.928 "
      />
      <path
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010600"
        fill="#E1E1E1"
        d="M860.637,229.084v-1.981l-4.106,4.101v-17.433h4.106v-1.103h-6.227l4.106-4.106h-1.986v-16.419h-3.22

l-4.989-4.985v-1.104l-1.102,1.103v0.879h-0.878l-2.122,2.125v-2.125h-4.105v-8.208h-8.21v-12.319h-8.212v-12.314h-16.416v-4.106
h-4.11v-7.107h4.11v-1.981l-4.11-4.105v-3.228h-4.104v-4.105h-7.332l-5.149-5.147l-2.089,1.044h-10.058v-4.984l-4.112-4.107v-3.227
h-4.099v-4.102h-1.99l-4.721,4.723l-8.837-8.829h-1.979v4.106h-15.315v-4.106h-1.109v4.106h-17.297l-2.609,2.603l2.61,2.605h4.98
v8.212h4.112v9.088l4.101,4.106v4.104l4.103,4.103v8.213l4.732,4.728l-8.835,8.828v4.985h-4.101v3.229l-4.991,4.984h-3.223v3.223
l-4.112,4.107v4.985h-4.978l-3.224,3.223v4.985h-4.11v5.033l-9.409-4.708l-3.517,3.513l-8.739,4.373h-5.087l-4.98-4.984v-0.001
v-4.104l-2.198-2.197l-2.187,4.381l-1.092,2.188l-3.835,3.838v0.879h-0.878l-2.122,2.123v-2.123h-1.113v10.193l4.113,4.106v5.346
l-1.988,1.985h1.988v9.091l3.491,3.494l7.676,3.834h9.355v4.108h4.105v12.313h4.1v5.251l3.842,7.68l3.491,3.49h1.983v-4.101h4.988
l4.103-4.108h7.333v-4.106h8.214v-4.106h4.979l4.103-4.106h3.231v-4.101h4.104v-6.223l7.111,7.101v7.329h4.101v9.091l4.101,4.108
v3.222h1.988l0.482-0.482l0.981,4.795l3.996,12.307l0.767,1.252v2.032l1.625,0.622l1.355,2.214l1.12,2.11v10.99h-4.101v3.533
l-3.358,1.678h1.502l15.279-7.638v-1.677h-4.112v-3.544l1.163-1.41l-7.493-12.423l-1.479-4.042l-3.155-4.673l-1.486-8.728
l1.02-1.018h15.544v-7.985l24.628-4.105v-4.336h7.104v4.106h5.212v-3.227l4.98-4.98h5.353l4.981,4.98v11.439h3.224l8.212,8.209
h6.971l4.1-4.101h4.111l7.327-7.335v-4.985h4.106v-7.328l4.982-4.985h3.233v-3.229l4.101-4.101v-14.305H860.637z"
      />
      {/* <polygon
        // onClick={mapClickEvt}
        // onMouseOver={mapOverEvt}
        // onMouseOut={mapOutEvt}
        className="land land_E"
        // id="dong_4223010600"
        fill="#E1E1E1"
        points="765.113,299.98 765.113,315.295 761.012,315.295 761.012,318.828 757.654,320.506 759.157,320.506 
 774.435,312.868 774.435,311.191 770.324,311.191 770.324,307.085 766.224,307.085 766.224,298.874 762.113,298.874 
 762.113,290.665 761.012,290.665 761.012,299.98 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010600"
        fill="#E1E1E1"
        points="677.766,208.562 672.679,208.562 667.7,203.578 667.7,203.577 667.7,199.473 665.501,197.276 
 663.314,201.657 662.222,203.845 658.387,207.683 658.387,208.562 657.509,208.562 655.387,210.685 655.387,208.562 
 654.274,208.562 654.274,218.755 658.387,222.861 658.387,228.207 656.399,230.192 658.387,230.192 658.387,239.283 
 661.878,242.777 669.554,246.611 678.911,246.611 678.911,250.719 683.015,250.719 683.015,263.032 687.116,263.032 
 687.116,268.283 690.958,275.963 694.449,279.453 696.431,279.453 696.431,275.352 701.419,275.352 705.522,271.244 
 712.855,271.244 712.855,267.138 721.069,267.138 721.069,263.032 726.047,263.032 730.15,258.926 733.381,258.926 733.381,254.825 
 737.485,254.825 737.485,248.602 744.596,255.703 744.596,263.032 748.697,263.032 748.697,272.123 752.797,276.231 
 752.797,279.453 754.787,279.453 758.891,275.352 774.435,275.352 774.435,267.367 799.063,263.262 799.063,258.926 
 806.167,258.926 806.167,263.032 811.378,263.032 811.378,259.805 816.359,254.825 821.711,254.825 826.693,259.805 
 826.693,271.244 829.916,271.244 838.128,279.453 845.099,279.453 849.2,275.352 853.31,275.352 860.637,268.017 860.637,263.032 
 864.745,263.032 864.745,255.704 869.726,250.719 872.959,250.719 872.959,247.49 877.06,243.389 877.06,229.084 860.637,229.084 
 860.637,227.103 856.532,231.204 856.532,213.771 860.637,213.771 860.637,212.668 854.412,212.668 858.517,208.562 
 856.532,208.562 856.532,192.143 853.311,192.143 848.322,187.158 848.322,186.054 847.22,187.157 847.22,188.036 846.342,188.036 
 844.22,190.161 844.22,188.036 840.116,188.036 840.116,179.828 831.905,179.828 831.905,167.509 823.693,167.509 823.693,155.195 
 807.277,155.195 807.277,151.089 803.167,151.089 803.167,143.982 807.277,143.982 807.277,142.001 803.167,137.896 
 803.167,134.668 799.063,134.668 799.063,130.563 791.731,130.563 786.583,125.416 784.494,126.46 774.435,126.46 774.435,121.476 
 770.324,117.369 770.324,114.142 766.224,114.142 766.224,110.04 764.234,110.04 759.513,114.763 750.676,105.934 748.697,105.934 
 748.697,110.04 733.381,110.04 733.381,105.934 732.272,105.934 732.272,110.04 714.975,110.04 712.367,112.643 714.976,115.248 
 719.958,115.248 719.958,123.46 724.069,123.46 724.069,132.548 728.169,136.654 728.169,140.758 732.272,144.861 732.272,153.074 
 737.004,157.802 728.169,166.63 728.169,171.615 724.069,171.615 724.069,174.844 724.069,174.844 719.079,179.828 715.855,179.828 
 715.855,183.051 711.744,187.158 711.744,192.143 706.765,192.143 703.541,195.366 703.541,200.351 699.431,200.351 
 699.431,205.384 690.022,200.676 686.505,204.189 "
      /> */}
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223012000"
        fill="#E1E1E1"
        points="729.272,363.677 729.272,347.121 733.381,351.227 733.381,345.134 737.485,345.134 737.485,333.697 
 741.596,329.591 741.596,324.612 745.697,324.612 745.697,323.506 744.941,323.506 745.697,323.128 745.697,320.506 
 747.197,320.506 750.943,320.506 758.012,316.973 758.012,312.295 762.113,312.295 762.113,302.98 758.012,302.98 758.012,290.665 
 758.012,289.786 753.908,285.68 753.908,282.453 749.797,282.453 749.797,277.472 747.196,274.866 735.502,286.559 719.079,286.559 
 710.865,294.77 699.431,294.77 699.431,298.874 695.328,298.874 695.328,316.4 695.328,320.506 699.431,320.506 699.431,330.834 
 695.328,334.94 695.328,339.928 687.116,339.928 687.116,341.033 697.45,341.033 687.739,350.74 695.949,358.955 691.226,363.677 
 691.226,372.767 687.116,372.767 687.116,380.098 683.015,384.203 683.015,392.148 678.911,400.357 678.911,409.715 
 674.801,409.715 674.801,419.908 678.911,424.014 678.911,435.449 682.136,435.449 687.116,440.434 687.116,440.434 
 687.116,458.976 678.032,458.976 669.822,467.181 661.611,467.181 645.183,483.606 641.082,483.606 633.75,490.938 633.75,497.907 
 637.861,502.009 637.861,507.356 634.075,511.133 636.773,516.526 641.579,526.143 649.562,530.13 653.396,533.973 656.265,533.973 
 660.366,529.862 664.469,529.862 668.58,525.761 672.681,525.761 675.911,522.535 675.911,518.437 680.015,514.325 680.015,505.234 
 681.996,505.234 680.015,503.252 680.015,493.808 684.116,489.697 684.116,480.606 688.226,480.606 688.226,477.382 
 697.309,468.291 700.541,468.291 700.541,464.181 708.744,464.181 708.744,460.957 715.926,453.785 708.744,450.19 708.744,440.433 
 712.855,436.327 712.855,424.015 714.838,422.03 712.855,422.03 712.855,414.922 716.958,414.922 716.958,398.503 721.069,398.503 
 721.069,393.294 716.958,393.294 716.958,386.188 721.069,386.188 721.069,382.082 729.272,382.082 729.272,378.854 734.26,373.873 
 741.596,373.873 741.596,372.194 733.993,368.395 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010500"
        fill="#E1E1E1"
        points="662.489,291.77 666.589,291.77 666.589,299.98 670.7,299.98 670.7,307.085 666.589,307.085 
 666.589,308.191 671.801,308.191 671.801,304.085 675.911,304.085 675.911,299.98 687.116,299.98 687.116,304.964 691.226,309.07 
 691.226,316.4 692.328,316.4 692.328,295.874 696.431,295.874 696.431,291.77 709.623,291.77 717.837,283.559 734.259,283.559 
 742.885,274.934 745.697,272.122 745.697,266.032 741.596,266.032 741.596,256.947 740.485,255.837 740.485,257.825 
 736.381,257.825 736.381,261.926 731.394,261.926 727.291,266.032 724.069,266.032 724.069,270.138 715.855,270.138 
 715.855,274.244 706.766,274.244 702.663,278.352 699.431,278.352 699.431,282.453 693.207,282.453 688.493,277.74 684.116,268.992 
 684.116,266.032 680.015,266.032 680.015,253.719 675.911,253.719 675.911,249.611 668.847,249.611 661.284,245.834 
 658.387,248.732 658.387,276.23 662.489,280.331 662.489,283.559 662.489,286.559 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223012100"
        fill="#E1E1E1"
        points="812.525,409.715 795.83,409.715 791.73,405.609 787.628,405.609 783.519,401.503 779.682,401.503 
 770.936,397.127 767.103,393.294 762.99,393.294 744.596,374.892 744.596,376.873 735.502,376.873 732.272,380.099 732.272,385.082 
 724.069,385.082 724.069,389.188 719.958,389.188 719.958,390.294 724.069,390.294 724.069,401.503 719.958,401.503 
 719.958,417.922 715.855,417.922 715.855,419.03 722.078,419.03 715.855,425.257 715.855,437.57 711.744,441.676 711.744,448.337 
 720.99,452.964 711.744,462.201 711.744,467.181 703.541,467.181 703.541,471.291 698.553,471.291 691.226,478.624 691.226,483.606 
 687.116,483.606 687.116,490.938 683.015,495.048 683.015,502.01 689.237,508.234 683.015,508.234 683.015,515.565 678.911,519.677 
 678.911,523.779 674.801,527.883 674.801,531.008 678.644,538.694 686.238,546.287 691.226,546.287 691.226,550.963 
 697.635,554.174 709.623,542.185 722.214,542.185 729.883,538.351 733.648,534.584 737.485,526.907 737.485,522.535 
 742.207,517.824 750.943,513.445 754.481,513.445 758.012,506.381 758.012,497.029 762.992,497.029 767.104,492.928 
 771.203,492.928 787.628,476.503 794.952,476.503 794.952,474.983 794.952,471.821 794.952,465.059 810.277,449.756 
 810.277,455.976 811.378,455.976 811.378,451.875 815.48,451.875 815.48,448.642 819.59,444.537 819.59,435.449 823.693,435.449 
 823.693,427.242 827.795,427.242 827.795,423.136 831.905,423.136 831.905,413.816 820.737,413.816 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010800"
        fill="#E1E1E1"
        points="634.861,99.708 655.387,79.179 655.387,74.771 664.735,70.093 675.911,70.093 675.911,65.987 
 680.015,65.987 680.015,61.88 684.996,61.88 688.226,58.657 688.226,54.551 696.431,46.339 696.431,44.357 684.116,44.357 
 684.116,40.252 680.894,40.252 676.79,36.145 671.801,36.145 671.801,32.039 665.579,32.039 669.681,27.938 665.583,27.938 
 669.683,23.831 651.274,23.831 651.274,19.725 650.173,19.725 650.173,22.953 646.063,27.059 646.063,32.039 641.961,32.039 
 641.961,36.145 633.75,36.145 633.75,40.252 629.646,40.252 629.646,51.419 624.973,60.779 621.435,60.779 621.435,61.88 
 625.545,61.88 625.545,68.987 621.435,68.987 621.435,72.214 612.955,80.69 609.12,88.366 609.12,92.474 605.018,100.682 
 605.018,102.934 609.12,102.934 609.12,107.04 627.792,107.04 634.861,103.506 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223011200"
        fill="#E1E1E1"
        points="432.601,241.404 432.601,245.51 424.389,245.51 424.389,249.611 420.286,249.611 420.286,259.805 
 426.992,266.517 431.102,262.411 436.702,268.017 436.702,272.122 439.934,275.352 443.416,275.352 443.416,276.852 
 443.416,278.352 440.813,278.352 440.813,288.543 451.626,299.359 455.729,295.252 460.46,299.98 477.756,299.98 477.756,304.085 
 487.072,304.085 487.072,299.98 504.372,299.98 507.598,296.753 507.598,287.665 511.699,287.665 511.699,280.331 511.699,280.33 
 520.788,271.244 524.022,271.244 524.022,268.284 528.392,259.538 531.606,256.324 528.124,252.84 528.124,245.51 525.765,245.51 
 513.443,249.611 510.598,249.611 510.598,257.825 504.64,257.825 495.282,253.146 495.282,243.389 499.384,239.283 499.384,236.601 
 487.072,228.387 487.072,228.387 486.216,227.816 487.072,226.962 487.072,224.983 485.087,224.983 480.978,229.084 
 475.329,229.084 471.036,220.495 463.587,216.771 458.339,216.771 458.339,202.472 452.249,208.562 449.017,208.562 
 444.038,208.562 440.813,211.789 440.813,215.626 436.435,224.372 432.601,228.206 432.601,238.404 435.202,238.404 
 435.202,239.904 435.202,241.404 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223011800"
        fill="#E1E1E1"
        points="592.091,361.823 599.762,365.662 603.761,365.662 615.718,369.647 623.691,365.662 627.525,365.662 
 643.063,350.12 643.063,346.28 647.747,336.928 649.154,336.928 643.063,330.834 643.063,327.612 641.961,327.612 641.961,331.713 
 636.982,331.713 630.75,337.938 630.75,331.713 626.646,331.713 626.646,327.612 622.545,327.612 622.545,317.28 624.528,315.295 
 624.045,315.295 624.045,313.795 624.045,312.295 626.646,312.295 626.646,293.891 624.044,291.286 620.23,295.095 610.223,290.092 
 610.223,285.68 606.732,282.186 598.519,278.084 594.417,273.976 587.093,270.315 582.839,272.442 578.585,274.569 578.584,274.569 
 570.056,266.032 568.066,266.032 568.066,269.258 563.965,273.364 563.965,281.574 560.484,285.059 563.965,288.543 
 563.965,300.859 568.066,304.965 568.066,308.191 572.177,308.191 572.177,316.974 580.381,321.08 580.381,328.713 584.491,328.713 
 584.491,348.134 580.381,348.134 580.381,352.24 576.28,352.24 576.28,356.347 572.177,356.347 572.177,357.454 587.714,357.454 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010200"
        fill="#E1E1E1"
        points="631.764,312.295 629.646,314.416 629.646,315.295 628.768,315.295 625.545,318.521 625.545,324.612 
 629.646,324.612 629.646,328.713 633.75,328.713 633.75,330.701 635.74,328.713 635.74,328.713 638.961,328.713 638.961,324.612 
 646.063,324.612 646.063,329.592 652.775,336.307 656.483,332.603 669.445,336.928 669.681,336.928 663.263,330.51 667.7,321.652 
 667.7,314.276 671.801,318.382 671.801,316.4 673.786,316.4 671.801,314.417 671.801,311.191 663.589,311.191 663.589,304.085 
 667.7,304.085 667.7,302.98 663.589,302.98 663.589,294.77 659.489,294.77 659.489,286.559 647.173,286.559 647.173,282.453 
 643.942,282.453 640.461,278.973 636.982,282.453 626.471,282.453 629.646,288.811 629.646,292.649 629.646,296.895 
 629.646,312.295 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223011900"
        fill="#E1E1E1"
        points="672.89,345.063 671.801,346.151 671.801,342.888 671.475,342.237 671.801,341.911 671.801,339.928 
 668.956,339.928 657.291,336.036 654.896,338.429 656.394,339.928 653.395,339.928 652.773,340.549 652.152,339.928 
 649.599,339.928 646.063,346.989 646.063,351.361 628.767,368.662 624.4,368.662 617.333,372.194 617.333,380.099 612.343,385.082 
 609.12,385.082 609.12,388.309 605.639,391.793 609.12,395.273 609.12,402.609 615.343,402.609 611.24,406.715 613.223,406.715 
 613.223,415.801 617.333,419.908 617.333,423.136 621.435,423.136 621.435,436.594 625.279,444.27 629.646,448.643 629.646,448.643 
 629.646,453.618 625.761,465.277 629.035,468.559 637.479,472.783 641.389,480.606 643.943,480.606 660.366,464.181 
 668.579,464.181 676.79,455.976 684.116,455.976 684.116,441.675 680.894,438.449 675.911,438.449 675.911,425.257 671.801,421.151 
 671.801,406.715 675.911,406.715 675.911,399.649 680.015,391.44 680.015,382.961 684.116,378.855 684.116,369.767 688.226,369.767 
 688.226,362.434 691.707,358.953 684.994,352.24 676.486,352.24 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010700"
        fill="#E1E1E1"
        points="641.961,111.142 641.961,116.127 646.063,120.234 646.063,124.338 650.173,128.44 650.173,135.774 
 651.274,135.774 654.274,135.774 666.015,135.774 670.7,145.128 670.7,148.968 674.801,153.075 674.801,157.179 678.911,161.282 
 678.911,165.388 687.116,173.602 687.116,180.929 691.226,180.929 691.226,188.036 687.116,188.036 687.116,193.244 
 691.226,193.244 691.226,197.924 696.431,200.529 696.431,197.351 700.541,197.351 700.541,194.123 705.523,189.143 
 708.744,189.143 708.744,185.915 712.855,181.808 712.855,176.828 717.837,176.828 721.069,173.6 721.069,168.615 725.169,168.615 
 725.169,165.388 732.758,157.801 729.272,154.317 729.272,146.104 725.169,142.001 725.169,137.895 721.069,133.789 721.069,126.46 
 716.958,126.46 716.958,118.248 713.734,118.248 708.121,112.642 713.735,107.04 729.272,107.04 729.272,102.934 736.381,102.934 
 736.381,107.04 741.596,107.04 741.596,104.434 743.096,104.434 744.596,104.434 744.596,107.04 745.697,107.04 745.697,105.054 
 737.485,96.842 737.485,93.62 734.26,93.62 730.151,89.512 725.169,89.512 725.169,84.527 721.947,81.301 716.958,81.301 
 716.958,76.32 708.744,68.108 708.744,64.001 705.523,60.779 700.541,60.779 700.541,45.46 706.624,45.46 705.523,44.357 
 702.041,44.357 699.431,44.357 699.431,47.582 691.226,55.793 691.226,59.901 686.237,64.88 683.015,64.88 683.015,68.987 
 678.911,68.987 678.911,73.093 665.444,73.093 658.387,76.625 658.387,80.423 637.861,100.949 637.861,105.361 633.415,107.583 
 636.982,111.142 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010100"
        fill="#E1E1E1"
        points="681.036,319.4 674.801,319.4 674.801,325.631 670.7,321.525 670.7,322.361 666.915,329.917 
 673.922,336.928 674.801,336.928 674.801,337.807 674.801,337.809 675.354,338.363 676.79,336.928 680.015,336.928 680.015,332.819 
 684.116,332.819 684.116,331.713 681.99,331.713 684.116,329.591 684.116,328.713 684.996,328.713 688.226,325.49 688.226,319.4 
 688.226,316.4 688.226,310.313 684.116,306.207 684.116,302.98 678.911,302.98 678.911,307.085 674.801,307.085 674.801,308.191 
 674.801,311.191 674.801,313.173 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223012600"
        fill="#E1E1E1"
        points="822.59,281.574 822.59,291.77 826.693,291.77 826.693,300.859 834.026,308.191 847.22,308.191 
 847.22,312.295 851.322,312.295 851.322,316.4 855.432,316.4 855.432,338.074 860.459,348.134 855.432,348.134 855.432,352.24 
 851.322,352.24 851.322,365.662 853.932,365.662 853.932,367.162 853.932,368.662 851.322,368.662 851.322,373.873 857.558,373.873 
 851.322,380.099 851.322,382.082 855.432,382.082 855.432,386.188 858.654,386.188 869.868,397.395 863.637,397.395 
 863.637,410.236 869.646,408.234 885.272,403.028 885.272,390.294 897.587,390.294 897.587,389.188 877.06,389.188 877.06,361.556 
 889.375,361.556 889.375,356.347 878.205,356.347 871.848,353.167 871.848,360.454 864.745,360.454 864.745,353.347 
 868.848,353.347 868.848,345.134 887.394,345.134 894.977,352.726 899.088,348.619 904.688,354.226 904.688,358.71 908.609,370.457 
 912.636,374.485 914.003,377.223 914.003,367.782 909.903,363.676 909.903,346.819 905.798,330.213 905.798,326.734 
 901.688,322.628 901.688,318.521 898.465,315.295 898.16,315.295 897.855,314.684 895.469,312.295 896.658,312.295 893.476,305.94 
 893.476,302.98 889.375,302.98 889.375,298.874 885.272,298.874 885.272,290.665 877.06,290.665 877.06,280.331 880.541,276.851 
 877.06,273.364 877.06,247.631 875.959,248.732 875.959,253.719 870.97,253.719 867.745,256.946 867.745,266.032 863.637,266.032 
 863.637,269.259 854.554,278.352 850.443,278.352 846.341,282.453 836.886,282.453 828.674,274.244 826.693,274.244 
 826.693,277.473 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223012300"
        fill="#E1E1E1"
        points="788.87,479.503 772.446,495.928 768.344,495.928 764.233,500.029 761.012,500.029 761.012,507.088 
 756.335,516.445 751.652,516.445 743.985,520.289 740.485,523.779 740.485,527.614 736.115,536.361 731.66,540.815 722.923,545.185 
 710.865,545.185 700.052,555.999 702.662,558.609 707.644,558.609 707.644,567.693 711.744,571.794 711.744,576.88 708.156,584.065 
 714.708,587.34 736.381,587.34 736.381,591.442 740.485,591.442 740.485,596.688 744.596,604.91 744.596,617.217 748.873,625.793 
 744.596,634.351 744.596,637.913 748.697,654.522 748.697,665.336 752.797,665.336 752.797,671.433 755.114,669.12 762.943,673.037 
 766.224,666.481 766.224,661.226 783.52,661.226 799.943,644.817 803.167,644.817 803.167,637.17 811.99,632.764 815.48,629.271 
 815.48,624.292 819.59,624.292 819.59,620.181 832.782,620.181 836.007,616.95 836.007,614.091 831.283,609.364 836.618,604.032 
 845.365,599.654 861.515,599.654 864.745,596.422 864.745,583.237 868.848,583.237 868.848,580.005 885.272,563.589 
 885.272,558.609 889.375,558.609 889.375,551.536 897.262,535.769 893.476,531.983 893.476,519.677 889.375,515.565 
 889.375,511.457 885.539,507.624 881.161,498.884 881.161,495.928 866.865,495.928 858.654,504.131 853.312,504.131 
 849.201,500.029 844.792,500.029 840.498,491.435 833.05,487.707 820.47,487.707 812.257,479.503 808.533,479.503 797.952,475.981 
 797.952,479.503 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010900"
        fill="#E1E1E1"
        points="550.394,258.926 562.708,263.032 565.066,263.032 568.066,263.032 571.298,263.032 579.178,270.918 
 583.136,268.939 586.205,267.404 589.702,263.911 589.702,242.51 593.807,242.51 593.807,238.404 598.786,238.404 602.018,235.176 
 602.018,230.192 606.12,230.192 606.12,226.084 610.223,226.084 610.223,217.877 622.545,217.877 622.545,213.771 627.525,213.771 
 630.75,210.546 630.75,208.562 626.646,208.562 626.646,202.335 630.129,198.851 626.646,195.365 626.646,189.143 630.75,189.143 
 630.75,176.828 634.861,176.828 634.861,172.722 643.063,172.722 643.063,161.282 647.173,157.179 647.173,148.089 651.274,148.089 
 651.274,138.774 647.173,138.774 647.173,129.685 643.063,125.583 643.063,121.475 638.961,117.368 638.961,114.142 635.74,114.142 
 631.63,110.04 628.499,110.04 621.79,110.04 606.12,110.04 606.12,105.934 605.018,105.934 605.018,109.162 600.029,114.142 
 592.702,114.142 592.702,124.474 586.472,118.248 578.259,118.248 572.177,112.161 572.177,116.258 565.945,110.04 548.65,110.04 
 548.65,105.934 543.438,105.934 543.438,112.164 539.337,108.058 539.337,110.04 535.227,110.04 535.227,114.142 531.124,114.142 
 531.124,122.354 522.91,122.354 522.91,126.46 518.81,126.46 518.81,129.417 514.699,137.629 514.699,146.103 511.218,149.589 
 514.699,153.075 514.699,167.509 510.598,167.509 510.598,174.576 502.001,191.761 494.182,195.671 494.182,198.23 498.282,202.336 
 498.282,207.416 494.182,215.625 494.182,224.105 490.927,227.353 502.384,234.995 502.384,240.525 498.282,244.631 
 498.282,251.292 505.349,254.825 507.598,254.825 507.598,246.611 512.956,246.611 525.279,242.51 531.124,242.51 531.124,251.598 
 538.458,258.926 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223011600"
        fill="#E1E1E1"
        points="560.965,378.855 560.965,376.873 549.796,376.873 540.438,372.194 540.438,367.782 537.215,364.556 
 534.349,364.556 530.245,368.662 519.91,368.662 519.91,364.556 501.505,364.556 497.404,368.662 494.182,368.662 494.182,372.767 
 492.196,372.767 498.282,378.855 498.282,381.155 504.64,377.977 513.82,377.977 527.022,391.172 527.022,391.173 527.022,395.274 
 530.246,398.503 545.421,398.503 556.863,387.066 556.863,382.961 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223012700"
        fill="#E1E1E1"
        points="606.12,507.355 606.12,504.131 602.018,504.131 602.018,495.928 597.907,495.928 597.907,470.411 
 594.073,466.568 589.702,457.831 589.702,453.996 578.261,442.555 576.28,442.555 576.28,450.764 572.177,450.764 572.177,454.875 
 567.187,454.875 555.753,466.303 555.753,470.412 550.772,475.401 546.663,475.401 543.438,478.625 543.438,486.827 
 539.337,490.938 539.337,503.253 534.347,508.234 530.427,508.234 522.91,519.511 522.91,529.862 527.022,529.862 527.022,538.083 
 533.246,538.083 526.143,545.185 522.91,545.185 522.91,548.408 518.81,552.511 518.81,555.646 523.236,564.507 518.81,568.936 
 518.81,573.036 510.598,581.248 510.598,590.34 506.494,590.34 506.494,597.397 503.311,603.765 506.494,603.765 506.494,619.449 
 506.494,626.146 506.494,628.393 509.452,628.393 517.013,632.171 520.788,628.393 545.421,628.393 548.917,624.903 
 560.965,600.801 560.965,592.321 569.177,584.115 569.177,581.249 564.443,576.527 570.678,570.293 573.28,572.899 573.28,570.915 
 578.261,570.915 586.796,562.386 595.661,566.814 606.12,566.814 606.12,550.39 611.103,550.39 614.333,547.165 614.333,539.229 
 618.435,531.009 618.435,527.615 610.49,511.724 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223012200"
        fill="#E1E1E1"
        points="862.38,413.816 860.637,414.397 860.637,413.816 834.905,413.816 834.905,426.136 830.795,426.136 
 830.795,430.242 826.693,430.242 826.693,438.449 822.59,438.449 822.59,445.781 818.48,449.886 818.48,454.875 814.378,454.875 
 814.378,458.976 807.277,458.976 807.277,456.993 797.952,466.303 797.952,472.819 809.02,476.503 813.499,476.503 821.71,484.707 
 833.759,484.707 842.735,489.2 846.648,497.029 850.442,497.029 854.552,501.131 857.411,501.131 865.625,492.928 884.161,492.928 
 884.161,498.175 888.004,505.845 892.375,510.213 892.375,514.325 896.476,518.437 896.476,530.741 900.912,535.177 
 892.375,552.243 892.375,561.609 888.272,561.609 888.272,564.833 878.073,575.026 883.281,575.026 887.392,579.127 
 891.497,579.127 895.598,583.237 897.587,583.237 897.587,562.711 901.688,562.711 901.688,558.609 905.798,558.609 
 905.798,554.499 910.781,554.499 914.003,551.27 914.003,546.287 918.992,546.287 922.215,543.063 922.215,538.083 924.201,538.083 
 922.215,536.093 922.215,525.761 927.198,525.761 931.857,521.107 926.319,515.566 926.319,507.355 922.215,503.252 
 922.215,483.606 914.881,483.606 910.781,479.503 901.688,479.503 901.688,475.401 897.587,475.401 897.587,469.171 
 901.688,465.061 901.688,463.079 897.587,463.079 897.587,451.875 902.567,451.875 905.798,448.643 905.798,446.659 
 901.688,446.659 901.688,441.675 896.967,436.949 901.364,432.547 897.855,425.524 893.476,421.152 893.476,413.244 
 885.272,409.142 885.272,406.19 868.568,411.755 863.637,413.397 863.637,413.816 "
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010300"
        class="land land_E"
        fill="#E1E1E1"
        points="684.027,335.819 683.015,335.819 683.015,336.417 681.5,336.417 681.5,339.928 678.031,339.928 
		675.127,342.83 678.337,349.24 684.993,349.24 690.204,344.033 686.416,344.033 686.416,338.363 692.326,338.363 692.326,337.319 
		684.027,337.319 	"
      />
      <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010400"
        fill="#E1E1E1"
        points="692.326,323.506 692.326,322.006 692.326,319.4 691.226,319.4 691.226,326.734 689.241,328.713 
		690.691,328.713 688.226,332.061 688.226,331.713 685.78,331.713 685.78,334.808 691.938,334.808 693.521,332.504 696.431,329.592 
		696.431,323.506 	"
      />
      {/* <polygon
        onClick={mapClickEvt}
        onMouseOver={mapOverEvt}
        onMouseOut={mapOutEvt}
        className="land land_E"
        id="dong_4223010300"
        fill="#E1E1E1"
        points="696.431,329.592 696.431,323.506 692.328,323.506 692.328,322.006 692.328,319.4 691.226,319.4 
691.226,326.734 689.243,328.713 690.691,328.713 688.226,332.061 688.226,331.713 685.78,331.713 685.78,335.819 683.015,335.819 
683.015,336.417 681.501,336.417 681.501,339.928 678.032,339.928 675.127,342.83 678.337,349.24 684.995,349.24 690.206,344.033 
686.417,344.033 686.417,338.363 692.328,338.363 692.328,335.819 691.244,335.819 693.521,332.504 "
      /> */}
      <text
        class="geoText"
        transform="matrix(1 0 0 1 240.8875 296.0732)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        도경동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 359.3621 245.8345)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        등봉동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 542.6995 188.4258)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        우지동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 448.0344 266.0322)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        평전동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 503.3103 328.9336)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        자원동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 555.1428 424.2686)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        건지동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 448.0344 431.0107)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        마평동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 605.6389 708.4043)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        조비동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 542.6995 533.9727)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        근산동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 780.5745 567.2939)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        적노동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 831.9045 464.5327)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        오분동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 780.5745 349.2402)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        정상동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 858.5471 326.1099)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="12">
        청하동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 642.6741 503.873)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="12">
        남양동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 658.387 122.4429)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        갈천동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 637.635 64.3823)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="12">
        증산동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 609.8674 257.8247)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="12">
        마달동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 583.803 320.6714)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="12">
        원당동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 630.6995 307.9502)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="12">
        성북동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 664.0198 290.6646)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="12">
        당저동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 665.2952 320.2441)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="10">
        성내동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 515.0007 381.5293)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="12">
        오사동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 738.4202 442.5918)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        사직동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 753.3518 210.2705)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        교동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 625.5452 408.3213)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="16">
        성남동
      </text>
      <text
        class="geoText"
        transform="matrix(1 0 0 1 671.7756 355.416)"
        fill="#050505"
        font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
        font-size="10">
        읍상동
      </text>
    </svg>
  );
};

export default GeoSamcheokTwo;
