import GathStatusDay from "./gathStatusDay";
import GathStatusMonth from "./gathStatusMonth";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useState, memo } from "react";
import "./gathStatus.css";
import { Button } from "@progress/kendo-react-buttons";
import { Search } from "@material-ui/icons";
import { yearList } from "../../../com/dropDownDs";
import kendo from "@progress/kendo-ui";

import { getGathStatusDay, getGathStatusMonth } from "../../../service/analy/gathStatusService";


const GathStatus = memo(({ conn, codeList }) => {
  // 데이터 설정
  const ensoList = codeList.filter(c => c.grpCodeNo === 15);
  const [search , setSearch] = useState({
    ensoTypeCode : 15001 , 
    bizYy : '' ,
    isSearch : false ,
    day : new Date() ,
    dayLoading : false, 
    monthLoading : false
  });

  // 일별 데이터 소스
  const [dayDataSource, setDayDataSource] = useState({
    grid: [],
    chart: [],
    x_categories: [],
    excelData: null,
  });

  // 월별 데이터 소스
  const [monthDataSource, setMonthDataSource] = useState({
    grid: [],
    chart: [],
    x_categories: [],
    excelData: null,
  });

  // 드롭다운 체인지 이벤트
  const onchange = e => {
    if( e.target.name === "ensoTypeDropDown" ){
      setSearch( search => {
        const item = {...search};
        item.ensoTypeCode = e.target.value.codeNo;
        return item;
      } );
    }else if ( e.target.name === "bizYy" ){
      setSearch( search => {
        const item = {...search};
        item.bizYy = e.target.value;
        return item;
      } );
    }
  };

  // Validation 설정
  const checkValid = () => {
    let isValid = true;
    let message = "";
    if( !search.bizYy ){
      isValid = false;
      message = "사업연도를 선택 해주세요.";
    }else if( search.bizYy === "사업연도" ){
      isValid = false;
      message = "사업연도를 선택 해주세요.";
    }
    if( !search.ensoTypeCode ){
      isValid = false;
      message = "에너지원을 선택 해주세요.";
    }
    return { isValid : isValid , message : message }
  }

  
  // 조회버튼 클릭 이벤트
  const setSearchClickEvt = e => {
    const valid = checkValid();
    if( !valid.isValid ){
      kendo.alert(valid.message);
      return;
    }
    getDayDs();
    getMonthDs();
  };


  // 일단위 조회 로직
  const getDayDs = () => {
      const valid = checkValid();
      if( !valid.isValid ){
        kendo.alert(valid.message);
        return;
      }
      setSearch(search => {
        const item = {...search};
        item.dayLoading = true;
        return item;
      });
      getGathStatusDay(conn, getDayResult, getRejection, search.ensoTypeCode, search.day, search.bizYy , setSearch);
  };

  // 결과 데이터
  const getDayResult = result => {
    // x카테고리 작성
    const x_categories = [];
    for (let i = 0; i < 24; i++) {
      x_categories.push(i);
    }
    // 차트 데이터 생성
    let chart_data = [];
    let col = 0;
    let row = 0;
    for (var i = 0; i < 24 * 4; i++) {
      chart_data.push({
        x: col,
        y: row,
        value: null,
      });
      col += 1;
      if (col >= 24) {
        col = 0;
        row += 1;
      }
    }
    // 차트 데이터 가공
    result.forEach(element => {
      const date = kendo.toString(new Date(element.gathDtm), "yyyy-MM-dd HH:mm:ss");
      const strArr = date.split(" ");
      const timeArr = strArr[1].split(":");
      const hh = timeArr[0] * 1;
      const mm = timeArr[1] * 1;
      const idx = hh + (mm / 15) * 24;
      chart_data[idx].value = element.gathRate;
    });
    // 시간 값 변경
    result.forEach(element => {
      element.gathDtm = kendo.toString(new Date(element.gathDtm), "yyyy년 MM월 dd일 HH시 mm분");
    });

    setDayDataSource(dataSource => {
      const item = { ...dataSource };
      item.grid = result;
      item.chart = chart_data;
      item.x_categories = x_categories;
      return item;
    });
  };


  // 월단위 조회 로직 
  // 데이터 불러오기
  const getMonthDs = () => {
      const valid = checkValid();
      if( !valid.isValid ){
        kendo.alert(valid.message);
        return;
      }
      setSearch(search => {
        const item = {...search};
        item.monthLoading = true;
        return item;
      });
      getGathStatusMonth(conn, getMonthResult, getRejection, search.ensoTypeCode, search.day, search.bizYy , setSearch);
  };

  // 결과 데이터
  const getMonthResult = result => {
    // 년도 , 월 구하기
    const year = kendo.toString(search.day, "yyyy");
    const month = kendo.toString(search.day, "MM");
    // 해당 월의 마지막 일자 구하기
    const dayMonth = new Date(year, month, 0).getDate();
    // 차트 변수 정의
    let chart_data = [];
    let col = 0;
    let row = 0;
    let x_categories = [];
    // 카테고리 가공
    for (let i = 1; i <= dayMonth; i++) {
      x_categories.push(i);
    }
    // 차트 데이터 초기화
    for (var i = 0; i < dayMonth * 4; i++) {
      chart_data.push({
        x: col,
        y: row,
        value: null,
      });
      col += 1;
      if (col >= dayMonth) {
        col = 0;
        row += 1;
      }
    }
    // 차트 데이터 가공
    result.forEach(element => {
      let date = kendo.toString(new Date(element.gathDtm), "yyyy-MM-dd HH:mm:ss");
      let strArr = date.split(" ");
      let dayArr = strArr[0].split("-");
      let timeArr = strArr[1].split(":");
      let HH = (timeArr[0] * 1) / 6;
      let DD = dayArr[2] * 1 - 1;
      let idx = DD + HH * dayMonth;
      chart_data[idx].value = element.gathRate;
      // 시간 값 변경
      element.gathDtm =
        dayArr[0] +
        "년 " +
        dayArr[1] +
        "월 " +
        dayArr[2] +
        "일 " +
        (timeArr[0] * 1 === 0 || timeArr[0] * 1 === 6 ? timeArr[0] : timeArr[0]) +
        "시";
    });

    // 데이터 소스 세팅
    setMonthDataSource(dataSource => {
      const item = { ...dataSource };
      item.grid = result;
      item.chart = chart_data;
      item.x_categories = x_categories;
      return item;
    });
  };

  const getRejection = error => {
  };

  return (
    <div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="md-card TBgA">
            <div className="md-card-content small-padding">
              <span className="searchSubTitleA">에너지원</span>
              <DropDownList
                data={ensoList}
                defaultValue={{ codeVal: "태양광", codeNo: 15001 }}
                name="ensoTypeDropDown"
                textField="codeVal"
                dataItemKey="codeNo"
                onChange={onchange}
                className="searchArea"
              />
              <DropDownList
                data={yearList()}
                defaultItem = "사업연도"
                name="bizYy"
                onChange={onchange}
                className="searchArea"
              />
              <span>
                <Button
                  className="searchBtn"
                  onClick={setSearchClickEvt}
                ><Search className="material-icons" /></Button>
                &nbsp;&nbsp;
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-grid">
        <GathStatusDay conn={conn} getDayDs = {getDayDs} dayDataSource = {dayDataSource} setDayDataSource = {setDayDataSource} search = {search} />
        <GathStatusMonth conn={conn} getMonthDs = {getMonthDs} monthDataSource = {monthDataSource} setMonthDataSource = {setMonthDataSource} search = {search} />
      </div>
    </div>
  );
});

export default GathStatus;
