// 인버터 효율 15001

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../../com/dataUtil";

// 시간별 차트
export const ReportDayIntegratedChart = ({ timeChart, height, width, clsName }) => {
  // 차트 옵션
  const chartConfig = {
    chart: { type: "column", zoomType: "xy", width: width !== null ? width : "100%", height: height !== null ? height : null },
    title: { text: null },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        title: {
          text: null,
        },
        stackLables: {
          enabled: true,
        },
      },
    ],
    credits: { enabled: false },
    tooltip: { 
      shared: true,
      formatter: function() {
        return(
          `
            <span style="color:${this.points[0].point.color}">\u25CF</span>
            <span>${this.points[0].series.name}</span> : <b>${numFormat(this.points[0].y)} kWh</b><br>
            <span style="color:${this.points[1].point.color}">\u25CF</span>
            <span>${this.points[1].series.name}</span> : <b>${numFormat(this.points[1].y)} kWh</b><br>
            <span style="color:${this.points[2].point.color}">\u25CF</span>
            <span>${this.points[2].series.name}</span> : <b>${numFormat(this.points[2].y)} kWh</b>
          `
        );
      }
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      floating: false,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: { 
          enabled: true ,
          color : '#000000',
          style: { textShadow: false },
          formatter: function() { 
            return `<span><b>${numFormat(roundNum(this.y , 0))}</b></span>`
          }
        }
      },
    },
    series: [
      {
        name: "발전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#69bcc8",
        data: timeChart.chartTimeData.power,
      },
      {
        name: "생산열량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#8fff94",
        data: timeChart.chartTimeData.cal,
      },
      {
        name: "ESS충전량",
        tooltip: {
          valueSuffix: " kWh",
        },
        color: "#ff6f6f",
        data: timeChart.chartTimeData.ess,
      },
    ],
  };

  return (
    <div className={clsName !== null ? clsName : "reportDayBackground"} style={{ marginBottom: "10px" }}>
      <HighchartsReact Highcharts={Highcharts} options={chartConfig}></HighchartsReact>
    </div>
  );
};

export default ReportDayIntegratedChart;
