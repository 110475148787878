import { useCallback, useEffect, useState } from "react";
import { getDbInfo, getStatsByConsm } from "../../../../service/stats/type/statsByConsmService";
import { useHistory } from "react-router";
import { getMeainTypeTermList } from "../../../../service/meainModelService";
import { getMakrTermList } from "../../../../service/makrService";
import { getDongs } from "../../../../service/areaService";
import { downloadExcel } from "../../../../com/downloadExcel";
import { parseDate } from "../../../../com/dateUtil";

import log from "../../../../com/log";
//kendo
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import kendo from "@progress/kendo-ui";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

// npm-install
import { BeatLoader } from "react-spinners";
import calculateSize from "calculate-size";

//css
import styles from "./statsByConsm.module.css";
// import styles from "./statsByConsm.dk.module.css";

const StatsByConsm = ({ conn, codeList, cityList, rgnList }) => {
  const EXCEL_LIMIT = 100000;
  const [gridTitle, setGridTitle] = useState("발전량");
  const [loading, setLoading] = useState(false);
  //에너지원별 DB 컬럼명 - 멀티셀렉트에서 사용
  const [dbInfo, setDbInfo] = useState([]);
  const history = useHistory();
  //그리드 데이터
  const [dataSource, setDataSource] = useState({
    total: 0,
    dataList: [],
  });
  //설비업체 드롭다운 리스트 데이터
  const [makrTermList, setMakrTermList] = useState({
    origin: [],
    filtered: [],
  });
  //설비모델 드롭다운 리스트 데이터
  const [meainModelList, setMeainModelList] = useState({
    origin: [],
    filtered: [],
  });
  //에너지원 DDL data
  const [ensoTypeCode, setEnsoTypeCode] = useState([]);
  const [cityDdl, setCityDdl] = useState({ cityProvCode: "", cityProvTerm: "시/도" });
  //시군구 DDL date <- 시/도 선택 시 선택필터링
  const [rgnData, setRgnData] = useState([]);
  //시군구 DDL value <- 시/도 선택 시 필터해제 위해서 만듬
  const [rgnDdl, setRgnDdl] = useState({ rgnTermSimple: "시/군/구", rgnCode: "" });
  //읍면동 DDL data <- 시군구 선택 시 선택필터링 해야함
  const [dongData, setDongData] = useState([]);
  //읍면동 DDL value <- 시군구 선택 시 필터해제 위해서 만듬
  const [dongDdl, setDongDdl] = useState({ fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" });
  //필터링
  
  const [makrDdl, setMakrDdl] = useState({ makrSeriNo: 0, makrTerm: "설비 업체" });
  const [meainTypeDdl, setMeainTypeDdl] = useState({ meainTypeSeriNo: 0, meainTypeTerm: "설비 모델" });

  const [dataState, setDataState] = useState({
    take: 20,
    skip: 0,
    sort: [],
    sortStatus: [],
    filter: {
      ensoType: { codeNo: 15001, codeVal: "태양광" }, //에너지원
      userTerm: "", //설치자명
      cid: "", //cid
      startDtm: new Date(), //시작일자
      endDtm: new Date(), //종료일자
      cityProvCode: "", //시도
      rgnCode: "", //시군구
      fixbylawBundCode: "", //읍면동
      alias: "", //설비명
      makr: "",
      meainModel: "", //설비모델
    },
  });
  var citys = cityList;

  //DatePicker 관련 데이터
  const [calendarState, setCalendarState] = useState({
    min: new Date(),
    max: new Date(),
  });
  let [multiSelectValue, setMultiSelectValue] = useState([]);
  useEffect(() => {
    // getGridData();
    getMeainTypeTermList(conn)
      .then(response => {
        //설비 모델 데이터 오름차순 정렬
        response.sort(function (a, b) {
          return a.meainTypeTerm < b.meainTypeTerm ? -1 : a.meainTypeTerm > b.meainTypeTerm ? 1 : 0; //오름차순으로 정렬
        });
        setMeainModelList({ origin: response, filtered: response });
      })
      .catch(error => log(error, "meainTypeTermListError"));

    getMakrTermList(conn)
      .then(response => {
        //설비 업체 데이터 오름차순 정렬
        response.sort(function (a, b) {
          return a.makrTerm < b.makrTerm ? -1 : a.makrTerm > b.makrTerm ? 1 : 0; //오름차순으로 정렬
        });
        setMakrTermList({ origin: response, filtered: response });
      })
      .catch(error => log(error, "getMakrTermList Error"));

    getDbInfo(conn, dataState)
      .then(response => {
        response.unshift({ columnId: 0, comment: "전체" });
        setDbInfo(response);
      })
      .catch(error => log(error, "setDbInfo Error!"));
      // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //grpCodeNo:15 에너지원만 뽑아냄
    setEnsoTypeCode(codeList.filter(c => c.grpCodeNo === 15));
  }, [codeList]);

  //그리드 width 자동계산 - 영어기준이라 잘안맞음
  const calculateWidth = (field, title) => {
    const fontSize = "20px";
    let maxWidth = calculateSize(title, {
      fontSize: fontSize,
      font: "Noto Sans KR",
      fontWeight: 500,
    }).width;

    dataSource.dataList.forEach(item => {
      const size = calculateSize(item[field], {
        fontSize: fontSize,
        font: "Noto Sans KR",
        fontWeight: 500,
      }); // pass the font properties based on the application
      if (size.width > maxWidth) {
        maxWidth = size.width;
      }
    });
    return maxWidth;
  };

  // 그리드 페이징 처리
  const pageChangeEvt = useCallback(
    e => {
      const { take, skip } = e.page;
      dataState.skip = skip;
      dataState.take = take;
      getGridData();
    },
    // eslint-disable-next-line
    [dataState],
  );

  //그리드 정렬 처리
  const sortChangeEvt = e => {
    const { sort } = e;
    dataState.sort = sort;
    dataState.sortStatus = sort;
    if (sort.length !== 0) {
      if (sort[0].field === "userTerm") {
        switch (dataState.filter.ensoType.codeNo) {
          case 15001:
            dataState.sort = "meainSola.installer." + sort[0].field + "," + sort[0].dir;
            break;
          case 15002:
            dataState.sort = "meainHeat.installer." + sort[0].field + "," + sort[0].dir;
            break;
          case 15003:
            dataState.sort = "meainHeath.installer." + sort[0].field + "," + sort[0].dir;
            break;
          case 15004:
            dataState.sort = "meainVelo.installer." + sort[0].field + "," + sort[0].dir;
            break;
          case 15006:
            dataState.sort = "meainFuelcell.installer." + sort[0].field + "," + sort[0].dir;
            break;
          case 15007:
            dataState.sort = "meainEss.installer." + sort[0].field + "," + sort[0].dir;
            break;
          default:
            break;
        }
      } else {
        dataState.sort = sort[0].field + "," + sort[0].dir;
      }
    }
    getGridData();
  };

  // 수용가별 통계 데이터 조회
  const getGridData = useCallback(() => {
    setLoading(true);
    getStatsByConsm(conn, dataState)
      .then(response => {
        response.results.forEach(e => {
          //bldSrvCodeNo, bizSct, rtuType 자료형 불일치로 백엔드단에서 쿼리DSL 매핑불가 -> 프론트에서 codeList로 변환함
          if (e.bldSrvCodeNo) {
            if (typeof e.bldSrvCodeNo === "string") e.bldSrvCodeNo = parseInt(e.bldSrvCodeNo);
            const temp = codeList.filter(c => c.codeNo === e.bldSrvCodeNo);
            e.bldSrvCodeNo = temp.length ? temp[0].codeVal : null;
          }
          if (e.bizSct) {
            if (typeof e.bizSct === "string") e.bizSct = parseInt(e.bizSct);
            const temp = codeList.filter(c => c.codeNo === e.bizSct);
            e.bizSct = temp.length ? temp[0].codeVal : null;
          }
          if (e.rtuType) {
            if (typeof e.rtuType === "string") e.rtuType = parseInt(e.rtuType);
            const temp = codeList.filter(c => c.codeNo === e.rtuType);
            e.rtuType = temp.length ? temp[0].codeVal : null;
          }
          //소수점 두자리 고정
          for (let i = 0; i < 24; i++) {
            let temp = "";
            if (i < 10 ? (temp = "t0" + i.toString()) : (temp = "t" + i.toString()))
              if (e[temp] !== undefined && !Number.isInteger(e[temp])) e[temp] = e[temp].toFixed(1);
          }
        });

        setDataSource(dataSource => {
          const item = { ...dataSource };
          item.total = response.total;
          item.dataList = response.results;
          return item;
        });
        setLoading(false);
      })
      .catch(error => {
        console.log(error, "getStatsByConsm Error");
        if (error.errCode === "L007") {
          alert("로그인 한 ID는 해당 메뉴에 접근할 수 없습니다.");
          history.goBack();
          return;
        }
      });
      // eslint-disable-next-line
  }, [dataState, dataSource]);

  /* 에너지원 DDL 선택 */
  const ensoTypeChngEvt = e => {
    dataState.filter.userTerm = "";
    dataState.filter.cid = "";
    dataState.filter.startDtm = new Date();
    dataState.filter.endDtm = new Date();
    dataState.filter.alias = "";
    
    // 에너지원 변경시 초기화 후 시군구, 읍면동 드랍다운 disable
    dataState.filter.cityProvCode = "";
    dataState.filter.rgnCode = "";
    dataState.filter.fixbylawBundCode = "";

    setCityDdl({ cityProvCode: "", cityProvTerm: "시/도" });
    setRgnDdl({ rgnTermSimple: "시/군/구", rgnCode: "" });
    setDongDdl({ fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" });

    dataState.filter.makr = "";
    dataState.filter.meainModel = "";
    setMakrDdl({ makrSeriNo: 0, makrTerm: "설비 업체" });
    setMeainTypeDdl({ meainTypeSeriNo: 0, meainTypeTerm: "설비 모델" });
    
    setMultiSelectValue([]);

    const { value } = e;
    setDataState({ ...dataState, filter: { ...dataState.filter, ensoType: value } });
    dataState.filter.ensoType = value;

    // setMultiSelectValue([]);
    // //초기화
    // setDataSource({
    //   total: 0,
    //   dataList: [],
    // });
    //멀티셀렉트 DB 데이터 조회
    getDbInfo(conn, dataState)
      .then(response => {
        response.unshift({ columnId: 0, comment: "전체" });
        setDbInfo(response);
      })
      .catch(error => log(error, "setDbInfo Error!"));
  };

  /* 시도 DDL 선택 */
  const cityDdlChngEvt = e => {
    const { value } = e;
    setCityDdl(value);
    //시도 선택 시 시군구 선택하게 함
    setDataState({ ...dataState, filter: { ...dataState.filter, cityProvCode: value.cityProvCode, rgnCode: "" } });

    const rgns = rgnList.filter(item => item.cityProvCode === value.cityProvCode);
    rgns.sort(function (a, b) {
      return a.rgnTermSimple < b.rgnTermSimple ? -1 : a.rgnTermSimple > b.rgnTermSimple ? 1 : 0; //오름차순으로 정렬
    });
    setRgnData(rgns);
    setRgnDdl({ rgnTermSimple: "시/군/구", rgnCode: "" });
    setDongDdl({ fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" });
  };

  /* 시군구 DDL 선택 */
  const rgnDdlChngEvt = e => {
    const { value } = e;
    setRgnDdl(value);
    setDongDdl({ fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" });
    //시군구 선택 시 읍면동 선택하게 함
    setDataState({ ...dataState, filter: { ...dataState.filter, rgnCode: value.rgnCode, fixbylawBundCode: "" } });
    //읍면동 데이터 세팅
    getDongs(conn, e.target.value.rgnCode).then(result => {
      result.sort(function (a, b) {
        return a.fixbylawBundTermSimple < b.fixbylawBundTermSimple ? -1 : a.fixbylawBundTermSimple > b.fixbylawBundTermSimple ? 1 : 0; //오름차순으로 정렬
      });
      setDongData(result);
    });
  };

  /* 읍면동 DDL 선택 */
  const dongDdlChngEvt = e => {
    const { value } = e;
    setDongDdl(value);
    setDataState({ ...dataState, filter: { ...dataState.filter, fixbylawBundCode: value.fixbylawBundCode } });
  };

  /* 설비업체 DDL 선택 */
  const makrTermChngEvt = e => {
    const { value } = e;
    setMakrDdl(value);
    setDataState({ ...dataState, filter: { ...dataState.filter, makr: value } });
  };

  /* 설비 모델 DDL 선택 */
  const meainTypeTermChngEvt = e => {
    const { value } = e;
    setMeainTypeDdl(value);
    setDataState({ ...dataState, filter: { ...dataState.filter, meainModel: value } });
  };

  /* 시작일 변경 이벤트 */
  const startDtmChngEvt = e => {
    const { value } = e;
    const year = new Date(value).getFullYear();
    const month = new Date(value).getMonth();
    const day = new Date(value).getDate();

    setCalendarState({ ...calendarState, min: value });
    setDataState({ ...dataState, filter: { ...dataState.filter, startDtm: new Date(year, month, day) } });
  };

  /* 종료일 변경 이벤트 */
  // const endDtmChngEvt = e => {
  //   const { value } = e;
  //   const year = new Date(value).getFullYear();
  //   const month = new Date(value).getMonth();
  //   const day = new Date(value).getDate();
  //
  //   setCalendarState({ ...calendarState, max: value });
  //   setDataState({ ...dataState, filter: { ...dataState.filter, endDtm: new Date(year, month, day, 23, 59, 59) } });
  // };

  /* 설치자명 인풋 변경 이벤트 */
  const userTermInputChngEvt = e => {
    const { value } = e;
    setDataState({ ...dataState, filter: { ...dataState.filter, userTerm: value } });
  };

  /* CID 인풋 변경 이벤트 */
  const cIdInputChngEvt = e => {
    const { value } = e;
    if(value !== value.replace(/[^0-9]/g, '')) {
      kendo.alert("숫자만 입력 가능합니다.");
      return;
    } 
    
    setDataState({ ...dataState, filter: { ...dataState.filter, cid: value } });
  };

  /* 설비명 인풋 변경 이벤트 */
  const aliasInputChngEvt = e => {
    const { value } = e;
    setDataState({ ...dataState, filter: { ...dataState.filter, alias: value } });
  };

  //조회 버튼 클릭이벤트
  const searchStatsByConsmBtnEvt = e => {
    dataState.skip = 0;
    getGridData();
    if (dataState.filter.ensoType.codeNo === 15002 || dataState.filter.ensoType.codeNo === 15003) setGridTitle("생산량");
    else setGridTitle("발전량");
  };

  //발전량-시간(kWh)셀
  const timeColumns = () => {
    let arr = [];
    for (let i = 0; i < 24; i++) {
      if (i < 10) arr.push(<Column field={"t0" + i} title={i + "시"} width="50px" sortable={false}></Column>);
      else arr.push(<Column field={"t" + i} title={i + "시"} width="50px" sortable={false}></Column>);
    }
    return arr;
  };
  //그리드 넘버 셀
  const gridNumCell = e => {
    return <td>{e.dataIndex + 1}</td>;
  };
  //발전량-일 셀
  const dayPowerCell = e => {
    //소수점 두번째자리 고정
    let result;
    if (e.dataItem.dayPower) result = e.dataItem.dayPower.toFixed(2);
    else result = "0.00";
    return <td>{result}</td>;
  };
  //누적발전량 셀
  const accumPowerCell = e => {
    //소수점 두번째자리 고정
    let result;
    if (e.dataItem.accumPower) result = e.dataItem.accumPower.toFixed(2);
    else result = "0.00";
    return <td>{result}</td>;
  };
  //효율-일 셀
  const dayAccumEficCell = e => {
    //소수점 두번째자리 고정
    let result;
    if (e.dataItem.dayAccumEfic) result = e.dataItem.dayAccumEfic.toFixed(2);
    else result = "0.00";
    return <td>{result}</td>;
  };

  // 파일 이름
  const defineFileName = () => {
    let name = "전체";
    switch (dataState.filter.ensoType.codeNo) {
      case 15001:
        name = "태양광";
        break;
      case 15002:
        name = "태양열";
        break;
      case 15003:
        name = "지열";
        break;
      case 15004:
        name = "풍력";
        break;
      case 15006:
        name = "연료전지";
        break;
      case 15007:
        name = "ESS";
        break;
      default:
        break;
    }
    const template = name + "_수용가별통계_" + kendo.toString(new Date(), "yyyyMMdd_HHmmss") + ".xlsx";
    return template;
  };

  //엑셀 서버사이드 다운로드
  const excelExport = e => {
    e.preventDefault();
    if (dataSource.total > EXCEL_LIMIT) {
      kendo.alert("10만건 이상은 다운로드 불가능합니다!");
    } else {
      setLoading(true);
      const { filter } = dataState;
      let data = {};

      if (filter !== undefined) {
        data.ensoType = filter.ensoType.codeNo;
        if (filter.startDtm !== "") data.startDtm = parseDate("yyyy-mm-dd", filter.startDtm);
        if (filter.endDtm !== "") data.endDtm = parseDate("yyyy-mm-dd", filter.endDtm);
        if (filter.userTerm !== undefined) data.userTerm = filter.userTerm;
        if (filter.cid !== undefined) data.cid = filter.cid;
        if (filter.cityProvCode !== undefined) data.cityProvCode = filter.cityProvCode;
        if (filter.rgnCode !== undefined) data.rgnCode = filter.rgnCode;
        if (filter.fixbylawBundCode !== undefined) data.fixbylawBundCode = filter.fixbylawBundCode;
        if (filter.alias !== undefined) data.alias = filter.alias;
        if (filter.meainModel !== undefined) {
          if (filter.meainModel.meainTypeSeriNo) {
            data.meainModel = filter.meainModel.meainTypeSeriNo;
          }
        }
        if (filter.makr !== undefined) {
          if (filter.makr.makrSeriNo) {
            data.makr = filter.makr.makrSeriNo;
          }
        }
      }

      let meainInfo = [];
      if (multiSelectValue.length) {
        //멀티셀렉트 전체 일때
        if (multiSelectValue[0].columnId === 0) {
          meainInfo = dbInfo.slice();
          meainInfo.shift();
        } else {
          meainInfo = multiSelectValue.slice();
        }
      }
      log(meainInfo, "설비인포");
      let columns = [];
      for (let idx in meainInfo) {
        let title = meainInfo[idx].comment;
        let field = meainInfo[idx].columnName;

        //사업구분
        if (field === "bizSct") field = "bizTerm";
        //에너지원별 예외 항목 체크
        if (data.ensoType === 15001) {
          if (field === "meainTypeSeriNo") field = "meainTypeTerm";
          if (field === "invtMakrNo") field = "makrTerm";
        } else if (data.ensoType === 15002) {
          if (field === "meainTypeSeriNo") field = "meainTypeTerm";
          if (field === "coldCtrlerMakrNo") field = "makrTerm";
        } else if (data.ensoType === 15003) {
          if (field === "meainTypeSeriNo") field = "meainTypeTerm";
          if (field === "heatPumpMakrNo") field = "makrTerm";
        } else if (data.ensoType === 15004) {
          if (field === "meainTypeSeriNo") field = "meainTypeTerm";
          if (field === "generMakrNo") field = "makrTerm";
        } else if (data.ensoType === 15006) {
        } else if (data.ensoType === 15007) {
          if (field === "meainTypeSeriNo") field = "meainTypeTerm";
          if (field === "batteryMakrNo") field = "makrTerm";
        }
        if (field === "rtuType") field = "rtuTypeTerm";

        //code | no 체크
        let idxCodeOrNo = field.search(/code|no/gi);
        let idxExcept = field.search(/INST_CNFRM_CODE|FIXBYLAW_BUND_CODE/gi);
        if (idxCodeOrNo >= 0 && idxExcept < 0) {
          field = field.substring(0, idxCodeOrNo) + "Term";
        }
        let template = "#= rems.isEmptyExcptZero(data." + field + ") ? " + field + " : '-' #";
        //날짜 필드 체크
        let idxDateOrDtm = field.search(/date|dtm/gi);
        if (idxDateOrDtm >= 0) {
          template = "#= data." + field + " ? kendo.toString(new Date(" + field + "),'yyyy-MM-dd hh:mm:ss') : '-' #";
        }

        //잘못변경된것 예외처리
        if (field === "socoShtTerm") field = "socoShtno";
        if (field === "instCnfrmTerm") field = "instCnfrmCode";
        columns.push({
          field: field,
          title: title,
          template: template,
        });
      }

      log(columns, "컬럼테스트");

      data.meainInfo = columns;
      const fileName = defineFileName();
      const url = "/api/v2.2/stat/byconsm";
      downloadExcel(data, fileName, url, setLoading);
    }
  };

  /* 멀티셀렉트 변경이벤트 */
  const multiSelectOnChange = e => {
    let flag = 0;
    if (multiSelectValue.length) {
      //'전체' 선택상태에서 다른거 선택할때 '전체' 해제시키고 다른거 추가
      if (multiSelectValue[0].columnId === 0) e.value = e.value.filter(item => item.columnId !== 0);

      //'전체' 선택할 때 다른거 전부 해제시키고 '전체' 추가
      e.value.forEach(item => {
        if (item.columnId === 0) flag = 1;
      });
    }
    if (flag) setMultiSelectValue([{ columnId: 0, comment: "전체" }]);
    else setMultiSelectValue([...e.value]);
  };

  //설비업체 드롭다운리스트 검색이벤트
  const makrDdlFilterChng = e => {
    const filteredData = filterBy(makrTermList.origin, e.filter);
    setMakrTermList({ ...makrTermList, filtered: filteredData });
  };
  //설비모델 드롭다운리스트 검색이벤트
  const meainModlDdlFilterChng = e => {
    const filteredData = filterBy(meainModelList.origin, e.filter);
    setMeainModelList({ ...meainModelList, filtered: filteredData });
  };

  return (
    <div>
      <div className={styles.filterBox}>
        <table className={styles.filterTable}>
          <tr>
            <td>
              <DropDownList
                className={styles.ensoTypeDdl}
                data={ensoTypeCode}
                textField="codeVal"
                key="codeNo"
                value={dataState.filter.ensoType}
                onChange={ensoTypeChngEvt}
              />
            </td>
            <td>
              <Input className={styles.userTermInput} placeholder="설치자명" onChange={userTermInputChngEvt} value={dataState.filter.userTerm}></Input>
            </td>
            <td>
              <Input className={styles.cidInput} placeholder="CID" onChange={cIdInputChngEvt} value={dataState.filter.cid}></Input>
            </td>
            <td>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <DatePicker
                    className={styles.startDtmPicker}
                    value={dataState.filter.startDtm}
                    onChange={startDtmChngEvt}
                    max={calendarState.max}></DatePicker>
                </IntlProvider>
              </LocalizationProvider>
              {/* <span>&nbsp;~&nbsp;</span>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <DatePicker
                    className={styles.endDtmPicker}
                    value={dataState.filter.endDtm}
                    onChange={endDtmChngEvt}
                    min={calendarState.min}
                    max={new Date()}></DatePicker>
                </IntlProvider>
              </LocalizationProvider> */}
            </td>
            <td>
              <Input className={styles.aliasDdl} placeholder="설비명" onChange={aliasInputChngEvt} value={dataState.filter.alias}/>
            </td>
            <td>
            
            </td>
          </tr>
          <tr>
            <td>
              <DropDownList
                className={styles.cityProvDdl}
                data={citys}
                value={cityDdl}
                textField="cityProvTerm"
                key="cityProvCode"
                onChange={cityDdlChngEvt}
                // value={rgnData}
                defaultItem={{ cityProvCode: "", cityProvTerm: "시/도" }}
              />
            </td>
            <td>
              <DropDownList
                className={styles.rgnDdl}
                data={rgnData}
                value={rgnDdl}
                textField="rgnTermSimple"
                key="rgnCode"
                disabled={!dataState.filter.cityProvCode}
                onChange={rgnDdlChngEvt}
                defaultItem={{ rgnTermSimple: "시/군/구", rgnCode: "" }}
              />
            </td>
            <td>
              <DropDownList
                className={styles.fixbylawDdl}
                data={dongData}
                value={dongDdl}
                textField="fixbylawBundTermSimple"
                key="fixbylawBu도dCode"
                disabled={!dataState.filter.rgnCode}
                onChange={dongDdlChngEvt}
                defaultItem={{ fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" }}
              />
            </td>
            <td>
              <DropDownList
                className={styles.makrTermDdl}
                data={makrTermList.filtered}
                value={makrDdl}
                defaultItem={{ makrSeriNo: 0, makrTerm: "설비 업체" }}
                filterable={true}
                onChange={makrTermChngEvt}
                onFilterChange={makrDdlFilterChng}
                key={"makrSeriNo"}
                textField={"makrTerm"}
              />
            </td>
            <td>
              <DropDownList
                className={styles.meainModelDdl}
                data={meainModelList.filtered}
                value={meainTypeDdl}
                defaultItem={{ meainTypeSeriNo: 0, meainTypeTerm: "설비 모델" }}
                filterable={true}
                onChange={meainTypeTermChngEvt}
                onFilterChange={meainModlDdlFilterChng}
                key={"meainTypeSeriNo"}
                textField={"meainTypeTerm"}
              />
            </td>
            <td>
            <Button className={styles.inqBtn} icon="search" onClick={searchStatsByConsmBtnEvt}>
            </Button>
              {/* <Input className={styles.aliasDdl} placeholder="설비명" onChange={aliasInputChngEvt} value={dataState.filter.alias}/> */}
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <MultiSelect
                data={dbInfo}
                key="columnId"
                textField="comment"
                value={multiSelectValue}
                onChange={multiSelectOnChange}
                autoClose={false}
                placeholder={"설비정보선택"}></MultiSelect>
            </td>
            
          </tr>
        </table>
      </div>
      <div align="right">
       
        <Button
          className={styles.excelBtn}
          style={
            loading
              ? {
                  opacity: 0.6,
                  pointerEvents: "none",
                  boxShadow: "none",
                }
              : null
          }
          icon="excel"
          onClick={excelExport}>
          엑셀저장
        </Button>
      </div>
      <div className={styles.statConsmGridDiv}>
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              className={styles.statConsmGrid}
              style={
                loading
                  ? {
                      opacity: 0.6,
                      pointerEvents: "none",
                      height: "60vh",
                    }
                  : { height: "60vh" }
              }
              data={dataSource.dataList}
              skip={dataState.skip}
              take={dataState.take}
              total={dataSource.total}
              sort={dataState.sortStatus}
              sortable
              pageable={{ buttonCount: 5, info: true, pageSizes: [5, 10, 20, 50] }}
              onPageChange={pageChangeEvt}
              onSortChange={sortChangeEvt}
              scrollable={true}
              resizable={true}>
              <Column field="" title="No" width="50px" cell={gridNumCell} sortable={false}></Column>
              <Column field="cid" title="CID" width="110px"></Column>
              <Column field="userTerm" title="설치자" width={calculateWidth("userTerm", "설치자")}></Column>
              <Column field="alias" title="설비명" width={calculateWidth("alias", "설비명")} sortable={false}></Column>
              <Column field="instCapa" title="설비용량(kW)" width="105px" sortable={false}></Column>
              <Column field="meainStatCodeNo" title="설비상태" width="80px" sortable={false}></Column>
              <Column field="gathDtm" title="수집일자" width="90px"></Column>
              <Column field="dayPower" title={gridTitle + "-일(kWh)"} width="125px" cell={dayPowerCell}></Column>
              <Column field="accumPower" title={"누적" + gridTitle + "(kWh)"} width="135px" cell={accumPowerCell}></Column>
              <Column field="dayAccumEfic" title="효율-일(%)" width="100px" cell={dayAccumEficCell}></Column>
              <Column title={gridTitle + "-시간(kWh)"} width="30px" sortable={false}>
                {timeColumns()}
              </Column>
              {multiSelectValue.length
                ? multiSelectValue[0].columnId === 0
                  ? dbInfo.map(m => {
                      if (m.columnId)
                        return (
                          <Column
                            field={m.columnName}
                            title={m.comment}
                            width={calculateWidth(m.columnName, m.comment)}
                            sortable={false}></Column>
                        )
                      else{
                        return (<></>);
                      }
                    })
                  : multiSelectValue.map(m => {
                      return (
                        <Column
                          field={m.columnName}
                          title={m.comment}
                          width={calculateWidth(m.columnName, m.comment)}
                          sortable={false}></Column>
                      );
                    })
                : null}
              <GridNoRecords>{"조회 결과 데이터가 없습니다."}</GridNoRecords>
            </Grid>
            {/* 엑셀로딩*/}
            {loading && (
              <p
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}>
                <BeatLoader loading={true} size={24} color="#1e88e5" />
              </p>
            )}
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default StatsByConsm;
