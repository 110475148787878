
export const getInstallerMeainList = async (conn, search, page) => {
    // console.log("search", search, page);
    const response = await conn.get('monitor/installerMeain/page', {
        params: {
            page : (page.skip / page.take) + 1,
            size : page.take,
            ensoTypeCode : search.ensoTypeCode,
            bldSrvCode: search.bldSrvCode,
            cityProvCode: search.cityProvCode,
            rgnCode: search.rgnCode,
            dongCode: search.fixbylawBundCode,
            searchTerm : search.searchTerm,
            statNormal : search.statNormal,
            statUnOper : search.statUnOper,
            statReady : search.statReady,
            statWarn : search.statWarn,
            statError : search.statError  
        }
    });
    const result = response;
    result.results.map((result,index) => result.key = index );
    return result;
};


export const getInstallerMeainStatCnt = async (conn, search) => {
    const response = await conn.get('monitor/installerMeain/statCnt', {
        params: {
            ensoTypeCode : search.ensoTypeCode,
            bldSrvCode: search.bldSrvCode,
            cityProvCode: search.cityProvCode,
            rgnCode: search.rgnCode,
            dongCode: search.fixbylawBundCode,
            searchTerm : search.searchTerm,
            statNormal : search.statNormal,
            statUnOper : search.statUnOper,
            statReady : search.statReady,
            statWarn : search.statWarn,
            statError : search.statError  
        }
    });
    return response;
};
