import "./index.css";

const AmountOfPowerGenerationTaean = ({ data }) => {
  return (
    <div className="taean-content-wrapper ">
      <span className="taean-dashboard-title">발전량 환산 금액</span>
      <div className="taean-left-top-wrapper" style={{ alignItems: "center" }}>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <span className="amount-powerGeneration-taean-text-1">오늘 시세</span>
          <></>
        </div>
        <div className="amount-powerGeneration-taean-content-wrapper">
          <span className="amount-powerGeneration-taean-text-2">SMP</span>
          <div>
            <span className="amount-powerGeneration-taean-text-3">{data.smpPrice.toLocaleString()}</span>
            <span className="amount-powerGeneration-taean-text-4"> 원</span>
          </div>
        </div>
        <div className="amount-powerGeneration-taean-content-wrapper">
          <span className="amount-powerGeneration-taean-text-2">REC</span>
          <div>
            <span className="amount-powerGeneration-taean-text-3">{data.recPrice.toLocaleString()}</span>
            <span className="amount-powerGeneration-taean-text-4"> 원</span>
          </div>
        </div>
        <div className="today-profit-wrapper">
          <span className="amount-powerGeneration-taean-text-1 amount-powerGeneration-taean-text-position">오늘 수익</span>
          <div>
            <span className="amount-powerGeneration-taean-text-5">{data.totalPrice.toLocaleString()}</span>
            <span className="amount-powerGeneration-taean-text-6"> 원</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmountOfPowerGenerationTaean;
