import { useEffect, useState } from 'react'
import { TreeView, processTreeViewItems, handleTreeViewCheckChange } from '@progress/kendo-react-treeview';

import kendo from '@progress/kendo-ui';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Search } from "@material-ui/icons";

// 로딩 스피너 
import { BeatLoader } from 'react-spinners'
import { filterBy } from '@progress/kendo-data-query';

const MenuRoleAreaTreeView = ({ conn, setCheckData, areaUserList, setAreaUserList, styles, saveChanges, loading, setLoading, dataSource, setDataSource, getTreeData,
    setCommonCheckData, areaUserListData, selectedUser, setSelectedUser, setIsChecked }) => {
    const settings = { checkChildren: true, checkParents: true };
    const filterChange = (e) => {
        const { filter } = e;
        const ddlId = e.target.props.id;
        switch (ddlId) {
            case "areaUser":
                setDdlData({ ...ddlData, userInfo: filterBy(areaUserListData, filter) });
                break;
            default:
                break;
        }
    };
    const [ddlData, setDdlData] = useState({ userInfo: areaUserListData.slice(), });

    // 드롭다운 이벤트
    const onChangeUserType = e => {
        setAreaUserList(areaUserList => {
            const item = { ...areaUserList };
            item.userId = e.target.value.userId;
            item.userTerm = e.target.value.userTerm;
            return item;
        });
    };

    //조회버튼 클릭시
    const viewBtnOnclick = () => {
        if (areaUserList.userId === "") {
            kendo.alert("지자체 계정을 먼저 선택해주세요");
        }
        else {
            setLoading(true);
            getTreeData();
            // setDataSource({});
            setCommonCheckData({ menuData: [], menuList: [] });
            setSelectedUser({ userId: areaUserList.userId, userTerm: areaUserList.userTerm + "(" + areaUserList.userId + ")" });
        }
    }
    const onCheckChange = (event) => {
        setDataSource({
            ...dataSource,
            check: handleTreeViewCheckChange(event, dataSource.check, dataSource.data, settings),
        });

        setIsChecked(true);

        event.item.checked = !event.item.checked;

        const checkList = handleTreeViewCheckChange(event, dataSource.check, dataSource.data, settings)
        setCheckData(selectedUser.userId, checkList.ids, dataSource);
    }

    const onExpandChange = (event) => {
        event.item.expanded = !event.item.expanded;
    }

    useEffect(() => {
        setDdlData({ userInfo: areaUserListData.slice(), });
    }, [areaUserListData])

    return (
        <div className="uk-width-medium-1-3">
            <div className="md-card" style={{ border: "0px solid black" }}>
                <div className="md-card-content">
                    <div className="md-card-toolbar">
                        <h3 className="md-card-toolbar-heading-text" style={{ border: "1px solid #dddddd" }}>
                            <DropDownList
                                className={styles.AreaUserDropDown}
                                data={ddlData.userInfo}
                                textField="userTerm"
                                dataItemKey="userId"
                                name="areaUser"
                                id="areaUser"
                                onChange={onChangeUserType}
                                value={areaUserList}
                                // popupSettings={{ className: "autoFitDropDown" }}
                                filterable={true}
                                onFilterChange={filterChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                className={styles.viewBtn}
                                onClick={viewBtnOnclick}
                            >
                                <Search />
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                title="update"
                                className={styles.saveBtn}
                                onClick={saveChanges}
                                icon="save"
                            >
                                저장
                            </Button>
                        </h3>
                        <h3 className="md-card-toolbar-heading-text" style={{ border: "1px solid #dddddd", marginTop: "10px" }}>
                            지자체<b style={{ float: "right", marginRight: "10px" }}>{selectedUser.userTerm}</b>
                        </h3>
                        {selectedUser.userId === "" ? <></> :
                            <TreeView
                                className={["menuTree", loading ? styles.treeViewLoading : ""].join(' ')}
                                data={processTreeViewItems(dataSource.data, { check: dataSource.check })}
                                expandIcons={true}
                                checkboxes={true}
                                onCheckChange={onCheckChange}
                                onExpandChange={onExpandChange}
                                selectField={false}
                            />}
                        {loading && <p className={styles.loading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuRoleAreaTreeView;