
import { CODE_HEAT, CODE_SOLA, CODE_HEATH } from './../../../com/codeUtil';

/**
 * 관리현황에 연동 버튼 클릭 이벤트
 */
 export const meainSyncData = ( history , meainInfo , codeList , bizSctCode ) => {
  // 절차 추가
  // 1. 연동 클릭 시 안내 문구 추가 : 해당 설비 연동 하려면 먼저 설비 상세 정보를 저장 해야합니다. 설비 상세 정보가 저장되있는 설비라면 연동 진행 하도록 하겠습니다.
  // 2. 확인 버튼 클릭 하면 연동 진행 >> 연동 시 설비 내역의 연동 여부는 N -> Y 로 변경
  // 3. 조회 시 설비상세정보에 등록된 설비가 아니라면 안내문구로 안내 : 해당 설비는 설비상세정보를 등록되지 않은 설비 입니다. 상세 정보를 등록 해주세요.
  let ensoTypeCode = converterEnsoTypeCode(meainInfo.energyTypeCode);
  let sysMode = meainInfo.sysMode ? meainInfo.sysMode : '' ;

  // 사업 구분 설정
  converterBizSct(meainInfo , bizSctCode)

  // 용량 설정
  converterInstCapa(ensoTypeCode, meainInfo);

  // 설치 유형 컨버터 : 태양광, 태양열
  converterInstType( ensoTypeCode, meainInfo , codeList );

  // 설비명 설정
  meainInfo.alias = meainInfo.instlTerm
  // console.log( 'meainSyncData meainInfo = ' , meainInfo );
  // 연동 여부 표시 설정
  meainInfo.requestMeainSync = "Y";
  // 주소 설정
  meainInfo.address = meainInfo.instlAddr;

  // console.log("meainSyncData meainInfo = ", meainInfo);

  // 설비 등록 이동
  history.push(
    {
      pathname: "/mngt/meain",   // 관리 - 설비등록으로 이동
      meainTransData:{
        meainSync : meainInfo.requestMeainSync ,  // 싱크 결정 여부 Y
        meainInfo : meainInfo ,         // 설비 정보
        ensoTypeCode : ensoTypeCode ,   // 에너지원 코드 설정
        sysMode : sysMode               // 태양열 설치모드 설정 : 강제, 자연
      }
    }
  );

}

/**
 * 에너지원 코드 컨버터 ( 사업현황 설비 ) 
 */
 export const converterEnsoTypeCode = ( energyTypeCode ) => {
  let ensoTypeCodeVal = energyTypeCode;
  if(energyTypeCode){
    if( energyTypeCode === 39001 ){ ensoTypeCodeVal = CODE_SOLA; }
    else if( energyTypeCode === 39002 ){ ensoTypeCodeVal = CODE_HEAT; }
    else if( energyTypeCode === 39004 ){ ensoTypeCodeVal = CODE_HEATH; }
    else{ ensoTypeCodeVal = CODE_SOLA; }
  }
  return ensoTypeCodeVal;
}


/**
 * 에너지원별 용량 컨버터 ( 사업현황 설비 ) 
 */
 export const converterInstCapa = ( ensoTypeCode , meainInfo ) => {
  // 에너지원별 용량 기준
  // 태양광 : 모듈 총 용량
  // 태양열 : 집열기 면적
  // 지열 : 히트펌프용량
  if(ensoTypeCode){
    if( ensoTypeCode === CODE_SOLA ){ meainInfo.modulAlCapa = meainInfo.instCapa; }
    else if( ensoTypeCode === CODE_HEAT ){ meainInfo.socoArea = meainInfo.instCapa; }
    else if( ensoTypeCode === CODE_HEATH ){ meainInfo.heatPumpCapa = meainInfo.instCapa;  }
  }
  return meainInfo;
}

/**
 * 설치 유형 컨버터 : 태양광, 태양열
 */
export const converterInstType = ( ensoTypeCode, meainInfo , codeList ) => {
  // console.log("meainInfo.instType = ", meainInfo.instType);
  let instTypeTerm = "";
  let grpCodeNo = 0;
  if ( ensoTypeCode === CODE_SOLA ){
    grpCodeNo = 51;
  }else if ( ensoTypeCode === CODE_HEAT ){
    grpCodeNo = 52;
  }
  const instDs = meainInfo.instType ? codeList.filter(v => v.grpCodeNo === grpCodeNo && v.codeNo === Number(meainInfo.instType) ) : [];  
  // console.log("instDs = ", instDs);
    if( instDs.length > 0 ){
      instTypeTerm = instDs[0].codeVal;
      // console.log("instTypeTerm = ", instTypeTerm);
    }
    meainInfo.instType = instTypeTerm;
  return meainInfo
}


/**
 * 사업 구분 코드 컨버터 
 */
export const converterBizSct = ( meainInfo , bizSctCode ) =>{
  if( bizSctCode === 37001 ){ meainInfo.bizSct = 34001; }
  else if( bizSctCode === 37002 ){ meainInfo.bizSct = 34002; }
  else if( bizSctCode === 37003 ){ meainInfo.bizSct = 34003; }
  else if( bizSctCode === 37004 ){ meainInfo.bizSct = 34004; }
  else { meainInfo.bizSct = ''; }
  meainInfo.bizStatSctCode = bizSctCode;
  return meainInfo;
}