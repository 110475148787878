import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { roundNum } from '../../../../com/dataUtil';

const UserBetweenUserBizYyChart = ({userBizYyList, search , getMeainModelChartData}) => {

  console.log("userBizYyList = ", userBizYyList);

    const chartList = userBizYyList
      .filter((item, idx) => idx < 10) 
      .map(item => ({ 
      name : item.bizYy ,
      y : roundNum( item.instCapaPerEnergy , 2 ) , 
      color : "#69bcc8" } )
    );

    console.log("chartList = ", chartList);  

    const category = [];
      chartList.forEach(element => {
      category.push(element.name + "년");
    });

    console.log("category = ", category);  

    // 에너지원 코드 번호
    const ensoTypeCodeNo = search.ensoTypeCode; 
    // 발전량 , 생산량 조건부 렌더링
    const isCal = ensoTypeCodeNo === 15002 || ensoTypeCodeNo === 15003 ? true : false;

    // 제조사  / 모델 차트 옵션
    const chartConfig = {
      chart: { zoomType: 'xy', height : 400 },
      noData: { style:{ color: '#FFFFFF', fontSize: '14px' } },
      title: { text: null },
      legend: { enabled: false },
      tooltip: {
          shared: true,
          positioner: function (labelWidth, labelHeight, point) {
            // 툴팁 위치 조정 
            const tooltipX = point.plotX;
            const tooltipY = 150;
            return {
                x: tooltipX,
                y: tooltipY
            };
          },
          formatter: function () {
              return(
                `
                  <b>${this.points[0].point.name}</b><br/>
                  <span style="color:${this.points[0].point.color}">\u25CF</span>
                  ${this.points[0].series.name} : ${Math.round(this.y*100)/100} kWh/${search.ensoTypeCode===15002?'㎡':'kW'}.day
                `
              );
          }
      },
      xAxis: { 
        type: 'category',
        crosshair: true,
        categories : category,
        labels: {
          style: {
            color: '#000000'
          }
        }
      },
      yAxis: [{ // Primary yAxis
          labels: {
              format: '{value:,.2f}',
              style: {
                  color: "#69bcc8"
              }
          },
          title: {
              text: isCal === false ? '용량대비생산량(kWh/' : '용량대비생산량(kWh/' +(search.ensoTypeCode===15002?'㎡':'kW')+'.day)',
              style: {
                  color: "#69bcc8"
              }
          }
      }],
      credits: { enabled: false },
      plotOptions: {
          series: { colorByPoint: true },
          column : {
              cursor: 'pointer',
              point: {
                  events: {}
              }
          }
      },
      series: [{
          name: '용량대비생산량',
          type: 'column',
          color: '#69bcc8',
          data: chartList,
          dataLabels: {
              enabled: true,
              format: '{point.y:,.2f}'
          },
          tooltip: {
              pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} kWh/'+(search.ensoTypeCode===15002?'㎡':'kW')+'.day</b><br/>'
          }
      }]
    }

    return(
      <div style = {{height : "400px"}}>
          <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}/>
      </div>
    );
}

export default UserBetweenUserBizYyChart;