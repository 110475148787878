// React
import React, { useState, useEffect, useCallback } from "react";
import log from "../../../../com/log";
import { parseDate } from "../../../../com/dateUtil";
import { numFormat } from "../../../../com/dataUtil";
import { downloadExcel } from "../../../../com/downloadExcel";
import { getEnsoEventTime, getEnsoEventContent } from "../../../../service/stats/event/ensoEventService";

// Kendo
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import kendo from "@progress/kendo-ui";

// Highcharts
import { EnsoPieCharts } from "./ensoPieCharts";
import { EnsoLineCharts } from "./ensoLineCharts";

// Css
import "./enso.css";
// import "./enso.dk.css";
import styles from "./enso.module.css";
import { Search } from "@material-ui/icons";

// 로딩 스피너
import { BeatLoader } from "react-spinners";

const Enso = ({ conn, codeList }) => {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);
  // 날짜검색
  // const dateCate = useMemo(() => codeList.filter(item => item.grpCodeNo === 23), [codeList]);

  // 데이터 소스
  const [dataSource, setDataSource] = useState({
    eventTime: [],
    eventContent: [
      { ensoType: "태양광", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "태양열", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "지열", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "풍력", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "연료전지", warn: 0, err: 0, all: 0, manaTm: 0 },
      { ensoType: "ESS", warn: 0, err: 0, all: 0, manaTm: 0 },
    ],
    dashboard: {
      total: 0,
      totalWarn: 0,
      totalErr: 0,
      totalManaTm: 0,
      actHour: 0,
      actMinute: 0,
    },
  });

  const [category, setCategory] = useState({
    dateList: "오늘",
    startDate: parseDate("yyyy-mm-dd"),
    endDate: parseDate("yyyy-mm-dd"),
    firstDay : kendo.toString( new Date() , 'yyyy-MM-01' ),
    isSearch : false
  });

  // 조회 로직 (수정 20210423 jhchoi)
  // 시간대별 경고/고장 상세정보 (차트)
  const getEnsoEventTimeList = () => {
    setLoading(true);
    console.log("category = ", category);
    // 선택한 날의 첫번쨰 날을 설정
    getEnsoEventTime(conn, category)
      .then(response => {
        setDataSource(dataSource => {
          const item = { ...dataSource };
          item.eventTime = response;
          return item;
        });
        setLoading(false);
      })
      .catch(err => {
        log(`enso.useEffect.getEnsoEventTime : ${err}`);
      });
  };

  // 에너지원별 이벤트 (차트) & 시간대별 경고/고장 상세정보 (그리드)
  const getEnsoEventContentList = () => {
    setLoading(true);
    getEnsoEventContent(conn, category)
      .then(response => {
        console.log("response = ", response);
        let total = 0;
        let totalWarn = 0;
        let totalErr = 0;
        // let totalManaTm = 0;
        response.forEach(item => {
          item.all = item.warn + item.err;
          total += item.all;
          totalWarn += item.warn;
          totalErr += item.err;
          // totalManaTm += item.manaTm;
        });
        
        console.log("total = ", total);    
        console.log("totalWarn = ", totalWarn);  
        console.log("totalErr = ", totalErr);  

        // 총 수동조치시간
        // const actTime = totalManaTm;
        // 수동조치시간 시간
        // const actHour = Math.floor(actTime / 60);
        // 수동조치시간 분
        // const actMinute = Math.floor(actTime % 60);

        setDataSource(dataSource => {
          const item = { ...dataSource };
          item.eventContent = response;
          item.dashboard.total = total;
          item.dashboard.totalWarn = totalWarn;
          item.dashboard.totalErr = totalErr;
          // item.dashboard.totalManaTm = totalManaTm;
          // item.actHour = actHour;
          // item.actMinute = actMinute;
          return item;
        });
        setLoading(false);
      })
      .catch(err => {
        log(`enso.useEffect.getEnsoEventContent : ${err}`);
      });
  };

  // 데이터 불러오기
  const getAllData = () => {
    getEnsoEventTimeList();
    getEnsoEventContentList();
  };

  // 시작 날짜 변경 이벤트
  const handleStartDateChange = useCallback(
    e => {
      const { value } = e.target;
      setCategory({ ...category, startDate: parseDate("yyyy-mm-dd", value) , endDate: parseDate("yyyy-mm-dd", value) , firstDay : kendo.toString( value , 'yyyy-MM-01' ) });
    },
    [category],
  );

  // 상단 카테고리 검색버튼 클릭 이벤트
  const handleClick = useCallback(
    e => {
      e.preventDefault();
      category.isSearch = true;
      getAllData();
    },
    // eslint-disable-next-line
    [dataSource, category],
  );

  // 엑셀저장
  const ensoDownloadExcel = useCallback(e => {
    setLoading(true);
    e.preventDefault();
    const url = "/api/v2.2/stat/event/enso";
    const data = {
      startDate : category.startDate,
      endDate : category.endDate
    };
    const fileName = "경보_에너지원별_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    downloadExcel(data, fileName, url, setLoading);
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // getAllData();
  }, []);

  const warnCell = data => {
    return <td>{numFormat(data.dataItem.warn)}</td>;
  };
  const errCell = data => {
    return <td>{numFormat(data.dataItem.err)}</td>;
  };

  // const manaTmCell = data => {
  //   return <td>{numFormat(data.dataItem.manaTm)}</td>;
  // };
  //
  // // 대시보드 클릭 이벤트 (이벤트 이력 이동 (전체)) (20220222_jhchoi : 사용 안함 , 이벤트 이력에 에너지원 필수로 입력, 신호 갯수가 아닌 설비 갯수로 판단 해당 내용은 제외)
  // const clickEventWholeData = e => {
  //   window.location.href = "/mngt/analy/event/log?start=" + category.startDate + "&end=" + category.endDate;
  // };
  // // 대시보드 클릭 이벤트 (이벤트 이력 이동 (전체)) (20220222_jhchoi : 사용 안함 , 이벤트 이력에 에너지원 필수로 입력, 신호 갯수가 아닌 설비 갯수로 판단 해당 내용은 제외)
  // const clickEventWarnData = e => {
  //   window.location.href = "/mngt/analy/event/log?start=" + category.startDate + "&end=" + category.endDate + "&facilityState=7006";
  // };
  // // 대시보드 클릭 이벤트 (이벤트 이력 이동 (전체)) (20220222_jhchoi : 사용 안함 , 이벤트 이력에 에너지원 필수로 입력, 신호 갯수가 아닌 설비 갯수로 판단 해당 내용은 제외)
  // const clickEventErrData = e => {
  //   window.location.href = "/mngt/analy/event/log?start=" + category.startDate + "&end=" + category.endDate + "&facilityState=7007";
  // };

  return (
    <>
      <div
        className="ensoBody"
        style={
          loading
            ? {
                opacity: 0.6,
                pointerEvents: "none",
              }
            : null
        }>
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <div className="ensoCate">
              <span>날짜검색</span>
              <span>
                <DatePicker
                  format={"yyyy-MM-dd"}
                  defaultValue={new Date()}
                  max={new Date()}
                  value={new Date(category.startDate)}
                  onChange={handleStartDateChange}
                />
              </span>
              <span>
                <Button className="inqBtn" onClick={handleClick}>
                  <Search className="material-icons" />
                </Button>
              </span>
            </div>
            { category.isSearch === true ? 
              (<>
                <div className="ensoBoard">
                  <div className={styles.ensoBoardSub} >
                    <div>
                      <span>총발생 신호</span>
                      <br />
                      <span className="ensoBoardContent">
                        <b>{numFormat(dataSource.dashboard.total)}</b>
                        <span>개소</span>
                      </span>
                    </div>
                  </div>
                  <div className={styles.ensoBoardSub} >
                    <div>
                      <span>누적 경고 신호</span>
                      <br />
                      <span className="ensoBoardContent">
                        <b>{numFormat(dataSource.dashboard.totalWarn)}</b>
                        <span>개소</span>
                      </span>
                    </div>
                  </div>
                  <div className={styles.ensoBoardSub} >
                    <div>
                      <span>누적 고장 신호</span>
                      <br />
                      <span className="ensoBoardContent">
                        <b>{numFormat(dataSource.dashboard.totalErr)}</b>
                        <span>개소</span>
                      </span>
                    </div>
                  </div>
                  {/* <div className={styles.ensoBoardSubNotClick}>
                    <div>
                      <span>수동 조치시간</span>
                      <br />
                      <span className="ensoBoardContent">
                        {dataSource.dashboard.totalManaTm >= 60 && (
                          <>
                            {" "}
                            <b>{numFormat(dataSource.dashboard.actHour)}</b> <span>시간 </span> <b>{dataSource.dashboard.actMinute}</b>{" "}
                            <span>분</span>{" "}
                          </>
                        )}
                        {dataSource.dashboard.totalManaTm < 60 && (
                          <>
                            {" "}
                            <b>{dataSource.dashboard.actMinute}</b> <span>분</span>{" "}
                          </>
                        )}
                      </span>
                    </div>
                  </div> */}
                </div>

                <div className="ensoArticle">
                  <div className="ensoArticleSub">
                    <div className="ensoArticleSubTitle">
                      <h3>에너지원별 이벤트</h3>
                    </div>
                    <div className="ensoArticleEngCharts">
                      <div>
                        <EnsoPieCharts chartTitle="발생건수" data={dataSource.eventContent} />
                      </div>
                      {/* <div>
                        <EnsoPieCharts chartTitle="조치소요시간" data={dataSource.eventContent} />
                      </div> */}
                    </div>
                  </div>
                  <div className="ensoArticleSub">
                    <div className="ensoArticleSubTitle">
                      <h3>시간대별 경고/고장 상세정보</h3>
                      <Button className="excelBtn" icon="excel" onClick={ensoDownloadExcel}>
                        엑셀저장
                      </Button>
                    </div>
                    <div className="ensoArticleTimeCharts">
                      <EnsoLineCharts data={dataSource.eventTime} />
                    </div>
                    <div className="ensoArticleTimeGrid">
                      <Grid className={styles.ensoGrid} data={dataSource.eventContent}>
                        <GridColumn field="ensoType" title="에너지원" />
                        <GridColumn field="warn" title="경고" cell={warnCell} />
                        <GridColumn field="err" title="고장" cell={errCell} />
                        {/* <GridColumn field="manaTm" title="조치시간(분)" cell={manaTmCell} /> */}
                      </Grid>
                    </div>
                  </div>
                </div>
              </> )
              : 
              (<>
                <div className={styles.noDataCommnet}>
                  경보 데이터가 존재 하지 않습니다. 날짜를 선택하여 조회를 실행 해주세요.
                </div>
              </>)
            }
          </IntlProvider>
        </LocalizationProvider>
        {loading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </>
  );
};

export default Enso;
