import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import LocationMunicPrzt from "./locationMunicPrzt";

//service
import { getDong } from "../../../service/areaService";

//css
import "./locationMunic.css";
import {
  DEFAULT_MAP_LEVEL,
  // MAX_RGN_MAP_LEVEL,
  // MAX_DONG_MAP_LEVEL,
  // MAX_MARKER_MAP_LEVEL,
  MIN_CITY_MAP_LEVEL,
  MIN_RGN_MAP_LEVEL,
  MIN_DONG_MAP_LEVEL,
  AREA_CITY,
  AREA_RGN,
  AREA_DONG,
  AREA_MARKER,
  DEFAULT_LATI,
  DEFAULT_LONGI,
  setMapPosition,
  removeMarkers,
  // getMapLevel,
  // getClusterStyle,
} from "../../../com/kakaoMapFunc";
import { getLocMunicData, getMunicMarker, getMunicNInstDong } from "../../../service/location/locationMunicService";
import log from "./../../../com/log";
import { MunicMeainInfo } from "./municMeainInfo.jsx";
// import { cheonanGeo } from "./cheonanHDong";
import {
  getEficPowerDataResource,
  getMeainCntDataResource,
  getSmpRecCerDataResource,
  // getSolaDongDataResource,
} from "./../../../service/monitor/municService";
import { initMunicDataSource } from "../../monitor/municipality/municData";

const LocationMunic = props => {
  // const geo = cheonanGeo;
  const { conn, qs, rgnList, setContentLayoutMargin } = props;
  // 카카오 적용
  const { kakao } = window;
  const geocoder = new kakao.maps.services.Geocoder();
  const [loading, setLoading] = useState(false);
  const [navigatorTerm, setNavigatorTerm] = useState("전국");
  // const [map, setMap] = useState("");
  // const [areaDataList, setAreaDataList] = useState([]);
  // const [marker, setMarker] = useState([]);
  // 에너지원별 개수, 용량 통계
  const [gridData, setGridData] = useState([]);
  const [rghtWindow, setRghtWindow] = useState({ visible: false, ensoTypeCode: 15001, installerId: "", rgnCode: "" });
  const [profitData, setProfitData] = useState(initMunicDataSource);
  //profit 부분 콜백데이터
  let callbackData = {};
  // 히스토리 사용
  const history = useHistory();
  let location = useLocation();

  let { cityProvCode, rgnCode, dongCode } = qs.parse(location.search);
  const rgnObj = rgnList.filter(c => c.rgnCode === rgnCode)[0];

  // 검색 조건
  // const [search, setSearch] = useState({
  //   cityProvCode: cityProvCode ? cityProvCode : "",
  //   rgnCode: rgnCode ? rgnCode : "",
  //   dongCode: dongCode ? dongCode : "",
  //   bldSrvCodeNo: "",
  //   bizSct: "",
  //   bizYy: "",
  //   cityProvTerm: "",
  //   rgnTerm: "",
  //   dongTerm: "",
  //   lati: rgnObj ? rgnObj.lati : "",
  //   longi: rgnObj ? rgnObj.longi : "",
  //   mapLevel: DEFAULT_MAP_LEVEL,
  //   mapAddressCode: "",
  //   mapLevelTerm: "전체 시도별",
  //   type: AREA_CITY,
  // });

  const search = {
    cityProvCode: cityProvCode ? cityProvCode : "",
    rgnCode: rgnCode ? rgnCode : "",
    dongCode: dongCode ? dongCode : "",
    bldSrvCodeNo: "",
    bizSct: "",
    bizYy: "",
    cityProvTerm: "",
    rgnTerm: "",
    dongTerm: "",
    lati: rgnObj ? rgnObj.lati : "",
    longi: rgnObj ? rgnObj.longi : "",
    mapLevel: DEFAULT_MAP_LEVEL,
    mapAddressCode: "",
    mapLevelTerm: "전체 시도별",
    type: AREA_CITY,
  }

  // 지도에 표시된 마커 객체
  let markers = useRef([]);

  // 네비게이터 설정
  const setNavigator = s => {
    const { mapLevel } = s;
    let rgnTitle = "";
    const cityTitle = mapLevel < MIN_CITY_MAP_LEVEL ? (s.cityProvCode ? s.cityProvTerm : " ") : "";
    if (mapLevel < MIN_DONG_MAP_LEVEL) {
      rgnTitle = mapLevel < MIN_RGN_MAP_LEVEL ? (s.rgnCode ? " > " + s.rgnTerm : " ") : "";
    } else {
      rgnTitle = mapLevel < MIN_RGN_MAP_LEVEL ? (s.rgnCode ? " > " + s.rgnTerm.substring(0, 3) : " ") : "";
    }
    const dongTitle = mapLevel < MIN_DONG_MAP_LEVEL ? (s.fixbylawBundCode ? " > " + s.dongTerm : " ") : "";
    setNavigatorTerm(cityTitle + rgnTitle + dongTitle);
  };

  // 클러스터 생성 -- 추후
  // const setClusterList = (areaInfoList, map, type) => {
  //   // 이전 마커 삭제
  //   removeMarkers(markers, search);
  //   areaInfoList.forEach(areaInfo => {
  //     let clusterStyle = getClusterStyle(map.getLevel(), areaInfo.rate, areaInfo.instRate);
  //     let content =
  //       '<div class = "areaCluster ' +
  //       clusterStyle.className +
  //       ' " id ="' +
  //       areaInfo.areaCode +
  //       '">' +
  //       areaInfo.areaTerm +
  //       "<br>" +
  //       // areaInfo.cntTotal +
  //       "" +
  //       "</div>";
  //     let position = new kakao.maps.LatLng(areaInfo.lati, areaInfo.longi);
  //     let clusterMarker = new kakao.maps.CustomOverlay({
  //       position: position,
  //       content: content,
  //     });
  //     markers.current.push(clusterMarker);
  //     clusterMarker.setMap(map);

  //     const cluster = document.getElementById(areaInfo.areaCode);
  //     if (cluster) {
  //       cluster.style.width = clusterStyle.len;
  //       cluster.style.height = clusterStyle.len;
  //       cluster.style.fontSize = clusterStyle.fontSize;
  //       if (cluster) {
  //         cluster.addEventListener("click", () => {
  //           const s = {
  //             ...search,
  //             ...{
  //               dongCode: type === AREA_DONG ? areaInfo.areaCode : "",
  //               dongTerm: type === AREA_DONG ? areaInfo.areaTerm : "",
  //               rgnCode: type === AREA_RGN ? areaInfo.areaCode : "", // result[0].code.substring(0,5),
  //               rgnTerm: type === AREA_RGN ? areaInfo.areaTerm : "",
  //               cityProvCode: type === AREA_CITY ? areaInfo.areaCode : "", // result[0].code.substring(0,2),
  //               cityProvTerm: type === AREA_CITY ? areaInfo.areaTerm : "",
  //               mapLevel: getMapLevel(type, map),
  //               lati: areaInfo.lati,
  //               longi: areaInfo.longi,
  //             },
  //           };
  //           getAreaInstallerList(map, s, true);
  //         });
  //       }
  //     }
  //   });
  // };

  // 데이터 불러오기 (현재 level에 따라 city, rgn, dong ,meain 동적 호출)
  const getAreaInstallerList = (map, s, isClusterClick = false) => {
    // setMap(map);

    // 위치 이동
    if (isClusterClick) {
      setMapPosition(kakao, s.lati, s.longi, map);
      map.setLevel(s.mapLevel); // map 의 zoom_change 함수 실행 시킨다.
    }
    setNavigator(s);

    if (s.mapLevel <= DEFAULT_MAP_LEVEL && s.mapLevel >= MIN_CITY_MAP_LEVEL) {
      s = { ...s, ...{ type: AREA_CITY, cityProvCode: "", rgnCode: "", dongCode: "" } };
      // getMunicNInstCity(conn).then(result => {
      //   setAreaDataList(result);
      //   setClusterList(result, map, AREA_CITY);
      // });
    } else if (s.mapLevel < MIN_CITY_MAP_LEVEL && s.mapLevel >= MIN_RGN_MAP_LEVEL) {
      s = { ...s, ...{ type: AREA_RGN, rgnCode: "", dongCode: "" } };
      // getMunicNInstRgn(conn).then(result => {
      //   setAreaDataList(result);
      //   setClusterList(result, map, AREA_RGN);
      // });
    } else if (s.mapLevel < MIN_RGN_MAP_LEVEL && s.mapLevel >= MIN_DONG_MAP_LEVEL) {
      s = { ...s, ...{ type: AREA_DONG, fixbylawBundCode: "" } };
      getProfitData(s);
      getMunicNInstDong(conn, s).then(result => {
        console.log("getMunicNInstDong result = ", result);
        // setAreaDataList(result.municMapClusterDataDtoList);
        // setClusterList(result.municMapClusterDataDtoList, map, AREA_DONG);
        setGridData(result.ensoMeainCntList);
      });
    } else if (s.mapLevel < MIN_DONG_MAP_LEVEL && s.mapLevel >= 0) {
      s = { ...s, ...{ type: AREA_MARKER, cityProvCode: "" } };
      getProfitData(s);
      getLocMunicData(conn, s)
        .then(result => {
          console.log("getLocMunicData result = ", result);
          setGridData(result.ensoMeainCntList);
        })
        .catch(e => log(e, "getLocMunicDataError"));
      // dispalyArea(s);
    }
  };

  // //폴리곤 그리기 - 추후
  // const dispalyArea = s => {
  //   const geoPoly = geo.features.find(e => e.properties.adm_cd2 === s.hangCode);
  //   let resultList = [];
  //   geoPoly.geometry.coordinates[0][0].forEach(e => {
  //     resultList.push(new kakao.maps.LatLng(e[0], e[1]));
  //   });

  //   // 다각형을 생성합니다
  //   const polygon = new kakao.maps.Polygon({
  //     map: map, // 다각형을 표시할 지도 객체
  //     path: resultList,
  //     strokeWeight: 2,
  //     strokeColor: "#004c80",
  //     strokeOpacity: 0.8,
  //     fillColor: "#fff",
  //     fillOpacity: 0.7,
  //   });
  // };

  // 마크 그리기(읍면동별)
  const setMarkerList = (meainList, map) => {
    //이전에 열려있던 infowindow 삭제 이벤트
    const allInfoWinCloseEvt = () => {
      if (document.getElementsByName("closeInfoBtn")[0]) document.getElementsByName("closeInfoBtn")[0].click();
    };
    // closeBtnEVt 추가
    const closeBtnEVt = (map, marker, infoWindow) => {
      const closeBtn = document.getElementsByName("closeInfoBtn")[0];
      closeBtn.addEventListener("click", function () {
        infoWindow.close();
      });
    };
    // 이전 마커 삭제
    removeMarkers(markers, search);

    // 위치 정보 중복 처리 로직 추가 하기 (설비가 만약 천안시 중복으로 있을 경우 위치 정보 중복 제거로 마크를 중복으로 찍기 안하기 위해 작업)
    const meainLocationList = meainList.filter((item, i) => {
      return (
        meainList.findIndex((item2, j) => {
          return item.userId === item2.userId && item.lati === item2.lati && item.longi === item2.longi;
        }) === i
      );
    });

    meainLocationList.forEach(meain => {
      if (meain.syncYn === "Y") {
        // 마커이미지의 주소입니다
        const imageSrc = "/images/mark/mark_ready.png";
        // if (meain.meainStatCodeNo === 7001) {
        //   imageSrc = "/images/mark/mark_ready.png";
        // } else if (meain.meainStatCodeNo === 7002) {
        //   imageSrc = "/images/mark/mark_normal.png";
        // } else if (meain.meainStatCodeNo === 7004) {
        //   imageSrc = "/images/mark/mark_unoper.png";
        // } else if (meain.meainStatCodeNo === 7006) {
        //   imageSrc = "/images/mark/mark_warn.png";
        // } else if (meain.meainStatCodeNo === 7007) {
        //   imageSrc = "/images/mark/mark_error.png";
        // } else {
        //   imageSrc = "/images/mark/mark_error.png";
        // }
        const marker = new kakao.maps.Marker({
          position: new kakao.maps.LatLng(meain.lati, meain.longi),
          image: new kakao.maps.MarkerImage(imageSrc, new kakao.maps.Size(27, 32)), // 마커이미지 설정
          clickable: true,
        });

        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);
        markers.current.push(marker);

        const infoWindow = new kakao.maps.InfoWindow({
          position: new kakao.maps.LatLng(meain.lati, meain.longi),
          content: MunicMeainInfo(meain),
        });
        // 마커에 클릭이벤트를 등록합니다
        kakao.maps.event.addListener(marker, "mouseover", () => {
          allInfoWinCloseEvt();
          infoWindow.open(map, marker);
          closeBtnEVt(map, marker, infoWindow);
        });
        kakao.maps.event.addListener(marker, "mouseout", () => infoWindow.close());
        kakao.maps.event.addListener(marker, "click", () => {
          setRghtWindow({ visible: true, ensoTypeCode: meain.ensoTypeCode, installerId: meain.userId, rgnCode: "44130" });
        });
      } else {
        const imageSrc = "/images/mark/icon_red.png";
        // const imageSrc = "/images/mark/icon_yel.png";
        const marker = new kakao.maps.Marker({
          position: new kakao.maps.LatLng(meain.lati, meain.longi),
          image: new kakao.maps.MarkerImage(imageSrc, new kakao.maps.Size(5, 5)), // 마커이미지 설정
          clickable: true,
        });
        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);
        markers.current.push(marker);
        const infoWindow = new kakao.maps.InfoWindow({
          position: new kakao.maps.LatLng(meain.lati, meain.longi),
          content: MunicMeainInfo(meain),
        });
        kakao.maps.event.addListener(marker, "click", () => {
          allInfoWinCloseEvt();
          infoWindow.open(map, marker);
          closeBtnEVt(map, marker, infoWindow);
        });
      }
    });
  };

  // 현재 보고있는 위치의 info 가져오기
  const getLocationInfo = (map, evtName) => {
    const latlng = map.getCenter();
    geocoder.coord2RegionCode(map.getCenter().getLng(), map.getCenter().getLat(), result => {
      // console.log("result = ", result);
      if (evtName !== "center_changed")
        // console.log("result[0].code = ", result[0].code);
        // 틀별자치구 컨버터
        converTerDongCode(result[0]);
        // console.log("result[0].code.substring(0, 8)  = ", result[0].code.substring(0, 8) + "00");
        getDong(conn, result[0].code.substring(0, 8) + "00")
          .then(r => {
            const s = {
              lati: latlng.getLat(),
              longi: latlng.getLng(),
              mapLevel: map.getLevel(),
              cityProvCode: r.provinceCode,
              rgnCode: r.rgnCode,
              fixbylawBundCode: r.fixbylawBundCode,
              cityProvTerm: r.provinceTerm,
              rgnTerm: r.rgnTerm,
              dongTerm: r.fixbylawBundTermSimple,
              hangCode: result[1].code,
            };
            getAreaInstallerList(map, s);
          })
          .catch(e => log(e));
    });
  };


  // 컨버터 추가  ( 강원도 -> 강원 특별시  -> 강원도 법정동 코드)
  const converTerDongCode = (obj) => {
    // console.log("obj = " , obj);
    const code = obj.code.substring(0, 8) + "00";
    // 강원도 특별시 정선읍 법정동 코드를 기존 강원도 정선읍 법정동 코드로 변경
    if ( code === "5177035000" ){  // 정선 임계면
      obj.code = "4277035000";
    } else if ( code === "5177025000" ){  // 정선 정선읍
      obj.code = "4277025000";
    } else if ( code === "5177034000" ){  // 정선 북평면
      obj.code = "4277034000";
    } else if ( code === "5177037000" ){   // 정선 북평면
      obj.code = "4277037000";
    } else if ( code === "5177036000" ){  // 정선 화암면
      obj.code = "4277036000";
    } else if ( code === "5177025900" ){  // 정선 신동읍
      obj.code = "4277025900";
    } else if ( code === "5177032000" ){  // 정선 남면
      obj.code = "4277032000";
    } else if ( code === "5177025600" ){  // 정선 사북읍
      obj.code = "4277025600";
    } else if ( code === "5177025300" ){  // 정선 사북읍
      obj.code = "4277025300";
    }

    return obj;
  }



  // 맵 로드
  const loadMap = (lati, longi, rgnCode) => {
    setLoading(true);
    const map = new kakao.maps.Map(document.getElementById("locationMunicMap"), {
        center: new kakao.maps.LatLng(lati, longi), //지도의 중심좌표.
        level: MIN_DONG_MAP_LEVEL - 1, //지도의 레벨(확대, 축소 정도)
        mapTypeId: 1,
        maxLevel: MIN_DONG_MAP_LEVEL,
      });
    // customOverlay = new kakao.maps.CustomOverlay({});
    // setMap(map);
    kakao.maps.event.addListener(map, "zoom_changed", () => getLocationInfo(map, "zoom_changed"));
    kakao.maps.event.addListener(map, "dragend", () => getLocationInfo(map, "dragend"));
    getLocationInfo(map, "dragend");
    getMunicMarker(conn, rgnCode)
      .then(result => {
        setLoading(false);
        console.log("setMarkerList result = ", result);
        // setMarker(result.markerList);
        setMarkerList(result.markerList, map);
      })
      .catch(e => {
        setLoading(false);
      });
  };

  const closeRghtWindow = () => {
    setRghtWindow({ ...rghtWindow, visible: false });
  };

  const callBackFunc = result => {
    console.log("result =", result);
    callbackData = { ...callbackData, ...result };
  };
  const getRejection = error => {
    log(error, "getAllDataResourceResult Error");
  };

  const getProfitData = param => {
    Promise.all([
      //1.smpreccer
      getSmpRecCerDataResource(conn, param, callBackFunc, getRejection),
      //2. 오늘,어제 power,효율 eficdata
      getEficPowerDataResource(conn, param, callBackFunc, getRejection),
      //3. 설비개수,발전량,용량 soladongdata
      // getSolaDongDataResource(conn, param, callBackFunc, getRejection),
      //4. 에너지원별 통계 meaincnt
      getMeainCntDataResource(conn, param, callBackFunc, getRejection),
    ]).then(results => {
      setLoading(false);
      setProfitData({ ...profitData, ...callbackData });
    });
  };

  useEffect(() => {
    setContentLayoutMargin(history.location.pathname);
    const historyState = history.location.state;
    if (historyState) loadMap(historyState.lati, historyState.longi, historyState.rgnCode);
    else loadMap(DEFAULT_LATI, DEFAULT_LONGI, 44130);
    /* eslint-disable-next-line */
  }, []);

  return (
    <>
      <LocationMunicPrzt
        conn={conn}
        navigatorTerm={navigatorTerm}
        gridData={gridData}
        rghtWindow={rghtWindow}
        closeRghtWindow={closeRghtWindow}
        profitData={profitData}
        loading={loading}
      />
    </>
  );
};

export default LocationMunic;
