import React, { useState, useEffect } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import kendo from "@progress/kendo-ui";

import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Input } from "@progress/kendo-react-inputs";
import styles from "./consm.module.css";

import { Button } from "@progress/kendo-react-buttons";
import { CustomCalendar } from "./customCalender";
import ReportConsmPrintModal from "./reportConsmPrintModal";
import { getPowerCompare, getEnergyMonth, getEnergyCount, getEnergyWarn } from "../../../service/report/reportConsmService";
import UserSearchList from "../../com/userSearch/userSearchList";

function ConsmSearch({
  conn,
  search,
  setSearch,
  selectMeain,
  setSelectMeain,
  energyData,
  setEnergyData,
  energyCount,
  setEnergyCount,
  chartMonthData,
  chartWarn,
  setChartWarn,
  setChartMonthData,
  chartYearData,
  setChartYearData,
  setLoading,
}) {
  const [titleName, setTitleName] = useState("");
  const [locale, /*setLocale*/] = useState("ko");
  // 윈도우 창 Visible
  let [userInfoVisible, setUserInfoVisible] = useState(false);

  const [modalItem, setModalItem] = useState({
    visible: false,
  });

  let today = new Date();
  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  useEffect(() => {
    if (search.consmId) {
      getReportConsmList();
    }
  // eslint-disable-next-line
  }, [search]);

  // 날짜 선택 이벤트
  const selectCalendar = e => {
    setSearch({
      ...search,
      dtm: e.target.value,
    });
  };

  // console.log(modalItem);
  const print = () => {
    // window.open('','보고서 프린트', width=430, height=500, location=no, status=no, scrollbars=yes');
    // window.print();
    setModalItem({ ...modalItem, visible: true });
  };

  // 설치자 검색 버튼 클릭 이벤트
  const searchUserId = () => {
    setUserInfoVisible(!userInfoVisible);
  };

  // 그리드에서 유저 선택 시 이벤트
  const selectUserId = e => {
    setTitleName(e.dataItem.userTerm);
    setSearch({
      ...search,
      consmId: e.dataItem.userId,
      userTerm: e.dataItem.userTerm,
    });
    setUserInfoVisible(false);
  };

  const getReportConsmList = () => {
    setLoading(true);
    Promise.all([
      getEnergyMonth(conn, getEnergyCallback, getRejection, search),
      getEnergyCount(conn, getEnergyCountCallback, getRejection, search),
      getEnergyWarn(conn, getEnergyWarnCallback, getRejection, search),
      getPowerCompare(conn, getCallback, getRejection, search),
    ])
      .then(results => {
        setLoading(false);
      })
      .catch(error => {
        kendo.alert("데이터 조회 실패");
        console.log("error", error);
        setLoading(false);
      });
  };

  // const getReportConsmList = () => {
  //   setEnergyData(getEnergyData);
  //   setEnergyCount(getEnergyCount);
  //   setChartWarn(getChartWarn);
  //   setSelectMeain(getSelectMeain);
  //   setChartMonthData(getChartMonthData);
  //   setChartYearData(getChartYearData);
  // };

  const getEnergyCallback = dataList => {
    console.log("dataList = ", dataList);
    setEnergyData(dataList);
  };

  const getEnergyCountCallback = powerCount => {
    setEnergyCount(powerCount);
  };

  const getEnergyWarnCallback = powerCount => {
    setChartWarn(powerCount);
  };

  const getCallback = (gridList, chartMonth, chartYear) => {
    setSelectMeain(gridList);
    setChartMonthData(chartMonth);
    setChartYearData(chartYear);
  };

  // rejection
  const getRejection = (name, error) => {
    if (error.errCode === "F011") {
      kendo.alert("수용가를 검색해주세요");
      return;
    }
  };

  return (
    <>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="reportDayBackground"> {titleName}&nbsp;월간 종합 운영 보고서 </div>
          <div className="reportDayBackground"> Monthly Operation Report </div>
          <div className="reportDayBackground">
            보고일 : &nbsp;
            <LocalizationProvider language={locale}>
              <IntlProvider locale={locale}>
                <DatePicker
                  defaultValue={today}
                  // onChange={(e)=>setSearch({...search, dtm: kendo.toString(e.target.value , 'yyyy-MM')}, [search])}
                  onChange={selectCalendar}
                  format={"yyyy-MM"}
                  calendar={props => <CustomCalendar {...props} topView={"year"} bottomView={"year"} max={yesterday} />}
                />
              </IntlProvider>
            </LocalizationProvider>
            <b> &nbsp; &nbsp;수용가 검색 &nbsp;</b>
            <Input
              id="userTerm"
              type="text"
              className={styles.SetSearchTerm}
              // className = "SetSearchTerm"
              placeholder="설치자"
              readOnly
              value={search.userTerm ? search.userTerm : ""}
              onClick={searchUserId}
            />
            <Button className={styles.consmListBtn} icon="search" onClick={searchUserId}></Button>
            {userInfoVisible && <UserSearchList conn={conn} setUserInfo={selectUserId} setVisible={setUserInfoVisible} />}
            <img onClick={print} src="/images/printer.png" alt=" " style={{ cursor: "pointer", position: "absolute", right: 50 }} />
          </div>
        </div>
      </div>
      <div className="uk-gridConsmSeach">
        {/* <div className="uk-grid"> */}
        {modalItem.visible && (
          <ReportConsmPrintModal
            search={search}
            energyData={energyData}
            energyCount={energyCount}
            selectMeain={selectMeain}
            chartWarn={chartWarn}
            chartMonthData={chartMonthData}
            chartYearData={chartYearData}
            setModalItem={setModalItem}
          />
        )}
      </div>
    </>
  );
}

export default ConsmSearch;
