// React
import React, { useState, useEffect, useCallback } from "react";
import { BeatLoader } from "react-spinners"; // 로딩스피너

// Kendo
import kendo from "@progress/kendo-ui";
import { Dialog, Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
// import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
// import { Search } from "@material-ui/icons";
// import { Upload } from "@progress/kendo-react-upload";

// Service
import BizProgressGrid from "./bizProgressGrid";
import { getCalCityList, getCalRgnList } from "../../../../com/dataUtil";
// import BizProgressStatistics from "./statistics/bizProgressStatistics";
import BizProgressSearch from "./bizProgressSearch";
import BizProgressContents from "./bizProgressContents";
import BizProgressStatisticsRateModal from "./statistics/bizProgressStatisticsRateModal";
import BizProgressMobileGrid from "./bizProgressMobileGrid";

import BizProgressEnergyImg from "./stepImg/bizProgressEnergyImg";
import { makeNavigator } from "../commonFuc";
import {
  getProgress,
  setAgressment,
  progressMonth,
  progressRate,
  progressWeek,
  getProgressImgListData,
} from "../../../../service/bizStat/com/progressApiService";

// CSS
import stylesProgress from "./bizProgress.module.css";

const BizProgress = ({ conn, codeList, cityList, rgnList, styles, isMobile, search, setSearch, Mobile }) => {
  // 시도 , 시군구 리스트 가공
  let citys = getCalCityList(cityList);
  let rgns = getCalRgnList(rgnList.filter(e => e.cityProvCode === search.cityProvCode && e.rgnCode !== "44131" && e.rgnCode !== "44133"));

  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;

  // const [show, setShow] = useState(false);
  const show = false;

  // 데이터리스트
  const [dataList, setDataList] = useState({
    instl: {},
    grid: {
      data: [],
      total: 0,
    },
    instList: [
      {
        instlTerm: "",
        instlPlnnm: "",
        instlAddr: "",
        instlCelphon: "",
        instlCntct: "",
        newbuild: "",
        classFaci: "",
        etc: "",
        cityNewdeal: "",
        rgnCode: "",
        cityProvCode: "",
        bizYy: "",
        createUpdateYn: false,
        delYn: "",
        regiDtm: "",
        moniYn: "N",
      },
    ],
    progressWeekList: {
      39001: {
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
      },
      39002: {
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
      },
      39003: {
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
      },
      39004: {
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
      },
      39005: {
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
      },
      total: {
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
      },
    },
    progressMonthList: [],
    convProgressRateList: [],
    convProssCnt: {
      sola: 0,
      heat: 0,
      velo: 0,
      heath: 0,
      fuel: 0,
      moni: 0,
    },
    imgFile: {
      step1: {},
      step2: {},
      step3: {},
      step4: {},
      moni: {},
    },
    // 진척률 - 단계별 이미지 ( 서버에 올린 이미지가 아닌 미리보기 이미지 )
    previewFile: {
      step1: {},
      step2: {},
      step3: {},
      step4: {},
      moni: {},
    },
  });

  // 기본정보, 설비내역, 서류업로드 윈도우
  const [windowState, setWindowState] = useState({
    visible: false,
    left: isMobile === "Y" ? 0 : 350,
    top: 0,
    width: isMobile === "Y" ? innerWidth : 1700,
    height: isMobile === "Y" ? innerHeight : 700,
    formItem: [],
  });

  const staticProssAdd = e => {
    // console.log("search", search);
    // if(search.rgnCode != "" && search.bizYyNo != "") {
    setWindowState(state => {
      const item = { ...state };
      item.visible = !windowState.visible;
      return item;
    });

    if (e?.instlPlnnm) {
    } else {
      initalInputs();
    }
    getConvProgressRateCountDataList();
  };

  const initalInputs = () => {
    // console.log("inital");
    setDataList(dataList => {
      const item = dataList;
      item.convProssCnt.sola = 0;
      item.convProssCnt.heat = 0;
      item.convProssCnt.velo = 0;
      item.convProssCnt.heath = 0;
      item.convProssCnt.fuel = 0;
      item.convProssCnt.moni = 0;
      return item;
    });

    // console.log("inputs",inputs);
    getConvProgressRateCountDataList();
  };

  // 데이터 불러오기 (검색 부분에서 찾을 시)
  const getData = () => {
    search.progress.paging.take = 10;
    search.progress.paging.skip = 0;
    search.saveBizYyNo = search.bizYyNo;
    search.saveCityProvTerm = search.city.cityProvTerm;
    search.saveRgnTerm = search.rgn.rgnTerm;
    // console.log("convProgress search = ", search);

    // 네비게이션 설정
    makeNavigator(search);
    // 데이터 불러오기
    getDs();
  };

  // 로딩스피너
  const [loading, setLoading] = useState(false);

  // 데이터 불러오기 ( 설치 계획서 목록)
  const getDs = () => {
    setLoading(true);
    // 날짜 설정
    setSearchDtm();
    // 월별 날짜 설정
    setSearchMonth();
    // 왼쪽 - 사업추친 진행 상황 - 설치 계획서 번호
    getConvProgressInfoList();
    // 오른쪽 - 사업추친 진행 상황 - 주별 진행률
    getProgressWeek();
    // 오른쪽 - 사업추친 진행 상황 - 월별 진행률
    getProgressMonthList();
    // 오른쪽 - 사업추친 진행 상황 - 추진현황 전체대비 진행률
    getConvProgressRateCountDataList();

    // setLoading(false);
  };

  // 검색 데이터 날짜 설정
  const setSearchDtm = () => {
    const year = search.bizYy !== "사업연도" ? search.bizYy : kendo.toString(new Date(), "yyyy");
    search.date.startGathDtm = year + "-01-01";
    search.date.endGathDtm = year + "-12-31";
  };

  // 월별 날짜 선택시 검색 날짜 설정
  const setSearchMonth = () => {
    const year = search.bizYy !== "사업연도" ? search.bizYy : kendo.toString(new Date(), "yyyy");
    const month = kendo.toString(search.month, "MM");
    const dayMonth = new Date(year, month, 0).getDate();
    // console.log("month", month);
    if (month === "01") {
      for (let cnt = 1; cnt < 10; cnt++) {
        let week1 = new Date(year, 0, cnt).getDay();
        // console.log("week1", new Date(year, 0, cnt), week1, cnt);

        if (week1 === 1) {
          // 1월인 경우 월요일부터 1주차
          search.monthDate.startGathDtm = year + "-" + month + "-0" + cnt;
          break;
        }
      }
    } else {
      search.monthDate.startGathDtm = year + "-" + month + "-01";
    }
    search.monthDate.endGathDtm = year + "-" + month + "-" + dayMonth;
  };

  // 사업추친 진행 상황 - 설치 계획서 번호
  const getConvProgressInfoList = () => {
    getProgress(conn, getConvProgressList, getRejection, setLoading, search);
  };

  const getConvProgressList = result => {
    if (result.total > 0) {
      setDataList(dataList => {
        const item = { ...dataList };
        item.grid.data = result.results;
        item.grid.total = result.total;
        return item;
      });
    } else {
      setDataList(dataList => {
        const item = { ...dataList };
        item.grid.data = [];
        item.grid.total = 0;
        return item;
      });
    }
  };

  // 사업추친 진행 상황 - 주별 진행률
  const getProgressWeek = () => {
    progressWeek(conn, search, getWeekDataList, getRejection);
  };

  // 주간 데이터 리스트를 가공하여 데이터 설정
  const getWeekDataList = result => {
    const weekList = result;
    // 데이터 설정
    setDataList(dataList => {
      const item = { ...dataList };

      // 초기화 코드
      const ensoList = [39001, 39002, 39003, 39004, 39005, "total"];
      ensoList.forEach(enso => {
        for (let index = 1; index <= 5; index++) {
          item.progressWeekList[enso]["week" + index] = 0;
        }
      });

      // 데이터 설정
      if (weekList.length > 0) {
        weekList.forEach(value => {
          if (value.energyTypeCode) {
            // 에너지원
            const ensoData = item.progressWeekList[value.energyTypeCode];
            ensoData["week" + value.weekValue] = value.meainCount ? value.meainCount : 0;
            // 총계 데이터
            const totalData = item.progressWeekList.total;
            totalData["week" + value.weekValue] += value.meainCount ? Number(value.meainCount) : 0;
          }
        });
      }
      return item;
    });
  };

  // 사업추친 진행 상황 - 월별 진행률
  const getProgressMonthList = () => {
    progressMonth(conn, search, getProgressMonthDataList, getRejection);
  };

  const getProgressMonthDataList = result => {
    setDataList(dataList => {
      const item = { ...dataList };
      item.progressMonthList = result;
      return item;
    });
  };

  // 사업추진 진행 상황 - 전체대비 진행률
  const getConvProgressRateCountDataList = () => {
    progressRate(conn, search, getConvProgressRateCountList, getRejection);
  };

  const getConvProgressRateCountList = result => {
    setDataList(dataList => {
      const item = { ...dataList };
      item.convProgressRateList = result;
      return item;
    });
  };

  // 썸네일 사진 불러오는 부분
  const getProgressImgList = () => {
    // conn , search , 사업코드 : 융복합 , callback , rejection
    getProgressImgListData(conn, search, getProgressImgListDataResult, getRejection);
  };

  // 썸네일 사진 리스트 Reulst 함수
  const getProgressImgListDataResult = result => {
    // console.log("getProgressImgListDataResult result = ", result);
    if (result.length > 0) {
      setDataList(dataList => {
        const item = { ...dataList };
        // 미리보기 이미지 내용 초기화
        item.previewFile.step1 = {};
        item.previewFile.step2 = {};
        item.previewFile.step3 = {};
        item.previewFile.step4 = {};
        item.previewFile.moni = {};
        // 기존 이미지 초기화
        item.imgFile.step1 = {};
        item.imgFile.step2 = {};
        item.imgFile.step3 = {};
        item.imgFile.step4 = {};
        item.imgFile.moni = {};
        // 서버에서 불러온 이미지 적용
        result.forEach(element => {
          // console.log("element = ", element);
          if (element.instStep === "moni") {
            item.imgFile.moni = "data:image/png;base64," + element.mediaData.data;
          } else {
            item.imgFile[element.instStep] = "data:image/png;base64," + element.mediaData.data;
          }
        });
        return item;
      });
    } else {
      setDataList(dataList => {
        const item = { ...dataList };
        item.imgFile.step1 = {};
        item.imgFile.step2 = {};
        item.imgFile.step3 = {};
        item.imgFile.step4 = {};
        item.imgFile.moni = {};
        // 미리보기 이미지 내용 초기화
        item.previewFile.step1 = {};
        item.previewFile.step2 = {};
        item.previewFile.step3 = {};
        item.previewFile.step4 = {};
        item.previewFile.moni = {};
        return item;
      });
    }
  };

  // rejection 함수 - 공용
  const getRejection = error => {
    console.log("error = ", error);
    if (error.errCode === "IP001") {
      kendo.alert("사업 계획서 번호가 없습니다.");
      return;
    } else if (error.errCode === "IP005") {
      kendo.alert("에너지원이 없습니다. 설비를 등록 해주세요.");
      return;
    } else {
      kendo.alert("검색을 실패 하였습니다.");
      return;
    }
  };

  console.log('bizProgress');

  // 추가 모달창 저장/수정 처리
  const handleModalSaveSubmit = useCallback(    
    e => {      
      // console.log("e",e);
      // console.log("")
      // Vlidation Check
      // const isValid = validationCheck(e);
      // if( isValid.isValid === false ){
      //   kendo.alert(isValid.message);
      //   return;
      // }

      // 데이터 저장
      staticProssAdd();

      e.bizYy = search.bizYyNo;
      e.rgnCode = search.rgnCode;
      e.cityProvCode = search.cityProvCode;
      e.solaCnt = e.solaCnt !== "" && e.solaCnt ? e.solaCnt : 0;
      e.heatCnt = e.heatCnt !== "" && e.heatCnt ? e.heatCnt : 0;
      e.veloCnt = e.veloCnt !== "" && e.veloCnt ? e.veloCnt : 0;
      e.heathCnt = e.heathCnt !== "" && e.heathCnt ? e.heathCnt : 0;
      e.fuelCnt = e.fuelCnt !== "" && e.fuelCnt ? e.fuelCnt : 0;
      e.moniCnt = e.moniCnt !== "" && e.moniCnt ? e.moniCnt : 0;      

      setAgressment(conn, e, search)
        .then(response => {
          if (!response) {
            kendo.alert("저장을 실패 하였습니다.");
            return;
          }
          setWindowState({
            ...windowState,
            visible: !windowState.visible,
            formItem: [],
          });
          kendo.alert("저장을 완료 하였습니다.");
          getConvProgressRateCountDataList();
        })
        .catch(err => {
          kendo.alert("저장을 실패 하였습니다.");
        }); 

    /* eslint-disable-next-line */       
    },[dataList]);

  useEffect(() => {
    getData();
    /* eslint-disable-next-line */
  }, []);

  return (
    <>
      {Mobile ? (
        <>
          <div className={stylesProgress.JinTabCon} id="JinTab_con">
            <BizProgressSearch
              search={search}
              setSearch={setSearch}
              rgns={rgns}
              citys={citys}
              getData={getData}
              codeList={codeList}
              isMobile={Mobile}
            />
            <div className={loading ? [styles.convProject, styles.convProjectTable].join(" ") : styles.convProjectTable}>
              <div class="uk-grid">
                <BizProgressMobileGrid
                  conn={conn}
                  search={search}
                  getData={getDs}
                  paging={search.progress.paging}
                  dataList={dataList}
                  setDataList={setDataList}
                  grid={dataList.grid}
                  setSearch={setSearch}
                  stylesProgress={stylesProgress}
                  isMobile={Mobile}
                  Grid={Grid}
                  GridColumn={GridColumn}
                  GridNoRecords={GridNoRecords}
                  IntlProvider={IntlProvider}
                  LocalizationProvider={LocalizationProvider}
                />
                {show ? (
                  <BizProgressEnergyImg
                    conn={conn}
                    search={search}
                    setSearch={setSearch}
                    dataList={dataList}
                    stylesProgress={stylesProgress}
                    isMobile={Mobile}
                    useState={useState}
                    useCallback={useCallback}
                    kendo={kendo}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <dd>
          <div className={stylesProgress.JinTabCon} id="JinTab_con">
            <BizProgressSearch search={search} setSearch={setSearch} rgns={rgns} citys={citys} getData={getData} codeList={codeList} />
            <div className={loading ? [styles.convProject, styles.convProjectTable].join(" ") : styles.convProjectTable}>
              <div class="uk-grid">
                <BizProgressGrid
                  conn={conn}
                  search={search}
                  getData={getDs}
                  paging={search.progress.paging}
                  dataList={dataList}
                  setDataList={setDataList}
                  grid={dataList.grid}
                  getProgressImgList={getProgressImgList}
                  useState={useState}
                  kendo={kendo}
                  Window={Window}
                  Grid={Grid}
                  GridColumn={GridColumn}
                  GridNoRecords={GridNoRecords}
                  IntlProvider={IntlProvider}
                  LocalizationProvider={LocalizationProvider}
                  Button={Button}
                />
                {/* <ConvProgressStatistics conn = {conn} search = {search} setSearch = {setSearch} dataList={dataList} 
            setSearchMonth = {setSearchMonth} getProgressWeek = {getProgressWeek} staticProssAdd = {staticProssAdd} windowState = {windowState} /> */}
                <BizProgressContents
                  conn={conn}
                  stylesProgress={stylesProgress}
                  search={search}
                  setSearch={setSearch}
                  dataList={dataList}
                  setSearchMonth={setSearchMonth}
                  getProgressWeek={getProgressWeek}
                  staticProssAdd={staticProssAdd}
                  windowState={windowState}
                  isMobile={Mobile}
                  useState={useState}
                  useCallback={useCallback}
                  kendo={kendo}
                />
                {windowState.visible && (
                  <BizProgressStatisticsRateModal
                    stylesProgress={stylesProgress}
                    useCallback={useCallback}
                    Dialog={Dialog}
                    Form={Form}
                    Field={Field}
                    FormElement={FormElement}
                    Input={Input}
                    Button={Button}
                    windowState={windowState}
                    staticProssAdd={staticProssAdd}
                    handleModalSaveSubmit={handleModalSaveSubmit}
                  />
                )}
              </div>
            </div>
            {loading && (
              <p className={styles.convProjectLoading}>
                <BeatLoader loading={true} size={24} color="#1e88e5" />
              </p>
            )}
            {/* { excelLoading && <p className={styles.saveLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p> }  */}
          </div>
        </dd>
      )}
    </>
  );
};

export default BizProgress;
