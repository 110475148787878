import HighchartsReact from 'highcharts-react-official';
import { numFormat } from '../../../com/dataUtil';

const OperationStatEnergyStat = ({state, styles}) => {
   // 전체
  let operChart = {
    chart: {
      type: 'pie',
      height: 160,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false
    },
    title: {
      text : numFormat(state.meatinStatCnt[0].totalCnt),
      y: 20,
      verticalAlign: 'middle',
      className: "operTitle",
      style: { "color": "#333", "fontSize": "18px", "font-weight": "bold" }
    },
    tooltip:  {
      shared: true,
      pointFormat: '{series.data.name} : {point.y}</b><br/>',
    },
    plotOptions: {
      pie: {
          // borderWidth: 6,
          // startAngle: ,
        allowPointSelect: true,
          // innerSize: '55%',
        size: 115,
          // shadow: true,
          // {
          //     color: '#000000',
          //     offsetX: 0,
          //     offsetY: 2,
          //     opacity: 0.7,
          //     width: 3
          // },
        dataLabels: {
          enabled: false,
          distance: -20,
          format: ' {point.y:,.0f}',
          style: {
            color: "#fff",
            fontSize: "13px",
            fontWeight: "400",
            textOutline: false, 
            fontFamily: '"Lucida Console", Monaco, monospace'
          }
        },
        stickyTracking: false,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    series: [{
      innerSize: '45%',
    // showInLegend: true,
          // data: chartTotal 
      data: [    
        {name : "정상", y:state.meatinStatCnt[0].normalCnt, color: "#11C577"}, //초록색
        {name : "고장", y:state.meatinStatCnt[0].errorCnt, color: "#FF3B3B"}, // 빨간색
        {name : "경고", y:state.meatinStatCnt[0].waringCnt, color: "#F9CC13"}, // 노란색
        {name : "준비중", y:state.meatinStatCnt[0].readyCnt, color: "#0E65EC"}, // 파란색
        {name : "미작동", y:state.meatinStatCnt[0].unOperCnt, color: "#9090A7"} // 회색   
      ]
    }]
  };

   // 태양광
  let operSolaChart = {
    chart: {
      // renderTo: 'vacation-time-chart',
      type: 'pie',
      height: 160,
      // width: 140,
      // borderRadius: 0
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false
    },
    title: {
      text : numFormat(state.meatinStatCnt[1].readyCnt + state.meatinStatCnt[1].normalCnt + state.meatinStatCnt[1].unOperCnt + state.meatinStatCnt[1].waringCnt +  state.meatinStatCnt[1].errorCnt),
      y: 20,
      verticalAlign: 'middle',
      style: { "color": "#333", "fontSize": "18px", "font-weight": "bold" }
      // y: 5
    },
    tooltip:  {
      shared: true,
      pointFormat: '{series.data.name} : {point.y}</b><br/>',
    },
    plotOptions: {
      pie: {
          // borderWidth: 6,
          // startAngle: ,
        allowPointSelect: true,
          // innerSize: '55%',
        size: 115,
          // shadow: true,
          // {
          //     color: '#000000',
          //     offsetX: 0,
          //     offsetY: 2,
          //     opacity: 0.7,
          //     width: 3
          // },
        dataLabels: {
          enabled: false,
          distance: -20,
          format: ' {point.y:,.0f}',
          style: {
            color: "#fff",
            fontSize: "13px",
            fontWeight:"400",
            textOutline: false, 
            fontFamily: '"Lucida Console", Monaco, monospace'
          }
        },
        stickyTracking: false,
        states: {
          hover: {
            enabled: false
          }
        },
          //     point: {
          //     events: {
          //         mouseOver: function(){
          //             this.series.chart.innerText.attr({text: this.y});
          //         }, 
          //         mouseOut: function(){
          //             this.series.chart.innerText.attr({text: 112});
          //         }
          //     }
          //     }
      }
    },
    series: [{
      innerSize: '45%',
    // showInLegend: true,
      data: [    
        {name : "정상", y:state.meatinStatCnt[1].normalCnt, color: "#11C577"}, //초록색
        {name : "고장", y:state.meatinStatCnt[1].errorCnt, color: "#FF3B3B"}, // 빨간색
        {name : "경고", y:state.meatinStatCnt[1].waringCnt, color: "#F9CC13"}, // 노란색
        {name : "준비중", y:state.meatinStatCnt[1].readyCnt, color: "#0E65EC"}, // 파란색
        {name : "미작동", y:state.meatinStatCnt[1].unOperCnt, color: "#9090A7"} // 회색     
      ]
    }]
  };

   // 태양열
  let operHeatChart = {
    chart: {
      // renderTo: 'vacation-time-chart',
      type: 'pie',
      height: 160,
      // width: 140,
      // borderRadius: 0
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false
    },
    title: {
      text : numFormat(state.meatinStatCnt[2].readyCnt + state.meatinStatCnt[2].normalCnt + state.meatinStatCnt[2].unOperCnt + state.meatinStatCnt[2].waringCnt +  state.meatinStatCnt[2].errorCnt),
      y: 20,
      verticalAlign: 'middle',
      style: { "color": "#333", "fontSize": "18px", "font-weight": "bold" }
      // y: 5
    },
    tooltip:  {
      shared: true,
      pointFormat: '{series.data.name} : {point.y}</b><br/>',
    },
    plotOptions: {
      pie: {
          // borderWidth: 6,
          // startAngle: ,
        allowPointSelect: true,
          // innerSize: '55%',
        size: 115,
          // shadow: true,
          // {
          //     color: '#000000',
          //     offsetX: 0,
          //     offsetY: 2,
          //     opacity: 0.7,
          //     width: 3
          // },
        dataLabels: {
            enabled: false,
            distance: -20,
            format: ' {point.y:,.0f}',
            style: {
                color: "#fff",
                fontSize: "13px",
                fontWeight:"400",
                textOutline: false, 
                fontFamily: '"Lucida Console", Monaco, monospace'
            }
        },
        stickyTracking: false,
        states: {
            hover: {
                enabled: false
            }
        },
        //     point: {
        //     events: {
        //         mouseOver: function(){
        //             this.series.chart.innerText.attr({text: this.y});
        //         }, 
        //         mouseOut: function(){
        //             this.series.chart.innerText.attr({text: 112});
        //         }
        //     }
        //     }
      }
    },
    series: [{
      innerSize: '45%',
    // showInLegend: true,
      data: [    
        {name : "정상", y:state.meatinStatCnt[2].normalCnt, color: "#11C577"}, //초록색
        {name : "고장", y:state.meatinStatCnt[2].errorCnt, color: "#FF3B3B"}, // 빨간색
        {name : "경고", y:state.meatinStatCnt[2].waringCnt, color: "#F9CC13"}, // 노란색
        {name : "준비중", y:state.meatinStatCnt[2].readyCnt, color: "#0E65EC"}, // 파란색
        {name : "미작동", y:state.meatinStatCnt[2].unOperCnt, color: "#9090A7"} // 회색  
      ]
    }]
  };

   // 지열
  let operHeathChart = {
    chart: {
      // renderTo: 'vacation-time-chart',
      type: 'pie',
      height: 160,
      // width: 140,
      // borderRadius: 0
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false
    },
    title: {
      text : numFormat(state.meatinStatCnt[3].readyCnt + state.meatinStatCnt[3].normalCnt + state.meatinStatCnt[3].unOperCnt + state.meatinStatCnt[3].waringCnt +  state.meatinStatCnt[3].errorCnt),
      y: 20,
      verticalAlign: 'middle',
      style: { "color": "#333", "fontSize": "18px", "font-weight": "bold" }
      // y: 5
    },
    tooltip:  {
      shared: true,
      pointFormat: '{series.data.name} : {point.y}</b><br/>',
    },
    plotOptions: {
      pie: {
          // borderWidth: 6,
          // startAngle: ,
        allowPointSelect: true,
          // innerSize: '55%',
        size: 115,
          // shadow: true,
          // {
          //     color: '#000000',
          //     offsetX: 0,
          //     offsetY: 2,
          //     opacity: 0.7,
          //     width: 3
          // },
        dataLabels: {
          enabled: false,
          distance: -20,format: ' {point.y:,.0f}',
          style: {
            color: "#fff",
            fontSize: "13px",
            fontWeight:"400",
            textOutline: false, 
            fontFamily: '"Lucida Console", Monaco, monospace'
          }
        },
        stickyTracking: false,
        states: {
          hover: {
            enabled: false
          }
        },
          //     point: {
          //     events: {
          //         mouseOver: function(){
          //             this.series.chart.innerText.attr({text: this.y});
          //         }, 
          //         mouseOut: function(){
          //             this.series.chart.innerText.attr({text: 112});
          //         }
          //     }
          //     }
      }
    },
    series: [{
      innerSize: '45%',
    // showInLegend: true,
      data: [    
        {name : "정상", y:state.meatinStatCnt[3].normalCnt, color: "#11C577"}, //초록색
        {name : "고장", y:state.meatinStatCnt[3].errorCnt, color: "#FF3B3B"}, // 빨간색
        {name : "경고", y:state.meatinStatCnt[3].waringCnt, color: "#F9CC13"}, // 노란색
        {name : "준비중", y:state.meatinStatCnt[3].readyCnt, color: "#0E65EC"}, // 파란색
        {name : "미작동", y:state.meatinStatCnt[3].unOperCnt, color: "#9090A7"} // 회색 
      ]
    }]
  };

   // 풍력
  let operVeloChart = {
    chart: {
      // renderTo: 'vacation-time-chart',
      type: 'pie',
      height: 160,
      // width: 140,
      // borderRadius: 0
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false
    },
    title: {
      text : numFormat(state.meatinStatCnt[4].readyCnt + state.meatinStatCnt[4].normalCnt + state.meatinStatCnt[4].unOperCnt + state.meatinStatCnt[4].waringCnt +  state.meatinStatCnt[4].errorCnt),
      y: 20,
      verticalAlign: 'middle',
      style: { "color": "#333", "fontSize": "18px", "font-weight": "bold" }
      // y: 5
    },
    tooltip:  {
      shared: true,
      pointFormat: '{series.data.name} : {point.y}</b><br/>',
    },
    plotOptions: {
      pie: {
          // borderWidth: 6,
          // startAngle: ,
        allowPointSelect: true,
          // innerSize: '55%',
        size: 115,
          // shadow: true,
          // {
          //     color: '#000000',
          //     offsetX: 0,
          //     offsetY: 2,
          //     opacity: 0.7,
          //     width: 3
          // },
        dataLabels: {
          enabled: false,
          distance: -20,
          format: ' {point.y:,.0f}',
          style: {
            color: "#fff",
            fontSize: "13px",
            fontWeight:"400",
            textOutline: false, 
            fontFamily: '"Lucida Console", Monaco, monospace'
          }
        },
        stickyTracking: false,
        states: {
          hover: {
            enabled: false
          }
        },
          //     point: {
          //     events: {
          //         mouseOver: function(){
          //             this.series.chart.innerText.attr({text: this.y});
          //         }, 
          //         mouseOut: function(){
          //             this.series.chart.innerText.attr({text: 112});
          //         }
          //     }
          //     }
      }
    },
    series: [{
      innerSize: '45%',
    // showInLegend: true,
      data: [    
        {name : "정상", y:state.meatinStatCnt[4].normalCnt, color: "#11C577"}, //초록색
        {name : "고장", y:state.meatinStatCnt[4].errorCnt, color: "#FF3B3B"}, // 빨간색
        {name : "경고", y:state.meatinStatCnt[4].waringCnt, color: "#F9CC13"}, // 노란색
        {name : "준비중", y:state.meatinStatCnt[4].readyCnt, color: "#0E65EC"}, // 파란색
        {name : "미작동", y:state.meatinStatCnt[4].unOperCnt, color: "#9090A7"} // 회색    
      ]
    }]
  };

   // 연료전지
  let operFuelCellChart = {
    chart: {
      // renderTo: 'vacation-time-chart',
      type: 'pie',
      height: 160,
      // width: 140,
      // borderRadius: 0
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false
    },
    title: {
      text : numFormat(state.meatinStatCnt[5].readyCnt + state.meatinStatCnt[5].normalCnt + state.meatinStatCnt[5].unOperCnt + state.meatinStatCnt[5].waringCnt +  state.meatinStatCnt[5].errorCnt),
      y: 20,
      verticalAlign: 'middle',
      style: { "color": "#333", "fontSize": "18px", "font-weight": "bold" }
      // y: 5
    },
    tooltip:  {
      shared: true,
      pointFormat: '{series.data.name}: {point.y}</b><br/>',
    },
    plotOptions: {
      pie: {
          // borderWidth: 6,
          // startAngle: ,
        // allowPointSelect: true,
          // innerSize: '55%',
        size: 115,
          // shadow: true,
          // {
          //     color: '#000000',
          //     offsetX: 0,
          //     offsetY: 2,
          //     opacity: 0.7,
          //     width: 3
          // },
        dataLabels: {
          enabled: false,
          distance: -20,
          format: ' {point.y:,.0f}',
          style: {
            color: "#fff",
            fontSize: "13px",
            fontWeight:"400",
            textOutline: false, 
            fontFamily: '"Lucida Console", Monaco, monospace'
          }
        },
        stickyTracking: false,
        states: {
          hover: {
            enabled: false
          }
        },
          //     point: {
          //     events: {
          //         mouseOver: function(){
          //             this.series.chart.innerText.attr({text: this.y});
          //         }, 
          //         mouseOut: function(){
          //             this.series.chart.innerText.attr({text: 112});
          //         }
          //     }
          //     }
      }
    },
    series: [{
      innerSize: '45%',
      // showInLegend: true,
      data: [    
        {name : "정상", y:state.meatinStatCnt[5].normalCnt, color: "#11C577"}, //초록색
        {name : "고장", y:state.meatinStatCnt[5].errorCnt, color: "#FF3B3B"}, // 빨간색
        {name : "경고", y:state.meatinStatCnt[5].waringCnt, color: "#F9CC13"}, // 노란색
        {name : "준비중", y:state.meatinStatCnt[5].readyCnt, color: "#0E65EC"}, // 파란색
        {name : "미작동", y:state.meatinStatCnt[5].unOperCnt, color: "#9090A7"} // 회색 
      ]
    }]
  };

   // ESS
  let operEssChart = {
    chart: {
      // renderTo: 'vacation-time-chart',
      type: 'pie',
      height: 160,
      // width: 140,
      // borderRadius: 0
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false
    },
    title: {
      text : numFormat(state.meatinStatCnt[6].readyCnt + state.meatinStatCnt[6].normalCnt + state.meatinStatCnt[6].unOperCnt + state.meatinStatCnt[6].waringCnt +  state.meatinStatCnt[6].errorCnt),
      y: 20,
      verticalAlign: 'middle',
      style: { "color": "#333", "fontSize": "18px", "font-weight": "bold" }
      // y: 5
    },
    tooltip:  {
      shared: true,
      pointFormat: '{series.data.name} : {point.y}</b><br/>',
    },
    plotOptions: {
      pie: {
          // borderWidth: 6,
          // startAngle: ,
        // allowPointSelect: true,
          // innerSize: '55%',
        size: 115,
          // shadow: true,
          // {
          //     color: '#000000',
          //     offsetX: 0,
          //     offsetY: 2,
          //     opacity: 0.7,
          //     width: 3
          // },
        dataLabels: {
          enabled: false,
          distance: -20,
          format: ' {point.y:,.0f}',
          style: {
            // color: "#fff",
            fontSize: "13px",
            fontWeight:"400",
            textOutline: false, 
            fontFamily: '"Lucida Console", Monaco, monospace'
          }
        },
        stickyTracking: false,
        states: {
          hover: {
            enabled: false
          }
        },
          //     point: {
          //     events: {
          //         mouseOver: function(){
          //             this.series.chart.innerText.attr({text: this.y});
          //         }, 
          //         mouseOut: function(){
          //             this.series.chart.innerText.attr({text: 112});
          //         }
          //     }
          //     }
      }
    },
    series: [{
      innerSize: '45%',
    // showInLegend: true,
      data: [    
        {name : "정상", y:state.meatinStatCnt[6].normalCnt, color: "#11C577"}, //초록색
        {name : "고장", y:state.meatinStatCnt[6].errorCnt, color: "#FF3B3B"}, // 빨간색
        {name : "경고", y:state.meatinStatCnt[6].waringCnt, color: "#F9CC13"}, // 노란색
        {name : "준비중", y:state.meatinStatCnt[6].readyCnt, color: "#0E65EC"}, // 파란색
        {name : "미작동", y:state.meatinStatCnt[6].unOperCnt, color: "#9090A7"} // 회색   
      ]
    }]
  };

  return (
    <div className="uk-width-medium-2-3">
      <div className="md-card">
        <div className={["md-card-content" ,"operationPage"].join(" ")}>
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">
              운영현황
            </h3>
            {/* <div className="md-card-toolbar-actionsLeft">
                <ul id="select-period" data-role="buttongroup" className="km-widget km-buttongroup k-widget k-button-group">
                    <li className="k-button km-button k-state-active km-state-active"><span className="k-text km-text">에너지원별</span></li>
                    <li className="k-button km-button"><span className="k-text km-text">건물용도별</span></li>
                </ul>
            </div> */}
            <div className="md-card-toolbar-actions">
              <span className="titleTbar"><img src="/images/icon_gre.png" alt="정상"/>&nbsp;&nbsp;정상</span>
              <span className="titleTbar"><img src="/images/icon_gray.png" alt="미작동"/>&nbsp;&nbsp;미작동</span>
              <span className="titleTbar"><img src="/images/icon_bl.png" alt="준비중"/>&nbsp;&nbsp;준비중</span>
              <span className="titleTbar"><img src="/images/icon_yl.png" alt="경고"/>&nbsp;&nbsp;경고</span>
              <span className="titleTbar"><img src="/images/icon_rd.png" alt="고장"/>&nbsp;&nbsp;고장</span>
            </div>
          </div>
          <div>
            <table className="Ch-TableF">
              <tr className={styles.operationStatTable}>
                <td>
                  <span>
                    전체&nbsp;&nbsp;
                    {/* <img src="/images/icon_d.png" alt="전체"/> */}
                  </span>
                  <div style = {{height : "140px", width: "162px"}}>
                    <HighchartsReact options = {operChart}></HighchartsReact>
                  </div>
                </td>
                <td>
                  <span>
                    태양광&nbsp;&nbsp;<img src="/images/enso/icon_15001_Small_black.png" alt=""/>
                  </span>
                  <div style = {{height : "140px", width: "162px"}}>
                    <HighchartsReact options = {operSolaChart}></HighchartsReact>
                  </div>
                </td>
                <td>
                  <span>
                    태양열&nbsp;&nbsp;<img src="/images/enso/icon_15002_Small_black.png" alt=""/>
                  </span>
                  <div style = {{height : "140px", width: "162px"}}>
                    <HighchartsReact options = {operHeatChart}></HighchartsReact>
                  </div>
                </td>
                <td>
                  <span>
                    지열&nbsp;&nbsp;<img src="/images/enso/icon_15003_Small_black.png" alt=""/>
                  </span>
                  <div style = {{height : "140px", width: "162px"}}>
                    <HighchartsReact options = {operHeathChart}></HighchartsReact>
                  </div>
                </td>
                <td>
                  <span>
                    풍력&nbsp;&nbsp;<img src="/images/enso/icon_15004_Small_black.png" alt=""/>
                  </span>
                  <div style = {{height : "140px", width: "162px"}}>
                    <HighchartsReact options = {operVeloChart}></HighchartsReact>
                  </div>
                </td>
                <td>
                  <span>
                    연료전지&nbsp;&nbsp;<img src="/images/enso/icon_15006_Small_black.png" alt=""/>
                  </span>
                  <div style = {{height : "140px", width: "162px"}}>
                    <HighchartsReact options = {operFuelCellChart}></HighchartsReact>
                  </div>
                </td>
                <td>
                  <span>
                    ESS&nbsp;&nbsp;<img src="/images/enso/icon_15007_Small_black.png" alt=""/>
                  </span>
                  <div style = {{height : "140px", width: "162px"}}>
                    <HighchartsReact options = {operEssChart}></HighchartsReact>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OperationStatEnergyStat;