import { numFormat, roundNum } from "../../com/dataUtil";
/**
 * 설치현황 Depth3 각 설치자의 설비 정보
 *
 * @param 검색 조건
 * @return 각 설치자 별 설비 정보와 설비 위치 정보
 */
export const getInstallAreaDataList = async (conn, param, callback, rejection) => {
  await conn
    .get("location/depth3/installmeain", {
      params: {
        userTerm: param.userTerm,
        bizSct: param.bizSct,
        bldSrvCodeNo: param.bldSrvCodeNo,
        bizYy: param.bizYy,
        rgnCode: param.rgnCode,
        fixbylawBundCode: param.dongCode,
      },
    })
    .then(response => {
      // 데이터 설정
      const result = response;

      // 설치자별 리스트
      const installer = [];
      const meainList = [];

      // 설비 좌표에 보여줄 데이터 저장용 배열
      const meainDataBoxList = [];

      if (result.locationInstallerMeainListDtoList.length !== 0) {
        const userIdArray = [];
        // 설치자별 에너지원별 데이터 가공
        result.locationInstallerMeainListDtoList.forEach(element => {
          if (element.accumUse === null) element.accumUse = 0;
          if (element.accumPower === null) element.accumPower = 0;
          if (element.meainCnt === null) element.meainCnt = 0;
          if (element.ensoTypeCode === 15002 || element.ensoTypeCode === 15003) {
            element.co2 = "-";
            element.isUse = true;
          } else {
            element.co2 = element.accumPower * 0.4653;
            element.isUse = false;
          }
          // 저장
          userIdArray.push({ userId: element.userId, userTerm: element.userTerm });
        });

        // 중복 ID 제거 후 유니크 ID 추출 *객체의 속성이 1개만 있거나, 객체 값이 완전히 같은 것을 중복 제거 할 경우만 new Set사용
        // const uniqUserId = [...new Set(userIdArray.map(JSON.stringify))].map(JSON.parse);
        const uniqUserId = userIdArray.filter((item, i) => {
          return (
            userIdArray.findIndex((item2, j) => {
              return item.userId === item2.userId;
            }) === i
          );
        });

        // 중복 ID를 KEY 로 설정 후 값을 가공한다.
        for (let index = 0; index < uniqUserId.length; index++) {
          let sumCount = 0;
          const installerData = [];
          result.locationInstallerMeainListDtoList.forEach(element => {
            if (uniqUserId[index].userId === element.userId) {
              sumCount += element.meainCnt;
              installerData.push(element);
              // 설비 좌표에 보여줄 데이터 저장용 배열
              meainDataBoxList.push(element);
            }
          });
          installer.push({
            userId: uniqUserId[index].userId,
            userTerm: uniqUserId[index].userTerm,
            sumCount: numFormat(sumCount, 2),
            installer: installerData,
          });
        }
      }

      // 각 마커에 표시할 데이터
      if (result.locationMeainListDtoList.length !== 0) {
        const meainResultList = result.locationMeainListDtoList;
        const userIdArray = [];

        meainResultList.forEach(element => {
          // 유저 ID , 명 저장
          userIdArray.push({ userId: element.userId, userTerm: element.userTerm });
        });

        // 마커 데이터 가공 (왼쪽 설치자 정보를 마커 데이터에 주입한다.)
        if (meainDataBoxList.length !== 0) {
          meainResultList.forEach(item1 => {
            meainDataBoxList.forEach(item2 => {
              if (item1.userId === item2.userId && item1.ensoTypeCode === item2.ensoTypeCode) {
                item1.accumPower = item2.accumPower;
                item1.accumUse = item2.accumUse;
                item1.instCapa = item2.instCapa;
              }
            });
            meainList.push(item1);
          });
        }
      }

      // 최종 가공된 데이터
      const totalResult = { installer: installer, meainList: meainList };

      // 왼쪽 리스트 데이터 좌표 설정 (여러 설비 중 첫번째 설비의 좌표를 가져온다.) (순회 하는 부분 수정해야함 전체 순회 함)
      if (totalResult.installer.length !== 0) {
        totalResult.installer.forEach(item1 => {
          totalResult.meainList.some(item2 => {
            if (item1.userId === item2.userId) {
              item1.lati = item2.lati;
              item1.longi = item2.longi;
              return true;
            }
            return false;
          });
        });
      }
      //callback
      callback(totalResult);
    })
    .catch(error => {
      console.log("getInstallAreaDataList reject start");
      rejection(error);
    });
};

/**
 * 전국 각 시도별 데이터 리스트
 *
 * @param 검색 조건 없음 //TODO 검색 조건에 따라서 확인 필요
 * @return 각 시도별 데이터 리스트
 */
export const getCityDataList = async (conn, param, callback, rejection) => {
  await conn
    .get("location/city/list", {
      params: {
        userTerm: param.userTerm,
        bizSct: param.bizSct,
        bldSrvCodeNo: param.bldSrvCodeNo,
        bizYy: param.bizYy,
      },
    })
    .then(response => {
      const cityDataList = [];
      // 설치율 계산
      let sumInstRate = 0;
      response.forEach(element => {
        sumInstRate += element.cntTotal;
      });
      response.forEach(element => {
        // co2 계산
        const co2 = element.dayPower !== null ? element.dayPower * 0.4653 : 0 + element.dayUse !== null ? element.dayUse * 0.4653 : 0;
        // 연동률 계산
        const rate = (element.cntNormal / element.cntTotal) * 100;
        // 설치률 계산
        const instRate = (element.cntTotal / sumInstRate) * 100;

        // 도시별 데이터 리스트 가공
        cityDataList.push({
          areaCode: element.cityProvCode,
          areaTerm: element.cityProvTerm,
          lati: element.lati,
          longi: element.longi,
          instCapa: element.instCapa !== null ? numFormat(roundNum(element.instCapa, 2)) : 0,
          dayPower: element.dayPower !== null ? numFormat(roundNum(element.dayPower, 2)) : 0,
          accumPower: element.accumPower !== null ? numFormat(roundNum(element.accumPower / 1000, 2)) : 0,
          dayPrdct: element.dayPrdct !== null ? numFormat(roundNum(element.dayPrdct, 2)) : 0,
          accumPrdct: element.accumPrdct !== null ? numFormat(roundNum(element.accumPrdct / 1000, 2)) : 0,
          dayUse: element.dayUse !== null ? numFormat(roundNum(element.dayUse, 2)) : 0,
          accumUse: element.accumUse !== null ? numFormat(roundNum(element.accumUse, 2)) : 0,
          cntTotal: element.cntTotal !== null ? numFormat(element.cntTotal) : 0,
          cntSola: element.cntSola !== null ? numFormat(element.cntSola) : 0,
          cntHeat: element.cntHeat !== null ? numFormat(element.cntHeat) : 0,
          cntHeath: element.cntHeath !== null ? numFormat(element.cntHeath) : 0,
          cntVelo: element.cntVelo !== null ? numFormat(element.cntVelo) : 0,
          cntfuelcell: element.cntfuelcell !== null ? numFormat(element.cntfuelcell) : 0,
          co2: numFormat(roundNum(co2, 2)),
          rate: isNaN(rate) ? 0 : numFormat(roundNum(rate, 2)),
          instRate: roundNum(instRate, 2),
        });
      });
      callback(cityDataList);
    })
    .catch(error => {
      rejection(error);
    });
};

/**
 * 해당 시도별의 전국 시군구 데이터 리스트
 *
 * @param 검색 조건 없음 //TODO 검색 조건에 따라서 확인 필요
 * @return 각 시도별 데이터 리스트
 */
export const getRgnDataList = async (conn, param, callback, rejection) => {
  await conn
    .get("location/rgn/list", {
      params: {
        userTerm: param.userTerm,
        bizSct: param.bizSct,
        bldSrvCodeNo: param.bldSrvCodeNo,
        bizYy: param.bizYy,
        cityProvCode: param.cityProvCode,
      },
    })
    .then(response => {
      const cityRgnDataList = [];
      // 설치율 계산
      let sumInstRate = 0;
      response.forEach(element => {
        sumInstRate += element.cntTotal;
      });

      response.forEach(element => {
        // co2 계산
        const co2 = element.dayPower !== null ? element.dayPower * 0.4653 : 0 + element.dayUse !== null ? element.dayUse * 0.4653 : 0;
        // 연동률 계산
        const rate = (element.cntNormal / element.cntTotal) * 100;
        // 설치률 계산
        const instRate = (element.cntTotal / sumInstRate) * 100;

        // 도시별 데이터 리스트 가공
        cityRgnDataList.push({
          areaCode: element.rgnCode,
          areaTerm: element.rgnTerm,
          lati: element.lati,
          longi: element.longi,
          instCapa: element.instCapa !== null ? numFormat(roundNum(element.instCapa, 2)) : 0,
          dayPower: element.dayPower !== null ? numFormat(roundNum(element.dayPower, 2)) : 0,
          accumPower: element.accumPower !== null ? numFormat(roundNum(element.accumPower / 1000, 2)) : 0,
          dayPrdct: element.dayPrdct !== null ? numFormat(roundNum(element.dayPrdct, 2)) : 0,
          accumPrdct: element.accumPrdct !== null ? numFormat(roundNum(element.accumPrdct / 1000, 2)) : 0,
          dayUse: element.dayUse !== null ? numFormat(roundNum(element.dayUse, 2)) : 0,
          accumUse: element.accumUse !== null ? numFormat(roundNum(element.accumUse, 2)) : 0,
          cntTotal: element.cntTotal !== null ? numFormat(element.cntTotal) : 0,
          cntSola: element.cntSola !== null ? numFormat(element.cntSola) : 0,
          cntHeat: element.cntHeat !== null ? numFormat(element.cntHeat) : 0,
          cntHeath: element.cntHeath !== null ? numFormat(element.cntHeath) : 0,
          cntVelo: element.cntVelo !== null ? numFormat(element.cntVelo) : 0,
          cntfuelcell: element.cntfuelcell !== null ? numFormat(element.cntfuelcell) : 0,
          co2: numFormat(roundNum(co2, 2)),
          rate: isNaN(rate) ? 0 : numFormat(roundNum(rate, 2)),
          instRate: roundNum(instRate, 2),
        });
      });
      callback(cityRgnDataList);
    })
    .catch(error => {
      rejection(error);
    });
};

export const getDongDataList = async (conn, param, callback, rejection) => {
  await conn
    .get("location/dong/list", {
      params: {
        userTerm: param.userTerm,
        bizSct: param.bizSct,
        bldSrvCodeNo: param.bldSrvCodeNo,
        bizYy: param.bizYy,
        rgnCode: param.rgnCode,
      },
    })
    .then(response => {
      const dongDataList = [];
      // 설치율 계산
      let sumInstRate = 0;
      response.forEach(element => {
        sumInstRate += element.cntTotal;
      });

      response.forEach(element => {
        // co2 계산
        const co2 = element.dayPower !== null ? element.dayPower * 0.4653 : 0 + element.dayUse !== null ? element.dayUse * 0.4653 : 0;
        // 연동률 계산
        const rate = (element.cntNormal / element.cntTotal) * 100;
        // 설치률 계산
        const instRate = (element.cntTotal / sumInstRate) * 100;

        // 도시별 데이터 리스트 가공
        dongDataList.push({
          areaCode: element.dongCode,
          areaTerm: element.dongTerm,
          lati: element.lati,
          longi: element.longi,
          instCapa: element.instCapa !== null ? numFormat(roundNum(element.instCapa, 2)) : 0,
          dayPower: element.dayPower !== null ? numFormat(roundNum(element.dayPower, 2)) : 0,
          accumPower: element.accumPower !== null ? numFormat(roundNum(element.accumPower / 1000, 2)) : 0,
          dayPrdct: element.dayPrdct !== null ? numFormat(roundNum(element.dayPrdct, 2)) : 0,
          accumPrdct: element.accumPrdct !== null ? numFormat(roundNum(element.accumPrdct / 1000, 2)) : 0,
          dayUse: element.dayUse !== null ? numFormat(roundNum(element.dayUse, 2)) : 0,
          accumUse: element.accumUse !== null ? numFormat(roundNum(element.accumUse, 2)) : 0,
          cntTotal: element.cntTotal !== null ? numFormat(element.cntTotal) : 0,
          cntSola: element.cntSola !== null ? numFormat(element.cntSola) : 0,
          cntHeat: element.cntHeat !== null ? numFormat(element.cntHeat) : 0,
          cntHeath: element.cntHeath !== null ? numFormat(element.cntHeath) : 0,
          cntVelo: element.cntVelo !== null ? numFormat(element.cntVelo) : 0,
          cntfuelcell: element.cntfuelcell !== null ? numFormat(element.cntfuelcell) : 0,
          co2: numFormat(roundNum(co2, 2)),
          rate: isNaN(rate) ? 0 : numFormat(roundNum(rate, 2)),
          instRate: roundNum(instRate, 2),
        });
      });
      callback(dongDataList);
    })
    .catch(error => {
      rejection(error);
    });
};

/**
 * 위치값을 근접한 값을 가져와 지역코드 , 동코드 가져오기
 *
 * @param 맵 레벨 , 위도 , 경도
 * @return 해당 지역의 위도 , 해당 지역의  경도 , 시군구코드 , 읍면동코드
 */
export const getLocation = async (conn, level, param, wholeMapLevel, cityMapLevel, rgnMapLevel, dongMapLevel) => {
  const result = await conn.get("location/position", {
    params: {
      mapLevel: level,
      mapAddressCode: param.mapAddressCode,
      wholeMapLevel: wholeMapLevel,
      cityMapLevel: cityMapLevel,
      rgnMapLevel: rgnMapLevel,
      dongMapLevel: dongMapLevel,
    },
  });
  return result;
};
