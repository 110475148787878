import NotiDetail from "./notiDetail";

import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";

import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { useEffect } from "react";

const NotiGrid = ({
  dataState,
  paging,
  onPageChange,
  onSortChange,
  sort,
  expandChange,
  notiNoCell,
  alignCell,
  updateCell,
  deleteCell,
  loginUserTypeCode,
  isMobile,
}) => {
  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;
  useEffect(() => {
    // document.getElementsByClassName("k-pager-sm")[0].classList.remove("k-pager-sm");
  }, []);
  return (
    <>
      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="ko">
          <Grid
            className="noti"
            style={{ height: isMobile ? innerHeight * 0.8 : "700px" }}
            data={dataState.datalist}
            total={dataState.total}
            skip={paging.skip}
            take={paging.take}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            sort={sort}
            page={paging}
            sortable
            pageable={{
              type: "numeric",
              // pageSizes: true,
              pageSizes: [5, 10, 20, 50, 100],
              previousNext: true,
              buttonCount: 5,
            }}
            detail={NotiDetail}
            onRowClick={expandChange}
            onExpandChange={expandChange}
            expandField="expanded">
            <Column
              className="gridBody"
              headerClassName="gridHeader"
              field="noticeNo"
              title="No"
              width={isMobile ? innerWidth * 0.15 : "70px"}
              cell={notiNoCell}
            />
            <Column
              className="gridBody"
              headerClassName="gridHeader"
              field="titl"
              title="제목"
              cell={alignCell}
              width={isMobile ? innerWidth * 0.75 : "auto"}
            />
            <Column className="gridBody" headerClassName="gridHeader" field="regiDtm" title="최초작성일시" width={isMobile ? 0 : "200px"} />
            <Column
              className="gridBody"
              headerClassName="gridHeader"
              field="modifyDtm"
              title="최종수정일시"
              width={isMobile ? 0 : "200px"}
            />
            <Column className="gridBody" headerClassName="gridHeader" field="viewCnt" title="조회수" width={isMobile ? 0 : "200px"} />
            {loginUserTypeCode === 2003 || loginUserTypeCode === 2005 ? (
              <Column className="gridBody" headerClassName="gridHeader" field="수정" cell={updateCell} width="80px" />
            ) : null}
            {loginUserTypeCode === 2003 || loginUserTypeCode === 2005 ? (
              <Column className="gridBody" headerClassName="gridHeader" field="삭제" cell={deleteCell} width="80px" />
            ) : null}
            <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
};

export default NotiGrid;
