import { useEffect } from "react";
import { ReactComponent as Rank1 } from "../../../../../assets/svg/gongju/rank1.svg";
import { ReactComponent as Rank2 } from "../../../../../assets/svg/gongju/rank2.svg";
import { ReactComponent as Rank3 } from "../../../../../assets/svg/gongju/rank3.svg";

const GongjuRank = ({ data }) => {
  return (
    <div className="gongju-rank">
      <div className="gongju-rank-title">발전량 순위</div>
      {data &&
        data.slice(0, 3).map((item, index) => (
          <div className="gongju-rank-wrapper" key={item.admnsBundTermS}>
            {index === 0 && <Rank1 className="gongju-rank-icon" />}
            {index === 1 && <Rank2 className="gongju-rank-icon" />}
            {index === 2 && <Rank3 className="gongju-rank-icon" />}
            <span className="gongju-rank-area">{item.admnsBundTermS}</span>
          </div>
        ))}
    </div>
  );
};

export default GongjuRank;
