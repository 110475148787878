// React
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { getSys } from "../../../service/sys/sysService";
import { parseDate } from "../../../com/dateUtil";

// Kendo
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import kendo from "@progress/kendo-ui";

// Highcharts
import { SysGause } from "./sysGuage";

// import './sys.dk.css';
import "./sys.css";
import styles from "./sys.module.css";
// 로딩 스피너
import { BeatLoader } from "react-spinners";

// 언어 변경
import koMessage from "../../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

function Sys({ conn, codeList }) {
  const [loading, setLoading] = useState(false);
  // 리소스 모니터 드롭다운 데이터
  const resourceMonitor = useMemo(() => codeList.filter(item => item.grpCodeNo === 11 && item.codeNo !== 11001), [codeList]);

  // 시스템 이벤트 이력 데이터 리스트
  const [dataSource, setDataSource] = useState([]);
  // 데이터 조회 상태 조건
  const [dataState, setDataState] = useState({
    take: 100,
    skip: 0,
    total: 0,
    sysCodeNo: 11001,
    resourceValue: resourceMonitor[0],
    startDate: parseDate("LocalDateTime"),
    endDate: parseDate("LocalDateTime"),
  });

  // CPU, 메모리, 디스크 데이터 상태
  const [tabInfo, setTabInfo] = useState({
    cpuGuage: [0],
    memGuage: [0],
    diskType: [],
    diskTypeData: [],
    diskData: [],
    init: false,
  });

  // 데이터 로드
  const getSysDataList = () => {
    setLoading(true);
    getSys(conn, dataState)
      .then(response => {
        const { results, total } = response;
        results.forEach(element => {
          element.event = element.event ? element.event : "-";
          element.gathDtm = element.gathDtm ? kendo.toString(new Date(element.gathDtm), "yyyy-MM-dd hh:mm:ss") : "-";

          // 등급 로직 (step1 : CPU  체크 , step2 : 메모리  체크 , step3 : 디스크  체크  )
          let statCodeNo = "정상";
          statCodeNo = setStatConverter(element.cpuStatCodeNo);
          statCodeNo = setStatConverter(element.memoryStatCodeNo);
          const diskStat = element.prtnStatCodeNo.split(" ");
          diskStat.forEach(diskValue => {
            statCodeNo = setStatConverter(Number(diskValue));
          });
          element.statCodeNo = statCodeNo;
        });

        setDataSource(results);
        setDataState({ ...dataState, total });

        // 데이터가 있는지 확인
        if (results.length !== 0) {
          // 처음 리소스 모니터 게이지 값이 초기화인지 아닌지 확인 후 초기화가 아니라면 초기화 한다.
          if (!tabInfo.init) {
            const resourceData = results[0].detail.split(",");
            // 디스크 영역부터 저장해야함
            for (let index = 2; index < resourceData.length; index++) {
              const rate = resourceData[index].split(" : ");
              const name = rate[0].replace(/(\s*)/g, "");
              tabInfo.diskType.push(name);
              // eslint-disable-next-line
              tabInfo.diskTypeData.push({ name: name, data: Number(rate[1].replace(/[\%,s]/g, "")) });
              if (name === "DISK/") {
                // eslint-disable-next-line
                tabInfo.diskData = [Number(rate[1].replace(/[\%,s]/g, ""))];
              }
            }
            // 게이지 차트 데이터
            // eslint-disable-next-line
            const cpuGuage = resourceData[0].split(" : ")[1].replace(/[\%,s]/g, "");
            // eslint-disable-next-line
            const memGuage = resourceData[1].split(" : ")[1].replace(/[\%,s]/g, "");
            setTabInfo(tabInfo => {
              const item = { ...tabInfo };
              item.cpuGuage = [Number(cpuGuage)];
              item.memGuage = [Number(memGuage)];
              item.init = true;
              return item;
            });
          }
        }
        setLoading(false);
      })
      .catch(err => {
        console.log("시스템 문제 코드 : ", err);
        kendo.alert("시스템에 문제가 발생 하였습니다.");
        return;
      });
  };

  // 등급로직 판별
  const setStatConverter = value => {
    let stat = "정상";
    if (value) {
      if (value === 3002) {
        stat = "정보";
      } else if (value === 3003) {
        stat = "경고";
      } else if (value === 3004) {
        stat = "에러";
      }
    }
    return stat;
  };

  useEffect(() => {
    getSysDataList();
    // eslint-disable-next-line
  }, []);

  // 리소스모니터 드롭다운 이벤트
  const handleResourceChange = useCallback(
    e => {
      dataState.take = 100;
      dataState.skip = 0;

      dataState.resourceValue = e.target.value;
      dataState.sysCodeNo = e.target.value.codeNo;
      getSysDataList();
    },
    // eslint-disable-next-line
    [dataSource, dataState, tabInfo],
  );

  // 디스크 드롭다운 이벤트
  const handleDiskChange = e => {
    const { value } = e.target;
    setTabInfo(tabInfo => {
      const item = { ...tabInfo };
      item.diskTypeData.some(v => {
        if (v.name === value) {
          item.diskData = [v.data];
          return true;
        }
        return false;
      });
      return item;
    });
  };

  // 그리드 페이징 처리
  const handlePageChange = useCallback(
    e => {
      const { take, skip } = e.page;
      dataState.take = take;
      dataState.skip = skip;
      getSysDataList();
    },
    // eslint-disable-next-line
    [dataSource, dataState, tabInfo],
  );

  // 시작 날짜 이벤트 처리
  const handleStartDateChange = useCallback(
    e => {
      dataState.take = 100;
      dataState.skip = 0;

      const { value } = e.target;
      dataState.startDate = value;
      getSysDataList();
    },
    // eslint-disable-next-line
    [dataSource, dataState, tabInfo],
  );

  // 종료 날짜 이벤트 처리
  const handleEndDateChange = useCallback(
    e => {
      dataState.take = 100;
      dataState.skip = 0;

      const { value } = e.target;
      dataState.endDate = value;
      getSysDataList();
    },
    // eslint-disable-next-line
    [dataSource, dataState, tabInfo],
  );

  return (
    <div
      className="sysBodyDiv"
      style={
        loading
          ? {
              opacity: 0.6,
              pointerEvents: "none",
            }
          : null
      }>
      <div className="leftSysDiv">
        <div className="leftSysDivHeader">
          <span>리소스모니터</span>
          <DropDownList
            data={resourceMonitor}
            className="resourceMonitorDropDown"
            dataItemKey="codeNo"
            textField="codeVal"
            onChange={handleResourceChange}
            value={dataState.resourceValue}
          />
        </div>
        <div className="leftSysDivBody">
          <div className="leftSysDivBodyChild">
            <TabStrip selected={0} className="sysTabArea">
              <TabStripTab title="CPU">
                <div>
                  <SysGause dataSource={tabInfo.cpuGuage} />
                </div>
              </TabStripTab>
            </TabStrip>

            <TabStrip selected={0} className="sysTabArea">
              <TabStripTab title="메모리">
                <div>
                  <SysGause dataSource={tabInfo.memGuage} />
                </div>
              </TabStripTab>
            </TabStrip>

            <div className="sysTabArea">
              <DropDownList className={styles.sysKendoTab} data={tabInfo.diskType} defaultValue={"DISK/"} onChange={handleDiskChange} />
              {
                <div>
                  <SysGause dataSource={tabInfo.diskData} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="rightSysDiv">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              className={styles.sysGrid}
              data={dataSource}
              {...dataState}
              pageable={{ buttonCount: 5, pageSizes: [5, 10, 20, 50, 100] }}
              onPageChange={handlePageChange}>
              <GridToolbar>
                <span className="sysSpanTxt1">시스템 이벤트 이력</span>
                <span className="sysSpanTxt2">
                  날짜검색 &nbsp;
                  <DatePicker
                    defaultValue={new Date()}
                    format={"yyyy-MM-dd"}
                    max={new Date(dataState.endDate)}
                    onChange={handleStartDateChange}
                  />
                  &nbsp; ~ &nbsp;
                  <DatePicker defaultValue={new Date()} format={"yyyy-MM-dd"} max={new Date()} onChange={handleEndDateChange} />
                </span>
              </GridToolbar>
              <GridColumn field="statCodeNo" title="등급" width="75px" />
              <GridColumn field="gathDtm" title="일시" width="170px" />
              <GridColumn field="event" title="이벤트" width="325px" />
              <GridColumn field="detail" title="상세내역" />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
        {loading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </div>
  );
}

export default Sys;
