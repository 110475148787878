import { ReactComponent as Sulbe } from "dist/svg/asan/sulbe.svg";
import { ReactComponent as ElecEnergy } from "dist/svg/asan/elec-energy.svg";
import { ReactComponent as HeatEnergy } from "dist/svg/asan/heat-energy.svg";
import { translateUnit } from "components/localArea/asan/components/DevelopmentState/DevelopmentState";
import "./NonsanTooltip.css";

export default function NonsanTooltip({ x, y, info }) {
  return (
    <div className="nonsan-tooltip-container" style={{ top: y - 100, left: x - 150 }}>
      <div>{info.name}</div>
      <div className="nonsan-tooltip-info">
        <div className="nonsan-icon-wrapper">
          <Sulbe className="nonsan-tooltip-icon" />
        </div>
        <div className="nonsan-icon-wrapper">{info.meainCount}개소</div>
      </div>
      <div className="nonsan-tooltip-info">
        <div className="nonsan-icon-wrapper">
          <HeatEnergy className="nonsan-tooltip-icon" />
        </div>
        <div className="nonsan-icon-wrapper">
          {translateUnit(info.dongUsePower).split(" ")[0]} {translateUnit(info.dongUsePower).split(" ")[1]}
        </div>
      </div>
      <div className="nonsan-tooltip-info">
        <div className="nonsan-icon-wrapper">
          <ElecEnergy className="nonsan-tooltip-icon" />
        </div>
        <div className="nonsan-icon-wrapper">
          {translateUnit(info.dongElecPower).split(" ")[0]} {translateUnit(info.dongElecPower).split(" ")[1]}
        </div>
      </div>
    </div>
  );
}
