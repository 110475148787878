import kendo from '@progress/kendo-ui'

const MeainNewItem = ({dataItem}) => {

  const dataTime =  dataItem.sysRegiDtm
  const data = kendo.toString( new Date(dataTime), "yy.MM.dd")
  const time = kendo.toString( new Date(dataTime), "HH:mm:ss")
  
  const imgSrc = "/images/enso/icon_" + dataItem.ensoTypeCode + "_" + dataItem.meainStatCodeNo + "_Small.png";

  return (
    <>
      <div className="timeline">
        <div className="timeline_item">
          <div className="timeline_dateBB">
            {data}<br/> {time}
          </div>
          <div className="timeline_icon"/>
          <div className="timeline_content">
            <span className="TcSpace">
              <img src={imgSrc} alt={dataItem.meainStatTerm}/>
              &nbsp;&nbsp;{dataItem.meainStatTerm}
              </span>
            <span className="TcSpace">-</span>
            <span className="TcSpace">{dataItem.userTerm}</span>
            <span className="TcSpace">|</span>
            <span className="TcSpace">{dataItem.capa}W</span>
            <span className="TcSpace">|</span>
            <span className="TcSpace">{dataItem.rtuEnteTerm}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default MeainNewItem;