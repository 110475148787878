const BizPlanAddMonitorEtc = ({ styles, moniInfo, energyTypeCode, onItemChange, onPlanChange, DropDownList, Form, FormElement, Input }) => {
  return (
    <>
      <div class="SubM_title">[ 설비내역 ]</div>
      <Form
        render={() => (
          <FormElement>
            <table className={styles.emcTable}>
              <colgroup>
                <col width="20%" />
                <col width="30%" />
                <col width="20%" />
                <col width="30%" />
              </colgroup>
              <tbody>
                <tr style={{ border: "solid 1px #dedede" }}>
                  <th>
                    <span class="red_cl">*</span> 에너지원
                  </th>
                  <td>
                    <DropDownList
                      name="energyTypeCode"
                      component={DropDownList}
                      data={energyTypeCode}
                      textField="codeVal"
                      dataItemKey="codeNo"
                      defaultValue={
                        moniInfo.energyTypeCode !== ""
                          ? {
                              codeNo: moniInfo.energyTypeCode,
                              codeVal: moniInfo.energyTypeCodeData
                                ? moniInfo.energyTypeCodeData.codeVal
                                  ? moniInfo.energyTypeCodeData.codeVal
                                  : "-"
                                : "-",
                            }
                          : { codeNo: "", codeVal: "선택" }
                      }
                      onChange={onItemChange}
                    />
                  </td>
                  <th>
                    <span class="red_cl">*</span> 설치용량
                  </th>
                  <td>
                    <input
                      className={styles.borderE4}
                      name="instCapa"
                      component={Input}
                      onChange={onPlanChange}
                      defaultValue={moniInfo.instCapa !== "" ? moniInfo.instCapa : ""}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="red_cl">*</span> 용량단위
                  </th>
                  <td>
                    <input
                      className={styles.borderE4}
                      name="unit"
                      component={Input}
                      onChange={onPlanChange}
                      defaultValue={moniInfo.unit !== "" ? moniInfo.unit : ""}
                    />
                  </td>
                  <th>
                    <span class="red_cl">*</span> CID(11자리)
                  </th>
                  <td>
                    <input
                      className={styles.borderE4}
                      name="cid"
                      component={Input}
                      onChange={onPlanChange}
                      maxLength="11"
                      defaultValue={moniInfo.cid !== "" ? moniInfo.cid : ""}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </FormElement>
        )}
      />
    </>
  );
};

export default BizPlanAddMonitorEtc;
