import React, { Fragment, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat } from "../../../com/dataUtil";

const TroubleShootingDetailChart = ({ search, selectedUser, detailChartData }) => {
  const [plotBands, setPlotBands] = useState([]); // plotBands 상태
  useEffect(() => {
    if(detailChartData.xAxis.length > 0){
      // 데이터 변경 시 plotBands 업데이트
      const updatePlotBands = () => {
        const newPlotBands = [];
        const data1 = detailChartData.elecEffi;
        const data2 = detailChartData.dayEffi;
        for (let i = 0; i < data1.length; i++) {
          const y1 = data1[i];
          const y2 = data2[i];
          const from = i - 0.5;
          const to = i + 0.5;
          let color = 'white';
          if ((y1 - y2) / y1 <= -0.05) {
            color = '#FAC2C5';
          } else if ((y1 - y2) / y1 < 0) {
            color = '#FFFCBF';
          }
          newPlotBands.push({
            from,
            to,
            color
          });
        }
        setPlotBands(newPlotBands);
      };
      updatePlotBands(); // 초기 plotBands 업데이트
    }
  }, [detailChartData, selectedUser])

  const chartOption = {
    chart: {
      zoomType: "xy",
      height: 309
    },
    noData: {
      text: '대상자를 선택해주세요.',
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [
      {
        type: "category",
        categories: detailChartData.xAxis,
        plotBands: plotBands,
        crosshair: true,
        title: {
          text: '(일)',
          rotation: 0,
          x: 20,
          y: 10,
          offset: 0,
          align: 'high'
      },
      },
    ],
    yAxis: [
      {
        // 강수량 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#72CDEC",
          },
        },
        title: null,
        opposite: true,
      },
      {
        // 일사량 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#ED1D24",
          },
        },
        title: null,
        opposite: true,
        max: 40,
      },
      {
        // 발전효율 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#1BAD63",
          },
        },
        max: 100,
        title: null,
        opposite: true,
      },
      {
        // 일별평균 yAxis
        showEmpty: false,
        labels: {
          format: "{value}",
          style: {
            color: "#E2AC2A",
          },
        },
        max: 100,
        title: null,
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = "";
        if (this.points[0]) {
          msg +=
            '<span style="color:' +
            this.points[0].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            "강수량" +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(this.points[0].y) +
            "mm</b>" +
            "<br>";
        }
        if (this.points[1]) {
          msg +=
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            "일사량" +
            " : " +
            "<b>" +
            numFormat(this.points[1].y) +
            "MJ/m²</b>" +
            "<br>";
        }
        if (this.points[2]) {
          msg +=
            '<span style="color:' +
            this.points[2].point.color +
            '">\u25CF</span> ' +
            "발전효율" +
            " : " +
            "<b>" +
            numFormat(this.points[2].y) +
            "%</b>" +
            "<br>";
        }
        if (this.points[3]) {
          msg +=
            '<span style="color:' +
            this.points[3].point.color +
            '">\u25CF</span> ' +
            "일별평균" +
            " : " +
            "<b>" +
            numFormat(this.points[3].y) +
            "%</b>" +
            "<br>";
        }

        return msg;
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        color: "#72CDEC",
        name: "강수량(mm)",
        type: "spline",
        yAxis: 0,
        data: detailChartData.precQty,
        tooltip: {
          valueSuffix: " mm",
        },
        zIndex: 10,
      },
      {
        color: "#ED1D24",
        name: "일사량(MJ/m²)",
        type: "spline",
        data: detailChartData.soraQty,
        yAxis: 1,
        tooltip: {
          valueSuffix: " MJ/m²",
        },
        zIndex: 10,
      },
      {
        color: "#1BAD63",
        name: "발전효율(%)",
        type: "column",
        data: detailChartData.elecEffi,
        yAxis: 2,
        tooltip: {
          valueSuffix: " %",
        },
        zIndex: 10,
      },
      {
        color: "#E2AC2A",
        name: "일별평균(%)",
        type: "spline",
        data: detailChartData.dayEffi,
        yAxis: 3,
        tooltip: {
          valueSuffix: " %",
        },
        zIndex: 10,
      },
    ],
  };

  return (
    <>
      {selectedUser ?
        <Fragment>
          <HighchartsReact highcharts={Highcharts} options={chartOption} />
        </Fragment>
        :
        <div className="troubleShootingDetailNoUser">
          대상자를 선택해주세요
        </div>
      }
    </>

  );
}

export default TroubleShootingDetailChart;
