// 설비 정보 연료전지
import React , {useState , useEffect} from "react";
import kendo from '@progress/kendo-ui';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import styles from './meain.module.css';
import DateTimePickerKo from '../../com/dateInputs/dateTimePickerKo';
import DatePickerKo from '../../com/dateInputs/datePickerKo';
import { Input } from '@progress/kendo-react-inputs';

// 연료전지 설비 정보
function MeainInfoFuelcell({ selectMeain , setMeainInfo  , codeList , meainModlList , inputValidMsg , itemRender }){

  const [bldSrvCodeNo , setBldSrvCodeNo] = useState( selectMeain.bldSrvCodeNo ? { codeNo : selectMeain.bldSrvCodeNo , codeVal : "" } : { codeNo : "" , codeVal : "" } );
  const [modulMakr , setModulMakr] = useState( selectMeain.modulMakrNo ? { makrSeriNo : selectMeain.modulMakrNo , makrTerm : "" } : { makrSeriNo : "" , makrTerm : "" } );
  const [modulModl , setModulModl] = useState( selectMeain.meainTypeSeriNo ? { meainTypeSeriNo : selectMeain.meainTypeSeriNo , meainTypeTerm : "" } : { meainTypeSeriNo : "" , meainTypeTerm : "" } );
  const [invtMakr , setInvtMakr] = useState( selectMeain.invtMakrNo ? { makrSeriNo : selectMeain.invtMakrNo , makrTerm : "" } : { makrSeriNo : "" , makrTerm : "" } );
  const [invtModl , setInvtModl] = useState( selectMeain.invtTypeNo ? { meainTypeSeriNo : selectMeain.invtTypeNo , meainTypeTerm : "" } : { meainTypeSeriNo : "" , meainTypeTerm : "" } );

  // 건물 용도 코드 리스트
  const bldSrvDs = codeList.filter( c => c.grpCodeNo === 31 );

   // 연료전지 모듈 제조사
  const modulMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter(c => c.makrClsfNo === 30009) : [];

   // 연료전지 모듈 모델 
  const modulModlList = meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter(c=> c.ensoTypeCodeNo === 15006 && c.makrSeriNo === selectMeain.modulMakrNo) : []; 

  // 연료전지 인버터 제조사
  const invtMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter(c => c.makrClsfNo === 30010) : [];

  // 연료전지 인버터 모델 
  const invtModlList = meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter(c=> c.ensoTypeCodeNo === 15006 && c.makrSeriNo === selectMeain.invtMakrNo) : []; 
  
  // Input 데이터 값 변경 이벤트
  const onchageInput = (e) => {
    let meainInfo = {};
    const objName = e.target.name;
    // 연료전지는  모듈 용량이 설비 용량이다.
    if( e.target.name === "modulCapa" ){
      meainInfo = {
            ...selectMeain
            ,  [objName] : e.target.value
            ,  instCapa : e.target.value
        };
    }else{
        // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
        meainInfo = {
            ...selectMeain
            ,  [objName] : e.target.value
        };
    }
    setMeainInfo(meainInfo);
  }

  // Date 데이터 값 변경 이벤트
  const onchageDate = (v , name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const meainInfo = {
        ...selectMeain
        ,  [name] : kendo.toString(v , 'yyyy-MM-dd') 
    };
    setMeainInfo(meainInfo);
  }

  // DateTimeOnChangeDate
  const onChangeDateTiem = (v , name) =>{
    const meainInfo = {
      ...selectMeain
      ,  [name] : v
      // ,  [name] : kendo.toString(v , 'yyyy-MM-dd HH:mm:00')
    };
    setMeainInfo(meainInfo);
  }

  // 날짜 포맷 지정
  const dateFormat = (date) =>{
    if( typeof date !== "undefined" ){
        if( date !== "" ){ date = new Date(date); }
        else{ date = ""; } }
    else{ date = ""; }
    return date;
  }

  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(selectMeain).length !== 0 ){
      // 모듈
      if( selectMeain.modulMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        modulMakrList.forEach(element => {
            if( element.makrSeriNo === selectMeain.modulMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setModulMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      // 인버터
      if( selectMeain.invtMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        invtMakrList.forEach(element => {
            if( element.makrSeriNo === selectMeain.invtMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setInvtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }

    }else{
      setModulMakr({ makrSeriNo: '' , makrTerm: '' });
      setInvtMakr({ makrSeriNo: '' , makrTerm: '' });
    }
  }

  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(selectMeain).length !== 0 ){
      if( selectMeain.meainTypeSeriNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        modulModlList.forEach(element => {
            if( element.meainTypeSeriNo === selectMeain.meainTypeSeriNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setModulModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setModulModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( selectMeain.invtTypeNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        invtModlList.forEach(element => {
            if( element.meainTypeSeriNo === selectMeain.invtTypeNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setInvtModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setInvtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
    }else{
      setModulModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setInvtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
    }
  }

  // Converter 건물용도별
  const  bldSrvConverter = () =>{
    if( Object.keys(selectMeain).length !== 0 ){
        let codeNo = "";
        let codeVal = "";
        bldSrvDs.forEach(element => {
            if( element.codeNo === selectMeain.bldSrvCodeNo ){
                codeNo = element.codeNo;
                codeVal = element.codeVal;
            }
        });
        setBldSrvCodeNo({ codeNo: codeNo, codeVal: codeVal });
    }else{
        setBldSrvCodeNo({ codeNo: '', codeVal: '' });
    }
  }

  useEffect(() => {
     // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    bldSrvConverter();
    // eslint-disable-next-line
  }, [selectMeain]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  return(
      

    <table className="Ch-TableN">
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>모듈 제조사</th>     
            <td>
                <DropDownList className = "meainInfoDropDown" id = "modulMakrNo" name = "modulMakrNo" 
                    data = {modulMakrList} 
                    value = { modulMakr  } valid = {selectMeain.modulMakrNo !== "" && selectMeain.modulMakrNo !== null && typeof selectMeain.modulMakrNo !== "undefined" }
                    dataItemKey="makrSeriNo" textField = "makrTerm" 
                    onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.makrSeriNo }; 
                    setModulMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
                    required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
                />
            </td>
            <th>모듈 모델명</th>
            <td>
                <DropDownList className = "meainInfoDropDown" id = "meainTypeSeriNo" name = "meainTypeSeriNo" 
                    data = {modulModlList} 
                    valid = {selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined" }
                    value = { modulModl  } disabled = { selectMeain.modulMakrNo ? false : true }
                    dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
                    onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.meainTypeSeriNo}; 
                    setModulModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
                    required = {true} validationMessage = {inputValidMsg.normalText}  itemRender = {itemRender}
                />
            </td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>모듈 용량</th>
            <td><Input id = "modulCapa" name = "modulCapa" type="number" className={styles.searchTermTBC} step="0.01" min = {0}
                    value = { selectMeain.modulCapa ? selectMeain.modulCapa : "" } required = {true} validationMessage = {inputValidMsg.number2Fix}
                    onChange = {onchageInput}/></td>
            <th>모듈 총용량</th>     
            <td><Input id = "modulAlCapa" name = "modulAlCapa" type="number" className={styles.searchTermTBC} step="0.01" min = {0}
                    value = { selectMeain.modulAlCapa ? selectMeain.modulAlCapa : "" } required = {true} validationMessage = {inputValidMsg.number2Fix}
                    onChange = {onchageInput}/></td>
            <th>발전기 용량</th>
            <td><Input id = "generCapa" name = "generCapa" type="number" className={styles.searchTermTBC} step="0.01" min = {0}
                    value = { selectMeain.generCapa ? selectMeain.generCapa : "" } required = {true} validationMessage = {inputValidMsg.number2Fix}
                    onChange = {onchageInput}/></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>인버터 제조사</th>     
            <td>
                <DropDownList className = "meainInfoDropDown" id = "invtMakrNo" name = "invtMakrNo" 
                    data = {invtMakrList} 
                    value = { invtMakr } valid = {selectMeain.invtMakrNo !== "" && selectMeain.invtMakrNo !== null && typeof selectMeain.invtMakrNo !== "undefined" }
                    dataItemKey="makrSeriNo" textField = "makrTerm" required = {true} validationMessage = {inputValidMsg.normalText}
                    onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.makrSeriNo }; 
                    setInvtMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
                    itemRender = {itemRender}
                />
            </td>
            <th>인버터 모델명</th>
            <td>
                <DropDownList className = "meainInfoDropDown" id = "invtTypeNo" name = "invtTypeNo" 
                    data = {invtModlList} 
                    valid = {selectMeain.invtTypeNo !== "" && selectMeain.invtTypeNo !== null && typeof selectMeain.invtTypeNo !== "undefined" }
                    value = { invtModl  } disabled = { selectMeain.invtMakrNo ? false : true }
                    dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm"  required = {true} validationMessage = {inputValidMsg.normalText}
                    onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.meainTypeSeriNo}; 
                    setInvtModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
                    itemRender = {itemRender}
                />
            </td>
            <th>인버터 용량</th>
            <td><Input id = "invtCapa" name = "invtCapa" type="number" className={styles.searchTermTBC} step="0.01" min = {0}
                    value = { selectMeain.invtCapa ? selectMeain.invtCapa : "" } required = {true} validationMessage = {inputValidMsg.number2Fix}
                    onChange = {onchageInput}/></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>건물용도</th>
            <td>
              <DropDownList className = "bldSrvCodeNo" id = "bldSrvCodeNo" name = "bldSrvCodeNo" 
                data = { bldSrvDs } valid = {selectMeain.bldSrvCodeNo !== "" && selectMeain.bldSrvCodeNo !== null && typeof selectMeain.bldSrvCodeNo !== "undefined" }
                value = { bldSrvCodeNo } required = {true} validationMessage = {inputValidMsg.normalText}
                dataItemKey="codeNo" textField = "codeVal" 
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.codeNo }; setBldSrvCodeNo({codeNo : e.target.value.codeNo , codeVal : e.target.value.codeVal }); setMeainInfo(obj); } } 
                itemRender = {itemRender}
              />
            </td>
            <th>시공업체</th>
            <td>
              <Input id = "consEnte" name = "consEnte" type="text" className={styles.searchTermTBC} required = {true} validationMessage = {inputValidMsg.normalText}
              value = { selectMeain.consEnte ? selectMeain.consEnte : "" } onChange = {onchageInput}/>
            </td>
            <th>시공업체 연락처</th>
            <td><Input id = "consEnteCntct" name = "consEnteCntct" type="text" className={styles.searchTermTBC}
            placeholder = "하이픈(-) 없이 입력해주세요."
            onChange = {onchageInput}/></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>시공업체 핸드폰 연락처</th>
            <td><Input id = "consPhoneCntct" name = "consPhoneCntct" type="text" className={styles.searchTermTBC}  
                    placeholder = "하이픈(-) 없이 입력해주세요."
                    onChange = {onchageInput}/></td>
            <th>시공일시</th>
            <td>
                <DateTimePickerKo id = "meainInstDtm"  name = "meainInstDtm" className = "meainDatePicker"
                    value = {dateFormat(selectMeain.meainInstDtm)} 
                    format = {"yyyy-MM-dd HH:mm"}
                    formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                    setDtm = {(v) => { onChangeDateTiem(v , "meainInstDtm"); }} required = {true} validationMessage = {inputValidMsg.normalText}
                    >
                </DateTimePickerKo> 
            </td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
          <th>사용전검사일</th>
          <td>
            <DatePickerKo id = "useBforInspDtm"  name = "useBforInspDtm" className = "meainDatePicker"
            format="yyyy-MM-dd" value = {dateFormat(selectMeain.useBforInspDtm)} 
            formatPlaceholder={{ year: '년', month: '월', day: '일' }}
            setDtm = { (v) => { onchageDate(v , "useBforInspDtm"); } }
            required = {true} validationMessage = {inputValidMsg.normalText}
              >
            </DatePickerKo> 
          </td>
          <th>모니터링시작일시</th>
          <td>
              <DateTimePickerKo 
                id = "mntrStrtDtm" name = "mntrStrtDtm" className = "meainDatePicker"
                value = {dateFormat(selectMeain.mntrStrtDtm)} format = {"yyyy-MM-dd HH:mm"}
                formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                setDtm = {(v) => { onChangeDateTiem(v , "mntrStrtDtm"); }}
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></DateTimePickerKo>
          </td>
          <th>설치확인일</th>
          <td>
              <DatePickerKo id = "instCnfmDtm"  name = "instCnfmDtm" className = "meainDatePicker"
              format="yyyy-MM-dd" value = {dateFormat(selectMeain.instCnfmDtm)} 
              formatPlaceholder={{ year: '년', month: '월', day: '일' }}
              setDtm = { (v) => { onchageDate(v , "instCnfmDtm"); } }
              required = {true} validationMessage = {inputValidMsg.normalText}
                  >
              </DatePickerKo> 
          </td>
        </tr>
      <tr><th colSpan="6"><div></div></th></tr>
      <tr>
          <th>AS만료일</th>
              <td>
                <DatePickerKo 
                id = "asExpiDtm" name = "asExpiDtm" className = "meainDatePicker"   
                value = {dateFormat(selectMeain.asExpiDtm)} 
                format="yyyy-MM-dd"
                formatPlaceholder={{ year: '년', month: '월', day: '일' }}
                setDtm = {(v) => { onchageDate(v , "asExpiDtm"); }} required = {true} validationMessage = {inputValidMsg.normalText}
                ></DatePickerKo>
              </td>
          <th>특이사항</th>
          <td colSpan = "3"><Input id = "etc" name = "etc" type="text" className={styles.searchTermTBC} value = { selectMeain.etc ? selectMeain.etc : "" } onChange = {onchageInput}/></td>
      </tr>
      <tr><th colSpan="6"><div></div></th></tr>
    </table>

                  
  );
}

export default MeainInfoFuelcell;