import { useState, useEffect, useRef } from "react";

import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";

import { optomalDegreeData } from "../../../service/ai/optomalAzimIncl/optomalAzimInclService";

// 카카오 적용
const { kakao } = window;
const geocoder = new kakao.maps.services.Geocoder();
/***
 * 주의사항 : 카카오는 React 기반이 아닌 바닐라 자바스크립트 코드로 이루어져서 마커업과 클러스터 작업 시 
 *           데이터 소스를 useState 가 아닌 useRef 로 제어 해야한다. 
 *           맵은 데이터 소스가 랜더링 하기 전에 맵이 변경 되어 데이터 소스에 따라서 내가 원하는 마커와 클러스터를 그릴 수 없다. 
 *           그래서 데이터 소스를 state 방식이 아닌 useRef 로 제어 하여 랜더링에 제안이 아닌 쉽게 제어가 되도록 해야한다.
 * */


const AzimInclTab = ({ conn, styles, address, setAddress, chungnamRgnList, codeList, bundCode, selectedRgn, setSelectedRgn, userInfoVisible, setUserInfoVisible, setLoading, getRejection }) => {
    const [map, setMap] = useState("");
    const [optomalDegree, setOptomalDegree] = useState({ azim: "-", incl: "-" });
    const [search, setSearch] = useState({
        address: "", instCapa: "", bundCode: "",
        rgn: { rgnCode: "", rgnTermSimple: "시군구" },
        instPlace: { codeNo: "", codeVal: "설치위치" },
        instBldSrv: { codeNo: "", codeVal: "건물용도" },
        instType: { codeNo: "", codeVal: "설치유형" },
    });

    const [divAddress, setDivAddress] = useState("-");

    const instPlace = codeList.filter(item => item.grpCodeNo === 41);
    const instBldSrv = codeList.filter(item => item.grpCodeNo === 31);
    const instType = codeList.filter(item => item.grpCodeNo === 51);

    // const allChRgnId = ["44130", "44150", "44180", "44200", "44210", "44230", "44250", "44270", "44710", "44760", "44770", "44790", "44800", "44810", "44825",];

    //충남이 중앙에 보일 수 있는 값
    const DEFAULT_LATI = 36.4859;
    const DEFAULT_LONGI = 126.954;
    const DEFAULT_MAP_LEVEL = 11;
    const SEARCH_MAP_LEVEL = 1;

    let markers = useRef([]);

    const onChangeInstCapa = (e) => {
        setSearch(search => {
            const item = { ...search };
            item.instCapa = e.target.value;
            return item;
        });
    }

    const onnchageRgn = e => {        
        setSearch(search => {
            const item = { ...search };            
            if (item.rgn.rgnCode !== e.target.value.rgnCode) {
                item.address = "";
                item.bundCode ="";
                item.instCapa = "";
                item.instPlace.codeNo = "";
                item.instPlace.codeVal = "설치위치";
                item.instBldSrv.codeNo = "";
                item.instBldSrv.codeVal = "건물용도";
                item.instType.codeNo = "";
                item.instType.codeVal = "설치유형";
                setDivAddress("-");
                setOptomalDegree({ azim: "-", incl: "-" });
                if (map) {
                    removeMarkers();
                    // var defaultPosition = new kakao.maps.LatLng(DEFAULT_LATI, DEFAULT_LONGI);
                    let defaultPosition = new kakao.maps.LatLng(DEFAULT_LATI, DEFAULT_LONGI);
                    map.setLevel(DEFAULT_MAP_LEVEL);
                    map.panTo(defaultPosition);
                    setMap(map);
                }
            }
            item.rgn.rgnTermSimple = e.target.value.rgnTermSimple;
            item.rgn.rgnCode = e.target.value.rgnCode;
            return item;
        });
        const changeRgnCode = 'rgnCode_' + e.target.value.rgnCode;
        setSelectedRgn({ selectedRgn: changeRgnCode, selectedRgnCodeS: e.target.value.rgnCode });
    };

    const onnchageInstPlace = e => {
        setSearch(search => {
            const item = { ...search };
            item.instPlace.codeVal = e.target.value.codeVal;
            item.instPlace.codeNo = e.target.value.codeNo;
            return item;
        });
    };

    const onnchageinstBldSrv = e => {
        setSearch(search => {
            const item = { ...search };
            item.instBldSrv.codeVal = e.target.value.codeVal;
            item.instBldSrv.codeNo = e.target.value.codeNo;
            return item;
        });
    };

    const onnchageinstType = e => {
        setSearch(search => {
            const item = { ...search };
            item.instType.codeVal = e.target.value.codeVal;
            item.instType.codeNo = e.target.value.codeNo;
            return item;
        });
    };

    // 결과 데이터
    const getResult = (optomalDegreeData) => {
        setOptomalDegree({
            azim: optomalDegreeData.azim,
            incl: optomalDegreeData.incl
        });
        setLoading(false);
    };

    const removeMarkers = () => {
        for (let i = 0; i < markers.current.length; i++) { markers.current[i].setMap(null); }
        // 저장된 마커 전부 초기화
        if (search.dongCode === "") { markers.current = []; }
    }

    const searchAddress = () => {
        setUserInfoVisible(!userInfoVisible);
    }

    const searchBtn = () => {
        if (!search.address) {
            kendo.alert("주소를 입력해주세요");
            return;
        }
        if (!search.rgn.rgnCode) {
            kendo.alert("시군구를 선택해주세요");
            return;
        }
        if (!search.instPlace.codeNo) {
            kendo.alert("설치위치를 선택해주세요");
            return;
        }
        if (!search.instBldSrv.codeNo) {
            kendo.alert("건물용도를 선택해주세요");
            return;
        }
        if (!search.instType.codeNo) {
            kendo.alert("설치유형를 선택해주세요");
            return;
        }
        if (!search.instCapa) {
            kendo.alert("설치용량을 입력해주세요 (설치용량은 숫자만 입력해야합니다)");
            return;
        }
        if (search.instCapa <= 0) {
            kendo.alert("0보다 큰 값을 입력해주세요");
            return;
        }

        optomalDegreeData(conn, getResult, getRejection, search, setLoading);
        // for (let i = 0; i < allChRgnId.length; i++) {
        //     if (search.rgn.rgnCode == allChRgnId[i]) {
        //         setOptomalDegree({ azim: areaAzimIncl.data[i].areaAzim + "º", incl: areaAzimIncl.data[i].areaIncl + "º" });
        //         break;
        //     }
        // }

        removeMarkers();
        var nowPlaceName = search.address;
        geocoder.addressSearch(nowPlaceName, function (result, status) {
            if (status === kakao.maps.services.Status.OK) {
                var installPostiiton = new kakao.maps.LatLng(result[0].y, result[0].x);
                var marker = new kakao.maps.Marker({
                    position: installPostiiton
                });
                map.setLevel(SEARCH_MAP_LEVEL);
                map.panTo(installPostiiton);
                markers.current.push(marker);
                marker.setMap(map);
            }
        })
        setDivAddress(search.address);
    }


    //시작 시 기초 지도 화면 설정
    useEffect(() => {
        setAddress("");
        const map = new kakao.maps.Map(
            document.getElementById("locaitonMap"),
            {
                center: new kakao.maps.LatLng(DEFAULT_LATI, DEFAULT_LONGI), //지도의 중심좌표.
                level: DEFAULT_MAP_LEVEL, //지도의 레벨(확대, 축소 정도)
                mapTypeId: 3,
                draggable: false,
            }
        );
        setMap(map);
        /* eslint-disable-next-line */
    }, []);

    //왼쪽 지도와 검색 조건의 시군구를 연계
    //선택을 해제할 경우 search를 초기화
    //선택을 변경할 경우 시군구를 선택한 시군구로 변경하고 search의 나머지 값들을 초기화
    useEffect(() => {                
        if (!selectedRgn.selectedRgn) {
            setSearch(search => {
                const item = { ...search };
                item.rgn.rgnTermSimple = '시군구';
                item.rgn.rgnCode = '';
                item.address = "";
                item.bundCode ="";
                item.instCapa = "";
                item.instPlace.codeNo = "";
                item.instPlace.codeVal = "설치위치";
                item.instBldSrv.codeNo = "";
                item.instBldSrv.codeVal = "건물용도";
                item.instType.codeNo = "";
                item.instType.codeVal = "설치유형";
                return item;
            });
            setAddress("");
            setDivAddress("-");
            setOptomalDegree({ azim: "-", incl: "-" });
            if (map) {
                removeMarkers();
                // var defaultPosition = new kakao.maps.LatLng(DEFAULT_LATI, DEFAULT_LONGI);
                let defaultPosition = new kakao.maps.LatLng(DEFAULT_LATI, DEFAULT_LONGI);
                map.setLevel(DEFAULT_MAP_LEVEL);
                map.panTo(defaultPosition);
                setMap(map);
            }
        }
        else if (selectedRgn.selectedRgnCodeS !== search.rgn.rgnCode) {
            setSearch(search => {
                const item = { ...search };
                item.rgn.rgnTermSimple = chungnamRgnList.filter(item => item.rgnCode === selectedRgn.selectedRgnCodeS)[0].rgnTermSimple;
                item.rgn.rgnCode = selectedRgn.selectedRgnCodeS;
                item.address = "";
                item.bundCode ="";
                item.instCapa = "";
                item.instPlace.codeNo = "";
                item.instPlace.codeVal = "설치위치";
                item.instBldSrv.codeNo = "";
                item.instBldSrv.codeVal = "건물용도";
                item.instType.codeNo = "";
                item.instType.codeVal = "설치유형";
                return item;
            });
            setDivAddress("-");
            setOptomalDegree({ azim: "-", incl: "-" });
            if (map) {
                removeMarkers();
                // var defaultPosition = new kakao.maps.LatLng(DEFAULT_LATI, DEFAULT_LONGI);
                let defaultPosition = new kakao.maps.LatLng(DEFAULT_LATI, DEFAULT_LONGI);
                map.setLevel(DEFAULT_MAP_LEVEL);
                map.panTo(defaultPosition);
                setMap(map);
            }
        }
        /* eslint-disable-next-line */
    }, [selectedRgn]);

    useEffect(() => {
        setSearch(search => {
            const item = { ...search };
            item.address = address;
            return item;
        });
    }, [address]);

    useEffect(() => {
        setSearch(search => {
            const item = { ...search };
            item.bundCode = bundCode;
            return item;
        });
    }, [bundCode]);




    return (
        <dd className={styles.azimInclTabCon}>
            <div className={"md-card"}>
                <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                        최적의 방위각/경사각 예측
                    </h3>
                </div>
                <div className="md-card-content small-padding">
                    <div>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;시군구&nbsp;&nbsp;&nbsp;</b>
                        <DropDownList
                            className={styles.DropDownList}
                            data={chungnamRgnList}
                            textField="rgnTermSimple"
                            dataItemKey="rgnCode"
                            name="rgn"
                            onChange={onnchageRgn}
                            value={search.rgn}
                        />
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;주소검색&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                        <Input
                            id="address"
                            type="text"
                            className={styles.addressSearch}
                            placeholder="주소명을 입력하세요"
                            value={search.address}
                            onClick={searchAddress}
                            autoComplete='off'
                            readOnly
                        />
                        <br /><br />
                        <b>&nbsp;&nbsp;설치위치&nbsp;&nbsp;</b>
                        <DropDownList
                            className={styles.DropDownList}
                            data={instPlace}
                            textField="codeVal"
                            dataItemKey="codeNo"
                            name="instPlace"
                            onChange={onnchageInstPlace}
                            value={search.instPlace}
                        />
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;건물용도&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                        <DropDownList
                            className={styles.DropDownList}
                            data={instBldSrv}
                            textField="codeVal"
                            dataItemKey="codeNo"
                            name="instPurpose"
                            onChange={onnchageinstBldSrv}
                            value={search.instBldSrv}
                        />
                        <br /><br />
                        <b>&nbsp;&nbsp;설치유형&nbsp;&nbsp;</b>
                        <DropDownList
                            className={styles.DropDownList}
                            data={instType}
                            textField="codeVal"
                            dataItemKey="codeNo"
                            name="instType"
                            onChange={onnchageinstType}
                            value={search.instType}
                        />
                        <b>&nbsp;&nbsp;설치용량</b><span className={styles.capaTerm}>(kW)&nbsp;&nbsp;</span>
                        <input
                            id="instCapa"
                            type="number"
                            className={styles.instCapaSearch}
                            placeholder="설치용량을 입력하세요 (단위 : kW)"
                            value={search.instCapa}
                            onChange={onChangeInstCapa}
                            autoComplete='off'
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Button className="inqBtn" icon="search" onClick={searchBtn}></Button>
                    </div>
                </div>
            </div>
            <div className={styles.backgroundGray}>
                <div className={styles.nowPlaceName}>
                    <b>{divAddress}</b>
                    <br />
                </div>
                <br />
                <div className={styles.mapPhotoSpace}>
                    <div id='locaitonMap' style={{ width: '100%', height: '350px' }}></div>
                </div>
                <br />
                <div className={["uk-grid", styles.displayoptomalAzimIncl].join(' ')}>
                    <div className="uk-width-medium-1-2">
                        <h2>최적의 방위각</h2>
                        <div className={styles.answerBox}>
                            {optomalDegree.azim}
                        </div>
                    </div>
                    <div className="uk-width-medium-1-2">
                        <h2>최적의 경사각</h2>
                        <div className={styles.answerBox}>
                            {optomalDegree.incl}
                        </div>
                    </div>
                </div>
            </div>
        </dd >
    );
};

export default AzimInclTab;