import React from 'react';
import { numFormat, roundNum } from '../../../com/dataUtil';

const LocalAreaMonitorSmpRecDataInfo = ({styles , energyPriceData}) => {
  return (
    <div id = "localAreaMonitorSmpRecInfoArea" className={styles.localAreaMonitorSmpRecInfoArea}>
      <div className={styles.localAreaMonitorSmpRecInfoTitle}>
        <table>
          <tr>
            <td><img src='/images/localareaImg/42770/info/info.png' alt='정보' /></td>
            <td>상세 현황</td>
          </tr>
        </table>
      </div> 
      <div className={styles.localAreaMonitorSmpRecInfoTextArea}>
        <div>
          1. 태양광 발전량 기준으로 계산
        </div>
        <div>
          2. SMP 금일 수익금 ( 연동 , 미연동 ) : SMP x 연동 금일 발전량 또는 미연동 금일 발전량 (kWh)
        </div>
        <div>
          3. REC 금일 수익금 ( 연동 , 미연동 ) : REC x 연동 금일 발전량 또는 미연동 금일 발전량 (MWh) 
        </div> 
        <div>
          4. 탄소배출권 금일 수익금 ( 연동 , 미연동 ) : 탄소배출권 x 연동 금일 발전량 또는 미연동 금일 발전량 (MWh) x Co2 계수
        </div>   
      </div>
      <div>
        <table className={styles.localAreaMonitorSmpRecInfoTable} >
          <thead>
            <tr>
              <th rowSpan={2} >구분</th>
              <th rowSpan={2} >거래 가격</th>
              <th colSpan={3}>금일 수익금</th>
            </tr>
            <tr>
              <th>연동</th>
              <th>미연동</th>
              <th>총( 연동 + 미연동 )</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><img src="/images/localareaImg/42770/icon_50x45_04.png" alt='SMP' /><div>SMP</div></td>
              <td>{numFormat( roundNum ( energyPriceData.smp , 0 ) )} 원</td>
              <td className={styles.localAreaMonitorSmpRecSyncValue}>{numFormat( roundNum ( energyPriceData.smpRealTodayPrice , 0 ) )} 원</td>
              <td className={styles.localAreaMonitorSmpRecAsyncValue}>{numFormat( roundNum ( energyPriceData.smpExpeTodayPrice , 0 ) )} 원</td>
              <td>{numFormat( roundNum ( energyPriceData.smpTotalTodayPrice , 0 ) )} 원</td>
            </tr>
            <tr>
              <td><img src="/images/localareaImg/42770/icon_50x45_05.png" alt='REC' /><div>REC</div></td>
              <td>{numFormat( roundNum ( energyPriceData.rec , 0 ) )} 원</td>
              <td className={styles.localAreaMonitorSmpRecSyncValue}>{numFormat( roundNum ( energyPriceData.recRealTodayPrice , 0 ) )} 원</td>
              <td className={styles.localAreaMonitorSmpRecAsyncValue}>{numFormat( roundNum ( energyPriceData.recExpeTodayPrice , 0 ) )} 원</td>
              <td>{numFormat( roundNum ( energyPriceData.recTotalTodayPrice , 0 ) )} 원</td>
            </tr>
            <tr>
              <td><img src="/images/localareaImg/42770/icon_50x45_06.png" alt='CER' /><div>탄소배출권</div></td>
              <td>{numFormat( roundNum ( energyPriceData.cer , 0 ) )} 원</td>
              <td className={styles.localAreaMonitorSmpRecSyncValue}>{numFormat( roundNum ( energyPriceData.cerRealTodayPrice , 0 ) )} 원</td>
              <td className={styles.localAreaMonitorSmpRecAsyncValue}>{numFormat( roundNum ( energyPriceData.cerExpeTodayPrice , 0 ) )} 원</td>
              <td>{numFormat( roundNum ( energyPriceData.cerTotalTodayPrice , 0 ) )} 원</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.localAreaMonitorSmpRecSubTotalArea}>
        <img src="/images/localareaImg/42770/icon_50x45_07.png" alt='금일수입합계' /> <span>금일수익합계 (SMP+REC+CER) : {numFormat( roundNum ( energyPriceData.totalTodayPrice , 0 ))} 원 </span> 
      </div>
    </div>
  );
};

export default LocalAreaMonitorSmpRecDataInfo;