import React from "react";

import { getPayOrder } from "../../../../service/bizStat/com/expenseApiService";
import PayOrderModal from "./bizPayOrderModal";

const BizPayOrderGrid = ({
  conn,
  styles,
  bizStyles,
  search,
  selected,
  dataList,
  setDataList,
  getData,
  energyTypeCodeListData,
  setBizExpenseWindowVisible,
  setBizFileWindowVisible,
  payOrderWindowVisible,
  setPayOrderWindowVisible,
  setBizExpendWindowVisible,
  initalBizExpense,
  loadConvPayOrder,
  Grid,
  GridColumn,
  IntlProvider,
  LocalizationProvider,
  kendo,
  numFormat,
  useState,
  useEffect,
  Form,
  FormElement,
  Button,
  NumberFormat,
  DropDownList,
  Input,
}) => {
  // 추가 시 데이터 저장
  const payOrderGridAdd = e => {
    if (search.rgnCode !== "" && search.bizYyNo.toString() !== "") {
      setPayOrderWindowVisible(state => {
        const item = { ...state };
        item.visible = !payOrderWindowVisible.visible;
        return item;
      });

      setBizExpenseWindowVisible(false);
      setBizFileWindowVisible(false);
      setBizExpendWindowVisible(false);

      initalBizExpense();
      // loadConvPayOrder();
      getData();
    } else {
      kendo.alert("사업연도와 시군구를 선택해주세요");
    }
  };

  // 취소 클릭 시 이벤트
  const cancelBtnEvt = () => {
    setPayOrderWindowVisible(state => {
      const item = { ...state };
      item.visible = !payOrderWindowVisible.visible;
      return item;
    });
  };

  const cellBalance = e => {
    const item = e.dataItem;
    const field = e.field;

    if (field === "balance") {
      item.balance = item.amount - item.expend;
    }

    return <td>{numFormat(item[field])}</td>;
  };

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const { take, skip } = e.page;
    search.payOrderPaging.skip = skip;
    search.payOrderPaging.take = take;
    getPayOrder(conn, search).then(results => {
      setDataList(dataList => {
        const item = { ...dataList };
        item.payOrderGrid.data = results.results;
        item.payOrderGrid.total = results.total;
        return item;
      });
    });
  };

  const expandChange = props => {
    // console.log(props)
    let data = props.dataItem;

    setDataList(dataList => {
      const item = { ...dataList };
      item.payOrderInput.bizYy = search.bizYyNo;
      item.payOrderInput.rgnCode = search.rgnCode;
      item.payOrderInput.cityProvCode = search.cityProvCode;
      item.payOrderInput.energyTypeCode = data.energyTypeCode;
      item.payOrderInput.amount = data.amount;
      item.payOrderInput.consEnte = data.consEnte;
      item.payOrderInput.etc = data.etc;
      item.payOrderInput.createUpdateYn = false;
      return item;
    });
    setPayOrderWindowVisible(state => {
      const item = { ...state };
      item.visible = !payOrderWindowVisible.visible;
      return item;
    });
  };

  return (
    <div className="uk-width-medium-1-2">
      <div className="md-card">
        <div className="md-card-content">
          {payOrderWindowVisible.visible && (
            <PayOrderModal
              conn={conn}
              styles={styles}
              bizStyles={bizStyles}
              search={search}
              dataList={dataList}
              setDataList={setDataList}
              energyTypeCodeListData={energyTypeCodeListData}
              getData={getData}
              payOrderGridAdd={payOrderGridAdd}
              cancelBtnEvt={cancelBtnEvt}
              kendo={kendo}
              Form={Form}
              FormElement={FormElement}
              Button={Button}
              useState={useState}
              useEffect={useEffect}
              NumberFormat={NumberFormat}
              DropDownList={DropDownList}
              Input={Input}
            />
          )}
          <div className="md-card-toolbar-conv">
            <h3 className="md-card-toolbar-heading-text-left">지급 차수별 현황</h3>
            <div className={styles.convMdCardToolbar}>
              <button className="btn_pls" onClick={payOrderGridAdd}>
                등록
              </button>
            </div>
          </div>
          <div className="eer-Table-scr" style={{ height: "470px" }}>
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid
                  className="eer-Table eer-Table-striped"
                  // "bizExpendG"
                  data={dataList.payOrderGrid.data}
                  total={dataList.payOrderGrid.total}
                  filterable={false}
                  sortable={false}
                  skip={search.payOrderPaging.skip}
                  take={search.payOrderPaging.take}
                  pageable={{ buttonCount: 5 }}
                  onPageChange={handlePageChange}
                  onRowClick={expandChange}>
                  <GridColumn title="컨소시엄(업체)명" field="consEnte" width={150} />
                  <GridColumn title="에너지원" field="energyTypeCodeVal" />
                  <GridColumn title="협약금액" field="amount" cell={cellBalance} />
                  <GridColumn title="지출액" field="expend" cell={cellBalance} />
                  <GridColumn title="잔액" field="balance" cell={cellBalance} />
                  <GridColumn title="특이사항" field="etc" width={150} />
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BizPayOrderGrid;
