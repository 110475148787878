import React from "react";

const GeoChuncheonTwo = props => {
  const { mapClickEvt, mapOutEvt, mapOverEvt, goDepth, depthMapOverEvt, depthMapOutEvt } = props;
  return (
    <svg
      version="1.1"
      id="레이어_1"
      x="0px"
      y="0px"
      width="1030px"
      height="950px"
      viewBox="0 0 1030 950"
      enable-background="new 0 0 1030 950">
      <g>
        <polygon
          fill="#808080"
          points="801.485,655.6 801.485,652.232 798.115,652.232 794.755,652.232 791.391,652.232 788.022,648.873 
     784.66,645.514 781.294,645.514 777.93,645.514 777.93,642.152 774.567,642.152 771.203,642.152 774.567,638.773 774.567,635.412 
     774.567,632.053 774.567,628.691 771.203,628.691 771.203,625.328 767.843,621.951 764.479,618.592 761.11,615.229 761.11,611.867 
     757.75,611.867 757.75,608.504 754.379,608.504 754.379,605.131 751.018,601.77 751.018,598.41 747.656,598.41 744.289,595.043 
     744.289,591.684 740.926,588.322 737.566,588.322 737.566,584.949 734.196,584.949 734.196,581.586 730.835,581.586 
     727.468,581.586 724.108,581.586 724.108,578.227 724.108,574.865 720.744,568.133 720.744,564.77 720.744,561.408 
     717.378,561.408 714.013,561.408 710.647,558.041 707.283,558.041 707.283,554.682 703.923,551.313 703.923,547.947 
     707.283,547.947 707.283,544.582 710.647,544.582 714.013,541.221 714.013,537.861 717.378,537.861 717.378,534.494 
     717.378,531.123 720.744,531.123 717.378,524.4 717.378,521.04 714.013,517.674 714.013,514.3 717.378,507.579 717.378,504.219 
     717.378,500.85 717.378,497.486 717.378,494.118 714.013,494.118 714.013,490.758 710.647,490.758 710.647,487.395 
     707.283,487.395 703.923,487.395 703.923,484.031 700.553,484.031 697.194,480.664 693.83,480.664 693.83,477.299 693.83,473.936 
     693.83,470.573 690.467,470.573 687.104,470.573 683.736,470.573 680.372,473.936 673.642,473.936 670.282,473.936 
     666.919,473.936 663.548,473.936 663.548,470.573 660.188,470.573 660.188,457.113 663.548,457.113 663.548,453.753 
     663.548,443.659 663.548,430.207 670.282,423.473 670.282,389.835 670.282,376.383 666.919,369.646 663.548,349.463 
     663.548,342.739 660.188,336.004 660.188,325.918 660.188,322.557 653.458,295.639 660.188,272.094 660.188,268.726 
     660.188,265.364 660.188,261.995 660.188,258.632 660.188,255.274 660.188,251.91 656.822,251.91 653.458,251.91 653.458,255.274 
     650.094,255.274 646.731,255.274 643.371,258.632 640.008,258.632 636.636,261.995 636.636,258.632 633.277,258.632 
     633.277,255.274 633.277,251.91 633.277,245.173 633.277,241.811 629.907,238.451 629.907,235.089 633.277,235.089 
     633.277,231.721 629.907,224.99 629.907,221.628 626.547,214.9 626.547,208.169 623.183,208.169 623.183,201.446 619.816,198.084 
     616.452,194.717 613.092,194.717 609.722,191.349 606.363,187.987 599.635,184.625 599.635,181.263 592.905,177.895 
     589.541,177.895 589.541,174.528 589.541,171.166 592.905,164.442 596.272,161.074 602.996,154.345 609.722,147.622 
     613.092,147.622 616.452,147.622 613.092,144.26 606.363,144.26 606.363,140.891 602.996,140.891 602.996,137.523 599.635,137.523 
     596.272,134.161 592.905,134.161 592.905,130.801 589.541,127.438 586.18,124.07 586.18,120.7 582.811,117.338 579.451,117.338 
     576.08,117.338 572.719,117.338 569.357,117.338 569.357,113.978 565.993,110.616 562.63,110.616 562.63,107.248 559.27,107.248 
     559.27,103.879 559.27,100.518 555.901,100.518 555.901,97.155 552.54,97.155 552.54,93.794 552.54,90.427 552.54,87.065 
     549.169,83.697 545.808,83.697 542.446,83.697 542.446,80.335 539.076,80.335 532.356,76.973 532.356,80.335 532.356,83.697 
     528.985,87.065 525.624,83.697 522.258,83.697 518.897,87.065 518.897,90.427 518.897,93.794 522.258,97.155 522.258,100.518 
     518.897,103.879 515.534,103.879 512.165,107.248 512.165,110.616 508.803,110.616 505.433,113.978 502.073,117.338 
     498.71,117.338 498.71,120.7 498.71,124.07 495.343,124.07 491.981,127.438 488.62,130.801 488.62,134.161 488.62,137.523 
     485.249,137.523 481.892,137.523 478.522,137.523 475.162,137.523 471.799,134.161 468.432,134.161 465.07,134.161 
     461.708,137.523 458.338,140.891 458.338,144.26 461.708,147.622 461.708,150.983 468.432,161.074 468.432,164.442 
     471.799,167.804 478.522,174.528 481.892,181.263 485.249,184.625 488.62,194.717 488.62,198.084 488.62,201.446 491.981,208.169 
     491.981,211.538 502.073,211.538 502.073,228.352 505.433,241.811 502.073,255.274 491.981,265.364 485.249,282.178 
     481.892,288.916 481.892,295.639 475.162,305.736 475.162,322.557 471.799,325.918 468.432,332.642 468.432,342.739 
     465.07,349.463 458.338,369.646 454.977,369.646 454.977,376.383 451.608,379.746 434.797,403.287 434.797,406.651 
     407.883,436.934 397.785,447.022 384.331,460.477 367.507,477.299 360.781,480.664 354.056,504.219 347.326,510.939 
     327.141,521.04 323.776,534.494 320.415,541.221 320.415,544.582 313.684,547.947 303.593,564.77 290.13,571.496 280.04,584.949 
     273.313,591.684 269.953,608.504 266.589,621.951 266.589,632.053 266.589,638.773 263.218,648.873 256.498,662.332 
     253.129,672.414 249.764,679.154 249.764,689.24 246.397,706.063 243.034,726.244 243.034,736.344 243.034,746.424 
     243.034,780.072 246.397,790.166 249.764,800.254 253.129,803.627 256.498,806.986 256.498,810.348 249.764,840.629 
     249.764,854.076 246.397,860.816 246.397,870.897 253.129,870.897 256.498,870.897 269.953,864.176 276.679,860.816 
     283.409,864.176 286.77,864.176 290.13,867.537 290.13,870.897 303.593,874.27 306.954,870.897 310.324,874.27 320.415,874.27 
     320.415,877.637 323.776,877.637 327.141,874.27 330.505,874.27 333.865,874.27 337.236,880.998 350.69,884.357 357.42,884.357 
     360.781,880.998 364.147,880.998 370.871,877.637 374.238,877.637 384.331,877.637 391.061,880.998 394.425,880.998 
     397.785,887.717 401.153,887.717 407.883,887.717 414.602,891.084 417.972,891.084 421.337,894.457 424.697,897.818 
     424.697,901.178 428.067,901.178 431.426,897.818 434.797,901.178 438.157,901.178 441.52,901.178 444.887,901.178 
     448.248,901.178 451.608,901.178 454.977,901.178 458.338,904.539 461.708,904.539 461.708,907.898 465.07,911.279 
     468.432,907.898 471.799,907.898 475.162,907.898 475.162,911.279 475.162,914.639 478.522,918 481.892,918 481.892,921.359 
     488.62,921.359 491.981,921.359 495.343,918 491.981,918 488.62,918 485.249,914.639 481.892,911.279 485.249,907.898 
     485.249,901.178 488.62,897.818 491.981,894.457 488.62,894.457 488.62,891.084 488.62,884.357 491.981,880.998 491.981,877.637 
     491.981,870.897 491.981,867.537 491.981,860.816 491.981,857.457 491.981,854.076 491.981,850.715 488.62,847.354 488.62,843.996 
     485.249,843.996 485.249,840.629 485.249,837.256 488.62,833.895 488.62,830.527 491.981,830.527 491.981,827.168 491.981,823.809 
     491.981,820.447 491.981,817.074 491.981,813.715 491.981,810.348 495.343,810.348 495.343,806.986 502.073,810.348 
     505.433,810.348 508.803,810.348 512.165,810.348 515.534,810.348 518.897,806.986 522.258,806.986 525.624,806.986 
     532.356,806.986 539.076,806.986 539.076,810.348 542.446,810.348 542.446,813.715 545.808,817.074 549.169,820.447 
     552.54,827.168 555.901,827.168 559.27,827.168 562.63,827.168 565.993,827.168 569.357,827.168 569.357,830.527 572.719,830.527 
     572.719,833.895 576.08,833.895 579.451,833.895 579.451,830.527 579.451,827.168 582.811,827.168 582.811,830.527 586.18,830.527 
     586.18,833.895 586.18,837.256 589.541,833.895 592.905,837.256 592.905,840.629 596.272,840.629 599.635,847.354 602.996,847.354 
     602.996,850.715 606.363,854.076 609.722,854.076 609.722,857.457 613.092,857.457 613.092,860.816 616.452,864.176 
     619.816,864.176 623.183,864.176 623.183,867.537 636.636,867.537 640.008,870.897 646.731,867.537 650.094,867.537 
     653.458,867.537 656.822,867.537 660.188,874.27 663.548,874.27 666.919,870.897 683.736,867.537 687.104,860.816 690.467,860.816 
     700.553,860.816 703.923,857.457 707.283,854.076 710.647,847.354 710.647,843.996 714.013,843.996 717.378,843.996 
     720.744,840.629 724.108,840.629 727.468,840.629 730.835,840.629 734.196,840.629 734.196,843.996 737.566,840.629 
     740.926,840.629 740.926,837.256 744.289,837.256 737.566,833.895 734.196,830.527 734.196,827.168 730.835,827.168 
     730.835,823.809 730.835,820.447 727.468,817.074 727.468,813.715 730.835,813.715 730.835,810.348 724.108,810.348 
     720.744,810.348 720.744,806.986 717.378,806.986 714.013,803.627 714.013,800.254 710.647,800.254 710.647,796.887 
     707.283,796.887 707.283,793.527 703.923,793.527 703.923,790.166 700.553,790.166 700.553,786.807 697.194,783.426 
     697.194,780.072 693.83,773.346 690.467,766.625 690.467,763.244 687.104,763.244 687.104,759.885 690.467,759.885 
     690.467,756.523 693.83,753.164 697.194,753.164 700.553,753.164 700.553,749.797 700.553,746.424 703.923,746.424 
     703.923,743.063 707.283,736.344 710.647,736.344 714.013,732.984 714.013,726.244 717.378,722.883 720.744,722.883 
     724.108,722.883 724.108,719.523 727.468,716.156 724.108,712.797 734.196,709.422 737.566,709.422 737.566,706.063 
     737.566,702.695 740.926,702.695 744.289,702.695 747.656,702.695 751.018,702.695 754.379,702.695 757.75,702.695 761.11,702.695 
     764.479,699.336 764.479,695.975 767.843,695.975 771.203,695.975 774.567,692.602 777.93,692.602 777.93,689.24 781.294,689.24 
     784.66,685.875 784.66,682.514 788.022,679.154 791.391,679.154 791.391,675.779 794.755,675.779 794.755,672.414 791.391,672.414 
     794.755,669.053 794.755,665.693 798.115,662.332 801.485,662.332 804.844,658.961 804.844,655.6 	"
        />
        <polygon fill="#808080" points="724.108,534.494 720.744,531.123 720.744,534.494 	" />
        <polygon fill="#808080" points="424.697,904.539 424.697,901.178 421.337,901.178 	" />
      </g>
      <g>
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_A"
          id="dong_4211012300"
          fill="#E1E1E1"
          d="M573.466,670.032l-6.196-3.094h-9.735c-0.828,0-1.5-0.672-1.5-1.5v-1.86h-1.871c-0.828,0-1.5-0.672-1.5-1.5
     v-1.873h-1.86c-0.828,0-1.5-0.672-1.5-1.5v-1.861h-1.862c-0.13,0-0.259-0.017-0.38-0.049c-0.368-0.097-0.691-0.331-0.896-0.662
     c-0.137-0.221-0.211-0.47-0.222-0.722c-0.007-0.126,0.004-0.252,0.029-0.376s0.068-0.246,0.126-0.362l2.278-4.556h-0.937
     c-0.397,0-0.778-0.158-1.061-0.438l-2.925-2.921h-2.742c-0.828,0-1.5-0.672-1.5-1.5v-1.875h-1.86c-0.828,0-1.5-0.672-1.5-1.5
     v-1.865h-1.869c-0.231,0-0.462-0.054-0.67-0.157l-6.73-3.361c-0.146-0.072-0.276-0.167-0.391-0.281l-9.653-9.652h-2.739
     c-0.396,0-0.779-0.157-1.061-0.438l-0.819-0.819c-0.001,0.008-0.005,0.016-0.007,0.022c-0.021,0.112-0.052,0.222-0.098,0.326
     c-0.008,0.02-0.021,0.038-0.028,0.059c-0.015,0.026-0.028,0.051-0.043,0.076c-0.03,0.055-0.063,0.107-0.101,0.159
     c-0.022,0.033-0.05,0.063-0.076,0.094c-0.022,0.027-0.043,0.058-0.067,0.083l-2.933,2.932v2.74c0,0.828-0.672,1.5-1.5,1.5h-2.434
     l-2.793,5.575v3.011c0,0.828-0.672,1.5-1.5,1.5h-1.859v8.596c0,0.397-0.158,0.779-0.439,1.062l-0.804,0.805h3.104
     c0.828,0,1.5,0.672,1.5,1.5v3.361c0,0.828-0.672,1.5-1.5,1.5h-1.859v5.234c0,0.827-0.673,1.5-1.5,1.5h-1.863v1.057l9.422,6.281
     c0.374,0.249,0.616,0.651,0.66,1.1c0.011,0.111,0.01,0.224-0.004,0.333c-0.04,0.328-0.188,0.638-0.427,0.876l-4.188,4.193
     c0.149,0.022,0.296,0.069,0.433,0.139l6.728,3.367c0.431,0.216,0.73,0.626,0.809,1.103c0.077,0.477-0.079,0.96-0.42,1.302
     l-5.664,5.657l2.304,2.3c0.586,0.585,0.588,1.532,0.003,2.119l-6.289,6.304v2.739c0,0.828-0.672,1.5-1.5,1.5h-1.863v1.859
     c0,0.828-0.672,1.5-1.5,1.5h-1.869v11.959c0,0.828-0.672,1.5-1.5,1.5h-1.86v8.602c0,0.828-0.673,1.5-1.5,1.5h-1.87v1.859
     c0,0.828-0.673,1.5-1.5,1.5h-2.739l-6.291,6.282c-0.281,0.281-0.662,0.438-1.061,0.438h-2.28l-2.728,8.196l2.705,2.704
     c0.281,0.281,0.439,0.663,0.439,1.061v6.722c0,0.396-0.157,0.777-0.438,1.06l-2.304,2.31l2.305,2.313
     c0.28,0.281,0.438,0.662,0.438,1.059v5.222h0.362v-1.861c0-0.52,0.271-1.003,0.711-1.275c0.442-0.273,0.996-0.298,1.459-0.065
     l6.413,3.203h12.486l2.924-2.922c0.281-0.281,0.663-0.439,1.062-0.439h20.179c0.828,0,1.5,0.672,1.5,1.5v1.861h1.869
     c0.828,0,1.5,0.672,1.5,1.5v2.745l6.286,6.296c0.112,0.112,0.207,0.243,0.277,0.386l2.956,5.894h15.892c0.828,0,1.5,0.673,1.5,1.5
     v1.859h1.862c0.828,0,1.5,0.672,1.5,1.5v1.867h3.731v-5.228c0-0.827,0.672-1.5,1.5-1.5h3.359c0.828,0,1.5,0.673,1.5,1.5v1.859
     h1.869c0.828,0,1.5,0.672,1.5,1.5v3.106l0.801-0.801c0.586-0.586,1.535-0.586,2.121,0l3.362,3.361
     c0.281,0.281,0.439,0.662,0.439,1.062v1.872h1.868c0.567,0,1.088,0.321,1.342,0.829l2.948,5.897h2.434c0.828,0,1.5,0.672,1.5,1.5
     v2.737l2.487,2.482h2.738c0.828,0,1.5,0.672,1.5,1.5v1.881h1.869c0.828,0,1.5,0.672,1.5,1.5v2.738l2.481,2.479h6.11
     c0.827,0,1.5,0.673,1.5,1.5v1.861h11.952c0.396,0,0.777,0.157,1.06,0.438l2.606,2.598l5.757-2.877
     c0.209-0.104,0.438-0.158,0.672-0.158h10.091c0.567,0,1.088,0.321,1.342,0.829l2.951,5.903h1.813l2.933-2.935
     c0.209-0.209,0.476-0.353,0.767-0.41l16.099-3.216l1.95-3.894h-0.938c-0.397,0-0.778-0.158-1.061-0.438l-2.925-2.921h-12.832
     c-0.397,0-0.781-0.158-1.063-0.439l-2.933-2.94h-2.737c-0.827,0-1.5-0.672-1.5-1.5v-5.223h-1.865c-0.828,0-1.5-0.672-1.5-1.5
     v-2.738l-2.926-2.929c-0.279-0.281-0.438-0.662-0.438-1.06v-1.873h-1.863c-0.828,0-1.5-0.673-1.5-1.5v-10.089
     c0-0.827,0.672-1.5,1.5-1.5h15.324v-5.221c0-0.828,0.672-1.5,1.5-1.5h1.863v-8.6c0-0.828,0.672-1.5,1.5-1.5h2.736l5.851-5.843
     v-1.549l-29.104-6.466l-20.107-3.346c-0.147-0.024-0.291-0.071-0.425-0.138l-6.406-3.201h-3.016c-0.828,0-1.5-0.672-1.5-1.5v-1.881
     h-1.862c-0.828,0-1.5-0.672-1.5-1.5v-3.354c0-0.396,0.157-0.777,0.438-1.06l2.925-2.93v-45.979l-9.729-12.962L573.466,670.032z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_B"
          id="dong_4211012100"
          fill="#E1E1E1"
          d="M499.668,619.678c-0.508-0.255-0.828-0.772-0.828-1.342v-1.859h-5.224c-0.397,0-0.778-0.156-1.061-0.438
     l-3.369-3.359c-0.061-0.061-0.111-0.128-0.161-0.195c-0.007-0.011-0.017-0.02-0.022-0.028c-0.029-0.044-0.051-0.093-0.075-0.139
     c-0.021-0.04-0.047-0.078-0.063-0.12c-0.02-0.045-0.028-0.092-0.043-0.14c-0.016-0.048-0.033-0.093-0.043-0.143
     c-0.021-0.098-0.03-0.197-0.03-0.298v-1.861h-8.592c-0.101,0-0.198-0.01-0.295-0.028l-16.68-3.351h-5.076v1.879
     c0,0.828-0.673,1.5-1.5,1.5h-6.725c-0.397,0-0.781-0.159-1.063-0.44l-2.305-2.313l-1.862,1.872v2.742
     c0,0.606-0.365,1.153-0.927,1.386c-0.559,0.232-1.205,0.104-1.635-0.325l-2.922-2.922h-4.609v25.4c0,0.397-0.157,0.78-0.439,1.063
     l-3.37,3.361c-0.281,0.28-0.662,0.438-1.06,0.438h-1.859v4.618l2.92,2.921c0.281,0.28,0.439,0.662,0.439,1.061v6.372l3.212,6.418
     c0.104,0.208,0.157,0.438,0.157,0.671v15.322h1.86c0.828,0,1.5,0.672,1.5,1.5v13.447c0,0.396-0.158,0.778-0.438,1.061l-3.36,3.359
     c-0.281,0.28-0.663,0.438-1.062,0.438h-2.746l-2.482,2.499v9.108l3.201,6.416c0.104,0.208,0.158,0.438,0.158,0.67v9.465l2.929,2.92
     c0.282,0.281,0.44,0.664,0.44,1.063v3.136c0.014,0.079,0.021,0.161,0.021,0.245s-0.007,0.166-0.021,0.245v3.113
     c0,0.828-0.672,1.5-1.5,1.5h-1.869v1.861c0,0.828-0.673,1.5-1.5,1.5h-1.859v1.239l2.48,2.486h6.108c0.828,0,1.5,0.673,1.5,1.5
     v5.234h1.87c0.004,0,0.008,0,0.01,0h0.01c0.829,0,1.5,0.672,1.5,1.5c0,0.084-0.007,0.166-0.02,0.245v4.976h1.861
     c0.827,0,1.5,0.672,1.5,1.5v1.859h0.362v-1.859c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v1.859h1.866
     c0.828,0,1.5,0.672,1.5,1.5v1.867h1.863c0.828,0,1.5,0.672,1.5,1.5v2.752l2.921,2.92c0.429,0.429,0.558,1.074,0.325,1.635
     c-0.233,0.562-0.779,0.926-1.387,0.926h-1.859v18.683c0,0.828-0.672,1.5-1.5,1.5h-1.863v8.588c0,0.396-0.157,0.778-0.438,1.06
     l-2.929,2.935v1.247h1.244l2.924-2.928c0.281-0.281,0.663-0.439,1.063-0.439h3.359c0.828,0,1.5,0.672,1.5,1.5v1.867h0.37v-1.867
     c0-0.828,0.672-1.5,1.5-1.5h1.856v-1.874c0-0.398,0.157-0.781,0.439-1.063l2.932-2.922v-2.744c0-0.828,0.672-1.5,1.5-1.5h1.859
     v-18.68v-6.102l-2.923-2.935c-0.584-0.586-0.583-1.533,0.001-2.118l2.922-2.928v-5.479l-2.921-2.92
     c-0.4-0.4-0.542-0.995-0.362-1.534l3.36-10.102c0.203-0.613,0.777-1.026,1.423-1.026h2.743l6.289-6.28
     c0.281-0.281,0.663-0.439,1.061-0.439h1.86v-1.859c0-0.827,0.672-1.5,1.5-1.5h1.87v-8.602c0-0.828,0.672-1.5,1.5-1.5h1.861v-11.959
     c0-0.828,0.672-1.5,1.5-1.5h1.868v-1.859c0-0.827,0.673-1.5,1.5-1.5h1.863v-1.86c0-0.397,0.158-0.779,0.439-1.062l5.667-5.677
     l-2.307-2.301c-0.281-0.281-0.439-0.663-0.439-1.063c0-0.396,0.158-0.778,0.438-1.062l5.255-5.249l-4.549-2.277h-3.006
     c-0.605,0-1.152-0.365-1.386-0.926c-0.06-0.141-0.094-0.285-0.107-0.432c-0.042-0.438,0.11-0.882,0.433-1.203l5.435-5.44
     l-8.567-5.712c-0.418-0.278-0.668-0.746-0.668-1.248v-3.358c0-0.828,0.672-1.5,1.5-1.5h1.862v-5.234c0-0.828,0.672-1.5,1.5-1.5
     h1.86v-0.361h-5.225c-0.605,0-1.152-0.365-1.386-0.926c-0.232-0.562-0.104-1.205,0.325-1.635l2.924-2.927v-9.475
     c0-0.828,0.672-1.5,1.5-1.5h1.86v-1.865c0-0.232,0.055-0.463,0.159-0.672l3.365-6.721c0.254-0.508,0.773-0.828,1.342-0.828h1.859
     v-1.861c0-0.396,0.158-0.778,0.439-1.061l2.309-2.31l-2.136-2.13L499.668,619.678z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_C"
          id="dong_4211012400"
          fill="#E1E1E1"
          d="M682.005,636.656h-2.679l-19.733,23.024c-0.271,0.316-0.663,0.506-1.081,0.522
     c-0.414,0.018-0.822-0.144-1.118-0.438l-3.359-3.36c-0.281-0.281-0.439-0.663-0.439-1.062v-3.365c0-0.827,0.672-1.5,1.5-1.5h1.86
     v-0.36h-7.969l-2.924,2.922c-0.281,0.281-0.663,0.438-1.062,0.438h-6.731c-0.396,0-0.777-0.157-1.06-0.438l-0.825-0.824
     c-0.113,0.716-0.732,1.264-1.48,1.264h-2.438l-2.947,5.896c-0.255,0.508-0.773,0.829-1.343,0.829h-2.741l-2.92,2.932
     c-0.28,0.282-0.664,0.441-1.063,0.441h-1.863V678.9c0,0.827-0.672,1.5-1.5,1.5h-6.73c-0.398,0-0.78-0.158-1.063-0.44l-2.93-2.935
     h-2.738c-0.828,0-1.5-0.673-1.5-1.5v-1.859h-5.23c-0.398,0-0.78-0.158-1.063-0.44l-6.283-6.285h-1.248v1.858
     c0,0.828-0.672,1.5-1.5,1.5h-1.859v1.867c0,0.746-0.545,1.365-1.26,1.479l0.818,0.817c0.115,0.114,0.21,0.247,0.282,0.393
     l3.252,6.516l9.92,9.916c0.281,0.281,0.438,0.662,0.438,1.061v3.359v47.102c0,0.397-0.157,0.778-0.438,1.061l-2.925,2.929v1.232
     h1.862c0.828,0,1.5,0.672,1.5,1.5v1.881h1.868c0.232,0,0.462,0.054,0.671,0.158l6.521,3.259l19.965,3.324l30.355,6.741
     c0.688,0.152,1.176,0.762,1.176,1.465v3.373c0,0.397-0.158,0.779-0.44,1.062l-6.729,6.721c-0.281,0.28-0.662,0.438-1.061,0.438
     h-1.859v8.6c0,0.828-0.673,1.5-1.5,1.5h-1.863v5.221c0,0.828-0.672,1.5-1.5,1.5h-15.324v7.089h1.863c0.828,0,1.5,0.672,1.5,1.5
     v2.752l2.925,2.929c0.28,0.281,0.438,0.662,0.438,1.06v1.857h1.865c0.828,0,1.5,0.672,1.5,1.5v5.221h1.86
     c0.398,0,0.781,0.158,1.063,0.44l2.933,2.939h12.83c0.396,0,0.777,0.158,1.06,0.438l2.926,2.921h2.746h12.83l6.116-6.127
     l3.098-6.188v-3.006c0-0.828,0.673-1.5,1.5-1.5h6.109l2.927-2.928c0.281-0.281,0.663-0.438,1.062-0.438h13.451
     c0.749,0,1.368,0.549,1.48,1.267l0.828-0.828c0.281-0.28,0.663-0.438,1.062-0.438h1.859v-1.874c0-0.242,0.059-0.473,0.16-0.676
     l-2.691-1.345c-0.144-0.072-0.274-0.167-0.39-0.28l-3.37-3.366c-0.281-0.282-0.438-0.663-0.438-1.063v-1.859h-1.86
     c-0.828,0-1.5-0.672-1.5-1.5v-6.101l-2.929-2.934c-0.281-0.281-0.438-0.662-0.438-1.061v-3.358c0-0.828,0.672-1.5,1.5-1.5h1.867
     v-0.367h-8.591c-0.828,0-1.5-0.672-1.5-1.5v-1.861h-1.866c-0.396,0-0.777-0.158-1.06-0.438l-3.364-3.359
     c-0.282-0.281-0.44-0.663-0.44-1.063v-1.873h-1.865c-0.828,0-1.5-0.672-1.5-1.5v-1.866h-1.864c-0.828,0-1.5-0.672-1.5-1.5v-1.859
     h-1.86c-0.828,0-1.5-0.672-1.5-1.5v-1.861h-1.869c-0.827,0-1.5-0.672-1.5-1.5v-2.739l-2.924-2.942
     c-0.278-0.281-0.437-0.661-0.437-1.058v-2.999l-6.568-13.132c-0.104-0.207-0.158-0.438-0.158-0.671v-1.881h-1.862
     c-0.828,0-1.5-0.672-1.5-1.5v-3.358c0-0.828,0.672-1.5,1.5-1.5h1.862v-1.861c0-0.398,0.158-0.78,0.44-1.063l3.363-3.358
     c0.28-0.28,0.661-0.438,1.06-0.438h5.225v-5.239c0-0.828,0.672-1.5,1.5-1.5h1.868v-1.859c0-0.232,0.056-0.463,0.158-0.671
     l3.36-6.721c0.254-0.509,0.772-0.829,1.342-0.829h2.744l2.486-2.481v-6.118c0-0.397,0.157-0.778,0.438-1.062l3.364-3.361
     c0.281-0.28,0.663-0.438,1.061-0.438h5.229v-1.859c0-0.397,0.157-0.778,0.438-1.061l2.302-2.307l-2.301-2.3
     c-0.367-0.367-0.519-0.898-0.398-1.404c0.119-0.506,0.49-0.914,0.983-1.079l10.088-3.375c0.153-0.051,0.313-0.076,0.477-0.076h1.87
     v-5.228c0-0.828,0.672-1.5,1.5-1.5h22.924l2.49-2.481v-2.738c0-0.828,0.672-1.5,1.5-1.5h6.101l2.924-2.934
     c0.281-0.281,0.664-0.439,1.063-0.439h1.863v-1.861c0-0.828,0.672-1.5,1.5-1.5h2.742l2.487-2.486v-2.739
     c0-0.398,0.157-0.779,0.438-1.062l3.361-3.358c0.281-0.281,0.663-0.439,1.061-0.439h1.87v-1.875c0-0.828,0.672-1.5,1.5-1.5h1.863
     v-0.365h-1.863c-0.605,0-1.152-0.364-1.386-0.926c-0.233-0.561-0.104-1.206,0.325-1.635l2.924-2.922v-2.738
     c0-0.396,0.158-0.779,0.438-1.061l3.359-3.361c0.281-0.281,0.662-0.439,1.061-0.439h2.747l2.483-2.49v-1.241h-1.86
     c-0.827,0-1.5-0.672-1.5-1.5v-1.867h-8.593c-0.396,0-0.777-0.157-1.059-0.438l-6.294-6.28h-6.108c-0.828,0-1.5-0.672-1.5-1.5
     v-1.861h-5.228h-16.581l-9.859,3.284c-0.152,0.051-0.313,0.077-0.474,0.077h-5.225v1.858c0,0.828-0.672,1.5-1.5,1.5h-13.334
     l-18.809,3.131v2.097v13.453C683.505,635.984,682.833,636.656,682.005,636.656z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_D"
          id="dong_4211012000"
          fill="#E1E1E1"
          d="M579.218,453.498c0,0.062-0.004,0.125-0.012,0.186l-3.272,26.18l2.771,2.778l13.175,9.876l9.861,3.287
     c0.612,0.204,1.024,0.777,1.024,1.423v11.958h1.863c0.398,0,0.78,0.158,1.063,0.439l3.359,3.367
     c0.281,0.281,0.438,0.662,0.438,1.059v1.867h1.869c0.828,0,1.5,0.671,1.5,1.5c0,0.828-0.672,1.5-1.5,1.5h-1.869v1.245l2.929,2.92
     c0.282,0.281,0.44,0.663,0.44,1.062v10.093c0,0.828-0.673,1.5-1.5,1.5h-0.941l1.351,2.696l1.89-1.894
     c0.429-0.431,1.076-0.559,1.636-0.327c0.561,0.232,0.927,0.779,0.927,1.387v6.107l6.295,6.285c0.114,0.114,0.21,0.246,0.281,0.391
     l3.359,6.734c0.104,0.208,0.157,0.438,0.157,0.67v2.737l5.85,5.849h9.469c0.828,0,1.5,0.672,1.5,1.5v1.861h1.871
     c0.828,0,1.5,0.672,1.5,1.5v3.372c0,0.748-0.547,1.367-1.263,1.481l0.821,0.82c0.282,0.28,0.44,0.663,0.44,1.062v3.363
     c0,0.398-0.158,0.781-0.44,1.063l-2.931,2.923v2.735c0,0.396-0.157,0.777-0.437,1.059l-0.815,0.818h3.123
     c0.828,0,1.5,0.672,1.5,1.5v1.863h1.863c0.605,0,1.153,0.365,1.386,0.926c0.059,0.142,0.094,0.286,0.107,0.433
     c0.042,0.438-0.111,0.882-0.434,1.202l-2.924,2.923v4.6h1.863c0.399,0,0.782,0.159,1.063,0.442l3.36,3.379
     c0.279,0.28,0.438,0.661,0.438,1.058v1.861h1.868c0.828,0,1.5,0.672,1.5,1.5v1.858h1.86c0.101,0,0.198,0.011,0.294,0.029
     c0.288,0.058,0.555,0.199,0.768,0.409l2.924,2.921h2.746c0.828,0,1.5,0.672,1.5,1.5v1.867h13.819v-1.867
     c0-0.733,0.53-1.359,1.254-1.479l20.186-3.359c0.081-0.014,0.164-0.021,0.246-0.021h11.958v-1.858c0-0.828,0.672-1.5,1.5-1.5h6.479
     l9.859-3.284c0.151-0.051,0.313-0.077,0.474-0.077h16.201l2.486-2.498v-7.963h-1.863c-0.828,0-1.5-0.672-1.5-1.5v-2.744
     l-9.653-9.654c-0.282-0.281-0.439-0.663-0.439-1.063v-1.863h-1.859c-0.828,0-1.5-0.672-1.5-1.5v-1.862h-1.871
     c-0.828,0-1.5-0.673-1.5-1.5v-2.752l-2.921-2.923c-0.281-0.28-0.438-0.663-0.438-1.061v-1.859h-1.863
     c-0.397,0-0.779-0.157-1.062-0.438l-3.365-3.367c-0.281-0.281-0.439-0.663-0.439-1.061v-2.738l-2.484-2.482h-2.738
     c-0.828,0-1.5-0.671-1.5-1.5v-1.873h-1.87c-0.828,0-1.5-0.671-1.5-1.5v-1.862h-8.588c-0.828,0-1.5-0.673-1.5-1.5v-6.366
     l-3.205-6.417c-0.104-0.209-0.158-0.438-0.158-0.67v-5.226h-5.229c-0.397,0-0.779-0.157-1.062-0.438l-2.927-2.928h-2.743
     c-0.828,0-1.5-0.672-1.5-1.5v-2.738l-2.922-2.931c-0.281-0.28-0.438-0.661-0.438-1.059v-3.367c0-0.828,0.672-1.5,1.5-1.5h1.86
     v-1.864c0-0.829,0.672-1.5,1.5-1.5h2.744l2.485-2.482v-2.739c0-0.829,0.673-1.5,1.5-1.5h1.864v-3.736h-1.864
     c-0.827,0-1.5-0.672-1.5-1.5c0-0.829,0.673-1.5,1.5-1.5h1.864v-1.863c0-0.588,0.339-1.099,0.832-1.344l-0.673-1.346
     c-0.104-0.208-0.159-0.438-0.159-0.671v-2.739l-2.926-2.928c-0.28-0.281-0.438-0.663-0.438-1.06v-3.374
     c0-0.233,0.056-0.463,0.158-0.671l3.206-6.404v-11.606h-1.864c-0.827,0-1.5-0.671-1.5-1.5v-1.859h-1.865
     c-0.828,0-1.5-0.671-1.5-1.5V455h-5.226c-0.827,0-1.5-0.671-1.5-1.5v-1.863h-1.868c-0.398,0-0.78-0.158-1.063-0.44l-2.921-2.927
     h-2.741c-0.828,0-1.5-0.671-1.5-1.5v-8.591h-7.974l-2.484,2.484v2.742c0,0.829-0.672,1.5-1.5,1.5s-1.5-0.671-1.5-1.5v-1.863H641.63
     c-0.827,0-1.5-0.671-1.5-1.5v-1.863h-1.859c-0.828,0-1.5-0.671-1.5-1.5v-7.105l-3.888,7.776c-0.255,0.508-0.773,0.829-1.343,0.829
     h-26.911c-0.063,0-0.124-0.004-0.186-0.012l-25.227-3.15L579.218,453.498L579.218,453.498z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211011800"
          fill="#E1E1E1"
          d="M544.075,269.971h-1.863v8.592c0,0.232-0.054,0.462-0.158,0.67l-2.275,4.561h21.116
     c0.827,0,1.5,0.671,1.5,1.5v57.193c0,0.829-0.673,1.5-1.5,1.5h-9.639l-9.712,6.471c-0.247,0.164-0.536,0.251-0.832,0.251h-5.229
     v1.86c0,0.397-0.157,0.778-0.438,1.059l-2.923,2.931v6.114c0,0.829-0.672,1.5-1.5,1.5h-1.871v1.86c0,0.829-0.672,1.5-1.5,1.5h-1.86
     v0.358h1.86c0.828,0,1.5,0.671,1.5,1.5c0,0.829-0.672,1.5-1.5,1.5h-1.86v5.234c0,0.829-0.672,1.5-1.5,1.5h-3.215l-14.589,2.92
     l8.997,14.994c0.141,0.233,0.214,0.5,0.214,0.772v3.254l3.312,23.199l2.846,5.685h49.532c0.232,0,0.463,0.054,0.671,0.159
     l6.502,3.252l16.747,2.091l9.815,1.226h25.892l6.313-12.63c0.071-0.143,0.167-0.266,0.275-0.376
     c0.036-0.037,0.077-0.063,0.117-0.096c0.078-0.065,0.16-0.125,0.25-0.173c0.052-0.027,0.104-0.048,0.156-0.069
     c0.09-0.035,0.183-0.06,0.278-0.078c0.058-0.011,0.113-0.021,0.172-0.024c0.031-0.002,0.062-0.013,0.092-0.013h1.86v-25.406
     c0-0.398,0.158-0.779,0.439-1.061l6.295-6.294v-46.116l-3.205-6.42c-0.066-0.133-0.113-0.276-0.139-0.423l-3.37-20.183
     c-0.015-0.082-0.021-0.164-0.021-0.247v-6.37l-3.202-6.418c-0.104-0.208-0.158-0.438-0.158-0.67v-13.263l-6.685-26.739
     c-0.063-0.255-0.06-0.523,0.013-0.776l6.672-23.343v-18.475h-3.729v1.864c0,0.829-0.672,1.5-1.5,1.5h-6.106l-2.92,2.919
     c-0.28,0.281-0.663,0.439-1.061,0.439h-2.743l-2.932,2.925c-0.02,0.019-0.043,0.033-0.063,0.05
     c-0.053,0.047-0.105,0.095-0.164,0.134c-0.027,0.019-0.058,0.03-0.086,0.047c-0.056,0.032-0.111,0.066-0.172,0.091
     c-0.034,0.015-0.071,0.022-0.107,0.034c-0.057,0.019-0.111,0.04-0.172,0.052c-0.098,0.02-0.196,0.03-0.297,0.03h-2.739l-2.93,2.929
     c-0.281,0.281-0.663,0.439-1.062,0.439h-2.738l-2.924,2.923c-0.281,0.281-0.663,0.439-1.062,0.439h-8.592v1.869
     c0,0.829-0.672,1.5-1.5,1.5c-0.749,0-1.369-0.549-1.481-1.266l-0.388,0.387v11.331h1.869c0.151,0,0.3,0.023,0.438,0.066
     c0.422,0.129,0.771,0.44,0.946,0.86c0.058,0.14,0.094,0.286,0.107,0.432c0.027,0.292-0.03,0.586-0.168,0.846
     c-0.069,0.129-0.157,0.25-0.266,0.357l-0.825,0.826c0.717,0.113,1.266,0.733,1.266,1.482v3.362c0,0.829-0.672,1.5-1.5,1.5h-6.108
     l-2.928,2.923c-0.428,0.428-1.073,0.556-1.634,0.324c-0.562-0.232-0.926-0.779-0.926-1.386v-1.862h-7.093v1.862
     c0,0.829-0.672,1.5-1.5,1.5h-18.685v1.862c0,0.829-0.672,1.5-1.5,1.5h-5.228v1.866
     C545.575,269.299,544.904,269.971,544.075,269.971z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211012700"
          fill="#E1E1E1"
          d="M399.419,737.588c-0.828,0-1.5-0.672-1.5-1.5v-9.478l-2.485-2.483h-2.748c-0.828,0-1.5-0.672-1.5-1.5V717.4
     h-1.86c-0.828,0-1.5-0.673-1.5-1.5v-1.873h-1.859c-0.828,0-1.5-0.672-1.5-1.5v-10.08c0-0.232,0.054-0.461,0.157-0.669l2.878-5.774
     l-2.156-2.156h-16.199c-0.828,0-1.5-0.672-1.5-1.5v-1.86h-8.591c-0.828,0-1.5-0.672-1.5-1.5v-1.859h-1.87
     c-0.828,0-1.5-0.672-1.5-1.5v-1.867h-5.229c-0.828,0-1.5-0.672-1.5-1.5v-2.434l-3.725-1.869v7.67c0,0.828-0.672,1.5-1.5,1.5h-15.32
     v1.859c0,0.397-0.158,0.779-0.439,1.062l-6.73,6.721c-0.281,0.28-0.662,0.438-1.06,0.438h-3.36c-0.828,0-1.5-0.672-1.5-1.5v-1.859
     h-0.37v1.859c0,0.828-0.672,1.5-1.5,1.5h-10.09c-0.696,0-1.301-0.479-1.46-1.156c-0.16-0.677,0.167-1.375,0.79-1.686l6.281-3.137
     l2.979-5.956v-9.623l-3.131-18.807H298.5c-0.827,0-1.5-0.672-1.5-1.5v-1.864h-7.091v1.864c0,0.828-0.672,1.5-1.5,1.5h-3.37
     c-0.827,0-1.5-0.672-1.5-1.5v-1.864h-31.52l-2.748,2.753l-3.256,6.517c-0.072,0.146-0.167,0.277-0.282,0.392l-6.29,6.281v2.745
     c0,0.828-0.672,1.5-1.5,1.5h-12.189l-3.135,18.806v53.461l6.542,19.615l6.481,6.479c0.282,0.28,0.439,0.664,0.439,1.063v0.935
     l4.551-2.275c0.208-0.104,0.438-0.158,0.671-0.158h10.094c0.828,0,1.5,0.672,1.5,1.5v1.861h1.867c0.232,0,0.463,0.055,0.671,0.158
     l6.413,3.209h16.46c0.161,0,0.319,0.025,0.474,0.075l10.101,3.359c0.223,0.074,0.425,0.199,0.59,0.365l2.921,2.932h2.739
     c0.397,0,0.778,0.157,1.061,0.438l2.928,2.923h7.971v-5.234c0-0.396,0.157-0.778,0.438-1.061l2.921-2.92v-2.746
     c0-0.828,0.672-1.5,1.5-1.5h3.37c0.397,0,0.78,0.158,1.062,0.439l2.922,2.928h16.201c0.232,0,0.463,0.054,0.671,0.158l6.727,3.358
     c0.146,0.072,0.278,0.168,0.394,0.283l2.921,2.932h5.483l2.486-2.493v-9.468c0-0.828,0.672-1.5,1.5-1.5h2.749l6.279-6.291
     c0.28-0.282,0.663-0.44,1.063-0.44h3.37c0.828,0,1.5,0.672,1.5,1.5v1.873h8.591c0.397,0,0.779,0.157,1.062,0.438l2.92,2.92h12.84
     c0.828,0,1.5,0.672,1.5,1.5v0.936l4.553-2.276c0.575-0.286,1.272-0.177,1.73,0.279l0.811,0.81v-3.105
     c0-0.232,0.054-0.461,0.157-0.669l2.879-5.774l-2.597-2.596c-0.281-0.281-0.439-0.663-0.439-1.062v-6.101l-2.933-2.942
     c-0.28-0.28-0.438-0.661-0.438-1.059v-3.354c0-0.002,0-0.004,0-0.006v-0.001v-0.001v-0.001c0,0,0-0.001,0-0.002
     c0,0,0-0.003,0-0.007c0.002-0.185,0.037-0.359,0.101-0.521c0.018-0.044,0.052-0.077,0.072-0.12c0.056-0.11,0.108-0.224,0.188-0.315
     c0-0.001,0.001-0.002,0.001-0.002c0-0.001,0.001-0.001,0.001-0.001c0.002-0.002,0.004-0.003,0.005-0.005
     c0.002-0.002,0.002-0.004,0.004-0.006l0.001-0.001c0.001-0.001,0.002-0.002,0.002-0.002c0.063-0.069,0.129-0.134,0.202-0.19
     c0.002-0.001,0.003-0.002,0.004-0.003c0.002-0.001,0.004-0.002,0.006-0.004c0.002-0.001,0.003-0.003,0.005-0.004v-0.001h0.001
     c0.097-0.073,0.205-0.128,0.316-0.178c0.018-0.008,0.031-0.021,0.049-0.026c0.135-0.053,0.277-0.086,0.428-0.099
     c0.035-0.002,0.068-0.004,0.105-0.003c0.007,0,0.012-0.001,0.02-0.001h0.001h1.858v-1.866c0-0.397,0.158-0.779,0.438-1.062
     l2.301-2.299l-1.86-1.861H399.419L399.419,737.588z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211011200"
          fill="#E1E1E1"
          d="M606.129,542.469v5.222c0,0.827-0.672,1.5-1.5,1.5h-1.863v1.862c0,0.398-0.157,0.779-0.438,1.062
     l-3.368,3.373c-0.281,0.28-0.663,0.438-1.062,0.438h-5.225v1.861c0,0.828-0.672,1.5-1.5,1.5h-1.869v1.858
     c0,0.828-0.672,1.5-1.5,1.5h-6.104l-1.862,1.866l2.301,2.3c0.586,0.586,0.586,1.535,0,2.121l-3.359,3.361
     c-0.281,0.281-0.663,0.439-1.062,0.439h-5.23v5.235c0,0.397-0.158,0.779-0.439,1.062l-6.724,6.725
     c-0.281,0.281-0.663,0.439-1.062,0.439h-2.748l-2.921,2.92c-0.43,0.43-1.075,0.559-1.635,0.325
     c-0.562-0.232-0.926-0.779-0.926-1.387v-1.858h-1.871c-0.232,0-0.463-0.055-0.671-0.158l-5.758-2.878l-2.599,2.597
     c-0.281,0.281-0.663,0.439-1.061,0.439h-3.363c-0.397,0-0.779-0.158-1.062-0.439l-0.818-0.818c-0.115,0.714-0.734,1.259-1.48,1.259
     h-2.441l-6.316,12.632c-0.254,0.508-0.773,0.829-1.342,0.829h-2.906l-9.591,6.387l-6.626,6.634c-0.281,0.282-0.663,0.44-1.063,0.44
     h-3.359c-0.828,0-1.5-0.672-1.5-1.5v-1.861h-5.228c-0.828,0-1.5-0.672-1.5-1.5v-1.879h-1.618l0.817,0.819
     c0.428,0.43,0.556,1.073,0.323,1.635c-0.232,0.56-0.779,0.925-1.387,0.925h-1.868v1.238l2.488,2.481h6.104
     c0.828,0,1.5,0.672,1.5,1.5v2.434l5.897,2.952c0.144,0.071,0.273,0.166,0.389,0.278l0.829,0.828
     c0.008-0.041,0.024-0.077,0.034-0.116c0.014-0.055,0.027-0.105,0.046-0.157c0.029-0.081,0.066-0.155,0.109-0.229
     c0.025-0.045,0.049-0.091,0.079-0.133c0.054-0.075,0.118-0.143,0.187-0.205c0.031-0.031,0.06-0.065,0.095-0.094
     c0.107-0.086,0.227-0.161,0.357-0.217c0.561-0.232,1.205-0.104,1.636,0.325l2.921,2.922h2.739c0.396,0,0.778,0.158,1.061,0.439
     l9.921,9.919l6.196,3.095h3.016c0.828,0,1.5,0.672,1.5,1.5v1.865h1.86c0.828,0,1.5,0.672,1.5,1.5v1.875h1.863
     c0.1,0,0.197,0.01,0.294,0.029c0.287,0.058,0.555,0.198,0.767,0.408l2.924,2.922h2.742c0.52,0,1.003,0.27,1.276,0.711
     c0.271,0.441,0.298,0.995,0.064,1.46l-2.278,4.556h0.938c0.828,0,1.5,0.672,1.5,1.5v1.861h1.86c0.827,0,1.5,0.672,1.5,1.5v1.873
     h1.87c0.828,0,1.5,0.672,1.5,1.5v1.86h8.591c0.231,0,0.462,0.055,0.67,0.158l6.73,3.359c0.146,0.072,0.277,0.167,0.393,0.281
     l1.62,1.623l2.562,2.563c0.02-0.113,0.05-0.224,0.095-0.332c0.231-0.561,0.778-0.927,1.386-0.927h1.866v-1.867
     c0-0.828,0.672-1.5,1.5-1.5h1.86v-1.859c0-0.827,0.672-1.5,1.5-1.5h3.369c0.396,0,0.778,0.158,1.061,0.439l6.285,6.287h6.109
     c0.828,0,1.5,0.672,1.5,1.5v1.859h1.859c0.398,0,0.78,0.157,1.063,0.439l2.93,2.936h4.609v-15.322c0-0.828,0.672-1.5,1.5-1.5h2.74
     l2.92-2.933c0.28-0.281,0.664-0.44,1.063-0.44h2.438l2.947-5.897c0.255-0.508,0.773-0.829,1.343-0.829h1.862v-1.86
     c0-0.606,0.365-1.153,0.927-1.386c0.561-0.233,1.205-0.104,1.634,0.323l2.928,2.923h5.489l2.924-2.922
     c0.281-0.281,0.663-0.438,1.061-0.438h10.091c0.827,0,1.5,0.672,1.5,1.5v3.36c0,0.828-0.673,1.5-1.5,1.5h-1.86v1.244l1.775,1.776
     l19.128-22.319c0.285-0.332,0.701-0.522,1.139-0.522h1.869v-10.453h-15.319c-0.828,0-1.5-0.672-1.5-1.5v-1.867h-1.867
     c-0.101,0-0.198-0.01-0.295-0.029c-0.287-0.058-0.555-0.198-0.767-0.409l-2.924-2.921h-2.739c-0.828,0-1.5-0.672-1.5-1.5v-1.858
     h-1.869c-0.828,0-1.5-0.672-1.5-1.5v-2.742l-2.483-2.498h-2.739c-0.828,0-1.5-0.672-1.5-1.5v-6.721
     c0-0.398,0.158-0.779,0.439-1.062l0.819-0.819c-0.714-0.114-1.26-0.733-1.26-1.48v-1.863h-5.231c-0.605,0-1.151-0.363-1.385-0.924
     c-0.232-0.56-0.104-1.205,0.321-1.634l2.924-2.938v-2.739c0-0.398,0.158-0.781,0.44-1.063l2.931-2.922v-2.118l-2.931-2.924
     c-0.431-0.429-0.56-1.074-0.326-1.635c0.23-0.562,0.777-0.927,1.386-0.927h1.871v-0.373h-1.871c-0.828,0-1.5-0.672-1.5-1.5v-1.861
     h-8.59c-0.101,0-0.198-0.01-0.294-0.029c-0.288-0.058-0.556-0.198-0.768-0.408l-6.729-6.729c-0.28-0.281-0.438-0.663-0.438-1.061
     v-3.007l-3.093-6.198l-6.56-6.549c-0.282-0.281-0.441-0.664-0.441-1.063v-3.102l-0.798,0.8c-0.078,0.078-0.165,0.145-0.257,0.202
     c-0.033,0.021-0.068,0.036-0.104,0.057c-0.061,0.031-0.121,0.062-0.186,0.086c-0.043,0.016-0.085,0.027-0.129,0.039
     c-0.063,0.017-0.127,0.029-0.192,0.037c-0.043,0.006-0.085,0.012-0.13,0.014c-0.021,0.001-0.043,0.006-0.065,0.006h-5.229V542.469z
     "
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211011700"
          fill="#E1E1E1"
          d="M450.504,606.756h4.602v-1.879c0-0.828,0.672-1.5,1.5-1.5h6.727c0.1,0,0.198,0.01,0.295,0.029l16.679,3.35
     h9.697l-0.817-0.819c-0.095-0.095-0.168-0.203-0.233-0.317c-0.017-0.028-0.031-0.057-0.045-0.085
     c-0.058-0.116-0.104-0.236-0.13-0.363c0-0.003,0-0.006,0-0.01c-0.02-0.093-0.028-0.188-0.028-0.283v-3.361
     c0-0.232,0.055-0.464,0.159-0.672l3.21-6.402v-5.764l-0.369-0.368v3.122c0,0.828-0.672,1.5-1.5,1.5h-3.361
     c-0.75,0-1.37-0.55-1.481-1.269l-0.826,0.828c-0.281,0.282-0.663,0.439-1.063,0.439h-3.359c-0.828,0-1.5-0.672-1.5-1.5v-5.235
     h-1.863c-0.828,0-1.5-0.672-1.5-1.5v-1.861h-1.866c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5h1.866v-1.241l-2.927-2.924
     c-0.429-0.43-0.559-1.074-0.325-1.636c0.23-0.562,0.779-0.927,1.386-0.927h1.866v-3.733h-1.866c-0.828,0-1.5-0.672-1.5-1.5v-3.359
     c0-0.828,0.672-1.5,1.5-1.5h1.866v-8.588c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v1.861h3.724v-1.861
     c0-0.396,0.158-0.778,0.438-1.06l2.932-2.936v-2.742c0-0.232,0.056-0.463,0.158-0.672l2.275-4.549h-0.935
     c-0.396,0-0.778-0.158-1.061-0.438l-3.37-3.369c-0.429-0.431-0.559-1.074-0.325-1.636c0.233-0.561,0.779-0.926,1.386-0.926h1.87
     v-1.862c0-0.829,0.672-1.5,1.5-1.5h1.861v-1.863c0-0.398,0.158-0.781,0.439-1.063l6.292-6.286v-2.738c0-0.828,0.672-1.5,1.5-1.5
     h1.86v-1.247l-2.482-2.487h-2.74c-0.828,0-1.5-0.671-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5h1.862v-1.865c0-0.829,0.672-1.5,1.5-1.5
     h5.228v-7.968l-2.928-2.931c-0.43-0.43-0.558-1.074-0.324-1.635c0.231-0.561,0.779-0.926,1.386-0.926h1.866v-8.583
     c0-0.396,0.157-0.777,0.438-1.06l2.923-2.928v-2.754c0-0.296,0.088-0.586,0.253-0.832l6.479-9.703v-6.278
     c0-0.397,0.157-0.778,0.438-1.06l2.922-2.93v-7.966h-1.86c-0.828,0-1.5-0.671-1.5-1.5v-6.732c0-0.52,0.271-1.002,0.711-1.276
     c0.442-0.274,0.994-0.298,1.46-0.066l4.56,2.279v-14.036l-3.212-6.416c-0.071-0.145-0.119-0.3-0.144-0.459l-3.177-22.254h-1.815
     l-9.53,3.174l-3.109,3.11c-0.069,0.07-0.146,0.133-0.229,0.188l-9.971,6.646l-6.625,6.625c-0.114,0.114-0.246,0.209-0.391,0.281
     l-6.607,3.3l-13.343,10.018c-0.105,0.079-0.221,0.144-0.344,0.193l-16.823,6.724c-0.177,0.071-0.366,0.107-0.558,0.107h-9.592
     l-12.457,9.345v2.613c0,0.296-0.088,0.585-0.252,0.832l-6.611,9.925l-3.229,9.672v3.117c0,0.567-0.321,1.088-0.83,1.342
     l-1.038,0.518h0.368c0.828,0,1.5,0.672,1.5,1.5v16.824c0,0.161-0.026,0.321-0.077,0.475l-3.331,10.01l-3.352,16.729
     c-0.075,0.373-0.289,0.705-0.6,0.927l-22.562,16.123l9.438,22.023l9.724,6.473l13.528,10.148c0.058,0.043,0.11,0.09,0.161,0.14
     l9.918,9.927l13.232,6.614c0.144,0.072,0.274,0.166,0.388,0.279l3.199,3.188l6.508,3.253c0.133,0.065,0.247,0.154,0.353,0.255
     c0.012,0.011,0.027,0.017,0.04,0.027L450.504,606.756z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211011900"
          fill="#E1E1E1"
          d="M520.528,374.625h1.862v-0.37h-1.862c-0.828,0-1.5-0.671-1.5-1.5v-3.364c0-0.829,0.672-1.5,1.5-1.5h1.862
     v-1.858c0-0.829,0.673-1.5,1.5-1.5h1.86v-1.86c0-0.829,0.672-1.5,1.5-1.5h1.871v-5.234c0-0.397,0.157-0.778,0.438-1.059
     l2.924-2.931v-2.74c0-0.829,0.672-1.5,1.5-1.5h6.274l9.712-6.471c0.247-0.164,0.536-0.251,0.832-0.251h8.592v-54.193h-22.041
     c-0.521,0-1.002-0.269-1.275-0.711s-0.299-0.994-0.066-1.459l3.202-6.414v-9.739c0-0.829,0.672-1.5,1.5-1.5h1.863v-0.366h-5.224
     c-0.828,0-1.5-0.671-1.5-1.5v-12.833l-2.931-2.93c-0.281-0.281-0.438-0.663-0.438-1.061v-29.915l-3.202-6.42
     c-0.232-0.465-0.207-1.017,0.065-1.458c0.273-0.441,0.756-0.711,1.275-0.711h1.86v-11.599l-3.202-6.414
     c-0.104-0.208-0.158-0.438-0.158-0.67v-9.468l-2.931-2.922c-0.282-0.281-0.44-0.664-0.44-1.062v-5.23h-1.239l-9.214,9.213v2.746
     c0,0.398-0.158,0.779-0.438,1.061l-3.36,3.362c-0.281,0.281-0.662,0.439-1.061,0.439h-5.231v1.862c0,0.606-0.365,1.153-0.926,1.386
     c-0.56,0.23-1.206,0.104-1.634-0.324l-2.929-2.923h-2.738c-0.828,0-1.5-0.671-1.5-1.5v-2.741l-2.485-2.488h-2.747
     c-0.828,0-1.5-0.671-1.5-1.5v-1.862h-7.092v15.129l3.314,13.28c0.061,0.238,0.061,0.488,0,0.727l-3.359,13.463
     c-0.065,0.264-0.202,0.505-0.396,0.698l-9.875,9.872l-6.617,16.529c-0.016,0.038-0.031,0.075-0.05,0.111l-3.198,6.421v6.371
     c0,0.296-0.089,0.585-0.252,0.832l-6.479,9.719v16.367c0,0.398-0.158,0.78-0.438,1.061l-3.191,3.189l-3.1,6.189v9.743
     c0,0.233-0.055,0.463-0.157,0.671l-3.315,6.628l-6.04,18.107h4.407l9.526-3.174l6.479-6.472c0.584-0.585,1.534-0.584,2.12,0
     l10.088,10.084c0.281,0.281,0.439,0.663,0.439,1.061v2.441l5.9,2.954c0.056,0.028,0.108,0.059,0.159,0.093l10.094,6.722
     c0.083,0.055,0.159,0.118,0.229,0.188l6.729,6.73c0.281,0.281,0.439,0.663,0.439,1.061v6.379l2.79,5.573h2.434
     c0.646,0,1.22,0.413,1.423,1.026l2.947,8.844l15.523-3.106C520.33,374.634,520.428,374.625,520.528,374.625z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211012900"
          fill="#E1E1E1"
          d="M378.363,539.749l23.062-16.479l3.254-16.239c0.013-0.062,0.028-0.119,0.049-0.179l3.282-9.867v-15.081
     h-5.229c-0.696,0-1.301-0.479-1.46-1.156c-0.16-0.677,0.167-1.375,0.79-1.687l5.899-2.945v-2.434c0-0.162,0.026-0.321,0.077-0.475
     l3.37-10.093c0.042-0.126,0.101-0.246,0.175-0.356l6.473-9.714v-2.909c0-0.472,0.223-0.917,0.6-1.2l13.457-10.095
     c0.26-0.195,0.576-0.3,0.9-0.3h9.803l16.371-6.542l13.292-9.98c0.072-0.054,0.149-0.102,0.229-0.142l6.512-3.251l6.559-6.558
     c0.069-0.07,0.146-0.133,0.229-0.188l9.971-6.646l3.256-3.255c0.165-0.165,0.365-0.289,0.587-0.363l10.098-3.363
     c0.152-0.051,0.313-0.077,0.475-0.077h1.859v-1.448l-9.878-16.463c-0.057-0.094-0.103-0.194-0.137-0.298l-3.024-9.075h-2.278
     c-0.568,0-1.088-0.321-1.343-0.829l-3.362-6.718c-0.104-0.208-0.158-0.438-0.158-0.671v-6.112l-6.187-6.186l-9.893-6.588
     l-6.647-3.327c-0.508-0.254-0.827-0.773-0.827-1.341v-2.747l-8.589-8.584l-5.671,5.662c-0.163,0.164-0.364,0.288-0.586,0.362
     l-10.093,3.362c-0.152,0.051-0.313,0.077-0.474,0.077h-6.731h-1.859v5.237c0,0.398-0.158,0.78-0.44,1.062l-3.281,3.275
     l-16.459,23.048v2.883c0,0.367-0.135,0.722-0.379,0.997l-26.914,30.283l-40.437,40.429c-0.114,0.114-0.245,0.209-0.391,0.281
     l-6.136,3.07l-6.543,22.92c-0.069,0.245-0.202,0.469-0.383,0.649l-6.73,6.721c-0.112,0.113-0.243,0.209-0.389,0.28l-19.567,9.792
     l-3.198,12.785c-0.026,0.105-0.064,0.208-0.113,0.306L300,507.681v3.007c0,0.568-0.321,1.088-0.829,1.343l-6.34,3.17l-9.866,16.446
     c-0.146,0.244-0.359,0.442-0.615,0.57l-13.146,6.568l-9.877,13.17c-0.043,0.057-0.089,0.11-0.139,0.16l-6.407,6.412l-3.271,16.376
     l-3.335,13.338v15.138h8.349l9.858-3.284c0.036-0.013,0.073-0.022,0.11-0.032l13.454-3.358c0.119-0.03,0.241-0.045,0.363-0.045
     h6.109l2.932-2.924c0.113-0.113,0.244-0.207,0.389-0.279l6.724-3.363c0.208-0.104,0.438-0.158,0.671-0.158h16.363l9.715-6.483
     c0.054-0.034,0.106-0.065,0.163-0.095l13.104-6.547l6.506-9.766c0.277-0.417,0.746-0.668,1.248-0.668h2.834l33.234-26.575
     l0.057-0.04C378.357,539.756,378.359,539.752,378.363,539.749z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211012800"
          fill="#E1E1E1"
          d="M549.301,261.743c0-0.829,0.672-1.5,1.5-1.5h18.686v-1.862c0-0.829,0.672-1.5,1.5-1.5h10.092
     c0.748,0,1.367,0.547,1.48,1.263l0.825-0.824c0.281-0.281,0.662-0.438,1.061-0.438h5.229v-0.362h-1.869
     c-0.15,0-0.3-0.023-0.438-0.066c-0.421-0.129-0.771-0.44-0.946-0.86c-0.058-0.14-0.094-0.286-0.106-0.432
     c-0.028-0.292,0.029-0.586,0.168-0.846c0.068-0.129,0.156-0.25,0.265-0.357l0.826-0.826c-0.718-0.113-1.267-0.733-1.267-1.482
     v-13.453c0-0.398,0.158-0.779,0.439-1.062l3.369-3.369c0.016-0.016,0.031-0.031,0.048-0.046c0.003-0.001,0.003-0.001,0.003-0.002
     c0.001-0.001,0.002-0.002,0.003-0.002l0,0c0.003-0.002,0.006-0.004,0.008-0.006c0.007-0.006,0.015-0.013,0.021-0.019
     c0.001,0,0.004-0.001,0.004-0.003c0.002,0,0.002-0.002,0.004-0.003h0.001c0.009-0.008,0.021-0.011,0.03-0.018
     c0.104-0.083,0.22-0.153,0.344-0.208c0.021-0.009,0.037-0.02,0.058-0.028c0.143-0.055,0.291-0.083,0.437-0.093
     c0.013-0.001,0.024-0.007,0.037-0.008c0.021-0.001,0.041-0.001,0.063-0.001c0.001,0,0.002,0,0.003,0h0.013h9.459l2.924-2.923
     c0.281-0.281,0.663-0.439,1.061-0.439h2.739l2.93-2.929c0.281-0.281,0.663-0.439,1.062-0.439h1.86v-0.363h-1.86
     c-0.828,0-1.5-0.671-1.5-1.5V208.54l-2.929-2.92c-0.282-0.281-0.44-0.664-0.44-1.062v-3.362c0-0.829,0.672-1.5,1.5-1.5h1.869
     v-1.514l-3.211-6.414c-0.104-0.208-0.158-0.438-0.158-0.671v-3.008l-3.202-6.412c-0.104-0.208-0.157-0.438-0.157-0.67v-5.23h-1.863
     c-0.828,0-1.5-0.671-1.5-1.5v-6.102l-5.854-5.851h-2.737c-0.397,0-0.779-0.158-1.062-0.439l-6.558-6.558l-6.509-3.253
     c-0.509-0.254-0.829-0.773-0.829-1.342v-2.435l-5.587-2.795h-3.009c-0.828,0-1.5-0.671-1.5-1.5v-6.729
     c0-0.233,0.056-0.463,0.158-0.671l3.363-6.724c0.071-0.144,0.167-0.276,0.28-0.39l16.818-16.821
     c0.281-0.281,0.663-0.439,1.062-0.439h3.108l-0.36-0.362h-6.108c-0.828,0-1.5-0.671-1.5-1.5v-1.869h-1.866
     c-0.828,0-1.5-0.671-1.5-1.5v-1.868h-1.859c-0.397,0-0.779-0.158-1.062-0.439l-2.924-2.923h-2.747c-0.828,0-1.5-0.671-1.5-1.5
     v-2.739l-6.285-6.292c-0.28-0.281-0.438-0.662-0.438-1.06v-2.748l-2.489-2.484h-12.835c-0.828,0-1.5-0.671-1.5-1.5v-2.739
     l-2.484-2.483h-2.741c-0.828,0-1.5-0.671-1.5-1.5v-1.869h-1.86c-0.828,0-1.5-0.671-1.5-1.5v-5.229h-1.869
     c-0.828,0-1.5-0.671-1.5-1.5v-1.865h-1.86c-0.827,0-1.5-0.671-1.5-1.5v-9.468l-2.491-2.49h-6.104c-0.828,0-1.5-0.671-1.5-1.5
     v-1.862h-1.869c-0.232,0-0.463-0.054-0.671-0.159l-4.55-2.275v4.296c0,0.398-0.158,0.78-0.438,1.061l-3.371,3.369
     c-0.281,0.281-0.663,0.439-1.062,0.439h-0.001c-0.397,0-0.78-0.159-1.061-0.44l-2.922-2.928h-2.121l-2.482,2.489v5.488l2.921,2.921
     c0.281,0.281,0.439,0.663,0.439,1.061v3.363c0,0.398-0.158,0.779-0.439,1.061l-3.36,3.361c-0.28,0.281-0.662,0.439-1.061,0.439
     h-2.742l-2.49,2.49v2.747c0,0.829-0.672,1.5-1.5,1.5h-2.74l-6.29,6.283c-0.281,0.281-0.663,0.439-1.062,0.439h-1.863v5.232
     c0,0.829-0.672,1.5-1.5,1.5h-2.743l-5.847,5.852v6.101c0,0.829-0.672,1.5-1.5,1.5h-13.458c-0.396,0-0.778-0.158-1.061-0.439
     l-2.924-2.923h-5.487l-5.854,5.852v2.125l2.931,2.922c0.282,0.281,0.439,0.664,0.439,1.062v2.908l6.474,9.712
     c0.164,0.247,0.252,0.536,0.252,0.832v2.747l9.649,9.647c0.114,0.114,0.209,0.245,0.281,0.389l3.261,6.519l3.187,3.19
     c0.164,0.165,0.288,0.365,0.36,0.585l3.371,10.092c0.052,0.153,0.077,0.313,0.077,0.475v6.375l3.202,6.407
     c0.104,0.208,0.158,0.438,0.158,0.67v1.869h8.593h10.092c0.828,0,1.5,0.671,1.5,1.5v1.862h1.869c0.398,0,0.78,0.158,1.063,0.44
     l3.362,3.367c0.28,0.281,0.438,0.663,0.438,1.06v1.862h1.86c0.396,0,0.777,0.158,1.06,0.438l0.825,0.824
     c0.114-0.716,0.732-1.263,1.48-1.263h6.11l2.481-2.483v-2.746c0-0.398,0.157-0.779,0.438-1.061l10.093-10.093
     c0.281-0.281,0.663-0.439,1.062-0.439h3.359c0.828,0,1.5,0.671,1.5,1.5v6.108l2.932,2.922c0.282,0.281,0.439,0.664,0.439,1.062
     v9.737l3.202,6.414c0.104,0.208,0.158,0.438,0.158,0.67v13.453c0,0.829-0.672,1.5-1.5,1.5h-0.937l2.277,4.567
     c0.104,0.208,0.158,0.438,0.158,0.669v29.647l2.93,2.93c0.281,0.281,0.439,0.663,0.439,1.061v11.954h5.224h5.227v-1.864H549.301z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211012500"
          fill="#E1E1E1"
          d="M414.74,741.309v-1.858c0-0.828,0.672-1.5,1.5-1.5h1.862v-1.861c0-0.828,0.673-1.5,1.5-1.5h2.746
     l2.922-2.92c0.28-0.281,0.662-0.439,1.061-0.439h1.859v-1.881c0-0.606,0.365-1.153,0.927-1.387
     c0.108-0.044,0.221-0.076,0.333-0.094l-0.818-0.816c-0.283-0.281-0.44-0.664-0.44-1.063v-9.734l-3.201-6.416
     c-0.104-0.208-0.158-0.438-0.158-0.67v-10.08c0-0.396,0.156-0.776,0.437-1.058l3.358-3.381c0.282-0.283,0.665-0.442,1.064-0.442
     h2.749l2.48-2.48v-11.325h-1.859c-0.828,0-1.5-0.673-1.5-1.5v-16.469l-3.213-6.418c-0.104-0.208-0.157-0.438-0.157-0.671v-6.104
     l-2.921-2.92c-0.28-0.281-0.438-0.663-0.438-1.062v-6.74c0-0.827,0.672-1.5,1.5-1.5h2.739l2.49-2.482v-26.278
     c0-0.828,0.672-1.5,1.5-1.5h6.729c0.397,0,0.779,0.158,1.062,0.439l0.819,0.819c0.05-0.308,0.194-0.595,0.417-0.817l1.898-1.907
     l-4.867-2.433c-0.145-0.072-0.274-0.166-0.388-0.279l-3.199-3.188l-13.233-6.615c-0.145-0.072-0.275-0.167-0.391-0.281
     l-10.016-10.022l-13.375-10.034l-10.024-6.672c-0.243-0.162-0.433-0.392-0.548-0.658l-9.313-21.733l-32.105,25.674
     c-0.267,0.212-0.597,0.328-0.938,0.328h-2.558l-6.281,9.431c-0.145,0.217-0.344,0.393-0.578,0.51L322.795,586l-10.015,6.686
     c-0.247,0.163-0.537,0.252-0.833,0.252h-16.463l-6.19,3.097l-3.198,3.189c-0.28,0.28-0.661,0.438-1.06,0.438h-6.546l-13.219,3.301
     l-10.036,3.343c-0.152,0.052-0.313,0.077-0.475,0.077h-9.01l-3.028,9.074c-0.021,0.067-0.05,0.133-0.08,0.195l-6.675,13.365
     l-3.335,9.98c-0.021,0.066-0.05,0.132-0.081,0.194l-3.206,6.424v9.732c0,0.099-0.01,0.196-0.028,0.294l-3.009,15.027h10.131v-1.867
     c0-0.397,0.158-0.779,0.44-1.062l6.558-6.548l3.256-6.517c0.071-0.145,0.166-0.274,0.279-0.39l3.359-3.365
     c0.281-0.281,0.663-0.439,1.062-0.439h33.643c0.828,0,1.5,0.672,1.5,1.5v1.864h0.37v-1.864c0-0.828,0.672-1.5,1.5-1.5h10.091
     c0.828,0,1.5,0.672,1.5,1.5v1.864h8.59c0.733,0,1.358,0.53,1.479,1.255l3.36,20.182c0.014,0.081,0.021,0.163,0.021,0.246v10.102
     c0,0.232-0.056,0.463-0.158,0.671l-3.36,6.722c-0.146,0.29-0.381,0.525-0.672,0.671l-1.036,0.518h2.228v-1.858
     c0-0.828,0.672-1.5,1.5-1.5h3.37c0.827,0,1.5,0.672,1.5,1.5v1.858h1.238l5.853-5.843v-2.737c0-0.828,0.672-1.5,1.5-1.5h15.319
     v-8.602c0-0.521,0.271-1.003,0.713-1.276c0.443-0.273,0.996-0.298,1.461-0.063l6.725,3.375c0.507,0.255,0.827,0.772,0.827,1.341
     v1.859h5.229c0.828,0,1.5,0.672,1.5,1.5v1.866h1.87c0.828,0,1.5,0.672,1.5,1.5v1.859h8.591c0.828,0,1.5,0.672,1.5,1.5v1.861h15.321
     c0.397,0,0.779,0.158,1.062,0.438l3.358,3.359c0.456,0.456,0.569,1.151,0.282,1.729l-3.202,6.426v8.227h1.859
     c0.828,0,1.5,0.672,1.5,1.5v1.873h1.859c0.828,0,1.5,0.672,1.5,1.5v5.227h1.87c0.397,0,0.779,0.158,1.062,0.438l3.363,3.361
     c0.281,0.281,0.439,0.662,0.439,1.062v8.6h5.229c0.397,0,0.779,0.158,1.062,0.439l6.29,6.281h1.241V741.309z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211012600"
          fill="#E1E1E1"
          d="M426.951,734.229l-2.921,2.92c-0.281,0.28-0.663,0.438-1.062,0.438h-1.867v1.861c0,0.828-0.672,1.5-1.5,1.5
     h-1.862v1.858c0,0.828-0.672,1.5-1.5,1.5h-3.36c-0.396,0-0.777-0.156-1.059-0.438l-2.311-2.303l-1.861,1.86v6.101
     c0,0.605-0.364,1.151-0.925,1.385c-0.113,0.048-0.23,0.08-0.35,0.099l0.836,0.839c0.28,0.281,0.438,0.661,0.438,1.06v6.1
     l2.921,2.92c0.456,0.456,0.568,1.152,0.282,1.729l-3.203,6.425v6.367c0,0.605-0.365,1.153-0.926,1.386
     c-0.561,0.23-1.205,0.104-1.635-0.323l-2.604-2.6l-5.759,2.879c-0.467,0.231-1.018,0.206-1.46-0.064
     c-0.44-0.273-0.711-0.758-0.711-1.276v-1.861h-11.961c-0.396,0-0.779-0.158-1.061-0.438l-2.921-2.92h-9.47
     c-0.828,0-1.5-0.673-1.5-1.5v-1.873h-1.247l-6.282,6.293c-0.281,0.28-0.663,0.438-1.062,0.438h-1.87v8.588
     c0,0.397-0.157,0.778-0.438,1.061l-3.363,3.373c-0.281,0.282-0.664,0.439-1.063,0.439h-6.729c-0.397,0-0.781-0.158-1.063-0.44
     l-3.188-3.199l-6.189-3.092h-16.472c-0.397,0-0.78-0.158-1.062-0.44l-2.921-2.927h-1.248v1.867c0,0.396-0.158,0.779-0.439,1.061
     l-2.921,2.92v6.113c0,0.828-0.672,1.5-1.5,1.5h-10.09c-0.397,0-0.778-0.157-1.061-0.438l-2.928-2.924h-2.743
     c-0.397,0-0.78-0.158-1.063-0.44l-3.109-3.121l-9.532-3.17h-16.57c-0.233,0-0.463-0.055-0.672-0.158l-6.412-3.209h-3.014
     c-0.828,0-1.5-0.672-1.5-1.5v-1.861h-8.238l-5.767,2.885l-6.543,29.424v13.282c0,0.231-0.054,0.462-0.157,0.67l-3.209,6.424v8.227
     h8.246l19.865-9.922c0.422-0.211,0.918-0.211,1.34,0l6.413,3.201h3.007c0.397,0,0.779,0.158,1.062,0.438l3.359,3.361
     c0.281,0.281,0.439,0.663,0.439,1.062v2.188l11.502,2.882l2.76-2.77c0.281-0.282,0.663-0.441,1.063-0.441h0.001
     c0.397,0,0.778,0.158,1.062,0.439l2.931,2.934h9.47c0.828,0,1.5,0.672,1.5,1.5v1.867h1.239l2.927-2.928
     c0.28-0.281,0.663-0.439,1.061-0.439h6.725c0.566,0,1.087,0.32,1.341,0.828l3.062,6.112l12.606,3.146h5.925l2.921-2.92
     c0.281-0.28,0.663-0.438,1.062-0.438h3.012l6.408-3.203c0.208-0.104,0.438-0.158,0.671-0.158h13.46c0.231,0,0.462,0.054,0.67,0.158
     l6.414,3.203h3.01c0.568,0,1.088,0.32,1.342,0.829l2.945,5.89h9.171c0.233,0,0.464,0.055,0.672,0.159l6.402,3.208h3.016
     c0.398,0,0.78,0.158,1.063,0.439l6.722,6.733c0.281,0.281,0.439,0.663,0.439,1.061v1.859h1.248l2.922-2.92
     c0.585-0.585,1.533-0.587,2.119-0.002l2.932,2.922h19.561c0.397,0,0.779,0.158,1.062,0.438l2.921,2.923h2.749
     c0.828,0,1.5,0.672,1.5,1.5v2.739l1.861,1.873l2.3-2.312c0.281-0.283,0.664-0.442,1.063-0.442h5.229v-1.858
     c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5v9.479l2.48,2.482h2.749c0.828,0,1.5,0.672,1.5,1.5v1.858h7.968l0.359-0.358h-3.1
     c-0.397,0-0.778-0.157-1.061-0.438l-6.729-6.723c-0.583-0.585-0.585-1.531-0.003-2.117l2.921-2.942v-6.103
     c0-0.398,0.159-0.781,0.44-1.063l4.188-4.178c-0.714-0.115-1.259-0.734-1.259-1.479v-10.101c0-0.396,0.158-0.778,0.438-1.061
     l2.921-2.92v-29.042l-2.921-2.92c-0.28-0.28-0.438-0.662-0.438-1.061v-1.859h-1.871c-0.828,0-1.5-0.672-1.5-1.5v-1.866h-0.356
     v1.866c0,0.828-0.672,1.5-1.5,1.5h-3.37c-0.827,0-1.5-0.672-1.5-1.5v-1.866h-1.237l-2.924,2.927
     c-0.281,0.282-0.663,0.439-1.063,0.439h-3.366c-0.828,0-1.5-0.672-1.5-1.5v-3.366c0-0.397,0.157-0.778,0.438-1.061l2.928-2.934
     v-9.469c0-0.827,0.672-1.5,1.5-1.5h1.863v-18.682c0-0.746,0.545-1.364,1.258-1.479l-0.817-0.818
     c-0.281-0.281-0.439-0.663-0.439-1.061v-1.873h-1.863c-0.828,0-1.5-0.673-1.5-1.5v-1.867h-5.229c-0.828,0-1.5-0.672-1.5-1.5v-1.859
     h-1.861c-0.828,0-1.5-0.672-1.5-1.5v-5.221h-1.87c-0.828,0-1.5-0.672-1.5-1.5v-5.234h-5.229c-0.398,0-0.78-0.158-1.063-0.439
     l-3.358-3.367c-0.281-0.281-0.438-0.662-0.438-1.061v-3.358c0-0.828,0.672-1.5,1.5-1.5h1.859v-1.861c0-0.828,0.672-1.5,1.5-1.5
     h1.87v-0.359h-1.87L426.951,734.229L426.951,734.229z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211011300"
          fill="#E1E1E1"
          d="M521.317,448.043c-0.443,0.273-0.995,0.297-1.46,0.066l-4.56-2.279v2.805h1.86c0.828,0,1.5,0.671,1.5,1.5
     v10.087c0,0.397-0.157,0.778-0.438,1.061l-2.922,2.929v5.491l2.481,2.48h1.248v-1.859c0-0.829,0.672-1.5,1.5-1.5h20.185
     c0.232,0,0.462,0.054,0.67,0.158l6.728,3.359c0.056,0.028,0.108,0.06,0.161,0.093l9.718,6.47h15.043l3.188-25.499v-19.162
     l-5.587-2.794h-48.604v15.319C522.028,447.288,521.758,447.77,521.317,448.043z"
        />
      </g>
      <g className="land_link" onClick={goDepth} onMouseOver={depthMapOverEvt} onMouseOut={depthMapOutEvt}>
        <path
          fill="#E1E1E1"
          id="dong_4211010700"
          d="M576.632,485.094l-1.801-1.806v0.747c0,0.188-0.107,0.362-0.276,0.447l-6.582,3.292l-3.232,6.466v16.703
     c0,0.275-0.225,0.5-0.5,0.5h-2.869v2.865c0,0.276-0.225,0.5-0.5,0.5h-3.153l-3.223,3.219c-0.021,0.02-0.041,0.039-0.064,0.055
     c0,0,0,0-0.001,0.001c-0.042,0.03-0.089,0.054-0.138,0.069c-0.001,0-0.001,0-0.001,0s0,0-0.001,0
     c-0.049,0.016-0.084,0.034-0.149,0.022c-0.053,0-0.104-0.008-0.155-0.024c0,0,0,0-0.001,0c-0.042-0.015-0.082-0.033-0.12-0.059l0,0
     l-0.001-0.001l0,0l0,0c-0.029-0.02-0.057-0.041-0.079-0.064l-3.357-3.364c-0.094-0.094-0.146-0.221-0.146-0.354v-1.247
     l-2.363,4.731v3.25c0,0.202-0.122,0.385-0.309,0.462c-0.188,0.078-0.401,0.035-0.545-0.108l-2.791-2.796l-2.972,8.916l6.339,3.166
     c0.159,0.08,0.266,0.238,0.275,0.416c0.01,0.179-0.073,0.349-0.222,0.447l-8.713,5.811h1.709c0.275,0,0.5,0.225,0.5,0.5v2.87h6.02
     l6.577-6.587c0.094-0.094,0.221-0.146,0.354-0.146h2.871v-2.863c0-0.276,0.225-0.5,0.5-0.5h2.86v-2.86
     c0-0.133,0.053-0.26,0.146-0.354l3.369-3.367c0.095-0.094,0.222-0.146,0.354-0.146h2.86v-16.32c0-0.276,0.224-0.5,0.5-0.5h3.155
     l6.438-6.435v-3.163c0-0.276,0.224-0.5,0.5-0.5h2.859v-2.863c0-0.276,0.225-0.5,0.5-0.5h6.929l-11.188-8.387
     C576.738,485.192,576.682,485.145,576.632,485.094z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211010900"
          d="M536.828,558.045v-2.86h-6.229c-0.133,0-0.26-0.053-0.354-0.146l-2.964-2.965l-9.746,12.999
     c-0.005,0.006-0.014,0.008-0.02,0.016c-0.044,0.053-0.095,0.1-0.157,0.131l-6.102,3.049l2.87,2.871
     c0.094,0.095,0.146,0.222,0.146,0.354v3.166l3.214,3.217c0.094,0.094,0.146,0.221,0.146,0.354v2.157l2.369-2.364v-3.156
     c0-0.275,0.225-0.5,0.5-0.5h2.863v-2.873c0-0.275,0.224-0.5,0.5-0.5h6.23v-2.861c0-0.274,0.225-0.5,0.5-0.5h6.229v-2.858
     c0-0.275,0.224-0.5,0.5-0.5h6.224v-2.867v-2.859h-6.224C537.051,558.545,536.828,558.321,536.828,558.045z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211011000"
          d="M568.099,554.685c0,0.276-0.224,0.5-0.5,0.5c-0.275,0-0.5-0.224-0.5-0.5v-2.873h-2.859
     c-0.276,0-0.5-0.224-0.5-0.5v-2.863h-2.369v2.863c0,0.276-0.224,0.5-0.5,0.5h-9.592v2.873c0,0.276-0.225,0.5-0.5,0.5h-2.863v2.86
     c0,0.276-0.224,0.5-0.5,0.5h-2.863v2.859v3.159l3.217,3.214c0.095,0.094,0.146,0.221,0.146,0.354v2.861h2.863
     c0.275,0,0.5,0.225,0.5,0.5v3.373c0,0.133-0.054,0.26-0.146,0.354l-6.58,6.58v0.535l1.804-1.803
     c0.457-0.457,1.154-0.568,1.73-0.281l6.407,3.203h3.017c0.746,0,1.365,0.545,1.48,1.258l0.818-0.818
     c0.281-0.28,0.663-0.438,1.062-0.438h2.748l5.845-5.846v-6.115c0-0.828,0.672-1.5,1.5-1.5h6.11l1.859-1.861l-2.3-2.299
     c-0.586-0.584-0.586-1.533-0.001-2.119l3.36-3.367c0.158-0.16,0.353-0.274,0.562-0.35v-1.451h-2.86c-0.275,0-0.5-0.224-0.5-0.5
     v-2.86h-2.862c-0.276,0-0.5-0.224-0.5-0.5v-2.872h-2.868c-0.133,0-0.26-0.054-0.354-0.146l-2.51-2.51V554.685L568.099,554.685z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211012200"
          d="M581.554,497.485c0,0.275-0.224,0.5-0.5,0.5h-2.859v2.869c0,0.133-0.054,0.261-0.146,0.354l-5.876,5.874
     h2.159c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5h-2.868v2.86c0,0.275-0.224,0.5-0.5,0.5c-0.275,0-0.5-0.225-0.5-0.5v-2.86
     H568.1v16.32c0,0.275-0.224,0.5-0.5,0.5h-3.152l-3.076,3.073v2.653h2.869c0.275,0,0.5,0.225,0.5,0.5v13.25l3.214,3.213l3.217,3.219
     h3.161c0.276,0,0.5,0.224,0.5,0.5v2.872h2.863c0.275,0,0.5,0.225,0.5,0.5v2.86h2.859c0.276,0,0.5,0.224,0.5,0.5v1.859h4.728v-1.859
     c0-0.828,0.672-1.5,1.5-1.5h1.869v-1.86c0-0.828,0.672-1.5,1.5-1.5h6.102l2.49-2.492v-2.744c0-0.828,0.672-1.5,1.5-1.5h1.863
     v-5.221c0-0.828,0.672-1.5,1.5-1.5h4.301l-2.282-4.562c-0.231-0.466-0.208-1.018,0.065-1.461c0.272-0.441,0.757-0.711,1.276-0.711
     h1.868v-7.969l-2.929-2.92c-0.282-0.281-0.439-0.663-0.439-1.063v-6.114l-2.482-2.485h-2.741c-0.828,0-1.5-0.673-1.5-1.5v-12.377
     l-9.065-3.021c-0.152-0.051-0.297-0.126-0.426-0.223l-0.935-0.7h-7.762v2.864H581.554z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211010800"
          d="M537.681,544.234c0.105,0.104,0.159,0.254,0.146,0.401c-0.016,0.149-0.096,0.284-0.221,0.367l-9.594,6.386
     l2.793,2.795h6.522c0.276,0,0.5,0.225,0.5,0.5v2.86h6.224h2.863v-2.86c0-0.275,0.224-0.5,0.5-0.5h2.863v-2.873
     c0-0.275,0.224-0.5,0.5-0.5h9.592v-2.863c0-0.276,0.224-0.5,0.5-0.5h3.369c0.275,0,0.5,0.224,0.5,0.5v2.863h2.359v-2.655
     l-3.214-3.214c-0.094-0.095-0.146-0.222-0.146-0.354V531.63h-2.869h-2.86v2.863c0,0.275-0.224,0.5-0.5,0.5h-3.163l-6.577,6.586
     c-0.095,0.095-0.222,0.146-0.354,0.146h-6.727h-5.514L537.681,544.234z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211010500"
          d="M536.542,544.51l-2.937-2.928v-0.001l-0.002-0.001l-3.007-2.016l-2.87,1.869v3.153
     c0,0.133-0.053,0.26-0.146,0.354l-19.33,19.329h2.163h2.86v-2.866c0-0.275,0.224-0.5,0.5-0.5c0.275,0,0.5,0.225,0.5,0.5v2.866
     h2.609l9.942-13.26c0.034-0.045,0.076-0.085,0.123-0.116L536.542,544.51z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211011100"
          d="M543.698,581.238l6.58-6.58v-2.666h-2.863c-0.276,0-0.5-0.224-0.5-0.5v-3.154l-3.07-3.065h-6.017v2.858
     c0,0.277-0.225,0.5-0.5,0.5h-6.229v2.861c0,0.276-0.224,0.5-0.5,0.5h-6.23v2.873c0,0.276-0.224,0.5-0.5,0.5h-2.863v2.862
     c0,0.134-0.053,0.261-0.146,0.354l-3.368,3.363c-0.046,0.045-0.102,0.082-0.162,0.106c-0.001,0.002-0.001,0.002-0.002,0.002
     c-0.062,0.025-0.125,0.037-0.19,0.037h-9.592v2.861c0,0.276-0.224,0.5-0.5,0.5h-3.152l-3.073,3.065v3.17c0,0.277-0.225,0.5-0.5,0.5
     c-0.276,0-0.5-0.223-0.5-0.5v-2.166l-2.506,2.521c-0.095,0.094-0.223,0.146-0.354,0.146h-1.863v2.863
     c0,0.234-0.055,0.465-0.158,0.672l-3.211,6.404v1.506h5.232c0.828,0,1.5,0.672,1.5,1.5v1.879h5.228c0.827,0,1.5,0.672,1.5,1.5
     v1.861h1.237l6.292-6.299c0.07-0.07,0.147-0.136,0.23-0.189l10.093-6.721c0.246-0.164,0.535-0.252,0.831-0.252h2.435l3.635-7.271
     c-0.506-0.252-0.837-0.76-0.837-1.332v-3.358c0-0.828,0.672-1.5,1.5-1.5h3.359h1.869v-1.861c0-0.604,0.365-1.152,0.926-1.385
     c0.561-0.234,1.205-0.105,1.636,0.323l2.921,2.923h2.121l0.12-0.121v-1.74C543.551,581.459,543.604,581.332,543.698,581.238z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211011500"
          d="M496.956,544.088c0.276,0,0.5,0.224,0.5,0.5v2.859h2.86c0.275,0,0.5,0.224,0.5,0.5v2.864h2.365v-2.864
     c0-0.276,0.225-0.5,0.5-0.5h2.86v-2.859c0-0.276,0.224-0.5,0.5-0.5h2.871v-6.229c0-0.276,0.224-0.5,0.5-0.5h2.86v-2.863
     c0-0.174,0.09-0.334,0.236-0.426c0.146-0.093,0.331-0.101,0.486-0.021l6.729,3.363c0.169,0.084,0.276,0.258,0.276,0.447v2.869
     h2.363v-2.869c0-0.276,0.224-0.5,0.5-0.5h2.859v-4.363h-2.859c-0.276,0-0.5-0.225-0.5-0.5v-1.363v-2.859h-2.863
     c-0.276,0-0.5-0.225-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h2.863v-2.367h-2.863c-0.276,0-0.5-0.225-0.5-0.5v-6.729v-2.866H503.68
     c-0.078,0-0.154-0.019-0.224-0.053l-4.623-2.313h-0.38v1.246l2.922,2.927c0.281,0.281,0.438,0.662,0.438,1.06v3.368
     c0,0.829-0.672,1.5-1.5,1.5h-1.859v1.859c0,0.396-0.158,0.777-0.438,1.06l-6.294,6.291v2.74c0,0.828-0.672,1.5-1.5,1.5h-1.86v1.863
     c0,0.749-0.55,1.37-1.268,1.481l0.388,0.389h2.74c0.521,0,1.003,0.269,1.276,0.712c0.268,0.434,0.293,0.971,0.075,1.43l2.225,2.219
     h3.159V544.088z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211010600"
          d="M513.421,575.219c-0.094-0.094-0.146-0.221-0.146-0.354v-3.166l-3.215-3.215
     c-0.112-0.113-0.166-0.275-0.14-0.435c0.025-0.158,0.126-0.295,0.271-0.367l4.824-2.412h-4.395l-3.227,3.216
     c-0.094,0.092-0.221,0.145-0.353,0.145h-2.86v2.861c0,0.237-0.168,0.442-0.4,0.49l-16.725,3.354l-8.789,5.857v2.26h1.863
     c0.828,0,1.5,0.672,1.5,1.5v5.236h1.238l2.931-2.937c0.431-0.432,1.074-0.561,1.636-0.326c0.561,0.23,0.927,0.777,0.927,1.386
     v1.877h0.36v-5.236c0-0.605,0.365-1.152,0.926-1.385c0.557-0.232,1.204-0.107,1.635,0.321l3.369,3.359
     c0.282,0.281,0.439,0.664,0.439,1.063v2.877h1.655l3.213-3.229h0.001c0.001-0.002,0.001-0.002,0.002-0.002l0.001-0.002l3.364-3.357
     c0.094-0.094,0.221-0.146,0.354-0.146h2.86v-2.861c0-0.274,0.224-0.5,0.5-0.5h9.592v-2.655L513.421,575.219z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211010200"
          d="M524.367,524.403v3.227c0.013,0.045,0.02,0.092,0.02,0.141c0,0.05-0.007,0.097-0.02,0.142v2.72h2.359
     v-2.859c0-0.276,0.225-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v6.224h2.871c0.276,0,0.5,0.224,0.5,0.5v3.156l2.592,2.592
     l3.152-12.594c0.009-0.035,0.021-0.068,0.038-0.103l3.36-6.727l3.311-9.959v-2.97l-6.342-3.17h-3.044l-2.36,2.36h2.153
     c0.276,0,0.5,0.224,0.5,0.5v3.36c0,0.275-0.224,0.5-0.5,0.5h-2.86v2.865c0,0.276-0.224,0.5-0.5,0.5h-2.871v2.866
     c0,0.276-0.224,0.5-0.5,0.5c-0.275,0-0.5-0.224-0.5-0.5v-2.866h-2.359v2.866c0,0.276-0.225,0.5-0.5,0.5h-2.863v5.729h2.863
     C524.143,523.903,524.367,524.127,524.367,524.403z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211010100"
          d="M550.779,484.535h-6.521l-3.217,3.22c-0.094,0.094-0.221,0.146-0.354,0.146h-6.229v2.859
     c0,0.276-0.224,0.5-0.5,0.5h-2.86v2.86c0,0.275-0.224,0.5-0.5,0.5h-2.871v2.863c0,0.275-0.224,0.5-0.5,0.5h-2.154l2.363,2.369
     h6.522c0.276,0,0.5,0.225,0.5,0.5v2.867h2.869c0.077,0,0.154,0.019,0.224,0.054l6.725,3.359c0.169,0.085,0.275,0.258,0.275,0.447
     v2.859h6.228c0.016,0,0.027,0.009,0.044,0.01c0.022,0.002,0.047-0.001,0.07,0.005c0.018,0.004,0.029,0.014,0.046,0.019
     c0.021,0.007,0.042,0.016,0.063,0.026c0.01,0.004,0.02,0.007,0.028,0.012c0.024,0.017,0.046,0.033,0.066,0.054
     c0.019,0.014,0.034,0.026,0.051,0.045c0.016,0.018,0.028,0.034,0.043,0.056c0.004,0.005,0.01,0.007,0.014,0.013
     c0.005,0.009,0.004,0.021,0.009,0.027c0,0.001,0.001,0.002,0.001,0.003c0.01,0.019,0.021,0.033,0.029,0.052
     c0.017,0.043,0.023,0.089,0.028,0.136c0.001,0.012,0.005,0.021,0.005,0.032c0.001,0.004,0.003,0.009,0.003,0.015v3.158l2.36,2.364
     v-2.157c0-0.275,0.224-0.5,0.5-0.5c0.275,0,0.5,0.225,0.5,0.5v2.16l2.518-2.515c0.094-0.093,0.221-0.146,0.354-0.146h2.86v-2.865
     c0-0.276,0.224-0.5,0.5-0.5h2.869v-16.321c0-0.078,0.019-0.154,0.053-0.224l3.36-6.721c0.049-0.097,0.127-0.175,0.224-0.224
     l6.455-3.229v-1.44l-0.123-0.123H557.51c-0.296,0-0.585-0.087-0.831-0.251l-0.232-0.156l-5.443,2.729
     C550.932,484.517,550.857,484.535,550.779,484.535z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211011400"
          d="M540.335,472.081h-18.331v1.86c0,0.828-0.672,1.5-1.5,1.5h-3.369c-0.396,0-0.779-0.158-1.061-0.439
     l-2.067-2.068l-5.464,8.184v2.919c0,0.396-0.157,0.778-0.438,1.06l-2.923,2.929v9.463c0,0.749-0.549,1.37-1.266,1.481l0.827,0.828
     c0.28,0.281,0.438,0.663,0.438,1.061v10.088c0,0.828-0.672,1.5-1.5,1.5h-2.611l2.729,1.366h16.703c0.276,0,0.5,0.224,0.5,0.5v2.865
     h2.363v-2.865c0-0.276,0.224-0.5,0.5-0.5h6.23v-2.866c0-0.276,0.225-0.5,0.5-0.5h2.86v-2.36h-2.86
     c-0.202,0-0.385-0.122-0.462-0.309c-0.077-0.188-0.034-0.401,0.108-0.545l3.214-3.215v-2.659h-6.231
     c-0.133,0-0.261-0.054-0.354-0.147l-3.36-3.37c-0.143-0.143-0.185-0.357-0.106-0.545c0.077-0.187,0.26-0.308,0.462-0.308h2.859
     v-2.863c0-0.276,0.225-0.5,0.5-0.5h2.871v-2.86c0-0.275,0.225-0.5,0.5-0.5h2.86v-2.859c0-0.276,0.224-0.5,0.5-0.5h6.521
     l3.218-3.221c0.094-0.094,0.221-0.146,0.354-0.146h6.608l4.826-2.419l-8.825-5.875L540.335,472.081z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211011600"
          d="M496.602,554.332l3.215-3.229v-2.656h-2.86c-0.276,0-0.5-0.225-0.5-0.5v-2.859h-2.863
     c-0.133,0-0.26-0.053-0.354-0.146l-2.132-2.127l-2.745,5.486v3.01c0,0.397-0.158,0.778-0.438,1.061l-2.932,2.935v2.739
     c0,0.828-0.672,1.5-1.5,1.5h-5.225v5.227c0,0.828-0.672,1.5-1.5,1.5h-1.865v0.359h1.865c0.828,0,1.5,0.672,1.5,1.5v6.734
     c0,0.748-0.547,1.367-1.262,1.481l0.822,0.82c0.281,0.281,0.439,0.662,0.439,1.061v1.765l8.315-5.543
     c0.056-0.035,0.115-0.062,0.179-0.074l16.419-3.293v-2.832l-6.674-13.342C496.413,554.716,496.45,554.484,496.602,554.332z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211010400"
          d="M537.8,527.945l-3.2,12.781h5.59v-2.369h-2.86c-0.221,0-0.415-0.146-0.479-0.355
     c-0.063-0.21,0.019-0.438,0.201-0.561l9.37-6.248l-5.956-2.977c-0.223-0.11-0.329-0.369-0.251-0.604l3.363-10.096
     c0.055-0.164,0.191-0.289,0.36-0.328c0.165-0.04,0.346,0.011,0.468,0.134l2.51,2.514v-2.16c0-0.078,0.019-0.153,0.053-0.224
     l3.002-6.009h-5.558l-3.249,9.759L537.8,527.945z"
        />
        <path
          fill="#E1E1E1"
          id="dong_4211010300"
          d="M530.122,534.891h-2.718v3.468h-0.678l0,0h-2.359v2.869c0,0.276-0.225,0.5-0.5,0.5h-3.363
     c-0.275,0-0.5-0.224-0.5-0.5v-3.062l-5.729-2.863v2.556c0,0.275-0.225,0.5-0.5,0.5h-2.86v6.229c0,0.276-0.224,0.5-0.5,0.5h-2.871
     v2.859c0,0.275-0.224,0.5-0.5,0.5h-2.86v2.363h2.86c0.276,0,0.5,0.225,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5h-2.86v2.873
     c0,0.276-0.224,0.5-0.5,0.5c-0.275,0-0.5-0.224-0.5-0.5v-2.873h-2.657l-2.96,2.973l6.428,12.848h2.844l2.368-2.358h-2.161
     c-0.202,0-0.385-0.121-0.462-0.31s-0.034-0.402,0.108-0.545l20.038-20.037v-3.153v-0.214l3.395-2.448v-3.675H530.122z"
        />
      </g>
      <g>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 497.5647 138.6758)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          신동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 478.2688 285.2959)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          사농동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 565.5862 377.7607)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          우두동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 325.8069 517.2539)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          중도동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 433.0256 503.9648)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          근화동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 613.219 486.873)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          후평동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 521.0725 456.1758)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="12">
          소양로1가
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 573.8303 616.2588)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          효자동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 613.219 701.7969)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          석사동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 525.5706 729.3379)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          퇴계동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 439.9202 677.4004)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          온의동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 341.2639 629.3945)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          삼천동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 277.7483 734.2285)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          송암동
        </text>
        <text
          className="geoText"
          transform="matrix(1 0 0 1 347.8909 816.1982)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="16">
          칠전동
        </text>
      </g>
    </svg>
  );
};

export default GeoChuncheonTwo;
