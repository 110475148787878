// 결정분류 15001
import ModuleCrystalTimeChart from './module_crystal_time_chart';
import ModuleCrystalTypeChart from './module_crystal_type_chart';
import React, {useState, useEffect , useCallback} from 'react';
import {getModuleCrystalDataList} from '../../../../service/stats/type/ensoDataService';

const ModuleCrystal = ( {conn , search, setLoading} )=> {
    // 설치 유형 데이터 소스
    const [moduleCrystalDs , setModuleCrystalDs] = useState({
        moduleCrystal : [],
        moduleCrystalTime : []
    });

    // 데이터 소스 가져오기
  const getResult = useCallback((getData) => {
      setLoading(false)
        setModuleCrystalDs({
            moduleCrystal : getData.solaModuleCrystalDataDtoList,
            moduleCrystalTime : getData.solaModuleCrystalTimeDataDtoList
        });
        // eslint-disable-next-line
    } , [search]);

    // rejection 함수
    const getRejection = (error) => {
      setLoading(false)
    }

    useEffect(() => {
        getModuleCrystalDataList( conn  , getResult , getRejection , search );
        // eslint-disable-next-line
    },[getResult]);

    return(
        <div className="uk-grid">
            <div className="uk-width-medium-1-1">
                <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                        결정분류별 분석
                    </h3>                    
                </div>
            </div>
            <div className="uk-width-medium-1-2">
                <div className="md-card">
                    <div className="md-card-content">
                        <ModuleCrystalTypeChart moduleCrystalDs = {moduleCrystalDs.moduleCrystal} ></ModuleCrystalTypeChart>
                    </div>
                </div>
            </div>
            <div className="uk-width-medium-1-2">
                <div className="md-card">
                    <div className="md-card-content">
                        <ModuleCrystalTimeChart moduleCrystalDs = {moduleCrystalDs.moduleCrystalTime} ></ModuleCrystalTimeChart>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModuleCrystal;