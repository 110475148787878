import NonsanTitle from "../NonsanTitle";
import Card from "./Card";
import "./index.css";
import { ReactComponent as Sola } from "../svg/sola.svg";
import { ReactComponent as Heat } from "../svg/heat.svg";
import { ReactComponent as Geo } from "../svg/geo.svg";

export default function DevelopmentState({ solaDayPower, heatDayPower, geoDayPower }) {
  const stateList = [
    {
      title: "태양광",
      power: solaDayPower,
      svg: <Sola className="energy-icon" />,
    },
    {
      title: "태양열",
      power: heatDayPower,
      svg: <Heat className="energy-icon" />,
    },
    {
      title: "지열",
      power: geoDayPower,
      svg: <Geo className="energy-icon" />,
    },
  ];

  return (
    <div className="nonsan-develop-container">
      <NonsanTitle title="에너지원별 발전 현황" />
      <div className="nonsan-develop-list">
        {stateList.map(state => (
          <Card title={state.title} power={state.power} svg={state.svg} key={state.title} />
        ))}
      </div>
    </div>
  );
}
