import styles from "./nonOperHistGrid.module.css";

import { useState, useEffect, useRef } from "react";
import { BeatLoader } from "react-spinners";

import kendo from "@progress/kendo-ui";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Search } from "@material-ui/icons";

import { getNonOperHistList, getRgnNonOperHistList, getInvNonOperHistList } from "../../../service/stats/nonOperHist/nonOperHist";
import { isInstaller } from "../../../service/authService";
import { getMeainModlMakr } from "../../../service/meain/meain/meainService";

import NonOperHistGrid from "./nonOperHistGrid";
import OperationStatArea from "./nonOperationStat_area";
import OperationStatMeainModl from "./nonOperationStat_meainModl";
import { Button } from "@progress/kendo-react-buttons";

const NonOperHistSearch = ({ conn, codeList, cityList, rgnList }) => {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  const completeExcel = () => {
    setLoading(false);
  };
  // 에너지원 데이터 리스트
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15);

  const defaultValCity = { cityProvCode: "", cityProvTerm: "시/도" };
  const defaultValRgn = { rgnTermSimple: "전체", rgnCode: "" };
  // 통신모델 데이터 리스트
  const communicationMthdList = [
    {
      communicationMthd: "TCP/IP(OPC-UA)",
      cm: 1,
    },
    {
      communicationMthd: "LTE",
      cm: 2,
    },
    {
      communicationMthd: "WI-FI",
      cm: 3,
    },
    {
      communicationMthd: "LoRa(SKT)",
      cm: 4,
    },
    {
      communicationMthd: "NBIoT(KT)",
      cm: 5,
    },
    {
      communicationMthd: "NBIoT(LG)",
      cm: 6,
    },
    {
      communicationMthd: "HTTPS",
      cm: 7,
    },
    {
      communicationMthd: "DB",
      cm: 8,
    },
  ];
  // const defaultCommunicationModel = { communicationMthd: "전체", cm: "" };

  // 읍면동 / 설비모델 optionLabel
  const [optionLabel, setOptionLabel] = useState({
    meainModl: { meainTypeTermSimple: "설비 모델", meainTypeSeriNo: "" },
    makr : { makrTermSimple: "제조사", makrSeriNo: "" }
  });
  const [search, setSearch] = useState({
    date: "",
    ensoTypeCode: 15001, // 에너지원 코드
    enso: { codeNo: 15001, codeVal: "태양광" },
    cityProvCode: "",
    rgnCode: "", // 지역 코드
    cid: "", // CID
    rgn : { rgnCode : "", rgnTermSimple : "시군구" } , 
    city : {cityProvTerm : "시/도" , cityProvCode : ""},
    installerTerm: "", // 설비명
    communication: { communicationMthd: "전체", cm: "" },
    communicationMthd: "", // 통신 방식
    makrTerm: "", // 제조사
    makrSeriNo: "", // 제조사 번호
    meainTypeTerm: "", // 모델
    meainTypeSeriNo: "", // 모델 번호
  });

  // 지역 데이터 리스트
  const rgns = rgnList.filter((e) => e.cityProvCode === search.cityProvCode);

  const searchData = (e) => {
    if (e.target.name === "ensoTypeCodeNo") {
      search.cityProvCode = "";
      search.rgnCode = "";
      search.communicationMthd = "";
      search.meainTypeSeriNo = "";
      optionLabel.meainModl = { meainTypeTermSimple: "설비 모델", meainTypeSeriNo: "" };
      search.makrSeriNo = "";
      optionLabel.makr = { makrTermSimple: "제조사", makrSeriNo: "" };
      setSearch((state) => {
        const item = { ...state };
        item.city = {cityProvTerm : "시/도" , cityProvCode : ""};
        item.rgn = { rgnCode : "", rgnTermSimple : "시군구" };
        item.cid = "";
        item.installerTerm = "";
        item.ensoTypeCode = e.target.value.codeNo;
        item.enso = e.target.value;
        item.communication = { communicationMthd: "전체", cm: "" };
        return item;
      });    
    } else if (e.target.name === "city") {
      setSearch((search) => {
        const item = { ...search };
        item.cityProvCode = e.target.value.cityProvCode;
        item.city = e.target.value;
        item.rgnCode = "";
        item.rgn = { rgnCode : "", rgnTermSimple : "시군구" };  
        return item;
      });
    } else if (e.target.name === "rgn") {
      setSearch((state) => {
        const item = { ...state };
        item.rgn = e.target.value;
        item.rgnCode = e.target.value.rgnCode;
        return item;
      });
    } else if (e.target.name === "communicationMthd") {
      if (e.target.value.communicationMthd === "전체") {
        setSearch((state) => {
          const item = { ...state };
          item.communication = { communicationMthd: "전체", cm: "" };
          item.communicationMthd = "";
          return item;
        });
      } else {
        setSearch((state) => {
          const item = { ...state };
          item.communication = e.target.value;
          item.communicationMthd = e.target.value.communicationMthd;
          return item;
        });
      }
    } else if (e.target.name === "makrSeriNo") {
      search.meainTypeSeriNo = "";
      optionLabel.meainModl = { meainTypeTermSimple: "설비 모델", meainTypeSeriNo: "" };
      optionLabel.makr = e.target.value;
      setSearch((search) => {
        const item = { ...search };
        item.makrSeriNo = e.target.value.makrSeriNo;
        item.makrTerm = e.target.value.makrTerm;
        return item;
      });
    } else if (e.target.name === "meainTypeSeriNo") {
      search.meainTypeSeriNo = e.target.value.meainTypeSeriNo;
      setOptionLabel((optionLabel) => {
        const item = { ...optionLabel };
        item.meainModl = e.target.value;
        return item;
      });
    }
  };

  // CID 검색
  const cidChange = (e) => {
    setSearch((state) => {
      const item = { ...state };
      item.cid = e.target.value;
      return item;
    });
  };
  const cidKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearchClickEvt();
    }
  };

  // 설비명 검색
  const installerChange = (e) => {
    setSearch((state) => {
      const item = { ...state };
      item.installerTerm = e.target.value;
      return item;
    });
  };
  const installerKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearchClickEvt();
    }
  };

  // 페이징
  const [paging, setPaging] = useState({ take: 10, skip: 0 });
  // 정렬
  const [sort, setSort] = useState([{ field: "serverDiff", dir: "desc" }]);
  // 페이징 이벤트
  const onPageChange = (e) => {
    paging.skip = e.page.skip;
    paging.take = e.page.take;
    setNonOperHistList();
    setRgnNonOperHistList();
    setInvNonOperHistList();
    getMeainModlMakrList();
  };
  // 정렬 이벤트
  const onSortChange = (e) => {
    const { sort } = e;
    setSort(sort);
  };

  // 오늘날
  const today = new Date();
  // 설정한 날짜
  const day = useRef(new Date());

  const [dataSource, setDataSource] = useState({
    nonOperHistData: [],
    total: 0,
    rgnNonOperHistData: [],
    rgnTotal: 0,
    invNonOperHistData: [],
    invTotal: 0,
  });

  // 데이터 소스 설정 rejection 함수 (현재 불필요)
  const getRejection = (error) => {};
  // 설비 제조사 & 설비 모델
  const [makrMeainModelList, setMakrMeainModelList] = useState({
    makrList: [],
    meainModelList: [],
  });
  const getMeainModlMakrList = () => {
    getMeainModlMakr(conn, getMeainModleMakrResult, getRejection);
  };
  const getMeainModleMakrResult = (result) => {
    setMakrMeainModelList((makrMeainModelList) => {
      const item = { ...makrMeainModelList };
      console.log("item = ", item);
      item.makrList = result.makrDtoList;
      item.meainModelList = result.meainModelDtoList;
      return item;
    });
  };

  const setSearchClickEvt = (e) => {
    setPaging((paging) => {
      const item = { ...paging };
      item.take = 10;
      item.skip = 0;
      return item;
    });
    setNonOperHistList();
    setRgnNonOperHistList();
    setInvNonOperHistList();
    getMeainModlMakrList();
  };

  const setNonOperHistList = () => {
    setLoading(true);
    const data = {
      date: kendo.toString(day.current, "yyyyMMdd"),
      ensoTypeCode: search.ensoTypeCode,
      cityProvCode: search.cityProvCode,
      rgnCode: search.rgnCode,
      cid: search.cid,
      installerTerm: search.installerTerm,
      communicationMthd: search.communicationMthd,
      makrTerm: search.makrTerm,
      makrSeriNo: search.makrSeriNo,
      meainTypeTerm: search.meainTypeTerm,
      meainTypeSeriNo: search.meainTypeSeriNo,
    };
    setSearch((state) => {
      const item = { ...state };
      item.date = data.date;
      return item;
    });
    getNonOperHistList(conn, paging, data)
      .then((result) => {
        console.log("getNonOperHistList result = ", result);
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.nonOperHistData = result.results;
          item.total = result.total;
          return item;
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("getNonOperHistList error = ", error);
        setLoading(false);
        // alert(getMessage(error.errCode));
      });
  };

  const setRgnNonOperHistList = () => {
    const data = {
      date: kendo.toString(day.current, "yyyyMMdd"),
      ensoTypeCode: search.ensoTypeCode,
      cityProvCode: search.cityProvCode,
      rgnCode: search.rgnCode,
      cid: search.cid,
      installerTerm: search.installerTerm,
      communicationMthd: search.communicationMthd,
      makrTerm: search.makrTerm,
      makrSeriNo: search.makrSeriNo,
      meainTypeTerm: search.meainTypeTerm,
      meainTypeSeriNo: search.meainTypeSeriNo,
    };

    setSearch((state) => {
      const item = { ...state };
      item.date = data.date;
      return item;
    });
    getRgnNonOperHistList(conn, data)
      .then((result) => {
        console.log("getRgnNonOperHistList result = ", result);
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.rgnNonOperHistData = result;
          return item;
        });
      })
      .catch((error) => {
        console.error("getRgnNonOperHistList error = ", error);
        // alert(getMessage(error.errCode));
      });
  };

  const setInvNonOperHistList = () => {
    const data = {
      date: kendo.toString(day.current, "yyyyMMdd"),
      ensoTypeCode: search.ensoTypeCode,
      cityProvCode: search.cityProvCode,
      rgnCode: search.rgnCode,
      cid: search.cid,
      installerTerm: search.installerTerm,
      communicationMthd: search.communicationMthd,
      makrTerm: search.makrTerm,
      makrSeriNo: search.makrSeriNo,
      meainTypeTerm: search.meainTypeTerm,
      meainTypeSeriNo: search.meainTypeSeriNo,
    };

    setSearch((state) => {
      const item = { ...state };
      item.date = data.date;
      return item;
    });

    getInvNonOperHistList(conn, data)
      .then((result) => {
        console.log("getInvNonOperHistList result = ", result);
        setDataSource((dataSource) => {
          const item = { ...dataSource };
          item.invNonOperHistData = result;
          return item;
        });
      })
      .catch((error) => {
        console.error("getInvNonOperHistList error = ", error);
        // alert(getMessage(error.errCode));
      });
  };

  // 제조사 데이터 리스트
  let makrList = makrMeainModelList.makrList;

  // 에너지원별 설비타입기준 제조사 코드
  if (search.ensoTypeCode === 15001) {
    makrList = makrMeainModelList.makrList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30002);
  } else if (search.ensoTypeCode === 15002) {
    makrList = makrMeainModelList.makrList.filter(
      (e) => e.ensoTypeCodeNo === search.ensoTypeCode && (e.makrClsfNo === 30003 || e.makrClsfNo === 30013)
    );
  } else if (search.ensoTypeCode === 15003) {
    makrList = makrMeainModelList.makrList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30005);
  } else if (search.ensoTypeCode === 15004) {
    makrList = makrMeainModelList.makrList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30007);
  } else if (search.ensoTypeCode === 15006) {
    makrList = makrMeainModelList.makrList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrClsfNo === 30010);
  } else {
    makrList = [];
  }

  useEffect(() => {
    getMeainModlMakrList();
  // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={loading ? "nonOperHist" : ""}>
        <div className="md-card TBgA">
          <div className="md-card-content small-padding">
            <span className="searchSubTitleA">에너지원</span>
            <DropDownList
              data={ensoList.filter((e) => e.codeNo !== 15007)}
              // defaultItem={{ codeNo : 15001 , codeVal : "태양광" }}
              name="ensoTypeCodeNo"
              textField="codeVal"
              dataItemKey="codeNo"
              onChange={searchData}
              className="searchArea"
              value={search.enso}
              // disabled = { isInstaller() === true ? true : false }
            ></DropDownList>
            <span className="searchSubTitleB">지역검색</span>
            <DropDownList
              data={cityList}
              textField="cityProvTerm"
              dataItemKey="cityProvCode"
              disabled={isInstaller() === true ? true : false}
              name="city"
              defaultItem={defaultValCity}
              onChange={searchData}
              value={search.city}
            />
            &nbsp;&nbsp;
            <DropDownList
              data={rgns}
              textField="rgnTermSimple"
              name="rgn"
              dataItemKey="rgnCode"
              defaultItem={defaultValRgn}
              onChange={searchData}
              disabled={search.cityProvCode === ""}
              // disabled={isInstaller() === true ? true : false}
              value = {search.rgn}
            />
            &nbsp;&nbsp;
            <input type="text" className="searchTerm" placeholder="CID" onChange={cidChange} onKeyPress={cidKeyPress} value={search.cid}></input>
            &nbsp;
            <input type="text" className="searchTerm" placeholder="설비명" onChange={installerChange} onKeyPress={installerKeyPress} value={search.installerTerm}></input>
            <span className="searchSubTitleB">통신방식</span>
            <DropDownList
              data={communicationMthdList}
              textField="communicationMthd"
              name="communicationMthd"
              dataItemKey="cm"
              defaultItem={{ communicationMthd: "전체", cm: "" }}
              onChange={searchData}
              value={search.communication}
            ></DropDownList>
            <span className="searchSubTitleB">조회일시</span>
            <DatePickerKo
              id="start"
              name="start"
              className="datePicker"
              format={"yyyy-MM-dd"}
              defaultValue={today}
              setDtm={(v) => {
                day.current = v;
                setSearch((state) => {
                  const item = { ...state };
                  item.date = v;
                  return item;
                });
              }}
              max={today}
            ></DatePickerKo>
            <span className="searchSubTitleC">
              <Button className="searchBtn" onClick={setSearchClickEvt}>
                <Search className="material-icons" />
              </Button>
              {/* <button className="ch-btnA" onClick={setSearchClickEvt} >
              <Search className="material-icons"  />
            </button> */}
            </span>
          </div>
          <div className={["md-card-content", styles.searchPadding].join(" ")}>
            <span className="searchSubTitleA">설비 제조사 / 모델</span>
            <DropDownList
              data={makrList}
              value={optionLabel.makr}
              defaultItem={{ makrTermSimple: "제조사", makrSeriNo: "" }}
              name="makrSeriNo"
              textField="makrTermSimple"
              dataItemKey="makrSeriNo"
              onChange={searchData}
              className="searchArea"
            ></DropDownList>
            <DropDownList
              disabled={!search.makrSeriNo}
              value={optionLabel.meainModl}
              data={makrMeainModelList.meainModelList.filter((e) => e.ensoTypeCodeNo === search.ensoTypeCode && e.makrSeriNo === search.makrSeriNo)}
              defaultItem={{ meainTypeTermSimple: "설비 모델", meainTypeSeriNo: "" }}
              name="meainTypeSeriNo"
              textField="meainTypeTermSimple"
              dataItemKey="meainTypeSeriNo"
              onChange={searchData}
              className="searchArea"
            ></DropDownList>
          </div>
        </div>
        <br></br>
        <div>
          <div className="uk-grid">
            <div className="uk-width-medium-1-1">
              <div className="md-card">
                <NonOperHistGrid
                  conn={conn}
                  styles={styles}
                  dataSource={dataSource}
                  setDataSource={setDataSource}
                  paging={paging}
                  sort={sort}
                  onPageChange={onPageChange}
                  onSortChange={onSortChange}
                  search={search}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
          <div className="uk-grid">
            <div className="uk-width-medium-1-2">
              <div className="md-card">
                <OperationStatArea
                  styles={styles}
                  dataSource={dataSource.rgnNonOperHistData}
                  loading={loading}
                  setLoading={setLoading}
                  BeatLoader={BeatLoader}
                  completeExcel={completeExcel}
                />
              </div>
            </div>
            <div className="uk-width-medium-1-2">
              <div className="md-card">
                <OperationStatMeainModl
                  styles={styles}
                  dataSource={dataSource.invNonOperHistData}
                  loading={loading}
                  setLoading={setLoading}
                  BeatLoader={BeatLoader}
                  completeExcel={completeExcel}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <p className="nonOperHistLoading">
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
};
export default NonOperHistSearch;
