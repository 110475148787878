// React
import React from "react";
import { useState, useEffect, useRef } from "react";

// Kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";

// Css
import "./daedeokMonitor.css";
import { geoComponents } from "./geoDir/geoDatas";
import { isAdmin } from "../../../service/authService";
import { Button } from "@progress/kendo-react-buttons";
import GeoGradient from "./daedeokGeoGradient";
import DaedeokMonitorMapTable from "./daedeokMonitorMapTable";
import { getLtByDongCode } from "../../../service/location/locationMunicService";
import DaedeokScaleComp from "./daedeokScaleComp";

const DaedeokMonitorMap = props => {
  const { conn, dataSource, search, setSearch, history , styles , getData, isMapView } = props;
  const [rgnDdlData, /*setRgnDdlData*/] = useState([
    {
      rgnCode: "44130",
      rgnTerm: "천안시",
    },
    {
      rgnCode: "44200",
      rgnTerm: "아산시",
    },
    {
      rgnCode: "43150",
      rgnTerm: "제천시",
    },
    {
      rgnCode: "44210",
      rgnTerm: "서산시",
    },
    {
      rgnCode: "44825",
      rgnTerm: "태안시",
    },
    {
      rgnCode: "42230",
      rgnTerm: "삼척시",
    },
    {
      rgnCode: "42770",
      rgnTerm: "정선군",
    }
    ,
    {
      rgnCode: "42810",
      rgnTerm: "인제군",
    },
    {
      rgnCode: "42110",
      rgnTerm: "춘천시",
    },
    {
      rgnCode: "30230",
      rgnTerm: "대덕구",
    },
  ]);
  // 마우스 오버 시 보여주는 테이블(infoBo)
  const infoBoxRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [hoverDongData, setHoverDongData] = useState({});

  // 동 데이터 리스트
  const dongDataList = dataSource.dongDataList;

  /* 읍면동 마우스Over */
  const mapOverEvt = e => {
    let hoverDongCode = e.target.id.substring(5);

    dongDataList.some(e => {
      if(e.dongCode === hoverDongCode){
        setHoverDongData(e);
        return true;
      }
    })
    
    infoBoxRef.current.style.display = "block";
    infoBoxRef.current.style.left = e.nativeEvent.offsetX + 15 + "px";
    if(e.nativeEvent.offsetY > 600) {
      infoBoxRef.current.style.top = e.nativeEvent.offsetY - 150 + "px";
    } else {
      infoBoxRef.current.style.top = e.nativeEvent.offsetY + 50 + "px";
    }
  };

  /* 읍면동 마우스Out */
  const mapOutEvt = e => {
    infoBoxRef.current.style.display = "none";
  };

  /* 읍면동 클릭 */
  const mapClickEvt = e => {
    const { target } = e;
    const dongCode = target.id.substring(5);
    if(isMapView) mapMoveEvt(dongCode);
    
    if(dongCode === search.dongCode){
      search.dongCode = ""
      // search.fixbylawBundCode = ""
    } else {
      search.dongCode = dongCode
      // search.fixbylawBundCode = dongCode
    }

    getData();
  };

  // 맵 클릭 시 이동 이벤트
  const mapMoveEvt = (dongCode) => {
    getLtByDongCode(conn, dongCode).then(response => {
      history.push({
        pathname: "/location/munic",
        state: { lati: response.lati, longi: response.longi, rgnCode: search.rgn.rgnCode },
      });
    });
  }


  /**
   * 동적 컴포넌트(맵) 조회 함수
   * @param {*} rgnCode
   * @returns 동적 컴포넌트
   */
  const getGeo = (rgnCode, depth) => {
    let Geo;
    if (depth) Geo = geoComponents[rgnCode + "_" + depth];
    else Geo = geoComponents[rgnCode];
    return (
      <Geo
        mapOverEvt={mapOverEvt}
        mapOutEvt={mapOutEvt}
        mapClickEvt={mapClickEvt}
      />
    );
  };

  const mapDdlEvt = e => {
    const { value } = e;
    history.replace = { state: value };
    setSearch({ ...search, rgn: value, geoDepth: 0 });
    window.location.href = '/monitor/munic';
  };

  // 헤더 변경 데이터
  const chngHeader = () => {
    const rgn = rgnDdlData.find(e => e.rgnCode === search.rgn.rgnCode);
    const headerLogo = document.getElementById("headerLogo");
    
    if(headerLogo) {
      if (rgn) {
        headerLogo.src = "/images/localareaImg/30230/title/" + rgn.rgnCode + "_logo_img.png";
        headerLogo.style.marginRight = '20px';
        // document.getElementById("headerTerm").style.display = 'block';
        document.getElementById("headerTerm").innerHTML = rgn.rgnTerm + " 신재생에너지 관리센터";
        document.getElementById("headerTerm").style.fontSize = "32px";
        document.getElementById("headerTerm").style.letterSpacing = "10px";
        document.getElementById("subHeaderTerm").style.fontSize = "12px";
        document.getElementById("subHeaderTerm").style.letterSpacing = "6px";
      
      }
    }
  };

  /**
   * 맵의 색상 설정하기 ( 현재 설비 설치 갯수 기준으로 진행 )
   */
  const drawMap = () => {
    const getColorByPer = e => {
      if (e < 20) return "url(#scale0025)";
      else if (e >= 20 && e < 40) return "url(#scale2650)";
      else if (e >= 40 && e < 80) return "url(#scale5175)";
      else if (e >= 80 && e <= 100) return "url(#scale76100)";
      return "url(#scale0025)";
    };
    let colorArr = [];
    dongDataList.forEach(e => {
      const dongCode = e.dongCode;
      const rateByCnt = e.rateByCnt;
      const dongMap = document.getElementById("dong_" + dongCode);    
      if (!dongMap) return;
      const color = getColorByPer(rateByCnt);
      dongMap.style.fill = color;
      if(dongCode == search.dongCode){dongMap.style.fill = "url(#overGra)";}
      colorArr.push({ fixbylawBundCode: dongCode, color: color });
    });

  }

  useEffect(() => {
    drawMap();
    chngHeader();
  }, [dongDataList]);

  return (
    <>
      <GeoGradient />
      <div
        className="con_center"
        style={
          loading
            ? {
                opacity: 0.6,
                pointerEvents: "none",
                paddingTop: "0px"
              }
            : {paddingTop: "0px"}
        }>

        {search.geoDepth > 0 ? (
          <Button className="geoDepthBtn" onClick={() => setSearch({ ...search, geoDepth: search.geoDepth > 0 ? search.geoDepth - 1 : 0 })}>
            지도 축소
          </Button>
        ) : null}

        {isAdmin() ? (
          <DropDownList
            className="municMapDdl"
            value={search.rgn}
            data={rgnDdlData}
            textField="rgnTerm"
            dataItemKey="rgnCode"
            onChange={mapDdlEvt}
          />
        ) : null}

        <div className={styles.mapBox}>

          <DaedeokMonitorMapTable
            infoBoxRef={infoBoxRef}
            styles={styles}
            hoverDongData={hoverDongData}
          />
          
          <DaedeokScaleComp styles={styles}/>

          {getGeo(search.rgn.rgnCode, search.geoDepth)}

        </div>
      </div>
    </>
  );
};

export default DaedeokMonitorMap;
