import { numFormat, roundNum } from "../../../com/dataUtil";

const OperationStatToday = ({ state, styles }) => {
    console.log("state = ", state);
    return (
        <div className="uk-width-medium-1-3">
            <div className="md-card">
                <div className="md-card-content , operationPage" >
                    <div className="md-card-toolbar">
                        <h3 className="md-card-toolbar-heading-text">
                            금일발전현황
                        </h3>
                        <div className="md-card-toolbar-actions">
                            {/* <span className="titleTbarWimg"> */}
                            {/* <img src="/images/weather/weather_icon01.png" alt="구름"/>
                            </span>
                            23&#730;C&nbsp;&nbsp;&#124;&nbsp;&nbsp;맑음 */}
                        </div>
                    </div>
                    <div>
                        <table className="Ch-TableE">
                            <tr>
                                <th>
                                    <div>금일발전량</div><br />
                                    <b>{numFormat(roundNum(state.totalEnergy.dayPower, 1))}</b>
                                    <span>kWh</span>
                                </th>
                                <td>
                                    <img src="/images/fourimg_A.png" alt="" />
                                </td>
                                <th className={styles.TBgA}>
                                    <div>CO<sub>2</sub>저감량</div><br />
                                    <b>{numFormat(roundNum(state.totalEnergy.dayCo2 / 1000, 1))}</b>
                                    <span>t</span>
                                </th>
                                <td className={styles.TBgA}>
                                    <img src="/images/fourimg_B.png" alt="" />
                                </td>
                            </tr>
                            <tr>
                                <th className={styles.TBgA}>
                                    <div>금일사용량<small>(태양열)</small></div><br />
                                    <b>{numFormat(roundNum(state.totalEnergy.heatDayUse, 1))}</b>
                                    <span>kWh</span>
                                </th>
                                <td className={styles.TBgA}>
                                    <img src="/images/fourimg_C.png" alt="" />
                                </td>
                                <th>
                                    <div>금일사용량<small>(지열)</small></div><br />
                                    <b>{numFormat(roundNum(state.totalEnergy.heathDayUse, 1))}</b>
                                    <span>kWh</span>
                                </th>
                                <td>
                                    <img src="/images/fourimg_D.png" alt="" />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OperationStatToday;