import "./index.css";

export default function ConvertedAmount({ recPrice, smpPrice, totalPrice }) {
  return (
    <div className="today-nonsan-container">
      <div className="divide" />
      <div className="nonsan-card">
        <h3 className="nonsan-detail-title">오늘 시세</h3>
        <div className="nonsan-amount">
          <div>SMP</div>
          <div>
            {smpPrice.toLocaleString()} <span className="won">원</span>
          </div>
        </div>
        <div className="nonsan-amount">
          <div>REC</div>
          <div>
            {recPrice.toLocaleString()} <span className="won">원</span>
          </div>
        </div>
        <div className="nonsan-amount-card">
          <h3 className="nonsan-detail-title">오늘 수익</h3>
          <div>
            {Number(totalPrice.toFixed()).toLocaleString()} <span className="amount-won">원</span>
          </div>
        </div>
      </div>
    </div>
  );
}
