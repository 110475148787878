import kendo from "@progress/kendo-ui";

const avgHourEfic = [
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  2.0,
  18.0,
  34.0,
  52.0,
  62.0,
  64.0,
  56.0,
  38.0,
  22.0,
  6.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
];

const calEff = resultObj => {
  let todayExpeEffi = 0;
  let preExpeEffi = 0;
  let prePower = 0;
  // 현재 시간을 조회하여 현재시간까지의 Efic, Power값을 구한다.
  let time = new Date().getHours();
  if (resultObj.todayEficData) {
    for (let i = 0; i <= time; i++) {
      let idx;
      if (i < 10) idx = "0" + i;
      else idx = i + "";
      todayExpeEffi += resultObj.todayEficData["e" + idx];
      preExpeEffi += resultObj.preEficData["e" + idx];
      prePower += resultObj.prePowerData["p" + idx];
    }
  } else {
    for (let i = 0; i <= time; i++) {
      todayExpeEffi += avgHourEfic[i];
      preExpeEffi += avgHourEfic[i];
    }
  }
  return {
    todayExpeEffi: todayExpeEffi / 100,
    preExpeEffi: preExpeEffi / 100,
    prePower: prePower,
  };
};

export const getProfitDataResource = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  if (data.fixbylawBundCode) {
    param.fixbylawBundCode = data.fixbylawBundCode;
  }
  await conn
    .get("monitor/installer/munic/getData/profit", {
      params: param,
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getSmpRecCerDataResource = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    // start: 
    rgnCode: data.rgnCode,
  };
  if (data.fixbylawBundCode) {
    param.fixbylawBundCode = data.fixbylawBundCode;
  }
  await conn
    .get("monitor/installer/munic/getData/smpreccer", {
      params: param,
    })
    .then(response => {
      //SMP 데이터 가공
      let item = {};
      if (response) {
        item.landSmp = response.municBillDataDto.smpDataDto.landSmpToday;
        item.jejuSmp = response.municBillDataDto.smpDataDto.jejuSmpToday;
        item.landSmpYesterday = response.municBillDataDto.smpDataDto.landSmpPre;
        item.jejuSmpYesterday = response.municBillDataDto.smpDataDto.jejuSmpPre;
        item.rec = response.municBillDataDto.recDataDto.recAvgToday;
        item.recYesterday = response.municBillDataDto.recDataDto.recAvgPre;
        item.cer = response.municBillDataDto.cerDataDto.cerAvgToday;
        item.cerYesterday = response.municBillDataDto.cerDataDto.cerAvgPre;
      }
      callback(item);
    })
    .catch(error => {
      reject(error);
    });
};
export const getEficPowerDataResource = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  if (data.fixbylawBundCode) {
    param.fixbylawBundCode = data.fixbylawBundCode;
  }
  await conn
    .get("monitor/installer/munic/getData/eficpower", {
      params: param,
    })
    .then(response => {
      let item = {};
      if (response) {
        item.todayPowerData = response.municBillDataDto.todayPowerData;
        item.prePowerData = response.municBillDataDto.prePowerData;
        item.effi = calEff(response.municBillDataDto);
      }
      callback(item);
    })
    .catch(error => {
      reject(error);
    });
};
export const getSolaDongDataResource = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  if (data.fixbylawBundCode) {
    param.fixbylawBundCode = data.fixbylawBundCode;
  }
  await conn
    .get("monitor/installer/munic/getData/soladong", {
      params: param,
    })
    .then(response => {
      let item = {};
      if (response) {
        item.realSolaDongData = response.solaDongData;
      }
      callback(item);
    })
    .catch(error => {
      reject(error);
    });
};
export const getMeainCntDataResource = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  if (data.fixbylawBundCode) {
    param.fixbylawBundCode = data.fixbylawBundCode;
  }
  await conn
    .get("monitor/installer/munic/getData/meaincnt", {
      params: param,
    })
    .then(response => {
      let item = {};
      if (response) {
        item.ensoMeainCntList = response.ensoMeainCntList;
      }
      callback(item);
    })
    .catch(error => {
      reject(error);
    });
};
export const getUserPowerDataResource = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  await conn
    .get("monitor/installer/munic/getData/userpower", {
      params: param,
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};
export const getAllDataResource = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  await conn
    .get("monitor/installer/munic/getData", {
      params: param,
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

// 지역별 태양광 데이터 (콜백)
export const getTodayDongEnergy = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  await conn
    .get("monitor/installer/munic/dong/energy", {
      params: param,
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

// 지역별 태양광 데이터 (리턴)
export const getYesterdayDongEnergy = async (conn, data) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  const result = await conn.get("monitor/installer/munic/dong/energy", {
    params: param,
  });

  return result;
};

// 수용가별 태양광 데이터 (콜백)
export const getTodayUserEnergy = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  await conn
    .get("monitor/installer/munic/user/energy", {
      params: param,
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

// 수용가별 태양광 데이터 (리턴)
export const getYesterdayUserEnergy = async (conn, data) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  const result = await conn.get("monitor/installer/munic/user/energy", {
    params: param,
  });

  return result;
};

export const getSmpDayData = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  await conn
    .get("monitor/installer/gath/smp/day", {
      params: param,
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getSmpMonthData = async (conn, callback, reject) => {
  await conn
    .get("monitor/installer/gath/smp/month", {
      params: {
        start: kendo.toString(new Date(), "yyyy-MM-01"),
        end: kendo.toString(new Date(), "yyyy-MM-dd"),
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getRecDayData = async (conn, num, callback, reject) => {
  await conn
    .get("monitor/installer/munic/rec/day", {
      params: {
        start: kendo.toString(new Date(), "yyyy-MM-01"),
        end: kendo.toString(new Date(), "yyyy-MM-dd"),
        num: num,
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getRecMonthData = async (conn, callback, reject) => {
  await conn
    .get("monitor/installer/munic/rec/month", {
      params: {
        month: kendo.toString(new Date(), "yyyyMM"),
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getSolaData = async (conn, search, callback, reject) => {
  await conn
    .get("monitor/installer/munic/sola/data", {
      params: {
        fixbylawBundCode: search.dongTypeCode,
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getSolaStatData = async (conn, search, callback, reject) => {
  await conn
    .get("monitor/installer/munic/sola/data", {
      params: {},
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getHeatData = async (conn, search, callback, reject) => {
  await conn
    .get("monitor/installer/munic/heat/data", {
      params: {},
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getHeathData = async (conn, search, callback, reject) => {
  await conn
    .get("monitor/installer/munic/heath/data", {
      params: {},
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getFuelcellData = async (conn, search, callback, reject) => {
  await conn
    .get("monitor/installer/munic/fuelcell/data", {
      params: {},
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const getRealSolaDongData = async (conn, callback, reject) => {
  await conn
    .get("monitor/installer/munic/real/dong/sola", {
      params: {},
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

// 통합으로 데이터 불러오기
// export const getMunicData = async (conn, date, callback, reject) => {

//   await conn.get('monitor/installer/munic/getData', {
//       params: {
//         start : kendo.toString( new Date() , 'yyyy-MM-dd' )
//       }
//   })
//   .then((response) => {
//     callback(response);
//   })
//   .catch((error) => {
//     reject(error);
//   });
// };

/**
 * 새로운 지자체 모니터링 V2 버전
 * 에너지원별 연동된 설비에 한에서 발전량, 사용량, 설비갯수, 설비용량 총합 가져오는 부분
 */
export const getEnergySyncDataList = async (conn, parameter, callback, reject) => {
  let param = {
    today: kendo.toString(parameter.date, "yyyy-MM-dd"),
    // start: 
    rgnCode: parameter.rgnCode,
    dongCode: parameter.dongCode
  };
  await conn
    .get("monitor/installer/munic/real/energy/totalData", {
      params: param,
    })
    .then(response => {
      let item = {};
      if (response) {
        item.energySyncData = response.energySyncData;
      }
      callback(item);
    })
    .catch(error => {
      reject(error);
    });
};

/**
 * 각 읍면동 동데이터 ( 태양광 , 태양열 , 지열 )
 * 태양열 , 지열은 미연동 사용량을 구할 수 없어서 0으로 처리 해야함
 * jhchoi : 20230608
 * @param {*} conn 
 * @param {*} data 
 * @param {*} callback 
 * @param {*} reject 
 */
export const getEnergyDongDataList = async (conn, data, callback, reject) => {
  let param = {
    start: kendo.toString(data.date, "yyyy-MM-dd"),
    rgnCode: data.rgnCode,
  };
  if (data.fixbylawBundCode) {
    param.fixbylawBundCode = data.fixbylawBundCode;
  }
  await conn
    .get("monitor/installer/munic/getData/energydong", {
      params: param,
    })
    .then(response => {
      let item = {};
      if (response) {
        item.energyDongData = response.energyDongData;
      }
      callback(item);
    })
    .catch(error => {
      reject(error);
    });
};


/**
 * 새로운 지자체 모니터링 V2 버전
 * 발전량 추이 그래프
 */
export const getWeekPowerDataList = async (conn, parameter, callback, reject) => {
  // console.log("parameter = ", parameter);
  let param = {
    rgnCode: parameter.rgnCode,
    dongCode: parameter.dongCode
  };
  await conn
    .get("monitor/installer/munic/real/energy/weekPower", {
      params: param,
    })
    .then(response => {
      let item = {};
      if (response) {
        item.dayPowerList = response;
      }
      callback(item);
    })
    .catch(error => {
      reject(error);
    });
};