import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { roundNum } from '../../../../com/dataUtil';

// 제조사 / 설비모델 그래프
const UserBetweenUserMakerChart = ({userMakrList, search , getMeainModelChartData}) => {

  const chartList = userMakrList
    .filter((item, idx) => idx < 10) 
    .map(item => ({ 
    name : search.makrSeriNo ?  item.meainTypeTerm : item.makrTerm , 
    y : roundNum( item.instCapaPerEnergy , 2 ) , 
    makrSeriNo : item.makrSeriNo ? item.makrSeriNo : null , 
    meainTypeSeriNo : item.meainTypeSeriNo ? item.meainTypeSeriNo : null , 
    color : "#69bcc8" } )
 );

 const category = [];
 chartList.forEach(element => {
  category.push(element.name);
 });

  // 에너지원 코드 번호
  const ensoTypeCodeNo = search.ensoTypeCode; 
  
  // 제조사  / 모델 차트 옵션
  const chartConfig = {
    chart: { zoomType: 'xy', height: 400 },
    noData: { style:{ color: '#FFFFFF', fontSize: '14px' } },
    title: { text: null },
    legend: { enabled: false },
    tooltip: {
        shared: true,
        positioner: function (labelWidth, labelHeight, point) {
          // 툴팁 위치 조정 
          const tooltipX = point.plotX;
          const tooltipY = 150;
          return {
              x: tooltipX,
              y: tooltipY
          };
        },
        formatter: function () {
          return(
            `
              <b>${this.points[0].point.name}</b><br/>
              <span style="color:${this.points[0].point.color}">\u25CF</span>
              ${this.points[0].series.name} : ${Math.round(this.points[0].y*100)/100} kWh/${ensoTypeCodeNo===15002?'㎡':'kW'}.day
            `
          );
        }
    },
    xAxis: { 
      type: 'category',
      crosshair: true,
      categories : category,
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    yAxis: [{ // Primary yAxis
        labels: {
            format: '{value:,.2f}',
            style: {
                color: "#69bcc8"
            }
        },
        title: {
            text: `용량대비생산량(kWh/${ensoTypeCodeNo === 15002?'㎡':'kW'}.day)`,
            style: {
                color: "#69bcc8"
            }
        }
    }],
    credits: { enabled: false },
    plotOptions: {
        series: { colorByPoint: true },
        column : {
            cursor: 'pointer',
            point: {
                events: {
                    click: function(e){
                      // 제조사 코드 확인 
                      search.makrSeriNo = e.point.makrSeriNo;
                      getMeainModelChartData(search);
                    }
                }
            }
        }
    },
    series: [{
        name: '용량대비생산량',
        type: 'column',
        color: '#69bcc8',
        data: chartList,
        dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}'
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} kWh/'+(search.ensoTypeCodeNo===15002?'㎡':'kW')+'.day</b><br/>'
        }
    }]
  }

  return(
    <div style = {{height : "400px"}}>
        <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}/>
    </div>
  );
}

export default UserBetweenUserMakerChart;