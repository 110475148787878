// import kendo from "@progress/kendo-ui";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import DatePickerKo from "../../../com/dateInputs/datePickerKo";
import styles from "./cmpUserByUser.module.css";
// import styles from "./cmpUserByUser.dk.module.css";
import { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { getLastMonthDay } from "../../../../com/dateUtil";

// 검색
const CmpUserByUserSearch = ({ search, setSearch, codeList, getMeainListData, kendo, isMobile }) => {
  const [selected, setSelected] = useState(0);
  const handleSelect = e => {
    setSelected(e.selected);
  };
  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 검색 객체의 라디오 상태 값 (검색 시간별 상태)
  const selectedValue = search.dateType;

  // 날짜 조회 드롭다운
  // const dateList = codeList.filter(e => e.grpCodeNo === 23);

  // 라디오 상태 useState
  const [radioStatus, setRadioStatus] = useState("HH");

  // 라디오 이벤트
  const radioChange = e => {
    console.log("e = ", e);
    search.dateType = e.value;
    if( e.value === "HH" ){
      search.titleName = '시간별';
    } else if( e.value === "DD" ){
      search.titleName = '일별';
    } else if( e.value === "MM" ){
      search.titleName = '월별';
    } else{
      search.titleName = '시간별';
    }


    setRadioStatus(e.value);
  };
  // console.log("radioStatus", radioStatus);

  // 드롭다운 이벤트
  // const onchangeEvt = e => {
  //   // 날짜 검색
  //   let start = yesterday;
  //   if (e.target.value.codeNo === 23002) {
  //     start = yesterday;
  //   } else if (e.target.value.codeNo === 23003) {
  //     start = lastWeek;
  //   } else if (e.target.value.codeNo === 23004) {
  //     start = lastMonth;
  //   } else if (e.target.value.codeNo === 23005) {
  //     start = lastYear;
  //   } else if (e.target.value.codeNo === 23006) {
  //     start = yesterday;
  //   }
  //   // 검색 상태 설정
  //   setSearch(search => {
  //     const item = { ...search };
  //     item.dateStatus = e.target.value;
  //     item.startGathDtm = start;
  //     return item;
  //   });
  // };

  // 유저 ID 검색
  const searchUserId = e => {
    console.log("확인");
    setSearch(search => {
      const item = { ...search };
      item.userInfoVisible = true;
      return item;
    });
  };

  // 조회 이벤트
  const onClickSearchEvt = e => {
    if (search.userId === "") {
      kendo.alert("수용가가 선택되지 않았습니다. 수용가를 선택 해주세요.");
      return;
    }
    getMeainListData();
  };

  return (
    <>
      {isMobile ? (
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div>
              {/* 모바일 버전 css */}
              <div className="md-card-content small-padding MobileUserByUserDiv">
                <TabStrip selected={selected} onSelect={handleSelect}>
                  <TabStripTab title="수용가선택">
                    <div className="userByUserDivPadding">
                      <span className="searchSubTitleA">수용가</span>
                      <Input
                        className="userByUserInput"
                        type="text"
                        placeholder="설치자"
                        readOnly
                        value={search.userTerm ? search.userTerm : ""}
                        onClick={searchUserId}
                      />
                      <Button className={"userByUserSearchBtn"} onClick={searchUserId}>
                        <img className={styles.searchImg} alt ="search" src={"/images/icon_d_w_v2.png"} />
                      </Button>
                    </div>
                    <div className="mobileFullBtnDiv">
                  <Button className={styles.mobileSearchBtn} onClick={onClickSearchEvt} icon="search">
                    조회
                  </Button>
                </div>
                  </TabStripTab>
                  <TabStripTab title="기간선택">
                    <div>
                      <table className="userByUserTable">
                        <tr>
                          <td>
                            <RadioButton value="HH" label="" className="radioBtn" checked={radioStatus === "HH"} onChange={radioChange} />
                            <label>시간별</label>
                          </td>
                          <td>
                            <RadioButton value="DD" label="" className="radioBtn" checked={radioStatus === "DD"} onChange={radioChange} />
                            <label>일별</label>
                          </td>
                          <td>
                            <RadioButton value="MM" label="" className="radioBtn" checked={radioStatus === "MM"} onChange={radioChange} />
                            <label>월별</label>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="userByUserDivPadding">
                      <DatePickerKo
                        id="startGathDtm"
                        name="startGathDtm"
                        className="userByUserdatePicker"
                        defaultValue={search.yesterday}
                        format={search.dateType === "MM" ? "yyyy-MM" : "yy-MM-dd"}
                        view={search.dateType === "MM" ? "year" : "month"}
                        setDtm={v => {
                          setSearch(search => {
                            const item = { ...search };
                            item.endMin = v;
                            item.startGathDtm = v;
                            item.endGathDtm = v;
                            // 시작 날짜 선택 시 마지막 날짜 MAX 값 을 해당 월의 마지막 일로 설정
                            item.endMax = ( getLastMonthDay(v) );
                            return item;
                          });
                        }}
                        value={search.startGathDtm}
                        readonly={true}
                        max={search.startMax}
                      />
                      {selectedValue !== "HH" && selectedValue !== "MM" && <span className="textColorGr datePeriodIcon ">~</span>}
                      {selectedValue !== "HH" && selectedValue !== "MM" && (
                        <DatePickerKo
                          id="endGathDtm"
                          name="endGathDtm"
                          className="userByUserdatePicker"
                          defaultValue={search.yesterday}
                          format={search.dateType === "MM" ? "yyyy-MM" : "yy-MM-dd"}
                          view={search.dateType === "MM" ? "year" : "month"}
                          setDtm={v => {
                            setSearch(search => {
                              const item = { ...search };
                              item.endGathDtm = v
                              return item;
                            });
                          }}
                          min={search.endMin}
                          max={search.yesterday}
                          readonly={true}
                          value = {search.endGathDtm}
                        />
                      )}
                    </div>
                    <div className="mobileFullBtnDiv">
                  <Button className={styles.mobileSearchBtn} onClick={onClickSearchEvt} icon="search">
                    조회
                  </Button>
                </div>
                  </TabStripTab>
                </TabStrip>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="uk-grid">
          <div className="uk-width-medium-1-1">
            <div className="md-card TBgA">
              {/* PC버전 css */}
              <div className="md-card-content small-padding pcUserByUserDiv">
                <span className="searchSubTitleA">수용가</span>
                <span className={styles.userInfoArea}>
                  <div className={styles.SetSearch}>
                    <Input
                      id="userTerm"
                      type="text"
                      className={styles.SetSearchTerm}
                      placeholder="설치자"
                      readOnly
                      value={search.userTerm ? search.userTerm : ""}
                      onClick={searchUserId}
                    />
                  </div>
                  <div className={styles.SetSearch}>
                    <Button icon="search" onClick={searchUserId}></Button>
                  </div>
                </span>
                <span className='radioForm'>
                  <RadioButton value="HH" label="" className="radioBtn" checked={radioStatus === "HH"} onChange={radioChange} />
                  <label>
                    <span></span>시간별
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <RadioButton value="DD" label="" className="radioBtn" checked={radioStatus === "DD"} onChange={radioChange} />
                  <label>
                    <span></span>일별
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <RadioButton value="MM" label="" className="radioBtn" checked={radioStatus === "MM"} onChange={radioChange} />
                  <label>
                    <span></span>월별
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span className="searchSubTitleA">조회기간</span>
                <DatePickerKo
                  id="startGathDtm"
                  name="startGathDtm"
                  className="datePicker"
                  defaultValue={search.yesterday}
                  format={search.dateType === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
                  setDtm={v => {
                    setSearch(search => {
                      const item = { ...search };
                      item.endMin = v;
                      item.startGathDtm = v;
                      item.endGathDtm = v;
                      // 시작 날짜 선택 시 마지막 날짜 MAX 값 을 해당 월의 마지막 일로 설정
                      item.endMax = ( getLastMonthDay(v) );
                      return item;
                    });
                  }}
                  value={search.startGathDtm}
                  readonly={true}
                  max={search.startMax}
                  view= {selectedValue !== "MM" ? null : "year" }
                />
                {selectedValue !== "HH" && selectedValue !== "MM" && <span className="textColorGr datePeriodIcon ">~</span>}
                {selectedValue !== "HH" && selectedValue !== "MM" && (
                  <DatePickerKo 
                    id="endGathDtm"
                    name="endGathDtm"
                    className="datePicker"
                    defaultValue={search.yesterday}
                    format={search.dateType === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
                    setDtm={v => {
                      setSearch(search => {
                        const item = { ...search };
                        item.endGathDtm = v;
                        return item;
                      });
                    }}
                    min={search.endMin}
                    max={search.endMax}
                    readonly={true}
                    value = {search.endGathDtm}
                  />
                )}
                <Button icon="search" className="inqBtn" onClick={onClickSearchEvt}></Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CmpUserByUserSearch;
