import React from "react";

const BizPlanGrid = ({
  grid,
  codeList,
  search,
  handlePageChange,
  handleSortChange,
  handleFilterChange,
  expandChange,
  convPlanListNoCell,
  dateFormatCell,
  bizInfoPaging,
  useState,
  IntlProvider,
  LocalizationProvider,
  Grid,
  GridColumn,
  GridNoRecords,
  GridColumnInputFilter,
  CustomFilter,
  addressFormatFixFuc,
  customFilterObj,
}) => {
  /* 도시재생뉴딜 필터 */
  const ynDropDownList = CustomFilter(["Y", "N"], "도시재생뉴딜", "none", "none", false);

  // 필터 드롭다운
  const [filterDropdown, setFilterDropdown] = useState({
    classFaciVal: { codeNo: "", codeVal: "시설구분" },
  });

  /* 시설구분 필터 */
  const classFaciList = codeList.filter(c => c.grpCodeNo === 41);
  const classFaciDropDownList = props => {
    return customFilterObj(
      props,
      "classFaci",
      "classFaci",
      "codeNo",
      "codeVal",
      classFaciList,
      { codeNo: "", codeVal: "시설구분" },
      filterDropdown,
      setFilterDropdown,
      false,
    );
  };

  // 상세 주소 포맷 설정
  const getAddressFormatDataCell = data => {
    return addressFormatFixFuc(data, search);
  };

  return (
    <>
      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="ko">
          <Grid
            className="convListG"
            // {loading ? styles.iotKtLogG : styles.iotktGrid}
            data={grid.dataList}
            skip={bizInfoPaging.skip}
            take={bizInfoPaging.take}
            total={grid.total}
            sort={search.sortStatus}
            pageable={{ buttonCount: 5, pageSizes: true }}
            sortable
            filterable
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            onFilterChange={handleFilterChange}
            onRowClick={expandChange}
            style={{ height: "580px" }}>
            <GridColumn title="순번" field="no" cell={convPlanListNoCell} width={100} filterable={false} sortable={false} />
            <GridColumn title="설치계획서 신청번호" field="instlPlnnm" width={170} filterable={false} />
            {/* <GridColumn title="사업연도" field="bizYy" width={80}/> */}
            {/* <GridColumn title="시도" field="rgnTerm" width={170}/> */}
            <GridColumn title="신청자(건물명)" field="instlTerm" width={200} filterable={false} sortable={false} />
            <GridColumn title="시설구분" field="classFaciVal" width={150} sortable={false} filterCell={classFaciDropDownList} />
            <GridColumn
              title="설치장소"
              field="instlAddr"
              sortable={false}
              cell={getAddressFormatDataCell}
              filterCell={GridColumnInputFilter}
            />
            <GridColumn title="도시재생뉴딜" field="cityNewdeal" width={150} sortable={false} filterCell={ynDropDownList} />
            <GridColumn
              title="에너지원(용량)"
              field="convInfoListList"
              cell={dateFormatCell}
              width={230}
              filterable={false}
              sortable={false}
            />
            <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
};

export default BizPlanGrid;
