import log from '../../../com/log';
import {parseDate} from '../../../com/dateUtil';
import kendo from "@progress/kendo-ui";
import { getMessage } from '../../../com/messageUtil';

const iotKtUrl = 'iot/kt';

// iotMakers 그리드 데이터 조회
export const getIotKt = async(conn, state) => {
  console.log("state = ", state);
    const {take, skip, sort, filter} = state;
    const config = {
      params: {
        page: (take + skip) / take
        , size: take
        , sort
      }
    };

    if(filter !== undefined) {
      if(filter.nbDevid !== undefined)     config.params.nbDevid = filter.nbDevid;
      if(filter.rtuEnteCode !== undefined) config.params.rtuEnteCode = filter.rtuEnteCode;
      if(filter.stateCode !== undefined)   config.params.stateCode = filter.stateCode
    }

    const result = await conn.get(iotKtUrl + '/page', config);

    result.results.forEach(item => {
      if(item.stateCode === 200)
        item.stateTerm = '등록';
      else 
        item.stateTerm = '실패';
    })

    log(result, 'getIotKt');
    return result;
};

// iotMakers 정보입력
export const setIotKt = async(conn, data) => {
    console.log("data = ", data);
    // 헤더 옵션 변경
    const config = {
      headers: {'Content-Type': 'application/json'}
    };
    
    const result = await conn.post(iotKtUrl,  JSON.stringify(data) , config);
    return result;
};

// iotMakers Log 그리드 데이터 조회
export const getIotKtLog = async(conn, state, data) => {
  try {
    const {take, skip, sort} = state;
    const config = {
      params: {
        page: (take + skip) / take
        , size: take
        , sort
      }
    };

    if(data !== '전체') config.params.regiId = data;
    else delete config.params.regiId;
  
    const result = await conn.get(iotKtUrl + '/log/page', config);
    for(let item of result.results) {
      item.regiDtm = parseDate('yyyy-mm-dd hh:mm:ss', item.regiDtm);
    }

    log(result, 'getIotKtLog');
    return result;
  } catch(e) {
    kendo.alert(getMessage(e.errCode));
  }
};

// iotMakers Log UserId 조회
export const getLogUserIdList = async(conn) => {
  const result = await conn.get(iotKtUrl + '/log/list/userId');
  log(result, 'getLogUserIdList');
  return result;
}