import DaedeokMonitor from "../../../monitor/municipality_daedeok/daedeokMonitor";
import DaeDeokHeader from "./daedeokHeader";
import DaedeokIntroPage from "./daedeokIntroPage";

import { Route, Switch } from "react-router-dom/cjs/react-router-dom";

const DaedeokMainPage = props => {
  const {conn} = props;  

  return(    
    <div id="app">
      <Switch>
        <Route exact path="/" render={()=><DaedeokIntroPage />} />
        <Route exact path="/Main">
          <DaeDeokHeader conn={conn} />
          <DaedeokMonitor conn={conn} isMapView={false} />
        </Route>
      </Switch>
    </div>
  );
}

export default DaedeokMainPage;