import "./index.css";
import { numFormat, roundNum } from "com/dataUtil";
import { ReactComponent as SolaIcon } from "../../../../../assets/svg/taean/sola.svg";
import { ReactComponent as VeloIcon } from "../../../../../assets/svg/taean/velo.svg";
import { ReactComponent as HeatIcon } from "../../../../../assets/svg/taean/heat.svg";
import { ReactComponent as GeoIcon } from "../../../../../assets/svg/taean/geo.svg";

const PowerGenerationTaean = ({ data }) => {
  const formatEnergy = value => {
    if (value >= 1000) {
      return {
        value: numFormat(roundNum(value / 1000, 1)),
        unit: "MWh",
      };
    }
    return {
      value: numFormat(roundNum(value, 1)),
      unit: "kWh",
    };
  };

  const formatCo2 = value => {
    if (value >= 1000) {
      return {
        value: numFormat(roundNum(value / 1000, 1)),
        unit: "tCO₂",
      };
    }
    return {
      value: numFormat(roundNum(value, 1)),
      unit: "CO₂",
    };
  };

  return (
    <div className="taean-flex-container-width">
      <span className="taean-dashboard-title">에너지원별 발전현황</span>
      <div className="powerGeneration-container-taean">
        <div className="powerGeneration-wrapper-taean">
          <SolaIcon className="powerGeneration-taean-icon" />
          <span className="powerGeneration-taean-text-1">태양광</span>
          <div>
            <span className="powerGeneration-taean-text-2"> {formatEnergy(data.solaDayPower).value}</span>
            <span className="powerGeneration-taean-text-3"> {formatEnergy(data.solaDayPower).unit}</span>
          </div>
          <span className="powerGeneration-taean-text-4">
            온실가스 감소량 {formatCo2(data.solaCo2).value} {formatCo2(data.solaCo2).unit}
          </span>
        </div>
        <div className="powerGeneration-wrapper-taean">
          <VeloIcon className="powerGeneration-taean-icon" />
          <span className="powerGeneration-taean-text-1">풍력</span>
          <div>
            <span className="powerGeneration-taean-text-2">{formatEnergy(data.veloDayPower).value}</span>
            <span className="powerGeneration-taean-text-3"> {formatEnergy(data.veloDayPower).unit}</span>
          </div>
          <span className="powerGeneration-taean-text-4">
            온실가스 감소량 {formatCo2(data.veloCo2).value} {formatCo2(data.veloCo2).unit}
          </span>
        </div>
        <div className="powerGeneration-wrapper-taean">
          <HeatIcon className="powerGeneration-taean-icon" />
          <span className="powerGeneration-taean-text-1">태양열</span>
          <div>
            <span className="powerGeneration-taean-text-2">{formatEnergy(data.heatDayPower).value}</span>
            <span className="powerGeneration-taean-text-3"> {formatEnergy(data.heatDayPower).unit}</span>
          </div>
          <span className="powerGeneration-taean-text-4">
            온실가스 감소량 {formatCo2(data.heatCo2).value} {formatCo2(data.heatCo2).unit}
          </span>
        </div>

        <div className="powerGeneration-wrapper-taean">
          <GeoIcon className="powerGeneration-taean-icon" />
          <span className="powerGeneration-taean-text-1">지열</span>
          <div>
            <span className="powerGeneration-taean-text-2">{formatEnergy(data.geoDayPower).value}</span>
            <span className="powerGeneration-taean-text-3"> {formatEnergy(data.geoDayPower).unit}</span>
          </div>
          <span className="powerGeneration-taean-text-4">
            온실가스 감소량 {formatCo2(data.geoCo2).value} {formatCo2(data.geoCo2).unit}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PowerGenerationTaean;
