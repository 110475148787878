// 화면
import { useState } from "react";
import { BeatLoader } from "react-spinners";
import BizManagementContentGrid from "./bizManagementContentGrid";
import BizManagementContents from "./bizManagementContents";

// CSS
import bizMngtStyles from "./bizManagement.module.css";

// Kendo
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import SearchCom from "../searchCom";
import { getCalCityList, getCalRgnList, roundNum, numFormat } from "../../../../com/dataUtil";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DatePickerKo from "../../../com/dateInputs/datePickerKo";
import DateTimePickerKo from "../../../com/dateInputs/dateTimePickerKo";
import { useEffect } from "react";
import { getMeainModlMakr } from "../../../../service/meain/meain/meainService";
import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";

import "./bizManagement.css";
import BizMngtMeainSaveResult from "./meain/bizMngtMeainSaveResult";
import { makeNavigator } from "../commonFuc";
import {
  getMngtMeainAsList,
  getMngtMeainHistFileList,
  getMngtMeainInfo,
  getMngtMeainList,
  saveMeainAsInfo,
  saveMngtMeainInfo,
} from "../../../../service/bizStat/com/managementApiService";

/**
 * 융복합지원사업 - 관리현황 메인
 */
const BizManagement = ({ search, setSearch, conn, codeList, cityList, rgnList, styles }) => {  
  // 시도 , 시군구 리스트 가공
  let citys = getCalCityList(cityList);
  let rgns = getCalRgnList(rgnList.filter(e => e.cityProvCode === search.cityProvCode && e.rgnCode !== "44131" && e.rgnCode !== "44133"));

  // 로딩스피너
  const [loading, setLoading] = useState(false);

  // 데이터 리스트
  const [dataList, setDataList] = useState({
    meainList: {
      list: [],
      total: 0,
    },
    meainInfo: {}, // 저장 시 설비 정보
    excelMeainInfo: [], // 저장 시 설비 정보 엑셀
    saveMeainInfoList: [], // 설비 저장 시 보여줄 정보
    asHistList: [], // AS 보수 이력
    mngtFileList: [], // 관리 이력
    initialDs: {
      makrList: [],
      meainModelList: [],
      instTypeSola: [],
      instTypeHeat: [],
      instTypeHeath: [],
    },
  });

  // 제조사 , 검색 필터 변수
  const [searchKeyWord, setSearchKeyWord] = useState({
    // 태양광
    moduleMakrKey: "",
    moduleModlKey: "",
    invtMakrKey: "",
    invtModlKey: "",
    // 태양열 공통
    socoMakrKey: "",
    socoTypeKey: "",
    // 태양열 강제
    coldCtrlerMakrKey: "",
    coldCtrlerModlKey: "",
    loadClrmtMakrKey: "",
    loadClrmtModlKey: "",
    // 태양열 자연
    wtrHtrMakrKey: "",
    wtrHtrModlKey: "",
    clrmtMakrKey: "",
    clrmtModlKey: "",
    // 지열
    heatPumpMakrKey: "",
    heatPumpModlKey: "",
    hwClrmtModlKey: "",
    hcClrmtModlKey: "",
    clrmtMakrHwKey: "",
    clrmtMakrHcKey: "",
    elctrMeterMakrKey: "",
    elctrMeterModlKey: "",
    bntnMakrtKey: "",
    bntnTypeKey: "",
  });

  // 검색 값에 대한 제조사 , 모델 필터 Change
  const makrMeainModlfilterChange = e => {
    const { filter } = e;
    const target = e.target.props.id;
    setSearchKeyWord(searchKeyWord => {
      const item = { ...searchKeyWord };
      // 태양광
      if (target === "modulMakrNo") {
        item.moduleMakrKey = filter.value;
      } else if (target === "modulTypeNo") {
        item.moduleModlKey = filter.value;
      } else if (target === "invtMakrNo") {
        item.invtMakrKey = filter.value;
      } else if (target === "invtTypeNo") {
        item.invtModlKey = filter.value;
      }
      // 태양열
      else if (target === "socoMakrNo") {
        item.socoMakrKey = filter.value;
      } else if (target === "socoTypeNo") {
        item.socoTypeKey = filter.value;
      } else if (target === "coldCtrlerMakrNo") {
        item.coldCtrlerMakrKey = filter.value;
      } else if (target === "coldCtrlerModlNo") {
        item.coldCtrlerModlKey = filter.value;
      } else if (target === "loadClrmtMakrNo") {
        item.loadClrmtMakrKey = filter.value;
      } else if (target === "loadClrmtModlNo") {
        item.loadClrmtModlKey = filter.value;
      } else if (target === "wtrHtrMakrNo") {
        item.wtrHtrMakrKey = filter.value;
      } else if (target === "wtrHtrModlNo") {
        item.wtrHtrModlKey = filter.value;
      } else if (target === "clrmtMakrNo") {
        item.clrmtMakrKey = filter.value;
      } else if (target === "clrmtModlNo") {
        item.clrmtModlKey = filter.value;
      }
      // 지열
      else if (target === "heatPumpMakrNo") {
        item.heatPumpMakrKey = filter.value;
      } else if (target === "heatPumpModlNo") {
        item.heatPumpModlKey = filter.value;
      } else if (target === "hwClrmtMakrNo") {
        item.hwClrmtModlKey = filter.value;
      } else if (target === "hwClrmtModlNo") {
        item.hcClrmtModlKey = filter.value;
      } else if (target === "hcClrmtMakrNo") {
        item.clrmtMakrHwKey = filter.value;
      } else if (target === "hcClrmtModlNo") {
        item.clrmtMakrHcKey = filter.value;
      } else if (target === "elctrMeterMakrNo") {
        item.elctrMeterMakrKey = filter.value;
      } else if (target === "elctrMeterModlNo") {
        item.elctrMeterModlKey = filter.value;
      } else if (target === "bntnMakrNo") {
        item.bntnMakrtKey = filter.value;
      } else if (target === "bntnTypeNo") {
        item.bntnTypeKey = filter.value;
      }
      return item;
    });
  };

  // 검색 필터 초기화
  const filterInitialList = () => {
    searchKeyWord.moduleMakrKey = "";
    searchKeyWord.moduleModlKey = "";
    searchKeyWord.invtMakrKey = "";
    searchKeyWord.invtModlKey = "";
    searchKeyWord.socoMakrKey = "";
    searchKeyWord.socoTypeKey = "";
    searchKeyWord.coldCtrlerMakrKey = "";
    searchKeyWord.coldCtrlerModlKey = "";
    searchKeyWord.loadClrmtMakrKey = "";
    searchKeyWord.loadClrmtModlKey = "";
    searchKeyWord.wtrHtrMakrKey = "";
    searchKeyWord.wtrHtrModlKey = "";
    searchKeyWord.clrmtMakrKey = "";
    searchKeyWord.clrmtModlKey = "";
    searchKeyWord.heatPumpMakrKey = "";
    searchKeyWord.heatPumpModlKey = "";
    searchKeyWord.hwClrmtModlKey = "";
    searchKeyWord.hcClrmtModlKey = "";
    searchKeyWord.clrmtMakrHwKey = "";
    searchKeyWord.clrmtMakrHcKey = "";
    searchKeyWord.elctrMeterMakrKey = "";
    searchKeyWord.elctrMeterModlKey = "";
    searchKeyWord.bntnMakrtKey = "";
    searchKeyWord.bntnTypeKey = "";
  };

  // 데이터 불러오기
  const getData = () => {
    setLoading(true);
    initialSearchKey();
    initaialSelectMeain();
    // 네비게이션 설정
    makeNavigator(search);
    // 통합 데이터 불러오기
    getMngtMeainDataList();
  };

  // 선택한 설비 초기화
  const initaialSelectMeain = () => {
    setDataList(dataList => {
      const item = { ...dataList };
      item.meainInfo = {};
      item.saveMeainInfoList = [];
      return item;
    });
  };

  // 검색 시 키값 초기화 (랜더링 X)
  const initialSearchKey = () => {
    search.mngt.paging.take = 10;
    search.mngt.paging.skip = 0;
    search.mngt.energyTypeCode = "";
    search.mngt.tabStatus = 0;
    search.instlPlnnm = "";
  };

  // 설비 리스트 불러오기
  const getMngtMeainDataList = () => {
    getMngtMeainList(conn, getMngtMeainListResult, getRejection, search);
  };

  const getMngtMeainListResult = results => {
    setLoading(false);
    setDataList(dataList => {
      const item = { ...dataList };
      item.meainList.list = results.results;
      item.meainList.total = results.total;
      return item;
    });
  };

  // 설치유형 - 태양광 리스트, 태양열(자연순환식) 리스트, 지열 리스트
  const getInstTypeSolaList = () => {
    const instTypeSola = codeList.filter(v => v.grpCodeNo === 51);
    const instTypeHeat = codeList.filter(v => v.grpCodeNo === 52);
    const instTypeHeath = codeList.filter(v => v.grpCodeNo === 53);
    setDataList(dataList => {
      const item = { ...dataList };
      item.initialDs.instTypeSola = instTypeSola;
      item.initialDs.instTypeHeat = instTypeHeat;
      item.initialDs.instTypeHeath = instTypeHeath;
      return item;
    });
  };

  // 설비 모델 & 제조사 목록
  const getMeainModlMakrList = () => {
    getMeainModlMakr(conn, getMeainModleMakrResult, getRejection);
  };

  const getMeainModleMakrResult = result => {
    setDataList(dataList => {
      const item = { ...dataList };
      item.initialDs.makrList = result.makrDtoList;
      item.initialDs.meainModelList = result.meainModelDtoList;
      return item;
    });
  };

  // 설비 설정 값 변경 시 적용 ( 설치계획서에서 설비 클릭 및 관리정보에서 설정된 값 변경 시 함수 작동 )
  const setMeainInfo = info => {
    setDataList(dataList => {
      const item = { ...dataList };
      item.meainInfo = info;
      return item;
    });
  };

  // 설비 클릭 시 설비 에너지원에 따라서 데이터 가져오는 API 함수
  const getMngtMeainInfoData = () => {
    // console.log(" 설비 클릭");
    getMngtMeainInfo(conn, getMngtMeainInfoDataResult, getRejection, search);
  };

  // 설비 클릭 시 설비 에너지원에 따라서 데이터 가져오는 API 함수 결과
  const getMngtMeainInfoDataResult = result => {
    // 결과에서 설치 계획서 번호에 따른 값 설정
    if (result.instlPlnnm) {
      setDataList(dataList => {
        const item = { ...dataList };
        // 객체 합치기 기존 설비 정보 + 새로운 객체 정보
        const newObj = { ...result, ...item.meainInfo }; // 순서 바뀌면 안됨 : 앞의 객체에 뒤의 객체를 덮어 씌우기에 중요
        // 연동 가능 설정
        newObj.ableSync = "Y";
        item.meainInfo = newObj;
        // 이미 연동 했다면 연동 할 이유가 없기에 연동 목록에서 제외
        if (item.meainInfo.syncYn === "Y") {
          item.meainInfo.ableSync = "N";
        }
        return item;
      });
    } else {
      setDataList(dataList => {
        // 연동 불가능 설정
        const item = { ...dataList };
        item.meainInfo.ableSync = "N";
        return item;
      });
    }
  };

  // 설비 저장 (단건)
  const saveMeainInfoData = () => {
    const isValidResult = isValidData(dataList.meainInfo);
    if (!isValidResult.isValid) {
      kendo.alert(isValidResult.message);
      return;
    }

    setDataList(dataList => {
      const item = { ...dataList };
      item.meainInfo.createUpdateYn = true;
      return item;
    });

    // console.log("dataList.meainInfo",dataList.meainInfo);

    saveMeainInfoDataList([dataList.meainInfo], search.mngt.energyTypeCode);
  };

  // 설비 저장 (배열 저장) (매개변수 : 배열 설비 데이터 리스트)
  const saveMeainInfoDataList = (meainList, energyTypeCode) => {
    saveMngtMeainInfo(conn, saveMeainInfoResult, getRejection, meainList, energyTypeCode, search);
  };

  // 저장된 설비 정보에 대해서 결과 보여주기
  const saveMeainInfoResult = result => {
    setDataList(dataList => {
      const item = { ...dataList };
      item.saveMeainInfoList = result;
      return item;
    });
  };

  // A/S 보수이력 및 관리이력 - AS 보수 이력 리스트  불러오기
  const getMeainAsList = () => {
    const energyTypeCode = search.mngt.energyTypeCode;
    getMngtMeainAsList(conn, getMeainAsListResult, getRejection, search, energyTypeCode);
  };

  // A/S 보수이력 및 관리이력 - AS 보수 이력 리스트  불러오기 결과
  const getMeainAsListResult = result => {
    console.log("result", result);
    setDataList(dataList => {
      const item = { ...dataList };
      item.asHistList = result;
      return item;
    });
  };

  // A/S 보수이력 및 관리이력 - AS 보수이력 데이터 저장
  const saveMeainAsInfoData = info => {
    const energyTypeCode = search.mngt.energyTypeCode;
    const saveData = info;
    saveMeainAsInfo(conn, saveMeainAsInfoResult, getRejection, saveData, energyTypeCode, search);
  };

  const saveMeainAsInfoResult = result => {
    kendo.alert("저장 되었습니다.");
    getMeainAsList();
  };

  // A/S 보수이력 및 관리이력 - 관리이력 파일 리스트
  const getMeainMngtFileList = () => {
    getMngtMeainHistFileList(conn, getMeainMngtFileDataList, getRejection, search);
  };

  const getMeainMngtFileDataList = result => {
    setDataList(dataList => {
      const item = { ...dataList };
      item.mngtFileList = result;
      return item;
    });
  };

  // Rejection 함수
  const getRejection = error => {
    setLoading(false);
    console.log("getRejection = ", error);
    if (error.errCode === "IP001") {
      kendo.alert("신청자 ( 설치계획서 번호 )가 없습니다.");
      return;
    } else if (error.errCode === "IP005") {
      kendo.alert("에너지원이 선택되거나 입력되지 않았습니다.");
      return;
    }
  };

  // INFO : 탭의 상태에 따라서 알맞게 데이터를 불러오도록 제어 함수
  const getSelectMeainInfoOrAsHistByTab = () => {
    if (search.instlPlnnm) {
      // INFO = tab 0 : 신청자 및 설비 관리정보 // tab 1 : A/S 보수이력 및 관리이력

      console.log("search", search);
      if (
        search.mngt.energyTypeCode === 39001 ||
        search.mngt.energyTypeCode === 39002 ||
        search.mngt.energyTypeCode === 39003 ||
        search.mngt.energyTypeCode === 39004 ||
        search.mngt.energyTypeCode === 39005
      ) {
        const tabStatus = search.mngt.tabStatus;
        if (tabStatus === 1) {
          getMeainAsList();
          getMeainMngtFileList();
        } else {
          getMngtMeainInfoData();
        }
      } else {
        kendo.alert("준비중입니다.");
      }
    }
  };

  // INFO Valid 확인 부분
  // Validaition 함수
  const isValidData = meainData => {    
    let isValid = true;
    let message = "";
    const energyTypeCode = search.mngt.energyTypeCode;    

    console.log('meainData: ', meainData);

    if (energyTypeCode !== 39003 && energyTypeCode !== 39004 && energyTypeCode !== 39005) {
      // 위도 , 경도
      if (!meainData.azimuth) {
        message = "방위각을 입력 해주세요.";
        isValid = false;
      } else {
        if (meainData.azimuth > 270 || meainData.azimuth < 90) {
          message = "입력된 방위각 값이 잘못 되었습니다. 다시 한번 확인 하시고 입력 해주세요. (범위 : 90 ~ 120)";
          isValid = false;
        }
      }
      if (!meainData.incliAngle) {
        message = "경사각을 입력 해주세요.";
        isValid = false;
      } else {
        if (meainData.incliAngle > 90 || meainData.incliAngle < 0) {
          message = "입력된 경사각 값이 잘못 되었습니다. 다시 한번 확인 하시고 입력 해주세요. (범위 : 0 ~ 90)";
          isValid = false;
        }
      }

      if (!meainData.instType) {
        isValid = false;
        message = "설치 유형을 선택 해주세요.";
      }
    }

    // 시공업체 , 시공업체 연락처 , 시공업체 핸드폰 번호
    if (!meainData.consEnte) {
      message = "시공업체를 입력 해주세요.";
      isValid = false;
    }

    // 태양광
    if (energyTypeCode === 39001) {
      if (!meainData.modulMakrNo) {
        message = validMessage("모듈 제조사", "drop");
        isValid = false;
      } else if (!meainData.modulTypeNo) {
        message = validMessage("모듈 모델", "drop");
        isValid = false;
      } else if (!meainData.invtMakrNo) {
        message = validMessage("인버터 제조사", "drop");
        isValid = false;
      } else if (!meainData.invtTypeNo) {
        message = validMessage("인버터 모델", "drop");
        isValid = false;
      } else if (!meainData.invtCapa) {
        message = validMessage("인버터 용량", "text");
        isValid = false;
      }
    }
    // 태양열
    else if (energyTypeCode === 39002) {
      // 시스템 형식 ( 중요 )
      if (!meainData.sysMode) {
        message = "시스템 형식을 입력하지 않았습니다.";
        isValid = false;
      } else {
        // 태양열 ( 공통 )
        if (!meainData.socoMakrNo) {
          message = validMessage("집열기 제조사", "drop");
          isValid = false;
        }
        if (!meainData.socoTypeNo) {
          message = validMessage("집열기 모델", "drop");
          isValid = false;
        }
        if (!meainData.presUseHeatSrc) {
          message = validMessage("현재사용열원", "text");
          isValid = false;
        }
        // 태양열 ( 강제 순환식 )
        if (meainData.sysMode === 29001) {
          if (!meainData.socoKind || meainData.socoKind === "-") {
            message = validMessage("집열기 종류", "text");
            isValid = false;
          }
          if (!meainData.socoArea) {
            message = validMessage("집열기 면적", "text");
            isValid = false;
          }
          if (!meainData.socoSeriCnt) {
            message = validMessage("집열기 직렬 개수", "text");
            isValid = false;
          }
          if (!meainData.socoArowCnt) {
            message = validMessage("집열기 병렬 개수", "text");
            isValid = false;
          }
          if (!meainData.coldCtrlerMakrNo) {
            message = validMessage("컨트롤로(차온제어) 제조사", "drop");
            isValid = false;
          }
          if (!meainData.coldCtrlerModlNo) {
            message = validMessage("컨트롤로(차온제어) 모델", "drop");
            isValid = false;
          }
          if (!meainData.hmediPumpType) {
            message = validMessage("열매체 펌프 유형", "text");
            isValid = false;
          }
          if (!meainData.hmediPumpOilQty) {
            message = validMessage("열매체 펌프 유량", "text");
            isValid = false;
          }
          if (!meainData.hmediPumpHead) {
            message = validMessage("열매체 펌프 양정", "text");
            isValid = false;
          }
          if (!meainData.hmediSubPumpType) {
            message = validMessage("열매체 보충펌프 유형", "text");
            isValid = false;
          }
          if (!meainData.hmediSubPumpOilQty) {
            message = validMessage("열매체 보충펌프 유량", "text");
            isValid = false;
          }
          if (!meainData.hmediSubPumpHead) {
            message = validMessage("열매체 보충펌프 양정", "text");
            isValid = false;
          }
          if (!meainData.hotwaterCirclPumpType) {
            message = validMessage("온수 순환펌프 유형", "text");
            isValid = false;
          }
          if (!meainData.hotwaterCirclPumpOilQty) {
            message = validMessage("온수 순환펌프 유량", "text");
            isValid = false;
          }
          if (!meainData.hotwaterCirclPumpHead) {
            message = validMessage("온수 순환펌프 양정", "text");
            isValid = false;
          }
          if (!meainData.woilSpeed) {
            message = validMessage("유속", "text");
            isValid = false;
          }
          if (!meainData.drvePres) {
            message = validMessage("운전압력", "text");
            isValid = false;
          }
          if (!meainData.plaDiamtr) {
            message = validMessage("배관관경", "text");
            isValid = false;
          }
          if (!meainData.rthrmTankCapa) {
            message = validMessage("축열탱크용량", "text");
            isValid = false;
          }
          if (!meainData.rthrmTankQual) {
            message = validMessage("축열탱크재질", "text");
            isValid = false;
          }
        }
        // 태양열 ( 자연 순환식 )
        else {
          if (!meainData.presUseHeatSrc) {
            message = validMessage("현재사용열원", "text");
            isValid = false;
          }
          if (!meainData.socoShtno) {
            message = validMessage("집열기 매수", "text");
            isValid = false;
          }
          if (!meainData.socoArea) {
            message = validMessage("집열기 면적", "text");
            isValid = false;
          }
          if (!meainData.rthrmTankCapa) {
            message = validMessage("축열탱크용량", "text");
            isValid = false;
          }
          if (!meainData.rthrmTankQual) {
            message = validMessage("축열탱크재질", "text");
            isValid = false;
          }
        }
      }
    }
    // 소형풍력
    else if (energyTypeCode === 39003) {
      if (!meainData.generCapa) {
        message = validMessage("발전기 용량", "text");
        isValid = false;
      }
      if (!meainData.generSeriNo) {
        message = validMessage("발전기 모델", "text");
        isValid = false;
      }
      if (!meainData.generMakrNo) {
        message = validMessage("발전기 제조사", "text");
        isValid = false;
      }
      if (!meainData.generHubHeit) {
        message = validMessage("발전기 허브높이", "text");
        isValid = false;
      }
      if (!meainData.generInstQuan) {
        message = validMessage("발전기 설치대수", "text");
        isValid = false;
      }
      if (!meainData.invtMakrNo) {
        message = validMessage("인버터 제조사", "drop");
        isValid = false;
      }
      if (!meainData.invtTypeNo) {
        message = validMessage("인버터 모델", "drop");
        isValid = false;
      }
      if (!meainData.invtCapa) {
        message = validMessage("인버터 용량", "text");
        isValid = false;
      }
    }
    // 지열
    else if (energyTypeCode === 39004) {
      if (!meainData.sysMthd || meainData.sysMthd === "-") {
        message = validMessage("시스템방식", "drop");
        isValid = false;
      }
      if (!meainData.sysSct || meainData.sysSct === "-") {
        message = validMessage("시스템구분", "drop");
        isValid = false;
      }
      if (!meainData.heatPumpCapa) {
        message = validMessage("히트펌프용량", "text");
        isValid = false;
      }
      if (!meainData.heatPumpMakrNo) {
        message = validMessage("히트펌프 제조사", "drop");
        isValid = false;
      }
      if (!meainData.heatPumpModlNo) {
        message = validMessage("히트펌프 모델명", "drop");
        isValid = false;
      }
      if (!meainData.hotchOilQty) {
        message = validMessage("냉온수 유량", "text");
        isValid = false;
      }
      if (!meainData.heathwtrOilQty) {
        message = validMessage("지열수 유량", "text");
        isValid = false;
      }
      if (!meainData.boringDeep) {
        message = validMessage("천공깊이", "text");
        isValid = false;
      }
      if (!meainData.boringCnt) {
        message = validMessage("천공개수", "text");
        isValid = false;
      }
      if (!meainData.bntnMakrNo) {
        message = validMessage("벤토나이트 제조사", "drop");
        isValid = false;
      }
      if (!meainData.bntnTypeNo) {
        message = validMessage("벤토나이트 모델명", "drop");
        isValid = false;
      }
    }
    // 연료전지
    else if (energyTypeCode === 39005) {
      if (!meainData.fuelCellMakrNo) {
        message = validMessage("연료전지 제조사", "drop");
        isValid = false;
      }
      if (!meainData.fuelCellModlNo) {
        message = validMessage("연료전지 모델명", "drop");
        isValid = false;
      }
      if (!meainData.fuelCellCapa) {
        message = validMessage("연료전지 용량", "text");
        isValid = false;
      }
    }

    // 설치확인관리번호 부분
    if (meainData.moniYn) {
      if (meainData.moniYn === "Y") {
        if (!meainData.instCnfrmCode) {
          message = validMessage("설치확인관리번호", "text");
          isValid = false;
        }
      }
    }

    // 설치자 선택
    if (!meainData.instlPlnnm) {
      isValid = false;
      message = "설치자를 선택 해주세요.";
    }
    // else if( !meainData.instType ){
    //   if( energyTypeCode !== 39004 )
    //   isValid = false; message = "설치 유형을 선택 해주세요.";
    // }

    return { isValid: isValid, message: message };
  };

  // 에너지원별 Validaion 메세지
  const validMessage = (name, comp) => {
    let meassage = "";
    if (comp === "text") {
      meassage = "설비 중 " + name + " 를 입력하지 않았습니다. " + name + " 를 입력 해주세요.";
    } else if (comp === "drop") {
      meassage = "설비 중 " + name + " 을 선택하지 않거나 입력하지 않았습니다. " + name + " 을 입력 또는 선택 해주세요.";
    }
    return meassage;
  };

  // 초기 데이터 리스트 불러오기
  useEffect(() => {
    // 설비 모델 & 제조사 목록 로드
    getMeainModlMakrList();
    // 설치유형 - 태양광, 태양열, 지열 리스트
    getInstTypeSolaList();

    getData();
    /* eslint-disable-next-line */
  }, []);

  return (
    <div className={styles.JinTabCon} id="JinTab_con">
      <SearchCom search={search} setSearch={setSearch} rgns={rgns} citys={citys} getData={getData} codeList={codeList} />
      <div className={loading ? [styles.convProject, styles.convProjectTable].join(" ") : styles.convProjectTable}>
        <div class="uk-grid">
          <BizManagementContentGrid
            Grid={Grid}
            Column={Column}
            GridNoRecords={GridNoRecords}
            styles={styles}
            meainList={dataList.meainList}
            search={search}
            setSearch={setSearch}
            getMngtMeainDataList={getMngtMeainDataList}
            setDataList={setDataList}
            convMngtStyles={bizMngtStyles}
            getMngtMeainInfoData={getMngtMeainInfoData}
            getMeainAsList={getMeainAsList}
            getSelectMeainInfoOrAsHistByTab={getSelectMeainInfoOrAsHistByTab}
            filterInitialList={filterInitialList}
          />
          <BizManagementContents
            styles={styles}
            search={search}
            setSearch={setSearch}
            convMngtStyles={bizMngtStyles}
            conn={conn}
            Input={Input}
            DropDownList={DropDownList}
            DatePickerKo={DatePickerKo}
            DateTimePickerKo={DateTimePickerKo}
            Window={Window}
            Upload={Upload}
            roundNum={roundNum}
            numFormat={numFormat}
            dataList={dataList}
            setDataList={setDataList}
            setMeainInfo={setMeainInfo}
            kendo={kendo}
            Button={Button}
            saveMeainInfoData={saveMeainInfoData}
            saveMeainInfoDataList={saveMeainInfoDataList}
            Grid={Grid}
            Column={Column}
            GridNoRecords={GridNoRecords}
            getMeainAsList={getMeainAsList}
            getSelectMeainInfoOrAsHistByTab={getSelectMeainInfoOrAsHistByTab}
            saveMeainAsInfoData={saveMeainAsInfoData}
            getMeainMngtFileList={getMeainMngtFileList}
            searchKeyWord={searchKeyWord}
            makrMeainModlfilterChange={makrMeainModlfilterChange}
            filterInitialList={filterInitialList}
            codeList={codeList}
          />
        </div>
        {dataList.saveMeainInfoList.length > 0 && (
          <BizMngtMeainSaveResult
            Grid={Grid}
            Column={Column}
            GridNoRecords={GridNoRecords}
            convMngtStyles={bizMngtStyles}
            result={dataList.saveMeainInfoList}
            Button={Button}
            initaialSelectMeain={initaialSelectMeain}
          />
        )}
      </div>
      {loading && (
        <p className={styles.convProjectLoading}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
};

export default BizManagement;
