export const getAreaEnergyList = async (conn, selectedArea) => {
  const result = await conn.get("monitor/area/list", {
    params: {
      areaType: selectedArea.areaType,
      cityProvCode: selectedArea.cityProvCode,
      rgnCode: selectedArea.rgnCode,
    },
  });

  // console.log("getAreaEnergyList result = ", result);

  const totalEnergy = {
    dayPower: 0,
    dayPowerCo2: 0,
    powerCapa: 0,
    accumPower: 0,
    dayUse: 0,
    dayUseCo2: 0,
    heatCapa: 0,
    heathCapa: 0,
    accumUse: 0,
  };
  if (result)
    result.map((item) => {
      totalEnergy.powerCapa += item.solaCapa + item.veloCapa + item.fuelcellCapa;
      totalEnergy.dayPower += item.solaDayPower + item.veloDayPower + item.fuelcellDayPower;
      totalEnergy.accumPower += item.solaAccumPower + item.veloAccumPower + item.fuelcellAccumPower;
      totalEnergy.dayUse += item.heatDayUse + item.heathDayUse;
      totalEnergy.accumUse += item.heatAccumUse + item.heathAccumUse;
      totalEnergy.heatCapa += item.heatCapa;
      totalEnergy.heathCapa += item.heathCapa;
      if (selectedArea.areaType === "CITY") item.areaTerm = item.cityProvTerm;
      else if (selectedArea.areaType === "RGN") item.areaTerm = item.rgnTerm;
      else item.areaTerm = item.dongTerm;
      return item;
    });

  totalEnergy.dayPowerCo2 = totalEnergy.dayPower * 0.46553;
  totalEnergy.dayUseCo2 = totalEnergy.dayUse * 0.4653;

  // MW 단위 로직 적용 (발전량 , 사용량)
  if (totalEnergy.accumPower) {
    totalEnergy.accumPower = totalEnergy.accumPower / 1000;
  }
  if (totalEnergy.accumUse) {
    totalEnergy.accumUse = totalEnergy.accumUse / 1000;
  }

  return { areaEnergyList: result, areaEnergyTotal: totalEnergy };
};

export const getMeainStatCntList = async (conn, selectedArea) => {
  const result = await conn.get("monitor/area/meainStatus", {
    params: {
      areaType: selectedArea.areaType,
      cityProvCode: selectedArea.cityProvCode,
      rgnCode: selectedArea.rgnCode,
    },
  });

  const totalCnt = {
    ensoTypeCode: 15000,
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    waringCnt: 0,
    errorCnt: 0,
  };

  if (!result) return [];

  result.map((item) => {
    totalCnt.readyCnt += item.readyCnt;
    totalCnt.normalCnt += item.normalCnt;
    totalCnt.unOperCnt += item.unOperCnt;
    totalCnt.waringCnt += item.waringCnt;
    totalCnt.errorCnt += item.errorCnt;
    return item;
  });
  result.splice(0, 0, totalCnt);

  return result;
};
