import React from "react";

// import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
// import { getToken } from "../../../../service/authService";
// import { Upload } from "@progress/kendo-react-upload";
// import { Window } from "@progress/kendo-react-dialogs";

const BizFileBpm1Moni = ({
  onAddFile,
  onRemoveFile,
  onStatusChangeFile,
  fileState,
  windowVisible,
  uploadFileEvt,
  deleteFileEvt,
  file,
  downloadFileEvt,
  saveFileUrl,
  removeFileUrl,
  IntlProvider,
  LocalizationProvider,
  getToken,
  Upload,
  Window,
}) => {
  return (
    <>
      <div class="JinTabMo_con" id="JinTabModalBB_con">
        <div class="SubM_title">[ 첨부서류 ]</div>
        <div class="info_text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; · <span className="red_cl">50MB이상의 파일은 첨부하실 수 없습니다.</span>(이미지파일은
          JPEG,JPG,GIF로 변환하여 첨부하셔야 합니다.)
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; · 첨부파일을 수정하시려면 반드시 삭제후 재등록 하셔야 합니다.
          <br />
        </div>
        <div class="eer-Table-scr" style={{ height: "330px" }}>
          <table class="remcBB-Table eer-Table-striped">
            <colgroup>
              <col width="550" />
              <col width="250" />
              <col width="" />
              <col width="250" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>필수</th>
                <th>첨부파일</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>본인설치대상시설(건물) 등기부등본 또는 건축물대장 1부(신축건물은 건축허가서)</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="BM11" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.BM11 ? file.BM11.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.BM11 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="BM11"
                    onClick={file?.BM11 ? deleteFileEvt : uploadFileEvt}>
                    {file?.BM11 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>설비 설치계획서[모니터링]</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="BM12" class="gree_cl" onClick={downloadFileEvt}>
                    {file.BM12 ? file.BM12.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.BM12 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="BM12"
                    onClick={file?.BM12 ? deleteFileEvt : uploadFileEvt}>
                    {file?.BM12 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>설치예정 장소 현장사진1매(4*6) 및 약도 1부</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="BM13" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.BM13 ? file.BM13.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.BM13 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="BM13"
                    onClick={file?.BM13 ? deleteFileEvt : uploadFileEvt}>
                    {file?.BM13 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>개인정보 수집·이용 동의서</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="BM14" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.BM14 ? file.BM14.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.BM14 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="BM14"
                    onClick={file?.BM14 ? deleteFileEvt : uploadFileEvt}>
                    {file?.BM14 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>(모니터링 고도화)RTU 설치사진(명판, 외함, 차단기 등)</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="BM15" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.BM15 ? file.BM15.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.BM15 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="BM15"
                    onClick={file?.BM15 ? deleteFileEvt : uploadFileEvt}>
                    {file?.BM15 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>모니터링 고도화 첨부1</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  <button 
                    style={{background:'transparent', border:'none', color:'#1e88e5', outline:'none'}}
                    name="bpm1" id="BM16" class="gree_cl" onClick={downloadFileEvt}>
                    {file?.BM16 ? file.BM16.fileName : null}
                  </button>
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.BM16 ? "redBg" : "btn_pls"}
                    name="bpm1"
                    id="BM16"
                    onClick={file?.BM16 ? deleteFileEvt : uploadFileEvt}>
                    {file?.BM16 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {windowVisible && (
        <Window
          title={"파일 등록"}
          onClose={uploadFileEvt}
          top={186}
          left={483}
          initialHeight={350}
          initialWidth={600}
          modal={true}
          resizable={false}
          doubleClickStageChange={false}
          draggable={false}>
          <div>
            {/* 윈도우 창 */}
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Upload
                  autoUpload={false}
                  batch={true}
                  multiple={false}
                  files={fileState.fileList}
                  defaultFiles={[]}
                  onAdd={onAddFile}
                  onRemove={onRemoveFile}
                  onStatusChange={onStatusChangeFile}
                  withCredentials={true}
                  removeVerb={"DELETE"}
                  restrictions={{
                    allowedExtensions: [
                      ".gif",
                      ".jpg",
                      ".png",
                      // ".xls",
                      // ".xlsx",
                      ".hwp",
                      ".doc",
                      ".pdf",
                      ".exe",
                      ".zip",
                    ],
                    maxFileSize: 51200000,
                  }}
                  saveMethod={"post"}
                  saveHeaders={{
                    "Content-Type": "multipart/form-data",
                    "X-AUTH-TOKEN": getToken(),
                  }}
                  saveUrl={saveFileUrl}
                  removeUrl={removeFileUrl}
                />
              </IntlProvider>
            </LocalizationProvider>
            {/* <div>
              <Button>저장</Button>
              <Button>취소</Button>
            </div> */}
          </div>
        </Window>
      )}
    </>
  );
};

export default BizFileBpm1Moni;
