// css
import styles from "./notiDetail.module.css";
// import styles from "./notiDetail.dk.module.css";

// react
import React, { useEffect, useState } from "react";

// service
import { downloadFile } from "../../service/fileService";
import { getDetailList } from "../../service/bbs/notiService";

export const DetailComponent = props => {
  console.log("props", props);
  const dataItem = props.dataItem;
  // console.log("dataItem = ", dataItem)
  const conn = props.dataItem.pwd;

  // const [data, setData] = useState({
  //   noticeNo: dataItem.noticeNo,
  // });

  const data = {noticeNo: dataItem.noticeNo};

  // 리스트
  const [dataState, setDataState] = useState({
    ctnt: "",
    fileList: [],
  });

  // 꼼수 카운트
  // const [fakeCount, setFakeCount] = useState(0);

  const setDetailList = noticeNo => {
    getDetailList(conn, noticeNo)
      .then(result => {
        setDataState(state => {
          const item = { ...state };
          console.log("item", item);
          item.ctnt = result.ctnt;
          item.fileList = result.boardNoticeFileDtoList;
          return item;
        });
      })
      .catch(error => {
        console.log("error = ", error);
      });
  };

  useEffect(() => {
    console.log("useEffect 실행");
    setDetailList(data.noticeNo);
    /* eslint-disable-next-line */
  }, []);
  // console.log("AAAAAAAAAA = ", props.dataItem.boardNoticeFileDtoList[0])
  // // const countDownload = 0;
  // const search = props.dataItem.userId;
  // const paging = props.dataItem.userTypeCode;
  // const conn = props.dataItem.pwd;

  // const setNotiList = (conn, search, paging) => {
  //   getNotiList(conn, search, paging)
  //     .then((result) => {
  //       console.log("새로고침")
  //     })
  //     .catch((error) => {
  //       console.log("getNotiList error = ", error);
  //     });
  // };
  // const beforeDownloadFile = (item) => {
  //   console.log("beforeDownloadFile item = ", item)
  //   console.log("beforeDownloadFile item.countDownload = ", item.countDownload)

  //   downloadFile(item);
  //   console.log("search = ", search)
  //   console.log("paging = ", paging)
  //   // countDownload = item.countDownload+1
  //   // setNotiList(conn, search, paging)
  // }
  // const [count, setCount] = useState(0);

  console.log("dataState.fileList = ", dataState.fileList);

  // 카운트 증가 함수 로직
  const countAdd = fileId => {
    console.log("카운트 증가 함수");
    if (dataState.fileList.length !== 0) {
      const fileList = [];
      const getFileList = dataState.fileList;

      getFileList.forEach(element => {
        if (fileId === element.fileId) {
          element.countDownload = element.countDownload + 1;
        }
        fileList.push(element);
      });

      //
      setDataState(dataState => {
        const item = { ...dataState };
        item.fileList = fileList;
        return item;
      });
    }
  };

  // const innerWidth = window.innerWidth;

  console.log("dataState", dataState);
  return (
    <section>
      <div className={styles.gridContents}>
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: dataItem.titl }}></div>
        <div className={styles.dtm} dangerouslySetInnerHTML={{ __html: "작성일 : " + dataItem.regiDtm }}></div>
        {/* <div className={styles.dtm} dangerouslySetInnerHTML={{ __html: "조회수 : " + dataItem.viewCnt }}></div> */}
        <div className={styles.ctnt} dangerouslySetInnerHTML={{ __html: dataState.ctnt }}></div>
        {dataState.fileList.length > 0
          ? dataState.fileList.map((item, index) => (
              <div className={styles.fileListDiv}>
                <table className={styles.fileList} key={index}>
                  <thead>
                    <tr>
                      <th>파일이름</th>
                      <th>파일사이즈(KB)</th>
                      <th>다운로드수</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.size}</td>
                      <td>{item.countDownload}</td>
                      {/* <td>{count.count}</td> */}
                      <td>
                        <button
                          style={{background:'transparent'}}
                          className={styles.downBtn}
                          onClick={e => {
                            console.log("e = ", e);

                            //id 찾아서 count 값을 올린다.
                            // item.countDownload = item.countDownload + 1;
                            // setFakeCount(1 + 1);
                            // console.log("item = ", item);

                            // const [count, setCount] = useState(item.countDownload);
                            // setCount(count + 1);
                            // item.countDownload = count;

                            // const countDownloadInc = () => {
                            //   setCount((state) => {
                            //     const item = { ...state };
                            //     count.count =  count.count + 1;
                            //     return item;
                            //   });
                            //   console.log("count.count = ", count.count)
                            // }
                            // countDownloadInc();

                            downloadFile(item);
                            countAdd(item.fileId);
                            // setDetailList(item.noticeNo);
                          }}>
                          다운로드
                        </button>
                      </td>
                      {/* setNotiList(search, paging); */}
                    </tr>
                  </tbody>
                </table>
              </div>
            ))
          : null}
      </div>
    </section>
  );
};
export default DetailComponent;
