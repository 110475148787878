import React, { useEffect, useState, useHistory } from "react";

import { initMunicDongData, initMunicUserData } from "../../../monitor/municipality/municData";

import { initMapItem } from "../../../monitor/municipality/municData";

// Css
import "../../../monitor/municipality_v2/localAreaMonitor.css";
import styles from '../../../monitor/municipality_v2/localAreaMonitor.module.css'

import { 
  getTodayDongEnergy,
  getYesterdayDongEnergy,
  getYesterdayUserEnergy,
  getSmpRecCerDataResource,
  getMeainCntDataResource,
  getEficPowerDataResource
} from "../../../../service/monitor/municService";
import { CODE_HEAT, CODE_HEATH, CODE_SOLA } from "../../../../com/codeUtil";
import { _CO2_CAL_VAL, numFormat, roundNum } from "../../../../com/dataUtil";
import { setBoxPositionCss } from "../../../monitor/municipality_v2/geoDir/geoBoxComp";

// KENDO 
import kendo from "@progress/kendo-ui";

// 컴포넌트
import LocalAreaMonitorTitle from "../../../monitor/municipality_v2/localAreaMonitorTitle";
import LocalAreaMonitorToTalStat from "../../../monitor/municipality_v2/localAreaMonitorToTalStat";
import LocalAreaScaleComp from "../../../monitor/municipality_v2/localAreaScaleComp";
import LocalAreaRightTitle from "./localAreaRightTitle";
import LocalAreaMonitorStat from "../../../monitor/municipality_v2/localAreaMonitorStat";
import LocalAreaMonitorPowerChart from "../../../monitor/municipality_v2/localAreaMonitorPowerChart";
import LocalAreaMonitorSmpRecData from "../../../monitor/municipality_v2/localAreaMonitorSmpRecData";

// 카운트 업
import CountUp from "react-countup";
import { getEnergyDongDataList, getEnergySyncDataList, getWeekPowerDataList } from "../../../../service/monitor/localAreaMonitorService";
import JeongSeonMunicMap from "./jeongseonMunicMap";
import { BeatLoader } from "react-spinners";
import { geoBackGroudImg } from "../../../monitor/municipality_v2/geoDir/geoBackGroudImg";

const JeongSeonMunic = (props) => {
  // const history = useHistory();
  const { conn, dongList } = props;
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const testDay = new Date();
  testDay.setDate(today.getDate() - 79);

  // 로딩스피너
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    rgn: { rgnCode: "42770", rgnTerm: "정선군" },
    geoDepth: 0 ,
    dongCode : ''
  });
  let callbackData = {};
  const [dataSource, setDataSource] = useState({
    // SMP, REC , CER , 수입금 데이터
    energyPriceData : {
      // 금일 SMP
      landSmp: 0 ,
      jejuSmp: 0 ,
      smp : 0 ,
       // 금일 REC
      rec: 0 ,
      // 금일 CER ( 탄소 배출권 )
      cer: 0 ,
      // SMP 금일 예상 수익금
      smpExpeTodayPrice : 0 ,
      // SMP 금일 실제 수익금
      smpRealTodayPrice : 0 ,
      // SMP 금일 전체 수익금
      smpTotalTodayPrice : 0 ,
      // REC 금일 예상 수익금 (REC 는 1MW 당 1개가 발급이 됨)
      recExpeTodayPrice : 0 ,
      // REC 금일 실제 수익금
      recRealTodayPrice : 0 ,
      // REC 금일 전체 수익금
      recTotalTodayPrice : 0 ,
      // CER 금일 예상 수익금 (KOC 탄소배출권은 1tCO2 당 1개가 발급이 됨)
      cerExpeTodayPrice : 0 ,
      // CER 금일 실제 수익금
      cerRealTodayPrice : 0 ,
      // CER 금일 전체 수익금
      cerTotalTodayPrice : 0 ,
      // SMP + REC + CER 전체 합계
      totalTodayPrice : 0 ,
      // 연동 발전량
      syncDayPower : 0 ,
      // 미연동 발전량 
      asyncDayPower : 0 ,
      // 총 발전량
      totalDayPower : 0
    } ,
    // 태양광 계산 데이터 ( SMP , REC , CER 관련 계산 데이터 )
    solaCalDayPowerData: {
      // 금일 예상 발전량
      todayExpePowerAmount: 0 ,
      // 금일 전체 발전량 (금일 예상 발전량 + 금일 실제 발전량)
      todayTotalPowerAmount : 0 ,
    },
    // 왼쪽의 금일, 전일 데이터
    todayPowerData: { },
    // 효율 값
    effi: {
      preExpeEffi : 0 ,
      prePower : 0 ,
      todayExpeEffi : 0
    },
    // 오른쪽 위 통계 데이터
    ensoMeainCntList: [],
    // 오른쪽 사용량 상위 에너지원별 통계 
    ensoMeainData : {
      sola : { code : CODE_SOLA,   cnt : 0 , syncCount : 0 , asyncCount : 0 , instCapa : 0 , syncInstCapa : 0 , asyncInstCapa : 0 , power : 0 , syncPower : 0 , aSyncPower : 0 , use : 0 , syncUse : 0 , aSyncUse : 0 } ,
      heat : { code : CODE_HEAT,   cnt : 0 , syncCount : 0 , asyncCount : 0 , instCapa : 0 , syncInstCapa : 0 , asyncInstCapa : 0 , power : 0 , syncPower : 0 , aSyncPower : 0 , use : 0 , syncUse : 0 , aSyncUse : 0 } , 
      heath : { code : CODE_HEATH, cnt : 0 , syncCount : 0 , asyncCount : 0 , instCapa : 0 , syncInstCapa : 0 , asyncInstCapa : 0 , power : 0 , syncPower : 0 , aSyncPower : 0 , use : 0 , syncUse : 0 , aSyncUse : 0 }
    } ,
    // 동별 태양광 데이터
    realSolaDongData: [],
    // 에너지 종합 데이터 현황
    totalEnergyData : {
      totalMeainCnt : 0 ,
      totalPower : 0 ,
      totalUse : 0 , 
      asyncPower : 0 ,
      syncPower : 0 ,
      syncUse : 0 , 
      asyncUse : 0 ,
      syncMeainCnt : 0 ,
      asyncMeainCnt : 0
    } ,
    dongDataList : [] ,
    // 추이 그래프 차트 데이터
    dayPowerList : []
  });
  const [dongData, setDongData] = useState(initMunicDongData);
  const [userData, setUserData] = useState(initMunicUserData);
  const [mapItem, setMapItem] = useState(initMapItem);

  // 시간별 계산
  const calHourData = resultObj => {
    // 00시 ~ 23시
    const today = new Date();
    let hour = today.getHours();
    let hourSumPower = 0;
    let hourAvgEfic = 0;

    for (let index = 0; index <= hour; index++) {
      let indexStr = index;
      if (index < 10) {
        indexStr = "0" + index;
      }
      let powerIndex = "p" + indexStr;
      let eifcIndex = "e" + indexStr;
      hourSumPower += resultObj[powerIndex];
      hourAvgEfic += resultObj[eifcIndex];
    }
    hourAvgEfic = hourAvgEfic / (hour + 1);

    return {
      fixbylawBundTermS: resultObj.fixbylawBundTermS,
      fixbylawBundCode: resultObj.fixbylawBundCode,
      userId: resultObj.userId,
      userTerm: resultObj.userTerm,
      hourSumPower: hourSumPower,
      hourAvgEfic: hourAvgEfic,
    };
  };

  // 오른쪽 하단 지역별 태양광 모니터링 발전순위 정보 데이터
  const getTodayDongEnergyResult = result => {
    const todayPowerData = [];
    const todayEficData = [];
    const yesterdayData = [];

    result.forEach(element => {
      todayPowerData.push(calHourData(element));
      todayEficData.push(calHourData(element));
    });

    const todayPowerRank = todayPowerData.sort(function (comp1, comp2) {
      const comp1UC = comp1.hourSumPower;
      const comp2UC = comp2.hourSumPower;
      if (comp1UC < comp2UC) {
        return 1;
      } else if (comp1UC > comp2UC) {
        return -1;
      }
      return 0;
    });

    const todayEficRank = todayEficData.sort(function (comp1, comp2) {
      const comp1UC = comp1.hourAvgEfic;
      const comp2UC = comp2.hourAvgEfic;
      if (comp1UC < comp2UC) {
        return 1;
      } else if (comp1UC > comp2UC) {
        return -1;
      }
      return 0;
    });

    let item = {};
    const len = todayPowerRank.length <= 3 ? todayPowerRank.length : 3;
    for (let i = 0; i < 3; i++) {
      item["dongPower" + (i + 1)] = i < len ? todayPowerRank[i].hourSumPower : 0;
      item["dongPowerTerm" + (i + 1)] = i < len ? todayPowerRank[i].fixbylawBundTermS : "-";
      item["dongEfic" + (i + 1)] = i < len ? todayEficRank[i].hourAvgEfic : 0;
      item["dongEficTerm" + (i + 1)] = i < len ? todayEficRank[i].fixbylawBundTermS : "-";
    }
    const param = {
      date: yesterday,
      rgnCode: search.rgn.rgnCode,
    };

    // 오른쪽 하단 지역별 태양광 모니터링 발전순위 정보 데이터 (전일)
    getYesterdayDongEnergy(conn, param)
      .then(result => {
        result.forEach(element => {
          yesterdayData.push(calHourData(element));
        });
        for (let i = 0; i < 3; i++) {
          let preData;
          preData = i < len ? yesterdayData.find(e => e.fixbylawBundCode === todayPowerRank[i].fixbylawBundCode) : null;
          item["dongPrePowerRank" + (i + 1)] = preData ? todayPowerRank[i].hourSumPower - preData.hourSumPower : 0;
          item["dongPreEficRank" + (i + 1)] = preData ? todayEficRank[i].hourAvgEfic - preData.hourAvgEfic : 0;
        }
        setDongData({ ...dongData, ...item });
      })
      .catch(e => console.log("Error", e));
  };

  // 오른쪽 하단 수용가별 태양광 모니터링 발전순위 정보 데이터
  const getTodayUserEnergyResult = result => {
    const todayData = [];
    const yesterdayData = [];
    result.forEach(element => {
      todayData.push(calHourData(element));
    });

    const todayRank = todayData.sort(function (comp1, comp2) {
      const comp1UC = comp1.hourSumPower;
      const comp2UC = comp2.hourSumPower;
      if (comp1UC < comp2UC) {
        return 1;
      } else if (comp1UC > comp2UC) {
        return -1;
      }
      return 0;
    });

    let item = {};
    const len = todayRank.length <= 3 ? todayRank.length : 3;
    for (let i = 0; i < 3; i++) {
      item["userPower" + (i + 1)] = i < len ? todayRank[i].hourSumPower : 0;
      item["userTerm" + (i + 1)] = i < len ? todayRank[i].userTerm : "-";
    }

    const param = {
      date: yesterday,
      rgnCode: search.rgn.rgnCode,
    };
    // 오른쪽 하단 수용가별 태양광 모니터링 발전순위 정보 데이터 (전일)
    getYesterdayUserEnergy(conn, param)
      .then(yesterdayResult => {
        yesterdayResult.forEach(element => {
          yesterdayData.push(calHourData(element));
        });
        for (let i = 0; i < 3; i++) {
          let preData;
          preData = i < len ? yesterdayData.find(e => e.userId === todayRank[i].userId) : null;
          item["userPrePowerRank" + (i + 1)] = preData ? todayRank[i].hourSumPower - preData.hourSumPower : 0;
        }
        setUserData({ ...userData, ...item });
      })
      .catch(e => console.log("Error", e));
  };

  const callBackFunc = result => {
    callbackData = { ...callbackData, ...result };
  };

  const getRejection = error => {
    console.log("error = ", error);
  };

  const getData = () => {
    setLoading(true);
    const param = {
      date: today,
      // date: testDay,
      rgnCode: search.rgn.rgnCode,
      dongCode : search.dongCode
    };
    Promise.all([
      // 오른쪽 하단 지역별 태양광 모니터링 발전순위 정보 데이터
      getTodayDongEnergy(conn, param, getTodayDongEnergyResult, getRejection),
      // 오른쪽 하단 수용가별 태양광 모니터링 발전순위 정보 데이터
      // getTodayUserEnergy(conn, param, getTodayUserEnergyResult, getRejection),
      //1.smpreccer
      getSmpRecCerDataResource(conn, param, callBackFunc, getRejection),
      //2. 오늘,어제 power,효율 eficdata
      getEficPowerDataResource(conn, param, callBackFunc, getRejection),
      //3. 설비개수,발전량,용량 soladongdata
      getEnergyDongDataList(conn, param, callBackFunc, getRejection),
      //4. 에너지원별 통계 meaincnt
      getMeainCntDataResource(conn, param, callBackFunc, getRejection),
      //5. 에너지원별 연동된 설비 데이터 ( 발전량, 갯수, 용량 , 사용량  : 태양광 ,태양열, 지열)
      getEnergySyncDataList(conn, param, callBackFunc, getRejection),
      //6. 발전량 추이 그래프
      getWeekPowerDataList(conn, param, callBackFunc, getRejection)
    ]).then(results => {
      setLoading(false);
      calDataSource(callbackData)
    });
  };

  // 계산식 첨부 함수
  const calDataSource = (callbackData) => {
    console.log("데이터 불러오기");
    setDataSource( data => {
      const item = {...data} ;
      // 금일 SMP
      item.energyPriceData.landSmp = callbackData.landSmp;
      item.energyPriceData.jejuSmp = callbackData.jejuSmp;
      // SMP 값 ( 제주 인지 육지인지 체크)
      const cityProvCode = search.rgn.rgnCode.substr(0,2);
      const smp = cityProvCode === 50 ? callbackData.jejuSmp : callbackData.landSmp;
      item.energyPriceData.smp = smp;
      // 금일 REC
      const rec = callbackData.rec;
      item.energyPriceData.rec = rec;
      // 금일 CER ( 탄소 배출권 )
      const cer = callbackData.cer;
      item.energyPriceData.cer = cer;

      // 태양광 데이터 추출
      const solaDataList = callbackData.ensoMeainCntList.filter( v => v.ensoTypeCode === CODE_SOLA )[0]; 
      // 금일 예상 발전량
      const asyncDayPower = solaDataList.asyncDayPower;
      item.energyPriceData.asyncDayPower = asyncDayPower;
      // 연동 금일 발전량
      const syncDayPower = solaDataList.syncDayPower;
      item.energyPriceData.syncDayPower = syncDayPower;
      // 총 발전량
      const totalDayPower = syncDayPower + asyncDayPower;
      item.energyPriceData.totalDayPower = totalDayPower;
      
      // SMP 금일 예상 수익금
      item.energyPriceData.smpExpeTodayPrice = asyncDayPower * smp;
      // SMP 금일 실제 수익금
      item.energyPriceData.smpRealTodayPrice = syncDayPower * smp;
      // SMP 금일 전체 수익금
      const smpTotalTodayPrice = totalDayPower * smp;
      item.energyPriceData.smpTotalTodayPrice = smpTotalTodayPrice;
      // REC 금일 예상 수익금 (REC 는 1MW 당 1개가 발급이 됨)
      const recExpeTodayPrice =  Math.floor(asyncDayPower / 1000) * rec;
      item.energyPriceData.recExpeTodayPrice = recExpeTodayPrice;
      // REC 금일 실제 수익금
      const recRealTodayPrice = Math.floor(syncDayPower / 1000) * rec;
      item.energyPriceData.recRealTodayPrice = recRealTodayPrice;
      // REC 금일 전체 수익금
      const recTotalTodayPrice = Math.floor(totalDayPower / 1000) * rec;
      item.energyPriceData.recTotalTodayPrice = recTotalTodayPrice;
      // CER 금일 예상 수익금 (KOC 탄소배출권은 1tCO2 당 1개가 발급이 됨)
      const cerExpeTodayPrice = Math.floor((asyncDayPower * _CO2_CAL_VAL) / 1000) * cer;
      item.energyPriceData.cerExpeTodayPrice = cerExpeTodayPrice;
      // CER 금일 실제 수익금
      const cerRealTodayPrice = Math.floor((syncDayPower * _CO2_CAL_VAL) / 1000) * cer
      item.energyPriceData.cerRealTodayPrice = cerRealTodayPrice;
      // CER 금일 전체 수익금
      const cerTotalTodayPrice = Math.floor((totalDayPower * _CO2_CAL_VAL) / 1000) * cer;
      item.energyPriceData.cerTotalTodayPrice = cerTotalTodayPrice;
      // SMP + REC + CER 전체 수익금 ( 예상 + 실제 )
      const totalTodayPrice = smpTotalTodayPrice + recTotalTodayPrice + cerTotalTodayPrice;
      item.energyPriceData.totalTodayPrice = totalTodayPrice;

      // 왼쪽의 금일, 전일 데이터
      item.todayPowerData = callbackData.todayPowerData;
      // 효율 값
      // 어제 효율 값
      item.effi.preExpeEffi = callbackData.effi.preExpeEffi;
      // 어제 발전량
      item.effi.prePower = callbackData.effi.prePower;
      // 금일 효율값
      item.effi.todayExpeEffi = callbackData.effi.todayExpeEffi;
      // 오른쪽 위 통계 데이터
      item.ensoMeainCntList = callbackData.ensoMeainCntList;
      // 동별 태양광 데이터
      item.realSolaDongData = callbackData.realSolaDongData;
      
      // 미연동 태양광 설비 용량 (kWh) ( 차트에서 사용 )
      let asyncInstCapa = 0;
      // 사용량 상위 에너지원별 통계 데이터 제작
      callbackData.ensoMeainCntList.forEach(it => {
        if( it.ensoTypeCode === CODE_SOLA ) {
          item.ensoMeainData.sola.cnt = roundNum( ( it.syncCount + it.asyncCount ) , 1 ) ; item.ensoMeainData.sola.syncCount = roundNum( it.syncCount , 1 ) ; item.ensoMeainData.sola.asyncCount = roundNum( it.asyncCount , 1 );
          // 설비용량은 MW 단위로
          item.ensoMeainData.sola.instCapa = roundNum( ( it.syncInstCapa + it.asyncInstCapa ) / 1000 , 1 ) ; item.ensoMeainData.sola.syncInstCapa = roundNum( it.syncInstCapa / 1000 , 1 ) ; item.ensoMeainData.sola.asyncInstCapa = roundNum( it.asyncInstCapa / 1000 , 1 );
          asyncInstCapa = it.asyncInstCapa;
          // 발전량
          item.ensoMeainData.sola.power = roundNum( ( it.syncDayPower + it.asyncDayPower ) / 1000 , 1 ) ; item.ensoMeainData.sola.syncPower = roundNum( it.syncDayPower / 1000 , 1 ) ; item.ensoMeainData.sola.aSyncPower = roundNum( it.asyncDayPower / 1000 , 1 ) ; 
          // 사용량 
          item.ensoMeainData.sola.use = 0 ; item.ensoMeainData.sola.syncUse = it.syncDayUse ; item.ensoMeainData.sola.aSyncUse = 0 ; 
        }
        else if( it.ensoTypeCode === CODE_HEAT ) {
          item.ensoMeainData.heat.cnt = roundNum( ( it.syncCount + it.asyncCount ) , 1 ) ; item.ensoMeainData.heat.syncCount = roundNum( it.syncCount , 1 ) ; item.ensoMeainData.heat.asyncCount = roundNum( it.asyncCount , 1 );
          // 설비용량은 MW 단위로
          item.ensoMeainData.heat.instCapa = roundNum( ( it.syncInstCapa + it.asyncInstCapa ) / 1000 , 1 ) ; item.ensoMeainData.heat.syncInstCapa = roundNum( it.syncInstCapa / 1000 , 1 ) ; item.ensoMeainData.heat.asyncInstCapa = roundNum( it.asyncInstCapa / 1000 , 1 );
          // 발전량
          item.ensoMeainData.heat.power = 0 ; item.ensoMeainData.heat.syncPower = 0 ; item.ensoMeainData.heat.aSyncPower = 0 ; 
          // 사용량 
          item.ensoMeainData.heat.use = roundNum( ( it.syncDayUse + 0 ) / 1000 , 1 ) ; item.ensoMeainData.heat.syncUse = roundNum( it.syncDayUse / 1000 , 1 ) ; item.ensoMeainData.heat.aSyncUse = 0 ; 
        }
        else if( it.ensoTypeCode === CODE_HEATH ) {
          item.ensoMeainData.heath.cnt = roundNum( ( it.syncCount + it.asyncCount ) , 1 ) ; item.ensoMeainData.heath.syncCount = roundNum( it.syncCount , 1 ) ; item.ensoMeainData.heath.asyncCount = roundNum( it.asyncCount , 1) ;
          // 설비용량은 MW 단위로
          item.ensoMeainData.heath.instCapa = roundNum( ( it.syncInstCapa + it.asyncInstCapa ) / 1000 , 1 ) ; item.ensoMeainData.heath.syncInstCapa = roundNum( it.syncInstCapa / 1000 , 1 ) ; item.ensoMeainData.heath.asyncInstCapa = roundNum( it.asyncInstCapa / 1000 , 1) ;
          // 발전량
          item.ensoMeainData.heath.power = 0 ; item.ensoMeainData.heath.syncPower = 0 ; item.ensoMeainData.heath.aSyncPower = 0 ; 
          // 사용량 
          item.ensoMeainData.heath.use = roundNum( ( it.syncDayUse + 0 ) / 1000 , 1 ) ; item.ensoMeainData.heath.syncUse = roundNum( it.syncDayUse / 1000 , 1 ) ; ; item.ensoMeainData.heath.aSyncUse = 0 ; 
        }
      });

      // 총 설비 갯수
      let totalMeainCnt = 0 
      // 연동 개수
      , syncMeainCnt = 0 
      // 미연동 개수
      , asyncMeainCnt = 0;  

      // 총 전기 에너지
      let totalPower = 0; 
      let asyncPower = 0;
      let syncPower = 0;
      // 총 열 에너지.
      let totalUse = 0;;
      let syncUse = 0;
      let asyncUse = 0;

      // 미 연동 발전량
      asyncPower = item.ensoMeainData.sola.aSyncPower ;
      // 연동 발전량
      syncPower = item.ensoMeainData.sola.syncPower ;
      // 연동 사용량
      syncUse = item.ensoMeainData.heat.syncUse + item.ensoMeainData.heath.syncUse ;
      // 미연동 사용량
      asyncUse = 0;
      // 연동 미연동 총 갯수
      totalMeainCnt = item.ensoMeainData.sola.cnt + item.ensoMeainData.heat.cnt + item.ensoMeainData.heath.cnt ;
      // 연동 총 개수
      syncMeainCnt = item.ensoMeainData.sola.syncCount + item.ensoMeainData.heat.syncCount + item.ensoMeainData.heath.syncCount ;
      // 미연동 총 개수
      asyncMeainCnt = item.ensoMeainData.sola.asyncCount + item.ensoMeainData.heat.asyncCount + item.ensoMeainData.heath.asyncCount ;
      // 연동 + 미연동 사용량 ( 미연동 사용량 0)
      totalUse =  syncUse + 0  ;
      // 연동 + 미연동 발전량
      totalPower =  item.ensoMeainData.sola.power ;

      // 총 전기 에너지 , 열에너지 계산
      item.totalEnergyData.totalMeainCnt =  roundNum( totalMeainCnt , 1 );
      item.totalEnergyData.syncMeainCnt = numFormat( roundNum(  syncMeainCnt , 1 ) );
      item.totalEnergyData.asyncMeainCnt = numFormat( roundNum(  asyncMeainCnt , 1 ) );
      item.totalEnergyData.totalUse = roundNum(  totalUse , 1 ) ;
      item.totalEnergyData.totalPower = roundNum(  totalPower , 1) ;
      item.totalEnergyData.asyncPower = numFormat( roundNum( asyncPower , 1  ) );
      item.totalEnergyData.syncPower = numFormat( roundNum( syncPower , 1  ) );
      item.totalEnergyData.syncUse = numFormat( roundNum( syncUse , 1  ) );
      item.totalEnergyData.asyncUse = asyncUse;

      // 동 마다 데이터 가공해서 보여주기
      item.dongDataList = [];
      callbackData.energyDongData.forEach(dongData => {
        const dongCode = dongData.fixbylawBundCode;
        const dongTerm = dongData.fixbylawBundTermS;
        const totalCnt = dongData.asyncCount + dongData.syncCount;
        // 태양광 ( 태양열 , 지열도 추가 해야함) /// 태양열 과 지열은 미연동은 값 0
        const totalDayPower = roundNum( ( dongData.asyncDayPower + dongData.syncDayPower + dongData.syncDayUse ) , 1 );
        // 박스 위치 값 설정
        const position = setBoxPositionCss(  dongData );
        // 갯수 비율 설정
        const rateByCnt = roundNum( ( totalCnt / totalMeainCnt ) * 100  , 2);
        item.dongDataList.push({dongCode:dongCode , dongTerm : dongTerm, totalCnt : totalCnt , totalDayPower : totalDayPower , position : position , rateByCnt : rateByCnt});
      });

      // 추이 그래프 제작하기  ( 발전량 )
      // 오늘
      const today = new Date();
      // 더미 날짜
      let targetDate = new Date(today);
      // 발전량 추이 배열 초기화
      item.dayPowerList = [];
      
      // 발전량 추이 그래프 데이터 설정
      if( callbackData.dayPowerList.length > 0 ){
        const dayPowerList = callbackData.dayPowerList;
        // 예상 발전량 구하기
        const asyncInstCapaDataUseChart = asyncInstCapa;
        dayPowerList.forEach(element => {
          const asyncDayPower = asyncInstCapaDataUseChart * element.dayPowerTime ;
          // 예상 발전량 값 저장
          element.asyncDayPower = roundNum( asyncDayPower , 2 );
          // 연동 발전량 
          element.syncDayPower = roundNum( element.dayPower , 2 );
          // 총 발전량
          const totalPower = roundNum( ( asyncDayPower + element.dayPower ) , 2 );
          element.y = totalPower;
          element.totalPower = totalPower;
          // x축 이름
          element.name = kendo.toString( new Date ( element.gathDtm ) , 'MM월 dd일<br>(yy년)' ) ;
          // 날짜 이름
          element.gathName = kendo.toString( new Date ( element.gathDtm ) , 'yyyy년 MM월 dd일' ) ;
          // 데이터를 DataSource 에 저장
          item.dayPowerList.push(element);
        });
      }else{
        // 발전량 추이 객체 초기화
        for (let index = 0; index <= 7; index++) {
          targetDate.setDate( today.getDate() - index ) ;
          item.dayPowerList.push( { 
            name : kendo.toString( new Date ( targetDate.setDate( today.getDate() - index ) ) , 'MM월 dd일<br>(yy년)' ) ,
            gathName : kendo.toString( new Date ( targetDate.setDate( today.getDate() - index ) ) , 'yyyy년 MM월 dd일' ) ,
            instCapa : 0 , dayPower : 0 , asyncDayPower : 0 , syncDayPower : 0 , totalPower : 0 , y : 0
          } );
        }
      }

      console.log("item = ", item);

      return item;
    } )
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []); 

  // 반복 사이클 진행 ( 10분에 1번 조회 ) 사용안함
  // const setIntervalCycle = () => {
  //   setInterval(() => {
  //     // console.log("인터벌 함수 실행 실행 시간 = ", kendo.toString( new Date() , 'yyyy-MM-dd HH:mm:ss' ));
  //     getData();
  //   }, 600000);
  // };

  const getBackgorundImg = rgnCode => {
    const imgSrc = geoBackGroudImg[rgnCode]
    if( imgSrc ){ return `url(` + imgSrc + `)`; }
    else{ return `url(` + geoBackGroudImg[44130] + `)`; }
    // if (rgnCode === "44200" || rgnCode === "44130") return `url("/images/dist/mapBG_` + rgnCode + `.png")`;
    // else return `url("/images/dist/mapBG_` + 44130 + `.png")`;
  };

  // 검색 초기화
  const initailSearchEvt = () => {
    const dongCode = search.dongCode;
    // 테이블 색상 칠하기
    const targetTable = document.getElementById("tb_" + dongCode);
    targetTable.classList.remove('infoBoxLocalAreaOver');
    targetTable.classList.add('infoBoxLocalArea');

    // 테이블 글씨 설정
    const targetTextCnt = document.getElementById("info_text_cnt_" + dongCode);
    const targetTextPower = document.getElementById("info_text_power_" + dongCode);
    const targetTextTitle = document.getElementById("info_title_" + dongCode);
    targetTextCnt.style.color = "#3e3e3e";
    targetTextPower.style.color = "#3e3e3e";
    targetTextTitle.style.color = "#3e3e3e";

    // 지도 색상 칠하기
    const targetMap = document.getElementById("dong_" + dongCode);
    // 지도의 원래 색상으로 다시 원복한다.
    targetMap.style.fill = '#DCDCDC';

    // 지도 글씨 설정
    const mapText = document.getElementById("mapText_" + dongCode);
    mapText.style.fill = "#3e3e3e";

    // 읍면동 코드 초기화
    search.dongCode = '';

    getData();
  }

  return (
    <div
      style={
        loading
          ? {
              opacity: 0.6,
              pointerEvents: "none",
            }
          : null
      }>
      <div className={["comBg" , styles.contentStyleJeongseon].join(' ')}>
        <section
          className="content"
          style={{
            backgroundImage: getBackgorundImg(search.rgn.rgnCode),
          }}>
          <div className = { styles.localAreaLeftArea }>
            <LocalAreaMonitorTitle styles = {styles} rgnTerm = { search.rgn.rgnTerm }  />
            <LocalAreaMonitorToTalStat styles = {styles} totalEnergyData={dataSource.totalEnergyData} CountUp = {CountUp} />
            <LocalAreaScaleComp styles = {styles} />
          </div>
          <JeongSeonMunicMap
            conn={conn}
            search={search}
            setSearch={setSearch}
            dataSource={dataSource}
            mapItem={mapItem}
            setMapItem={setMapItem}
            dongList={dongList}
            styles = {styles}
            CountUp = {CountUp}
            getData = {getData}
          />
          <div className={styles.rightArea}>
            <LocalAreaRightTitle styles={styles} clickBtnEvt = {initailSearchEvt}/>
            <LocalAreaMonitorStat styles = {styles} ensoMeainData={dataSource.ensoMeainData} CountUp = {CountUp}  />
            <LocalAreaMonitorPowerChart styles = {styles} dayPowerList={dataSource.dayPowerList} />
            <LocalAreaMonitorSmpRecData styles = {styles} energyPriceData={dataSource.energyPriceData} CountUp = {CountUp} />
          </div>
        </section>
      </div>
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </div>
  );
}

export default JeongSeonMunic;