import ContributionCard from "components/localArea/asan/components/Contribution/ContributionCard";
import "./index.css";
import { environmentList } from "./../../asan/components/constant/Environment";
import { ReactComponent as Earth } from "../svg/earth.svg";
import NonsanTitle from "../NonsanTitle";

export default function Contribution({ totalCo2, totalTree, totalPlasticCup, totalPaperCup, totalCarDistance }) {
  const totalData = { totalTree, totalPlasticCup, totalPaperCup, totalCarDistance };
  return (
    <div className="nonsan-contribute-container">
      <div className="nonsan-contribute-title">
        <NonsanTitle title="오늘의 환경공헌도" />
      </div>
      <div className="con-card-list">
        {environmentList.map(item => (
          <ContributionCard title={item.title} unit={item.unit} image={item.image} totalData={totalData} />
        ))}
      </div>
      <div className="nonsan-gas">
        <Earth className="nonsan-earth-icon" />
        <div className="nonsan-gas-decrease">
          <h2>온실가스 감소량</h2>
          <div className="nonsan-gas-amount">
            <span>{totalCo2}</span>
            <span>t</span>
            <span>co</span>
            <span>2</span>
          </div>
        </div>
      </div>
    </div>
  );
}
