import { Dialog } from '@progress/kendo-react-dialogs';
import React from 'react';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 저장 결과 목록창
*/
const BizMngtMeainSaveResult = ({ 
  Grid, Column, GridNoRecords, convMngtStyles, result , Button , initaialSelectMeain
}) => {
  // 결과 메세지 CELL 
  const resultFormatCell = (e) => {
    const data = e.dataItem;
    let message = "실패";
    let resultColor = '#d51923';
    if( data.result === "save" ){
      message = "성공";
      resultColor = '#000dff';
    }
    return (
      <td style={{color : resultColor }} > {message} </td>
    );
  }

  // 사유 코드 설정
  // INFO : Validation 결과 사유 메세지 설정
  const generateErrorData = (e) => {
    let message = "";
    const data = e.dataItem;
    if( data.errorCode ){
      // INFO 공용 설비 체크
      if( data.errorCode === "IP001" ){ message = "설치계획서가 입력되지 않았습니다."; }
      else if( data.errorCode === "IPM003" ){ message = "사업 대상지에서 설치계획서가 등록되지 않았습니다."; }
      else if( data.errorCode === "IPM004" ){ message = "기존 추가된 이력이 존재합니다."; }
      else if( data.errorCode === "MS001" ){ message = "태양광 설치유형을 입력되지 않았습니다."; }
      else if( data.errorCode === "MH002" ){ message = "태양열 설치유형을 입력되지 않았습니다."; }
      else if( data.errorCode === "MHS018" ){ message = "지열 설치유형을 입력되지 않았습니다."; }
      else if( data.errorCode === "M014" ){ message = "설치일자를 입력되지 않았습니다."; }
      else if( data.errorCode === "M016" ){ message = "A/S 만료일자를 입력되지 않았습니다."; }
      // 모니터링 고도화에 따른 설치확인관리번호 입력 여부 확인
      else if( data.errorCode === "IPM001" ){ message = "모니터링 고도화 설비라서 설치확인관리번호를 입력 해야합니다."; }
      else if( data.errorCode === "IPM002" ){ message = "모니터링 고도화 설비가 아니라서 설치확인관리번호를 입력 하시면 안됩니다."; }
      // 시공업체
      else if( data.errorCode === "M011" ){ message = "시공업체가 입력되지 않았습니다."; }

      // INFO 태양광, 태양열 설비 체크
      // 방위각 경사각 입력 값 코드
      else if( data.errorCode === "M022" ){ message = "경사각을 0 ~ 90도 사이로 입력 해주세요."; }
      else if( data.errorCode === "M021" ){ message = "방위각을 90 ~ 270도 사이로 입력 해주세요."; }
      // 태양광 - 방위각
      else if( data.errorCode === "MS007" ){ message = "방위각이 입력되지 않았습니다."; }
      // 태양광 - 경사각
      else if( data.errorCode === "MS008" ){ message = "경사각이 입력되지 않았습니다."; }
      // 태양열 - 방위각
      else if( data.errorCode === "MH003" ){ message = "방위각이 입력되지 않았습니다."; }
      // 태양열 - 경사각
      else if( data.errorCode === "MH004" ){ message = "경사각이 입력되지 않았습니다."; }
      

      // INFO 태양광 설비 체크
      // 태양광 설비 모듈 제조사
      else if( data.errorCode === "MS005" ){ message = "모듈 제조사가 입력되지 않았습니다."; }
      // 태양광 설비 모듈 모델
      else if( data.errorCode === "MS006" ){ message = "모듈 모델이 입력되지 않았습니다."; }
      // 태양광 설비 인버터 제조사
      else if( data.errorCode === "MS011" ){ message = "인버터 제조사가 입력되지 않았습니다."; }
      // 태양광 설비 인버터 모델
      else if( data.errorCode === "MS012" ){ message = "인버터 모델이 입력되지 않았습니다."; }
      // 태양광 설비 인버터 용량
      else if( data.errorCode === "MS013" ){ message = "인버터 용량이 입력되지 않았습니다."; }

      // INFO 태양열 설비 체크
      // INFO 태양열 공통
      // 시스템방식
      else if( data.errorCode === "MH029" ){ message = "시스템방식이 입력되지 않았습니다."; }
      // 현재사용열원
      else if( data.errorCode === "MH001" ){ message = "현재사용열원이 입력되지 않았습니다."; }
      // 집열기 제조사
      else if( data.errorCode === "MH009" ){ message = "집열기 제조사가 입력되지 않았습니다."; }
      // 집열기 모델
      else if( data.errorCode === "MH010" ){ message = "집열기 모델이 입력되지 않았습니다."; }
      // 축열탱크용량
      else if( data.errorCode === "MH005" ){ message = "축열탱크용량이 입력되지 않았습니다."; }
      // 축열탱크재질
      else if( data.errorCode === "MH006" ){ message = "축열탱크재질가 입력되지 않았습니다."; }

      // INFO 태양열 - 강제
      // 집열기 종류
      else if( data.errorCode === "MH008" ){ message = "집열기 종류가 입력되지 않았습니다."; }
      // 집열기 면적
      else if( data.errorCode === "MH007" ){ message = "집열기 면적이 입력되지 않았습니다."; }
      // 집열기 직렬개수
      else if( data.errorCode === "MH011" ){ message = "집열기 직렬개수가 입력되지 않았습니다."; }
      // 집열기 병렬개수
      else if( data.errorCode === "MH012" ){ message = "집열기 병렬개수가 입력되지 않았습니다."; }
      // 컨트롤러(차온제어) 제조사
      else if( data.errorCode === "MH013" ){ message = " 컨트롤러(차온제어) 제조사가 입력되지 않았습니다."; }
      // 컨트롤러(차온제어) 모델
      else if( data.errorCode === "MH014" ){ message = "컨트롤러(차온제어) 모델이 입력되지 않았습니다."; }
      // 열매체 펌프 유형
      else if( data.errorCode === "MH017" ){ message = "열매체 펌프 유형이 입력되지 않았습니다."; }
      // 열매체 펌프 유량
      else if( data.errorCode === "MH018" ){ message = "열매체 펌프 유량이 입력되지 않았습니다."; }
      // 열매체 펌프 양정
      else if( data.errorCode === "MH019" ){ message = "열매체 펌프 양정이 입력되지 않았습니다."; }
      // 열매체 보충펌프 유형
      else if( data.errorCode === "MH020" ){ message = "열매체 보충펌프 유형이 입력되지 않았습니다."; }
      // 열매체 보충펌프 유량
      else if( data.errorCode === "MH021" ){ message = "열매체 보충펌프 유량이 입력되지 않았습니다."; }
      // 열매체 보충펌프 양정
      else if( data.errorCode === "MH022" ){ message = "열매체 보충펌프 양정이 입력되지 않았습니다."; }
      // 온수 순환펌프 유형
      else if( data.errorCode === "MH023" ){ message = "온수 순환펌프 유형이 입력되지 않았습니다."; }
      // 온수 순환펌프 유량
      else if( data.errorCode === "MH024" ){ message = "온수 순환펌프 유량이 입력되지 않았습니다."; }
      // 온수 순환펌프 양정
      else if( data.errorCode === "MH025" ){ message = "온수 순환펌프 양정이 입력되지 않았습니다."; }
      // 유속
      else if( data.errorCode === "MH026" ){ message = "유속이 입력되지 않았습니다."; }
      // 운전압력
      else if( data.errorCode === "MH027" ){ message = "운전압력이 입력되지 않았습니다."; }
      // 배관관경
      else if( data.errorCode === "MH028" ){ message = "배관관경이 입력되지 않았습니다."; }

      // INFO 소형풍력
      // 발전기 용량
      else if( data.errorCode === "MV001" ){ message = "발전기 용량이 입력되지 않았습니다."; }
      // 발전기 허브높이
      else if( data.errorCode === "MV004" ){ message = "발전기 허브높이가 입력되지 않았습니다."; }
      // 발전기 제조사
      else if( data.errorCode === "MV002" ){ message = "발전기 제조사가 입력되지 않았습니다."; }
      // 발전기 모델명
      else if( data.errorCode === "MV003" ){ message = "발전기 모델이 입력되지 않았습니다."; }
      // 발전기 설치대수
      else if( data.errorCode === "MV009" ){ message = "발전기 설치대수가 입력되지 않았습니다."; }
      // 인버터 용량
      else if( data.errorCode === "MV007" ){ message = "인버터 용량이 입력되지 않았습니다."; }
      // 인버터 제조사
      else if( data.errorCode === "MV005" ){ message = "인버터 제조사가 입력되지 않았습니다."; }
      // 인버터 모델명
      else if( data.errorCode === "MV006" ){ message = "인버터 모델이 입력되지 않았습니다."; }
      

      // INFO 연료전지
      // 인버터 제조사
      else if( data.errorCode === "ME001" ){ message = "인버터 제조사가 입력되지 않았습니다."; }
      // 인버터 모델명
      else if( data.errorCode === "ME002" ){ message = "인버터 모델이 입력되지 않았습니다."; }
      // 인버터 용량
      else if( data.errorCode === "ME003" ){ message = "인버터 용량이 입력되지 않았습니다."; }
      

      // INFO 태양열 - 자연
      // 집열기 매수
      else if( data.errorCode === "MHN001" ){ message = "집열기 매수가 입력되지 않았습니다."; }
      // 집열기 면적
      else if( data.errorCode === "MH007" ){ message = "집열기 면적이 입력되지 않았습니다."; }

      // INFO 지열 설비 체크
      // 지열 - 시스템방식
      else if( data.errorCode === "MHS001" ){ message = "시스템방식이 입력되지 않았습니다."; }
      // 지열 - 시스템구분
      else if( data.errorCode === "MHS002" ){ message = "시스템구분이 입력되지 않았습니다."; }
      // 지열 - 히트펌프 용량
      else if( data.errorCode === "MHS003" ){ message = "히트펌프 용량이 입력되지 않았습니다."; }
      // 지열 - 히트펌프 제조사
      else if( data.errorCode === "MHS004" ){ message = "히트펌프 제조사가 입력되지 않았습니다."; }
      // 지열 - 히트펌프 모델
      else if( data.errorCode === "MHS005" ){ message = "히트펌프 모델이 입력되지 않았습니다."; }
      // 지열 - 냉온수 유량
      else if( data.errorCode === "MHS012" ){ message = "냉온수 유량이 입력되지 않았습니다."; }
      // 지열 - 지열수 유량
      else if( data.errorCode === "MHS013" ){ message = "지열수 유량이 입력되지 않았습니다."; }
      // 지열 - 천공깊이
      else if( data.errorCode === "MHS014" ){ message = "천공깊이가 입력되지 않았습니다."; }
      // 지열 - 천공개수
      else if( data.errorCode === "MHS015" ){ message = "천공개수가 입력되지 않았습니다."; }
      // 지열 - 벤토나이트 제조사
      else if( data.errorCode === "MHS016" ){ message = "벤토나이트 제조사가 입력되지 않았습니다."; }
      // 지열 - 벤토나이트 모델명
      else if( data.errorCode === "MHS017" ){ message = "벤토나이트 모델명이 입력되지 않았습니다."; }
    }
    return <td style={{color : '#d51923', textAlign : "left" }} > {message} </td>;
  }

  return (
      <Dialog>
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">
                  결과 목록
              </h3>
            </div>
            <Grid
                data = {result} style ={{height: "250px"}}
              >
                <Column field="instlPlnnm" title="설치계획서 번호" width = {250} /> 
                {/* 신청자(건물)명을 넣으면 엑셀 업로드 데이터에서 신청자(건물)명 불러오는 로직 또는 엑셀업로드 양식에 입력 해서 넣는 방법을 써야함 */}
                <Column field="instlTerm" title="신청자(건물)명" width = {250} /> 
                <Column field="energyTypeTerm" title="에너지원" width = {250} /> 
                <Column field="result" title="결과" width = {150} cell = {resultFormatCell} />
                <Column field="errorTerm" title="사유" width = {400} cell = {generateErrorData} />
                <GridNoRecords>
                  데이터가 존재하지 않습니다.
                </GridNoRecords>
              </Grid>
          </div>
        </div>
        <Button className={convMngtStyles.confirmResultBtn} onClick = {initaialSelectMeain}>확인</Button>
      </Dialog>
  );
};

export default BizMngtMeainSaveResult;