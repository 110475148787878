import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import DatePickerKo  from '../../../com/dateInputs/datePickerKo';
import kendo from '@progress/kendo-ui';

import { useState, useEffect, memo } from 'react';
import { BeatLoader } from 'react-spinners'

import AreaMaker from './areaMaker';
import AreaRank from './areaRank';
import { getMakrDataList , getModlDataList , getAreaDataList } from '../../../../service/stats/type/areaDataService';

import './cmpArea.css';

// 켄도 언어 컨버터 (한글)
import { IntlProvider, LocalizationProvider , loadMessages } from '@progress/kendo-react-intl';
import koMessage from "../../../../com/ko-language.json";
import { getCalCityList, getCalRgnList } from '../../../../com/dataUtil';
loadMessages(koMessage, "ko-KR");

const CmpArea = memo(( {conn  , codeList , cityList , rgnList } ) => {

  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 페이징 상태
  let [markerPaging , setMarkerPaging] = useState({ take: 10, skip: 0 });
  let [rankPaging , setRankPaging] = useState({ take: 10, skip: 0 });

    const ensoList = codeList.filter( c => c.grpCodeNo === 15 && c.codeNo !== 15007 );
    // const dateList = codeList.filter( c => c.grpCodeNo === 23 && c.codeNo !== 23001 );

    // 날짜 Default 정의
    let today = new Date();
    let yesterday = new Date();
    let lastWeek = new Date();
    let lastMonth = new Date();
    let lastYear = new Date();
    yesterday.setDate( today.getDate() - 1 );
    lastWeek.setDate(yesterday.getDate() - 7);
    lastMonth.setDate(yesterday.getDate() - 30);
    lastYear.setDate(yesterday.getDate() - 365);

    // 날짜 MAX MIN
    let [startMax, /*setStartMax*/] = useState(yesterday);
    let [/*endMin*/, setEndMin] = useState(yesterday);

    // 조회 데이터 
    const [search, setSearch] = useState({
      ensoTypeCodeNo : 15001 ,
      cityProvCode : "",
      rgnCode : "",
      start : yesterday ,
      end : yesterday ,
      rgn : { rgnCode : "", rgnTermSimple : "시군구" } , 
      city : {cityProvTerm : "시/도" , cityProvCode : ""}
    });
  
    const citys = getCalCityList(cityList);
    const rgns = getCalRgnList(rgnList.filter( e=> e.cityProvCode === search.cityProvCode ));

    // 랭크 데이터소스
    const [dataSource , setDataSource] = useState({
        rgnList : []
        , rgnRankList : {}
        , makrList : []
        , makrRankList : {}
        , modlList : []
        , modlRankList : {}
        , rgnCode : ""
        , makrDrillDown : false
    });

    // 검색 이벤트
    const setSearchObjEvt = (e) => {
      if( e.target.name === "ensoTypeCodeNo" ){ 
        search.ensoTypeCodeNo = e.target.value.codeNo;
      }
      else if( e.target.name === "city" ){
        setSearch(search => {
          const item = {...search};
          item.city = e.target.value;
          item.cityProvCode = e.target.value.cityProvCode;
          // item.cityProvTerm = e.target.value.cityProvTerm;
          item.rgnCode = "";
          // item.rgnTerm = "";
          item.rgn = { rgnCode : "", rgnTermSimple : "시군구" };  
          return item;
        })
      }
      else if( e.target.name === "rgnCode" ){ 
        setSearch( state => { const item = {...state}; item.rgn = e.target.value; item.rgnCode = e.target.value.rgnCode; return item; });
      }
    }

    // 조회 버튼 클릭 이벤트
  const setSearchClickEvt = (e) => {
      markerPaging.take = 10;
      markerPaging.skip = 0;
      rankPaging.take = 10;
      rankPaging.skip = 0;

      setDataSource( dataSource =>{
        const item = {...dataSource}; 
        item.makrDrillDown = false;
        item.makrList = [] ;
        item.makrRankList = {} ;
        item.modlList = [] ;
        item.modlRankList = {} ;
        return item;
      } );
      setRgnDataSource();
    }

    // 지역 데이터 가져와서 데이터 소스 세팅
  const setRgnDataSource = () => {
    setLoading(true);
      getAreaDataList( conn  , getRgnList , getRejection , search );
    }

    // 지역 데이터 Callback
  const getRgnList = (result) => {
    setLoading(false);
      // 차트데이터 설정
      const obj = getChartCal(result , "rgnCode" , "rgnTerm");
      setDataSource( dataSource => {
          const item = {...dataSource};
          item.rgnList = result;
          item.rgnRankList = obj;
          return item;
      });    
      // 순위 1등 지역 데이터 설정
      setMakrDataSource( obj.efic[0].rgnCode );
    }

    // 제조사 데이터 가져와서 데이터 소스 세팅
    const setMakrDataSource = (rgnCode) => {
        getMakrDataList(conn  , getMakrList , getRejection , search , rgnCode);
    } 

    const getMakrList = (result , rgnCode) =>{
        if( result.length !== 0 ){
            const obj = getChartCal(result , "makrSeriNo" , "makrTerm");
            setDataSource( dataSource => {
                const item = {...dataSource};
                item.makrList = result;
                item.makrRankList = obj;
                item.rgnCode = rgnCode;
                item.makrDrillDown = false;
                return item;
            });    
        }else{
          setDataSource( dataSource => {
            const item = {...dataSource};
            item.makrList = [];
            item.makrRankList = [];
            item.rgnCode = rgnCode;
            item.makrDrillDown = false;
            return item;
        });    
        }
    }

    // 모델 데이터 가져와서 데이터 소스 세팅
  const setModlDataSource = (makrSeriNo) => {
        getModlDataList(conn  , getModlList , getRejection ,search, makrSeriNo , dataSource.rgnCode);
    }

  const getModlList = (result, makrSeriNo) => {
        if( result.length !== 0 ){
            const obj = getChartCal(result , "meainTypeSeriNo" , "meainTypeTerm");
            setDataSource( dataSource => {
                const item = {...dataSource};
                item.makrList = result;
                item.makrRankList = obj;
                item.makrDrillDown = true;
                return item;
            });    
        }
    }

    // 지역차트 아이템 가공 (동적으로 가공함)
    const getChartCal = (result , key , term) =>{
        let ratio = [];
        let efic = [];
        let fullIndex = 6;
        // 최대 6위까지 데이터가 안보이면 데이터 갯수만큼 최대 인덱스를 설정한다.
        if( result.length < 6 ){ fullIndex = result.length; }
        // 1위부터 7위까지 랭크
        for (let index = 0; index < fullIndex; index++) {
            ratio.push({
                name : result[index][term]
                , y : result[index].ratio
                , instCapa : result[index].instCapa
                , [key] : result[index][key]
            });
            // 색깔 설정
            let color = "#69bcc8";
            if( index === 0 ){ color = "#3e7bab"; }
            // 설비용량대비효율  (에너지원별 분리 해야함)
            let eficData = 0;
            if( search.ensoTypeCodeNo === 15001 || search.ensoTypeCodeNo === 15004 || search.ensoTypeCodeNo === 15006 ){ eficData = result[index].instVsPower; }
            else{ eficData = result[index].instVsUse; }
            efic.push({
                color : color
                , name : result[index][term]
                , y : eficData
                , [key] : result[index][key]
                , instCapa : result[index].instCapa
            });
        }
        const obj = {ratio , efic};
        return obj;
    }

    // reject 함수
  const getRejection = (error) => {
      setLoading(false);
      if( error.errCode === "E001" ){
        kendo.alert("에너지원 항목이 선택되지 않았습니다. 에너지원을 다시 선택 해주세요.");
        return;
      }else if( error.errCode === "E002" ){
        kendo.alert("지역이 선택되지 않았습니다. 지역을 다시 선택 해주세요.");
        return;
      }
    }

    useEffect(() => {
        // 처음 데이터 불러오는 부분 제어 로직
        setRgnDataSource();
      // eslint-disable-next-line
    }, []);

  return (
      <>
        <div className={loading ? "cmpArea" : ""}>
            <div className="uk-grid">
                <div className="uk-width-medium-1-1">
                    <div className="md-card TBgA">
                        <div className="md-card-content small-padding">
                            <span className="searchSubTitleA">에너지원</span>
                            <DropDownList
                                data = {ensoList} defaultValue = { {codeVal : "태양광" , codeNo : 15001} } name = "ensoTypeCodeNo"
                                textField = "codeVal" dataItemKey="codeNo" onChange = {setSearchObjEvt} className = "searchArea"
                            ></DropDownList>
                            <span className="searchSubTitleB">지역검색</span>
                            <DropDownList
                                data = {citys} value = {search.city} defaultItem = { {cityProvTerm : "시/도" , cityProvCode : ""} } name = "city"
                                textField = "cityProvTerm" dataItemKey="cityProvCode" onChange = {setSearchObjEvt} className = "searchArea"
                            ></DropDownList>
                            <DropDownList
                                data = {rgns} value = {search.rgn} defaultItem = { {rgnTermSimple : "시군구" , rgnCode : ""} }  disabled = { search.cityProvCode === "" } name = "rgnCode"
                                textField = "rgnTermSimple" dataItemKey="rgnCode" onChange = {setSearchObjEvt} className = "searchArea"
                            ></DropDownList>
                            <DatePickerKo id = "start" name = "start" className = "datePicker" defaultValue={yesterday} format = "yyyy-MM-dd " 
                                setDtm = { (v) =>{ search.start = v;  search.end = v; setEndMin(v); }} max={startMax} value = {search.start} /> 
                            <span className="searchSubTitleC">
                                <Button className = "inqBtn" onClick = {setSearchClickEvt} icon = "search"></Button>    
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-grid">
                <div className="uk-width-medium-1-2">
                    <div className="md-card">
                      <AreaRank dataSource={dataSource} search={search} setMakrDataSource={setMakrDataSource} setLoading={setLoading}
                      LocalizationProvider = {LocalizationProvider} IntlProvider = {IntlProvider} paging = {markerPaging} setPaging = {setMarkerPaging}
                      />
                    </div>
                </div>  
                <div className="uk-width-medium-1-2">
                    <div className="md-card">
                        <AreaMaker dataSource = {dataSource} search = {search} setModlDataSource = {setModlDataSource} setMakrDataSource = {setMakrDataSource} setLoading={setLoading}
                        LocalizationProvider = {LocalizationProvider} IntlProvider = {IntlProvider} paging = {rankPaging} setPaging = {setRankPaging}
                        />
                    </div>
                </div>
            </div>
      </div>
      {loading && <p className="cmpAreaLoading"><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}
      </>
    );
});

export default CmpArea;