// react
import React from "react";

// Component
import BizProgressEnergyImgSola from "./bizProgressEnergyImgSola";
import BizProgressNoEnergyImg from "./bizProgressNoEnergyImg";
import BizProgressEnergyImgHeat from "./bizProgressEnergyImgHeat";
import BizProgressEnergyImgHeath from "./bizProgressEnergyImgHeath";
import BizProgressEnergyImgVelo from "./bizProgressEnergyImgVelo";
import BizProgressEnergyImgFuel from "./bizProgressEnergyImgFuel";

const BizProgressEnergyImg = ({ conn, search, setSearch, dataList, stylesProgress, isMobile, useState, useCallback, kendo }) => {
  // console.log("search.progress = ", search);

  let stepName = [];
  // 파일
  const [filesState, setFilesState] = useState({
    files: [],
    fileInfo: [],
    // currentFiles: [],
    fileId: "",
    docuCodeNo: "PG",
    energyTypeCode: "",
    instlPlnnm: "",
    instStep: "",
  });

  // 확대 창 정의
  const [imgClickStatus, setImgClickStatus] = useState({
    state: false,
    url: "",
    width: 0,
    height: 0,
  });

  // 확대 창 닫기
  const closeImgWindow = () => {
    initImgClick();
  };

  // 확대 창에 대한 초기화 로직
  const initImgClick = () => {
    setImgClickStatus({
      state: false,
      url: "",
      width: 0,
      height: 0,
    });
  };

  // INFO UPLOAD 파일 추가
  const onAdd = e => {
    // props 값
    const props = e.target.props;
    // Extensions 정책 가져오기
    const allowedExtensions = props.restrictions.allowedExtensions;
    // 파일 데이터
    const fileData = e.affectedFiles[0];
    // 정책에 따라서 Validation 진행
    if (allowedExtensions.length > 0) {
      let flag = false;
      allowedExtensions.forEach(element => {
        if (fileData.extension === element) {
          flag = true;
        }
      });
      if (!flag) {
        kendo.alert("이미지 파일만 업로드 할 수 있습니다. 이미지 파일을 선택 해주세요.");
        return;
      }
    }

    // Step 이름 설정
    stepName = props.name;
    // 미리보기 파일 세팅
    dataList.previewFile[stepName] = fileData;

    // 파일 상태 데이터 세팅
    filesState.files = e.newState;
    filesState.fileInfo = e.affectedFiles;
    filesState.energyTypeCode = search.progress.energyTypeCode;
    filesState.instlPlnnm = search.instlPlnnm;
    filesState.instStep = stepName;

    // 상태 변경
    onProgress(e);
    onStatusChange(e);
  };

  // INFO 파일 삭제
  const onRemove = useCallback(
    e => {
      // 이미지 초기화
      const step = e.target.props.name;
      dataList.previewFile[step] = {};
      // 파일 상태 초기화
      setFilesState(state => {
        const item = { ...state };
        item.files = e.newState;
        item.instStep = step;
        return item;
      });

    /* eslint-disable-next-line */
    },[filesState, dataList.previewFile]);

  // INFO UPLOAD 파일 진행
  const onProgress = e => {
    setFilesState(state => {
      const item = { ...state };
      item.files = e.newState;
      return item;
    });
  };

  // INFO UPLOAD 파일 상태 변경
  const onStatusChange = e => {
    // 파일 세팅
    setFilesState(state => {
      const item = { ...state };
      item.files = e.newState;
      return item;
    });
  };

  // INFO 업로드 파일 경로
  const saveFileUrl =
    `${process.env.REACT_APP_REST_API_VERSION}/biz/file/upload?fileInfo=` +
    encodeURI(JSON.stringify(filesState.fileInfo)) +
    "&instlPlnnm=" +
    filesState.instlPlnnm +
    "&bizDocuCodeNo=38009&docuCodeNo=PG&convName=pross&energyTypeCode=" +
    filesState.energyTypeCode +
    "&instStep=" +
    filesState.instStep +
    "&bizSctCode=" +
    search.bizSctCode;
  // 제거 파일 경로
  const removeFileUrl = `${process.env.REACT_APP_REST_API_VERSION}/biz/file/delete`;

  return (
    <div>
      {search.instlPlnnm &&
      (search.progress.energyTypeCode === 39001 ||
        search.progress.energyTypeCode === 39002 ||
        search.progress.energyTypeCode === 39003 ||
        search.progress.energyTypeCode === 39004 ||
        search.progress.energyTypeCode === 39005) ? (
        <>
          {search.progress.energyTypeCode === 39001 && (
            <BizProgressEnergyImgSola
              stylesProgress={stylesProgress}
              imgFile={dataList.imgFile}
              previewFile={dataList.previewFile}
              filesState={filesState}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              saveFileUrl={saveFileUrl}
              removeFileUrl={removeFileUrl}
              isMobile={isMobile}
              imgClickStatus={imgClickStatus}
              setImgClickStatus={setImgClickStatus}
              closeImgWindow={closeImgWindow}
            />
          )}{" "}
          {search.progress.energyTypeCode === 39002 && (
            <BizProgressEnergyImgHeat
              stylesProgress={stylesProgress}
              imgFile={dataList.imgFile}
              previewFile={dataList.previewFile}
              filesState={filesState}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              saveFileUrl={saveFileUrl}
              removeFileUrl={removeFileUrl}
              isMobile={isMobile}
              imgClickStatus={imgClickStatus}
              setImgClickStatus={setImgClickStatus}
              closeImgWindow={closeImgWindow}
            />
          )}{" "}
          {search.progress.energyTypeCode === 39003 && (
            <BizProgressEnergyImgVelo
              stylesProgress={stylesProgress}
              imgFile={dataList.imgFile}
              previewFile={dataList.previewFile}
              filesState={filesState}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              saveFileUrl={saveFileUrl}
              removeFileUrl={removeFileUrl}
              isMobile={isMobile}
              imgClickStatus={imgClickStatus}
              setImgClickStatus={setImgClickStatus}
              closeImgWindow={closeImgWindow}
            />
          )}{" "}
          {search.progress.energyTypeCode === 39004 && (
            <BizProgressEnergyImgHeath
              stylesProgress={stylesProgress}
              imgFile={dataList.imgFile}
              previewFile={dataList.previewFile}
              filesState={filesState}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              saveFileUrl={saveFileUrl}
              removeFileUrl={removeFileUrl}
              isMobile={isMobile}
              imgClickStatus={imgClickStatus}
              setImgClickStatus={setImgClickStatus}
              closeImgWindow={closeImgWindow}
            />
          )}{" "}
          {search.progress.energyTypeCode === 39005 && (
            <BizProgressEnergyImgFuel
              stylesProgress={stylesProgress}
              imgFile={dataList.imgFile}
              previewFile={dataList.previewFile}
              filesState={filesState}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              saveFileUrl={saveFileUrl}
              removeFileUrl={removeFileUrl}
              isMobile={isMobile}
              imgClickStatus={imgClickStatus}
              setImgClickStatus={setImgClickStatus}
              closeImgWindow={closeImgWindow}
            />
          )}
        </>
      ) : (
        <>
          <BizProgressNoEnergyImg stylesProgress={stylesProgress} isMobile={isMobile} />
        </>
      )}
    </div>
  );
};

export default BizProgressEnergyImg;
