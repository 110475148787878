
const GathInfo = ({data , ensoTypeCode , sysMode}) => {
  // console.log("data = ", data);
  return(
    <>
      { ensoTypeCode === 15001 &&
        <>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'18%', left:'33.5%'}} > V1 : { data.length > 0 ? data[0] ? data[0].value ? data[0].value + " V" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'22%', left:'33.5%'}} > I1 : { data.length > 0 ? data[1] ? data[1].value ? data[1].value + " A" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'61.5%', left:'50%'}} > V2 : { data.length > 0 ? data[2] ? data[2].value ? data[2].value + " V" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'65%', left:'50%'}} > I2 : { data.length > 0 ?   data[3] ? data[3].value ? data[3].value + " A" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'69%', left:'50%'}} > P : { data.length > 0 ?    data[4] ? data[4].value ? data[4].value + " kW" : '미수집' : '미수집' : '미수집' }</div>
        </>
      }
      { ensoTypeCode === 15002 && sysMode === 29001 &&
        <>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'41%', left:'8%'}} > T1 : { data.length > 0 ?  data[0] ? data[0].value ? data[0].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'20%', left:'29%'}} > T2 : { data.length > 0 ? data[1] ? data[1].value ? data[1].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'24%', left:'40%'}} > T3 : { data.length > 0 ? data[2] ? data[2].value ? data[2].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'56%', left:'35%'}} > T4 : { data.length > 0 ? data[3] ? data[3].value ? data[3].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'43%', left:'51%'}} > T5 : { data.length > 0 ? data[4] ? data[4].value ? data[4].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'38%', left:'51%'}} > T6 : { data.length > 0 ? data[5] ? data[5].value ? data[5].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        </>
      }
      { ensoTypeCode === 15002 && sysMode === 29002 &&
        <>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'42%', left:'8%'}} > T1 : { data.length > 0 ?    data[0] ? data[0].value ? data[0].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'28%', left:'28%'}} > T2 : { data.length > 0 ?   data[1] ? data[1].value ? data[1].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'15%', left:'16.5%'}} > T3 : { data.length > 0 ? data[2] ? data[2].value ? data[2].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'53%', left:'24.5%'}} > T4 : { data.length > 0 ? data[3] ? data[3].value ? data[3].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'79%', left:'48%'}} > T5 : { data.length > 0 ?   data[4] ? data[4].value ? data[4].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'57%', left:'60%'}} > T6 : { data.length > 0 ?   data[5] ? data[5].value ? data[5].value + " ℃" : '미수집' : '미수집' : '미수집' }</div>
        </>
      }
      { ensoTypeCode === 15003 &&
        <>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'10%', left:'15.5%'}} > T1 : { data.length > 0 ?   data[0] ? data[0].value ? data[0].value + " ℃" : '미수집' : '미수집' : '미수집'}</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'15%', left:'8.5%'}} > T2 : { data.length > 0 ?    data[1] ? data[1].value ? data[1].value + " ℃" : '미수집' : '미수집' : '미수집'}</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'49.5%', left:'26%'}} > T3 : { data.length > 0 ?   data[2] ? data[2].value ? data[2].value + " ℃" : '미수집' : '미수집' : '미수집'}</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'17.5%', left:'23.5%'}} > T4 : { data.length > 0 ? data[3] ? data[3].value ? data[3].value + " ℃" : '미수집' : '미수집' : '미수집'}</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'57.5%', left:'45%'}} > T5 : { data.length > 0 ?   data[4] ? data[4].value ? data[4].value + " ℃" : '미수집' : '미수집' : '미수집'}</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'45%', left:'43%'}} > T6 : { data.length > 0 ?     data[5] ? data[5].value ? data[5].value + " ℃" : '미수집' : '미수집' : '미수집'}</div>
      <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'25%', left:'41%'}} > T7 : { data.length > 0 ?       data[6] ? data[6].value ? data[6].value + " ℃" : '미수집' : '미수집' : '미수집'}</div>
        <div className="floatBox" style={{position: 'absolute', fontWeight:'bold', top:'25%', left:'27.5%'}} > T8 : { data.length > 0 ?   data[7] ? data[7].value ? data[7].value + " ℃" : '미수집' : '미수집' : '미수집'}</div>
        </>
      }
    </>
  );
}

export default GathInfo;