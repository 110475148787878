import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';

const BizPlanSaveResult = ({
  Grid, GridColumn, GridNoRecords, convMngtStyles, result , Button , initalInputs
}) => {
  // 결과 메세지 CELL 
  const resultFormatCell = (e) => {
    const data = e.dataItem;
    let message = "실패";
    let resultColor = '#d51923';
    if( data.result === "save" ){
      message = "성공";
      resultColor = '#000dff';
    }
    return (
      <td style={{color : resultColor }} > {message} </td>
    );
  }

  // 사유 코드 설정
  // INFO : Validation 결과 사유 메세지 설정
  const generateErrorData = (e) => {
    let message = "";
    const data = e.dataItem;
    if( data.errorCode ){
      // INFO 공용 설비 체크
      if( data.errorCode === "IP001" ){ message = "설치계획서가 입력되지 않았습니다."; }
      else if( data.errorCode === "IP004" ){ message = "중복된 신청번호 이력이 존재합니다."; }
      else if( data.errorCode === "F011" ){ message = "필수 항목이 입력되지 않았습니다."; }
      else if( data.errorCode === "IP002" ){ message = "연락처가 정확히 입력되지 않았습니다."; }
      else if( data.errorCode === "IP003" ){ message = "이메일 형식이 정확히 입력되지 않았습니다."; }
      else if( data.errorCode === "IP008" ){ message = "BPM설치계획서 번호가 지수 형태 입니다."; }
    }
    return <td style={{color : '#d51923', textAlign : "left" }} > {message} </td>;
  }

  return (
      <Dialog>
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">
                  결과 목록
              </h3>
            </div>
            <Grid
                data = {result} style ={{height: "250px"}}
              >
                <GridColumn field="instlPlnnm" title="설치계획서 번호" width = {250} /> 
                <GridColumn field="result" title="결과" width = {150} cell = {resultFormatCell} />
                <GridColumn field="errorTerm" title="사유" width = {400} cell = {generateErrorData} />
                <GridNoRecords>
                  데이터가 존재하지 않습니다.
                </GridNoRecords>
              </Grid>
          </div>
        </div>
        <Button className={convMngtStyles.confirmResultBtn} onClick = {initalInputs}>확인</Button>
      </Dialog>
  );
};

export default BizPlanSaveResult;