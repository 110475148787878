
/**
 * 읍면동코드로 데이터 조회
 * @param {*} conn
 * @param {*} param
 * @returns
 */
export const getLocMunicData = async (conn, param) => {
  const result = await conn.get("monitor/munic/map/syncdata", {
    params: {
      fixbylawBundCode: param.fixbylawBundCode,
    },
  });
  return result;
};

/**
 * rgnCode로 데이터 조회
 * @param {*} conn
 * @param {*} param
 * @returns
 */
export const getMunicNInstDong = async (conn, param) => {
  const result = await conn.get("monitor/munic/map/syncdata", {
    params: {
      rgnCode: param.rgnCode,
    },
  });
  return result;
};

/**
 * 각 시군구의 마커 전체 조회 - 초기 1회만 조회함
 * @param {*} conn
 * @param {*} rgnCode
 * @returns
 */
export const getMunicMarker = async (conn, rgnCode) => {
  const result = await conn.get("monitor/munic/map/syncdata/marker", {
    params: {
      rgnCode: rgnCode,
    },
  });
  return result;
};

/**
 * 사용안함
 * @param {*} conn
 * @param {*} userId
 * @returns
 */
export const getMunicLastGath = async (conn, userId) => {
  const result = await conn.get("monitor/munic/map/gath", {
    params: {
      userId: userId,
    },
  });
  return result;
};

/**
 * 동코드로 lati, longi 가져오는 서비스
 * @param {*} conn
 * @param {*} dongCode
 * @returns
 */
export const getLtByDongCode = async (conn, dongCode) => {
  const result = await conn.get("/area", {
    params: {
      dongCode: dongCode,
    },
  });
  return result;
};

/**
 * rgnCode로 읍면동별 발전량 rank percentage 조회 함수
 * @param {*} conn
 * @param {*} param
 * @returns
 */
export const getDongRankPer = async (conn, data, callback, reject, setLoading) => {
  setLoading(true); 
  const param = {
    rgnCode: data.rgn.rgnCode,
  };
  await conn
    .get("monitor/munic/map/rankper", {
      params: param,
    })
    .then(response => {      
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};
