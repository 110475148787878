import kendo from "@progress/kendo-ui";
import { downloadExcel } from "../../../com/downloadExcel";


export const getUserRankList = async (conn, search) =>{
  const list = await conn.get('stat/userbetweenuser/rank', {
    params: {
      ensoTypeCode : search.ensoTypeCode
      , cityProvCode : search.cityProvCode
      , rgnCode : search.rgnCode
      , startGathDtm : kendo.toString(search.startGathDtm , 'yyyy-MM-dd') 
      , endGathDtm : kendo.toString(search.endGathDtm , 'yyyy-MM-dd') 
    }
  });

  return list;

}

export const getUserRankPage = async (conn, search) => {
  const page = await conn.get('stat/userbetweenuser/rank/page', {
    params: {
      page : (search.skip / search.take) + 1,
      size : search.take,
      ensoTypeCode : search.ensoTypeCode,
      cityProvCode : search.cityProvCode,
      rgnCode : search.rgnCode,
      startGathDtm : kendo.toString(search.startGathDtm , 'yyyy-MM-dd') ,
      endGathDtm : kendo.toString(search.endGathDtm , 'yyyy-MM-dd') 
    }
  });
  // console.log("sear", search, page);
  return {
      skip : search.skip,
      take : search.take,
      size : search.size,
      results : page.results?.map((item, index) => ({...item, no:search.skip+index+1})),
      total : page.total
    };
}


export const getUserMakrRankList = async (conn, search) => {
  // console.log("getUserMakrRankList search = ", search);
  const list = await conn.get('stat/userbetweenuser/makr/list', {
    params: {
      ensoTypeCode : search.ensoTypeCode,
      cityProvCode : search.cityProvCode,
      rgnCode : search.rgnCode,
      makrSeriNo : search.makrSeriNo,
      startGathDtm : kendo.toString(search.startGathDtm , 'yyyy-MM-dd') ,
      endGathDtm : kendo.toString(search.endGathDtm , 'yyyy-MM-dd') 
    }
  });
  return list?.map(( item,index) =>({...item,...{no:index+1}}));
}

export const getUserBizYyRankList = async (conn, search) => {
  // console.log("getUserMakrRankList search = ", search);
  const list = await conn.get('stat/userbetweenuser/bizYy/list', {
    params: {
      ensoTypeCode : search.ensoTypeCode,
      cityProvCode : search.cityProvCode,
      rgnCode : search.rgnCode,
      makrSeriNo : search.makrSeriNo,
      startGathDtm : kendo.toString(search.startGathDtm , 'yyyy-MM-dd') ,
      endGathDtm : kendo.toString(search.endGathDtm , 'yyyy-MM-dd') 
    }
  });
  return list?.map(( item,index) =>({...item,...{no:index+1}}));
}

export const getUserListExcelDownload = ( param , setLoading ) => {
  const today = kendo.toString(new Date() , 'yyyy-MM-dd');
  const excelTitle = "수용가간_수용가 데이터 리스트_(" + today + ")" ;
  const url = "/api/v2.2//stat/userbetweenuser/userList";
  try {
    downloadExcel( param , excelTitle , url , setLoading );
  } catch (error) {
      setLoading(false);
      kendo.alert("엑셀 다운로드를 실패 하였습니다.");
      return;
  }
}
