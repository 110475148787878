// 방위각 / 경사각 15001 그래프 
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';

// 하이 차트를 히트맵하이차트로 변환 
HighchartsHeatmap(Highcharts);

const AngleDataChart = ( {dataSource , x_categories , y_categories} ) => {

    const chartConfig = {
        chart: {
            type: 'heatmap',
            backgroundColor: "transparent"
        },
        title: { text: null },
        xAxis: {
            categories: x_categories,
            title: { text : '방위각', align: 'high', x: 40, y: -22 , style: { color: '#000000' } },
            labels: { format: '{value}˚' , style: { color: '#000000' } }
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        yAxis: {
            categories: y_categories,
            title: { text : '경사각' , style: { color: '#000000' } },
            labels: { format: '{value}˚' , style: { color: '#000000' }  }
        },
        colorAxis: { reversed: false, min: 0, max: 30, minColor: '#ffffff', maxColor: '#4dc9e7' ,labels: { style: { color: '#ffffff' } } },
        legend: { align: 'right', layout: 'vertical', verticalAlign: 'top' },
        tooltip: {
            formatter: function () {
                return  (this.point.value ==null ? "데이터가 없습니다!": '<b>방위각 : 약 </b> '+ this.series.xAxis.categories[this.point.x]+'˚<br><b>경사각 : 약 </b>'+ this.series.yAxis.categories[this.point.y]+'˚<br><b>운영개소 :</b> '+this.point.count+' 개소 </br><br><b>평균효율 :</b> '+this.point.value+' % </br>');
            }
        },
        series: [{
            borderWidth: 1,
            borderRadius: 2,
            borderColor: 'none',
            data: dataSource,
            nullColor: '#D3D3D3', //Defaults to #f7f7f7.
            dataLabels: { enabled: true, color: '#000000' }
        }]
    }

    return(
        <div>
            <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
        </div>
    );
}

export default AngleDataChart;