import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

const GathRawDataLogGridSola = ({ search, paging, setOperHistData , cellKeyFormat , cellFormat }) => {

  // 페이징 이벤트
  const onPageChange = e => {
    let { take, skip } = e.page;
    // 검색 안되도록 수정 _ 20220216 _ jhchoi
    if(search.cid === "" && search.userId === ""){
      return;
    }
    paging.take = take;
    paging.skip = skip;
    setOperHistData(search, paging);
  };

  // 정렬 이벤트
  const onSortChange = e => {
    const { sort } = e;
    // 검색 안되도록 수정 _ 20220216 _ jhchoi
    if(search.cid === "" && search.userId === ""){
      return;
    }
    paging.sort = sort;
    setOperHistData(search, paging);
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              id="solarGrid"
              key={15001}
              className="operHistGrid"
              data={paging.list}
              resizable={true}
              pageable={{
                type: "numeric",
                pageSizes: [5, 10, 20, 50, 100],
                previousNext: true,
                buttonCount: 5,
                refresh: true,
              }}
              total={paging.count}
              skip={paging.skip}
              take={paging.take}
              pageSize={paging.take}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              sort={paging.sort}
              sortable>
              <GridNoRecords> </GridNoRecords>
              <GridColumn title="CID" field="cid" width="130px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="설치자" field="userTerm" width="200px" cell={cellKeyFormat}></GridColumn>
              <GridColumn title="수집일자" field="gathDtm" width="170px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="dataStatSctCodeTerm" title="데이터상태" width="140px" cell={cellKeyFormat}></GridColumn>
              <GridColumn field="accumGelecQty" title="누적발전량(Wh)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="inptVotgFigr" title="입력단 전압(V)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="inptEcurFigr" title="입력단 전류(A)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="inptElcpFigr" title="입력단 출력(W)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="outpVotgFigr" title="출력단 전압(V)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="outpEcurFigr" title="출력단 전류(A)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="outpElcpFigr" title="출력단 출력(W)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="sysRPhaseVotg" title="계통R상전압(V)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="sysSPhaseVotg" title="계통S상전압(V)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="sysTPhaseVotg" title="계통T상전압(V)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="sysRPhaseEcur" title="계통R상전류(A)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="sysSPhaseEcur" title="계통S상전류(A)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="sysTPhaseEcur" title="계통T상전류(A)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="freqFigr" title="주파수(Hz)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="pwrftRate" title="역률(%)" width="110px" cell={cellFormat}></GridColumn>
              <GridColumn field="errCode" title="에러코드" width="150px" cell={cellFormat}></GridColumn>
            <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default GathRawDataLogGridSola;
