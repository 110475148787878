import "./index.css";
import { useEffect, useState } from "react";
import { useCreateAxiosInstance } from "conInstance";
import TaeanArea from "./TaeanArea";
import { ReactComponent as AreaInfo } from "../../../../../assets/svg/taean/areaInfo.svg";

const CurrentRenewableEnergyTaean = () => {
  const { conn } = useCreateAxiosInstance();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await conn.get("/monitor/installer/munic/dongEnergyData", {
          params: {
            rgnCode: 44825,
          },
        });
        setData(res);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="taean-flex-container">
      <span className="taean-dashboard-title">구역별 신재생에너지 현황</span>
      <div className="currentRenewableEnergy-wrapper-taean">
        <TaeanArea className="taean-area" data={data} />
        <AreaInfo className="taean-area-info" />
      </div>
    </div>
  );
};

export default CurrentRenewableEnergyTaean;
