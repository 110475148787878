// React 
import React, { useState, useCallback } from 'react';

// Kendo
import { FileUpload } from "@material-ui/icons";
import { Upload } from '@progress/kendo-react-upload';
import { Window } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column , GridNoRecords } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";

// Service
import { getUserTypeCode } from "../../../service/authService";
import { getUser } from "../../../service/authService";
import koMessage from "../../../com/ko-language.json";
import { getExcelDownload } from './../../../service/smp/smpHistService';
import { addSmpHour } from './../../../service/smp/smpHistService';
import { addSmpDay } from './../../../service/smp/smpHistService';
import { addSmpMonth } from './../../../service/smp/smpHistService';
import { getToken } from '../../../service/authService';
import { setCenterCalc } from './../../../com/modalUtil';


// import koMessage from "../../../../../com/ko-language.json";
loadMessages(koMessage, "ko-KR");

const SmpGrid = ({conn , styles, search, setSearch, smpResultList, getSmpHistListData, setLoading}) => {

  const loginUserTypeCode = getUserTypeCode();
  const loginUser = getUser();

  // 페이징 이벤트 처리
  const onPageChange = (e) => {
    const { page } = e;
    search.page = page.skip;
    search.size = page.take;
    getSmpHistListData();
  }

  // 그리드 동적 컬럼 설정
  const setColumns = () => {
    const columns = [];
    // 시간별 컬럼
    if( search.radio === "HH" ){
      columns.push({field: "gathDtm", title: "수집 기간" , width : "170px"});
      columns.push({field: "areaTerm", title: "지역" , width : "100px"});
      columns.push({field: "smp00", title: "00시 SMP" , width : "100px"});
      columns.push({field: "smp01", title: "01시 SMP" , width : "100px"});
      columns.push({field: "smp02", title: "02시 SMP" , width : "100px"});
      columns.push({field: "smp03", title: "03시 SMP" , width : "100px"});
      columns.push({field: "smp04", title: "04시 SMP" , width : "100px"});
      columns.push({field: "smp05", title: "05시 SMP" , width : "100px"});
      columns.push({field: "smp06", title: "06시 SMP" , width : "100px"});
      columns.push({field: "smp07", title: "07시 SMP" , width : "100px"});
      columns.push({field: "smp08", title: "08시 SMP" , width : "100px"});
      columns.push({field: "smp09", title: "09시 SMP" , width : "100px"});
      columns.push({field: "smp10", title: "10시 SMP" , width : "100px"});
      columns.push({field: "smp11", title: "11시 SMP" , width : "100px"});
      columns.push({field: "smp12", title: "12시 SMP" , width : "100px"});
      columns.push({field: "smp13", title: "13시 SMP" , width : "100px"});
      columns.push({field: "smp14", title: "14시 SMP" , width : "100px"});
      columns.push({field: "smp15", title: "15시 SMP" , width : "100px"});
      columns.push({field: "smp16", title: "16시 SMP" , width : "100px"});
      columns.push({field: "smp17", title: "17시 SMP" , width : "100px"});
      columns.push({field: "smp18", title: "18시 SMP" , width : "100px"});
      columns.push({field: "smp19", title: "19시 SMP" , width : "100px"});
      columns.push({field: "smp20", title: "20시 SMP" , width : "100px"});
      columns.push({field: "smp21", title: "21시 SMP" , width : "100px"});
      columns.push({field: "smp22", title: "22시 SMP" , width : "100px"});
      columns.push({field: "smp23", title: "23시 SMP" , width : "100px"});
    }
    // 일별 컬럼
    else if( search.radio === "DD" ){
      columns.push({field: "gathDtm", title: "수집 기간" , width : "170px"});
      columns.push({field: "landSmpDay", title: "일일 육지 SMP" , width : "347px"});
      columns.push({field: "jejuSmpDay", title: "일일 제주 SMP" , width : "347px"});
    }
    // 월별 컬럼
    else if( search.radio === "MM" ){
      columns.push({field: "gathDtm", title: "수집 기간" , width : "170px"});
      columns.push({field: "month", title: "월", width : "93px"});
      columns.push({field: "landSmpMonth", title: "월 육지 SMP" , width : "300px"});
      columns.push({field: "jejuSmpMonth", title: "월 제주 SMP" , width : "300px"});
    }
    return columns;
  }

 // 팝업창 가운데 정렬을 위한 변수
  let posCalc = {};  

  // 토글 메뉴
  const [open, setOpen] = useState(false);  // 메뉴의 초기값을 false로 설정

  // 엑셀 업로드 헤더 설정
  // eslint-disable-next-line
  const handleUploadHeaders = useCallback((e) =>  {e.headers['X-AUTH-TOKEN'] = getToken();}); 

  // 시간별 SMP 파일 업로드 윈도우 창 제어
  let [hourWindowVisible, setHourWindowVisible] = useState(false);

  // 일별 SMP 파일 업로드 윈도우 창 제어
  let [dayWindowVisible, setDayWindowVisible] = useState(false);

  // 월별 SMP 파일 업로드 윈도우 창 제어
  let [monthWindowVisible, setMonthWindowVisible] = useState(false);

  // 파일 업로드
  const [uploadState, setUploadState] = useState({
    files: [],
    events: [],
    filePreviews: {},
    afterStateChange: [],
  });

  // 파일 업로드 후 문제 없다면 리스트 변수
  let [uploadDataList, setUploadDataList] = useState([]);

  // 엑셀 업로드 취소 , 닫기 누를 시 초기화
  const initialExcelFile = () => {
    uploadState.files =  [];
    uploadState.events =  [];
    uploadState.filePreviews =  {};
    uploadState.afterStateChange = [] ;
    setUploadDataList([]);
  };

  const onAdd = (event) => {
    console.log('onAdd event = ', event);

    // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.
    const afterStateChange = () => {
      event.affectedFiles
        .filter((file) => !file.validationErrors)
        .forEach((file) => {
          const reader = new FileReader();

          reader.onloadend = (ev) => {
            setUploadState({
              files: uploadDataList.files,
              events: uploadDataList.events,
              filePreviews: {
                [file.uid]: ev.target.result,
              },
            });
          };

          reader.readAsDataURL(file.getRawFile());
        });
    };
// 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.
    setUploadState({
      // files: uploadDataList.files,
      files: event.newState,
      events: [uploadDataList.events],
      filePreviews: uploadDataList.filePreviews,
      afterStateChange: afterStateChange,
    });

    // console.log('uploadStatus = ', uploadState);
  };

 const onRemove = (event) => {
    console.log('onRemove event = ', event);

    setUploadState({
      files: [],
    });
    setUploadDataList([]);

  };

  const onProgress = (event) => {
    console.log('onProgress event = ', event);
  };

  const onStatusChange = (event) => {
    console.log('onStatusChange event = ', event);

    // 이걸 안해주면 파일 업로드 로딩이 안끝난다.
    setUploadState({
      files: event.newState,
    });

    if(event.response?.response) {
      if(event.response.response.status === 200) {
        setUploadDataList(event.response.response);
      } else {
        kendo.alert("업로드에 실패하여 관리자에게 문의해주세요.")
      }
    }
  };

  // 시간별 SMP 엑셀 업로드 윈도우 창 제어
  const uploadHourExcel = () => {
    initialExcelFile() ;
    setHourWindowVisible(!hourWindowVisible);
  };

  // 시간별 SMP 엑셀 업로드 Save
  const uploadHourSave = () => {
    console.log("uploadDataList = ", uploadDataList);
    if(uploadDataList.length <= 0) {
      kendo.alert("저장할 시간별 SMP 이력 리스트가 없습니다.");
      return;
    } else {
      if(uploadDataList.data.length <= 0){
        kendo.alert("저장할 시간별 SMP 이력 리스트가 없습니다.");
        console.log("uploadDataList.data", uploadDataList.data);
        return;
      } 
      uploadHourExcel();
      addSmpHour(conn, uploadDataList.data);    
      kendo.alert("등록이 완료되었습니다.")
    }
    getSmpHistListData();
  };

  // 일별 SMP 엑셀 업로드 윈도우 창 제어
  const uploadDayExcel = () => {
    initialExcelFile() ;
    setDayWindowVisible(!dayWindowVisible);
  };

  // 일별 SMP 엑셀 업로드 Save
  const uploadDaySave = () => {
    if(uploadDataList.length <= 0) {
      kendo.alert("저장할 일별 SMP 이력 리스트가 없습니다.");
      return;
    } else {
      if(uploadDataList.data.length <= 0){
        kendo.alert("저장할 일별 SMP 이력 리스트가 없습니다.");
        return;
      } 
      uploadDayExcel();
      addSmpDay(conn, uploadDataList.data);    
      kendo.alert("등록이 완료되었습니다.")
    }    
    getSmpHistListData();
  };

  // 월별 SMP 엑셀 업로드 윈도우 창 제어
  const uploadMonthExcel = () => {
    initialExcelFile() ;
    setMonthWindowVisible(!monthWindowVisible);
  };

  // 월별 SMP 엑셀 업로드 Save
  const uploadMonthSave = () => {
    if(uploadDataList.length <= 0) {
      kendo.alert("저장할 월별 SMP 이력 리스트가 없습니다.");
      return;
    } else {
      if(uploadDataList.data.length <= 0){
        kendo.alert("저장할 월별 SMP 이력 리스트가 없습니다.");
        return;
      } 
      uploadMonthExcel();
      addSmpMonth(conn, uploadDataList.data);    
      kendo.alert("등록이 완료되었습니다.")
    }    
    getSmpHistListData();
  };

  const toggleMenu = () => {
    setOpen(!open); // on,off 개념 boolean
  }

  posCalc = setCenterCalc(1650 ,700);
 
  // 엑셀 다운로드
  const excelDownload = () => {
    getExcelDownload(search, setLoading);
  }


  // 엑셀 업로드
  // const excelUpload = () => {
  //
  // }

  // 엑셀 샘플 다운로드
  const excelSampleDownload = () => {
    const link = document.createElement('a');
  
    link.href = '/template/smp/SMP이력_엑셀업로드샘플.xlsx';
    document.body.appendChild(link);
    link.click();
  }

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
          <div className="md-card-content">
            <div className={open ? styles.showMenu : styles.hideMenu}>
              <ul className={styles.ulDownloadCss}>
                <li className={styles.liDownloadCss} onClick={uploadHourExcel}> 
                  - &nbsp;
                  <a className="btn_up" onClick={uploadHourExcel} href="#!">
                    <FileUpload className="material-icons" style={{ fontSize: 23, color: '#000' }} />
                    <em>시간별 SMP 이력 일괄등록</em>
                  </a>&nbsp;&nbsp;
                </li>
                <li className={styles.liDownloadCss} onClick={uploadDayExcel}> 
                  - &nbsp;
                  <a className="btn_up" onClick={uploadDayExcel} href="#!">
                    <FileUpload className="material-icons" style={{ fontSize: 23, color: '#000' }} />
                    <em>일별 SMP 이력 일괄등록</em>
                  </a>&nbsp;&nbsp;
                </li>
                <li className={styles.liDownloadCss} onClick={uploadMonthExcel}> 
                  - &nbsp;
                  <a className="btn_up" onClick={uploadMonthExcel} href="#!">
                    <FileUpload className="material-icons" style={{ fontSize: 23, color: '#000' }} />
                    <em>월별 SMP 이력 일괄등록</em>
                  </a>&nbsp;&nbsp;
                </li>                  
              </ul>
            </div>
            <h3 className="md-card-toolbar-heading-text">
            {search.radio === "HH" && <span class={styles.test}>시간별 SMP 이력</span>}
            {search.radio === "DD" && <span class={styles.test}>일별 SMP 이력</span>}
            {search.radio === "MM" && <span calss={styles.test}>월별 SMP 이력</span>}
            {loginUserTypeCode === 2005 && loginUser.userId === 'developer' && (
            <Button
              className="excelBtn"
              onClick={excelSampleDownload}
              icon="excel"
            >SMP 엑셀 샘플 다운로드</Button>)}
            &nbsp;&nbsp;&nbsp;
            {loginUserTypeCode === 2005 && loginUser.userId === 'developer' && (
            <Button
              className="excelBtn"
              onClick={toggleMenu}
              icon="upload"
            >SMP 엑셀 업로드</Button>)}
            &nbsp;&nbsp;&nbsp;  
            <Button
              className="excelBtn"
              onClick={excelDownload}
              icon="download"
            >SMP 엑셀 다운로드</Button>
            </h3>
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid
                  id="smpGrid" 
                  className={styles.smpGrid}
                  data={smpResultList.results}
                  resizable={true}
                  pageable={{
                    type: "numeric",
                    pageSizes: [5, 10, 20, 50, 100],
                    previousNext: true,
                    buttonCount: 5,
                    refresh: true,
                  }}
                  total={smpResultList.total}
                  skip={search.page}
                  take={search.size}
                  pageSize={search.size}
                  onPageChange={onPageChange}
                  sortable>
                  { setColumns().map( (column ,i) => {
                    return (
                    <Column key={i} field={column.field} title={column.title} width = {column.width} />
                    )
                  })}
                  <GridNoRecords>조회된 데이터가 존재하지 않습니다.</GridNoRecords>
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
          </div>
          {hourWindowVisible && (
          <Window title={'시간별 SMP 이력 일괄등록'} onClose={uploadHourExcel} top={posCalc.top} left={posCalc.left}  initialHeight={265} initialWidth={600} modal={true}>
            <div>
            {/* 윈도우 창 */}
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Upload
                    batch={false}
                    multiple={false}
                    withCredentials={true}
                    onBeforeUpload={handleUploadHeaders}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onProgress={onProgress}
                    onStatusChange={onStatusChange}
                    restrictions={{
                      allowedExtensions: ['.xlsx'],
                      maxFileSize: 10240000
                    }}
                    files={uploadState.files}
                    saveUrl={`${process.env.REACT_APP_REST_API_VERSION}/smp/hist/hour/xlsxUpload`}
                    removeUrl={`${process.env.REACT_APP_REST_API_VERSION}/file/biz/delete`}
                  ></Upload>
                </IntlProvider>
              </LocalizationProvider>
              <div>
                <Button onClick={uploadHourSave} className="excelUploadButton">등록</Button>
                <Button onClick={uploadHourExcel} className="cancelButton">취소</Button>
              </div>
            </div>
          </Window>
        )}
        {dayWindowVisible && (
          <Window title={'일별 SMP 이력 일괄등록'} onClose={uploadDayExcel} top={posCalc.top} left={posCalc.left}  initialHeight={265} initialWidth={600} modal={true}>
            <div>
            {/* 윈도우 창 */}
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Upload
                    batch={false}
                    multiple={false}
                    withCredentials={true}
                    onBeforeUpload={handleUploadHeaders}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onProgress={onProgress}
                    onStatusChange={onStatusChange}
                    restrictions={{
                      allowedExtensions: ['.xlsx'],
                      maxFileSize: 10240000
                    }}
                    files={uploadState.files}
                    saveUrl={`${process.env.REACT_APP_REST_API_VERSION}/smp/hist/day/xlsxUpload`}
                    removeUrl={`${process.env.REACT_APP_REST_API_VERSION}/file/biz/delete`}
                  ></Upload>
                </IntlProvider>
              </LocalizationProvider>
              <div>
                <Button onClick={uploadDaySave} className="excelUploadButton">등록</Button>
                <Button onClick={uploadDayExcel} className="cancelButton">취소</Button>
              </div>
            </div>
          </Window>
        )}
        {monthWindowVisible && (
          <Window title={'월별 SMP 이력 일괄등록'} onClose={uploadMonthExcel} top={posCalc.top} left={posCalc.left}  initialHeight={265} initialWidth={600} modal={true}>
            <div>
            {/* 윈도우 창 */}
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Upload
                    batch={false}
                    multiple={false}
                    withCredentials={true}
                    onBeforeUpload={handleUploadHeaders}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onProgress={onProgress}
                    onStatusChange={onStatusChange}
                    restrictions={{
                      allowedExtensions: ['.xlsx'],
                      maxFileSize: 10240000
                    }}
                    files={uploadState.files}
                    saveUrl={`${process.env.REACT_APP_REST_API_VERSION}/smp/hist/month/xlsxUpload`}
                    removeUrl={`${process.env.REACT_APP_REST_API_VERSION}/file/biz/delete`}
                  ></Upload>
                </IntlProvider>
              </LocalizationProvider>
              <div>
                <Button onClick={uploadMonthSave} className="excelUploadButton">등록</Button>
                <Button onClick={uploadMonthExcel} className="cancelButton">취소</Button>
              </div>
            </div>
          </Window>
        )}
        </div>
      </div>
    </div>
  );
};

export default SmpGrid;