import * as React from "react";
import { Create, Delete, Save, Cancel } from "@material-ui/icons";

export const InEditCommandCell = (props) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = props.isNewItem;

  return inEdit ? (
    <td className="k-command-cell">
      <td style={{ width: 30, borderBottom: "none" }}>
        <button style={{ width: 40, border: "none", background: "transparent" }} onClick={() => (isNewItem ? props.add(dataItem) : props.update(dataItem))}>
          <i>
            <Save />
          </i>
        </button>
      </td>
      <td style={{ width: 30, border: "none" }}>
        <button style={{ width: 40, border: "none", background: "transparent" }} onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}>
          {isNewItem ? (
            <i>
              <Delete />
            </i>
          ) : (
            <i>
              <Cancel />
            </i>
          )}
        </button>
      </td>
    </td>
  ) : (
    <td className="k-command-cell">
      <td style={{ width: 30, borderBottom: "none" }}>
        <button style={{ width: 40, border: "none", background: "transparent" }} onClick={() => props.edit(dataItem)}>
          <i>
            <Create />
          </i>
        </button>
      </td>

      <td style={{ width: 30, border: "none" }}>
        <button style={{ width: 40, border: "none", background: "transparent" }} onClick={() => props.remove(dataItem)}>
          <i>
            <Delete />
          </i>
        </button>
      </td>
    </td>
  );
};
