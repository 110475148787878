import React, { useState, useCallback, useEffect } from "react";

// Kendo
import kendo from "@progress/kendo-ui";
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";

import SearchCom from "./searchCom";
import { isManager, getUserMngRgnList } from "../../../service/authService";
import TroubleShootingLeftGrid from './troubleShootingLeftGrid.jsx';
import TroubleShootingDetailChartSpace from "./troubleShootingDetailChartSpace";

import "./troubleShooting.css";
import TroubleShootingStateChartSpace from "./troubleShootingStateChartSpace";
import TroubleShootingChangeChartSpace from "./troubleShootingChangeChartSpace";
import { getTroubleShootingChangeChart, getTroubleShootingDetailChart, getTroubleShootingGrid, getTroubleShootingStateChart } from "../../../service/ai/troubleShooting/troubleShootingService";

const TroubleShooting = ({ conn, dongList }) => {
    const [search, setSearch] = useState({
        dong: { fixbylawBundTerm: "전체", fixbylawBundCode: "" },
        rgnCode: isManager() ? getUserMngRgnList()[0] : "",
        fixbylawBundCode: "",
        fixbylawBundTermSimple: "",
        instlTerm: "",
        day: new Date(),
    });

    const [gridLoading, setGridLoading] = useState(false);
    const [detailChartLoading, setDetailChartLoading] = useState(false);
    const [stateChartLoading, setStateChartLoading] = useState(false);
    const [changeChartLoading, setChangeChartLoading] = useState(false);

    const [gridData, setGridData] = useState();
    const [detailChartData, setDetailChartData] = useState({xAxis: [], precQty : [], soraQty : [], elecEffi : [], dayEffi : [], });
    const [stateChartData, setStateChartData] = useState([]);
    const [changeChartData, setChangeChartData] = useState([]);


    const [selectedUser, setSelectedUser] = useState("");

    const customDongList = isManager() ? dongList.filter(item => item.rgnCode === search.rgnCode) : dongList;

    const getData = () => {
        setGridLoading(true);
        setStateChartLoading(true);
        setChangeChartLoading(true);
        setSelectedUser("");

        // 그리드 데이터
        getTroubleShootingGrid(conn, search)
        .then((result) => {
            setGridLoading(false);
            setGridData(result);
        })
        .catch((error) => {
            setGridLoading(false);
            console.log(error);
        })
 
        // 진단 상태 현황 데이터
        getTroubleShootingStateChart(conn, search)
        .then((result) => {
            setStateChartLoading(false);
            setStateChartData(result);
        })
        .catch((error) => {
            setStateChartLoading(false);
            console.log(error);
        })

        // 수용가별 발전효율 변동 현황 데이터
        getTroubleShootingChangeChart(conn, search)
        .then((result) => {
            setChangeChartLoading(false);
            setChangeChartData(result);
        })
        .catch((error) => {
            setChangeChartLoading(false);
            console.log(error);
        })
    }

    const getDetailChartData = () => {
        setDetailChartLoading(true);
        getTroubleShootingDetailChart(conn, search, selectedUser)
        .then((result) => {  
            setDetailChartData({ xAxis: [], precQty : [], soraQty : [], elecEffi : [], dayEffi : [], });
            setDetailChartLoading(false);
            setDetailChartData(result);
        })
        .catch((error) => {
            setDetailChartLoading(false);
            console.log(error);
        })
    }

    const onClickEvt = () => {
        getDetailChartData();
    }

    useEffect(() => {
        if(selectedUser !== ""){
            getDetailChartData();
        }
    }, [selectedUser])

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className='uk-grid'>
            <TroubleShootingLeftGrid
                search={search}
                setSearch={setSearch}
                getData={getData}
                dongList={customDongList}
                SearchCom={SearchCom}
                setSelectedUser={setSelectedUser}
                gridData={gridData}
                gridLoading={gridLoading}
            />
            <div className="uk-width-medium-1-2">
                <TroubleShootingDetailChartSpace
                    search={search}
                    setSearch={setSearch}
                    onClickEvt={onClickEvt}
                    selectedUser={selectedUser}
                    detailChartData={detailChartData}
                    detailChartLoading={detailChartLoading}
                />
                <div className='uk-grid'>
                    <div className="uk-width-medium-1-2 troubleShootingStateChart">
                        <TroubleShootingStateChartSpace
                            stateChartData={stateChartData}
                            stateChartLoading={stateChartLoading}
                        />
                    </div>
                    <div className="uk-width-medium-1-2 troubleShootingChangeChart">
                        <TroubleShootingChangeChartSpace
                            changeChartData={changeChartData}
                            changeChartLoading={changeChartLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TroubleShooting;