import React from 'react';

// 지역별 제조사별 효율
const RgnMakrEfficiency = () => {
  return (
    <div>
      <img src = "/images/ai/rgnMakrEfficiency.png" alt='충남 지역별 현황'/>
    </div>
  );
};

export default RgnMakrEfficiency;