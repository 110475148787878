import axios from 'axios';

const codeComUrl = '/api/v2.2/code';
const codeConnUrl = '/code';



// 그룹코드 입력에 의한 코드 리스트 목록 조회 
// 만약 그룹코드 15를 입력 하면 에너지원 코드 리스트를 출력
export const getCodeList = async(grpCodeNo) => {
    // try {
        const result = await axios.get(codeComUrl + '/list', {
            params: {
                grpCodeNo : grpCodeNo
            }
        });
        return result;
    // } catch(err) {
    //     throw new Error(`code Service getListByGrpCode error: ${err}`);
    // }
};


/*
 * ### 코드 관리 - 코드 불러오기
 */
export const getCodes = async(conn, callback) => {
        const res = await conn.get(codeConnUrl + '/list');
        return res;
};

/*
 * ### 코드 관리 - 코드 추가, 수정
 */
export const setCodeList = async(conn, codes) => {
  const config = {
      headers: {'Content-Type': 'application/json'}
  };
  await conn.post(codeConnUrl,JSON.stringify(codes), config);
}