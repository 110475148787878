import styles from "./daedeokIntro.module.css";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const DaedeokIntroPage = () => {
    document.body.style.paddingTop = '0px'

    const [btnHover, setBtnHover] = useState(false);

    const history = useHistory();

    const redirectBtnHandler = () => {
        history.push("/Main")
    }

    return (
        <div className={styles.imgContainer}>
            <img
                className={styles.promotionImg}
                src="/images/localareaImg/30230/promotion/promotionImg.jpg"
            />
            
            <div className={styles.footer}>
                <button
                    className={styles.redirectBtn}
                    type="button"
                    onClick={redirectBtnHandler}
                >
                    <img
                        className={styles.redirectBtn__image}
                        src={btnHover ? "/images/localareaImg/30230/promotion/redirectBtn_hover.gif" : "/images/localareaImg/30230/promotion/redirectBtn.gif"}
                        onMouseOver={() => setBtnHover(true)}
                        onMouseOut={() => setBtnHover(false)}
                        onClick={redirectBtnHandler}
                    />
                </button>

                <img className={styles.redirectBtn__doggy} src="/images/localareaImg/30230/promotion/clickMe.png" />
            </div>
            
        </div>
    )
}
export default DaedeokIntroPage;