import React from "react";

const GeoChunCheonThree = props => {
  const { mapClickEvt, mapOutEvt, mapOverEvt } = props;
  return (
    <svg
      version="1.1"
      id="레이어_1"
      x="0px"
      y="0px"
      width="1030px"
      height="950px"
      viewBox="0 0 1030 950"
      enable-background="new 0 0 1030 950">
      <path
        fill="#808080"
        d="M899.198,389.992v-16.44v-3.342v-17.125c0-0.442-0.174-0.866-0.487-1.18l-18.761-18.794
 c-0.313-0.314-0.739-0.49-1.184-0.49h-17.107v-73.469c0-0.72-0.46-1.358-1.142-1.585l-56.088-18.692l-74.816-56.083l-18.691-18.75
 c0,0-0.001,0-0.002-0.001c-0.105-0.106-0.227-0.189-0.352-0.262c-0.03-0.017-0.062-0.034-0.091-0.049
 c-0.131-0.065-0.267-0.117-0.408-0.146c-0.006-0.001-0.012,0-0.02-0.001c-0.102-0.019-0.205-0.031-0.311-0.031h-93.414
 l-55.941-37.244c-0.059-0.039-0.117-0.072-0.18-0.103l-37.557-18.762c-0.23-0.116-0.487-0.176-0.746-0.176H409.206
 c-0.922,0-1.671,0.748-1.671,1.671v17.091h-16.45l-18.272-18.271c-0.352-0.354-0.846-0.534-1.346-0.481
 c-0.498,0.049-0.947,0.319-1.225,0.735l-37.584,56.285c-0.184,0.275-0.282,0.599-0.282,0.928v18.145l-18.273,18.308
 c-0.313,0.313-0.487,0.738-0.487,1.181v54.628h-17.123c-0.676,0-1.285,0.406-1.545,1.031c-0.258,0.624-0.115,1.342,0.361,1.82
 l18.307,18.327v53.961h-35.886c-0.011,0-0.02,0.004-0.029,0.004c-0.173,0.003-0.34,0.032-0.497,0.086
 c-0.037,0.012-0.071,0.033-0.109,0.049c-0.082,0.034-0.165,0.062-0.241,0.11c-0.034,0.021-0.061,0.052-0.091,0.075
 c-0.046,0.032-0.087,0.066-0.127,0.102c-0.09,0.08-0.171,0.169-0.243,0.268c-0.028,0.038-0.06,0.074-0.083,0.113
 c-0.086,0.141-0.157,0.295-0.197,0.463c-0.002,0.006-0.006,0.01-0.007,0.017c-0.005,0.025,0,0.049-0.004,0.074
 c-0.02,0.102-0.041,0.204-0.041,0.31v17.123l0.979,3.342l17.784,17.815v16.443h-17.092c-0.922,0-1.671,0.747-1.671,1.671v18.064
 l-18.288,18.31l-18.81,18.761c-0.314,0.313-0.49,0.74-0.49,1.183v17.108h-17.097c-0.922,0-1.671,0.746-1.671,1.671v17.104
 l0.49,21.667c0.312,0.313,0.739,0.489,1.181,0.489h16.063l-17.557,35.102c-0.115,0.23-0.176,0.489-0.176,0.746v18.093
 l-18.328,18.343c-0.312,0.313-0.487,0.737-0.487,1.184v17.091h-34.198v-17.091c0-0.923-0.748-1.671-1.671-1.671
 c-0.922,0-1.671,0.748-1.671,1.671v54.646h-17.124c-0.922,0-1.671,0.749-1.671,1.671v18.756c0,0.923,0.749,1.671,1.671,1.671h17.124
 v34.26h-17.124c-0.674,0-1.285,0.407-1.543,1.031c-0.259,0.624-0.115,1.342,0.362,1.821l18.305,18.288v16.415v3.342v17.096
 c0,0.922,0.749,1.671,1.671,1.671h17.107v35.941c0,0.922,0.749,1.671,1.671,1.671h18.762c0.443,0,0.869-0.177,1.183-0.49
 l15.963-15.995v14.816c0,0.922,0.75,1.671,1.67,1.671h18.768c0.922,0,1.671-0.749,1.671-1.671v-33.585l15.469,15.425v18.16v18.382
 l-18.633,37.17c-0.116,0.233-0.177,0.489-0.177,0.749v18.769c0,0.923,0.749,1.671,1.671,1.671h35.918v17.196
 c0,0.922,0.749,1.671,1.671,1.671h35.884v17.096c0,0.923,0.749,1.671,1.671,1.671h18.762c0.442,0,0.869-0.177,1.182-0.492
 l37.467-37.514l55.792-37.15h18.256c0.633,0,1.212-0.357,1.495-0.923l37.12-74.234h17.779c0.923,0,1.671-0.748,1.671-1.671v-14.732
 l15.909,15.912c0.001,0.002,0.002,0.002,0.003,0.007c0.002,0.002,0.005,0.004,0.005,0.004c0.002,0.002,0.004,0.004,0.006,0.007
 c0.044,0.041,0.095,0.073,0.143,0.115c0.033,0.023,0.063,0.054,0.097,0.078c0.053,0.032,0.109,0.059,0.164,0.089
 c0.04,0.02,0.075,0.044,0.117,0.062c0.098,0.041,0.201,0.073,0.307,0.096c0.029,0.006,0.063,0.006,0.094,0.011
 c0.076,0.011,0.154,0.023,0.234,0.023l0,0c0.005,0,0.008,0,0.011,0h18.766h0.013c0.003,0,0.007,0,0.011,0l0,0
 c0.002,0,0.007,0,0.009,0c0.019,0,0.037,0,0.057-0.002c0.001,0,0.001,0,0.001,0c0.013,0,0.022-0.007,0.036-0.009
 c0.195-0.015,0.385-0.057,0.557-0.13c0.017-0.007,0.03-0.018,0.047-0.026c0.163-0.078,0.316-0.179,0.446-0.303
 c0.001-0.002,0.002-0.002,0.003-0.004c0.001,0,0.002-0.003,0.003-0.003c0,0,0.001,0,0.001-0.002c0.003,0,0.003-0.002,0.004-0.002
 c0.004-0.004,0.002-0.004,0.004-0.004c0-0.003,0.001-0.003,0.004-0.005l17.927-17.916l36.462,18.229
 c0.231,0.117,0.488,0.176,0.747,0.176h17.15v17.088l0,0c0,0.002,0,0.002,0,0.004v0.002c0,0.003,0,0.005,0,0.007s0,0.002,0,0.004
 c0,0.003,0,0.003,0,0.007c0,0.026,0.015,0.048,0.016,0.072c0.01,0.163,0.042,0.321,0.095,0.47c0.008,0.02,0.005,0.041,0.013,0.061
 c0.014,0.035,0.046,0.062,0.062,0.094c0.062,0.129,0.141,0.248,0.236,0.361c0.024,0.028,0.033,0.063,0.061,0.089l0,0
 c0.002,0.002,0.004,0.002,0.004,0.005c0.002,0,0.005,0.002,0.005,0.004c0,0,0,0.002,0.002,0.002c0,0.003,0.004,0.005,0.004,0.005
 l0.003,0.002l0.002,0.002c0.002,0.003,0.004,0.005,0.004,0.005l0,0c0.016,0.015,0.034,0.02,0.048,0.032
 c0.146,0.131,0.303,0.241,0.475,0.313c0.002,0,0.003,0,0.004,0.002c0,0,0,0,0.002,0h0.001c0.017,0.007,0.034,0.009,0.051,0.016
 c0.086,0.032,0.171,0.061,0.258,0.078c0.048,0.011,0.098,0.011,0.147,0.018c0.057,0.004,0.111,0.015,0.167,0.018h0.001
 c0.001,0,0.002,0,0.004,0h0.002c0.001,0,0.002,0,0.003,0c0.004,0,0.005,0,0.005,0c0.003,0,0.004,0,0.006,0h0.001
 c0.001,0,0.003,0,0.005,0c0,0,0.002,0,0.004,0s0.003,0,0.006,0c0.006,0,0.012-0.005,0.02-0.005c0.209-0.006,0.411-0.046,0.595-0.119
 c0,0,0.001,0,0.002,0c0.002-0.005,0.004-0.007,0.006-0.007c0.187-0.076,0.361-0.191,0.515-0.34c0.004-0.004,0.01-0.006,0.014-0.008
 c0,0,0,0,0-0.003c0.002,0,0.002-0.002,0.005-0.004c0,0,0.004-0.002,0.004-0.005h0.002l18.271-18.269h18.119
 c0.441,0,0.867-0.176,1.182-0.489l37.541-37.545c0.313-0.311,0.489-0.737,0.489-1.181V674.03h35.91c0.442,0,0.868-0.176,1.183-0.489
 l18.762-18.77c0.313-0.313,0.49-0.737,0.49-1.181c0-0.444-0.177-0.868-0.49-1.182l-17.582-17.574l17.095-17.129h36.863
 c0.921,0,1.67-0.748,1.67-1.671V598.95h17.143c0.922,0,1.67-0.748,1.67-1.671v-17.092h35.869c0.442,0,0.867-0.176,1.182-0.491
 l18.806-18.832c0.312-0.313,0.488-0.738,0.488-1.18v-17.113h17.107c0.922,0,1.671-0.748,1.671-1.671v-35.848h35.901
 c0.579,0,1.117-0.301,1.422-0.792c0.303-0.493,0.331-1.107,0.071-1.625l-17.602-35.175h16.107c0.922,0,1.671-0.749,1.671-1.671
 v-56.341c0-0.443-0.177-0.87-0.492-1.183L899.198,389.992z"
      />
      <g>
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_A"
          id="dong_4211010700"
          fill="#E1E1E1"
          d="M541.218,358.181c0,0.676-0.406,1.285-1.033,1.544c-0.624,0.257-1.343,0.114-1.821-0.363l-16.865-16.891
     l-17.466,52.42l36.259,18.114c0.534,0.265,0.886,0.796,0.922,1.39c0.036,0.593-0.245,1.165-0.741,1.495l-51.727,34.495h13.244
     c0.923,0,1.671,0.747,1.671,1.671v17.143h35.191l37.052-37.103c0.313-0.313,0.738-0.488,1.183-0.488h17.151v-17.107
     c0-0.923,0.749-1.671,1.671-1.671h17.091v-17.092c0-0.443,0.175-0.868,0.488-1.182l18.811-18.8
     c0.313-0.313,0.739-0.489,1.183-0.489h17.093v-92.241c0-0.923,0.748-1.671,1.671-1.671h18.087l36.602-36.581v-18.125
     c0-0.924,0.748-1.671,1.671-1.671h17.091V207.87c0-0.924,0.749-1.671,1.671-1.671h51.354l-71.117-53.31
     c-0.064-0.048-0.125-0.101-0.182-0.157l-15.922-15.972v14.792c0,0.632-0.357,1.211-0.923,1.495l-37.086,18.548l-18.338,36.669
     v93.523c0,0.923-0.749,1.671-1.671,1.671h-17.14v17.123c0,0.923-0.75,1.671-1.671,1.671h-18.069l-18.325,18.298
     c-0.082,0.083-0.172,0.157-0.27,0.221c-0.003,0.002-0.004,0.003-0.006,0.003c-0.001,0.001-0.003,0.002-0.006,0.003
     c-0.02,0.012-0.04,0.018-0.059,0.029c-0.018,0.009-0.027,0.023-0.043,0.033c-0.003,0.001-0.004,0.002-0.006,0.003
     c-0.002,0-0.004,0.001-0.006,0.002c-0.067,0.035-0.135,0.067-0.205,0.093c-0.002,0-0.001,0.001-0.006,0.001
     c-0.005,0.002-0.012,0.001-0.019,0.003c-0.01,0.003-0.016,0.009-0.024,0.013c-0.129,0.044-0.262,0.054-0.395,0.065
     c-0.044,0.003-0.088,0.023-0.131,0.024c-0.005,0-0.007,0-0.011,0c-0.001,0-0.004,0-0.005,0c-0.012,0-0.023,0-0.036,0
     c-0.011,0-0.021-0.004-0.032-0.006c-0.153-0.003-0.283-0.027-0.409-0.069c-0.011-0.002-0.021-0.001-0.03-0.004
     c-0.002-0.001-0.002,0-0.004-0.001c-0.006-0.001-0.012-0.005-0.018-0.008c-0.011-0.003-0.021-0.006-0.032-0.009
     c-0.001,0-0.003,0-0.004-0.001c-0.105-0.038-0.208-0.085-0.303-0.145c-0.002,0-0.004-0.002-0.005-0.003
     c-0.002,0,0.006-0.004-0.007-0.003c-0.005-0.003-0.009-0.008-0.014-0.012s-0.01-0.004-0.015-0.006l0,0
     c-0.004-0.001-0.007-0.003-0.011-0.005c0,0,0,0-0.001-0.001c-0.086-0.056-0.162-0.124-0.236-0.194
     c-0.011-0.011-0.023-0.019-0.035-0.029l-18.751-18.786c-0.313-0.313-0.488-0.737-0.488-1.181v-11.711l-15.438,30.9V358.181
     L541.218,358.181z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_B"
          id="dong_4211010900"
          fill="#E1E1E1"
          d="M483.231,566.436c-0.922,0-1.671-0.748-1.671-1.671v-17.092h-35.901c-0.444,0-0.868-0.176-1.183-0.487
     l-17.459-17.473l-55.195,73.61c-0.027,0.034-0.064,0.065-0.092,0.098c-0.03,0.035-0.061,0.069-0.095,0.103
     c-0.028,0.023-0.05,0.059-0.081,0.08c-0.061,0.053-0.133,0.09-0.201,0.133c-0.04,0.024-0.077,0.057-0.121,0.078l-35.448,17.721
     l17.121,17.129c0.313,0.313,0.49,0.737,0.49,1.181v18.141l18.272,18.288c0.312,0.313,0.488,0.737,0.488,1.182v14.748l15.47-15.442
     V658.68c0-0.923,0.749-1.671,1.671-1.671h17.108v-17.161c0-0.923,0.749-1.671,1.671-1.671h35.912v-17.099
     c0-0.922,0.749-1.671,1.671-1.671h35.901v-17.085c0-0.923,0.749-1.671,1.671-1.671h35.868v-17.129v-17.087H483.231L483.231,566.436
     z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_C"
          id="dong_4211011000"
          fill="#E1E1E1"
          d="M653.916,528.841h-20.435c-0.923,0-1.671-0.748-1.671-1.671v-17.111h-15.47v17.111
     c0,0.923-0.748,1.671-1.671,1.671h-54.674v17.161c0,0.923-0.749,1.671-1.671,1.671h-17.107v17.092c0,0.923-0.748,1.671-1.671,1.671
     h-17.106v17.087v18.108l18.288,18.264c0.234,0.235,0.394,0.535,0.457,0.855c0.021,0.108,0.032,0.215,0.032,0.328v17.099h17.107
     c0.922,0,1.671,0.748,1.671,1.671v18.832c0,0.439-0.177,0.868-0.489,1.181l-37.066,37.066v14.041l15.925-15.914
     c0.511-0.512,1.287-0.636,1.929-0.313l37.188,18.588h18.427c0.923,0,1.671,0.749,1.671,1.671v14.728l15.909-15.906
     c0.313-0.313,0.739-0.489,1.182-0.489h18.12l18.273-18.277c0.002-0.002,0.005-0.002,0.007-0.005l18.281-18.283v-36.918
     c0-0.923,0.749-1.671,1.671-1.671h36.89l17.092-17.099l-17.581-17.574c-0.652-0.65-0.652-1.708-0.001-2.36l18.274-18.313v-16.394
     h-17.092c-0.923,0-1.671-0.748-1.671-1.671v-17.092H689.83c-0.923,0-1.671-0.748-1.671-1.671v-17.161h-17.136
     c-0.442,0-0.868-0.176-1.183-0.489l-15.925-15.926V528.841L653.916,528.841z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_D"
          id="dong_4211012200"
          fill="#E1E1E1"
          d="M635.153,414.499v74.441l18.272,18.264l18.288,18.295h18.114c0.923,0,1.671,0.751,1.671,1.671v17.161
     h17.105c0.922,0,1.67,0.749,1.67,1.671v17.092h17.092c0.923,0,1.671,0.751,1.671,1.671v17.087h34.214v-17.087
     c0-0.92,0.75-1.671,1.671-1.671h17.143v-17.092c0-0.922,0.749-1.671,1.671-1.671h36.846l17.826-17.851v-18.093
     c0-0.922,0.75-1.671,1.671-1.671h17.108v-35.85c0-0.92,0.748-1.671,1.671-1.671h34.868l-17.602-35.172
     c-0.259-0.52-0.231-1.135,0.072-1.626c0.304-0.493,0.844-0.793,1.421-0.793h17.141v-53.976l-18.32-18.266
     c-0.313-0.313-0.489-0.74-0.489-1.183v-36.909l-17.784-17.814h-18.086c-0.922,0-1.671-0.747-1.671-1.671v-73.936l-54.943-18.312
     h-54.427v17.107c0,0.923-0.748,1.671-1.671,1.671h-17.092v17.146c0,0.442-0.176,0.868-0.486,1.181l-34.726,34.709l-2.369,3.342
     h-3.342h-15.435v92.241c0,0.923-0.749,1.671-1.671,1.671h-18.072l-17.832,17.821v16.4h17.141
     C634.403,412.828,635.153,413.575,635.153,414.499z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211010800"
          fill="#E1E1E1"
          d="M484.41,488.449c0.354,0.354,0.532,0.848,0.483,1.348c-0.05,0.498-0.32,0.949-0.737,1.228l-54.697,36.409
     l16.891,16.9h36.879c0.922,0,1.671,0.748,1.671,1.67v17.092h35.868h17.105v-17.092c0-0.922,0.749-1.67,1.671-1.67h17.107v-17.162
     c0-0.92,0.749-1.671,1.671-1.671h54.675v-17.11c0-0.923,0.748-1.671,1.671-1.671h18.811c0.922,0,1.671,0.748,1.671,1.671v17.11
     h15.423v-16.415l-18.274-18.272c-0.313-0.313-0.489-0.737-0.489-1.182V416.17h-17.14h-17.091v17.105
     c0,0.923-0.748,1.671-1.671,1.671H577.78l-37.051,37.104c-0.313,0.313-0.738,0.487-1.184,0.487H501.99h-33.53L484.41,488.449z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211010500"
          fill="#E1E1E1"
          d="M425.687,525.967c0.048-0.043,0.096-0.085,0.146-0.122c0.026-0.021,0.049-0.045,0.078-0.067l54.694-36.406
     l-17.366-17.32h-0.001l-0.002-0.002l-17.58-17.631l-17.149,17.141v18.073c0,0.442-0.175,0.866-0.488,1.182l-93.875,93.891
     l-15.967,15.947h14.786h17.09h3.342h16.255l55.85-74.482c0.021-0.025,0.046-0.045,0.069-0.071
     C425.605,526.052,425.643,526.009,425.687,525.967z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211011100"
          fill="#E1E1E1"
          d="M484.902,603.993v17.085c0,0.923-0.749,1.671-1.671,1.671h-35.901v17.099c0,0.922-0.748,1.671-1.67,1.671
     h-35.912v17.161c0,0.922-0.748,1.671-1.671,1.671h-17.108v17.104c0,0.443-0.175,0.868-0.489,1.184l-18.812,18.775
     c-0.008,0.009-0.014,0.013-0.022,0.02c-0.022,0.021-0.051,0.041-0.074,0.061c-0.05,0.046-0.099,0.092-0.156,0.129
     c-0.002,0-0.004,0.002-0.007,0.004c-0.017,0.011-0.034,0.018-0.05,0.026c-0.016,0.011-0.034,0.02-0.051,0.026
     c-0.013,0.006-0.026,0.015-0.04,0.021c-0.045,0.024-0.089,0.055-0.139,0.072c0,0-0.002,0.002-0.004,0.002
     c0,0.002-0.002,0.002-0.004,0.002c-0.022,0.013-0.048,0.018-0.07,0.023c-0.019,0.007-0.037,0.012-0.057,0.018
     c-0.06,0.02-0.118,0.042-0.179,0.055c-0.001,0-0.002,0-0.003,0c-0.107,0.021-0.215,0.032-0.324,0.032h-54.674v17.097
     c0,0.922-0.749,1.671-1.671,1.671h-18.073l-17.814,17.778l-3.342,3.355l-15.906,15.984c-0.313,0.315-0.74,0.493-1.184,0.493
     h-17.108v17.107c0,0.259-0.061,0.516-0.177,0.748l-18.633,37.171v16.699h35.918c0.921,0,1.671,0.749,1.671,1.671v17.196h35.884
     c0.923,0,1.671,0.748,1.671,1.671v17.096h16.398l37.094-37.142c0.078-0.078,0.164-0.15,0.256-0.211l56.352-37.523
     c0.274-0.183,0.596-0.28,0.926-0.28h17.729l32.516-65.024l-11.481,11.479c-0.32,0.319-0.748,0.489-1.183,0.489
     c-0.214,0-0.433-0.041-0.639-0.128c-0.625-0.26-1.032-0.868-1.032-1.543v-18.758c0-0.923,0.75-1.671,1.671-1.671h18.763h17.141
     v-17.097c0-0.676,0.407-1.285,1.031-1.544c0.624-0.259,1.342-0.118,1.822,0.363l18.271,18.274h16.415v-17.094
     c0-0.443,0.176-0.867,0.489-1.181l37.066-37.066v-16.469h-17.107c-0.922,0-1.671-0.749-1.671-1.671v-18.075l-17.8-17.779H484.902z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211011500"
          fill="#E1E1E1"
          d="M293.707,508.388c0-0.922,0.749-1.671,1.671-1.671h17.091v-17.085c0-0.923,0.748-1.671,1.671-1.671h17.151
     v-35.907c0-0.923,0.748-1.671,1.671-1.671h17.09v-17.107c0-0.58,0.3-1.117,0.792-1.42c0.493-0.305,1.107-0.334,1.626-0.073
     l37.573,18.777c0.565,0.283,0.922,0.861,0.922,1.495v17.143h15.437v-17.143c0-0.924,0.749-1.671,1.671-1.671h6.406v-15.436h-6.406
     c-0.922,0-1.671-0.749-1.671-1.671v-18.777v-17.092v-3.342v-15.459h-17.108c-0.921,0-1.671-0.748-1.671-1.671v-37.561v-17.124
     h-92.246c-0.26,0-0.516-0.061-0.747-0.177l-35.137-17.583v15.397l18.273,18.306c0.312,0.313,0.487,0.738,0.487,1.181v18.805
     c0,0.923-0.749,1.671-1.671,1.671h-17.091v17.085c0,0.442-0.176,0.868-0.488,1.181l-18.778,18.801l-18.322,18.274v18.085
     c0,0.921-0.749,1.671-1.671,1.671h-17.096v17.105c0,0.922-0.749,1.671-1.67,1.671h-14.784l15.477,15.474h18.075
     c0.026,0,0.051,0.006,0.076,0.009c0.042,0.002,0.08,0.006,0.12,0.011c0.085,0.011,0.167,0.026,0.25,0.05
     c0.036,0.008,0.072,0.02,0.108,0.032c0.086,0.031,0.168,0.067,0.248,0.113c0.028,0.015,0.058,0.028,0.084,0.043
     c0.105,0.067,0.206,0.142,0.295,0.231l18.32,18.272h18.088c0.922,0,1.671,0.748,1.671,1.671v17.085h17.09
     c0.922,0,1.671,0.749,1.671,1.671v17.113h15.452L293.707,508.388L293.707,508.388z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211010600"
          fill="#E1E1E1"
          d="M350.54,659.86c-0.311-0.313-0.487-0.739-0.487-1.181v-18.141l-18.272-18.279
     c-0.379-0.379-0.554-0.918-0.468-1.449c0.086-0.533,0.422-0.985,0.903-1.227l31.19-15.591h-29.752L315.32,622.26
     c-0.313,0.313-0.738,0.489-1.18,0.489h-17.091v17.099c0,0.796-0.561,1.481-1.342,1.638l-93.588,18.767l-55.337,36.877v16.202
     h17.106c0.922,0,1.671,0.748,1.671,1.671v35.94h16.397l18.328-18.364c0.479-0.479,1.196-0.622,1.822-0.363
     c0.625,0.262,1.032,0.868,1.032,1.547v17.184h15.425v-35.941v-0.002c0-0.198,0.044-0.386,0.11-0.566
     c0.02-0.052,0.048-0.1,0.072-0.151c0.065-0.136,0.147-0.26,0.247-0.372c0.024-0.028,0.033-0.063,0.06-0.09
     c0.017-0.018,0.036-0.023,0.055-0.037c0.143-0.133,0.3-0.241,0.47-0.315c0.007-0.002,0.011-0.009,0.019-0.011
     c0.002-0.002,0.004-0.002,0.006-0.002c0.01-0.005,0.02-0.005,0.027-0.007c0.178-0.071,0.362-0.1,0.546-0.106
     c0.054-0.002,0.104,0.005,0.159,0.007c0.146,0.009,0.286,0.037,0.424,0.08c0.054,0.02,0.106,0.033,0.161,0.059
     c0.18,0.079,0.351,0.183,0.499,0.329l18.81,18.758c0.159,0.159,0.282,0.346,0.366,0.551c0,0,0.001,0.002,0.002,0.002
     c0.001,0.004,0.001,0.009,0.002,0.013c0.071,0.181,0.111,0.374,0.115,0.57c0,0.009,0.004,0.016,0.004,0.024c0,0,0,0,0,0.002l0,0
     c0,0.002,0,0.004,0,0.004s0,0.002,0,0.007c0,0.002,0,0.002,0,0.004c0,0.003,0,0.005,0,0.007c0,0,0,0,0,0.002v17.181h16.414
     l18.268-18.357c0-0.002,0.002-0.002,0.003-0.004c0.002-0.003,0.004-0.005,0.006-0.007c0.001,0,0.002-0.002,0.003-0.002
     l18.789-18.752c0.313-0.313,0.738-0.489,1.18-0.489h17.091v-17.097c0-0.922,0.749-1.671,1.671-1.671h54.674v-16.414L350.54,659.86z
     "
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211010200"
          fill="#E1E1E1"
          d="M409.746,396.01v16.818h18.761v18.776h17.15c0.921,0,1.671,0.749,1.671,1.671v18.086l16.188,16.234
     l18.092-72.266c0.029-0.118,0.072-0.232,0.126-0.341l18.708-37.451l7.463-22.411l11.192-33.614v-17.458l-36.262-18.124H465.11
     l-15.419,15.42h14.728c0.921,0,1.671,0.747,1.671,1.671v18.762c0,0.923-0.75,1.671-1.671,1.671h-17.09v17.123
     c0,0.923-0.75,1.671-1.671,1.671h-17.15h-3.342h-15.419v17.124c0,0.923-0.75,1.671-1.671,1.671h-17.108v34.219h17.108
     c0.921,0,1.671,0.748,1.671,1.671v18.526c0.014,0.089,0.021,0.181,0.021,0.274C409.766,395.828,409.76,395.921,409.746,396.01z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211010100"
          fill="#E1E1E1"
          d="M483.977,262.769l37.539,18.761c0.565,0.283,0.924,0.861,0.924,1.495v17.091h35.886
     c0.126,0,0.25,0.017,0.367,0.044c0.006,0.001,0.011,0,0.018,0.001c0.004,0.001,0.011,0.005,0.017,0.006
     c0.031,0.008,0.061,0.021,0.092,0.03c0.091,0.028,0.177,0.063,0.259,0.105c0.036,0.018,0.071,0.035,0.104,0.056
     c0.005,0.003,0.011,0.005,0.016,0.008c0.014,0.008,0.027,0.014,0.042,0.023c0.066,0.044,0.128,0.096,0.188,0.148
     c0.02,0.018,0.042,0.032,0.062,0.051c0.041,0.039,0.074,0.083,0.111,0.125c0.02,0.023,0.037,0.045,0.057,0.069
     c0.025,0.035,0.052,0.068,0.076,0.104c0.004,0.006,0.011,0.013,0.016,0.019c0.011,0.019,0.015,0.041,0.024,0.058
     c0.044,0.076,0.083,0.154,0.114,0.236c0.008,0.02,0.015,0.039,0.021,0.059c0.012,0.031,0.019,0.062,0.025,0.092
     c0.024,0.092,0.04,0.184,0.049,0.28c0.004,0.037,0.01,0.072,0.011,0.11c0,0.016,0.005,0.029,0.005,0.044v18.103l15.42,15.448
     l3.341,0.009l15.972-15.948c0.313-0.313,0.737-0.488,1.182-0.488h17.09v-17.124c0-0.923,0.749-1.671,1.671-1.671h17.14v-92.246
     c0-0.26,0.061-0.515,0.177-0.748l18.763-37.521c0.164-0.323,0.426-0.585,0.748-0.748l36.66-18.335v-16.132h-91.854l-37.23,18.658
     c-0.231,0.117-0.488,0.177-0.749,0.177h-36.864l-18.288,18.308c-0.313,0.313-0.738,0.49-1.182,0.49h-35.902v17.088
     c0,0.924-0.749,1.671-1.671,1.671h-17.091v17.091c0,0.923-0.748,1.671-1.67,1.671h-17.15v17.107c0,0.923-0.749,1.671-1.671,1.671
     h-14.735l15.429,15.476h36.889c0.922,0,1.671,0.747,1.671,1.671v17.128h17.141C483.49,262.592,483.744,262.652,483.977,262.769z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211011400"
          fill="#E1E1E1"
          d="M406.404,320.581c0-0.924,0.749-1.671,1.671-1.671h35.912v-17.124c0-0.923,0.749-1.671,1.671-1.671h17.091
     v-15.42h-17.091c-0.675,0-1.285-0.406-1.543-1.031c-0.26-0.625-0.116-1.343,0.361-1.821l18.272-18.272v-16.437h-35.912
     c-0.442,0-0.87-0.177-1.184-0.491l-18.762-18.818c-0.477-0.478-0.619-1.196-0.359-1.82c0.258-0.624,0.868-1.03,1.543-1.03h17.09
     v-17.107c0-0.923,0.749-1.67,1.671-1.67h17.15v-17.091c0-0.923,0.749-1.671,1.671-1.671h17.091v-17.088
     c0-0.923,0.748-1.671,1.67-1.671h36.88l18.288-18.307c0.313-0.314,0.737-0.49,1.183-0.49h37.161l34.656-17.37l-53.881-35.871
     l-37.108-18.539H390.966v17.09c0,0.924-0.749,1.671-1.671,1.671h-18.812c-0.441,0-0.867-0.176-1.181-0.489L351.981,79.05
     l-36.173,54.169v18.33c0,0.442-0.175,0.867-0.487,1.181l-18.273,18.308v55.607c0,0.924-0.749,1.671-1.671,1.671h-14.764
     l15.945,15.966c0.313,0.313,0.489,0.739,0.489,1.181v56.323c0,0.923-0.749,1.671-1.671,1.671h-30.482l30.877,15.453h93.523
     c0.922,0,1.671,0.747,1.671,1.671v17.124h15.437v-17.124H406.404z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211011600"
          fill="#E1E1E1"
          d="M256.639,544.825l18.274-18.345v-16.422h-17.09c-0.923,0-1.671-0.748-1.671-1.671v-17.085h-17.108
     c-0.443,0-0.867-0.174-1.181-0.489l-13.196-13.16l-3.975-3.969l-17.556,35.098v18.388c0,0.441-0.175,0.868-0.487,1.184
     l-18.328,18.343v18.07c0,0.923-0.749,1.671-1.671,1.671h-35.869v35.885c0,0.923-0.748,1.671-1.671,1.671h-17.124v15.414h17.124
     c0.923,0,1.671,0.749,1.671,1.671v37.602c0,0.922-0.748,1.671-1.671,1.671h-14.758l15.94,15.923
     c0.313,0.313,0.488,0.74,0.488,1.184v15.658l53.76-35.824c0.18-0.121,0.385-0.206,0.597-0.248l92.57-18.564v-17.004l-37.38-74.724
     C256.006,546.106,256.133,545.334,256.639,544.825z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211010400"
          fill="#E1E1E1"
          d="M503.575,358.708c-0.023,0.075-0.054,0.148-0.089,0.218l-18.681,37.393l-18.247,72.879h33.761v-15.474
     h-17.09c-0.737,0-1.386-0.483-1.6-1.187c-0.213-0.705,0.06-1.465,0.672-1.875l53.92-35.958l-34.979-17.475
     c-0.744-0.372-1.102-1.233-0.839-2.022l18.778-56.361c0.183-0.551,0.637-0.966,1.202-1.099c0.562-0.131,1.155,0.037,1.565,0.446
     l15.923,15.948v-14.767c0-0.26,0.061-0.515,0.176-0.747l17.571-35.172h-33.649l-10.225,30.702L503.575,358.708z"
        />
        <path
          onClick={mapClickEvt}
          onMouseOver={mapOverEvt}
          onMouseOut={mapOutEvt}
          className="land land_E"
          id="dong_4211010300"
          fill="#E1E1E1"
          d="M443.443,435.294h-18.277V416.17h-15.697v16.106h7.797v21.449h-7.52v17.145
     c0,0.922-0.749,1.671-1.671,1.671h-18.778c-0.922,0-1.671-0.749-1.671-1.671v-17.783l-34.231-17.107v16.076
     c0,0.922-0.749,1.67-1.671,1.67h-17.09v35.906c0,0.923-0.749,1.671-1.671,1.671h-17.151v17.085c0,0.923-0.749,1.671-1.671,1.671
     H297.05v15.442v3.342h-3.342H277.28l-17.424,17.488l36.555,73.076h17.039l15.47-15.414H314.14c-0.675,0-1.285-0.407-1.544-1.031
     s-0.115-1.345,0.363-1.821l37.583-37.555l74.622-74.646v-18.073l18.277-19.283L443.443,435.294L443.443,435.294z"
        />
      </g>
      <g>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 351.6487 179.4453)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          소양로2가
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 527.7649 228.0195)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          봉의동
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 555.6233 386.3281)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          옥천동
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 733.7844 414.9961)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          교동
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 293.7078 403.77)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          소양로3가
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 427.4407 366.7651)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          요선동
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 318.177 525.501)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          낙원동
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 234.6335 682.5625)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          중앙로3가
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 318.177 757.626)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          약사동
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 412.1003 603.9932)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          죽림동
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 360.8601 563.0957)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="13.367">
          중앙로2가
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 592.5891 619.4072)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          운교동
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 519.0989 514.0811)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          조양동
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 478.9983 423.8535)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="13.367">
          중앙로1가
        </text>
        <text
          class="geoText"
          transform="matrix(1 0 0 1 187.7966 611.3164)"
          fill="#050505"
          font-family="'NotoSansKR-Light-KSCpc-EUC-H'"
          font-size="17.8226">
          소양로4가
        </text>
      </g>
    </svg>
  );
};
export default GeoChunCheonThree;
