import React, { useState, useEffect } from "react";
import kendo from "@progress/kendo-ui";
import { useHistory } from "react-router-dom";
import { Person, Lock } from "@material-ui/icons";
// import sha512 from "js-sha512";
import "./login.css";
// import "./login.dk.css";

import { login, setToken, isLogin, setNewPassword, getUserTypeCode } from "../../service/authService";
import { getMessage } from "../../com/messageUtil";
import { Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

import { useLocation } from "react-router";

// import Modal from './Modal';
// import { CookiesProvider } from 'react-cookie';

function Login({ conn, setUpdateLoginStatus, getRoleMenu , roleMenu, setSelectMenu, setContentLayoutMargin , isMobile }) {
  const history = useHistory();
  // document.body.style.backgroundColor = "#01579B";
  document.body.style.backgroundColor = "#f5f5f5";
  if (isLogin()) {
    // if(isManager()){
    //   history.push("/bizStat/convergenceInfo");
    // } else {
      // history.push("/monitor/installerMeain");
      history.push("/monitor/areaEnergy");
    // }
  } else {
    document.body.style.background = "linear-gradient(#1F93C8, #1054B1)";
  }

  let [id, setId] = useState("");
  let [password, setPassword] = useState("");

  // 새로운 패스워드 입력
  const [newUser, setNewUser] = useState({
    isNewUser: false,
    newPassword: "",
    newPasswordComfirm: "",
  });

  const inputChage = event => {
    if (event.target.name === "id") {
      setId(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  useEffect(() => {}, []);

  const appKeyPress = e => {
    if (e.key === "Enter") {
      clickLogin();
    }
  };

  // 라우터 location 변수 설정
  const location = useLocation();
  // 로그인 체크 
  const clickLogin = () => {
    if (id === "") {
      kendo.alert("ID를 입력 해주세요.");
      return;
    }
    if (password === "") {
      kendo.alert("비밀번호를 입력 해주세요.");
      return;
    }
    // const encryptPw = sha512.sha512(password);
    
    login(conn, id, password)
      .then(result => {
        setToken(result.token);
        if(getUserTypeCode() === 2001) {
          location.pathname = "/monitor/installerMeain";
          getRoleMenu("/monitor/installerMeain");
          setUpdateLoginStatus(true);
          history.push("/monitor/installerMeain");
        } else if(getUserTypeCode() === 2002) {
          location.pathname = "/monitor/munic";
          getRoleMenu("/monitor/munic");
          setUpdateLoginStatus(true);
          history.push("/monitor/munic");
        } else {
          location.pathname = "/monitor/areaEnergy";
          getRoleMenu("/monitor/areaEnergy");
          setUpdateLoginStatus(true);
          history.push("/monitor/areaEnergy");
        }
        // if(isManager()){
        //   location.pathname = "/bizStat/convergenceInfo";
        //   getRoleMenu("/bizStat/convergenceInfo");
        //   setUpdateLoginStatus(true);
        //   history.push("/bizStat/convergenceInfo");
        // } else {
          // 여기서 로케이션을 설정 안해주면 로그인 시 '/' 로 이동 되서 드롭다운에서는 지역별현황으로 설정이 되고 화면은 수용가현황으로 된다.. 
          // location.pathname = "/monitor/installerMeain";
          // location.pathname = "/monitor/areaEnergy";
          // getRoleMenu("/monitor/installerMeain");
          // getRoleMenu("/monitor/areaEnergy");
          // setUpdateLoginStatus(true);
          // 메뉴 설정
          // setSelectMenu("/monitor/installerMeain", roleMenu);
          // setContentLayoutMargin("/monitor/installerMeain");
          // history.push("/monitor/installerMeain");
          // history.push("/monitor/areaEnergy");
        // }
      })
      .catch(e => {
        if (e.errCode === "L008") {
          setNewUser(newUser => {
            const item = { ...newUser };
            item.isNewUser = true;
            return item;
          });
          kendo.alert("새로운 유저라서 새로운 비밀번호를 등록 해야합니다.");
          return;
        } else if (e.errCode === "L009") {
          kendo.alert(
            "해당 사용자는 새로운 유저가 아니라서 비밀번호가 설정되어 있지 않습니다. 관리자에 문의 하여 해당 사용자의 비밀번호를 설정 요청해주세요.",
          );
          return;
        }
        alert(getMessage(e.errCode));
      });
  };

  // 새로운 패스워드 입력
  const onChageNewPassword = e => {
    setNewUser(newUser => {
      const item = { ...newUser };
      item[e.target.name] = e.target.value;
      return item;
    });
  };

  // 새로운 패스워드 저장
  const saveNewUserPassword = () => {
    // 패스워드 입력 안하면 Validation
    if (!newUser.newPassword) {
      kendo.alert("새로운 비밀번호를 입력 해주세요.");
      return;
    }
    if (!newUser.newPasswordComfirm) {
      kendo.alert("새로운 확인 비밀번호를 입력 해주세요.");
      return;
    }

    // 패스워드 입력 시 확인 로직 (정규식)
    if (newUser.newPassword) {
      if (newUser.newPassword !== newUser.newPasswordComfirm) {
        kendo.alert("입력하신 변경된 비밀번호와 확인 비밀번호와 맞지 않습니다. 다시 확인하시고 똑같이 입력 해주세요.");
        return;
      } else {
        // 비밀번호 정규식 (영어 대문자, 소문자 포함 숫자 포함 특수문자 포함 8자리 이상)
        const regPassword = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        // const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^*+=-]).{9,15}$/;
        if (!regPassword.test(newUser.newPassword)) {
          kendo.alert("유효하지 않은 비밀번호입니다! 영어 ,숫자,특수문자 포함한 8자리 이상 작성해주세요.");
          return;
        }
      }
    }

    // 새로운 계정의 패스워드 등록
    setNewPassword(conn, id, newUser.newPassword)
      .then(result => {
        kendo.alert("새로운 패스워드 등록이 완료 되었습니다. 바로 로그인 해주세요.");
        onClose();
      })
      .catch(e => {});
  };

  // 새로운 패스워드 윈도우 창 닫기
  const onClose = () => {
    setNewUser(newUser => {
      const item = { ...newUser };
      item.isNewUser = false;
      item.newPassword = "";
      item.newPasswordComfirm = "";
      return item;
    });
  };

  return (
    <> 
      {/* { !isMobile && 
        <CookiesProvider>
          <Modal/>
        </CookiesProvider>
      } */}
      <img alt="로그인" className="login" src="/images/login.jpg"></img>
      <div id="login" className="login_page_wrapper">
        <div className="md-card">
          <div className="md-card-content">
            <div className="login_heading">
              <div className="login_title">
                <img alt="로그인" src="/images/login_logo.svg" />
              </div>
            </div>
            <div>
              <div className="uk-form-row">
                <span className="md-input-icon">
                  <Person className="material-icons" />
                </span>
                <input className="md-input" type="text" name="id" placeholder="ID" onChange={inputChage} />
              </div>
              <div className="uk-form-row">
                <span className="md-input-icon">
                  <Lock className="material-icons" />
                </span>
                <input
                  className="md-input"
                  type="password"
                  name="password"
                  placeholder="PW"
                  onChange={inputChage}
                  onKeyPress={appKeyPress}
                />
              </div>
              <div className="uk-form-row-btn">
                <button className="md-btn-large BtnBg BtnText" onClick={clickLogin}>
                  <span className="loginBtnText">로 그 인</span>
                </button>
              </div>
            </div>
            <div className="login_logo">
              <table>
                <tbody>
                  <tr>
                    <td style={{textAlign:"center"}}>
                      Copyright 2022. SR ENERGY Inc.&nbsp;&nbsp;
                      all rights reserved.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="errorTxt" className="errorTxt">
              등록되지 않은 아이디이거나, 아이디 또는 비밀번호가 일치하지 않습니다.
            </div>
          </div>
        </div>
      </div>
      {newUser.isNewUser && (
        <Window title={"새로운 패스워드 등록"} onClose={onClose} initialHeight={250} initialWidth={340} modal={true}>
          <div id="newLoginPassword">
            <div className="uk-form-row">
              <span className="md-input-icon">
                <Lock className="material-icons" />
              </span>
              <input
                className="md-input"
                name="newPassword"
                style={{ width: "300px" }}
                type="password"
                placeholder="새로운 비밀번호"
                onChange={onChageNewPassword}
                value={newUser.newPassword}
              />
            </div>
            <div className="uk-form-row">
              <span className="md-input-icon">
                <Lock className="material-icons" />
              </span>
              <input
                className="md-input"
                name="newPasswordComfirm"
                style={{ width: "300px" }}
                type="password"
                placeholder="비밀번호 확인"
                onChange={onChageNewPassword}
                value={newUser.newPasswordComfirm}
              />
            </div>
          </div>
          <div className="newUserInputPasswordArea">
            <Button onClick={saveNewUserPassword}>저장</Button>
            <Button onClick={onClose}>취소</Button>
          </div>
        </Window>
      )}
    </>
  );
}
export default Login;
