import { DaedeokUnderBar } from "./daedeokUnderBar"

// Mui
import CountUp from "react-countup"
import { CustomToolTip } from "./customToolTip"

export const DaedeokGeneInfo = (props) => {
	const {styles, dataSource, today} = props;

	return (
		<div className={styles.geneInfo}>
			<div className={styles.geneInfoHeader}>
				<div>{today}기준</div>
			</div>
			<div className={styles.geneInfoBody}>
			<div className={styles.leftBox}>
				<div className={styles.leftBoxHeader}>
					<img src="/images/localareaImg/30230/icon/icon_02.png" width={"40px"}/>

					<div>
						총 설비용량(개수)

						<CustomToolTip
							width={'25px'}
							height={'25px'}
							content={
									<div className={styles.geneInfoToolTip}>
										<div className={styles.geneInfoToolTipBorder}>
											<table className={styles.syncTable}>
												<tbody>
													<tr>
														<td><img src="/images/localareaImg/30230/icon/plug.png"/></td>
														<td>연동:</td>
														<td><span>{dataSource.totalEnergyData.syncMeainCnt}</span>개</td>
													</tr>

													<tr>
														<td><img src="/images/localareaImg/30230/icon/unplug.png"/></td>
														<td>미연동:</td>
														<td><span>{dataSource.totalEnergyData.asyncMeainCnt}</span>개</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
							}
						/>
						
						<DaedeokUnderBar width={"120px"} />
					</div>

					
				</div>
				
				<div className={styles.leftBoxBody}>
					<div className={styles.leftCountBox}>

						<div>
							{
								dataSource.totalEnergyData.totalInstCapa < 10000
								? (<>
									<span><CountUp end={dataSource.totalEnergyData.totalInstCapa} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></span>
									<span>kW</span>
								</>)
								: (<>
									<span><CountUp end={dataSource.totalEnergyData.totalInstCapa / 1000} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></span>
									<span>MW</span>
								</>)
							}
						</div>
						
						<div>
							<span><CountUp end={dataSource.totalEnergyData.totalMeainCnt} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></span>
							<span>개</span>
						</div>

					</div>
				</div>

			</div>
				

				<div className={styles.leftBox}>
					<div className={styles.leftBoxHeader}>
					<img src="/images/localareaImg/30230/icon/icon_01.png" width={"40px"}/>
					
						<div>
							금일발전량


						<CustomToolTip
							width={'25px'}
							height={'25px'}
							content={
								<div className={styles.geneInfoToolTip}>
									<div className={styles.geneInfoToolTipBorder}>

										<table className={styles.syncTable}>
											<tbody>
												
												<tr>
													<td><img src="/images/localareaImg/30230/icon/plug.png"/></td>
													<td>연동:</td>
													<td>
														{
															dataSource.totalEnergyData.syncPower < 10000
															? (<><span>{dataSource.totalEnergyData.syncPower}</span>kWh</>)
															: (<><span>{dataSource.totalEnergyData.syncPower / 1000}</span>MWh</>)
														}
													</td>
												</tr>
												<tr>
													<td><img src="/images/localareaImg/30230/icon/unplug.png"/></td>
													<td>미연동:</td>
													<td>
														{
															dataSource.totalEnergyData.asyncPower < 10000
															? (<><span>{dataSource.totalEnergyData.asyncPower}</span>kWh</>)
															: (<><span>{dataSource.totalEnergyData.asyncPower / 1000}</span>MWh</>)
														}
													</td>
												</tr>
											
											</tbody>
										</table>

									</div>
								</div>
							}
						/>

							<DaedeokUnderBar width={"120px"} />
						</div>
						
						
						
					</div>

					<div className={styles.leftBoxBody}>
						<div className={styles.leftBoxEnergy}>

							<div>
								{
									dataSource.totalEnergyData.totalPower < 10000
									? (
										<>
											<span><CountUp end={dataSource.totalEnergyData.totalPower} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></span>
											<span>kWh</span>
										</>
									)
									: (
										<>
											<span><CountUp end={dataSource.totalEnergyData.totalPower / 1000} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></span>
											<span>MWh</span>
										</>
									)
								}
								
							</div>
							
							<div>
								<span>{dataSource.totalEnergyData.totalPowerDiff}</span>
								<span>kWh</span>
							</div>
							
						</div>
					</div>

				</div>

				<div className={styles.leftBox}>
					<div className={styles.leftBoxHeader}>
					<img src="/images/localareaImg/30230/icon/icon_03.png" width={"40px"}/>
					
						<div>
							열에너지

						<CustomToolTip
							width={'25px'}
							height={'25px'}
							content={
								<div className={styles.geneInfoToolTip}>
									<div className={styles.geneInfoToolTipBorder}>

										<table className={styles.syncTable}>
											<tbody>
											<tr>
												<td><img src="/images/localareaImg/30230/icon/plug.png"/></td>
												<td>연동:</td>
												<td>
													{
														dataSource.totalEnergyData.syncUse < 10000
														? (<><span>{dataSource.totalEnergyData.syncUse}</span>kWh</>)
														: (<><span>{dataSource.totalEnergyData.syncUse / 1000}</span>MWh</>)
													}
												</td>
											</tr>
											<tr>
												<td><img src="/images/localareaImg/30230/icon/unplug.png"/></td>
												<td>미연동:</td>
												<td>
													{
														dataSource.totalEnergyData.asyncUse < 10000
														? (<><span>{dataSource.totalEnergyData.asyncUse}</span>kWh</>)
														: (<><span>{dataSource.totalEnergyData.asyncUse / 1000}</span>MWh</>)
													}
													
												</td>
											</tr>
											</tbody>
										</table>

									</div>
								</div>
							}
						/>

							<DaedeokUnderBar width={"120px"} />
						</div>
						
						
					</div>
					<div className={styles.leftBoxBody}>
						
						<div className={styles.leftBoxEnergy}>
							<div>
								{
									dataSource.totalEnergyData.totalUse < 10000
									? (
										<>
											<span><CountUp end={dataSource.totalEnergyData.totalUse} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></span>
											<span>kWh</span>
										</>
									)
									: (
										<>
											<span><CountUp end={dataSource.totalEnergyData.totalUse / 1000} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></span>
											<span>MWh</span>
										</>
									)
								}
								
							</div>
							
							<div>
								<span>{dataSource.totalEnergyData.totalUseDiff}</span>
								<span>kWh</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}