import kendo from "@progress/kendo-ui";
/**
 * 현재 시간을 포멧해주는 공용 함수
 *
 * parseDate(formatter = 'yyyy-mm-dd', formatDate);
 *
 * 1. formatter (필수 [포멧방식 참조])
 * 2. formatDate ('1970-01-01')
 *
 * 포멧방식
 * 1. 'yyyy-mm-dd'
 * 2. 'yyyy-mm-dd hh:mm:ss'
 * 3. 'yyyymmdd'
 * 4. 'yyyymmddhhmmss'
 * 5. 'LocalDateTime'
 */
export const parseDate = (formatter = "yyyy-mm-dd", formatDate) => {
  const currentDate = formatDate ? new Date(formatDate) : new Date();

  const currentYear = currentDate.getFullYear();

  const currentMonth =
    1 + currentDate.getMonth() >= 10
      ? 1 + currentDate.getMonth()
      : `0${1 + currentDate.getMonth()}`;

  const currentDay =
    currentDate.getDate() >= 10
      ? currentDate.getDate()
      : `0${currentDate.getDate()}`;

  const currentHour =
    currentDate.getHours() >= 10
      ? currentDate.getHours()
      : `0${currentDate.getHours()}`;

  const currentMinute =
    currentDate.getMinutes() >= 10
      ? currentDate.getMinutes()
      : `0${currentDate.getMinutes()}`;

  const currentSecond =
    currentDate.getSeconds() >= 10
      ? currentDate.getSeconds()
      : `0${currentDate.getSeconds()}`;

  switch (formatter) {
    case "yyyy-mm-dd":
      return `${currentYear}-${currentMonth}-${currentDay}`;

    case "yyyy-mm-dd hh:mm:ss":
      return `${currentYear}-${currentMonth}-${currentDay} ${currentHour}:${currentMinute}:${currentSecond}`;

    case `yyyymmdd`:
      return `${currentYear}${currentMonth}${currentDay}`;

    case "yyyymmddhhmmss":
      return `${currentYear}${currentMonth}${currentDay}${currentHour}${currentMinute}${currentSecond}`;

    case "LocalDateTime":
      return `${currentYear}-${currentMonth}-${currentDay}T${currentHour}:${currentMinute}:${currentSecond}`;

    default:
      return `'${formatter}' 방식은 존재하지 않습니다.`;
  }
};

/**
 * ## [현재 날짜 기준] 
 * ### 어제, 1주일, 1달, 1년을 빼주는 공용함수
 * 
 * ### 매개변수
 * - 'D' (Day)
 * - 'W' (Week)
 * - 'M' (Month)
 * - 'Y' (Year)
 */
export const subDate = (subDate) => {

  const currentDate = new Date();

  switch (subDate) {
    case "D":
      currentDate.setDate(currentDate.getDate() - 1);
      break;

    case "W":
      currentDate.setDate(currentDate.getDate() - 7);
      break;

    case "M":
      currentDate.setMonth(currentDate.getMonth() - 1);
      break;

    case "Y":
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      break;

    default:
      return `'${subDate}' 방식은 존재하지 않습니다.`;
  }

  const currentYear = currentDate.getFullYear();

  const currentMonth =
    1 + currentDate.getMonth() >= 10
      ? 1 + currentDate.getMonth()
      : `0${1 + currentDate.getMonth()}`;

  const currentDay =
    currentDate.getDate() >= 10
      ? currentDate.getDate()
      : `0${currentDate.getDate()}`;

    return `${currentYear}-${currentMonth}-${currentDay}`;
};



/**
 * 입력한 날짜의 첫주 일
 * @param d date값
 * @returns {Date} 이번주 월요일 
 */
 export const getMonday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday(0=Sunday, 1=Monday)
  return new Date(d.setDate(diff));
}

/**
 * 입력한 날짜의 이번달 1일 
 * @param d date 값
 * @returns {Date} 이번달 1일
 */
 export const getFirstDayOfMonth = (d) => {
	d = new Date(d);
	return new Date(d.getFullYear(), d.getMonth(), 1);
}

/**
 * 입력한 날짜의 올해 1일
 * @param d date값
 * @returns {Date} 올해 1일
 */
 export const getFirstDayOfYear = (d) => {
	d = new Date(d);
	return new Date(d.getFullYear(), 0, 1);
}


/**
 * 입력한 날짜의 이번주 일요일 
 * @param d date값
 * @returns {Date} 이번주 일요일 
 */
 export const getSunday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -7:0); // adjust when day is sunday(0=Sunday, 1=Monday)
  return new Date(d.setDate(diff));
}

/**
 * 입력한 날짜의 지난주 일요일 
 * @param d date값
 * @returns {Date} 지난주 일요일 
 */
 export const getLastWeekSunday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -7:0) - 7; // adjust when day is sunday(0=Sunday, 1=Monday)
  return new Date(d.setDate(diff));
}

/**
 * 입력한 날짜의 지난주 토요일
 * @param d date값
 * @returns {Date} 지난주 토요일
 */
 export const getLastWeekSaturday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -7:0) - 1; // adjust when day is sunday(0=Sunday, 1=Monday)
  return new Date(d.setDate(diff));
}

/**
 * 입력한 날짜의 작년 12월 31일
 * @param d date값
 * @returns {Date} 작년 12월 31일
 */
 export const getFirstDayOfLastYear = (d) => {
	d = new Date(d);
	return new Date(d.getFullYear() - 1, 0, 1);
}

/**
 * 입력한 날짜의 작년 12월 31일
 * @param d date값
 * @returns {Date} 작년 12월 31일
 */
 export const getLastDayOfLastYear = (d) => {
	d = new Date(d);
	return new Date(d.getFullYear(), 0, 0);
}

/**
 * 입력한 날짜의 일주일전
 * @param d date값
 * @returns {Date} 입력한 날짜의 일주일전
 */
 export const getLastWeek = (d) => {
  d = new Date(d);  
  let diff = d.getDate() - 6; // adjust when day is sunday(0=Sunday, 1=Monday)
  return new Date(d.setDate(diff));
}

/**
 * 입력한 날짜의 해당 월의 마지막 일
 * @param d date값
 * @returns {Date} 입력한 날짜의 해당 월의 마지막 일
 */
export const getLastMonthDay = (d) => {
  d = new Date(d);
  const year = kendo.toString(d , 'yyyy');
  const month = kendo.toString(d , 'MM');
  const lastDay = new Date(year , month , 0);
  return lastDay;
}