import kendo from "@progress/kendo-ui";

const troubleShootingUrl = "ai/troubleShooting";

/**
 * 그리드 데이터를 가져오는 서비스
 * @param {*} conn 
 * @param {*} search 
 * @returns 
 */
export const getTroubleShootingGrid = async (conn, search) => {

  const response = await conn.get(troubleShootingUrl + "/grid", {
    params: {
      fixbylawBundCode: search.fixbylawBundCode,
      rgnCode: search.rgnCode,
      instlTerm: search.instlTerm
    },
  })
  
  return response;
}

/**
 * 대상자별 기상요소 및 발전효율 상세 조회 차트 데이터를 가져오는 서비스
 * @param {*} conn 
 * @param {*} search 
 * @param {*} selectedUser 
 * @returns 
 */
export const getTroubleShootingDetailChart = async (conn, search, selectedUser) => {
  const firstDayOfMonth = kendo.toString(new Date(search.day.getFullYear(), search.day.getMonth(), 1), 'yyyy-MM-dd');
  const nextMonth = search.day.getMonth() + 1;
  const lastDayOfMonth = kendo.toString(new Date(search.day.getFullYear(), nextMonth, 1), 'yyyy-MM-dd');

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth()는 0부터 시작하므로 +1 해줌

  let dayOfMonth;

  if (search.day.getFullYear() === currentYear && search.day.getMonth() + 1 === currentMonth) {
    dayOfMonth = currentDate.getDate();
  } else {
    const nextMonth = search.day.getMonth() + 1 + 1;
    const lastDayOfMonth = new Date(search.day.getFullYear(), nextMonth, 0).getDate();
    dayOfMonth = lastDayOfMonth;
  }


  const response = await conn.get(troubleShootingUrl + "/detailChart", {
    params: {
      consmId: selectedUser,
      startDay: firstDayOfMonth,
      endDay: lastDayOfMonth,
      fixbylawBundCode: search.fixbylawBundCode,
    },
  })

  const dataState = {
    xAxis: [],
    precQty : [],
    soraQty : [],
    elecEffi : [],
    dayEffi : [],
  }
  
  response.map((item) => {
    dataState.xAxis.push(item.gathDate);
    dataState.precQty.push(item.totalPrecQty);
    dataState.soraQty.push(item.totalSolaQty);
    dataState.elecEffi.push(item.dayEffi);
    dataState.dayEffi.push(item.rgnTotalDayEffi);
  })

  return dataState;
}

/**
 * 진단 상태 현황 차트의 데이터를 가져오는 서비스
 * @param {*} conn 
 * @param {*} search 
 * @returns 
 */
export const getTroubleShootingStateChart = async (conn, search) => {

  const response = await conn.get(troubleShootingUrl + "/stateChart", {
    params: {
      fixbylawBundCode: search.fixbylawBundCode,
      rgnCode: search.rgnCode,
      instlTerm: search.instlTerm
    },
  })

  const order = ['우수', '양호', '보통', '미흡', '취약']

  const data = order.map((name) => {
    const item = response.find((item) => item.name === name);
    let color;
    switch (name) {
      case '우수':
        color = "#237CCE";
        break;
      case '양호':
        color = "#46BD80";
        break;
      case '보통':
        color = "#E2D43B";
        break;
      case '미흡':
        color = "#E4AE2A";
        break;
      case '취약':
        color = "#E8574C";
        break;
    }
    if(item){
      return {
        name: item.name,
        y: item.y,
        color: color,
        visible: true,
      }
    }
    else {
      return {
        name: name,
        y: 0,
        color: color,
        visible: true,
      }
    }
  })

  return data;
}

/**
 * 수용가별 발전효율 변동 현황 차트의 데이터를 가져오는 서비스
 * @param {*} conn 
 * @param {*} search 
 * @returns 
 */
export const getTroubleShootingChangeChart = async (conn, search) => {

  const response = await conn.get(troubleShootingUrl + "/changeChart", {
    params: {
      fixbylawBundCode: search.fixbylawBundCode,
      rgnCode: search.rgnCode,
      instlTerm: search.instlTerm
    },
  })

  const dataState = {
      xAxis: [],
      yData: [],
  }

  response.map((item) => {
      if(item.name.includes('-')){
        dataState.xAxis.push(item.name.replace(/-(\d+)%/, '+$1%'));
      }
      else{
        dataState.xAxis.push(item.name);
      }
      dataState.yData.push(item.count);
  })
  
  return dataState;
}