// 설치 유형 15001

import InstTypeChart from './inst_type_chart';
import InstTypeTimeChart from './inst_type_time_chart';
import React, {useState, useEffect , useCallback} from 'react';
import {getInstTypeDataList} from '../../../../service/stats/type/ensoDataService';

const InstType = ({conn , search, setLoading}) => {

    // 설치 유형 데이터 소스
    const [instTypeDs , setInstTypeDs] = useState({
        instType : [],
        instTypeTime : []
    });

    // 데이터 소스 가져오기
  const getResult = useCallback((getData) => {
      setLoading(false)
        setInstTypeDs({
            instType : getData.solaInstTypeDtoList,
            instTypeTime : getData.solaInstTypeTimeDataDtoList
        });
        // eslint-disable-next-line
    } , [search]);

    // rejection 함수
    const getRejection = (error) => {
      setLoading(false)
    }

    useEffect(() => {
        getInstTypeDataList( conn  , getResult , getRejection , search );
        // eslint-disable-next-line
    },[getResult]);

    return(
        <div className="uk-grid">
            <div className="uk-width-medium-1-1">
                <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                        설치유형별 분석
                    </h3>                    
                </div>
            </div>
           
            <div className="uk-width-medium-1-2">
                <div className="md-card">
                    <div className="md-card-content">
                        <InstTypeChart instTypeDs = {instTypeDs.instType}></InstTypeChart>
                    </div>
                </div>
            </div>
            <div className="uk-width-medium-1-2">
                <div className="md-card">
                    <div className="md-card-content">
                        <InstTypeTimeChart instTypeDs = {instTypeDs.instTypeTime}></InstTypeTimeChart>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstType;