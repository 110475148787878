import GathStatusDayChart from "./gathStatusDayChart";
import GathStatusDayGrid from "./gathStatusDayGrid";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./gathStatus.module.css";
import { BeatLoader } from "react-spinners";

const GathStatusDay = ({ getDayDs, dayDataSource,  setDayDataSource,  search }) => {
  // 오늘날
  const today = new Date();
  
  // 엑셀 다운로드 이벤트
  const onClickEvt = e => {
    dayDataSource.excelData.save();
    
  };

  // 엑셀 변수 상태 설정
  const setExcelData = _excelData => {
    setDayDataSource(dataSource => {
      const item = { ...dataSource };
      item.excelData = _excelData;
      return item;
    });
  };

  return (
    <div
      className="uk-width-medium-1-2"
      style={
        search.dayLoading
          ? {
              opacity: 0.6,
              pointerEvents: "none",
            }
          : null
      }>
      <div className="md-card">
        <div className="md-card-content">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">일간 시간별 수집률</h3>
            <div className={styles.gathStatusToolbar}>
              날짜검색&nbsp;&nbsp;
              <DatePickerKo
                id="start"
                name="start"
                className="datePicker"
                defaultValue={today}
                format="yyyy-MM-dd "
                setDtm={v => {
                  search.day = v;
                  // getDs();
                  getDayDs();
                }}
                max={today}
                view="month"
              />
              <Button className="gathStatusExcelBtn" icon="excel" onClick={onClickEvt}>
                엑셀저장
              </Button>
            </div>
          </div>
          <GathStatusDayChart dataSource={dayDataSource.chart} x_categories={dayDataSource.x_categories} />
          <GathStatusDayGrid dataSource={dayDataSource.grid} ensoTypeCodeNo={search.ensoTypeCodeNo} setExcelData={setExcelData} />
        </div>
        {search.dayLoading && (
          <p
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </div>
  );
};

export default GathStatusDay;
