import React, { useEffect, useState } from 'react';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 지열 정보
*/
const BizManagementMeainVelo = ({
  Input, convMngtStyles, DropDownList, meainInfo , initialDs,  setMeainInfo
  , DatePickerKo, DateTimePickerKo, onchageDate, onChangeDateTiem, dateFormat
  , onchageInput, onchageDropdown
  , searchKeyWord ,makrMeainModlfilterChange
}) => {

  // 제조사 / 모델 데이터 소스
  const makrList = initialDs.makrList.length > 0 ? initialDs.makrList : [];
  const meainModelList = initialDs.meainModelList.length > 0 ? initialDs.meainModelList : []; 

  // 발전기 제조사 목록
  const modulMakrDs = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30007 && c.makrTerm.includes(searchKeyWord.moduleMakrKey) ) : [];
  // 발전기 타입 목록
  const modulTypeDs = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15004 && c.makrSeriNo === meainInfo.generMakrNo && c.meainTypeTerm.includes(searchKeyWord.moduleModlKey) ) : [];
  // 인버터 제조사 목록
  const invtMakrDs = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30008 && c.makrTerm.includes(searchKeyWord.invtMakrKey) ) : [];
  // 인버터 타입 목록
  const invtTypeDs = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15004 && c.makrSeriNo === meainInfo.invtMakrNo && c.meainTypeTerm.includes(searchKeyWord.invtModlKey) ) : [];

  const [modulMakr , setModulMakr] = useState( meainInfo.generMakrNo ? { makrSeriNo : meainInfo.generMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  const [modulType , setModulType] = useState( meainInfo.generSeriNo ? { meainTypeSeriNo : meainInfo.generSeriNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [invtMakr , setInvtMakr] = useState( meainInfo.invtMakrNo ? { makrSeriNo : meainInfo.invtMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : "-" } );
  const [invtType , setInvtType] = useState( meainInfo.invtTypeNo ? { meainTypeSeriNo : meainInfo.invtTypeNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );

  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.generMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        modulMakrDs.forEach(element => {
            if( element.makrSeriNo === meainInfo.generMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setModulMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setModulMakr({ makrSeriNo: '' , makrTerm: '-' });
      }
      if( meainInfo.invtMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        invtMakrDs.forEach(element => {
            if( element.makrSeriNo === meainInfo.invtMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setInvtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setInvtMakr({ makrSeriNo: '', makrTerm: '-' });
      }
    }else{
      setModulMakr({ makrSeriNo: '' , makrTerm: '-' });
      setInvtMakr({ makrSeriNo: '', makrTerm: '-' });
    }
  }

  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.generSeriNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        modulTypeDs.forEach(element => {
            if( element.meainTypeSeriNo === meainInfo.generSeriNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setModulType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setModulType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( meainInfo.meainTypeSeriNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        invtTypeDs.forEach(element => {
            if( element.meainTypeSeriNo === meainInfo.invtTypeNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setInvtType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
    }else{
      setModulType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
    }
  }

  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    makrConverter();
    modlConverter();
    /* eslint-disable-next-line */
  }, [meainInfo]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  console.log(" meainInfo =  ", meainInfo);

  return (
    <>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;설치일자
        </th>
        <td>
          <DateTimePickerKo 
                  id = "meainInstDtm" name = "meainInstDtm" className={convMngtStyles.inputTerm}
                  value = {dateFormat(meainInfo.meainInstDtm)} format = {"yyyy-MM-dd HH:mm"}
                  formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                  setDtm = {(v) => { onChangeDateTiem(v , "meainInstDtm"); }} 
                  // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;A/S 만료일자
        </th>
        <td>
          <DatePickerKo 
                id = "asExpiDtm" name = "asExpiDtm" className={convMngtStyles.inputTerm}
                value = {dateFormat(meainInfo.asExpiDtm)} 
                format="yyyy-MM-dd"
                formatPlaceholder={{ year: '년', month: '월', day: '일' }}
                setDtm = {(v) => { onchageDate(v , "asExpiDtm"); }}
                // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;발전기 용량</th>
        <td>
            <Input 
                id = "generCapa" name = "generCapa"  type = "text" className={convMngtStyles.inputTerm}
                value = { meainInfo.generCapa ? meainInfo.generCapa : "" } onChange = {onchageInput} 
                // required = {true} validationMessage = {inputValidMsg.normalText}
                />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;발전기허브높이(m)</th>
        <td>
            <Input 
                id = "generHubHeit" name = "generHubHeit"  type = "text" className={convMngtStyles.inputTerm}
                value = { meainInfo.generHubHeit ? meainInfo.generHubHeit : "" } onChange = {onchageInput} 
                // required = {true} validationMessage = {inputValidMsg.normalText}
                />
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;발전기 제조사
        </th>
        <td>
          <DropDownList className={convMngtStyles.inputTerm}  id = "generMakrNo" name = "generMakrNo" 
                data = {modulMakrDs} 
                popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
                defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
                value = { modulMakr } 
                filterable={true}
                onFilterChange={makrMeainModlfilterChange}
                // required = {true} validationMessage = {inputValidMsg.normalText}
                dataItemKey="makrSeriNo" textField = "makrTerm" 
                onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; setModulMakr(e.target.value); setMeainInfo(obj); } } 
                // valid = {selectMeain.invtMakrNo !== "" && selectMeain.invtMakrNo !== null && typeof selectMeain.invtMakrNo !== "undefined" }
                // itemRender = {itemRender}
            />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;발전기 모델명
        </th>
        <td>
          <DropDownList className={convMngtStyles.inputTerm} id = "generSeriNo" name = "generSeriNo" 
              data = {modulTypeDs}  disabled = { meainInfo.generMakrNo ? false : true }
              popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
              value = { modulType } 
              filterable={true}
              onFilterChange={makrMeainModlfilterChange}
              // required = {true} validationMessage = {inputValidMsg.normalText}
              dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
              onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo }; setModulType(e.target.value); setMeainInfo(obj); } } 
              // valid = {selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined" }
              // itemRender = {itemRender}
            />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;발전기 설치대수</th>
        <td>
            <Input 
                id = "generInstQuan" name = "generInstQuan"  type = "text" className={convMngtStyles.inputTerm}
                value = { meainInfo.generInstQuan ? meainInfo.generInstQuan : "" } onChange = {onchageInput} 
                // required = {true} validationMessage = {inputValidMsg.normalText}
                />
        </td>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;인버터 용량(kW)</th>
        <td>
            <Input 
                id = "invtCapa" name = "invtCapa"  type = "text" className={convMngtStyles.inputTerm}
                value = { meainInfo.invtCapa ? meainInfo.invtCapa : "" } onChange = {onchageInput} 
                // required = {true} validationMessage = {inputValidMsg.normalText}
                />
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;인버터 제조사
        </th>
        <td>
          <DropDownList className={convMngtStyles.inputTerm}  id = "invtMakrNo" name = "invtMakrNo" 
              data = {invtMakrDs} 
              defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
              popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
              value = { invtMakr } 
              filterable={true}
              onFilterChange={makrMeainModlfilterChange}
              // required = {true} validationMessage = {inputValidMsg.normalText}
              dataItemKey="makrSeriNo" textField = "makrTerm" 
              onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; setInvtMakr(e.target.value); setMeainInfo(obj); } } 
              // valid = {selectMeain.invtMakrNo !== "" && selectMeain.invtMakrNo !== null && typeof selectMeain.invtMakrNo !== "undefined" }
              // itemRender = {itemRender}
          />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;인버터 모델명
        </th>
        <td>
          <DropDownList className={convMngtStyles.inputTerm} id = "invtTypeNo" name = "invtTypeNo" 
            data = {invtTypeDs}  disabled = { meainInfo.invtMakrNo ? false : true }
            popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            value = { invtType } 
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
            onChange = { (e) =>{ 
              const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo , "meainTypeSeriNo" : e.value.meainTypeSeriNo }; 
              setInvtType(e.target.value); setMeainInfo(obj); } 
            } 
            // valid = {selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined" }
            // itemRender = {itemRender}
          />
        </td>
      </tr>
      <tr>
        <th><span className={ convMngtStyles.required }>*</span>&nbsp;시공업체</th>
        <td>
            <Input 
                id = "consEnte" name = "consEnte"  type = "text" className={convMngtStyles.inputTerm}
                value = { meainInfo.consEnte ? meainInfo.consEnte : "" } onChange = {onchageInput} 
                // required = {true} validationMessage = {inputValidMsg.normalText}
                />
        </td>
        <th>시공업체 연락처</th>
        <td>
            <Input 
            id = "consEnteCntct" name = "consEnteCntct"  type = "text" className={convMngtStyles.inputTerm}
            value = { meainInfo.consEnteCntct ? meainInfo.consEnteCntct : "" } onChange = {onchageInput}
            placeholder = "하이픈(-) 없이 입력해주세요."
            />
        </td>
      </tr>
      <tr>
      { meainInfo.moniYn === "Y" ?
          <>
            <th>
              <span className={ convMngtStyles.required }>*</span>&nbsp;설치확인관리번호
            </th>
            <td>
              <Input id = "instCnfrmCode" name = "instCnfrmCode" type="text" 
                    className={convMngtStyles.inputTerm} 
                    value = {meainInfo.instCnfrmCode ? meainInfo.instCnfrmCode : ""}
                    onChange = {onchageInput} />
            </td>
            <th></th>
            <td></td>
          </> :
          <>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
          </> 
        }
      </tr>
    </>
  );
};

export default BizManagementMeainVelo;