import React from "react";

const LocalAreaGeoGradient = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ position: "fixed" }}>
      <linearGradient id="overGra" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#FF9A57" stop-opacity="1" />
        <stop offset="100%" stop-color="#E8613B" stop-opacity="1" />
      </linearGradient>
      <linearGradient id="scale0025" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#FFFFFF"></stop>
        <stop offset="70%" stop-color="#D4D4D4"></stop>
        <stop offset="100%" stop-color="#D4D4D4"></stop>
      </linearGradient>
      <linearGradient id="scale2650" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#C9FFF9"></stop>
        <stop offset="70%" stop-color="#AAD8D3"></stop>
        <stop offset="100%" stop-color="#AAD8D3"></stop>
      </linearGradient>
      <linearGradient id="scale5175" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#00DBE6"></stop>
        <stop offset="70%" stop-color="#00ADB5"></stop>
        <stop offset="100%" stop-color="#00ADB5"></stop>
      </linearGradient>
      <linearGradient id="scale76100" x1="0%" y1="0%" x2="0%" y2="100%" spreadMethod="pad">
        <stop offset="0%" stop-color="#268BB3"></stop>
        <stop offset="70%" stop-color="#1A5F7A"></stop>
        <stop offset="100%" stop-color="#1A5F7A"></stop>
      </linearGradient>
    </svg>
  );
};
export default LocalAreaGeoGradient;
