
import kendo from '@progress/kendo-ui'
import { roundNum } from '../../com/dataUtil';

export const getOperEnergyList = async (conn, selectedArea) => {
  const response = await conn.get('monitor/area/list/area', {
      params: {
        areaType : selectedArea.areaType,
        cityProvCode : selectedArea.cityProvCode,
        rgnCode: selectedArea.rgnCode
      }
  });

  const totalEnergy = {
      dayPower : 0,
      dayPowerCo2 : 0,
      dayUse : 0,
      dayUseCo2 : 0,
      heatDayUse : 0,
      heathDayUse : 0,
      dayCo2 : 0
  };
  if(response)
      response.map(item => {
          totalEnergy.dayPower += item.solaDayPower + item.veloDayPower + item.fuelcellDayPower;
          totalEnergy.dayUse += item.heatDayUse + item.heathDayUse;
          totalEnergy.heatDayUse = item.heatDayUse; 
          totalEnergy.heathDayUse = item.heathDayUse;
          totalEnergy.powerEfic += item.solaPowerEfic + item.heatPowerEfic + item.heatPowerEfic + item.heatPowerEfic + item.heatPowerEfic + item.heatPowerEfic
          
          return item;
      });
      
      totalEnergy.dayPowerCo2 = totalEnergy.dayPower * 0.46553;
      totalEnergy.dayUseCo2 = totalEnergy.dayUse * 0.4653;

      totalEnergy.dayCo2 = totalEnergy.dayPowerCo2 + totalEnergy.dayUseCo2
  
  // console.log("Level2 = ", result);
  
  let chartCount = [];
  let chartInstCapa = [];
  let chartPowerEfic = [];
  let chartCalEfic = [];
  response.forEach(element => {
    let cnt = element.solaCnt + element.heatCnt + element.heathCnt  + element.veloCnt + element.fuelcellCnt + element.essCnt;
    let capa = roundNum((element.solaCapa / 1000), 2) + roundNum((element.heatCapa / 1000), 2) + roundNum((element.heathCapa / 1000), 2)  + roundNum((element.veloCapa / 1000), 2) + roundNum((element.fuelcellCapa / 1000), 2) + roundNum((element.essCapa / 1000), 2);

    let powerEficCnt = 0
    if(element.solaPowerEfic > 0) {
      powerEficCnt =+1
    } 
    if(element.veloPowerEfic > 0) {
      powerEficCnt =+1
    } 
    if(element.fuelcellPowerEfic > 0) {
      powerEficCnt =+1
    } 

    let calEficCnt = 0
    if(element.heatPowerEfic > 0) {
      calEficCnt =+1
    } 
    if(element.heathPowerEfic > 0) {
      calEficCnt =+1
    } 

    let powerEfic = powerEficCnt!== 0 ? (element.solaPowerEfic + element.veloPowerEfic  + element.fuelcellPowerEfic) / powerEficCnt : 0;
    powerEficCnt = 0;
    let calEfic = calEficCnt !== 0 ? (element.heatPowerEfic + element.heathPowerEfic) / calEficCnt : 0;
    calEficCnt = 0;

    // console.log("calEfic",calEfic);
    chartCount.push( { name : element.cityProvTerm , y : cnt } );
    chartInstCapa.push( { name : element.cityProvTerm , y : capa } );
    chartPowerEfic.push( { name : element.cityProvTerm , y : powerEfic !== "NaN" ?parseFloat(powerEfic.toFixed(3)) : 0 } )
    chartCalEfic.push( { name : element.cityProvTerm , y : calEfic !== "NaN" ?parseFloat(calEfic.toFixed(3)) : 0 } )
  });
  
  // 지역운영차트의 개소수에 따른 우선순위 작업
  chartCount.sort( ( after , before) => {
    if( before.y > after.y ){ return 1; }
    if( before.y < after.y ){ return -1; }
    return 0;
  });

  return { areaEnergyList : response, areaEnergyTotal : totalEnergy , chartCount : chartCount , chartInstCapa : chartInstCapa , chartPowerEfic : chartPowerEfic , chartCalEfic : chartCalEfic };
  
};

export const getOperMeainStatCntList = async (conn, selectedArea) => {
  const response = await conn.get('monitor/area/meainStatus', {
      params: {
          areaType : selectedArea.areaType,
          cityProvCode : selectedArea.cityProvCode,
          rgnCode: selectedArea.rgnCode
      }
  });
  // const result = response;
  // console.log("level 1 result = ", result);
  const totalCnt = {
      ensoTypeCode : 15000,
      readyCnt : 0,
      normalCnt : 0,
      unOperCnt : 0,
      waringCnt : 0,
      errorCnt : 0,
      totalCnt : 0,
  };

  if(response)
  response.map(item => {
    totalCnt.readyCnt += item.readyCnt;
    totalCnt.normalCnt += item.normalCnt;
    totalCnt.unOperCnt += item.unOperCnt;
    totalCnt.waringCnt += item.waringCnt;
    totalCnt.errorCnt += item.errorCnt;

    return item;
  }); 

  totalCnt.totalCnt += totalCnt.readyCnt + totalCnt.normalCnt + totalCnt.unOperCnt + totalCnt.waringCnt +  totalCnt.errorCnt
  response.splice(0,0,totalCnt);

  const meatinStatCnt = [
    {
      normalCnt : 0,
      errorCnt : 0,
      waringCnt : 0,
      readyCnt : 0,
      unOperCnt : 0,
      totalCnt : 0 
    },
    {
      normalCnt : 0,
      errorCnt : 0,
      waringCnt : 0,
      readyCnt : 0,
      unOperCnt : 0,
      totalCnt : 0 
    },
    {
      normalCnt : 0,
      errorCnt : 0,
      waringCnt : 0,
      readyCnt : 0,
      unOperCnt : 0,
      totalCnt : 0 
    },
    {
      normalCnt : 0,
      errorCnt : 0,
      waringCnt : 0,
      readyCnt : 0,
      unOperCnt : 0,
      totalCnt : 0 
    },
    {
      normalCnt : 0,
      errorCnt : 0,
      waringCnt : 0,
      readyCnt : 0,
      unOperCnt : 0,
      totalCnt : 0 
    },
    {
      normalCnt : 0,
      errorCnt : 0,
      waringCnt : 0,
      readyCnt : 0,
      unOperCnt : 0,
      totalCnt : 0 
    },
    {
      normalCnt : 0,
      errorCnt : 0,
      waringCnt : 0,
      readyCnt : 0,
      unOperCnt : 0,
      totalCnt : 0 
    }
  ]

  response.forEach(element => {
    if(element.ensoTypeCode === 15000){
      meatinStatCnt[0].readyCnt = element.readyCnt;
      meatinStatCnt[0].normalCnt = element.normalCnt;
      meatinStatCnt[0].unOperCnt = element.unOperCnt;
      meatinStatCnt[0].waringCnt = element.waringCnt;
      meatinStatCnt[0].errorCnt = element.errorCnt;
      meatinStatCnt[0].totalCnt = element.totalCnt;
    } 
    if(element.ensoTypeCode === 15001){
      meatinStatCnt[1].readyCnt = element.readyCnt;
      meatinStatCnt[1].normalCnt = element.normalCnt;
      meatinStatCnt[1].unOperCnt = element.unOperCnt;
      meatinStatCnt[1].waringCnt = element.waringCnt;
      meatinStatCnt[1].errorCnt = element.errorCnt;
    }
    if(element.ensoTypeCode === 15002){
      meatinStatCnt[2].readyCnt = element.readyCnt;
      meatinStatCnt[2].normalCnt = element.normalCnt;
      meatinStatCnt[2].unOperCnt = element.unOperCnt;
      meatinStatCnt[2].waringCnt = element.waringCnt;
      meatinStatCnt[2].errorCnt = element.errorCnt;
    }
    if(element.ensoTypeCode === 15003){
      meatinStatCnt[3].readyCnt = element.readyCnt;
      meatinStatCnt[3].normalCnt = element.normalCnt;
      meatinStatCnt[3].unOperCnt = element.unOperCnt;
      meatinStatCnt[3].waringCnt = element.waringCnt;
      meatinStatCnt[3].errorCnt = element.errorCnt;
    }
    if(element.ensoTypeCode === 15004){
      meatinStatCnt[4].readyCnt = element.readyCnt;
      meatinStatCnt[4].normalCnt = element.normalCnt;
      meatinStatCnt[4].unOperCnt = element.unOperCnt;
      meatinStatCnt[4].waringCnt = element.waringCnt;
      meatinStatCnt[4].errorCnt = element.errorCnt;
    }
    if(element.ensoTypeCode === 15006){
      meatinStatCnt[5].readyCnt = element.readyCnt;
      meatinStatCnt[5].normalCnt = element.normalCnt;
      meatinStatCnt[5].unOperCnt = element.unOperCnt;
      meatinStatCnt[5].waringCnt = element.waringCnt;
      meatinStatCnt[5].errorCnt = element.errorCnt;
    }
    if(element.ensoTypeCode === 15007){
      meatinStatCnt[6].readyCnt = element.readyCnt;
      meatinStatCnt[6].normalCnt = element.normalCnt;
      meatinStatCnt[6].unOperCnt = element.unOperCnt;
      meatinStatCnt[6].waringCnt = element.waringCnt;
      meatinStatCnt[6].errorCnt = element.errorCnt;
    }
  })
  
  return meatinStatCnt;

};


export const getOperNewMeainList = async (conn, selectedArea) => {
  const response = await conn.get("monitor/oper/new", {
    params: {
        areaType : selectedArea.areaType,
        cityProvCode : selectedArea.cityProvCode,
        rgnCode: selectedArea.rgnCode
    }
  });

  return response;
}

export const getOperWarnMeainList = async (conn, selectedArea) => {
  const response = await conn.get("monitor/oper/warn", {
    params: {
        areaType : selectedArea.areaType,
        cityProvCode : selectedArea.cityProvCode,
        rgnCode: selectedArea.rgnCode
    }
  });

  return response;
}

export const getOperPowerWeek = async (conn) => {
  const response = await conn.get("monitor/oper/power");

  let sumCapa = [];
  let sumCnt = [];
  let sumDayPower = [];
  response.forEach(element => {
    const capa = roundNum((element.sumCapa / 1000), 2);
    element.gathDtm = kendo.toString( new Date(element.gathDtm) , 'MM.dd' );
    sumCapa.push( { name : element.gathDtm , y : capa } );
    sumCnt.push( { name : element.gathDtm , y : element.sumCnt } );
    sumDayPower.push( { name : element.gathDtm , y : element.sumDayPower } );
  });

  return { sumCapa : sumCapa, sumCnt : sumCnt , sumDayPower : sumDayPower };
  
}