// 그룹 담당자 목록 조회
export const getGroupMangerList = async(conn) => {
  const response = await conn.get('user/groupManager/list');
  return response;
};

// 설치자(수용가) 목록(페이징) 조회
export const getNoneGroupInstallerPage = async(conn, search) => {
  console.log("searcInstaller",search);
  const response = await conn.get('group/installer/none/page', {
      params: {
          page : (search.skip / search.take) + 1,
          size : search.take,
          groupManagerId : search.groupManagerId,
          installerId : search.installerId,
          userTerm : search.userTerm,
          rgnCode : search.rgnCode,
          address : search.address
      }
  });
  const result = response;
  result.results?.map((result,index) => result.key = index );
  console.log("getInstallerPage", result);
  return result;
};

// 그룹 설치자(수용가) 목록(페이징) 조회
export const getGroupInstallerPage = async(conn, search) => {
  console.log("searcGroupInstaller",search);
  const response = await conn.get('group/installer/page', {
      params: {
          page : (search.skip / search.take) + 1,
          size : search.take,
          groupManagerId : search.groupManagerId,
          installerId : search.installerId,
          userTerm : search.userTerm,
          rgnCode : search.rgnCode,
          address : search.address
      }
  });
  const result = response;
  result.results?.map((result,index) => result.key = index );
  console.log("getGroupInstallerPage", result);
  return result;
};

// 그룹 설치자 추가
export const setGroupInstallerList = async(conn, selectInstallerList, groupManagerId) =>{
  const list = selectInstallerList.map(v =>({ installerId : v, groupManagerId : groupManagerId}));
  console.log("setGroupInstallerList", list );
  return await conn.post('group/installer'
    , JSON.stringify(list) 
    , {headers: {'Content-Type': 'application/json'}})
};

// 그룹 설치자 삭제
export const setDeleteGroupInstallerList = async(conn, selectGroupInstallerList, groupManagerId) =>{
  const list =  selectGroupInstallerList.map(v =>({ installerId : v, groupManagerId : groupManagerId}));
  console.log("setGroupInstallerList", list );
  return await conn.delete('group/installer'
    , {
      headers: {'Content-Type': 'application/json'}
      , data : JSON.stringify(list) 
    });
};

