import React from "react";

const LocalAreaMonitorMapDepthTable = props => {
  const { depthBoxRef } = props;
  return (
    <div className="info-box-A info-table municDepthTbl" ref={depthBoxRef} style={{ display: "none" }}>
      클릭 시 해당 지도를 확대하여 볼 수 있습니다.
    </div>
  );
};

export default LocalAreaMonitorMapDepthTable;
