import { useCreateAxiosInstance } from "conInstance";
import "./index.css";
import { useEffect, useRef, useState } from "react";
import { roundNum } from "com/dataUtil";
import NonsanTitle from "../NonsanTitle";
import Time from "./../../asan/components/Today/Time";
import { NONSAN_CODE } from "../index";
import WeatherIcon from "components/localArea/gongju/components/todayGongju/weatherIcon";

export default function Today() {
  const { conn } = useCreateAxiosInstance();
  const timeId = useRef(null);
  const [data, setData] = useState();
  const getTodayInfo = async () => {
    const response = await conn.get(`/monitor/installer/munic/weather?rgnCode=${NONSAN_CODE}`);
    setData(response);
  };

  useEffect(() => {
    getTodayInfo();
    if (timeId.current) clearInterval(timeId.current);
    timeId.current = setInterval(() => getTodayInfo(), 1000 * 60 * 60);
    // eslint-disable-next-line
  }, []); // 초기 렌더링 시에만 적용 및 interval 진행, 새로고침 시 interval 초기화

  if (!data) return <div />;

  return (
    <div className="today-nonsan-container">
      <NonsanTitle title={"논산의 오늘"} />
      <div className="nonsan-card">
        <div className="nonsan-info">
          <div className="nonsan-info-detail">
            <div>{new Date().toISOString().slice(0, 10)}</div>
            <div>
              <div className="nonsan-weather">{data.presWeathVal}</div>
              <div className="nonsan-weather-box">
                <div className="nonsan-tem">{data.temp}</div>
                <div className="nonsan-degree">ºC</div>
              </div>
            </div>
          </div>
          <div>
            <Time />
            <WeatherIcon weather={data.presWeathVal}/>
          </div>
        </div>
        <div className="nonsan-info-etc">
          <div>습도: {data.humi}%</div>
          <div>강수량: {roundNum(data.precQty, 0)}mm</div>
          <div>
            일사량: {roundNum(data.solaQty, 0)} W/m<sup>2</sup>
          </div>
        </div>
      </div>
    </div>
  );
}
