import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

// * react, IE에서 호환(크로스브라우징)
// (1) 리엑트 개발에서 사용하는 다양한 문법을 변환해주는 라이브러리
// index.js import 설정
// IE9의 경우
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
// IE11의 경우
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

// @babel/polyfill
// (2) 본인 프로젝트에서 async, await, function*를 프로젝트에서 활용하는 경우 설치
//index.js import 설정
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);