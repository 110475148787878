import React from "react";

import { saveBizExpend } from "../../../../service/bizStat/com/expenseApiService";

const BizExpendModal = ({
  conn,
  styles,
  bizStyles,
  search,
  dataList,
  setDataList,
  spendItemListData,
  energyTypeCodeListData,
  getData,
  bizExpendGridAdd,
  cancelBtnEvt,
  kendo,
  Form,
  FormElement,
  Button,
  useState,
  useEffect,
  NumberFormat,
  DropDownList,
  Input,
}) => {
  // 드롭다운 데이터 정의
  const [energyTypeData, setEnergyTypeData] = useState({ codeNo: "", codeVal: "" });
  const [spendItemData, setSpendItemData] = useState({ codeNo: "", codeVal: "" });

  const deleteCommas = str => {
    return str.replace(/,/g, "");
  };

  const onBizExpendChange = e => {
    // console.log("e",e);
    //input에 name을 가진 요소의 value에 이벤트를 걸었다
    const { name, value } = e.target;
    // 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
    const nextInputs = dataSource => {
      //스프레드 문법으로 기존의 객체를 복사한다.
      const item = { ...dataSource };
      item.bizExpendInput[name] = deleteCommas(value);
      return item;
    };

    //만든 변수를 seInput으로 변경해준다.
    setDataList(nextInputs);

    setValue();
  };

  const setValue = () => {
    setDataList(data => {
      const item = { ...data }; 
      item.bizExpendInput.bizYy = search.bizYyNo;
      item.bizExpendInput.rgnCode = search.rgnCode;
      item.bizExpendInput.cityProvCode = search.cityProvCode;
      item.bizExpendInput.totalExpense =
        parseInt(item.bizExpendInput.gvmSpt === "" ? 0 : item.bizExpendInput.gvmSpt) +
        parseInt(item.bizExpendInput.province === "" ? 0 : item.bizExpendInput.province) +
        parseInt(item.bizExpendInput.city === "" ? 0 : item.bizExpendInput.city) +
        parseInt(item.bizExpendInput.priCompany === "" ? 0 : item.bizExpendInput.priCompany);
      item.bizExpendInput.mupal =
        parseInt(item.bizExpendInput.city === "" ? 0 : item.bizExpendInput.city) +
        parseInt(item.bizExpendInput.province === "" ? 0 : item.bizExpendInput.province);        
      return item;
    });
  };

  // 드롭다운 변경 이벤트
  const onItemChange = e => {
    if (e.target.name === "spendItemCode") {
      dataList.bizExpendInput.spendItem = e.target.value.codeNo;
      setSpendItemData({
        codeNo: e.target.value.codeNo,
        codeVal: e.target.value.codeVal,
      });
    } else if (e.target.name === "consEnte") {
      setDataList(dataSource => {
        const item = { ...dataSource };
        item.bizExpendInput.consEnte = e.target.value;
        return item;
      });
    } else if (e.target.name === "energyTypeCode") {
      dataList.bizExpendInput.energyTypeCode = e.target.value.codeNo;
      setEnergyTypeData({
        codeNo: e.target.value.codeNo,
        codeVal: e.target.value.codeVal,
      });
    }
  };

  const saveBizExpendEvent = e => {
    dataList.bizExpendInput.bizSctCode = search.bizSctCode;
    saveBizExpend(conn, dataList.bizExpendInput).then(results => {
      kendo.alert("사업비 지출현황 입력을 완료 하였습니다.");
      bizExpendGridAdd();
    });
  };

  const cancelBizExpend = e => {
    cancelBtnEvt();
  };

  // converter
  const dataConverter = item => {
    // 에너지원
    if (item.energyTypeCode) {
      const ensoTypeValue = energyTypeCodeListData.filter(v => v.codeNo === item.energyTypeCode)[0];
      setEnergyTypeData({ codeNo: ensoTypeValue.codeNo, codeVal: ensoTypeValue.codeVal });
    }
    if (item.spendItem) {
      const spendItemValue = spendItemListData.filter(v => v.codeNo === item.spendItem)[0];
      setSpendItemData({ codeNo: spendItemValue.codeNo, codeVal: spendItemValue.codeVal });
    }
  };

  useEffect(() => {
    const ensoData = dataList.bizExpendInput;
    // console.log("ensoData", ensoData);
    dataConverter(ensoData);
    /* eslint-disable-next-line */
  }, []);


  return (
    <div className={bizStyles.bizExpendModal}>
      <div className={bizStyles.bizExpendModalTitle}>
        <h3 style={{ textAlign: "left" }}>사업비 지출현황 입력</h3>
        <button class="btn_iconMM b-close">
          <i class="material-icons" style={{ color: "black", fontSize: "20px" }} onClick={cancelBizExpend}>
            X
          </i>
        </button>
      </div>
      <div>
        <Form
          render={() => (
            <FormElement>
              <table class={bizStyles.emcTable}>
                <colgroup>
                  <col width="10" />
                  <col width="40" />
                </colgroup>
                <tbody>
                  <tr style={{ border: "solid 1px #dedede" }}>
                    <th>지급명</th>
                    <td>
                      <input
                        className={styles.borderE4}
                        name="paymentName"
                        component={Input}
                        onChange={onBizExpendChange}
                        defaultValue={dataList.bizExpendInput.paymentName !== "" ? dataList.bizExpendInput.paymentName : ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>정부(원)</th>
                    <td>
                      <NumberFormat
                        thousandSeparator={true}
                        name="gvmSpt"
                        type="text"
                        className={styles.borderE4}
                        defaultValue={dataList.bizExpendInput.gvmSpt.toString() !== "" ? dataList.bizExpendInput.gvmSpt : ""}
                        onChange={onBizExpendChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>도비(원)</th>
                    <td>
                      <NumberFormat
                        thousandSeparator={true}
                        name="province"
                        type="text"
                        className={styles.borderE4}
                        defaultValue={dataList.bizExpendInput.province.toString() !== "" ? dataList.bizExpendInput.province : ""}
                        onChange={onBizExpendChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>시/군비(원)</th>
                    <td>
                      <NumberFormat
                        thousandSeparator={true}
                        name="city"
                        type="text"
                        className={styles.borderE4}
                        defaultValue={dataList.bizExpendInput.city.toString() !== "" ? dataList.bizExpendInput.city : ""}
                        onChange={onBizExpendChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>민간(원)</th>
                    <td>
                      <NumberFormat
                        thousandSeparator={true}
                        name="priCompany"
                        type="text"
                        className={styles.borderE4}
                        defaultValue={dataList.bizExpendInput.priCompany.toString() !== "" ? dataList.bizExpendInput.priCompany : ""}
                        onChange={onBizExpendChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>지출항목</th>
                    <td>
                      <DropDownList
                        name="spendItemCode"
                        component={DropDownList}
                        data={spendItemListData}
                        textField="codeVal"
                        dataItemKey="codeNo"
                        value={spendItemData}
                        // defaultValue={dataList.bizExpendInput.spendItem != '' ? {codeNo :dataList.bizExpendInput.spendItemCode.codeNo , codeVal : dataList.bizExpendInput.spendItemCode.codeVal} : { codeNo: '', codeVal: '선택' }}
                        onChange={onItemChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>지급업체명</th>
                    <td>
                      <DropDownList
                        name="consEnte"
                        component={DropDownList}
                        data={dataList.consEnteList}
                        value={dataList.bizExpendInput.consEnte}
                        onChange={onItemChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>에너지원</th>
                    <td>
                      <DropDownList
                        name="energyTypeCode"
                        component={DropDownList}
                        data={energyTypeCodeListData}
                        textField="codeVal"
                        dataItemKey="codeNo"
                        value={energyTypeData}
                        // defaultValue={dataList.bizExpendInput.energyType != '' ? {codeNo :dataList.bizExpendInput.energyType.codeNo , codeVal : dataList.bizExpendInput.energyType.codeVal} : { codeNo: '', codeVal: '선택' }}
                        onChange={onItemChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button title="saveBizExpend" className="saveButton" onClick={saveBizExpendEvent}>
                저장
              </Button>
              <Button title="cancelBizExpend" className="cancelButton" onClick={cancelBizExpend}>
                취소
              </Button>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default BizExpendModal;
