import kendo from "@progress/kendo-ui";
import { getMessage } from "../../../com/messageUtil";
import { getToken } from "../../authService";

/**
 * 파일 삭제
 * @param {*} conn
 * @param {*} fileIds
 * @returns
 */
export const deleteFileList = async (conn, fileIds) => {
  const response = await conn.delete("/biz/file/delete", {
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(fileIds),
  });
  return response;
};

/**
 * 파일 정보
 * @param {*} conn
 * @param {*} data
 * @param {*} convTabName
 * @param {*} callback
 * @param {*} reject
 */
export const getFileName = async (conn, data, convTabName, callback, reject) => {
  // console.log("data",instlPlnnm, bizDocuCodeNo, convTabName, energyTypeCode);
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    await conn
      .get(
        "biz/file/fileName",
        {
          params: {
            instlPlnnm: data.instlPlnnm,
            bizDocuCodeNo: data.bizDocuCodeNo,
            energyTypeCode: data.energyTypeCode,
            bizSctCode: data.bizSctCode,
          },
        },
        config,
      )
      .then(response => {
        // console.log("response = ", response);
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};

/**
 * 파일 다운로드
 * @param {*} fileInfo
 * @param {*} convTabName
 * @param {*} fileName
 * @returns
 */
export const downloadFile = (fileInfo, fileName) => {
  const file = {
    instlPlnnm: fileInfo.instlPlnnm,
    docuCodeNo: fileInfo.docuCodeNo,
    bizDocuCodeNo: fileInfo.bizDocuCodeNo,
    energyTypeCode: fileInfo.energyTypeCode,
    bizYy: fileInfo.bizYy,
    rgnCode: fileInfo.rgnCode,
    bizSctCode: fileInfo.bizSctCode,
  };

  // console.log("file", file)
  const request = new XMLHttpRequest();
  request.open("POST", `${process.env.REACT_APP_REST_API_VERSION}/biz/file/download`, true);
  request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
  request.setRequestHeader("X-AUTH-TOKEN", getToken());
  if (fileInfo.bizDocuCodeNo === 38008) {
    request.setRequestHeader("Accept", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  }
  request.responseType = "blob";
  request.onload = function (e) {
    if (this.status === 200) {
      const blob = this.response;
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const downloadLink = window.document.createElement("a");
        const contentTypeHeader = request.getResponseHeader("Content-Type");
        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      // clearInterval(progress);
      // excelUploadProgressClear(url+"/excelDownloadProgressClear");
    } else {
      // clearInterval(progress);
      // excelUploadProgressClear(url+"/excelDownloadProgressClear");
    }
  };
  request.send(JSON.stringify(file));
  // console.log(request)
  return request;
};

// 사업비 - 산출내역 엑셀파일 데이터 가져오기
export const getBizExpenseFile = async (conn, data) => {
  const response = await conn.get("biz/file/fileName", {
    params: {
      bizYy: data.bizYy,
      rgnCode: data.rgnCode,
      bizDocuCodeNo: 38008,
      docuCodeNo: data.docuCodeNo,
      bizSctCode: data.bizSctCode,
    },
  });

  return response;
};
