import { getToken } from "../service/authService";

export const setDeleteFileList = async (conn, fileIds) => {
  const response = await conn.delete("/file/delete",{
      headers: {'Content-Type': 'application/json'}
      , data: JSON.stringify(fileIds)
    });
  return response;
};


export const downloadFile = (fileInfo) => {
  const file = {
    fileId : fileInfo.fileId,
    fileName : fileInfo.name,
  }
  const request = new XMLHttpRequest();
  request.open("POST", "/file/download", true);
  request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
  request.setRequestHeader('X-AUTH-TOKEN', getToken());
  request.responseType = "blob";
  request.onload = function (e) {
    if (this.status === 200) {
      const blob = this.response;
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileInfo.name);
      } else {
        const downloadLink = window.document.createElement("a");
        const contentTypeHeader = request.getResponseHeader("Content-Type");
        downloadLink.href = window.URL.createObjectURL(
          new Blob([blob], { type: contentTypeHeader })
        );
        downloadLink.download =  fileInfo.name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      // clearInterval(progress);
      // excelUploadProgressClear(url+"/excelDownloadProgressClear");
    } else {
      // clearInterval(progress);
      // excelUploadProgressClear(url+"/excelDownloadProgressClear");
    }
  };
  request.send(JSON.stringify(file));
  console.log(request)
  return request;
};


export const getFileList = async (conn) => {
  const response = await conn.get("/file/loadFile", {
    params: {
    
    }
  });
  return response;
}
