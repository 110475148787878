import { ReactComponent as PowerPlant } from "../../../../../assets/svg/taean/powerPlantIcon.svg";
import { ReactComponent as Heat } from "../../../../../assets/svg/taean/heatIcon.svg";
import { ReactComponent as Electric } from "../../../../../assets/svg/taean/electricIcon.svg";
import { useEffect, useState } from "react";
import { numFormat, roundNum } from "com/dataUtil";

const TaeanTooltip = ({ x, y, display, area, data }) => {
  const [meainCount, setMeanCount] = useState(0);
  const [elecPower, setElecPower] = useState(0);
  const [heatPower, setHeatPower] = useState(0);

  useEffect(() => {
    if (data && area) {
      const matchingData = data.find(item => item.admnsBundTermS === area);
      if (matchingData) {
        setMeanCount(matchingData.meainCount);
        setElecPower(matchingData.dongElecPower);
        setHeatPower(matchingData.dongUsePower);
      }
    }
  }, [area]);

  const formatEnergy = value => {
    if (value >= 1000) {
      return {
        value: numFormat(roundNum(value / 1000, 1)),
        unit: "MWh",
      };
    }
    return {
      value: numFormat(roundNum(value, 1)),
      unit: "kWh",
    };
  };

  const formattedHeatPower = formatEnergy(heatPower);
  const formattedElecPower = formatEnergy(elecPower);

  return (
    <div className="taean-tooltip-container" style={{ top: y - 130, left: x + 20, display }}>
      <div className="taean-tooltip-title">{area}</div>
      <div className="taean-tooltip-info">
        <div className="taean-icon-wrapper">
          <PowerPlant className="taean-tooltip-icon" />
        </div>
        <div className="taean-icon-wrapper">{meainCount}개소</div>
      </div>
      <div className="taean-tooltip-info">
        <div className="taean-icon-wrapper">
          <Heat className="taean-tooltip-icon" />
        </div>
        <div className="taean-icon-wrapper">
          {formattedHeatPower.value} {formattedHeatPower.unit}
        </div>
      </div>
      <div className="taean-tooltip-info">
        <div className="taean-icon-wrapper">
          <Electric className="taean-tooltip-icon" />
        </div>
        <div className="taean-icon-wrapper">
          {formattedElecPower.value} {formattedElecPower.unit}
        </div>
      </div>
    </div>
  );
};

export default TaeanTooltip;
