import { getToken } from "./../../authService";
// 관리현황 - 설비 내역 리스트
export const getMngtMeainList = async (conn, callback, reject, search) => {
  const { take, skip } = search.mngt.paging;
  // 검색 객체 생성
  await conn
    .get("biz/management/page", {
      params: {
        bizYy: search.bizYyNo,
        rgnCode: search.rgnCode,
        instlTerm: search.instlTerm,
        energyTypeCode: search.energyTypeCode,
        bizSctCode: search.bizSctCode,
        // page: (take + skip) / take,
        page: skip,
        size: take,
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

// 관리현황 - 신청자 및 설비 관리 정보 불러오기
export const getMngtMeainInfo = async (conn, callback, reject, search) => {
  // console.log("getMngtMeainInfo search = ", search);
  // 에너지원에 따른 URL 변경
  let url = "";
  if (search.mngt.energyTypeCode === 39001) {
    url = "biz/management/info/sola";
  } else if (search.mngt.energyTypeCode === 39002) {
    url = "biz/management/info/heat";
  } else if (search.mngt.energyTypeCode === 39003) {
    url = "biz/management/info/velo";
  } else if (search.mngt.energyTypeCode === 39004) {
    url = "biz/management/info/heath";
  } else if (search.mngt.energyTypeCode === 39005) {
    url = "biz/management/info/fuel";
  }

  await conn
    .get(url, {
      params: {
        instlPlnnm: search.instlPlnnm,
        bizSctCode: search.bizSctCode,
      },
    })
    .then(response => {
      if (!response) {
        response = {};
      }
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

// 관리현황 - 신청자 및 설비 관리 정보 - 설비 저장하기
export const saveMngtMeainInfo = async (conn, callback, reject, saveMeainList, energyTypeCode, search) => {
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  let url = "";
  if (energyTypeCode === 39001) {
    url = "biz/management/save/sola";
  } else if (energyTypeCode === 39002) {
    url = "biz/management/save/heat";
  } else if (energyTypeCode === 39003) {
    url = "biz/management/save/velo";
  } else if (energyTypeCode === 39004) {
    url = "biz/management/save/heath";
  } else if (energyTypeCode === 39005) {
    url = "biz/management/save/fuel";
  }

  // console.log("url = ", url);

  // 사업 코드 부여
  saveMeainList.forEach(element => {
    element.bizSctCode = search.bizSctCode;
  });

  await conn
    .post(url, JSON.stringify(saveMeainList), config)
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

// 관리현황 - 신청자 및 설비 관리 정보 - AS 보수이력 목록 불러오기
export const getMngtMeainAsList = async (conn, callback, reject, search, energyTypeCode) => {
  // 검색 객체 생성
  await conn
    .get("biz/management/as/list", {
      params: {
        instlPlnnm: search.instlPlnnm,
        energyTypeCode: energyTypeCode,
        bizSctCode: search.bizSctCode,
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

// 관리현황 - 신정자 및 설비 관리 정보 - AS 보수이력 추가
export const saveMeainAsInfo = async (conn, callback, reject, saveData, energyTypeCode, search) => {
  // console.log("saveMeainAsInfo saveData = ", saveData);
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  console.log("saveData = ", saveData);

  const saveInfo = {
    instlPlnnm: saveData.instlPlnnm,
    energyTypeCode: Number(energyTypeCode),
    asReason: saveData.asReason,
    regiDtm: saveData.regiDtm,
    fileExt: saveData.fileExt,
    fileId: saveData.fileId,
    fileName: saveData.fileName,
    fileSize: saveData.fileSize,
    saveName: saveData.saveName,
    savePath: saveData.savePath,
    bizSctCode: search.bizSctCode,
  };

  await conn
    .post("biz/management/as", saveInfo, config)
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

// 관리현황 - 신청자 및 설비 관리 정보 - 관리이력 파일 리스트 불러오기
export const getMngtMeainHistFileList = async (conn, callback, reject, search) => {
  // 검색 객체 생성
  await conn
    .get("biz/management/file/list", {
      params: {
        instlPlnnm: search.instlPlnnm,
        energyTypeCode: search.mngt.energyTypeCode,
        bizSctCode: search.bizSctCode,
      },
    })
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};

export const downloadAsFile = (fileInfo, fileName, search) => {
  const file = {
    instlPlnnm: fileInfo.instlPlnnm,
    energyTypeCode: fileInfo.energyTypeCode,
    regiDtmDay: fileInfo.regiDtm,
    bizTabName: fileInfo.bizTabName,
    bizSctCode: search.bizSctCode,
  };

  console.log("file", file);
  const request = new XMLHttpRequest();
  request.open("POST", `${process.env.REACT_APP_REST_API_VERSION}/biz/management/file/biz/download`, true);
  request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
  request.setRequestHeader("X-AUTH-TOKEN", getToken());
  request.responseType = "blob";
  request.onload = function (e) {
    if (this.status === 200) {
      const blob = this.response;
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const downloadLink = window.document.createElement("a");
        const contentTypeHeader = request.getResponseHeader("Content-Type");
        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } else {
      // clearInterval(progress);
      // excelUploadProgressClear(url+"/excelDownloadProgressClear");
    }
  };
  request.send(JSON.stringify(file));
  // console.log(request)
  return request;
};
