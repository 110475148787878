import { ReactComponent as SupplyIcon } from "dist/svg/supply.svg";
import { ReactComponent as Question } from "dist/svg/question.svg";

import "./Supply.css";
import { useState } from "react";

export default function Supply() {
  const [isOver, setIsOver] = useState(false);

  return (
    <div className="asan-supply-container">
      <div className="asan-supply-title">
        <h3 className="asan-card-title">
          신재생에너지 보급률
          <Question className="question-icon" onMouseEnter={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)} />
          {isOver && 
          <div className={'question-tooltip'}>
            <div>2024년 아산시 현황 자료 기준</div>
            <div>(출처: 한국에너지공단)</div>
          </div>}
        </h3>
      </div>
      <div className="asan-supply-card">
        <SupplyIcon className="supply-icon" />
        <h2>4.81 %</h2>
      </div>
    </div>
  );
}
