import MenuRoleCommon from './menuRole_common';

import { getMessage } from '../../../com/messageUtil';

import { useState } from 'react'

import { setUpdateMenuRole } from '../../../service/menu/menuService'
import { getMenuTree, getMenuRoleTree } from '../../../service/menu/menuService'

import kendo from '@progress/kendo-ui';

import styles from './menuRole.module.css'
import './menuRole.css'

const MenuRole = ({ conn, codeList }) => {
  // 트리뷰 체크 여부 확인 저장용
  let [dataSource, setDataSource] = useState({})

  const [commonCheckData, setCommonCheckData] = useState({
    menuData: [],
    menuList: []
  })

  const [nowUserType, setNowUserType] = useState({
    codeNo: "", codeVal: "사용자유형"
  });

  const [selectedUserType, setSelectedUserType] = useState({
    codeNo: "", codeVal: "-"
  });

  // 로딩 스피너 설정용
  const [loading, setLoading] = useState(false);

  const userTypeList = codeList.filter(item => item.grpCodeNo === 2);

  // 메뉴 트리 데이터
  let [treeData, setTreeData] = useState({})

  // 메뉴 트리 데이터 불러오기
  const getTreeData = (treeData) => {
    getMenuTree(conn, treeData)
      .then(treeData => {
        setTreeData({
          data: treeData
        })

        getCheckData(treeData)
      })
      .catch(res => {
        // console.error(res);
        alert(getMessage(res.errCode));
      });
  }

  function getCheckData(obj) {
    getMenuRoleTree(conn, obj, nowUserType.codeNo)
      .then(dataSource => {
        setDataSource({
          data: dataSource,
          checkChildren: true,
          checkParents: true,
          check: { idField: 'id', ids: [], applyCheckIndeterminate: true }
        });
        setLoading(false);
      })
      .catch(res => {
        // console.log( "error codeList res = ", res );
        // console.error(res);
        alert(getMessage(res.errCode));
        setLoading(false);
      });
  }

  /* 저장 버튼 */
  const saveChanges = () => {
    let menuData = [commonCheckData];
    if (selectedUserType.codeNo === "") {
      kendo.alert("사용자유형을 먼저 선택해주세요");
    }
    else if (selectedUserType.codeNo !== nowUserType.codeNo) {
      kendo.alert("조회하고 있는 사용자 유형과 현재 드롭다운에서 선택된 사용자 유형이 다릅니다");
    }
    else {
      setUpdateMenuRole(conn, menuData)
        .then((response) => {
          kendo.alert('저장되었습니다.');
          getTreeData();
          // 권한 여부를 변경했을 때 왼쪽 메뉴바는 렌더링이 끝난 상태여서 갱신이 안되므로 개발자 계정일 경우 새로고침?
          // if (selectedUserType.codeNo === 2005)
          //   window.location.replace("");
        })
        .catch(function (error) {  // API 호출이 실패한 경우
          // kendo.alert("저장을 실패 하였습니다.");
          // console.error(error);  // 에러표시

          alert(getMessage(error.errCode));
        })
    }
  };

  const setCheckData = (userTypeCode, menuData, menuList) => {
    setCommonCheckData({
      ...commonCheckData,
      menuData: menuData,
      menuList: menuList,
      userTypeCode: userTypeCode
    })
  }

  return (
    <div>
      <div className="uk-grid" style={{ justifyContent: "center" }}>
        <MenuRoleCommon
          conn={conn}
          setCheckData={setCheckData}
          nowUserType={nowUserType}
          setNowUserType={setNowUserType}
          userTypeList={userTypeList}
          styles={styles}
          saveChanges={saveChanges}
          loading={loading}
          setLoading={setLoading}
          treeData={treeData}
          setTreeData={setTreeData}
          getTreeData={getTreeData}
          dataSource={dataSource}
          setDataSource={setDataSource}
          setCommonCheckData={setCommonCheckData}
          selectedUserType={selectedUserType}
          setSelectedUserType={setSelectedUserType}
        />
      </div>
    </div>
  )
}

export default MenuRole