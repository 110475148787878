import { Window } from "@progress/kendo-react-dialogs";

const BizProgressStepImgPopup = ({ imgSrc, closeImgWindow, styled }) => {
  console.log(" ProgressStepImgPopup imgSrc = ", imgSrc);

  const imgSrcUrl = imgSrc;
  console.log("ProgressStepImgPopup imgSrcUrl = ", imgSrcUrl);

  // 윈도우 위치 설정
  const popupWidth = 940;
  const popupHeight = 470;

  const popupX = window.screen.width / 2 - popupWidth / 2;
  // 만들 팝업창 width 크기의 1/2 만큼 보정값으로 빼주었음

  const popupY = window.screen.height / 2 - popupHeight / 2;
  // 만들 팝업창 height 크기의 1/2 만큼 보정값으로 빼주었음

  return (
    <>
      <Window
        title={"사진 확대창"}
        className="commentImgWindow"
        onClose={closeImgWindow}
        resizable={false}
        draggable={false}
        width={"100vh"}
        height={"50vh"}
        top={popupY}
        left={popupX}
        minimizeButton={"none"}
        maximizeButton={"none"}>
        <div className={styled.popupImgArea}>
          <img src={imgSrcUrl} alt=""></img>
        </div>
      </Window>
    </>
  );
};

export default BizProgressStepImgPopup;
