import { numFormat } from "../../../com/dataUtil";

// 선택한 브라우저에 대한 1주일 데이터 (어제부터 ~ 어제 기준으로 1주일 전) 차트 
const BrowserKindChart = ({targetBrowserDataChartData , targetBrowserDataCategory , Highcharts ,HighchartsReact }) => {
  
  // console.log("targetBrowserDataChartData = ", targetBrowserDataChartData);
  // console.log("targetBrowserDataCategory = ", targetBrowserDataCategory);

  // 차트 옵션
  const chartConfig = {
    chart:{ 
      height: 345,
      type: 'spline',
      backgroundColor: "#ffffff",
      width: 753,
      className : "visitorChart"
    },
    title: { text: null },
    credits: { enabled: false },
    xAxis: { categories : targetBrowserDataCategory },
    yAxis: {
        title: {
            text: '세션 갯수',
            rotation: 0,
            y: -20,
            offset: 0,
            align: 'high',
        }
        , labels : {
          format: '{value:,.0f}',
        }
    },
    tooltip: {
      formatter: function () {
        return(
          `
            <b>${this.point.name}</b><br/>
            <b>session : </b>${numFormat(this.point.y)}
          `
        );
      }
    },
    legend: {  layout: 'horizontal', align: 'center', verticalAlign: 'top', floating: false },
    plotOptions: {
        series: { label: { connectorAllowed: false } }
    },
    series: [{
      name: 'session 갯수',
      data: targetBrowserDataChartData
    }],
    responsive: {
      rules: [{
          condition: { maxWidth: 500 },
          chartOptions: {
              legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
              }
          }
      }]
    }
  };

  return(
    <>
      <HighchartsReact Highcharts = {Highcharts} options = {chartConfig} />
    </>
  );

}


export default BrowserKindChart;