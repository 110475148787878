// React
import React from "react";

// HighCharts
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "../../../../com/dataUtil";

HighchartsMore(Highcharts);

export const EnsoPieCharts = ({ chartTitle, data }) => {
  // 차트 데이터
  const chartData = {
    sola : 0,
    heat : 0,
    heath : 0,
    velo : 0,
    fuelcell : 0,
  };

  let optPointFormat;
  let optPlotFormat;

  if (chartTitle === "발생건수") {
    data.forEach(item => {
      for (const [/*k*/, v] of Object.entries(item)) {
        if (v === "태양광") chartData.sola = item.all;
        else if (v === "태양열") chartData.heat = item.all;
        else if (v === "지열") chartData.heath = item.all;
        else if (v === "풍력") chartData.velo = item.all;
        else if (v === "연료전지") chartData.fuelcell = item.all;
        else if (v === "ESS") chartData.ess = item.all;
      }
    });

    optPointFormat = function () {
      return(
        `
          <span><b>${this.key}</b></span><br>
          <span>${this.series.name}</span> : 
          <b>${numFormat(this.point.y)}건(${roundNum(this.point.percentage, 1)}%)</b><br>
        `
      );
    };
    optPlotFormat = function () {
      return (
        `
          <span><b>${this.point.name}-${numFormat(this.y)}건(${roundNum(this.point.percentage, 1)}%)</b></span>
        `
      );
    };
  } else if (chartTitle === "조치소요시간") {
    data.forEach(item => {
      for (const [/*k*/, v] of Object.entries(item)) {
        if (v === "태양광") chartData.sola = item.manaTm;
        else if (v === "태양열") chartData.heat = item.manaTm;
        else if (v === "지열") chartData.heath = item.manaTm;
        else if (v === "풍력") chartData.velo = item.manaTm;
        else if (v === "연료전지") chartData.fuelcell = item.manaTm;
        else if (v === "ESS") chartData.ess = item.manaTm;
      }
    });

    optPointFormat = function () {
      let time = this.y > 60 ? `${numFormat(Math.round(this.y / 60))} 시간` : `${numFormat(this.y)} 분`;
      return (
        `
          <span><b>${this.key}</b></span><br>
          <span>조치시간</span> : <b>${time}(${roundNum(this.point.percentage, 1)}%)</b><br>
        `
      );
    };
    optPlotFormat = function () {
      return (
        "<span>" +
        "<b>" +
        this.point.name +
        "-" +
        numFormat(Math.round(this.y / 60)) +
        "분(" +
        roundNum(this.point.percentage, 1) +
        "%)</b>" +
        "</span>"
      );
    };
  }

  const options = {
    chart: { type: "pie", height: '510', width: "850" },
    title: {
      text: chartTitle,
      style: {
        color: "black",
        fontSize: "16px",
        // fontWeight: "bold"
      },
    },
    credits: false,
    tooltip: {
      shared: true,
      formatter: optPointFormat,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          color: "black",
          style: { textShadow: false },
          formatter: optPlotFormat,
          showInLegend: true,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        name: "발생건수",
        colorByPoint: true,
        data: [
          {
            name: "태양광",
            color: "#E06C5F",
            y: chartData.sola,
          },
          {
            name: "태양열",
            color: "#FDAC41",
            y: chartData.heat,
          },
          {
            name: "지열",
            color: "#82B440",
            y: chartData.heath,
          },
          {
            name: "풍력",
            color: "#6DB6EE",
            y: chartData.velo,
          },
          {
            name: "연료전지",
            color: "#A266D1",
            y: chartData.fuelcell,
          },
          {
            name: "ESS",
            color: "#475F7B",
            y: chartData.ess,
          },
        ],
      },
    ],
  };

  return (
    <>
      <HighchartsReact Highcharts={Highcharts} options={options} />
    </>
  );
};
