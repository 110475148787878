// 코드 정의서
// 코드 정의서 추가 시 변수 룰 적용 대문자, 단어마다 _ 추가하여 구분 시켜준다.
// 앞에는 CODE 를 명시, 각 구분은 _ 로 구분하고 맨 뒤의 단어는 목적을 알 수 있는 단어로 표기
// ex ) CODE_UTIL_SOLA = 15001

// 1. 에너지원 코드 정의
export const CODE_SOLA = 15001; // 태양광 코드
export const CODE_HEAT = 15002; // 태양열 코드
export const CODE_HEATH = 15003; // 지열 코드
export const CODE_VELO = 15004; // 풍력 코드
export const CODE_FUELCELL = 15006; // 연료전지 코드
export const CODE_ESS = 15007; // ESS 코드

// 2. 설비 상태 코드 정의
export const CODE_MEAIN_STAT_UNOPER = 7004; // 미작동
export const CODE_MEAIN_STAT_WARN = 7006; // 경고
export const CODE_MEAIN_STAT_ERR = 7007; // 고장

// CODE UTIL 코드 추가 시 이름 정의 형식 : CODE_SOLA = 15001;

// 3. 사업현황-융복합지원사업 서류 코드 정의
export const CODE_BIZ_DEMAND = 38001; // 사업대상지정보 - 수요조사서류
export const CODE_BIZ_BPM1 = 38002; // 사업대상지정보 - BPM서류 1차
export const CODE_BIZ_BPM2 = 38003; // 사업대상지정보 - BPM서류 2차
export const CODE_BIZ_RGN_ISSUE = 38004; // 사업대상지정보 - 시군구교부서류
export const CODE_BIZ_RGN_SUPPLY = 38005; // 사업대상지정보 - 시군구지급서류
export const CODE_BIZ_MNGT_ENERGY = 38006; // 관리현황 - 서류 파일
export const CODE_BIZ_CANCEL_MNGT = 38007; // 취소관리 - 첨부 파일
export const CODE_BIZ_EXPENSE = 38008; // 사업비 - 산출내역 파일
export const CODE_BIZ_PROGRESS_IMG = 38009; // 진척률  - 썸네일

// export const HOUSE_DEMAND = 58001; // 주택지원사업 - 수요조사서류
// export const HOUSE_BPM1 = 58002;   // 주택지원사업 - BPM서류 1차
// export const HOUSE_BPM2 = 58003; // 주택지원사업 - BPM서류 2차
// export const HOUSE_RGN_ISSUE = 58004;  // 주택지원사업 - 시군구교부서류
// export const HOUSE_RGN_SUPPLY = 58005; // 주택지원사업 - 시군구지급서류
// export const HOUSE_CANCEL_MNGT = 58007; // 주택지원사업 - 취소관리 파일
// export const HOUSE_BIZ_EXPENSE = 58008; // 주택지원사업 - 사업비파일

// export const AREA_DEMAND = 59001; // 지역지원사업 - 수요조사서류
// export const AREA_BPM1 = 59002;   // 지역지원사업 - BPM서류 1차
// export const AREA_BPM2 = 59003; // 지역지원사업 - BPM서류 2차
// export const AREA_RGN_ISSUE = 59004;  // 지역지원사업 - 시군구교부서류
// export const AREA_RGN_SUPPLY = 59005; // 지역지원사업 - 시군구지급서류
// export const AREA_CANCEL_MNGT = 59007; // 지역지원사업 - 취소관리 파일
// export const AREA_BIZ_EXPENSE = 59008; // 지역지원사업 - 사업비파일

export const CONV_PROGRESS_IMG = 38009; // 융복합지원사업 - 설비설치 사진

// 4. 사업현황-융복합지원사업 에너지원 코드 정의
export const CODE_BIZ_STAT_MONI = 39000; // 모니터링고도화
export const CODE_BIZ_STAT_SOLA = 39001; // 태양광
export const CODE_BIZ_STAT_HEAT = 39002; // 태양열
export const CODE_BIZ_STAT_VELO = 39003; // 소형풍력
export const CODE_BIZ_STAT_GEOT = 39004; // 지열
export const CODE_BIZ_STAT_FUEL = 39005; // 연료전지
export const CODE_BIZ_STAT_WAST = 39006; // 폐기물
export const CODE_BIZ_STAT_BAIO = 39007; // 바이오
export const CODE_BIZ_STAT_COLI = 39008; // 집광채광
export const CODE_BIZ_STAT_SMPO = 39009; // 수소력
export const CODE_BIZ_STAT_HYDR = 39010; // 수열에너지
export const CODE_BIZ_STAT_WOPE = 39011; // 목재펠릿
export const CODE_BIZ_STAT_PSD1 = 39012; // 전력저장장치(1)
export const CODE_BIZ_STAT_PSD2 = 39013; // 전력저장장치(2)
export const CODE_BIZ_STAT_PSD3 = 39014; // 전력저장장치(3)
export const CODE_BIZ_STAT_PSD4 = 39015; // 전력저장장치(4)
export const CODE_BIZ_STAT_DESU = 39016; // 설계/감리

// 37001	37	1	융복합지원사업
export const CODE_BIZ_CONV = 37001;
// 37002	37	2	주택지원사업
export const CODE_BIZ_HOUSE = 37002;
// 37003	37	3	건물지원사업
export const CODE_BIZ_BUILDING = 37003;
// 37004	37	4	지역지원사업(설치의무)
export const CODE_BIZ_AREA = 37004;
// 37005	37	5	발전소
export const CODE_BIZ_POWERSTATION = 37005;
// 37006	37	6	기타
export const CODE_BIZ_ETC = 37006;
