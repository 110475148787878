import { translateUnit } from "components/localArea/asan/components/DevelopmentState/DevelopmentState";

export default function Card({ svg, title, power }) {
  const getTranslatePower = translateUnit(power).split(" ");
  const translatedPower = getTranslatePower[0];
  const translatedUnit = getTranslatePower[1];
  return (
    <div className="nonsan-develop-card">
      {svg}
      <div className="nonsan-energy-set">
        <div>{title}</div>
        <div>
          {translatedPower} <span className="nonsan-energy-unit">{translatedUnit}</span>
        </div>
      </div>
    </div>
  );
}
