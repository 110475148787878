import "./index.css";
import { ReactComponent as LOGO } from "../../../../assets/svg/taean/logo.svg";
import { ReactComponent as LOGIN } from "../../../../assets/svg/taean/login.svg";

const TaeanHeader = () => {
  const handleRedirectMonitoring = () => {
    window.location.href = "https://kremc.kr/";
  };

  return (
    <div className="taean-header">
      <LOGO className="taean-logo" />
      <button className="taean-login-wrapper" onClick={handleRedirectMonitoring} type="button">
        <LOGIN className="taean-login-icon" />
        로그인
      </button>
    </div>
  );
};

export default TaeanHeader;
