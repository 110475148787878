import { ReactComponent as NonsanIcon } from "../svg/nonsan-icon.svg";
import { ReactComponent as LoginButton } from "../svg/login-button.svg";
import "./index.css";

export default function Top() {
  return (
    <div className="nonsan-top-container">
      <div className="nonsan-top-set">
        <NonsanIcon className="nonsan-icon" />
        <h2 className="nonsan-top-title">신재생에너지 관리 센터</h2>
      </div>
      <a className="nonsan-top-set nosan-login" href="https://kremc.kr/">
        <LoginButton className="nonsan-login-button" />
        <h3 className="nonsan-top-title">로그인</h3>
      </a>
    </div>
  );
}
