import {Button} from '@progress/kendo-react-buttons'
import './meain_info_control.css';
import kendo from '@progress/kendo-ui';
import { deleteMeain } from '../../../service/meain/meain/meainService';
import styles from './meain.module.css';

// 설비 버튼 제어
function MeainInfoControl({conn ,  paging , resetInfo , setMeainList , ensoTypeCodeNo , setReplaceMeain , saveCall}){
  const selectMeain = paging.selectMeain;
  // meainDelete (삭제 로직 )
  const meainDelete = () => {
    deleteApi();
  }

  // 삭제 API
  const deleteApi = () =>{
    kendo.confirm("해당 설비를 삭제 하시겠습니까? ").then(e => {
      console.log("삭제 = ", selectMeain);
      if( Object.keys(selectMeain).length === 0 ){
        kendo.alert("삭제할 설비를 선택 해주세요.");
        return;
      }
      if(selectMeain.meainStatCodeNo === 7005){
        kendo.alert("삭제된 설비는 다시 삭제할 수 없습니다. 다른 설비를 선택 해주세요.");
        return;
      }
      // // CID 배열 저장
      var deleteCid = [];
      deleteCid.push( selectMeain.cid );
      // console.log("deleteCid=  ", deleteCid);
      deleteMeain(conn , getDeleteResult , getRejection , deleteCid , ensoTypeCodeNo);
    },  () => { kendo.alert("설비 삭제를 취소하였습니다.");  resetInfo(paging); return; } );
  }

  // 삭제 callback
  const getDeleteResult = (result) => {
    kendo.alert("설비를 삭제 하였습니다.");
    meainAdd(paging);
    setMeainList(paging);
  }

  // 설비 저장, 삭제 rejection
  const getRejection = (error , subject) => {
    // console.log("error= ", error);
    if( error.errCode === "M001" ){
      kendo.alert("설비를 선택하지 않았습니다. 설비를 선택 해주세요.");
      return;
    }
  }

  // meainAdd (완료)
  const meainAdd = () => { 
    resetInfo(paging);
  }


  // 설비 교체
  const changeMeain = () => {
    kendo.confirm("선택된 설비를 교체 하시겠습니까? 현재 설비는 교체상태로 변경되며 동일정보의 새로운CID로 설비가 생성됩니다.").then(e => {
      console.log("교체 = ", selectMeain);
      if( Object.keys(selectMeain).length === 0 ){
        kendo.alert("교체할 설비를 선택 해주세요.");
        return;
      }
      if(selectMeain.meainStatCodeNo === 7008){
        kendo.alert("교체된 설비는 다시 교체할 수 없습니다. 다른 설비를 선택 해주세요.");
        return;
      }

      setReplaceMeain();
    },  () => { kendo.alert("설비 교체를 취소하였습니다.");  resetInfo(paging); return; } );
  }
  
  return(
      <div>
          <Button icon = "save" type = "submit" className={styles.meainBtn} onClick = {saveCall} >저장</Button>
          <Button icon = "delete" className={styles.meainBtn} onClick = {meainDelete} >삭제</Button>
          <Button icon = "add" className={styles.meainBtn} onClick = {meainAdd} >추가</Button>
          <Button icon = "refresh" className={styles.meainBtn} onClick = {changeMeain} >교체</Button>
      </div>
  );
}

export default MeainInfoControl;