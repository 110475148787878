import React from 'react';
import { useState } from 'react';
// import ConvMngtMeainUploadArea from './ConvMngtMeainUploadArea';
import BizMngtMeainSampleDownArea from './bizMngtMeainSampleDownArea';
import BizMngtMeainUploadArea from './bizMngtMeainUploadArea';
import { meainSyncData } from '../managementFunc';
import { useHistory } from "react-router-dom";
/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 일괄등록 양식다운, 일괄등록, 저장 버튼 부분 
*/
const BizManagementContentTab = ({
  Button, convMngtStyles , Window, Upload, kendo
  , saveMeainInfoData
  , token
  , saveMeainInfoDataList
  , filterInitialList , meainInfo
  , codeList
}) => {
  
  // console.log("meainInfo = ", meainInfo);

  // 히스토리 설정
  const history = useHistory();

  // 상태 설정
  const [ openSample , setOpenSample ] = useState(false);
  const [ openUpload , setOpenUpload ] = useState(false);

  // 일괄 양식 다운로드 이벤트
  const isOpenSample = () => {
    setOpenSample(!openSample);
    setOpenUpload(false);
  }

  // 일괄 양식 다운로드 이벤트
  const isOpenUpload = () => {
    setOpenUpload(!openUpload);
    setOpenSample(false);
  }

  // 저장 이벤트
  const clickSaveEvt = () => {
    filterInitialList();
    saveMeainInfoData();
  }

  /**
   * 연동 버튼 이벤트
   */
  const meainSyncDataBtn = () =>{
    if( meainInfo.ableSync === "N" ){
      kendo.alert("해당 설비는 설비 관리 정보가 저장 되지 않은 설비 입니다. 설비 관리 정보를 기입해서 저장 해주세요.");
      return;
    }
    // step1 연동 버튼 클릭 하면 설비 내역에서 연동 했는지 체크  ( 이미 연동한 설비라면 해당 기능 하지 않도록 알림 처리 )
    if( meainInfo.syncYn === "Y" ){
      kendo.alert("해당 설비는 이미 연동된 설비 입니다. 해당 설비는 다시 연동 할 수 없습니다.");
      return;
    }

    if( meainInfo.moniYn === "Y" ){
      kendo.alert("해당 설비는 모니터링 고도화 설비 입니다. 해당 설비는 아직 지원할 수 없기에 연동 할 수 없습니다.");
      return;
    }
    
    // console.log("연동");
    meainSyncData(history , meainInfo , codeList , 37001 );
  }
  
  return (
    <>
      <div id = "convMngtButtonControlArea" className={convMngtStyles.convMngtButtonControlArea}>
        { meainInfo.instlPlnnm && 
          <Button icon="share" className={convMngtStyles.meainSyncBtn} onClick = {meainSyncDataBtn} disabled={meainInfo.ableSync === "Y" ? false : true} > 연동</Button>
        }
        <Button icon="download" className={convMngtStyles.downloadMeainExcelTemplateBtn} onClick = {isOpenSample} > 일괄등록 양식다운</Button>
        <Button icon="upload" className={convMngtStyles.uploadMeainListBtn} onClick = {isOpenUpload} > 일괄등록</Button>
        <Button icon="save" className={convMngtStyles.saveMeainInfoDataBtn} onClick = {clickSaveEvt} >저장</Button>
      </div>
      { openSample === true &&
        <div>
          <BizMngtMeainSampleDownArea openSample = {openSample} convMngtStyles = {convMngtStyles} Button = {Button} />
        </div>
      }
      { openUpload === true &&
        <div>
          <BizMngtMeainUploadArea openUpload = {openUpload} convMngtStyles = {convMngtStyles} Button = {Button} Window = {Window} Upload = {Upload} kendo = {kendo}
                                  token = {token} saveMeainInfoDataList = {saveMeainInfoDataList}
          />
        </div>
      }
    </>
  );
};

export default BizManagementContentTab;