// React
import React, { useState, useEffect, useCallback } from "react";
import { getEventLog, setEventLog } from "../../../service/event/eventLogService";
import { downloadExcel } from "../../../com/downloadExcel";
import { parseDate } from "../../../com/dateUtil";

// Kendo
import kendo from "@progress/kendo-ui";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog } from "@progress/kendo-react-dialogs";

// Css
import "./eventLog.css";
import styles from "./eventLog.module.css";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import GridColumnInputFilter from "../../com/gridFilter/gridColumnInputFilter";
import { isInstaller } from "../../../service/authService";
import { useLocation } from "react-router";
import { getDongs } from "../../../service/areaService";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

// 로딩 스피너
import { BeatLoader } from "react-spinners";

// 유저 검색 리스트
import UserSearchList from "../../com/userSearch/userSearchList";

const EventLog = ({ conn, codeList, cityList, rgnList, qs, isMobile }) => {
  const [selected, setSelected] = useState(0);
  const handleSelect = e => {
    setSelected(e.selected);
  };

  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;
  const location = useLocation();
  const parameter = qs.parse(location.search);

  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 에너지원
  const engList = codeList.filter(item => item.grpCodeNo === 15);

  // 건물용도
  const buildingUse = codeList.filter(item => item.grpCodeNo === 31);

  // 설비상태
  const facilityState = codeList.filter(item => item.grpCodeNo === 7 
    && item.codeNo !== 7001 && item.codeNo !== 7002 
    && item.codeNo !== 7003 && item.codeNo !== 7005 && item.codeNo !== 7008 );

  // 날짜검색
  const dateList = codeList.filter(item => item.grpCodeNo === 23);

  // 처리상태
  const processStateList = codeList.filter(item => item.grpCodeNo === 21);

  // 담당자 일 떄 시군구 리스트 설정

  const ensoType = parameter.ensoTypeCodeNo
    ? engList.filter(e => e.codeNo === Number(parameter.ensoTypeCodeNo))[0]
    : { codeNo: 15001, codeVal: "태양광" };

  const ct = parameter.cityProvCode
    ? cityList.filter(e => e.cityProvCode === parameter.cityProvCode)[0]
    : { cityProvCode: "", cityProvTerm: "시/도" };

  const rgn = parameter.rgnCode ? rgnList.filter(e => e.rgnCode === parameter.rgnCode)[0] : { rgnCode: "", rgnTermSimple: "시군구" };

  // const fs = parameter.facilityState
  //   ? facilityState.filter(e => e.codeNo === Number(parameter.facilityState))[0]
  //   : { codeNo: "", codeVal: "전체" };

  // 검색 파라미터
  const [search, setSearch] = useState({
    ensoTypeCode: parameter.ensoTypeCodeNo ? Number(parameter.ensoTypeCodeNo) : 15001,
    ensoType: ensoType,
    buildingUseCode: "",
    facilityStateCode: parameter.facilityState ? Number(parameter.facilityState) : 7007,
    fs: { codeNo: 7007, codeVal: "고장" },
    city: { cityProvCode: "", cityProvTerm: "시도" },
    cityProvCode: "",
    ct: ct,
    rgnCode: parameter.rgnCode ? parameter.rgnCode : "",
    rgn: rgn,
    dongCode: "",
    dongData: { fixbylawBundCode: "", fixbylawBundTermSimple: "읍면동" },
    dateState: { codeNo: 23001, codeVal: "오늘" },
    startDate: parameter.start ? new Date(parameter.start) : new Date(),
    endDate: parameter.end ? new Date(parameter.end) : new Date(),
    installer: "",
    cid: "",
    endMin: parameter.end ? new Date(parameter.end) : new Date(),
    startMax: parameter.end ? new Date() : new Date(),
    eventSateStatus: { codeNo: "", codeVal: "상태" },
    userId : ""
  });

  const citys = cityList;
  const rgns = rgnList.filter(e => e.cityProvCode === search.cityProvCode);

  const [dongList, setDongList] = useState();

  const [dataSource, setDataSource] = useState({
    dataList: [],
    total: 0,
  });
  const [dataState, setDataState] = useState({
    take: 20,
    skip: 0,
    total: 0,
    sort: [],
    sortStatus: [],
    filter: null,
    eventTrmeCtnt: "",
    eventTrmeMan: "",
    eventTrmeStatCodeNo: "",
    eventTrmeStat : { codeNo: "", codeVal: "상태" }
  });
  const [eventStat, setEventStat] = useState({
    visible: false,
    formItem: [],
  });

  // 설치자 검색 윈도우 창 Visible
  let [userInfoVisible , setUserInfoVisible] = useState(false);

  // 설치자 검색 버튼 클릭 이벤트
  const searchUserId = () =>{
    // setUserInfoPosition(controlWindowPosition());    
    setUserInfoVisible(!userInfoVisible);
  }

  // 그리드에서 유저 선택 시 이벤트
  const selectUserId = (e) =>{
    // const obj = {
    //     ...paging.search
    //     , userId : e.dataItem.userId
    //     , userTerm : e.dataItem.userTerm
    //     , rgnCode : e.dataItem.rgnCode
    //     // , cityProvCode : e.dataItem.cityProvCode
    //     // , alias : e.dataItem.userTerm (SREMS에서는 직접 입력)
    // };
    // setMeainInfo(obj);
    // 검색 유저 ID 설정
    search.userId = e.dataItem.userId;
    search.installer = e.dataItem.userTerm;
    setUserInfoVisible(false);
  }

  // 단순 그리드 데이터 조회
  const getGridData = () => {
    setLoading(true);
    getEventLog(conn, dataState, search).then(response => {
      const { results, total } = response;
      setLoading(false);
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.dataList = results;
        item.total = total;
        return item;
      });
    });
  };

  // 드롭다운 onchange 이벤트
  const onChangeEvt = e => {

    if (e.target.name === "ensoTypeCode") {
      setSearch(search => {
        const item = { ...search };
        item.ensoTypeCode = e.target.value.codeNo;
        item.ensoType = e.target.value;
        return item;
      });
    } else if (e.target.name === "buildingUse") {
      setSearch(search => {
        const item = { ...search };
        item.buildingUseCode = e.target.value.codeNo;
        return item;
      });
    } else if (e.target.name === "facilityState") {
      setSearch(search => {
        const item = { ...search };
        item.facilityStateCode = e.target.value.codeNo;
        item.fs = e.target.value;
        return item;
      });
    } else if (e.target.name === "city") {
      setSearch(search => {
        const item = { ...search };
        item.city = e.target.value;
        item.cityProvCode = e.target.value.cityProvCode;
        item.cityProvTerm = e.target.value.cityProvTerm;
        item.rgnCode = "";
        item.rgnTerm = "";
        item.rgn = { rgnCode: "", rgnTermSimple: "시군구" };
        item.ct = e.target.value;
        return item;
      });
    } else if (e.target.name === "rgn") {
      setSearch(search => {
        const item = { ...search };
        item.rgnCode = e.target.value.rgnCode;
        item.dongCode = "";
        item.dongData = { fixbylawBundCode: "", fixbylawBundTermSimple: "읍면동" };
        item.rgn = e.target.value;
        return item;
      });

      getDongs(conn, e.target.value.rgnCode).then(result => {
        setDongList(result);
      });
    } else if (e.target.name === "dong") {
      setSearch(search => {
        const item = { ...search };
        item.dongCode = e.target.value.fixbylawBundCode;
        item.dongData = e.target.value;
        return item;
      });
    } else if (e.target.name === "dateState") {
      if (e.target.value.codeNo === 23001) {
        setSearch(state => {
          const item = { ...state };
          item.startDate = today;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23002) {
        setSearch(state => {
          const item = { ...state };
          item.startDate = yesterday;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23003) {
        setSearch(state => {
          const item = { ...state };
          item.startDate = lastWeek;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23004) {
        setSearch(state => {
          const item = { ...state };
          item.startDate = lastMonth;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23005) {
        setSearch(state => {
          const item = { ...state };
          item.startDate = lastYear;
          item.dateState = e.target.value;
          return item;
        });
      } else if (e.target.value.codeNo === 23006) {
        setSearch(state => {
          const item = { ...state };
          item.startDate = today;
          item.dateState = e.target.value;
          return item;
        });
      }
    } else if (e.target.name === "installer") {
      search.installer = e.target.value;
    } else if (e.target.name === "cid") {
      search.cid = e.target.value;
    }
  };

  // 그리드 페이징 처리
  const handlePageChange = useCallback(
    e => {
      const { take, skip } = e.page;
      dataState.take = take;
      dataState.skip = skip;
      getGridData();
    },
    // eslint-disable-next-line
    [dataSource, dataState, search],
  );

  // 그리드 필터링 처리
  const handleFilterChange = useCallback(
    e => {
      const { filter } = e;
      dataState.filter = e;
      if (filter !== null) {
        const filters = filter.filters[0];
        if (filters.field === "eventTrmeStatCodeTerm") {
          dataState.eventTrmeStatCodeNo = filters.value.codeNo;
        } else if (filters.field === "eventTrmeMan") {
          dataState.eventTrmeMan = filters.value;
        } else if (filters.field === "eventTrmeCtnt") {
          dataState.eventTrmeCtnt = filters.value;
        }
      }
      getGridData();
    },
    // eslint-disable-next-line
    [dataSource, dataState, search],
  );

  // 그리드 소팅 처리
  const handleSortChange = useCallback(
    e => {
      const { sort } = e;
      dataState.sort = sort;
      if (sort[0] !== undefined) {
        if (sort[0].field === "ensoTypeTerm" || sort[0].field === "installerTerm" || sort[0].field === "cid") {
          dataState.sortStatus = "meain." + sort[0].field + "," + sort[0].dir;
        } else if (sort[0].field === "meainStatCodeTerm") {
          dataState.sortStatus = "meainStatCodeNo.codeVal," + sort[0].dir;
        } else if (sort[0].field === "eventStatCodeTerm") {
          dataState.sortStatus = "eventStatCodeNo.codeVal," + sort[0].dir;
        } else if (sort[0].field === "eventTrmeStatCodeTerm") {
          dataState.sortStatus = "eventTrmeStatCodeNo.codeVal," + sort[0].dir;
        } else {
          dataState.sortStatus = sort[0].field + "," + sort[0].dir;
        }
      } else {
        dataState.sortStatus = null;
      }
      getGridData();
    },
    // eslint-disable-next-line
    [dataSource, dataState, search],
  );

  // 카테고리 조회 버튼
  const handleSearchBtn = () => {
    dataState.skip = 0;
    dataState.take = 20;
    console.log("검색 조건 확인 : ", search);
    const valid = validateSearch();
    if( !valid.isValid ){
      kendo.alert(valid.message);
      return;
    }
    getGridData();
  };

  // 입력간 Validation 설정 
  const validateSearch = () => {
    let isValid = true;
    let message = "";
    if( !search.installer ){
      isValid = false;
      message = "수용가를 선택 해주세요.";
    }
    if( !search.ensoTypeCode ){
      isValid = false;
      message = "에너지원을 선택 해주세요.";
    }
    return { isValid : isValid , message : message }
  }

  // 엑셀 저장
  const handleExcelDown = e => {

    const valid = validateSearch();
    if( !valid.isValid ){
      kendo.alert(valid.message);
      return;
    }

    e.preventDefault();
    setLoading(true);
    // sorting
    const sort = dataState.sort.length > 0 ? dataState.sort[0].field : "";
    const direction = dataState.sort.length > 0 ? dataState.sort[0].dir : "desc";

    // 검색 조건
    const searchExcelData = {
      ensoTypeCode: search.ensoTypeCode,
      buildingUseCode: search.buildingUseCode,
      facilityStateCode: search.facilityStateCode,
      cityProvCode: search.cityProvCode,
      rgnCode: search.rgnCode,
      dongCode: search.dongCode,
      startDate: kendo.toString(search.startDate, "yyyy-MM-dd"),
      endDate: kendo.toString(search.endDate, "yyyy-MM-dd"),
      installer: search.installer,
      cid: search.cid,
      eventTrmeCtnt: dataState.eventTrmeCtnt,
      eventTrmeMan: dataState.eventTrmeMan,
      eventTrmeStatCodeNo: dataState.eventTrmeStatCodeNo,
      sort: sort,
      direction: direction,
      userId : search.userId
    };

    const url = "/api/v2.2/event/log";
    const fileName = "이벤트이력_" + search.installer + "_" + parseDate("yyyymmdd") + ".xlsx";
    downloadExcel(searchExcelData, fileName, url, setLoading);
  };

  // 이벤트 상세 내역 폼 컨트롤
  const handleEvtFormControl = useCallback(
    e => {
      setEventStat({ ...eventStat, visible: !eventStat.visible, formItem: [] });
    },
    [eventStat],
  );

  // 이벤트 상세 내역 폼 선택 이벤트
  const handleEvtSubmit = useCallback(
    e => {
      const { eventNo, eventOcurCus, eventTrmeCtnt, eventTrmeStatCodeNo, eventTrmeMan } = e;

      if (!eventOcurCus) {
        kendo.alert("발생 원인을 입력 해주세요.");
        return;
      } else if (!eventTrmeCtnt) {
        kendo.alert("조치 방법을 입력 해주세요.");
        return;
      } else if (!eventTrmeStatCodeNo.codeNo) {
        kendo.alert("진행단계를 선택 해주세요.");
        return;
      } else if (!eventTrmeMan) {
        kendo.alert("조치자을 입력 해주세요.");
        return;
      }

      // 저장 객체 생성
      const eventSaveData = {
        eventNo: eventNo,
        eventOcurCus: eventOcurCus,
        eventTrmeCtnt: eventTrmeCtnt,
        eventTrmeStatCodeNo: eventTrmeStatCodeNo.codeNo ? eventTrmeStatCodeNo.codeNo : "",
        eventTrmeMan: eventTrmeMan,
      };

      // 상세 내역 저장
      setEventLog(conn, eventSaveData).then(() => {
        setEventStat({ ...eventStat, visible: !eventStat.visible, formItem: [] });
        getGridData();
      });
    },
    // eslint-disable-next-line
    [eventStat, dataSource, dataState],
  );

  // 처리상태 필터CELL 드롭다운
  const handleEventStatFilterList = props => {
    const filterOnchage = e => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });

      setDataState(dataState => {
        const item = { ...dataState };
        item.eventSateStatus = e.value;
        return item;
      });
    };

    return (
      <div className="k-filtercell">
        <DropDownList
          data={processStateList}
          onChange={filterOnchage}
          defaultItem={{ codeNo: "", codeVal: "상태" }}
          className="CustomFilterDropDown"
          name="eventStatTerm"
          textField="codeVal"
          dataItemKey="codeNo"
          value={dataState.eventSateStatus}
        />
      </div>
    );
  };

  // 조치방법 드롭다운 리스트
  const handleTrmeStatList = props => {
    const value = processStateList.filter(e => e.codeNo === props.value.codeNo)[0];
    const filterOnchage = e => {
      props.onChange({
        value: e.value,
        operator: e.value ? "eq" : "",
        syntheticEvent: e.syntheticEvent,
      });
    };

    return (
      <div className="k-filtercell">
        <DropDownList
          data={processStateList.filter(e => e.codeNo !== 21002)}
          onChange={filterOnchage}
          defaultItem={{ codeNo: "", codeVal: "상태" }}
          className="CustomFilterDropDown"
          name="eventTrmeStatCodeTerm"
          textField="codeVal"
          dataItemKey="codeNo"
          value={  value }
          // value = {dataState.eventSateStatus}
        />
      </div>
    );
  };

  useEffect(() => {
    // getGridData();
  }, []);

  // const mobileOcurDtm = e => {
  //   const tempDtm = e.dataItem.ocurDtm;
  //   const result = tempDtm.substring(2, 16);
  //   return <td>{result}</td>;
  // };

  return (
    <>
      {isMobile ? (
        //모바일 페이지
        <>
          <div className="eventLogForm">
            <div>
              <div>
                <div>
                  <TabStrip selected={selected} onSelect={handleSelect}>
                    <TabStripTab title="기간선택">
                      <table className="mobileEventLogCateTable">
                        <tr>
                          <th colSpan="2">
                            <span>날짜검색</span>
                          </th>
                          <td colSpan="2">
                            <DropDownList
                              data={dateList}
                              dataItemKey="codeNo"
                              textField="codeVal"
                              name="dateState"
                              value={search.dateState}
                              defaultValue={{ codeNo: 23001, codeVal: "오늘" }}
                              onChange={onChangeEvt}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="4">
                            <span>조회기간</span>
                          </th>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <DatePickerKo
                              id="start"
                              name="start"
                              className="mobileEventLogDatePicker"
                              defaultValue={today}
                              format="yyyy-MM-dd "
                              setDtm={v => {
                                setSearch(search => {
                                  const item = { ...search };
                                  item.startDate = v;
                                  item.dateState = { codeNo: 23006, codeVal: "직접조회" };
                                  item.endMin = v;
                                  return item;
                                });
                              }}
                              max={search.startMax}
                              value={search.startDate}
                            />
                            &nbsp;~&nbsp;
                            <DatePickerKo
                              id="end"
                              name="end"
                              className="mobileEventLogDatePicker"
                              defaultValue={today}
                              format="yyyy-MM-dd"
                              setDtm={v => {
                                setSearch(search => {
                                  const item = { ...search };
                                  item.endDate = v;
                                  item.startMax = v;
                                  return item;
                                });
                              }}
                              min={search.endMin}
                              max={today}
                              value={search.endDate}
                            />
                          </td>
                        </tr>
                      </table>
                    </TabStripTab>
                    <TabStripTab title="상세조회">
                      <table className="mobileEventLogCateTable">
                        <tr>
                          <th>
                            <span>에너지원</span>
                          </th>
                          <td>
                            <DropDownList
                              data={engList}
                              name="ensoTypeCode"
                              dataItemKey="codeNo"
                              textField="codeVal"
                              onChange={onChangeEvt}
                              value={search.ensoType}
                            />
                          </td>
                          <th>
                            <span>건물용도</span>
                          </th>
                          <td>
                            <DropDownList
                              data={buildingUse}
                              name="buildingUse"
                              dataItemKey="codeNo"
                              textField="codeVal"
                              defaultItem={{ codeNo: "", codeVal: "전체" }}
                              onChange={onChangeEvt}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <span>설비상태</span>
                          </th>
                          <td>
                            <DropDownList
                              data={facilityState}
                              name="facilityState"
                              dataItemKey="codeNo"
                              textField="codeVal"
                              onChange={onChangeEvt}
                              value={search.fs}
                            />
                          </td>
                          <th>
                            <span>지역검색</span>
                          </th>
                          <td>
                            <DropDownList
                              data={citys}
                              textField="cityProvTerm"
                              dataItemKey="cityProvCode"
                              disabled={isInstaller() === true ? true : false}
                              name="city"
                              onChange={onChangeEvt}
                              value={search.ct}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <DropDownList
                              data={rgns}
                              textField="rgnTermSimple"
                              dataItemKey="rgnCode"
                              disabled={isInstaller() === true ? true : search.cityProvCode === "" ? true : false}
                              // disabled={search.cityProvCode === ""}
                              name="rgn"
                              onChange={onChangeEvt}
                              value={search.rgn}
                            />
                          </td>
                          <td colSpan="2">
                            <DropDownList
                              data={dongList}
                              disabled={search.rgnCode ? false : true}
                              textField="fixbylawBundTermSimple"
                              dataItemKey="fixbylawBundCode"
                              name="dong"
                              value={search.dongData}
                              defaultItem={{ fixbylawBundCode: "", fixbylawBundTermSimple: "읍면동" }}
                              onChange={onChangeEvt}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="2">
                            <Input
                              placeholder="설치자명"
                              name="installer"
                              disabled={isInstaller() === true ? true : false}
                              onChange={onChangeEvt}
                            />
                          </td>
                          <td colSpan="2">
                            <Input placeholder="CID" disabled={isInstaller() === true ? true : false} name="cid" onChange={onChangeEvt} />
                          </td>
                        </tr>
                      </table>
                    </TabStripTab>
                  </TabStrip>
                </div>
              </div>
              <div className="mobileEventLogbtnDiv">
                <Button onClick={handleSearchBtn} icon="search" className={styles.mobileSearchBtn}>
                  조회
                </Button>
              </div>
            </div>

            <div className="eventLogBody">
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Grid
                    style={
                      loading
                        ? { height: innerHeight * 0.6 + "px", opacity: 0.6, pointerEvents: "none" }
                        : { height: innerHeight * 0.6 + "px" }
                    }
                    className={styles.mobileEventLogGrid}
                    data={dataSource.dataList}
                    total={dataSource.total}
                    skip={dataState.skip}
                    take={dataState.take}
                    pageable={{ buttonCount: 5, pageSizes: true }}
                    filterable
                    sort={dataState.sort}
                    sortable
                    onPageChange={handlePageChange}
                    onFilterChange={handleFilterChange}
                    onSortChange={handleSortChange}>
                    <GridColumn title="에너지원" field="ensoTypeTerm" filterable={false} width={60} />
                    <GridColumn title="설치자" field="installerTerm" filterable={false} width={100} />
                    <GridColumn title="발생일시" field="ocurDtm" filterable={false} width={130} />
                    <GridColumn title="설비상태" field="meainStatCodeTerm" filterable={false} width={60} />
                    <GridColumn
                      width={100}
                      title="처리상태"
                      field="eventTrmeStatCodeTerm"
                      filterCell={handleEventStatFilterList}
                      cell={props => (
                        <td>
                          {props.dataItem.eventTrmeStatCodeTerm ? props.dataItem.eventTrmeStatCodeTerm + " " : "미처리 "}
                          <button style={{ border: "none", background: "transparent"}}
                            onClick={e => {
                              e.preventDefault();
                              setEventStat({
                                ...eventStat,
                                visible: !eventStat.visible,
                                formItem: props.dataItem,
                              });
                            }}>
                            &nbsp;
                            <img src="/images/icon_d.png" alt="" />
                          </button>
                        </td>
                      )}
                    />
                    <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
                  </Grid>
                  {loading && (
                    <p
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                      }}>
                      <BeatLoader loading={true} size={24} color="#1e88e5" />
                    </p>
                  )}
                </IntlProvider>
              </LocalizationProvider>
              {eventStat.visible && (
                <Dialog
                  title="이벤트 상세 내역"
                  className={styles.eventStatDialog}
                  onClose={handleEvtFormControl}
                  width={innerWidth * 0.9}
                  height={innerHeight * 0.7}>
                  <div className="mobileEventStatDialogDiv">
                    <Form
                      onSubmit={handleEvtSubmit}
                      initialValues={eventStat.formItem}
                      render={formRenderProps => (
                        <FormElement>
                          <table>
                            <tr>
                              <th>진행상태</th>
                              <td>
                                <span>발생</span> &nbsp;&gt;&nbsp;
                                <span>확인</span> &nbsp;&gt;&nbsp;
                                <span>처리중</span> &nbsp;&gt;&nbsp;
                                <span>처리완료</span>
                              </td>
                            </tr>
                            <tr>
                              <th>발생원인</th>
                              <td>
                                <Field name="eventOcurCus" component={Input} />
                              </td>
                            </tr>
                            <tr>
                              <th>조치방법</th>
                              <td>
                                <Field name="eventTrmeCtnt" component={Input} />
                              </td>
                            </tr>
                            <tr>
                              <th>진행단계</th>
                              <td>
                                <Field
                                  name="eventTrmeStatCodeNo"
                                  // component={DropDownList}
                                  component={handleTrmeStatList}

                                  // data={processStateList.filter(e => e.codeNo !== 21002)}
                                  // defaultValue={processStateList.filter(e => e.codeNo === eventStat.formItem.eventTrmeStatCodeTerm )}
                                  // textField = "codeVal" dataItemKey= "codeNo"
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>조치자</th>
                              <td>
                                <Field name="eventTrmeMan" component={Input} />
                              </td>
                            </tr>
                          </table>
                          <div className="mobileEventStatDialogBtnDiv">
                            <Button onClick={handleEvtFormControl}>취소</Button>
                            &nbsp; &nbsp;
                            <Button type="submit">선택</Button>
                          </div>
                        </FormElement>
                      )}
                    />
                  </div>
                </Dialog>
              )}
            </div>
            {loading && (
              <p
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}>
                <BeatLoader loading={true} size={24} color="#1e88e5" />
              </p>
            )}
          </div>
        </>
      ) : (
        // PC페이지
        <div>
          <div className={["eventLogForm", loading ? styles.eventG : ""].join(" ")}>
            <div className="eventLogCate">
              <div>
                <span>에너지원</span>
                <DropDownList
                  data={engList}
                  name="ensoTypeCode"
                  dataItemKey="codeNo"
                  textField="codeVal"
                  onChange={onChangeEvt}
                  value={search.ensoType}
                />
                <span>설비상태</span>
                <DropDownList
                  data={facilityState}
                  name="facilityState"
                  dataItemKey="codeNo"
                  textField="codeVal"
                  onChange={onChangeEvt}
                  value={search.fs}
                />
                <Input
                  className="eventLogCateInputOne"
                  placeholder="설치자명"
                  name="installer"
                  disabled={isInstaller() === true ? true : false}
                  value = {search.installer}
                  readOnly
                />
                <Button className={styles.searchUserBtn} onClick={searchUserId} icon="search"></Button>
                <Input
                  className="eventLogCateInputTwo"
                  placeholder="CID"
                  // disabled={isInstaller() == true ? true : false}
                  name="cid"
                  onChange={onChangeEvt}
                />
                <span>조회기간</span>
                <DatePickerKo
                  id="start"
                  name="start"
                  className="datePicker"
                  defaultValue={today}
                  format="yyyy-MM-dd "
                  setDtm={v => {
                    setSearch(search => {
                      const item = { ...search };
                      item.startDate = v;
                      item.endDate = v;
                      // item.dateState = { codeNo: 23006, codeVal: "직접조회" };
                      // item.endMin = v;
                      return item;
                    });
                  }}
                  max={search.startMax}
                  value={search.startDate}
                />
                <Button className={styles.inqBtn} onClick={handleSearchBtn} icon="search">
                </Button>
                <Button className={styles.excelBtn} onClick={handleExcelDown} icon="excel">
                  엑셀저장
                </Button>
              </div>
            </div>
            <div className={["eventLogBody", loading ? styles.eventG : ""].join(" ")}>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Grid
                    className={styles.eventLogGrid}
                    data={dataSource.dataList}
                    total={dataSource.total}
                    skip={dataState.skip}
                    take={dataState.take}
                    pageable={{ buttonCount: 5, pageSizes: true }}
                    filterable
                    sort={dataState.sort}
                    sortable
                    onPageChange={handlePageChange}
                    onFilterChange={handleFilterChange}
                    onSortChange={handleSortChange}>
                    <GridColumn title="순번" field="eventNo" filterable={false} width={100} />
                    <GridColumn title="에너지원" field="ensoTypeTerm" filterable={false} width={100} />
                    <GridColumn title="설치자" field="installerTerm" filterable={false} width={300} />
                    <GridColumn title="CID" field="cid" filterable={false} />
                    <GridColumn title="발생일시" field="ocurDtm" filterable={false} width={200} />
                    <GridColumn title="설비상태" field="meainStatCodeTerm" filterable={false} />
                    <GridColumn title="발생원인" field="eventOcurCus" filterable={false} />
                    <GridColumn title="이벤트상태" field="eventStatCodeTerm" filterable={false} />
                    <GridColumn
                      title="처리상태"
                      field="eventTrmeStatCodeTerm"
                      filterCell={handleEventStatFilterList}
                      cell={props => (
                        <td>
                          {props.dataItem.eventTrmeStatCodeTerm ? props.dataItem.eventTrmeStatCodeTerm + " " : "미처리 "}
                          <button style={{ border: "none", background: "transparent"}}
                            onClick={e => {
                              e.preventDefault();
                              setEventStat({
                                ...eventStat,
                                visible: !eventStat.visible,
                                formItem: props.dataItem,
                              });
                            }}>
                            &nbsp;
                            <img src="/images/icon_d.png" alt="" />
                          </button>
                        </td>
                      )}
                    />
                    <GridColumn title="조치일시" field="eventTrmeDtm" filterable={false} width={200} />
                    <GridColumn title="조치자" field="eventTrmeMan" filterCell={GridColumnInputFilter} />
                    <GridColumn title="조치내용" field="eventTrmeCtnt" filterCell={GridColumnInputFilter} />
                    <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
                  </Grid>
                </IntlProvider>
              </LocalizationProvider>
              {eventStat.visible && (
                <Dialog title="이벤트 상세 내역" className={styles.eventStatDialog} onClose={handleEvtFormControl}
                width={innerWidth * 0.21}
                  height={innerHeight * 0.47}>
                  <div className="eventModalForm">
                    <div className={styles.leftModalItem}>
                      <div>진행상태</div>
                      <div>발생원인</div>
                      <div>조치방법</div>
                      <div>진행단계</div>
                      <div>조치자</div>
                    </div>
                    <div className={styles.rightModalItem}>
                      <Form
                        onSubmit={handleEvtSubmit}
                        initialValues={eventStat.formItem}
                        render={formRenderProps => (
                          <FormElement>
                            <div>
                              <span>발생</span> &nbsp;&gt;&nbsp;
                              <span>확인</span> &nbsp;&gt;&nbsp;
                              <span>처리중</span> &nbsp;&gt;&nbsp;
                              <span>처리완료</span>
                            </div>
                            <div>
                              <Field name="eventOcurCus" component={Input} />
                            </div>
                            <div>
                              <Field name="eventTrmeCtnt" component={Input} />
                            </div>
                            <div>
                              <Field
                                name="eventTrmeStatCodeNo"
                                // component={DropDownList}
                                component={handleTrmeStatList}

                                // data={processStateList.filter(e => e.codeNo !== 21002)}
                                // defaultValue={processStateList.filter(e => e.codeNo === eventStat.formItem.eventTrmeStatCodeTerm )}
                                // textField = "codeVal" dataItemKey= "codeNo"
                              />
                            </div>
                            <div>
                              <Field name="eventTrmeMan" component={Input} />
                            </div>
                            <div>
                              <Button onClick={handleEvtFormControl}>취소</Button>
                              <Button type="submit">선택</Button>
                            </div>
                          </FormElement>
                        )}
                      />
                    </div>
                  </div>
                </Dialog>
              )}
              { userInfoVisible &&
                <UserSearchList conn = {conn} setUserInfo = {selectUserId} setVisible = {setUserInfoVisible} />
              }
            </div>
            {loading && (
              <p className={styles.eventLoading}>
                <BeatLoader loading={true} size={24} color="#1e88e5" />
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EventLog;
