import { useEffect, useState, useRef } from "react";

export default function Time() {
  const [time, setTime] = useState(new Date());
  const id = useRef(null);
  const hour = time.getHours();
  const minute = time.getMinutes();
  const second = time.getSeconds();

  useEffect(() => {
    if (id.current) {
      clearInterval(id.current);
    }
    id.current = setInterval(() => setTime(new Date()), 1000);
  }, []);

  return (
    <div style={{ marginBottom: "20px", fontSize: '1em' }}>{`${hour.toString().length < 2 ? "0" + hour : hour}:${
      minute.toString().length < 2 ? "0" + minute : minute
    }:${second.toString().length < 2 ? "0" + second : second}`}</div>
  );
}
