import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isPowerEnsoType, numFormat, roundNum } from "../../../com/dataUtil";

const HourEnergyChart = ({ hourEnergyList, ensoTypeCode, height = "305px" }) => {
  const setChartData = (data, isEnergy) =>
    data.map(v => ({
      name: v.term,
      y: !isEnergy ? (v.energy ? v.energy : 0) : v.rate ? v.rate : 0,
      color: !isEnergy ? v.color || "#EE7951" : "#6DB6EF",
    }));

  const hourEnergyChartOptions = {
    chart: {
      type: "column",
      height: height,
      backgroundColor: "#f5f5f5",
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      categories: hourEnergyList.map(v => v.term),
      crosshair: true,
    },
    yAxis: [
      {
        startOnTick: false,
        title: {
          text: isPowerEnsoType(ensoTypeCode) ? "발전량(kWh)" : "생산량(kWh)",
        },
        labels: {
          formatter: function () {
            return numFormat(roundNum(this.value, 2));
          },
        },
      },
      {
        min: 0,
        max: 100,
        opposite: true,
        title: {
          text: isPowerEnsoType(ensoTypeCode) ? "발전효율(%)" : "생산효율(%)",
        },
        labels: {
          formatter: function () {
            return numFormat(roundNum(this.value, 2));
          },
        },
      },
    ],
    plotOptions: {
      series: {
        color: "#6DB6EF",
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "13px",
          },
          formatter: function () {
            return `<span><b>${numFormat(roundNum(this.y, 2))}</b></span>`
          },
        },
        marker: {
          lineWidth: 2,
          lineColor: "#6DB6EF",
          fillColor: "white",
        },
      },
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let msg = "<span><b>" + this.x + "시</b></span><br>";
        if (this.points[0]) {
          msg +=
            '<span style="color:' +
            this.points[0].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[0].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(roundNum(this.points[0].y, 2)) +
            " (kWh)" +
            "</b>" +
            "<br>";
        }
        if (this.points[1]) {
          msg +=
            '<span style="color:' +
            this.points[1].point.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.points[1].series.name +
            "</span>" +
            " : " +
            "<b>" +
            numFormat(roundNum(this.points[1].y, 2)) +
            " (%)" +
            "</b>" +
            "<br>";
        }
        return msg;
      },
    },
    series: [
      {
        yAxis: 0,
        name: isPowerEnsoType(ensoTypeCode) ? "발전량" : "생산량",
        data: setChartData(hourEnergyList),
      },
      {
        yAxis: 1,
        name: "효율",
        data: setChartData(hourEnergyList, true),
        type: "spline",
      },
    ],
  };

  return <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={hourEnergyChartOptions} />;
};

export default HourEnergyChart;
