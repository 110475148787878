/**
 * 검색 객체 파라미터로 검색 조건에 따른 네비게이션 설정 ( 사업연도, 시군구, 에너지원)
 * @param {검색 객체 파라미터} search
 * @returns search
 */
export const makeNavigator = search => {
  let navigator = "";
  // 사업연도 설정
  if (search.bizYy) {
    if (search.bizYy !== "사업연도") {
      navigator += "사업연도 : ";
      navigator += search.bizYy;
    }
  }
  // 시군구 설정
  if (search.rgn.rgnCode) {
    // 네비게이션 설정된 내용이 있다면 구분 처리
    if (navigator) {
      navigator += " , ";
    }
    navigator += "시군구 : ";
    navigator += search.rgn.rgnTerm;
  }
  // 에너지원 설정
  if (search.energyType.codeNo) {
    // 네비게이션 설정된 내용이 있다면 구분 처리
    if (navigator) {
      navigator += " , ";
    }
    navigator += "에너지원 : ";
    navigator += search.energyType.codeVal;
  }

  // 네비게이션 내용이 없다면 전체 설정
  if (!navigator) {
    navigator = "전체";
  }

  search.navi = navigator;
  return search;
};

/**
 * 설치 장소의 시도 , 시군구 제외 컴포넌트
 * @param { KENDO GRID 에서 받은 CELL PROPS } data
 * @returns
 */
export const addressFormatCell = (data, search) => {
  const dataItem = data.dataItem;
  let address = "";

  if (dataItem.instlAddr) {
    // 시도 , 시군구 제외 로직
    const instlAddr = dataItem.instlAddr;
    // const addressFix = instlAddr.substring(instlAddr.indexOf(" ") + 1, instlAddr.length);
    // const addressFix2 = addressFix.substring(addressFix.indexOf(" ") + 1, addressFix.length);
    // if (search.cityProvCode === "") {
      address = instlAddr;
    // } else if (search.rgnCode === "") {
      // address = addressFix;
    // } else {
      // address = addressFix2;
    // }
  } else {
    address = " - ";
  }
  return address;
};

/**
 * 상세 주소 가공 설정
 * @param {kendo grid row data} data
 * @param {검색 객체} search
 * @returns
 */
export const addressFormatFixFuc = (data, search) => {
  let address = data.dataItem.instlAddr;
  // console.log("search.navi = ", search.navi);
  // 시가 포함하면 아래 로직 적용
  // if( search.navi.includes('시') ){
  address = addressFormatCell(data, search);
  // }
  return <td>{address}</td>;
};

/**
 * 그리드에서 "에너지원(용량)" 컬럼 데이터 포맷 설정
 * @param {kendo grid props} props
 * @param {에너지원 용량, 단위 리스트} infoList
 * @returns
 */
export const instCapaAndUnitByEnergyType = (props, infoList) => {
  let result = "";
  let data = "";
  if (props.dataItem.moniYn === "Y") {
    result = "모니터링고도화";
  } else {
    if (infoList.length !== 0) {
      for (var i = 0; i < infoList.length; i++) {
        // 용량값과 단위를 입력 하지 않았을 경우
        if (!infoList[i].instCapa && !infoList[i].unit) {
          data = infoList[i].energyTypeCodeVal + "( 미등록 )";
        } else {
          data = infoList[i].energyTypeCodeVal + "( " + infoList[i].instCapa + " " + infoList[i].unit + " )";
        }
        // 에너지원이 여러개 일경우 , 로 구분 처리
        if (i !== infoList.length - 1) {
          data += ", ";
        }
        result += data;
      }
    } else {
      result = "설비 미등록";
    }
  }
  return result;
};
