import React from "react";

// import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
// import { getToken } from "../../../../service/authService";
// import { Upload } from "@progress/kendo-react-upload";
// import { Window } from "@progress/kendo-react-dialogs";

const BizFileBpm2 = ({
  onAddFile,
  onRemoveFile,
  onStatusChangeFile,
  fileState,
  windowVisible,
  uploadFileEvt,
  deleteFileEvt,
  file,
  downloadFileEvt,
  saveFileUrl,
  removeFileUrl,
  styles,
  IntlProvider,
  LocalizationProvider,
  getToken,
  Upload,
  Window,
}) => {
  let fileLinkBtn = {
    border: "none",
    background: "transparent",
    color: "#0082ff",
  }

  return (
    <>
      <div className="JinTabMo_con" id="JinTabModalBB_con">
        <div className="SubM_title">[ 첨부서류 ]</div>
        <div className="info_text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; · <span className="red_cl">50MB이상의 파일은 첨부하실 수 없습니다.</span>(이미지파일은
          JPEG,JPG,GIF로 변환하여 첨부하셔야 합니다.)
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; · 첨부파일을 수정하시려면 반드시 삭제후 재등록 하셔야 합니다.
          <br />
        </div>
        <div className="SubM_title">[ 태양광 ]</div>
        <div className="eer-Table-scr" style={{ height: "330px", marginTop: "10px" }}>
          <table className="remcBB-Table eer-Table-striped">
            <colgroup>
              <col width="700" />
              <col width="250" />
              <col width="" />
              <col width="250" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>필수</th>
                <th>첨부파일</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  1.인증서
                  <br />
                  - 신청서상 인증번호 모델명과 첨부된 인증서 동일
                  <br />
                  - 해당품목 인증제품 없을 경우 KOLAS인증 시험기관의 시험성적서로 대체
                  <br />
                  - 태양광의 경우, 접속함 인증서 및 시험성적서 추가 제출(’18년 권장, ’19년부터 의무)
                  <br />
                  ※ KS, 신재생에너지 인증서 내 출력일시, 출력자명(담당자), 인증서사용처(용도) 기재된 신규인증서로 첨부 요망
                  <br />- 태양광의 경우, 저탄소 모듈 사용 시 탄소배출량 검증인정서 필수 추가 제출(주택,건물,융복합,설치의무화,
                  공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2S1 &&
                    <button name="bpm2" id="B2S1" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S1.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S1 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S1"
                    onClick={file?.B2S1 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S1 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  2.하자보증서
                  <br />
                  - 신청서상 총사업비와 하자보증서상 계약금액이 동일
                  <br />
                  (주택,건물,융복합,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2S2 &&
                    <button name="bpm2" id="B2S2" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S2.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S2 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S2"
                    onClick={file?.B2S2 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S2 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  3.설계도면
                  <br />
                  - 전력시설물에 해당하는 경우(사용전검사 및 사용전점검 대상 설비 등) 설계도면 및 시방서 추가 제출
                  <br />
                  - 전력기술관리법 제11조에 따른 전기분야 기술사, 설계사 및 설계업자(제14조 1항에 따라 설계업 등록한 자) 직인 날인
                  <br />
                  (주택,건물,융복합,지역,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2S3 &&
                    <button name="bpm2" id="B2S3" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S3.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S3 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S3"
                    onClick={file?.B2S3 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S3 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  4.공정사진
                  <br />
                  (주택,건물,융복합,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2S4 &&
                    <button name="bpm2" id="B2S4" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S4.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S4 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S4"
                    onClick={file?.B2S4 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S4 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  5.모니터링설비 설치기준 내용
                  <br />
                  - 50kW 이상의 발전설비(수소·연료전지 : 1kW 초과설비)
                  <br />
                  - 200㎡ 이상의 태양열설비
                  <br />
                  - 175kW 이상의 지열설비
                  <br />
                  (주택,건물,융복합,설치의무화)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2S5 &&
                    <button name="bpm2" id="B2S5" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S5.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S5 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S5"
                    onClick={file?.B2S5 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S5 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  6.사용전 점검필증(태양광, 연료전지, 풍력)
                  <br />
                  - 10kw미만 : 사용전 점검 확인증
                  <br />
                  - 10kw이상 : 사용전 검사 확인증
                  <br />
                  (주택,건물,융복합,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2S6 &&
                    <button name="bpm2" id="B2S6" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S6.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S6 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S6"
                    onClick={file?.B2S6 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S6 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  10.현장점검표 및 설치완료 확인서
                  <br />
                  - 현장점검표
                  <br />
                  가) 지역,설치의무화: 소유자(기관장(직인), 소속 직책 현장확인자(기관현장감독자)
                  <br />
                  나) 주택, 건물: 소유자(신청자), 소속 직책 현장확인자(시공기업감독자)
                  <br />
                  다) 융복합 : 소유자(신청자), 소속 직책 현장확인자(주관기관담당자(직인))
                  <br />
                  라) 공공기관태양광 : 소유자(기관장(직인)), 소속 직책 현장확인자(시공기업감독자)
                  <br />
                  ※미해당 사항에 제외 체크
                  <br />
                  (주택, 건물, 지역, 융복합, 설치의무화, 공공기관태양광)
                  <br />
                  - 설치완료확인서(날짜, 소유자 서명 확인)
                  <br />
                  (주택, 건물)
                  <br />
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2S10 &&
                    <button name="bpm2" id="B2S10" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S10.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S10 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S10"
                    onClick={file?.B2S10 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S10 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>11.자체설치확인보고서 (지역/융복합)</td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2S11 &&
                    <button name="bpm2" id="B2S11" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S11.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S11 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S11"
                    onClick={file?.B2S11 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S11 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  15.설치대상시설 등기부등본 또는 건축물대장(신축건물:건축허가서/건축신고서)
                  <br />
                  (융복합, 공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2S15 &&
                    <button name="bpm2" id="B2S15" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S15.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S15 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S15"
                    onClick={file?.B2S15 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S15 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  16.설치대상시설 토지대장(토지 위 설치 시 첨부. 단, 사업승인단계에서 서류검토시 미제출된 건에 한해서만 제출)
                  <br />
                  (주택)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2S16 &&
                    <button name="bpm2" id="B2S16" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S16.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S16 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S16"
                    onClick={file?.B2S16 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S16 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  17.모니터링설비(RTU) 사진
                  <br />
                  - 외함전경사진, 외함내부사진, RTU에 부착한 명판(스티커) 사진
                  <br />
                  - 특히, RTU 명판(스티커)는 사진상으로 작성 내용이 확인되어야 함<br />
                  ※ 모델명, CID번호, 설치계획서 번호, KC 인증번호, 제조사, 제조연월
                  <br />
                  (주택(공공임대), 건물, 융복합, 지역, 공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2S17 &&
                    <button name="bpm2" id="B2S17" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S17.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S17 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S17"
                    onClick={file?.B2S17 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S17 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>18.성실의무이행각서</td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2S18 &&
                    <button name="bpm2" id="B2S18" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S18.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S18 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S18"
                    onClick={file?.B2S18 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S18 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  19.구조안전확인서
                  <br />
                  - 3.3kW 초과 건물설치형 설비 및 BAPV형 제출
                  <br />
                  - 기초 지지대에 직접 고정이 불가능한 경우 제출
                  <br />- 건축법 제67조에 따른 관계전문기술자 직인 날인
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2S19 &&
                    <button name="bpm2" id="B2S19" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S19.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S19 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S19"
                    onClick={file?.B2S19 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S19 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  20. 기타-현장부적합사항
                  <br />
                  (보완서류,지지대 재질 Mill Sheet 등)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2S20 &&
                    <button name="bpm2" id="B2S20" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2S20.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2S20 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2S20"
                    onClick={file?.B2S20 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2S20 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="SubM_title">[ 태양열 ]</div>
        <div class="eer-Table-scr" style={{ height: "330px" }}>
          <table class="remcBB-Table eer-Table-striped">
            <colgroup>
              <col width="700" />
              <col width="250" />
              <col width="" />
              <col width="250" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>필수</th>
                <th>첨부파일</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  1.인증서
                  <br />
                  - 신청서상 인증번호 모델명과 첨부된 인증서 동일
                  <br />
                  - 해당품목 인증제품 없을 경우 KOLAS인증 시험기관의 시험성적서로 대체
                  <br />
                  - 태양광의 경우, 접속함 인증서 및 시험성적서 추가 제출(’18년 권장, ’19년부터 의무)
                  <br />
                  ※ KS, 신재생에너지 인증서 내 출력일시, 출력자명(담당자), 인증서사용처(용도) 기재된 신규인증서로 첨부 요망
                  <br />- 태양광의 경우, 저탄소 모듈 사용 시 탄소배출량 검증인정서 필수 추가 제출(주택,건물,융복합,설치의무화,
                  공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2H1 &&
                    <button name="bpm2" id="B2H1" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H1.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H1 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H1"
                    onClick={file?.B2H1 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H1 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  2.하자보증서
                  <br />
                  - 신청서상 총사업비와 하자보증서상 계약금액이 동일
                  <br />
                  (주택,건물,융복합,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2H2 &&
                    <button name="bpm2" id="B2H2" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H2.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H2 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H2"
                    onClick={file?.B2H2 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H2 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  3.설계도면
                  <br />
                  - 전력시설물에 해당하는 경우(사용전검사 및 사용전점검 대상 설비 등) 설계도면 및 시방서 추가 제출
                  <br />
                  - 전력기술관리법 제11조에 따른 전기분야 기술사, 설계사 및 설계업자(제14조 1항에 따라 설계업 등록한 자) 직인 날인
                  <br />
                  (주택,건물,융복합,지역,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2H3 &&
                    <button name="bpm2" id="B2H3" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H3.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H3 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H3"
                    onClick={file?.B2H3 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H3 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  4.공정사진
                  <br />
                  (주택,건물,융복합,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2H4 &&
                    <button name="bpm2" id="B2H4" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H4.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H4 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H4"
                    onClick={file?.B2H4 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H4 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  5.모니터링설비 설치기준내용
                  <br />
                  - 50kW 이상의 발전설비(수소·연료전지 : 1kW 초과설비)
                  <br />
                  - 200㎡ 이상의 태양열설비
                  <br />
                  - 175kW 이상의 지열설비
                  <br />
                  (주택,건물,융복합,설치의무화)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2H5 &&
                    <button name="bpm2" id="B2H5" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H5.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H5 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H5"
                    onClick={file?.B2H5 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H5 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  9.수압시험결과 자체기록서(태양열,지열)
                  <br />
                  - 단위 Mpa, 최고사용압력의 1.5배
                  <br />
                  (주택,건물,융복합,설치의무화)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2H9 &&
                    <button name="bpm2" id="B2H9" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H9.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H9 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H9"
                    onClick={file?.B2H9 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H9 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  10.현장점검표 및 설치완료 확인서
                  <br />
                  - 현장점검표
                  <br />
                  가) 지역,설치의무화: 소유자(기관장(직인), 소속 직책 현장확인자(기관현장감독자)
                  <br />
                  나) 주택, 건물: 소유자(신청자), 소속 직책 현장확인자(시공기업감독자)
                  <br />
                  다) 융복합 : 소유자(신청자), 소속 직책 현장확인자(주관기관담당자(직인))
                  <br />
                  라) 공공기관태양광 : 소유자(기관장(직인)), 소속 직책 현장확인자(시공기업감독자)
                  <br />
                  ※미해당 사항에 제외 체크
                  <br />
                  (주택, 건물, 지역, 융복합, 설치의무화, 공공기관태양광)
                  <br />
                  - 설치완료확인서(날짜, 소유자 서명 확인)
                  <br />
                  (주택, 건물)
                  <br />
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2H10 &&
                    <button name="bpm2" id="B2H10" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H10.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H10 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H10"
                    onClick={file?.B2H10 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H10 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>11.자체설치확인보고서 (지역/융복합)</td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2H11 &&
                    <button name="bpm2" id="B2H11" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H11.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H11 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H11"
                    onClick={file?.B2H11 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H11 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  15.설치대상시설 등기부등본 또는 건축물대장(신축건물:건축허가서/건축신고서)
                  <br />
                  (융복합, 공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2H15 &&
                    <button name="bpm2" id="B2H15" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H15.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H15 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H15"
                    onClick={file?.B2H15 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H15 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  16.설치대상시설 토지대장(토지 위 설치 시 첨부. 단, 사업승인단계에서 서류검토시 미제출된 건에 한해서만 제출)
                  <br />
                  (주택)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2H16 &&
                    <button name="bpm2" id="B2H16" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H16.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H16 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H16"
                    onClick={file?.B2H16 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H16 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  17.모니터링설비(RTU) 사진
                  <br />
                  - 외함전경사진, 외함내부사진, RTU에 부착한 명판(스티커) 사진
                  <br />
                  - 특히, RTU 명판(스티커)는 사진상으로 작성 내용이 확인되어야 함<br />
                  ※ 모델명, CID번호, 설치계획서 번호, KC 인증번호, 제조사, 제조연월
                  <br />
                  (주택(공공임대), 건물, 융복합, 지역, 공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2H17 &&
                    <button name="bpm2" id="B2H17" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H17.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H17 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H17"
                    onClick={file?.B2H17 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H17 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>18.성실의무이행각서</td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2H18 &&
                    <button name="bpm2" id="B2H18" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H18.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H18 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H18"
                    onClick={file?.B2H18 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H18 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  19.구조안전확인서
                  <br />
                  - 3.3kW 초과 건물설치형 설비 및 BAPV형 제출
                  <br />
                  - 기초 지지대에 직접 고정이 불가능한 경우 제출
                  <br />- 건축법 제67조에 따른 관계전문기술자 직인 날인
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2H19 &&
                    <button name="bpm2" id="B2H19" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H19.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H19 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H19"
                    onClick={file?.B2H19 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H19 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  20. 기타-현장부적합사항
                  <br />
                  (보완서류,지지대 재질 Mill Sheet 등)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2H20 &&
                    <button name="bpm2" id="B2H20" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2H20.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2H20 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2H20"
                    onClick={file?.B2H20 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2H20 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="SubM_title">[ 지열 ]</div>
        <div class="eer-Table-scr" style={{ height: "330px" }}>
          <table class="remcBB-Table eer-Table-striped">
            <colgroup>
              <col width="700" />
              <col width="250" />
              <col width="" />
              <col width="250" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>필수</th>
                <th>첨부파일</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  1.인증서
                  <br />
                  - 신청서상 인증번호 모델명과 첨부된 인증서 동일
                  <br />
                  - 해당품목 인증제품 없을 경우 KOLAS인증 시험기관의 시험성적서로 대체
                  <br />
                  - 태양광의 경우, 접속함 인증서 및 시험성적서 추가 제출(’18년 권장, ’19년부터 의무)
                  <br />
                  ※ KS, 신재생에너지 인증서 내 출력일시, 출력자명(담당자), 인증서사용처(용도) 기재된 신규인증서로 첨부 요망
                  <br />- 태양광의 경우, 저탄소 모듈 사용 시 탄소배출량 검증인정서 필수 추가 제출(주택,건물,융복합,설치의무화,
                  공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH1 &&
                    <button name="bpm2" id="B2HH1" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH1.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH1 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH1"
                    onClick={file?.B2HH1 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH1 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  2.하자보증서
                  <br />
                  - 신청서상 총사업비와 하자보증서상 계약금액이 동일
                  <br />
                  (주택,건물,융복합,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH2 &&
                    <button name="bpm2" id="B2HH2" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH2.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH2 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH2"
                    onClick={file?.B2HH2 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH2 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  3.설계도면
                  <br />
                  - 전력시설물에 해당하는 경우(사용전검사 및 사용전점검 대상 설비 등) 설계도면 및 시방서 추가 제출
                  <br />
                  - 전력기술관리법 제11조에 따른 전기분야 기술사, 설계사 및 설계업자(제14조 1항에 따라 설계업 등록한 자) 직인 날인
                  <br />
                  (주택,건물,융복합,지역,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH3 &&
                    <button name="bpm2" id="B2HH3" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH3.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH3 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH3"
                    onClick={file?.B2HH3 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH3 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  4.공정사진
                  <br />
                  (주택,건물,융복합,설치의무화,공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH4 &&
                    <button name="bpm2" id="B2HH4" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH4.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH4 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH4"
                    onClick={file?.B2HH4 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH4 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  5.모니터링설비 설치기준내용
                  <br />
                  - 50kW 이상의 발전설비(수소·연료전지 : 1kW 초과설비)
                  <br />
                  - 200㎡ 이상의 태양열설비
                  <br />
                  - 175kW 이상의 지열설비
                  <br />
                  (주택,건물,융복합,설치의무화)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2HH5 &&
                    <button name="bpm2" id="B2HH5" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH5.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH5 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH5"
                    onClick={file?.B2HH5 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH5 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  7.시운전데이터(지열)
                  <br />
                  - 12시간 이상 운전자료
                  <br />
                  (주택,건물,융복합,설치의무화)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH7 &&
                    <button name="bpm2" id="B2HH7" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file?.B2HH7 ? file.B2HH7.fileName : null}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH7 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH7"
                    onClick={file?.B2HH7 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH7 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  8.열펌프유니트 안전검사증(지열)
                  <br />
                  - 17.5kw초과시에만 해당
                  <br />
                  (주택,건물,융복합,설치의무화)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2HH8 &&
                    <button name="bpm2" id="B2HH8" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH8.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH8 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH8"
                    onClick={file?.B2HH8 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH8 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  9.수압시험결과 자체기록서(태양열,지열)
                  <br />
                  - 단위 Mpa, 최고사용압력의 1.5배
                  <br />
                  (주택,건물,융복합,설치의무화)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH9 &&
                    <button name="bpm2" id="B2HH9" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH9.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH9 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH9"
                    onClick={file?.B2HH9 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH9 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  10.현장점검표 및 설치완료 확인서
                  <br />
                  - 현장점검표
                  <br />
                  가) 지역,설치의무화: 소유자(기관장(직인), 소속 직책 현장확인자(기관현장감독자)
                  <br />
                  나) 주택, 건물: 소유자(신청자), 소속 직책 현장확인자(시공기업감독자)
                  <br />
                  다) 융복합 : 소유자(신청자), 소속 직책 현장확인자(주관기관담당자(직인))
                  <br />
                  라) 공공기관태양광 : 소유자(기관장(직인)), 소속 직책 현장확인자(시공기업감독자)
                  <br />
                  ※미해당 사항에 제외 체크
                  <br />
                  (주택, 건물, 지역, 융복합, 설치의무화, 공공기관태양광)
                  <br />
                  - 설치완료확인서(날짜, 소유자 서명 확인)
                  <br />
                  (주택, 건물)
                  <br />
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH10 &&
                    <button name="bpm2" id="B2HH10" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH10.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH10 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH10"
                    onClick={file?.B2HH10 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH10 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>11.자체설치확인보고서 (지역/융복합)</td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH11 &&
                    <button name="bpm2" id="B2HH11" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH11.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH11 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH11"
                    onClick={file?.B2HH11 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH11 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  15.설치대상시설 등기부등본 또는 건축물대장(신축건물:건축허가서/건축신고서)
                  <br />
                  (융복합, 공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2HH15 &&
                    <button name="bpm2" id="B2HH15" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH15.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH15 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH15"
                    onClick={file?.B2HH15 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH15 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  16.설치대상시설 토지대장(토지 위 설치 시 첨부. 단, 사업승인단계에서 서류검토시 미제출된 건에 한해서만 제출)
                  <br />
                  (주택)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2HH16 &&
                    <button name="bpm2" id="B2HH16" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH16.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH16 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH16"
                    onClick={file?.B2HH16 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH16 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  17.모니터링설비(RTU) 사진
                  <br />
                  - 외함전경사진, 외함내부사진, RTU에 부착한 명판(스티커) 사진
                  <br />
                  - 특히, RTU 명판(스티커)는 사진상으로 작성 내용이 확인되어야 함<br />
                  ※ 모델명, CID번호, 설치계획서 번호, KC 인증번호, 제조사, 제조연월
                  <br />
                  (주택(공공임대), 건물, 융복합, 지역, 공공기관태양광)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH17 &&
                    <button name="bpm2" id="B2HH17" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH17.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH17 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH17"
                    onClick={file?.B2HH17 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH17 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>18.성실의무이행각서</td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2HH18 &&
                    <button name="bpm2" id="B2HH18" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH18.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH18 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH18"
                    onClick={file?.B2HH18 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH18 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>
                  19.구조안전확인서
                  <br />
                  - 3.3kW 초과 건물설치형 설비 및 BAPV형 제출
                  <br />
                  - 기초 지지대에 직접 고정이 불가능한 경우 제출
                  <br />- 건축법 제67조에 따른 관계전문기술자 직인 날인
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.B2HH19 &&
                    <button name="bpm2" id="B2HH19" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH19.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH19 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH19"
                    onClick={file?.B2HH19 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH19 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  20. 기타-현장부적합사항
                  <br />
                  (보완서류,지지대 재질 Mill Sheet 등)
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  N
                </td>
                <td>
                  {file?.B2HH20 &&
                    <button name="bpm2" id="B2HH20" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.B2HH20.fileName}
                    </button>
                  }
                </td>
                <td className={styles.tableCenter} style={{ textAlign: "center" }}>
                  <button
                    className={file?.B2HH20 ? "redBg" : "btn_pls"}
                    name="bpm2"
                    id="B2HH20"
                    onClick={file?.B2HH20 ? deleteFileEvt : uploadFileEvt}>
                    {file?.B2HH20 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {windowVisible && (
        <Window title={"파일 등록"} onClose={uploadFileEvt} top={186} left={483} initialHeight={350} initialWidth={600} modal={true}>
          <div>
            {/* 윈도우 창 */}
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Upload
                  autoUpload={false}
                  batch={true}
                  multiple={false}
                  files={fileState.fileList}
                  defaultFiles={[]}
                  onAdd={onAddFile}
                  onRemove={onRemoveFile}
                  onStatusChange={onStatusChangeFile}
                  withCredentials={true}
                  removeVerb={"DELETE"}
                  restrictions={{
                    allowedExtensions: [
                      ".gif",
                      ".jpg",
                      ".png",
                      // ".xls",
                      // ".xlsx",
                      ".hwp",
                      ".doc",
                      ".pdf",
                      ".exe",
                      ".zip",
                    ],
                    maxFileSize: 51200000,
                  }}
                  saveMethod={"post"}
                  saveHeaders={{
                    "Content-Type": "multipart/form-data",
                    "X-AUTH-TOKEN": getToken(),
                  }}
                  saveUrl={saveFileUrl}
                  removeUrl={removeFileUrl}
                />
              </IntlProvider>
            </LocalizationProvider>
            {/* <div>
              <Button>저장</Button>
              <Button>취소</Button>
            </div> */}
          </div>
        </Window>
      )}
    </>
  );
};

export default BizFileBpm2;
