// 지역 효율 공통
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat, roundNum } from '../../../../com/dataUtil';

const AreaEficChart = ( {dataSource , ensoTypeNo , clickDrillDown} ) =>{
    // 차트 옵션
    const chartConfig = {
        chart: {
            zoomType: 'xy'
        },
        noData: {
            style:{
                color: '#000000',
                fontSize: '14px'
            }
        },
        tooltip: {
            shared: true,
            formatter: function (e) {
                return(
                    `
                        <b>${this.points[0].point.name}</b><br/>
                        <span style="color:${this.points[0].point.color}">\u25CF</span>
                        ${this.points[0].series.name} : ${roundNum(this.points[0].y,2)} ${ensoTypeNo === 15003 ? ' (COP)':' %'}<br/>
                        <span style="color:${this.points[1].point.color}">\u25CF</span>
                        ${this.points[1].series.name} : ${Math.round(this.points[1].y)} % (${numFormat(roundNum(this.points[1].point.instCapa, 2))} kW)
                    `
                );
            }
        },
        title: {
            text: null
        },
        credits : {
            enabled: false
        },
        plotOptions: {
            column : {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function(e){
                            clickDrillDown(e.point.rgnCode)
                        }
                    }
                }
            },
            series: {
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        
                     }
                }
            }
        },
        xAxis: [{
            type : 'category',
            crosshair: true, 
            labels : { style: { color: '#000000' } }
        }],
        yAxis: [{ // Primary yAxis
            labels: {
                format: '{value:,.2f}',
                style: {
                    color: "#69bcc8"
                }
            },
            title: {
                text: '평균효율(%)',
                style: {
                    color: "#69bcc8"
                },
                rotation: 0,
                y: -20,
                offset: 0,
                align: 'high'
            }
        }, { // Secondary yAxis
            title: {
                text: '설비용량비율(%)',
                style: {
                    color: "#c1ce01"
                },
                rotation: 0,
                y: -20,
                offset: 0,
                align: 'high'
            },
            labels: {
                format: '{value:,.0f}',
                style: {
                    color: "#c1ce01"
                }
            },
            max:100,
            opposite: true
        }],
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
        },
        series: [{
            name: '평균효율',
            type: 'column',
            color: '#69bcc8',
            data: dataSource.meainTypeData,
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f} %</b><br/>'
            }

        }, {
            name: '설비용량비율',
            type: 'column',
            yAxis: 1,
            color: '#c1ce01',
            data: dataSource.meainTypeInstCapaData,
            tooltip: {
                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.1f} %</b><br/>'
            }
        }]
    };

    return(
        <div>
            <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
        </div>
    )
}

export default AreaEficChart;