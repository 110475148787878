import styles from './regiLoraLog.module.css';
import React, {useState, useEffect, useCallback} from 'react'
import { BeatLoader } from 'react-spinners'
import {Button} from '@progress/kendo-react-buttons'
import {Grid, GridColumn as Column, GridToolbar, GridNoRecords} from "@progress/kendo-react-grid";
// import kendo from '@progress/kendo-ui';
import { parseDate } from '../../../com/dateUtil';
import { downloadExcel } from '../../../com/downloadExcel';
import { DropDownList } from '@progress/kendo-react-dropdowns';
// import { ExcelExport } from '@progress/kendo-react-excel-export';
import { getLoraLog, getLogRegiIdList } from '../../../service/mngt/loraService';
import {getMessage} from '../../../com/messageUtil';
import {
    LocalizationProvider,
    loadMessages
} from "@progress/kendo-react-intl";
import koMessage from "../../../com/ko-language.json"
import './regiLoraLog.css';
loadMessages(koMessage, "ko-KR");

const RegiLoraLog = ({conn}) => {

      // 로딩 스피너
    const [loading, setLoading] = useState(true);
      // 엑셀 로딩 스피너
    const [excelLoading, setExcelLoading] = useState(false);

    // 테이블 데이터
    let [dataSource, setDataSource] = useState([])

    /* 페이징 */
    const [pageState, setPageState] = useState({
        take: 15
        , skip: 0
        , total: 0
        , sort: []
    });

    // 로라 등록 ID
    let [regiIdData, setRegiIdData] = useState({
        userIdArr: []
        , filterUserIdArr: []
        , selectUserId: '전체'
    });

    useEffect(() => {
        getRegiId();
        getDataSource();
        /* eslint-disable-next-line */
    }, []);

    function getRegiId() {
        getLogRegiIdList(conn)
            .then(response => {
                setRegiIdData({...regiIdData, userIdArr: response, filterUserIdArr: response});
            })
            .catch(res => {
                alert(getMessage(res.errCode));
            });
    }


    // 데이터 axios 
    function getDataSource() {
        getLoraLog(conn, setLoading, pageState)
            .then(response => {
                const {results, total} = response;
                setDataSource(results);
                setPageState({...pageState, total});
                setLoading(false);
            })
            .catch(res => {
                setLoading(false);
                // console.error(res);
                alert(getMessage(res.errCode));
            });
    }


    // 그리드 페이징 처리
    const handlePageChange = useCallback(e => {
        const {take, skip} = e.page;
        const {sort} = pageState;
        const {selectUserId} = regiIdData;

        // const stateObj = new Object();
        // stateObj.take = take;
        // stateObj.skip = skip;
        const stateObj = { take: take, skip: skip };
        if (sort[0] !== undefined) {
            if (sort[0].field === 'regiId') {
                stateObj.sort = 'user.userId,' + sort[0].dir;
            } else if (sort[0].field === 'userTerm') {
                stateObj.sort = 'user.userTerm,' + sort[0].dir;
            } else {
                stateObj.sort = sort[0].field + ',' + sort[0].dir;
            }
        } else {
            delete stateObj.sort;
        }

        getLoraLog(conn, setLoading, stateObj, selectUserId)
            .then(response => {
                const {results, total} = response;
                setDataSource(results);
                setPageState({...pageState, take, skip, total});
                setLoading(false);
            })
            .catch(res => {
                // console.error(res);
                alert(getMessage(res.errCode));
            });
            /* eslint-disable-next-line */
    }, [dataSource, pageState]);

    // function setFileName() {
    //     let template = "LoRa로그목록_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
    //     return template;
    // }

    // 엑셀다운
    const handleExcelDown = useCallback(e => {
        setExcelLoading(true);
        e.preventDefault();
        const url = '/api/v2.2/iot/sk/log';
        const data = [];
        const fileName = 'LoRa로그목록_' + parseDate('yyyymmddhhmmss') + ".xlsx";

        console.log("url", url, data, fileName);
        downloadExcel(data, fileName, url, setExcelLoading);
    }, []);

    // 엑셀 다운로드
    // let _export;
    // const downloadExcel = () => {
    //     console.log("Download Excel");
    //     setFileNameExcel(
    //         setFileName()
    //     )
    //     _export.save();
    // }

    /* 페이징 이벤트 */
    // const pageChange = (event) => {
    //     setPageState((prevState) => ({
    //         ...prevState,
    //         skip: event.page.skip,
    //         take: event.page.take,
    //     }));
    // };

    // 등록 ID 드롭다운리스트 변경 이벤트
    const handleDropDownChange = useCallback(e => {
        const {value} = e.target;

        // const stateObj = new Object();
        // stateObj.take = 15;
        // stateObj.skip = 0;
        // stateObj.sort = [];
        const stateObj = { take: 15, skip: 0, sort: [], };

        regiIdData.selectUserId = value;

        getLoraLog(conn, setLoading, stateObj, value)
            .then(response => {
                const {results, total} = response;
                setDataSource(results);
                setPageState({take: 15, skip: 0, total, sort: []});
                setLoading(false);
            })
            .catch(res => {
                alert(getMessage(res.errCode));
            });
            
    /* eslint-disable-next-line */
    }, [dataSource, pageState]);

    console.log("console",loading);

    // 등록 ID 드롭다운리스트 필터 이벤트
    const handleDropDownFilterChange = useCallback(e => {
        const {value} = e.filter;

        // const filterArr = new Array();
        const filterArr = [];
        regiIdData.userIdArr.forEach(userIdItem => {
            if (userIdItem.startsWith(value)) filterArr.push(userIdItem);
        });

        setRegiIdData({...regiIdData, filterUserIdArr: filterArr});
    }, [regiIdData]);

    const cellKeyFormat = (data) => {
        const field = data.field;
        let value = "-";
        if (field === "regiDtm") {
            value = data.dataItem[field] ? data.dataItem[field].replace("T", " ") : "-";
        } else {
            value = data.dataItem[field] ? data.dataItem[field] : "-";
        }
        return <td style={{textAlign: "center"}}>{value}</td>;
    };

    return (
      <>
      <div className={excelLoading ? styles.regiLoraLogG : ""}>
        <div className={loading ? styles.regiLoraLogG : ""}>
          <LocalizationProvider language="ko-KR">
            <Grid
                className="regiLoraLogGrid"
                // style={{height: "695px", width: "1800px"}}
                data={dataSource}
                {...pageState}
                pageable={{buttonCount: 5}}
                sortable
                onPageChange={handlePageChange}
            >
                 <GridToolbar>
              등록 ID :
              <DropDownList
                data={regiIdData.filterUserIdArr}
                defaultItem='전체'
                defaultValue='전체'
                filterable
                onChange={handleDropDownChange}
                onFilterChange={handleDropDownFilterChange}
              />
              <Button className='excelBtn' onClick={handleExcelDown} icon="excel">엑셀 저장</Button>
            </GridToolbar>
              <Column field="regiDtm" title="등록일시" width="400px" cell={cellKeyFormat}/>
              <Column field="loraDevid" title="LoRa ID" width="400px"/>
              <Column field="loraStatus" title="LoRa 상태" width="280px"/>
              <Column field="regiId" title="등록ID" width="350px"/>
              <Column field="userTerm" title="등록유저명" width="350px"/>
              <GridNoRecords>
                  데이터가 존재하지 않습니다.
              </GridNoRecords>
            </Grid>
          </LocalizationProvider>
        </div>
        { loading && <p className={styles.regiLoraLogLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p>}    
    </div>
    { excelLoading && <p className={styles.regiLoraLogLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p> }
    </>
  )
};
export default RegiLoraLog