// import kendo from "@progress/kendo-ui";

const BizProgressStatisticsMonth = ({ search, dataList }) => {
  const monthDataList = dataList.progressMonthList;
  return (
    <>
      <div class="tbTitle">
        <span class="sel-title">[ 월별 진행률 ]</span>
        <span class="danrightB">단위 : 개소</span>
      </div>
      <table class="eer-Table eer-Table-striped">
        <colgroup>
          <col width="16.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
          <col width="6.666%" />
        </colgroup>
        <thead>
          <tr>
            <td class="backslash left">
              <div>{search?.bizYyNo ? search.bizYyNo : ""}&nbsp;&nbsp;</div>&nbsp;&nbsp;추진현황
            </td>
            <th>1월</th>
            <th>2월</th>
            <th>3월</th>
            <th>4월</th>
            <th>5월</th>
            <th>6월</th>
            <th>7월</th>
            <th>8월</th>
            <th>9월</th>
            <th>10월</th>
            <th>11월</th>
            <th>12월</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>설치완료</td>
            <td>{monthDataList.length > 0 ? monthDataList[0].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[1].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[2].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[3].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[4].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[5].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[6].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[7].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[8].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[9].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[10].meainCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[11].meainCount : 0}</td>
          </tr>
          <tr>
            <td>취소</td>
            <td>{monthDataList.length > 0 ? monthDataList[0].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[1].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[2].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[3].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[4].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[5].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[6].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[7].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[8].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[9].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[10].cancelCount : 0}</td>
            <td>{monthDataList.length > 0 ? monthDataList[11].cancelCount : 0}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default BizProgressStatisticsMonth;
