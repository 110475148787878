export const getNotiList = async (conn, search, paging) => {
  const title = search.title;
  const response = await conn.get("bbs/notice/page", {
    params: {
      // page: page.skip / page.take + 1,
      // size: page.take,
      page: paging.skip / paging.take + 1,
      size: paging.take,
      title: title,
    },
  });
  return response;
};

export const setNoti = async (conn, obj) => {
  const response = await conn.post("bbs/notice", obj, {
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export const setDeleteNoti = async (conn, dataItem) => {
  const response = await conn.delete("bbs/notice", {
    params: {
      noticeNo: dataItem.noticeNo,
    },
  });
  return response;
};

export const setViewCnt = async (conn, data) => {
  const response = await conn.post("bbs/notice/viewCntInc", data, {
    config: {
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export const getDetailList = async (conn, noticeNo) => {
  const response = await conn.get("bbs/notice/detail", {
    params: {
      noticeNo: noticeNo
    }
  });
  return response;
};