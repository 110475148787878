import BizExpendModal from "./bizExpendModal";
import { getBizExpend, getEnteList } from "../../../../service/bizStat/com/expenseApiService";

const BizExpendGrid = ({
  conn,
  styles,
  bizStyles,
  search,
  selected,
  dataList,
  setDataList,
  getData,
  spendItemListData,
  energyTypeCodeListData,
  setBizExpenseWindowVisible,
  setBizFileWindowVisible,
  setPayOrderWindowVisible,
  bizExpendWindowVisible,
  setBizExpendWindowVisible,
  initalBizExpense,
  loadbizExpend,
  Grid,
  GridColumn,
  IntlProvider,
  LocalizationProvider,
  kendo,
  numFormat,
  useState,
  useEffect,
  Form,
  FormElement,
  Button,
  NumberFormat,
  DropDownList,
  Input,
}) => {
  const sumDataCell = e => {    
    let total = "";
    if (e.field === "orderNum") {
      total = "총계";
    } else {
      total = dataList.expendGrid.data.reduce((acc, current) => acc + current[e.field], 0);
    }

    return <td style={{ backgroundColor: "#FFEAEA" }}>{numFormat(total)}</td>;
  };

  const cellOrderFormat = props => {
    var record = 0;
    record = dataList.expendGrid.total - props.dataIndex;
    return <td>{record}</td>;
  };

  const cellNumFormat = e => {
    const item = e.dataItem;
    const field = e.field;

    return <td>{numFormat(item[field])}</td>;
  };

  const state = [
    { field: "orderNum", title: "순번", show: true, cell: cellOrderFormat, footerCell: sumDataCell, width: 50 },
    { field: "paymentName", title: "지급명", show: true },
    { field: "paymentDate", title: "지급일", show: true },
    { field: "totalExpense", title: "총액(원)", show: true, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "gvmSpt", title: "정부(원)", show: true, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "mupal", title: "지자체(원)", show: selected === 0 ? true : false, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "province", title: "도비(원)", show: selected === 1 ? true : false, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "city", title: "시/군비(원)", show: selected === 1 ? true : false, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "priCompany", title: "민간(원)", show: true, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "energyTypeCodeVal", title: "에너지원", show: true },
    { field: "spendItemVal", title: "지출항목", show: true },
    { field: "consEnte", title: "지급업체", show: true },
  ];

  const bizExpendGridAdd = e => {
    if (search.rgnCode !== "" && search.bizYyNo !== "") {
      // getData();
      // if(dataList.consEnteList.length > 0) {
      setBizExpendWindowVisible(state => {
        const item = { ...state };
        item.visible = !bizExpendWindowVisible.visible;
        return item;
      });

      setBizExpenseWindowVisible(false);
      setBizFileWindowVisible(false);
      setPayOrderWindowVisible(false);

      if (!bizExpendWindowVisible.visible) {
        getEnteList(conn, search, setConsEnteList, getRejection);
        initalBizExpense();
      }
      getData();

      console.log("dataList.consEnteList", dataList);
      // } else {
      //   kendo.alert('지급 차수별 현황 등록을 해주세요');
      // }
    } else {
      kendo.alert("사업연도와 시군구를 선택해주세요");
    }
  };

  // 취소 버튼 이벤트
  const cancelBtnEvt = () => {
    setBizExpendWindowVisible(state => {
      const item = { ...state };
      item.visible = !bizExpendWindowVisible.visible;
      return item;
    });
  };

  const setConsEnteList = result => {
    setDataList(data => {
      const item = { ...data };
      item.consEnteList = result;
      return item;
    });
  };

  const getRejection = e => {
    console.log("error = ", e);
  };

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const { take, skip } = e.page;
    search.bizExpendPaging.skip = skip;
    search.bizExpendPaging.take = take;

    getBizExpend(conn, search).then(results => {
      setDataList(dataList => {
        const item = { ...dataList };
        item.expendGrid.data = results.results;
        item.expendGrid.total = results.total;
        return item;
      });
    });
  };

  const expandChange = props => {
    let data = props.dataItem;

    setDataList(dataList => {
      const item = { ...dataList };
      item.bizExpendInput.bizYy = search.bizYyNo;
      item.bizExpendInput.rgnCode = search.rgnCode;
      item.bizExpendInput.cityProvCode = search.cityProvCode;
      item.bizExpendInput.orderNum = data.orderNum;
      item.bizExpendInput.energyTypeCode = data.energyTypeCode;
      item.bizExpendInput.paymentName = data.paymentName;
      item.bizExpendInput.paymentDate = data.paymentDate;
      item.bizExpendInput.totalExpense = data.totalExpense;
      item.bizExpendInput.gvmSpt = data.gvmSpt;
      item.bizExpendInput.mupal = data.mupal;
      item.bizExpendInput.province = data.province;
      item.bizExpendInput.city = data.city;
      item.bizExpendInput.priCompany = data.priCompany;
      item.bizExpendInput.spendItem = data.spendItem;
      item.bizExpendInput.consEnte = data.consEnte;
      return item;
    });

    setBizExpendWindowVisible(state => {
      const item = { ...state };
      item.visible = !bizExpendWindowVisible.visible;
      return item;
    });
  };

  return (
    <div className="uk-width-medium-1-2">
      <div className="md-card">
        <div className="md-card-content">
          {bizExpendWindowVisible.visible && (
            <BizExpendModal
              conn={conn}
              styles={styles}
              bizStyles={bizStyles}
              search={search}
              dataList={dataList}
              setDataList={setDataList}
              spendItemListData={spendItemListData}
              energyTypeCodeListData={energyTypeCodeListData}
              getData={getData}
              bizExpendGridAdd={bizExpendGridAdd}
              cancelBtnEvt={cancelBtnEvt}
              kendo={kendo}
              Form={Form}
              FormElement={FormElement}
              Button={Button}
              useState={useState}
              useEffect={useEffect}
              NumberFormat={NumberFormat}
              DropDownList={DropDownList}
              Input={Input}
            />
          )}
          <div className="md-card-toolbar-conv">
            <h3 className="md-card-toolbar-heading-text-left">사업비 지출현황</h3>
            <div className={styles.convMdCardToolbar}>
              <button className="btn_pls" onClick={bizExpendGridAdd}>
                등록
              </button>
            </div>
          </div>
          <div className="eer-Table-scr" style={{ height: "470px" }}>
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid
                  className="eer-Table eer-Table-striped"
                  // "bizExpendG"
                  data={dataList.expendGrid.data}
                  total={dataList.expendGrid.total}
                  filterable={false}
                  sortable={false}
                  skip={search.bizExpendPaging.skip}
                  take={search.bizExpendPaging.take}
                  pageable={{ buttonCount: 5 }}
                  onPageChange={handlePageChange}
                  onRowClick={expandChange}>
                  {state.map((column, index) => {
                    return (
                      column.show && (
                        <GridColumn
                          key={index}
                          field={column.field}
                          title={column.title}
                          cell={column.cell}
                          footerCell={column.footerCell}
                          width={column.width}
                        />
                      )
                    );
                  })}
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BizExpendGrid;
