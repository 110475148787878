import { ReactComponent as AsanIcon } from "dist/svg/asan-icon.svg";
import { ReactComponent as AsanTitle } from "dist/svg/asan-title-white.svg";
import { ReactComponent as Monitor } from "dist/svg/monitor.svg";
import "./ShortCut.css";

export default function ShortCut() {
    return (
    <div>
      <h3 className="asan-card-title">바로가기</h3>
      <div className="shortcut monitoring">
        <a className="monitoring-container" href="https://kremc.kr/">
          <div className="all-monitoring">
            <div>통합</div>
            <div>모니터링</div>
          </div>
          <div>
            <Monitor className="monitor" />
          </div>
          <div className="asansi-shortcut">바로가기</div>
        </a>
      </div>
      <a className="shortcut asansi" href="https://www.asan.go.kr/main/">
        <AsanIcon className="asan-title-shortcut" />
        <div>
          <AsanTitle className="asan-title-white" />
          <div className="asansi-shortcut">바로가기</div>
        </div>
      </a>
    </div>
  );
}
