// 방위각 / 경사각 15001
import { useState, useEffect, useCallback } from 'react';
import AngleDataChart from './angle_data_chart';
import {getAngleData} from '../../../../service/stats/type/ensoDataService';

const AngleData = ({conn , search, setLoading}) => {

  // 데이터 소스 
  const [dataSource , setDataSource] = useState({ chart : [] , ensoTypeNo : 15001 });

  // 데이터 소스 가져오기
  const getResult = useCallback((getData) => {
    setLoading(false)
    setDataSource( dataSource => { const item = {...dataSource}; item.chart = getData; return item; } );
    // eslint-disable-next-line
  } , [search]);

  // rejection함수
  const getRejection = (error) => {
    setLoading(false)
  }

  useEffect(() => {
    getAngleData( conn  , getResult , getRejection , search );
    // eslint-disable-next-line
  },[getResult]);


  return(
      <div className="uk-grid">
          <div className="uk-width-medium-1-1">
              <div className="md-card">
                  <div className="md-card-content">
                      <div className="md-card-toolbar">
                          <h3 className="md-card-toolbar-heading-text">
                          방위각 경사각별 평균효율
                          </h3>
                      </div>
                      <AngleDataChart dataSource = {dataSource.chart.chart} x_categories = {dataSource.chart.x_categories} y_categories = {dataSource.chart.y_categories} ></AngleDataChart>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default AngleData;