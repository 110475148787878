// React
import React, { useState, useCallback, useEffect } from "react";

// material icons
import { Download, FileUpload, SaveAlt, Dehaze } from "@material-ui/icons";

// kakao 주소검색
import KakaoPostcodeSearch from "../../../com/kakaoPostcodeSearch/kakaoPostcodeSearch";

// kendo
import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Window } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

// loading spinner
import { BeatLoader } from "react-spinners";

// CSS
import "../../bizContents.css";
import convMngtStyles from "./bizProjectInfo.module.css";

// Service
import SearchCom from "../searchCom";
import BizPlanGrid from "./bizPlanGrid";
import BizInstPlanAdd from "./bizInstPlanAdd";
import BizPlanSaveResult from "./bizPlanSaveResult";
import { instCapaAndUnitByEnergyType, makeNavigator } from "../commonFuc";
import { downloadExcel } from "../../../../com/downloadExcel";
import { getMessage } from "../../../../com/messageUtil";
import { setCenterCalc } from "../../../../com/modalUtil";
import GridColumnInputFilter from "../../../com/gridFilter/gridColumnInputFilter";
import CustomFilter from "../../../com/gridFilter/custom_filter";
import { customFilterObj } from "../../../com/gridFilter/custom_filter_fix";
import {
  CODE_BIZ_STAT_SOLA,
  CODE_BIZ_STAT_HEAT,
  CODE_BIZ_STAT_VELO,
  CODE_BIZ_STAT_GEOT,
  CODE_BIZ_STAT_FUEL,
  CODE_BIZ_STAT_WAST,
  CODE_BIZ_STAT_BAIO,
  CODE_BIZ_STAT_COLI,
  CODE_BIZ_STAT_SMPO,
  CODE_BIZ_STAT_HYDR,
  CODE_BIZ_STAT_WOPE,
  CODE_BIZ_STAT_PSD1,
  CODE_BIZ_STAT_PSD2,
  CODE_BIZ_STAT_PSD3,
  CODE_BIZ_STAT_PSD4,
} from "../../../../com/codeUtil";
import { getToken } from "../../../../service/authService";
import { getInfo, findInst, findMonitor, findPlan, setMonitorData, setPlanInstData } from "../../../../service/bizStat/com/infoApiService";
import { addressFormatFixFuc } from "../commonFuc";

const BizProjectInfo = ({ conn, codeList, cityList, rgnList, styles, isMobile, search, setSearch, getCalCityList, getCalRgnList }) => {
  // 토글 메뉴
  const [open, setOpen] = useState(false); // 메뉴의 초기값을 false로 설정

  // 로딩스피너
  const [loading, setLoading] = useState(false);

  // 엑셀 로딩 스피너
  const [excelLoading, setExcelLoading] = useState(false);

  // 신규 / 기존 사업장 여부
  const [isNew, setIsNew] = useState(false);

  // 기본정보 + 설비내역 파일 업로드 윈도우 창 제어
  const [planInstWindowVisible, setPlanInstWindowVisible] = useState(false);

  // 모니터링정보 파일 업로드 윈도우 창 제어
  const [monitorWindowVisible, setMonitorWindowVisible] = useState(false);

  // 파일 업로드 후 문제 없다면 리스트에 담는 변수
  const [uploadDataList, setUploadDataList] = useState([]);

  // 파일 업로드 정보
  const [uploadState, setUploadState] = useState({
    files: [],
    events: [],
    filePreviews: {},
    afterStateChange: [],
  });

  // 주소 윈도우 창 Visible
  let [userInfoVisible, setUserInfoVisible] = useState(false);

  // 데이터 담는 변수
  const [dataSource, setDataSource] = useState({
    grid: {
      dataList: [],
      total: 0,
    },
    backupGrid: {
      dataList: [],
      total: 0,
    },
    plan: null,
    inst: {
      sola: "",
      heat: "",
      velo: "",
      geot: "", // 에너지원 지열 (4글자로 만들어야해서 이름 변경)
      fuel: "",
      wast: "",
      baio: "",
      coli: "",
      smpo: "",
      hydr: "",
      wope: "",
      psd1: "",
      psd2: "",
      psd3: "",
      psd4: "",
    },
    monitor: null,
    inputs: [
      {
        // 기본정보 값
        instlTerm: "",
        instlPlnnm: "",
        instlAddr: "",
        instlDetailAddr: "",
        instlCelphon: "",
        instlCntct: "",
        newbuild: "",
        classFaci: "",
        etc: "",
        cityNewdeal: "",
        rgnCode: "",
        cityProvCode: "",
        bizYy: "",
        fixbylawBundCode: "",
        longi: "",
        lati: "",
        createUpdateYn: true,
        delYn: "N",
        moniYn: "Y",
      },
    ],
    instInput: {
      // 설비내역 값
      sola: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_SOLA,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      heat: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_HEAT,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "㎡",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      velo: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_VELO,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      geot: {
        // 에너지원 지열 (4글자로 만들어야해서 이름 변경)
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_GEOT,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      fuel: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_FUEL,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      wast: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_WAST,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      baio: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_BAIO,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      coli: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_COLI,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      smpo: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_SMPO,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      hydr: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_HYDR,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      wope: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_WOPE,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      psd1: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_PSD1,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      psd2: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_PSD2,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      psd3: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_PSD3,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
      psd4: {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_PSD4,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      },
    },
    moniInfo: {
      relatBizCode: "",
      relatBiz: { codeNo: "", codeVal: "전체" },
      relatBizYy: search.bizYyNo,
      instCnfrmCode: "",
      instlTerm: "",
      bizno: "",
      energyTypeCode: "",
      energyType: { codeNo: "", codeVal: "전체" },
      instCapa: "",
      unit: "",
      cid: "",
    },
    saveInfoList: [],
  });

  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;

  // 기본정보, 설비내역, 서류업로드 윈도우
  const [windowState, setWindowState] = useState({
    visible: false,
    left: isMobile === "Y" ? 0 : 350,
    top: 0,
    width: isMobile === "Y" ? innerWidth : 1700,
    height: isMobile === "Y" ? innerHeight : 700,
  });

  // ROW 클릭 데이터
  const [rowData, setRowData] = useState({
    bizYy: "",
    cityProvCode: "",
    rgnCode: "",
    rgnTerm: "",
  });

  const initialHeight = 560;
  const initialWidth = 500;

  // 팝업창 가운데 정렬을 위한 변수
  let posCalc = {};

  let citys = getCalCityList(cityList);
  let rgns = getCalRgnList(rgnList.filter(e => e.cityProvCode === search.cityProvCode && e.rgnCode !== "44131" && e.rgnCode !== "44133"));

  // 엑셀 업로드 헤더 설정
  const handleUploadHeaders = useCallback(e => {
    e.headers["X-AUTH-TOKEN"] = getToken();
    /* eslint-disable-next-line */
  }, []);

  /**
   * 사업대상지정보 - 리스트
   */
  const getDs = () => {
    // console.log("bizYyNo", bizYyNo);
    setLoading(true);

    getInfo(conn, getResult, getRejection, setLoading, search);
  };

  /**
   * 사업대상지 정보 입력하는 창 제어
   * @param {설치자 정보} e
   */
  const instPlanListAdd = (data, setInitalData = true) => {
    setWindowState(state => {
      const item = { ...state };
      item.visible = !windowState.visible;
      return item;
    });

    if (!data?.instlPlnnm) {
      setRowData(dataSource => {
        const item = dataSource;
        item.bizYy = search.bizYy;
        item.cityProvCode = search.cityProvCode;
        item.rgnCode = search.rgnCode;
        item.rgnTerm = search.rgn.rgnTerm;
        return item;
      });
      initalInputs();
      setIsNew(false);
    }
    if(setInitalData) {
      initalConvInfo();
      initalInstInput();
    }
    getDs();
  };

  // 엑셀 업로드 샘플 다운로드
  const downloadPlanSample = () => {
    const link = document.createElement("a");

    link.href = "/template/meain/사업현황_사업대상지정보_엑셀업로드샘플.xlsx";
    document.body.appendChild(link);
    link.click();
  };

  // 기본정보 + 설비내역 엑셀 업로드 윈도우 창 제어
  const uploadPlanInstExcel = () => {
    if (search.rgnCode !== "" && search.bizYyNo !== "") {
      initialExcelFile();
      setPlanInstWindowVisible(!planInstWindowVisible);
    } else {
      kendo.alert("사업연도와 시군구를 선택해주세요");
    }
  };

  // 모니터링고도화 엑셀 업로드 윈도우 창 제어
  const uploadMonitorExcel = () => {
    if (search.rgnCode !== "" && search.bizYyNo !== "") {
      initialExcelFile();
      setMonitorWindowVisible(!monitorWindowVisible);
    } else {
      kendo.alert("사업연도와 시군구를 선택해주세요");
    }
  };

  // 기본정보 + 설비내역 업로드 Save
  const uploadPlanInstSave = () => {
    if (uploadDataList.length <= 0) {
      kendo.alert("저장할 기본정보 리스트가 없습니다.");
      return;
    } else {
      if (uploadDataList.data.length <= 0) {
        kendo.alert("저장할 기본정보 리스트가 없습니다.");
        return;
      } else {
        uploadDataList.data.forEach(element => {
          element.bizYy = search.bizYyNo;
          element.rgnCode = search.rgnCode;
          element.cityProvCode = search.cityProvCode;
          element.createUpdateYn = true;
          element.moniYn = "N";
        });

        setPlanInstData(conn, uploadDataList.data, search, getExcelResult, getExcelRejection);
      }
    }
  };

  // 모니터링고도화 업로드 Save
  const uploadMonitorSave = () => {
    if (uploadDataList.length <= 0) {
      kendo.alert("저장할 모니터링 고도화 리스트가 없습니다.");
      return;
    } else {
      if (uploadDataList.data.length <= 0) {
        kendo.alert("저장할 모니터링 고도화 리스트가 없습니다.");
        return;
      } else {
        uploadDataList.data.forEach(element => {
          element.bizYy = search.bizYyNo;
          element.rgnCode = search.rgnCode;
          element.cityProvCode = search.cityProvCode;
          element.createUpdateYn = true;
          element.moniYn = "Y";
        });

        setMonitorData(conn, uploadDataList.data, search, getExcelMoniResult, getExcelRejection);
      }
    }
  };

  const getResult = result => {
    console.log("result", result);
    setDataSource(dataSource => {
      const item = { ...dataSource };
      item.grid.dataList = result.results;
      item.grid.total = result.total;
      return item;
    });

    // setLoading(false);
  };

  // rejection 함수
  const getRejection = error => {
    if (error) {
      kendo.alert(getMessage(error.errCode));
    } else {
      kendo.alert("검색을 실패했습니다");
    }
  };

  const getExcelResult = result => {
    // kendo.alert("엑셀 업로드를 완료 하였습니다.");
    setDataSource(dataList => {
      const item = { ...dataList };
      item.saveInfoList = result;
      return item;
    });
    uploadPlanInstExcel();
    getDs();
  };

  const getExcelMoniResult = result => {
    // kendo.alert("엑셀 업로드를 완료 하였습니다.");
    setDataSource(dataList => {
      const item = { ...dataList };
      item.saveInfoList = result;
      return item;
    });
    uploadMonitorExcel();
    getDs();
  };

  const getExcelRejection = error => {
    if (error) {
      kendo.alert(getMessage(error.errCode));
    } else {
      kendo.alert("엑셀 업로드를 실패 하였습니다.");
    }
  };

  // 엑셀 업로드 취소 , 닫기 누를 시 초기화
  const initialExcelFile = () => {
    setUploadState({
      files: [],
    });
    setUploadDataList([]);
  };

  /**
   * 엑셀 업로드 관련
   * onAdd
   * onRemove
   * onProgress
   * onStatusChange
   */

  // 엑셀 업로드 관련 함수 모음
  // files: [],
  // events: [],
  // filePreviews: {}
  const onAdd = event => {
    console.log("onAdd event = ", event);

    // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.
    const afterStateChange = () => {
      event.affectedFiles
        .filter(file => !file.validationErrors)
        .forEach(file => {
          const reader = new FileReader();

          reader.onloadend = ev => {
            setUploadState({
              files: uploadDataList.files,
              events: uploadDataList.events,
              filePreviews: {
                [file.uid]: ev.target.result,
              },
            });
          };

          reader.readAsDataURL(file.getRawFile());
        });
    };
    // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.
    setUploadState({
      // files: uploadDataList.files,
      files: event.newState,
      events: [uploadDataList.events],
      filePreviews: uploadDataList.filePreviews,
      afterStateChange: afterStateChange,
    });

    // console.log('uploadStatus = ', uploadState);
  };

  const onRemove = event => {
    console.log("onRemove event = ", event);

    setUploadState({
      files: [],
    });
    setUploadDataList([]);
  };

  const onProgress = event => {
    console.log("onProgress event = ", event);
  };

  const onStatusChange = event => {
    console.log("onStatusChange event = ", event);

    // 이걸 안해주면 파일 업로드 로딩이 안끝난다.
    setUploadState({
      files: event.newState,
    });

    if (event.response?.response) {
      if (event.response.response.status === 200) {
        setUploadDataList(event.response.response);
      } else {
        kendo.alert("업로드에 실패하여 관리자에게 문의해주세요.");
      }
    }
  };

  // 엑셀다운
  const handleExcelDown = e => {
    setExcelLoading(true);
    e.preventDefault();
    const url = "/api/v2.2/biz/info";

    const data = {
      rgnCode: search.rgn.rgnCode,
      bizYy: search.bizYyNo,
      instlTerm: search.instlTerm,
      energyTypeCode: search.energyTypeCode,
      bizSctCode: search.bizSctCode,
    };

    const fileName = "설치계획서목록_" + kendo.toString(new Date(), "yyyyMMdd_hh") + ".xlsx";

    downloadExcel(data, fileName, url, setExcelLoading);
  };

  /**
   * 행정동 코드 저장하는 함수
   * @param {kakao에서 선택한 주소} e
   */
  const setInfo = e => {
    // 천안시 (44131, 44133) 처럼 이런 지역구를 위한 if 문
    if (e.sigunguCode.slice(0, 4) === rowData.rgnCode.slice(0, 4)) {
      setDataSource(dataSource => {
        const item = dataSource;
        item.inputs.instlAddr = e.address;
        item.inputs.fixbylawBundCode = e.bcode;
        return item;
      });
    } else {
      kendo.alert("선택하신 시군구와 주소가 일치하지 않습니다.");
    }

    setUserInfoVisible(false);
  };

  const convPlanListNoCell = props => {
    var record = 0;
    record = dataSource.grid.total - props.dataIndex;

    return <td>{record}</td>;
  };

  const dateFormatCell = props => {
    const convInfoList = props.dataItem.bizInfoInstDtos;
    const result = instCapaAndUnitByEnergyType(props, convInfoList);
    return <td>{result}</td>;
  };

  // 그리드 페이징 처리
  const handlePageChange = e => {
    const { take, skip } = e.page;
    search.bizInfoPaging.skip = skip;
    search.bizInfoPaging.take = take;
    getDs();
  };

  // 그리드 정렬 처리
  const handleSortChange = e => {
    const { sort } = e;
    search.sort = sort;
    search.sortStatus = sort;
    if (sort.length !== 0) {
      search.sort = sort[0].field + "," + sort[0].dir;
    }
    getDs();
  };

  // 그리드 필터 처리
  const handleFilterChange = e => {
    search.bizInfoPaging.skip = 0;
    search.bizInfoPaging.take = 10;

    // 필터 값 설정
    if (e.filter) {
      const isFilter = e.filter.filters;
      console.log("isFilter", isFilter);
      if (isFilter.length !== 0) {
        if (isFilter[0].field === "classFaciVal") {
          search.bizInfoPaging.filter.classFaci = isFilter[0].value;
        } else if (isFilter[0].field === "instlAddr") {
          search.bizInfoPaging.filter.instlAddr = isFilter[0].value;
        } else if (isFilter[0].field === "cityNewdeal") {
          search.bizInfoPaging.filter.cityNewdeal = isFilter[0].value;
        }
      }
    }
    else{
      search.bizInfoPaging.filter.cityNewdeal = "";
    }

    getDs();
  };

  const modifyInstPlan = e => {
    findPlan(conn, e.instlPlnnm, search, getModiPlanResult, getModiRejection);
    findInst(conn, e.instlPlnnm, search, getModiInstResult, getModiRejection);
    findMonitor(conn, e.instlPlnnm, search, getModiMonitorResult, getModiRejection);
  };

  const getModiPlanResult = result => {
    console.log("plan result", result);
    if (result.length > 0) {
      result.forEach(element => {
        setDataSource(dataSource => {
          const item = { ...dataSource };
          item.plan = element;
          item.inputs = element;
          return item;
        });
      });

      instPlanListAdd(result[0], false);
    }
  };

  const getModiMonitorResult = result => {
    if (result.length > 0) {
      result.forEach(element => {
        setDataSource(dataSource => {
          const item = { ...dataSource };
          item.moniInfo = element;
          item.monitor = element;
          return item;
        });
      });

      instPlanListAdd(result[0], false);
    }
  };

  const getModiInstResult = result => {    
    if (result.length > 0) {
      result.forEach(element => {
        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_SOLA) {
          console.log("sola", element);
          setDataSource(value => {
            const item = value;
            item.inst.sola = element;
            return item;
          });

          setDataSource(value => {
            const item = value;
            item.instInput.sola = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_HEAT) {
          setDataSource(value => {
            const item = value;
            item.inst.heat = element;
            return item;
          });

          setDataSource(value => {
            const item = value;
            item.instInput.heat = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_VELO) {
          setDataSource(value => {
            const item = value;
            item.inst.velo = element;
            return item;
          });

          setDataSource(value => {
            const item = value;
            item.instInput.velo = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_GEOT) {
          setDataSource(value => {
            const item = value;
            item.inst.geot = element;
            return item;
          });

          setDataSource(value => {
            const item = value;
            item.instInput.geot = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_FUEL) {
          setDataSource(value => {
            const item = value;
            item.inst.fuel = element;
            return item;
          });

          setDataSource(value => {
            const item = value;
            item.instInput.fuel = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_WAST) {
          setDataSource(value => {
            const item = value;
            item.instInput.wast = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_BAIO) {
          setDataSource(value => {
            const item = value;
            item.instInput.baio = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_COLI) {
          setDataSource(value => {
            const item = value;
            item.instInput.coli = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_SMPO) {
          setDataSource(value => {
            const item = value;
            item.instInput.smpo = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_HYDR) {
          setDataSource(value => {
            const item = value;
            item.instInput.hydr = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_WOPE) {
          setDataSource(value => {
            const item = value;
            item.instInput.wope = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_PSD1) {
          setDataSource(value => {
            const item = value;
            item.instInput.psd1 = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_PSD2) {
          setDataSource(value => {
            const item = value;
            item.instInput.psd2 = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_PSD3) {
          setDataSource(value => {
            const item = value;
            item.instInput.psd3 = element;
            return item;
          });
        }

        if (Number(element.energyTypeCode) === CODE_BIZ_STAT_PSD4) {
          setDataSource(value => {
            const item = value;
            item.instInput.psd4 = element;
            return item;
          });
        }
      });
    } else {
      initalConvInfo();
      initalInstInput();
    }
  };

  // rejection 함수
  const getModiRejection = error => {
    console.log("error = ", error);
  };

  const expandChange = props => {
    console.log("pros", props);

    if (props.dataItem.moniYn === "Y" || props.dataItem.moniYn === "N") {
      setIsNew(true);
    } else {
      setIsNew(false);
    }

    setRowData(dataSource => {
      const item = dataSource;
      item.bizYy = props.dataItem.bizYy;
      item.cityProvCode = props.dataItem.cityProvCode;
      item.rgnCode = props.dataItem.rgnCode;
      item.rgnTerm = props.dataItem.rgnTerm;
      return item;
    });

    modifyInstPlan(props.dataItem);
  };

  const initalInputs = () => {
    // console.log("inital");
    setDataSource(dataSource => {
      const item = dataSource;
      item.inputs.instlTerm = "";
      item.inputs.instlPlnnm = "";
      item.inputs.instlAddr = "";
      item.inputs.instlDetailAddr = "";
      item.inputs.instlCelphon = "";
      item.inputs.instlCntct = "";
      item.inputs.newbuild = "";
      item.inputs.classFaci = "";
      item.inputs.etc = "";
      item.inputs.cityNewdeal = "";
      item.inputs.rgnCode = "";
      item.inputs.cityProvCode = "";
      item.inputs.bizYy = "";
      item.inputs.fixbylawBundCode = "";
      item.inputs.longi = "";
      item.inputs.lati = "";
      item.inputs.createUpdateYn = true;
      item.inputs.moniYn = "N";
      item.plan = null;
      item.moniInfo.relatBizCode = "";
      item.moniInfo.relatBiz = { codeNo: "", codeVal: "전체" };
      item.moniInfo.relatBizYy = search.bizYyNo;
      item.moniInfo.instCnfrmCode = "";
      item.moniInfo.instlTerm = "";
      item.moniInfo.bizno = "";
      item.moniInfo.energyTypeCode = "";
      item.moniInfo.energyType = { codeNo: "", codeVal: "전체" };
      item.moniInfo.instCapa = "";
      item.moniInfo.unit = "";
      item.moniInfo.cid = "";
      item.saveInfoList = [];
      return item;
    });

    getDs();
  };

  const initalConvInfo = () => {
    setDataSource(dataSource => {
      const item = dataSource;
      item.inst.sola = "";
      item.inst.heat = "";
      item.inst.velo = "";
      item.inst.geot = ""; // 에너지원 지열 (4글자로 만들어야해서 이름 변경)
      item.inst.fuel = "";
      item.inst.wast = "";
      item.inst.baio = "";
      item.inst.coli = "";
      item.inst.smpo = "";
      item.inst.hydr = "";
      item.inst.wope = "";
      item.inst.psd1 = "";
      item.inst.psd2 = "";
      item.inst.psd3 = "";
      item.inst.psd4 = "";
      return item;
    });
  };

  const initalInstInput = () => {
    setDataSource(dataSource => {
      const item = dataSource;
      item.instInput.sola = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_SOLA,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.heat = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_HEAT,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "㎡",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.velo = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_VELO,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.geot = {
        // 에너지원 지열 (4글자로 만들어야해서 이름 변경)
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_GEOT,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.fuel = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_FUEL,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.wast = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_WAST,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.baio = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_BAIO,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.coli = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_COLI,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.smpo = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_SMPO,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.hydr = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_HYDR,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "kW",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.wope = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_WOPE,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.psd1 = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_PSD1,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.psd2 = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_PSD2,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.psd3 = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_PSD3,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      item.instInput.psd4 = {
        instlPlnnm: "",
        energyTypeCode: CODE_BIZ_STAT_PSD4,
        formCapa: "",
        instType: "",
        instCapa: "",
        unit: "",
        totalExpense: "",
        gvmSpt: "",
        mupal: "",
        province: "",
        priCompany: "",
        spendItem: "",
        consEnte: "",
      };
      return item;
    });
  };

  // 데이터 불러오기 (검색 부분에서 찾을 시)
  const getData = () => {
    setBizObjSettData();

    search.bizInfoPaging.take = 10;
    search.bizInfoPaging.skip = 0;

    // 네비게이션 설정
    makeNavigator(search);
    getDs();
  };

  const setBizObjSettData = () => {
    if (search.rgnCode) {
      // setRgnCodeNo (search.rgn);
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.inputs.rgnCode = search.rgn.rgnCode;
        item.inputs.cityProvCode = search.rgn.cityProvCode;
        return item;
      });
    } else {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.inputs.rgnCode = "";
        item.inputs.cityProvCode = "";
        return item;
      });
    }
    if (search.bizYyNo !== "사업연도") {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.inputs.bizYy = search.cityProvCode;
        return item;
      });
    } else {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.inputs.bizYy = "";
        return item;
      });
    }
    if (search.instlTerm) {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.inputs.instlTerm = search.instlTerm;
        return item;
      });
    } else {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.inputs.instlTerm = "";
        return item;
      });
    }
  };

  const toggleMenu = () => {
    setOpen(!open); // on,off 개념 boolean
  };

  posCalc = setCenterCalc(700, 900);

  useEffect(() => {
    getData();
    /* eslint-disable-next-line */
  }, []);

  console.log('search: ', search);

  return (
    <>
      <div className={styles.JinTabCon} id="JinTab_con">
        <div className={excelLoading ? styles.convProject : ""}>
          {/* 공용 검색 컴포넌트 */}
          <SearchCom search={search} setSearch={setSearch} rgns={rgns} citys={citys} getData={getData} codeList={codeList} />
          <div className={loading ? [styles.convProject, styles.convProjectTable].join(" ") : styles.convProjectTable}>
            <div class="uk-grid">
              <div class="uk-width-medium-1-1">
                <div class="md-card">
                  <div class="md-card-content">
                    <div className={open ? styles.showMenu : styles.hideMenu}>
                      <ul className={styles.ulDownloadCss}>
                        <li className={styles.liDownloadCss} onClick={downloadPlanSample}>
                          - &nbsp;
                          <a className="btn_dl" onClick={downloadPlanSample} href="#!">
                            <Download className="material-icons" style={{ fontSize: 23, color: "#000" }} />
                            <em>일괄등록 양식다운</em>
                          </a>
                          &nbsp;&nbsp;
                        </li>
                        <li className={styles.liDownloadCss} onClick={uploadPlanInstExcel}>
                          - &nbsp;
                          <a className="btn_up" onClick={uploadPlanInstExcel} href="#!">
                            <FileUpload className="material-icons" style={{ fontSize: 23, color: "#000" }} />
                            <em>기본정보 일괄등록</em>
                          </a>
                          &nbsp;&nbsp;
                        </li>
                        <li className={styles.liDownloadCss} onClick={uploadMonitorExcel}>
                          - &nbsp;
                          <a className="btn_dl" onClick={uploadMonitorExcel} href="#!">
                            <FileUpload className="material-icons" style={{ fontSize: 23, color: "#000" }} />
                            <em>모니터링정보 일괄등록</em>
                          </a>
                          &nbsp;&nbsp;
                        </li>
                      </ul>
                    </div>
                    <div class="md-card-toolbar-conv">
                      <h3 class="md-card-toolbar-heading-text-left">
                        설치계획서 목록
                        {
                          <>
                            {" "}
                            <span style={{ fontSize: "13px", marginLeft: "5px" }}> [{search.navi}] </span>{" "}
                          </>
                        }
                      </h3>
                      <div className={styles.convMdCardToolbar}>
                        {search.bizYyNo === "" || search.rgnCode === "" ? (
                          <></>
                        ) : (
                          <button class="btn_pls ModalBtn_A" onClick={instPlanListAdd}>
                            등록
                          </button>
                        )}
                        &nbsp;&nbsp;
                        {windowState.visible && (
                          <BizInstPlanAdd
                            conn={conn}
                            rgnCode={search.rgn}
                            bizYy={search.bizYyNo}
                            instPlanListAdd={instPlanListAdd}
                            codeList={codeList}
                            search={search}
                            setSearch={setSearch}
                            setDataSource={setDataSource}
                            instInput={dataSource.instInput}
                            inst={dataSource.inst}
                            inputs={dataSource.inputs}
                            plan={dataSource.plan}
                            styles={styles}
                            initalConvInfo={initalConvInfo}
                            initalInstInput={initalInstInput}
                            moniInfo={dataSource.moniInfo}
                            isNew={isNew}
                            rowData={rowData}
                            userInfoVisible={userInfoVisible}
                            setUserInfoVisible={setUserInfoVisible}
                            useState={useState}
                            useEffect={useEffect}
                            TabStrip={TabStrip}
                            TabStripTab={TabStripTab}
                            kendo={kendo}
                            Form={Form}
                            FormElement={FormElement}
                            Input={Input}
                            RadioButton={RadioButton}
                            DropDownList={DropDownList}
                            getMessage={getMessage}
                            IntlProvider={IntlProvider}
                            LocalizationProvider={LocalizationProvider}
                            getToken={getToken}
                            Upload={Upload}
                            Window={Window}
                            Button={Button}
                          />
                        )}
                        {search.bizYyNo === "" || search.rgnCode === "" ? (
                          <></>
                        ) : (
                          <button className="btn_dl" onClick={toggleMenu}>
                            <Dehaze className="material-icons" style={{ fontSize: 23, color: "#000" }} />
                            <em>일괄등록 및 양식다운</em>
                          </button>
                        )}
                        &nbsp;&nbsp;
                        <button className="btn_icon" onClick={handleExcelDown}>
                          <SaveAlt className="material-icons" style={{ fontSize: 23, color: "#000" }} />
                          <em>엑셀 저장</em>
                        </button>
                      </div>
                    </div>
                    <BizPlanGrid
                      grid={dataSource.grid}
                      codeList={codeList}
                      search={search}
                      handlePageChange={handlePageChange}
                      handleSortChange={handleSortChange}
                      handleFilterChange={handleFilterChange}
                      expandChange={expandChange}
                      convPlanListNoCell={convPlanListNoCell}
                      dateFormatCell={dateFormatCell}
                      bizInfoPaging={search.bizInfoPaging}
                      useState={useState}
                      IntlProvider={IntlProvider}
                      LocalizationProvider={LocalizationProvider}
                      Grid={Grid}
                      GridColumn={GridColumn}
                      GridNoRecords={GridNoRecords}
                      GridColumnInputFilter={GridColumnInputFilter}
                      CustomFilter={CustomFilter}
                      addressFormatFixFuc={addressFormatFixFuc}
                      customFilterObj={customFilterObj}
                    />
                    {planInstWindowVisible && (
                      <Window
                        title={"기본정보 일괄등록"}
                        onClose={uploadPlanInstExcel}
                        top={posCalc.top}
                        left={posCalc.left}
                        initialHeight={265}
                        initialWidth={600}
                        modal={true}>
                        <div>
                          {/* 윈도우 창 */}
                          <LocalizationProvider language="ko-KR">
                            <IntlProvider locale="ko">
                              <Upload
                                batch={false}
                                multiple={false}
                                withCredentials={true}
                                onBeforeUpload={handleUploadHeaders}
                                onAdd={onAdd}
                                onRemove={onRemove}
                                onProgress={onProgress}
                                onStatusChange={onStatusChange}
                                restrictions={{
                                  allowedExtensions: [".xlsx"],
                                  maxFileSize: 10240000,
                                }}
                                files={uploadState.files}
                                saveUrl={`${process.env.REACT_APP_REST_API_VERSION}/biz/upload/info/xlsx/planInst`}
                                removeUrl={`${process.env.REACT_APP_REST_API_VERSION}/biz/file/delete`}></Upload>
                            </IntlProvider>
                          </LocalizationProvider>
                          <div>
                            <Button onClick={uploadPlanInstSave} className="excelUploadButton">
                              등록
                            </Button>
                            <Button onClick={uploadPlanInstExcel} className="cancelButton">
                              취소
                            </Button>
                          </div>
                        </div>
                      </Window>
                    )}
                    {monitorWindowVisible && (
                      <Window
                        title={"모니터링정보 일괄등록"}
                        onClose={uploadMonitorExcel}
                        top={posCalc.top}
                        left={posCalc.left}
                        initialHeight={265}
                        initialWidth={600}
                        modal={true}>
                        <div>
                          {/* 윈도우 창 */}
                          <LocalizationProvider language="ko-KR">
                            <IntlProvider locale="ko">
                              <Upload
                                batch={false}
                                multiple={false}
                                withCredentials={true}
                                onBeforeUpload={handleUploadHeaders}
                                onAdd={onAdd}
                                onRemove={onRemove}
                                onProgress={onProgress}
                                onStatusChange={onStatusChange}
                                restrictions={{
                                  allowedExtensions: [".xlsx"],
                                  maxFileSize: 10240000,
                                }}
                                files={uploadState.files}
                                saveUrl={`${process.env.REACT_APP_REST_API_VERSION}/biz/upload/info/xlsx/monitor`}
                                removeUrl={`${process.env.REACT_APP_REST_API_VERSION}/biz/file/delete`}></Upload>
                            </IntlProvider>
                          </LocalizationProvider>
                          <div>
                            <Button onClick={uploadMonitorSave} className="excelUploadButton">
                              등록
                            </Button>
                            <Button onClick={uploadMonitorExcel} className="cancelButton">
                              취소
                            </Button>
                          </div>
                        </div>
                      </Window>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {dataSource.saveInfoList.length > 0 && (
              <BizPlanSaveResult
                Grid={Grid}
                GridColumn={GridColumn}
                GridNoRecords={GridNoRecords}
                convMngtStyles={convMngtStyles}
                result={dataSource.saveInfoList}
                Button={Button}
                initalInputs={initalInputs}
              />
            )}
            {userInfoVisible && (
              <KakaoPostcodeSearch
                setInfo={setInfo}
                setVisible={setUserInfoVisible}
                initialTop={(window.screen.height - initialHeight) / 2 - 50}
                initialLeft={(window.screen.width - initialWidth) / 2}
                initialHeight={initialHeight}
                initialWidth={initialWidth}
              />
            )}
          </div>
          {loading && (
            <p className={styles.convProjectLoading}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
          {excelLoading && (
            <p className={styles.saveLoading}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default BizProjectInfo;
