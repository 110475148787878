// 모듈 효율 15001

import { useCallback, useEffect, useState } from "react";
import { getModuleDataList, getModuleDataListByMakr } from "../../../../service/stats/type/ensoDataService";
import ModuleEficChart from "./module_efic_chart";
import ModuleEficGrid from "./module_efic_grid";
import { Button } from "@progress/kendo-react-buttons";

const ModuleCrystal = ({ conn, search, setLoading, LocalizationProvider, IntlProvider }) => {
  // 설치 유형 데이터 소스
  const [dataSource, setDataSource] = useState({ eficChart: [], ratioChart: [], grid: [], drilldown: false, makrTerm: null });

  // 엑셀 데이터 설정
  const setExcelData = excel => {
    setDataSource(dataSource => {
      const item = { ...dataSource };
      item.excelData = excel;
      return item;
    });
  };

  // 엑셀 저장
  const downloadExcel = () => {
    setLoading(true);
    dataSource.excelData.save();
  };

  const completeExcel = () => {
    setLoading(false);
  };

  // 드릴다운 이벤트
  const clickDrillDown = (makrSeriNo, makrTerm) => {
    if (makrSeriNo !== null) {
      getModuleDataListByMakr(conn, getResult, getRejection, search, makrSeriNo, makrTerm);
    } else {
      getModuleDataList(conn, getResult, getRejection, search);
    }
  };

  // 데이터 소스 가져오기
  const getResult = useCallback(
    getData => {
      setLoading(false);
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.eficChart = getData.efic;
        item.ratioChart = getData.ratio;
        item.grid = getData.result;
        item.drilldown = getData.drilldown;
        item.makrTerm = getData.makrTerm;
        return item;
      });
    },
    // eslint-disable-next-line
    [search],
  );

  // rejection 함수
  const getRejection = error => {
    setLoading(false);
  };

  useEffect(() => {
    getModuleDataList(conn, getResult, getRejection, search);
    // eslint-disable-next-line
  }, [getResult]);

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-2">
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">
                {dataSource.drilldown === false && "모듈 발전효율(그래프 클릭시 상세정보 출력)"}
                {dataSource.drilldown === true && dataSource.makrTerm + "발전효율"}
              </h3>
            </div>
            <ModuleEficChart efic={dataSource.eficChart} ratio={dataSource.ratioChart} clickDrillDown={clickDrillDown}></ModuleEficChart>
          </div>
        </div>
      </div>
      <div className="uk-width-medium-1-2">
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">
                <Button className="ensoCmpExcelDownBtn" onClick={downloadExcel} icon="excel">
                  엑셀저장
                </Button>
              </h3>
            </div>
            <ModuleEficGrid
              makrTerm={dataSource.makrTerm}
              drilldown={dataSource.drilldown}
              dataSource={dataSource.grid}
              unit={search.unit}
              setExcelData={setExcelData}
              completeExcel={completeExcel}
              LocalizationProvider={LocalizationProvider}
              IntlProvider={IntlProvider}></ModuleEficGrid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleCrystal;
