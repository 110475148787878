import kendo from "@progress/kendo-ui";
import { downloadExcel } from "../../com/downloadExcel";

export const getWeathCmpList = async (conn, callback, reject, search) => {

  let start = kendo.toString(search.startGathDtm, "yyyy-MM-dd");
  let end = kendo.toString(search.endGathDtm, "yyyy-MM-dd");
  // 시간별일 경우 시작일 과 마지막일은 같다.
  if (search.selectedValue === "HH") {
    start = kendo.toString(start, "yyyy-MM-dd");
    end = kendo.toString(start, "yyyy-MM-dd");
  }
  // 월별일 경우 시작일은 1일 마지막일은 마지막 날짜로 한다.
  else if (search.selectedValue === "MM") {
    // 마지막 날짜 설정
    const endDate = new Date(end);
    const year = kendo.toString(endDate, "yyyy");
    const month = kendo.toString(endDate, "MM");
    const lastDay = new Date(year, month, 0);
    start = kendo.toString(start, "yyyy-MM-01");
    end = kendo.toString(lastDay, "yyyy-MM-dd");
  } else {
    start = kendo.toString(search.startGathDtm, "yyyy-MM-dd");
    end = kendo.toString(search.endGathDtm, "yyyy-MM-dd");
  }

  await conn.get("analy/weath/page", {
    params: {
      ensoTypeCode: search.ensoTypeCode,
      cityProvCode: search.cityProvCode,
      rgnCode: search.rgnCode,
      startGathDtm: start,
      endGathDtm: end,
      dateFormat: search.selectedValue
    }
  }).then((result) => {
    let powerData = [];
    let tempData = [];
    let wspdData = [];
    let precQtyData = [];
    let humiData = [];
    let soraQtyData = [];
    let precPrbblData = [];
    result.forEach((element) => {
      powerData.push({ name: element.gathDtm, y: element.power });
      tempData.push({ name: element.gathDtm, y: element.temp });
      wspdData.push({ name: element.gathDtm, y: element.wspd });
      precQtyData.push({ name: element.gathDtm, y: element.precQty });
      humiData.push({ name: element.gathDtm, y: element.humi });
      soraQtyData.push({ name: element.gathDtm, y: element.soraQty });
      precPrbblData.push({ name: element.gathDtm, y: element.precPrbbl });
    });

    // 가공된 결과 값
    const resultData = {
      data: result,
      power: powerData,
      temp: tempData,
      wspd: wspdData,
      precQty: precQtyData,
      humi: humiData,
      soraQty: soraQtyData,
      precPrbbl: precPrbblData,
    }
    callback(resultData);
  })
    .catch((error) => {
      reject(error);
    });;
};
const weathInfoUrl = "/analy/weath/info";
export const getWeathInfo = async (conn) => {
  // const dtm = parseDate('yyyy-mm-dd 00:00:00', new Date());
  const dtm = kendo.toString(new Date(), 'yyyy-MM-dd HH:00:00');

  const response = await conn.get(weathInfoUrl + "/" + dtm);
  return response;
};

/**
 * 정선 날씨조회 api
 */
const jeongseonWeathInfoUrl = "/analy/weath/jeongseon/info";
export const getJeongseonWeathInfo = async (conn) => {

  const dtm = kendo.toString(new Date(), 'yyyy-MM-dd HH:00:00');
  // const lastDtm = '2022-09-17 23:00:00';

  const response = await conn.get(jeongseonWeathInfoUrl + "/" + dtm + "&" + "42770");

  return response;
}

/**
 * @author       KST
 * @date         20230509
 * @description  대덕 지자체 모니터링용 날씨조회 api 
 * 
 * @param {*} conn 
 * @returns 
 */
const daedeokWeathInfoUrl = "/analy/weath/daedeok/info";
export const getDaedeokWeathInfo = async (conn) => {

  const dtm = kendo.toString(new Date(), 'yyyy-MM-dd HH:00:00');
  // const lastDtm = '2022-09-17 23:00:00';

  const response = await conn.get(daedeokWeathInfoUrl + "/" + dtm + "&" + "30230");

  return response;
}

// 엑셀 다운로드
export const getExcelDownload = async (param, setLoading) => {
  setLoading(true)
  const cityTerm = param.cityProvCode ? param.city.cityProvTerm + '_' : '';
  const rgnTerm = param.rgnCode ? param.rgn.rgnTermSimple + '_' : '';
  const excelTitle = "기상비교_" + cityTerm + rgnTerm + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
  const url = "/api/v2.2/analy/weath";

  // 조건 검색 객체
  let start = kendo.toString(param.startGathDtm, "yyyy-MM-dd");
  let end = kendo.toString(param.endGathDtm, "yyyy-MM-dd");

  const search = {
    ensoTypeCode: param.ensoTypeCode,
    cityProvCode: param.cityProvCode,
    rgnCode: param.rgnCode,
    startGathDtm: start,
    endGathDtm: end,
    dateFormat: param.selectedValue
  }

  try {
    downloadExcel(search, excelTitle, url, setLoading);
  } catch (error) {
    kendo.alert("엑셀 다운로드를 실패 하였습니다.");
    return;
  }
}