import { Window } from "@progress/kendo-react-dialogs";
import React, { useEffect, useState } from "react";
import styles from "../../monitor/installerGath/installerGath.module.css";
import WeekEnergyChart from "../../monitor/installerGath/weekEnergyChart";
import { isPowerEnsoType, num, numFormat, reduceTCo2, roundNum } from "../../../com/dataUtil";
import { parseDate } from "../../../com/dateUtil";
import {
  getInstallerEnergy,
  getWeekEnergyList,
  getHourlyEnergyList,
  getInstaller,
  getInstallerEnsoList,
  getSmpTodayData,
  getSmpCurMonthData,
} from "../../../service/monitor/installerGathService";
import log from "../../../com/log";
import HourEnergyChart from "../../monitor/installerGath/hourEnergyChart";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { LocalizationProvider } from "@progress/kendo-react-intl";
import { IntlProvider } from "@progress/kendo-react-intl";
import { BeatLoader } from "react-spinners";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import { getMunicLastGath } from "../../../service/location/locationMunicService";

const LocMunicWindow = props => {
  const history = useHistory();
  const { conn, rghtWindow, closeRghtWindow } = props;
  const { ensoTypeCode, installerId, rgnCode } = rghtWindow;

  const [energy, setEnergy] = useState({});
  const [weekEnergyList, setWeekEnergyList] = useState([]); // 주간 발전량/소비량
  const [loading, setLoading] = useState(false);
  const [hourEnergyList, setHourEnergyList] = useState([]); // 금일 시간당 발전량/소비량 추이
  const [state, setState] = useState({ installer: { userTerm: "" }, ensoList: [] });
  const [gathData, setGathData] = useState([]);
  log(gathData, "gathData");

  const [dataSource, setDataSource] = useState({
    smpDayLand: "",
    smpDayJeju: "",
    smpMonthLand: "",
    smpMonthJeju: "",
    recDay: "",
    recMonth: "",
    cerDay: "",
    cerMonth: "",
  });

  const getSmpResult = result => {
    if (result.length !== 0) {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.smpDayLand = result[0].landSmpDay;
        item.smpDayJeju = result[0].jejuSmpDay;
        return item;
      });
    } else {
    }
  };

  const getSmpMonthResult = result => {
    if (result.length !== 0) {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.smpMonthLand = result[0].landSmpMonth;
        item.smpMonthJeju = result[0].jejuSmpMonth;
        return item;
      });
    } else {
    }
  };

  const setInstallerGath = (selectedEnsoTypeCode, selectedCid) => {
    setLoading(true);
    Promise.all([
      //
      getInstallerEnergy(conn, installerId, selectedEnsoTypeCode, selectedCid),
      getWeekEnergyList(conn, installerId, selectedEnsoTypeCode, selectedCid),
      getHourlyEnergyList(conn, installerId, selectedEnsoTypeCode, selectedCid),
    ]).then(results => {
      setEnergy({ ...results[0], ...{ latestGathDtm: results[2].latestGathDtm } });
      setWeekEnergyList(results[1]);
      setHourEnergyList(results[2]);
      setLoading(false);
    });
  };
  const getRejection = error => {
    log(error, "error");
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      //
      getInstaller(conn, installerId),
      getInstallerEnsoList(conn, installerId),
      getMunicLastGath(conn, installerId),
    ])
      .then(results => {
        setState({
          ...state,
          installer: results[0],
          ensoList: results[1],
        });

        // setMeainList(results[2]);
        setInstallerGath(ensoTypeCode, "");
        setGathData(results[2]);
      })
      .catch(e => log(e, "error"));

    getSmpTodayData(conn, getSmpResult, getRejection);
    getSmpCurMonthData(conn, getSmpMonthResult, getRejection);
    /* eslint-disable-next-line */
  }, [installerId]);

  const ensoTypeInfo = energy => {
    if (ensoTypeCode === 15001 || ensoTypeCode === 15004 || ensoTypeCode === 15006)
      return (
        <th className="locMunicTBgA">
          <div>금일발전량</div>
          <b>{energy.dayEnergy && numFormat(num(energy.dayEnergy))}</b>
          <span>kWh</span>
          <br />
          <small>금월&nbsp; {energy.monthEnergy && numFormat(num(energy.monthEnergy))} kWh</small>
          &nbsp;&nbsp;<small>금년&nbsp; {energy.yearEnergy && numFormat(roundNum(energy.yearEnergy / 1000, 2))} MWh</small>
        </th>
      );
    else if (ensoTypeCode === 15002 || ensoTypeCode === 15003) {
      if (energy.sysMode === 29002) {
        return (
          <th className="locMunicTBgA">
            <div>금일사용량</div>
            <b>{energy.dayUse && numFormat(num(energy.dayUse))}</b>
            <span>kWh</span>
            <br />
            <small>금월&nbsp;&nbsp; 미제공 </small>
            <small>금년&nbsp;&nbsp; 미제공 </small>
          </th>
        );
      } else {
        return (
          <th className="locMunicTBgA">
            <div>금일생산량</div>
            <b>{energy.dayEnergy && numFormat(num(energy.dayEnergy))}</b>
            <span>kWh</span>
            <br />
            <small>금월&nbsp;&nbsp; {energy.monthEnergy && numFormat(num(energy.monthEnergy))} MWh</small>
            <small>금년&nbsp;&nbsp; {energy.yearEnergy && numFormat(num(energy.yearEnergy))}MWh</small>
          </th>
        );
      }
    }
  };

  const locMunicTitle = () => {
    const locMunicTitleBtnEVt = () => {
      history.push("/monitor/installerGath?installerId=" + installerId + "&ensoType=" + ensoTypeCode + "&rgnCode=" + rgnCode);
    };
    return (
      <>
        <span>{state.installer.userTerm}</span>
        &nbsp;&nbsp;
        <Button onClick={locMunicTitleBtnEVt}>상세정보</Button>
      </>
    );
  };

  return (
    <Window
      className="locMuninWindow"
      height={"100%"}
      width={"500px"}
      minimizeButton={"none"}
      maximizeButton={"none"}
      draggable={false}
      doubleClickStageChange={false}
      title={locMunicTitle()}
      left="none"
      onClose={closeRghtWindow}
      // style={loading ? { opacity: 0.9 } : null}
    >
      <div className="md-card">
        <div className="md-card-content">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">
              발전정보
              <span className={styles.marginLeft10} />
              <small>
                [{energy.bizYyyy}: {energy.bizTerm}]
              </small>
            </h3>
          </div>
          <div className="installerGath-infoBox">
            <table className="locMunic-infoTable">
              <tr>
                <th>
                  <div>설비용량</div>
                  <b>{energy.instCapa && numFormat(roundNum(energy.instCapa, 2))}</b>
                  <span>kW</span>
                  <br />
                  <small>
                    설비설치일 &nbsp;&nbsp;
                    {energy.instDtm && parseDate("yyyy-mm-dd", energy.instDtm)}
                  </small>
                </th>
                {ensoTypeInfo(energy)}
              </tr>
              <tr>
                <th className="locMunicTBgA">
                  <div>
                    누적CO<sub>2</sub>저감량
                  </div>
                  <b>
                    {energy.accumUse | energy.accumEnergy &&
                      reduceTCo2(energy.ensoTypeCode === 15002 || energy.ensoTypeCode === 15003 ? energy.accumUse : energy.accumEnergy)}
                  </b>
                  <span>
                    tCO<sub>2</sub>
                  </span>
                </th>
                <th>
                  <div>SMP 수익금 (금일 / 금월)</div>
                  {rgnCode.substring(0, 2) !== 50 ? (
                    <b className="profitB">
                      {numFormat(num(dataSource.smpDayLand * energy.dayEnergy))} /{" "}
                      {numFormat(num(dataSource.smpMonthLand * energy.monthEnergy + dataSource.smpDayLand * energy.dayEnergy))}{" "}
                      <span>원</span>
                    </b>
                  ) : (
                    <b className="profitB">
                      {numFormat(num(dataSource.smpDayJeju * energy.dayEnergy))} /{" "}
                      {numFormat(num(dataSource.smpMonthJeju * energy.monthEnergy + dataSource.smpDayJeju * energy.dayEnergy))}{" "}
                      <span>원</span>
                    </b>
                  )}
                </th>
              </tr>
            </table>
          </div>
          <div>
            <div className="locMunicWeekEnergyTitle">
              {!isPowerEnsoType(ensoTypeCode) ? (energy.sysMode === 29002 ? "주간소비량" : "주간생산량") : "주간발전량"}
            </div>
            <div>
              {weekEnergyList.length > 0 ? (
                <WeekEnergyChart weekEnergyList={weekEnergyList} ensoTypeCode={ensoTypeCode} energy={energy} height="120px" />
              ) : (
                <div className="weekEnergyChartNoneData"> 조회된 데이터가 없습니다.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="md-card " style={{ marginTop: "0px" }}>
        <div className="md-card-content">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">
              {" "}
              {!isPowerEnsoType(ensoTypeCode) ? (energy.sysMode === 29002 ? "사용량" : "생산량") : "발전량"}추이
            </h3>
          </div>
          <div className={styles.bottomChartArea}>
            {!isPowerEnsoType(ensoTypeCode) ? (
              energy.sysMode === 29002 ? (
                <div style={{ height: "160px", textAlign: "center", marginTop: "30%" }}>
                  {" "}
                  자연순환식은 시간대별 사용량 추이를 제공하지 않습니다.{" "}
                </div>
              ) : hourEnergyList.length > 0 ? (
                <HourEnergyChart hourEnergyList={hourEnergyList} ensoTypeCode={ensoTypeCode} height="180px" />
              ) : (
                <div style={{ height: "160px", textAlign: "center", marginTop: "30%" }}>조회된 데이터가 없습니다.</div>
              )
            ) : hourEnergyList.length > 0 ? (
              <HourEnergyChart hourEnergyList={hourEnergyList} ensoTypeCode={ensoTypeCode} height="180px" />
            ) : (
              <div style={{ height: "160px", textAlign: "center", marginTop: "30%" }}>조회된 데이터가 없습니다.</div>
            )}
          </div>
        </div>
      </div>
      <div className="md-card " style={{ marginTop: "0px" }}>
        <div className="md-card-content">
          <div className="md-card-toolbar">
            <h3 className="md-card-toolbar-heading-text">운전이력</h3>
          </div>
          <div>
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid data={gathData} className="locMunicGathGrid">
                  <GridColumn title="CID" field="cid" width={"110px"}></GridColumn>
                  {/* <GridColumn title="설치자" field="userTerm"></GridColumn> */}
                  <GridColumn title="수집일자" field="gathDtm" width={"150px"}></GridColumn>
                  <GridColumn title="데이터상태" field="dataStatTerm" width={"85px"}></GridColumn>
                  <GridColumn title="누적발전량(Wh)" field="accumPower"></GridColumn>
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </div>
      </div>
      {loading && (
        <p
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: 99999,
          }}>
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </Window>
  );
};

export default LocMunicWindow;
