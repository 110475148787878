import { environmentList } from "../constant/Environment";
import ContributionCard from "./ContributionCard";
import earth from "dist/img/Earth2.png";
import "./Contribution.css";

export default function Contribution({ totalCo2, totalTree, totalPlasticCup, totalPaperCup, totalCarDistance }) {
  const totalData = { totalTree, totalPlasticCup, totalPaperCup, totalCarDistance };
  return (
    <div className="asan-contribute-container">
      <h3 className="asan-card-title">오늘의 환경공헌도</h3>
      <div className="con-card-list">
        {environmentList.map(item => (
          <ContributionCard title={item.title} unit={item.unit} image={item.image} totalData={totalData} />
        ))}
      </div>
      <div className="asan-gas">
        <img alt="지구" src={earth} />
        <div className="asan-gas-decrease">
          <h2>온실가스 감소량</h2>
          <div className="asan-gas-amount">
            <span>{totalCo2}</span>
            <span>t</span>
            <span>co</span>
            <span>2</span>
          </div>
        </div>
      </div>
    </div>
  );
}
