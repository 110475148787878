import React from 'react';

// SMP 상단 대시보드 영역 - 일별, 월별, 전월 데이터 보여주는 부분
const SmpDashBoardDayMonthData = ({conn , styles, dashboardData}) => {

  return (
    <>
    <div className="uk-grid">
      <div className="uk-width-medium-1-6">
          <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
              <div className="md-card-content">
                  <div className={styles.dashGridTable}>
                    <img src="/images/today.png" className={styles.calendarImg} alt="today"></img>
                    <span className={styles.dashboardTitleText}>금일 육지 SMP</span>
                    <br/>
                    <br/>
                    <span className={styles.dashboardPriceText}>{dashboardData.todaySmpDayLand}</span>&nbsp;<span className={styles.dashboardUnitText}>원/kWh</span><br/>
                  </div>
              </div>
          </div>
      </div>
     
    <div className="uk-width-medium-1-6">
          <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
              <div className="md-card-content">
                  <div className={styles.dashGridTable}>
                    <img src="/images/today.png" className={styles.calendarImg} alt="today"></img>
                    <span className={styles.dashboardTitleText}>금일 제주 SMP</span>
                    <br/>
                    <br/>
                    <span className={styles.dashboardPriceText}>{dashboardData.todaySmpDayJeju}</span>&nbsp;<span className={styles.dashboardUnitText}>원/kWh</span><br/>
                  </div>
              </div>
          </div>
      </div>
      <div className="uk-width-medium-1-6">
          <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
              <div className="md-card-content">
                  <div className={styles.dashGridTable}>
                    <img src="/images/thisMonth.png" className={styles.calendarImg} alt="thisMonth"></img>
                    <span className={styles.dashboardTitleText}>금월 육지 SMP</span>
                    <br/>
                    <br/>
                    <span className={styles.dashboardPriceText}>{dashboardData.todaySmpMonthLand}</span>&nbsp;<span className={styles.dashboardUnitText}>원/kWh</span><br/>
                    {/* &nbsp;<span className={styles.dashboardSmallText}>(매일 갱신)</span> */}
                  </div>
              </div>
          </div>
      </div>

      <div className="uk-width-medium-1-6">
          <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
              <div className="md-card-content">
                  <div className={styles.dashGridTable}>
                    <img src="/images/thisMonth.png" className={styles.calendarImg} alt="thisMonth"></img>
                    <span className={styles.dashboardTitleText}>금월 제주 SMP</span>
                    <br/>
                    <br/>
                    <span className={styles.dashboardPriceText}>{dashboardData.todaySmpMonthJeju}</span>&nbsp;<span className={styles.dashboardUnitText}>원/kWh</span><br/>
                    {/* &nbsp;<span className={styles.dashboardSmallText}>(매일 갱신)</span> */}
                  </div>
              </div>
          </div>
      </div>
      <div className="uk-width-medium-1-6">
          <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
              <div className="md-card-content">
                  <div className={styles.dashGridTable}>
                    <img src="/images/lastMonth.png" className={styles.calendarImg} alt="lastMonth"></img>
                    <span className={styles.dashboardTitleText}>전월 육지 SMP</span>
                    <br/>
                    <br/>
                    <span className={styles.dashboardPriceText}>{dashboardData.preSmpMonthLand}</span>&nbsp;<span className={styles.dashboardUnitText}>원/kWh</span><br/>
                  </div>
              </div>
          </div>
      </div>
      <div className="uk-width-medium-1-6">
          <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
              <div className="md-card-content">
                  <div className={styles.dashGridTable}>
                  <img src="/images/lastMonth.png" className={styles.calendarImg} alt="lastMonth"></img>
                  <span className={styles.dashboardTitleText}>전월 제주 SMP</span>
                  <br/>
                  <br/>
                  <span className={styles.dashboardPriceText}>{dashboardData.preSmpMonthJeju}</span>&nbsp;<span className={styles.dashboardUnitText}>원/kWh</span><br/>
                  </div>
              </div>
          </div>
      </div>
    </div>
    </>
    
  );
};

export default SmpDashBoardDayMonthData;