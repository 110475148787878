import JeongSeonHeader from "./jeongseonHeader";
import JeongSeonMunic from "./munic/jeongseonMunic";

const JeongseonMainPage = props => {
  const { conn } = props;

  return (
    <div id="app">
      <JeongSeonHeader conn={conn} />
      <JeongSeonMunic conn={conn} />
    </div>
  )
}

export default JeongseonMainPage;