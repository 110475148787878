import "./index.css";
import { ReactComponent as RenewableEnergy } from "../../../../../assets/svg/gongju/renewableEnergy.svg";
import { ReactComponent as Tooltip } from "../../../../../assets/svg/gongju/tooltipLogo.svg";
import { numFormat, roundNum } from "com/dataUtil";
import { useRef } from "react";

const PowerGeneration = ({ data }) => {
  const tooltipRef = useRef(null);

  const handleFocusTooltip = () => {
    tooltipRef.current.style.display = "flex";
  };

  const handleBlurTooltip = () => {
    tooltipRef.current.style.display = "none";
  };

  const formatEnergy = value => {
    if (value >= 1000) {
      return {
        value: numFormat(roundNum(value / 1000, 1)),
        unit: "MWh",
      };
    }
    return {
      value: numFormat(roundNum(value, 1)),
      unit: "kWh",
    };
  };

  const formatCo2 = value => {
    if (value >= 1000) {
      return {
        value: numFormat(roundNum(value / 1000, 1)),
        unit: "tCO₂",
      };
    }
    return {
      value: numFormat(roundNum(value, 1)),
      unit: "CO₂",
    };
  };

  return (
    <div className="gongju-right-top-container">
      <div className="gongju-flex-container">
        <span className="gongju-dashboard-title">에너지원별 발전현황</span>
        <div className="powerGeneration-container">
          <div className="powerGeneration-wrapper">
            <span className="powerGeneration-gongju-text-1">태양광</span>
            <div>
              <span className="powerGeneration-gongju-text-2"> {formatEnergy(data.solaDayPower).value}</span>
              <span className="powerGeneration-gongju-text-3"> {formatEnergy(data.solaDayPower).unit}</span>
            </div>
            <span className="powerGeneration-gongju-text-4">
              온실가스 감소량 {formatCo2(data.solaCo2).value} {formatCo2(data.solaCo2).unit}
            </span>
          </div>
          <div className="powerGeneration-wrapper">
            <span className="powerGeneration-gongju-text-1">태양열</span>
            <div>
              <span className="powerGeneration-gongju-text-2">{formatEnergy(data.heatDayPower).value}</span>
              <span className="powerGeneration-gongju-text-3"> {formatEnergy(data.heatDayPower).unit}</span>
            </div>
            <span className="powerGeneration-gongju-text-4">
              온실가스 감소량 {formatCo2(data.heatCo2).value} {formatCo2(data.heatCo2).unit}
            </span>
          </div>
          <div className="powerGeneration-wrapper">
            <span className="powerGeneration-gongju-text-1">지열</span>
            <div>
              <span className="powerGeneration-gongju-text-2">{formatEnergy(data.geoDayPower).value}</span>
              <span className="powerGeneration-gongju-text-3"> {formatEnergy(data.geoDayPower).unit}</span>
            </div>
            <span className="powerGeneration-gongju-text-4">
              온실가스 감소량 {formatCo2(data.geoCo2).value} {formatCo2(data.geoCo2).unit}
            </span>
          </div>
        </div>
      </div>
      <div className="gongju-flex-container">
        <div className="renewableEnergy-title-wrapper">
          <span className="gongju-dashboard-title">신재생에너지 보급률</span>
          <Tooltip className="renewableEnergy-tooltip" onMouseOver={handleFocusTooltip} onMouseOut={handleBlurTooltip} />
          <div className="renewableEnergy-tooltip-container" ref={tooltipRef}>
            2024년 공주시 현황 자료 기준
            <br />
            (출처 : 한국에너지공단)
          </div>
        </div>
        <div className="renewableEnergy-wrapper">
          <RenewableEnergy className="renewableEnergy-icon" />
          <span className="powerGeneration-gongju-text-5">2.78%</span>
        </div>
      </div>
    </div>
  );
};

export default PowerGeneration;
