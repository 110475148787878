import log from '../../../com/log';
import kendo from "@progress/kendo-ui";
import { getMessage } from '../../../com/messageUtil';

const sttstMngtComUrl = 'sttstMngt';
// 통계 데이터 조회
export const getSttstData = async (conn, data) => {
  const { skip, take, sort, filter } = data;
  const config = {
    params: {
      page: (take + skip) / take
      , size: take
      , sort
    }
  };
  // filter 값 설정
  if (filter !== undefined) {
    if (filter.address !== "") config.params.address = filter.address;
    if (filter.bizSct !== "") config.params.bizSct = filter.bizSct;
    if (filter.bizYy !== "") config.params.bizYy = filter.bizYy;
    if (filter.ensoTypeCode !== "") config.params.ensoTypeCode = filter.ensoTypeCode;
    if (filter.cityCode !== "") config.params.cityProvCode = filter.cityProvCode;
    if (filter.cityProvTerm !== undefined) config.params.cityProvTerm = filter.cityProvTerm;
    if (filter.rgnCode !== "") config.params.rgnCode = filter.rgnCode;
    if (filter.rgnTermSimple !== undefined) config.params.rgnTermSimple = filter.rgnTermSimple;
    if (filter.fixbylawBundCode !== "") config.params.fixbylawCode = filter.fixbylawBundCode;
    if (filter.fixbylawBundTermSimple !== undefined) config.params.fixbylawBundTermSimple = filter.fixbylawBundTermSimple;
  }
  let response = await conn.get(sttstMngtComUrl + '/page', config);
  return response;
};


// 통계 데이터 수정
export const setUpdateSttst = async (conn, data) => {
  const dataArr = [];

  if (data.constructor === Object) {
    dataArr.push(data);
  } 

  const response = await conn.post(sttstMngtComUrl, JSON.stringify(dataArr), {
    headers: { 'Content-Type': 'application/json' }
  });
  return response;
}

// 통계 데이터 삭제
export const setDeleteSttst = async (conn, data) => {
  try {

    const response = await conn.delete(sttstMngtComUrl, {
      headers: { 'Content-Type': 'application/json' }
      , data: data
    });

    log(response, 'setDeleteSttst');
    return response;

  } catch (e) {
    kendo.alert(getMessage(e));
  }
}