import React, { useState, memo, useEffect } from "react";
import GathRawDataLogSearch from "./GathRawDataLogSearch";
import GathRawDataLogGridSola from "./GathRawDataLogGridSola";
import GathRawDataLogGridHeat from "./gathRawDataLogGridHeat";
import GathRawDataLogGridHeath from "./gathRawDataLogGridHeath";
import GathRawDataLogGridVelo from "./GathRawDataLogGridVelo";
import GathRawDataLogGridFuelcell from "./gathRawDataLogGridFuelcell";

import { getRawDataList } from "../../../service/stats/raw/rawDataService";
import queryString from "query-string";
import { useLocation } from "react-router";
import kendo from "@progress/kendo-ui";
import { BeatLoader } from "react-spinners";

//CSS
import "./GathRawDataLog.css";
import styles from "./gathRawDataLog.module.css";
import { getUser, isInstaller } from "../../../service/authService";

const OperHist = memo(({ conn, codeList, cityList, rgnList, isMobile }) => {
  const location = useLocation();
  const { cid, ensoType } = queryString.parse(location.search);

  let getCid = cid;
  // 유저정보
  const userInfo = getUser();
  let userTerm = userInfo ? userInfo.userTerm : "";
  let userId = userInfo ? userInfo.userId : "";

  // 오늘
  const today = new Date();

  // 검색 객체 (시작날짜는 현재 시간의 1시간 전으로 기준)
  const [search, setSearch] = useState({
    ensoTypeCode: ensoType ? Number(ensoType) : 15001,
    cityProvCode: "",
    rgnCode: "",
    fixbylawBundCode: "",
    startGathDtm: new Date(today.setDate(today.getDate() - 1)),
    endGathDtm: new Date(),
    makrSeriNo: "",
    meainTypeSeriNo: "",
    rtuEnteCode: "",
    cid : getCid ? getCid : "",
    userTerm: isInstaller() === true ? userTerm : "",
    search: getCid ? true : false,
    userId : isInstaller() === true ? userId : ""
  });

  const [paging, setPage] = useState({
    skip: 0,
    take: 20,
    count: 0,
    // sort : [{field: 'gathDtm', dir: 'desc'}],
    sort: [],
    list: [],
  });

  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 조회 로직
  const setOperHistData = (search, paging) => {
    if (!search.ensoTypeCode) {
      kendo.alert("에너지원을 선택 해주세요.");
      return;
    }
    setLoading(true);
    getRawDataList(conn, getDataSource, getRejection, search, paging, loading, setLoading);
  };
  // 데이터 소스 설정 CALLBACK
  const getDataSource = result => {
    setPage(paging => {
      const item = { ...paging };
      item.list = result.results;
      item.count = result.total;
      return item;
    });
    setLoading(false);
  };
  // 데이터 소스 설정 rejection 함수 (현재 불필요)
  const getRejection = error => {
    setLoading(false);
  };

  // RTU 업체 ( 사용 안함 X )
  const rtuList = [];
  // const getRtuEnteList = () => {
  //   getRtu(conn)
  //     .then(response => {
  //       setRtuList(response);
  //     })
  //     .catch(error => {
  //       getRejection(error);
  //     });
  // };
  // 설비 제조사 & 설비 모델 ( 사용 안함 X )
  const makrMeainModelList = {
    makrList: [],
    meainModelList: [],
  };
  // const getMeainModlMakrList = () => {
  //   getMeainModlMakr(conn, getMeainModleMakrResult, getRejection);
  // };
  // const getMeainModleMakrResult = result => {
  //   console.log("result = ", result);
  //   setMakrMeainModelList(makrMeainModelList => {
  //     const item = { ...makrMeainModelList };
  //     item.makrList = result.makrDtoList;
  //     item.meainModelList = result.meainModelDtoList;
  //     return item;
  //   });
  // };

  // CID , 설치자 , 수집일자 , 데이터상태 값의 포맷
  const cellKeyFormat = data => {
    const field = data.field;
    let value = "-";
    let fontColor = "black";
    if (field === "gathDtm") {
      value = data.dataItem[field] ? data.dataItem[field].replace("T", " ") : "-";
    } else if (field === "dataStatSctCodeTerm") {
      if (data.dataItem[field] !== "정상") {
        fontColor = "red";
      }
      value = data.dataItem[field];
    } else {
      value = data.dataItem[field];
    }
    return <td style={{ textAlign: "center", color: fontColor }}>{value}</td>;
  };

  // Cell 의 각 데이터 값에 대한 포맷
  const cellFormat = data => {
    const field = data.field;
    let value = "-";
    // 동적으로 객체 존재를 확인 하고 있다면 포맷 적용 (undefined 와 null 체크 )
    if (data.dataItem.hasOwnProperty(field) && data.dataItem[field] != null) {
      if (field === "errCode") {
        value = data.dataItem[field];
      } else {
        value = kendo.format("{0:n0}", data.dataItem[field]);
      }
    }
    return <td style={{ textAlign: "right", paddingRight: "6px" }}>{value}</td>;
  };

  useEffect(() => {
    // 처음 데이터 불러오는 부분 제어 로직
    // getRtuEnteList();
    // getMeainModlMakrList();
    // setOperHistData(search, paging);
  }, []);

  return (
    <>
      <div className={loading ? styles.operHistArea : ""}>
        <GathRawDataLogSearch
          search={search}
          setSearch={setSearch}
          conn={conn}
          codeList={codeList}
          cityList={cityList}
          rgnList={rgnList}
          paging={paging}
          setOperHistData={setOperHistData}
          rtuList={rtuList}
          makrList={makrMeainModelList.makrList}
          meainModelList={makrMeainModelList.meainModelList}
          setLoading={setLoading}
          isMobile={isMobile}
        />
        {search.ensoTypeCode === 15001 && (
          <GathRawDataLogGridSola
            search={search}
            paging={paging}
            setOperHistData={setOperHistData}
            cellKeyFormat={cellKeyFormat}
            cellFormat={cellFormat}
          />
        )}
        {search.ensoTypeCode === 15002 && (
          <GathRawDataLogGridHeat
            search={search}
            paging={paging}
            setOperHistData={setOperHistData}
            cellKeyFormat={cellKeyFormat}
            cellFormat={cellFormat}
          />
        )}
        {search.ensoTypeCode === 15003 && (
          <GathRawDataLogGridHeath
            search={search}
            paging={paging}
            setOperHistData={setOperHistData}
            cellKeyFormat={cellKeyFormat}
            cellFormat={cellFormat}
          />
        )}
        {search.ensoTypeCode === 15004 && (
          <GathRawDataLogGridVelo
            search={search}
            paging={paging}
            setOperHistData={setOperHistData}
            cellKeyFormat={cellKeyFormat}
            cellFormat={cellFormat}
          />
        )}
        {search.ensoTypeCode === 15006 && (
          <GathRawDataLogGridFuelcell
            search={search}
            paging={paging}
            setOperHistData={setOperHistData}
            cellKeyFormat={cellKeyFormat}
            cellFormat={cellFormat}
          />
        )}
        {search.ensoTypeCode === "" && (
          <GathRawDataLogGridSola
            search={search}
            paging={paging}
            setOperHistData={setOperHistData}
            cellKeyFormat={cellKeyFormat}
            cellFormat={cellFormat}
          />
        )}
        {loading && (
          <p className={styles.operHistLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </>
  );
});

export default OperHist;
