import React from "react";

import { useEffect, useState } from "react";
import { getJeongseonWeathInfo } from "../../../service/analy/weathCmpService";
import { roundNum } from "../../../com/dataUtil";

import HeaderTimer from "../../headerTimer";
import HeaderFullScr from "../../headerFullSc";

import "./jeongseonHeader.css";

const JeongSeonHeader = ({conn}) => {  
  const [weathInfo, setWeathInfo] = useState({
    presWeathVal: "",
    rgnCode: "",
    temp: "",
    img: "",
    rgnTerm: "",
  });


  useEffect(() => {      
    getJeongseonWeathInfo(conn)
      .then(response => {
        if (response) {
          setWeathInfo(weathInfo => {
            const item = { ...weathInfo };
            item.presWeathVal = response.presWeathVal;
            item.rgnCode = response.rgnCode;
            item.temp = roundNum(response.temp, 2);
            item.rgnTerm = response.rgnTerm;
            if (item.presWeathVal === "구름") {
              item.img = "/images/weather/weather_icon01.png";
            } else if (item.presWeathVal === "구름 많음") {
              item.img = "/images/weather/weather_icon02.png";
            } else if (item.presWeathVal === "구름 조금") {
              item.img = "/images/weather/weather_icon03.png";
            } else if (item.presWeathVal === "눈") {
              item.img = "/images/weather/weather_icon04.png";
            } else if (item.presWeathVal === "비/눈") {
              item.img = "/images/weather/weather_icon05.png";
            } else if (item.presWeathVal === "맑음") {
              item.img = "/images/weather/weather_icon06.png";
            } else if (item.presWeathVal === "비") {
              item.img = "/images/weather/weather_icon07.png";
            } else if (item.presWeathVal === "흐림") {
              item.img = "/images/weather/weather_icon08.png";
            } else if (item.presWeathVal === "소나기") {
              item.img = "/images/weather/weather_icon09.png";
            } else if (item.presWeathVal === "눈/비") {
              item.img = "/images/weather/weather_icon10.png";
            } else {
              item.img = "/images/weather/weather_icon06.png";
            }
            return item;
          });
        }
      })
      .catch(error => console.log(error, "getWeathInfo Error!"));
    /* eslint-disable-next-line */
  }, []);

  const btnClickEvt = () => {
    window.location.href = "https://kremc.kr/";
  };

  return (
    <header id="header_main">      
      <nav className="uk-navbar">  
        <div className="uk-navbar-center header-center">
          <h1 className="mainTitle">
            <div className="title" id = 'title'>
              <img alt="" id="headerLogo" src="/images/dist/42770_logo.png" style={{ width: "65px"}}></img>
              <div className="title-text" id = "localAreaTitle">
                <h1 id="headerTerm" className="title-h1">
                  정선군 신재생에너지 관리센터
                </h1>
                <img id = "headerImg" className="headerImg" alt="" ></img>
                <h4 id="subHeaderTerm" className="title-h4">Renewable Energy Management Center</h4>
              </div>
            </div>
          </h1>  
          <div className="uk-navbar-flip">
            <ul className="uk-navbar-nav user_actions">
              <li className="user_action_icon">
                <span
                  style={{                                                            
                    lineHeight:'60px',                                        
                  }}>                  
                  <HeaderTimer />
                  {weathInfo.rgnCode ? (
                    <span className="headata">
                      <span className="headRgnData">&nbsp;| {weathInfo.rgnTerm} |</span>{" "}
                      <span className="headTempData">
                        {weathInfo.temp}℃ &nbsp; <img alt="날씨" src={weathInfo.img} />
                      </span>
                    </span>
                  ) : null}                  
                </span>
              </li>
              <li className="loginIconLi">
                <button 
                  style={{color:'#333', background:'transparent', border:'none', lineHeight:'60px', height:'70px', padding:'0 15px', }}
                  onClick={btnClickEvt} className="user_action_icon">
                  <span className="headimgB">
                    <img src="/images/icon_login.png" alt="로그인" />
                  </span>
                </button>
              </li>
              <li className="headerFullLi">
                {" "}
                <HeaderFullScr />{" "}
              </li>
            </ul>
          </div>        
        </div>                
      </nav>                               
    </header>
  );
}

export default JeongSeonHeader;