import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Search } from "@material-ui/icons";
import { Window } from "@progress/kendo-react-dialogs";

import "./installerMeainItem.css";
// import './installerMeainItem.dk.css';
import styles from "./installerMeainItem.module.css";

const InstallerMeainItem = props => {
  const history = useHistory();
  let item = props.dataItem;
  const [isMobile, setIsMobile] = useState(false);
  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;
  useEffect(() => {
    if (navigator.userAgent.indexOf("Mobile") === -1) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  const [state, setState] = useState({
    visible: false,
    leftPosition: 200,
    rightPosition: 200,
  });

  // const meainList = [
  //   { cid: "1111111111", dayPower: 1111, meainStat: 7001 },
  //   { cid: "1111111112", dayPower: 2222, meainStat: 7002 },
  //   { cid: "1111111113", dayPower: 3333, meainStat: 7006 },
  // ];
  //
  // const installer = {};

  const viewMeainInfo = e => {
    let x = e.clientX || 200;
    let y = e.clientY || 200;
    if (isMobile) {
      x = 0;
      y = innerHeight * 0.2;
    } else {
      if (x > 1000 && x < 1400) x = 1000;
      else if (x > 1400) x = 1200;
      if (y > 600 && y < 700) y = 600;
      else if (y > 700) y = 620;
    }
    setState({
      ...state,
      visible: !state.visible,
      leftPosition: x,
      rightPosition: y,
    });
  };

  const goGathInfo = e => {
    if (Number(item.meainStatCodeNo) === 7001) alert("운영 준비중인 설비 입니다.");
    else history.push("/monitor/installerGath?installerId=" + item.consmId + "&ensoType=" + item.ensoTypeCode + "&rgnCode=" + item.rgnCode);
  };

  const goOperHistory = e => {
    if (Number(item.meainStatCodeNo) === 7001) alert("운영 준비중인 설비 입니다.");
    else history.push("/analy/raw/log?cid=" + item.cid + "&ensoType=" + item.ensoTypeCode);
  };

  // const selectMeain = e => {
  //   setState({
  //     ...state,
  //     selected: e.selected,
  //   });
  // };
  // eslint-disable-next-line
  const getTitleStyle = useCallback(meainStatCodeNo => {
    switch (meainStatCodeNo) {
      case 7001:
        return styles.ready;
      case 7002:
        return styles.normal;
      case 7004:
        return styles.unOper;
      case 7006:
        return styles.warn;
      case 7007:
        return styles.error;
      default:
        return styles.unOper;
    }
  });

  // 유저 이름 폰트 스타일 설정 _ 20220210 _ jhchoi
  const setUserTermFontStyle = (value) => {
    let fontSize = 15;
    let length = value ? value.toString().replace(" " , "").length : 0;
    if( length > 22 ){
      // console.log('길이 22 이상 : ', value , length);
      let calValue = (length - 10) + ( ((length - 10) - 13) * - 2 ) ;
      calValue = calValue < 12 ? 10 : calValue;
      fontSize =  calValue < 0 ? 15 : calValue ;
    }
    return <span style={{fontSize : fontSize}}>{value}</span>
  }

  // 사업 구분 글자 크기 조절 _ 20220210 _ jhchoi
  const setBizSctTermStyle = (value) => {
    let fontSize = 12;
    if( value && value.toString() === "주택지원(구그린홈)" ){
      fontSize = 11;
    }
    return <span style={{fontSize: fontSize}}>{value ? value : '-'}</span>
  }

  // eslint-disable-next-line
  const getEnsoImgSrc = useCallback((ensoTypeCode, meainStatCodeNo) => `/images/enso/icon_${ensoTypeCode}_${meainStatCodeNo}_Big.png`);

  const getEnsoItem = () => {
    // console.log("what enso", item.ensoTypeCode);
    if (Number(item.ensoTypeCode) === 15001) {
      return (
        <tr>
          <th>금일 발전량</th>
          <td>{item.dayPower} kWh</td>
          <th>누적 발전량</th>
          <td>{item.accumPower} MWh</td>
        </tr>
      );
    } else {
    }
  };
  return (
    <div className="uk-width-medium-1-5" style={{ display: "inline-block", paddingLeft: "35px", marginTop: "10px" }}>
      <table className="installerMeain">
        <thead>
          <tr>
            <th rowSpan="2">
              <img className={styles.blankImg} src={getEnsoImgSrc(item.ensoTypeCode, item.meainStatCodeNo)} alt="" />
            </th>
            <td colSpan="3" className={getTitleStyle(item.meainStatCodeNo)}>
              {setUserTermFontStyle(item.userTerm)}
            </td>
          </tr>
          <tr>
            <th onClick={viewMeainInfo} className={styles.infoClick}>
              설비정보 <Search className="material-icons" />
            </th>
            <th onClick={goGathInfo} className={styles.infoClick}>
              계측정보 <Search className="material-icons" />
            </th>
            <th onClick={goOperHistory} className={styles.infoClick}>
              운전이력 <Search className="material-icons" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>사업구분</th>
            <td>{setBizSctTermStyle(item.bizSctTerm) || "-"}</td>
            <th>건물용도</th>
            <td>{item.bldSrvTerm || "-"}</td>
          </tr>
          <tr>
            <th>지역</th>
            <td>{item.rgnTerm}</td>
            <th>설치날짜</th>
            <td>{item.meainInstDtm}</td>
          </tr>
          <tr>
            <th>설비용량</th>
            <td>{item.instCapa ? item.instCapa + " kW" : ""}</td>
            <th>금일발전량</th>
            <td>{item.dayPower || 0} kWh</td>
          </tr>
        </tbody>
      </table>
      {state.visible && (
        <Window
          className="installerMeain-info"
          title={item.userTerm}
          onClose={viewMeainInfo}
          width={isMobile ? innerWidth : 640}
          height={isMobile ? innerHeight * 0.5 : 280}
          left={state.leftPosition}
          top={state.rightPosition}
          doubleClickStageChange={false}>
          <table className="installerMeainPopW">
            <tbody>
              <tr>
                <th>CID</th>
                <td colSpan="3">{item.cid}</td>
              </tr>
              <tr>
                <th>위치</th>
                <td colSpan="3">{item.address}</td>
              </tr>
              <tr>
                <th>사업구분</th>
                <td>{item.bizSctTerm}</td>
                <th>사업연도</th>
                <td>-</td>
              </tr>
              <tr>
                <th>설비용량</th>
                <td>{item.instCapa} kW</td>
                <th>설비 상태</th>
                <td>{item.meainStatVal}</td>
              </tr>
              {getEnsoItem()}
              <tr>
                <th>설치일시</th>
                <td>{item.meainInstDtm}</td>
                <th>최종수신일시</th>
                <td>{item.finalRcpmsgDtm ? item.finalRcpmsgDtm.replace("T", " ") : item.finalRcpmsgDtm}</td>
              </tr>
              <tr>
                <th>특이사항</th>
                <td colSpan="3">-</td>
              </tr>
            </tbody>
          </table>
        </Window>
      )}
    </div>
  );
};
export default InstallerMeainItem;
