import React , {useState , useEffect , memo, useRef} from "react";
import kendo from '@progress/kendo-ui';
import {getMeainList, getMeainModlMakr, replaceMeain, setMeain} from '../../../service/meain/meain/meainService';
import MeainGrid from './meain_grid';
import MeainInfoSola from './meain_info_sola';
import MeainInfoHeat from "./meain_info_heat";
import MeainInfoHeath from "./meain_info_heath";
import MeainInfoVelo from "./meain_info_velo";
import MeainInfoFuelcell from "./meain_info_fuelcell";
import MeainInfoHeatNa from "./meain_info_heatNa";
import MeainInfoControl from "./meain_info_control";
import MeainInfoCom from "./meain_info_com";
import styles from "./meain.module.css";
// 로딩 스피너 
import { BeatLoader } from 'react-spinners'
import UserSearchList from "../../com/userSearch/userSearchList";
import MeainInfoEss from "./meain_info_ess";

const MeainTab = memo(({ conn, codeList, cityList, rgnList, meainDs, ensoTypeCode, sysMode, cityFilterDropDown, rgnFilterDropDown , bpmManagementInfo ,
  setMeainDs
}) => {

  // 설비 리스트의 로딩 스피너
  const [meainListLoading , setMeainListLoading] = useState(true);

  // 저장 , 엑셀 저장 로딩 스피터
  const [saveLoading , setSaveLoading] = useState(false);

  // 윈도우 창 Visible
  let [userInfoVisible , setUserInfoVisible] = useState(false);
  // 윈도우 창 position
  // const [userInfoPosition , setUserInfoPosition] = useState(0);

  // rtu 업체 , 설비 제조사 & 모델 데이터 소스
  const rtuEnteList = meainDs.rtuEnteList;
  const meainModlList = meainDs.meainModleMakrList;

  // 연동 설비 정보
  const meainSyncInfo = bpmManagementInfo.meainInfo;
  // console.log("meainSyncInfo = ", meainSyncInfo);

  // 설치자 검색 버튼 클릭 이벤트
  const searchUserId = () =>{
    // setUserInfoPosition(controlWindowPosition());    
    setUserInfoVisible(!userInfoVisible);
  }

  // 그리드에서 유저 선택 시 이벤트
  const selectUserId = (e) =>{
    const obj = {
        ...paging.selectMeain
        , userId : e.dataItem.userId
        , userTerm : e.dataItem.userTerm
        , rgnCode : e.dataItem.rgnCode
        // , cityProvCode : e.dataItem.cityProvCode
        // , alias : e.dataItem.userTerm (SREMS에서는 직접 입력)
    };
    setMeainInfo(obj);
    setUserInfoVisible(false);
}

  let isEnso = 0;
  if (ensoTypeCode === 15001) {
    isEnso = 15001;
  } else if (ensoTypeCode === 15002) {
    // 태양열 자연계 / 일반 설정
    if (sysMode === 29001) {
      isEnso = 29001;
    } else {
      isEnso = 29002;
    }
  } else if (ensoTypeCode === 15003) {
    isEnso = 15003;
  } else if (ensoTypeCode === 15004) {
    isEnso = 15004;
  } else if (ensoTypeCode === 15006) {
    isEnso = 15006;
  } else if (ensoTypeCode === 15007) {
    isEnso = 15007;
  }


  // 페이징
  const [paging, setPage] = useState({
    skip: 0,
    take: 20,
    count: 0,
    sort: [],
    list: [],
    selectMeain: {},
    selectId: "",
    filter: { cid: "", userTerm: "", cityProvCode: "", rgnCode: "", meainStatusCode: "" },
    sysMode: sysMode,
  });

  // 로우 선택 시 
  const setSelectMeain = (meain) =>{
    // 연동 객체 초기화
    bpmManagementInfo.meainInfo = {};
      setPage( 
          paging => {
              const item = {...paging};
              item.selectMeain = meain.selectMeain;
              item.selectId = meain.selectId;
              return item;
          }
      );
  }

  // 객체 변경 시
  const setMeainInfo = (meain) => {
    // 연동 객체 초기화
    bpmManagementInfo.meainInfo = {};
    setPage((paging) => {
      const item = { ...paging };
      item.selectMeain = meain;
      return item;
    });
  };

  // 선택된 객체 초기화
  const resetInfo = () => {
    // 연동 객체 초기화
    bpmManagementInfo.meainInfo = {};
    setPage((paging) => {
      const item = { ...paging };
      item.selectId = "";
      item.selectMeain = {};
      return item;
    });
  };

  // 설비 리스트 불러오기
  const setMeainList = (paging) => {
    setMeainListLoading(true);
    getMeainList(conn, getDataSource, getRejection, paging, ensoTypeCode);
  };

  // 설비 리스트 CallBack
  const getDataSource = (result) => {
    setMeainListLoading(false);
    setPage((paging) => {
      const item = { ...paging };
      item.list = result.results;
      item.count = result.total;
      return item;
    });
  };

  // 설비 리스트 reJect
  const getRejection = (error) => {
    // 스피너 제어
    setMeainListLoading(false);
    setSaveLoading(false);
    // 공통 설비 에러 코드 처리
    if (error.errCode === "L001") {
      validMessage("설치자", "drop");
      return;
    } else if (error.errCode === "M002") {
      validMessage("사업연도", "drop");
      return;
    } else if (error.errCode === "M004") {
      validMessage("RTU업체", "drop");
      return;
    } else if (error.errCode === "M005") {
      validMessage("통신방식", "drop");
      return;
    } else if (error.errCode === "M007") {
      validMessage("주소", "text");
      return;
    } else if (error.errCode === "M008") {
      validMessage("위도", "text");
      return;
    } else if (error.errCode === "M009") {
      validMessage("경도", "text");
      return;
    } else if (error.errCode === "M010") {
      validMessage("건물용도", "drop");
      return;
    } else if (error.errCode === "M011") {
      validMessage("시공업체", "text");
      return;
    } else if (error.errCode === "M014") {
      validMessage("시공일시", "drop");
      return;
    } else if (error.errCode === "M015") {
      validMessage("모니터링 시작일시", "drop");
      return;
    } else if (error.errCode === "M016") {
      validMessage("AS만료일", "drop");
      return;
    } else if (error.errCode === "M017") {
      validMessage("LoRa ID", "text");
      return;
    } else if (error.errCode === "M018") {
      validMessage("IMEI", "text");
      return;
    } else if (error.errCode === "M019") {
      kendo.alert("입력된 위도 값이 잘못 되었습니다. 다시 한번 확인 하시고 입력 해주세요. (범위 : 32.765586 ~ 38.929936)");
      return;
    } else if (error.errCode === "M020") {
      kendo.alert("입력된 경도 값이 잘못 되었습니다. 다시 한번 확인 하시고 입력 해주세요. (범위 : 124.611705 ~ 131.729758)");
      return;
    } else if (error.errCode === "M021") {
      kendo.alert("입력된 방위각 값이 잘못 되었습니다. 다시 한번 확인 하시고 입력 해주세요. (범위 : 90 ~ 270)");
      return;
    } else if (error.errCode === "M022") {
      kendo.alert("입력된 경사각 값이 잘못 되었습니다. 다시 한번 확인 하시고 입력 해주세요. (범위 : 0 ~ 90)");
      return;
    } else if (error.errCode === "M023") {
      kendo.alert("최대 Multi(Port) 갯수는 4개 입니다.");
      return;
    } else if (error.errCode === "M024") {
      kendo.alert("인증키가 입력되지 않았습니다. 인증키를 입력 해주세요.");
      return;
    }

    // 태양광 설비 에러 코드
    else if (error.errCode === "MS001") {
      validMessage("설치유형", "drop");
      return;
    } else if (error.errCode === "MS002") {
      validMessage("모듈 결정 분류", "drop");
      return;
    } else if (error.errCode === "MS003") {
      validMessage("모듈당 용량", "text");
      return;
    } else if (error.errCode === "MS004") {
      validMessage("모듈 총 용량", "text");
      return;
    } else if (error.errCode === "MS005") {
      validMessage("모듈 제조사", "drop");
      return;
    } else if (error.errCode === "MS006") {
      validMessage("모듈 모델", "drop");
      return;
    } else if (error.errCode === "MS007") {
      validMessage("모듈 방위각", "text");
      return;
    } else if (error.errCode === "MS008") {
      validMessage("모듈 경사각", "text");
      return;
    } else if (error.errCode === "MS009") {
      validMessage("모듈 직렬 개수", "text");
      return;
    } else if (error.errCode === "MS010") {
      validMessage("모듈 병렬 개수", "text");
      return;
    } else if (error.errCode === "MS011") {
      validMessage("인버터 제조사", "drop");
      return;
    } else if (error.errCode === "MS012") {
      validMessage("인버터 모델", "drop");
      return;
    } else if (error.errCode === "MS013") {
      validMessage("인버터 용량", "text");
      return;
    } else if (error.errCode === "MS014") {
      validMessage("위상", "drop");
      return;
    } else if (error.errCode === "MS015") {
      validMessage("추적 시스템", "drop");
      return;
    }

    // 태양열 , 태양열 자연순환식 설비 공통 에러 코드
    else if (error.errCode === "MH001") {
      validMessage("현재 사용 열원", "text");
      return;
    } else if (error.errCode === "MH002") {
      validMessage("설치 유형", "text");
      return;
    } else if (error.errCode === "MH003") {
      validMessage("방위각", "text");
      return;
    } else if (error.errCode === "MH004") {
      validMessage("경사각", "text");
      return;
    } else if (error.errCode === "MH005") {
      validMessage("축열 탱크 용량", "text");
      return;
    } else if (error.errCode === "MH006") {
      validMessage("축열 탱크 재질", "text");
      return;
    } else if (error.errCode === "MH007") {
      validMessage("집열기 면적", "text");
      return;
    }

    // 태양열 설비 에러 코드
    else if (error.errCode === "MH008") {
      validMessage("집열기 종류", "text");
      return;
    } else if (error.errCode === "MH009") {
      validMessage("집열기 제조사", "drop");
      return;
    } else if (error.errCode === "MH010") {
      validMessage("집열기 모델", "drop");
      return;
    } else if (error.errCode === "MH011") {
      validMessage("집열기 직렬 개수", "text");
      return;
    } else if (error.errCode === "MH012") {
      validMessage("집열기 병렬 개수", "text");
      return;
    } else if (error.errCode === "MH013") {
      validMessage("컨트롤로(차온제어) 제조사", "drop");
      return;
    } else if (error.errCode === "MH014") {
      validMessage("컨트롤로(차온제어) 모델", "drop");
      return;
    }
    // else if( error.errCode === "MH015" ){ validMessage( "부하측 열량계 제조사" , "drop" ); return; }
    // else if( error.errCode === "MH016" ){ validMessage( "부하측 열량계 모델" , "drop" ); return; }
    else if (error.errCode === "MH017") {
      validMessage("열매체 펌프 유형", "text");
      return;
    } else if (error.errCode === "MH018") {
      validMessage("열매체 펌프 유량", "text");
      return;
    } else if (error.errCode === "MH019") {
      validMessage("열매체 펌프 양정", "text");
      return;
    } else if (error.errCode === "MH020") {
      validMessage("열매체 보충펌프 유형", "text");
      return;
    } else if (error.errCode === "MH021") {
      validMessage("열매체 보충펌프 유량", "text");
      return;
    } else if (error.errCode === "MH022") {
      validMessage("열매체 보충펌프 양정", "text");
      return;
    } else if (error.errCode === "MH023") {
      validMessage("온수 순환펌프 유형", "text");
      return;
    } else if (error.errCode === "MH024") {
      validMessage("온수 순환펌프 유량", "text");
      return;
    } else if (error.errCode === "MH025") {
      validMessage("온수 순환펌프 양정", "text");
      return;
    } else if (error.errCode === "MH026") {
      validMessage("유속", "text");
      return;
    } else if (error.errCode === "MH027") {
      validMessage("운전압력", "text");
      return;
    } else if (error.errCode === "MH028") {
      validMessage("배관관경", "text");
      return;
    }

    // 태양열 자연순환식 설비 에러 코드
    else if( error.errCode === "MHN001" ){ validMessage( "집열기 매수" , "text" ); return; }
    // else if( error.errCode === "MHN002" ){ validMessage( "온수기 제조사" , "drop" ); return; }
    // else if( error.errCode === "MHN003" ){ validMessage( "온수기 모델명" , "drop" ); return; }
    // else if( error.errCode === "MHN004" ){ validMessage( "열량계 제조사" , "drop" ); return; }
    // else if( error.errCode === "MHN005" ){ validMessage( "열량계 모델" , "drop" ); return; }

    // 지열 설비 에러 코드
    else if (error.errCode === "MHS001") {
      validMessage("시스템방식", "text");
      return;
    } else if (error.errCode === "MHS002") {
      validMessage("시스템구분", "text");
      return;
    } else if (error.errCode === "MHS003") {
      validMessage("히트펌프용량", "text");
      return;
    } else if (error.errCode === "MHS004") {
      validMessage("히트펌프 제조사", "drop");
      return;
    } else if (error.errCode === "MHS005") {
      validMessage("히트펌프 모델", "drop");
      return;
    } else if (error.errCode === "MHS012") {
      validMessage("냉온수 유량", "text");
      return;
    } else if (error.errCode === "MHS013") {
      validMessage("지열수 유량", "text");
      return;
    } else if (error.errCode === "MHS014") {
      validMessage("벤토나이트 제조사", "drop");
      return;
    } else if (error.errCode === "MHS015") {
      validMessage("벤토나이트 모델", "drop");
      return;
    } else if (error.errCode === "MHS016") {
      validMessage("천공깊이", "text");
      return;
    } else if (error.errCode === "MHS017") {
      validMessage("천공개수", "text");
      return;
    }

    // 풍력 설비 에러 코드
    else if (error.errCode === "MV001") {
      validMessage("발전기용량", "text");
      return;
    } else if (error.errCode === "MV002") {
      validMessage("발전기 제조사", "text");
      return;
    } else if (error.errCode === "MV003") {
      validMessage("발전기 모델", "text");
      return;
    } else if (error.errCode === "MV004") {
      validMessage("발전기 허브높이", "text");
      return;
    } else if (error.errCode === "MV005") {
      validMessage("인버터 제조사", "text");
      return;
    } else if (error.errCode === "MV006") {
      validMessage("인버터 모델", "text");
      return;
    } else if (error.errCode === "MV007") {
      validMessage("인버터 용량", "text");
      return;
    }

    // 연료전지 설비 에러 코드
    // else if( error.errCode === "MF001" ){ validMessage( "연료전지 제조사" , "text" ); return; }
    // else if( error.errCode === "MF002" ){ validMessage( "연료전지 모델명" , "text" ); return; }
    else if( error.errCode === "MF003" ){ validMessage( "연료전지 용량" , "text" ); return; }
    else if( error.errCode === "MF004" ){ validMessage( "모듈 제조사" , "drop" ); return; }
    else if( error.errCode === "MF005" ){ validMessage( "모듈 모델" , "drop" ); return; }
    else if( error.errCode === "MF006" ){ validMessage( "인버터 제조사" , "drop" ); return; }
    else if( error.errCode === "MF007" ){ validMessage( "인버터 모델" , "drop" ); return; }
    else if( error.errCode === "MF008" ){ validMessage( "모듈 용량" , "text" ); return; }
    else if( error.errCode === "MF009" ){ validMessage( "모듈 총용량" , "text" ); return; }
    else if( error.errCode === "MF010" ){ validMessage( "발전기 용량" , "text" ); return; }
    else if( error.errCode === "MF011" ){ validMessage( "인버터 용량" , "text" ); return; }

    else if( error.errCode === "ME001" ){ validMessage( "배터리 제조사" , "drop" ); return; }
    else if( error.errCode === "ME002" ){ validMessage( "배터리 모델" , "drop" ); return; }
    else if( error.errCode === "ME003" ){ validMessage( "배터리 용량" , "text" ); return; }
    else if( error.errCode === "ME004" ){ validMessage( "인버터 제조사" , "drop" ); return; }
    else if( error.errCode === "ME005" ){ validMessage( "인버터 모델" , "drop" ); return; }
    else if( error.errCode === "ME006" ){ validMessage( "인버터 용량" , "text" ); return; }
  
  }

  // Validaion 메세지
  const validMessage = (name, comp) => {
    let meassage = "";
    if (comp === "text") {
      meassage = "설비 중 " + name + " 를 입력하지 않았습니다. " + name + " 를 입력 해주세요.";
    } else if (comp === "drop") {
      meassage = "설비 중 " + name + " 을 선택하지 않거나 입력하지 않았습니다. " + name + " 을 입력 또는 선택 해주세요.";
    }
    kendo.alert(meassage);
  };

  // 저장 로직
  const saveCall = () => {
    kendo.confirm("설비 저장을 하시겠습니까?").then(e => {
      saveApi();
    },  () => { kendo.alert("설비 저장을 취소하였습니다.");return; }) ;
  }

   // 저장 API
   const saveApi = () =>{
    setSaveLoading(true);
    let selectMeain = paging.selectMeain;
    if(selectMeain.meainStatCodeNo === 7008){
      kendo.alert("교체된 설비는 저장을 할 수 없습니다. 다른 설비를 선택 해주세요.");
      setSaveLoading(false);
      resetInfo(paging);
      return;
    }
    if(selectMeain.meainStatCodeNo === 7005){
      kendo.alert("삭제된 설비는 저장을 할 수 없습니다. 다른 설비를 선택 해주세요.");
      setSaveLoading(false);
      resetInfo(paging);
      return;
    }
    // 태양열이라면 sysMode 객체 추가
    if (ensoTypeCode === 15002) {
      selectMeain.sysMode = sysMode;
    }

    // console.log("paging.selectMeain = ", paging.selectMeain);

    if(selectMeain.meainInstDtm)
      selectMeain.meainInstDtm = kendo.toString(selectMeain.meainInstDtm, 'yyyy-MM-dd HH:mm:ss');
    if(selectMeain.mntrStrtDtm)
      selectMeain.mntrStrtDtm = kendo.toString(selectMeain.mntrStrtDtm, 'yyyy-MM-dd HH:mm:ss');
    
      // 설비 저장 
    const saveList = [];
    saveList.push(selectMeain);

    // 값 Validation
    const { boolean, message } = valueValidator(saveList);
    if (boolean === false) {
      setSaveLoading(false);
      kendo.alert(message);
      return;
    }

    setMeain(conn, getSaveResult, getRejection, saveList, ensoTypeCode, sysMode);
  };

  // 설비 저장 callback
  const getSaveResult = (result) => {
    kendo.alert("저장 하였습니다.");
    // meainAdd(paging);
    // 연동 객체 초기화
    bpmManagementInfo.meainInfo = {};
    setSaveLoading(false);
    setMeainList(paging);
    resetInfo(paging);
  };

  const setReplaceMeain = () =>{
    const selectMeain = paging.selectMeain;
    // 태양열이라면 sysMode 객체 추가
    if( ensoTypeCode === 15002 ){
      selectMeain.sysMode = sysMode;
    }
    replaceMeain(conn , setReplaceMeainResult , getRejection , selectMeain , ensoTypeCode );
  }

  const setReplaceMeainResult = (result) => {
    kendo.alert("교체 완료 하였습니다.");
    setMeainList(paging);
    resetInfo(paging);
  }

  // 설비 객체 배열의 필수 값 Validation 
  const valueValidator = (array) => {
    let boolean = true;
    let message = "";

    array.some((value) => {
      // 설치자 ID , 설치자 RTU업체
      if (!value.userId) {
        message = "설치자를 선택 하시거나 엑셀 업로드 일 경우 설치자 ID를 입력 해주세요.";
        boolean = false;
        return true;
      }
      if (!value.alias) {
        message = "설비명을 입력 해주세요.";
        boolean = false;
        return true;
      }
      if (!value.rtuEnteCode) {
        message = "RTU업체를 선택 하시거나 엑셀 업로드 일 경우 RTU 업체 ID를 입력 해주세요.";
        boolean = false;
        return true;
      }
      if (!value.rtuType) {
        message = "RTU모델를 선택 하시거나 엑셀 업로드 일 경우 RTU 모델 ID를 입력 해주세요.";
        boolean = false;
        return true;
      }

      // 통신방식 , 통신모듈
      if (!value.communicationMthd) {
        message = "통신방식를 선택 해주세요.";
        boolean = false;
        return true;
      } else {
        if (value.communicationMthd === "LoRa(SKT)" && !value.loraDevid) {
          message = "LoRa ID를 입력 해주세요.";
          boolean = false;
          return true;
        }
        if (value.communicationMthd === "NBIoT(KT)" && !value.nbDevid) {
          message = "IMEI를 입력 해주세요.";
          boolean = false;
          return true;
        }
        if (value.communicationMthd === "NBIoT(LG)" && !value.nbDevid) {
          message = "IMEI를 입력 해주세요.";
          boolean = false;
          return true;
        }
        if (value.communicationMthd === "TCP/IP(OPC-UA)" && !value.token) {
          message = "인증키를 입력 해주세요.";
          boolean = false;
          return true;
        }
        if (value.communicationMthd === "LTE" && !value.token) {
          message = "인증키를 입력 해주세요.";
          boolean = false;
          return true;
        }
        if (value.communicationMthd === "Cat.M1" && !value.token) {
          message = "인증키를 입력 해주세요.";
          boolean = false;
          return true;
        }
        if (value.communicationMthd === "DB" && !value.token) {
          message = "인증키를 입력 해주세요.";
          boolean = false;
          return true;
        }
      }
      // if( !value.cmnctMdl ){ message = "통신모듈를 선택 하시거나 엑셀 업로드 일 경우 통신모듈를 입력 해주세요."; boolean = false; return true; }

      // 주소
      if (!value.address) {
        message = "주소를 입력 해주세요.";
        boolean = false;
        return true;
      }

      // 멀티(Port 입력)
      // if (value.port === null) {
      //   message = "멀티(port)를 입력 해주세요.";
      //   boolean = false;
      //   return true;
      // }

      // 위도 , 경도
      if (!value.lati) {
        message = "위도를 입력 해주세요.";
        boolean = false;
        return true;
      } else {
        if (value.lati < 32.765586 || value.lati > 38.929936) {
          message = "입력된 위도 값이 잘못 되었습니다. 다시 한번 확인 하시고 입력 해주세요. (범위 : 32.765586 ~ 38.929936)";
          boolean = false;
          return true;
        }
      }
      if (!value.longi) {
        message = "경도를 입력 해주세요.";
        boolean = false;
        return true;
      } else {
        if (value.longi < 124.611705 || value.lati > 131.729758) {
          message = "입력된 경도 값이 잘못 되었습니다. 다시 한번 확인 하시고 입력 해주세요. (범위 : 124.611705 ~ 131.729758)";
          boolean = false;
          return true;
        }
      }

      // 시공업체 , 시공업체 연락처 , 시공업체 핸드폰 번호
      if (!value.consEnte) {
        message = "시공업체를 입력 해주세요.";
        boolean = false;
        return true;
      }

      // 건물용도
      if (!value.bldSrvCodeNo) {
        message = "건물용도를 선택 해주시거나 엑셀업로드 일 경우 입력 해주세요.";
        boolean = false;
        return true;
      }

      // AS 만료일 , 모니터링 시작날짜 , 설비 설치날짜 , 사용전 검사일 체크

      // if( isValidDate(new Date(value.useBforInspDtm)) === false ){ message = "사용전 검사일 날짜가 비정상입니다. 다시 입력 해주세요."; boolean = false; return true; }
      if( isValidDate(new Date(value.asExpiDtm)) === false ){ message = "AS 만료일 날짜가 비정상입니다. 다시 입력 해주세요."; boolean = false; return true; }
      if( isValidDate(new Date(value.mntrStrtDtm)) === false ){ message = "모니터링 시작 날짜가 비정상입니다. 다시 입력 해주세요."; boolean = false; return true; }
      if( isValidDate(new Date(value.meainInstDtm)) === false ){ message = "설비 설치 날짜가 비정상입니다. 다시 입력 해주세요."; boolean = false; return true; }
      return false;
    });
    return { boolean: boolean, message: message };
  };

  // Invalid Date 체크 함수
  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  // Form validation 부분
  const valid = useRef(false);
  const handleSubmit = (event) => {
    // console.log("event = ", event);
    event.preventDefault();
    // 설비 저장
    saveApi();
  };
  // validation 메세지
  const inputValidMsg = { 
    normalText : "필수 항목 입니다." ,
    number0Fix : "필수 항목 입니다. 그리고 입력 값은 정수로 입력 해주세요." ,
    number2Fix : "필수 항목 입니다. 그리고 입력 값의 최대 소수점 자리는 2자리 까지입니다." ,
    number3Fix : "필수 항목 입니다. 그리고 입력 값의 최대 소수점 자리는 3자리 까지입니다." ,
    number6Fix : "필수 항목 입니다. 그리고 입력 값의 최대 소수점 자리는 6자리 까지입니다." 
  };

  // form 에서 키제어 함수
  const keyControl = (e) => {
    // console.log("E = ", e);
    // form 에서 엔터키를 누르면 submit 자동으로 실행으로 아래 함수로 엔터키 입력 시 저장 못하도록 제어 
    if( e.code === "Enter" ){
      e.preventDefault();
      saveCall();
    }
  }

  // 드롭다운 리스트 아이템 스타일 변경  (아이템 렌더링)
  const itemRender = (li, itemProps) => {
    const itemChildren = <div className = {styles.dropDownItem}>{li.props.children}</div>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  // 설비 모델 & 제조사 목록
  const getMeainModlMakrList = () => {
    getMeainModlMakr(conn , getMeainModleMakrResult , getRejection , ensoTypeCode )
  }

  const getMeainModleMakrResult = (result) => {
    // console.log("제조사, 모델 물러오기")
    setMeainDs(meainDs => {
      const item = {...meainDs};
      item.meainModleMakrList = result;
      return item;
    })
    // 연동 설비 설정
    if( meainSyncInfo.meainSync === "Y" ){
      setMeainInfo(meainSyncInfo);
    }
  }

  useEffect(() => {
    // 설비 리스트 불러오기
    setMeainList(paging);
    getMeainModlMakrList();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
    <div className={["uk-grid" , saveLoading ? styles.saveLoadingArea : "" ].join(' ')}>
      <MeainGrid
        conn={conn}
        setMeainList={setMeainList}
        setSelectMeain={setSelectMeain}
        paging={paging}
        codeList={codeList}
        rgnList={rgnList}
        cityFilterDropDown={cityFilterDropDown}
        rgnFilterDropDown={rgnFilterDropDown}
        ensoTypeCodeNo={ensoTypeCode}
        sysMode={sysMode}
        getSaveResult={getSaveResult}
        getRejection={getRejection}
        setMeain={setMeain}
        valueValidator={valueValidator}
        meainListLoading = {meainListLoading}
        setSaveLoading = {setSaveLoading}
        BeatLoader = {BeatLoader}
      />
      <div className="uk-width-medium-1-2">
        {/* <form onSubmit={handleSubmit} onKeyPress = {keyControl} > */}
          <div className={["md-card" , styles.meainInfoArea].join(' ')}>
              <div className="md-card-content">
                  <div className="md-card-toolbar">
                    <h3 className="md-card-toolbar-heading-text">
                        설비상세정보
                    </h3>
                    <div className="md-card-toolbar-actions">
                    <MeainInfoControl conn = {conn} paging = {paging} resetInfo = {resetInfo} setMeainList = {setMeainList} ensoTypeCodeNo = {ensoTypeCode} sysMode = {sysMode} valid = {valid}
                                      setReplaceMeain = {setReplaceMeain} saveCall = {saveCall}
                      ></MeainInfoControl>
                    </div>
                </div>
              </div>
              <div>
              <form onSubmit={handleSubmit} onKeyPress = {keyControl} >
                <MeainInfoCom
                  conn={conn}
                  cityList={cityList}
                  rgnList={rgnList}
                  paging={paging}
                  setMeainInfo={setMeainInfo}
                  codeList={codeList}
                  rtuEnteList={rtuEnteList}
                  inputValidMsg={inputValidMsg}
                  searchUserId = {searchUserId}
                  itemRender = {itemRender}
                ></MeainInfoCom>
                {isEnso === 15001 && (
                  <MeainInfoSola
                    selectMeain={paging.selectMeain}
                    setMeainInfo={setMeainInfo}
                    codeList={codeList}
                    meainModlList={meainModlList}
                    inputValidMsg={inputValidMsg}
                    itemRender = {itemRender}
                    meainSyncInfo = {meainSyncInfo}
                  />
                )}
                {isEnso === 29001 && (
                  <MeainInfoHeat
                    selectMeain={paging.selectMeain}
                    setMeainInfo={setMeainInfo}
                    codeList={codeList}
                    meainModlList={meainModlList}
                    inputValidMsg={inputValidMsg}
                    itemRender = {itemRender}
                  />
                )}
                {isEnso === 29002 && (
                  <MeainInfoHeatNa
                    selectMeain={paging.selectMeain}
                    setMeainInfo={setMeainInfo}
                    codeList={codeList}
                    meainModlList={meainModlList}
                    inputValidMsg={inputValidMsg}
                    itemRender = {itemRender}
                  />
                )}
                {isEnso === 15003 && (
                  <MeainInfoHeath
                    selectMeain={paging.selectMeain}
                    setMeainInfo={setMeainInfo}
                    codeList={codeList}
                    meainModlList={meainModlList}
                    inputValidMsg={inputValidMsg}
                    itemRender = {itemRender}
                  />
                )}
                {isEnso === 15004 && (
                  <MeainInfoVelo
                    selectMeain={paging.selectMeain}
                    setMeainInfo={setMeainInfo}
                    codeList={codeList}
                    meainModlList={meainModlList}
                    inputValidMsg={inputValidMsg}
                    itemRender = {itemRender}
                  />
                )}
                {isEnso === 15006 && (
                  <MeainInfoFuelcell
                    selectMeain={paging.selectMeain}
                    setMeainInfo={setMeainInfo}
                    codeList={codeList}
                    meainModlList={meainModlList}
                    inputValidMsg={inputValidMsg}
                    itemRender = {itemRender}
                  />
                )}
                { isEnso === 15007 && (
                  <MeainInfoEss
                    selectMeain={paging.selectMeain}
                    setMeainInfo={setMeainInfo}
                    codeList={codeList}
                    meainModlList={meainModlList}
                    inputValidMsg={inputValidMsg}
                    itemRender = {itemRender}
                  />
                )}
                </form>
              </div>

              {/* <Meain_info_com
                conn={conn}
                cityList={cityList}
                rgnList={rgnList}
                paging={paging}
                setMeainInfo={setMeainInfo}
                codeList={codeList}
                rtuEnteList={rtuEnteList}
                inputValidMsg={inputValidMsg}
                searchUserId = {searchUserId}
                itemRender = {itemRender}
              ></Meain_info_com>
              {isEnso === 15001 && (
                <Meain_info_sola
                  selectMeain={paging.selectMeain}
                  setMeainInfo={setMeainInfo}
                  codeList={codeList}
                  meainModlList={meainModlList}
                  inputValidMsg={inputValidMsg}
                  itemRender = {itemRender}
                />
              )}
              {isEnso === 29001 && (
                <Meain_info_heat
                  selectMeain={paging.selectMeain}
                  setMeainInfo={setMeainInfo}
                  codeList={codeList}
                  meainModlList={meainModlList}
                  inputValidMsg={inputValidMsg}
                  itemRender = {itemRender}
                />
              )}
              {isEnso === 29002 && (
                <Meain_info_heatNa
                  selectMeain={paging.selectMeain}
                  setMeainInfo={setMeainInfo}
                  codeList={codeList}
                  meainModlList={meainModlList}
                  inputValidMsg={inputValidMsg}
                  itemRender = {itemRender}
                />
              )}
              {isEnso === 15003 && (
                <Meain_info_heath
                  selectMeain={paging.selectMeain}
                  setMeainInfo={setMeainInfo}
                  codeList={codeList}
                  meainModlList={meainModlList}
                  inputValidMsg={inputValidMsg}
                  itemRender = {itemRender}
                />
              )}
              {isEnso === 15004 && (
                <Meain_info_velo
                  selectMeain={paging.selectMeain}
                  setMeainInfo={setMeainInfo}
                  codeList={codeList}
                  meainModlList={meainModlList}
                  inputValidMsg={inputValidMsg}
                  itemRender = {itemRender}
                />
              )}
              {isEnso === 15006 && (
                <Meain_info_fuelcell
                  selectMeain={paging.selectMeain}
                  setMeainInfo={setMeainInfo}
                  codeList={codeList}
                  meainModlList={meainModlList}
                  inputValidMsg={inputValidMsg}
                  itemRender = {itemRender}
                />
              )}
              { isEnso === 15007 && (
                <Meain_info_ess 
                  selectMeain={paging.selectMeain}
                  setMeainInfo={setMeainInfo}
                  codeList={codeList}
                  meainModlList={meainModlList}
                  inputValidMsg={inputValidMsg}
                  itemRender = {itemRender}
                />
              )} */}
          </div>
        {/* </form> */}
      </div>
    </div>
    { userInfoVisible &&
      <UserSearchList conn = {conn} setUserInfo = {selectUserId} setVisible = {setUserInfoVisible} />
    }
    { saveLoading && <p className={styles.saveLoading}><BeatLoader loading={true} size={24} color='#1e88e5' /></p> }
    </div>
  );
});

export default MeainTab;
