import React, { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners"; // 로딩스피너
import NumberFormat from "react-number-format";

import kendo from "@progress/kendo-ui";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Upload } from "@progress/kendo-react-upload";
import { Window } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";

import bizStyles from "./bizExpense.module.css";

import { getBizExpend, getBizExpense, getPayOrder } from "../../../../service/bizStat/com/expenseApiService";
import { getCalCityList, getCalRgnList, numFormat } from "../../../../com/dataUtil";
import { getBizExpenseFile } from "../../../../service/bizStat/com/bizFileSupportService";
import BizExpenseSearch from "./bizExpenseSearch";
import BizExpenseContent from "./bizExpenseContent";

const BizExpense = ({ conn, codeList, cityList, rgnList, styles, isMobile, search, setSearch }) => {
  // 시도 , 시군구 리스트 가공
  let citys = getCalCityList(cityList);
  let rgns = getCalRgnList(rgnList.filter(e => e.cityProvCode === search.cityProvCode && e.rgnCode !== "44131" && e.rgnCode !== "44133"));

  // 지출항목 데이터 가공
  const spendItemListData = codeList.filter(c => c.grpCodeNo === 48);

  // 에너지원 데이터 가공
  const energyTypeCodeListData = codeList.filter(c => c.grpCodeNo === 39);

  // 모달창 크기
  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;

  // 로딩스피너
  const [loading, setLoading] = useState(false);

  // 탭 번호
  const [selected, setSelected] = useState(0);

  // 데이터리스트
  const [dataList, setDataList] = useState({
    expenseGrid: {
      data: [
        {
          term: "사업비 총액",
          totalExpense: 0,
          gvmSpt: 0,
          mupal: 0,
          province: 0,
          city: 0,
          priCompany: 0,
        },
        {
          term: "지출액",
          totalExpense: 0,
          gvmSpt: 0,
          mupal: 0,
          province: 0,
          city: 0,
          priCompany: 0,
        },
      ],
    },
    expenseFile: [],
    expendGrid: {
      data: [],
      total: 0,
    },
    payOrderGrid: {
      data: [],
      total: 0,
    },
    bizExpenseInput: {
      bizYy: search.bizYyNo,
      rgnCode: search.rgnCode,
      cityProvCode: search.cityProvCode,
      totalExpense: 0,
      gvmSpt: 0,
      mupal: 0,
      province: 0,
      city: 0,
      priCompany: 0,
    },
    bizExpendInput: {
      bizYy: search.bizYyNo,
      rgnCode: search.rgnCode,
      cityProvCode: search.cityProvCode,
      orderNum: null,
      paymentName: "",
      paymentDate: new Date(),
      totalExpense: "",
      gvmSpt: 0,
      mupal: 0,
      province: 0,
      city: 0,
      spendItem: "",
      consEnte: "",
      energyTypeCode: "",
    },
    payOrderInput: {
      bizYy: search.bizYyNo,
      rgnCode: search.rgnCode,
      cityProvCode: search.cityProvCode,
      consEnte: "",
      energyTypeCode: "",
      amount: 0,
      etc: "",
      createUpdateYn: true,
    },
    docuCode: "corp",
    consEnteList: [],
  });

  // 사업비 추가 윈도우 창 제어
  const [bizExpenseWindowVisible, setBizExpenseWindowVisible] = useState({
    visible: false,
    left: isMobile === "Y" ? 0 : 350,
    top: 0,
    width: isMobile === "Y" ? innerWidth : 1700,
    height: isMobile === "Y" ? innerHeight : 700,
  });

  // 산출내역 엑셀파일 업로드 윈도우 창 제어
  const [bizFileWindowVisible, setBizFileWindowVisible] = useState(false);

  // 지급 차수별 현황 등록 윈도우 창 제어
  const [payOrderWindowVisible, setPayOrderWindowVisible] = useState({
    visible: false,
    left: isMobile === "Y" ? 0 : 350,
    top: 0,
    width: isMobile === "Y" ? innerWidth : 1700,
    height: isMobile === "Y" ? innerHeight : 700,
  });

  // 사업비 지출현황 등록 윈도우 창 제어
  const [bizExpendWindowVisible, setBizExpendWindowVisible] = useState({
    visible: false,
    left: isMobile === "Y" ? 0 : 350,
    top: 0,
    width: isMobile === "Y" ? innerWidth : 1700,
    height: isMobile === "Y" ? innerHeight : 700,
  });

  const handleSelect = e => {
    setSelected(e.selected);    

    if (e.selected === 0) {
      dataList.docuCode = "corp";
    } else {
      dataList.docuCode = "mupal";
    }

    getData();
  };

  // 데이터 불러오기 (검색 부분에서 찾을 시)
  const getData = () => {
    search.bizExpendPaging.take = 10;
    search.bizExpendPaging.skip = 0;

    search.payOrderPaging.take = 10;
    search.payOrderPaging.skip = 0;

    if (search.rgnCode !== "" && search.bizYyNo.toString !== "") {
      getDs();
    } else {
      kendo.alert("사업연도와 시군구를 선택해주세요");
    }
  };

  // 데이터 불러오기 ( 설치 계획서 목록)
  const getDs = () => {
    setLoading(true);

    let fileIds = {
      bizYy: search.bizYyNo,
      rgnCode: search.rgnCode,
      docuCodeNo: dataList.docuCode,
      bizSctCode: search.bizSctCode,
    };

    Promise.all([
      getBizExpense(conn, search),
      getBizExpenseFile(conn, fileIds, "bizExpense"),
      getPayOrder(conn, search),
      getBizExpend(conn, search),
    ])
      .then(results => {
        console.log("results = ", results);
        setLoading(false);
        setDataList(dataList => {
          const item = { ...dataList };
          item.expenseGrid.data = results[0];
          item.bizExpenseInput = results[0][0];
          item.expenseFile = results[1];
          item.payOrderGrid.data = results[2].results;
          item.payOrderGrid.total = results[2].total;
          item.expendGrid.data = results[3].results;
          item.expendGrid.total = results[3].total;
          return item;
        });
      })
      .catch(err => {
        console.log("initial data load Fail", err);
        setLoading(false);
      });
  };

  // 지급 차수별 현황 데이터 불러오기
  const loadConvPayOrder = () => {
    setLoading(true);
    getPayOrder(conn, search)
      .then(result => {
        setLoading(false);
        setDataList(dataList => {
          const item = { ...dataList };
          item.payOrderGrid.data = result.results;
          item.payOrderGrid.total = result.total;
          return item;
        });
      })
      .catch(err => {
        console.log("loadConvPayOrder load Fail", err);
        setLoading(false);
      });
  };

  // 사업비 지출현황 데이터 불러오기
  const loadbizExpend = () => {
    setLoading(true);
    getBizExpend(conn, search)
      .then(result => {
        setLoading(false);
        setDataList(dataList => {
          const item = { ...dataList };
          item.expendGrid.data = result.results;
          item.expendGrid.total = result.total;
          return item;
        });
      })
      .catch(err => {
        console.log("loadbizExpend load Fail", err);
        setLoading(false);
      });
  };

  const initalBizExpense = () => {
    setDataList(dataList => {
      const item = { ...dataList };
      item.bizExpenseInput.totalExpense = 0;
      item.bizExpenseInput.gvmSpt = 0;
      item.bizExpenseInput.mupal = 0;
      item.bizExpenseInput.province = 0;
      item.bizExpenseInput.city = 0;
      item.bizExpenseInput.priCompany = 0;
      item.bizExpendInput.orderNum = null;
      item.bizExpendInput.paymentName = "";
      item.bizExpendInput.gvmSpt = 0;
      item.bizExpendInput.mupal = 0;
      item.bizExpendInput.province = 0;
      item.bizExpendInput.city = 0;
      item.bizExpendInput.priCompany = 0;
      item.bizExpendInput.spendItem = "";
      // item.bizExpendInput.spendItemCode.codeNo = "";
      // item.bizExpendInput.spendItemCode.codeVal = "전체";
      item.bizExpendInput.consEnte = "";
      item.bizExpendInput.energyTypeCode = "";
      // item.bizExpendInput.energyType.codeNo = "";
      // item.bizExpendInput.energyType.codeVal = "전체";
      item.payOrderInput.consEnte = "";
      item.payOrderInput.energyTypeCode = "";
      item.payOrderInput.amount = 0;
      item.payOrderInput.etc = "";
      item.payOrderInput.createUpdateYn = true;
      return item;
    });
  };

  useEffect(() => {
    getData();
    /* eslint-disable-next-line */
  }, []);

  return (
    <dd>
      <div className={bizStyles.JinTabCon} id="JinTab_con">
        <BizExpenseSearch search={search} setSearch={setSearch} rgns={rgns} citys={citys} getData={getData} codeList={codeList} />
        <div className={loading ? [styles.convProject, styles.convProjectTable].join(" ") : styles.convProjectTable}>
          <div>
            <TabStrip className="JinTab" id="JinTab" selected={selected} onSelect={handleSelect}>
              <TabStripTab title="공단" key={"tabCorp"}>
                <BizExpenseContent
                  conn={conn}
                  styles={styles}
                  bizStyles={bizStyles}
                  search={search}
                  selected={selected}
                  dataList={dataList}
                  setDataList={setDataList}
                  getData={getData}
                  spendItemListData={spendItemListData}
                  energyTypeCodeListData={energyTypeCodeListData}
                  bizExpenseWindowVisible={bizExpenseWindowVisible}
                  setBizExpenseWindowVisible={setBizExpenseWindowVisible}
                  bizFileWindowVisible={bizFileWindowVisible}
                  setBizFileWindowVisible={setBizFileWindowVisible}
                  payOrderWindowVisible={payOrderWindowVisible}
                  setPayOrderWindowVisible={setPayOrderWindowVisible}
                  bizExpendWindowVisible={bizExpendWindowVisible}
                  setBizExpendWindowVisible={setBizExpendWindowVisible}
                  initalBizExpense={initalBizExpense}
                  loadConvPayOrder={loadConvPayOrder}
                  loadbizExpend={loadbizExpend}
                  Grid={Grid}
                  GridColumn={GridColumn}
                  IntlProvider={IntlProvider}
                  LocalizationProvider={LocalizationProvider}
                  kendo={kendo}
                  numFormat={numFormat}
                  Form={Form}
                  FormElement={FormElement}
                  Button={Button}
                  useState={useState}
                  useEffect={useEffect}
                  Upload={Upload}
                  Window={Window}
                  NumberFormat={NumberFormat}
                  DropDownList={DropDownList}
                  Input={Input}
                />
              </TabStripTab>
              <TabStripTab title="지자체" key={"tabLocalGov"}>
                <BizExpenseContent
                  conn={conn}
                  styles={styles}
                  bizStyles={bizStyles}
                  search={search}
                  selected={selected}
                  dataList={dataList}
                  setDataList={setDataList}
                  getData={getData}
                  spendItemListData={spendItemListData}
                  energyTypeCodeListData={energyTypeCodeListData}
                  bizExpenseWindowVisible={bizExpenseWindowVisible}
                  setBizExpenseWindowVisible={setBizExpenseWindowVisible}
                  bizFileWindowVisible={bizFileWindowVisible}
                  setBizFileWindowVisible={setBizFileWindowVisible}
                  payOrderWindowVisible={payOrderWindowVisible}
                  setPayOrderWindowVisible={setPayOrderWindowVisible}
                  bizExpendWindowVisible={bizExpendWindowVisible}
                  setBizExpendWindowVisible={setBizExpendWindowVisible}
                  initalBizExpense={initalBizExpense}
                  loadConvPayOrder={loadConvPayOrder}
                  loadbizExpend={loadbizExpend}
                  Grid={Grid}
                  GridColumn={GridColumn}
                  IntlProvider={IntlProvider}
                  LocalizationProvider={LocalizationProvider}
                  kendo={kendo}
                  numFormat={numFormat}
                  Form={Form}
                  FormElement={FormElement}
                  Button={Button}
                  useState={useState}
                  useEffect={useEffect}
                  Upload={Upload}
                  Window={Window}
                  NumberFormat={NumberFormat}
                  DropDownList={DropDownList}
                  Input={Input}
                />
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
        {loading && (
          <p className={styles.convProjectLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
      </div>
    </dd>
  );
};

export default BizExpense;
