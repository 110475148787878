// 설비 정보 지열
import React , {useState , useEffect} from "react";
import kendo from '@progress/kendo-ui';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import styles from './meain.module.css';
import DateTimePickerKo from '../../com/dateInputs/dateTimePickerKo';
import DatePickerKo from '../../com/dateInputs/datePickerKo';
import { Input } from "@progress/kendo-react-inputs";

// 태양열 설비 정보
function MeainInfoHeat({ selectMeain , setMeainInfo  , codeList , meainModlList , inputValidMsg , itemRender }){

  // console.log("selectMeain= ", selectMeain);

  const [socoMakr , setSocoMakr] = useState( selectMeain.socoMakrNo ? { makrSeriNo : selectMeain.socoMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '' } );
  const [socoType , setSocoType] = useState( selectMeain.socoTypeNo ? { meainTypeSeriNo : selectMeain.socoTypeNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [coldCtrlerMakr , setColdCtrlerMakr] = useState( selectMeain.coldCtrlerMakrNo ? { makrSeriNo : selectMeain.coldCtrlerMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '' } );
  const [meainTypeSeri , setMeainTypeSeri] = useState( selectMeain.meainTypeSeriNo ? { meainTypeSeriNo : selectMeain.meainTypeSeriNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [loadClrmtMakr , setLoadClrmtMakr] = useState( selectMeain.bldSrvCodeNo ? { makrSeriNo : selectMeain.loadClrmtMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '' } );
  const [loadClrmtModl , setLoadClrmtModl] = useState( selectMeain.modulTypeNo ? { meainTypeSeriNo : selectMeain.invtMakr , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [bldSrvCodeNo , setBldSrvCodeNo] = useState( selectMeain.bldSrvCodeNo ? { codeNo : selectMeain.bldSrvCodeNo , codeVal : "" } : { codeNo : '' , codeVal : '' } );
  const bldSrvDs = codeList.filter( c => c.grpCodeNo === 31 );

  // 집열기 제조사 목록
  const socoMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter(c => c.makrClsfNo === 30003) : [];
  // 집열기 모델 목록
  const socoTypeList = meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter(c=> c.ensoTypeCodeNo === 15002 && c.makrSeriNo === selectMeain.socoMakrNo) : [];
  // 컨트롤러 차온제어 제조사
  const coldCtrlerMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter(c => c.makrClsfNo === 30004) : [];
  // 컨트롤러 차온제어 모델 
  const meainTypeSeriList = meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter(c=> c.ensoTypeCodeNo === 15002 && c.makrSeriNo === selectMeain.coldCtrlerMakrNo) : []; 
  // 부하측 열량계 제조사
  const loadClrmtMakrList = meainModlList.length !== 0 ? meainModlList.makrDtoList.filter(c => c.makrClsfNo === 30013) : [];
  // 부하측 열량계 모델
  const loadClrmtModlNoList = meainModlList.length !== 0 ? meainModlList.meainModelDtoList.filter(c=> c.ensoTypeCodeNo === 15002 && c.makrSeriNo === selectMeain.loadClrmtMakrNo) : []; 

  // 드롭다운 값 변경 이벤트 (Single 데이터 버전)
  const onchageDropdown = (e) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const objName = e.target.name;
    const meainInfo = {
        ...selectMeain
        ,  [objName] : e.value
    };
    setMeainInfo(meainInfo);
  }

  // Input 데이터 값 변경 이벤트
  const onchageInput = (e) => {
    let meainInfo = {};
    const objName = e.target.name;
    // 태양열은 집열기 면적이 설비 용량이다.
    if( e.target.name === "socoArea" ){
      meainInfo = {
            ...selectMeain
            ,  [objName] : e.target.value
            ,  instCapa : e.target.value
        };
    }else{
        // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
        meainInfo = {
            ...selectMeain
            ,  [objName] : e.target.value
        };
    }
    setMeainInfo(meainInfo);
  }

  // Date 데이터 값 변경 이벤트
  const onchageDate = (v , name) => {
    // 값변경한 부분에 대해서 추적하여 객체 이름 기입하기 위해 name 값 가져오기
    const meainInfo = {
        ...selectMeain
        ,  [name] : kendo.toString(v , 'yyyy-MM-dd') 
    };
    setMeainInfo(meainInfo);
  }

  // DateTimeOnChangeDate
  const onChangeDateTiem = (v , name) =>{
    const meainInfo = {
      ...selectMeain
      ,  [name] : v
      // ,  [name] : kendo.toString(v , 'yyyy-MM-dd HH:mm:00')
    };
    setMeainInfo(meainInfo);
  }

  // 날짜 포맷 지정
  const dateFormat = (date) =>{
    if( typeof date !== "undefined" ){
        if( date !== "" ){ date = new Date(date); }
        else{ date = ""; } }
    else{ date = ""; }
    return date;
  }

  // 컨버터

  // 집열기 제조사
  // 집열기 모델
  // 컨트롤러 차온제어
  // 컨트롤러 차온제어 모델
  // 부하측 열량계 제조사
  // 부하측 열량계 모델

  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(selectMeain).length !== 0 ){
      let makrSeriNo = "";
      let makrTerm = "";
      if( selectMeain.socoMakrNo ){
        socoMakrList.forEach(element => {
          if( element.makrSeriNo === selectMeain.socoMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setSocoMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      if( selectMeain.coldCtrlerMakrNo ){
        coldCtrlerMakrList.forEach(element => {
          if( element.makrSeriNo === selectMeain.coldCtrlerMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setColdCtrlerMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
      if( selectMeain.loadClrmtMakrNo ){
        loadClrmtMakrList.forEach(element => {
          if( element.makrSeriNo === selectMeain.loadClrmtMakrNo ){
              makrSeriNo = element.makrSeriNo;
              makrTerm = element.makrTerm;
          }
        });
        setLoadClrmtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }
    }else{
      setSocoMakr({ makrSeriNo: '', makrTerm: '' });
      setColdCtrlerMakr({ makrSeriNo: '', makrTerm: '' });
      setLoadClrmtMakr({ makrSeriNo: '', makrTerm: '' });
    }
  }
  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(selectMeain).length !== 0 ){
      if( selectMeain.socoTypeNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        socoTypeList.forEach(element => {
          if( element.meainTypeSeriNo === selectMeain.socoTypeNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setSocoType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setSocoType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( selectMeain.meainTypeSeriNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        meainTypeSeriList.forEach(element => {
          if( element.meainTypeSeriNo === selectMeain.meainTypeSeriNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setMeainTypeSeri({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setMeainTypeSeri({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( selectMeain.loadClrmtModlNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        loadClrmtModlNoList.forEach(element => {
          if( element.meainTypeSeriNo === selectMeain.loadClrmtModlNo ){
            meainTypeSeriNo = element.meainTypeSeriNo;
            meainTypeTerm = element.meainTypeTerm;
          }
        });
        setLoadClrmtModl({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setLoadClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
    }else{
      setSocoType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setMeainTypeSeri({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setLoadClrmtModl({ meainTypeSeriNo: '', meainTypeTerm: '' });
    }
  }

  // Converter 건물용도별
  const  bldSrvConverter = () =>{
    if( Object.keys(selectMeain).length !== 0 ){
        let codeNo = "";
        let codeVal = "";
        bldSrvDs.forEach(element => {
            if( element.codeNo === selectMeain.bldSrvCodeNo ){
                codeNo = element.codeNo;
                codeVal = element.codeVal;
            }
        });
        setBldSrvCodeNo({ codeNo: codeNo, codeVal: codeVal });
    }else{
        setBldSrvCodeNo({ codeNo: '', codeVal: '-' });
    }
  }
 
  useEffect(() => {
    makrConverter();
    modlConverter();
    bldSrvConverter();
    // eslint-disable-next-line
  }, [selectMeain]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다. 

  return(
    <table className="Ch-TableN">
        <tr>
            <th>현재사용열원</th>
            <td>
                <Input 
                id = "presUseHeatSrc" name = "presUseHeatSrc"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.presUseHeatSrc ? selectMeain.presUseHeatSrc : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></Input>
            </td>
            <th>설치유형</th>
            <td>
                <DropDownList 
                id = "instType" name = "instType"  type = "text" className = "meainInfoDropDown" 
                data = { [ "지상형" , "경사지붕형" , "평슬라브형" , "곡면형" , "건물일체형(BIST)" , "옥외" , "옥상", "기타"] }
                value = { selectMeain.instType ? selectMeain.instType : "" } onChange = {onchageDropdown} 
                required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
                  />
            </td>
            <th>건물용도</th>
            <td>
                <DropDownList className = "bldSrvCodeNo" id = "bldSrvCodeNo" name = "bldSrvCodeNo" 
                    data = { bldSrvDs } required = {true} validationMessage = {inputValidMsg.normalText}
                    value = { bldSrvCodeNo } 
                    valid = {selectMeain.bldSrvCodeNo !== "" && selectMeain.bldSrvCodeNo !== null && typeof selectMeain.bldSrvCodeNo !== "undefined" }
                    dataItemKey="codeNo" textField = "codeVal" 
                    onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.codeNo }; setBldSrvCodeNo({codeNo : e.target.value.codeNo , codeVal : e.target.value.codeVal }); setMeainInfo(obj); } } 
                    itemRender = {itemRender}
                />
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>집열기 종류</th>
            <td>
                <DropDownList 
                id = "socoKind" name = "socoKind"  type = "text" className = "meainInfoDropDown" 
                data = { [ "단일진공관" , "이중진공관" , "평판형"] }
                value = { selectMeain.socoKind ? selectMeain.socoKind : "" } onChange = {onchageDropdown} 
                required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
                />
            </td>
            <th>집열기 면적(㎡)</th>
            <td>
                <Input 
                id = "socoArea" name = "socoArea"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.socoArea ? selectMeain.socoArea : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number2Fix} step="0.01" min = {0}
                ></Input>
            </td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>집열기 제조사</th>
            <td>
                <DropDownList 
                id = "socoMakrNo" name = "socoMakrNo"  type = "text" className="meainInfoDropDown"
                dataItemKey="makrSeriNo" textField = "makrTerm" value = { socoMakr } data = {socoMakrList}
                valid = {selectMeain.socoMakrNo !== "" && selectMeain.socoMakrNo !== null && typeof selectMeain.socoMakrNo !== "undefined" }
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.makrSeriNo }; 
                setSocoMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
                required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th>집열기 모델명</th>
            <td>
                <DropDownList 
                id = "socoTypeNo" name = "socoTypeNo"  type = "text" className="meainInfoDropDown"
                dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm"
                disabled = { selectMeain.socoMakrNo ? false : true } value = { socoType } data = {socoTypeList}
                valid = {selectMeain.socoTypeNo !== "" && selectMeain.socoTypeNo !== null && typeof selectMeain.socoTypeNo !== "undefined" }
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.meainTypeSeriNo }; 
                setSocoType({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
                required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>집열기 직렬개수</th>
            <td>
                <Input 
                id = "socoSeriCnt" name = "socoSeriCnt"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.socoSeriCnt ? selectMeain.socoSeriCnt : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.normalText} min = {0}
                ></Input>
            </td>
            <th>집열기 병렬개수</th>
            <td>
                <Input 
                id = "socoArowCnt" name = "socoArowCnt"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.socoArowCnt ? selectMeain.socoArowCnt : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.normalText} min = {0}
                ></Input>
            </td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>컨트롤러(차온제어) 제조사</th>
            <td>
                <DropDownList 
                id = "coldCtrlerMakrNo" name = "coldCtrlerMakrNo"  type = "text" className="meainInfoDropDown"
                value = { coldCtrlerMakr } data = {coldCtrlerMakrList} 
                valid = {selectMeain.coldCtrlerMakrNo !== "" && selectMeain.coldCtrlerMakrNo !== null && typeof selectMeain.coldCtrlerMakrNo !== "undefined" }
                dataItemKey="makrSeriNo" textField = "makrTerm"
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.makrSeriNo }; 
                setColdCtrlerMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
                required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th>컨트롤러(차온제어) 모델</th>
            <td>
                <DropDownList 
                id = "meainTypeSeriNo" name = "meainTypeSeriNo"  type = "text" className="meainInfoDropDown"
                value = { meainTypeSeri } data = {meainTypeSeriList} 
                valid = {selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined" }
                disabled = { selectMeain.coldCtrlerMakrNo ? false : true }
			          dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.meainTypeSeriNo }; 
                setMeainTypeSeri({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
                required = {true} validationMessage = {inputValidMsg.normalText} itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>부하측 열량계 제조사</th>
            <td>
                <DropDownList 
                id = "loadClrmtMakrNo" name = "loadClrmtMakrNo"  type = "text" className="meainInfoDropDown"
                value = { loadClrmtMakr } data = {loadClrmtMakrList} 
                dataItemKey="makrSeriNo" textField = "makrTerm" 
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.makrSeriNo }; 
                setLoadClrmtMakr({makrSeriNo : e.target.value.makrSeriNo , makrTerm : e.target.value.makrTerm}); setMeainInfo(obj); } } 
                itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th>부하측 열량계 모델</th>
            <td>
                <DropDownList 
                id = "loadClrmtModlNo" name = "loadClrmtModlNo"  type = "text" className="meainInfoDropDown"
                value = { loadClrmtModl } data = {loadClrmtModlNoList}
                disabled = { selectMeain.loadClrmtMakrNo ? false : true }
			          dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
                onChange = { (e) =>{ const obj = { ...selectMeain , [e.target.name] : e.value.meainTypeSeriNo }; 
                setLoadClrmtModl({meainTypeSeriNo : e.target.value.meainTypeSeriNo , meainTypeTerm : e.target.value.meainTypeTerm}); setMeainInfo(obj); } } 
                itemRender = {itemRender}
                ></DropDownList>
            </td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>방위각</th>
            <td>
                <Input 
                id = "azimuth" name = "azimuth"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.azimuth ? selectMeain.azimuth : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number2Fix} step="0.01" min = {0}
                ></Input>
            </td>
            <th>경사각</th>
            <td>
                <Input 
                id = "incliAngle" name = "incliAngle"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.incliAngle ? selectMeain.incliAngle : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number2Fix} step="0.01" min = {0}
                ></Input>
            </td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>열매체 펌프 유형</th>
            <td>
                <Input 
                id = "hmediPumpType" name = "hmediPumpType"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.hmediPumpType ? selectMeain.hmediPumpType : "" } onChange = {onchageInput} 
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></Input>
            </td>
            <th>열매체 펌프 유량(LPM)</th>
            <td>
                <Input 
                id = "hmediPumpOilQty" name = "hmediPumpOilQty"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.hmediPumpOilQty ? selectMeain.hmediPumpOilQty : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number2Fix} step="0.01"
                ></Input>
            </td>
            <th>열매체 펌프 양정(m)</th>
            <td>
                <Input 
                id = "hmediPumpHead" name = "hmediPumpHead"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.hmediPumpHead ? selectMeain.hmediPumpHead : "" } onChange = {onchageInput} 
                required = {true} validationMessage = {inputValidMsg.number2Fix} step="0.01"
                ></Input>
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>열매체 보충펌프 유형</th>
            <td>
                <Input 
                id = "hmediSubPumpType" name = "hmediSubPumpType"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.hmediSubPumpType ? selectMeain.hmediSubPumpType : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></Input>
            </td>
            <th>열매체 보충펌프 유량(LPM)</th>
            <td>
                <Input 
                id = "hmediSubPumpOilQty" name = "hmediSubPumpOilQty"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.hmediSubPumpOilQty ? selectMeain.hmediSubPumpOilQty : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number2Fix} step="0.01" min = {0}
                ></Input>
            </td>
            <th>열매체 보충펌프 양정(m)</th>
            <td>
                <Input 
                id = "hmediSubPumpHead" name = "hmediSubPumpHead"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.hmediSubPumpHead ? selectMeain.hmediSubPumpHead : "" } onChange = {onchageInput} 
                required = {true} validationMessage = {inputValidMsg.number2Fix} step="0.01" min = {0}
                ></Input>
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>온수 순환펌프 유형</th>
            <td>
                <Input 
                id = "hotwaterCirclPumpType" name = "hotwaterCirclPumpType"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.hotwaterCirclPumpType ? selectMeain.hotwaterCirclPumpType : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></Input>
            </td>
            <th>온수 순환펌프 유량(LPM)</th>
            <td>
                <Input 
                id = "hotwaterCirclPumpOilQty" name = "hotwaterCirclPumpOilQty"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.hotwaterCirclPumpOilQty ? selectMeain.hotwaterCirclPumpOilQty : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number2Fix}  step="0.01" min = {0}
                ></Input>
            </td>
            <th>온수 순환펌프 양정(m)</th>
            <td>
                <Input 
                id = "hotwaterCirclPumpHead" name = "hotwaterCirclPumpHead"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.hotwaterCirclPumpHead ? selectMeain.hotwaterCirclPumpHead : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number2Fix}  step="0.01" min = {0}
                ></Input>
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>유속(m/s)</th>
            <td>
                <Input 
                id = "woilSpeed" name = "woilSpeed"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.woilSpeed ? selectMeain.woilSpeed : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number2Fix}  step="0.01" min = {0}
                  ></Input>
            </td>
            <th>운전압력(bar)</th>
            <td>
                <Input 
                id = "drvePres" name = "drvePres"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.drvePres ? selectMeain.drvePres : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number0Fix} min = {0}
                ></Input>
            </td>
            <th>배관관경(A)</th>
            <td>
                <Input 
                id = "plaDiamtr" name = "plaDiamtr"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.plaDiamtr ? selectMeain.plaDiamtr : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number0Fix} min = {0}
                ></Input>
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>축열탱크용량(L)</th>
            <td>
                <Input 
                id = "rthrmTankCapa" name = "rthrmTankCapa"  type = "number" className={styles.searchTermTBC}
                value = { selectMeain.rthrmTankCapa ? selectMeain.rthrmTankCapa : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.number2Fix}  step="0.01" min = {0}
                ></Input>
            </td>
            <th>축열탱크재질</th>
            <td>
                <Input 
                id = "rthrmTankQual" name = "rthrmTankQual"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.rthrmTankQual ? selectMeain.rthrmTankQual : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></Input>
            </td>
            <th>시공업체</th>
            <td>
                <Input 
                id = "consEnte" name = "consEnte"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.consEnte ? selectMeain.consEnte : "" } onChange = {onchageInput}
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></Input>
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>시공업체 연락처</th>
            <td>
                <Input 
                  id = "consEnteCntct" name = "consEnteCntct"  type = "text" className={styles.searchTermTBC}
                  value = { selectMeain.consEnteCntct ? selectMeain.consEnteCntct : "" } onChange = {onchageInput}
                  placeholder = "하이픈(-) 없이 입력해주세요."
                  ></Input>
            </td>
            <th>시공업체 핸드폰 연락처</th>
            <td>
                <Input 
                id = "consPhoneCntct" name = "consPhoneCntct"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.consPhoneCntct ? selectMeain.consPhoneCntct : "" } onChange = {onchageInput}
                placeholder = "하이픈(-) 없이 입력해주세요."
                ></Input>
            </td>
            <th>시공일시</th>
            <td>
                <DateTimePickerKo 
                id = "meainInstDtm" name = "meainInstDtm" className = "meainDatePicker" 
                value = {dateFormat(selectMeain.meainInstDtm)} format = {"yyyy-MM-dd HH:mm"}
                formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                setDtm = {(v) => { onChangeDateTiem(v , "meainInstDtm"); }} 
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></DateTimePickerKo>
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>AS만료일</th>
            <td>
              <DatePickerKo 
              id = "asExpiDtm" name = "asExpiDtm" className = "meainDatePicker"   
              value = {dateFormat(selectMeain.asExpiDtm)} 
              format="yyyy-MM-dd"
              formatPlaceholder={{ year: '년', month: '월', day: '일' }}
              setDtm = {(v) => { onchageDate(v , "asExpiDtm"); }}
              required = {true} validationMessage = {inputValidMsg.normalText}
              ></DatePickerKo> 
            </td>
            <th>보증기간</th>
            <td>
                <Input 
                id = "wrntyPrid" name = "wrntyPrid"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.wrntyPrid ? selectMeain.wrntyPrid : "" } onChange = {onchageInput}
                ></Input>
            </td>
            <th>모니터링시작일시</th>
            <td>
                <DateTimePickerKo 
                id = "mntrStrtDtm" name = "mntrStrtDtm" className = "meainDatePicker" 
                value = {dateFormat(selectMeain.mntrStrtDtm)} format = {"yyyy-MM-dd HH:mm"}
                formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                setDtm = {(v) => { onChangeDateTiem(v , "mntrStrtDtm"); }}
                required = {true} validationMessage = {inputValidMsg.normalText}
                ></DateTimePickerKo>
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
          <th>설치확인일</th>
          <td>
              <DatePickerKo id = "instCnfmDtm"  name = "instCnfmDtm" className = "meainDatePicker"
              format="yyyy-MM-dd" value = {dateFormat(selectMeain.instCnfmDtm)} 
              formatPlaceholder={{ year: '년', month: '월', day: '일' }}
              setDtm = { (v) => { onchageDate(v , "instCnfmDtm"); } }
              required = {true} validationMessage = {inputValidMsg.normalText}
                  >
              </DatePickerKo> 
          </td>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
        <tr>
            <th>특이사항</th>
            <td colSpan = "4">
                <Input 
                id = "etc" name = "etc"  type = "text" className={styles.searchTermTBC}
                value = { selectMeain.etc ? selectMeain.etc : "" } onChange = {onchageInput}
                ></Input>
            </td>
        </tr>
        <tr><th colSpan="6"><div></div></th></tr>
    </table>
  );
}

export default MeainInfoHeat;