import React from "react";

const DaedeokMonitorMapTable = ({ infoBoxRef, styles, hoverDongData }) => {

  return (
    <div className={styles.infoBox} ref={infoBoxRef}>
        <table>

          <thead>
            <tr>
              <td colSpan="2">
                {/* <img src="/images/localareaImg/30230/icon/30230_circle.png"/> */}
                {hoverDongData.dongTerm}
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>설비개소</td>
              <td>
                <span>{hoverDongData.totalCnt}</span>
                <span>개</span>
              </td>
            </tr>

            <tr>
              <td>발전량</td>
              <td>
                <span>{hoverDongData.totalDayPower}</span>
                <span>kWh</span>
              </td>
            </tr>
          </tbody>

        </table>
    </div>
  );
};

export default DaedeokMonitorMapTable;
