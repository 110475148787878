import { yearList } from "../../../../com/dropDownDs";
import BizPlanAddMonitorConv from "./bizPlanAddMonitorConv";
import BizPlanAddMonitorEtc from "./bizPlanAddMonitorEtc";

const BizPlanAddMonitor = ({ codeList, inputs, setDataSource, plan, styles, moniInfo, DropDownList, Form, FormElement, kendo, Input }) => {
  const energyTypeCode = codeList.filter(c => c.grpCodeNo === 39 && c.codeSeriNo !== 0 && c.codeSeriNo !== 16);
  const relatBizCode = codeList.filter(c => c.grpCodeNo === 49);  

  /**
   * 기본정보의 시설구분 코드 값이 있을 경우 아래 필터 작업 진행
   */
  if (moniInfo) {
    if (moniInfo.energyTypeCode) {
      const energyTypeCodeData = energyTypeCode.filter(c => c.codeNo === parseInt(moniInfo.energyTypeCode))[0];
      moniInfo.energyTypeCodeData = energyTypeCodeData;
    }
    if (moniInfo.relatBizCode) {
      const relatBizCodeData = relatBizCode.filter(c => c.codeNo === parseInt(moniInfo.relatBizCode))[0];
      moniInfo.relatBizCodeData = relatBizCodeData;
    }
  }

  const onPlanChange = e => {
    //input에 name을 가진 요소의 value에 이벤트를 걸었다
    const { name, value } = e.target;

    var regExpCid = /[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z]/g;
    var regExpNum = /[0-9]/gi;

    if (name === "cid") {
      if (regExpCid.test(value)) {
        kendo.alert("숫자만 입력해주세요.");
        e.target.value = e.target.value.substring(0, e.target.value.length - 1);
      } else {
        // 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
        const nextInputs = dataSource => {
          //스프레드 문법으로 기존의 객체를 복사한다.
          const item = { ...dataSource };
          item.moniInfo[name] = value;
          return item;
        };

        //만든 변수를 seInput으로 변경해준다.
        setDataSource(nextInputs);
      }
    } else if (name === "unit") {
      if (regExpNum.test(value)) {
        kendo.alert("숫자빼고 입력해주세요.");
        e.target.value = e.target.value.substring(0, e.target.value.length - 1);
      } else {
        // 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
        const nextInputs = dataSource => {
          //스프레드 문법으로 기존의 객체를 복사한다.
          const item = { ...dataSource };
          item.moniInfo[name] = value;
          return item;
        };

        //만든 변수를 seInput으로 변경해준다.
        setDataSource(nextInputs);
      }
    } else {
      // 변수를 만들어 이벤트가 발생했을때의 value를 넣어줬다
      const nextInputs = dataSource => {
        //스프레드 문법으로 기존의 객체를 복사한다.
        const item = { ...dataSource };
        item.moniInfo[name] = value;
        return item;
      };

      //만든 변수를 seInput으로 변경해준다.
      setDataSource(nextInputs);
    }
  };

  // 드롭다운 변경 이벤트
  const onItemChange = e => {
    // console.log(" onItemChange E = ", e);
    if (e.target.name === "relatBizCode") {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.moniInfo.relatBiz = e.target.value;
        item.moniInfo.relatBizCode = e.target.value.codeNo;
        return item;
      });
    } else if (e.target.name === "relatBizYy") {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.moniInfo.relatBizYy = e.target.value;
        return item;
      });
    } else if (e.target.name === "energyTypeCode") {
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.moniInfo.energyType = e.target.value;
        item.moniInfo.energyTypeCode = e.target.value.codeNo;
        return item;
      });
    }
  };
  
  return (
    <>
      <div class="SubM_title">[ 모니터링고도화 기본정보 ]</div>
      <Form
        render={() => (
          <FormElement>
            <table className={styles.emcTable}>
              <colgroup>
                <col width="20%" />
                <col width="30%" />
                <col width="20%" />
                <col width="30%" />
              </colgroup>
              <tbody>
                <tr style={{ border: "solid 1px #dedede" }}>
                  <th>
                    <span class="red_cl">*</span>관련사업
                  </th>
                  <td>
                    <DropDownList
                      name="relatBizCode"
                      component={DropDownList}
                      data={relatBizCode}
                      textField="codeVal"
                      dataItemKey="codeNo"
                      defaultValue={
                        moniInfo.relatBizCode !== ""
                          ? {
                              codeNo: moniInfo.relatBizCode,
                              codeVal: moniInfo.relatBizCodeData
                                ? moniInfo.relatBizCodeData.codeVal
                                  ? moniInfo.relatBizCodeData.codeVal
                                  : "-"
                                : "-",
                            }
                          : { codeNo: "", codeVal: "선택" }
                      }
                      onChange={onItemChange}
                    />
                  </td>
                  <th>
                    <span class="red_cl">*</span>관련사업 년도
                  </th>
                  <td>
                    <DropDownList
                      id="relatBizYy"
                      name="relatBizYy"
                      data={yearList()}
                      value={moniInfo.relatBizYy}
                      onChange={onItemChange}
                      className={styles.searchArea}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </FormElement>
        )}
      />
      {moniInfo.relatBizCode === "49001" && (
        <BizPlanAddMonitorConv
          styles={styles}
          moniInfo={moniInfo}
          energyTypeCode={energyTypeCode}
          onItemChange={onItemChange}
          onPlanChange={onPlanChange}
          DropDownList={DropDownList}
          Form={Form}
          FormElement={FormElement}
          Input={Input}
        />
      )}
      {(moniInfo.relatBizCode === "49002" || moniInfo.relatBizCode === "49003") && (
        <BizPlanAddMonitorEtc
          styles={styles}
          moniInfo={moniInfo}
          energyTypeCode={energyTypeCode}
          onItemChange={onItemChange}
          onPlanChange={onPlanChange}
          DropDownList={DropDownList}
          Form={Form}
          FormElement={FormElement}
          Input={Input}
        />
      )}
    </>
  );
};

export default BizPlanAddMonitor;
