
// React
import React, { useState } from "react";

// Mui
import { SvgIcon } from "@material-ui/core";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// CSS
import styles from './customToolTip.module.css'

export const CustomToolTip = (props) => {

	const [show, setShow] = useState(false);
	const handleMouseEnter = () => { setShow(true) }
	const handleMouseLeave = () => { setShow(false) }

	let iconStyle = {
		color: props.color,
		width: props.width,
		height: props.height,
	}

	return (
		<div className={styles.toopTipContainer}>

			<SvgIcon
				component={InfoOutlinedIcon}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={iconStyle}
			/>

			<div className={`
				${show ? styles.tooltip_mouse_enter : styles.tooltip_mouse_leave}
				${styles.toolTip}
			`}>
				{props.content}
			</div>

		</div>
	)

}