// 그리드
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import React, {useEffect} from 'react';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import kendo from '@progress/kendo-ui';
import { IntlProvider, LocalizationProvider , loadMessages } from '@progress/kendo-react-intl';
import koMessage from "../../../../com/ko-language.json";
import { numFormat, roundNum } from '../../../../com/dataUtil';
loadMessages(koMessage, "ko-KR");

const UserBetweenUserMakerGrid = ({userMakrList, makrModelList, search ,  setMakrExcelData, setLoading }) => {

  console.log("UserBetweenUserMakerGrid search = ", search);
  console.log("UserBetweenUserMakerGrid userMakrList = ", userMakrList);

  // 발전량 , 생산량 조건부 렌더링
  const isCal = search.ensoTypeCode === 15002 || search.ensoTypeCode === 15003 ? true : false;

  // 엑셀 레퍼런스
  let _excelData;

  // 파일 이름
  const fileName = () =>{
    let template = "설치자별_";
    if( !search.makrSeriNo ){ template += "제조사_"; }
    else{ template += "모델_"; }
    template += "데이터_" + kendo.toString(new Date() , "yyyy-MM-dd") + ".xlsx";
    return template;
  }

  const completeExcel = () => {
    setLoading(false);
  }
  
  useEffect(() => {
    // 엑셀 다운로드 데이터 설정
    setMakrExcelData(_excelData);
    // eslint-disable-next-line
  }, []);

  // 숫자 데이터 형식 변환
  const cellNumberTemplate = (e) => {
    const item = e.dataItem;
    const field = e.field;
    return(
      <td>{numFormat(roundNum( item[field] , 2))}</td>
    );
  }

  return(
    <div className="Table-scr" style = {{height : "400px"}}>
      <ExcelExport
          data={userMakrList}
          ref={exporter => _excelData = exporter}
        fileName={fileName()}
        onExportComplete={completeExcel}
        >
          { !search.makrSeriNo &&
            <ExcelExportColumn title='제조사' field='makrTerm'/>
          }
          { search.makrSeriNo &&
            <ExcelExportColumn title='모델명' field='meainTypeTerm'/>
          }
          <ExcelExportColumn title= { isCal === false ? '발전량(kWh)' : '생산량(kWh)' } field='dayEnergy' cell = {cellNumberTemplate} />
          <ExcelExportColumn title='설비용량(kW)' field='instCapa' cell = {cellNumberTemplate} />
          <ExcelExportColumn title= { isCal === false ? '용량대비발전량(kWh/kW.day)' : '용량대비생산량(kWh/kW.day)' } field='instCapaPerEnergy' cell = {cellNumberTemplate} />
          <ExcelExportColumn title='운영개소' field='count' cell = {cellNumberTemplate}  />
        </ExcelExport>
        <LocalizationProvider language="ko-KR">
	        <IntlProvider locale="ko">
            <Grid style={{height:"400px"}} className = "userBetweenUserMakerGrid" 
              data =  {userMakrList}>
                <GridNoRecords>
                    {"조회 결과 데이터가 없습니다."}
                </GridNoRecords>
                <Column title='순위' field='no' width = "80px" />
                { !search.makrSeriNo &&
                  <Column title='제조사' field='makrTerm' width = "250px"/>
                }
                { search.makrSeriNo &&
                  <Column title='모델명' field='meainTypeTerm' width = "250px" />
                }
                <Column title= { isCal === false ? '발전량(kWh)' : '생산량(kWh)' } field='dayEnergy' cell = {cellNumberTemplate} />
                <Column title='설비용량(kW)' field='instCapa' cell = {cellNumberTemplate} />
                <Column title={ isCal === false ? <div>용량대비발전량<br></br>(kWh/kW.day)</div> : <div>용량대비생산량<br></br>(kWh/kW.day)</div> } field='instCapaPerEnergy' cell = {cellNumberTemplate} />
                <Column title='운영개소' field='count' width = "80px" cell = {cellNumberTemplate} />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
    </div>
  );
}

export default UserBetweenUserMakerGrid;