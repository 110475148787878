import React from "react";
import BizProgressEnergyImg from "../bizProgressStepImg";

const BizProgressEnergyImgVelo = ({
  stylesProgress,
  imgFile,
  previewFile,
  filesState,
  onAdd,
  onRemove,
  onProgress,
  onStatusChange,
  saveFileUrl,
  removeFileUrl,
  isMobile,
  imgClickStatus,
  setImgClickStatus,
  closeImgWindow,
}) => {
  return (
    <>
      <div className={stylesProgress.energyProgressTitle}>소형풍력</div>
      <div class="JinTabB_con" id="JinTabBB_con">
        <dl>
          <table className={stylesProgress.meainTable}>
            <colgroup>
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <tbody>
              <tr>
                <th>기초</th>
                <th>전력간선</th>
                <th>풍력발전기</th>
                <th>사용전 검사 및 시운전</th>
                <th>모니터링</th>
              </tr>
              <tr>
                <td>
                  <BizProgressEnergyImg
                    imgFile={imgFile}
                    stepFileArray={imgFile.step1}
                    previewFile={previewFile.step1}
                    filesState={filesState}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onProgress={onProgress}
                    onStatusChange={onStatusChange}
                    saveFileUrl={saveFileUrl}
                    removeFileUrl={removeFileUrl}
                    isMobile={isMobile}
                    name="step1"
                    imgClickStatus={imgClickStatus}
                    setImgClickStatus={setImgClickStatus}
                    closeImgWindow={closeImgWindow}
                  />
                </td>
                <td>
                  <BizProgressEnergyImg
                    imgFile={imgFile}
                    stepFileArray={imgFile.step2}
                    previewFile={previewFile.step2}
                    filesState={filesState}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onProgress={onProgress}
                    onStatusChange={onStatusChange}
                    saveFileUrl={saveFileUrl}
                    removeFileUrl={removeFileUrl}
                    isMobile={isMobile}
                    name="step2"
                    imgClickStatus={imgClickStatus}
                    setImgClickStatus={setImgClickStatus}
                    closeImgWindow={closeImgWindow}
                  />
                </td>
                <td>
                  <BizProgressEnergyImg
                    imgFile={imgFile}
                    stepFileArray={imgFile.step3}
                    previewFile={previewFile.step3}
                    filesState={filesState}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onProgress={onProgress}
                    onStatusChange={onStatusChange}
                    saveFileUrl={saveFileUrl}
                    removeFileUrl={removeFileUrl}
                    isMobile={isMobile}
                    name="step3"
                    imgClickStatus={imgClickStatus}
                    setImgClickStatus={setImgClickStatus}
                    closeImgWindow={closeImgWindow}
                  />
                </td>
                <td>
                  <BizProgressEnergyImg
                    imgFile={imgFile}
                    stepFileArray={imgFile.step4}
                    previewFile={previewFile.step4}
                    filesState={filesState}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onProgress={onProgress}
                    onStatusChange={onStatusChange}
                    saveFileUrl={saveFileUrl}
                    removeFileUrl={removeFileUrl}
                    isMobile={isMobile}
                    name="step4"
                    imgClickStatus={imgClickStatus}
                    setImgClickStatus={setImgClickStatus}
                    closeImgWindow={closeImgWindow}
                  />
                </td>
                <td>
                  <BizProgressEnergyImg
                    imgFile={imgFile}
                    stepFileArray={imgFile.moni}
                    previewFile={previewFile.moni}
                    filesState={filesState}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onProgress={onProgress}
                    onStatusChange={onStatusChange}
                    saveFileUrl={saveFileUrl}
                    removeFileUrl={removeFileUrl}
                    isMobile={isMobile}
                    name="moni"
                    imgClickStatus={imgClickStatus}
                    setImgClickStatus={setImgClickStatus}
                    closeImgWindow={closeImgWindow}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </dl>
      </div>
    </>
  );
};

export default BizProgressEnergyImgVelo;
