import { React, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { useLocation } from "react-router";

import "./menuNavigator.css";
import { menuNavComponents } from "../service/menu/menuNavigatorData";
import { getUser } from "../service/authService";

/**
 * 레프트 메뉴
 * 이용자의 권한별로 메뉴 표시 및 이동
 * 게스트는 이용자 정보 없음
 * @param {conn, codeList, user} param0
 * @returns
 */
const MenuNavigator = props => {
  const { roleMenu, getRoleMenu, setSelectMenu, setContentLayoutMargin } = props;

  let location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") getRoleMenu(location.pathname);
    // eslint-disable-next-line
  }, []);


  /**
   * 메뉴 네비게이터 클릭 시 동적으로 권한 있는 최하위 url로 접근
   * @param {}} menu click event
   * @returns 최하위 menu route
   */
  const conditionalMenuSelect = menu => {
    let url = "";
    if (menu.items) {
      url = conditionalMenuSelect(menu.items[0]);
    } else {
      return menu.data.route;
    }
    return url;
  };

  const setMenuSelectEvt = event => {
    if (event.item.data.authority) {
      const url = conditionalMenuSelect(event.item);
      setSelectMenu(url, roleMenu);
      setContentLayoutMargin(url);
      props.history.push(url);
    } else alert("권한이 없습니다.");
  };

  useEffect(() => {
    if (location.pathname !== "/") setContentLayoutMargin(location.pathname);
    // eslint-disable-next-line
  }, []);

  const getMenuNav = menu => {
    /* 기존 메뉴는 00000 으로 정리 */
    let MenuNav = menuNavComponents["00000"];
    /* 대덕은 독자적인 메뉴구조 */
    if(getUser().userTypeCode === 2002 && getUser().rgnCode === "30230"){ MenuNav = menuNavComponents["30230"]; }
    return(<MenuNav menu={menu}/>)
  }

  return (
    <>
      <aside id="sidebar_main">
        <div className="menu_section">
          <Menu vertical={true} style={{}} onSelect={setMenuSelectEvt}>
            {roleMenu.map(md1 =>
              !md1.checked ? (
                ""
              ) : (
                <MenuItem
                  text={getMenuNav(md1)}
                  data={{ route: md1.menuUrl, id: md1.id, authority: md1.checked }}
                  itemId={md1.id}
                  id={md1.id}
                  key={md1.id}
                  cssStyle={{ color: "#1e8825", boxShadow: "initial" }}>
                  {md1.items.map(
                    md2 =>
                      !md2.checked || (
                        <MenuItem
                          text={md2.term}
                          data={{ route: md2.menuUrl, id: md2.id, authority: md2.checked }}
                          itemId={md1.id}
                          id={md2.id}
                          key={md2.id}>
                          {md2.items.map(
                            md3 =>
                              !md3.checked || (
                                <MenuItem
                                  text={md3.term}
                                  data={{ route: md3.menuUrl, id: md3.id, authority: md3.checked }}
                                  itemId={md1.id}
                                  id={md3.id}
                                  key={md3.id}>
                                  {}
                                </MenuItem>
                              ),
                          )}
                        </MenuItem>
                      ),
                  )}
                </MenuItem>
              ),
            )}
          </Menu>
          {/* <div className="FooterText">
            Copyright 2022. SR ENERGY Inc. <br />
            all rights reserved.{" "}
          </div> */}
        </div>
        <div className="FooterText">
          Copyright 2022. SR ENERGY Inc. <br />
          all rights reserved.{" "}
        </div>
      </aside>
      <div id="page_content">
        <div id="page_content_inner">{props.children}</div>
      </div>
    </>
  );
};
export default withRouter(MenuNavigator);
