import React, { Fragment, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat } from "../../../com/dataUtil";

const TroubleShootingChangeChart = ({ changeChartData }) => {
    

    const chartOption = {
        chart: {
            zoomType: "xy",
            height: 309,
            spacing: [40, 15, 10, 30],
        },
        title: {
            text: null,
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        xAxis: [
            {
                type: "category",
                categories: changeChartData.xAxis,
                crosshair: true,
                title: {
                    text: '변동값',
                    style: { color: '#000000' },
                    x: -20,
                    y: 7,
                    offset: 0,
                    padding: 5,
                    align: 'low'
                },
            },
        ],
        yAxis: [
            {
                // 개소수 yAxis
                showEmpty: false,
                labels: {
                    style: {
                        color: '#33B8EC'
                    }
                },
                title: {
                    text: '개소수',
                    style: { color: '#33B8EC' },
                    rotation: 0,
                    y: -20,
                    offset: 0,
                    align: 'high'
                },
                opposite: true,
                min: 0,
            },
        ],
        tooltip: {
            shared: true,
            formatter: function () {
                let msg = "";
                msg +=
                    "<span>" +
                    "개소수(" +
                    numFormat(this.points[0].x) +
                    ")</span>" +
                    " : " +
                    "<b>" +
                    numFormat(this.points[0].y) +
                    "</b>" +
                    "<br>";

                return msg;
            },
        },
        series: [
            {
                color: {
                    linearGradient: {
                        x1: 0, // 시작 위치
                        x2: 0, // 끝 위치
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, "#008AB5"],
                        [1, "#70DBFF"]
                    ]
                },
                name: "개소수",
                type: "column",
                data: changeChartData.yData,
                yAxis: 0,
                tooltip: {
                    valueSuffix: " %",
                },
                zIndex: 10,
                showInLegend: false,
            },
        ],
    };

    return (
        <>
            <Fragment>
                <HighchartsReact highcharts={Highcharts} options={chartOption} />
            </Fragment>
        </>

    );
}

export default TroubleShootingChangeChart;
