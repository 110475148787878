import kendo from "@progress/kendo-ui";
/**
 * 사용자접속이력
 * 
 * @param 검색 , 정렬 , 필터 , 페이징 파라미터 
 * @return 사용자접속이력 리스트 
 */
export const getHmpgAccsLog = async(conn, callback , reject , param) => {
  await conn.get("/accslog/page", {
    params: {
      userId : param.userId
      , accsIp : param.accsIp
      , accsUrl : param.accsUrl
      , msg : param.msg
      , page : (param.skip / param.take) + 1
      , size : param.take
      , sort : param.sort
  }
}).then(response => {
  response.results.forEach(element => {
    element.regiDtm = kendo.toString( new Date(element.regiDtm) , "yyyy-MM-dd HH:mm:ss" );
  });
  callback(response);
}).catch(error => {
  reject(error)
});
};