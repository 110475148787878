// React
import React, { useState, useCallback } from 'react';

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from '@progress/kendo-react-buttons';
import { Error } from '@progress/kendo-react-labels';

// Css
import './installerModal.css';
import styles from './installerModal.module.css';

const InstallerModal = ({ modalForm, setModalForm, fixbylaw, rasys, handleModalSaveSubmit, handleModalUpdateSubmit }) => {
  const smsUseYn = ['Y', 'N'];
  const emailUseYn = ['Y', 'N'];

  // 수정 유무 설정
  modalForm.formItem.createUpdateYn = modalForm.createUpdateYn;

  const rasysCode = rasys.filter( e => e.codeNo === Number(modalForm.formItem.rasysCodeNo) && e.grpCodeNo === 14 );
  const fixbylawBund = fixbylaw.filter(e => e.fixbylawBundCode === modalForm.formItem.fixbylawBundCode);
  // console.log("modalForm = ", modalForm);
  // console.log("rasysCode = ", rasysCode);
  // console.log("fixbylawBund",fixbylawBund);
  rasysCode.length !== 0 ? modalForm.formItem.rasysCode = rasysCode[0] : modalForm.formItem.rasysCode = { codeNo : "" , codeVal : "요금제 검색" } ;
  fixbylawBund.length !== 0 ? modalForm.formItem.fixbylawBund = fixbylawBund[0] : modalForm.formItem.fixbylawBund = { fixbylawBundCode  : "" , fixbylawBundTerm : "주소검색" } ;

  // password Validation
  // const pwdRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/);
  // const pwdValidator = useCallback( value => (pwdRegex.test(value) ? '' : ' '));
  // eslint-disable-next-line
  const PwdInput = useCallback( fieldRenderProps => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {
          visited && validationMessage &&
            (<Error>{validationMessage}</Error>)
        }
      </div>
    )
  });


  // phon Validation
  // const phoneRegex = new RegExp(/^\d{3}-\d{3,4}-\d{4}$/);
  // const phoneValidator = useCallback( value => (phoneRegex.test(value) ? '' : ' '));
  // eslint-disable-next-line
  const PhoneInput = useCallback( fieldRenderProps => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {
          visited && validationMessage &&
            (<Error>{validationMessage}</Error>)
        }
      </div>
    );
  });


  // Email Validation
  // const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  // const emailValidator = useCallback( value => (emailRegex.test(value) ? '' : ' '));
  // eslint-disable-next-line
  const EmailInput = useCallback( fieldRenderProps => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {
          visited && validationMessage &&
            (<Error>{validationMessage}</Error>)
        }
      </div>
    );
  });

  const [data, setData] = useState(fixbylaw.slice());

  const filterData = (filter) => {
    const data = fixbylaw.slice();
    console.log("data = " , data);
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setData(filterData(event.filter));
  }


  // 닫기, 취소 이벤트
  const handleClose = useCallback( () => {
    setModalForm({...modalForm, visible: !modalForm.visible, title: '', formItem: []});
    // eslint-disable-next-line
  }, [modalForm]);


  // 저장 버튼
  // eslint-disable-next-line
  const handleSubmit = useCallback( e => { 
    e.isNew = modalForm.isNew;
    modalForm.title === '추가' ? handleModalSaveSubmit(e) : handleModalUpdateSubmit(e) 
  });


  return (
    <Dialog
      title={modalForm.title}
      onClose={handleClose}
    >
      <div className="installerModalForm">
        <div className="leftModalItem">
          <div><span className={styles.requriedItem}> * </span>ID</div>
          <div><span className={styles.requriedItem}> * </span>설치자</div>
          <div><span className={styles.requriedItem}> * </span>읍/면/동</div>
          <div>{  modalForm.isNew === true && <span className={styles.requriedItem}> * </span> }패스워드</div>
          <div>연락처</div>
          <div>이메일</div>
          <div><span className={styles.requriedItem}> * </span>요금제</div>
          <div>SMS 수신여부</div>
          <div>EMAIL 수신여부</div>
        </div>
        <div className="rightModalItem">
          <Form
            onSubmit={handleSubmit}
            initialValues={modalForm.formItem}
            render={ formRenderProps => (
              <FormElement>
                <div>
                  <Field name='userId' component={Input} placeholder = "아이디 입력" readOnly = {modalForm.formItem.userId ? true : false }  required/> 
                </div>
                <div>
                  <Field name='userTerm' component={Input} placeholder = "사용자 이름 입력" />
                </div>
                <div>
                  <Field
                    name='fixbylawBund'
                    component={DropDownList}
                    data={data} 
                    textField = "fixbylawBundTerm" dataItemKey= "fixbylawBundCode"
                    defaultValue={{ fixbylawBundCode  : "" , fixbylawBundTerm : "주소검색" }}
                    onFilterChange={filterChange}
                    filterable={true}
                  />
                </div>
                <div>
                  <Field 
                    name='loginPwd'
                    type='password'
                    component={PwdInput}
                    placeholder='8자 이상 영문,숫자,특수문자 입력'
                    // validator={pwdValidator}
                  />
                </div>
                <div>
                  <Field 
                    name='userCelphon'
                    type='tel'
                    component={PhoneInput}
                    placeholder='010-1234-5678'
                    // validator={phoneValidator}
                  />
                </div>
                <div>
                  <Field 
                    name='userEmail' 
                    type='email' 
                    component={EmailInput} 
                    placeholder='myname@example.com' 
                    // validator={emailValidator}
                  />
                </div>
                <div>
                  <Field
                    name='rasysCode'
                    component={DropDownList}
                    data={rasys}
                    textField = "codeVal" dataItemKey= "codeNo"
                    defaultValue={{ codeNo : "" , codeVal : "요금제 검색" }}
                  />
                </div>
                <div>
                  <Field
                    name='smsNotiUseYn'
                    component={DropDownList}
                    data={smsUseYn}
                    defaultValue={smsUseYn[0]}
                  />
                </div>
                <div>
                  <Field
                    name='emailNotiUseYn'
                    component={DropDownList}
                    data={emailUseYn}
                    defaultValue={emailUseYn[0]}
                  />
                </div>
                <div>
                  <Button onClick={handleClose}>취소</Button>
                  <Button type='submit' disabled={!formRenderProps.allowSubmit}>저장</Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default InstallerModal;