
import jwt_decode from 'jwt-decode';

const TOKEN_NAME = "SREMS";

export const authGuest = async (conn , status , user) => {
  const userInfo = user; 
  // console.log("userInfo = ", userInfo);
  const response = await conn.get('login' , {
    params: {
      status : status,
      userId : userInfo ? userInfo.userId : null
    }
  });
  return response;
};

export const login = async (conn, id, loginPwd) => {
    const response = await conn.post('login', {
                userId : id,
                password : loginPwd
      });
      return response;
};

// 새로운 계정의 패스워드 등록 시 사용 서비스
export const setNewPassword = async (conn, id, loginPwd) => {
  const response = await conn.post('/user/new/password', {
              userId : id,
              password : loginPwd
    });
    return response;
};


export const getUser = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  if(token){
    const decode = jwt_decode(token);
    // console.log("decode", decode);
    return {
      userId : decode.sub,
      userTerm : decode.userTerm,
      userTypeCode : decode.userTypeCode,
      userCelphone : decode.userCelphone,
      exp : decode.exp,
      iat : decode.iat,
      userMngRgnList : decode.userMngRgnList,
      userRtuEnteCode : decode.userRtuEnteCode,
      rgnCode : decode.rgnCode
    };
  }
    return null;
};

export const getToken = () => (localStorage.getItem(TOKEN_NAME) || null);
export const setToken = (token) => (localStorage.setItem(TOKEN_NAME, token));
export const setDelToken = () =>( localStorage.removeItem(TOKEN_NAME));

export const getUserId = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  if(token){
    const decode = jwt_decode(token);
    return decode.sub;
  }
  return null;
}

export const getUserTypeCode = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  if(token){
    const decode = jwt_decode(token);
    return decode.userTypeCode;
  }
  return null;
} 

export const getUserMngRgnList = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  if(token){
    const decode = jwt_decode(token);
    return decode.userMngRgnList;
  }
  return null;
} 

export const getUserRtuEnteCode = () => {
  const token = localStorage.getItem(TOKEN_NAME);
  if(token){
    const decode = jwt_decode(token);
    return decode.userRtuEnteCode;
  }
  return null;
} 

export const isAuthenticated = () =>{
  if(!localStorage.getItem(TOKEN_NAME))
    return false;
  const user = jwt_decode(localStorage.getItem(TOKEN_NAME));
  if(user && (user.userTypeCode === 2001 
    || user.userTypeCode === 2002
    || user.userTypeCode === 2003 
    || user.userTypeCode === 2004 
    || user.userTypeCode === 2005))
    return true;
  else 
    return false;
}

export const isLogin = () =>{
  if(!localStorage.getItem(TOKEN_NAME))
    return false;
  const user = jwt_decode(localStorage.getItem(TOKEN_NAME));
  // 로그인 시도 시 유저 타입코드를 확인 후 return true 함 (만약 유저 타입을 재정의 하거나 추가 및 수정 하면 아래 코드 꼭 수정 하기)
  if(user && (user.userTypeCode === 2001 
    || user.userTypeCode === 2002
    || user.userTypeCode === 2003 
    || user.userTypeCode === 2005
    || user.userTypeCode === 2007
    || user.userTypeCode === 2008
    ))
    return true;
  else 
    return false;
}

export const isAdmin = () =>{
  const token = localStorage.getItem(TOKEN_NAME);
  if(!token)
    return false;
  const user = jwt_decode(token);
  if(user.userTypeCode === 2003 || user.userTypeCode === 2005)
    return true;
  else 
    return false;
}

export const isManager = () =>{
  const token = localStorage.getItem(TOKEN_NAME);
  if(!token)
    return false;
  const user = jwt_decode(token);
  if(user.userTypeCode === 2002)
    return true;
  else 
    return false;
}

export const isRtuUser = () =>{
  const token = localStorage.getItem(TOKEN_NAME);
  if(!token)
    return false;
  const user = jwt_decode(token);
  if(user.userTypeCode === 2007)
    return true;
  else 
    return false;
}

export const isInstaller = () =>{
  const token = localStorage.getItem(TOKEN_NAME);
  if(!token)
    return false;
  const user = jwt_decode(token);
  if(user.userTypeCode === 2001)
    return true;
  else 
    return false;
}

// 페이지 진입 시 히스토리(사용자접속이력)에 저장하기 위한 서비스
export const setUserHistPage = async(conn , user , url) => {
    const response = await conn.post('/accslog/set/pageInfo', {
        userId : user ? user.userId : null,
        url : url
            });
      return response;
}