 import React from 'react';
import { numFormat } from '../../../com/dataUtil';
 
 const LocalAreaMonitorToTalStatInfo = ({styles , totalEnergyData}) => {
 
  return (
    <div id='totalStatBoxInfo' className={styles.totalStatBoxInfo} >
      <div className={styles.totalStatBoxInfoTitle}>
        <table>
          <tr>
            <td><img src='/images/localareaImg/42770/info/info.png' alt='정보' /></td>
            <td>상세 현황</td>
          </tr>
        </table>
      </div>
      <div className={styles.totalStatBoxInfoItemArea}>
        <div><img src='/images/localareaImg/42770/info/meain.png' alt='설비개수' /> 
          <span className={styles.totalStatBoxInfoMainTtl}>설비 갯수 : <span>{numFormat(totalEnergyData.totalMeainCnt)} 개 </span> </span></div>
        <table className={styles.totalStatBoxInfoItem}>
          <tr>
            <th><img src='/images/localareaImg/42770/info/link.png' alt='연동' /></th>
            <th>연동 : </th>
            <td className={styles.syncValue}>{totalEnergyData.syncMeainCnt}</td>
            <td> 개</td>
          </tr>
          <tr>
            <th><img src='/images/localareaImg/42770/info/unlink.png' alt='미연동' /></th>
            <th>미연동 : </th>
            <td className={styles.asyncValue}>{totalEnergyData.asyncMeainCnt}</td>
            <td> 개</td>
          </tr>
        </table>
      </div>
      <div className={styles.totalStatBoxInfoItemArea}>
        <div><img src='/images/localareaImg/42770/info/power.png' alt='설비개수' /> 
          <span className={styles.totalStatBoxInfoMainTtl}>전기 에너지 : <span>{numFormat(totalEnergyData.totalPower)} MWh </span></span> </div>
        <table className={styles.totalStatBoxInfoItem}>
          <tr>
            <th><img src='/images/localareaImg/42770/info/link.png' alt='연동' /></th>
            <th>연동 : </th>
            <td className={styles.syncValue}>{totalEnergyData.syncPower}</td>
            <td> MWh</td>
          </tr>
          <tr>
            <th><img src='/images/localareaImg/42770/info/unlink.png' alt='미연동' /></th>
            <th>미연동 : </th>
            <td className={styles.asyncValue}>{totalEnergyData.asyncPower}</td>
            <td> MWh</td>
          </tr>
        </table>
      </div>
      <div className={styles.totalStatBoxInfoItemArea}>
        <div><img src='/images/localareaImg/42770/info/heat.png' alt='설비개수' /> 
          <span className={styles.totalStatBoxInfoMainTtl}>열 에너지 : <span>{numFormat(totalEnergyData.totalUse)} MWh </span></span></div>
        <table className={styles.totalStatBoxInfoItem}>
          <tr>
            <th><img src='/images/localareaImg/42770/info/link.png' alt='연동' /></th>
            <th>연동 : </th>
            <td className={styles.syncValue}>{totalEnergyData.asyncUse}</td>
            <td> MWh</td>
          </tr>
          <tr>
            <th><img src='/images/localareaImg/42770/info/unlink.png' alt='미연동' /></th>
            <th>미연동 : </th>
            <td className={styles.asyncValue}>{totalEnergyData.syncUse}</td>
            <td> MWh</td>
          </tr>
        </table>
      </div>
    </div>
  );
 };
 
 export default LocalAreaMonitorToTalStatInfo;