// 지역 효율 공통

import AreaEficChart from "./area_efic_chart";
import AreaEficGrid from "./area_efic_grid";
import React, { useState, useEffect, useCallback } from "react";
import { getAreaDataList, getAreaDataListByRgnCode } from "../../../../service/stats/type/ensoDataService";
import { Button } from "@progress/kendo-react-buttons";

const AreaEfic = ({ conn, search, setLoading, LocalizationProvider, IntlProvider }) => {
  // 지역 데이터 소스
  const [dataSource, setDataSource] = useState({ ds: [], chart: [], ensoTypeNo: 15001, excelData: null });
  // 데이터 소스 가져오기
  const getResult = useCallback(
    (getData, chart, ensoTypeNo) => {
      setLoading(false);
      setDataSource(dataSource => {
        const item = { ...dataSource };
        item.ds = getData;
        item.chart = chart;
        item.ensoTypeNo = ensoTypeNo;
        return item;
      });
    },
    // eslint-disable-next-line
    [search],
  );

  // reject 함수
  const getRejection = error => {
    setLoading(false);
  };

  // 드릴다운 이벤트
  const clickDrillDown = rgnCode => {
    if (rgnCode !== null) {
      getAreaDataListByRgnCode(conn, getResult, getRejection, search, rgnCode);
    } else {
      getAreaDataList(conn, getResult, getRejection, search);
    }
  };

  // 엑셀 데이터 설정
  const setExcelData = excel => {
    setDataSource(dataSource => {
      const item = { ...dataSource };
      item.excelData = excel;
      return item;
    });
  };

  // 엑셀 저장
  const downloadExcel = () => {
    setLoading(true);
    dataSource.excelData.save();
  };

  const completeExcel = () => {
    setLoading(false);
  };
  useEffect(() => {
    getAreaDataList(conn, getResult, getRejection, search);
    // eslint-disable-next-line
  }, [getResult]);

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-2">
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">지역별 발전효율 (그래프 클릭시 상세정보 출력)</h3>
            </div>
            <AreaEficChart dataSource={dataSource.chart} ensoTypeNo={dataSource.ensoTypeNo} clickDrillDown={clickDrillDown}></AreaEficChart>
          </div>
        </div>
      </div>
      <div className="uk-width-medium-1-2">
        <div className="md-card">
          <div className="md-card-content">
            <div className="md-card-toolbar">
              <h3 className="md-card-toolbar-heading-text">
                <Button className="ensoCmpExcelDownBtn" onClick={downloadExcel} icon="excel">
                  엑셀저장
                </Button>
              </h3>
            </div>
            <AreaEficGrid
              dataSource={dataSource.ds}
              ensoTypeCodeNo={search.ensoTypeCodeNo}
              unit={search.unit}
              setExcelData={setExcelData}
              completeExcel={completeExcel}
              LocalizationProvider={LocalizationProvider}
              IntlProvider={IntlProvider}></AreaEficGrid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaEfic;
