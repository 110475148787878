// React
import React from "react";
import { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";

// Service
import {
  getTodayDongEnergy,
  getYesterdayDongEnergy,
  getSmpRecCerDataResource,
  getMeainCntDataResource,
  getEficPowerDataResource,
  getEnergySyncDataList,
  getEnergyDongDataList,
  getWeekPowerDataList,
  getMonthPower,
  getAsyncData,
} from "../../../service/monitor/daedeokMonitorService";

// Css
import "./daedeokMonitor.css";
import styles from './daedeokMonitor.module.css';

// Componenet
import { initMapItem, initMunicDongData } from "./daedeokMonitorData";
import { useHistory } from "react-router-dom";
import { _CO2_CAL_VAL, numFormat, reduceCo2, roundNum } from "../../../com/dataUtil";
import { CODE_HEAT } from "../../../com/codeUtil";
import { CODE_SOLA } from "../../../com/codeUtil";
import { CODE_HEATH } from "../../../com/codeUtil";
import CountUp from "react-countup";
import { DaedeokUnderBar } from "./daedeokUnderBar";
import DaedeokMonitorMap from "./daedeokMonitorMap";
import { DaedeokCarbonChart } from "./daedeokCarbonChart";

// KENDO 
import kendo from "@progress/kendo-ui";
import DaedeokMonitorPowerChart from "./daedeokMonitorPowerChart";

//  Mui
import { DaedeokGeneInfo } from "./daedeokGeneInfo";
import { CustomToolTip } from "./customToolTip";

const DaedeokMonitor = props => {
  const history = useHistory();
  const { conn, isMapView } = props;
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if(window.location.pathname === '/Main') {
    document.body.style.paddingTop = '70px'
  }

  // 로딩스피너
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    rgn: { rgnCode: "30230", rgnTerm: "대덕구" },
    geoDepth: 0 ,
    dongCode: '',
  });
  let callbackData = {};
  const [dataSource, setDataSource] = useState({
    // SMP, REC , CER , 수입금 데이터
    energyPriceData : {
      // 금일 SMP
      landSmp: 0 ,
      jejuSmp: 0 ,
      smp : 0 ,
       // 금일 REC
      rec: 0 ,
      recDate: '',
      // 금일 CER ( 탄소 배출권 )
      cer: 0 ,
      // SMP 금일 예상 수익금
      smpExpeTodayPrice : 0 ,
      // SMP 금일 실제 수익금
      smpRealTodayPrice : 0 ,
      // SMP 금일 전체 수익금
      smpTotalTodayPrice : 0 ,
      // REC 금일 예상 수익금 (REC 는 1MW 당 1개가 발급이 됨)
      recExpeTodayPrice : 0 ,
      // REC 금일 실제 수익금
      recRealTodayPrice : 0 ,
      // REC 금일 전체 수익금
      recTotalTodayPrice : 0 ,
      // CER 금일 예상 수익금 (KOC 탄소배출권은 1tCO2 당 1개가 발급이 됨)
      cerTradeItem : '',
      cerDate: '',
      cerExpeTodayPrice : 0 ,
      // CER 금일 실제 수익금
      cerRealTodayPrice : 0 ,
      // CER 금일 전체 수익금
      cerTotalTodayPrice : 0 ,
      // SMP + REC + CER 전체 합계
      totalTodayPrice : 0 ,
      // 연동 발전량
      syncDayPower : 0 ,
      // 미연동 발전량 
      asyncDayPower : 0 ,
      // 총 발전량
      totalDayPower : 0
    } ,
    // 태양광 계산 데이터 ( SMP , REC , CER 관련 계산 데이터 )
    solaCalDayPowerData: {
      // 금일 예상 발전량
      todayExpePowerAmount: 0 ,
      // 금일 전체 발전량 (금일 예상 발전량 + 금일 실제 발전량)
      todayTotalPowerAmount : 0 ,
    },
    // 왼쪽의 금일, 전일 데이터
    todayPowerData: { },
    // 효율 값
    effi: {
      preExpeEffi : 0 ,
      prePower : 0 ,
      todayExpeEffi : 0
    },
    // 오른쪽 위 통계 데이터
    ensoMeainCntList: [],
    // 오른쪽 사용량 상위 에너지원별 통계 
    ensoMeainData : {
      sola : { code : CODE_SOLA,   cnt : 0 , syncCount : 0 , asyncCount : 0 , instCapa : 0 , syncInstCapa : 0 , asyncInstCapa : 0 , power : 0 , syncPower : 0 , syncPrevDayPower: 0, asyncPower : 0 , asyncPrevDayPower : 0 , use : 0 , syncUse : 0 , syncPrevDayUse : 0 , asyncUse : 0 } ,
      heat : { code : CODE_HEAT,   cnt : 0 , syncCount : 0 , asyncCount : 0 , instCapa : 0 , syncInstCapa : 0 , asyncInstCapa : 0 , power : 0 , syncPower : 0 , syncPrevDayPower: 0, asyncPower : 0 , asyncPrevDayPower : 0 , use : 0 , syncUse : 0 , syncPrevDayUse : 0 , asyncUse : 0 } , 
      heath : { code : CODE_HEATH, cnt : 0 , syncCount : 0 , asyncCount : 0 , instCapa : 0 , syncInstCapa : 0 , asyncInstCapa : 0 , power : 0 , syncPower : 0 , syncPrevDayPower: 0, asyncPower : 0 , asyncPrevDayPower : 0 , use : 0 , syncUse : 0 , syncPrevDayUse : 0 , asyncUse : 0 }
    } ,
    // 동별 태양광 데이터
    realSolaDongData: [],
    // 에너지 종합 데이터 현황
    totalEnergyData : {
      totalMeainCnt : 0 ,
      totalInstCapa : 0 ,

      totalPower : 0 ,
      totalPowerDiff: 0,

      totalUse : 0 , 
      totalUseDiff: 0,
      
      syncMeainCnt : 0 ,
      syncPower : 0 ,
      syncUse : 0 , 

      asyncMeainCnt : 0,
      asyncPower : 0 ,
      asyncUse : 0, //  미연동은 열발전량 측정X
    } ,
    dongDataList : [] ,
    // 추이 그래프 차트 데이터
    dayPowerList : [],
    monthReduceCo2: [],
    rgnTotalMeainCnt : 0
  });
  const [dongData, setDongData] = useState(initMunicDongData);
  const [mapItem, setMapItem] = useState(initMapItem);

  // 시간별 계산
  const calHourData = resultObj => {
    // 00시 ~ 23시
    const today = new Date();
    let hour = today.getHours();
    let hourSumPower = 0;
    let hourAvgEfic = 0;

    for (let index = 0; index <= hour; index++) {
      let indexStr = index;
      if (index < 10) {
        indexStr = "0" + index;
      }
      let powerIndex = "p" + indexStr;
      let eifcIndex = "e" + indexStr;
      hourSumPower += resultObj[powerIndex];
      hourAvgEfic += resultObj[eifcIndex];
    }
    hourAvgEfic = hourAvgEfic / (hour + 1);

    return {
      fixbylawBundTermS: resultObj.fixbylawBundTermS,
      fixbylawBundCode: resultObj.fixbylawBundCode,
      userId: resultObj.userId,
      userTerm: resultObj.userTerm,
      hourSumPower: hourSumPower,
      hourAvgEfic: hourAvgEfic,
    };
  };

  // 오른쪽 하단 지역별 태양광 모니터링 발전순위 정보 데이터
  const getTodayDongEnergyResult = result => {
    const todayPowerData = [];
    const todayEficData = [];
    const yesterdayData = [];

    result.forEach(element => {
      todayPowerData.push(calHourData(element));
      todayEficData.push(calHourData(element));
    });

    const todayPowerRank = todayPowerData.sort(function (comp1, comp2) {
      const comp1UC = comp1.hourSumPower;
      const comp2UC = comp2.hourSumPower;
      if (comp1UC < comp2UC) {
        return 1;
      } else if (comp1UC > comp2UC) {
        return -1;
      }
      return 0;
    });

    const todayEficRank = todayEficData.sort(function (comp1, comp2) {
      const comp1UC = comp1.hourAvgEfic;
      const comp2UC = comp2.hourAvgEfic;
      if (comp1UC < comp2UC) {
        return 1;
      } else if (comp1UC > comp2UC) {
        return -1;
      }
      return 0;
    });

    let item = {};
    const len = todayPowerRank.length <= 3 ? todayPowerRank.length : 3;
    for (let i = 0; i < 3; i++) {
      item["dongPower" + (i + 1)] = i < len ? todayPowerRank[i].hourSumPower : 0;
      item["dongPowerTerm" + (i + 1)] = i < len ? todayPowerRank[i].fixbylawBundTermS : "-";
      item["dongEfic" + (i + 1)] = i < len ? todayEficRank[i].hourAvgEfic : 0;
      item["dongEficTerm" + (i + 1)] = i < len ? todayEficRank[i].fixbylawBundTermS : "-";
    }
    const param = {
      date: yesterday,
      rgnCode: search.rgn.rgnCode,
    };

    // 오른쪽 하단 지역별 태양광 모니터링 발전순위 정보 데이터 (전일)
    getYesterdayDongEnergy(conn, param)
      .then(result => {
        result.forEach(element => {
          yesterdayData.push(calHourData(element));
        });
        for (let i = 0; i < 3; i++) {
          let preData;
          preData = i < len ? yesterdayData.find(e => e.fixbylawBundCode === todayPowerRank[i].fixbylawBundCode) : null;
          item["dongPrePowerRank" + (i + 1)] = preData ? todayPowerRank[i].hourSumPower - preData.hourSumPower : 0;
          item["dongPreEficRank" + (i + 1)] = preData ? todayEficRank[i].hourAvgEfic - preData.hourAvgEfic : 0;
        }
        setDongData({ ...dongData, ...item });
      })
      .catch(e => console.log("Error", e));
  };


  const callBackFunc = result => {
    callbackData = { ...callbackData, ...result };
  };

  const getRejection = error => {
    console.log("error = ", error);
  };

  const getData = () => {
    setLoading(true);
    const param = {
      date: today,
      rgnCode: search.rgn.rgnCode,
      dongCode: search.dongCode
    };
    Promise.all([
      // 오른쪽 하단 지역별 태양광 모니터링 발전순위 정보 데이터
      getTodayDongEnergy(conn, param, getTodayDongEnergyResult, getRejection),
      // 오른쪽 하단 수용가별 태양광 모니터링 발전순위 정보 데이터
      // getTodayUserEnergy(conn, param, getTodayUserEnergyResult, getRejection),
      //1.smpreccer
      getSmpRecCerDataResource(conn, param, callBackFunc, getRejection),
      //2. 오늘,어제 power,효율 eficdata
      getEficPowerDataResource(conn, param, callBackFunc, getRejection),
      //3. 설비개수,발전량,용량 soladongdata
      getEnergyDongDataList(conn, param, callBackFunc, getRejection),
      //4. 에너지원별 통계 meaincnt (선택한 동 데이터, 미선택시 시군구)
      getMeainCntDataResource(conn, param, callBackFunc, getRejection),
      //5. 에너지원별 연동된 설비 데이터 ( 발전량, 갯수, 용량 , 사용량  : 태양광 ,태양열, 지열)
      getEnergySyncDataList(conn, param, callBackFunc, getRejection),
      //6. 발전량 추이 그래프
      getWeekPowerDataList(conn, param, callBackFunc, getRejection),
      //7. 두달치 발전량 그래프
      getMonthPower(conn, param, callBackFunc, getRejection),
      //8.  시군구 전역 데이터
      // getMeainCntDataResource(conn, {date: today, rgnCode: search.rgn.rgnCode}, calRgnTotalMeainCnt, getRejection),
      //9. 미연동 데이터
      getAsyncData(conn, param, callBackFunc, getRejection)
    ]).then(results => {
      setLoading(false);
      calDataSource(callbackData)
    });
  };

  const getDiff = (from, to) => {
    let diff = roundNum(to - from, 1);
    if(diff === 0){return '-'}
    else if(diff > 0){return (<span style={{color: '#FF6464'}}>▲{ numFormat( Math.abs(diff) ) }</span>)}
    else if(diff < 0){return (<span style={{color: '#18C4B7'}}>▼{ numFormat( Math.abs(diff) ) }</span>)}
  }

  // 계산식 첨부 함수
  const calDataSource = (callbackData) => {
    // console.log('callbackData', callbackData)
    setDataSource( data => {
      const item = {...data} ;
      
      // 미연동 태양광 설비 용량 (kWh) ( 차트에서 사용 )
      let asyncInstCapa = 0;
      // 사용량 상위 에너지원별 통계 데이터 제작
      callbackData.ensoMeainCntList.forEach(it => {
        if( it.ensoTypeCode === CODE_SOLA ) {
          item.ensoMeainData.sola.cnt = it.syncCount + it.asyncCount;
          item.ensoMeainData.sola.syncCount = roundNum(it.syncCount, 1 );
          item.ensoMeainData.sola.asyncCount = roundNum(it.asyncCount, 1 );

          item.ensoMeainData.sola.instCapa = roundNum(it.syncInstCapa + it.asyncInstCapa, 1 );
          item.ensoMeainData.sola.syncInstCapa = roundNum(it.syncInstCapa, 1 );
          item.ensoMeainData.sola.asyncInstCapa = roundNum(it.asyncInstCapa, 1 );
          asyncInstCapa = it.asyncInstCapa;
          // 발전량
          item.ensoMeainData.sola.power = roundNum(it.syncDayPower + it.asyncDayPower, 1);
          item.ensoMeainData.sola.syncPower = roundNum(it.syncDayPower, 1 );
          item.ensoMeainData.sola.asyncPower = roundNum(it.asyncDayPower, 1 ); 
          // 사용량 
          item.ensoMeainData.sola.use = 0 ; item.ensoMeainData.sola.syncUse = it.syncDayUse ; item.ensoMeainData.sola.asyncUse = 0 ; 
          // 전일 데이터
          item.ensoMeainData.sola.syncPrevDayPower = roundNum(it.syncPrevDayPower, 1);
          item.ensoMeainData.sola.asyncPrevDayPower = roundNum(it.asyncPrevDayPower, 1);
          item.ensoMeainData.sola.syncPrevDayUse = 0;
        }
        else if( it.ensoTypeCode === CODE_HEAT ) {
          item.ensoMeainData.heat.cnt = it.syncCount + it.asyncCount;
          item.ensoMeainData.heat.syncCount = roundNum( it.syncCount , 1 );
          item.ensoMeainData.heat.asyncCount = roundNum( it.asyncCount , 1 );

          item.ensoMeainData.heat.instCapa = roundNum(it.syncInstCapa + it.asyncInstCapa, 1 );
          item.ensoMeainData.heat.syncInstCapa = roundNum( it.syncInstCapa, 1 );
          item.ensoMeainData.heat.asyncInstCapa = roundNum( it.asyncInstCapa, 1 );
          // 발전량
          item.ensoMeainData.heat.power = 0 ; item.ensoMeainData.heat.syncPower = 0 ; item.ensoMeainData.heat.asyncPower = 0 ; 
          // 사용량 
          item.ensoMeainData.heat.use = roundNum((it.syncDayUse + 0), 1 );
          item.ensoMeainData.heat.syncUse = roundNum(it.syncDayUse, 1 );
          item.ensoMeainData.heat.asyncUse = 0; 
          // 전일 데이터
          item.ensoMeainData.heat.syncPrevDayPower = 0;
          item.ensoMeainData.heat.asyncPrevDayPower = 0;
          item.ensoMeainData.heat.syncPrevDayUse = roundNum(it.syncPrevDayUse / 1000, 1);
        }
        else if( it.ensoTypeCode === CODE_HEATH ) {
          item.ensoMeainData.heath.cnt = it.syncCount + it.asyncCount
          item.ensoMeainData.heath.syncCount = roundNum( it.syncCount , 1 );
          item.ensoMeainData.heath.asyncCount = roundNum( it.asyncCount , 1);

          item.ensoMeainData.heath.instCapa = roundNum(it.syncInstCapa + it.asyncInstCapa, 1 );
          item.ensoMeainData.heath.syncInstCapa = roundNum( it.syncInstCapa, 1 );
          item.ensoMeainData.heath.asyncInstCapa = roundNum( it.asyncInstCapa, 1);
          // 발전량
          item.ensoMeainData.heath.power = 0 ; item.ensoMeainData.heath.syncPower = 0 ; item.ensoMeainData.heath.asyncPower = 0 ; 
          // 사용량 
          item.ensoMeainData.heath.use = roundNum(it.syncDayUse + 0, 1);
          item.ensoMeainData.heath.syncUse = roundNum(it.syncDayUse, 1 );
          item.ensoMeainData.heath.asyncUse = 0; 
          // 전일 데이터
          item.ensoMeainData.heath.syncPrevDayPower = 0;
          item.ensoMeainData.heath.asyncPrevDayPower = 0;
          item.ensoMeainData.heath.syncPrevDayUse = roundNum(it.syncPrevDayUse, 1);
        }
      });

      // 총 설비 갯수
      let totalMeainCnt = 0 
      // 연동 개수
      , syncMeainCnt = 0 
      // 미연동 개수
      , asyncMeainCnt = 0;  

      // 총 전기 에너지
      let totalPower = 0; 
      let asyncPower = 0;
      let syncPower = 0;
      // 총 열 에너지.
      let totalUse = 0;;
      let syncUse = 0;
      let asyncUse = 0;

      // 미 연동 발전량
      asyncPower = callbackData.getAsyncData[0].DAY_POWER
      // 연동 발전량
      syncPower = item.ensoMeainData.sola.syncPower ;
      // 연동 사용량
      syncUse = item.ensoMeainData.heat.syncUse + item.ensoMeainData.heath.syncUse ;
      // 미연동 사용량
      asyncUse = callbackData.getAsyncData[1].DAY_POWER + callbackData.getAsyncData[2].DAY_POWER
      // 연동 총 개수
      syncMeainCnt = item.ensoMeainData.sola.syncCount + item.ensoMeainData.heat.syncCount + item.ensoMeainData.heath.syncCount ;
      // 미연동 총 개수
      asyncMeainCnt = callbackData.getAsyncData[0].CNT + callbackData.getAsyncData[1].CNT + callbackData.getAsyncData[2].CNT
      // 연동 미연동 총 갯수
      totalMeainCnt = syncMeainCnt + asyncMeainCnt;
      // 연동 + 미연동 사용량
      totalUse =  syncUse + asyncUse ;
      // 연동 + 미연동 발전량
      totalPower = syncPower + asyncPower ;


      // 전체 설비용량
      item.totalEnergyData.totalInstCapa =
        (item.ensoMeainData.sola.asyncInstCapa + item.ensoMeainData.sola.syncInstCapa)
        + (item.ensoMeainData.heat.asyncInstCapa + item.ensoMeainData.heat.syncInstCapa)
        + (item.ensoMeainData.heath.asyncInstCapa + item.ensoMeainData.heath.syncInstCapa)
        

      // 동 마다 데이터 가공해서 보여주기
      item.dongDataList = [];

      let dongSyncCnt = 0;
      let dongAsyncCnt = 0;

      let dongPower = 0;
      let dongSyncPower = 0;
      let dongAsyncPower = 0;

      let dongSyncPrevPower = 0;
      let dongAsyncPrevPower = 0;

      let dongSyncUse = 0;
      let dongAsyncUse = 0;

      let dongSyncPrevUse = 0;
      let dongAsyncPrevUse = 0;

      callbackData.energyDongData.forEach(dongData => {
        totalMeainCnt += dongData.asyncCount + dongData.syncCount;
      });

      callbackData.energyDongData.forEach(dongData => {
        const dongCode = dongData.fixbylawBundCode;
        const dongTerm = dongData.fixbylawBundTermS;
        const dongCnt = dongData.asyncCount + dongData.syncCount;

        // 태양광 ( 태양열 , 지열도 추가 해야함) /// 태양열 과 지열은 미연동은 값 0
        const totalDayPower = roundNum( ( dongData.asyncDayPower + dongData.syncDayPower ) , 1 );

        if(search.dongCode == "" || dongCode === search.dongCode){
          dongPower += totalDayPower;

          dongSyncCnt += dongData.syncCount;
          dongAsyncCnt += dongData.asyncCount;
          
          dongSyncPower += dongData.syncDayPower;
          dongAsyncPower += dongData.asyncDayPower;

          dongSyncPrevPower += dongData.syncPrevDayPower;
          dongAsyncPrevPower += dongData.asyncPrevDayPower;

          dongSyncUse += dongData.syncDayUse;
          dongAsyncUse += dongData.asyncDayUse;

          dongSyncPrevUse += dongData.syncPrevDayUse;
          dongAsyncPrevUse += dongData.asyncPrevDayUse;

          dongSyncUse += dongData.syncDayUse;
          dongAsyncUse += dongData.asyncDayUse;
        }


        // 갯수 비율 설정
        const rateByCnt = roundNum( ( dongCnt / totalMeainCnt ) * 100  , 2);
        item.dongDataList.push({dongCode:dongCode , dongTerm : dongTerm, totalCnt : numFormat( dongCnt ) , totalDayPower : numFormat( totalDayPower ) , rateByCnt : rateByCnt});
      });

      // 총 전기 에너지 , 열에너지 계산
      item.totalEnergyData.totalMeainCnt =  roundNum( dongSyncCnt+dongAsyncCnt , 1 );
      item.totalEnergyData.syncMeainCnt = numFormat( roundNum(  dongSyncCnt , 1 ) );
      item.totalEnergyData.asyncMeainCnt = numFormat( roundNum(  dongAsyncCnt , 1 ) );
      item.totalEnergyData.totalUse = roundNum( dongSyncUse+dongAsyncUse , 1 ) ;
      item.totalEnergyData.totalPower = roundNum(  dongPower , 1) ;
      item.totalEnergyData.asyncPower = numFormat( roundNum( dongAsyncPower , 1  )+'' );
      item.totalEnergyData.syncPower = numFormat( roundNum( dongSyncPower , 1  ) );
      item.totalEnergyData.syncUse = numFormat( roundNum( dongSyncUse , 1  ) );
      item.totalEnergyData.asyncUse = numFormat( roundNum( dongAsyncUse , 1  ) );

      item.totalEnergyData.totalPowerDiff = getDiff(dongSyncPrevPower+dongAsyncPrevPower, dongPower);
      item.totalEnergyData.totalUseDiff = getDiff(dongSyncPrevUse+dongAsyncPrevUse, dongSyncUse+dongAsyncUse);

      // 금일 SMP
      item.energyPriceData.landSmp = callbackData.landSmp;
      item.energyPriceData.jejuSmp = callbackData.jejuSmp;
      // SMP 값 ( 제주 인지 육지인지 체크)
      const cityProvCode = search.rgn.rgnCode.substr(0,2);
      const smp = cityProvCode === 50 ? callbackData.jejuSmp : callbackData.landSmp;
      item.energyPriceData.smp = smp;
      // 금일 REC
      const rec = callbackData.rec;
      item.energyPriceData.rec = rec;
      // 금일 CER ( 탄소 배출권 )
      const cer = callbackData.cer;
      item.energyPriceData.cer = cer;

      // 태양광 데이터 추출
      const solaDataList = callbackData.ensoMeainCntList.filter( v => v.ensoTypeCode === CODE_SOLA )[0]; 
      // 금일 예상 발전량
      const asyncDayPower = solaDataList.asyncDayPower;
      item.energyPriceData.asyncDayPower = asyncDayPower;
      // 연동 금일 발전량
      const syncDayPower = solaDataList.syncDayPower;
      item.energyPriceData.syncDayPower = syncDayPower;
      
      // 총 발전량
      const totalDayPower = item.totalEnergyData.totalPower;
      item.energyPriceData.totalDayPower = totalDayPower;
      

      // SMP 금일 전체 수익금
      const smpTotalTodayPrice = totalDayPower * smp;
      item.energyPriceData.smpTotalTodayPrice = smpTotalTodayPrice;


      // REC 금일 전체 수익금
      const recTotalTodayPrice = Math.floor(totalDayPower / 1000) * rec;
      item.energyPriceData.recTotalTodayPrice = recTotalTodayPrice;

      item.energyPriceData.recDate = callbackData.recDate;
      item.energyPriceData.cerTradeItem = callbackData.cerTradeItem;
      item.energyPriceData.cerDate = callbackData.cerDate;

      // CER 금일 전체 수익금
      const cerTotalTodayPrice = Math.floor((totalDayPower * _CO2_CAL_VAL) / 1000) * cer;
      item.energyPriceData.cerTotalTodayPrice = cerTotalTodayPrice;

      // SMP + REC + CER 전체 수익금 ( 예상 + 실제 )
      const totalTodayPrice = smpTotalTodayPrice + recTotalTodayPrice + cerTotalTodayPrice;
      item.energyPriceData.totalTodayPrice = totalTodayPrice;

      // 추이 그래프 제작하기  ( 발전량 )
      // 오늘
      const today = new Date();
      // 발전량 추이 배열 초기화
      item.dayPowerList = [];
      
      // 발전량 추이 그래프 데이터 설정
      if( callbackData.dayPowerList?.length > 0 ){
        const dayPowerList = callbackData.dayPowerList;
        // 예상 발전량 구하기
        const asyncInstCapaDataUseChart = asyncInstCapa;
        dayPowerList.forEach(element => {
          let asyncDayPower = element.asyncDayPower ;
          // 예상 발전량 값 저장
          element.asyncDayPower = roundNum( asyncDayPower , 2 );
          // 연동 발전량 
          element.syncDayPower = roundNum( element.syncDayPower , 2 );
          // 총 발전량
          const totalPower = roundNum( ( asyncDayPower + element.dayPower ) , 2 );
          element.y = totalPower;
          element.totalPower = totalPower;
          // x축 이름
          element.name = kendo.toString( new Date ( element.gathDtm ) , 'MM.dd' ) ;
          // 데이터를 DataSource 에 저장
          item.dayPowerList.push(element);
        });
      }else{
        // 발전량 추이 객체 초기화
        for (let index = 0; index < 7; index++) {
          let targetDate = new Date(today);
          item.dayPowerList.push( { 
            name : kendo.toString( new Date( targetDate.setDate(today.getDate() - 6 + index))  , 'yy.MM.dd' ) ,
            gathName : kendo.toString( new Date( targetDate.setDate(today.getDate() - 6 + index))  , 'yy.MM.dd' ) ,
            instCapa : 0 , dayPower : 0 , asyncDayPower : 0 , syncDayPower : 0 , totalPower : 0 , y : 0
          } );
        }
      }
      // 월 CO2 저감 차트 가공
      item.monthReduceCo2 = [];
      callbackData.getMonthPower.forEach(e => {
        item.monthReduceCo2.push(
          {
            gathDtm: e.gathDtm,
            reducedCo2: roundNum(reduceCo2(e.monthPower), 3)
          }
        )
      })

      return item;
    } )
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [search.rgn]); 

  const reducedCo2 = roundNum(reduceCo2(dataSource.totalEnergyData.totalPower), 1)
  
  return (

        <div
          style={loading ? {opacity: 0.6, pointerEvents: "none"} : null}
          className={["comBg" , styles.contentStyleMain].join(' ')}
        >

            <section
              className="content"
              style={{
                backgroundImage: 'url("/images/localareaImg/30230/mapBg10.jpg")',
                backgroundSize: 'cover'
              }}>

              <div className = { styles.localAreaLeftArea } style={{position: 'relative'}}>
                <img src="/images/localareaImg/30230/sun.png" style={{position: 'absolute', maxWidth: 'none', marginLeft: '30px'}}></img>

                <div className={styles.sub_title_container} style={{position: 'relative'}}>
                  <div>
                    <div className={styles.sub_title}>신재생에너지 현황</div>
                    {/* <img src="/images/localareaImg/30230/sun.png"></img> */}
                    <DaedeokUnderBar width={"100%"} />
                  </div>
                </div>

                <DaedeokGeneInfo
                  styles={styles}
                  today={kendo.toString(today, 'yy.MM.dd')}
                  dataSource={dataSource}
                />

              </div>

              <DaedeokMonitorMap
                conn={conn}
                search={search}
                setSearch={setSearch}
                dataSource={dataSource}
                mapItem={mapItem}
                setMapItem={setMapItem}
                history={history}
                styles = {styles}
                getData={getData}
                isMapView={isMapView}
              />

              <div className={styles.rightArea}>
                <div className={styles.rightBox}>
                  <div className={styles.rightBoxHeader}>
                    상세정보

                    <CustomToolTip
                      width={'25px'}
                      height={'25px'}
                      content={
                        <div className={styles.co2ToolTip}>
                          <div className={styles.co2ToolTipContent}>
                            <div><img src="/images/localareaImg/30230/icon/profitTable/CO2.png"/>탄소저감량</div>
                            <div>= 금일발전량 x CO₂저감계수</div>
                            <div>
                              <table>
                                <tbody>
                                <tr>
                                  <td>※</td>
                                  <td>CO₂저감계수: 0.45941</td>
                                </tr>
                                <tr>
                                  <td>※</td>
                                  <td>플라스틱컵 평균 0.052Kg</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>종이컵 평균 0.028Kg으로 계산</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      }
                    />

                    <DaedeokUnderBar width={"130px"} />
                  </div>
                  <div className={styles.rightBoxContent}>
                    <div className={styles.rowFlex}>
                      <div className={styles.colFlex}>
                        <div className={`${styles.co2Header} ${styles.rowFlex}`}>
                          <img src="/images/localareaImg/30230/earth.png" width={"75px"}/>
                          <div>
                            <span>탄소저감량</span>
                            <span>
                              <CountUp end={reducedCo2} separator="," duration={1.5} decimals={1} delay={2} redraw={true} />
                            </span>
                            <span>Kg</span>
                          </div>
                        </div>

                        <div className={styles.reducedItem}>
                          <table>
                            <tr>
                              <th>플라스틱컵</th>
                              <td>
                                <span>
                                <CountUp end={roundNum(reducedCo2 / 0.052, 0)} separator="," duration={1.5} decimals={0} delay={2} redraw={true} />
                                </span> 개 저감
                              </td>
                            </tr>

                            <tr>
                              <th>종이컵</th>
                              <td>
                                <span>
                                <CountUp end={roundNum(reducedCo2 / 0.028, 0)} separator="," duration={1.5} decimals={0} delay={2} redraw={true} />
                                </span> 개 저감
                              </td>
                            </tr>
                          </table>
                        </div>
                          

                      </div>

                      <div className={styles.colBorder}></div>

                      <div className={`${styles.textShadowNone} ${styles.reduceChart}`}>
                        <DaedeokCarbonChart chartData={dataSource.monthReduceCo2}/>
                      </div>

                    </div>
                    
                  </div>

                </div>

                <div className={styles.rightBox}>
                  <div className={styles.rightBoxHeader}>
                    발전수입


                    <CustomToolTip
                      width={'25px'}
                      height={'25px'}
                      content={
                        <div className={styles.profitTableToolTip}>
                          <div className={styles.profitTableToolTipContent}>

                            <div>
                              <img src="/images/localareaImg/30230/icon/profitTable/profit.png"/>
                              <div>
                                <span>수익금</span> = 단가 x 발전량
                              </div>
                            </div>

                            <div>
                              <img src="/images/localareaImg/30230/icon/profitTable/SMP.png"/>
                              <div>
                                <span>SMP단가</span>
                                <span> = 일별 SMP</span>
                                <span>_({kendo.toString(today, 'yyyy-MM-dd')} 기준)</span>
                              </div>
                            </div>

                            <div>
                              <img src="/images/localareaImg/30230/icon/profitTable/REC.png"/>
                              <div>
                                <span>REC단가</span>
                                <span> = 최근 REC 시장평균가</span>
                                {
                                  dataSource.energyPriceData.recDate
                                  ? <span>_({dataSource.energyPriceData.recDate} 기준)</span>
                                  : <></>
                                }
                              </div>
                            </div>

                            <div>
                              <img src="/images/localareaImg/30230/icon/profitTable/CO2.png"/>
                              <div>
                                <span>CER단가</span>
                                <span> = {dataSource.energyPriceData.cerTradeItem ? dataSource.energyPriceData.cerTradeItem : 'KOC'} 평균가</span>
                                {
                                  dataSource.energyPriceData.cerDate
                                  ? <span>_({dataSource.energyPriceData.cerDate} 기준)</span>
                                  : <></>
                                }
                              </div>
                            </div>

                          </div>
                        </div>
                      }
                    />

                    <DaedeokUnderBar width={"130px"} />
                  </div>

                  <div className={styles.rightBoxContent}>
                    <table className={styles.profitTable}>
                      <thead>
                          <tr>
                              <th>구분</th>
                              <th>단가</th>
                              <th>금일 예상 수익금</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <th>SMP
                              </th>
                              <td>
                                <span className={styles.profitValue}><CountUp end={dataSource.energyPriceData.smp} separator="," duration={1.5} decimals={2} delay={2} redraw={true} /></span>
                                <span>원</span>
                              </td>
                              <td>
                                <span className={styles.profitValue}><CountUp end={dataSource.energyPriceData.smpTotalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></span>
                                <span>원</span>
                              </td>
                          </tr>
                          <tr>
                              <th>REC / RE100</th>
                              <td>
                                <span className={styles.profitValue}><CountUp end={dataSource.energyPriceData.rec} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></span>
                                <span>원</span>
                              </td>
                              <td>
                                <span className={styles.profitValue}><CountUp end={dataSource.energyPriceData.recTotalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></span>
                                <span>원</span>
                              </td>
                          </tr>
                          <tr>
                              <th>탄소배출권</th>
                              <td>
                                <span className={styles.profitValue}><CountUp end={dataSource.energyPriceData.cer} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></span>
                                <span>원</span>
                              </td>
                              <td>
                                <span className={styles.profitValue}><CountUp end={dataSource.energyPriceData.cerTotalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></span>
                                <span>원</span>
                              </td>
                          </tr>
                        
                      </tbody>
                      <tfoot>
                          <tr>
                              <th>금일 예상 수익합계</th>
                              <td colSpan="2">
                                <span className={styles.profitValue}><CountUp end={dataSource.energyPriceData.totalTodayPrice} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></span><span>원</span>
                              </td>
                          </tr>
                      </tfoot>
                  </table>
                  </div>
                </div>

                <div className={styles.rightBox}>
                  <div className={styles.rightBoxHeader}>
                    발전량 추이
                    <DaedeokUnderBar width={"130px"} />
                  </div>
                  <div className={`${styles.rightBoxContent} ${styles.textShadowNone}`}>
                    <DaedeokMonitorPowerChart dayPowerList={dataSource.dayPowerList}/>
                  </div>
                </div>

              </div>
            </section>
          {loading && (
            <p
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </div>
  );
};

export default DaedeokMonitor;
