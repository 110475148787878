import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { cellTypeList } from "./jsonFile/moduleInit";
import SolarModuleCurveFitCht from "./solarModuleCurveFitCht";
import { ScatterPlot } from "@material-ui/icons";

const SolarModuleModal = props => {
  const { solModModal, setModalVisible, solModChngEvt, saveSolModFunc, setCurveFitting } = props;
  const inputChngEvt = e => {
    const elmntName = e.target.props.name;
    const value = e.value;
    solModChngEvt({ elmntName: elmntName, value: value });
  };

  return (
    <>
      <Dialog title={solModModal.title} onClose={setModalVisible} width="800px" height="670px">
        <div className="solModModalDiv">
          <div className="solModModalLeft">
            <div style={{ height: "50%" }}>
              <div className="solModModalLeftHd">전압</div>
              <div className="solModModalLeftCont">
                <SolarModuleCurveFitCht data={solModModal} title="전압" />
              </div>
            </div>
            <div style={{ height: "50%" }}>
              <div className="solModModalLeftHd">전류</div>
              <div className="solModModalLeftCont">
                <SolarModuleCurveFitCht data={solModModal} title="전류" />
              </div>
            </div>
          </div>
          <div className="solModModalRight">
            <table className="solModTable">
              <tbody>
                <tr>
                  <th>코드</th>
                  <td>
                    <span>{solModModal.modSeqNo ? solModModal.modSeqNo : "자동생성"}</span>
                  </td>
                </tr>
                <tr>
                  <th>모델명</th>
                  <td>
                    <Input name="modTerm" required={true} onChange={inputChngEvt} defaultValue={solModModal.modTerm}></Input>
                  </td>
                </tr>
                <tr>
                  <th>최대출력 Pmp(W)</th>
                  <td>
                    <Input
                      name="pwrMp"
                      required={true}
                      onChange={inputChngEvt}
                      defaultValue={solModModal.pwrMp}
                      type="number"
                      step="0.001"></Input>
                  </td>
                </tr>
                <tr>
                  <th>최대전압 Vmp(V)</th>
                  <td>
                    <Input
                      name="vltMp"
                      required={true}
                      onChange={inputChngEvt}
                      defaultValue={solModModal.vltMp}
                      type="number"
                      step="0.001"></Input>
                  </td>
                </tr>
                <tr>
                  <th>최대전류 Imp(A)</th>
                  <td>
                    <Input
                      name="curMp"
                      required={true}
                      onChange={inputChngEvt}
                      defaultValue={solModModal.curMp}
                      type="number"
                      step="0.001"></Input>
                  </td>
                </tr>
                <tr>
                  <th>개방전압 Voc(V)</th>
                  <td>
                    <Input
                      name="vltOc"
                      required={true}
                      onChange={inputChngEvt}
                      defaultValue={solModModal.vltOc}
                      type="number"
                      step="0.001"></Input>
                  </td>
                </tr>
                <tr>
                  <th>단락전류 Isc(A)</th>
                  <td>
                    <Input
                      name="curSc"
                      required={true}
                      onChange={inputChngEvt}
                      defaultValue={solModModal.curSc}
                      type="number"
                      step="0.001"></Input>
                  </td>
                </tr>
                <tr>
                  <th>
                    전압 온도계수 <br />
                    (%/°C)
                  </th>
                  <td>
                    <Input
                      name="tempVltCof"
                      style={{ height: "50px" }}
                      required={true}
                      onChange={inputChngEvt}
                      defaultValue={solModModal.tempVltCof}
                      type="number"
                      step="0.001"></Input>
                  </td>
                </tr>
                <tr>
                  <th>
                    전류 온도계수 <br />
                    (%/°C)
                  </th>
                  <td>
                    <Input
                      name="tempCurCof"
                      style={{ height: "50px" }}
                      required={true}
                      onChange={inputChngEvt}
                      defaultValue={solModModal.tempCurCof}
                      type="number"
                      step="0.001"></Input>
                  </td>
                </tr>
                <tr>
                  <th>셀 종류</th>
                  <td>
                    <DropDownList
                      name="cellType"
                      data={cellTypeList}
                      textField="text"
                      style={{ width: "100%", paddingLeft: "0px" }}
                      required={true}
                      onChange={inputChngEvt}
                      defaultValue={solModModal.cellType}></DropDownList>
                  </td>
                </tr>
                <tr>
                  <th>VOLT_FIT_A</th>
                  <td>
                    <span>{solModModal.vltFita !== null ? solModModal.vltFita : "커브피팅 후 자동생성"}</span>
                  </td>
                </tr>
                <tr>
                  <th>VOLT_FIT_B</th>
                  <td>
                    <span>{solModModal.vltFitb !== null ? solModModal.vltFitb : "커브피팅 후 자동생성"}</span>
                  </td>
                </tr>
                <tr>
                  <th>VOLT_FIT_C</th>
                  <td>
                    <span>{solModModal.vltFitc !== null ? solModModal.vltFitc : "커브피팅 후 자동생성"}</span>
                  </td>
                </tr>
                <tr>
                  <th>CURRENT_FIT_A</th>
                  <td>
                    <span>{solModModal.curFita !== null ? solModModal.curFita : "커브피팅 후 자동생성"}</span>
                  </td>
                </tr>
                <tr>
                  <th>CURRENT_FIT_B</th>
                  <td>
                    <span>{solModModal.curFitb !== null ? solModModal.curFitb : "커브피팅 후 자동생성"}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {solModModal.title === "피팅 보기" ? null : (
            <>
              <span className="solModModalBtnSpan">
                <Button onClick={setCurveFitting}>
                  <ScatterPlot />
                  커브피팅 시작
                </Button>
                <Button icon="save" onClick={saveSolModFunc}>
                  저장
                </Button>
                <Button icon="close" onClick={setModalVisible}>
                  취소
                </Button>
              </span>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default SolarModuleModal;
