import { numFormat } from '../../../../com/dataUtil';
import styles from './cmpUserByUser.module.css';
// import styles from './cmpUserByUser.dk.module.css';


// 지역별 비교
const CmpUserByUserArea = ({ Highcharts , HighchartsReact , areaCmpPowerList , areaXcategory }) => {
  const chartConfig = {
    chart: {
      type: 'column',
      zoomType: 'xy'
    },
    title: {
        text: null
    },
    credits : {
      enabled: false
  },
  tooltip: {
    shared: true
    , formatter: function () {
      let s = "";
      if( this.points.length > 0 ){
        s += '<span style = "color: #333333;font-weight: bold;">' + this.points[0].x + '</span>' 
        this.points.forEach( element => { 
          s += '<br>' 
          + '<span style="color:' + element.point.color + '">\u25CF</span><span style = "color: #333333;font-weight: bold;"> ' + element.series.name + " : " + numFormat( element.y ) + '</span>'
          + '</br>'  
        });
      }
      return s;
    }
  },
    plotOptions: {
        series: {
            cursor: 'pointer'
        }
    },
    xAxis: [{
        type: 'category',
        crosshair: true,
        categories : areaXcategory,
        labels: {
          style: {
            color: '#000000'
          }
        }
    }],
    yAxis: [{
      min: 0,
      labels: {
        style: {
          color: '#000000'
        }
      },
      title: {
        text: '용량대비생산량 (kWh/kW.day)',
        style: {
          color: '#000000'
        }
      }
    }],
    legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        // backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#656565'
    },
    series: areaCmpPowerList
  }

  return(
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="md-card TBgA">
          <div className="md-card-content small-padding">
            <div className= {styles.graphBox}>
              <HighchartsReact Highcharts = {Highcharts} options = {chartConfig}></HighchartsReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CmpUserByUserArea;