import React from "react";

const BizProgressNoEnergyImg = ({ stylesProgress, isMobile }) => {
  return (
    <>
      {isMobile ? (
        <div class="uk-width-medium-2-5">
          <div className={stylesProgress.convMngtNoDataArea}>
            <img src="/images/conv/convProgressNoData.png" alt="clickNavigator" style={{ margin: "120px 0px 50px 0px" }} />
          </div>
        </div>
      ) : (
        <div className={stylesProgress.convMngtNoDataArea}>
          <img src="/images/conv/convProgressNoData.png" alt="clickNavigator" style={{ margin: "120px 0px 50px 0px" }} />
        </div>
      )}
    </>
  );
};

export default BizProgressNoEnergyImg;
