import "./index.css";
import { useEffect, useState } from "react";
import { useCreateAxiosInstance } from "conInstance";
import WeatherIcon from "./weatherIcon";

const TodayGongju = () => {
  const { conn } = useCreateAxiosInstance();
  const [data, setData] = useState(null);
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await conn.get("/monitor/installer/munic/weather", {
          params: {
            rgnCode: 44150,
          },
        });
        setData(res);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = date => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="gongju-content-wrapper">
      <span className="gongju-dashboard-title">오늘의 공주</span>
      <div className="gongju-left-top-wrapper">
        <div className="today-gongju-content-wrapper">
          <span className="today-gongju-text-1">{formatDate(dateTime)}</span>
          <span className="today-gongju-text-1">{formatTime(dateTime)}</span>
        </div>
        <div className="today-gongju-content-wrapper">
          <div className="gongju-flex-container">
            <span className="today-gongju-text-2">{data.presWeathVal}</span>
            <div>
              <span className="today-gongju-text-4">{data.temp}</span>
              <span className="today-gongju-text-5"> ℃</span>
            </div>
          </div>
          <WeatherIcon weather={data.presWeathVal} />
        </div>
        <span className="today-gongju-text-3">습도: {data.humi} %</span>
        <span className="today-gongju-text-3">강수량: {data.precQty} mm</span>
        <span className="today-gongju-text-3">일사량: {parseFloat(data.solaQty).toFixed(1)} W/m²</span>
      </div>
    </div>
  );
};

export default TodayGongju;
