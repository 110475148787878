// 융복합지원 - 취소관리 - 설치계획서 목록 데이터 가져오기
export const getCancel = async (conn, callback, reject, setLoading, search) => {
  // 검색 객체 생성
  const { take, skip } = search.cancelPaging;
  await conn
    .get("biz/cancel/page", {
      params: {
        bizYy: search.bizYyNo,
        rgnCode: search.rgnCode,
        instlTerm: search.instlTerm,
        energyTypeCode: search.energyTypeCode,
        row: skip,
        size: take,
        sort: search.sort,
        bizSctCode: search.bizSctCode,
      },
    })
    .then(response => {
      setLoading(false);
      callback(response);
      console.log("response", response);
    })
    .catch(error => {
      setLoading(false);
      reject(error);
    });
};

// 취소관리 - 재신청 선택시 이벤트
export const cancelReapp = async (conn, data, search) => {
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  // 사업 코드 부여
  data.bizSctCode = search.bizSctCode;

  const result = await conn.post("biz/cancel/reapp", JSON.stringify(data), config);

  return result;
};

export const deleteBiz = async (conn, data, search) => {
  // 사업 코드 부여
  data.bizSctCode = search.bizSctCode;

  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(data),
  };

  const result = await conn.delete("biz/cancel/delete", config);

  return result;
};
