import kendo from "@progress/kendo-ui";
import { getMessage } from "../../../com/messageUtil";

/**
 * 사업별로 내가 요청한 URL을 통합 가공 로직
 * @param {사업코드} bizSct
 * @param {추가 내용 URL} addURL
 * @returns
 */
// const generateUrl = (bizSctCode, addURL) => {
//   // URL 설정
//   let url = "";
//   if (bizSctCode === CODE_BIZ_CONV) {
//     url += "conv";
//   } else if (bizSctCode === CODE_BIZ_HOUSE) {
//     url += "house";
//   } else if (bizSctCode === CODE_BIZ_BUILDING) {
//     url += "building";
//   } else if (bizSctCode === CODE_BIZ_AREA) {
//     url += "area";
//   } else if (bizSctCode === CODE_BIZ_POWERSTATION) {
//     url += "powerStation";
//   } else if (bizSctCode === CODE_BIZ_ETC) {
//     url += "etc";
//   }
//
//   return url + addURL;
// };

/**
 * 진척률 - 설치계획서 목록 데이터 가져오기
 * @param {*} conn
 * @param {*} callback
 * @param {*} reject
 * @param {*} setLoading
 * @param {*} search
 */
export const getProgress = async (conn, callback, reject, setLoading, search) => {
  // 검색 객체 생성
  const { take, skip } = search.progress.paging;

  await conn
    .get("biz/progress/page", {
      params: {
        bizYy: search.bizYyNo,
        rgnCode: search.rgnCode,
        instlTerm: search.instlTerm,
        energyTypeCode: search.energyTypeCode,
        row: skip,
        size: take,
        bizSctCode: search.bizSctCode,
      },
    })
    .then(response => {
      setLoading(false);
      callback(response);
    })
    .catch(error => {
      setLoading(false);
      reject(error);
    });
};

/**
 * 진척률 - 사업추진 진행상황 - 주별 진행률
 * @param {*} conn
 * @param {*} data
 * @param {*} callback
 * @param {*} reject
 */
export const progressWeek = async (conn, data, callback, reject) => {
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    await conn
      .get(
        "biz/progress/week",
        {
          params: {
            startGathDtm: data.monthDate.startGathDtm,
            endGathDtm: data.monthDate.endGathDtm,
            rgnCode: data.rgn.rgnCode,
            bizYy: data.bizYyNo,
            bizSctCode: data.bizSctCode,
          },
        },
        config,
      )
      .then(response => {
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};

/**
 * 진척률 - 사업추진 진행상황 - 월별 진행률
 * @param {*} conn
 * @param {*} data
 * @param {*} callback
 * @param {*} reject
 */
export const progressMonth = async (conn, data, callback, reject) => {
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    await conn
      .get(
        "biz/progress/month",
        {
          params: {
            startGathDtm: data.date.startGathDtm,
            endGathDtm: data.date.endGathDtm,
            rgnCode: data.rgnCode,
            bizYy: data.bizYyNo,
            bizSctCode: data.bizSctCode,
          },
        },
        config,
      )
      .then(response => {
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};

/**
 * 진척률 - 사업추진 진행상황 - 추진현황 전체대비 진행률
 * @param {*} conn
 * @param {*} data
 * @param {*} callback
 * @param {*} reject
 */
export const progressRate = async (conn, data, callback, reject) => {
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    await conn
      .get(
        "biz/progress/rate",
        {
          params: {
            bizYy: data.bizYyNo,
            rgnCode: data.rgnCode,
            startGathDtm: data.date.startGathDtm,
            endGathDtm: data.date.endGathDtm,
            bizSctCode: data.bizSctCode,
          },
        },
        config,
      )
      .then(response => {
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};

/**
 * 진척률 - 협약개소 저장
 * @param {*} conn
 * @param {*} data
 * @param {*} search
 * @returns
 */
export const setAgressment = async (conn, data, search) => {
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  // 사업 코드 부여
  data.bizSctCode = search.bizSctCode;

  const result = await conn.post("biz/progress/agressment", data, config);

  return result;
};

/**
 * 진척률 - 설치계획서 목록 - 설치여부 저장
 * @param {*} conn
 * @param {*} data
 * @param {*} search
 * @param {*} callback
 * @param {*} reject
 */
export const progressStat = async (conn, data, search, callback, reject) => {
  try {
    // 헤더 옵션 변경
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    // 사업 코드 부여
    data.bizSctCode = search.bizSctCode;

    await conn
      .post("biz/progress/stat", JSON.stringify(data), config)
      .then(response => {
        callback(response);
      })
      .catch(error => {
        reject(error);
      });
  } catch (e) {
    kendo.alert(getMessage(e.errCode));
  }
};

/**
 * 진척률 - 포기 선택 시 이벤트
 * @param {*} conn
 * @param {*} data
 * @param {*} search
 * @returns
 */
export const progressCancel = async (conn, data, search) => {
  console.log("data", data);
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  // 사업 코드 부여
  data.bizSctCode = search.bizSctCode;

  const result = await conn.post("biz/progress/cancel", JSON.stringify(data), config);

  return result;
};

/**
 * 썸네일 사진 불러오는 로직
 * @param {axios 통합 객체} conn
 * @param {검색 조건} search
 * @param {call back 함수} callback
 * @param {rejection 함수} reject
 */
export const getProgressImgListData = async (conn, search, callback, reject) => {
  // console.log("getProgressImgListData 조회");
  const url = "biz/progress/step/img";
  // 헤더 옵션 변경
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  await conn
    .get(
      url,
      {
        params: {
          instlPlnnm: search.instlPlnnm,
          energyTypeCode: search.progress.energyTypeCode,
          bizSctCode: search.bizSctCode,
        },
      },
      config,
    )
    .then(response => {
      callback(response);
    })
    .catch(error => {
      reject(error);
    });
};
