//React
import React from "react";

//Kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import DateTimePickerKo from "./../../com/dateInputs/dateTimePickerKo";

//css
import "./solarPred.css";
import SolarPredChart from "./solarPredChart";
import { QueryStats } from "@material-ui/icons";
const SolarPredPrzt = props => {
  const {
    gridData,
    gridState,
    cidList,
    cidListChngEvt,
    dtmOptList,
    dtmOptListChngEvt,
    prdCht,
    // setPrdCht,
    prdChtUnVsblEvt,
    prdChtVsblEvt,
    handlePageChange,
    handleFilterChange,
    startDtmPickerChngEvt,
    endDtmPickerChngEvt,
    getGridData,
    xlDownEvt,
    pvCell,
  } = props;

  return (
    <div>
      <div className="solarPredSrchDiv">
        설비명&nbsp;&nbsp;
        <DropDownList
          className="solPredMeainDdl"
          data={cidList}
          key="cid"
          textField="meainTerm"
          onChange={cidListChngEvt}
          value={gridState.filter.cid}
          popupSettings={{ className: "autoFitDropDown" }}></DropDownList>
        &nbsp;&nbsp;&nbsp;
        <DropDownList
          data={dtmOptList}
          key="opt"
          textField="optTerm"
          onChange={dtmOptListChngEvt}
          value={dtmOptList.find(item => item.opt === gridState.filter.dtmOpt)}></DropDownList>
        &nbsp;&nbsp;&nbsp; 조회기간&nbsp;&nbsp;&nbsp;
        <DateTimePickerKo
          className={"predDtmPckr"}
          name={"startDtm"}
          value={gridState.filter.startDtm}
          setDtm={startDtmPickerChngEvt}></DateTimePickerKo>
        &nbsp;~&nbsp;
        <DateTimePickerKo
          className={"predDtmPckr"}
          name={"endDtm"}
          value={gridState.filter.endDtm}
          setDtm={endDtmPickerChngEvt}
          max={new Date()}></DateTimePickerKo>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={prdChtVsblEvt}>
          <QueryStats />
          &nbsp; 그래프 보기
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button icon="excel" onClick={xlDownEvt}>
          엑셀 다운로드
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button icon="search" onClick={getGridData}>
          조회
        </Button>
      </div>
      <div className="solarPredGridDiv">
        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko">
            <Grid
              resizable={true}
              className="solarPredGrid"
              data={gridData.results}
              skip={gridState.skip}
              take={gridState.take}
              total={gridData.total}
              pageable={{ buttonCount: 5, pageSizes: true }}
              onPageChange={handlePageChange}
              onFilterChange={handleFilterChange}>
              <GridColumn title="설비 ID" field="cid" width={"100px"}></GridColumn>
              <GridColumn title="설비명" field="meainTerm" width={"180px"}></GridColumn>
              <GridColumn title="수집일자" width={"160px"} field="gathDtm"></GridColumn>
              <GridColumn title="일사량(W/㎡)" field="slpIrrad" cell={pvCell}></GridColumn>
              <GridColumn title="모듈온도(℃)" field="modTemp" cell={pvCell}></GridColumn>
              <GridColumn title="PV전압(V)">
                <GridColumn title="측정값" field="pvVlt" cell={pvCell}></GridColumn>
                <GridColumn title="AI모델예측값" field="pvVltPred" cell={pvCell}></GridColumn>
                <GridColumn title="물리모델예측값" field="pvVltEst" cell={pvCell}></GridColumn>
              </GridColumn>

              <GridColumn title="PV전류(A)">
                <GridColumn title="측정값" field="pvCur" cell={pvCell}></GridColumn>
                <GridColumn title="AI모델예측값" field="pvCurPred" cell={pvCell}></GridColumn>
                <GridColumn title="물리모델예측값" field="pvCurEst" cell={pvCell}></GridColumn>
              </GridColumn>

              <GridColumn title="PV출력(kW)">
                <GridColumn title="측정값" field="pvPwr" cell={pvCell}></GridColumn>
                <GridColumn title="AI모델예측값" field="pvPwrPred" cell={pvCell}></GridColumn>
                <GridColumn title="물리모델예측값" field="pvPwrEst" cell={pvCell}></GridColumn>
              </GridColumn>
              <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
      {prdCht.visible ? <SolarPredChart prdCht={prdCht} prdChtUnVsblEvt={prdChtUnVsblEvt}></SolarPredChart> : null}
    </div>
  );
};

export default SolarPredPrzt;
