import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import "./solarModuleMngt.css";
import SolarModuleModal from "./solarModuleModal";
import koMessage from "../../../com/ko-language.json";
import GridColumnInputFilter from "./../../com/gridFilter/gridColumnInputFilter";
loadMessages(koMessage, "ko-KR");

const SolarModuleMngtPrzt = props => {
  const {
    gridData,
    gridState,
    solModModal,
    setModalVisible,
    saveSolModFunc,
    // getGridData,
    solModChngEvt,
    handlePageChange,
    handleFilterChange,
    cellTypeCell,
    curveFitCell,
    editCell,
    delCell,
    setCurveFitting,
    setModalInitial,
  } = props;
  return (
    <div>
      <div>
        <div>
          <Button icon="add" className="solModAddBtn" onClick={setModalInitial}>
            추가
          </Button>
        </div>
        <div className="solModGridDiv">
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Grid
                className="solModGrid"
                data={gridData.results}
                skip={gridState.skip}
                take={gridState.take}
                total={gridData.total}
                pageable={{ buttonCount: 5, pageSizes: true }}
                filterable
                onPageChange={handlePageChange}
                onFilterChange={handleFilterChange}>
                <GridColumn title="코드" field="modSeqNo" filterCell={GridColumnInputFilter}></GridColumn>
                <GridColumn title="모델명" field="modTerm" width={"200px"} filterCell={GridColumnInputFilter}></GridColumn>
                <GridColumn title="최대출력 Pmp(W)" field="vltMp" filterable={false}></GridColumn>
                <GridColumn title="최대전압 Vmp(V)" field="curMp" filterable={false}></GridColumn>
                <GridColumn title="최대전류 Imp(A)" field="pwrMp" filterable={false}></GridColumn>
                <GridColumn title="개방전압 Voc(V)" field="vltOc" filterable={false}></GridColumn>
                <GridColumn title="단락전류 Isc(A)" field="curSc" filterable={false}></GridColumn>
                <GridColumn title="전압 온도계수(%/°C) " field="tempVltCof" width={"145px"} filterable={false}></GridColumn>
                <GridColumn title="전류 온도계수(%/°C) " field="tempCurCof" width={"145px"} filterable={false}></GridColumn>
                <GridColumn title="셀 종류" field="cellType" filterable={false} cell={cellTypeCell}></GridColumn>
                <GridColumn title="커브피팅" field="" filterable={false} cell={curveFitCell}></GridColumn>
                <GridColumn title="수정" field="" filterable={false} cell={editCell}></GridColumn>
                <GridColumn title="삭제" field="" filterable={false} cell={delCell}></GridColumn>
                <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </div>
        {solModModal.visible ? (
          <SolarModuleModal
            solModModal={solModModal}
            setModalVisible={setModalVisible}
            solModChngEvt={solModChngEvt}
            saveSolModFunc={saveSolModFunc}
            setCurveFitting={setCurveFitting}></SolarModuleModal>
        ) : null}
      </div>
    </div>
  );
};

export default SolarModuleMngtPrzt;
