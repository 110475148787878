// 메인 프레임
// 다른 데이터 소스
// 1. 유저 리스트
// 2. 유저에 따른 설비 리스트
// 총 4개 데이터 소스 필요 (정보 데이터)
// 1. 시간데이터 차트 ,그리드
// 2. 지역별 비교
// 3. 대시보드 데이터
// 4. 설비별 데이터

import { useState } from "react";
import { BeatLoader } from "react-spinners";

import CmpUserByUserSearch from "./cmpUserByUserSearch";
// import UserInfoWindow from "./userInfoWindow";
import "./cmpUserByUser.css";
import styles from "./cmpUserByUser.module.css";
// import styles from "./cmpUserByUser.dk.module.css";
import CmpUserByUserMeainList from "./cmpUserByUserMeainList";
import { getDataByMeainInfo, getDataByUserInfo, getMeainListOfUser } from "../../../../service/stats/type/userByUserDataService";
import CmpUserByUserArea from "./cmpUserByUserArea";
import CmpUserByUserMeainInfoGrid from "./cmpUserByUserMeainInfoGrid";
import CmpUserByUserDashboard from "./cmpUserByUserDashboard";
import CmpUserByUserTimeChart from "./cmpUserByUserTimeChart";
import CmpUserByUserTimeGrid from "./cmpUserByUserTimeGrid";
import kendo from "@progress/kendo-ui";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import UserSearchList from "../../../com/userSearch/userSearchList";

const CmpUserByUser = ({ conn, codeList, cityList, rgnList, isMobile }) => {
  const innerHeight = window.innerHeight;
  const innerWidth = window.innerWidth;
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 날짜 Default 정의
  let today = new Date();
  let yesterday = new Date();
  let lastWeek = new Date();
  let lastMonth = new Date();
  let lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 검색 조건
  const [search, setSearch] = useState({
    userId: "",
    userTerm: "",
    dateType: "HH",
    cid: "",
    cityProvCode: "",
    ensoTypeCode: "",
    rgnCode: "",
    dongCode: "",
    startGathDtm: yesterday,
    endGathDtm: yesterday,
    endMin: yesterday,
    startMax: yesterday,
    yesterday: yesterday,
    dateStatus: { codeNo: 23006, codeVal: "직접조회" },
    userInfoVisible: false,
    endMax : yesterday ,
    titleName : '시간별'
  });

  // 데이터 리스트 (정보)
  const [dataList, setDataList] = useState({
    meainList: [],
    dashboard: {
      instCapa: 0,
      accumPower: 0,
      efic: 0,
      dayPower: 0,
      co2: 0, // <- 사용량 기준 : 사용량 * 0.4653 발전량 : 발전량 * 0.4653
    },
    timeData: {
      // <- 시간대별 데이터
      timeDataList: [],
      timeSeries: [],
      timeCategory: [],
    },
    timeDataListChart: [], // <- 차트
    areaCmpPowerList: [], // 지역구별 발전량 or 생산량 분포 차트 데이터
    areaXcategory: [],
    // 1.전국 , 시군구 , 읍면동 , 설치자
    meainCmpPowerList: [], // 설비별로 데이터
  });

  // 데이터 불러오기 (설비 리스트)
  const getMeainListData = () => {
    getMeainListOfUser(conn, getMeainList, getRejection, search.userId);
  };

  // 설비 callback 함수
  const getMeainList = meainList => {
    console.log("meainList - ", meainList);
    // CID 설정
    if (meainList.length > 0) {
      search.cid = meainList[0].cid;
      search.ensoTypeCode = meainList[0].ensoTypeCode;
    }
    // 굳이 셋 스테이트 말고 바로 설정 로직 확인 후 변경 하기
    setDataList(dataList => {
      const item = { ...dataList };
      item.meainList = meainList;
      return item;
    });
    // 데이터 불러오기 // 첫번쨰 값
    if (meainList.length > 0) {
      getDataList();
    }else{
      kendo.alert("검색 결과 등록된 설비가 없습니다. 설비를 등록 해주세요.");
      return;
    }
  };

  // 통합 Reject함수
  const getRejection = error => {
    setLoading(false);
    if (error.errCode === "F011") {
      kendo.alert("필요한 검색 파라미터가 선택 및 입력 되지 않았습니다. 다시 확인 해주세요.");
      return;
    } else {
      console.log("에러코드 = ", error);
      kendo.alert("해당 시스템이 문제가 있습니다. 다시 확인 해주세요.");
      return;
    }
  };

  // 데이터 설정
  const getDataList = () => {
    setLoading(true);
    if( search.dateType === "HH" ){
      getDataByUserInfo(conn, getDataByUserInfoResult, getRejection, search);
    }
    getDataByMeainInfo(conn, getDataByMeainInfoResult, getRejection, search);
  };

  // 설비선택 시 설비에 관한건만 데이터 불러오기
  const getDataListByMeain = () => {
    setLoading(true);
    getDataByMeainInfo(conn, getDataByMeainInfoResult, getRejection, search);
  };

  // 백엔드 데이터와 프론트 데이터 연동 설정 (유저 ID : 발전량 분포 , 설비별 발전량)
  const getDataByUserInfoResult = result => {
    setLoading(false);
    setDataList(dataList => {
      const item = { ...dataList };
      item.areaCmpPowerList = result.areaChartSeries;
      item.areaXcategory = result.areaXcategory;
      item.meainCmpPowerList = result.userByMeainInfoDtoList;
      return item;
    });
  };

  // 백엔드 데이터와 프론트 데이터 연동 설정 (유저 ID : 생산현황 , 시간대별 그래프)
  const getDataByMeainInfoResult = result => {
    setLoading(false);
    setDataList(dataList => {
      const item = { ...dataList };
      item.dashboard = result.userByDashboardDto;
      item.timeData.timeDataList = result.userByTimeDataResultDtoList;
      item.timeData.timeSeries = result.timeSeries;
      item.timeData.timeCategory = result.timeCategory;
      return item;
    });
  };

  // 유저 다이어로그 닫기 (윈도우 창)
  const toggleDialog = () => {
    setSearch(search => {
      const item = { ...search };
      item.userInfoVisible = false;
      return item;
    });
  };

  // UserId 선택
  const selectUserId = e => {
    // 설비 리스트 초기화
    dataList.meainList = [];

    // 조회 시 데이터 초기화
    dataList.dashboard = {
      instCapa: 0,
      accumPower: 0,
      efic: 0,
      dayPower: 0,
      co2: 0,
    };
    dataList.timeData = {
      // <- 시간대별 데이터
      timeDataList: [],
      timeSeries: [],
      timeCategory: [],
    };
    dataList.timeDataListChart = []; // <- 차트
    dataList.areaCmpPowerList = []; // 지역구별 발전량 or 생산량 분포 차트 데이터
    dataList.areaXcategory = [];
    // 1.전국 , 시군구 , 읍면동 , 설치자
    dataList.meainCmpPowerList = []; // 설비별로 데이터

    // 사용자 정보 설정
    setSearch(search => {
      const item = { ...search };
      item.userId = e.dataItem.userId;
      item.userTerm = e.dataItem.userTerm;
      item.cityProvCode = e.dataItem.cityProvCode;
      item.rgnCode = e.dataItem.rgnCode;
      item.dongCode = e.dataItem.fixbylawBundCode;
      item.userInfoVisible = false;
      return item;
    });
  };

  return (
    <>
      <div className={loading ? "cmpUserByUser" : ""}>
        <CmpUserByUserSearch
          search={search}
          setSearch={setSearch}
          codeList={codeList}
          getMeainListData={getMeainListData}
          kendo={kendo}
          isMobile={isMobile}
        />
        {dataList.meainList.length !== 0 ? (
          <>
            <div className={styles.titleName}> ⋅ 설비목록</div>
            <CmpUserByUserMeainList meainList={dataList.meainList} search={search} getDataListByMeain={getDataListByMeain} />
            <div className={styles.titleName}> ⋅ 생산현황 요약</div>
            <CmpUserByUserDashboard dashboard={dataList.dashboard} search={search} />
            <div className="uk-grid">
              <CmpUserByUserTimeChart
                Highcharts={Highcharts}
                HighchartsReact={HighchartsReact}
                ensoTypeCode={dataList.timeData.ensoTypeCode}
                timeSeries={dataList.timeData.timeSeries}
                timeCategory={dataList.timeData.timeCategory}
                search = {search}
              />
              <CmpUserByUserTimeGrid
                timeDataList={dataList.timeData.timeDataList}
                search={search}
                kendo={kendo}
                setLoading={setLoading}
                isMobile={isMobile}
              />
            </div>
            { search.dateType === "HH" && 
              <>
                <div className={styles.titleName}> ⋅ 발전량 분포</div>
                <CmpUserByUserArea
                  Highcharts={Highcharts}
                  HighchartsReact={HighchartsReact}
                  areaCmpPowerList={dataList.areaCmpPowerList}
                  areaXcategory={dataList.areaXcategory}
                />
                <div className={styles.titleName}> ⋅ 설비별 발전량</div>
                <CmpUserByUserMeainInfoGrid
                  meainCmpPowerList={dataList.meainCmpPowerList}
                  search={search}
                  kendo={kendo}
                  setLoading={setLoading}
                  isMobile={isMobile}
                />
              </>
            }
          </>
        ) : (
          <>
            <div className={styles.noDataCommnet}>
              {" "}
              설비 데이터가 존재 하지 않습니다.<br></br> 사용자를 선택 해주세요.
            </div>
          </>
        )}
        {search.userInfoVisible && (
          <UserSearchList
            conn={conn}
            setUserInfo={selectUserId}
            setVisible={toggleDialog}
            initialHeight={isMobile ? innerHeight : null}
            initialWidth={isMobile ? innerWidth : null}
            isMobile={isMobile}
          />
        )}
      </div>
      {loading && (
        <p className="cmpUserByUserLoading">
          <BeatLoader loading={true} size={24} color="#1e88e5" />
        </p>
      )}
    </>
  );
};

export default CmpUserByUser;
