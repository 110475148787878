export const moduleInit = {
  title: "추가",
  visible: false,
  modSeqNo: null,
  modTerm: null,
  vltMp: null,
  curMp: null,
  pwrMp: null,
  vltOc: null,
  curSc: null,
  cellType: null,
  tempVltCof: null,
  tempCurCof: null,
  vltFitA: null,
  vltFitB: null,
  vltFitC: null,
  curFitA: null,
  curFitB: null,
};

export const cellTypeList = [
  { text: "Mono 3BB", id: 1 },
  { text: "Mono 4BB", id: 2 },
  { text: "Mono 5BB", id: 3 },
  { text: "Mono 12BB", id: 4 },
  { text: "Multi 3BB", id: 5 },
  { text: "Multi 4BB", id: 6 },
  { text: "Multi 5BB", id: 7 },
  { text: "Multi 9BB", id: 8 },
];
