import React from "react";

import BizPlanAdd from "./bizPlanAdd";
import BizInstAdd from "./bizInstAdd";
import BizFileDemand from "./bizFileDemand";
import BizFileBpm1 from "./bizFileBpm1";
import BizFileBpm2 from "./bizFileBpm2";
import BizFileRgnIssued from "./bizFileRgnIssued";
import BizFileRgnSupply from "./bizFileRgnSupply";
import BizFileBpm1Moni from "./bizFileBpm1Moni";
import { CODE_BIZ_DEMAND, CODE_BIZ_BPM1, CODE_BIZ_BPM2, CODE_BIZ_RGN_ISSUE, CODE_BIZ_RGN_SUPPLY } from "../../../../com/codeUtil";

import { deleteFileList, downloadFile, getFileName } from "../../../../service/bizStat/com/bizFileSupportService";

const BizInstPlanAdd = ({
  conn,
  rgnCode,
  bizYy,
  instPlanListAdd,
  codeList,
  search,
  setSearch,
  setDataSource,
  instInput,
  inst,
  inputs,
  plan,
  styles,
  initalConvInfo,
  initalInstInput,
  moniInfo,
  isNew,
  rowData,
  userInfoVisible,
  setUserInfoVisible,
  useState,
  useEffect,
  TabStrip,
  TabStripTab,
  kendo,
  Form,
  FormElement,
  Input,
  RadioButton,
  DropDownList,
  getMessage,
  IntlProvider,
  LocalizationProvider,
  getToken,
  Upload,
  Window,
  Button,
}) => {
  // console.log("rgnCode, bizYy", rgnCode, bizYy)
  // console.log("inputs",inputs);

  const [fileTabName, setFileTabName] = useState();

  // const [biz, setBiz] = useState({
  //   code: "",
  //   name: "",
  // });
  const [biz, setBiz] = useState({
    code: "",
    name: "",
  });

  let energyTypeCode = null;
  // 탭 번호
  let [selected, setSelected] = useState();

  // 파일 업로드 윈도우 창 제어
  const [windowVisible, setWindowVisible] = useState(false);

  // 파일
  const [fileState, setFileState] = useState({
    fileList: [],
    currentFiles: [],
    docuCodeNo: "",
  });

  // const [submitFile, setSubmitFile] = useState({
  //   instlPlnnm: "",
  //   bizDocuCodeNo: "",
  // });

  const [file, setFile] = useState({});

  const [value, setValue] = useState(false);

  const handleMoniYChange = event => {
    setValue(!value);
    setDataSource(dataSource => {
      const item = dataSource;
      item.inputs.moniYn = "Y";
      return item;
    });
  };
  const handleMoniNChange = event => {
    setValue(!value);
    setDataSource(dataSource => {
      const item = dataSource;
      item.inputs.moniYn = "N";
      return item;
    });
  };

  const handleNewbulidYChange = event => {
    setValue(!value);
    setDataSource(dataSource => {
      const item = dataSource;
      item.inputs.newbuild = "Y";
      return item;
    });
  };
  const handleNewbulidNChange = event => {
    setValue(!value);
    setDataSource(dataSource => {
      const item = dataSource;
      item.inputs.newbuild = "N";
      return item;
    });
  };
  const handleCityNewdealYChange = event => {
    setValue(!value);
    setDataSource(dataSource => {
      const item = dataSource;
      item.inputs.cityNewdeal = "Y";
      return item;
    });
  };

  const handleCityNewdealNChange = event => {
    setValue(!value);
    setDataSource(dataSource => {
      const item = dataSource;
      item.inputs.cityNewdeal = "N";
      return item;
    });
  };

  // const onClassFaciChange = event => {
  //   setDataSource(dataSource => {
  //     const item = dataSource;
  //     item.inputs.classFaci = event.target.value;
  //     return item;
  //   });
  // };

  useEffect(() => {
    setSelected(0);
  }, []);

  const getFileList = () => {
    // console.log("데이터 불러오기");
    // console.log("biz", biz);

    let fileIds = {
      instlPlnnm: inputs.instlPlnnm,
      bizDocuCodeNo: biz.code,
      energyTypeCode: energyTypeCode,
      bizSctCode: search.bizSctCode,
    };

    getFileName(conn, fileIds, biz.name, getFileListResult, getFileListRejection);
  };  

  const handleSelect = e => {
    if (inputs.moniYn === "N") {
      if (e.selected > 1) {
        if (inputs.instlPlnnm !== "") {
          if (e.selected === 2) {
            biz.code = CODE_BIZ_DEMAND;
            biz.name = "demand";
          } else if (e.selected === 3) {
            biz.code = CODE_BIZ_BPM1;
            biz.name = "bpm1";
          } else if (e.selected === 4) {
            biz.code = CODE_BIZ_BPM2;
            biz.name = "bpm2";
          } else if (e.selected === 5) {
            biz.code = CODE_BIZ_RGN_ISSUE;
            biz.name = "rgnIssue";
          } else if (e.selected === 6) {
            biz.code = CODE_BIZ_RGN_SUPPLY;
            biz.name = "rgnSupply";
          }

          setSelected(e.selected);
          getFileList();
        } else {
          kendo.alert("기본정보탭에서 BPM설치계획서 신청번호를 입력해주세요");
        }
      } else {
        setSelected(e.selected);
      }
    } else if (inputs.moniYn === "Y") {
      if (e.selected > 0) {
        if (inputs.instlPlnnm !== "") {
          if (e.selected === 1) {
            biz.code = CODE_BIZ_BPM1;
            biz.name = "bpm1";
          } else if (e.selected === 2) {
            biz.code = CODE_BIZ_RGN_ISSUE;
            biz.name = "rgnIssue";
          } else if (e.selected === 3) {
            biz.code = CODE_BIZ_RGN_SUPPLY;
            biz.name = "rgnSupply";
          }

          setSelected(e.selected);
          getFileList();
        } else {
          kendo.alert("기본정보탭에서 BPM설치계획서 신청번호를 입력해주세요");
        }
      } else {
        setSelected(e.selected);
      }
    }
    // console.log("convInfo",convInfo);

    if (selected === 1 && inputs.createUpdateYn === true) {
      initalConvInfo();
      initalInstInput();
    } else if (selected === 1 && inputs.createUpdateYn === false) {
      initalInstInput();
    }
  };

  const getFileListResult = result => {
    // console.log("result", result);
    // console.log("selected", selected);

    if (result.length > 0) {
      setFile({});

      result.forEach(element => {
        let docuCode = element.docuCodeNo;
        setFile(file => {
          const item = { ...file };
          // console.log("item = ", item);
          item[docuCode] = element;
          return item;
        });
      });
    } else {
      setFile({});
    }
  };

  // rejection 함수
  const getFileListRejection = error => {
    // console.log("error = ", error);
  };

  const onAddFile = e => {
    // console.log("onAdd e ", e, fileState);
    setFileState(state => {
      const item = { ...state };
      item.currentFiles = e.affectedFiles;
      return item;
    });
  };

  const onRemoveFile = e => {
    console.log("onRemove e", e, fileState);
    let removeFileIds = [];
    let updateFiles = [];
    fileState.fileList.forEach(f => {
      e.affectedFiles.forEach(v => {
        if (v.uid === f.uid && v.name === f.name)
          removeFileIds.push({
            instlPlnnm: f.instlPlnnm,
            bizDocuCodeNo: f.bizDocuCodeNo,
            docuCodeNo: f.docuCodeNo,
            energyTypeCode: null,
          });
      });
      e.newState.forEach(n => {
        if (f.uid === n.uid && f.name === n.name) updateFiles.push(f);
      });
    });

    if (removeFileIds.length !== 0) {
      deleteFileList(conn, removeFileIds, fileTabName)
        .then(r => {
          kendo.alert("삭제되었습니다.");
          setFileState(state => {
            const item = { ...state };
            item.fileList = updateFiles;
            return item;
          });
        })
        .catch(e => {
          kendo.alert("삭제되지 않았습니다.");
        });
    }
  };

  const onStatusChangeFile = e => {
    // console.log("onStatusChange e", e, fileState);
    if (e.response?.response) {
      let list = [...fileState.fileList, ...e.response.response.data];
      setFileState(state => {
        const item = { ...state };
        item.fileList = list;
        item.currentFiles = e.response.response;
        return item;
      });

      if (e.response.response.status === 200) {
        setWindowVisible(!windowVisible);
        kendo.alert("업로드되었습니다.");
        getFileList();
      }
    }
  };

  // 파일 업로드 윈도우 창 제어
  const uploadFileEvt = e => {
    // console.log('엑셀 업로드');
    // initialExcelFile();

    setFileTabName(e.target.name);

    setFileState(state => {
      const item = { ...state };
      item.docuCodeNo = e.target.id;
      return item;
    });
    console.log("windowVisible = ", windowVisible);
    setWindowVisible(!windowVisible);
    getFileList();
  };

  const deleteFileEvt = e => {
    // console.log('삭제');

    let removeFileIds = [
      {
        instlPlnnm: inputs.instlPlnnm,
        bizDocuCodeNo: biz.code,
        docuCodeNo: e.target.id,
        energyTypeCode: null,
        bizSctCode: search.bizSctCode,
      },
    ];

    // console.log("removeFileIds", removeFileIds);

    kendo
      .confirm("해당 파일을 삭제 하시겠습니까?")
      .then(result => {
        deleteFileList(conn, removeFileIds)
          .then(r => {
            kendo.alert("삭제되었습니다.");

            getFileList();
          })
          .catch(e => {
            kendo.alert("삭제되지 않았습니다.");
          });
      })
      .catch(e => {
        kendo.alert("삭제되지 않았습니다.");
      });
  };

  function downloadFileEvt(e) {
    const fileInfo = {
      instlPlnnm: inputs.instlPlnnm,
      bizDocuCodeNo: biz.code,
      docuCodeNo: e.target.id,
      energyTypeCode: null,
      bizSctCode: search.bizSctCode,
    };
    const fileName = e.target.outerText;
    downloadFile(fileInfo, fileName);
  }

  const saveFileUrl =
    `${process.env.REACT_APP_REST_API_VERSION}/biz/file/upload?fileInfo=` +
    encodeURI(JSON.stringify(fileState.currentFiles)) +
    "&instlPlnnm=" +
    inputs.instlPlnnm +
    "&bizDocuCodeNo=" +
    biz.code +
    "&docuCodeNo=" +
    fileState.docuCodeNo +
    "&convName=" +
    biz.name +
    "&bizSctCode=" +
    search.bizSctCode;
  // const removeFileUrl = `${process.env.REACT_APP_REST_API_VERSION}` + "/file/biz/delete";
  const removeFileUrl = `${process.env.REACT_APP_REST_API_VERSION}/biz/file/delete`;

  return (
    <>
      {/* <Window
      className="noti-info"
      title={"설치계획서 관리"}
      left={windowState.left}
      top={windowState.top}
      width={windowState.width}
      height={windowState.heiht}
      onClose={instPlanListAdd}
      initialHeight={isMobile === 'Y' ? innerHeight : 450}
      initialWidth={isMobile === 'Y' ? innerWidth : 900}
      > */}
      <div
        class="Modal_pop_up_A modal_Bg"
        style={{
          left: "20px",
          position: "relative",
          top: "-160px",
          zIndex: "9999",
          opacity: "1",
          display: "block",
          border: "1px solid #DEDEDE",
        }}>
        <div class="modal_title">
          <h3 style={{ textAlign: "left" }}>{inputs.instlPlnnm !== "" ? inputs.instlPlnnm : "설치계획서 관리(등록)"}</h3>
          <button class="btn_iconMM b-close" onClick={instPlanListAdd}>
            <i searchclass="material-icons" style={{ color: "black", fontSize: "20px" }}>
              X
            </i>
          </button>
        </div>
        <div class="ModalInputArea">
          <TabStrip className={styles.JinTabMo} id="JinTabModal" selected={selected} onSelect={handleSelect}>
            <TabStripTab title="기본정보" key={"tabPlan"}>
              <div className={styles.plan} id="JinTabModalBB_con">
                <BizPlanAdd
                  conn={conn}
                  styles={styles}
                  rgnCode={rgnCode}
                  bizYy={bizYy}
                  instPlanListAdd={instPlanListAdd}
                  codeList={codeList}
                  setPaging={setSearch}
                  inputs={inputs}
                  setDataSource={setDataSource}
                  plan={plan}
                  moniInfo={moniInfo}
                  value={value}
                  handleMoniYChange={handleMoniYChange}
                  handleMoniNChange={handleMoniNChange}
                  handleNewbulidYChange={handleNewbulidYChange}
                  handleNewbulidNChange={handleNewbulidNChange}
                  handleCityNewdealYChange={handleCityNewdealYChange}
                  handleCityNewdealNChange={handleCityNewdealNChange}
                  isNew={isNew}
                  rowData={rowData}
                  search={search}
                  userInfoVisible={userInfoVisible}
                  setUserInfoVisible={setUserInfoVisible}
                  useEffect={useEffect}
                  Form={Form}
                  FormElement={FormElement}
                  Input={Input}
                  RadioButton={RadioButton}
                  DropDownList={DropDownList}
                  Button={Button}
                  kendo={kendo}
                  getMessage={getMessage}
                />
              </div>
            </TabStripTab>
            {inputs.moniYn === "N" && (
              <TabStripTab title="설비내역" key={"tabInst"}>
                <div className="JinTabMo_con" id="JinTabModalBB_con">
                  <BizInstAdd
                    conn={conn}
                    codeList={codeList}
                    inputs={inputs}
                    instInput={instInput}
                    setDataSource={setDataSource}
                    inst={inst}
                    search={search}
                    DropDownList={DropDownList}
                    Button={Button}
                    kendo={kendo}
                    getMessage={getMessage}
                  />
                </div>
              </TabStripTab>
            )}
            {inputs.moniYn === "N" && (
              <TabStripTab title="수요조사서류" key={"tabPlan"}>
                <div className="JinTabMo_con" id="JinTabModalBB_con">
                  <BizFileDemand
                    onAddFile={onAddFile}
                    onRemoveFile={onRemoveFile}
                    onStatusChangeFile={onStatusChangeFile}
                    fileState={fileState}
                    windowVisible={windowVisible}
                    uploadFileEvt={uploadFileEvt}
                    deleteFileEvt={deleteFileEvt}
                    file={file}
                    downloadFileEvt={downloadFileEvt}
                    saveFileUrl={saveFileUrl}
                    removeFileUrl={removeFileUrl}
                    IntlProvider={IntlProvider}
                    LocalizationProvider={LocalizationProvider}
                    getToken={getToken}
                    Upload={Upload}
                    Window={Window}
                  />
                </div>
              </TabStripTab>
            )}
            {inputs.moniYn === "N" && (
              <TabStripTab title="BPM서류 1차" key={"tabInst"}>
                <div className="JinTabMo_con" id="JinTabModalBB_con">
                  <BizFileBpm1
                    onAddFile={onAddFile}
                    onRemoveFile={onRemoveFile}
                    onStatusChangeFile={onStatusChangeFile}
                    fileState={fileState}
                    windowVisible={windowVisible}
                    uploadFileEvt={uploadFileEvt}
                    deleteFileEvt={deleteFileEvt}
                    file={file}
                    downloadFileEvt={downloadFileEvt}
                    saveFileUrl={saveFileUrl}
                    removeFileUrl={removeFileUrl}
                    IntlProvider={IntlProvider}
                    LocalizationProvider={LocalizationProvider}
                    getToken={getToken}
                    Upload={Upload}
                    Window={Window}
                  />
                </div>
              </TabStripTab>
            )}
            {inputs.moniYn === "Y" && (
              <TabStripTab title="BPM서류 1차" key={"tabInst"}>
                <div className="JinTabMo_con" id="JinTabModalBB_con">
                  <BizFileBpm1Moni
                    onAddFile={onAddFile}
                    onRemoveFile={onRemoveFile}
                    onStatusChangeFile={onStatusChangeFile}
                    fileState={fileState}
                    windowVisible={windowVisible}
                    uploadFileEvt={uploadFileEvt}
                    deleteFileEvt={deleteFileEvt}
                    file={file}
                    downloadFileEvt={downloadFileEvt}
                    saveFileUrl={saveFileUrl}
                    removeFileUrl={removeFileUrl}
                    IntlProvider={IntlProvider}
                    LocalizationProvider={LocalizationProvider}
                    getToken={getToken}
                    Upload={Upload}
                    Window={Window}
                  />
                </div>
              </TabStripTab>
            )}
            {inputs.moniYn === "N" && (
              <TabStripTab title="BPM서류 2차" key={"tabInst"}>
                <div className={styles.fileBpm2} id="JinTabModalBB_con">
                  <BizFileBpm2
                    onAddFile={onAddFile}
                    onRemoveFile={onRemoveFile}
                    onStatusChangeFile={onStatusChangeFile}
                    fileState={fileState}
                    windowVisible={windowVisible}
                    uploadFileEvt={uploadFileEvt}
                    deleteFileEvt={deleteFileEvt}
                    file={file}
                    downloadFileEvt={downloadFileEvt}
                    saveFileUrl={saveFileUrl}
                    removeFileUrl={removeFileUrl}
                    styles={styles}
                    IntlProvider={IntlProvider}
                    LocalizationProvider={LocalizationProvider}
                    getToken={getToken}
                    Upload={Upload}
                    Window={Window}
                  />
                </div>
              </TabStripTab>
            )}
            <TabStripTab title="시군구 교부서류" key={"tabInst"}>
              <div className="JinTabMo_con" id="JinTabModalBB_con">
                <BizFileRgnIssued
                  onAddFile={onAddFile}
                  onRemoveFile={onRemoveFile}
                  onStatusChangeFile={onStatusChangeFile}
                  fileState={fileState}
                  windowVisible={windowVisible}
                  uploadFileEvt={uploadFileEvt}
                  deleteFileEvt={deleteFileEvt}
                  file={file}
                  downloadFileEvt={downloadFileEvt}
                  saveFileUrl={saveFileUrl}
                  removeFileUrl={removeFileUrl}
                  IntlProvider={IntlProvider}
                  LocalizationProvider={LocalizationProvider}
                  getToken={getToken}
                  Upload={Upload}
                  Window={Window}
                />
              </div>
            </TabStripTab>
            <TabStripTab title="시군구 지급서류" key={"tabInst"}>
              <div className="JinTabMo_con" id="JinTabModalBB_con">
                <BizFileRgnSupply
                  onAddFile={onAddFile}
                  onRemoveFile={onRemoveFile}
                  onStatusChangeFile={onStatusChangeFile}
                  fileState={fileState}
                  windowVisible={windowVisible}
                  uploadFileEvt={uploadFileEvt}
                  deleteFileEvt={deleteFileEvt}
                  file={file}
                  downloadFileEvt={downloadFileEvt}
                  saveFileUrl={saveFileUrl}
                  removeFileUrl={removeFileUrl}
                  IntlProvider={IntlProvider}
                  LocalizationProvider={LocalizationProvider}
                  getToken={getToken}
                  Upload={Upload}
                  Window={Window}
                />
              </div>
            </TabStripTab>
          </TabStrip>
        </div>
      </div>
      {/* </Window> */}
    </>
  );
};

export default BizInstPlanAdd;
