import { useState, useEffect } from "react";
import { Input } from "@progress/kendo-react-inputs";
import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";

import { BeatLoader } from "react-spinners";
import { getExpctConsmList } from "../../../../service/stats/expt/exptConsmService";
import { getUser, isInstaller } from "../../../../service/authService";

import ExpctConsmGrid from "./expctConsmGrid";
import styles from "./expctConsm.module.css";
import "./expctConsm.css";
import UserSearchList from "../../../com/userSearch/userSearchList";

function ExpctConsm({ conn, cityList, rgnList }) {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 유저정보
  const userInfo = getUser();

  // 윈도우 창 Visible
  let [userInfoVisible, setUserInfoVisible] = useState(false);

  // 검색 조건
  const [search, setSearch] = useState({
    userId: isInstaller() === true ? userInfo.userId : "",
    userTerm: isInstaller() === true ? userInfo.userTerm : "",
  });

  const [dataSource, setDataSource] = useState({
    grid: [],
    chart: [],
    excelData: null,
  });

  // 설치자 검색 버튼 클릭 이벤트
  const searchUserId = () => {
    if (isInstaller() !== true) {
      setUserInfoVisible(!userInfoVisible);
    }
  };

  // 그리드에서 유저 선택 시 이벤트
  const selectUserId = e => {
    setSearch({
      ...search,
      userId: e.dataItem.userId,
      userTerm: e.dataItem.userTerm,
    });
    setUserInfoVisible(false);

    getExpctConsmData({
      ...search,
      userId: e.dataItem.userId,
      rgnCode: e.dataItem.fixbylawBundCode.substring(0, 5),
    });
  };

  const getExpctConsmData = search => {
    getExpctConsmList(conn, getResult, getRejection, search, setLoading);
  };

  // 결과 데이터
  const getResult = (gridList, chartData) => {
    setDataSource(dataSource => {
      const item = { ...dataSource };
      item.grid = gridList;
      item.chart = chartData;
      return item;
    });
    setLoading(false);
  };

  // rejection
  const getRejection = (name, error) => {
    setLoading(false);
    if (error.errCode === "F011") {
      kendo.alert("예측 수용가 오류");
      return;
    }
  };

  useEffect(() => {
    if (isInstaller() === true) {
      getExpctConsmData(search);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
        <div className={["uk-width-medium-1-1", loading ? styles.expctConsmG : ""].join(" ")}>
          <div className="md-card TBgA">
              <div className="md-card-content small-padding">
              <b>수용가 검색 &nbsp; &nbsp;</b>
              <Input
                id="userTerm"
                type="text"
                className={styles.SetSearchTerm}
                onClick={searchUserId}
                placeholder="설치자"
                readOnly
                value={search.userTerm ? search.userTerm : ""}
                disabled={isInstaller() === true ? true : false}
              />
              <Button
                icon="search"
                // className={styles.searchBtn}
                onClick={searchUserId}
                disabled={isInstaller() === true ? true : false}></Button>
              {userInfoVisible && (
                <UserSearchList conn={conn} setUserInfo={selectUserId} setVisible={setUserInfoVisible} initialTop={50} initialLeft={220} />
              )}
          </div>
        </div>
          <ExpctConsmGrid
            search={search}
            dataSourceGrid={dataSource.grid}
            dataSourceChart={dataSource.chart}
            dataSourceExcel={dataSource.excelData}
            setDataSource={setDataSource}
            loading={loading}
          />
        </div>
        {loading && (
          <p className={styles.expctConsmLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
    </>
  );
}

export default ExpctConsm;
