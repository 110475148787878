
import { BeatLoader } from "react-spinners";

import TroubleShootingChangeChart from "./troubleShootingChangeChart";

const TroubleShootingChangeChartSpace = ({ changeChartData, changeChartLoading }) => {
    const today = new Date();
    
    return (
        <>
            <div className='uk-grid'>
                <div className="md-card troubleShootingChart">
                    <div className="md-card-content">
                        <div className="md-card-toolbar">
                            <h3 className="md-card-toolbar-heading-text">수용가별 발전효율 변동 현황</h3>
                        </div>
                        <TroubleShootingChangeChart changeChartData={changeChartData}/>
                    </div>
                    {changeChartLoading && (
                        <p
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                            }}>
                            <BeatLoader loading={true} size={24} color="#1e88e5" />
                        </p>
                    )}
                </div>
            </div>
        </>
    )
};

export default TroubleShootingChangeChartSpace;