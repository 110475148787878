import { Dialog } from "@progress/kendo-react-dialogs";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numFormat, roundNum } from "./../../../com/dataUtil";

import "./solarPred.css";

const SolarPredChart = props => {
  const { prdCht, prdChtUnVsblEvt } = props;
  const gathDtm = prdCht.data.gathDtm;
  const pvVlt = prdCht.data.pvVlt;
  const pvVltEst = prdCht.data.pvVltEst;
  const pvVltPred = prdCht.data.pvVltPred;
  const pvCur = prdCht.data.pvCur;
  const pvCurEst = prdCht.data.pvCurEst;
  const pvCurPred = prdCht.data.pvCurPred;
  const pvPwr = prdCht.data.pvPwr;
  const pvPwrEst = prdCht.data.pvPwrEst;
  const pvPwrPred = prdCht.data.pvPwrPred;
  const slpIrrad = prdCht.data.slpIrrad;
  const modTemp = prdCht.data.modTemp;
  const linearVltEst = prdCht.data.linearVltEst;
  const linearCurEst = prdCht.data.linearCurEst;
  const linearPwrEst = prdCht.data.linearPwrEst;
  const linearVltPred = prdCht.data.linearVltPred;
  const linearCurPred = prdCht.data.linearCurPred;
  const linearPwrPred = prdCht.data.linearPwrPred;

  const vltCht = {
    chart: { zoomType: "xy", backgroundColor: "#FFFFFF" },
    lang: {
      noData: "데이터가 없습니다.",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        showEmpty: false,
        labels: {
          format: "{value:,f}V",
          style: {
            color: "#000000",
          },
        },
        title: {
          text: "전압(V)",
          style: {
            color: "#000000",
          },
          // ,
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        // min: 0,
      },
      {
        // visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,f} (W/㎡)",
          style: {
            color: "#F8AC29",
          },
        },
        // max: 100,
        title: {
          text: "일사량(W/㎡)",
          style: {
            color: "#F8AC29",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        min: 0,
        opposite: true,
      },
      {
        // visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,f} (℃)",
          style: {
            color: "#0FB54C",
          },
        },
        // max: 100,
        title: {
          text: "모듈온도(℃)",
          style: {
            color: "#0FB54C",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        // min: 0,
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let s = "<b>" + gathDtm[this.x] + "</b>";
        this.points.forEach((item, index) => {
          if (index <= 2)
            s +=
              "<br/>" +
              '<span style="color:' +
              this.points[index].point.color +
              '">\u25CF</span> ' +
              this.points[index].series.name +
              ": " +
              numFormat(roundNum(this.points[index].y, 3)) +
              " V";
        });
        s +=
          "<br/>" +
          '<span style="color:#F8AC29' +
          '">\u25CF</span> ' +
          "일사량" +
          ": " +
          numFormat(roundNum(slpIrrad[this.x], 2)) +
          " (W/㎡)";
        s +=
          "<br/>" +
          '<span style="color:#0FB54C' +
          '">\u25CF</span> ' +
          "모듈온도" +
          ": " +
          numFormat(roundNum(modTemp[this.x], 2)) +
          " (℃)";
        return s;
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        name: "측정값",
        type: "spline",
        data: pvVlt,
        color: "#25383C",
      },
      {
        name: "물리모델예측값",
        type: "spline",
        data: pvVltEst,
        color: "#000099",
      },
      {
        name: "AI모델예측값",
        type: "spline",
        data: pvVltPred,
        color: "#C2103F",
      },
      {
        yAxis: 1,
        visible: false,
        name: "일사량",
        type: "spline",
        data: slpIrrad,
        color: "#F8AC29",
      },
      {
        yAxis: 2,
        visible: false,
        name: "모듈온도",
        type: "spline",
        data: modTemp,
        color: "#0FB54C",
      },
    ],
  };

  const curCht = {
    chart: { zoomType: "xy", backgroundColor: "#FFFFFF" },
    lang: {
      noData: "데이터가 없습니다.",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        showEmpty: false,
        labels: {
          format: "{value:,f}A",
          style: {
            color: "#000000",
          },
        },
        title: {
          text: "전류(A)",
          style: {
            color: "#000000",
          },
          // ,
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        // min: 0,
      },
      {
        // visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,f} (W/㎡)",
          style: {
            color: "#F8AC29",
          },
        },
        // max: 100,
        title: {
          text: "일사량(W/㎡)",
          style: {
            color: "#F8AC29",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        min: 0,
        opposite: true,
      },
      {
        // visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,f} (℃)",
          style: {
            color: "#0FB54C",
          },
        },
        // max: 100,
        title: {
          text: "모듈온도(℃)",
          style: {
            color: "#0FB54C",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        // min: 0,
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let s = "<b>" + gathDtm[this.x] + "</b>";
        this.points.forEach((item, index) => {
          if (index <= 2)
            s +=
              "<br/>" +
              '<span style="color:' +
              this.points[index].point.color +
              '">\u25CF</span> ' +
              this.points[index].series.name +
              ": " +
              numFormat(roundNum(this.points[index].y, 3)) +
              " A";
        });
        s +=
          "<br/>" +
          '<span style="color:#F8AC29' +
          '">\u25CF</span> ' +
          "일사량" +
          ": " +
          numFormat(roundNum(slpIrrad[this.x], 2)) +
          " (W/㎡)";
        s +=
          "<br/>" +
          '<span style="color:#0FB54C' +
          '">\u25CF</span> ' +
          "모듈온도" +
          ": " +
          numFormat(roundNum(modTemp[this.x], 2)) +
          " (℃)";
        return s;
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        name: "측정값",
        type: "spline",
        data: pvCur,
        color: "#25383C",
      },
      {
        name: "물리모델예측값",
        type: "spline",
        data: pvCurEst,
        color: "#000099",
      },
      {
        name: "AI모델예측값",
        type: "spline",
        data: pvCurPred,
        color: "#C2103F",
      },
      {
        yAxis: 1,
        visible: false,
        name: "일사량",
        type: "spline",
        data: slpIrrad,
        color: "#F8AC29",
      },
      {
        yAxis: 2,
        visible: false,
        name: "모듈온도",
        type: "spline",
        data: modTemp,
        color: "#0FB54C",
      },
    ],
  };
  const pwrCht = {
    chart: { zoomType: "xy", backgroundColor: "#FFFFFF" },
    lang: {
      noData: "데이터가 없습니다.",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [{ type: "category", crosshair: true }],
    yAxis: [
      {
        showEmpty: false,
        labels: {
          format: "{value:,f}kW",
          style: {
            color: "#000000",
          },
        },
        title: {
          text: "출력(kW)",
          style: {
            color: "#000000",
          },
          // ,
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        // min: 0,
      },
      {
        // visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,f} (W/㎡)",
          style: {
            color: "#F8AC29",
          },
        },
        // max: 100,
        title: {
          text: "일사량(W/㎡)",
          style: {
            color: "#F8AC29",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        min: 0,
        opposite: true,
      },
      {
        // visible: false,
        showEmpty: false,
        // showEmpty: true,
        labels: {
          format: "{value:,f} (℃)",
          style: {
            color: "#0FB54C",
          },
        },
        // max: 100,
        title: {
          text: "모듈온도(℃)",
          style: {
            color: "#0FB54C",
          },
          rotation: 0,
          y: -20,
          x: -10,
          offset: 0,
          align: "high",
        },
        // min: 0,
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let s = "<b>" + gathDtm[this.x] + "</b>";
        this.points.forEach((item, index) => {
          if (index <= 2)
            s +=
              "<br/>" +
              '<span style="color:' +
              this.points[index].point.color +
              '">\u25CF</span> ' +
              this.points[index].series.name +
              ": " +
              numFormat(roundNum(this.points[index].y, 3)) +
              " kW";
        });

        s +=
          "<br/>" +
          '<span style="color:#F8AC29' +
          '">\u25CF</span> ' +
          "일사량" +
          ": " +
          numFormat(roundNum(slpIrrad[this.x], 2)) +
          " (W/㎡)";
        s +=
          "<br/>" +
          '<span style="color:#0FB54C' +
          '">\u25CF</span> ' +
          "모듈온도" +
          ": " +
          numFormat(roundNum(modTemp[this.x], 2)) +
          " (℃)";
        return s;
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || "#FFFFFF",
    },
    series: [
      {
        name: "측정값",
        type: "spline",
        data: pvPwr,
        color: "#25383C",
      },
      {
        name: "물리모델예측값",
        type: "spline",
        data: pvPwrEst,
        color: "#000099",
      },
      {
        name: "AI모델예측값",
        type: "spline",
        data: pvPwrPred,
        color: "#C2103F",
      },
      {
        yAxis: 1,
        visible: false,
        name: "일사량",
        type: "spline",
        data: slpIrrad,
        color: "#F8AC29",
      },
      {
        yAxis: 2,
        visible: false,
        name: "모듈온도",
        type: "spline",
        data: modTemp,
        color: "#0FB54C",
      },
    ],
  };

  const getLinearGraph = (chtData, title) => {
    let maxVal = Math.max(...chtData.flat());
    const cht = {
      chart: {
        height: "440px",
      },
      title: {
        text: title,
      },
      xAxis: {
        min: 0,
        max: maxVal,
        endOnTick: false,
      },
      yAxis: {
        title: "none",
        min: 0,
        max: maxVal,
        endOnTick: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        formatter: function () {
          // let s = "<b>" + " Observations" + "</b>";
          let s = "<b>Observations</b>";
          s +=
            "<br/>" +
            '<span style="color:' +
            this.color +
            '">\u25CF</span> ' +
            " 측정값: " +
            numFormat(roundNum(this.x, 3)) +
            "<br/>" +
            '<span style="color:' +
            this.color +
            '">\u25CF</span> ' +
            " 예측값: " +
            numFormat(roundNum(this.y, 3));
          return s;
        },
      },
      series: [
        {
          type: "line",
          name: "Regression Line",
          color: "#25383C",
          data: [
            [0, 0],
            [maxVal, maxVal],
          ],
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              lineWidth: 0,
            },
          },
          enableMouseTracking: false,
        },
        {
          type: "scatter",
          name: "Observations",
          color: "#000099",
          data: chtData,
          marker: {
            radius: 3,
          },
        },
      ],
    };
    return cht;
  };

  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
      resetZoom: "초기화",
    },
  });

  return (
    <Dialog title={"그래프 보기"} width="90%" height={"85%"} onClose={prdChtUnVsblEvt}>
      <div className="chtDiv">
        <div className="solPrdChtHdr">출력 그래프</div>
        <div className="solPrdChtCont">
          <HighchartsReact highcharts={Highcharts} options={pwrCht}></HighchartsReact>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="chtDiv">
        <div className="">
          <div className="solPrdChtHdr">전압 그래프</div>
          <div className="solPrdChtCont">
            <HighchartsReact highcharts={Highcharts} options={vltCht}></HighchartsReact>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="chtDiv">
          <div className="solPrdChtHdr">전류 그래프</div>
          <div className="solPrdChtCont">
            <HighchartsReact highcharts={Highcharts} options={curCht}></HighchartsReact>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="">
          <div className="solPrdChtHdr">AI모델 선형 그래프</div>
          <div className="solPrdChtCont">
            <span className="solPrdRightCht">
              <HighchartsReact highcharts={Highcharts} options={getLinearGraph(linearVltPred, "전압")}></HighchartsReact>
            </span>
            <span className="solPrdRightCht">
              <HighchartsReact highcharts={Highcharts} options={getLinearGraph(linearCurPred, "전류")}></HighchartsReact>
            </span>
            <span className="solPrdRightCht">
              <HighchartsReact highcharts={Highcharts} options={getLinearGraph(linearPwrPred, "출력")}></HighchartsReact>
            </span>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="">
          <div className="solPrdChtHdr">물리모델 선형 그래프</div>
          <div className="solPrdChtCont">
            <span className="solPrdRightCht">
              <HighchartsReact highcharts={Highcharts} options={getLinearGraph(linearVltEst, "전압")}></HighchartsReact>
            </span>
            <span className="solPrdRightCht">
              <HighchartsReact highcharts={Highcharts} options={getLinearGraph(linearCurEst, "전류")}></HighchartsReact>
            </span>
            <span className="solPrdRightCht">
              <HighchartsReact highcharts={Highcharts} options={getLinearGraph(linearPwrEst, "출력")}></HighchartsReact>
            </span>
          </div>
        </div>
      </div>
    </Dialog>

    //   <div className="solarPredChartDiv">
    //   <div className="solPrdChtLeftDiv">
    //     <div className="solPrdChtHdr">전압</div>
    //     <div className="solPrdChtCont">
    //       <HighchartsReact highcharts={Highcharts} options={chartOpt}></HighchartsReact>{" "}
    //     </div>
    //   </div>
    //   <div className="solPrdChtLeftDiv">
    //     <div className="solPrdChtHdr">전류</div>
    //     <div className="solPrdChtCont">그래프</div>
    //   </div>
    //   <div className="solPrdChtLeftDiv">
    //     <div className="solPrdChtHdr">출력</div>
    //     <div className="solPrdChtCont">그래프</div>
    //   </div>
    // </div>
    // <div className="solarPredChartDiv">
    //   <div className="solPrdChtRtDiv">
    //     <div className="solPrdChtHdr">ML 예측 그래프</div>
    //     <div className="solPrdChtCont">
    //       <span className="solPrdRightCht">1</span>
    //       <span className="solPrdRightCht">2</span>
    //       <span className="solPrdRightCht">3</span>
    //     </div>
    //   </div>
    //   <div className="solPrdChtRtDiv">
    //     <div className="solPrdChtHdr">EST 예측 그래프</div>
    //     <div className="solPrdChtCont">
    //       <span className="solPrdRightCht">4</span>
    //       <span className="solPrdRightCht">5</span>
    //       <span className="solPrdRightCht">6</span>
    //     </div>
    //   </div>
    // </div>
  );
};

export default SolarPredChart;
