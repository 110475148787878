import { useHistory } from 'react-router-dom';
import kendo from '@progress/kendo-ui'

const MeainWarnItem = ({dataItem}) => {
    // 히스토리 사용
    const history = useHistory();

    const dataTime =  dataItem.ocurDtm
    const time = kendo.toString( new Date(dataTime) , "HH:mm:ss" );

    const imgSrc = "/images/enso/icon_" + dataItem.ensoTypeCode + "_" + dataItem.meainStatCodeNo + "_Small.png";

    const meainWarnClick = (e) =>{
      if(e.target){
          // const userTerm = e.target.textContent;
          const cid = dataItem.cid;
          const ensoType = e.target.id;
          e.preventDefault();
          history.push("/analy/raw/log?cid="+cid+"&ensoType="+ensoType);
        }
    } 

    return (
        <>
            <div className="timeline">
                <div className="timeline_item">
                    <div className="timeline_dateBB operTime">
                        {time}
                    </div>
                    <div className="timeline_icon"></div>
                    <div className="timeline_content">
                        <span className="TcSpace">
                            <img src={imgSrc} alt={dataItem.meainStatTerm}/>
                            &nbsp;&nbsp;&nbsp;{dataItem.meainStatTerm}
                        </span>
                        {/* <span className="TcSpace">({dataItem.dataStatSctCodeVal})</span> */}
                        <span className="TcSpace">-</span>
                        <span className="TcSpace" onClick = {meainWarnClick} id= {dataItem.ensoTypeCode}>{dataItem.userTerm}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MeainWarnItem;