import React from "react";

// import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
// import { getToken } from "../../../../service/authService";
// import { Upload } from "@progress/kendo-react-upload";
// import { Window } from "@progress/kendo-react-dialogs";

const BizFileRgnIssued = ({
  onAddFile,
  onRemoveFile,
  onStatusChangeFile,
  fileState,
  windowVisible,
  uploadFileEvt,
  deleteFileEvt,
  file,
  downloadFileEvt,
  saveFileUrl,
  removeFileUrl,
  IntlProvider,
  LocalizationProvider,
  getToken,
  Upload,
  Window,
}) => {
  // 엑셀 샘플 다운로드
  const downloadSample = () => {
    const link = document.createElement("a");

    link.href = "/template/meain/issuedSample.hwp";
    link.download = "교부신청양식.hwp";
    document.body.appendChild(link);
    link.click();
  };

  let fileLinkBtn = {
    border: "none",
    background: "transparent",
    color: "#0082ff",
  }

  return (
    <>
      <div class="JinTabMo_con" id="JinTabModalBB_con">
        <div class="SubM_title">[ 첨부서류 ]</div>
        <div class="info_text">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; · <span className="red_cl">50MB이상의 파일은 첨부하실 수 없습니다.</span>(이미지파일은
          JPEG,JPG,GIF로 변환하여 첨부하셔야 합니다.)
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; · 첨부파일을 수정하시려면 반드시 삭제후 재등록 하셔야 합니다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span class="ble_cl">· 복수의 에너지원을 설치할 경우 동일 항목의 첨부서류는 하나의 파일로 압축해서 등록하세요.</span>
          <span style={{ float: "right", marginRight: "5px" }}>
            <button class="btn_download" onClick={downloadSample}>
              보조금 교부신청 서류양식
            </button>
          </span>
        </div>
        <div class="eer-Table-scr" style={{ height: "330px" }}>
          <table class="remcBB-Table eer-Table-striped">
            <colgroup>
              <col width="550" />
              <col width="250" />
              <col width="" />
              <col width="250" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>필수</th>
                <th>첨부파일</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>보조금 교부신청서</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.RI1 &&
                    <button name="rgnIssue" id="RI1" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.RI1.fileName}
                    </button>
                  }
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.RI1 ? "redBg" : "btn_pls"}
                    name="rgnIssue"
                    id="RI1"
                    onClick={file?.RI1 ? deleteFileEvt : uploadFileEvt}>
                    {file?.RI1 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>지방보조사업자 관리카드</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.RI2 &&
                    <button name="rgnIssue" id="RI2" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.RI2.fileName}
                    </button>
                  }
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.RI2 ? "redBg" : "btn_pls"}
                    name="rgnIssue"
                    id="RI2"
                    onClick={file?.RI2 ? deleteFileEvt : uploadFileEvt}>
                    {file?.RI2 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>보조사업 청렴이행 서약서</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.RI3 &&
                    <button name="rgnIssue" id="RI3" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.RI3.fileName}
                    </button>
                  }
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.RI3 ? "redBg" : "btn_pls"}
                    name="rgnIssue"
                    id="RI3"
                    onClick={file?.RI3 ? deleteFileEvt : uploadFileEvt}>
                    {file?.RI3 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr className="k-master-row k-alt">
                <td>설치예정장소</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.RI4 &&
                    <button name="rgnIssue" id="RI4" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.RI4.fileName}
                    </button>
                  }
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.RI4 ? "redBg" : "btn_pls"}
                    name="rgnIssue"
                    id="RI4"
                    onClick={file?.RI4 ? deleteFileEvt : uploadFileEvt}>
                    {file?.RI4 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
              <tr>
                <td>기타</td>
                <td class="center" style={{ textAlign: "center" }}>
                  Y
                </td>
                <td>
                  {file?.RI5 &&
                    <button name="rgnIssue" id="RI5" class="gree_cl" onClick={downloadFileEvt} style={fileLinkBtn}>
                      {file.RI5.fileName}
                    </button>
                  }
                </td>
                <td class="center" style={{ textAlign: "center" }}>
                  <button
                    className={file?.RI5 ? "redBg" : "btn_pls"}
                    name="rgnIssue"
                    id="RI5"
                    onClick={file?.RI5 ? deleteFileEvt : uploadFileEvt}>
                    {file?.RI5 ? "삭제" : "등록"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {windowVisible && (
        <Window
          title={"파일 등록"}
          onClose={uploadFileEvt}
          top={186}
          left={483}
          initialHeight={350}
          initialWidth={600}
          modal={true}
          resizable={false}
          doubleClickStageChange={false}
          draggable={false}>
          <div>
            {/* 윈도우 창 */}
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Upload
                  autoUpload={false}
                  batch={true}
                  multiple={false}
                  files={fileState.fileList}
                  defaultFiles={[]}
                  onAdd={onAddFile}
                  onRemove={onRemoveFile}
                  onStatusChange={onStatusChangeFile}
                  withCredentials={true}
                  removeVerb={"DELETE"}
                  restrictions={{
                    allowedExtensions: [
                      ".gif",
                      ".jpg",
                      ".png",
                      // ".xls",
                      // ".xlsx",
                      ".hwp",
                      ".doc",
                      ".pdf",
                      ".exe",
                      ".zip",
                    ],
                    maxFileSize: 51200000,
                  }}
                  saveMethod={"post"}
                  saveHeaders={{
                    "Content-Type": "multipart/form-data",
                    "X-AUTH-TOKEN": getToken(),
                  }}
                  saveUrl={saveFileUrl}
                  removeUrl={removeFileUrl}
                />
              </IntlProvider>
            </LocalizationProvider>
            {/* <div>
              <Button>저장</Button>
              <Button>취소</Button>
            </div> */}
          </div>
        </Window>
      )}
    </>
  );
};

export default BizFileRgnIssued;
