
import kendo from "@progress/kendo-ui";
import { downloadExcel } from "../../../com/downloadExcel";


// Dashboard
export const getMakrDashboard = async (conn, data) => {
  const response = await conn.get("stat/makr/dashboard", {
    params: {
      start: kendo.toString(data.start, "yyyyMMdd"),
      end: kendo.toString(data.end, "yyyyMMdd"),
      ensoTypeCodeNo: data.ensoTypeCodeNo,
      cityProvCode: data.cityProvCode,
      rgnCode: data.rgnCode
    }, 
  });
  return response;
};

// List
export const getMakrList = async (conn, data, paging) => {
    
  const take = paging.take
  const skip = paging.skip
  const page = paging.skip / paging.take + 1
  const pageSize = paging.take

  const response = await conn.get("stat/makr/list", {
    params: {
      start: kendo.toString(data.start, "yyyyMMdd"),
      end: kendo.toString(data.end, "yyyyMMdd"),
      ensoTypeCodeNo: data.ensoTypeCodeNo,
      cityProvCode: data.cityProvCode,
      rgnCode: data.rgnCode,
      take: take,
      skip: skip,
      page: page,
      pageSize: pageSize,
      firstDay : data.firstDay
    }, 
  });
  return response;
};

// Model
export const getMakrModel = async (conn, data) => {

  const response = await conn.get("stat/makr/model", {
    params: {
      start: kendo.toString(data.start, "yyyyMMdd"),
      end: kendo.toString(data.end, "yyyyMMdd"),
      ensoTypeCodeNo: data.ensoTypeCodeNo,
      cityProvCode: data.cityProvCode,
      rgnCode: data.rgnCode,
      makrSeriNo: data.makrSeriNo
    }, 
  });
  return response;
};

// 시간대별 Charts
export const getMakrCharts = async (conn, data) => {
  console.log("data = ", data);
  const response = await conn.get("stat/makr/charts", {
    params: {
      start: kendo.toString(data.start, "yyyyMMdd"),
      end: kendo.toString(data.end, "yyyyMMdd"),
      ensoTypeCodeNo: data.ensoTypeCodeNo,
      cityProvCode: data.cityProvCode,
      rgnCode: data.rgnCode,
      firstDay : data.firstDay
    }, 
  });
  return response;
};

// 이벤트 발생건수 Charts
export const getMakrEventCntCharts = async (conn, data) => {
  const response = await conn.get("stat/makr/eventCntCharts", {
    params: {
      start: kendo.toString(data.start, "yyyyMMdd"),
      end: kendo.toString(data.end, "yyyyMMdd"),
      ensoTypeCodeNo: data.ensoTypeCodeNo,
      cityProvCode: data.cityProvCode,
      rgnCode: data.rgnCode
    }, 
  });
  return response;
};

// 엑셀 다운로드
export const getExcelDownload = async( param, paging ) =>{
  const excelTitle = "경보-제조사별_" + kendo.toString(new Date(), "yyyyMMdd") + ".xlsx";
  const url = "/api/v2.2/stat/makr";

  // 조건 검색 객체
  const search = {
    start: kendo.toString(param.start, "yyyyMMdd"),
    end: kendo.toString(param.end, "yyyyMMdd"),
    ensoTypeCodeNo: param.ensoTypeCodeNo,
    cityProvCode: param.cityProvCode,
    rgnCode: param.rgnCode,
    firstDay : param.firstDay
  }
  console.log("search = ", search);
  
  try {
    downloadExcel( search , excelTitle , url );
  } catch (error) {
    kendo.alert("엑셀 다운로드를 실패 하였습니다.");
    return;
  }
}