import styles from "./installerMeain_list.module.css";
// import styles from "./installerMeain_list.dk.module.css";

import { ListView } from "@progress/kendo-react-listview";
import { Pager } from "@progress/kendo-react-data-tools";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import koMessage from "../../../com/ko-language.json";

import InstallerMeainItem from "./installerMeainItem";
loadMessages(koMessage, "ko-KR");

const InstallerMeainList = ({ search, setInstallerMeainList, page }) => {
  const setPage = e => {
    page.skip = e.skip;
    page.take = e.take;
    setInstallerMeainList(search, page);
  };
  return (
    <>
      <div className="uk-grid">
        {page.count !== 0 ? (
          <ListView className={styles.installerMeainList} data={page.list} item={InstallerMeainItem} />
        ) : (
          <span className={styles.noneData}> 데이터가 존재하지 않습니다. </span>
        )}
      </div>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <div className="installerMeainListPager">
            {page.count !== 0 ? (
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko">
                  <Pager skip={page.skip} take={page.take} onPageChange={setPage} previousNext={true} total={page.count} />
                </IntlProvider>
              </LocalizationProvider>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InstallerMeainList;
