// React
import React from "react";
import { useEffect } from "react";

import { numFormat, roundNum } from "../../../com/dataUtil";

// Css
import "./munic.css";

const MunicStat = ({ dataSource }) => {
  useEffect(() => {}, []);

  let etcAsyncCount = 0;
  let etcAsyncInstCapa = 0;
  let etcSyncCount = 0;
  let etcSyncInstCapa = 0;
  let totalSyncCount = 0;
  let totalAsyncCount = 0;
  let totalSyncInstCapa = 0;
  let totalHeatSyncInstCapa = 0;
  let totalAsyncInstCapa = 0;
  let totalHeatAsyncInstCapa = 0;

  return (
    <div className="R_box">
      <div className="energy-tb2">
        <div className="R_title">에너지원별 통계</div>
        <table>
          {/* <caption>에너지원별 통계</caption> */}
          <thead>
            <tr>
              <th>에너지원</th>
              <th colSpan="2">개수</th>
              <th colSpan="2">
                용량(MW/m<sup>2</sup>)
              </th>
            </tr>
          </thead>
          {dataSource.ensoMeainCntList.length > 0 ? (
            <>
              <tbody>
                {dataSource.ensoMeainCntList.map(function (result, index) {
                  totalSyncCount += dataSource.ensoMeainCntList[index].syncCount;
                  totalAsyncCount += dataSource.ensoMeainCntList[index].asyncCount;
                  if (result.ensoTypeCode === 15001) {
                    totalSyncInstCapa += dataSource.ensoMeainCntList[index].syncInstCapa;
                    totalAsyncInstCapa += dataSource.ensoMeainCntList[index].asyncInstCapa;
                    return (
                      <tr>
                        <th>태양광</th>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].asyncCount, 0))}</td>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].syncCount, 0))}</td>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].asyncInstCapa / 1000, 1))} MW</td>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].syncInstCapa / 1000, 1))} MW</td>
                      </tr>
                    );
                  } else if (result.ensoTypeCode === 15002) {
                    totalHeatSyncInstCapa += dataSource.ensoMeainCntList[index].syncInstCapa;
                    totalHeatAsyncInstCapa += dataSource.ensoMeainCntList[index].asyncInstCapa;
                    return (
                      <tr>
                        <th>태양열</th>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].asyncCount, 0))}</td>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].syncCount, 0))}</td>
                        <td>
                          {numFormat(roundNum(dataSource.ensoMeainCntList[index].asyncInstCapa, 1))} m<sup>2</sup>
                        </td>
                        <td>
                          {numFormat(roundNum(dataSource.ensoMeainCntList[index].syncInstCapa, 1))} m<sup>2</sup>
                        </td>
                      </tr>
                    );
                  } else if (result.ensoTypeCode === 15003) {
                    totalSyncInstCapa += dataSource.ensoMeainCntList[index].syncInstCapa;
                    totalAsyncInstCapa += dataSource.ensoMeainCntList[index].asyncInstCapa;
                    return (
                      <tr>
                        <th>지열</th>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].asyncCount, 0))}</td>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].syncCount, 0))}</td>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].asyncInstCapa / 1000, 1))} MW</td>
                        <td>{numFormat(roundNum(dataSource.ensoMeainCntList[index].syncInstCapa / 1000, 1))} MW</td>
                      </tr>
                    );
                  } else {
                    totalSyncInstCapa += dataSource.ensoMeainCntList[index].syncInstCapa;
                    totalAsyncInstCapa += dataSource.ensoMeainCntList[index].asyncInstCapa;

                    etcAsyncCount += dataSource.ensoMeainCntList[index].asyncCount;
                    etcSyncCount += dataSource.ensoMeainCntList[index].syncCount;
                    etcAsyncInstCapa += dataSource.ensoMeainCntList[index].asyncInstCapa;
                    etcSyncInstCapa += dataSource.ensoMeainCntList[index].syncInstCapa;
                    return(<></>);
                  }
                })}
                <tr>
                  <th>기타</th>
                  <td>{numFormat(roundNum(etcAsyncCount, 0))}</td>
                  <td>{numFormat(roundNum(etcSyncCount, 0))}</td>
                  <td>{numFormat(roundNum(etcAsyncInstCapa / 1000, 1))} MW</td>
                  <td>{numFormat(roundNum(etcSyncInstCapa / 1000, 1))} MW</td>
                </tr>
                <tr>
                  <th>소계</th>
                  <td>{numFormat(roundNum(totalAsyncCount, 0))}</td>
                  <td>{numFormat(roundNum(totalSyncCount, 0))}</td>
                  <td>
                    {numFormat(roundNum(totalAsyncInstCapa / 1000, 1))} MW
                    <br /> {numFormat(roundNum(totalHeatAsyncInstCapa, 1))} m<sup>2</sup>
                  </td>
                  <td>
                    {numFormat(roundNum(totalSyncInstCapa / 1000, 1))} MW
                    <br /> {numFormat(roundNum(totalHeatSyncInstCapa, 1))} m<sup>2</sup>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>총계</th>
                  <td colSpan={2}>{numFormat(roundNum(totalAsyncCount + totalSyncCount, 0))}</td>
                  <td colSpan={2}>
                    {numFormat(roundNum((totalAsyncInstCapa + totalSyncInstCapa) / 1000, 1))} MW /{" "}
                    {numFormat(roundNum(totalHeatAsyncInstCapa + totalHeatSyncInstCapa, 1))} m<sup>2</sup>
                  </td>
                </tr>
              </tfoot>
            </>
          ) : (
            <>
              <tbody>
                <tr>
                  <th>태양광</th>
                  <td>-</td>
                  <td>-</td>
                  <td>- MW</td>
                  <td>- MW</td>
                </tr>
                <tr>
                  <th>태양열</th>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    - m<sup>2</sup>
                  </td>
                  <td>
                    - m<sup>2</sup>
                  </td>
                </tr>
                <tr>
                  <th>지열</th>
                  <td>-</td>
                  <td>-</td>
                  <td>- MW</td>
                  <td>- MW</td>
                </tr>
                <tr>
                  <th>기타</th>
                  <td>-</td>
                  <td>-</td>
                  <td>- MW</td>
                  <td>- MW</td>
                </tr>
                <tr>
                  <th>소계</th>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    - MW
                    <br /> - m<sup>2</sup>
                  </td>
                  <td>
                    - MW
                    <br /> - m<sup>2</sup>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>총계</th>
                  <td colSpan={2}>-</td>
                  <td colSpan={2}>
                    - MW / - m<sup>2</sup>
                  </td>
                </tr>
              </tfoot>
            </>
          )}
        </table>
        {/* </div> */}
      </div>
    </div>
    // <div className="R_box">
    //   <div className="R_title">에너지원별 통계</div>
    //   <div className="R_con flexBox">
    //     <div className="dot-chart-a">
    //       <table>
    //         <tr>
    //           {/* <th className="Circle-notwork"><i className="fa-sharp fa-solid fa-circle"></i></th> */}
    //           <th>-</th>
    //           <td>태양광</td>
    //           <td>2,491 개</td>
    //         </tr>
    //         <tr>
    //           {/* <th className="Circle-notwork"><i className="fa-sharp fa-solid fa-circle"></i></th> */}
    //           <th>-</th>
    //           <td>태양열</td>
    //           <td>170 개</td>
    //         </tr>
    //         <tr>
    //           {/* <th className="Circle-notwork"><i className="fa-sharp fa-solid fa-circle"></i></th> */}
    //           <th>-</th>
    //           <td>지열</td>
    //           <td>13 개</td>
    //         </tr>
    //         <tr>
    //           {/* <th className="Circle-notwork"><i className="fa-sharp fa-solid fa-circle"></i></th> */}
    //           <th>-</th>
    //           <td>풍력</td>
    //           <td>4 개</td>
    //         </tr>
    //         <tr>
    //           {/* <th className="Circle-notwork"><i className="fa-sharp fa-solid fa-circle"></i></th> */}
    //           <th>-</th>
    //           <td>연료전지</td>
    //           <td>124 개</td>
    //         </tr>
    //         <tr>
    //           {/* <th className="Circle-notwork"><i className="fa-sharp fa-solid fa-circle"></i></th> */}
    //           <th>-</th>
    //           <td>ESS</td>
    //           <td>124 개</td>
    //         </tr>
    //       </table>
    //     </div>
    //     <div className="dot-chart-b">
    //       {/* <img src="/images/chart_sample.png" /> */}
    //     </div>
    //   </div>
    // </div>
  );
};

export default MunicStat;
