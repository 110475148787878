// React
import React from "react";

// Css
import "./localAreaMonitor.css";
import LocalAreaMonitorStatInfo from "./localAreaMonitorStatInfo";

/**
 * 오른쪽 상단 "사용량 상위 에너지원별 통계" 컴포넌트
 * @param {styles, ensoMeainData , CountUp} param0 
 * @returns 
 */
const LocalAreaMonitorStat = ({ styles, ensoMeainData , CountUp }) => {
  /**
   * 마우스 영역 진입 이벤트
   * @param {*} ensoTypeCode 
   */
  const onMouseOverEvt = () => {
    const localAreaEnsoStatArea = document.getElementById("localAreaEnsoStatArea");
    const localAreaEnsoStatInfo = document.getElementById("localAreaEnsoStatInfo");
    localAreaEnsoStatInfo.style.display = "block";
    localAreaEnsoStatInfo.style.left = localAreaEnsoStatArea.style.left + 1200 + "px";
    localAreaEnsoStatInfo.style.top = localAreaEnsoStatArea.style.top + 180 +  "px";
  }

  /**
   * 마우스 영역 아웃 이벤트
   * @param {*} ensoTypeCode 
   */
  const mouseOutEvt = () => {
    const localAreaEnsoStatInfo = document.getElementById("localAreaEnsoStatInfo");
    localAreaEnsoStatInfo.style.display = "none";
  }

  return (
    <div className={styles.rightBox} id = "localAreaEnsoStatArea" onMouseOver={ () => {onMouseOverEvt()} } onMouseOut={() => {mouseOutEvt()}}>
      <table className={styles.boxContents}>
          <caption>
              <span>사용량 상위 에너지원별 통계</span>
          </caption>
          <colgroup>
              <col width="50px" />
              <col width="" />
              <col width="" />
              <col width="" />
              <col width="" />
              <col width="" />
              <col width="" />
              <col width="" />
          </colgroup>
          <thead>
              <tr>
                  <th></th>
                  <th>구분</th>
                  <th colspan="2">개수</th>
                  <th colspan="2">용량</th>
                  <th colspan="2">발전/사용량</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <th><img src="/images/localareaImg/42770/icon_50x45_01.png" alt = "태양광" /></th>
                  <th>태양광</th>
                  <td><CountUp end={ensoMeainData.sola.cnt} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></td>
                  <td>개</td>
                  <td><CountUp end={ensoMeainData.sola.instCapa} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></td>
                  <td>MW</td>
                  <td><CountUp end={ensoMeainData.sola.power} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></td>
                  <td>MWh</td>
              </tr>
              <tr>
                  <th><img src="/images/localareaImg/42770/icon_50x45_02.png" alt = "태양열" /></th>
                  <th>태양열</th>
                  <td><CountUp end={ensoMeainData.heat.cnt} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></td>
                  <td>개</td>
                  <td><CountUp end={ensoMeainData.heat.instCapa} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></td>
                  <td>km<sup>2</sup></td>
                  <td><CountUp end={ensoMeainData.heat.use} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></td>
                  <td>MWh</td>
              </tr>
              <tr>
                  <th><img src="/images/localareaImg/42770/icon_50x45_03.png" alt = "지열" /></th>
                  <th>지열</th>
                  <td><CountUp end={ensoMeainData.heath.cnt} separator="," duration={1.5} decimals={0} delay={2} redraw={true} /></td>
                  <td>개</td>
                  <td><CountUp end={ensoMeainData.heath.instCapa} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></td>
                  <td>MW</td>
                  <td><CountUp end={ensoMeainData.heath.use} separator="," duration={1.5} decimals={1} delay={2} redraw={true} /></td>
                  <td>MWh</td>
              </tr>
          </tbody>
      </table>
      <LocalAreaMonitorStatInfo styles={styles} ensoMeainData = {ensoMeainData} />
  </div>
  );
};

export default LocalAreaMonitorStat;
