
// 설비 운영현황 
const MeainCount = ({statCnt}) => {

  return(
    <div>
      <div className="ChMapTb-A">
        <table>
          <tbody>
            <tr>
              <th>검색설비 운영현황({statCnt.totalCnt})</th>
            </tr>
            <tr>
              <td className="textGre">&nbsp;&nbsp;정&nbsp;&nbsp;&nbsp;&nbsp;상&nbsp;({statCnt.normalCnt })</td>
            </tr>
            <tr>
              <td className="textGry">&nbsp;&nbsp;미작동&nbsp;({statCnt.unOperCnt})</td>
            </tr>
            <tr>
              <td className="textBl">&nbsp;&nbsp;준비중&nbsp;({statCnt.readyCnt})</td>
            </tr>
            <tr>
              <td className="textYl">&nbsp;&nbsp;경&nbsp;&nbsp;&nbsp;&nbsp;고&nbsp;({statCnt.warnCnt})</td>
            </tr>
            <tr>
              <td className="textRd">&nbsp;&nbsp;고&nbsp;&nbsp;&nbsp;&nbsp;장&nbsp;({statCnt.errorCnt})</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="ChMapTb-B">
        <table>
          <tbody>
            <tr>
              <th>지역별 가동현황</th>
            </tr>
            <tr>
              <td><img src="/images/iconGr_02.png" alt="20"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#60;&nbsp;&nbsp;20 %</td>
            </tr>
            <tr>
              <td><img src="/images/iconGr_04.png" alt="60"/>&nbsp;&nbsp;40 ~ 60 %</td>
            </tr>
            <tr>
              <td><img src="/images/iconGr_05.png" alt="80"/>&nbsp;&nbsp;60 ~ 80 %</td>
            </tr>
            <tr>
              <td><img src="/images/iconGr_06.png" alt="100"/>&nbsp;&nbsp;80 ~ 100 %</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MeainCount;