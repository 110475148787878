// React 
import React, { useState } from 'react';

// Css
import "./smpHist.css";

// Service
import DatePickerKo from '../../com/dateInputs/datePickerKo';

const SmpSearch = ({
  conn , styles
  , DropDownList ,RadioButton ,Button ,kendo ,search ,setSearch
  , getSmpHistListData, setLoading
}) => {

  console.log("search = ", search);

  // const loginUserTypeCode = getUserTypeCode();

  const areaCdList = [{ codeNo : 1 , codeVal : '육지' }, { codeNo : 9 , codeVal : '제주' }]
  
  const [ area, setArea ] = useState({ codeNo: "", codeVal: "전체" })

  const [ disable , setDisable ] = useState(false)

  // 드롭다운 이벤트
  const onchange = (e) => {
    console.log("e = ", e);
    if(e.target.name === 'areaCd'){
      setSearch ( search => {
        const item = {...search};
        item.areaCd = e.value.codeNo;
        return item;
      } );

      setArea(e.target.value);
    }
  }

  // 라디오 버튼 이벤트
  const radioChange = (e) => {
    console.log(" radioChange e = ",  e);
    areaCdList.codeNo = "";
    areaCdList.codeVal = "전체";
    if (e.value === "DD" || e.value === "MM") {
      setDisable(true)

      setSearch ( search => {
        const item = {...search};
        item.areaCd = null;
        return item;
      } );
      
      setArea({ codeNo: "", codeVal: "전체" })
    } else {
      setDisable(false)
    }

    setSearch ( search => {
      const item = {...search};
      item.radioStatus = e.value;
      return item;
    } );
  }

  // 조회 이벤트
  const clickSearchEvt = () => {
    search.isSearch = true;
    // console.log("클릭 이벤트");
    console.log("search = ", search);
    // const isValid = validCheck();
    // if( isValid.isValid === false ){
    //   kendo.alert( isValid.message );
    // }
    search.radio = search.radioStatus;
    getSmpHistListData();
  }

  // Validaiont 체크
  // const validCheck = () => {
  //   let message = "";
  //   let isValid = true;
  //
  //   if( search.areaCd !== 1 && search.areaCd !== 0 ){
  //     message = "지역을 선택 하셔야 합니다.";
  //     isValid = false;
  //   }
  //
  //   return { message : message , isValid : isValid }
  // }


  return (
    <div className= {  [ styles.marginTop , "uk-grid" ].join(' ') } >
      <div className="uk-width-medium-1-1">
        <div className= {  [ styles.cardStyle , "md-card" ].join(' ') } >
          <div className="md-card-content small-padding">
            <span className="searchSubTitleA">지역</span>
            <DropDownList
              className={styles.smpAreaDropdown}
              id="areaCd"
              name="areaCd"
              data={areaCdList}
              defaultItem={{ codeNo: "", codeVal: "전체" }}
              dataItemKey="codeNo"
              textField="codeVal"
              onChange={onchange}
              disabled={disable}
              value={area}
            />
            <span class="searchSubTitleA">조회 기간</span>
            <span className={styles.radioForm}>
              <RadioButton
                name="HH"
                value="HH"
                label=""
                className={styles.smpHistradio}
                checked={search.radioStatus === "HH"}
                onChange={radioChange}
              />
              <label>
                <span></span>시간별
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="DD"
                value="DD"
                label=""
                className={styles.smpHistradio}
                checked={search.radioStatus === "DD"}
                onChange={radioChange}
              />
              <label>
                <span></span>일별
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="MM"
                value="MM"
                label=""
                className={styles.smpHistradio}
                checked={search.radioStatus === "MM"}
                onChange={radioChange}
              />
              <label>
                <span></span>월별
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <DatePickerKo
              id="start"
              name="start"
              className={styles.smpDatePicker}
              format={search.radioStatus === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
              setDtm={(v) => {
                setSearch( search => {
                  const item = {...search};
                  item.start = v;
                  item.endMin = v;
                  return item;
                });
              }}
              value={search.start}
              readonly={true}
              max={search.startMax}
              view= {search.radioStatus !== "MM" ? null : "year" }
            />
            &nbsp;&nbsp;~&nbsp;&nbsp;
            <DatePickerKo
              id="end"
              name="end"
              className={styles.smpDatePicker}
              format={search.radioStatus === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
              setDtm={(v) => {
                setSearch( search => {
                  const item = {...search};
                  item.end = v;
                  item.startMax = v;
                  return item;
                });
              }}
              value={search.end}
              readonly={true}
              min={search.endMin}
              max={search.endMax}
              view= {search.radioStatus !== "MM" ? null : "year" }
            />
            &nbsp;&nbsp;&nbsp;
            <Button
              className="inqBtn"
              onClick={clickSearchEvt}
              icon="search"
            ></Button>
           
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default SmpSearch;