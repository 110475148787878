import { useState } from "react";
import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { BeatLoader } from "react-spinners";
import PostPowerGrid from "./postPowerGrid";

import styles from "./postPower.module.css";
import "./postPower.css";
import { getUser } from "../../../service/authService";

import { postPowerList } from "../../../service/ai/postPower/postPowerService";
function PostPower({ conn, cityList }) {
    // 로딩 스피너
    const [loading, setLoading] = useState(false);

    // 유저정보
    const userInfo = getUser();

    // 검색 조건
    const [search, setSearch] = useState({
        cityProvCode: "",
        city: { cityProvCode: "", cityProvTerm: "시도" },
        cid: "",
    });
    const citys = cityList;

    const [dataSource, setDataSource] = useState({
        infoTable: [],
        chart: [],
        grid: [],
    })

    const today = kendo.toString(new Date(Date.parse(new Date())), 'yyyy-MM-dd');
    // const today = kendo.toString(new Date(Date.parse(new Date("2022-09-01"))), 'yyyy-MM-dd');

    // 드롭다운 이벤트
    const onChangeCity = e => {
        setSearch(search => {
            const item = { ...search };
            item.city = e.target.value;
            item.cityProvCode = e.target.value.cityProvCode;
            return item;
        });
    };

    // const onChangeCid = (e) => {
    //     setSearch(search => {
    //         const item = { ...search };
    //         item.cid = e.target.value;
    //         return item;
    //     });
    // }

    const getPostPowerData = () => {
        if (!search.cityProvCode) {
            kendo.alert("시도를 선택 해주세요.");
            return;
        }
        // if (!search.cid) {
        //     kendo.alert("CID를 입력해주세요.");
        //     return;
        // }
        postPowerList(conn, getResult, getRejection, search, setLoading);
    };

    // 결과 데이터
    const getResult = (gridList, chartData, infoTableData) => {
        setDataSource(dataSource => {
            const item = { ...dataSource };
            item.infoTable = infoTableData;
            item.grid = gridList;
            item.chart = chartData;
            return item;
        });
        setLoading(false);
    };

    // rejection
    const getRejection = error => {
        if (error.errCode === "F011") {
            kendo.alert("필수 입력 값이 입력되지 않았습니다. 다시 한번 입력 또는 선택 해주세요.");
            return;
        } else {
            console.log("error = ", error);
            kendo.alert("시스템에 문제가 발생 하였습니다.");
            return;
        }
    };

    return (
        <div className={["uk-grid", "postPowerBackgroundWhite", loading ? styles.postPowerG : ""].join(" ")}>
            <div className="uk-width-medium-1-1">
                <div className="md-card">
                    <div className="md-card-content small-padding">
                        <b>&nbsp;&nbsp;지역&nbsp;&nbsp;</b>
                        <DropDownList
                            className={styles.LocationDropDownRgn}
                            data={citys}
                            textField="cityProvTerm"
                            dataItemKey="cityProvCode"
                            name="city"
                            defaultItem={{ cityProvCode: "", cityProvTerm: "시도" }}
                            onChange={onChangeCity}
                            value={search.city}
                        />
                        {/* <b>&nbsp;&nbsp;수용가&nbsp;&nbsp;&nbsp;</b>
                        <input
                            id="userTerm"
                            type="text"
                            className="searchTerm"
                            placeholder="CID명을 입력하세요"
                            value={search.cid}
                            onChange={onChangeCid}
                            autoComplete='off'
                        /> */}
                        &nbsp;
                        <Button className="inqBtn" icon="search" onClick={getPostPowerData}>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="uk-width-medium-1-1">
                &nbsp;
            </div>
            <div className="uk-width-medium-1-1">
                <div className="md-card">
                    <div>
                        <table className="infoTable">
                            <tr>
                                <th>
                                    <div>설비용량<small>(기준일: {today})</small></div>
                                </th>
                                <td>
                                    <b>{dataSource.infoTable[0]?.instCapa != null ? dataSource.infoTable[0]?.instCapa : "-"}</b>
                                    <span>kW</span>
                                </td>
                                <th>
                                    <div>금일발전량</div>
                                </th>
                                <td>
                                    <b>{dataSource.infoTable[0]?.dayPower != null ? dataSource.infoTable[0]?.dayPower : "-"}</b>
                                    <span>kWh</span>
                                </td>
                                <th>
                                    <div>누적발전량</div>
                                </th>
                                <td>
                                    <b>{dataSource.infoTable[0]?.accumPower != null ? dataSource.infoTable[0]?.accumPower : "-"}</b>
                                    <span>MWh</span>
                                </td>
                                <th>
                                    <div>SMP환산금액<small>(육지 SMP기준)</small></div>
                                </th>
                                <td>
                                    <b>{dataSource.infoTable[0]?.smpAmount != null ? dataSource.infoTable[0]?.smpAmount : "-"}</b>
                                    <span>천원</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div className="uk-width-medium-1-1">
                <PostPowerGrid
                    search={search}
                    dataSourceChart={dataSource.chart}
                    dataSourceGrid={dataSource.grid}
                    setDataSource={setDataSource}
                    userInfo={userInfo}
                    loading={loading}
                    styles={styles}
                />
            </div>
            {loading && (
                <p className={styles.postPowerLoading}>
                    <BeatLoader loading={true} size={24} color="#1e88e5" />
                </p>
            )}
        </div>
    );
}

export default PostPower;