import React, { useState, useRef } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";
import { yearList } from "../../../../com/dropDownDs.js";
import DatePickerKo from "../../../com/dateInputs/datePickerKo";
import { getLastMonthDay } from "../../../../com/dateUtil.js";

const EnsoSearch = ({ setSearch, codeList , tabStat }) => {
  // 에너지원 드롭다운
  const ensoTypeCodeNo = useRef(15001);
  // 건물용도
  const bldSrvCodeNo = useRef("");
  // 사업구분
  const bizSct = useRef("");
  // 사업연도
  const bizYy = useRef(kendo.toString(new Date() , 'yyyy'));

  // 에너지원 데이터 소스
  const ensoList = codeList.filter((c) => c.grpCodeNo === 15 && c.codeNo !== 15007);
  // 날짜 검색 데이터 소스
  // const dateList = codeList.filter(
  //   (c) => c.grpCodeNo === 23 && c.codeNo !== 23001
  // );
  // 사업구분 데이터 소스
  const bizSctList = codeList.filter((c) => c.grpCodeNo === 34);
  //건물용도별 데이터 소스
  const bldSrvList = codeList.filter((c) => c.grpCodeNo === 31);

  // 체크 박스
  const [selectedValue, setSelectedValue] = useState("DD");

  // 어제 날짜 정의
  const today = new Date();
  const yesterday = new Date();
  const lastWeek = new Date();
  const lastMonth = new Date();
  const lastYear = new Date();
  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(yesterday.getDate() - 7);
  lastMonth.setDate(yesterday.getDate() - 30);
  lastYear.setDate(yesterday.getDate() - 365);

  // 날짜
  const [start, setStart] = useState(new Date(yesterday.getFullYear(), yesterday.getMonth(), 1));
  const [end, setEnd] = useState(yesterday);
  const [startMax, /*setStartMax*/] = useState(yesterday);
  const [endMin, setEndMin] = useState(yesterday);
  const [endMax, setEndMax] = useState(getLastMonthDay(yesterday));

  // 라디오 버튼 이벤트
  const radioChange = (e) => {
    setSelectedValue(e.value);
  };

  // 드롭다운 체인지 이벤트
  const onchange = (e) => {
    if (e.target.name === "ensoTypeDropDown") {
      ensoTypeCodeNo.current = e.value.codeNo;
    } else if (e.target.name === "bldSrvCodeNo") {
      bldSrvCodeNo.current = e.value.codeNo;
    } else if (e.target.name === "bizSct") {
      bizSct.current = e.value.codeNo;
    } else if (e.target.name === "bizYy") {
      console.log("e.target.value = ", e.target.value);
      // if (e.target.value === "사업연도") {
        // bizYy.current = "";
      // } else {
        bizYy.current = e.target.value;
      // }
    }
  };

  // 조회 버튼 이벤트
  const clickInqBtn = (e) => {

    if( !bizYy.current ){
      kendo.alert("사업연도를 입력 해주세요.");
      return;
    }

    let startDay = kendo.toString(start, "yyyy-MM-dd");
    let endDay = kendo.toString(end, "yyyy-MM-dd");

    // 시간별일 경우 시작일 과 마지막일은 같다.
    if (selectedValue === "HH") {
      startDay = kendo.toString(start, "yyyy-MM-dd");
      endDay = kendo.toString(start, "yyyy-MM-dd");
    }
    // 월별일 경우 시작일은 1일 마지막일은 마지막 날짜로 한다.
    else if (selectedValue === "MM") {
      startDay = kendo.toString(start, "yyyy-MM-01");
      endDay = kendo.toString(start, "yyyy-MM-dd");
      // 마지막 날짜 설정
      endDay = kendo.toString( getLastMonthDay(endDay) , "yyyy-MM-dd");
    } else {
      startDay = kendo.toString(start, "yyyy-MM-dd");
      endDay = kendo.toString(end, "yyyy-MM-dd");
    }

    // 탭 초기화 
    tabStat.state = 0; 
    // 조회 값 설정
    setSearch(search => {
      const item = {...search};
      item.ensoTypeCodeNo = ensoTypeCodeNo.current;
      item.bldSrvCodeNo = bldSrvCodeNo.current;
      item.bizSct = bizSct.current;
      // item.bizYy = bizYy.current;
      item.unit = selectedValue;
      item.start = startDay;
      item.end = endDay;
      return item;
    })
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="md-card TBgA">
          <div className="md-card-content small-padding">
            <span className="searchSubTitleA">에너지원</span>
            <DropDownList
              data={ensoList}
              defaultValue={{ codeVal: "태양광", codeNo: 15001 }}
              name="ensoTypeDropDown"
              textField="codeVal"
              dataItemKey="codeNo"
              onChange={onchange}
              className="searchArea"
            />
            <DropDownList
              className="ensoDropDown bizSctCodeNoDropDown"
              id="bizSct"
              name="bizSct"
              data={bizSctList}
              defaultItem={{ codeNo: "", codeVal: "사업구분" }}
              dataItemKey="codeNo"
              textField="codeVal"
              onChange={onchange}
            />
            <DropDownList
              className="ensoDropDown"
              id="bldSrvCodeNo"
              name="bldSrvCodeNo"
              data={bldSrvList}
              defaultItem={{ codeNo: "", codeVal: "건물용도" }}
              dataItemKey="codeNo"
              textField="codeVal"
              onChange={onchange}
            />
            <span className="radioForm">
              <RadioButton
                name="cc"
                value="HH"
                label=""
                className="radioBtn"
                checked={selectedValue === "HH"}
                onChange={radioChange}
              />
              <label>
                <span></span>시간별
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="cc"
                value="DD"
                label=""
                className="radioBtn"
                checked={selectedValue === "DD"}
                onChange={radioChange}
              />
              <label>
                <span></span>일별
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <RadioButton
                name="cc"
                value="MM"
                label=""
                className="radioBtn"
                checked={selectedValue === "MM"}
                onChange={radioChange}
              />
              <label>
                <span></span>월별
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span className="searchSubTitleA">설비날짜</span>
            <DatePickerKo
              id="start"
              name="start"
              className="datePicker"
              defaultValue={yesterday}
              format={selectedValue === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
              setDtm={(v) => {
                setEnd(v);
                setStart(v);
                setEndMin(v);
                // 시작 날짜 선택 시 마지막 날짜 MAX 값 을 해당 월의 마지막 일로 설정
                setEndMax( getLastMonthDay(v) );
              }}
              value={start}
              readonly={true}
              max={startMax}
              view= {selectedValue !== "MM" ? null : "year" }
            />
            {selectedValue !== "HH" && selectedValue !== "MM" && (
              <span className="textColorGr datePeriodIcon ">~</span>
            )}
            {selectedValue !== "HH" && selectedValue !== "MM" && (
              <DatePickerKo
                id="end"
                name="end"
                className="datePicker"
                defaultValue={yesterday}
                format={selectedValue === "MM" ? "yyyy-MM" : "yyyy-MM-dd"}
                setDtm={(v) => {
                  // console.log("v = ", v)
                  // end.current = v;
                  setEnd(v);
                }}
                min={endMin}
                max={endMax}
                readonly={true}
                value = {end}
              />
            )}
            <Button
              className="inqBtn"
              onClick={clickInqBtn}
              icon="search"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnsoSearch;
