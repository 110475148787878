import React, { useEffect, useState } from 'react';

/**
 * 융복합지원사업 - 신정차 및 설비 관리정보 - 태양광 정보
*/
const BizManagementMeainSola = ({
  Input, convMngtStyles, DropDownList, meainInfo , initialDs, DatePickerKo, DateTimePickerKo, setMeainInfo
  , onchageDate, onChangeDateTiem, dateFormat, onchageInput
  , searchKeyWord ,makrMeainModlfilterChange
}) => {

  // 제조사 / 모델 데이터 소스
  const makrList = initialDs.makrList.length > 0 ? initialDs.makrList : [];
  const meainModelList = initialDs.meainModelList.length > 0 ? initialDs.meainModelList : []; 

  // 모듈 제조사 목록
  const modulMakrDs = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30001 && c.makrTerm.includes(searchKeyWord.moduleMakrKey) ) : [];
  // 모듈 타입 목록
  const modulTypeDs = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15001 && c.makrSeriNo === meainInfo.modulMakrNo && c.meainTypeTerm.includes(searchKeyWord.moduleModlKey) ) : [];
  // 인버터 제조사 목록
  const invtMakrDs = makrList.length !== 0 ? makrList.filter(c => c.makrClsfNo === 30002 && c.makrTerm.includes(searchKeyWord.invtMakrKey) ) : [];
  // 인버터 타입 목록
  const invtTypeDs = meainModelList.length !== 0 ? meainModelList.filter(c=> c.ensoTypeCodeNo === 15001 && c.makrSeriNo === meainInfo.invtMakrNo && c.meainTypeTerm.includes(searchKeyWord.invtModlKey) ) : [];

  const [modulMakr , setModulMakr] = useState( meainInfo.modulMakrNo ? { makrSeriNo : meainInfo.modulMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : '-' } );
  const [modulType , setModulType] = useState( meainInfo.modulTypeNo ? { meainTypeSeriNo : meainInfo.modulTypeNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );
  const [invtMakr , setInvtMakr] = useState( meainInfo.invtMakrNo ? { makrSeriNo : meainInfo.invtMakrNo , makrTerm : "" } : { makrSeriNo : '' , makrTerm : "-" } );
  const [invtType , setInvtType] = useState( meainInfo.invtTypeNo ? { meainTypeSeriNo : meainInfo.invtTypeNo , meainTypeTerm : "" } : { meainTypeSeriNo : '' , meainTypeTerm : '' } );

  // 제조사 Converter
  const makrConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.modulMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        modulMakrDs.forEach(element => {
            if( element.makrSeriNo === meainInfo.modulMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setModulMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setModulMakr({ makrSeriNo: '' , makrTerm: '-' });
      }
      if( meainInfo.invtMakrNo ){
        let makrSeriNo = "";
        let makrTerm = "";
        invtMakrDs.forEach(element => {
            if( element.makrSeriNo === meainInfo.invtMakrNo ){
                makrSeriNo = element.makrSeriNo;
                makrTerm = element.makrTerm;
            }
        });
        setInvtMakr({ makrSeriNo: makrSeriNo, makrTerm: makrTerm });
      }else{
        setInvtMakr({ makrSeriNo: '', makrTerm: '-' });
      }
    }else{
      setModulMakr({ makrSeriNo: '' , makrTerm: '-' });
      setInvtMakr({ makrSeriNo: '', makrTerm: '-' });
    }
  }

  // 모델 Converter
  const modlConverter = () => {
    if( Object.keys(meainInfo).length !== 0 ){
      if( meainInfo.modulTypeNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        modulTypeDs.forEach(element => {
            if( element.meainTypeSeriNo === meainInfo.modulTypeNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setModulType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setModulType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      }
      if( meainInfo.meainTypeSeriNo ){
        let meainTypeSeriNo = "";
        let meainTypeTerm = "";
        invtTypeDs.forEach(element => {
            if( element.meainTypeSeriNo === meainInfo.invtTypeNo ){
                meainTypeSeriNo = element.meainTypeSeriNo;
                meainTypeTerm = element.meainTypeTerm;
            }
        });
        setInvtType({ meainTypeSeriNo: meainTypeSeriNo, meainTypeTerm: meainTypeTerm });
      }else{
        setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
      }
    }else{
      setModulType({ meainTypeSeriNo: '', meainTypeTerm: '' });
      setInvtType({ meainTypeSeriNo: "", meainTypeTerm: "" });
    }
  }
  
  useEffect(() => {
    // props의 데이터를 각각 데이터 소스 객체에 맞게 Convert 작업 해준다.
    // 제조사
    makrConverter()
    // 모델
    modlConverter();
    // 초기화
    // filterInitialList();
    /* eslint-disable-next-line */
  }, [meainInfo]); // <- 이걸 해줘야 다른 컴포넌트에 값을 입력해준다.

  return (
    <>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;설치일자
        </th>
        <td>
          <DateTimePickerKo 
                  id = "meainInstDtm" name = "meainInstDtm" className={convMngtStyles.inputTerm}
                  value = {dateFormat(meainInfo.meainInstDtm)} format = {"yyyy-MM-dd HH:mm"}
                  formatPlaceholder={{ year: '년', month: '월', day: '일' , hour : "시간" , minute : "분" }}
                  setDtm = {(v) => { onChangeDateTiem(v , "meainInstDtm"); }} 
                  // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;A/S 만료일자
        </th>
        <td>
          <DatePickerKo 
                id = "asExpiDtm" name = "asExpiDtm" className={convMngtStyles.inputTerm}
                value = {dateFormat(meainInfo.asExpiDtm)} 
                format="yyyy-MM-dd"
                formatPlaceholder={{ year: '년', month: '월', day: '일' }}
                setDtm = {(v) => { onchageDate(v , "asExpiDtm"); }}
                // required = {true} validationMessage = {inputValidMsg.normalText}
          />
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;방위각
        </th>
        <td>
          <Input id = "azimuth" name = "azimuth" type="number" className={convMngtStyles.inputTerm}  step="0.01" min = {0}
              value = {meainInfo.azimuth ? meainInfo.azimuth : ""}
              //  required = {true} validationMessage = {inputValidMsg.number2Fix}
              onChange = {onchageInput}/>
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;경사각
        </th>
        <td>
          <Input id = "incliAngle" name = "incliAngle" type="number" className={convMngtStyles.inputTerm}  step="0.01" min = {0}
              value = {meainInfo.incliAngle ? meainInfo.incliAngle : ""} 
              // required = {true} validationMessage = {inputValidMsg.number2Fix}
              onChange = {onchageInput}/>
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;모듈 제조사
        </th>
        <td>
          <DropDownList className={convMngtStyles.inputTerm}  id = "modulMakrNo" name = "modulMakrNo" 
                data = {modulMakrDs} 
                popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
                defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
                value = { modulMakr } 
                filterable={true}
                onFilterChange={makrMeainModlfilterChange}
                // required = {true} validationMessage = {inputValidMsg.normalText}
                dataItemKey="makrSeriNo" textField = "makrTerm" 
                onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; setModulMakr(e.target.value); setMeainInfo(obj); } } 
                // valid = {selectMeain.invtMakrNo !== "" && selectMeain.invtMakrNo !== null && typeof selectMeain.invtMakrNo !== "undefined" }
                // itemRender = {itemRender}
            />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;모듈 모델명
        </th>
        <td>
          <DropDownList className={convMngtStyles.inputTerm} id = "modulTypeNo" name = "modulTypeNo" 
              data = {modulTypeDs}  disabled = { meainInfo.modulMakrNo ? false : true }
              popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
              value = { modulType } 
              filterable={true}
              onFilterChange={makrMeainModlfilterChange}
              // required = {true} validationMessage = {inputValidMsg.normalText}
              dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
              onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo }; setModulType(e.target.value); setMeainInfo(obj); } } 
              // valid = {selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined" }
              // itemRender = {itemRender}
            />
        </td>
      </tr>
      <tr>
        <th>
          모듈 시리얼번호
        </th>
        <td>
          <Input id = "modulSeriNo" name = "modulSeriNo" type="text" 
                      className={convMngtStyles.inputTerm} 
                      value = {meainInfo.modulSeriNo ? meainInfo.modulSeriNo : ""}
                      onChange = {onchageInput} 
            />
        </td>
        <th>
          모듈 제조사 연락처
        </th>
        <td>
          <Input id = "modulMakrCntct" name = "modulMakrCntct" type="text" 
                    className={convMngtStyles.inputTerm} 
                    value = {meainInfo.modulMakrCntct ? meainInfo.modulMakrCntct : ""}
                    onChange = {onchageInput} 
          />
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;인버터 제조사
        </th>
        <td>
          <DropDownList className={convMngtStyles.inputTerm}  id = "invtMakrNo" name = "invtMakrNo" 
              data = {invtMakrDs} 
              defaultItem = { {makrTerm : "-" , makrSeriNo : ""} }
              popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
              value = { invtMakr } 
              filterable={true}
              onFilterChange={makrMeainModlfilterChange}
              // required = {true} validationMessage = {inputValidMsg.normalText}
              dataItemKey="makrSeriNo" textField = "makrTerm" 
              onChange = { (e) =>{ const obj = { ...meainInfo , [e.target.name] : e.value.makrSeriNo }; setInvtMakr(e.target.value); setMeainInfo(obj); } } 
              // valid = {selectMeain.invtMakrNo !== "" && selectMeain.invtMakrNo !== null && typeof selectMeain.invtMakrNo !== "undefined" }
              // itemRender = {itemRender}
          />
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;인버터 모델명
        </th>
        <td>
          <DropDownList className={convMngtStyles.inputTerm} id = "invtTypeNo" name = "invtTypeNo" 
            data = {invtTypeDs}  disabled = { meainInfo.invtMakrNo ? false : true }
            popupSettings={{ className: "convMeainInfoautoFitDropDown" }}
            value = { invtType } 
            filterable={true}
            onFilterChange={makrMeainModlfilterChange}
            // required = {true} validationMessage = {inputValidMsg.normalText}
            dataItemKey="meainTypeSeriNo" textField = "meainTypeTerm" 
            onChange = { (e) =>{ 
              const obj = { ...meainInfo , [e.target.name] : e.value.meainTypeSeriNo , "meainTypeSeriNo" : e.value.meainTypeSeriNo }; 
              setInvtType(e.target.value); setMeainInfo(obj); } 
            } 
            // valid = {selectMeain.meainTypeSeriNo !== "" && selectMeain.meainTypeSeriNo !== null && typeof selectMeain.meainTypeSeriNo !== "undefined" }
            // itemRender = {itemRender}
          />
        </td>
      </tr>
      <tr>
        <th>
          인버터 시리얼번호
        </th>
        <td>
          <Input id = "invtSeriNo" name = "invtSeriNo" type="text" 
                className={convMngtStyles.inputTerm} 
                value = {meainInfo.invtSeriNo ? meainInfo.invtSeriNo : ""}
                onChange = {onchageInput} 
          />
        </td>
        <th>
          인버터 제조사 연락처
        </th>
        <td>
          <Input id = "invtMakrCntct" name = "invtMakrCntct" type="text" 
                  className={convMngtStyles.inputTerm} 
                  value = {meainInfo.invtMakrCntct ? meainInfo.invtMakrCntct : ""}
                  onChange = {onchageInput} 
          />
        </td>
      </tr>
      <tr>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;인버터 용량(kW)
        </th>
        <td>
        <Input id = "invtCapa" name = "invtCapa" type="number" className={convMngtStyles.inputTerm} step="0.01" min = {0}
            value = {meainInfo.invtCapa ? meainInfo.invtCapa : ""} 
            // required = {true} validationMessage = {inputValidMsg.number2Fix}
            onChange = {onchageInput}/>
        </td>
        <th>
          <span className={ convMngtStyles.required }>*</span>&nbsp;시공업체
        </th>
        <td>
          <Input id = "consEnte" name = "consEnte" type="text" 
            className={convMngtStyles.inputTerm} 
            value = {meainInfo.consEnte ? meainInfo.consEnte : ""}
            onChange = {onchageInput} />
        </td>
      </tr>
      <tr>
        <th>
          시공업체 연락처
        </th>
        <td>
          <Input id = "consEnteCntct" name = "consEnteCntct" type="text" 
                className={convMngtStyles.inputTerm} 
                value = {meainInfo.consEnteCntct ? meainInfo.consEnteCntct : ""}
                onChange = {onchageInput} />
        </td>
        { meainInfo.moniYn === "Y" ?
          <>
            <th>
              <span className={ convMngtStyles.required }>*</span>&nbsp;설치확인관리번호
            </th>
            <td>
              <Input id = "instCnfrmCode" name = "instCnfrmCode" type="text" 
                    className={convMngtStyles.inputTerm} 
                    value = {meainInfo.instCnfrmCode ? meainInfo.instCnfrmCode : ""}
                    onChange = {onchageInput} />
            </td>
          </> :
          <>
            <th></th>
            <td></td>
          </> 
        }
      </tr>
    </>
  );
};

export default BizManagementMeainSola;