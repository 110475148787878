//kendo
import kendo from "@progress/kendo-ui";

export const getSolaPredPage = async (conn, data) => {
  const { take, skip, sort, filter } = data;
  const config = {
    params: {
      page: Math.floor((take + skip) / take),
      size: take,
      sort,
    },
  };
  if (filter) {
    if (filter.cid) config.params.cid = filter.cid.cid;
    if (filter.startDtm) config.params.startDtm = kendo.toString(filter.startDtm, "yyyy-MM-dd HH:mm:ss");
    if (filter.endDtm) config.params.endDtm = kendo.toString(filter.endDtm, "yyyy-MM-dd HH:mm:ss");
  }
  const response = await conn.get("/kier/pred/page", config);
  return response;
};

export const getSolaPredList = async (conn, data) => {
  const { filter } = data;
  const config = {
    params: {},
  };
  if (filter) {
    if (filter.cid) config.params.cid = filter.cid.cid;
    if (filter.startDtm) config.params.startDtm = kendo.toString(filter.startDtm, "yyyy-MM-dd HH:mm:ss");
    if (filter.endDtm) config.params.endDtm = kendo.toString(filter.endDtm, "yyyy-MM-dd HH:mm:ss");
  }
  const response = await conn.get("/kier/pred/list", config);

  const gathDtm = [];
  const pvVlt = [];
  const pvVltEst = [];
  const pvVltPred = [];
  const pvCur = [];
  const pvCurEst = [];
  const pvCurPred = [];
  const pvPwr = [];
  const pvPwrEst = [];
  const pvPwrPred = [];
  const slpIrrad = [];
  const modTemp = [];
  const linearVltEst = [];
  const linearCurEst = [];
  const linearPwrEst = [];
  const linearVltPred = [];
  const linearCurPred = [];
  const linearPwrPred = [];
  /**
   * 차트 데이터 가공
   */
  response.forEach((item, index) => {
    // if (index < 1001) {
    gathDtm.push(item.gathDtm);
    pvVlt.push(item.pvVlt);
    pvVltEst.push(item.pvVltEst);
    pvVltPred.push(item.pvVltPred);
    pvCur.push(item.pvCur);
    pvCurEst.push(item.pvCurEst);
    pvCurPred.push(item.pvCurPred);
    pvPwr.push(item.pvPwr);
    pvPwrEst.push(item.pvPwrEst);
    pvPwrPred.push(item.pvPwrPred);
    slpIrrad.push(item.slpIrrad);
    modTemp.push(item.modTemp);
    linearVltEst.push([item.pvVlt, item.pvVltEst]);
    linearCurEst.push([item.pvCur, item.pvCurEst]);
    linearPwrEst.push([item.pvPwr, item.pvPwrEst]);
    linearVltPred.push([item.pvVlt, item.pvVltPred]);
    linearCurPred.push([item.pvCur, item.pvCurPred]);
    linearPwrPred.push([item.pvPwr, item.pvPwrPred]);
    // }
  });
  //   if (index < 1001) {
  //     pvVlt.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.pvVlt });
  //     pvVltEst.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.pvVltEst });
  //     pvVltPred.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.pvVltPred });
  //     pvCur.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.pvCur });
  //     pvCurEst.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.pvCurEst });
  //     pvCurPred.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.pvCurPred });
  //     pvPwr.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.pvPwr });
  //     pvPwrEst.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.pvPwrEst });
  //     pvPwrPred.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.pvPwrPred });
  //     slpIrrad.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.slpIrrad });
  //     modTemp.push({ gathDtm: item.gathDtm, slpIrrad: item.slpIrrad, modTemp: item.modTemp, y: item.modTemp });
  //     linearVltEst.push([item.pvVlt, item.pvVltEst]);
  //     linearCurEst.push([item.pvCur, item.pvCurEst]);
  //     linearPwrEst.push([item.pvPwr, item.pvPwrEst]);
  //     linearVltPred.push([item.pvVlt, item.pvVltPred]);
  //     linearCurPred.push([item.pvCur, item.pvCurPred]);
  //     linearPwrPred.push([item.pvPwr, item.pvPwrPred]);
  //   }
  // });
  const result = {
    gathDtm: gathDtm,
    pvVlt: pvVlt,
    pvVltEst: pvVltEst,
    pvVltPred: pvVltPred,
    pvCur: pvCur,
    pvCurEst: pvCurEst,
    pvCurPred: pvCurPred,
    pvPwr: pvPwr,
    pvPwrEst: pvPwrEst,
    pvPwrPred: pvPwrPred,
    slpIrrad: slpIrrad,
    modTemp: modTemp,
    linearVltEst: linearVltEst,
    linearCurEst: linearCurEst,
    linearPwrEst: linearPwrEst,
    linearVltPred: linearVltPred,
    linearCurPred: linearCurPred,
    linearPwrPred: linearPwrPred,
  };
  return result;
};
