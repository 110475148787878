import React from "react";
import { useEffect } from "react";
import BizProgressEnergyImg from "../bizProgressStepImg";

const BizProgressEnergyImgSola = ({
  stylesProgress,
  imgFile,
  previewFile,
  filesState,
  onAdd,
  onRemove,
  onProgress,
  onStatusChange,
  saveFileUrl,
  removeFileUrl,
  isMobile,
  imgClickStatus,
  setImgClickStatus,
  closeImgWindow,
}) => {
  console.log("BizProgressEnergyImgSola previewFile", previewFile);
  console.log("BizProgressEnergyImgSola imgFile", imgFile);
  console.log("BizProgressEnergyImgSola imgClickStatus", imgClickStatus);

  // const Buffer = require('buffer').Buffer
  // const path = require('path')
  // const fs = require('fs')

  // const buf = fs.readFileSync(src)  // binary 형태로 반환됨
  // const base64 = buf.toString('base64')

  useEffect(() => {
    preview();

    return () => preview();
  });

  const preview = () => {
    // if(!files) return false;

    const imgEl = document.querySelector(".img_box");

    const reader = new FileReader();

    reader.onload = () => (imgEl.style.backgroundImage = `url(${reader.result})`);

    // reader.readAsDataURL(files[0]);
  };

  return (
    <>
      {isMobile ? (
        <div class="uk-width-medium-2-5">
          <div>
            <div className={stylesProgress.energyProgressTitle}>태양광</div>
            <div class="JinTabB_con" id="JinTabBB_con">
              <dl>
                <table className={stylesProgress.meainTable}>
                  <colgroup>
                    <col width="20%" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="20%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>구조물/모듈</th>
                      <th>전기</th>
                      <th>사용전 점검 및 검사</th>
                      <th>상계거래</th>
                      <th>모니터링</th>
                    </tr>
                    <tr>
                      <td>
                        <BizProgressEnergyImg
                          imgFile={imgFile}
                          stepFileArray={imgFile.step1}
                          previewFile={previewFile.step1}
                          filesState={filesState}
                          onAdd={onAdd}
                          onRemove={onRemove}
                          onProgress={onProgress}
                          onStatusChange={onStatusChange}
                          saveFileUrl={saveFileUrl}
                          removeFileUrl={removeFileUrl}
                          isMobile={isMobile}
                          name="step1"
                          imgClickStatus={imgClickStatus}
                          setImgClickStatus={setImgClickStatus}
                          closeImgWindow={closeImgWindow}
                        />
                      </td>
                      <td>
                        <BizProgressEnergyImg
                          imgFile={imgFile}
                          stepFileArray={imgFile.step2}
                          previewFile={previewFile.step2}
                          filesState={filesState}
                          onAdd={onAdd}
                          onRemove={onRemove}
                          onProgress={onProgress}
                          onStatusChange={onStatusChange}
                          saveFileUrl={saveFileUrl}
                          removeFileUrl={removeFileUrl}
                          isMobile={isMobile}
                          name="step2"
                          imgClickStatus={imgClickStatus}
                          setImgClickStatus={setImgClickStatus}
                          closeImgWindow={closeImgWindow}
                        />
                      </td>
                      <td>
                        <BizProgressEnergyImg
                          imgFile={imgFile}
                          stepFileArray={imgFile.step3}
                          previewFile={previewFile.step3}
                          filesState={filesState}
                          onAdd={onAdd}
                          onRemove={onRemove}
                          onProgress={onProgress}
                          onStatusChange={onStatusChange}
                          saveFileUrl={saveFileUrl}
                          removeFileUrl={removeFileUrl}
                          isMobile={isMobile}
                          name="step3"
                          imgClickStatus={imgClickStatus}
                          setImgClickStatus={setImgClickStatus}
                          closeImgWindow={closeImgWindow}
                        />
                      </td>
                      <td>
                        <BizProgressEnergyImg
                          imgFile={imgFile}
                          stepFileArray={imgFile.step4}
                          previewFile={previewFile.step4}
                          filesState={filesState}
                          onAdd={onAdd}
                          onRemove={onRemove}
                          onProgress={onProgress}
                          onStatusChange={onStatusChange}
                          saveFileUrl={saveFileUrl}
                          removeFileUrl={removeFileUrl}
                          isMobile={isMobile}
                          name="step4"
                          imgClickStatus={imgClickStatus}
                          setImgClickStatus={setImgClickStatus}
                          closeImgWindow={closeImgWindow}
                        />
                      </td>
                      <td>
                        <BizProgressEnergyImg
                          imgFile={imgFile}
                          stepFileArray={imgFile.moni}
                          previewFile={previewFile.moni}
                          filesState={filesState}
                          onAdd={onAdd}
                          onRemove={onRemove}
                          onProgress={onProgress}
                          onStatusChange={onStatusChange}
                          saveFileUrl={saveFileUrl}
                          removeFileUrl={removeFileUrl}
                          isMobile={isMobile}
                          name="moni"
                          imgClickStatus={imgClickStatus}
                          setImgClickStatus={setImgClickStatus}
                          closeImgWindow={closeImgWindow}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </dl>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={stylesProgress.energyProgressTitle}>태양광</div>
          <div class="JinTabB_con" id="JinTabBB_con">
            <dl>
              <table className={stylesProgress.meainTable}>
                <colgroup>
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>구조물/모듈</th>
                    <th>전기</th>
                    <th>사용전 점검 및 검사</th>
                    <th>상계거래</th>
                    <th>모니터링</th>
                  </tr>
                  <tr>
                    <td>
                      <BizProgressEnergyImg
                        imgFile={imgFile}
                        stepFileArray={imgFile.step1}
                        previewFile={previewFile.step1}
                        filesState={filesState}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onProgress={onProgress}
                        onStatusChange={onStatusChange}
                        saveFileUrl={saveFileUrl}
                        removeFileUrl={removeFileUrl}
                        isMobile={isMobile}
                        name="step1"
                        imgClickStatus={imgClickStatus}
                        setImgClickStatus={setImgClickStatus}
                        closeImgWindow={closeImgWindow}
                      />
                    </td>
                    <td>
                      <BizProgressEnergyImg
                        imgFile={imgFile}
                        stepFileArray={imgFile.step2}
                        previewFile={previewFile.step2}
                        filesState={filesState}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onProgress={onProgress}
                        onStatusChange={onStatusChange}
                        saveFileUrl={saveFileUrl}
                        removeFileUrl={removeFileUrl}
                        isMobile={isMobile}
                        name="step2"
                        imgClickStatus={imgClickStatus}
                        setImgClickStatus={setImgClickStatus}
                        closeImgWindow={closeImgWindow}
                      />
                    </td>
                    <td>
                      <BizProgressEnergyImg
                        imgFile={imgFile}
                        stepFileArray={imgFile.step3}
                        previewFile={previewFile.step3}
                        filesState={filesState}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onProgress={onProgress}
                        onStatusChange={onStatusChange}
                        saveFileUrl={saveFileUrl}
                        removeFileUrl={removeFileUrl}
                        isMobile={isMobile}
                        name="step3"
                        imgClickStatus={imgClickStatus}
                        setImgClickStatus={setImgClickStatus}
                        closeImgWindow={closeImgWindow}
                      />
                    </td>
                    <td>
                      <BizProgressEnergyImg
                        imgFile={imgFile}
                        stepFileArray={imgFile.step4}
                        previewFile={previewFile.step4}
                        filesState={filesState}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onProgress={onProgress}
                        onStatusChange={onStatusChange}
                        saveFileUrl={saveFileUrl}
                        removeFileUrl={removeFileUrl}
                        isMobile={isMobile}
                        name="step4"
                        imgClickStatus={imgClickStatus}
                        setImgClickStatus={setImgClickStatus}
                        closeImgWindow={closeImgWindow}
                      />
                    </td>
                    <td>
                      <BizProgressEnergyImg
                        imgFile={imgFile}
                        stepFileArray={imgFile.moni}
                        previewFile={previewFile.moni}
                        filesState={filesState}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onProgress={onProgress}
                        onStatusChange={onStatusChange}
                        saveFileUrl={saveFileUrl}
                        removeFileUrl={removeFileUrl}
                        isMobile={isMobile}
                        name="moni"
                        imgClickStatus={imgClickStatus}
                        setImgClickStatus={setImgClickStatus}
                        closeImgWindow={closeImgWindow}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </dl>
          </div>
        </>
      )}
    </>
  );
};

export default BizProgressEnergyImgSola;
