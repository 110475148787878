import { ReactComponent as Sunny } from "../../../../../assets/svg/weather/sunny.svg";
import { ReactComponent as Cloudy1 } from "../../../../../assets/svg/weather/cloudy.svg";
import { ReactComponent as Cloudy2 } from "../../../../../assets/svg/weather/cloudy2.svg";
import { ReactComponent as Cloudy3 } from "../../../../../assets/svg/weather/clouded2.svg";
import { ReactComponent as Rainy } from "../../../../../assets/svg/weather/rainy.svg";
import { ReactComponent as RainySnowy } from "../../../../../assets/svg/weather/rainysnowy.svg";
import { ReactComponent as SunRainy } from "../../../../../assets/svg/weather/sunrainy.svg";
import { ReactComponent as Snow } from "../../../../../assets/svg/weather/snow.svg";

const WeatherIcon = ({ weather }) => {
  const weatherStatus = {
    맑음: <Sunny />,
    구름조금: <Cloudy1 />,
    "구름 조금": <Cloudy1 />,
    구름많음: <Cloudy2 />,
    "구름 많음": <Cloudy2 />,
    흐림: <Cloudy3 />,
    비: <Rainy />,
    "눈/비": <RainySnowy />,
    "비/눈": <RainySnowy />,
    눈: <Snow />,
    소나기: <SunRainy style ={{height : '90px'}} />,
  };

  return <div>{weatherStatus[weather] || <Sunny />}</div>;
};

export default WeatherIcon;
