import * as React from 'react';

import { MultiViewCalendar } from '@progress/kendo-react-dateinputs';

// 사용법
// 1. 해당 컴포넌트를 사용할 화면에 임포트 한다. (DatePicker 기존 폼으로 변경) 
// 2. <DatePicker  calendar = {CustomCalendar} ></DatePicker> 처럼 DatePicker 의 calender 옵션 에 해당 컴포넌트를 추가하는 옵션을 부가 한다. 
/* 3. topview, bottomview 사용법 decade = 연간조회, year = 월간조회, month = 일간 조회 디폴트는 month임
<DatePicker 
calendar={(props)=> (
  <CustomCalendar {...props} topView={"decade"} bottomView={"decade"} />
)}> 와 같이 사용 */

export class CustomCalendar extends React.Component {
    render() {
        return (    
            <MultiViewCalendar
                views={1}
                value={this.props.value}
                onChange={this.props.onChange}
                topView={this.props.topView}
                bottomView={this.props.bottomView}
                max = {this.props.max}
            />
        );
    }
}
