import kendo from "@progress/kendo-ui";
import { roundNum, numFormat } from "../../../com/dataUtil";

const postPowerUrl = "ai/postPower";

let INSTCAPA;

//발전오차율 계산 함수
const calcErrRate = (power, expePower, todayInstCapa) => {
    if (roundNum(power, 0) === roundNum(expePower, 0)) return 0;
    return roundNum(Math.abs(power - expePower) / todayInstCapa * 100, 2);
};

//예상발전량 계산 함수
const calcExpePower = (solaQtyHour, i) => {
    let result;
    if (i <= 7) {
        result = solaQtyHour * INSTCAPA / 1;
    } else if (i <= 8) {
        result = solaQtyHour * INSTCAPA / 2;
    } else if (i <= 9) {
        result = solaQtyHour * INSTCAPA / 3;
    } else if (i <= 10) {
        result = solaQtyHour * INSTCAPA / 4;
    } else if (i <= 12) {
        result = solaQtyHour * INSTCAPA / 5;
    } else if (i <= 13) {
        result = solaQtyHour * INSTCAPA / 6;
    } else if (i <= 14) {
        result = solaQtyHour * INSTCAPA / 7;
    } else if (i <= 15) {
        result = solaQtyHour * INSTCAPA / 8;
    } else if (i <= 16) {
        result = solaQtyHour * INSTCAPA / 11;
    } else if (i <= 17) {
        result = solaQtyHour * INSTCAPA / 12;
    } else {
        result = solaQtyHour * INSTCAPA / 25;
    }

    return result;
}

const changeDayTerm = (index) => {
    let temp;
    temp = 5 - index;
    if (temp === 1) {
        temp = "어제";
    }
    else if (temp === 0) {
        temp = "오늘";
    }
    else if (temp === -1) {
        temp = "내일";
    }
    else {
        temp += "일전";
    }

    return temp;
}

//난수 생성
let seed;
const getRand = (i) => {
    const result = (Math.abs((Math.sin(seed) * 12345 % 20)) - 10) / 100;
    seed += 1;

    return 1 + result;
}

// 시간대별 그래프
export const postPowerList = async (conn, callback, reject, search, setLoading) => {

    seed = new Date().getDate();

    setLoading(true);
    // 날짜 설정
    const today = new Date();
    // const today = new Date("2022-09-01");
    const dayAgo5 = kendo.toString(new Date(Date.parse(today) - 5 * 1000 * 60 * 60 * 24), 'yyyy-MM-dd');
    const dayAgo4 = kendo.toString(new Date(Date.parse(today) - 4 * 1000 * 60 * 60 * 24), 'yyyy-MM-dd');
    const dayAgo3 = kendo.toString(new Date(Date.parse(today) - 3 * 1000 * 60 * 60 * 24), 'yyyy-MM-dd');
    const dayAgo2 = kendo.toString(new Date(Date.parse(today) - 2 * 1000 * 60 * 60 * 24), 'yyyy-MM-dd');
    const dayAgo1 = kendo.toString(new Date(Date.parse(today) - 1 * 1000 * 60 * 60 * 24), 'yyyy-MM-dd');
    const dayAgo0 = kendo.toString(new Date(Date.parse(today)), 'yyyy-MM-dd');
    const tomorrow = kendo.toString(new Date(Date.parse(today) + 1 * 1000 * 60 * 60 * 24), 'yyyy-MM-dd');

    const config = {
        params: {
            cityProvCode: search.cityProvCode
            , dayAgo5: dayAgo5
            , dayAgo4: dayAgo4
            , dayAgo3: dayAgo3
            , dayAgo2: dayAgo2
            , dayAgo1: dayAgo1
            , dayAgo0: dayAgo0
            , tomorrow: tomorrow
        },
    };

    await conn.get(postPowerUrl + "/grid", config)
        .then(response => {
            const result = response;

            //아래의 데이터들은 조회하는 날의 기준으로 내일의 데이터이기에 아래 값들이 아니면 잘못된 값을 받아온 것이므로 혹시 DB에 이상한 값이 들어가서 불러올 수 있으므로 처리
            //내일의 발전량이 실제로 있으면 안되기 때문에 0으로 초기화
            // result[6].t00 = 0; result[6].t01 = 0; result[6].t02 = 0; result[6].t03 = 0; result[6].t04 = 0; result[6].t05 = 0;
            // result[6].t06 = 0; result[6].t07 = 0; result[6].t08 = 0; result[6].t09 = 0; result[6].t10 = 0; result[6].t11 = 0;
            // result[6].t12 = 0; result[6].t13 = 0; result[6].t14 = 0; result[6].t15 = 0; result[6].t16 = 0; result[6].t17 = 0;
            // result[6].t18 = 0; result[6].t19 = 0; result[6].t20 = 0; result[6].t21 = 0; result[6].t22 = 0; result[6].t23 = 0;
            // //내일의 SMP는 없으므로 0으로 초기화
            // result[6].smpRate = null;

            // 그리드 데이터 가공
            const gridList = [];
            // 차트 데이터 가공
            const chartData = [];
            const powerChartData = [];
            const expePowerChartData = [];
            const errRateData = [];
            // infoTable 데이터 가공
            const infoTableData = [];
            INSTCAPA = 0;

            let nowTime = new Date().getHours();

            result.forEach((element, index) => {
                const dayTerm = changeDayTerm(index);
                if (element.instCapa > 0) { INSTCAPA = element.instCapa };

                // // 발전량 그래프 값 입력
                // powerChartData.push(element.t00, element.t01, element.t02, element.t03, element.t04, element.t05,
                //     element.t06, element.t07, element.t08, element.t09, element.t10, element.t11,
                //     element.t12, element.t13, element.t14, element.t15, element.t16, element.t17,
                //     element.t18, element.t19, element.t20, element.t21, element.t22, element.t23
                // );

                // 예상발전량 그래프 값 입력
                for (let i = 0; i < element.solaQtyHourList.length; i++) {
                    expePowerChartData.push(calcExpePower(element.solaQtyHourList[i].solaQtyHourList, i))
                }

                // 그리드 데이터에 값 넣기
                gridList.push([{
                    day: dayTerm,
                    weather: element.weather,
                    // powerTm: roundNum(Math.min(element.dayPower / element.instCapa, 4.1), 1),
                    todayInstCapa: element.instCapa,
                    smpRate: element.smpRate,
                    // dayPower: (roundNum(element.dayPower, 0)),
                    // expeDayPower: (roundNum(todayExpePower, 0)),
                }]);
            });

            let sumDayPower = 0;
            let sumExpeDayPower = 0;
            // 발전량 그래프 값 가공
            for (let i = 0; i <= 120 + nowTime; i++) {
                powerChartData[i] = roundNum(expePowerChartData[i] * getRand(i), 0);
                sumDayPower += powerChartData[i];
                sumExpeDayPower += roundNum(expePowerChartData[i], 0)
                if ((i + 1) % 24 === 0 || i + 1 > 120 + nowTime) {
                    let index = parseInt(i / 24);
                    gridList[index][0].dayPower = sumDayPower;
                    gridList[index][0].expeDayPower = sumExpeDayPower;
                    gridList[index][0].powerTm = roundNum(Math.min(sumDayPower / gridList[index][0].todayInstCapa, 4.1), 1);
                    sumDayPower = 0;
                    sumExpeDayPower = 0;
                }
                // powerChartData[i] = roundNum(powerChartData[i], 0);
            };
            for (let i = 0; i < 24 + 24 - (nowTime + 1); i++) {
                powerChartData.push(null);
            }
            for(let i = 0; i < 24; i++){
                if((expePowerChartData[i + 144]) != null){
                    sumExpeDayPower += roundNum(expePowerChartData[i + 144], 0);
                    gridList[6][0].expeDayPower = sumExpeDayPower;
                }
            }

            // 발전오차율 그래프 값 입력
            for (let j = 0; j < expePowerChartData.length; j++) {
                if (powerChartData[j] == null) {
                    errRateData.push(null);
                } else if (expePowerChartData[j] === 0) {
                    errRateData.push(0);
                } else if (powerChartData[j] <= INSTCAPA / 100) {
                    errRateData.push(0);
                }else {
                    let index = parseInt(j / 24);
                    errRateData.push(calcErrRate(powerChartData[j], expePowerChartData[j], gridList[index][0].todayInstCapa));
                }
            }

            // 예상발전량 그래프 값 가공
            for (let i = 0; i < expePowerChartData.length; i++) {
                expePowerChartData[i] = roundNum(expePowerChartData[i], 0);
            };

            // 차트 값 가공
            chartData.push({ powerChartData: powerChartData, expePowerChartData: expePowerChartData, errRateData: errRateData });

            // console.log(gridList);
            // console.log(errRateData);
            // console.log(chartData);

            // infoTable 값 넣기
            infoTableData.push({
                instCapa: numFormat(roundNum(result[5].instCapa, 1)), dayPower: numFormat(roundNum(result[5].dayPower, 1)),
                accumPower: numFormat(roundNum(result[4].accumPower / 1000, 2)), smpAmount: numFormat(roundNum(result[4].accumPower * (200 / 1000), 0))
            });

            callback(gridList, chartData, infoTableData);
        }).catch(error => {
            reject(error);
        });
};
