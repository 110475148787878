

import React, { useState } from 'react';


import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import koMessage from "../../../../com/ko-language.json";
import { useCallback } from 'react';

loadMessages(koMessage, "ko-KR");

const BizMngtMeainUploadArea = ({
  openUpload, convMngtStyles , Button, Window, Upload, kendo
  , token
  , saveMeainInfoDataList
}) => {

  // 엑셀 업로드 헤더 설정
  const handleUploadHeaders = useCallback(e =>  {
    e.headers['X-AUTH-TOKEN'] = token;
    /* eslint-disable-next-line */
  }, []);

  // 파일 업로드 
  const [uploadState , setUploadState] = useState({
    files: [],
    events: [],
    filePreviews: {},
    afterStateChange : []
  });

  const [uploadSearch, setUploadSearch] = useState({
    energyTypeCode : "",
    sysMode : "",
    title : "설비 업로드",     
  });


  // 파일 업로드 후 문제 없다면 저장할 리스트 변수
  let [uploadDataList , setUploadDataList] = useState([]);

  // 파일 업로드 윈도우 창 제어
  let [windowVisible , setWindowVisible] = useState(false);

  // 엑셀 업로드 취소 , 닫기 누를 시 초기화
  const initialExcelFile = () => {
    uploadState.files =  [];
    uploadState.events =  [];
    uploadState.filePreviews =  {};
    uploadState.afterStateChange = [] ;
    uploadSearch.energyTypeCode = "" ;
    uploadSearch.sysMode = ""
    setUploadDataList([]);
  }
  
  // 엑셀 업로드 관련 함수 모음 
  const onAdd = (event) => {
    // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.
    const afterStateChange = () => {
      event.affectedFiles
      .filter(file => !file.validationErrors)
      .forEach(file => {
          const reader = new FileReader();
          reader.onloadend = (ev) => {
            setUploadState({
                files: uploadDataList.files,
                events: uploadDataList.events,
                filePreviews: {
                            [file.uid]: ev.target.result
                        }
            });
          };
          reader.readAsDataURL(file.getRawFile());
      });
    };
    // 이걸 안해주면 파일 업로드 결과 목록을 안보여준다.    
    setUploadState({
        files: event.newState,
        events: [
            uploadDataList.events
        ] ,
        filePreviews : uploadDataList.filePreviews,
        afterStateChange : afterStateChange
    });
  }
  const onRemove = (event) => { }
  const onProgress = (event) => { }
  const onStatusChange = (event) => {
    console.log("event", event);
    // 이걸 안해주면 파일 업로드 로딩이 안끝난다.
    setUploadState({
        files: event.newState
    });
    setUploadDataList(event.response.response);
  }

  // 엑셀 업로드 URL 설정
  const getExcelUploadUrl = () => {
    let url = "";
    console.log("getExcelUploadUrl uploadSearch = ", uploadSearch);
    const energyTypeCode = uploadSearch.energyTypeCode;
    if(energyTypeCode === 39001){ url = "/api/v2.2/biz/upload/management/sola/xlsx"; } 
    else if(energyTypeCode === 39002){
      // 태양열 모드 확인
      const sysMode =  uploadSearch.sysMode;
      // 강제순환식
      if(sysMode === 29001){ url = "/api/v2.2/biz/upload/management/heatC/xlsx"; }
      // 자연순환식
      else{ url = "/api/v2.2/biz/upload/management/heatN/xlsx"; }}
    else if(energyTypeCode === 39003){ url = "/api/v2.2/biz/upload/management/velo/xlsx"; } 
    else if(energyTypeCode === 39004){ url = "/api/v2.2/biz/upload/management/heath/xlsx"; } 
    else if(energyTypeCode === 39005){ url = "/api/v2.2/biz/upload/management/fuel/xlsx"; } 
    console.log("url = ", url);
    return url;
  }

  // 엑셀 파일 업로드 닫을 시 
  const onCloseExcelUpload = () => {
    initialExcelFile();
    setWindowVisible(!windowVisible);
  }

  // 태양광 업로드 버튼 
  const uploadMeainSola = () => {
    uploadSearch.energyTypeCode = 39001;
    uploadSearch.sysMode = "";
    uploadSearch.title = "태양광 설비 업로드";
    setWindowVisible(!windowVisible);
  }

  // 태양열(강제) 업로드 버튼 
  const uploadMeainHeat = () => {
    uploadSearch.energyTypeCode = 39002;
    uploadSearch.sysMode = 29001;
    uploadSearch.title = "태양열(강제) 설비 업로드";
    setWindowVisible(!windowVisible);
  }

  // 태양열(자연) 업로드 버튼 
  const uploadMeainHeatN = () => {
    uploadSearch.energyTypeCode = 39002;
    uploadSearch.sysMode = 29002;
    uploadSearch.title = "태양열(자연) 설비 업로드";
    setWindowVisible(!windowVisible);
  }

  // 소형풍력 업로드 버튼 
  const uploadMeainVelo = () => {
    uploadSearch.energyTypeCode = 39003;
    uploadSearch.sysMode = "";
    uploadSearch.title = "소형풍력 설비 업로드";
    setWindowVisible(!windowVisible);
  }

  // 지열 업로드 버튼 
  const uploadMeainHeath = () => {
    uploadSearch.energyTypeCode = 39004;
    uploadSearch.sysMode = "";
    uploadSearch.title = "지열 설비 업로드";
    setWindowVisible(!windowVisible);
  }

  // 연료전지 업로드 버튼 
  const uploadMeainFuel = () => {
    uploadSearch.energyTypeCode = 39005;
    uploadSearch.sysMode = "";
    uploadSearch.title = "연료전지 설비 업로드";
    setWindowVisible(!windowVisible);
  }

  // 설비 리스트 저장
  const saveMeainListEvt = () => {
    if(uploadDataList.length <= 0){
      kendo.alert("저장할 설비 리스트가 없습니다.");
      console.log("uploadDataList", uploadDataList);
      return;
    }else{
      if(uploadDataList.data.length <= 0){
        kendo.alert("저장할 설비 리스트가 없습니다.");
        console.log("uploadDataList.data", uploadDataList.data);
        return;
      }
    }
    const energyTypeCode = uploadSearch.energyTypeCode;
    const meainList = uploadDataList.data;
    onCloseExcelUpload();
    saveMeainInfoDataList(meainList, energyTypeCode);
  }

  return (
    <>
    <div className={openUpload ? convMngtStyles.showMenuUpload : convMngtStyles.hideMenuUpload}>
      <ul className={convMngtStyles.ulUploadCss}>
        <li className={convMngtStyles.liUploadCss} >
          <Button icon = "upload" onClick = {uploadMeainSola} className = {convMngtStyles.uploadBtnCss} > 태양광 설비 업로드 </Button>
        </li>
        <li className={convMngtStyles.liUploadCss} > 
          <Button icon = "upload" onClick = {uploadMeainHeat} className = {convMngtStyles.uploadBtnCss} > 태양열(강제) 설비 업로드 </Button>
        </li>
        <li className={convMngtStyles.liUploadCss} > 
          <Button icon = "upload" onClick = {uploadMeainHeatN} className = {convMngtStyles.uploadBtnCss} > 태양열(자연) 설비 업로드 </Button>
        </li>
        <li className={convMngtStyles.liUploadCss} > 
          <Button icon = "upload" onClick = {uploadMeainVelo} className = {convMngtStyles.uploadBtnCss} > 소형풍력 설비 업로드 </Button>
        </li>
        <li className={convMngtStyles.liUploadCss}> 
          <Button icon = "upload" onClick = {uploadMeainHeath} className = {convMngtStyles.uploadBtnCss} > 지열 설비 업로드 </Button>
        </li>
        <li className={convMngtStyles.liUploadCss} > 
          <Button icon = "upload" onClick = {uploadMeainFuel} className = {convMngtStyles.uploadBtnCss} > 연료전지 설비 업로드 </Button>
        </li>
      </ul>
    </div>
    {windowVisible && (
      <Window title={ uploadSearch.title } 
              onClose={onCloseExcelUpload} 
              // top={186} 
              // left={600} 
              initialHeight={250} 
              initialWidth={600} 
              modal={true}
              resizable={false} 
              doubleClickStageChange={false} 
              draggable={false}
              className='uploadMeainWindow'
      >
        <div>
          {/* 윈도우 창 */}
          <LocalizationProvider language="ko-KR">
            <IntlProvider locale="ko">
              <Upload
                batch={false}
                multiple={false}
                onBeforeUpload={handleUploadHeaders}
                withCredentials={true}
                onAdd={onAdd}
                onRemove={onRemove}
                onProgress={onProgress}
                onStatusChange={onStatusChange}
                files={uploadState.files}
                restrictions={{
                  allowedExtensions: ['.xlsx'],
                  maxFileSize: 10240000
                }}
                saveUrl={getExcelUploadUrl()}
                removeUrl={''}
              />
            </IntlProvider>
          </LocalizationProvider>
          <div className={convMngtStyles.uploadBtnArea}>
            <Button className={ [ convMngtStyles.uploadBtn , convMngtStyles.uploadSaveBtn ].join(' ') }  icon="save" onClick = { () => {saveMeainListEvt();} }>저장</Button>
            <Button className={ [ convMngtStyles.uploadBtn , convMngtStyles.uploadCancelBtn ].join(' ') }  icon="cancel" onClick = { () => { onCloseExcelUpload(); } }>취소</Button>
          </div>
          
        </div>
      </Window>
    )}
    </>
  );
};

export default BizMngtMeainUploadArea;