import { useState, useEffect, memo } from "react";
import { BeatLoader } from "react-spinners";

import styles from "./installerMeain.module.css";
import "./installerMeain.css";

import { DropDownList as DropDown } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import DatePickerKo from "../../com/dateInputs/datePickerKo";
import InstallerMeainList from "./installerMeain_list";
import InstallerMeainCount from "./installerMeain_count";

import { getInstallerMeainStatCnt, getInstallerMeainList } from "../../../service/monitor/installerMeainService";
import { useLocation } from "react-router";
import { getUser, isInstaller } from "../../../service/authService";
import { getDongs } from "../../../service/areaService";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { getCalCityList, getCalRgnList } from "../../../com/dataUtil";

const InstallerMeain = memo(({ conn, codeList, cityList, rgnList, qs, isMobile }) => {
  const [selected, setSelected] = useState(0);
  const handleSelect = e => {
    setSelected(e.selected);
  };
  let location = useLocation();
  let { cityProvCode, rgnCode, dongCode, dongTerm, statNormal, statUnOper, statReady, statWarn, statError, ensoTypeCode } = qs.parse(
    location.search,
  );

  const defualtVal = { codeVal: "전체", codeNo: "" };
  const defualtValCity = { cityProvTerm: "시/도", cityProvCode: "" };
  const defualtValRgn = { rgnTermSimple: "시/군/구", rgnCode: "" };
  const defualtValDong = { fixbylawBundTermSimple: "읍/면/동", fixbylawBundCode: "" };
  const ensoList = codeList.filter(c => c.grpCodeNo === 15);
  const buildingTypeList = codeList.filter(c => c.grpCodeNo === 31);

  const accUserInfo = getUser();

  const [dongList, setDongList] = useState();

  // 검색 조건
  const [search, setSearch] = useState({
    ensoTypeCode: ensoTypeCode !== "15000" && ensoTypeCode !== "" ? ensoTypeCode : "",
    bldSrvCode: "",
    cityProvCode: cityProvCode ? cityProvCode : "",
    rgnCode: rgnCode ? rgnCode : "",
    fixbylawBundCode: dongCode ? dongCode : "",
    searchTerm: "",
    statNormal: statNormal === "false" ? false : true,
    statUnOper: statUnOper === "false" ? false : true,
    statReady: statReady === "false" ? false : true,
    statWarn: statWarn === "false" ? false : true,
    statError: statError === "false" ? false : true,
    startDtm: "",
    endDtm: "",
    ensoType: { codeNo: "", codeVal: "전체" },
    city: { cityProvCode: "", cityProvTerm: "시/도" },
    rgn: { rgnCode: "", rgnTermSimple: "시/군/구" },
    dong: { fixbylawBundCode: dongCode ? dongCode : "", fixbylawBundTermSimple: dongTerm ? dongTerm : "읍/면/동" },
  });

  // 지역 데이터 소스 
  const citys = getCalCityList(cityList);
  let rgns = getCalRgnList(rgnList.filter(e => e.cityProvCode === search.cityProvCode));

  const dongs = dongList;

  const [statCnt, setStatCnt] = useState({
    readyCnt: 0,
    normalCnt: 0,
    unOperCnt: 0,
    warnCnt: 0,
    errorCnt: 0,
  });

  const [page, setPage] = useState({
    skip: 0,
    take: isMobile ? 4 : 25,
    count: 0,
    list: [],
  });

  const setSearchClickEvt = e => {
    e.preventDefault();
    page.skip = 0;
    // page.take = 25;
    setInstallerMeainstatCount(search);
    setInstallerMeainList(search, page);
  };

  const setInstallerMeainstatCount = search => {
    getInstallerMeainStatCnt(conn, search).then(cnt => {
      setStatCnt(state => {
        const item = { ...state };
        item.readyCnt = cnt.readyCnt || 0;
        item.normalCnt = cnt.normalCnt || 0;
        item.unOperCnt = cnt.unOperCnt || 0;
        item.warnCnt = cnt.warnCnt || 0;
        item.errorCnt = cnt.errorCnt || 0;
        return item;
      });
    });
  };

  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  const setInstallerMeainList = (search, page) => {
    setLoading(true);
    getInstallerMeainList(conn, search, page, setLoading).then(result => {
      setPage(page => {
        const item = { ...page };
        item.skip = page.skip;
        item.take = page.take;
        item.count = result.total;
        item.list = result.results;
        return item;
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    setInstallerMeainstatCount(search);
    setInstallerMeainList(search, page);
    changeValueEnsoType();
    changeValueCity();
    changeValueRgn();
    // changeValueDong();
    // eslint-disable-next-line
  }, []);
  // 드롭다운 이벤트
  const onChangeEvt = e => {
    if (e.target.name === "ensoTypeCode") {
      setSearch(search => {
        const item = { ...search };
        item.ensoTypeCode = e.value.codeNo;
        // 지역검색 초기화
        item.city = { cityProvCode: "", cityProvTerm: "시/도" };
        item.cityProvCode = "";
        item.cityProvTerm = "";
        item.rgnCode = "";
        item.rgnTerm = "";
        item.rgn = { rgnCode: "", rgnTermSimple: "시/군/구" };
        item.dong = { fixbylawBundCode: "", fixbylawBundTermSimple: "읍/면/동" };
        item.dongTerm = "";
        item.fixbylawBundCode = "";
        return item;
      });
    } else if (e.target.name === "city") {
      setSearch(search => {
        const item = { ...search };
        item.city = e.value;
        item.cityProvCode = e.value.cityProvCode;
        item.cityProvTerm = e.value.cityProvTerm;
        item.rgnCode = "";
        item.rgnTerm = "";
        item.rgn = { rgnCode: "", rgnTermSimple: "시/군/구" };
        item.dong = { fixbylawBundCode: "", fixbylawBundTermSimple: "읍/면/동" };
        item.dongTerm = "";
        item.fixbylawBundCode = "";
        return item;
      });
    } else if (e.target.name === "rgn") {
      setSearch(search => {
        const item = { ...search };
        item.rgnCode = e.value.rgnCode;
        item.rgnTerm = e.value.rgnTermSimple;
        item.rgn = e.value;
        item.fixbylawBundCode = "";
        item.dongTerm = "";
        item.dong = { fixbylawBundCode: "", fixbylawBundTermSimple: "읍/면/동" };
        return item;
      });

      console.log("RGN 드롭다운 실행");
      getDongs(conn, e.value.rgnCode).then(result => {
        console.log("getDongs result = ", result);
        setDongList(result);
      });
    } else if (e.target.name === "dong") {
      setSearch(search => {
        const item = { ...search };
        item.fixbylawBundCode = e.value.fixbylawBundCode;
        item.dong = e.value;
        item.dongTerm = e.value.fixbylawBundTermSimple;
        return item;
      });
    }
  };

  // 에너지원 값 설정
  const changeValueEnsoType = () => {
    if (ensoTypeCode !== null) {
      setSearch(search => {
        const item = { ...search };
        item.ensoType = codeList.filter(c => c.codeNo.toString() === search.ensoTypeCode)[0];
        return item;
      });
    }
  };

  // 시도 값 설정
  const changeValueCity = () => {
    if (cityProvCode !== null) {
      setSearch(search => {
        const item = { ...search };
        item.city = cityList.filter(e => e.cityProvCode === search.cityProvCode)[0];
        return item;
      });
    }
  };

  // 시군구 값 설정
  const changeValueRgn = () => {
    if (rgnCode !== null) {
      setSearch(search => {
        const item = { ...search };
        item.rgn = rgnList.filter(e => e.rgnCode === search.rgnCode)[0];
        return item;
      });
    }
  };

  return (
    <>
      {isMobile ? (
        <div className={loading ? styles.installerMeain : ""}>
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title="설비상태">
              <div style={{ padding: "10px" }}>
                <InstallerMeainCount search={search} statCnt={statCnt} isMobile={isMobile} />
              </div>
              <div className="mobileFullBtnDiv">
                <Button className={styles.mobileSearchBtn} onClick={setSearchClickEvt} icon="search">
                  조회
                </Button>
              </div>
            </TabStripTab>
            <TabStripTab title="수용가/CID">
              <div style={{ padding: "10px" }}>
                <span className="searchSubTitleB">수용가/CID</span>
                <input
                  type="text"
                  className="installerMeainSearchTerm"
                  placeholder="수용가명 또는 CID"
                  disabled={isInstaller() === true ? true : false}
                  value={isInstaller() === true ? accUserInfo.userTerm : search.searchTerm}
                  onChange={e => {
                    setSearch(search => {
                      const item = { ...search };
                      item.searchTerm = e.target.value;
                      return item;
                    });
                  }}
                />
              </div>
              <div className="mobileFullBtnDiv">
                <Button className={styles.mobileSearchBtn} onClick={setSearchClickEvt} icon="search">
                  조회
                </Button>
              </div>
            </TabStripTab>
            <TabStripTab title="기간선택">
              <div style={{ padding: "10px" }}>
                <table className="installerMeainSearchTable">
                  <tr>
                    <th colSpan="4">
                      <span className="searchSubTitleA">설치날짜</span>
                    </th>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <DatePickerKo
                        className="installerMeainMobileCal"
                        format="yyyy-MM-dd"
                        setDtm={v => (search.startDtm = v)}
                        disabled={isInstaller() === true ? true : false}
                      />{" "}
                      ~{" "}
                      <DatePickerKo
                        className="installerMeainMobileCal"
                        format="yyyy-MM-dd"
                        setDtm={v => (search.endDtm = v)}
                        disabled={isInstaller() === true ? true : false}
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div className="mobileFullBtnDiv">
                <Button className={styles.mobileSearchBtn} onClick={setSearchClickEvt} icon="search">
                  조회
                </Button>
              </div>
            </TabStripTab>
            <TabStripTab title="상세조회">
              <div>
                <table className="installerMeainSearchTable">
                  <tr>
                    <th>
                      <span>에너지원</span>
                    </th>
                    <td>
                      <DropDown
                        className="installerMeainDropDown"
                        name="ensoTypeCode"
                        data={ensoList}
                        textField="codeVal"
                        dataItemKey="codeNo"
                        defaultItem={defualtVal}
                        disabled={isInstaller() === true ? true : false}
                        value={search.ensoType}
                        onChange={onChangeEvt}
                      />
                    </td>
                    <th>
                      <span>건축물용도</span>
                    </th>
                    <td>
                      <DropDown
                        className="installerMeainDropDown"
                        data={buildingTypeList}
                        textField="codeVal"
                        dataItemKey="codeNo"
                        defaultItem={defualtVal}
                        disabled={isInstaller() === true ? true : false}
                        onChange={e =>
                          setSearch(state => {
                            const item = { ...state };
                            item.bldSrvCode = e.target.value.codeNo;
                            return item;
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span>지역검색</span>
                    </th>
                    <td>
                      <DropDown
                        id="city"
                        name="city"
                        data={citys}
                        disabled={isInstaller() === true ? true : false}
                        className="installerMeainDropDown"
                        value={search.city}
                        textField="cityProvTerm"
                        dataItemKey="cityProvCode"
                        defaultItem={defualtValCity}
                        onChange={onChangeEvt}
                      />
                    </td>
                    <td>
                      <DropDown
                        id="rgn"
                        name="rgn"
                        data={rgns}
                        disabled={isInstaller() === true ? true : search.cityProvCode === "" ? true : false}
                        className="installerMeainDropDown"
                        value={search.rgn}
                        textField="rgnTermSimple"
                        dataItemKey="rgnCode"
                        defaultItem={defualtValRgn}
                        onChange={onChangeEvt}
                      />
                    </td>
                    <td>
                      <DropDown
                        id="dong"
                        name="dong"
                        data={dongs}
                        disabled={isInstaller() === true ? true : search.rgnCode === "" ? true : false}
                        className="installerMeainDropDown"
                        value={search.dong}
                        textField="fixbylawBundTermSimple"
                        dataItemKey="fixbylawBundCode"
                        defaultItem={defualtValDong}
                        onChange={onChangeEvt}
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div className="mobileFullBtnDiv">
                <Button className={styles.mobileSearchBtn} onClick={setSearchClickEvt} icon="search">
                  조회
                </Button>
              </div>
            </TabStripTab>
          </TabStrip>
          <div>
           
            <InstallerMeainList search={search} setInstallerMeainList={setInstallerMeainList} page={page} />
          </div>
          {loading && (
            <p className={styles.installerMeainLoading}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </div>
      ) : (
        <div className={loading ? styles.installerMeain : ""}>
          <div className="uk-grid">
            <div className="uk-width-medium-1-1">
              <div className="md-card installerMeainTBgA">
                <form className="uk-form-stacked">
                  <span className="searchSubTitleA">에너지원</span>
                  <DropDown
                    className="installerMeainDropDown"
                    name="ensoTypeCode"
                    data={ensoList}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultItem={defualtVal}
                    disabled={isInstaller() === true ? true : false}
                    value={search.ensoType}
                    onChange={onChangeEvt}
                  />
                  <span className="searchSubTitleA">지역검색</span>
                  <DropDown
                    id="city"
                    name="city"
                    data={citys}
                    disabled={isInstaller() === true ? true : false}
                    className="installerMeainDropDown"
                    value={search.city}
                    textField="cityProvTerm"
                    dataItemKey="cityProvCode"
                    defaultItem={defualtValCity}
                    onChange={onChangeEvt}
                  />
                  <DropDown
                    id="rgn"
                    name="rgn"
                    data={rgns}
                    disabled={isInstaller() === true ? true : search.cityProvCode === "" ? true : false }
                    className="installerMeainDropDown"
                    value={search.rgn}
                    textField="rgnTermSimple"
                    dataItemKey="rgnCode"
                    defaultItem={defualtValRgn}
                    onChange={onChangeEvt}
                  />
                  <DropDown
                    id="dong"
                    name="dong"
                    data={dongs}
                    disabled={isInstaller() === true ? true : search.rgnCode === "" ? true : false}
                    className="installerMeainDropDown"
                    value={search.dong}
                    textField="fixbylawBundTermSimple"
                    dataItemKey="fixbylawBundCode"
                    defaultItem={defualtValDong}
                    onChange={onChangeEvt}
                  />
                  <span className="searchSubTitleA">건축물용도</span>
                  <DropDown
                    className="installerMeainDropDown"
                    data={buildingTypeList}
                    textField="codeVal"
                    dataItemKey="codeNo"
                    defaultItem={defualtVal}
                    disabled={isInstaller() === true ? true : false}
                    onChange={e =>
                      setSearch(state => {
                        const item = { ...state };
                        item.bldSrvCode = e.target.value.codeNo;
                        return item;
                      })
                    }
                  />
                  <span className="searchSubTitleB">수용가/CID</span>
                  <input
                    type="text"
                    className="searchTerm"
                    placeholder="수용가명 또는 CID"
                    disabled={isInstaller() === true ? true : false}
                    value={isInstaller() === true ? accUserInfo.userTerm : search.searchTerm}
                    onChange={e => {
                      setSearch(search => {
                        const item = { ...search };
                        item.searchTerm = e.target.value;
                        return item;
                      });
                    }}
                  />
                  <span className={styles.disableCompo}>
                    <span className="searchSubTitleA">설치날짜</span>
                    <DatePickerKo
                      format="yyyy-MM-dd"
                      setDtm={v => (search.startDtm = v)}
                      disabled={isInstaller() === true ? true : false}
                    />
                    <span className="textColorGr"> ~ </span>
                    <DatePickerKo format="yyyy-MM-dd" setDtm={v => (search.endDtm = v)} disabled={isInstaller() === true ? true : false} />
                  </span>
                  <span className={styles.searchSubTitleC} align="right">
                    <Button
                      className={styles.searchBtn}
                      onClick={setSearchClickEvt}
                      icon="search"
                    ></Button>
                  </span>
                </form>
                <InstallerMeainCount search={search} statCnt={statCnt} />
              </div>
            </div>
          </div>
          <div>
            <InstallerMeainList search={search} setInstallerMeainList={setInstallerMeainList} page={page} />
          </div>
          {loading && (
            <p className={styles.installerMeainLoading}>
              <BeatLoader loading={true} size={24} color="#1e88e5" />
            </p>
          )}
        </div>
      )}
    </>
  );
});

export default InstallerMeain;
