import React from 'react';
import LocalAreaMonitorStatInfoTable from './localAreaMonitorStatInfoTable';

const LocalAreaMonitorStatInfo = ({ styles, ensoMeainData }) => {

  return (
    <div id = "localAreaEnsoStatInfo" className={styles.localAreaEnsoStatInfo}>
      {/* 태양광 */}
      <LocalAreaMonitorStatInfoTable ensoTypeTerm = {'태양광'} dataItem = {ensoMeainData.sola} styles = {styles} />
      {/* 태양열 */}
      <LocalAreaMonitorStatInfoTable ensoTypeTerm = {'태양열'} dataItem = {ensoMeainData.heat} styles = {styles} />
      {/* 지열 */}
      <LocalAreaMonitorStatInfoTable ensoTypeTerm = {'지열'} dataItem = {ensoMeainData.heath} styles = {styles} />
    </div>
  );
};

export default LocalAreaMonitorStatInfo;