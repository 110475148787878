import { ReactComponent as Sulbe } from "dist/svg/asan/sulbe.svg";
import { ReactComponent as ElecEnergy } from "dist/svg/asan/elec-energy.svg";
import { ReactComponent as HeatEnergy } from "dist/svg/asan/heat-energy.svg";
import { translateUnit } from "../DevelopmentState/DevelopmentState";
import "./AsanTooltip.css";

export default function AsanTooltip({ x, y, info }) {
  return (
    <div className="asan-tooltip-container" style={{ top: y-100, left: x-150}}>
      <div>{info.name}</div>
      <div className="asan-tooltip-info">
        <div className="asan-icon-wrapper">
          <Sulbe className="asan-tooltip-icon" />
        </div>
        <div className="asan-icon-wrapper">{info.meainCount}개소</div>
      </div>
      <div className="asan-tooltip-info">
        <div className="asan-icon-wrapper">
          <HeatEnergy className="asan-tooltip-icon" />
        </div>
        <div className="asan-icon-wrapper">{translateUnit(info.dongUsePower).split(' ')[0]} {translateUnit(info.dongUsePower).split(' ')[1]}</div>
      </div>
      <div className="asan-tooltip-info">
        <div className="asan-icon-wrapper">
          <ElecEnergy className="asan-tooltip-icon" />
        </div>
        <div className="asan-icon-wrapper">{translateUnit(info.dongElecPower).split(' ')[0]} {translateUnit(info.dongElecPower).split(' ')[1]}</div>
      </div>
    </div>
  );
}
