import HighchartsReact from "highcharts-react-official"

export const DaedeokCarbonChart = (props) => {
	let chartData = props.chartData

	let options = {
		chart: { 
			type:'column'
			, height: 180
			, backgroundColor: "rgba( 255, 255, 255, 0.0 )"
			// , marginBottom: 20
		}
		, title: { text: null }
		, credits: { enabled: false }
		, xAxis: [
			{
				categories: chartData?.map(e => e.gathDtm)
				, crosshair: true
				, labels: {
					style: {
						color: '#ffffff'
					}
				}
			}
		]
		, yAxis : [
			{
				title:false
				, labels: {
					style: {
						color: '#ffffff',
					}
				}
			}
		]
		, tooltip: {
			shared: true
			, valueSuffix: ' kg'
		}
		, series: [
			{ 
				name: '저감량'
				, data: chartData?.map(e => e.reducedCo2)
				, color: '#1DDFFFBF'
			}
		]
		, legend: false
	}

	return (<HighchartsReact options={options}/>)
}