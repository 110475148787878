import { useState } from "react";
import kendo from "@progress/kendo-ui";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { BeatLoader } from "react-spinners";
import ExpctBuildBsmanGrid from "./expctBuildBsmanGrid";

import styles from "./expctBuildBsman.module.css";
import "./expctBuildBsman.css";
import { getUser } from "../../../../service/authService";
import { getExpctBuildBsmanList } from "../../../../service/stats/expt/expctBuildBsmanService";

function ExpctBuildBsman({ conn, cityList, rgnList }) {
  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // 유저정보
  const userInfo = getUser();

  // 검색 조건
  const [search, setSearch] = useState({
    cityProvCode: "",
    rgnCode: "",
    city: { cityProvCode: "", cityProvTerm: "시도" },
    rgn: { rgnCode: "", rgnTermSimple: "시군구" },
  });

  const citys = cityList;
  const rgns = rgnList.filter(e => e.cityProvCode === search.cityProvCode);

  const [dataSource, setDataSource] = useState({
    grid: [],
    chart: [],
    excelData: null,
  });

  // 드롭다운 이벤트
  const onChangeEvt = e => {
    if (e.target.name === "city") {
      setSearch(search => {
        const item = { ...search };
        item.city = e.target.value;
        item.cityProvCode = e.target.value.cityProvCode;
        item.rgn = { rgnCode: "", rgnTermSimple: "시군구" };
        item.rgnCode = "";
        return item;
      });
    } else if (e.target.name === "rgn") {
      setSearch(search => {
        const item = { ...search };
        item.rgn = e.target.value;
        item.rgnCode = e.target.value.rgnCode;
        return item;
      });
    }
  };

  const getExpctBuildBsmanData = () => {
    if (!search.cityProvCode) {
      kendo.alert("시도를 선택 해주세요.");
      return;
    }
    getExpctBuildBsmanList(conn, getResult, getRejection, search, setLoading);
  };

  // 결과 데이터
  const getResult = (gridList, chartData) => {
    setDataSource(dataSource => {
      const item = { ...dataSource };
      item.grid = gridList;
      item.chart = chartData;
      return item;
    });
    setLoading(false);
  };

  // rejection
  const getRejection = error => {
    if (error.errCode === "F011") {
      kendo.alert("필수 입력 값이 입력되지 않았습니다. 다시 한번 입력 또는 선택 해주세요.");
      return;
    } else {
      console.log("error = ", error);
      kendo.alert("시스템에 문제가 발생 하였습니다.");
      return;
    }
  };

  return (
    <>
        <div className={["uk-width-medium-1-1", loading ? styles.expctBuildBsmanG : ""].join(" ")}>
          <div className="md-card">
              <div className="md-card-content small-padding">
              <b>지역 검색 &nbsp; &nbsp;</b>
              <DropDownList
                className={styles.LocationDropDownRgn}
                data={citys}
                textField="cityProvTerm"
                dataItemKey="cityProvCode"
                name="city"
                defaultItem={{ cityProvCode: "", cityProvTerm: "시도" }}
                onChange={onChangeEvt}
                value={search.city}
              />
              <DropDownList
                className={styles.LocationDropDownRgn}
                data={rgns}
                textField="rgnTermSimple"
                dataItemKey="rgnCode"
                name="rgn"
                defaultItem={{ rgnCode: "", rgnTermSimple: "시군구" }}
                disabled={search.cityProvCode === ""}
                onChange={onChangeEvt}
                value={search.rgn}
              />
              <Button className="inqBtn" icon="search" onClick={getExpctBuildBsmanData}>
              </Button>
            </div>
          </div>
          <ExpctBuildBsmanGrid
            search={search}
            dataSourceGrid={dataSource.grid}
            dataSourceChart={dataSource.chart}
            dataSourceExcel={dataSource.excelData}
            setDataSource={setDataSource}
            userInfo={userInfo}
            loading={loading}
          />
        </div>
        {loading && (
          <p className={styles.expctBuildBsmanLoading}>
            <BeatLoader loading={true} size={24} color="#1e88e5" />
          </p>
        )}
    </>
  );
}

export default ExpctBuildBsman;
