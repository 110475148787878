import React, { useEffect, useState } from 'react';
import SmpGrid from './smpGrid';

import styles from './smpHist.module.css';
import SmpSearch from './smpSearch';
import { getSmpDashboard, getSmpHistList } from './../../../service/smp/smpHistService';

import SmpDashBoardDayMonthData from './smpDashBoardDayMonthData';
import SmpDashBoardHourData from './smpDashBoardHourData';

// 켄도
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";
import { getLastMonthDay } from '../../../com/dateUtil';


const SmpHist = ({conn}) => {

  // 로딩 스피너
  const [/*loading*/, setLoading] = useState(false);

  /**
   * 검색 조건 데이터
   */
  const [ search , setSearch ] = useState({
    areaCd : '',
    radio : 'HH' ,
    radioStatus : 'HH' , 
    isSearch : true , 
    start : new Date() ,
    end : new Date() ,
    endMin : new Date() ,
    startMax : new Date() ,
    endMax : new Date() ,
    startSearchDtm : kendo.toString( new Date() , 'yyyy-MM-dd' ) , 
    endSearchDtm : kendo.toString( new Date() , 'yyyy-MM-dd' ) ,
    page : 0,
    size : 20
  });

  /**
   * 검색 결과 데이터 ( 프론트에 설정할 데이터 )
   */
  const [ dataList , setDataList ] = useState({
    dashboard : {
      smpHourTodayLand: [] ,
      smpHourTodayJeju: [] ,
      preSmpMonthJeju: 0 ,
      preSmpMonthLand: 0 ,
      todaySmpDayJeju: 0 ,
      todaySmpDayLand: 0 ,
      todaySmpMonthJeju: 0 ,
      todaySmpMonthLand: 0
    } ,
    smpResultList : { results : [] , total : 0 }
  });

  /**
   * SMP Dashboard 불러오는 데이터
   */
  const getSmpDashboardData = () => {
    getSmpDashboard( conn , getSmpDashboardResult , getRejection )
  }

  /**
   * SMP 대시보드 Callback 함수
   * @param {object} result  // 결과 데이터
   */
  const getSmpDashboardResult = (result) => {
    setDataList( dataList => {
      const item = {...dataList};
      // 상단 대시 보드 객체 받기
      const dashboard = item.dashboard;
      // 시간당 SMP 데이터
      dashboard.smpHourTodayLand = chartSettingData( false , result.smpHourToday );
      dashboard.smpHourTodayJeju = chartSettingData( true , result.smpHourToday );
      // 상단 대시보드 데이터 설정
      dashboard.preSmpMonthJeju = result.preSmpMonthJeju;
      dashboard.preSmpMonthLand = result.preSmpMonthLand;
      dashboard.todaySmpDayJeju = result.todaySmpDayJeju;
      dashboard.todaySmpDayLand = result.todaySmpDayLand;
      dashboard.todaySmpMonthJeju = result.todaySmpMonthJeju;
      dashboard.todaySmpMonthLand = result.todaySmpMonthLand;
      return item;
    })
  }

  /**
   * 시간당 SMP 차트 데이터 가공 함수
   * @param {boolean} isJeju 
   * @param {array} smpHourToday 
   * @returns 
   */
  const chartSettingData = ( isJeju , smpHourToday) => {

    // 차트 데이터 가공 ( 내부 함수 정의 )
    const calData = ( chartData ) => {
      // 배열 준비
      const array = [];
      for (let index = 0; index < 24; index++) {
        let time = '';
        let smpTitle = "";
        if( index < 10 ){
          time = '0' + index 
          smpTitle = "smp" + time;
        }else {
          time = String(index);
          smpTitle = "smp" + time;
        }
        // 객체 저장
        array.push({
          y :  chartData[smpTitle] ? chartData[smpTitle] : 0,
          name : time + '시'
        });
      }
      // 배열 리턴
      return array;
    }

    // 제주 인지 아닌지 체크
    const areaCd = isJeju === true ? 9 : 1;
    // 데이터 세팅 
    let resultList = [];
    if( smpHourToday.length > 0 ){
      smpHourToday.forEach(element => {
        if( areaCd === element.areaCd ){
          resultList = calData( element )
        }
      });
    }
    return resultList;
  }

  // 그리드 조회 이벤트 
  const getSmpHistListData = () => {
    if( search.radio === 'MM' ){
      // 선택한 시작 월의 1일 설정
      const startYear = search.start.getFullYear();
      const startMonth = search.start.getMonth();
      search.startSearchDtm = kendo.toString( new Date( startYear , startMonth , 1 ) , 'yyyy-MM-dd' ); // 여기서 1을 더하지 않아도 됨
      // 선택한 마지막 월의 마지막 일 설정
      search.endSearchDtm = kendo.toString( getLastMonthDay(search.end) , 'yyyy-MM-dd' );
    }else{
      search.startSearchDtm = kendo.toString( search.start , 'yyyy-MM-dd' );
      search.endSearchDtm = kendo.toString( search.end , 'yyyy-MM-dd' );
    }
    getSmpHistList( conn, getSmpHistListResult, getRejection, search );
  }

  // 시간별, 일별, 월별 SMP 이력 결과 함수
  const getSmpHistListResult = (result) => {
    console.log("getSmpHistListResult = ", result);
    setDataList( dataList => {
      const item = {...dataList};
      console.log("item = ", item);
      item.smpResultList.results = result.results;
      item.smpResultList.total = result.total;
      return item;
    });
  }
  /**
   * Rejection 함수
   * @param {*} error // 에러코드
   */
  const getRejection = (error) => {
    console.log("error Code = ", error);
  }

  useEffect(() => {
    // 대시보드 데이터 불러오기
    getSmpDashboardData();
    // SMP 데이터 불러오기
    getSmpHistListData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="uk-grid">
        <div className="uk-width-medium-1-1">
          <SmpDashBoardDayMonthData  conn = {conn} styles = {styles} dashboardData = {dataList.dashboard} />
        </div>
      </div>

      <div className="uk-grid">
        <div className="uk-width-medium-1-2"> 
          <SmpDashBoardHourData conn = {conn} styles = {styles} dashboardData = {dataList.dashboard} />
        </div>
        <div className="uk-width-medium-1-2"> 
          <SmpSearch conn = {conn} styles = {styles} DropDownList = {DropDownList} RadioButton = {RadioButton} 
                    Button = {Button} kendo = {kendo} search = {search} setSearch = {setSearch} getSmpHistListData = {getSmpHistListData} setLoading = {setLoading}/>
          <SmpGrid conn = {conn} styles = {styles} search = {search} setSearch = {setSearch} smpResultList = {dataList.smpResultList} getSmpHistListData = {getSmpHistListData} setLoading={setLoading}/> 
        </div>
      </div>
    </>
  );
};

export default SmpHist;