
/**
 * 환경변수를 이용한 로그 출력 함수
 * 
 * log(value, key = 'LOG');
 * 
 * 매개변수
 * 1. 로그 값 (필수)
 * 2. 로그 이름
 * 
 * [필수]
 * 개발 진행시 isLogger = true
 * 배포 진행시 isLogger = false
 * 
 */
const log = (value, key = 'LOG') => {

    // FIXME 배포시 isLogger = false로 변경해야 함
    const isLogUseYn = process.env.REACT_APP_LOG;
    
    // null 체크
    const isLogValueValidation = !!value;

    // 함수 입력 체크
    const isLogFuncValidation = !(typeof value == 'function');

    if(isLogUseYn && isLogValueValidation && isLogFuncValidation) {
        // console.log('===============================================================');
        if(typeof value !== 'object') {
            console.log(`[${key}] = ${value}`);
        } else if(typeof value === 'object') {
            console.log(`[${key}]`, value);
        }
        // console.log('===============================================================');
    }
};

export default log;