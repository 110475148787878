import "./index.css";
import { ReactComponent as LOGO } from "../../../../assets/svg/gongju/logo.svg";
import { ReactComponent as LOGIN } from "../../../../assets/svg/gongju/login.svg";

const GongjuHeader = () => {
  const handleRedirectMonitoring = () => {
    window.location.href = "https://kremc.kr/";
  };

  return (
    <div className="gongju-header">
      <LOGO className="gongju-logo" />
      <button className="gongju-login-wrapper" onClick={handleRedirectMonitoring} type="button">
        <LOGIN className="gongju-login-icon" />
        로그인
      </button>
    </div>
  );
};

export default GongjuHeader;
