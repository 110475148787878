// React
import React, { useState, useEffect } from "react";
import {
  getRprsn,
  setUpdateRprsn,
  setDeleteRprsn,
} from "../../../service/mngt/rtuEnte/rtuService";
import { parseDate } from "../../../com/dateUtil";

// Kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import kendo from "@progress/kendo-ui";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { IntlProvider, LocalizationProvider, /*loadMessages*/ } from "@progress/kendo-react-intl";
import { InEditCommandCell } from "../inEditCommandCell";

function RtuRprsnModal({conn, title, rtuEnteCode, cancelEdit }) {
  const editField = "inEdit";
  const [dataSource, setDataSource] = useState([]);
  const [backupDataSource, setBackupDataSource] = useState([]);
  
  // 그리드 상태(페이징, 정렬, 필터)
  const [dataState, setDataState] = useState({
    take: 5,
    skip: 0,
    total: 0,
    sort: [],
    filter: {},
  });
  useEffect(() => {
    getRprsn(conn, rtuEnteCode).then((result) => {
      setDataSource(result);
      setBackupDataSource(result);
    });
    // eslint-disable-next-line
  }, []);

  /* 페이징 이벤트 */
  const pageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  /* 정렬 이벤트 */
  const sortChange = (event) => {
    if (event.sort.length === 0) {
      setDataState({
        ...dataState,
        sort: [
          {
            field: "",
            dir: "",
          },
        ],
      });
    } else {
      setDataState({
        ...dataState,
        sort: [
          {
            field: event.sort[0].field,
            dir: event.sort[0].dir,
          },
        ],
      });
    }
  };
  /*inedit 추가, 수정 입력 이벤트*/
  const itemChange = (event) => {
    const temp = dataSource.map((item) =>
      item.rprsnSeriNo === event.dataItem.rprsnSeriNo
        ? { ...item, [event.field]: event.value }
        : item
    );

    setDataSource(temp);
  };

  /* 등록일시 parse */
  const setDateUtil = (e) => {
    return (
      <td className="k-command-cell">
        {parseDate("yyyy-mm-dd hh:mm:ss", e.dataItem.regiDtm)}
      </td>
    );
  };

  const CommandCell = (props) => (
    <InEditCommandCell
      {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
      isNewItem = {props.dataItem.rprsnSeriNo === undefined}
    />
  );

  const remove = (dataItem) => {
    kendo
      .confirm(dataItem.rprsnTerm + "의 데이터를 정말 삭제하시겠습니까?")
      .then(() => {
        setDeleteRprsn(conn, dataItem);
        const index = dataSource.findIndex(
          (record) => record.rprsnSeriNo === dataItem.rprsnSeriNo
        );
        const temp = [...dataSource];
        temp.splice(index, 1);
        setDataSource(temp);
      });
  };

  const add = (dataItem) => {
    console.log("dataItem = ", dataItem);
    // Validation
    const isValid = validation(dataItem);
    if( isValid.isValid === false ){
      kendo.alert(isValid.message);
      return;
    }

    //rprsnSeriNo 생성 = 전체 seriNo의 최대값 +1
    const rprsnSeriNoList = dataSource.map((item) =>
      item.rprsnSeriNo !== undefined ? item.rprsnSeriNo : 0
    );
    dataItem.rprsnSeriNo = Math.max.apply(null, rprsnSeriNoList) + 1;
    dataItem.rtuEnteCode = rtuEnteCode;
    setUpdateRprsn(conn, dataItem);

    //프론트엔드부분 setDataSource
    dataItem.regiDtm = new Date();
    dataItem.inEdit = false;
    setDataSource(
      dataSource.map((u) =>
        u.rprsnSeriNo === dataItem.rprsnSeriNo ? { ...u, ...dataItem } : u
      )
    );
  };

  const update = (dataItem) => {

    console.log("update = " , dataItem);

    // Validation
    const isValid = validation(dataItem);
    if( isValid.isValid === false ){
      kendo.alert(isValid.message);
      return;
    }

    dataItem.inEdit = false;
    setUpdateRprsn(conn, dataItem);
    const temp = dataSource.map((item) =>
      item.rprsnSeriNo === dataItem.rprsnSeriNo
        ? {
            ...item,
            ...dataSource,
          }
        : item
    );
    setDataSource(temp);
  };
  
  const discard = (dataItem) => {
    const temp = [...dataSource];
    temp.splice(0, 1);
    setDataSource(temp);
  };

  const cancel = (dataItem) => {
    const originalItem = backupDataSource.find(
      (p) => p.rprsnSeriNo === dataItem.rprsnSeriNo
    );
    const temp = dataSource.map((item) =>
      item.rprsnSeriNo === originalItem.rprsnSeriNo ? originalItem : item
    );

    dataItem.inEdit = false;
    setDataSource(temp);
  };

  const enterEdit = (dataItem) => {

    console.log("enterEdit");


    const temp = dataSource.map((item) =>
      item.rprsnSeriNo === dataItem.rprsnSeriNo
        ? { ...item, inEdit: true }
        : item
    );
    setDataSource(temp);
  };

  const addNew = () => {
    const newRecord = { inEdit: true };
    setDataSource([newRecord, ...dataSource]);
  };


  // Validation 설정
  const validation = (dataItem) => {
    let isValid = true;
    let message = "";
    if( !dataItem.rprsnTerm ){ message = "A/S담당자 이름을 입력 해주세요."; isValid = false; }
    else if( !dataItem.rprsnExpl ){ message = "A/S담당자 설명을 입력 해주세요."; isValid = false; }
    
    if( !dataItem.rprsnCntct ){ message = "내선 번호를 입력 해주세요."; isValid = false; }
    else if( dataItem.rprsnCntct ){ 
      const regPhone = /^\d{2,3}-\d{3,4}-\d{4}$/; // 현재 정규식 ( 02-xxx, xxxx-xxxx , 041-xxx , xxxx-xxxx )
      // console.log("regPhone.test(dataItem.rprsnCntct) = ", regPhone.test(dataItem.rprsnCntct));
      if( !regPhone.test(dataItem.rprsnCntct) ){
        message = "유효하지 않은 내선 번호입니다!"; isValid = false; 
      }
    }
    if( !dataItem.rprsnPhon ){ message = "A/S담당자 연락처를 입력 해주세요."; isValid = false; }
    else if( dataItem.rprsnPhon ){
      // const regPhone = /^(02|01[016789]{1}|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/;
      const regPhone = /^\d{3}-\d{4}-\d{4}$/;
      if( !regPhone.test(dataItem.rprsnPhon) ){
        message = "유효하지 않은 A/S담당자 연락처입니다!"; isValid = false; 
      }
    }

    if( !dataItem.rprsnEmail ){
      // eslint-disable-next-line
      const regEmail = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      if( !regEmail.test(dataItem.rprsnEmail) ){
        message = "유효하지 않은 A/S담당자 이메일입니다!"; isValid = false; 
      }
    }

    return { isValid : isValid , message : message }
  }

  return (
    <Dialog title={title + " A/S 담당자 관리"} onClose={cancelEdit}>
      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="ko">
          <Grid
            className="rtuRprsnGrid"
            style={{ height: "500px" }}
            data={orderBy(dataSource, dataState.sort).slice(
              dataState.skip,
              dataState.take + dataState.skip
            )}
            skip={dataState.skip}
            take={dataState.take}
            total={dataSource.length}
            pageable={{ buttonCount: 5, pageSizes: true }}
            onPageChange={pageChange}
            sortable
            sort={dataState.sort}
            onSortChange={sortChange}
            onItemChange={itemChange}
            editField={editField}
          >
            <GridToolbar>
              <Button className="k-button k-primary" onClick={addNew}>
                {/*<img src="/images/icon_add.png" alt=" " />*/}
                추가
              </Button>
            </GridToolbar>
            <Column
              field="rprsnSeriNo"
              title="번호"
              width="80px"
              editable={false}
            />
            <Column field="rprsnTerm" title="이름" width="130px" />
            <Column field="rprsnCntct" title="내선번호" width="150px" />
            <Column field="rprsnPhon" title="연락처" width="150px" />
            <Column field="rprsnEmail" title="이메일" width="250px" />
            <Column field="rprsnExpl" title="설명" width="300px" />
            <Column
              field="regiDtm"
              title="등록일시"
              width="150px"
              cell={setDateUtil}
              editable={false}
            />
            <Column cell={CommandCell} width="130px" />
            <GridNoRecords>데이터가 존재하지 않습니다.</GridNoRecords>
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </Dialog>
  );
}

export default RtuRprsnModal;
