import React from "react";

import BizExpenseModal from "./bizExpenseModal";

const BizExpenseGrid = ({
  conn,
  styles,
  bizStyles,
  search,
  selected,
  dataList,
  setDataList,
  getData,
  bizExpenseWindowVisible,
  setBizExpenseWindowVisible,
  setBizFileWindowVisible,
  setPayOrderWindowVisible,
  setBizExpendWindowVisible,
  initalBizExpense,
  Grid,
  GridColumn,
  IntlProvider,
  LocalizationProvider,
  kendo,
  numFormat,
  Form,
  FormElement,
  Button,
  NumberFormat,
}) => {
  const sumDataCell = e => {
    let result = "";
    if (e.field === "term") {
      result = "잔액";
    } else if (e.field === "totalExpense") {
      result = dataList.expenseGrid.data[0].totalExpense - dataList.expenseGrid.data[1].totalExpense;
    } else if (e.field === "gvmSpt") {
      result = dataList.expenseGrid.data[0].gvmSpt - dataList.expenseGrid.data[1].gvmSpt;
    } else if (e.field === "mupal") {
      result = dataList.expenseGrid.data[0].mupal - dataList.expenseGrid.data[1].mupal;
    } else if (e.field === "province") {
      result = dataList.expenseGrid.data[0].province - dataList.expenseGrid.data[1].province;
    } else if (e.field === "city") {
      result = dataList.expenseGrid.data[0].city - dataList.expenseGrid.data[1].city;
    } else if (e.field === "priCompany") {
      result = dataList.expenseGrid.data[0].priCompany - dataList.expenseGrid.data[1].priCompany;
    }

    return <td style={{ backgroundColor: "#FFEAEA" }}>{numFormat(result)}</td>;
  };

  const cellNumFormat = e => {
    const item = e.dataItem;
    const field = e.field;

    return <td>{numFormat(item[field])}</td>;
  };

  const state = [
    { field: "term", title: " ", show: true, footerCell: sumDataCell },
    { field: "totalExpense", title: "총액(원)", show: true, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "gvmSpt", title: "정부(원)", show: true, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "mupal", title: "지자체(원)", show: selected === 0 ? true : false, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "province", title: "도비(원)", show: selected === 1 ? true : false, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "city", title: "시/군비(원)", show: selected === 1 ? true : false, cell: cellNumFormat, footerCell: sumDataCell },
    { field: "priCompany", title: "민간(원)", show: true, cell: cellNumFormat, footerCell: sumDataCell },
  ];

  const bizExpenseGridAdd = e => {
    if (search.rgnCode !== "" && search.bizYyNo !== "") {
      setBizExpenseWindowVisible(state => {
        const item = { ...state };
        item.visible = !bizExpenseWindowVisible.visible;
        return item;
      });

      setBizFileWindowVisible(false);
      setPayOrderWindowVisible(false);
      setBizExpendWindowVisible(false);

      if (dataList.bizExpenseInput.totalExpense.toString() === "") {
        initalBizExpense();
      }

      getData();
    } else {
      kendo.alert("사업연도와 시군구를 선택해주세요");
    }
  };

  const imgChange = () => {
    let imgLink = "/images/plus.png";

    if (dataList.bizExpenseInput.totalExpense.toString() !== "") {
      imgLink = "/images/edit.png";
    } else {
      imgLink = "/images/plus.png";
    }

    return imgLink;
  };

  return (
    <div className="uk-grid">
      <div className="uk-width-medium-1-1">
        <div className="md-card">
          <div className="md-card-content">
            {bizExpenseWindowVisible.visible && (
              <BizExpenseModal
                conn={conn}
                styles={styles}
                bizStyles={bizStyles}
                search={search}
                dataList={dataList}
                setDataList={setDataList}
                getData={getData}
                bizExpenseGridAdd={bizExpenseGridAdd}
                kendo={kendo}
                Form={Form}
                FormElement={FormElement}
                Button={Button}
                NumberFormat={NumberFormat}
              />
            )}
            <div className="md-card-toolbar-conv">
              <h3 className="md-card-toolbar-heading-text-left">사업비</h3>
              <div className={styles.convMdCardToolbar}>
                <button className="btn_pls" onClick={bizExpenseGridAdd}>
                  <img className="tabCancelMngtImg" src={imgChange()} alt={imgChange()} />
                </button>
              </div>
            </div>
            <LocalizationProvider language="ko-KR">
              <IntlProvider locale="ko">
                <Grid
                  className="eer-Table eer-Table-striped"
                  // "bizExpendG"
                  data={dataList.expenseGrid.data}
                  total={dataList.expenseGrid.total}
                  filterable={false}
                  sortable={false}>
                  {state.map((column, index) => {
                    return (
                      column.show && (
                        <GridColumn
                          key={index}
                          field={column.field}
                          title={column.title}
                          cell={column.cell}
                          footerCell={column.footerCell}
                        />
                      )
                    );
                  })}
                </Grid>
              </IntlProvider>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BizExpenseGrid;
