import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { numFormat } from '../../../com/dataUtil';

const LocalAreaMonitorPowerChart = ({ styles, dayPowerList}) => {

  // 발전량추이
  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });
  let powerChart = {
    chart: { type: 'line' , zoomType: 'xy' , height: 270}
    , title: { text: null }
    , xAxis: [{ type: 'category', crosshair: true }]
    , yAxis: 
      [ 
        { // 발전량
          labels: {
            format: '{value:,.0f}',
            style: {
              color: "#002BFF"
            }
          } ,
          title : false
        }
      ]
      , credits: { enabled: false }
      , tooltip: {
        borderRadius: 5 ,
        shared: true,
        formatter: function() {
          return(
            ` 
              <div style="margin-top : 10px;">
                <span style="color:${this.points[0].point.color}">\u25CF</span>
                <span style="font-size : 12px; font-weight: bold;" >${this.points[0].point.gathName}</span>
              </div><br />
              <span style="font-size : 14px; font-weight: bold; color : #FF7300;">1. 총 발전량 : </span> 
              <span style="font-size : 14px; font-weight: bold; color : #FF7300;">${numFormat(this.points[0].point.totalPower)} kWh </span> <br />
              <span style="font-size : 14px; font-weight: bold;">2. 연동 발전량 : </span> <span>${numFormat(this.points[0].point.syncDayPower)} kWh </span> <br />
              <span style="font-size : 14px; font-weight: bold;">3. 미연동 발전량 : </span> <span>${numFormat(this.points[0].point.asyncDayPower)} kWh </span> <br />
            `
          );
        }
      } 
      , legend: false
      , series: [
      { 
        tooltip: {
          valueSuffix: ' kWh'
        }
        , color: "#002BFF"
        , data: dayPowerList
      }
    ]
  };


  return (
    <div className={styles.rightBoxChart}>
      <table className={styles.boxContents}>
          <caption>
              <span>태양광 발전량 추이그래프</span>
          </caption>
      </table>
      <div class={styles.powerChart}>
        <HighchartsReact options = {powerChart}/>  
      </div>
    </div>
  );
};

export default LocalAreaMonitorPowerChart;